import * as React from 'react';
import styled from 'styled-components';
import ImagePreview from 'components/ImagePreview';
import { Control, useController } from 'react-hook-form';

interface Props {
  control?: Control;
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  type?: string;
  defaultValue?: File | string | null;
  onFileChange: React.ChangeEventHandler<HTMLInputElement>;
}

const ImagePicker: React.FC<Props> = ({
  name,
  control,
  label,
  required,
  disabled,
  placeholder,
  type,
  defaultValue,
  onFileChange,
}) => {
  const [isHovering, setIsHovering] = React.useState<boolean>(false);
  const {
    field: { ref },
  } = useController({ name, control });

  return (
    <Container
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <label htmlFor={name}>
        <ImagePreview
          alt={name}
          isHovering={isHovering}
          label={label}
          src={
            // eslint-disable-next-line no-nested-ternary
            ref.current.files?.[0]
              ? URL.createObjectURL(ref.current.files?.[0])
              : defaultValue
              ? URL.createObjectURL(defaultValue as Blob)
              : ''
          }
          onClear={() => {}}
          onUpload={() => ref.current.click()}
        />
        <input
          ref={ref}
          name={name}
          id={name}
          hidden
          required={required}
          type="file"
          accept="image/*"
          onChange={onFileChange}
        />
      </label>
    </Container>
  );
};
export default ImagePicker;

const Container = styled.div.attrs({ className: 'image-picker' })`
  position: relative;
  ${({ theme }) => theme.utils.centerFlex};
  cursor: pointer;
  font-size: 0.875rem;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.silver};
  height: 150px;
  width: 150px;
  background: #f7f7f7;
  background-clip: content-box;
  border: 1px dashed#707070;
  border-radius: 8px;
  margin-bottom: 30px;
  & label {
    position: relative;
    user-select: none;
    ${({ theme }) => theme.utils.centerFlex}
    flex-direction: column;
    gap: 7px;
    align-self: center;
    cursor: pointer;
    max-width: 100%;
    max-height: 100%;
    img {
      width: 100% !important;
      height: 100% !important;
    }
  }
`;
