import { Type } from 'constants/config';
import styled, { css } from 'styled-components';

const Main = styled.main<{
  userType: Type;
  withNavbar?: boolean;
  withBrandCarousel?: boolean;
  isMobile?: boolean;
}>`
  display: flex;
  height: calc(100vh - 60px);
  width: calc(100vw - 90px);
  overflow: scroll;
  margin: 0;
  top: 60px;
  left: 90px;
  position: relative;
  z-index: 0;
  ${({ isMobile }) =>
    isMobile &&
    css`
      left: 0;
      width: unset;
    `}
  /* overflow-y: scroll;  */
  ${({ theme }) => theme.utils.scrollbar}
`;

export default Main;
