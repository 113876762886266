import * as React from 'react';
import useAuthentication from './useAuthentication';

// This hook is used in PrivateRoute or Public page that includes users in order to validate token and get user data.

const useValidateUser = () => {
  const {
    token,
    validateUser,
    error,
    allowUserAccess,
    fullName,
    type,
  } = useAuthentication();
  const [validating, setValidate] = React.useState(Boolean(token));

  React.useEffect(() => {
    if (!token) {
      validateUser();
    }
  }, [token, validateUser]);

  React.useEffect(() => {
    if (error || token) {
      setValidate(true);
    }
  }, [setValidate, error, token]);

  return {
    validating,
    isAuthenticated: !!token,
    allowUserAccess,
    fullName,
    token,
    type,
  };
};

export default useValidateUser;
