import * as React from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Tooltip from 'components/Tooltip';
import InfoIcon from 'assets/@client/supplier-login-flow/info-icon.png';
import styled from 'styled-components';
import { Setter } from 'types/common.types';

const MobileTooltip: React.FC<{
  label: string | React.ReactNode;
  title: React.ReactNode;
  open?: boolean;
  setOpen?: Setter<boolean>;
  marginWidth?: number;
  arrowMargin?: boolean;
}> = ({ label, title, open, setOpen, marginWidth, arrowMargin }) => {
  return (
    <StyledTooltip>
      <div>{label}</div>
      <ClickAwayListener onClickAway={() => setOpen?.(false)}>
        <div>
          <Tooltip
            arrow
            open={open}
            title={title}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            $variant="supplierInfo"
            placement="top"
            marginWidth={marginWidth}
            arrowMargin={arrowMargin}
          >
            <div>
              <InfoView style={{ marginTop: 0 }}>
                <InfoImage
                  src={InfoIcon}
                  alt="info-icon"
                  onClick={() => setOpen?.(true)}
                />
              </InfoView>
            </div>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </StyledTooltip>
  );
};

export default MobileTooltip;

const StyledTooltip = styled.div`
  display: flex;
`;

const InfoView = styled.div`
  height: 21.33px;
  width: 21.33px;
  margin-left: 6px;
  cursor: pointer;
`;

const InfoImage = styled.img`
  height: 100%;
  width: 100%;
`;
