import * as React from 'react';
import styled from 'styled-components';
import { MOBILE_RESOLUTION } from 'styles/sharedStyle';

const CardField: React.FC<{
  label?: string | React.ReactNode;
  value: string | JSX.Element;
  weight?: number;
}> = ({ label, value, weight }) => (
  <Container>
    {Boolean(label) && <Label>{label}</Label>}
    <Value weight={weight}>{value}</Value>
  </Container>
);

export default CardField;

const Container = styled.div.attrs({ className: 'card-field' })`
  color: ${({ theme }) => theme.colors.primary.clientTextColor};
  margin: 12px 40px 12px 0;

  @media ${MOBILE_RESOLUTION} {
    margin: 4px 0;
  }
`;

const Paragraph = styled.p`
  margin: 0;
  padding: 0;
`;

const Label = styled(Paragraph).attrs({ className: 'card-field-label' })`
  font-size: 0.875rem;
  font-weight: 400;

  @media ${MOBILE_RESOLUTION} {
    font-size: 0.625rem;
  }
`;

const Value = styled(Paragraph).attrs({ className: 'card-field-value' })<{
  weight?: number;
}>`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 1.125;
  font-weight: ${({ weight = 700 }) => weight};
  text-transform: capitalize;

  @media ${MOBILE_RESOLUTION} {
    font-size: 0.875rem;
  }
`;
