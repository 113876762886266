import * as React from 'react';
import Skeleton from '@material-ui/core/Skeleton';
import { alpha } from '@material-ui/core/styles';
import styled, { css } from 'styled-components';
import rtl from 'styled-components-rtl';
import { Link } from 'react-router-dom';

interface Props {
  label: string | React.ReactNode;
  borderColor?: string;
  isLoading?: boolean;
  action?: {
    label: string | React.ReactNode;
    onClick?: VoidFunction;
    to?: string;
  };
  center?: boolean;
  titleColor?: string;
  borderImage?: string;
}

const DeskTopSectionTitle: React.FC<Props> = ({
  label,
  borderColor = '#87b9e7',
  action,
  isLoading,
  center,
  titleColor,
  borderImage,
}) => {
  return (
    <Container center={center} titleColor={titleColor}>
      <LineContainer>
        <div className="section-title-label">
          {isLoading ? (
            <Skeleton variant="text" width={80} height={30} />
          ) : (
            label
          )}
        </div>
        <LineFill
          color={borderColor}
          isRight={true}
          borderImage={borderImage}
          isWidth={Boolean(action)}
        />
        {action && (
          <>
            <LineButton center={center} borderImage={borderImage}>
              {action.to ? (
                <CustomLink
                  $borderColor={borderColor}
                  to={action.to}
                  onClick={action.onClick}
                  borderImage={borderImage}
                >
                  {action.label}
                </CustomLink>
              ) : (
                <Button
                  $borderColor={borderColor}
                  onClick={action.onClick}
                  type="button"
                >
                  {action.label}
                </Button>
              )}
            </LineButton>
          </>
        )}
      </LineContainer>
    </Container>
  );
};

export default React.memo(DeskTopSectionTitle);

const Container = styled.div.attrs({ className: 'section-title-wrapper' })<{
  center?: boolean;
  titleColor?: string;
}>`
  width: 100%;
  margin: 32px 0 24px 0 !important;
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 700;
  @media (max-width: 1600px) and (min-width: 800px) {
    padding: 0 20px;
  }
  & .section-title-label {
    position: absolute;
    text-align: center;
    background: #fff;
    padding: 0 8px;

    font-weight: 500;
    font-size: 24px;
    color: #222222;
    ${({ titleColor }) =>
      titleColor &&
      css`
        color: ${titleColor};
      `};
  }
  ${({ center }) =>
    center &&
    css`
      position: relative;
    `};

  @media (min-width: 1150px) {
    font-size: 1.875rem;
  }
`;

const LineButton = styled.span<{ center?: boolean; borderImage?: string }>`
  ${({ center }) =>
    center &&
    css`
      position: absolute;
      ${rtl`
      right: 0;
      `}
      background: white;
      @media (max-width: 800px) {
        top: 0;
      }
    `};
  ${({ borderImage }) =>
    borderImage &&
    css`
      border-radius: 10px;
      ${rtl`margin-left: 8px;`}
      position: relative;
      height: 26px;
      width: 90px;
    `};
  ${rtl`
      right: 0;
      `}
`;

const LineFill = styled.hr.attrs({ className: 'section-title-line-fill' })<{
  color?: string;
  isMobile?: boolean;
  isRight?: boolean;
  borderImage?: string;
  isWidth?: boolean;
}>`
  height: 3px;
  border: none;
  background: ${({ color }) => color};
  flex: 1;
  flex-shrink: 1;

  border-radius: 22px;
  ${({ borderImage }) =>
    borderImage &&
    css`
      background: ${borderImage};
    `}
  ${({ isMobile }) =>
    isMobile &&
    css`
      ${rtl`
        margin-right: 8px;
      `}
    `};
`;

const buttonStyles = css<{ $borderColor: string }>`
  font-weight: 400;
  font-size: 16px;
  font-family: inherit;
  color: #222222;
  padding: 7px 22px;
  border: 1.5px solid ${({ $borderColor }) => $borderColor};
  border-radius: 10px;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s;

  &:hover,
  &:active {
    background: ${({ $borderColor }) => alpha($borderColor, 0.3)};
  }

  @media (max-width: 800px) {
    width: 91px;
    font-weight: 400;
    font-size: 14px;
    padding: 5px 0;
  }
`;

const Button = styled.button<{ $borderColor: string }>`
  ${buttonStyles}
`;

const CustomLink = styled(Link)<{ $borderColor: string; borderImage?: string }>`
  text-decoration: none;
  ${buttonStyles}
  font-size: 0.875rem;
  ${({ borderImage }) =>
    borderImage &&
    css`
      border: 2px;
      margin-left: 0;
      position: relative;
      width:91px;
      display: block;
      height 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      text-align: center;
      top: -2px;
      ::before {
        content: '';
        position: absolute;
        top: 0;
        ${rtl`
        right: -1px;
        `}
        bottom: 0;
        ${rtl`
        left: -2px;
        `}
        z-index: -1;
        margin: 0px;
        border-radius: inherit;
        background: ${borderImage};
      }
    `}
  a {
    color: inherit;
  }
`;

const LineContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
