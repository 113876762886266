import IconButton, { IconName } from 'components/Button/IconButton';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { useSpring, config, animated } from 'react-spring';
import Tooltip from 'components/Tooltip';
import { Tab } from 'utils/sidebar.utils';
import Badge from '@material-ui/core/Badge';
import { useSelector } from 'store/selectors';
import { cartItemCount } from 'store/selectors/@client/cartInfo.selectors';

interface Props {
  item: Tab;
  isMobile: boolean;
  isHidden?: boolean;
  component?: string;
  onClick?: VoidFunction;
  showBadge?: boolean;
  className?: string;
  propertyBadgeCount?: number | string;
}

const NavButton: React.FC<Props> = ({
  children,
  item: { icon, to, isMain, isActive, label = '', viewBox },
  isMobile,
  isHidden,
  onClick,
  component = 'li',
  showBadge,
  propertyBadgeCount,
}) => {
  const cartCount = useSelector(cartItemCount);

  const iconSize = React.useMemo(() => {
    if (isMain) {
      return isMobile ? 35 : 20;
    }
    return 25;
  }, [isMain, isMobile]);

  const zoomStyles = useSpring({
    config: { ...config.stiff, duration: 150 },
    from: { transform: 'scale(0)' },
    to: {
      transform: isMain && isHidden ? 'scale(0)' : 'scale(1)',
    },
  });

  const buttonProps = {
    children,
    onClick,
    isMain,
    isActive,
    iconName: children ? null : IconName.custom_icon,
    customIcon: icon ?? null,
    iconSize,
    isHidden: isMain && isHidden,
    $size: 'small' as 'small',
    viewBox,
    isMobile,
  };

  return (
    <ListItem isHidden={isMain && isHidden} component={component}>
      <Tooltip title={label} $variant="info">
        <StyledLink to={to} tabIndex={-1}>
          {isMobile ? (
            <>
              {showBadge ? (
                <StyledBadge
                  badgeContent={
                    icon === 'buildings-outlined'
                      ? propertyBadgeCount
                      : cartCount
                  }
                >
                  <StyledIconButton {...buttonProps} />
                </StyledBadge>
              ) : (
                <StyledIconButton {...buttonProps} />
              )}
            </>
          ) : (
            <animated.div style={zoomStyles}>
              {showBadge ? (
                <StyledBadge
                  badgeContent={
                    icon === 'buildings-outlined'
                      ? propertyBadgeCount
                      : cartCount
                  }
                >
                  <StyledIconButton {...buttonProps} />
                </StyledBadge>
              ) : (
                <StyledIconButton {...buttonProps} />
              )}
            </animated.div>
          )}
        </StyledLink>
      </Tooltip>
    </ListItem>
  );
};

export default NavButton;

interface ListProps {
  isHidden?: boolean;
  component?: string;
}

const ListItem = styled.li.attrs(({ component = 'li' }: ListProps) => ({
  as: component,
}))<ListProps>`
  list-style-type: none;

  ${({ isHidden }) =>
    isHidden &&
    css`
      pointer-events: none;
    `}
`;

const StyledLink = styled(Link).attrs(({ to }) => ({ as: !to && 'span' }))`
  &:focus-visible {
    outline: none;
  }
`;

const StyledIconButton = styled(IconButton)<{
  isMain?: boolean;
  isActive?: boolean;
  isHidden?: boolean;
  isMobile?: boolean;
}>`
  && {
    color: white;
    border-radius: 50% !important;
    padding: 10px;
    margin: ${({ isMobile }) => (isMobile ? '7px' : '0px')};

    svg {
      fill: white;
    }

    ${({ isMain, theme, isMobile }) =>
      isMain &&
      css`
        color: black;
        background: white;
        padding: 1px 0 0 0;
        min-width: 34px;
        min-height: 34px;
        margin: 0 10px;

        svg {
          fill: black;
        }

        .MuiIconButton-label span {
          flex-direction: column;
          ${theme.utils.centerFlex};
        }

        ${isMobile &&
        css`
          padding: 13px;
          position: absolute !important;
          z-index: 4;
          top: -35px;
          left: 0;
          right: 0;
          margin: 0 auto !important;
          width: 68px;
          height: 68px;
          border-radius: 50% !important;
          box-shadow: 0px -3px 25px 5px rgba(0, 88, 171, 0.07);
          color: white;
          svg {
            fill: white;
          }
          background: ${theme.colors.primary.lightMobile};
        `}
      `}
  }
`;

const StyledBadge = styled(Badge)`
  & > span {
    background-color: white;
    color: black;
    top: 8px;
    right: 8px;
  }
`;
