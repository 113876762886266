import * as React from 'react';
import styled from 'styled-components';
import MacbookImage from 'assets/@client/supplier/MacBook-Pro-mobile.png';
import { ReactComponent as PatriciaLogoMobile } from 'assets/@client/supplier/patriciamobile.svg';
import MobileImage from 'assets/@client/supplier/iphone-design-mobile.png';
import ImageInsideMacbook from 'assets/@client/supplier/image-inside-macbook.png';
import rtl from 'styled-components-rtl';
import CheckVector from 'assets/@client/onboarding/CheckVector.png';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import { LandingProps } from './SellerLandingPageContainer';

const ThankyouMobile: React.FC<LandingProps> = ({ supplierData }) => {
  return (
    <>
      <LogoContainer>
        <div>
          <PatriciaLogoContainer logoColor={supplierData?.color} />
          <Image src={MacbookImage} alt="floor-design" />
          <img
            src={supplierData?.hero_gif ?? ImageInsideMacbook}
            alt="hero gif"
            className="hero-gif"
          />
          <MobileImageContainer src={MobileImage} alt="iphone-image" />
        </div>
      </LogoContainer>
      <ImageContainer>
        <img src={CheckVector} alt="check" />
      </ImageContainer>
      <MessageParent>
        <SuccessMessage>
          <FormattedMessage id={Message.LANDING_PAGE_THANKYOU_SUCCESS} />
        </SuccessMessage>
        <SuccessSubMessage>
          <FormattedMessage
            id={Message.LANDING_PAGE_THANKYOU_SUCCESS_SUBTITLE}
            values={{ linebreak: <br />, mobileLineBreak: <br /> }}
          />
        </SuccessSubMessage>
        <AppointmentText>
          <FormattedMessage
            id={Message.LANDING_PAGE_THANKYOU_SUCCESS_APPOINTMENT_TEXT}
            values={{
              mobileLineBreak: <br />,
              contactName: supplierData?.supplier.user?.first_name,
              contactNumber:
                supplierData?.supplier.additional_phone_number ??
                supplierData?.supplier.user?.phone_number,
            }}
          />
        </AppointmentText>
      </MessageParent>
    </>
  );
};

const PatriciaLogoContainer = styled(PatriciaLogoMobile)<{
  logoColor?: string;
}>`
  position: relative;
  top: 0;

  path {
    fill: ${({ logoColor }) => logoColor};
  }
`;

const LogoContainer = styled.div`
  margin-top: 104px;
  & > div {
    height: 310px;
    width: 310px;
    position: relative;
    margin: 0 auto;
    @media only screen and (max-width: 1024px) {
      text-align: center;
    }
  }
  .hero-gif {
    border-radius: 4px;
    width: 246px;
    height: 138px;
    top: 70px;
    left: 64px;
    position: absolute;
    @media only screen and (max-width: 1024px) {
      left: -32.5px;
      right: 0;
      margin: 0 auto;
    }
  }
`;

const Image = styled.img`
  position: absolute;
  height: 168.38563537597656px;
  width: 305.91px;
  top: 62px;
  right: 0;
  left: -34px;
  margin: 0 auto;
  ${rtl`right: 31.54px`}
  @media only screen and (max-width: 1024px) {
    left: -34px;
    right: 0;
    margin: 0 auto;
  }
`;

const MobileImageContainer = styled.img`
  position: absolute;
  width: 162.99px;
  height: 108px;
  left: 206px;
  top: 156px;
  @media only screen and (max-width: 1024px) {
    right: -185px;
    left: 0;
    top: 161px;
    margin: 0 auto;
  }
`;

const ImageContainer = styled.div`
  width: min-content;
  margin: auto;
  margin-top: 32px;
`;

const SuccessMessage = styled.div`
  margin-top: 16px;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
`;

const SuccessSubMessage = styled.div`
  margin-top: 8px;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
`;

const AppointmentText = styled.div`
  margin-top: 32px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
`;

const MessageParent = styled.div`
  text-align: center;
`;
export default ThankyouMobile;
