import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import noop from 'lodash/noop';
import { METHODS } from 'api/client';
import {
  useSession,
  useAuthentication,
  useValidateUser,
  useFetch,
  useRouter,
} from 'hooks';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { MOBILE_RESOLUTION } from 'styles/sharedStyle';
import Button from 'components/Button';
import * as Form from 'components/forms';
import { Message } from 'i18n';
import { resetPasswordSchema } from 'utils/validation.utils';
import { ResetPasswordPayload } from 'store/types/authentication.types';
import { AuthRoute, ClientRouter } from 'router/routes';
import { AuthForm, Fields } from '../Auth.config';

const ResetPassword = () => {
  const { query, history } = useRouter<{ recovery_token: string }>();
  const intl = useIntl();
  const { isAuthenticated } = useValidateUser();
  const { resetPassword, loading: isLoading } = useAuthentication();
  const methods = useForm<ResetPasswordPayload>({
    resolver: yupResolver(resetPasswordSchema),
    defaultValues: { password: '', verifyPassword: '' },
    mode: 'all',
  });
  const { handleSubmit, formState } = methods;
  const { isValid } = formState;
  const { getLocation, country } = useSession();

  const onSubmit = ({ verifyPassword, ...payload }: ResetPasswordPayload) => {
    if (query.recovery_token)
      resetPassword({ ...payload, token: query.recovery_token });
  };

  React.useEffect(() => {
    if (!country) getLocation();
  }, [country, getLocation]);

  const onFailure = React.useCallback(() => {
    history.replace(AuthRoute.LOGIN);
  }, [history]);

  const { isLoading: isValidating } = useFetch<string>({
    initialUrl: '/api/auth/password/reset/validate_token/',
    config: { method: METHODS.POST, data: { token: query.recovery_token } },
    onFailure,
  });

  if (isAuthenticated) return <Redirect to={ClientRouter.APP} />;

  return (
    <Container
      title={intl.formatMessage({ id: Message.AUTH_RESET_PASSWORD_TITLE })}
    >
      <FormProvider {...methods}>
        <CustomForm onSubmit={handleSubmit(onSubmit)}>
          <Fields className="register-fields" direction="row">
            <Form.Field
              showValidAdornment
              onKeyDown={noop}
              label={{
                title: intl.formatMessage({
                  id: Message.AUTH_FORGOT_PASSWORD_PASSWORD_FIELD,
                }),
                isBold: true,
              }}
              name="password"
              type="password"
              width="100%"
              placeholder={intl.formatMessage({
                id: Message.AUTH_LOGIN_PASSWORD_FIELD_PLACEHOLDER,
              })}
            />
            <Form.Field
              showValidAdornment
              onKeyDown={noop}
              label={{
                title: intl.formatMessage({
                  id: Message.AUTH_FORGOT_PASSWORD_VERIFY_FIELD,
                }),
                isBold: true,
              }}
              name="verifyPassword"
              type="password"
              width="100%"
              placeholder={intl.formatMessage({
                id: Message.AUTH_REGISTRATION_VERIFY_PASSWORD_FIELD,
              })}
            />
          </Fields>
          <Button
            className="main-button"
            variant="contained"
            color="inherit"
            type="submit"
            disabled={!isValid || isLoading || isValidating}
            isLoading={isLoading || isValidating}
          >
            <FormattedMessage id={Message.AUTH_RESET_PASSWORD_BUTTON_LABEL} />
          </Button>
        </CustomForm>
      </FormProvider>
    </Container>
  );
};

export default ResetPassword;

const Container = styled(Form.Container).attrs({
  className: 'register-form-container',
})`
  && .auth-form-content {
    ${rtl`
      left: 0;
    `}
    height: 100vh;
    max-height: unset;

    @media (max-width: 1500px) {
      width: 576px;
      padding: 23px 28px;
    }

    @media (min-width: 1500px) {
      width: 688px;
      padding: 23px 72px;
    }
    @media (max-width: 535px) {
      width: 100%;
      padding: 23px 20px;
    }
  }

  && .auth-form-header {
    margin-bottom: 29px;
    flex-direction: row;
    align-items: flex-end;
    gap: 100px;

    @media (max-width: 500px) {
      margin-bottom: 29px;
      gap: 50px;
    }

    @media (min-width: 1500px) {
      margin-bottom: 29px;
      gap: 95px;
    }

    & .logo {
      @media (max-width: 500px) {
        align-self: center;
        height: 40px;
      }
      align-self: flex-start;
      @media (max-width: 1500px) {
        height: 50px;
      }
      height: 77px;
    }
  }
  && .auth-header-title {
    font-size: 1.25rem;
    margin: ${({ theme }) => (theme.dir === 'rtl' ? '0 5px 0 0' : '')};
    font-weight: bold;
    @media (max-width: 500px) {
      font-size: 1.25rem;
    }
    @media (min-width: 1501px) {
      font-size: 1.75rem;
    }
  }

  && .main-button {
    width: 100%;
    min-height: 48px;
    text-transform: none;
    margin-top: 75px;
  }

  && .MuiFormControl-root {
    @media (min-width: 500px) {
      width: 218px;
    }

    @media (min-width: 1500px) {
      width: 270px;
    }
  }
  & .register-fields {
    margin-top: 30px;
    row-gap: 14px;
    column-gap: 22px;

    @media (max-width: 1500px) {
      gap: 19px;
    }
    @media (max-width: 500px) {
      gap: 28px;
    }
  }
`;

const CustomForm = styled(AuthForm)`
  @media (max-width: 1500px) {
    width: 425px;
  }

  @media (min-width: 1500px) {
    width: 445px;
  }
  @media ${MOBILE_RESOLUTION} {
    width: 85%;
  }
  .back-link {
    margin-top: 41px;
  }
`;
