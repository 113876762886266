import {
  ThreeDTasksDataType,
  ThreeDInDbDataType,
} from 'store/constants/3d-content-management/threeD.constants';
import * as Payload from 'store/types/3d-content-management/threeD.types';

export const getThreeDTasksDataRequest = (
  payload: Payload.Get3DTasksDataRequestPayload
) => ({
  type: ThreeDTasksDataType.GET_3D_TASKS_REQUEST,
  payload,
});

export const getThreeDTasksDataFulfilled = (
  payload: Payload.Get3DTasksDataFulfilledPayload
) => ({
  type: ThreeDTasksDataType.GET_3D_TASKS_FULFILLED,
  payload,
});

export const getThreeDTasksDataRejected = (
  payload: Payload.Get3DTasksDataRejectedPayload
) => ({
  type: ThreeDTasksDataType.GET_3D_TASKS_REJECTED,
  payload,
});

export const getThreeDInDbDataRequest = (
  payload: Payload.Get3DInDbDataRequestPayload
) => ({
  type: ThreeDInDbDataType.GET_3D_IN_DB_REQUEST,
  payload,
});

export const getThreeDInDbDataFulfilled = (
  payload: Payload.Get3DInDbDataFulfilledPayload
) => ({
  type: ThreeDInDbDataType.GET_3D_IN_DB_FULFILLED,
  payload,
});

export const getThreeDInDbDataRejected = (
  payload: Payload.Get3DInDbDataRejectedPayload
) => ({
  type: ThreeDInDbDataType.GET_3D_IN_DB_REJECTED,
  payload,
});

export type ThreeDAction = ReturnType<
  | typeof getThreeDTasksDataRequest
  | typeof getThreeDTasksDataFulfilled
  | typeof getThreeDTasksDataRejected
  | typeof getThreeDInDbDataRequest
  | typeof getThreeDInDbDataFulfilled
  | typeof getThreeDInDbDataRejected
>;
