import * as React from 'react';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import no_image_placeholder from 'assets/images/client-products/no_image.png';
import { GenericCarouselImage } from 'styles/sharedStyle';
import { Category } from 'hooks/useCategories';
import { Link } from 'react-router-dom';
import { ClientRouter } from 'router/routes';
import { FormattedMessage, useIntl } from 'react-intl';
import { Message } from 'i18n';
import { userLogData } from 'mockData/userLogData';
import { LogsAPI } from 'api/methods';

interface Props {
  item: Category;
  to?: string;
  isIdea?: boolean;
}

const ProductCategoriesItem: React.FC<Props> = ({
  item,
  to,
  isIdea = false,
}) => {
  const intl = useIntl();

  return (
    <ListItem>
      <Link
        to={to || ClientRouter.PRODUCT_CATEGORY.replace(':catName', item.name)}
      >
        <ProductContainer
          onClick={() =>
            LogsAPI.postUserLogs({
              name: userLogData.eventName.category,
              area: userLogData.eventArea.homePage,
              section: userLogData.eventSection.catalog,
              path: userLogData.eventPath.appPath,
              metadata: { category_name: item.name, category_id: item.id },
            })
          }
        >
          <Image
            className="img-container"
            imgUrl={item.homepage_thumbnail || no_image_placeholder}
          >
            <ImageContainer className="img-container">
              <div className="shop-now">
                <h2>
                  <FormattedMessage
                    id={isIdea ? Message.TAKE_A_LOOK : Message.SHOP_NOW}
                    values={{ linebreak: <br /> }}
                  />
                </h2>
              </div>
            </ImageContainer>
          </Image>
          <div>
            <Text>{(intl.locale === 'he' && item.alt_name) || item.name}</Text>
          </div>
        </ProductContainer>
      </Link>
    </ListItem>
  );
};

export default ProductCategoriesItem;

const ListItem = styled.li`
  position: relative;
  cursor: pointer;
  list-style-type: none;
  a {
    text-decoration: none;
    color: inherit;
  }
`;

const ProductContainer = styled.div`
  &:hover {
    .shop-now {
      opacity: 1;
    }
  }
`;

const ImageContainer = styled.div`
  position: relative;
  width: 127px;
  height: 195px;

  @media (min-width: 800px) {
    width: 280px;
    height: 284px;
  }
  .shop-now {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    border-radius: 25px;
    z-index: 99;
    display: flex;
    h2 {
      color: white;
      font-size: 40px;
      font-weight: 800;
      margin: auto;
      text-align: center;
    }
  }
`;

const Text = styled.p`
  font-weight: 700;
  font-size: 1.125rem;
  ${rtl`
    margin-left: 20px;
  `}
  @media (max-width: 800px) {
    ${rtl`
      width: 127px;
      font-size: 0.75rem;
      margin: 10px 0 10px 20px
    `}
  }
`;

const Image = styled(GenericCarouselImage)`
  width: 127px;
  height: 195px;
  max-width: 100%;
  max-height: 100%;
  margin: 10px 20px 0 0;
  @media (min-width: 800px) {
    width: 280px;
    height: 284px;
  }
`;
