import * as React from 'react';
import { useMediaQuery } from '@mui/material';
import { MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';
import ThankYou from './ThankYou';
import ThankyouMobile from './ThankyouMobile';
import { LandingProps } from './SellerLandingPageContainer';

const ThankYouPageContainer: React.FC<LandingProps> = props => {
  const isMobile = useMediaQuery(MOBILE_TABLET_RESOLUTION);
  return (
    <>{isMobile ? <ThankyouMobile {...props} /> : <ThankYou {...props} />}</>
  );
};

export default ThankYouPageContainer;
