import * as React from 'react';
import * as Response from 'api/responses';
import * as Transform from 'api/transform';
import { useFetch, useNotifications, useRouter } from 'hooks';
import type { Skin } from 'types/properties';

const useMoreFromSkinUser = () => {
  const { showNotification } = useNotifications();
  const { query } = useRouter<{
    id: string;
  }>();
  const onFailure = React.useCallback(() => {
    showNotification({
      key: 'skins/getUserSkinsDataRejected',
      message:
        "We had a problem fetching this user's skins, please try again later",
      severity: 'error',
    });
  }, [showNotification]);

  const {
    data: userSkins,
    isLoading: isLoadingSkins,
    hasError,
    callFetch,
  } = useFetch<Skin[], Response.Skins>({
    initialUrl: `/api/skins/${query.id}/suggestions/`,
    onFailure,
    transform: Transform.transformSkinsPaginated,
    skipOnStart: true,
  });

  const fetchUserSkins = React.useCallback(
    (userId: number) => callFetch({ params: { public: true, owner: userId } }),
    [callFetch]
  );

  return { userSkins, isLoadingSkins, hasError, fetchUserSkins };
};

export default useMoreFromSkinUser;
