import * as React from 'react';
import { StyleSheet, Font } from '@react-pdf/renderer';
import { Table, TableRow, TableCell } from 'documents/components';
import { Order } from 'types/orders';
import { toCurrencyString } from 'utils/string.utils';
import { Supplier } from 'types/suppliers';
import { Roboto } from 'documents/documents.config';

Font.register(Roboto);

const styles = StyleSheet.create({
  headerId: {
    width: '15%',
    fontWeight: 500,
    fontSize: '14px',
    fontFamily: 'Roboto',
    textAlign: 'center',
    marginVertical: 'auto',
    color: 'white',
  },
  id: {
    width: '15%',
    fontWeight: 500,
    fontSize: '14px',

    textAlign: 'center',
    marginTop: 9,
    marginBottom: 9,
  },
  headerName: {
    color: 'white',
    paddingLeft: '24px',
    marginVertical: 'auto',
    width: '30%',
    fontWeight: 500,
    fontSize: '14px',
    fontFamily: 'Roboto',
  },
  name: {
    width: '30%',
    fontWeight: 500,
    fontSize: '14px',
    marginTop: 9,
    marginBottom: 9,
    marginLeft: '24px',
  },
  center: {
    textAlign: 'center',
  },
});

const headers = [
  { label: 'ITEM NAME', style: styles.headerName },
  { label: 'SKU', style: styles.headerId },
  { label: 'PRICE', style: { ...styles.headerId, ...styles.center } },
  { label: 'QTY', style: { ...styles.headerId, ...styles.center } },
  { label: 'TOTAL', style: { ...styles.headerId, ...styles.center } },
];

const ProductsTable: React.FC<{ order: Order }> = ({ order: { products } }) => {
  return (
    <Table>
      <TableRow header data={headers} fullWidth={true} />
      {products.map(
        (
          { id, name, quantity, unitPrice, totalRowPrice, supplier, sku },
          index
        ) => {
          return (
            <TableRow
              key={id}
              fullWidth={true}
              pageBreak={index === 7 || (index > 7 && (index - 7) % 12 === 0)}
              rowStyle={{
                maxHeight: '50px',
                backgroundColor:
                  index % 2 === 1
                    ? 'rgba(225, 225, 225, 1)'
                    : 'rgba(250, 252, 254, 1)',
              }}
            >
              <TableCell style={styles.name}>{name}</TableCell>
              <TableCell style={styles.id}>{sku}</TableCell>
              <TableCell style={{ ...styles.id, ...styles.center }}>
                {toCurrencyString({
                  value: unitPrice,
                  country: (supplier as Supplier)?.country,
                })}
              </TableCell>
              <TableCell style={{ ...styles.id, ...styles.center }}>
                {quantity}
              </TableCell>
              <TableCell style={{ ...styles.id, ...styles.center }}>
                {toCurrencyString({
                  value: totalRowPrice,
                  country: (supplier as Supplier)?.country,
                })}
              </TableCell>
            </TableRow>
          );
        }
      )}
    </Table>
  );
};

export default ProductsTable;
