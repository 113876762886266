import * as React from 'react';
import { useIntl } from 'react-intl';
import Modal, { ModalAction } from 'components/@client/Modal';
import { Message } from 'i18n';
import { useFetch, useNotifications } from 'hooks';
import { METHODS } from 'api/client';
import { Property } from 'types/properties';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  property: Property;
  onRefresh?: VoidFunction;
}

const DeletePropertyModal: React.FC<Props> = ({
  open,
  onClose,
  property,
  onRefresh,
}) => {
  const intl = useIntl();
  const { showNotification } = useNotifications();
  const onSuccess = React.useCallback(
    _ => {
      showNotification({
        key: 'clients/deletePropertyFulfilled',
        message: 'Property has been deleted successfully.',
        severity: 'success',
      });
      onClose();
      onRefresh?.();
    },
    [showNotification, onClose, onRefresh]
  );

  const onFailure = React.useCallback(
    (message: string) => {
      showNotification({
        key: 'clients/deletePropertyRejected',
        message,
        severity: 'error',
      });
      onClose();
    },
    [showNotification, onClose]
  );

  const { callFetch, isLoading } = useFetch<{}>({
    initialUrl: '/api/properties/archive/',
    skipOnStart: true,
    config: {
      method: METHODS.PATCH,
    },
    onSuccess,
    onFailure,
  });

  const onDeleteProperty = React.useCallback(() => {
    const data = {
      ids: [property.id],
      is_archived: true,
    };
    callFetch({ data, method: METHODS.PATCH });
  }, [callFetch, property]);

  const actions: ModalAction[] = [
    {
      label: intl.formatMessage({ id: Message.NO }),
      onClick: () => onClose(),
      variant: 'secondary',
    },
    {
      label: intl.formatMessage({ id: Message.YES }),
      onClick: onDeleteProperty,
      variant: 'primary',
      isLoading,
    },
  ];

  return (
    <Modal
      open={open || false}
      onClose={onClose}
      title={intl.formatMessage({ id: Message.DELETE_PROPERTY_TEXT })}
      actions={actions}
    >
      {null}
    </Modal>
  );
};

export default DeletePropertyModal;
