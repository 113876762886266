import { keyBy } from 'utils/lodash.utils';
import styled from 'styled-components';
import { isTest } from 'utils/common.utils';
import iconPaths from './selection.json'; // the file exported from IcoMoon

const { icons } = iconPaths;
const iconPathsHash = keyBy(icons, ({ properties }) => properties.name);

function getPath(iconName: string): string {
  if (iconName === '') return '';
  const iconSvg = iconPathsHash[iconName];

  if (iconSvg) {
    return iconSvg.icon.paths.join(' ');
  }
  if (isTest) {
    throw new Error(`icon ${iconName} does not exist.`);
  }
  // eslint-disable-next-line no-console
  console.warn(`icon ${iconName} does not exist.`);
  return '';
}

interface IProps {
  name: string;
  className?: string;
  width?: number;
  height?: number;
  size?: number;
  color?: string;
  hoverColor?: string;
  viewBox?: string;
  onClick?: VoidFunction;
}

const Icon = ({
  name,
  className = '',
  width,
  height,
  size = 16,
  color,
  hoverColor,
  viewBox,
  onClick,
}: IProps) => (
  <Svg
    className={`icon icon-${name} ${className}`}
    width={width || size}
    height={height || size}
    viewBox={viewBox || '0 0 1024 1024'}
    color={color}
    hoverColor={hoverColor}
    onClick={onClick}
  >
    <g id={name}>
      <path d={getPath(name)} />
    </g>
  </Svg>
);

const Svg = styled.svg<{ color?: string; hoverColor?: string }>`
  fill: ${({ theme, color }) => color || theme.colors.primary.dark};
  fill-rule: ${({ fillRule }) => fillRule};
  &:hover {
    fill: ${({ hoverColor }) => hoverColor};
  }
`;

export default Icon;
