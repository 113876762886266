import * as React from 'react';
import { METHODS } from 'api/client';
import { useNotifications, useFetch, useRouter } from 'hooks';
import { OnboardingAction, useOnboarding } from 'context/onboardingContext';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { clientInformationSchema } from 'utils/validation.utils';
import { size } from 'utils/lodash.utils';
import type { ClientInformationType } from 'types/common.types';
import { ClientRouter } from 'router/routes';
import { getRoute } from 'utils/sidebar.utils';
import { removeEmptyValues } from 'utils/common.utils';

const useClientInformation = () => {
  const methods = useForm<ClientInformationType>({
    resolver: yupResolver(clientInformationSchema),
    defaultValues: { firstName: '', lastName: '', email: '' },
    mode: 'all',
    shouldUnregister: false,
    reValidateMode: 'onChange',
  });
  const { reset, control, setValue, formState } = methods;
  const email = useWatch({ control, name: 'email' });
  const { errors } = formState;
  const isPassword = React.useMemo(() => Boolean(email) && !errors.email, [
    email,
    errors.email,
  ]);
  const { history, location } = useRouter();
  const { showNotification } = useNotifications();
  const [{ selectedFloor, image, newPropertyType }, dispatch] = useOnboarding();
  const onUpdatePassword = React.useCallback(() => {
    let generatedPassword: string = '0';
    const newVal = (Math.random() + 1)
      .toString(36)
      .slice(0, 10)
      .toUpperCase()
      .split('.');
    generatedPassword = newVal && newVal[1];

    setValue('password', generatedPassword, {
      shouldValidate: true,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue]);

  const onSuccess = React.useCallback(() => {
    const html = document.querySelector('html') as HTMLHtmlElement;
    html.style.overflowY = '';
    dispatch({ type: OnboardingAction.SET_IMAGE, payload: null });
    if (location.pathname.includes('/app/properties'))
      history.push(ClientRouter.PROPERTIES);
    else if (location.pathname.includes('/app/landing'))
      history.push(getRoute([location.pathname, '/thankyou']));
    else
      showNotification({
        key: 'user-add-success',
        message: 'User added successfully',
        severity: 'success',
      });
  }, [history, location.pathname, showNotification, dispatch]);

  const onFailure = React.useCallback(
    error => {
      showNotification({
        key: 'phone-error',
        message: error,
        severity: 'error',
      });
    },
    [showNotification]
  );

  const { isLoading, callFetch } = useFetch({
    initialUrl: '/api/properties/add_seller_property/',
    skipOnStart: true,
    config: {
      method: METHODS.POST,
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      },
    },
    onSuccess,
    onFailure,
  });
  const handleSave = React.useCallback(
    (payload: ClientInformationType) => {
      const param = {
        first_name: payload.firstName,
        last_name: payload.lastName,
        branch: payload.branch,
        phone_number: payload?.phone ? `+${payload?.phone}` : payload?.phone,
        ...(isPassword
          ? {
              email: payload.email,
              password: payload.password,
            }
          : {}),
      };
      if (newPropertyType === 'upload' && (!image || size(image.name) > 124)) {
        showNotification({
          key: 'uploadPlan/uploadPlanRejected',
          message:
            'File name has to be shorter than 124 characters, Please rename your file.',
          severity: 'error',
        });
      } else {
        const formData = new FormData();
        if (image) formData.append('file', image as Blob);
        formData.append('user', JSON.stringify(param));
        if (payload.supplier)
          formData.append('supplier', JSON.stringify(payload.supplier));
        if (selectedFloor)
          formData.append(
            'pre_made_property',
            JSON.stringify(selectedFloor.id)
          );
        callFetch(
          removeEmptyValues({
            data: formData,
          })
        );
      }
    },
    [
      isPassword,
      newPropertyType,
      image,
      showNotification,
      selectedFloor,
      callFetch,
    ]
  );

  return {
    isLoading,
    methods,
    selectedFloor,
    reset,
    handleSave,
    onUpdatePassword,
    isPassword,
    image,
  };
};

export default useClientInformation;
