import { all, call, put, debounce } from 'redux-saga/effects';
import { PropertiesAPI } from 'api/methods';
import { showNotification } from 'store/actions/notifications.actions';
import PropertiesDataType from 'store/constants/user-management/properties.constants';
import * as PropertiesActions from 'store/actions/user-management/properties.actions';
import * as Payload from 'store/types/user-management/properties.types';
import { newSortByMap } from 'utils/transform.utils';
import { removeEmptyValues } from 'utils/common.utils';
import { FILTER_DATE_FORMAT, formatDate } from 'utils/dates.utils';
import { PROPERTIES_STATUS } from 'types/properties';

function* errorHandler(
  e: Error,
  type: PropertiesDataType,
  rejectAction: (payload: {
    error: string;
  }) => { type: PropertiesDataType; payload: { error: string } }
) {
  const error = e.message || 'ERROR: Cannot refresh Data source';
  yield put(showNotification({ key: type, message: error, severity: 'error' }));
  yield put(rejectAction({ error }));
}

const sortPropertiesMap = {
  client: 'client_name',
  createdAt: 'created_at',
  id: 'id',
  name: 'name',
};

function* getPropertiesDataRequest({
  payload,
  type,
}: ReturnType<typeof PropertiesActions.getPropertiesDataRequest>) {
  try {
    const {
      page = 0,
      size = 10,
      search,
      dateRange,
      sortBy,
      withMedia,
    } = payload;
    const params = removeEmptyValues({
      page: page + 1,
      size,
      search,
      media_only: withMedia,
      from_date: formatDate(dateRange?.startDate, FILTER_DATE_FORMAT, ''),
      to_date: formatDate(dateRange?.endDate, FILTER_DATE_FORMAT, ''),
      status: PROPERTIES_STATUS.Published,
      ...newSortByMap<typeof sortPropertiesMap>(sortPropertiesMap, sortBy),
    }) as Payload.GetPropertiesDataRequestPayload;

    const {
      results: properties,
      count,
    }: Payload.GetPropertiesDataResponsePayload = yield call(
      PropertiesAPI.getProperties,
      {
        params,
      }
    );
    const pageCount = Math.ceil(count / size);
    yield put(
      PropertiesActions.getPropertiesDataFulfilled({ properties, pageCount })
    );
  } catch (e) {
    yield call(
      errorHandler,
      e,
      type,
      PropertiesActions.getPropertiesDataRejected
    );
  }
}

function* ordersSaga() {
  yield all([
    debounce(
      500,
      PropertiesDataType.GET_PROPERTIES_DATA_REQUEST,
      getPropertiesDataRequest
    ),
  ]);
}

export default ordersSaga;
