import styled from 'styled-components';

const Magazine2 = () => {
  return (
    <Root>
      <div>
        <p>
          <b>
            הוא בליבה של כל משפחה, המקום הראשון אליו נכנסים כאשר מגיעים הביתה,
            הרי הוא, הסלון. הסלון שלנו משמש לאינספור פעילויות ומחליף כובעים
            בהתאם לצורך. לפעמים הוא מהווה מרחב משפחתי חמים ולעיתים חלל מזמין
            ואלגנטי לאירוח. כך או כך מדובר במרחב הכי שימושי בבית. מהי הדרך
            הנכונה לעיצוב הסלון וכיצד נהפוך אותו למקום שנעים לשהות בו. כל
            התשובות מחכות לכם במאמר הבא
          </b>
        </p>
      </div>
      <div>
        <p>
          מי מאיתנו לא אוהב סלון מעוצב? מרחב כיפי המתאים לפעילות משפחתית, מקום
          להירגע בו בסופו של יום ארוך וחלל אלגנטי לאירוח חברים ומשפחה. הסלון
          הביתי שלנו הוא החדר המרכזי בבית וככזה, חשוב לנו שיהיה מעוצב, נוח, נעים
          ולא פחות חשוב מכך, מתאים לצרכינו. חושבים על עיצוב סלון, אבל מתלבטים
          היכן להתחיל ועל מה לתת דגש? אל דאגה!
          <br />
          <b>
            מומחי העיצוב של "פטרישיה", עוזרת וירטואלית חדשנית המציעה חוויה
            דיגיטלית ומותאמת אישית לעיצוב הבית , התגייסו לעזרתכם והכינו עבורכם
            עצות זהב ממוקדות לעיצוב הסלון, מצטרפים?
          </b>
        </p>
      </div>
      <div>
        <p>
          <b>כל עיצוב סלון מתחיל בתכנון מקדים</b>
          <br />
          ספונטניות היא כיף גדול, אך בכל הנוגע לעיצוב, מומלץ לתכנן מראש ולתת דגש
          לכמה אלמנטים חשובים. אין זה אומר כי לא תוכלו להיות יצירתיים, לשנות
          ולגוון,
          <br />
          אלא כי רצוי לפעול על פי תוכנית מוקדמת וברורה ולהתחשב במגבלות מרחב,
          סגנון עיצוב ועוד ועוד.
        </p>
      </div>
      <div>
        <p>
          <b>אלו גורמים כדאי לקחת בחשבון?</b>
        </p>
      </div>
      <div>
        <p>
          <b>מיקום - טבעי, אך לא מובן מאליו</b>
          <br />
          אולי זה נראה ברור ומובן לכולנו, אך למיקום הסלון ישנה השפעה רבה על אופן
          העיצוב, המרחב, התאורה ועוד. הסלון הוא אמנם החדר הקרוב לכניסה, אך בה
          בעת חשוב שיהיה מואר ומרכזי. בעיצוב הסלון כדאי לקחת בחשבון את הדרך
          המובילה אליו , האלמנטים העיצוביים שחשוב לכם שיראו, הפרדה משאר חדרי
          הבית ואספקטים פרקטיים של חיי היום יום ואורח החיים של בני המשפחה.
        </p>
      </div>
      <div>
        <p>
          <b>הגודל - עיצוב סלון מקטן ועד גדול</b>
          <br />
          אולי תתפלאו לשמוע, אך בעזרתו של עיצוב נכון, גם החלל הקטן ביותר, יכול
          להראות מרווח ומזמין.
          <br />
          עיצוב מדויק , מאוורר ומואר ישרה תחושה של מרחב ושפע. בכדי לקבל את הגודל
          הרצוי, תוכלו להוסיף אלמנטים של אור באמצעות צביעת הקירות,
          <br />
          שימוש בתאורה נכונה, תליית וילונות קלים ועוד.
        </p>
      </div>
      <div>
        <p>
          <b>סגנון ואווירה - טאצ' ייחודי משלכם</b>
          עיצוב סלון מדויק התואם את אופי הבית והמרחב המשפחתי הוא אישי וייחודי
          לכם. חשבו על הסגנון האישי שלכם ועל האווירה אותה תרצו ליצור. למרות שאין
          סגנון אחד הדומה למשנהו, תוכלו לקבל השראה ממגוון עיצובים קיימים, החל
          מעיצוב אורבני ונקי, סגנון עתיק או עיצוב רומנטי כפרי. בשלב הבא, סגנון
          העיצוב יכוון אתכם ברכישת הרהיטים המתאימים ובבחירת פריטי עיצוב ואחסון
          משלימים.
        </p>
      </div>
      <div>
        <p>
          <b>פרקטיות לצד עיצוב מזמין - בוחרים רהיטים</b>
          <br />
          שלב בחירת הרהיטים הוא אחד השלבים המהנים והמאתגרים כאחד בעיצוב הסלון.
          זהו השלב בו (כמעט) הכל מונח במקום והסלון הולך ומתעצב ומקבל פנים
          ברורות.
          <br />
          רגע לפני שאתם רוכשים רהיטים, חשבו על המרחב העומד לרשותכם. לצד הסגנון
          העיצובי, יש לקחת בחשבון את מגבלות המקום.
          <br />
          לדוגמא, אם עומד לרשותכם חלל קטן יותר, רצוי לבחור בפריטי ריהוט קלילים
          ומינימליסטיים.
          <br />
          מזמינים רהיטים אונליין? תוכלו להיעזר בהדמיה וירטואלית, אשר תאפשר לכם
          לקבל תמונה מלאה וחיה של הסלון הביתי.
          <br />
          <b>
            ועוד טיפ קטן מאיתנו - כלל אצבע בבחירת הריהוט הוא שימוש בחוק השלישים.
            למה הכוונה? ספה העומדת במרכז תהא כשני שליש מגודל החדר,
            <br />
            שולחן הסלון אותו תבחרו צריך להיות כשני שליש מגודל הספה , והמזנון?
            כשני שליש מהקיר עליו הוא נשען וכן הלאה.
          </b>
        </p>
      </div>
      <div>
        <p>
          <b>שטיחים, כריות ופריטי עיצוב - היופי נמצא בפרטים הקטנים</b>
          <br />
          פריטי עיצוב הם אמצעי נהדר ונגיש לשדרג כל חלל בבית ולהפוך את עיצוב
          הסלון לייחודי ומגוון. תוכלו לבחור בפריטי טקסטיל שונים, להוסיף כריות
          ושטיחים במגוון טקסטורות וסגנונות. זכרו כי לס איז מור, גוונו וצרו מיקס
          עיצובי, אך שמרו על קו עיצובי, מבלי להעמיס.
        </p>
      </div>
      <div>
        <p>
          <b>פטרישיה - מעצבים את הסלון בקליק בשלושה שלבים פשוטים</b>
          <br />
          נעים להכיר, פטרישיה, הסייעת וירטואלית שלכם המגדירה מחדש את חווית
          העיצוב בסטנדרט דיגיטלי ייחודי.
          <br />
          אני שמחה להזמין אתכם לחוויה וירטואלית חדשנית המציעה לכם מרחב מקוון
          ומותאם אישית בו תוכלו לעצב את הבית, הכל בקלות ובנוחות, מבלי לצאת ממנו!
          <br />
          מעצבים את הסלון הביתי? על מנת שתוכלו לבחור , ריכזתי עבורכם אונליין,
          תחת מטריה אחת, מנעד רחב של שירותי עיצוב וחברות ריהוט, אשר יאפשרו לכם
          עושר עיצובי,
          <br />
          בדיוק בסגנון המתאים לכם. בכדי לסייע לכם בבחירה, אלווה אתכם לאורך כל
          התהליך, מבלי לבזבז זמן בחיפושים מיותרים,
          <br />
          החל משלב התוכניות ועד לרגע שכל הפריטים המעוצבים יגיעו עד לפתח הדלת.
          מוכנים? קליק לעיצוב סלון בדיוק כמו שחלמתם.
        </p>
      </div>
    </Root>
  );
};

export default Magazine2;

const Root = styled.div``;
