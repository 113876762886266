import { Button, TextField, Checkbox, MenuItem, Select } from '@mui/material';
import * as React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { ReactComponent as UploadIcon } from 'assets/@client/supplier/cloud-upload-btn.svg';
import { ReactComponent as UploadDoneIcon } from 'assets/@client/supplier/cloud-upload-done.svg';
import { useRouter } from 'hooks';
import FloorDesign from 'assets/@client/supplier/floor-design.png';
import { Message } from 'i18n';
import rtl from 'styled-components-rtl';
import InsertInvitationOutlinedIcon from '@mui/icons-material/InsertInvitationOutlined';
import { FormattedMessage, useIntl } from 'react-intl';
import { Controller, FormProvider } from 'react-hook-form';
import useClientInformation from 'hooks/useClientInformation';
import { ClientInformationType } from 'types/common.types';
import { PhoneField } from 'components/forms';
import Loader from 'components/Loader/Loader';
import { PopupModal } from 'react-calendly';
import { isIsrael } from 'utils/addresses.utils';
import useUploadPlan from '../onboarding/upload-plan/useUploadPlan';

const UserForm: React.FC<{
  supplier?: number;
  calendlyUrl?: string;
  locations?: [
    {
      apartment_number: string;
      floor: number;
      house: string;
      street: string;
      city: string;
      country: string;
      state: string;
      zip_code: string;
      id?: number;
    }
  ];
}> = ({ supplier, calendlyUrl, locations }) => {
  const intl = useIntl();
  // eslint-disable-next-line
  let _window: any = window;

  const {
    methods,
    onUpdatePassword,
    handleSave,
    isPassword,
    image,
    isLoading,
  } = useClientInformation();
  const { control, handleSubmit, getValues, errors } = methods;
  const [open, setOpen] = React.useState(false);
  const [isError, setError] = React.useState(true);
  const { query } = useRouter<{ supplierName: string | number }>();
  const newError = methods.errors;
  // eslint-disable-next-line no-prototype-builtins
  if (newError.hasOwnProperty('password')) {
    delete newError.password;
  }
  React.useEffect(() => {
    if (isPassword) {
      onUpdatePassword();
    }
  }, [isPassword, onUpdatePassword]);
  const onSubmit = React.useCallback(
    (payload: ClientInformationType) => {
      handleSave({
        ...payload,
        supplier: supplier ?? Number(query.supplierName),
      });
    },
    [handleSave, supplier, query.supplierName]
  );
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [isTncAgreed, setIsTncAgreed] = React.useState(false);

  const { setImage } = useUploadPlan();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const submitForm = handleSubmit(onSubmit);
  const rootElement = document.getElementById('root');
  return (
    <UserFormContainer id="userForm">
      <Image src={FloorDesign} alt="floor-design" />
      <h4>
        <FormattedMessage id={Message.LANDING_PAGE_FORM_HEADING} />
      </h4>
      <h6>
        <FormattedMessage id={Message.LANDING_PAGE_HEADING_TEXT} />
      </h6>
      <input
        type="file"
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const fileList = e.target.files;

          if (!fileList) return;
          setImage(fileList[0]);
        }}
      />

      <FormProvider {...methods}>
        <form onSubmit={submitForm}>
          <div className="form-container">
            <Controller
              name="firstName"
              control={control}
              defaultValue=""
              rules={{ required: 'First name required' }}
              render={({ onChange, value }) => (
                <TextField
                  placeholder={intl.formatMessage({
                    id: Message.LANDING_PAGE_FIRST_NAME,
                  })}
                  onChange={e => {
                    onChange(e);
                    setError(!isError);
                  }}
                  required
                  value={value}
                  helperText={errors.firstName?.message}
                />
              )}
            />
            <Controller
              name="lastName"
              control={control}
              defaultValue=""
              rules={{ required: 'Last name required' }}
              render={({ onChange, value }) => (
                <TextField
                  placeholder={intl.formatMessage({
                    id: Message.LANDING_PAGE_LAST_NAME,
                  })}
                  onChange={e => {
                    onChange(e);
                    setError(!isError);
                  }}
                  required
                  value={value}
                  helperText={errors.lastName?.message}
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{ required: 'Email required' }}
              render={({ onChange, value }) => (
                <TextField
                  placeholder={intl.formatMessage({
                    id: Message.LANDING_PAGE_FORM_EMAIL,
                  })}
                  onChange={onChange}
                  required
                  value={value}
                  helperText={errors.email?.message}
                />
              )}
            />
            <PhoneNumber name="phone" required />
            <Controller
              control={methods.control}
              name="branch"
              render={({ onChange, value, name, ref }) => (
                <Select
                  inputRef={ref}
                  onChange={val => {
                    onChange(val.target.value);
                  }}
                  name={name}
                  inputProps={{
                    style: { opacity: value ? 0 : 1 },
                    'aria-label': 'Without label',
                    placeholder: intl.formatMessage({
                      id: Message.LANDING_PAGE_LOCATION,
                    }),
                  }}
                  style={{ width: '216px', height: '52px' }}
                >
                  <MenuItem value="">Select</MenuItem>
                  {locations?.map(location => (
                    <MenuItem value={location.id} key={location.id}>{`${
                      location?.street
                    },  ${location?.city},  ${location?.state}, ${
                      isIsrael(location?.country) ? 'ישראל' : location.country
                    }`}</MenuItem>
                  ))}
                </Select>
              )}
            />
            {calendlyUrl && query?.calendly !== 'false' && rootElement && (
              <>
                <Button
                  className="calendly-button"
                  onClick={() => setOpen(true)}
                >
                  <InsertInvitationOutlinedIcon
                    style={{ position: 'absolute', left: 15 }}
                    className="calendly-icon"
                  />
                  <StyledScheduleMeeting>
                    {intl.formatMessage({
                      id: Message.LANDING_PAGE_SCHEDULE_MEETING,
                    })}
                  </StyledScheduleMeeting>
                </Button>
                <PopupModal
                  open={open}
                  url={calendlyUrl}
                  prefill={{
                    name: `${getValues('firstName')} ${getValues('lastName')}`,

                    email: getValues('email'),
                  }}
                  rootElement={rootElement}
                  onModalClose={() => {
                    setOpen(false);
                  }}
                />
              </>
            )}
          </div>

          <div className="upload-file">
            <div className="upload-btn-container">
              <div className="upload-floor-plan">
                <FormattedMessage
                  id={Message.LANDING_PAGE_UPLPAD_FLOOR_PLAN_BUTTON}
                />
              </div>
              <UploadButton
                isImageSelected={Boolean(image)}
                className="upload-floor-btn"
                onClick={() => fileInputRef.current?.click()}
              >
                {image ? <UploadDoneIcon /> : <UploadIcon />}
                <FormattedMessage
                  id={
                    image
                      ? Message.LANDING_PAGE_CHANGE_FILE_BUTTON
                      : Message.LANDING_PAGE_CHOOSE_FILE_BUTTON
                  }
                />
              </UploadButton>
            </div>
            <div className="text-container">
              <FormattedMessage id={Message.LANDING_PAGE_FILE_UPLOAD_TERMS} />
              {intl.locale !== 'he' && (
                <>
                  <br /> following formats
                </>
              )}
              <SubTitle>
                <FormattedMessage
                  id={Message.LANDING_PAGE_FILE_UPLOAD_FORMAT}
                  values={{
                    linebreak: <br />,
                  }}
                />
              </SubTitle>
            </div>
            <div className="terms-container">
              <StyledCheckbox
                checked={isTncAgreed}
                onChange={() => setIsTncAgreed(prev => !prev)}
              />
              <FormattedMessage
                id={Message.LANDING_PAGE_ACCEPT_TERMS}
                values={{
                  link: (chunks: string) => (
                    <>
                      &nbsp;
                      <StyledLink to="/app/privacy-policy">{chunks}</StyledLink>
                    </>
                  ),
                }}
              />
            </div>
            <FinishButton
              type="submit"
              disabled={
                !getValues().email ||
                !getValues().firstName ||
                !getValues().lastName ||
                !isTncAgreed ||
                !image ||
                Boolean(Object.keys(newError).length) ||
                isLoading
              }
            >
              {isLoading ? (
                <Loader />
              ) : (
                <FormattedMessage id={Message.LANDING_PAGE_FINISH_BUTTON} />
              )}
            </FinishButton>
          </div>
        </form>
      </FormProvider>
    </UserFormContainer>
  );
};

const UserFormContainer = styled.div`
  width: 100v;
  background-color: white;
  padding: 48px 0;
  text-align: center;
  .MuiFormHelperText-root {
    margin: 2px auto;
    width: 80%;
  }
  h4 {
    font-weight: 500;
    font-size: 40px;
    line-height: 44px;
    color: #282929;
    margin: 0;
  }
  h6 {
    font-weight: 400;
    font-size: 30px;
    line-height: 34px;
    margin: 0;
    color: #3e3e3e;
    margin-top: 16px;
  }
  .form-container {
    display: flex;
    margin: auto;
    margin-top: 48px;
    width: fit-content;
    .MuiOutlinedInput-root {
      border-radius: 10px;
      margin: 0 24px;
    }
    .MuiSelect-nativeInput {
      bottom: 5px;
      ::placeholder {
        opacity: 0.5;
      }
      border: none;
    }
    input {
      background: #fafcfe;
      border: 1px solid #e8eaeb;
      border-radius: 10px;
      padding: 13px 16px;
      color: #696969;
      opacity: 1;
      ::placeholder {
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #696969;
      }
    }
    .calendly-button {
      width: 216px;
      background: #fafcfe;
      border: 1px solid #babcbd;
      border-radius: 10px;
      padding: 13px 16px;
      margin: 0 24px;
      text-transform: none;
      color: #c1c2c3;
      white-space: nowrap;
      line-height: 22px;
      height: fit-content;
      .calendly-popup-btn {
        line-height: 22px;
        border: none;
        color: inherit;
        border: none;
        background: inherit;
        font-size: inherit;
      }
    }
    .calendly-icon {
      color: #696969;
      margin-right: 6px;
      position: absolute;
      ${rtl`left: 15px;`}
    }
  }
  .react-tel-input {
    margin-top: 0;
  }
  .phone-input {
    padding: 13px 0 13px 64px !important;
    height: 51px;
  }
  .flag-dropdown {
    height: 51px;
  }
  .country-list {
    width: 236px;
    text-align: left;
  }
  .flag-dropdown.open {
    height: 51px;
  }
  .phoneNumber {
    margin: 0 24px;
  }
  .upload-file {
    text-align: center;
    margin-top: 48px;
    .upload-btn-container {
      display: flex;
      justify-content: center;
      .upload-floor-plan {
        height: 48px;
        border-radius: 10px 0px 0px 10px;
        background: #fafcfe;
        border: 1px solid #e8eaeb;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #282929;
        padding: 13px 16px;
        ${({ theme }) =>
          theme.dir === 'rtl' &&
          css`
            border-radius: 0px 10px 10px 0px;
          `};
      }
      .upload-floor-btn {
        background: #fafcfe;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        border: 1px solid #fc5c42;
        border-radius: 0px 10px 10px 0px;
        color: #fc5c42;
        width: 169px;
        height: 48px;
        text-transform: none;
        svg {
          ${rtl` margin-right: 8px;`}
        }
        ${({ theme }) =>
          theme.dir === 'rtl' &&
          css`
            border-radius: 10px 0px 0px 10px;
          `};
      }
    }
    .text-container {
      margin-top: 8px;
      line-height: ${({ theme }) => (theme.dir === 'rtl' ? '18px' : '16px')};
      font-size: 14px;
      color: #3e3e3e;
    }
    .terms-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: ${({ theme }) => (theme.dir === 'rtl' ? '32px' : '35px')};
      line-height: 22px;
      font-size: 18px;
      color: #3e3e3e;
    }
  }
`;
const Image = styled.img`
  position: absolute;
  margin-top: -36px;
  rotate: 0deg;
  ${rtl`right: 0px;`}
  height: 580px;
  ${({ theme }) =>
    theme.dir === 'rtl' &&
    css`
      transform: rotateY(180deg);
    `};
`;

const StyledCheckbox = styled(Checkbox)`
  .MuiSvgIcon-root {
    font-size: 32px;
    color: #2e294e;
    height: 24px;
    width: 24px;
  }
`;

const UploadButton = styled(Button)<{ isImageSelected: boolean }>`
  ${({ isImageSelected }) =>
    isImageSelected &&
    css`
      background: #2cdd73 !important;
      color: white !important;
      border: 1px solid #2cdd73 !important;
    `}
`;

const FinishButton = styled.button`
  margin-top: 32px;
  width: 240px;
  height: 48px;
  background: #fc5c42;
  border-radius: 15px;
  border: none;
  font-size: 22px;
  line-height: 26px;
  color: #ffffff;
  cursor: pointer;
  :hover {
    background: #a50a0a;
  }
  :disabled {
    background: #d9d9d9;
    color: #ffffff;
    border: 1px solid #d9d9d9;
  }
`;

const SubTitle = styled.p`
  margin: 0;
  font-weight: ${({ theme }) => (theme.dir === 'rtl' ? 600 : 500)};
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => (theme.dir === 'rtl' ? '#5C95FF' : '#3E3E3E')};
  text-decoration: none;
  border-bottom: 1px solid #5c95ff;
`;

const StyledScheduleMeeting = styled.p`
  margin: 0;
  position: relative;
  margin-left: 26px;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
`;

const PhoneNumber = styled(PhoneField)`
  &:hover {
    .flag-dropdown,
    .form-control {
      border: 1px solid black;
    }
  }
  .MuiFormHelperText-root {
    width: 100%;
    color: rgba(0, 0, 0, 0.54);
  }
  .icon-information-circle {
    display: none;
  }
  margin: 0 24px;
`;

export default UserForm;
