import * as React from 'react';
import Skeleton from '@material-ui/core/Skeleton';
import { MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';
import SectionTitle from 'components/@client/SectionTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { SkinsBlock } from '../sharedStyle';

const Loader = () => {
  const isMobile = useMediaQuery(MOBILE_TABLET_RESOLUTION);
  return (
    <div>
      {Array.from({ length: 4 }).map((_, idx) => (
        <SkinsBlock key={idx.toString()}>
          <SectionTitle label="loading" isLoading={true} />
          <div className="flex">
            {Array.from({ length: isMobile ? 2 : 4 }).map((_item, i) => (
              <Skeleton
                key={i.toString()}
                className="skin"
                variant="rectangular"
                width="%100"
                height="%100"
              />
            ))}
          </div>
        </SkinsBlock>
      ))}
    </div>
  );
};

export default Loader;
