import * as React from 'react';
import styled from 'styled-components';
import { Link, Route, LinkProps } from 'react-router-dom';
import { ClientRouter } from 'router/routes';
import { useRouter } from 'hooks';
import { SubCategory } from 'types/common.types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Message } from 'i18n';
import { Category } from 'types/products';
import { userLogData } from 'mockData/userLogData';
import { LogsAPI } from 'api/methods';
import ProductsCategoryPage from './components/ProductCategory';
import SubCategoryPageComponent from './components/SubCategoryPageComponent';
import DesktopHomePage from './components/DesktopHomePage';

interface Props {
  categoryOptions: Category[] | [];
  getFilteredCategories: (catName: string) => Array<SubCategory> | undefined;
  getSubOrCategoryObject: (
    catName: string,
    isSubCategory?: Boolean
  ) => Category | SubCategory | undefined;
}

const DesktopProducts: React.FC<Props> = ({
  categoryOptions,
  getFilteredCategories,
  getSubOrCategoryObject,
}) => {
  const { query } = useRouter<{
    catName: string;
    subCatName: string;
    productId: string;
  }>();
  const intl = useIntl();

  const links: LinkProps[] = [];

  const categories = {
    to: ClientRouter.PRODUCTS,
    children: <FormattedMessage id={Message.CATEGORIES} />,
    onClick: () =>
      LogsAPI.postUserLogs({
        name: userLogData.eventName.backToCategory,
        area: userLogData.eventArea.catalog,
        section: userLogData.eventSection.breadcrumbs,
        path: userLogData.eventPath.appProductsSubCategoryNamePath,
      }),
  };
  links.push(categories);

  if (query.catName) {
    const cat = {
      to: ClientRouter.PRODUCT_CATEGORY.replace(':catName', query.catName),
      children: (
        <>
          {(intl.locale === 'he'
            ? getSubOrCategoryObject(query.catName)?.alt_name
            : getSubOrCategoryObject(query.catName)?.name) || query.catName}
        </>
      ),
      onClick: () =>
        LogsAPI.postUserLogs({
          name: userLogData.eventName.backToSubcategory,
          area: userLogData.eventArea.catalog,
          section: userLogData.eventSection.breadcrumbs,
          path: userLogData.eventPath.appProductSubCategoryNameProductIdPath,
        }),
    };
    links.push(cat);
  }

  if (query.subCatName) {
    const subCat = {
      to: ClientRouter.PRODUCT_SUB_CATEGORY.replace(
        ':catName',
        query.catName
      ).replace(':subCatName', query.subCatName),
      children: (
        <>
          {(intl.locale === 'he'
            ? getSubOrCategoryObject(query.subCatName, true)?.alt_name
            : getSubOrCategoryObject(query.subCatName, true)?.name) ||
            query.subCatName}
        </>
      ),
    };
    links.push(subCat);
  }

  return (
    <Container>
      {/* <Header>
        {categoryOptions &&
          sortBy(categoryOptions, 'list_number').map((category: Category) => (
            <CategoryText key={category.name}>
              <StyledLink
                to={ClientRouter.PRODUCT_CATEGORY.replace(
                  ':catName',
                  category.name
                )}
              >
                {category.name}
              </StyledLink>
            </CategoryText>
          ))}
      </Header> */}
      <Route
        exact={true}
        path={ClientRouter.PRODUCT_SUB_CATEGORY}
        render={props => (
          <SubCategoryPageComponent
            categoryTitle={
              (intl.locale === 'he'
                ? getSubOrCategoryObject(query.catName)?.alt_name
                : getSubOrCategoryObject(query.catName)?.name) || query.catName
            }
            subCategory={
              getFilteredCategories(query.catName)?.filter(
                e => e.name === query.subCatName
              )[0]
            }
            subCategoryTitle={
              (intl.locale === 'he'
                ? getSubOrCategoryObject(query.subCatName, true)?.alt_name
                : getSubOrCategoryObject(query.subCatName, true)?.name) ||
              query.subCatName
            }
            links={links}
            {...props}
          />
        )}
      />
      <Route
        exact={true}
        path={ClientRouter.PRODUCT_CATEGORY}
        render={props => (
          <ProductsCategoryPage
            title={
              (intl.locale === 'he'
                ? getSubOrCategoryObject(query.catName)?.alt_name
                : getSubOrCategoryObject(query.catName)?.name) || query.catName
            }
            subCategory={getFilteredCategories(query.catName)}
            links={links}
            {...props}
          />
        )}
      />
      {categoryOptions && (
        <Route
          exact={true}
          path={ClientRouter.PRODUCTS}
          render={props => (
            <DesktopHomePage
              categoryOptions={categoryOptions}
              links={links}
              {...props}
            />
          )}
        />
      )}
    </Container>
  );
};

export default DesktopProducts;

const Container = styled.section`
  width: 100%;
  padding: 0;
`;

export const CategoryText = styled.div`
  padding-top: 10px;
  min-width: 150px;
  text-align: center;
  font-family: ${({ theme }) =>
    theme.dir === 'rtl' ? ' Assistant' : 'inherit'};
`;

export const Header = styled.header`
  ${({ theme }) => theme.utils.scrollbar};
  font-family: ${({ theme }) =>
    theme.dir === 'rtl' ? ' Assistant' : 'inherit'};
  width: 100%;
  height: 71px;
  padding-top: 15px;
  background: #ffffff;
  color: #3c3c3c;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  border: 2px solid rgba(222, 222, 222, 0.36);
  box-sizing: border-box;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.04);
  text-transform: capitalize;
  display: flex;
  overflow: auto;
`;

export const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`;
