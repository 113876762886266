import * as React from 'react';
import { OnboardingAction, useOnboarding } from 'context/onboardingContext';
import { useRouter } from 'hooks';
import { ClientRouter } from 'router/routes';
import { size } from 'utils/lodash.utils';

const useConfirmPlan = (
  prev?: (startOver?: boolean) => void,
  next?: VoidFunction
) => {
  const [{ floorPlanFiles, selectedFloor, image }, dispatch] = useOnboarding();
  const { history } = useRouter();

  const goBack = React.useCallback(() => {
    if (prev) {
      dispatch({ type: OnboardingAction.SET_SELECTED_FLOOR, payload: null });
      return prev(size(floorPlanFiles) > 1 ? undefined : true);
    }
    return history.push(
      size(floorPlanFiles) > 1
        ? ClientRouter.SELECT_FLOOR
        : ClientRouter.UPLOAD_PLAN
    );
  }, [dispatch, floorPlanFiles, history, prev]);

  const onClick = React.useCallback(() => {
    if (next) return next();
    return history.push(ClientRouter.PLAN_CONFIRMED);
  }, [history, next]);

  return { selectedFloor, goBack, onClick, image };
};

export default useConfirmPlan;
