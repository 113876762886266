import * as React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  divider: {
    justifyContent: 'center',
    marginTop: 44,
    marginHorizontal: 44,
    marginBottom: 26,
    borderBottomColor: '#E8E8E8',
    borderBottomWidth: 1,
  },
});

const Divider: React.FC<{ style?: object; fixed?: true }> = ({
  style,
  fixed,
}) => {
  return <View fixed={fixed} style={{ ...styles.divider, ...style }} />;
};

export default Divider;
