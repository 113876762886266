import * as React from 'react';
import { Page, View, StyleSheet } from '@react-pdf/renderer';
import theme from 'styles/theme';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: theme.colors.bodyGray,
    position: 'relative',
    minHeight: '100vh',
    display: 'flex',
    // paddingBottom: '50px',
  },
  content: {
    flexWrap: 'wrap',
    minHeight: '100vh',
    paddingBottom: '50px',
  },
});

const PageContent: React.FC = ({ children }) => {
  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.content}>{children}</View>
    </Page>
  );
};

export default PageContent;
