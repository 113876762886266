import * as React from 'react';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import { useSession, useNotifications } from 'hooks';
import { useMediaQuery } from '@material-ui/core';
import * as _ from 'utils/lodash.utils';
import { SupportedLanguage } from 'types/common.types';
import Alert from './Alert';

const Notifications = () => {
  const { notifications, closeNotification } = useNotifications();
  const { locale } = useSession();
  const isSmallMobile = useMediaQuery('(max-width: 480px)');

  const handleClose = React.useCallback(
    (key: string, reason?: string) => {
      if (reason === 'clickaway') return;
      closeNotification(key);
    },
    [closeNotification]
  );

  return (
    <>
      {_.map(
        notifications,
        notification =>
          notification && (
            <Snackbar
              key={notification.key}
              TransitionComponent={Slide}
              open
              anchorOrigin={{
                horizontal:
                  locale === SupportedLanguage.HEBREW ? 'right' : 'left',
                vertical: 'bottom',
              }}
              dir="ltr"
              autoHideDuration={6000}
              onClose={(e, reason) => handleClose(notification.key, reason)}
              style={{ marginBottom: isSmallMobile ? 100 : 0 }}
            >
              <Alert
                onClose={() => handleClose(notification.key)}
                severity={notification.severity}
              >
                {notification.message}
              </Alert>
            </Snackbar>
          )
      )}
    </>
  );
};

export default Notifications;
