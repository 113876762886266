import * as React from 'react';
import { oliveGreen } from 'styles/sharedStyle';
import ShowMoreButton from 'components/@client/Button/ShowMoreButton';
import { useGetQuoteComponents } from 'hooks';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import { size, isEmpty } from 'utils/lodash.utils';
import styled from 'styled-components';
import { LogsAPI } from 'api/methods';
import { userLogData } from 'mockData/userLogData';
import Controller from './Controller';
import List from './List';

interface Props {
  propertyId: string;
}

const ProductsSection: React.FC<Props> = ({ propertyId }) => {
  const [selectedRoom, setSelectedRoom] = React.useState<number | null>(null);
  const {
    quoteComponents,
    productsCount,
    isLoading,
    getProducts,
    getMoreProducts,
    isGetMoreRef,
  } = useGetQuoteComponents(propertyId, selectedRoom);
  const isShowMoreButton =
    !isLoading &&
    !isEmpty(quoteComponents) &&
    size(quoteComponents) !== productsCount;

  return (
    <>
      <Controller
        productsCount={productsCount}
        selectedRoom={selectedRoom}
        setSelectedRoom={setSelectedRoom}
        getProducts={getProducts}
        propertyId={propertyId}
      />

      <List
        propertyId={propertyId}
        quoteComponents={quoteComponents}
        isLoading={!isGetMoreRef.current && isLoading}
        selectedRoom={selectedRoom}
      />

      {/* Skeleton list for new added products */}
      {isGetMoreRef.current && isLoading && <List isLoading={isLoading} />}

      {isShowMoreButton && (
        <Container>
          <ShowMoreButton
            className="show-more-button"
            fullWidth
            onClick={() => {
              LogsAPI.postUserLogs({
                area: userLogData.eventArea.myProperties,
                section: userLogData.eventSection.propertyPage,
                name: userLogData.eventName.showMore,
                path: userLogData.eventPath.appPropertiesIdPath,
                metadata: {
                  property_id: propertyId,
                  room_id: selectedRoom,
                },
              });
              getMoreProducts(selectedRoom);
            }}
          >
            <FormattedMessage id={Message.PROPERTY_BUTTON_SHOW_MORE} />
          </ShowMoreButton>
        </Container>
      )}
    </>
  );
};

export default ProductsSection;

const Container = styled.div`
  padding: 0 15%;
  ${({ theme }) => theme.utils.centerFlex}
  margin-top: 28px;
  && .show-more-button {
    background-color: ${oliveGreen};
    border-radius: 12px;
    font-weight: 400;

    @media (min-width: 1150px) {
      max-width: 252px;
      height: 48px;
      font-size: 22px;
      line-height: 26px;
      padding: 11px 64px;
    }

    &:hover {
      background-color: #2e3f31;
    }
  }
`;
