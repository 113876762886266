import PropertiesType from 'store/constants/properties-management/PropertiesManagement.constants';
import * as Payload from 'store/types/properties-management/ArchivedProperties.types';

export const getArchivedPropertiesTasksDataRequest = (
  payload: Payload.GetArchivedPropertiesTasksDataRequestPayload
) => ({
  type: PropertiesType.GET_ARCHIVED_PROPERTIES_DATA_REQUEST,
  payload,
});

export const getArchivedPropertiesTasksDataFulfilled = (
  payload: Payload.GetArchivedPropertiesTasksFulfilledPayload
) => ({
  type: PropertiesType.GET_ARCHIVED_PROPERTIES_DATA_FULFILLED,
  payload,
});

export const getArchivedPropertiesTasksDataRejected = (
  payload: Payload.GetArchivedPropertiesTasksRejectedPayload
) => ({
  type: PropertiesType.GET_ARCHIVED_PROPERTIES_DATA_REJECTED,
  payload,
});

export type PropertiesTasksAction = ReturnType<
  | typeof getArchivedPropertiesTasksDataRequest
  | typeof getArchivedPropertiesTasksDataFulfilled
  | typeof getArchivedPropertiesTasksDataRejected
>;
