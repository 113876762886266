import * as Response from 'api/responses';
import * as Transform from 'api/transform';
import { PaginatedSkin } from 'types/properties';
import useFetch from './useFetch';

const useSkins = (is_public = true) => {
  const { data: skins, isLoading, callFetch } = useFetch<
    PaginatedSkin,
    Response.Skins
  >({
    initialUrl: '/api/skins/',
    config: { params: { public: is_public } },
    transform: Transform.transformSkinsPaginatedWithCount,
  });
  return {
    skins: skins?.results ?? [],
    isLoading,
    count: skins?.count,
    callFetch,
  };
};

export default useSkins;
