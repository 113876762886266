import Breadcrumbs from 'components/@client/Breadcrumbs/Breadcrumbs';
import styled, { css } from 'styled-components';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { FormattedMessage } from 'react-intl';
import * as React from 'react';
import { ClientRouter } from 'router/routes';
import Icon from 'components/Icon';
import rtl from 'styled-components-rtl';
import { Message } from 'i18n';
import PhotoGrid from 'assets/@client/skins';
import { LogsAPI } from 'api/methods';
import { ArrowBase } from 'components/@client/SlideshowModal/Arrows';
import { userLogData } from 'mockData/userLogData';
import SectionTitle from 'components/@client/SectionTitle/SectionTitle';
import Slider from 'react-slick';
import ProductPageModal from 'pages/appClient/productPage/component/ProductPageModal';
import {
  useAuthentication,
  useFetch,
  useNotifications,
  useRecentProperty,
  useRouter,
} from 'hooks';
import { METHODS } from 'api/client';
import {
  error,
  info,
  success,
  TABLET_RESOLUTION,
  warning,
} from 'styles/sharedStyle';
import Loader from 'components/Loader';
import SkinApplyButton from './Components/SkinApplyButton';
import useSkin from './useSkin';
import SkinCard from '../components/SkinCard';
import NewSkinMobile, { getPositionWiseImage } from './NewSkinMobile';

const Skin: React.FC = () => {
  const { history } = useRouter();
  const { token } = useAuthentication();
  const {
    skin,
    hasError,
    userSkins,
    products,
    isLoading: isSkinLoading,
  } = useSkin();
  const [productId, setProductId] = React.useState<string>('');
  const [prevIndex, setPrevIndex] = React.useState<number>(0);
  const [prevIndexSkin, setPrevIndexSkin] = React.useState<number>(0);

  const getTitleColor = () =>
    [success, warning, error, info, '#87b9e7'][Math.floor(Math.random() * 5)];
  const mainColor = React.useRef(getTitleColor());
  const { showNotification } = useNotifications();
  const { recentProperty } = useRecentProperty();
  const isMobile = useMediaQuery(TABLET_RESOLUTION);

  const settings = {
    dots: false,
    infinite: false,
    afterChange: (index: number) => {
      setPrevIndex(index);
    },
  };

  const settingsSkin = {
    dots: false,
    infinite: false,
    afterChange: (index: number) => {
      setPrevIndexSkin(index);
    },
  };

  const onApplyFreeSkinSuccess = React.useCallback(() => {
    if (recentProperty && skin) {
      history.push(
        `${ClientRouter.TOUR.replace(
          ':propertyId',
          recentProperty.id
        )}?skinId=${skin.id}`
      );
    }
  }, [history, recentProperty, skin]);

  const { callFetch, isLoading: isApplyingSkin } = useFetch({
    initialUrl: '/api/skins/',
    skipOnStart: true,
    config: { method: METHODS.PATCH },
    onSuccess: onApplyFreeSkinSuccess,
    onFailure: React.useCallback(
      (message: string) => {
        showNotification({
          severity: 'error',
          message,
          key: message,
        });
      },
      [showNotification]
    ),
  });

  const currentValue = React.useMemo(() => {
    return isMobile ? 1.2 : 4;
  }, [isMobile]);

  const currentValueSkin = React.useMemo(() => {
    return isMobile ? 1.2 : 5;
  }, [isMobile]);

  const NextArrow = (props: {
    onClick?: React.MouseEventHandler<HTMLDivElement>;
  }) => {
    const { onClick } = props;
    return isMobile ? (
      <></>
    ) : (
      <ArrowContainer
        role="button"
        isOpen={true}
        onClick={onClick}
        prev={false}
        prevIndex={Math.floor(prevIndex)}
        mediaLength={products?.length}
        currentValue={currentValue}
      >
        <ArrowIcon className="next" />
      </ArrowContainer>
    );
  };

  const NextArrowSkin = (props: {
    onClick?: React.MouseEventHandler<HTMLDivElement>;
  }) => {
    const { onClick } = props;
    return isMobile ? (
      <></>
    ) : (
      <ArrowContainer
        role="button"
        isOpen={true}
        onClick={onClick}
        prev={false}
        prevIndex={Math.floor(prevIndexSkin)}
        mediaLength={userSkins?.length}
        currentValue={currentValueSkin}
      >
        <ArrowIcon className="next" />
      </ArrowContainer>
    );
  };

  const PrevArrow = (props: {
    onClick?: React.MouseEventHandler<HTMLDivElement>;
  }) => {
    const { onClick } = props;
    return isMobile ? (
      <></>
    ) : (
      <ArrowContainer
        role="button"
        isOpen={true}
        onClick={onClick}
        prev={true}
        prevIndex={Math.floor(prevIndex)}
        mediaLength={products?.length}
        currentValue={currentValue}
      >
        <ArrowIcon className="prev" />
      </ArrowContainer>
    );
  };

  const PrevArrowSkin = (props: {
    onClick?: React.MouseEventHandler<HTMLDivElement>;
  }) => {
    const { onClick } = props;
    return isMobile ? (
      <></>
    ) : (
      <ArrowContainer
        role="button"
        isOpen={true}
        onClick={onClick}
        prev={true}
        prevIndex={Math.floor(prevIndexSkin)}
        mediaLength={userSkins?.length}
        currentValue={currentValueSkin}
      >
        <ArrowIcon className="prev" />
      </ArrowContainer>
    );
  };

  const handlePurchaseSkinClick = React.useCallback(() => {
    LogsAPI.postUserLogs({
      name: userLogData.eventName.applySkin,
      area: userLogData.eventArea.skins,
      section: userLogData.eventSection.skins,
      path: userLogData.eventPath.appSkinsCheckoutId,
      metadata: { propertyId: recentProperty?.id },
    });
    if (!token) {
      history.push(ClientRouter.ONBOARDING);
    } else if (skin?.price === 0)
      callFetch({
        url: `api/skins/${skin?.id}/purchase_free_skin/`,
      });
    else
      history.push(
        ClientRouter.SKIN_CHECKOUT.replace(':id', skin?.id?.toString() ?? '')
      );
  }, [callFetch, history, recentProperty?.id, skin?.id, skin?.price, token]);

  const buttonProps = React.useMemo(
    () => ({
      free: skin?.price === 0,
      onClick: handlePurchaseSkinClick,
      isLocked: Boolean(token) && skin?.isLocked,
      isLoading: isApplyingSkin,
    }),
    [
      handlePurchaseSkinClick,
      isApplyingSkin,
      skin?.isLocked,
      skin?.price,
      token,
    ]
  );

  return (
    <>
      {isMobile ? (
        <NewSkinMobile />
      ) : (
        <MainContainer>
          {isSkinLoading ? (
            <Loader fullHeight />
          ) : (
            <>
              <Breadcrumbs
                links={[
                  {
                    to: ClientRouter.SKINS,
                    children: <FormattedMessage id={Message.SKINS_TITLE} />,
                  },
                  {
                    to: ClientRouter.IDEA_PAGE,
                    children: (
                      <FormattedMessage
                        id={
                          skin?.name ??
                          (hasError ? Message.SKIN_NOT_FOUND : Message.LOADING)
                        }
                      />
                    ),
                  },
                ]}
              />
              <Container>
                <MobileImageContainer>
                  <Title>
                    <FormattedMessage
                      id={Message.SINGLE_SKINS_HEADER_TITLE}
                      values={{
                        value: (
                          <span className="title-text">{skin?.user?.name}</span>
                        ),
                        linebreak: <br />,
                      }}
                    />
                  </Title>
                  <SubTitle>{skin?.user.skinCreator.description}</SubTitle>
                </MobileImageContainer>
                <Image>
                  <FirstColumn>
                    <FirstColumnImage>
                      <ImageParent
                        src={skin?.user?.skinCreator?.logo ?? PhotoGrid.Image_2}
                        alt="image_1"
                      />
                    </FirstColumnImage>
                  </FirstColumn>
                  <SecondColumn>
                    <SecondColumnImage>
                      <ImageParent
                        src={getPositionWiseImage(1, skin) ?? PhotoGrid.Image_2}
                        alt="image_1"
                      />
                    </SecondColumnImage>
                    <SecondColumnImage>
                      <ImageParent
                        src={getPositionWiseImage(2, skin) ?? PhotoGrid.Image_2}
                        alt="image_1"
                      />
                    </SecondColumnImage>
                  </SecondColumn>
                  <ThirdColumn>
                    <ThirdColumnImage>
                      <ImageParent
                        src={getPositionWiseImage(3, skin) ?? PhotoGrid.Image_2}
                        alt="image_1"
                      />
                    </ThirdColumnImage>
                    <ThirdColumnImage>
                      <ImageParent
                        src={getPositionWiseImage(4, skin) ?? PhotoGrid.Image_2}
                        alt="image_1"
                      />
                    </ThirdColumnImage>
                    <ThirdColumnImage>
                      <ImageParent
                        src={getPositionWiseImage(5, skin) ?? PhotoGrid.Image_2}
                        alt="image_1"
                      />
                    </ThirdColumnImage>
                  </ThirdColumn>
                  <FourthColumn>
                    <FourthColumnImage>
                      <ImageParent
                        src={getPositionWiseImage(6, skin) ?? PhotoGrid.Image_2}
                        alt="image_1"
                      />
                    </FourthColumnImage>
                    <FourthColumnImage>
                      <ImageParent
                        src={getPositionWiseImage(7, skin) ?? PhotoGrid.Image_2}
                        alt="image_1"
                      />
                    </FourthColumnImage>
                  </FourthColumn>
                </Image>
              </Container>
              <AboutContent>
                <SectionTitle
                  label={
                    <FormattedMessage id={Message.SINGLE_SKINS_ABOUT_TITLE} />
                  }
                  center
                  borderColor="linear-gradient(276.97deg, #AE8265 3.19%, #BE9A6D 9.97%, #DCC77C 23.52%, #EFE486 34.17%, #F7EF8A 40.95%, #F5EB86 53.53%, #EFE17D 63.21%, #E6D16C 71.93%, #DAB955 80.64%, #D4AE4A 84.51%, #C59D54 91.29%, #AE8265 100%);"
                />
                <ContainerParent>
                  <LeftContainer>
                    <AboutSubTitle>
                      <FormattedMessage
                        id={Message.SINGLE_SKINS_ABOUT_SUB_TITLE}
                        values={{
                          linebreak: <br />,
                        }}
                      />
                    </AboutSubTitle>
                    <SkinNameParent>
                      <FormattedMessage
                        id={Message.SINGLE_SKINS_SKIN_NAME}
                        values={{
                          name: <span className="skin-name">{skin?.name}</span>,
                        }}
                      />
                    </SkinNameParent>
                    <PriceParent>
                      <FormattedMessage
                        id={Message.SINGLE_SKINS_ABOUT_PRICE}
                        values={{
                          price: (
                            <span className="skin-name">
                              {skin?.price === 0 ? (
                                <FormattedMessage
                                  id={Message.SINGLE_SKINS_FREE}
                                />
                              ) : (
                                skin?.price
                              )}
                            </span>
                          ),
                        }}
                      />
                    </PriceParent>
                    <SkinApplyButton {...buttonProps} />
                  </LeftContainer>
                  <RightContainer>
                    <SkinProductsTitle>
                      <FormattedMessage
                        id={Message.SINGLE_SKINS_PRODUCTS_SKIN}
                      />
                    </SkinProductsTitle>
                    {!skin?.isLocked || skin?.price === 0 ? (
                      <SliderParent>
                        <Slider
                          {...settings}
                          slidesToShow={currentValue}
                          slidesToScroll={1}
                          swipeToSlide={true}
                          nextArrow={<NextArrow />}
                          prevArrow={<PrevArrow />}
                        >
                          {products.map(product => {
                            return (
                              <ProductContainer
                                key={product.id}
                                onClick={() => {
                                  setProductId(product.id.toString());
                                }}
                              >
                                <ImageContainer
                                  alt="product-img"
                                  src={product.file}
                                />
                              </ProductContainer>
                            );
                          })}
                        </Slider>
                      </SliderParent>
                    ) : (
                      <SkinLockParent>
                        <SkinLockImage
                          src={PhotoGrid.SkinLock}
                          alt="skin-lock"
                        />
                        <SkinLockText>
                          <FormattedMessage
                            id={Message.SKIN_LOCK_TEXT}
                            values={{ linebreak: '' }}
                          />
                        </SkinLockText>
                      </SkinLockParent>
                    )}
                  </RightContainer>
                </ContainerParent>
              </AboutContent>
              <MoreContent>
                <SectionTitle
                  label={
                    <FormattedMessage id={Message.SKINS_YOU_MAY_ALSO_LIKE} />
                  }
                  center
                  borderColor="linear-gradient(276.97deg, #AE8265 3.19%, #BE9A6D 9.97%, #DCC77C 23.52%, #EFE486 34.17%, #F7EF8A 40.95%, #F5EB86 53.53%, #EFE17D 63.21%, #E6D16C 71.93%, #DAB955 80.64%, #D4AE4A 84.51%, #C59D54 91.29%, #AE8265 100%);"
                />
                <SliderParentSkin>
                  <Slider
                    {...settingsSkin}
                    slidesToShow={currentValueSkin}
                    slidesToScroll={1}
                    swipeToSlide={true}
                    nextArrow={<NextArrowSkin />}
                    prevArrow={<PrevArrowSkin />}
                  >
                    {userSkins?.length &&
                      userSkins.map(product => {
                        return (
                          <SkinCard
                            key={product.name}
                            skin={product}
                            withSkinCategory
                            color={mainColor.current}
                          />
                        );
                      })}
                  </Slider>
                </SliderParentSkin>
              </MoreContent>
              <ProductPageModal
                product={productId}
                defaultOpen={Boolean(productId)}
                hideEveryButton={true}
              />
            </>
          )}
        </MainContainer>
      )}
    </>
  );
};

export default Skin;

const MainContainer = styled.div`
  margin-top: 64px;
  height: 100%;
  .MuiBreadcrumbs-root {
    padding: 24px 20px 24px 80px;
  }
  .section-title-label {
    position: relative;
    ${rtl`
  padding: 0 16px 0 0;
    `}
    font-weight: ${({ theme }) => (theme.dir === 'rtl' ? 600 : 500)};
  }
  .section-title-wrapper {
    margin: 0 !important;
    padding: 0 !important;
  }
  .skin-name {
    color: #fc5c42;
    font-family: ${({ theme }) =>
      theme.dir === 'rtl' ? 'Assistant' : 'Roboto'};
    font-weight: ${({ theme }) => (theme.dir === 'rtl' ? 600 : 500)};
  }
  .skin {
    height: 388px !important;
    width: 304px;
    min-width: 304px !important;
    border-radius: 15px !important;
  }
  .buy-button {
    width: 198px;
    height: 48px;
    background: #fc5c42;
    border-radius: 15px;
    margin-top: 24px;
    border: 1px solid #fc5c42;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    color: rgba(255, 255, 255, 0.9);
    cursor: pointer;
  }
`;

const Container = styled.section`
  width: 100%;
  display: flex;
  gap: 84px;
  height: 523px;
  color: #424242;
  position: relative;
  text-align: center;
  background: linear-gradient(
    94.14deg,
    #f4f4f4 0.16%,
    #fafafa 51.85%,
    #eef1f3 100%
  );
  @media ${TABLET_RESOLUTION} {
    height: auto;
    display: block;
    background: #ffffff;
  }
  &::after {
    content: '';
    position: absolute;
    height: 30px;
    width: 100%;
    background-image: linear-gradient(
      to bottom,
      rgba(255, 0, 0, 0),
      rgb(255 255 255)
    );
    z-index: 9;
    bottom: 0;
  }
  &::before {
    content: '';
    position: absolute;
    height: 30px;
    width: 100%;
    background-image: linear-gradient(
      to top,
      rgba(255, 0, 0, 0),
      rgb(255 255 255)
    );
    z-index: 9;
  }
`;

const MobileImageContainer = styled.div`
  z-index: 1;
  width: 33.1%;
  ${rtl`
  margin-left: 48px;
    `}
  margin-top:87px;
  @media ${TABLET_RESOLUTION} {
    width: 100%;
    padding: 0;
    margin-left: 0;
    padding-left: 0;
  }
`;

const Image = styled.div`
  position: relative;
  z-index: 1;
  width: 60%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 24px;
  overflow: hidden;
  @media ${TABLET_RESOLUTION} {
    width: 100%;
    &:after {
      content: '';
      border: 2px solid #fc5c42;
      display: flex;
      margin: 0 60px;
      margin-top: 16px;
    }
  }
  img {
    width: 100%;
    height: 100%;
  }
  &::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 30px;
    z-index: 9;
    ${rtl`
    background-image: linear-gradient(
      to right,
      rgba(255, 0, 0, 0),
      rgb(255 255 255)
    );
      right:0;
    `}
  }
`;

const Title = styled.span`
  display: flex;
  font-weight: 600;
  font-size: 48px;
  line-height: 52px;
  color: #282929;
  font-family: ${({ theme }) => theme.dir !== 'rtl' && 'Roboto condensed'};
  border-bottom: 2px solid #fc5c42;
  width: fit-content;
  padding-bottom: 16px;
  @media (min-width: 1200px) and (max-width: 1600px) {
    ${({ theme }) =>
      theme.dir === 'rtl' &&
      css`
        font-size: 30px;
      `};
    font-size: 28px;
    line-height: 22px;
  }

  @media ${TABLET_RESOLUTION} {
    display: block;
    font-size: 34px;
    line-height: 40px;
    text-align: center;
    color: #282929;
    margin: 15px 10px 0 10px;
    &:after {
      content: '';
      border: 2px solid #fc5c42;
      display: flex;
      margin: 0 60px;
      margin-top: 16px;
    }
  }
  .title-text {
    ${rtl`
  padding: 0 10px 0 10px;
    `} @media ${TABLET_RESOLUTION} {
      padding: 0;
    }
    color: #fc5c42;
  }
`;

const SubTitle = styled.span`
  display: block;
  font-family: ${({ theme }) => theme.dir !== 'rtl' && 'Roboto Condensed'};
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  margin-top: 16px;
  ${rtl`
  text-align: left;
    `}

  color: #282929;
  .title-text {
    pointer-events: none;
    ${rtl`
    padding: 0px 10px 0 10px;
    `}
    color: #fc5c42;
    font-weight: 500;
    text-decoration: auto;
  }
  @media (min-width: 1200px) and (max-width: 1600px) {
    font-size: 18px;
    line-height: 22px;
  }
  @media ${TABLET_RESOLUTION} {
    display: block;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #282929;
    margin: 15px 24px;
    .title-text {
      display: block;
    }
  }
`;

const AboutContent = styled.div`
  padding: 32px 160px 0 160px;
  @media (min-width: 1200px) and (max-width: 1600px) {
    padding: 32px 80px 0 80px;
  }
`;

const AboutSubTitle = styled.div`
  font-size: 18px;
  line-height: 22px;
  color: #3e3e3e;
  margin-top: 24px;
  font-family: ${({ theme }) => theme.dir !== 'rtl' && 'Roboto Condensed'};
`;

const SkinNameParent = styled.div`
  font-weight: ${({ theme }) => (theme.dir === 'rtl' ? 600 : 500)};
  font-size: 22px;
  line-height: 26px;
  margin-top: 16px;
`;

const PriceParent = styled.div`
  font-weight: ${({ theme }) => (theme.dir === 'rtl' ? '600 !important' : 500)};
  margin-top: 8px;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
`;

const MoreContent = styled.div`
  padding: 32px 160px;
  @media (min-width: 1200px) and (max-width: 1600px) {
    padding: 32px 80px 0 80px;
  }
`;

const LeftContainer = styled.div`
  width: 527px;
`;

const RightContainer = styled.div`
  width: 848px;
  margin-top: 24px;
`;

const ContainerParent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SkinProductsTitle = styled.div`
  font-weight: ${({ theme }) => (theme.dir === 'rtl' ? 600 : 500)};
  font-size: 20px;
  line-height: 24px;
  color: #282929;
  font-weight: ${({ theme }) => theme.dir !== 'rtl' && 'Roboto Condensed'};
`;

const ArrowContainer = styled(ArrowBase)<{
  prev: boolean;
  prevIndex: number;
  mediaLength?: number;
  currentValue: number;
}>`
  position: absolute;
  top: -65px;
  ${({ theme, prev }) =>
    theme.dir === 'rtl'
      ? css`
          left: ${prev ? '58' : '0'}px;
          transform: rotate(${prev ? 0 : 180}deg);
        `
      : css`
          right: ${prev ? '62' : '0'}px;
          transform: rotate(${prev ? 180 : 0}deg);
        `}

  & .prev {
    fill: ${({ prevIndex }) => (prevIndex === 0 ? '#D9D9D9' : '#292929E5')};
  }
  & .next {
    fill: ${({ prevIndex, mediaLength, currentValue }) =>
      prevIndex + currentValue === mediaLength ? '#D9D9D9' : '#292929E5'};
  }
`;

const ArrowIcon = styled(Icon).attrs({
  name: 'nav-arrow-right',
})<{ isOpen: boolean }>`
  position: relative;
  left: ${({ isOpen }) => (isOpen ? -4 : 4)}px;
  ${({ theme, isOpen }) => theme.utils.rotateIcon({ isOpen })}
`;

const ProductContainer = styled.div`
  height: 250px;
  width: 200px !important;
  cursor: pointer;
`;

const ImageContainer = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

const SliderParent = styled.div`
  margin-top: 40px;
  .slick-slide {
    max-width: 246px !important;
    width: 200px !important;
    margin-right: 16px;
  }
  .slick-track {
    width: 100% !important;
    display: flex;
  }
  & .slick-list {
    @media (max-width: 768px) {
      margin: 0 0px;
    }
    @media (min-width: 769px) and (max-width: 1030px) {
      margin: 0 50px;
    }
    @media (min-width: 600px) and (max-width: 768px) {
      margin: 0 42px;
    }
  }
`;

const SkinLockParent = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 101px;
`;

const SkinLockImage = styled.img``;

const SkinLockText = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #282929;
  font-weight: ${({ theme }) => theme.dir !== 'rtl' && 'Roboto Condensed'};
  margin-top: 16px;
`;

const SliderParentSkin = styled.div`
  margin-top: 96px;
  margin-bottom: 24px;
  .slick-slide {
    max-width: 304px !important;
    width: 304px !important;
  }
  .slick-track {
    width: 100% !important;
    display: flex;
    gap: 16px;
  }
  & .slick-list {
    @media (max-width: 768px) {
      margin: 0 0px;
    }
    @media (min-width: 769px) and (max-width: 1030px) {
      margin: 0 50px;
    }
    @media (min-width: 600px) and (max-width: 768px) {
      margin: 0 42px;
    }
  }
`;

const FirstColumn = styled.div``;
const SecondColumn = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;
`;

const ThirdColumn = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;
`;

const FourthColumn = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;
`;

const ImageParent = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 10px;
`;

const FirstColumnImage = styled.div`
  height: 270px;
  width: 370px;
`;

const SecondColumnImage = styled.div`
  height: 220px;
  width: 270px;
`;

const ThirdColumnImage = styled.div`
  height: 180px;
  width: 220px;
`;

const FourthColumnImage = styled.div`
  height: 220px;
  width: 270px;
`;
