import * as React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import Autocomplete from '@material-ui/core/Autocomplete';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';
import { CustomLabel } from 'components/forms/FormLabel';
import Tooltip from 'components/Tooltip';
import Chip from 'components/Chip';
import { isEmpty } from 'utils/lodash.utils';
import { summarizeText } from 'utils/string.utils';
import ErrorMessage from 'components/forms/FormErrorMessage';

interface Props {
  disabled?: boolean;
  name: string;
  maxTagLimit?: number;
  required?: boolean;
  defaultValue?: string[];
  label?: string | React.ReactNode;
  tagsValue?: (data: string[]) => void;
}

const TagsInput: React.FC<Props> = ({
  disabled,
  name,
  maxTagLimit = 10,
  required = true,
  defaultValue = [],
  label,
  tagsValue,
}) => {
  const { setValue, control } = useFormContext();
  useController({ control, name, defaultValue: [] });
  const [tags, setTags] = React.useState<string[]>(defaultValue);

  const onChange = React.useCallback(
    (_e: React.SyntheticEvent<Element, Event>, values: string[]) =>
      values.length <= maxTagLimit && setTags([...values]),
    [maxTagLimit]
  );

  const onDelete = React.useCallback(
    (value: string) => setTags(prev => prev.filter(val => val !== value)),
    []
  );

  React.useEffect(() => {
    setValue(name, [...tags], { shouldDirty: true, shouldValidate: true });
    if (tagsValue) {
      tagsValue([...tags]);
    }
  }, [name, setValue, tags, tagsValue]);

  return (
    <Container>
      <LabelWrapper>
        <CustomLabel shrink htmlFor={name} required={required}>
          {label ?? 'More Tags'}
        </CustomLabel>
        <Counter>
          {tags.length}/{maxTagLimit}
        </Counter>
      </LabelWrapper>
      <Autocomplete
        disabled={disabled}
        classes={{
          input: 'tags-input',
          root: 'tags-input-root',
        }}
        disableClearable
        freeSolo
        multiple
        selectOnFocus
        ChipProps={{ clickable: true }}
        handleHomeEndKeys
        onChange={onChange}
        includeInputInList
        value={tags}
        options={[] as string[]}
        renderTags={(values, props) => (
          <>
            {values.map((value, i) => (
              <Tooltip
                $variant="info"
                key={`${value}${i.toString()}`}
                title={value?.length > 10 ? value : ''}
              >
                <Chip
                  {...props({ index: i })}
                  onDelete={() => onDelete(value)}
                  label={summarizeText(value, 10)}
                />
              </Tooltip>
            ))}
          </>
        )}
        renderInput={params => (
          <CustomField
            {...params}
            name={name}
            placeholder={
              isEmpty(tags) ? 'Type something and then press enter...' : ''
            }
          />
        )}
      />
      <ErrorMessage name={name} />
    </Container>
  );
};

export default TagsInput;

const Container = styled.div.attrs({ className: 'maindetail-tag' })`
  width: 100%;
  min-height: 80px;

  & .tags-input-root {
    width: 100%;
  }
`;

const LabelWrapper = styled.div`
  margin-bottom: 1px;
  font-size: 0.75rem !important;
  display: flex;
  justify-content: space-between;
`;

const Counter = styled.span.attrs({ className: 'maindetail-span' })`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.silver};
  padding-right: 12px;
`;

const CustomField = styled(TextField)`
  overflow-y: auto;
  ${({ theme }) => theme.utils.scrollbar};

  && .PrivateNotchedOutline-root-2 {
    border-color: transparent;
  }

  && .MuiInputBase-root {
    display: flex !important;
    border-radius: 8px;
    align-items: flex-start;
    padding: 0;
    min-height: 70px;
    /* max-height: 70px !important; */
    height: auto;
    align-self: flex-start;
  }
  & .Mui-disabled {
    background: ${({ theme }) => theme.colors.darkGray};
  }
  && .tags-input {
    padding: 10px 0;
    height: 100%;
    background: inherit;
    && .Mui-focused {
      border-color: transparent !important;
    }
  }
`;
