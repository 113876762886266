import * as React from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Message } from 'i18n';
import PatriciaLogo from 'assets/@client/supplier-login-flow/patricia-red-text.png';
import { ClientRouter } from 'router/routes';
import { FormProvider, useForm } from 'react-hook-form';
import { TagsField } from 'components/forms';
import { useFetch, useRouter, useNotifications } from 'hooks';
import { METHODS } from 'api/client';
import Icon from 'components/Icon';
import {
  SupplierOnboardingAction,
  useSupplierOnboardingDispatch,
  useSupplierOnboardingState,
} from 'context/supplierOnboardingContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { supplierProductStep3Schema } from 'utils/validation.utils';
import { useMediaQuery } from '@mui/material';
import {
  MOBILE_RESOLUTION,
  MOBILE_TABLET_MIN_RESOLUTION,
  MOBILE_TABLET_RESOLUTION,
} from 'styles/sharedStyle';
import ImagePicker from '../components/ImagePicker';
import {
  MainDetailsParent,
  PatriciaLogoContainer,
  MainDetails,
  UploadProductText,
  MainDetailsText,
  FormWrapperContainer,
  ImagePickerDiv,
  LogoParent,
  UploadLogoText,
  FooterButtons,
  BackButtonParent,
  BackButton,
  BackButtonText,
  NextButtonParent,
  NextButton,
  NextButtonText,
} from '../supplier-main-details/SupplierMainDetails';
import ColorField from '../../../appManagement/common/FormFields/ColorField';
import { FormFieldDiv } from '../supplier-additional-info/SupplierAdditionalInfo';
import SkipConfirmationDialog from '../components/SkipConfirmationDialog';
import SupplierMarketingInfoMobile from './SupplierMarketingInfoMobile';

const SupplierMarketingInfo: React.FC = () => {
  const intl = useIntl();
  const [isConfirmationModalVisible, setIsGoBackModalVisible] = React.useState(
    false
  );
  const isMobile = useMediaQuery(MOBILE_RESOLUTION);
  const { showNotification } = useNotifications();
  const state = useSupplierOnboardingState();
  const dispatch = useSupplierOnboardingDispatch();
  const methods = useForm({
    defaultValues: {
      specification: state.addProductDetails.specification ?? [],
      color: state.addProductDetails.color ?? '',
      tags: state.addProductDetails.tags ?? [],
      series: state.addProductDetails.series ?? '',
    },
    mode: 'all',
    resolver: yupResolver(supplierProductStep3Schema),
  });
  const { history } = useRouter();

  const onFailure = React.useCallback(
    (message: string) => {
      showNotification({
        key: 'clients/marketingInfoRejected',
        message,
        severity: 'error',
      });
    },
    [showNotification]
  );

  const { callFetch, isLoading } = useFetch({
    initialUrl: '/api/products/',
    config: {
      method: METHODS.POST,
    },
    skipOnStart: true,
    onSuccess: () => {
      if (state.isScheduledMeeting) {
        history.push('/app/supplier-congratulations/schedule_meeting');
      } else {
        history.push('/app/supplier-congratulations/upload_product');
      }
    },
    onFailure,
  });
  const { control, setValue, handleSubmit } = methods;
  const onFormSubmit = React.useCallback(
    data => {
      const formData = new FormData();
      const finalData = { ...state.addProductDetails, ...data };
      dispatch({
        type: SupplierOnboardingAction.SET_ADD_PRODUCT_DETAILS,
        payload: finalData,
      });
      formData.append('english_name', finalData.englishName);
      formData.append('manufacturer', finalData.manufacturer?.toString());
      formData.append('sku', finalData.sku);
      formData.append('sub_category', finalData.subCategory?.toString());
      formData.append('quantity', finalData.quantity?.toString());
      formData.append('media_urls', JSON.stringify(finalData.mediaUrls));
      if (finalData.media) {
        for (let i = 0; i < finalData.media.length; i++) {
          formData.append('media', finalData.media[i] as Blob);
        }
      }
      formData.append('english_description', finalData.description);
      formData.append('msrp', finalData.msrp?.toString());
      formData.append('price', finalData.price?.toString());
      formData.append('length', finalData.length?.toString());
      formData.append('width', finalData.width?.toString());
      formData.append('height', finalData.height?.toString());
      formData.append('measurement_units', finalData.measurementUnits);
      formData.append('type', finalData.type);
      formData.append('series', finalData.series);
      formData.append('tags', finalData.tags?.toString());
      formData.append('color', finalData.color);
      formData.append('web_page_link', finalData.webpageLink);
      if (finalData.specification) {
        for (let i = 0; i < finalData.specification.length; i++) {
          formData.append('specification', finalData.specification[i] as Blob);
        }
      }
      callFetch({ data: formData });
    },
    [callFetch, dispatch, state.addProductDetails]
  );
  return (
    <>
      {isMobile ? (
        <SupplierMarketingInfoMobile
          onSubmit={handleSubmit(onFormSubmit)}
          methods={methods}
          isLoading={isLoading}
        />
      ) : (
        <ParentMainDetailsDiv>
          <a href={ClientRouter.APP}>
            <PatriciaLogoContainer src={PatriciaLogo} alt="patricia-logo" />
          </a>
          <MainDetailsParent>
            <MainDetails>
              <UploadProductText>
                <FormattedMessage
                  id={Message.SUPPLIER_UPLOAD_YOUR_FIRST_PRODUCT}
                  values={{
                    span: (chunks: string) => (
                      <span style={{ color: '#FC5C42' }}>{chunks}</span>
                    ),
                  }}
                />
              </UploadProductText>
              <MainDetailsText>
                <FormattedMessage id={Message.SUPPLIER_MARKETING_INFO} />
              </MainDetailsText>
              <FormProvider {...methods}>
                <FormWrapperContainer onSubmit={handleSubmit(onFormSubmit)}>
                  <FirstRow>
                    <ImagePickerDiv>
                      <LogoParent>
                        <UploadLogoText>
                          <FormattedMessage
                            id={
                              Message.SUPPLIER_UPLOAD_SPECIFICATION_FILE_LABEL
                            }
                          />
                        </UploadLogoText>
                        <ImagePicker
                          name="specification"
                          label="Upload specification files"
                          control={control}
                          onFileChange={e => {
                            if (e?.target?.files)
                              setValue('specification', e?.target.files);
                          }}
                        />
                      </LogoParent>
                    </ImagePickerDiv>
                  </FirstRow>
                  <InlineView>
                    <FormFieldDiv
                      label={{
                        title: intl.formatMessage({
                          id: Message.SUPPLIER_SERIES_LABEL,
                        }),
                        isBold: true,
                      }}
                      name="series"
                      type="text"
                      placeholder={intl.formatMessage({
                        id: Message.SUPPLIER_SERIES_PLACEHOLDER,
                      })}
                    />
                    <ParentColorField>
                      <ColorField name="color" width={250} required={false} />
                    </ParentColorField>
                  </InlineView>
                  <InlineView>
                    <TagsField
                      required={false}
                      name="tags"
                      defaultValue={state.addProductDetails.tags ?? []}
                      label={intl.formatMessage({
                        id: Message.SUPPLIER_TAGS_LABEL,
                      })}
                    />
                  </InlineView>
                  <FooterButtons>
                    <FooterParent>
                      <BackButtonParent>
                        <BackButton onClick={() => history.goBack()}>
                          <BackButtonText>
                            <FormattedMessage
                              id={Message.PROPERTY_ADD_PROPERTY_BUTTON_PREVIOUS}
                            />
                          </BackButtonText>
                        </BackButton>
                      </BackButtonParent>
                      <NextButtonParent>
                        <NextButton
                          disabled={!methods.formState.isValid || isLoading}
                          type="submit"
                        >
                          {isLoading ? (
                            <CustomIcon name="spinner" size={20} />
                          ) : (
                            <NextButtonText>
                              <FormattedMessage
                                id={Message.PROPERTY_ADD_PROPERTY_BUTTON_NEXT}
                              />
                            </NextButtonText>
                          )}
                        </NextButton>
                      </NextButtonParent>
                    </FooterParent>
                  </FooterButtons>
                </FormWrapperContainer>
              </FormProvider>
            </MainDetails>
          </MainDetailsParent>
          <SkipText onClick={() => setIsGoBackModalVisible(true)}>
            <FormattedMessage id={Message.PROPERTY_ADD_PROPERTY_BUTTON_SKIP} />
          </SkipText>
          <SkipConfirmationDialog
            isOpen={isConfirmationModalVisible}
            toggle={() => setIsGoBackModalVisible(prev => !prev)}
          />
        </ParentMainDetailsDiv>
      )}
    </>
  );
};
export default SupplierMarketingInfo;

const FirstRow = styled.div``;
const InlineView = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 34px;
  label {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin-left: 0;
    font-family: 'Roboto Condensed';
  }
`;

const ParentMainDetailsDiv = styled.div`
  font-family: 'Roboto Condensed';
  .combobox-search-input {
    width: 230px !important;
  }
  .image-picker {
    width: 250px;
    height: 76px;
    margin-bottom: 0;
  }
`;

const SkipText = styled.div`
  position: absolute;
  right: 224px;
  top: 72px;
  text-decoration-line: underline;
  color: #696969;
  cursor: pointer;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    right: 115px;
    top: 164px;
  }
`;

const ParentColorField = styled.div`
  .form-field-container {
    margin-top: -5px;
  }
  label {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin-left: 0;
    font-family: 'Roboto Condensed';
  }
  .dropdown-placeholder {
    color: #3e3e3e;
  }
`;

const CustomIcon = styled(Icon)`
  ${({ theme }) => theme.utils.spinAnimation};
  position: absolute;
  fill: #f9fafa;
`;

const FooterParent = styled.div`
  display: flex;
  gap: 200px;
  margin-top: 236px;
  margin-bottom: 20px;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    gap: 100px;
    margin-top: 48px;
  }
`;
