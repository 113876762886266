import * as React from 'react';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import MuiListItem from '@material-ui/core/ListItem';
import rtl from 'styled-components-rtl';
import styled from 'styled-components';
import CheckboxItem from 'components/Checkbox/CheckboxItem';
import { isEmpty } from 'utils/lodash.utils';
import InputButton from 'components/InputButton';
import Popper from '@material-ui/core/Popper';
import { Options, OptionsGeneric } from '@popperjs/core';
import { Option } from 'components/Autocomplete/Autocomplete.config';

export type PopperPlacementType = Options['placement'];

export type PopperModifiersType = Options['modifiers'];

export type OptionsGenericType = Partial<OptionsGeneric<unknown>>;

interface Props {
  options: Option[];
  selectedItems: Option[];
  placeholder?: string;
  selectedPlaceholder?: string;
  multiple?: true;
  disableSelectAll?: boolean;
  onClick: (item: Option | string) => void;
  onDelete?: (val: Option) => void | null;
  isOpen: boolean;
  toggle: () => void;
  anchorEl?: HTMLElement | null;
  placement?: PopperPlacementType;
  onApply: () => void;
  disabled?: boolean;
  onClear?: () => void;
  width?: number;
  endIcon?: React.ReactNode;
  renderOption?:
    | ((
        option: Option,
        onClick?: React.MouseEventHandler<HTMLLIElement>
      ) => React.ReactNode)
    | undefined;
  buttonClassName?: string;
  modifiers?: PopperModifiersType;
  popperOptions?: OptionsGenericType;
}

const Select: React.FC<Props> = ({
  options,
  isOpen,
  selectedItems,
  placeholder = 'Select',
  selectedPlaceholder,
  disableSelectAll,
  multiple,
  endIcon,
  onClick,
  toggle,
  anchorEl,
  placement = 'auto',
  disabled,
  width = 230,
  renderOption,
  buttonClassName,
  modifiers,
  popperOptions,
}) => {
  const displayPlaceholder = React.useMemo(() => isEmpty(selectedItems), [
    selectedItems,
  ]);

  return (
    <>
      <InputButton
        isOpen={isOpen}
        onClick={toggle}
        disabled={disabled}
        displayPlaceholder={displayPlaceholder}
        selectedPlaceholder={selectedPlaceholder}
        placeholder={placeholder}
        selectedItems={selectedItems?.map(e => e?.name.toString())}
        width={width}
        endIcon={endIcon}
        disableCounter={!multiple}
        className={isOpen ? `${buttonClassName} isopen` : buttonClassName}
      />
      <CustomPopper
        open={isOpen}
        anchorEl={anchorEl}
        disablePortal
        placement={placement}
        className="custom-popper"
        modifiers={modifiers}
        popperOptions={popperOptions}
      >
        <div>
          <List>
            {multiple && !disableSelectAll && (
              <ListItem button onClick={() => onClick('All')}>
                <CheckboxItem
                  value="All"
                  checked={selectedItems?.length === options.length}
                />
              </ListItem>
            )}
            {options?.map(option =>
              renderOption?.(option, () => onClick(option))
            )}
          </List>
          {multiple && (
            <>
              <CustomDivider key="Divider" />
            </>
          )}
        </div>
      </CustomPopper>
    </>
  );
};

export default React.memo<React.FC<Props>>(Select);

const CustomDivider = styled(Divider)`
  padding: 0.3rem;
  width: 100%;
`;

export const ListItem = styled(MuiListItem)`
  width: 100%;
  display: flex;
  align-items: center;
  ${rtl`
  margin-left: 14px !important;
  .checkbox-input {
    margin-right: 7px;
  }
`}
  &.Mui-selected {
    background: white;
  }
`;

const CustomPopper = styled(Popper)`
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  z-index: 9999999;
  background-color: #fcfcfc;
  max-height: 350px;
  overflow: scroll;
  ${({ theme }) => theme.utils.scrollbar}
  overflow-x:hidden;
  border-radius: 10px;
`;
