export const userLogData = {
  eventArea: {
    experts: 'experts',
    homePage: 'homepage',
    magazine: 'magazine',
    myOrders: 'my_orders',
    myProfile: 'my_profile',
    myProperties: 'my_properties',
    registration: 'registration',
    myCart: 'my_cart',
    catalog: 'catalog',
    skins: 'skins',
    checkout: 'checkout',
  },
  eventSection: {
    experts: 'experts',
    header: 'header',
    catalog: 'catalog',
    heroSection: 'hero section',
    instaStories: 'insta-stories',
    magzine: 'magzine',
    personalMenu: 'personal_menu',
    footer: 'footer',
    orders: 'orders',
    mainPage: 'main_page',
    properties: 'properties',
    propertyPage: 'property_page',
    sectionClick: '123',
    planUpload: 'plan upload',
    quiz: 'quiz',
    userInfo: 'user info',
    registrationDone: 'registration done',
    cart: 'cart',
    categories: 'categories',
    subCategories: 'sub-categories',
    bestSeller: 'best seller',
    breadcrumbs: 'breadcrumbs',
    productPage: 'product_page',
    productModal: 'product_modal',
    addToCartConfirmation: 'add_to_cart_confirmation',
    skins: 'skins',
    checkout: 'checkout',
  },
  eventName: {
    becomeExpert: 'become_expert',
    submit: 'submit',
    homeButton: 'home_button',
    logo: 'logo',
    cartIcon: 'cart_icon',
    startTour: 'start_tour',
    search: 'search',
    searchResultPage: 'search_result_page',
    enterYourHome: 'enter_your_home',
    storyOpen: 'story_open',
    nextStory: 'next_story',
    seeMore: 'see_more',
    category: 'category',
    openMagazine: 'open_magazine',
    learnMore: 'learn_more',
    myProfile: 'my_profile',
    myProperties: 'my_properties',
    myOrders: 'my_orders',
    productsCatalog: 'products_catalog',
    catalogIcon: 'catalog_icon',
    shoppingCart: 'shopping_cart',
    customerSupport: 'customer_support',
    contactUs: 'contact_us',
    accessibility: 'accessibility',
    englishHebrew: 'english_hebrew',
    logOut: 'log_out',
    socialIcon: 'social_icon',
    ourStory: 'our_story',
    meetTheTeam: 'meet_the_team',
    careers: 'careers',
    press: 'press',
    magazine: 'magazine',
    supportFaqs: 'support_FAQs',
    termsOfUse: 'terms_of_use',
    privacyPolicy: 'privacy_policy',
    becomeAnExpert: 'become_an_expert',
    sellYourProducts: 'sell_your_products',
    suppliersInterface: "supplier's_interface",
    quickService: 'quick_service',
    weeklyHours: 'weekly_hours',
    openArticle: 'open_article',
    shareArticle: 'share_article',
    openOrder: 'open_order',
    downloadReceipt: 'download_receipt',
    productClick: 'product_click',
    profilePicture: 'profile_picture',
    addNewAddress: 'add_new_address',
    saveButton: 'save_button',
    viewProperty: 'view_property',
    xCloseButton: 'X_close_button',
    takeATour: 'take_a_tour',
    showAllMedia: 'show_all_media',
    roomsFilter: 'rooms_filter',
    showMore: 'show_more',
    order: 'order',
    createYourHome: 'create_your_home',
    firstStep: 'first_step',
    browseForPlan: 'browse_for_plan',
    preMadeFloorPlan: 'pre-made_floor_plan',
    chooseBedroom: 'choose_bedroom_',
    backToUpload: 'back_to_upload',
    nextStep: 'next_step',
    letsStartQuiz: 'lets_start_quiz',
    skipQuestion: 'skip_question',
    backQuestion: 'back_question',
    backToQuestions: 'back_to_questions',
    facebook: 'facebook',
    google: 'google',
    apple: 'apple',
    iReadAndAgree: 'I_read_and_agree',
    done: 'done',
    backToHomePage: 'back_to_home_page',
    browseCatalog: 'browse_catalog',
    nextFailed: 'next_failed',
    checkOutButton: 'checkout_button',
    quantityAdd: 'quantity_add',
    quantityLess: 'quantity_less',
    deleteProduct: 'delete_product',
    deleteProductConfirm: 'delete_product_confirm',
    deleteProductCancel: 'delete_product_cancel',
    propertySelected: 'property_selected',
    viewDetails: 'view_details',
    editButton: 'edit_button',
    shipping_method: 'shipping_method',
    proceed: 'proceed',
    laterDelivery: 'later_delivery',
    tranzilaProcessSuccess: 'tranzila_process_success',
    tranzilaProcessFailure: 'tranzila_process_failure',
    subCategory: 'subcategory',
    product: 'product',
    backToCategory: 'back_to_category',
    backToSubcategory: 'back_to_subcategory',
    backToHomepage: 'back_to_homepage',
    productViewDetailsModal: 'product_view_details_modal',
    addToCart: 'add_to_cart',
    checkout: 'checkout',
    keepShopping: 'keep_shopping',
    goodToKnow: 'good_to_know',
    dimensions: 'dimensions',
    addNewProperty: 'add_new_property',
    browseAddPlan: 'browse_add_plan',
    stepBack: 'step_back',
    selectQuiz: 'select_quiz',
    newQuiz: 'new_quiz',
    addProperty: 'add_property',
    skinsIcon: 'skins_icon',
    moreSkins: 'more_skins',
    openSkin: 'open_skin',
    applySkin: 'apply_skin',
    profile: 'profile',
    discount: 'discount_applied',
  },
  eventAction: {
    clicked: 'clicked',
  },
  eventPath: {
    becomeExpertPath: '/app/become-an-expert',
    appPath: '/app',
    appSearchPath: '/app/search',
    appMagazinePath: '/app/magazine',
    appOrderPath: '/app/orders',
    appOrderIdPath: '/app/orders/order_id',
    appMyAccountEditPath: '/app/my-account/edit',
    appPropertyPath: '/app/properties',
    appModalAddPropertyPath: '/app/properties?modal=add-property',
    appPropertiesIdPath: '/app/properties/property_id',
    appOnBoardingUploadPlanPath: '/app/onboarding/upload-plan',
    appOnBoardingConfirmPlanPath: '/app/onboarding/confirm-plan',
    appOnBoardingPlanConfirmPath: '/app/onboarding/confirm-plan',
    appOnBoardingQuizQuestionPath: '/app/onboarding/quiz-question',
    appOnBoardingQuizSummaryPath: '/app/onboarding/quiz-summary',
    appOnBoardingAdditionalInfoPath: '/app/onboarding/additional-info',
    appOnBoardingDonePath: '/app/onboarding/done',
    appCartCartIdPath: '/app/cart/cart_id',
    appCartCartCheckOutId: '/app/cart/cart_id?checkout_id',
    appProductsPath: 'app/products',
    appProductsCategoryNamePath: 'app/products/category_name',
    appProductsSubCategoryNamePath:
      'app/products/category_name/subcategory_name',
    appProductSubCategoryNameProductIdPath:
      'app/products/category_name/subcategory_name/product_id',
    appSkinsIDPath: '/app/skins/skin_id',
    appTourId: '/app/tour/tour_id',
    appSkinsCheckoutId: '/app/skins/checkout/checkout_id',
  },
};
