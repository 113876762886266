import * as React from 'react';
import TextButton from 'components/Button/TextButton';
import styled from 'styled-components';

interface Props {
  onClear?: () => void;
  onApply?: () => void;
}

const SelectActions: React.FC<Props> = ({ onClear, onApply }) => {
  return (
    <Actions>
      <ThinButton disableRipple onClick={onClear}>
        Clear
      </ThinButton>
      <TextButton $red onClick={onApply}>
        Apply
      </TextButton>
    </Actions>
  );
};

export default React.memo(SelectActions);

const Actions = styled.div`
  padding: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: white;
`;

const ThinButton = styled(TextButton)`
  && {
    font-weight: 400;

    &:focus-visible {
      background: rgba(255, 232, 232, 0.6);
      border-radius: 25%;
    }
  }
`;
