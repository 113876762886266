import * as React from 'react';
import Fade from '@material-ui/core/Fade';
import styled from 'styled-components';
import Icon from 'components/Icon';
import ImageActionsToolbar from 'components/ImageActionsToolbar';

interface Props {
  alt: string;
  isHovering: boolean;
  label?: string;
  src: string;
  onClear: () => void;
  required?: boolean;
  onUpload?: () => void;
}
const ImagePreview: React.FC<Props> = ({
  alt,
  isHovering,
  label = 'Add illustration',
  src,
  onClear,
  required,
  onUpload,
}) => {
  return (
    <>
      {src ? (
        <>
          <Fade in={isHovering} appear={isHovering}>
            <ActionsWrapper>
              <ImageActionsToolbar
                isCentralReplaceBtn={true}
                withRemove={false}
                withDownload={false}
                onRemove={onClear}
                onUpload={onUpload}
              />
            </ActionsWrapper>
          </Fade>
          <Image src={src} alt={alt} />
        </>
      ) : (
        <>
          <IconView>
            <PlusIcon />
          </IconView>

          <Label>
            {label}
            {required && '*'}
          </Label>
        </>
      )}
    </>
  );
};

export default ImagePreview;

const ActionsWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  border-radius: 5px;
  display: flex;
  align-items: center;
`;

const Image = styled.img`
  width: 148px;
  height: 148px;
  object-fit: cover;
`;

const PlusIcon = styled(Icon).attrs({
  name: 'plus',
  size: 24,
})`
  opacity: 0.5;
`;

const Label = styled.span.attrs({ className: 'label-image-picker' })`
  white-space: normal;
  text-align: center;
`;

const IconView = styled.div.attrs({ className: 'dashed-icon' })`
  border: 1px dashed #d9d9d9;
  border-radius: 100%;
  width: 42px;
  height: 42px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 5px;
`;
