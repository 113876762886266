import * as React from 'react';
import styled from 'styled-components';
import ImageActionsToolbar from 'components/ImageActionsToolbar';

export interface SlideItem {
  id: number;
  file: string;
  name: string;
  type?: string;
  startTourButton?: JSX.Element;
}

const Slide: React.FC<SlideItem> = ({ file, name, startTourButton }) => {
  return (
    <Container>
      <ActionsWrapper className="action-wrapper">
        <ImageActionsToolbar
          withDownload={false}
          withRemove={false}
          withShare
          onRemove={() => null}
          file={file}
        />
      </ActionsWrapper>
      {startTourButton}
      <div className="img-container">
        <img src={file} alt={name} />
      </div>
    </Container>
  );
};

export default React.memo(Slide);

const ActionsWrapper = styled.div``;
const Container = styled.div.attrs({
  className: 'slideshow-slide',
})`
  border-radius: 25px;
  height: 100%;
  width: 96%;
  z-index: 100;
  margin: auto;
  max-height: 640px;
  @media (max-height: 600px) {
    max-height: 400px;
  }
  && .action-wrapper {
    @media (max-width: 800px) {
      visibility: hidden;
    }
  }
  && .img-container {
    margin-top: 14px;

    @media (max-width: 800px) {
      /* height: 319px; */
      width: 100%;
      left: 0px;
      border-radius: 0px;
    }
    @media (min-height: 600px) {
      /* height: 319px; */
      height: 100%;
      max-height: 50vh;
    }
    @media (min-height: 999px) {
      /* height: 601px; */
    }
  }

  && .img-container > img {
    height: 100%;
    width: 100% !important;
    max-width: fit-content;
    object-fit: contain;
  }
`;
