import { Message } from 'i18n';
import * as yup from 'yup';
import { isUSA, isIsrael } from './addresses.utils';

export const alphaNumericRegExp = /^[a-zA-Z0-9]*$/i;
export const alphaNumericWhiteSpace = /^[a-z\d\s]+$/i;
export const numericRegExp = /^[0-9]*$/;
export const zipNumericRegExp = /^[0-9]{0,12}$/;
export const spaceRegex = /^\S*$/;
export const upperCaseRegExp = /^[A-Z0-9]*$/;
export const floatRegExp = /^(?:[1-9]\d*|0)?(\.[0-9]{1,5})?$/;
export const dimensionRegExp = /^(?:[1-9]\d*|0)?(\.[0-9]{1,10})?$/;
export const alphaNumericHebrewRegExp = /^[a-z\d\s]+$|[\u0590-\u05fe]/;
export const alphaNumericHebrewRegExpAddress = /^[A-Za-z\d\s]+$|[\u0590-\u05fe]/;

const hebrewRegExp = /[\u0590-\u05fe]/;
const passwordRegExp = /([A-Za-z0-9!@#$%^&*])\w+/;
const alphabeticRegExp = /^[A-Za-z ,.'-]+$/i;
const nameRegExp = /^[a-z\u05D0-\u05EA'\- .,]+$/i;
const phoneRegExpUserForm = /^(?:[0-9]●?){6,14}[0-9]$/;
// eslint-disable-next-line max-len

const websiteRegExp = new RegExp(
  '^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$',
  'i'
);

const genericError = 'Please enter a valid input';
const emailError = 'Please enter a valid Email address';
const phoneError = 'Please enter a valid Phone number';
const passwordError = 'Please enter a valid password';
const nameError = 'Name must contain English or hebrew letters only';
const streetError = 'Please enter a valid street address';
const mandatoryError = 'This field is mandatory';
const addressLine1Error = 'Please enter a valid address line1';
const emailErrors = 'Email is required field';

export const isValidName = (str: string | undefined) => {
  return isHebrewLetter(str) || isAlphabetic(str);
};

const emailValidation = yup
  .string()
  .required(mandatoryError)
  .email(emailError)
  .label('Email');

const nameValidation = yup
  .string()
  .required(mandatoryError)
  .matches(nameRegExp, nameError);

const discountCodeValidation = yup
  .string()
  .matches(spaceRegex, Message.VALIDATION_SPACE)
  .matches(alphaNumericRegExp, Message.VALIDATION_ALPHA_SPACE)
  .matches(upperCaseRegExp, Message.VALIDATION_UPPER_CASE);

const descriptionValidation = yup
  .string()
  .max(2000, 'Maximum 2000 characters')
  .label('Description');

const descriptionTechnicalValidation = yup
  .string()
  .required('Description is mandatory')
  .max(4000, 'Maximum 4000 characters')
  .label('Description');

const deviceVersionValidation = yup
  .string()
  .required('Device Version is required')
  .max(48, 'Maximum 48 characters')
  .label('Description');

const passwordValidation = yup
  .string()
  .required(passwordError)
  .min(8)
  .matches(passwordRegExp, passwordError)
  .label('Password');

const verifyPasswordValidation = yup
  .string()
  .required(passwordError)
  .oneOf([yup.ref('password')], 'Passwords do not match')
  .label('Verify password');

const phoneValidation = yup
  .string()
  .required(mandatoryError)
  .min(10, 'Minimum 10 numbers');

// const phoneOptionalUserForm = yup
//   .string()
//   .min(10)
//   .max(15)
//   .matches(phoneRegExpUserForm, phoneError)
//   .label('Phone number');

const phoneOptionalUserFormS = yup.string().label('Phone number');

export const loginSchema = yup.object().shape({
  email: emailValidation,
  password: passwordValidation,
});

export const registerSchema = yup.object().shape({
  firstName: nameValidation,
  lastName: nameValidation,
  email: emailValidation,
  // verifyEmail: yup
  //   .string()
  //   .required(mandatoryError)
  //   .email(emailError)
  //   .oneOf([yup.ref('email')], 'Emails does not match')
  //   .label('Verify email'),
  password: passwordValidation,
  verifyPassword: verifyPasswordValidation,
  // termsAndConditions: yup
  //   .boolean()
  //   .oneOf([true], 'Must Accept Terms and Conditions'),
});

export const registerSupplierSchema = yup.object().shape({
  firstName: nameValidation,
  lastName: nameValidation,
  email: emailValidation,
  // verifyEmail: yup
  //   .string()
  //   .required(mandatoryError)
  //   .email(emailError)
  //   .oneOf([yup.ref('email')], 'Emails does not match')
  //   .label('Verify email'),
  password: passwordValidation,
  verifyPassword: verifyPasswordValidation,
  phoneNumber: yup
    .string()
    .min(7, 'Phone number must be at least 7 numbers')
    .max(15, 'Phone number must be at least 15 numbers')
    .required(mandatoryError)
    .matches(phoneRegExpUserForm, phoneError)
    .label('Phone number'),
  // termsAndConditions: yup
  //   .boolean()
  //   .oneOf([true], 'Must Accept Terms and Conditions'),
});

export const storeInfoSchema = yup.object().shape({
  storeName: yup
    .string()
    .required(mandatoryError)
    .max(24, 'Store name must be at most 24 characters'),
  countries: yup.string().required(mandatoryError),
  phoneNumber: yup
    .string()
    .min(7, 'Phone number must be at least 7 numbers')
    .max(15, 'Phone number must be at least 15 numbers')
    .required(mandatoryError)
    .matches(phoneRegExpUserForm, phoneError)
    .label('Phone number'),
  website: yup
    .string()
    .optional()
    .matches(websiteRegExp, 'Website must be valid URL'),
  taxId: yup
    .string()
    .max(8, 'Tax id must be at most 8 characters')
    .matches(numericRegExp, {
      message: 'This field must contain numbers.',
      excludeEmptyString: true,
    }),
});

export const forgotPasswordSchema = yup
  .object()
  .shape({ email: emailValidation });

export const resetPasswordSchema = yup.object().shape({
  password: passwordValidation,
  verifyPassword: verifyPasswordValidation,
});

export const userDetailsSchema = yup.object().shape({
  firstName: nameValidation,
  lastName: nameValidation,
  email: emailValidation,
  phoneNumber: phoneValidation,
});

export const addressesSchema = yup.object().shape({
  country: yup.string().required(mandatoryError),
  state: yup.string().when('country', {
    is: (country: string) => isUSA(country),
    then: yup.string().required('Must select state'),
  }),
  city: yup.string().required(mandatoryError),

  zipCode: yup.string().required(mandatoryError).matches(zipNumericRegExp, {
    message: genericError,
    excludeEmptyString: true,
  }),
  elevator: yup.boolean(),
  isPrimary: yup.boolean(),

  street: yup.string().when('country', {
    is: (country: string) => isIsrael(country),
    then: yup
      .string()
      .required(mandatoryError)
      .matches(alphaNumericHebrewRegExpAddress, streetError),
    otherwise: yup.string().notRequired(),
  }),
  apartmentNumber: yup.string().when('country', {
    is: (country: string) => isIsrael(country),
    then: yup
      .string()
      .required(mandatoryError)
      .matches(numericRegExp, genericError),
    otherwise: yup.string().notRequired(),
  }),
  house: yup.string().when('country', {
    is: (country: string) => isIsrael(country),
    then: yup
      .string()
      .required(mandatoryError)
      .matches(numericRegExp, genericError),
    otherwise: yup.string().notRequired(),
  }),
  floor: yup.string().when('country', {
    is: (country: string) => isIsrael(country),
    then: yup
      .string()
      .required(mandatoryError)
      .matches(numericRegExp, genericError),
    otherwise: yup.string().notRequired(),
  }),

  address_line_1: yup.string().when('country', {
    is: (country: string) => !isIsrael(country),
    then: yup
      .string()
      .required(mandatoryError)
      .matches(alphaNumericHebrewRegExpAddress, addressLine1Error),
    otherwise: yup.string().notRequired(),
  }),
});

export const clientInformationSchema = yup.object().shape({
  firstName: nameValidation,
  lastName: nameValidation,
  phone: yup
    .string()
    .min(7, 'Phone number must be at least 7 numbers')
    .max(15, 'Phone number must be at most 15 numbers')
    .required(mandatoryError)
    .matches(phoneRegExpUserForm, phoneError)
    .label('Phone number'),
  email: yup.lazy(value => {
    if (Boolean(value) && value.length > 0) {
      return emailValidation;
    }
    return yup.string().required(emailErrors);
  }),
  password: yup.string().when('email', {
    is: (email: string) => email,
    then: yup
      .string()
      .required(mandatoryError)
      .matches(alphaNumericHebrewRegExpAddress, addressLine1Error),
    otherwise: yup.string().notRequired(),
  }),
});

export const clientSellerSchema = yup.object().shape({
  firstName: nameValidation,
  lastName: nameValidation,
  phone: yup.lazy(value => {
    if (Boolean(value) && value.length > 0) {
      return yup
        .string()
        .min(7, 'Phone number must be at least 7 numbers')
        .max(15, 'Phone number must be at most 15 numbers')
        .required(mandatoryError)
        .matches(phoneRegExpUserForm, phoneError)
        .label('Phone number');
    }
    return phoneOptionalUserFormS;
  }),
  email: yup.lazy(value => {
    if (Boolean(value) && value.length > 0) {
      return emailValidation;
    }
    return yup.string().required(emailErrors);
  }),
  password: yup.string().when('email', {
    is: (email: string) => email,
    then: yup
      .string()
      .required(mandatoryError)
      .matches(alphaNumericHebrewRegExpAddress, addressLine1Error),
    otherwise: yup.string().notRequired(),
  }),
});

export const ProjectInformationSchema = yup.object().shape({
  name: yup.string().required(mandatoryError),
  number: yup
    .string()
    .required(mandatoryError)
    .matches(numericRegExp, genericError)
    .label('Number'),
  address: yup.string().required(mandatoryError),
  city: yup.string().required(mandatoryError),
});

export const userBasicSchema = yup.object().shape({
  firstName: nameValidation,
  lastName: nameValidation,
});

export const supplierUserSchema = yup.object().shape({
  firstName: nameValidation,
  lastName: nameValidation,
  email: emailValidation,
});

export const addNewUnitSchema = yup.object().shape({
  buildingName: yup.string().required(mandatoryError),
  typeName: yup.string().required(mandatoryError),
});

export const userSchema = yup.object().shape({
  firstName: nameValidation,
  lastName: nameValidation,
  email: emailValidation,
  phoneNumber: phoneValidation,
});

export const userProfileSchema = yup.object().shape({
  firstName: nameValidation,
  lastName: nameValidation,
  phoneNumber: yup.lazy(val => {
    if (val.length >= 1)
      return yup
        .string()
        .min(7, 'Phone number must be at least 7 numbers')
        .max(15, 'Phone number must be at most 15 numbers')
        .required(mandatoryError)
        .matches(phoneRegExpUserForm, phoneError)
        .label('Phone number');
    return yup.string().optional();
  }),
});

export const checkoutUserSchema = yup.object().shape({
  name: nameValidation,
  email: emailValidation,
  phoneNumber: phoneValidation,
});

export const checkoutDiscountSchema = yup.object().shape({
  discount: discountCodeValidation,
});

export const contactCustomerSchema = yup.object().shape({
  email: emailValidation,
  description: descriptionValidation,
  phoneNumber: yup
    .string()
    .min(7, 'Phone number must be at least 7 numbers')
    .max(15, 'Phone number must be at least 15 numbers')
    .required(mandatoryError)
    .matches(phoneRegExpUserForm, phoneError)
    .label('Phone number'),
});

export const contactTechnicalSchema = yup.object().shape({
  email: emailValidation,
  description: descriptionTechnicalValidation,
  deviceVersion: deviceVersionValidation,
});

export const orderCancelSchema = yup.object().shape({
  email: emailValidation,
  phoneNumber: phoneValidation,
});

export const optionalAddressSchema = yup.object().shape({
  country: yup.string().optional(),
  state: yup.string().when('country', {
    is: (country: string) => isUSA(country),
    then: yup.string().required('Must select state'),
  }),
  city: yup.string().when('country', {
    is: (country: string) => Boolean(country),
    then: yup.string().required(mandatoryError),
  }),
  street: yup.string().when('country', {
    is: (country: string) => Boolean(country),
    then: yup.string().required(mandatoryError),
  }),
  apartmentNumber: yup.string().when('country', {
    is: (country: string) => Boolean(country),
    then: yup.string().required(mandatoryError),
  }),
  house: yup.string().when('country', {
    is: (country: string) => Boolean(country),
    then: yup.string().required(mandatoryError),
  }),
  floor: yup.string().when('country', {
    is: (country: string) => Boolean(country),
    then: yup.string().required(mandatoryError),
  }),
  zipCode: yup.string().optional().matches(zipNumericRegExp, {
    message: genericError,
    excludeEmptyString: true,
  }),
});

export const addClientSchema = addressesSchema.concat(userSchema);

export const teamMemberSchema = yup
  .object()
  .shape({
    role: yup.string().required(),
  })
  .concat(optionalAddressSchema)
  .concat(userSchema);

export const addSupplierSchema = yup
  .object()
  .shape({
    companyName: yup.string().required(mandatoryError).label('Supplier name'),
    skuPrefix: yup
      .string()
      .max(5, 'Maximum 5 characters')
      .required(mandatoryError)
      .matches(alphabeticRegExp, {
        message: 'This field must contain alphabetic letters.',
        excludeEmptyString: true,
      })
      .label('SKU Prefix'),
    additionalPhoneNumber: yup
      .string()
      .optional()
      .label('Additional phone number'),
    registeredNumber: yup
      .string()
      .optional()
      .matches(alphaNumericRegExp, {
        message: 'This field must contain alphabetic letters or numbers.',
        excludeEmptyString: true,
      })
      .label('Registered No.'),
    category: yup.string().required(mandatoryError).label('Category'),
    website: yup
      .string()
      .optional()
      .matches(websiteRegExp, 'Website must be valid URL'),
  })
  .concat(optionalAddressSchema)
  .concat(supplierUserSchema);

export const supplierProductStep1Schema = yup.object().shape({
  englishName: yup.string().required(mandatoryError).label('Product name'),
  manufacturer: yup
    .string()
    .required(mandatoryError)
    .label('Manufacturer name'),
  sku: yup.string().required(mandatoryError).label('Manufacturer SKU'),
  subCategory: yup.string().required(mandatoryError).label('Sub Category'),
  quantity: yup.string().required(mandatoryError).matches(numericRegExp, {
    message: genericError,
    excludeEmptyString: true,
  }),
  mediaUrls: yup
    .array()
    .of(yup.string().required())
    .test('website test', genericError, arr => {
      return !arr?.filter(data => data && !websiteRegExp.test(data)).length;
    })
    .min(1, mandatoryError)
    .required(mandatoryError)
    .label('Media URLs'),
  msrp: yup.string().required(mandatoryError).matches(floatRegExp, {
    message: genericError,
    excludeEmptyString: true,
  }),
  price: yup.string().required(mandatoryError).matches(floatRegExp, {
    message: genericError,
    excludeEmptyString: true,
  }),
});

export const supplierProductStep2Schema = yup.object().shape({
  description: yup
    .string()
    .max(1024, 'Maximum 1024 characters')
    .label('Description'),
  length: yup.string().required(mandatoryError).matches(floatRegExp, {
    message: genericError,
    excludeEmptyString: true,
  }),
  width: yup.string().required(mandatoryError).matches(floatRegExp, {
    message: genericError,
    excludeEmptyString: true,
  }),
  height: yup.string().required(mandatoryError).matches(floatRegExp, {
    message: genericError,
    excludeEmptyString: true,
  }),
  measurementUnits: yup
    .string()
    .required(mandatoryError)
    .label('Measurement Units'),
  webpageLink: yup.string().label('Web Page link'),
  type: yup.string().required(mandatoryError).label('Types'),
});

export const supplierProductStep3Schema = yup.object().shape({
  tags: yup.array().of(yup.string().required()).label('Tags'),
  series: yup.string().label('Series'),
  color: yup.string().label('Colors'),
});

export const productSchema = yup.object().shape({
  englishName: yup.string().required(mandatoryError).label('Product name'),
  manufacturer: yup
    .string()
    .required(mandatoryError)
    .label('Manufacturer name'),
  sku: yup.string().required(mandatoryError).label('Manufacturer SKU'),
  subCategory: yup.string().required(mandatoryError).label('Sub Category'),
  quantity: yup.string().required(mandatoryError).matches(numericRegExp, {
    message: genericError,
    excludeEmptyString: true,
  }),
  mediaUrls: yup
    .array()
    .of(yup.string().required())
    .test('website test', genericError, arr => {
      return !arr?.filter(data => data && !websiteRegExp.test(data)).length;
    })
    .min(0, mandatoryError)
    // .of(yup.string().required())

    // .length(1, mandatoryError)
    .label('Media URLs'),
  description: yup
    .string()
    .max(1024, 'Maximum 1024 characters')
    .label('Description'),
  msrp: yup.string().required(mandatoryError).matches(floatRegExp, {
    message: genericError,
    excludeEmptyString: true,
  }),
  price: yup.string().required(mandatoryError).matches(floatRegExp, {
    message: genericError,
    excludeEmptyString: true,
  }),
  length: yup.string().required(mandatoryError).matches(dimensionRegExp, {
    message: genericError,
    excludeEmptyString: true,
  }),
  width: yup.string().required(mandatoryError).matches(dimensionRegExp, {
    message: genericError,
    excludeEmptyString: true,
  }),
  height: yup.string().required(mandatoryError).matches(dimensionRegExp, {
    message: genericError,
    excludeEmptyString: true,
  }),
  measurementUnits: yup
    .string()
    .required(mandatoryError)
    .label('Measurement Units'),
  webpageLink: yup.string().label('Web Page link'),
  type: yup.string().required(mandatoryError).label('Types'),
  tags: yup.array().of(yup.string().required()).label('Tags'),
  series: yup.string().label('Series'),
  color: yup.string().label('Colors'),
});

export const rolesSchema = yup.object().shape({
  name: yup
    .string()
    .required(mandatoryError)
    .trim()
    .matches(alphaNumericWhiteSpace, {
      message: 'This field must contain alphabetic letters or numbers.',
    }),
  roleTypes: yup
    .array()
    .of(yup.object().shape({ id: yup.string(), role: yup.string() })),
  department: yup.string().required(mandatoryError),
});

export const newQuizSchema = yup.object().shape({
  designStyles: yup
    .array()
    .min(2)
    .of(yup.object().shape({ id: yup.number(), name: yup.string() })),
  name: yup.string().required(mandatoryError),
});

export const validateString = (
  val: string,
  validator: (val: string) => boolean
): string => val.split('').filter(validator).join('');

export const isFormHasEmptyField = (
  object: object,
  excludingFields?: string[]
) => {
  const isEmptyValue = (entry: string[]) => {
    const [key, value] = entry;
    return excludingFields ? !value && !excludingFields.includes(key) : !value;
  };

  return Object.entries(object).some(entry => {
    return isEmptyValue(entry);
  });
};

export const isAlphabetic = (str: string | undefined) => {
  return alphabeticRegExp.test(str ?? '');
};

export const isAlphaNumeric = (key: string) => {
  return /^[a-zA-Z0-9 ]*$/.test(key);
};

export const isHebrewLetter = (str?: string) => {
  return hebrewRegExp.test(str ?? '');
};

export const isSpecialCharacter = (str: string) => {
  return /^[!@#$%^&*)(+=._-]+$/g.test(str);
};

export const searchFilterValidation = (str: string) =>
  validateString(
    str,
    val => isAlphaNumeric(val) || isHebrewLetter(val) || isSpecialCharacter(val)
  );

export const quizQuestionSchema = yup.object().shape({
  questionLabel: yup.string().optional(),
  answers: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          image: yup.string().required(mandatoryError).label('Image'),
          priceTag: yup.string().required(mandatoryError).label('Price tag'),
          tags: yup
            .array()
            .of(yup.string().required())
            .required(mandatoryError)
            .label('More tags'),
          color: yup.string().required(mandatoryError).label('Color'),
          designStyle: yup
            .string()
            .required(mandatoryError)
            .label('Design style'),
        })
        .required(mandatoryError)
    )
    .required(mandatoryError)
    .min(2, 'The minimum amount of answers is 2')
    .max(6, 'The maximum amount of answers is 6')
    .label('Answers'),
});

export const hasEmptyField = <
  T extends Record<string, string | number | FileList>
>(
  field: T
) => {
  const uniqueLengths = new Set(
    Object.values(field).map(
      value => (value as string)?.length ?? (value as number)
    )
  );
  return uniqueLengths.has(0);
};

export const trackingNumberSchema = yup.object().shape({
  number: yup.string().required(mandatoryError),
});
