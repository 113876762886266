import * as React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import MuiAutocomplete from '@material-ui/core/Autocomplete';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { PopperProps } from '@material-ui/core/Popper';
import { FilterOptionsState } from '@material-ui/core/useAutocomplete';
import DeleteIcon from 'components/DeleteIcon';
import { Message } from 'i18n';
import type {
  AutocompleteChangeReason,
  AutocompleteCloseReason,
  AutocompleteRenderOptionState,
  AutocompleteRenderInputParams,
  AutocompleteChangeDetails,
  AutocompleteInputChangeReason,
} from '@material-ui/core/Autocomplete';
import type { Ref } from 'types/common.types';
import type { Option } from './Autocomplete.config';
import ListboxComponent from './WindowAdapter';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    option: {
      alignItems: 'flex-start',
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&[data-focus="true"]': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
);

interface Props {
  open?: boolean;
  options: Option[];
  loading?: boolean;
  openOnFocus?: boolean;
  inputRef?: Ref<unknown>;
  freeSolo?: true;
  value?: Option[] | Option;
  placeholder?: string;
  multiple?: boolean;
  disableListWrap?: true;
  filterOptions: (
    selection: Option[],
    state: FilterOptionsState<Option>
  ) => Option[];
  disablePortal?: true;
  forcePopupIcon?: boolean;
  disableCloseOnSelect?: boolean;
  inputValue?: string;
  getOptionLabel?: (option: Record<string, string | number> | null) => string;
  groupBy?: (option: Option) => string;
  onClose?: (
    event: React.SyntheticEvent<Element, Event>,
    reason: AutocompleteCloseReason
  ) => void;
  renderInput: (params: AutocompleteRenderInputParams) => React.ReactNode;
  renderOption?:
    | ((
        props: React.HTMLAttributes<HTMLLIElement>,
        option: Option,
        state: AutocompleteRenderOptionState
      ) => React.ReactNode)
    | undefined;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  onChange?: (
    event: React.SyntheticEvent<Element, Event>,
    value: string | Option | (string | Option)[] | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<Option | Option[]>
  ) => void;
  onSelect?: React.ReactEventHandler<HTMLDivElement>;
  onInputChange?: (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => void;
  PaperComponent?: React.ComponentType<React.HTMLAttributes<HTMLElement>>;
  PopperComponent?: React.ComponentType<PopperProps>;
}

const Autocomplete: React.FC<Props> = ({
  disableCloseOnSelect,
  freeSolo = false,
  inputValue,
  inputRef,
  getOptionLabel: handleOptionLabel,
  loading,
  multiple = false,
  open,
  openOnFocus = false,
  options,
  PaperComponent,
  PopperComponent,
  value,
  filterOptions,
  onBlur,
  onClose,
  onChange,
  onInputChange,
  onSelect,
  renderInput,
  renderOption,
  groupBy,
}) => {
  const { option } = useStyles();
  const intl = useIntl();
  const ListBox = React.useMemo(
    () =>
      ListboxComponent as React.ComponentType<
        React.HTMLAttributes<HTMLElement>
      >,
    []
  );

  const getOptionLabel = React.useCallback(
    o => handleOptionLabel?.(o) ?? o?.name ?? '',
    [handleOptionLabel]
  );

  const getOptionSelected = React.useCallback(
    ({ name }, val) => name === val?.name,
    []
  );

  return (
    <MuiAutocomplete
      open={open}
      freeSolo={freeSolo}
      loading={loading}
      openOnFocus={openOnFocus}
      clearOnBlur={false}
      clearOnEscape={false}
      selectOnFocus={false}
      clearIcon={<DeleteIcon />}
      multiple={multiple}
      PopperComponent={PopperComponent}
      PaperComponent={PaperComponent}
      disableListWrap
      onSelect={onSelect}
      onBlur={onBlur}
      disablePortal
      innerRef={inputRef}
      forcePopupIcon={false}
      onInputChange={onInputChange}
      limitTags={2}
      inputValue={inputValue}
      noOptionsText={intl.formatMessage(
        { id: Message.SEARCH_NO_RESULTS },
        { inputValue }
      )}
      ListboxComponent={ListBox}
      renderGroup={params => params}
      onClose={onClose}
      classes={{ option }}
      value={value}
      onChange={onChange}
      disableCloseOnSelect={disableCloseOnSelect}
      renderOption={renderOption}
      options={options}
      filterOptions={filterOptions}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      groupBy={groupBy}
      renderInput={renderInput}
    />
  );
};

export default React.memo(Autocomplete);

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  padding: 5px;
  padding-left: 8px;
  height: auto;
  .checkbox-input {
    margin-right: 15px;
  }
`;
