import { keyBy } from 'utils/lodash.utils';
import types from 'store/constants/user-management/suppliers.constants';
import { RootState } from 'store/types';
import { Supplier } from 'types/suppliers';

export const suppliersUsersSelector = (state: RootState) => state.suppliers;

export const allSuppliersUsersMap = (
  state: RootState
): Record<string, Supplier> => keyBy(suppliersUsersSelector(state).users, 'id');

export const allSuppliersUsers = (state: RootState) =>
  suppliersUsersSelector(state).users;

export const allSuppliersUsersPageCount = (state: RootState) =>
  suppliersUsersSelector(state).pageCount;
// SUPPLIER PRODUCTS
export const selectedSupplierProducts = (
  state: RootState,
  supplierID: string
) =>
  allSuppliersUsersMap(state)[supplierID]?.products || {
    pageCount: 0,
    results: [],
  };

// loading
// ALL USERS
export const allSuppliersUsersIsLoading = (state: RootState) =>
  suppliersUsersSelector(state).loading[types.GET_USERS_DATA_REQUEST];
export const supplierIsLoading = (state: RootState) =>
  suppliersUsersSelector(state).loading[types.GET_USER_DATA_REQUEST];
export const supplierOrdersIsLoading = (state: RootState) =>
  suppliersUsersSelector(state).loading[types.GET_ORDERS_DATA_REQUEST];
export const supplierFilesIsLoading = (state: RootState) =>
  suppliersUsersSelector(state).loading[types.GET_FILES_DATA_REQUEST];
// SUPPLIER PRODUCTS
export const supplierProductsIsLoading = (state: RootState) =>
  suppliersUsersSelector(state).loading[types.GET_PRODUCTS_DATA_REQUEST];

// error
// ALL USERS
export const allSuppliersUsersError = (state: RootState) =>
  suppliersUsersSelector(state).error[types.GET_USERS_DATA_REQUEST];
export const supplierError = (state: RootState) =>
  suppliersUsersSelector(state).error[types.GET_USER_DATA_REQUEST];
export const supplierOrdersError = (state: RootState) =>
  suppliersUsersSelector(state).error[types.GET_ORDERS_DATA_REQUEST];
export const supplierFilesError = (state: RootState) =>
  suppliersUsersSelector(state).error[types.GET_FILES_DATA_REQUEST];
// SUPPLIER PRODUCTS
export const supplierProductsError = (state: RootState) =>
  suppliersUsersSelector(state).error[types.GET_PRODUCTS_DATA_REQUEST];
