import * as React from 'react';
import { Link } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import styled, { css } from 'styled-components';
import rtl from 'styled-components-rtl';
import propertiesLivingRoom from 'assets/@client/properties/properties-living-room.png';
import propertiesLivingRoomMobile from 'assets/@client/properties/properties-living-room-mobile.png';
import Title from 'components/@client/TopTitle/Title';
import Button from 'components/@client/Button/Button';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import GradientImageCard, {
  GradientImageCardProps,
} from 'components/@client/GradientImageCard';
import { NO_PROPERTY_NAME } from 'constants/common.constants';
import {
  useAuthentication,
  useFetch,
  useNotifications,
  useRouter,
} from 'hooks';
import { ClientRouter } from 'router/routes';
import { MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';
import { summarizeText } from 'utils/string.utils';
import { PROPERTIES_STATUS, Property } from 'types/properties';
import TourDeviceNotSupportedModal from 'components/@client/TourDeviceNotSupportedModal/TourDeviceNotSupportedModal';
import IconButton, { IconName } from 'components/Button/IconButton';
import CustomTooltip from 'components/Tooltip';
import { RECENT_PROPERTY_ID } from 'constants/localstorage.constants';
import { saveLocalStorage } from 'utils/common.utils';
import { LogsAPI } from 'api/methods';
import { userLogData } from 'mockData/userLogData';
import { Edit, KeyboardArrowLeft } from '@material-ui/icons';
import { Type } from 'constants/config';
import { isWindows } from 'react-device-detect';
import AddCoOwner from 'pages/appClient/seller-property/my-projects/components/AddCoOwner';
import { Dialog, Menu } from '@material-ui/core';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ButtonBase, MenuItem } from '@mui/material';
import { Upload, ShareOutlined } from '@mui/icons-material';
import { METHODS } from 'api/client';
import EditPropertyName from './EditPropertyName';

interface Props extends GradientImageCardProps {
  label?: string;
  property: Property;
  onTourClick?: React.MouseEventHandler<HTMLButtonElement>;
  onMediaClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  updateProperty?: VoidFunction;
  showShare?: boolean;
}

const PropertyCard: React.FC<Props> = ({
  disabled,
  isLoading = false,
  property,
  onMediaClick,
  updateProperty,
  showShare,
}) => {
  const { query, history } = useRouter<{ id: string }>();
  const isMobile = useMediaQuery(MOBILE_TABLET_RESOLUTION);
  const [isOpen, setOpen] = React.useState(false);
  const propertyName = property.name?.length ? property.name : NO_PROPERTY_NAME;
  // eslint-disable-next-line
  let _window: any = window;
  const onCloseModal = () => {
    setOpen(false);
  };
  const { type } = useAuthentication();
  const tourLink =
    property.status === PROPERTIES_STATUS.Published
      ? ClientRouter.TOUR.replace(':propertyId', query.id)
      : ClientRouter.PROPERTY_IN_PROGRESS;
  const [isOpenCoOwnerForm, setIsOpenCoOwnerForm] = React.useState(false);

  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const isMenuOpen = Boolean(menuAnchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setMenuAnchorEl(null);
  };

  const [isUploadCSVOpen, setIsUploadCSVOpen] = React.useState(false);
  const inputFileRef = React.useRef<HTMLInputElement>(null);
  const { showNotification } = useNotifications();
  const { callFetch } = useFetch({
    initialUrl: `/api/properties/${property.id}/upload_products/`,
    config: {
      method: METHODS.POST,
    },
    skipOnStart: true,
    onFailure: err => {
      showNotification({
        key: 'upload-csv-err',
        message: err,
        severity: 'error',
      });
    },
    onSuccess: () => {
      setIsUploadCSVOpen(false);
      showNotification({
        key: 'upload-csv-success',
        message: 'Successfully uploaded product CSV',
        severity: 'success',
      });
    },
  });
  React.useEffect(() => {
    window.onscroll = handleClose;
    return () => {
      window.onscroll = null;
    };
  }, []);

  return (
    <Container>
      <TourDeviceNotSupportedModal />
      <HeaderContainer>
        <>
          {!isMobile && <Title title={propertyName} />}
          {!isMobile && (
            <CustomTooltip
              title={<FormattedMessage id={Message.EDIT_BUTTON} />}
              $variant="info"
            >
              <span className="icon-clients-action">
                <StyledIconButton
                  customIcon="edit"
                  $size="small"
                  iconSize={16}
                  iconName={IconName.custom_icon}
                  onClick={() => setOpen(true)}
                />
              </span>
            </CustomTooltip>
          )}
          {!isMobile && showShare && (
            // <CustomTooltip
            //   title={<FormattedMessage id={Message.PROPERTY_ADD_CO_OWNER} />}
            //   $variant="info"
            //   open={isToolTipMenuOpen}
            //   onClose={() => setIsToolTipMenuOpen(false)}
            // >
            //   {/* <ContentCopy
            //     className="icon-clients-action-share"
            //     onClick={e => {
            //       setIsOpenCoOwnerForm(true);
            //     }}
            //   /> */}
            //   <MoreVertIcon />
            // </CustomTooltip>

            <MenuRootDiv>
              <ButtonBase onClick={handleClick}>
                <MoreVertIcon />
              </ButtonBase>
              <Menu
                open={isMenuOpen}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                anchorEl={menuAnchorEl}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                // anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <CustomMenuItem
                  onClick={e => {
                    setIsOpenCoOwnerForm(true);
                  }}
                >
                  {/* <CustomTooltip
                    title={
                      <FormattedMessage id={Message.PROPERTY_ADD_CO_OWNER} />
                    }
                    $variant="info"
                  > */}
                  <ShareOutlined className="icon-clients-action-share" />
                  <FormattedMessage id={Message.PROPERTY_ADD_CO_OWNER} />
                  {/* </CustomTooltip> */}
                </CustomMenuItem>
                <CustomMenuItem onClick={() => setIsUploadCSVOpen(true)}>
                  <Upload />
                  <FormattedMessage id={Message.PROPERTY_UPLOAD_PRODUCT_CSV} />
                </CustomMenuItem>
              </Menu>
            </MenuRootDiv>
          )}
          {isMobile && (
            <MobileHeaderContainer>
              <CustomKeyboardArrow onClick={() => history.goBack()} />
              <span>{summarizeText(propertyName, 15)}</span>

              <div style={{ display: 'flex' }}>
                <CustomEditIcon onClick={() => setOpen(true)} />
                {showShare && (
                  <MenuRootDiv>
                    <ButtonBase onClick={handleClick}>
                      <MoreVertIcon />
                    </ButtonBase>
                    <Menu
                      open={isMenuOpen}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                      anchorEl={menuAnchorEl}
                      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                      // anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                      <CustomMenuItem
                        onClick={e => {
                          setIsOpenCoOwnerForm(true);
                        }}
                      >
                        {/* <CustomTooltip
                      title={
                        <FormattedMessage id={Message.PROPERTY_ADD_CO_OWNER} />
                      }
                      $variant="info"
                    > */}
                        <ShareOutlined className="icon-clients-action-share" />
                        <FormattedMessage id={Message.PROPERTY_ADD_CO_OWNER} />
                        {/* </CustomTooltip> */}
                      </CustomMenuItem>
                      <CustomMenuItem onClick={() => setIsUploadCSVOpen(true)}>
                        <Upload />
                        <FormattedMessage
                          id={Message.PROPERTY_UPLOAD_PRODUCT_CSV}
                        />
                      </CustomMenuItem>
                    </Menu>
                  </MenuRootDiv>
                )}
              </div>
            </MobileHeaderContainer>
          )}
          {isOpen && (
            <EditPropertyName
              open={isOpen}
              onClose={onCloseModal}
              propertyId={query.id}
              propertyName={property?.name ?? ''}
              updateProperty={updateProperty}
            />
          )}
        </>
      </HeaderContainer>
      <Wrapper
        onClick={() => {
          LogsAPI.postUserLogs({
            area: userLogData.eventArea.myProperties,
            section: userLogData.eventSection.propertyPage,
            name: userLogData.eventName.takeATour,
            path: userLogData.eventPath.appPropertiesIdPath,
            metadata: { property_id: query.id },
          });
          saveLocalStorage(RECENT_PROPERTY_ID, query.id);
          // history.push(tourLink);
        }}
      >
        <GradientImageCard
          isLoading={isLoading}
          imageUrl={
            isMobile ? propertiesLivingRoomMobile : propertiesLivingRoom
          }
        >
          <BottomSection>
            <Link to={tourLink} className="enter-home-btn">
              <Button className="enter-home-btn-main" disabled={disabled}>
                <FormattedMessage id={Message.PROPERTY_BUTTON_TAKE_TOUR} />
              </Button>
            </Link>
            {isWindows && type === Type.SELLER && (
              <Link
                to={`${tourLink}?openWindowBuild=true`}
                className="enter-home-btn"
              >
                <Button className="enter-home-btn-main" disabled={disabled}>
                  Open windows build
                </Button>
              </Link>
            )}
          </BottomSection>
        </GradientImageCard>
      </Wrapper>
      <Modal open={isOpenCoOwnerForm}>
        <AddCoOwner
          setIsOpenCoOwnerForm={setIsOpenCoOwnerForm}
          propertyId={property.id}
          co_owners={property.co_owner}
        />
      </Modal>
      <CustomModal
        open={isUploadCSVOpen}
        onClose={() => setIsUploadCSVOpen(false)}
      >
        <input
          ref={inputFileRef}
          type="file"
          onChange={e => {
            const formData = new FormData();
            formData.append('file', e.target.files?.[0] as Blob);
            callFetch({ data: formData });
          }}
        />
        <Button onClick={() => inputFileRef.current?.click()}>
          <FormattedMessage id={Message.PROPERTY_UPLOAD_PRODUCT_CSV} />
        </Button>
      </CustomModal>
    </Container>
  );
};

const CustomModal = styled(Dialog)`
  .MuiPaper-root {
    height: 50%;
    width: 50%;
    button {
      margin: auto;
    }
    input {
      display: none;
    }
  }
`;

const CustomMenuItem = styled(MenuItem)`
  svg {
    margin-right: 12px;
  }
`;

const MenuRootDiv = styled.div`
  position: absolute;
  right: 0;
  top: 10px;
  @media (max-width: 1024px) {
    position: initial;
  }
`;

const Modal = styled(Dialog)`
  .MuiDialog-paper {
    border-radius: 15px;
    overflow-y: hidden !important;
  }
  .client-dialog .MuiDialog-paper {
    overflow-y: hidden;
  }
`;

export default PropertyCard;

const CustomKeyboardArrow = styled(KeyboardArrowLeft)`
  font-size: 36px;
  ${({ theme }) =>
    theme.dir === 'rtl' &&
    css`
      rotate: 180deg;
    `}
`;
const CustomEditIcon = styled(Edit)`
  font-size: 24px;
`;

const BottomSection = styled.div`
  position: relative;
  ${({ theme }) => theme.utils.centerFlex};
  height: 100%;
  flex-direction: column;
  a {
    text-decoration: none;
  }

  && .enter-home-btn-main {
    width: 320px;
    height: 64px;
    font-size: 24px;
    @media (max-width: 800px) {
      width: 203px;
      height: 40px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      border-radius: 10px;
    }
  }

  @media ${MOBILE_TABLET_RESOLUTION} {
    position: absolute;
    bottom: calc(50% - 20px);
    width: 100%;
    height: unset;
    text-align: center;
  }
`;

const Container = styled.section`
  @media (min-width: 800px) {
    margin: 14px 160px 0px 160px;

    & .gradient-image-container {
      height: 446px;
      flex: 1;
      margin-top: 12px;
    }
  }
`;

const MobileHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 12px 12px 4px;
  & > span {
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
  }
`;

const Wrapper = styled.section`
  position: relative;
  display: flex;
  & .gradient-image-container {
    @media (max-width: 800px) {
      height: 224px;
      border-radius: 0;
    }
  }
  & .gradient-image {
    @media (max-width: 800px) {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    }
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15));
  }
  .enter-home-btn {
    display: flex;
    justify-content: center;
    border-radius: 14px;
    margin: 4px 0;
    &:hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));

      button {
        background-color: #a50a0a;
      }
    }
  }
  & .media-button {
    position: absolute;
    bottom: 18px;
    ${rtl`
        right: 13px;
    `}
  }

  & .back-button {
    mix-blend-mode: unset;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  position: relative;
  .client-top-title {
    width: auto;
  }
  > span {
    margin: auto 0;
  }
  .title {
    line-height: 28px;
    font-size: 24px;
    margin: -48px 0px 0px 0px !important;
  }
  .icon-clients-action-share {
    margin-left: 24px;
    margin-right: 24px;
    margin-top: 6px;
    cursor: pointer !important;
    &:hover {
      circle {
        fill: rgba(0, 0, 0, 0.04);
        r: 20;
      }
    }
  }
`;

const StyledIconButton = styled(IconButton)`
  ${rtl`margin-left: 24px !important;`}
`;
