import * as React from 'react';
import styled from 'styled-components';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';
import { skins as transformSkin } from 'api/transform';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import * as Response from 'api/responses';
import type { Skin, SkinFilter } from 'types/properties';
import { useFetch } from 'hooks';
import { removeEmptyValues } from 'utils/common.utils';
import MobileHeader from 'components/@client/MobileHeader';
import Header from './Header';
import SkinsContainer from './SkinsContainerNew';
import SkinsContainerMobile from './SkinsContainerMobile';
import Controller from './Controller';

const transformResponse = (data: Response.Skins) =>
  (data?.results ? transformSkin(data?.results) : []) as Skin[];

const Skins = () => {
  const { isLoading, data: groupedSkinListItems, callFetch } = useFetch<
    Skin[],
    Response.Skins
  >({
    initialUrl: `/api/skins/`,
    skipOnStart: true,
    transform: transformResponse,
  });
  const [filterState, setFilterState] = React.useState<SkinFilter>({
    ordering: '-staff_pick,-updated_at',
  });
  const onFilter = React.useCallback(
    (newFilter: SkinFilter) => {
      setFilterState(prevState => ({ ...prevState, ...newFilter }));
    },
    [setFilterState]
  );

  React.useEffect(
    () =>
      callFetch({
        params: removeEmptyValues({ ...filterState, is_public: true }),
      }),
    [filterState, callFetch]
  );

  const isMobile = useMediaQuery(MOBILE_TABLET_RESOLUTION);

  return (
    <Container>
      {isMobile && (
        <MobileHeader title={<FormattedMessage id={Message.SKINS_TITLE} />} />
      )}
      <Header />
      <Content>
        <Controller updateView={onFilter} />
        {isMobile ? (
          <SkinsContainerMobile
            isLoading={isLoading}
            groupedSkinListItems={groupedSkinListItems ?? []}
          />
        ) : (
          <SkinsContainer
            isLoading={isLoading}
            groupedSkinListItems={groupedSkinListItems ?? []}
          />
        )}
      </Content>
    </Container>
  );
};

export default Skins;

const Container = styled.section`
  margin-top: 0;
  .skin-controller {
    margin-top: 0;
    align-items: center;
    justify-content: center;
  }
  .mobile-header {
    position: fixed;
    z-index: 10;
    background-color: #fff;
    width: 100%;
    height: 64px;
    margin: 0px;
  }
`;

const Content = styled.section`
  /* width: 100%;
  margin: 5px auto;
  max-width: 1600px; */
`;
