enum MyDatabaseDataType {
  GET_PRODUCTS_DATA_REQUEST = 'suppliersDatabase/getProductsDataRequest',
  GET_PRODUCTS_DATA_FULFILLED = 'suppliersDatabase/getProductsDataFulfilled',
  GET_PRODUCTS_DATA_REJECTED = 'suppliersDatabase/getProductsDataRejected',
  GET_FILES_DATA_REQUEST = 'suppliersDatabase/getFilesDataRequest',
  GET_FILES_DATA_FULFILLED = 'suppliersDatabase/getFilesDataFulfilled',
  GET_FILES_DATA_REJECTED = 'suppliersDatabase/getFilesDataRejected',
}

export default MyDatabaseDataType;
