import * as React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { useFetch } from 'hooks';
import { METHODS } from 'api/client';
import * as Transform from 'api/transform';
import { Category, Product } from 'types/products';
import Loader from 'components/Loader';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import NoProduct from 'components/@client/NoProduct/NoProduct';
import { ProductCategoriesItem } from 'components/@client/HorizontalCarousel/CarouselItems';
import { sortBy } from 'utils/lodash.utils';
import { LinkProps } from 'react-router-dom';
import Breadcrumbs from 'components/@client/Breadcrumbs/Breadcrumbs';
import { userLogData } from 'mockData/userLogData';
import { LogsAPI } from 'api/methods';

import BestSellerCard from './BestSellerCard';
import { CategoryText } from './ProductCategory';

interface Props {
  categoryOptions: Category[] | [];
  links: LinkProps[];
}

const DesktopHomePage: React.FC<Props> = ({ categoryOptions, links }) => {
  const { data: randomProducts, callFetch, isLoading } = useFetch({
    initialUrl: `api/products/`,
    skipOnStart: true,
    config: {
      method: METHODS.GET,
      params: {
        size: 12,
        page: 1,

        ordering: '?',
      },
    },
    transform: Transform.PaginatedProducts,
  });

  React.useEffect(() => {
    callFetch({ params: { page: 1, size: 12, ordering: '?' } });
  }, [callFetch]);

  return (
    <Container>
      <BreadcrumbsContainer>
        <Breadcrumbs links={links} />
      </BreadcrumbsContainer>
      <CategoryText>
        <FormattedMessage id={Message.SHOP_BY_DEPARTMENT} />
      </CategoryText>
      <CategoryContainer>
        <Grid container>
          {categoryOptions &&
            sortBy(categoryOptions, 'list_number').map((data: Category) => {
              return (
                <Grid item lg={3} md={4} sm={6} key={data.name}>
                  <ProductCategoriesItem key={data.id} item={data} />
                </Grid>
              );
            })}
        </Grid>
      </CategoryContainer>
      <CategoryText>
        <FormattedMessage id={Message.PATRICIA_BEST_RECOMMENDED} />
      </CategoryText>
      {isLoading ? (
        <Loader />
      ) : (
        <Grid container>
          {randomProducts?.results.length ? (
            randomProducts.results.map((data: Product) => (
              <Grid item md={3} sm={3} key={data.name}>
                <BestSellerCard
                  product={data}
                  onClick={() =>
                    LogsAPI.postUserLogs({
                      name: userLogData.eventName.product,
                      area: userLogData.eventArea.catalog,
                      section: userLogData.eventSection.bestSeller,
                      path: userLogData.eventPath.appProductsCategoryNamePath,
                      metadata: {
                        product_id: data.id,
                        product_name: data.name,
                        product_sku: data.sku,
                      },
                    })
                  }
                />
              </Grid>
            ))
          ) : (
            <NoProduct value={Message.NO_PRODUCT_AVAILABLE} />
          )}
        </Grid>
      )}
    </Container>
  );
};

export default DesktopHomePage;

const Container = styled.div`
  width: 100%;
`;

const CategoryContainer = styled.div`
  margin: 0 20px;
`;

const BreadcrumbsContainer = styled.div`
  margin: 20px 16px 0px;
`;
