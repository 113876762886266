import { useIntl } from 'react-intl';
import PrivacyPolicyEn from './PrivacyPolicyEn';
import PrivacyPolicyHe from './PrivacyPolicyHe';

interface Props {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}
const TermsOfUse: React.FC<Props> = () => {
  const intl = useIntl();
  if (intl.locale === 'en') return <PrivacyPolicyEn />;
  return <PrivacyPolicyHe />;
};

export default TermsOfUse;
