import * as React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Slide from '@material-ui/core/Slide';
import styled, { css } from 'styled-components';
import Modal, { ModalProps } from 'components/@client/Modal';
import { OnboardingAction, useOnboarding } from 'context/onboardingContext';
import { useRouter, useAuthentication, useFetch } from 'hooks';
import { MOBILE_RESOLUTION } from 'styles/sharedStyle';
import { LogsAPI } from 'api/methods';
import { userLogData } from 'mockData/userLogData';
import { QuizResult } from 'types/quizzes';
import * as Response from 'api/responses';
import * as Transform from 'api/transform';
import { ClientRouter } from 'router/routes';
import { METHODS } from 'api/client';
import { PROPERTIES_STATUS } from 'types/properties';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import rtl from 'styled-components-rtl';
import { useIntl } from 'react-intl';
import { KeyboardArrowRight } from '@material-ui/icons';
import useUploadPlan from 'pages/appClient/onboarding/upload-plan/useUploadPlan';
import ConfirmPlanSeller from './ConfirmPlanSeller';
import ConfirmPlan from './ConfirmPlan';
import SelectFloor from './SelectFloor';
import UploadPlan from './UploadPlan';
// eslint-disable-next-line import/extensions
import UploadPlanSeller from './UploadPlanSeller';
import ClientInformation from './ClientInformation';
import DemoFloorPlansSeller from './DemoFloorPlansSeller';

interface Props extends ModalProps {}

const AddProperty: React.FC<Props> = ({ open }) => {
  const { history, pathname } = useRouter();
  const { type } = useAuthentication();
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const isMobile = useMediaQuery(MOBILE_RESOLUTION);
  const [
    { selectedQuizResult, propertyId, selectedFloor },
    dispatch,
  ] = useOnboarding();
  const { data: quizData, isLoading: isLoadingQuizzes } = useFetch<
    QuizResult[],
    { results: Response.QuizResult[] }
  >({
    initialUrl: '/api/quizzes_results/?page=1&size=1',
    transform: Transform.paginatedQuizResults,
  });
  const intl = useIntl();
  const {
    setImage,
    setNewPropertyType,
    setSelectedFloor,
    newPropertyType,
  } = useUploadPlan();
  const quizzes = React.useMemo(
    () =>
      (quizData ?? [])?.map(quizResult => ({
        id: quizResult.id ?? '0',
        name: quizResult.quizName ?? '',
        result: quizResult.designStyle?.name ?? '',
      })),
    [quizData]
  );

  React.useEffect(() => {
    if (quizzes)
      dispatch({
        type: OnboardingAction.SET_SELECTED_QUIZ_RESULT,
        payload: Number(quizzes?.[0]?.id),
      });
  }, [dispatch, quizzes]);

  const onSuccess = React.useCallback(() => {
    const html = document.querySelector('html') as HTMLHtmlElement;
    html.style.overflowY = '';

    history.push(ClientRouter.PROPERTIES);
  }, [history]);

  const { isLoading: isAddingProperty, callFetch } = useFetch({
    initialUrl: `/api/properties/${propertyId}/`,
    skipOnStart: true,
    onSuccess,
  });

  const addProperty = React.useCallback(() => {
    callFetch({
      method: METHODS.PATCH,
      url: `/api/properties/${propertyId}/`,
      data: {
        quiz_result: selectedQuizResult,
        primary_file: selectedFloor?.id,
        status: PROPERTIES_STATUS['To do'],
      },
    });
    setImage(null);
    setNewPropertyType('');
    setSelectedFloor(null);
  }, [
    callFetch,
    propertyId,
    selectedFloor?.id,
    selectedQuizResult,
    setImage,
    setNewPropertyType,
    setSelectedFloor,
  ]);

  const next = React.useCallback(() => {
    LogsAPI.postUserLogs({
      name: userLogData.eventName.nextStep,
      area: userLogData.eventArea.myProperties,
      section: userLogData.eventSection.properties,
      path: userLogData.eventPath.appModalAddPropertyPath,
    });
    if (newPropertyType === 'upload' && activeStep === 0) {
      setActiveStep(prevState => prevState + 2);
    } else {
      setActiveStep(prevState => prevState + 1);
    }
  }, [newPropertyType, activeStep]);

  const prev = React.useCallback(
    (startOver?: boolean) => {
      LogsAPI.postUserLogs({
        name: userLogData.eventName.stepBack,
        area: userLogData.eventArea.myProperties,
        section: userLogData.eventSection.properties,
        path: userLogData.eventPath.appModalAddPropertyPath,
      });
      if (newPropertyType === 'upload') {
        setActiveStep(prevState => prevState - 2);
      } else {
        setActiveStep(prevState => (startOver ? 0 : prevState - 1));
      }
    },
    [newPropertyType]
  );

  const close = React.useCallback(() => {
    LogsAPI.postUserLogs({
      area: userLogData.eventArea.myProperties,
      section: userLogData.eventSection.properties,
      name: userLogData.eventName.xCloseButton,
      path: `${history.location.pathname}${history.location.search}`,
    });
    setActiveStep(0);
    history.push(pathname);
  }, [history, pathname]);

  const steps = React.useMemo(
    () =>
      type === 'SELLER'
        ? [
            <UploadPlanSeller key="upload-plan" next={next} />,
            <DemoFloorPlansSeller key="upload-plan" next={next} prev={prev} />,
            <ConfirmPlanSeller
              key="confirm-plan-seller"
              next={next}
              prev={prev}
              isLoading={isAddingProperty || isLoadingQuizzes}
            />,
            <ClientInformation key="final-plan" onClose={close} />,
          ]
        : [
            <UploadPlan key="upload-plan" next={next} />,
            <SelectFloor key="select-floor" next={next} prev={prev} />,
            <ConfirmPlan
              key="confirm-plan"
              next={addProperty}
              prev={prev}
              isLoading={isAddingProperty || isLoadingQuizzes}
            />,
            // <SelectQuiz key="select-quiz" next={next} />,
            // <QuizQuestion key="quiz-question" next={next} />,
            // <QuizSummary key="quiz-summary" />,
          ],
    [type, next, close, prev, addProperty, isAddingProperty, isLoadingQuizzes]
  );

  const modalProp =
    type === 'SELLER'
      ? {
          open,
          disableClose: isMobile,
          isFooterBtn: false,
          onClose: () => {
            setImage(null);
            setNewPropertyType('');
            setSelectedFloor(null);
            close();
          },
        }
      : {
          fullWidth: true,
          fullScreen: isMobile,
          actions: [],
          open,
          onClose: close,
          disableClose: isMobile,
        };

  return (
    <CustomModal type={type} TransitionComponent={Slide} {...modalProp}>
      {isMobile && (
        <CustomArrowContainer onClick={() => history.goBack()}>
          {intl.locale === 'he' ? (
            <KeyboardArrowRight fontSize="large" />
          ) : (
            <KeyboardArrowLeftIcon fontSize="large" />
          )}
        </CustomArrowContainer>
      )}
      {steps[activeStep]}
      {/* <OnboardingContextProvider>{steps[activeStep]}</OnboardingContextProvider> */}
    </CustomModal>
  );
};

export default AddProperty;

const CustomModal = styled(Modal)<{
  type: string | null | undefined;
}>`
  &&.client-dialog .MuiDialog-paper {
    .MuiSvgIcon-root {
      font-size: 30.86px;
    }
    min-width: 600px;
    padding: 10px 24px 10px 24px;
    max-width: fit-content;
    @media (min-width: 1000px) {
      ${({ type }) =>
        type !== 'SELLER' &&
        type !== 'SUPPLIER' &&
        css`
          width: 598px;
          max-width: 598px;
          height: 600px;
          padding: 0;
        `}
    }
    @media (max-height: 700px) {
      .upload-plan-container {
        height: 620px;
      }
    }
    @media (max-width: 800px) {
      border-radius: 20px;
      max-width: 928px;
      max-height: 90vh;
    }
    @media (max-width: 820px) {
      border-radius: 20px;
      max-width: 928px;
      width: 928px;
    }
  }
  &.client-dialog .MuiDialog-paper .MuiDialogTitle-root {
    margin: 0;
  }

  && .MuiDialogContent-root {
    margin: 0;
    overflow: overlay;
    @media (max-width: 380px) {
      padding: 8px 0px !important;
    }
    padding: 8px 8px !important;
    ${({ theme }) => theme.utils.scrollbar};
  }
  &&.client-dialog .MuiDialog-paper .MuiDialogActions-root {
    padding: 0;
  }
`;

const CustomArrowContainer = styled.div`
  position: absolute;
  top: 25px;
  ${rtl`left: 10px;`}
`;
