import * as React from 'react';
import Counter from 'components/Counter';
import styled from 'styled-components';

interface Props {
  label?: string;
  count: number;
}

const CountPlaceholder: React.FC<Props> = ({ label = 'Filtered', count }) => {
  if (!count) return null;
  return (
    <Container>
      <Label>{label}</Label>
      <Counter count={count} />
    </Container>
  );
};

export default CountPlaceholder;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
`;

const Label = styled.span`
  color: ${({ theme }) => theme.colors.primary.dark};
`;
