import * as React from 'react';
import { Document, Text, View } from '@react-pdf/renderer';
import { PageContent, Footer } from 'documents/components';
import { Order } from 'types/orders';
import Header from './Header';
import ClientInfo from './ClientInfo';
import ProductsTable from './ProductsTable';
import Totals from './Totals';

interface Props {
  orders: Order[];
}

const OrderDetailsDocument: React.FC<Props> = ({ orders }) => {
  return (
    <Document subject="Order details" title="Order details">
      {orders.map(order => (
        <PageContent key={order.id}>
          <Header {...order} />
          <ClientInfo user={order.client} order={order} />
          <ProductsTable order={order} />
          <Totals order={order} />
          <View
            style={{
              height: '40px',
              textAlign: 'center',
              position: 'absolute',
              bottom: '73px',
              width: '100%',
            }}
          >
            <Text
              style={{ fontWeight: 500, fontSize: 26, textAlign: 'center' }}
            >
              THANK YOU
            </Text>
          </View>
          <Footer />
        </PageContent>
      ))}
    </Document>
  );
};

export default OrderDetailsDocument;
