import * as React from 'react';
import styled, { css } from 'styled-components';
import type { MediaFile } from 'types/common.types';
import SafeImg from 'components/SafeImg/SafeImg';
import { Check } from '@material-ui/icons';
import rtl from 'styled-components-rtl';
import { MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';

export interface SlideItem {
  answer: MediaFile;
  name?: string;
  withLabel?: boolean;
  withColors?: string[];
  onClickItem?: () => void;
  selectedAnswer: boolean;
}

const Slide: React.FC<SlideItem> = ({
  answer,
  name = 'Answer',
  withLabel,
  withColors = [],
  onClickItem,
  selectedAnswer,
}) => {
  return (
    <Container
      withLabel={withLabel}
      withColors={Boolean(withColors.length)}
      onClick={() => onClickItem?.()}
      selectedAnswer={selectedAnswer}
    >
      <div className="checkContainer">
        <Check />
      </div>
      <SafeImg
        src={answer?.file}
        alt={name}
        style={{ opacity: selectedAnswer ? 0.4 : 1 }}
      />
      {withColors && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          {withColors.map(e => (
            <div
              style={{
                width: `43px`,
                height: 42,
                background: e,
                margin: '4px',
                borderRadius: '10px',
              }}
              key={e}
            />
          ))}
        </div>
      )}
      {withLabel && <p>{name}</p>}
    </Container>
  );
};

export default React.memo(Slide);

const Container = styled.div.attrs({
  className: 'quiz-answer-slide',
})<{ withLabel?: boolean; withColors: boolean; selectedAnswer: boolean }>`
  max-width: 300px;
  min-width: 270px;
  border: ${({ selectedAnswer }) =>
    selectedAnswer ? ' 2px solid #fc5c42' : '1px solid #f7f7f7'};
  ${({ withColors }) =>
    !withColors &&
    css`
      height: 100%;
    `}
  border-radius: 25px;
  background: #f7f7f7;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  gap: 0px;
  padding: 0px 0px;
  @media (max-width: 500px) {
    width: 241px;
    height: 312px;
    object-fit: cover;
  }

  .checkContainer {
    height: 24px;
    width: 24px;
    position: absolute;
    top: 12px;
    ${rtl`right: 28px;`}
    @media ${MOBILE_TABLET_RESOLUTION} {
      ${rtl`right: 18px;`}
    }
    border-radius: 12px;
    z-index: 9;
    background-color: #fc5c42;
    display: ${({ selectedAnswer }) => (selectedAnswer ? 'block' : 'none')};
    svg {
      width: 15px;
      height: 23px;

      path {
        stroke: white;
        fill: white;
      }
    }
  }
  & > img {
    height: ${({ withLabel = false }) => (withLabel ? 70 : 100)}%;
    border-radius: 25px;
    width: 241px;
    object-fit: cover;
    height: ${({ withColors }) => (withColors ? '235px' : '100%')};
  }

  & > p {
    color: #5b7971;
    font-size: 1.25rem;
    font-weight: 900;
  }
`;
