import { Grid } from '@material-ui/core';
import { ReactSVG } from 'react-svg';
import styled, { css } from 'styled-components';
import { MOBILE_RESOLUTION, TABLET_RESOLUTION } from 'styles/sharedStyle';
import { WorkProps, NavigationTab } from './InstructionsModal.config';

const TabContentItem: React.FC<{
  item: WorkProps;
  type: string;
  key: string;
}> = ({ type, item, key }) => {
  const itemCount = type === NavigationTab.DESIGN ? 4 : 3;
  switch (type) {
    case NavigationTab.WORK:
      return (
        <GridItem item md={4}>
          <ListItem>
            <ListText>{item.title}</ListText>
            <Image src={item.icon} />
            {item.image && <Image src={item.image} />}
          </ListItem>
        </GridItem>
      );

    default:
      return (
        <GridItem item md={itemCount}>
          <ListItem>
            <Image src={item.image} type={true} />
            <Title>{item.title}</Title>
            <ListText>{item.description}</ListText>
          </ListItem>
        </GridItem>
      );
  }
};

const TabContent: React.FC<{ list: WorkProps[]; type: string }> = ({
  list,
  type,
}) => {
  return (
    <List container>
      {list.map((item, key) => {
        return <TabContentItem item={item} type={type} key={key.toString()} />;
      })}
    </List>
  );
};

export default TabContent;

interface IListItemProps {
  width?: string;
  type?: string;
}

const List = styled(Grid)`
  padding: 0;
  margin: 30px auto 30px;
  list-style: none;
`;
const GridItem = styled(Grid)`
  text-align: center;
`;
const ListItem = styled.div<IListItemProps>`
  padding: 20px 10px 10px 10px;
  position: relative;
  width: ${props => (props.width ? props.width : 'auto')};

  display: inline-block;
  margin: 0 0 20px 20px;
  vertical-align: top;
  box-sizing: border-box;
  min-height: 300px;
  text-align: center;
  @media ${TABLET_RESOLUTION} {
    width: 33.33%;
    margin: 0 0 20px 0px;
    min-height: 300px;
  }
  @media ${MOBILE_RESOLUTION} {
    width: 100%;
    margin: 0 0 20px 0px;
    min-height: 220px;
  }
`;
const ListText = styled.span<{
  type?: boolean;
}>`
  display: block;
  padding: 9px 0 0 0px;
  vertical-align: top;
  text-align: center;
  height: 85px;
  font-size: 20px;
  color: #ffffff;
  line-height: 145%;
  @media ${TABLET_RESOLUTION} {
    font-size: 20px;
    padding: 70px 0 0 0px;
    height: auto;
  }
  @media ${MOBILE_RESOLUTION} {
    font-size: 13px;
    padding: 9px 0 0 0px;
    height: auto;
  }
  ${({ type }) =>
    type &&
    css`
      height: auto;
    `}
`;
const Image = styled(ReactSVG)<{
  type?: boolean;
}>`
  vertical-align: top;
  display: block;
  position: relative;
  right: 0;
  top: 8px;
  text-align: center;
  margin: 0 auto 40px;
  vertical-align: top;
  & path {
    background: linear-gradient(
      123.09deg,
      #ffffff 12.39%,
      rgba(255, 255, 255, 0) 126.25%
    );
  }
  ${({ type }) =>
    type &&
    css`
      margin: 0 auto 20px;
      background: linear-gradient(
        137.45deg,
        rgba(255, 255, 255, 0.4) -12.51%,
        rgba(255, 255, 255, 0) 88.84%
      );
      border-radius: 60px;
      width: 80px;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
    `}
`;
const Title = styled.span`
  font-family: ${({ theme }) => (theme.dir === 'rtl' ? 'Assistant' : 'Roboto')};
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 145%;
  color: #ffffff;
  opacity: 0.9;
`;
