import * as React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { GoogleAPI } from 'constants/config';
import { loadScript } from 'utils/common.utils';
import { isEmpty } from 'utils/lodash.utils';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import {
  MIN_TABLET_RESOLUTION,
  MOBILE_RESOLUTION,
  MOBILE_TABLET_RESOLUTION,
} from 'styles/sharedStyle';
import InputAdornment from '@material-ui/core/InputAdornment';
import DeleteIcon from 'components/DeleteIcon';
import type { CountryRecord } from 'data/countries';
import FormLabel, { ILabel } from '../FormLabel';

interface Props {
  countryISO?: CountryRecord | null;
  required?: boolean;
  name: string;
  placeholder?: string;
  width?: number;
  label: ILabel;
}

const SearchLocationInput = ({
  countryISO,
  name,
  required,
  placeholder = 'Select city',
  label = { title: 'City' },
  width,
}: Props) => {
  const autoCompleteRef = React.useRef<HTMLInputElement>(null);
  const autoCompleteService = React.useRef<google.maps.places.Autocomplete>();
  const [scriptIsReady, setLoaded] = React.useState<boolean>(false);
  const country = countryISO?.iso || null;
  const { control, setValue } = useFormContext();
  const {
    field: { ref: _ref, ...inputProps },
    meta: { invalid },
  } = useController({
    name,
    control,
  });

  const handlePlaceChange = React.useCallback(() => {
    const addressObject = autoCompleteService?.current?.getPlace();
    setValue(name, addressObject?.formatted_address?.split(',')[0] ?? '');
  }, [setValue, name]);

  React.useEffect(() => {
    if (!window.google) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GoogleAPI}&libraries&libraries=places`,
        () => {
          setLoaded(true);
        }
      );
    }
  }, []);

  React.useEffect(() => {
    if (scriptIsReady && window.google && !autoCompleteService.current) {
      autoCompleteService.current = new window.google.maps.places.Autocomplete(
        autoCompleteRef.current as HTMLInputElement,
        {
          types: ['(cities)'],
          componentRestrictions: { country: country ?? [] },
        }
      );
      autoCompleteService.current.setFields([
        'address_components',
        'formatted_address',
      ]);
      autoCompleteService.current.addListener(
        'place_changed',
        handlePlaceChange
      );
    }
  }, [scriptIsReady, country, handlePlaceChange]);

  React.useEffect(() => {
    if (autoCompleteService.current && country) {
      autoCompleteService.current.setOptions({
        componentRestrictions: { country },
      });
    }
  }, [country]);

  const onInputClear = React.useCallback(() => {
    setValue(name, '');
  }, [setValue, name]);

  const disabled = !countryISO;
  return (
    <Root className="search-location-input" width={width}>
      <FormLabel
        label={label}
        htmlFor={name}
        required={required}
        isInvalid={invalid}
        className="google-maps-label"
      />
      <input
        ref={autoCompleteRef}
        {...inputProps}
        onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
        placeholder={placeholder}
        autoComplete="nope"
        disabled={disabled}
      />
      {!isEmpty(inputProps.value) && (
        <InputAdornment position="end" className="delete-icon">
          <DeleteIcon onClick={onInputClear} />
        </InputAdornment>
      )}
    </Root>
  );
};

export default SearchLocationInput;

const Root = styled.div<{ width?: number }>`
  position: relative;
  & input {
    background: #ffffff;
    font-size: 14px;
    padding: 10px 12px;
    height: 48px;
    border: 1px solid ${({ theme }) => theme.colors.darkGray};
    border-radius: 10px;
    min-width: ${({ width }) => (width ? `${width}px` : '100%')};
    &:focus {
      outline: none;
    }
    &:disabled {
      background: #f7f7f7;
    }

    &:hover {
      border-color: #000000;
    }
  }
  & .delete-icon {
    position: absolute;
    ${rtl` 
    top: 36px;
    right: 2%;       
    `}
    @media ${MIN_TABLET_RESOLUTION} {
      ${rtl` 
      right: 2%;       
       `}
    }
    @media ${MOBILE_TABLET_RESOLUTION} {
      ${rtl` 
      right: 2%; 
       `}
    }
    @media ${MOBILE_RESOLUTION} {
      ${rtl`           
          left: 88%;      
       `}
    }
  }
`;
