import * as React from 'react';
import { ResponseProjectDetail } from 'api/responses';
import useFetch from 'hooks/useFetch';

const useProjectOptions = () => {
  const { data: projects, isLoading: loadingUsersList } = useFetch<
    ResponseProjectDetail[]
  >({
    initialUrl: '/api/projects/',
  });

  const projectOptions = React.useMemo(() => {
    return projects?.map(({ id, name }) => {
      return { name, value: id.toString(), id: id.toString() };
    });
  }, [projects]);

  return { projectOptions, loadingUsersList };
};

export default useProjectOptions;
