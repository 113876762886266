import * as React from 'react';
import { Option } from 'components/Autocomplete/Autocomplete.config';
import { countryOptions, CountryRecord } from 'data/countries';
import { stateOptions } from 'data/states';
import { IAddress } from 'types/common.types';
import { isUSA, isIsrael } from 'utils/addresses.utils';
import { alphaNumericRegExp, zipNumericRegExp } from 'utils/validation.utils';

const useUpdateAddress = (entries: IAddress, defaultValues: IAddress) => {
  const [country, setCountry] = React.useState<Option | null>(
    () => countryOptions.find(option => option.iso === entries.country) ?? null
  );

  const [state, setState] = React.useState<Option | null>(() => {
    return stateOptions.find(option => option.iso === entries.state) ?? null;
  });
  const isSelectedIsrael = isIsrael(entries.country);
  const isStateSelectDisabled = !isUSA(entries.country);
  React.useEffect(() => {
    setCountry(
      (countryOptions as CountryRecord[]).find(
        option =>
          option.iso === (defaultValues as IAddress)?.country ||
          option.name === (defaultValues as IAddress)?.country
      ) ?? null
    );
    setState(
      stateOptions.find(
        option => option.iso === (defaultValues as IAddress)?.state
      ) ?? null
    );
  }, [defaultValues]);

  const handlePaste = React.useCallback((e: React.ClipboardEvent) => {
    e.preventDefault();
  }, []);

  const handleAlphaNumericField = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (!e.key.match(alphaNumericRegExp)) {
        e.preventDefault();
      }
    },
    []
  );
  React.useEffect(() => {
    if (country?.iso !== 'US') setState(null);
  }, [country]);
  const handleNumericField = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (!e.key.match(zipNumericRegExp)) e.preventDefault();
    },
    []
  );
  return {
    country,
    setCountry,
    state,
    setState,
    isStateSelectDisabled,
    isSelectedIsrael,
    handlePaste,
    handleAlphaNumericField,
    handleNumericField,
  };
};

export default useUpdateAddress;
