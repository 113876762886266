import { IAddress, KPI } from './common.types';

export enum Department {
  Business_Development = 'Business Development',
  IT_AND_SUPPORT = 'IT and Support',
  FINANCIAL = 'Financial',
  MARKETING = 'Marketing',
  R_AND_D = 'R&D',
  HR = 'HR',
  MANAGEMENT = 'Management',
  SALES = 'Supplier’s sales',
}

export type DepartmentInfo = {
  id: string;
  name: `${Department}`;
};

export type RoleTypeKeys = keyof typeof RolePermission;

export enum RolePermission {
  USER_MANAGEMENT = 'User Management',
  PROPERTIES_MANAGEMENT = 'Properties Management',
  MANAGEMENT_3D_CONTENT = '3D Content Management',
  SUPPLIER_SERVICE = 'Supplier Service',
  QUIZ_MANAGEMENT = 'Quiz Management',
}

export type RoleInfo = {
  name: string;
  id: string;
  roleType: RolePermission[];
  department: string;
  permissions?: string[];
};

export type RoleTypeFieldArray = {
  id?: string;
  role: RolePermission;
};

export type TeamMember = {
  id: string;
  userID: string;
  avatar: string | null;
  lastName: string;
  firstName: string;
  email: string;
  phoneNumber: string;
  country: string;
  addresses: IAddress[] | null;
  department: string;
  role: RoleInfo;
  isArchived?: boolean;
  kpiInfo?: KPI[];
};

export const roles = Object.values(RolePermission);
