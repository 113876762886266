import * as React from 'react';
import { usePDF } from '@react-pdf/renderer';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import styled from 'styled-components';
import { Message } from 'i18n';
import { FormattedMessage, useIntl } from 'react-intl';
import rtl from 'styled-components-rtl';
import { clientOrder } from 'api/transform';
// import { ReactComponent as Tag } from 'assets/@client/orders/Tag.svg';
import Breadcrumbs from 'components/@client/Breadcrumbs';
import Title from 'components/@client/TopTitle/Title';
import Link from 'components/Link';
import OrderDetailsDocument from 'documents/OrderDetails/OrderDetails.doc';
import { useFetch, useRouter } from 'hooks';
import { ClientRouter } from 'router/routes';
import { OrderHeading } from 'components/@client/Orders';
import DateField from 'components/@client/DateField';
import Loader from 'components/Loader/Loader';
import NotFound from 'components/@client/NotFound';
import InfoBox from 'components/@client/InfoBox';
import AddressCard from 'components/@client/AddressCard/AddressCard';
import List from 'components/@client/ProductsSection/List';
import Button from 'components/@client/Button';
import SecondaryButton from 'components/@client/Button/SecondaryButton';
import {
  MOBILE_RESOLUTION,
  MOBILE_TABLET_RESOLUTION,
  TABLET_RESOLUTION,
} from 'styles/sharedStyle';
import { OrderStatus, Order as IOrder } from 'types/orders';
import { userLogData } from 'mockData/userLogData';
import { LogsAPI } from 'api/methods';
import ProductPageModal from 'pages/appClient/productPage/component/ProductPageModal';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { ReactComponent as Discovery } from './Discovery.svg';
import SummaryField from './SummaryField';
import OrderProductItem from './OrderProductItem';
import OrderCancelModal from './OrderCancelModal';

const trackingLink = 'https://parcelsapp.com/en/tracking/TRACKING_NUMBER';
const Order = () => {
  const { query, history } = useRouter<{
    orderId: string;
    productId: string;
  }>();
  const [isOpen, setOpen] = React.useState(false);
  const onCancelModal = React.useCallback(() => {
    setOpen(prev => !prev);
  }, [setOpen]);
  const intl = useIntl();
  const isMobile = useMediaQuery(TABLET_RESOLUTION);
  const { data: order, isLoading, hasError, callFetch } = useFetch({
    initialUrl: `api/orders/${query.orderId}/`,
    transform: clientOrder,
  });
  const orders = React.useMemo(
    () =>
      ([order].filter(Boolean) as IOrder[]).map(filteredOrder => ({
        ...filteredOrder,
        createdAt: intl.formatDate(order?.createdAt),
        shippingDate: intl.formatDate(order?.shippingDate),
      })),
    [intl, order]
  );
  const [instance, update] = usePDF({
    document: <OrderDetailsDocument orders={orders} />,
  });

  React.useEffect(() => {
    update();
  }, [update, orders]);
  const links = React.useMemo(
    () => [
      {
        to: ClientRouter.ORDERS,
        children: <FormattedMessage id={Message.ORDERS_TITLE} />,
      },
      {
        to: ClientRouter.ORDER_DETAILS.replace(':orderId', query.orderId),
        children: intl.formatMessage(
          { id: Message.ORDER_TITLE_NUMBER },
          { idNumber: query.orderId }
        ),
      },
    ],
    [query.orderId, intl]
  );
  const goToHomePage = React.useCallback(() => {
    history.push(
      ClientRouter.PROPERTY.replace(':propertyId', order?.property ?? '')
    );
  }, [history, order]);

  const statusName = React.useMemo(
    () => ({
      [OrderStatus.approved]: intl.formatMessage({
        id: Message.ORDER_STATUS_WAITING_SHIPPING,
      }),
      [OrderStatus.shipped]: intl.formatMessage({
        id: Message.ORDER_STATUS_SHIPPING,
      }),
      [OrderStatus.done]: intl.formatMessage({
        id: Message.ORDER_STATUS_ARRIVED,
      }),
      [OrderStatus.received]: intl.formatMessage({
        id: Message.ORDER_STATUS_PENDING,
      }),
      [OrderStatus.not_approved]: intl.formatMessage({
        id: Message.ORDER_STATUS_PENDING,
      }),
      [OrderStatus.dispute]: intl.formatMessage({
        id: Message.ORDER_STATUS_PENDING,
      }),
      [OrderStatus.in_progress]: intl.formatMessage({
        id: Message.ORDER_STATUS_PENDING,
      }),
    }),
    [intl]
  );

  const orderStep = [
    intl.formatMessage({
      id: Message.ORDER_STATUS_PENDING,
    }),
    intl.formatMessage({
      id: Message.ORDER_STATUS_WAITING_SHIPPING,
    }),
    intl.formatMessage({
      id: Message.ORDER_STATUS_SHIPPING,
    }),
    intl.formatMessage({
      id: Message.ORDER_STATUS_ARRIVED,
    }),
  ];
  const status = order?.status as OrderStatus;
  return (
    <Container>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Breadcrumbs links={links} />
          {!order || hasError ? (
            <NotFound />
          ) : (
            <>
              <div className="content">
                {!isLoading && (
                  <CustomBox sx={{ width: '100%' }}>
                    <Stepper
                      activeStep={
                        status ? orderStep.indexOf(statusName[status]) : 0
                      }
                      alternativeLabel
                    >
                      {orderStep.map((label, i) => (
                        <Step
                          completed={
                            status && i <= orderStep.indexOf(statusName[status])
                          }
                          key={label}
                        >
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </CustomBox>
                )}
                <Title
                  title={intl.formatMessage(
                    { id: Message.ORDER_TITLE_NUMBER },
                    { idNumber: query.orderId }
                  )}
                />
                <OrderHeading
                  status={order.status as OrderStatus}
                  deliveryDate={order.shippingDate}
                  shippingEst={order.shippingEst}
                  showStepper={true}
                />
                {order.trackingNumber && (
                  <DateField
                    icon={<LocationOnOutlinedIcon />}
                    label={
                      <FormattedMessage
                        id={Message.ORDERS_CARD_TRACKING_NUMBER}
                      />
                    }
                    value={
                      <a
                        className="patricia-link"
                        href={trackingLink.replace(
                          'TRACKING_NUMBER',
                          order.trackingNumber
                        )}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {order.trackingNumber}
                      </a>
                    }
                  />
                )}
                <DateField
                  label={<FormattedMessage id={Message.ORDER_DATE} />}
                  date={order.createdAt}
                />
                <DateField
                  label={<FormattedMessage id={Message.ORDER_DATE_SHIPPING} />}
                  date={order.shippingDate}
                />
                {order.shippingAddress && (
                  <>
                    <FormattedMessage
                      tagName="h4"
                      id={Message.ORDER_SHIPPING_TITLE}
                    />
                    <AddressCard address={order.shippingAddress} />
                  </>
                )}
                <h4>
                  <FormattedMessage id={Message.ORDER_ITEMS} />
                </h4>
                {isMobile ? (
                  <MobileList>
                    {order.products.map(product => (
                      <OrderProductItem key={product.id} product={product} />
                    ))}
                  </MobileList>
                ) : (
                  <List
                    onProdClick={(productSku: string) => {
                      LogsAPI.postUserLogs({
                        area: userLogData.eventArea.myOrders,
                        section: userLogData.eventSection.orders,
                        name: userLogData.eventName.productClick,
                        path: userLogData.eventPath.appOrderIdPath,
                        metadata: {
                          order_id: query.orderId.toString(),
                          product_sku: productSku,
                        },
                      });
                    }}
                    isLoading={isLoading}
                    quoteComponents={order.products.map(product => ({
                      id: product.id,
                      product,
                      quoteId: product.id,
                    }))}
                  />
                )}
                <h4 className="order-summary-title">
                  <FormattedMessage id={Message.ORDER_SUMMARY} />
                </h4>
                <SummaryField
                  label={
                    <FormattedMessage id={Message.CHECKOUT_SUB_TOTAL_LABEL} />
                  }
                  value={order.subTotal}
                  country={order?.supplier?.country}
                />
                <SummaryField
                  label={<FormattedMessage id={Message.ORDER_TAXES} />}
                  value={order.vat}
                  country={order?.supplier?.country}
                />
                <SummaryField
                  label={
                    <FormattedMessage id={Message.CHECKOUT_SHIPPING_LABEL} />
                  }
                  value={0}
                  country={order?.supplier?.country}
                />
                <SummaryField
                  label={
                    <FormattedMessage id={Message.CHECKOUT_DISCOUNT_LABEL} />
                  }
                  value={order.discount ?? 0}
                  country={order?.supplier?.country}
                />
                <hr />
                <SummaryField
                  label={<FormattedMessage id={Message.CHECKOUT_TOTAL_LABEL} />}
                  value={order.finalPrice}
                  country={order?.supplier?.country}
                />
                <Actions>
                  <SecondaryButton
                    onClick={goToHomePage}
                    className="btn back-button"
                  >
                    Back to Shopping
                  </SecondaryButton>
                  <Link
                    download
                    className="no-decoration"
                    href={instance.url ?? '#'}
                  >
                    <Button
                      onClick={() =>
                        LogsAPI.postUserLogs({
                          area: userLogData.eventArea.myOrders,
                          section: userLogData.eventSection.orders,
                          name: userLogData.eventName.downloadReceipt,
                          path: userLogData.eventPath.appOrderIdPath,
                          metadata: { order_id: query.orderId.toString() },
                        })
                      }
                      className="btn receipt-button"
                    >
                      <FormattedMessage id={Message.ORDER_DOWNLOAD_RECEIPT} />
                    </Button>
                  </Link>
                  <SecondaryButton
                    onClick={onCancelModal}
                    className="btn cancel-button"
                  >
                    <FormattedMessage
                      id={Message.PROPERTY_CANCEL_ORDERS_TITLE}
                    />
                  </SecondaryButton>
                  <InfoBox
                    title={
                      <FormattedMessage id={Message.ORDER_INFO_BOX_TITLE} />
                    }
                    icon={<Discovery />}
                    text={
                      <FormattedMessage
                        id={Message.ORDER_INFO_BOX_TEXT}
                        values={{ linebreak: <br /> }}
                      />
                    }
                  />
                </Actions>
              </div>
            </>
          )}
        </>
      )}
      {Boolean(query.productId) && (
        <ProductPageModal
          product={query.productId}
          defaultOpen={Boolean(query.productId)}
        />
      )}
      {order && (
        <OrderCancelModal
          open={isOpen}
          onClose={onCancelModal}
          order={order}
          onRefresh={callFetch}
        />
      )}
    </Container>
  );
};

export default Order;

const Container = styled.section`
  padding: 20px;
  .breadcrumbs {
    padding: 20px 0;
  }
  a {
    text-decoration: none;
  }
  .order-heading {
    max-width: 400px;

    & .status-indicator {
      height: 15px;
      margin: 9px 0;
    }
  }

  & .date-field {
    margin-top: 35px;

    @media (max-width: 800px) {
      margin-top: 30px;
    }
  }

  & h4 {
    font-weight: 400;
    font-size: 0.875rem;

    @media (min-width: 1150px) {
      font-size: 1.375rem;
      margin-top: 180px;
    }
  }

  & .address-card {
    max-width: 350px;
  }

  & ul {
    padding: 0;
    img {
      max-width: 100%;
    }
  }

  & hr {
    border-top: 0;
    border-color: rgba(169, 153, 151, 0.5);
    max-width: 350px;
    margin: 0;

    @media ${MOBILE_TABLET_RESOLUTION} {
      margin: 15px 0;
    }

    @media ${MOBILE_RESOLUTION} {
      margin: 15px auto;
    }
  }

  & .order-summary-field:last-of-type,
  .order-summary-field:last-of-type .order-summary-label,
  .order-summary-field:last-of-type .order-summary-value {
    font-weight: 700;
    font-size: 1rem;

    @media (min-width: 1150px) {
      font-size: 1.5rem;
    }
  }

  & .content {
    position: relative;

    @media ${MOBILE_TABLET_RESOLUTION} {
      margin: 0 auto;
    }
  }
`;

const Actions = styled.section.attrs({ className: 'info-box' })`
  ${rtl`
  position: absolute;
  top: 0px;
  right: 0;
`}
  margin-top: 100px;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  & .cancel-button {
    min-width: 230px;
    ${rtl`
    margin: 11px auto 10px 0px;
    
    `}
    font-size: 1.25rem;
    max-width: 350px;
    align-self: center;
  }

  & .receipt-button {
    min-width: 230px;
    ${rtl`
    margin: 11px 0px 10px auto;
    
    `}
    font-size: 1.25rem;
    max-width: 350px;
  }
  & .back-button {
    display: none;
  }

  & .info-box {
    ${rtl`
      margin-right: 0;
    `}
  }

  @media (max-width: 1150px) {
    position: relative;
    min-width: unset;

    & .receipt-button {
      min-width: unset;
      width: 100%;
      max-width: 350px;
    }
    & .btn {
      margin: 20px 0 40px;
    }
    & .info-box {
      display: none;
    }
  }

  @media ${MOBILE_RESOLUTION} {
    margin: 0 auto;
    text-align: center;
    justify-content: space-between;
    & .back-button {
      display: block;
    }
    .btn {
      min-width: unset;
      width: 48%;
      font-size: 14px;
    }

    hr {
      margin: 0 auto;
    }
  }
`;

const MobileList = styled.ul`
  list-style: none;
  max-width: 500px;

  li {
    display: flex;
    margin: 10px 0;
  }

  & img {
    background: rgba(239, 239, 239, 1);
    border-radius: 25px;
    max-width: 117px !important;
    height: 110px;
  }

  & .content-stack {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    ${rtl`
        margin-left: 9px;
    `}
    width: 100%;
    color: rgba(66, 66, 66, 1);
  }

  & .stock {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    color: rgba(134, 142, 150, 1);

    & .stock-price {
      font-weight: 500;
      font-size: 1.25rem;
      color: rgba(66, 66, 66, 1);
    }
  }
`;

const CustomBox = styled(Box)`
  .Mui-active svg {
    color: ${({ theme }) => theme.colors.primary.lightMobile} !important;
  }
  direction: ltr;
  margin: auto;
  & .Mui-completed svg {
    color: ${({ theme }) => theme.colors.primary.lightMobile} !important;
  }
`;
