import * as React from 'react';
import styled from 'styled-components';
import Truck_En from 'assets/icons/truck.png';
import Truck_He from 'assets/icons/truck-he.png';
import SafeImg from 'components/SafeImg/SafeImg';
import { useIntl } from 'react-intl';

const TruckProgressBar: React.FC<{ value: number }> = ({ value }) => {
  const intl = useIntl();

  React.useEffect(() => {}, [value]);
  return (
    <>
      <TruckContainer width={value}>
        <StyledTruck src={intl.locale === 'en' ? Truck_En : Truck_He} />
      </TruckContainer>
      <ParentProgressBar width={value}>
        <div />
      </ParentProgressBar>
    </>
  );
};

export default TruckProgressBar;

const ParentProgressBar = styled.div<{ width: number }>`
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background: grey;

  & > div {
    width: ${({ width }) => `${width}%`};
    height: inherit;
    border-radius: 5px;
    transition: width 0.5s ease-in-out;
    background: ${({ theme }) => theme.colors.primary.lightMobile};
  }
`;

const TruckContainer = styled.div<{ width: number }>`
  width: ${({ width }) => `${width}%`};

  text-align: end;
  transition: width 0.5s ease-in-out;
`;

const StyledTruck = styled(SafeImg)`
  font-size: 5rem !important;

  margin-bottom: -16px;
  max-width: 100px;
`;
