import UserInfoDataType from 'store/constants/@client/userInfo.constants';
import * as Payload from 'store/types/@client/UserInfo.types';

export const getRecentPropertyRequest = () => ({
  type: UserInfoDataType.GET_RECENT_PROPERTY_REQUEST,
});

export const getRecentPropertyFulfilled = (
  payload: Payload.getRecentPropertyFulfilledPayload
) => ({
  type: UserInfoDataType.GET_RECENT_PROPERTY_FULFILLED,
  payload,
});

export const getRecentPropertyRejected = (
  payload: Payload.getRecentPropertyRejectedPayload
) => ({
  type: UserInfoDataType.GET_RECENT_PROPERTY_REJECTED,
  payload,
});

export const clearRecentProperty = () => ({
  type: UserInfoDataType.CLEAR_RECENT_PROPERTY,
  payload: null,
});

export type UserInfoActions = ReturnType<
  | typeof getRecentPropertyRequest
  | typeof getRecentPropertyFulfilled
  | typeof getRecentPropertyRejected
  | typeof clearRecentProperty
>;
