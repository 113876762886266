import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import Card, { CardField } from 'components/@client/CardList/Card';
import { NO_PROPERTY_NAME } from 'constants/common.constants';
import { summarizeText } from 'utils/string.utils';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import DeleteProjectModal from 'components/@client/DeleteProjectModal/DeleteProjectModel';
import { Projects } from 'api/responses';

interface Props {
  property: Projects;
  onClick?:
    | React.MouseEventHandler<HTMLDivElement>
    | React.KeyboardEventHandler<HTMLDivElement>;
  onRefresh?: VoidFunction;
}

const ProjectItem: React.FC<Props> = ({ property, onClick, onRefresh }) => {
  const [isOpen, setOpen] = React.useState(false);

  const onCloseModal = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <Container>
      <Card
        img={property?.cover_photo}
        alt={property?.name}
        onClick={onClick}
        inProgressOverlay={0}
      >
        <CardField
          label={<FormattedMessage id={Message.PROJECT_NAME} />}
          value={
            property.name?.length
              ? summarizeText(property.name, 15)
              : NO_PROPERTY_NAME
          }
        />
        <CardField
          label={<FormattedMessage id={Message.REAL_ESTATE_DEVELOPER} />}
          value={
            property.developer?.name?.length
              ? summarizeText(property.developer?.name, 15)
              : NO_PROPERTY_NAME
          }
        />
        <CardField
          label={<FormattedMessage id={Message.PROJECT_ADDRESS_FORM} />}
          value={`${property.address?.street}, ${property.address?.city}`}
          weight={500}
        />
      </Card>

      <DeleteProjectModal
        open={isOpen}
        onClose={onCloseModal}
        property={property}
        onRefresh={onRefresh}
      />
    </Container>
  );
};

export default ProjectItem;

const Container = styled.li`
  & :hover {
    & .icon-clients-action {
      display: block;
    }
  }
  > div {
    position: relative;

    & .icon-clients-action {
      position: absolute;
      ${rtl`right: 6px;`}
      top: 6px;
      display: none;
    }
  }
  .card-field {
    margin: 16px 40px 16px 0 !important;
    font-size: 18px;
  }
  .card-field-label {
    margin-top: 4px;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
  }
  .card-field-value {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    margin-top: 4px;
  }
`;
