import { LogsAPI } from 'api/methods';
import { userLogData } from 'mockData/userLogData';
import * as React from 'react';
import * as Action from 'store/actions/session.actions';
import { shallowEqual, useDispatch, useSelector } from 'store/selectors';
import { sessionSelector } from 'store/selectors/session.selector';
import { SupportedLanguage } from 'types/common.types';
import { isSupportedCountry } from 'utils/common.utils';

const useSession = () => {
  const dispatch = useDispatch();
  const {
    country,
    currency,
    locale,
    loading: isGettingLocation,
    error,
    showWelcomeMessage,
  } = useSelector(sessionSelector, shallowEqual);
  const hasError = Boolean(error);

  const selectLanguage = React.useCallback(
    (lang: SupportedLanguage) => dispatch(Action.selectLanguage(lang)),
    [dispatch]
  );

  const onLanguageChange = React.useCallback(() => {
    LogsAPI.postUserLogs({
      area: userLogData.eventArea.homePage,
      section: userLogData.eventSection.personalMenu,
      name: userLogData.eventName.englishHebrew,
      path: userLogData.eventPath.appPath,
    });
    selectLanguage(
      locale === SupportedLanguage.ENGLISH
        ? SupportedLanguage.HEBREW
        : SupportedLanguage.ENGLISH
    );
  }, [locale, selectLanguage]);

  const getLocation = React.useCallback(
    () => dispatch(Action.getLocationRequest()),
    [dispatch]
  );

  const setShowWelcomeMessage = React.useCallback(
    (payload: boolean) => dispatch(Action.setShowWelcomeMessage(payload)),
    [dispatch]
  );

  const isFromSupportedCountry = React.useMemo(
    () => !hasError || isSupportedCountry(country),
    [country, hasError]
  );

  return {
    country,
    currency,
    isGettingLocation,
    isFromSupportedCountry,
    locale,
    getLocation,
    onLanguageChange,
    selectLanguage,
    showWelcomeMessage,
    setShowWelcomeMessage,
  };
};

export default useSession;
