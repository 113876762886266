import * as React from 'react';
import styled from 'styled-components';

interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {}

const Link: React.FC<Props> = ({ children, ...props }) => (
  <Anchor {...props} rel="noopener noreferrer" target="_blank">
    {children}
  </Anchor>
);

export default Link;

const Anchor = styled.a`
  color: inherit;

  &.no-decoration {
    text-decoration: none;
  }
`;
