import styled from 'styled-components';
import { FormRow } from 'styles/sharedStyle';

export const Row = styled.div`
  ${FormRow}

  :last-child {
    margin-bottom: 0;
  }

  width: fit-content;
  gap: 26px;
`;
