import { ReactComponent as BrandLogoRed } from 'assets/icons/brandLogo-red.svg';
import { ReactComponent as BrandLogoWhite } from 'assets/icons/brandLogo-white.svg';
import styled from 'styled-components';

const Logo = styled(BrandLogoRed)`
  height: 100px;
`;

export const WhiteLogo = styled(BrandLogoWhite)`
  height: 100px;
`;

export default Logo;
