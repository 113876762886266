/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { View, Text, StyleSheet, Font } from '@react-pdf/renderer';
import { Roboto, VarelaRound } from 'documents/documents.config';
import theme from 'styles/theme';

Font.register(VarelaRound);
Font.register(Roboto);

const styles = StyleSheet.create({
  item: {
    flexGrow: 1,
  },
  label: {
    fontSize: 10,
    fontFamily: 'Varela Round',
    fontWeight: 400,
    paddingBottom: 9,
    color: theme.colors.primary.primaryTextColor,
    marginRight: 3,
  },
  value: {
    fontSize: 10,
    fontWeight: 500,
    fontFamily: 'Varela Round',
    color: '#2B2B2B',
    flexWrap: 'wrap',
    maxWidth: 200,
  },
});

const rowStyles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    paddingBottom: 0,
  },
});

interface Props {
  direction?: 'row' | 'column';
  label: string;
  value?: string;
  itemStyles?: any;
}

const HeaderItem: React.FC<Props> = ({
  label,
  value,
  itemStyles = {},
  direction = 'column',
}) => {
  const styleProps =
    direction === 'column'
      ? {}
      : { itemStyles: rowStyles.row, labelStyles: rowStyles.label };
  return (
    <View
      style={
        itemStyles?.header ?? {
          ...styles.item,
          ...itemStyles,
          ...styleProps.itemStyles,
        }
      }
    >
      <Text
        style={
          itemStyles.label ?? { ...styles.label, ...styleProps.labelStyles }
        }
      >
        {label}
      </Text>
      {value && <Text style={styles.value}>{value}</Text>}
    </View>
  );
};

export default HeaderItem;
