import { Message } from 'i18n';
import { IntlShape } from 'react-intl';
import {
  ClientRouter,
  ManagementRouter,
  SupplierDashBoardRoutes,
} from 'router/routes';
import { PROPERTIES_STATUS, Property } from 'types/properties';
import { Tab } from 'utils/sidebar.utils';
import { LogsAPI } from 'api/methods';
import { userLogData } from 'mockData/userLogData';

const homeButtonLink = (
  isAuthenticated: boolean,
  recentProperty: Property | null
) => {
  if (isAuthenticated && recentProperty?.status === PROPERTIES_STATUS.Published)
    return ClientRouter.TOUR.replace(':propertyId', recentProperty?.id ?? '');
  if (isAuthenticated) return ClientRouter.PROPERTY_IN_PROGRESS;
  return ClientRouter.AUTH;
};

const tabs = (
  route: string,
  isAuthenticated: boolean,
  recentProperty: Property | null,
  intl: IntlShape
): Tab[] => [
  {
    to: homeButtonLink(isAuthenticated, recentProperty),
    icon: 'home-nav',
    exact: true,
    isMain: true,
    label:
      isAuthenticated && Boolean(recentProperty)
        ? intl.formatMessage({ id: Message.ENTER_HOME_BUTTON })
        : intl.formatMessage({ id: Message.CREATE_HOME_BUTTON }),
    onClick: () => {
      LogsAPI.postUserLogs({
        name:
          isAuthenticated && Boolean(recentProperty)
            ? userLogData.eventName.enterYourHome
            : userLogData.eventName.createYourHome,
        area: userLogData.eventArea.homePage,
        section: userLogData.eventSection.header,
        path: userLogData.eventPath.appPath,
        metadata: { property_id: recentProperty?.id },
      });
    },
  },
  {
    to: route.includes(SupplierDashBoardRoutes.INFO)
      ? route
      : SupplierDashBoardRoutes.INFO,
    icon: 'supplier-shop',
    exact: true,
    public: false,
    viewBox: '0 0 24 24',
    isActive: route.includes(SupplierDashBoardRoutes.INFO),
    label: intl.formatMessage({ id: Message.HOME_SUPPLIER_CMS_TITLE }),
  },
  {
    to: ManagementRouter.APP,
    icon: 'supplier-shop',
    exact: true,
    public: false,
    viewBox: '0 0 24 24',
    isActive: route.includes(ManagementRouter.APP),
    label: intl.formatMessage({ id: Message.HOME_MANAGEMENT_TITLE }),
  },
  {
    to: route.includes(ClientRouter.PRODUCTS) ? route : ClientRouter.PRODUCTS,
    icon: 'furnitures',
    exact: true,
    public: true,
    isActive: route.includes(ClientRouter.PRODUCTS),
    label: intl.formatMessage({ id: Message.HOME_PRODUCTS_TITLE }),
    onClick: () => {
      LogsAPI.postUserLogs({
        name: userLogData.eventName.catalogIcon,
        area: userLogData.eventArea.homePage,
        section: userLogData.eventSection.header,
        path: userLogData.eventPath.appPath,
      });
    },
  },
  {
    to: ClientRouter.PROPERTIES,
    icon: 'buildings-outlined',
    exact: true,
    public: true,
    isActive: route.includes(ClientRouter.PROPERTIES),
    label: intl.formatMessage({ id: Message.PROPERTIES_TITLE }),
    showBadge: true,
  },
  {
    to: ClientRouter.SKINS,
    icon: 'discover',
    exact: true,
    public: true,
    isActive: route.includes(ClientRouter.SKINS),
    label: intl.formatMessage({ id: Message.HOME_SKINS_TITLE }),
  },
  {
    to: route.includes(ClientRouter.CART) ? route : ClientRouter.CART,
    icon: 'shopping-cart',
    exact: true,
    isActive: route.includes(ClientRouter.CART),
    label: intl.formatMessage({ id: Message.CART_TITLE }),
    showBadge: true,
    public: true,
    onClick: () => {
      LogsAPI.postUserLogs({
        name: userLogData.eventName.cartIcon,
        area: userLogData.eventArea.homePage,
        section: userLogData.eventSection.header,
        path: userLogData.eventPath.appPath,
      });
    },
  },
];

export default tabs;
