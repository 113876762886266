import styled, { css } from 'styled-components';
import { MOBILE_RESOLUTION } from 'styles/sharedStyle';

export const AuthForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .main-button {
    margin: 0;
    min-width: 146px;
    height: 48px;
    padding: 0;
    width: unset;
    text-transform: none;
  }
`;

export const Fields = styled.div<{ direction?: 'column' | 'row' }>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: ${({ direction = 'column' }) => direction};
  justify-content: flex-start;
  column-gap: 15px;
  width: 100%;

  @media (min-width: 1500px) {
    column-gap: 65px;
  }

  @media ${MOBILE_RESOLUTION} {
    width: initial;

    > * {
      width: 100%;
    }
  }

  .MuiFormControl-root {
    margin: 0;

    @media (max-width: 500px) {
      margin: 0;
    }

    @media (max-width: 1500px) {
      margin: 0;
    }

    .MuiInputBase-root {
      @media (max-width: 500px) {
        width: 100%;
      }
      @media (min-width: 1500px) {
        width: inherit;
      }
      width: inherit;

      input {
        background: #ffffff;
        padding: 8.5px 12px;
      }
    }
  }
  ${({ direction = 'column' }) =>
    direction === 'column' &&
    css`
      justify-content: center;
      gap: 30px;

      @media (min-width: 1500px) {
        gap: 41px;
      }

      .MuiFormControl-root {
        margin: 0 16px;

        @media (max-width: 1500px) {
          margin: 0 16px;
        }

        .MuiInputBase-root {
          @media (max-width: 500px) {
            width: 300px;
          }
          width: 400px;
          @media (min-width: 1500px) {
            width: 445px;
          }
        }
      }
    `}
`;

export const registerInitialValues = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  verifyEmail: '',
  password: '',
  verifyPassword: '',
  termsAndConditions: false,
};

export const registrationFields = [
  {
    name: 'firstName',
    label: { title: 'First name' },
    type: 'text',
  },
  {
    name: 'lastName',
    label: { title: 'Last name' },
    type: 'text',
  },
  {
    name: 'phoneNumber',
    label: { title: 'Phone number' },
    placeholder: '+XXX...',
    type: 'tel',
  },
  {
    name: 'email',
    label: { title: 'Email Address' },
    type: 'email',
  },
  {
    name: 'verifyEmail',
    label: { title: 'Verify email address' },
    type: 'email',
  },
  {
    name: 'password',
    label: { title: 'Password' },
    type: 'password',
  },
  {
    name: 'verifyPassword',
    label: { title: 'Verify password' },
    type: 'password',
  },
];
