import { all, call, put, debounce } from 'redux-saga/effects';
import PropertiesType from 'store/constants/properties-management/PropertiesManagement.constants';
import * as PropertiesPayload from 'store/types/properties-management/PropertiesManagement.types';
import * as PublishedPropertiesPayload from 'store/types/properties-management/PublishedProperties.types';
import * as ArchivedPropertiesPayload from 'store/types/properties-management/ArchivedProperties.types';
import * as PropertyTasksActions from 'store/actions/properties-management/PropertiesManagement.actions';
import * as UnitTasksActions from 'store/actions/properties-management/UnitsManagement.actions';
import * as PublishedPropertyTasksActions from 'store/actions/properties-management/PublishedPropertiesManagement.actions';
import * as ArchivedPropertyTasksActions from 'store/actions/properties-management/ArchivedPropertiesManagement.actions';
import { showNotification } from 'store/actions/notifications.actions';
import { PropertyManagementTasksAPI } from 'api/methods';
import {
  ArchivedPropertyTask,
  PropertyTask,
  PublishedPropertyTask,
  UnitTask,
} from 'types/properties';
import { removeEmptyValues } from 'utils/common.utils';
import { newSortByMap } from 'utils/transform.utils';

function* errorHandler(
  e: Error,
  type: PropertiesType,
  rejectAction: (payload: {
    error: string;
  }) => { type: PropertiesType; payload: { error: string } }
) {
  const error = e.message || 'ERROR: Cannot refresh Data source';
  yield put(showNotification({ key: type, message: error, severity: 'error' }));
  yield put(rejectAction({ error }));
}

const sortPropertyTasksMap = {
  id: 'id',
  name: 'name',
  createdAt: 'created_at',
};

function* getPropertyTasksDataRequest({
  payload,
  type,
}: ReturnType<typeof PropertyTasksActions.getPropertiesTasksDataRequest>) {
  try {
    const {
      page = 0,
      size = 10,
      search,
      sortBy,
      priority,
      assigned_to,
      status,
    } = payload;
    const params = removeEmptyValues({
      page: page + 1,
      size,
      search,
      priority,
      assigned_to,
      status,
      ...newSortByMap<typeof sortPropertyTasksMap>(
        sortPropertyTasksMap,
        sortBy
      ),
    }) as PropertiesPayload.GetPropertiesTasksDataRequestPayload;

    const {
      results,
      count,
    }: { results: PropertyTask[]; count: number } = yield call(
      PropertyManagementTasksAPI.getTasksList,
      {
        params,
      }
    );

    const pageCount = Math.ceil(count / size);
    yield put(
      PropertyTasksActions.getPropertiesTasksDataFulfilled({
        results,
        pageCount,
      })
    );
  } catch (e) {
    yield call(
      errorHandler,
      e,
      type,
      PropertyTasksActions.getPropertiesTasksDataRejected
    );
  }
}

function* getUnitTasksDataRequest({
  payload,
  type,
}: ReturnType<typeof UnitTasksActions.getUnitsTasksDataRequest>) {
  try {
    const {
      page = 0,
      size = 10,
      search,
      sortBy,
      assignee,
      status,
      priority,
      has_floor_plan,
      has_tour_file,
      is_archived,
    } = payload;
    const params = removeEmptyValues({
      page: page + 1,
      size,
      search,
      assignee,
      status,
      priority,
      is_archived,
      ...newSortByMap<typeof sortPropertyTasksMap>(
        sortPropertyTasksMap,
        sortBy
      ),
    }) as PropertiesPayload.GetUnitsTasksDataRequestPayload;

    const {
      results,
      count,
    }: { results: UnitTask[]; count: number } = yield call(
      PropertyManagementTasksAPI.getUnitTaskList,
      {
        params: { ...params, has_floor_plan, has_tour_file },
      }
    );

    const pageCount = Math.ceil(count / size);
    yield put(
      UnitTasksActions.getUnitsTasksDataFulfilled({
        results,
        pageCount,
      })
    );
  } catch (e) {
    yield call(
      errorHandler,
      e,
      type,
      UnitTasksActions.getUnitsTasksDataRejected
    );
  }
}

function* getPublishedPropertyTasksDataRequest({
  payload,
  type,
}: ReturnType<
  typeof PublishedPropertyTasksActions.getPublishedPropertiesTasksDataRequest
>) {
  try {
    const {
      page = 0,
      size = 10,
      search,
      sortBy,
      priority,
      assigned_to,
      status,
    } = payload;
    const params = removeEmptyValues({
      page: page + 1,
      size,
      search,
      priority,
      assigned_to,
      status,
      ...newSortByMap<typeof sortPropertyTasksMap>(
        sortPropertyTasksMap,
        sortBy
      ),
    }) as PublishedPropertiesPayload.GetPublishedPropertiesTasksDataRequestPayload;

    const {
      results,
      count,
    }: { results: PublishedPropertyTask[]; count: number } = yield call(
      PropertyManagementTasksAPI.getPublishedTasks,
      {
        params,
      }
    );

    const pageCount = Math.ceil(count / size);
    yield put(
      PublishedPropertyTasksActions.getPublishedPropertiesTasksDataFulfilled({
        results,
        pageCount,
      })
    );
  } catch (e) {
    yield call(
      errorHandler,
      e,
      type,
      PublishedPropertyTasksActions.getPublishedPropertiesTasksDataRejected
    );
  }
}

function* getArchivedPropertyTasksDataRequest({
  payload,
  type,
}: ReturnType<
  typeof ArchivedPropertyTasksActions.getArchivedPropertiesTasksDataRequest
>) {
  try {
    const {
      page = 0,
      size = 10,
      search,
      sortBy,
      priority,
      assigned_to,
      status,
    } = payload;
    const params = removeEmptyValues({
      page: page + 1,
      size,
      search,
      priority,
      assigned_to,
      status,
      ...newSortByMap<typeof sortPropertyTasksMap>(
        sortPropertyTasksMap,
        sortBy
      ),
    }) as ArchivedPropertiesPayload.GetArchivedPropertiesTasksDataRequestPayload;

    const {
      results,
      count,
    }: { results: ArchivedPropertyTask[]; count: number } = yield call(
      PropertyManagementTasksAPI.getArchivedTasks,
      {
        params,
      }
    );

    const pageCount = Math.ceil(count / size);
    yield put(
      ArchivedPropertyTasksActions.getArchivedPropertiesTasksDataFulfilled({
        results,
        pageCount,
      })
    );
  } catch (e) {
    yield call(
      errorHandler,
      e,
      type,
      ArchivedPropertyTasksActions.getArchivedPropertiesTasksDataRejected
    );
  }
}

function* PropertyTasksSaga() {
  yield all([
    debounce(
      500,
      PropertiesType.GET_PROPERTIES_DATA_REQUEST,
      getPropertyTasksDataRequest
    ),
    debounce(
      500,
      PropertiesType.GET_UNITS_DATA_REQUEST,
      getUnitTasksDataRequest
    ),
    debounce(
      500,
      PropertiesType.GET_PUBLISHED_PROPERTIES_DATA_REQUEST,
      getPublishedPropertyTasksDataRequest
    ),
    debounce(
      500,
      PropertiesType.GET_ARCHIVED_PROPERTIES_DATA_REQUEST,
      getArchivedPropertyTasksDataRequest
    ),
  ]);
}

export default PropertyTasksSaga;
