enum SupplierUserDataType {
  GET_USERS_DATA_REQUEST = 'supplier/getUsersDataRequest',
  GET_USERS_DATA_REJECTED = 'supplier/getUsersDataRejected',
  GET_USERS_DATA_FULFILLED = 'supplier/getUsersDataFulfilled',

  GET_USER_DATA_REQUEST = 'supplier/getUserDataRequest',
  GET_USER_DATA_REJECTED = 'supplier/getUserDataRejected',
  GET_USER_DATA_FULFILLED = 'supplier/getUserDataFulfilled',

  GET_ORDERS_DATA_REQUEST = 'supplier/getOrdersDataRequest',
  GET_ORDERS_DATA_REJECTED = 'supplier/getOrdersDataRejected',
  GET_ORDERS_DATA_FULFILLED = 'supplier/getOrdersDataFulfilled',

  GET_FILES_DATA_REQUEST = 'supplier/getFilesDataRequest',
  GET_FILES_DATA_REJECTED = 'supplier/getFilesDataRejected',
  GET_FILES_DATA_FULFILLED = 'supplier/getFilesDataFulfilled',

  GET_PRODUCTS_DATA_REQUEST = 'supplier/getProductsDataRequest',
  GET_PRODUCTS_DATA_REJECTED = 'supplier/getProductsDataRejected',
  GET_PRODUCTS_DATA_FULFILLED = 'supplier/getProductsDataFulfilled',
}

export default SupplierUserDataType;
