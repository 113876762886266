import * as React from 'react';
import type { RouteProps } from 'react-router';
import type {
  ManagementRouter,
  UserManagementRoute,
  UserManagementClientsRoute,
  UserManagementTeamPatriciaRoute,
  UserManagementSuppliersRoute,
  IRouteConfig,
} from 'router/routes';

export const getName = <K extends string>(key: K, selected: boolean) =>
  selected ? `${key}-filled` : `${key}-outlined`;

export const getRoute = (
  routes: (
    | ManagementRouter
    | UserManagementRoute
    | UserManagementClientsRoute
    | UserManagementTeamPatriciaRoute
    | UserManagementSuppliersRoute
    | string
  )[]
) => routes.join('');

type SubTab = {
  label: string;
  to: string;
  exact: boolean;
};

export type Tab = {
  label?: string;
  path?: string;
  to: string;
  icon: string | null;
  exact: boolean;
  component?: React.FC<RouteProps & { routes: IRouteConfig[] }>;
  isMain?: boolean;
  isActive?: boolean;
  showBadge?: boolean;
  viewBox?: string;
  public?: boolean;
  onClick?: VoidFunction;
  subTabs?: SubTab[];
};
