import * as React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import styled from 'styled-components';

const CloseButton: React.FC<{ onClick: () => void; className?: string }> = ({
  onClick,
  className,
}) => {
  return (
    <IconButton aria-label="close" onClick={onClick} className={className}>
      <Close className="close-icon" />
    </IconButton>
  );
};

export default React.memo(CloseButton);

const Close = styled(CloseIcon)`
  opacity: 0.6;
  font-size: 30px;
`;
