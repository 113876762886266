import * as React from 'react';
import { useSelector } from 'store/selectors';
import { shallowEqual, useDispatch } from 'react-redux';
import * as Action from 'store/actions/authentication.actions';
import { authSelector } from 'store/selectors/auth.selector';
import { resetNotifications } from 'store/actions/notifications.actions';
import {
  ForgotPasswordPayload,
  LoginPayload,
  RedirectRegisterPayload,
  RegisterPayload,
  ResetPasswordPayload,
} from 'store/types/authentication.types';
import { isEmpty } from 'utils/lodash.utils';
import { getUserName } from 'utils/string.utils';
import { Permission, Type } from 'constants/config';
import { transformBaseUserData } from 'api/transform';
import { BaseUser as UserType, BaseUserResponse } from 'types/common.types';

const useAuthentication = () => {
  const dispatch = useDispatch();
  const {
    avatar,
    error,
    firstName,
    email,
    id,
    lastName,
    loading,
    permissions: userPermissions = [],
    phoneNumber,
    role,
    supplierId,
    seller_supplier,
    token,
    type,
    profilePicture,
  } = useSelector(authSelector, shallowEqual);

  const login = React.useCallback(
    (payload: LoginPayload) => dispatch(Action.loginRequest(payload)),
    [dispatch]
  );

  const logOut = React.useCallback(() => dispatch(Action.logOutRequest()), [
    dispatch,
  ]);

  const clientLogout = React.useCallback(
    () => dispatch(Action.clientLogOutRequest()),
    [dispatch]
  );

  const validateUser = React.useCallback(
    () => dispatch(Action.verifyTokenRequest()),
    [dispatch]
  );

  const updateUser = React.useCallback(
    (data: BaseUserResponse) => {
      const user: UserType = transformBaseUserData(data);
      dispatch(Action.updateUser(user));
    },
    [dispatch]
  );

  const resetError = React.useCallback(() => {
    dispatch(Action.resetError());
    dispatch(resetNotifications());
  }, [dispatch]);

  const register = React.useCallback(
    (payload: RegisterPayload) => {
      dispatch(Action.registerRequest(payload));
    },
    [dispatch]
  );

  const onboard = React.useCallback(
    (payload: RegisterPayload) => {
      dispatch(Action.onboardingRequest(payload));
    },
    [dispatch]
  );

  const redirectOnBoard = React.useCallback(
    (payload: RedirectRegisterPayload) => {
      dispatch(Action.redirectOnboardingRequest(payload));
    },
    [dispatch]
  );

  const requestResetLink = React.useCallback(
    (payload: ForgotPasswordPayload) => {
      dispatch(Action.passwordResetLinkRequest(payload));
    },
    [dispatch]
  );

  const resetPassword = React.useCallback(
    (payload: ResetPasswordPayload) => {
      dispatch(Action.resetPasswordRequest(payload));
    },
    [dispatch]
  );

  const allowUserAccess = React.useCallback(
    ({
      allowedTypes,
      allowedPermissions = [],
    }: {
      allowedTypes: Type[];
      allowedPermissions: Permission[];
    }): boolean => {
      return (
        (allowedTypes.includes(type ?? Type.CLIENT) &&
          isEmpty(allowedPermissions)) ||
        allowedPermissions.some(p => userPermissions.includes(p))
      );
    },
    [userPermissions, type]
  );

  return React.useMemo(
    () => ({
      avatar,
      firstName,
      fullName: getUserName({ firstName, lastName }),
      email,
      error,
      id,
      lastName,
      loading,
      phoneNumber,
      role,
      supplierId,
      seller_supplier,
      token,
      type,
      userPermissions,
      profilePicture,
      allowUserAccess,
      clientLogout,
      login,
      logOut,
      onboard,
      register,
      resetError,
      validateUser,
      updateUser,
      requestResetLink,
      resetPassword,
      redirectOnBoard,
    }),
    [
      avatar,
      firstName,
      lastName,
      email,
      error,
      id,
      loading,
      phoneNumber,
      role,
      supplierId,
      seller_supplier,
      token,
      type,
      userPermissions,
      profilePicture,
      allowUserAccess,
      clientLogout,
      login,
      logOut,
      onboard,
      register,
      resetError,
      validateUser,
      updateUser,
      requestResetLink,
      resetPassword,
      redirectOnBoard,
    ]
  );
};

export default useAuthentication;
