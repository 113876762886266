import * as React from 'react';
import Button from '@material-ui/core/Button';
import styled, { css } from 'styled-components';
import Icon from 'components/Icon';
import { ButtonCss } from 'styles/sharedStyle';
import Loader from 'components/Loader/Loader';

interface Props {
  isConfirmDisabled: boolean;
  isLoading?: boolean;
  onCancel?: () => void;
}

const AddressesFormActions: React.FC<Props> = ({
  isConfirmDisabled,
  isLoading,
  onCancel,
}) => {
  return (
    <Buttons>
      <Button type="submit" className="confirm" disabled={isConfirmDisabled}>
        {isLoading ? <Loader /> : <>&#10003;</>}
      </Button>
      <Button type="button" className="cancellation" onClick={onCancel}>
        <Icon name="cancellation" />
      </Button>
    </Buttons>
  );
};

export default AddressesFormActions;

const ButtonStyle = css`
  ${ButtonCss}

  width: 32px;
  height: 32px;
  min-width: unset;
  border-radius: 10px;
`;

const Buttons = styled.div`
  display: flex;
  gap: 10px;

  button {
    ${ButtonStyle};

    svg {
      width: 8px;
      height: 8px;
    }

    &.confirm {
      color: #ff3100;
      border: 1px solid #ff3100;
      transition: color ${({ theme }) => theme.utils.quickTransition};

      &:disabled {
        border: 1px solid #ffffff;
      }
    }

    &.cancellation {
      border: 1px solid ${({ theme }) => theme.colors.primary.dark};
    }
  }
`;
