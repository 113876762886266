import * as React from 'react';
import { Message } from 'i18n';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as UploadDoneIcon } from 'assets/@client/supplier/cloud-upload-done.svg';
import { ReactComponent as UploadIcon } from 'assets/@client/supplier/cloud-upload-btn.svg';
import styled, { css } from 'styled-components';
import rtl from 'styled-components-rtl';
import { Button, Dialog } from '@mui/material';
import TextInput from 'components/@client/form-components/TextInput';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import { Setter } from 'types/common.types';
import { METHODS } from 'api/client';
import { useFetch, useNotifications, useRouter, useUploadFile } from 'hooks';
import Loader from 'components/Loader/Loader';
import { MOBILE_RESOLUTION } from 'styles/sharedStyle';
import { Units } from 'types/projects';
import { readFile } from 'utils/common.utils';
import { PropertyMedia } from 'types/properties';
import SelectFloor from './SelectFloor';

const SIZE_LIMIT = 1048576 * 10;
const MAX_PAGES_SIZE = 4;

const UnitForm: React.FC<{
  setIsOpenUnitForm: Setter<boolean>;
  isValid: boolean;
  isDirty: boolean;
  getProjectDetails: VoidFunction;
  isEdit: boolean;
  unit?: Units;
}> = ({
  setIsOpenUnitForm,
  isValid,
  isDirty,
  getProjectDetails,
  isEdit,
  unit,
}) => {
  const { control, getValues, reset } = useFormContext();
  const intl = useIntl();
  const { query } = useRouter<{
    id: string;
  }>();
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [image, setImage] = React.useState<File>();

  const onSuccess = () => {
    setIsOpenUnitForm(false);
    getProjectDetails();
  };

  React.useEffect(() => {
    if (isEdit) {
      reset({ buildingName: unit?.building, typeName: unit?.type });
    }
  }, [isEdit, reset, unit?.building, unit?.type]);

  const { showNotification } = useNotifications();

  const { callFetch: addNewUnit, isLoading: isLoadingAdd } = useFetch<{}>({
    initialUrl: `/api/units/`,
    skipOnStart: true,
    config: {
      method: METHODS.POST,
    },
    onSuccess,
    onFailure: error => {
      if (error.includes('type must make a unique set')) {
        showNotification({
          key: 'unique-type-project',
          message: 'This project already has unit with such type.',
          severity: 'error',
        });
      } else {
        showNotification({
          key: 'validation-unit',
          message: error,
          severity: 'error',
        });
      }
    },
  });

  const { callFetch: editUnit, isLoading: isLoadingEdit } = useFetch<{}>({
    initialUrl: `/api/units/${unit?.id}/`,
    skipOnStart: true,
    config: {
      method: METHODS.PATCH,
    },
    onSuccess,
    onFailure: error => {
      if (error.includes('type must make a unique set')) {
        showNotification({
          key: 'unique-type-project',
          message: 'This project already has unit with such type.',
          severity: 'error',
        });
      } else {
        showNotification({
          key: 'validation-unit',
          message: error,
          severity: 'error',
        });
      }
    },
  });
  const [isLoadingBtn, setIsLoadingBtn] = React.useState(false);
  const handleClick = async () => {
    setIsLoadingBtn(true);
    const response = await window.fetch(selectedFloor?.file ?? '', {
      cache: 'no-store',
    });
    const arrayBuffer = await response?.arrayBuffer();
    const blob = new Blob([arrayBuffer], {
      type: response?.headers?.get('Content-Type') ?? '',
    });
    const formData = new FormData();
    formData.append('building', getValues().buildingName);
    formData.append('type', getValues().typeName);
    if (image) {
      formData.append('status', 'to_do');
      formData.append('floor_plan', selectedFloor ? blob : (image as Blob));
    }
    formData.append('project', query?.projectId as string);
    setIsLoadingBtn(false);
    if (isEdit) {
      editUnit({
        data: formData,
      });
    } else {
      addNewUnit({
        data: formData,
      });
    }
  };

  const showError = React.useCallback(
    (
      message = 'The file type is not supported. Please, upload a JPG, JPEG, PNG, or PDF and should be less then 10MB'
    ) => {
      showNotification({
        key: 'fileDropZone/notSupported',
        message,
        severity: 'error',
      });
      return false;
    },
    [showNotification]
  );
  const [isSelectpage, setSelectPage] = React.useState<PropertyMedia[]>([]);
  const [selectedFloor, setSelectedFloor] = React.useState<PropertyMedia>();
  const [isDocumentProper, setIsDocumentProper] = React.useState(false);
  const onSuccessPDF = React.useCallback(
    (task: { id: number; files: PropertyMedia[] }) => {
      if (task.files.length > 1) {
        setSelectPage(task.files);
        setIsDocumentProper(false);
      }
    },
    []
  );

  const onFailurePDF = React.useCallback(
    e => {
      showNotification({
        key: 'uploadPlan/uploadPlanRejected',
        message:
          'We had an error uploading your floor plan, please try again later.',
        severity: 'error',
      });
    },
    [showNotification]
  );

  const { handleUpload, isUploading: isLoading } = useUploadFile({
    url: '/api/property_files/upload_floor_plan_file/',
    onSuccess: onSuccessPDF,
    onFailure: onFailurePDF,
  });

  const onChangeHandler = React.useCallback(
    async (files: File[] | FileList | null) => {
      const file = files?.[0];
      if (!file) return null;
      const conditions = [
        !['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'].includes(
          file?.type ?? ''
        ),
        (file?.size ?? 0) > SIZE_LIMIT,
      ];
      if (conditions.some(v => v)) {
        return showError();
      }
      // in case file is PDF - check if number of pages is valid
      if (file.type.includes('pdf')) {
        const { data: results, error } = await readFile(file);
        const count = results?.match(/\/Type[\s]*\/Page[^s]/g)?.length ?? 10;
        if (Boolean(error) || count > MAX_PAGES_SIZE)
          return showError('PDF should have less then 5 pages.');
        handleUpload({ file });
      }
      setIsDocumentProper(true);
      return true;
    },
    [handleUpload, showError]
  );

  return (
    <MainContainer>
      <Modal open={!!isSelectpage.length} onClose={() => setSelectPage([])}>
        <SelectFloor
          setIsDocumentProper={setIsDocumentProper}
          floorPlanFiles={isSelectpage}
          image={image}
          setSelectedFloor={setSelectedFloor as Setter<PropertyMedia>}
          selectedFloor={selectedFloor}
          setSelectPage={setSelectPage}
        />
      </Modal>
      <>
        <Icon onClick={() => setIsOpenUnitForm(false)}>
          <CloseIcon />
        </Icon>
        <DetailsContainer>
          <FormTitle>
            <FormattedMessage
              id={isEdit ? Message.EDIT_UNIT : Message.ADD_NEW_UNIT}
            />
          </FormTitle>
          <input
            type="file"
            id="fileInput"
            style={{ display: 'none' }}
            ref={fileInputRef}
            onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
              const fileList = e.target.files;
              if (!fileList) return;
              if (await onChangeHandler(fileList)) setImage(fileList[0]);
            }}
          />
          <FormContainer className="sign-up-form">
            <TextInput
              name="buildingName"
              control={control}
              label={intl.formatMessage({ id: Message.BUILDING_NAME })}
              placeholder={intl.formatMessage({ id: Message.GREEN_HOUSE })}
              required
            />
            <TextInput
              name="typeName"
              control={control}
              label={intl.formatMessage({ id: Message.TYPE_NAME })}
              placeholder={intl.formatMessage({ id: Message.A })}
              required
            />
            <FloorPlanText>
              <FormattedMessage id={Message.FLOOR_PLAN} />
            </FloorPlanText>
            <div className="upload-btn-container">
              <div className="upload-floor-plan">
                <FormattedMessage id={Message.UPLOAD_FLOOR_PLAN} />
              </div>
              <UploadButton
                isImageSelected={Boolean(image || unit?.floorPlan)}
                className="upload-floor-btn"
                onClick={() => fileInputRef.current?.click()}
              >
                {image || unit?.floorPlan ? <UploadDoneIcon /> : <UploadIcon />}
                <FormattedMessage
                  id={
                    image || unit?.floorPlan
                      ? Message.LANDING_PAGE_CHANGE_FILE_BUTTON
                      : Message.BROWSE
                  }
                />
              </UploadButton>
            </div>
            <TextWrapper>
              <FormattedMessage
                id={Message.NEW_UNIT_UPLOAD_NOTICE}
                values={{
                  linebreak: <br />,
                  span: (chunks: string) => (
                    <span style={{ fontWeight: 500 }}>{chunks}</span>
                  ),
                }}
              />
            </TextWrapper>
            <CreateButton
              data-test-id="add-new-unit-button"
              id="submit-unit-button"
              disabled={
                !isValid ||
                (!image && !unit?.floorPlan) ||
                (!isDirty && !image) ||
                isLoadingAdd ||
                isLoadingEdit ||
                isLoading ||
                !isDocumentProper
              }
              onClick={handleClick}
            >
              {isLoadingAdd || isLoadingEdit || isLoading || isLoadingBtn ? (
                <Loader />
              ) : (
                <FormattedMessage
                  id={isEdit ? Message.APPLY_CHANGES : Message.CREATE_NEW_UNIT}
                />
              )}
            </CreateButton>
          </FormContainer>
        </DetailsContainer>
      </>
    </MainContainer>
  );
};

export default UnitForm;

export const Modal = styled(Dialog)`
  .MuiDialog-paper {
    border-radius: 15px;
    overflow-y: hidden !important;
    max-width: none;
  }
  .client-dialog .MuiDialog-paper {
    overflow-y: hidden;
  }
`;

const MainContainer = styled.div`
  padding: 24px 32px;

  @media ${MOBILE_RESOLUTION} {
    width: 100%;
  }
  ${({ theme }) => theme.utils.scrollbar}
  .loader {
    span {
      height: 30px !important;
      width: 30px !important;
    }
  }
  label {
    font-size: 18px !important;
    line-height: 20px;
    color: #696969 !important;
    margin-left: 0;
    font-weight: 400 !important;
  }
  .text-input-root {
    min-height: 0;
    padding-bottom: 8px;
  }
  .MuiInputBase-input {
    background: #fafcfe;
  }
  .custom-input-root:hover {
    border: none !important;
  }
  .MuiOutlinedInput-input {
    padding: 0 14px;
    height: 48px;
  }
  .MuiInputBase-root {
    &:focus-within {
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1) !important;
      border-radius: 10px !important;
    }
  }
  .upload-btn-container {
    display: flex;
    justify-content: center;
    margin-top: 8px;
    .upload-floor-plan {
      border-radius: 10px 0px 0px 10px;
      background: #fafcfe;
      border: 1px solid #e8eaeb;
      font-size: 16px;
      line-height: 20px;
      color: #696969;
      padding: 13px 16px;
      height: 48px;
      width: 188px;
      position: relative;
      z-index: 1;
      text-align: center;
      ${({ theme }) =>
        theme.dir === 'rtl' &&
        css`
          border-radius: 0px 10px 10px 0px;
        `};
      @media ${MOBILE_RESOLUTION} {
        font-size: 14px;
      }
    }
    .upload-floor-btn {
      background: #fafcfe;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      border: 1px solid #fc5c42;
      border-radius: 0px 10px 10px 0px;
      color: #fc5c42;
      width: 169px;
      height: 48px;
      text-transform: none;
      svg {
        ${rtl` margin-right: 8px;`}
      }
      ${({ theme }) =>
        theme.dir === 'rtl' &&
        css`
          border-radius: 10px 0px 0px 10px;
        `};
      @media ${MOBILE_RESOLUTION} {
        font-size: 16px;
      }
    }
  }
`;

const Icon = styled(IconButton)`
  position: absolute !important;
  ${rtl` left: 6px;`}
  top: 6px;
  svg {
    font-size: 30.86px;
  }
`;

const DetailsContainer = styled.div`
  .sign-up-form {
    input {
      background-color: transparent !important;
    }
    .MuiInputBase-root {
      border: 0 !important;
    }
  }
`;

const FormTitle = styled.div`
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: #fc5c42;
  text-align: center;
`;

const FormContainer = styled.div`
  margin-top: 16px;
`;

const UploadButton = styled(Button)<{ isImageSelected: boolean }>`
  ${({ isImageSelected }) =>
    isImageSelected &&
    css`
      background: #2cdd73 !important;
      color: white !important;
      border: 1px solid #2cdd73 !important;
    `}
`;

const FloorPlanText = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #696969;
`;

const TextWrapper = styled.div`
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  margin-top: 8px;
  color: #3e3e3e;
`;

const CreateButton = styled.button`
  width: 174px;
  height: 48px;
  background: #fc5c42;
  border-radius: 15px;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px auto 0;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  :disabled {
    background: #d9d9d9;
    cursor: default;
  }
  @media ${MOBILE_RESOLUTION} {
    height: 40px;
    font-size: 16px;
  }
`;
