import * as React from 'react';
import { isEmpty } from 'utils/lodash.utils';
import type { Setter } from 'types/common.types';
import { Option } from 'components/Autocomplete/Autocomplete.config';

type UseSelectConfig<T> = {
  selectedItems: T[] | T;
  setSelected: Setter<T> | Setter<T[]>;
  options: T[];
  multiple?: true;
  onApply: (selection: T[] | T) => void;
  applyOnChange?: boolean;
  deselectOnReselect?: boolean;
  overrideDeselectOnReselect?: boolean;
};

const useSelect = <T = string>({
  selectedItems,
  setSelected,
  options,
  multiple,
  onApply: handleApply,
  applyOnChange = false,
  deselectOnReselect = true,
  overrideDeselectOnReselect,
}: UseSelectConfig<T>) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const toggle = React.useCallback(
    (event?: React.SyntheticEvent<HTMLDivElement, Event>) => {
      setAnchorEl(anchorEl ? null : event?.currentTarget!);
    },
    [anchorEl]
  );

  const onClear = React.useCallback(() => {
    if (isEmpty(selectedItems)) return;
    const setItems = setSelected as Setter<T[]>;
    setItems([]);
  }, [setSelected, selectedItems]);

  const onClick = React.useCallback(
    (item: T | string) => {
      if (!multiple) {
        const setItem = setSelected as Setter<T[]>;
        toggle();

        if (
          (typeof item === 'object' || overrideDeselectOnReselect) &&
          (selectedItems as T[]).length &&
          JSON.stringify((selectedItems as T[])[0]) === JSON.stringify(item) &&
          deselectOnReselect
        )
          return setItem([]);
        return setItem([item as T]);
      }

      const setItems = setSelected as Setter<T[]>;

      if (typeof item === 'string' && item === 'All')
        return setItems(prevItems =>
          prevItems.length === options.length ? [] : options
        );

      if (typeof item === 'object') {
        return setItems(prevItems => {
          return ((prevItems as unknown) as Option[]).filter(
            e => e?.value === ((item as unknown) as Option)?.value
          ).length
            ? prevItems.filter(
                prevItem =>
                  ((prevItem as unknown) as Option)?.value !==
                  ((item as unknown) as Option)?.value
              )
            : [...prevItems, item as T];
        });
      }
      return setItems(prevItems =>
        prevItems.includes(item as T)
          ? prevItems.filter(prevItem => prevItem !== (item as T))
          : [...prevItems, item as T]
      );
    },
    [
      multiple,
      setSelected,
      toggle,
      selectedItems,
      deselectOnReselect,
      options,
      overrideDeselectOnReselect,
    ]
  );

  const onDelete = React.useCallback(
    (val: T) => {
      if (!multiple) return null;
      const setItems = setSelected as Setter<T[] | null>;
      return setItems(prev => prev?.filter(item => item !== val) || null);
    },
    [multiple, setSelected]
  );

  React.useEffect(() => {
    if (applyOnChange || !multiple) {
      handleApply(selectedItems);
    }
  }, [selectedItems, applyOnChange, handleApply, multiple]);

  const onApply = React.useCallback(() => {
    handleApply(selectedItems);
    toggle();
  }, [handleApply, selectedItems, toggle]);

  const isOpen = Boolean(anchorEl);

  return React.useMemo(
    () => ({
      toggle,
      isOpen,
      onClear,
      onClick,
      anchorEl,
      onDelete,
      onApply,
    }),
    [toggle, isOpen, onClick, onClear, anchorEl, onDelete, onApply]
  );
};

export default useSelect;
