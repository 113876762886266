import { unityUrl } from 'constants/config';

export enum Types {
  // React -> WebGL calls
  PASS_PROPERTY_INFO = 'PASS_PROPERTY_INFO',
  RELOAD_TOUR = 'RELOAD_TOUR',
  // WebGL -> React calls
  UNITY_LOADED_SUCCESSFULLY = 'UNITY_LOADED_SUCCESSFULLY',
  SHOW_QUOTE_MODAL = 'SHOW_QUOTE_MODAL',
  SHOW_CART_MODAL = 'SHOW_CART_MODAL',
  SHOW_BUYCOIN_MODAL = 'SHOW_BUYCOIN_MODAL',
  SHOW_BUY_NOW_MODAL = 'SHOW_BUY_NOW_MODAL',
  SHOW_BUY_SKIN_MODAL = 'SHOW_BUY_SKIN_MODAL',
  SHOW_SUPPORT_MODAL = 'SHOW_SUPPORT_MODAL',
  SHOW_SHARE_MODAL = 'SHOW_SHARE_MODAL',
  SHOW_ADD_PROPERTY_MODAL = 'SHOW_ADD_PROPERTY_MODAL    ',
  RELOAD_TOUR_REQUEST = 'RELOAD_TOUR_REQUEST',
  // WebGL TYPES
  GET_PROPERTY_INFO = 'GET_PROPERTY_INFO',
  NAVIGATE_PROPERTIES_PAGE = 'NAVIGATE_PROPERTIES_PAGE',
  NAVIGATE_PROFILE_PAGE = 'NAVIGATE_PROFILE_PAGE',
  LOGOUT_USER_REQUEST = 'LOGOUT_USER_REQUEST',
  GET_PRODUCT_INFO = 'GET_PRODUCT_INFO',
}

export enum ModalName {
  TOUR_INSTRUCTIONS_MODAL = 'TOUR_INSTRUCTIONS_MODAL',
  QUOTE_MODAL = 'QUOTE_MODAL',
  CART_MODAL = 'CART_MODAL',
  BUYCOIN_MODAL = 'BUYCOIN_MODAL',
  ADDRESSES_MODAL = 'ADDRESSES_MODAL',
  BUY_NOW_MODAL = 'BUY_NOW_MODAL',
  BUY_SKIN_MODAL = 'BUY_SKIN_MODAL',
  SUPPORT_MODAL = 'SUPPORT_MODAL',
  SHARE_MODAL = 'SHARE_MODAL',
  ADD_PROPERTY_MODAL = 'ADD_PROPERTY_MODAL',
}

export const targetOrigin = unityUrl;
export const allowedOriginList = [
  'https://patricia-webgl-app-production.s3.eu-central-1.amazonaws.com',
  'https://patricia-webgl-app-staging.s3.eu-central-1.amazonaws.com',
  'https://patricia-unity-app.s3.eu-central-1.amazonaws.com',
];

export type Payload =
  | { productId: string; amount: number }
  | { skinId: string }
  | null;

export type Data =
  | {
      type: Types.RELOAD_TOUR_REQUEST;
      payload: null;
    }
  | {
      type: Types.SHOW_QUOTE_MODAL;
      payload: null;
    }
  | {
      type: Types.SHOW_CART_MODAL;
      payload: null;
    }
  | {
      type: Types.SHOW_BUY_NOW_MODAL;
      payload: {
        productId: string;
        amount: number;
      };
    }
  | {
      type: Types.SHOW_BUY_SKIN_MODAL;
      payload: { skinId: string };
    }
  | {
      type: Types.SHOW_SUPPORT_MODAL;
      payload: null;
    }
  | {
      type: Types.SHOW_SHARE_MODAL;
      payload: null;
    }
  | {
      type: Types.SHOW_ADD_PROPERTY_MODAL;
      payload: null;
    };
