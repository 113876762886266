import * as React from 'react';
import {
  OnboardingAction,
  useOnboarding,
  useOnboardingDispatch,
} from 'context/onboardingContext';
import { useFetch } from 'hooks';
// import { isIsrael } from 'utils/addresses.utils';
import type { QuizQuestion } from 'api/responses';

// const colors = [
//   '#C49C82',
//   '#C0774A',
//   '#C1411C',
//   '#98140F',
//   '#183B1B',
//   '#95ACA4',
//   '#C1DBD0',
//   '#EBDECB',
//   '#F4A24C',
//   '#DD7649',
//   '#CCBCAD',
//   '#907E68',
//   '#646756',
//   '#A0552B',
//   '#383931',
//   '#D66D50',
//   '#F4AC94',
//   '#F7D0CB',
//   '#A2B4C0',
//   '#366775',
//   '#AFCFCE',
//   '#99B6A0',
//   '#368979',
//   '#2A7595',
//   '#0D3D47',
// ];

// const israelColors = [
//   '#2a3342',
//   '#5480a3',
//   '#dceefc',
//   '#bc9e7a',
//   '#e1dfd3',
//   '#2c3a2b',
//   '#3b603f',
//   '#ce855a',
//   '#dd8d8e',
//   '#c6b8b8',
//   '#2e2a21',
//   '#d9c9b9',
//   '#b67a3e',
//   '#cdc3c1',
//   '#c84f5e',
//   '#334654',
//   '#ffcb3f',
//   '#e85533',
//   '#f0ccc0',
//   '#ddd3d1',
//   '#1d2528',
//   '#9da6ad',
//   '#75645d',
//   '#d89674',
//   '#e4e3e1',
// ];

const useOnboardingQuiz = () => {
  const dispatch = useOnboardingDispatch();
  // const { country } = useSession();
  const [{ choosePlan, isScan }, _] = useOnboarding();

  // const colorPalate = React.useMemo(() => {
  //   return isIsrael(country ?? 'US') ? israelColors : colors;
  // }, [country]);

  const onSuccess = React.useCallback(
    (payload: QuizQuestion[] | null) => {
      const rooms = {
        answers: [],
        id: 50,
        question: 'Choose the rooms you would like to design.',
      };

      const filter = payload?.filter(
        d =>
          d.question !== 'Choose your style' &&
          d.question !== 'Choose you color palette' &&
          d.question !== 'Choose the photo that appeals to you most'
      );

      // const customQuestions: QuizQuestion[] | undefined = payload?.map(e => {
      //   if (e.question === 'Choose you color palette')
      //     return {
      //       ...e,
      //       answers: e.answers.map((ans, i) => {
      //         const val = {
      //           ...ans,
      //           colors: [
      //             colorPalate[i * 5],
      //             colorPalate[i * 5 + 1],
      //             colorPalate[i * 5 + 2],
      //             colorPalate[i * 5 + 3],
      //             colorPalate[i * 5 + 4],
      //           ],
      //         };
      //         return val;
      //       }),
      //     };
      //   return e;
      // });

      if (!choosePlan) {
        const customPayload: QuizQuestion[] = [
          rooms,
          ...(filter as QuizQuestion[]),
        ];

        dispatch({
          type: OnboardingAction.SET_QUIZ_QUESTIONS,
          payload: customPayload,
        });
      } else if (isScan) {
        const customPayload: QuizQuestion[] = [
          rooms,
          ...(filter as QuizQuestion[]).filter(
            d => !d.question.includes('budget')
          ),
        ];

        dispatch({
          type: OnboardingAction.SET_QUIZ_QUESTIONS,
          payload: customPayload,
        });
      } else {
        const customDesignStylePayload: Array<QuizQuestion | undefined> = [
          payload?.find(e => {
            return e.question.includes('Design');
          }),
          payload?.find(e => {
            return e.question.includes('view');
          }),
          // customQuestions?.find(e => {
          //   return e.question.includes('budget');
          // }),
          // customQuestions?.find(e => {
          //   return e.question.includes('color');
          // }),
        ];

        dispatch({
          type: OnboardingAction.SET_QUIZ_QUESTIONS,
          payload: customDesignStylePayload.filter(e => !!e) as QuizQuestion[],
        });
      }
    },
    [choosePlan, isScan, dispatch]
  );
  const { isLoading } = useFetch({
    initialUrl: '/api/questions/',
    config: {
      isPublic: true,
    },
    onSuccess,
  });
  return { isLoading };
};

export default useOnboardingQuiz;
