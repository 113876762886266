import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import Card, {
  CardField,
  FieldStack,
  FieldIcon,
} from 'components/@client/CardList/Card';
import { useMediaQuery } from '@material-ui/core';
import { Property } from 'types/properties';
import { NO_PROPERTY_NAME } from 'constants/common.constants';
import { MOBILE_RESOLUTION } from 'styles/sharedStyle';
import { summarizeText } from 'utils/string.utils';
import styled from 'styled-components';
import CustomTooltip from 'components/Tooltip';
import IconButton, { IconName } from 'components/Button/IconButton';
import rtl from 'styled-components-rtl';
import DeletePropertyModal from 'components/@client/DeletePropertyModal';
import { Star, StarBorder } from '@material-ui/icons';
import { useAuthentication, useFetch } from 'hooks';
import { METHODS } from 'api/client';
import { Type } from 'constants/config';

interface Props {
  property: Property;
  onClick?:
    | React.MouseEventHandler<HTMLDivElement>
    | React.KeyboardEventHandler<HTMLDivElement>;
  onRefresh?: VoidFunction;
}

const PropertyItem: React.FC<Props> = ({ property, onClick, onRefresh }) => {
  const isMobile = useMediaQuery(MOBILE_RESOLUTION);

  const [isOpen, setOpen] = React.useState(false);

  const onCloseModal = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const inProgressOverlay = React.useMemo(() => {
    if (
      property.status === 'waiting' ||
      property.status === 'to_do' ||
      property.status === 'in_progress'
    )
      return 1;
    if (property.status === 'error_in_plan') return -1;
    return 0;
  }, [property.status]);

  const { callFetch: makePropertyPrimary, isLoading } = useFetch({
    initialUrl: '/api/properties/',
    config: {
      method: METHODS.PATCH,
    },
    skipOnStart: true,
    onSuccess: () => {
      onRefresh?.();
    },
  });
  const { type } = useAuthentication();
  return (
    <Container>
      <Card
        img={property.media?.[0]?.file}
        alt={property.media?.[0]?.name}
        onClick={onClick}
        inProgressOverlay={inProgressOverlay}
        id={property.id}
        onRefresh={onRefresh}
      >
        {type === Type.CLIENT && (
          <CustomTooltip title="Set as primary" $variant="info">
            <div
              className="primary-star"
              style={{ position: 'absolute', left: 10, top: 10 }}
              onClick={e => {
                if (!isLoading) {
                  makePropertyPrimary({
                    url: `/api/properties/${property.id}/`,
                    data: {
                      is_primary: true,
                    },
                  });
                }
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              {property.is_primary ? <Star /> : <StarBorder />}
            </div>
          </CustomTooltip>
        )}
        <CustomTooltip title="Delete property" $variant="info">
          <span className="icon-clients-action">
            <IconButton
              customIcon="trash-outlined"
              $size="large"
              iconName={IconName.custom_icon}
              onClick={e => {
                e.stopPropagation();
                setOpen(true);
              }}
            />
          </span>
        </CustomTooltip>
        <CardField
          label={<FormattedMessage id={Message.PROPERTY_CARD_NAME} />}
          value={
            property.name?.length
              ? summarizeText(property.name, 20)
              : NO_PROPERTY_NAME
          }
        />
        <CardField
          label={<FormattedMessage id={Message.PROPERTY_CARD_QUIZ_NAME} />}
          value={
            property.quizResult?.quizName ?? (
              <FormattedMessage id={Message.NO_VALUE} />
            )
          }
        />
        <FieldStack>
          <CardField
            label={
              <FormattedMessage id={Message.PROPERTY_CARD_LAST_MODIFIED} />
            }
            value={
              property.updatedAt ?? <FormattedMessage id={Message.NO_VALUE} />
            }
            weight={500}
          />
          {property.quizResult?.designStyle && (
            <CardField
              weight={500}
              value={
                <>
                  <FieldIcon name="discover" size={isMobile ? 16 : 25} />{' '}
                  {property.quizResult.designStyle.name?.length
                    ? summarizeText(property.quizResult.designStyle.name, 10)
                    : NO_PROPERTY_NAME}
                </>
              }
            />
          )}
        </FieldStack>
      </Card>

      <DeletePropertyModal
        open={isOpen}
        onClose={onCloseModal}
        property={property}
        onRefresh={onRefresh}
      />
    </Container>
  );
};

export default PropertyItem;

const Container = styled.li`
  & :hover {
    & .icon-clients-action {
      display: block;
    }
  }
  > div {
    position: relative;

    & .icon-clients-action {
      position: absolute;
      ${rtl`right: 6px;`}
      top: 6px;
      display: none;
    }
  }
`;
