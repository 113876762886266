import { RendersAction } from 'store/actions/user-management/renders.actions';
import RendersActionTypes from 'store/constants/user-management/renders.constants';
import {
  GetRenderDataFulfilledPayload,
  GetRenderDataRejectedPayload,
} from 'store/types/user-management/renders.types';
import { Render } from 'types/renders';

export interface RendersState {
  loading: Record<RendersActionTypes, boolean>;
  error: Record<RendersActionTypes, null | string>;
  renders: Render[];
  pageCount: number;
}

const REQUESTS = [RendersActionTypes.LOAD_RENDERS_LIST];

const initialState: RendersState = {
  loading: REQUESTS.reduce(
    (state, next) => ({ ...state, [next]: false }),
    {} as Record<RendersActionTypes, boolean>
  ),
  error: REQUESTS.reduce(
    (state, next) => ({ ...state, [next]: null }),
    {} as Record<RendersActionTypes, null | string>
  ),
  renders: [],
  pageCount: 0,
};

const rendersReducer = (
  state: RendersState = initialState,
  action: RendersAction
): RendersState => {
  switch (action.type) {
    case RendersActionTypes.LOAD_RENDERS_LIST:
      return {
        ...updateLoading(state, RendersActionTypes.LOAD_RENDERS_LIST),
      };
    case RendersActionTypes.LOAD_RENDERS_LIST_FULFILLED:
      return {
        ...updateSuccess(
          state,
          RendersActionTypes.LOAD_RENDERS_LIST,
          action.payload as GetRenderDataFulfilledPayload
        ),
      };
    case RendersActionTypes.LOAD_RENDERS_LIST_REJECTED:
      return updateError(
        state,
        RendersActionTypes.LOAD_RENDERS_LIST,
        action.payload as GetRenderDataRejectedPayload
      );
    default:
      return state;
  }
};

export default rendersReducer;

type requestType = RendersActionTypes.LOAD_RENDERS_LIST;
const updateLoading = (state: RendersState, requestType: requestType) => ({
  ...state,
  loading: {
    ...state.loading,
    [requestType]: true,
  },
  error: {
    ...state.error,
    [requestType]: null,
  },
});

type ErrorPayload = GetRenderDataRejectedPayload;
const updateError = (
  state: RendersState,
  requestType: requestType,
  { error }: ErrorPayload
) => ({
  ...state,
  loading: {
    ...state.loading,
    [requestType]: false,
  },
  error: {
    ...state.error,
    [requestType]: error,
  },
});
type SuccessPayload = GetRenderDataFulfilledPayload;
const updateSuccess = (
  state: RendersState,
  requestType: requestType,
  payload: SuccessPayload
) => ({
  ...state,
  loading: {
    ...state.loading,
    [requestType]: false,
  },
  error: {
    ...state.error,
    [requestType]: null,
  },
  ...payload,
});
