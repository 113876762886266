import * as React from 'react';
import styled from 'styled-components';
import LoginWelcomeImage from 'assets/@client/supplier-login-flow/supplier-login-welcome.png';
import PatriciaLogo from 'assets/@client/supplier-login-flow/patricia-red-text.png';
import { FormattedMessage } from 'react-intl';
import EastIcon from '@mui/icons-material/East';
import { Message } from 'i18n';
import { useAuthentication, useRouter } from 'hooks';
import { ClientRouter } from 'router/routes';
import { MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';
import { useMediaQuery } from '@material-ui/core';
import SupplierLoginWelcomeMobile from './SupplierLoginWelcomeMobile';

const SupplierLoginWelcome: React.FC = () => {
  const { fullName } = useAuthentication();
  const { history } = useRouter();
  const isMobile = useMediaQuery(MOBILE_TABLET_RESOLUTION);
  return (
    <>
      {isMobile ? (
        <SupplierLoginWelcomeMobile />
      ) : (
        <ParentLoginWelcome>
          <LeftContainer>
            <ParentDivText>
              <a href={ClientRouter.APP} className="patricia-logo">
                <img src={PatriciaLogo} alt="patricia-logo" />
              </a>
              <WelcomeTitle>
                <FormattedMessage
                  id={Message.SUPPLIER_LOGIN_WELCOME_TITLE}
                  values={{ name: fullName }}
                />
              </WelcomeTitle>
              <SubTitle>
                <FormattedMessage
                  id={Message.SUPPLIER_LOGIN_WELCOME_SUBTITLE}
                  values={{ linebreak: <br /> }}
                />
              </SubTitle>
              <LetsDoThisButton
                onClick={() => history.push(ClientRouter.SUPPLIER_INFO)}
              >
                <LetsDoThisText>
                  <FormattedMessage
                    id={Message.SUPPLIER_LOGIN_WELCOME_LETSDOTHIS_BUTTON}
                  />
                  <IconDiv>
                    <EastIcon />
                  </IconDiv>
                </LetsDoThisText>
              </LetsDoThisButton>
            </ParentDivText>
          </LeftContainer>
          <RightContainer>
            <img
              style={{ height: '100%' }}
              src={LoginWelcomeImage}
              alt="login-seller"
            />
          </RightContainer>
        </ParentLoginWelcome>
      )}
    </>
  );
};
export default SupplierLoginWelcome;

const ParentLoginWelcome = styled.div`
  display: flex;
  width: 100%;
  margin-top: -65px;
  font-family: 'Roboto Condensed';
  direction: ltr;
  .patricia-logo {
    margin-left: 50%;
    transform: translateX(-50%);
    margin-bottom: 24px;
  }
`;
const ParentDivText = styled.div`
  margin: auto 20px;
`;
const LeftContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RightContainer = styled.div`
  width: 50%;
`;

const WelcomeTitle = styled.div`
  font-weight: 600;
  font-size: 40px;
  line-height: 44px;
  color: #282929;
  margin-top: 24px;
`;

const SubTitle = styled.div`
  font-size: 24px;
  line-height: 28px;
  color: #3e3e3e;
  margin-top: 24px;
`;

const LetsDoThisButton = styled.button`
  cursor: pointer;
  background: #fc5c42;
  border: 0.5px solid #d9d9d9;
  border-radius: 15px;
  padding: 12px 19px;
  width: 184px;
  height: 48px;
  margin-top: 24px;
`;

const LetsDoThisText = styled.div`
  display: flex;
  color: #ffffff;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
`;

const IconDiv = styled.div`
  margin-left: 8px;
`;
