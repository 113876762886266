import { METHODS } from 'api/client';
import useFetch from 'hooks/useFetch';
import * as React from 'react';

export type useBulkAddToCartProduct = {
  cartData: null;
};

const useAddedToCartBulk = () => {
  const onFailure = React.useCallback(() => {}, []);

  const onSuccess = React.useCallback(() => {}, []);

  const { data = [], isLoading, callFetch } = useFetch<{}>({
    initialUrl: '/api/shopping_cart_item/products_bulk_add_to_cart/',
    skipOnStart: true,
    config: {
      method: METHODS.POST,
    },
    onSuccess,
    onFailure,
  });
  return { data, isLoading, callBulkProductAddCart: callFetch };
};

export default useAddedToCartBulk;
