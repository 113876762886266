import * as React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import styled from 'styled-components';
import { Option } from 'components/Autocomplete/Autocomplete.config';
import { BasicOption, CountryOption } from 'components/Filters/Options';
import FlagIcon from 'components/FlagIcon';
import { ComboBox, Field } from 'components/forms';
import CityFilter from 'components/forms/CitiesInput';
import countries, {
  countryOptions,
  CountryRecord,
  ISOCode,
} from 'data/countries';
import { stateOptions } from 'data/states';
import { useBreakpoint } from 'hooks';
import { FormRow } from 'styles/sharedStyle';
import { IAddress, Setter } from 'types/common.types';
import useUpdateAddress from 'pages/common/hooks/useUpdateAddress';
import { summarizeText } from 'utils/string.utils';
import AddressCheckboxes from './AddressCheckboxes';
import AddressesFormActions from './AddressFormActions';

interface Props {
  addressesCount?: number;
  defaultValues?: IAddress | object;
  isLoading?: boolean;
  withCheckboxes?: boolean;
  withActions?: boolean;
  onCancel?: () => void;
  required?: boolean;
  isShown?: boolean;
}

const AddressFormFields: React.FC<Props> = ({
  addressesCount,
  defaultValues,
  isLoading,
  withCheckboxes,
  withActions,
  required = true,
  onCancel,
  isShown,
}) => {
  const { setValue, formState, control } = useFormContext();
  const { isDirty, isValid } = formState;
  const entries = useWatch({ control });
  const {
    country,
    setCountry,
    state,
    setState,
    isStateSelectDisabled,
    handlePaste,
    handleAlphaNumericField,
    handleNumericField,
  } = useUpdateAddress(entries as IAddress, defaultValues as IAddress);

  const { isSmallScreen } = useBreakpoint();
  const comboBoxWidth = isSmallScreen ? 220 : 295;
  const comboBoxTextLimit = isSmallScreen ? 23 : 35;

  React.useEffect(() => {
    const { touched } = formState;
    if (!touched.country) return;
    if (entries.country !== country?.iso) {
      setValue('city', '', { shouldValidate: true });
      setValue('state', '', { shouldValidate: true });
      setState(null);
    }
  }, [country, setValue, entries.country, setState, formState]);

  const isConfirmDisabled =
    !(isDirty && isValid) || (country?.name === countries.US && !state);

  return (
    <>
      <FirstRow>
        <ComboBox
          label={{ title: 'Country' }}
          name="country"
          options={countryOptions}
          searchPlaceholder="Search for country..."
          renderOption={CountryOption}
          placeholder="Select country"
          required={isShown || required}
          selectedValue={country}
          setSelectedValue={setCountry as Setter<Option | null>}
          setterKey="iso"
          optionTextLimit={comboBoxTextLimit}
          width={comboBoxWidth}
          renderValue={option =>
            option ? (
              <FlagIcon
                ISO={option.iso as ISOCode}
                name={summarizeText(
                  option.name.toString(),
                  isSmallScreen ? 20 : 23
                )}
              />
            ) : null
          }
        />
        <ComboBox
          disabled={isStateSelectDisabled}
          label={{ title: 'State', parenthesesContent: 'Only for US' }}
          name="state"
          options={stateOptions}
          searchPlaceholder="Search for state..."
          renderOption={BasicOption}
          placeholder="Select state"
          required={required}
          selectedValue={state}
          setSelectedValue={setState}
          setterKey="iso"
          optionTextLimit={comboBoxTextLimit}
          width={comboBoxWidth}
        />
        <CityFilter
          name="city"
          label={{ title: 'City' }}
          placeholder="Select city"
          required={required}
          countryISO={country as CountryRecord}
          width={comboBoxWidth}
        />
      </FirstRow>
      <SecondRow>
        <Field
          label={{ title: 'Street address' }}
          name="street"
          type="text"
          required={required}
        />
        <HomeColumns>
          <Field
            onPaste={handlePaste}
            onKeyPress={handleAlphaNumericField}
            label={{ title: 'House No.' }}
            name="house"
            type="text"
            required={required}
          />
          <Field
            onPaste={handlePaste}
            onKeyPress={handleAlphaNumericField}
            label={{ title: 'Apartment Number' }}
            name="apartmentNumber"
            type="text"
            required={required}
          />
          <Field
            onPaste={handlePaste}
            onKeyPress={handleNumericField}
            label={{ title: 'Floor' }}
            name="floor"
            type="text"
            required={required}
          />
        </HomeColumns>
        <Field
          label={{ title: 'ZIP', parenthesesContent: 'optional' }}
          name="zipCode"
          type="text"
        />
      </SecondRow>
      {(withCheckboxes || withActions) && (
        <CheckboxesRow>
          {withCheckboxes && (
            <AddressCheckboxes
              defaultValues={defaultValues}
              addressesCount={addressesCount}
            />
          )}
          {withActions && (
            <AddressesFormActions
              isLoading={isLoading}
              onCancel={onCancel}
              isConfirmDisabled={isConfirmDisabled}
            />
          )}
        </CheckboxesRow>
      )}
    </>
  );
};

export default AddressFormFields;

const FirstRow = styled.div`
  ${FormRow};
  justify-content: space-between;

  div {
    flex: 0.31;
    max-width: unset;
  }
`;

const SecondRow = styled.div`
  ${FormRow};
  justify-content: space-between;
  margin-bottom: 0;

  div {
    flex: 0.315;

    @media (max-width: 1500px) {
      flex: 0.31;

      &:nth-child(3) {
        flex: 0.22;
      }
    }
  }
`;

const HomeColumns = styled.div`
  display: flex;
  justify-content: space-between;

  div {
    margin-left: 13px;
    flex: 0.27 !important;

    div {
      margin-left: unset;
    }
  }

  @media (max-width: 1500px) {
    flex: 0.4 !important;
  }
`;

const CheckboxesRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0 5px 5px;
  margin-top: 24px;
`;
