import * as React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { orderCancelSchema } from 'utils/validation.utils';
import { useFetch, useNotifications } from 'hooks';
import TextInput from 'components/@client/form-components/TextInput';
import { PhoneField } from 'components/forms';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Modal, { ModalAction } from 'components/@client/Modal';
import { MOBILE_RESOLUTION } from 'styles/sharedStyle';
import styled from 'styled-components';
import { Message } from 'i18n';
import { Order, CancelOrderPayload } from 'types/orders';
import { METHODS } from 'api/client';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  order: Order;
  onRefresh?: VoidFunction;
}

const OrderCancelModal: React.FC<Props> = ({
  open,
  onClose,
  order,
  onRefresh,
}) => {
  const intl = useIntl();
  const methods = useForm<CancelOrderPayload>({
    resolver: yupResolver(orderCancelSchema),
    defaultValues: {
      email: '',
      phoneNumber: '',
    },
    mode: 'all',
  });

  const { handleSubmit, formState } = methods;
  const { showNotification } = useNotifications();
  const onSuccess = React.useCallback(
    _ => {
      showNotification({
        key: 'clients/deletePropertyFulfilled',
        message: _,
        severity: 'success',
      });
      onClose();
      onRefresh?.();
    },
    [showNotification, onClose, onRefresh]
  );

  const onFailure = React.useCallback(
    (message: string) => {
      showNotification({
        key: 'clients/deletePropertyRejected',
        message,
        severity: 'error',
      });
      onClose();
    },
    [showNotification, onClose]
  );

  const { callFetch, isLoading } = useFetch<{}>({
    initialUrl: '/api/orders/cancel/',
    skipOnStart: true,
    config: {
      method: METHODS.POST,
    },
    onSuccess,
    onFailure,
  });

  const onSubmit = React.useCallback(
    (payload: CancelOrderPayload) => {
      const data = {
        email: payload.email,
        phone_number: payload.phoneNumber,
        order_id: order.id,
      };
      callFetch({ data, method: METHODS.POST });
    },
    [callFetch, order]
  );

  const actions: ModalAction[] = [
    {
      label: intl.formatMessage({ id: Message.CANCEL }),
      onClick: () => onClose(),
      variant: 'secondary',
    },
    {
      label: intl.formatMessage({ id: Message.SUBMIT }),
      onClick: handleSubmit(onSubmit),
      variant: 'primary',
      isLoading,
      disabled: !formState.isValid,
    },
  ];

  return (
    <ModalComponent
      open={open || false}
      onClose={onClose}
      title={intl.formatMessage({ id: Message.PROPERTY_CANCEL_ORDERS_TITLE })}
      actions={actions}
      maxWidth="sm"
    >
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Container>
            <Title>
              {intl.formatMessage({
                id: Message.PROPERTY_CANCEL_ORDERS_DESCRIPTION_LABLE,
              })}
            </Title>
            <Body>
              <FormattedMessage
                id={Message.PROPERTY_CANCEL_ORDERS_DESCRIPTION}
                values={{
                  ORDER_NUMBER: order.id,
                  linebreak: <br />,
                }}
              />
            </Body>
            <Title>
              {intl.formatMessage({
                id: Message.PROPERTY_CANCEL_ORDERS_CONTACTINFO_LABLE,
              })}
            </Title>
            <TextInput
              name="email"
              control={methods.control}
              label={intl.formatMessage({
                id: Message.AUTH_LOGIN_EMAIL_FIELD,
              })}
              type="email"
              required
            />
            <PhoneField
              name="phoneNumber"
              label={{
                title: intl.formatMessage({ id: Message.PHONE_NUMBER }),
              }}
              required
            />
          </Container>
        </Form>
      </FormProvider>
    </ModalComponent>
  );
};

export default OrderCancelModal;

const ModalComponent = styled(Modal)`
  .MuiDialogContent-root {
    padding: 0px 24px;
  }
  .MuiDialogTitle-root {
    padding: 0px !important;
    margin: 0 !important;
    h2 {
      text-align: center;
    }
  }
  header {
    position: relative;
    top: 0px;
    left: 0px;
    text-align: end;
  }
`;

const Container = styled.section`
  @media (min-width: 1150px) {
    padding: 0 30px;
    flex: 0.5;

    & .phoneNumber {
      margin-top: -16px;
    }
  }
  .phoneNumber {
    min-height: 98px;
  }
  .text-input-root {
    margin-bottom: 20px;
  }
`;

const Body = styled.p`
  margin-bottom: 20px;
  font-size: 18px;
  text-align: left;
  color: #808080;
  @media ${MOBILE_RESOLUTION} {
    font-size: 13px;
  }
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .main-button {
    margin: 0;
    min-width: 146px;
    height: 48px;
    padding: 0;
    width: unset;
    text-transform: none;
  }
`;

const Title = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 145%;
`;
