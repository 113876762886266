import * as React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import App from 'App';
import reportWebVitals from './reportWebVitals';

// eslint-disable-next-line
console.log('process.env.REACT_APP_ENV: ', process.env.REACT_APP_ENV);
if (process.env.REACT_APP_ENV === 'production') {
  Sentry.init({
    dsn:
      'https://691d3e204f494235bfe9e97b3d454f10@o359765.ingest.sentry.io/4505205044346880',
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
} else if (process.env.REACT_APP_ENV === 'development') {
  Sentry.init({
    dsn:
      'https://bf4f9bfca3324c3b80f5d13c1b059371@o359765.ingest.sentry.io/4505227450384384',
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
