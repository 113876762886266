import styled from 'styled-components';
import { ListItem } from 'components/Autocomplete';
import type { AutocompleteRenderOptionState } from '@material-ui/core/Autocomplete';
import type { Option } from 'components/Autocomplete/Autocomplete.config';

const BasicOption = () => (
  props: React.HTMLAttributes<HTMLLIElement>,
  option: Option,
  _?: AutocompleteRenderOptionState
) => {
  return (
    <ListItem key={option?.name} tabIndex={0} {...props}>
      <div className="item-wrapper">
        <ItemName>{option?.name as string}</ItemName>
      </div>
    </ListItem>
  );
};

export default BasicOption;

const ItemName = styled.span`
  white-space: nowrap;

  .item-wrapper {
    max-width: '100px';
  }
`;
