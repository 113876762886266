const integration = {
  onboardTitle: 'Onboard ',
  onboardDesc: 'as a supplier to patricia.',
  model: '3D model ',
  modeldesc: 'creation and quickly and easily.',
  boost: 'Boost ',
  boostDesc: 'your Brand and enhance sales.',
};

export default integration;
