import { all, call, put, debounce } from 'redux-saga/effects';
import QuizzesDataType from 'store/constants/quiz-management/quizManagement.constants';
import { QuizzesAPI } from 'api/methods';
import * as QuizzesActions from 'store/actions/quiz-management/quizManagement.actions';
import * as Payload from 'store/types/quizManagement.types';
import { showNotification } from 'store/actions/notifications.actions';
import { removeEmptyValues } from 'utils/common.utils';
import { newSortByMap } from 'utils/transform.utils';
import { FILTER_DATE_FORMAT, formatDate } from 'utils/dates.utils';

function* errorHandler(
  e: Error,
  type: QuizzesDataType,
  rejectAction: (payload: {
    error: string;
  }) => { type: QuizzesDataType; payload: { error: string } }
) {
  const error = e.message || 'ERROR: Cannot refresh Data source';
  yield put(showNotification({ key: type, message: error, severity: 'error' }));
  yield put(rejectAction({ error }));
}

const sortQuizzesMap = {
  id: 'id',
  name: 'name',
  designStyles: 'design_styles__name',
  createdAt: 'created_at',
  createdBy: 'created_by',
};

function* getQuizzesDataRequest({
  payload,
  type,
}: ReturnType<typeof QuizzesActions.getQuizzesDataRequest>) {
  try {
    const { page = 0, size = 10, search, dateRange, sortBy } = payload;
    const params = removeEmptyValues({
      page: page + 1,
      size,
      search,
      from_date: formatDate(dateRange?.startDate, FILTER_DATE_FORMAT, ''),
      to_date: formatDate(dateRange?.endDate, FILTER_DATE_FORMAT, ''),
      is_published: true,
      ...newSortByMap<typeof sortQuizzesMap>(sortQuizzesMap, sortBy),
    }) as Payload.GetQuizzesDataRequestPayload;
    const {
      results: quizzes,
      count,
    }: Payload.GetQuizzesDataResponsePayload = yield call(
      QuizzesAPI.getQuizzes,
      { params }
    );

    const pageCount = Math.ceil(count / size);
    yield put(QuizzesActions.getQuizzesDataFulfilled({ quizzes, pageCount }));
  } catch (e) {
    yield call(errorHandler, e, type, QuizzesActions.getQuizzesDataRejected);
  }
}

function* quizzesSaga() {
  yield all([
    debounce(
      500,
      QuizzesDataType.GET_QUIZZES_DATA_REQUEST,
      getQuizzesDataRequest
    ),
  ]);
}

export default quizzesSaga;
