import { all, call, put, takeLatest } from 'redux-saga/effects';
import UserInfoType from 'store/constants/@client/userInfo.constants';
import * as Payload from 'store/types/@client/UserInfo.types';
import * as UserInfoActions from 'store/actions/@client/userInfo.actions';
import { showNotification } from 'store/actions/notifications.actions';
import { ClientAPI } from 'api/methods';

function* errorHandler(
  e: Error,
  type: UserInfoType,
  rejectAction: (payload: {
    error: string;
  }) => { type: UserInfoType; payload: { error: string } }
) {
  const error = e.message || 'ERROR: Cannot get last property';
  yield put(showNotification({ key: type, message: error, severity: 'error' }));
  yield put(rejectAction({ error }));
}

function* getRecentPropertyRequest({
  type: actionType,
}: ReturnType<typeof UserInfoActions.getRecentPropertyRequest>) {
  try {
    const property: Payload.getRecentPropertyResponsePayload = yield call(
      ClientAPI.getRecentProperty
    );

    yield put(UserInfoActions.getRecentPropertyFulfilled(property));
  } catch (e) {
    yield call(
      errorHandler,
      e,
      actionType,
      UserInfoActions.getRecentPropertyRejected
    );
  }
}

function* UserInfoSaga() {
  yield all([
    takeLatest(
      UserInfoType.GET_RECENT_PROPERTY_REQUEST,
      getRecentPropertyRequest
    ),
  ]);
}

export default UserInfoSaga;
