import * as React from 'react';
import * as Response from 'api/responses';
import * as Transform from 'api/transform';
import type { SlideItem } from 'components/@client/SlideshowModal/Slide';
import { useFetch, useRouter } from 'hooks';
import type { Skin } from 'types/properties';
import useMoreFromSkinUser from './useMoreFromSkinUser';

const useSkin = () => {
  const { query } = useRouter<{
    id: string;
  }>();
  const initialId = React.useRef<string | null>(query.id);

  const { userSkins, isLoadingSkins, fetchUserSkins } = useMoreFromSkinUser();

  const onSuccess = React.useCallback(
    (skin: Skin | null) => {
      if (skin) fetchUserSkins(skin.user.id);
    },
    [fetchUserSkins]
  );

  const { data: skin, isLoading, hasError, callFetch } = useFetch<
    Skin,
    Response.Skin
  >({
    initialUrl: `/api/skins/${query.id}/`,
    transform: Transform.skin,
    onSuccess,
  });

  const products = React.useMemo(
    () =>
      skin?.rooms
        ?.reduce(
          (prev, next) => [
            ...prev,
            ...(next?.subCategories?.map(subCategory => ({
              id:
                +subCategory?.product?.media?.[0]?.id ??
                +subCategory?.product?.thumbnail?.id,
              file:
                subCategory?.product?.media?.[0]?.file ??
                subCategory?.product?.thumbnail?.file,
              name: subCategory?.product?.name,
              type: '',
              sort: Math.random(),
            })) ?? []),
          ],
          [] as (SlideItem & { sort: number })[]
        )
        ?.slice(0, 10)
        .sort((a, b) => a.sort - b.sort)
        .map(({ sort, ...value }) => value) ?? [],
    [skin?.rooms]
  );

  React.useEffect(() => {
    if (initialId.current !== query.id)
      callFetch({ url: `/api/skins/${query.id}/` });
    initialId.current = null;
  }, [callFetch, query.id]);

  return { skin, isLoading, hasError, userSkins, isLoadingSkins, products };
};

export default useSkin;
