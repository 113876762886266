import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import FileDisplay, { FileType } from 'components/@client/FileDisplay';
import { OnboardingAction, useOnboarding } from 'context/onboardingContext';
import { Message } from 'i18n';
import { PropertyMedia } from 'types/properties';
import { FloorGrid } from 'pages/appClient/onboarding/shared';
import SecondaryButton from 'components/@client/Button/SecondaryButton';

interface Props {
  next: VoidFunction;
  prev: VoidFunction;
}

const SelectFloor: React.FC<Props> = ({ next, prev }) => {
  const [{ image, selectedFloor, floorPlanFiles }, dispatch] = useOnboarding();

  const setSelectedFloor = React.useCallback(
    (payload: PropertyMedia | null = null) =>
      dispatch({ type: OnboardingAction.SET_SELECTED_FLOOR, payload }),
    [dispatch]
  );

  React.useEffect(() => {
    if (selectedFloor) next();
  }, [next, selectedFloor]);

  return (
    <Container>
      <FileDisplay
        type={FileType['application/pdf']}
        fileName={image ? `${image.name}` : undefined}
      />
      <LineBreak />
      <FormattedMessage id={Message.SELECT_FLOOR_CAPTION} tagName="p" />
      <FloorGrid
        items={floorPlanFiles ?? []}
        selectedFloor={selectedFloor?.id}
        onClick={floor => setSelectedFloor?.(floor)}
      />
      <SecondaryButton className="prev-button" onClick={prev}>
        <FormattedMessage id={Message.PROPERTY_ADD_PROPERTY_BUTTON_PREVIOUS} />
      </SecondaryButton>
    </Container>
  );
};

export default SelectFloor;

const Container = styled.div`
  .prev-button {
    margin-top: 20px;
  }

  .file-display {
    height: 75px;
  }
`;

const LineBreak = styled.hr`
  border-top: 0;
  border-bottom: 1px solid #a99997;
`;
