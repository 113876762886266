import { Button, TextField, Checkbox, MenuItem, Select } from '@mui/material';
import * as React from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as UploadIcon } from 'assets/@client/supplier/cloud-upload-btn.svg';
import { ReactComponent as UploadDoneIcon } from 'assets/@client/supplier/cloud-upload-done.svg';
import { Message } from 'i18n';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import FloorDesign from 'assets/@client/supplier/floor-design.png';
import rtl from 'styled-components-rtl';
import InsertInvitationOutlinedIcon from '@mui/icons-material/InsertInvitationOutlined';
import { Controller, FormProvider } from 'react-hook-form';
import useClientInformation from 'hooks/useClientInformation';
import { PhoneField } from 'components/forms';
import { ClientInformationType } from 'types/common.types';
import { PopupModal } from 'react-calendly';
import Loader from 'components/Loader/Loader';
import { useRouter } from 'hooks';
import { isIsrael } from 'utils/addresses.utils';
import useUploadPlan from '../onboarding/upload-plan/useUploadPlan';

const UserForm: React.FC<{
  supplier?: number;
  calendlyUrl?: string;
  locations?: [
    {
      apartment_number: string;
      floor: number;
      house: string;
      street: string;
      city: string;
      country: string;
      state: string;
      zip_code: string;
      id?: number;
    }
  ];
}> = ({ supplier, calendlyUrl, locations }) => {
  const intl = useIntl();
  const {
    methods,
    onUpdatePassword,
    handleSave,
    isPassword,
    image,
    isLoading,
  } = useClientInformation();
  const { control, getValues, handleSubmit } = methods;

  React.useEffect(() => {
    if (isPassword) {
      onUpdatePassword();
    }
  }, [isPassword, onUpdatePassword]);

  const onSubmit = React.useCallback(
    (payload: ClientInformationType) => {
      handleSave({ ...payload, supplier });
    },
    [handleSave, supplier]
  );
  const [open, setOpen] = React.useState(false);
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [isTncAgreed, setIsTncAgreed] = React.useState(false);
  const { setImage } = useUploadPlan();
  const rootElement = document.getElementById('root');

  const { query } = useRouter<{
    calendly: string;
    tncLink: string;
  }>();

  return (
    <UserFormContainer>
      <Image src={FloorDesign} alt="floor-design" />
      <h4>
        <FormattedMessage id={Message.LANDING_PAGE_FORM_HEADING} />
      </h4>
      <h6>
        <FormattedMessage id={Message.LANDING_PAGE_HEADING_TEXT} />
      </h6>
      <input
        type="file"
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const fileList = e.target.files;
          if (!fileList) return;
          setImage(fileList[0]);
        }}
      />

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-container">
            <Controller
              name="firstName"
              control={control}
              defaultValue=""
              rules={{ required: 'First name required' }}
              render={({ onChange, value }) => (
                <TextField
                  placeholder={intl.formatMessage({
                    id: Message.LANDING_PAGE_FIRST_NAME,
                  })}
                  onChange={onChange}
                  required
                  value={value}
                />
              )}
            />
            <Controller
              name="lastName"
              control={control}
              defaultValue=""
              rules={{ required: 'Last name required' }}
              render={({ onChange, value }) => (
                <TextField
                  placeholder={intl.formatMessage({
                    id: Message.LANDING_PAGE_LAST_NAME,
                  })}
                  onChange={onChange}
                  required
                  value={value}
                />
              )}
            />
          </div>
          <div className="email-container">
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{ required: 'Email required' }}
              render={({ onChange, value }) => (
                <TextField
                  placeholder={intl.formatMessage({
                    id: Message.LANDING_PAGE_FORM_EMAIL,
                  })}
                  onChange={onChange}
                  required
                  value={value}
                />
              )}
            />
          </div>
          <div className="email-container">
            <PhoneNumber name="phone" required />
          </div>
          <div className="email-container">
            <Controller
              control={methods.control}
              name="branch"
              render={({ onChange, value, name, ref }) => (
                <Select
                  inputRef={ref}
                  onChange={val => {
                    onChange(val.target.value);
                  }}
                  name={name}
                  inputProps={{
                    style: { opacity: value ? 0 : 1 },
                    'aria-label': 'Without label',
                    placeholder: intl.formatMessage({
                      id: Message.LANDING_PAGE_LOCATION,
                    }),
                  }}
                  defaultValue=""
                >
                  <MenuItem value="">Select</MenuItem>
                  {locations?.map(location => (
                    <MenuItem value={location.id} key={location.id}>{`${
                      location?.street
                    },  ${location?.city},  ${location?.state}, ${
                      isIsrael(location?.country) ? 'ישראל' : location.country
                    }`}</MenuItem>
                  ))}
                </Select>
              )}
            />
          </div>
          {query.calendly !== 'false' && calendlyUrl && rootElement && (
            <div className="email-container">
              <>
                <Button
                  className="calendly-button"
                  onClick={() => setOpen(true)}
                >
                  <InsertInvitationOutlinedIcon className="calendly-icon" />
                  <StyledScheduleMeeting>
                    {intl.formatMessage({
                      id: Message.LANDING_PAGE_SCHEDULE_MEETING,
                    })}
                  </StyledScheduleMeeting>
                </Button>
                <PopupModal
                  open={open}
                  url={calendlyUrl}
                  prefill={{
                    name: `${getValues('firstName')} ${getValues('lastName')}`,

                    email: getValues('email'),
                  }}
                  rootElement={rootElement}
                  onModalClose={() => {
                    setOpen(false);
                  }}
                />
              </>
              {/* <Button className="calendly-button" onClick={() => setOpen(true)}>
              <InsertInvitationOutlinedIcon className="calendly-icon" />
              <FormattedMessage id={Message.LANDING_PAGE_SCHEDULE_MEETING} />
            </Button>
            <Calendly open={open} setOpen={setOpen} calendlyUrl="" /> */}
            </div>
          )}

          <div className="upload-file">
            <div className="upload-btn-container">
              <div className="upload-floor-plan">
                <FormattedMessage
                  id={Message.LANDING_PAGE_UPLPAD_FLOOR_PLAN_BUTTON}
                />
              </div>
              <UploadButton
                isImageSelected={Boolean(image)}
                className="upload-floor-btn"
                onClick={() => fileInputRef.current?.click()}
              >
                {image ? <UploadDoneIcon /> : <UploadIcon />}
                <FormattedMessage
                  id={
                    image
                      ? Message.LANDING_PAGE_CHANGE_FILE_BUTTON
                      : Message.LANDING_PAGE_CHOOSE_FILE_BUTTON
                  }
                />
              </UploadButton>
            </div>
            <div className="text-container">
              <FormattedMessage id={Message.LANDING_PAGE_FILE_UPLOAD_TERMS} />
              {intl.locale !== 'he' && (
                <>
                  <br /> following formats
                </>
              )}
              <p style={{ margin: 0, fontWeight: 500 }}>
                <FormattedMessage
                  id={Message.LANDING_PAGE_FILE_UPLOAD_FORMAT}
                  values={{
                    linebreak: <br />,
                    link: (chunks: string) => (
                      <>
                        &nbsp;
                        <StyledLink to="/app/privacy-policy">
                          {chunks}
                        </StyledLink>
                      </>
                    ),
                  }}
                />
              </p>
            </div>
            <div className="terms-container">
              <StyledCheckbox
                checked={isTncAgreed}
                onChange={() => setIsTncAgreed(prev => !prev)}
              />
              <FormattedMessage
                id={Message.LANDING_PAGE_ACCEPT_TERMS}
                values={{
                  link: (chunks: string) => (
                    <>
                      &nbsp;
                      <a href={query.tncLink ?? '/app/privacy-policy'}>
                        {chunks}
                      </a>
                    </>
                  ),
                }}
              />
            </div>
            <FinishButton
              type="submit"
              disabled={
                !getValues().email ||
                !getValues().firstName ||
                !getValues().lastName ||
                !getValues().phone ||
                !isTncAgreed ||
                !image ||
                isLoading
              }
            >
              {isLoading ? (
                <Loader />
              ) : (
                <FormattedMessage id={Message.LANDING_PAGE_FINISH_BUTTON} />
              )}
            </FinishButton>
          </div>
        </form>
      </FormProvider>
    </UserFormContainer>
  );
};

const UserFormContainer = styled.div`
  width: 100v;
  background-color: white;
  padding: 48px 0;
  text-align: center;
  h4 {
    font-weight: 500;
    font-size: 30px;
    line-height: 34px;
    color: #282929;
    margin: 0;
  }
  h6 {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    color: #3e3e3e;
    margin-top: 16px;
  }
  .form-container {
    display: flex;
    width: 156px;
    justify-content: space-between;
    display: flex;
    margin: auto;
    margin-top: 16px;
    width: 328px;
    .MuiOutlinedInput-root {
      gap: 8px;
      border-radius: 10px;
      width: 156px;
    }
    input {
      background: #fafcfe;
      border: 1px solid #e8eaeb;
      border-radius: 10px;
      padding: 13px 16px;
      color: #696969;
      ::placeholder {
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #696969;
      }
    }
  }
  .email-container {
    display: flex;
    margin: auto;
    margin-top: 16px;
    width: fit-content;
    .MuiOutlinedInput-root {
      border-radius: 10px;
      width: 328px;
    }
    .MuiSelect-nativeInput {
      bottom: 5px;
      ::placeholder {
        opacity: 0.5;
      }
      border: none;
    }
    input {
      background: #fafcfe;
      border: 1px solid #e8eaeb;
      border-radius: 10px;
      padding: 13px 16px;
      opacity: 1;
      ::placeholder {
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #696969;
      }
    }

    .calendly-button {
      background: #fafcfe;
      border: 1px solid #babcbd;
      border-radius: 10px;
      padding: 13px 16px;
      margin: 0 24px;
      text-transform: none;
      color: #c1c2c3;
      white-space: nowrap;
      line-height: 22px;
      height: fit-content;
      width: 328px;
      .calendly-popup-btn {
        line-height: 22px;
        border: none;
        color: inherit;
        border: none;
        background: inherit;
        font-size: inherit;
      }
    }
    .calendly-icon {
      ${rtl`margin-right: 8px;`}
      color: #696969;
      position: absolute;
      ${rtl`left: 15px;`}
    }
  }
  .react-tel-input {
    margin-top: 0;
  }
  .phone-input {
    padding: 13px 0 13px 64px !important;
    height: 51px;
  }
  .flag-dropdown {
    height: 51px;
  }
  .country-list {
    width: 328px;
    text-align: left;
  }
  .flag-dropdown.open {
    height: 51px;
  }
  .phoneNumber {
    margin: 0 24px;
  }
  .upload-file {
    text-align: center;
    margin-top: 48px;
    .upload-btn-container {
      display: flex;
      justify-content: center;
      .upload-floor-plan {
        border-radius: 10px 0px 0px 10px;
        background: #fafcfe;
        border: 1px solid #e8eaeb;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #282929;
        padding: 13px 16px;
        height: 48px;
        position: relative;
        z-index: 1;
        ${({ theme }) =>
          theme.dir === 'rtl' &&
          css`
            border-radius: 0px 10px 10px 0px;
          `};
      }
      .upload-floor-btn {
        background: #fafcfe;
        /* Primary Color */
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        border: 1px solid #fc5c42;
        border-radius: 0px 10px 10px 0px;
        color: #fc5c42;
        width: 169px;
        height: 48px;
        text-transform: none;
        svg {
          ${rtl` margin-right: 8px;`}
        }
        ${({ theme }) =>
          theme.dir === 'rtl' &&
          css`
            border-radius: 10px 0px 0px 10px;
          `};
      }
    }
    .text-container {
      margin-top: 8px;
      line-height: 16px;
      font-size: 14px;
      color: #3e3e3e;
    }
    .terms-container {
      margin-top: 35px;
      line-height: 22px;
      font-size: 18px;
      color: #3e3e3e;
    }
  }
`;
// const Image = styled.img`
//   position: absolute;
//   opacity: 3px;
//   ${rtl`right: 8px;`}
//   height: 468px;
//   ${({ theme }) =>
//     theme.dir === 'rtl' &&
//     css`
//       transform: rotateY(180deg);
//     `};
// `;

const UploadButton = styled(Button)<{ isImageSelected: boolean }>`
  ${({ isImageSelected }) =>
    isImageSelected &&
    css`
      background: #2cdd73 !important;
      color: white !important;
      border: 1px solid #2cdd73 !important;
    `}
`;

const StyledCheckbox = styled(Checkbox)`
  .MuiSvgIcon-root {
    font-size: 20px;
    color: #2e294e;
  }
`;

const FinishButton = styled.button`
  margin-top: 32px;
  width: 240px;
  height: 48px;
  background: #fc5c42;
  border-radius: 15px;
  border: none;
  font-size: 22px;
  line-height: 26px;
  color: #ffffff;
  cursor: pointer;
  :hover {
    background: #a50a0a;
  }
  :disabled {
    background: #d9d9d9;
    color: #ffffff;
    border: 1px solid #d9d9d9;
  }
`;

const Image = styled.img`
  position: absolute;
  left: -20px;
  width: 475px;
  margin: -30px auto;
  right: 0px;
  opacity: 0.07;
  rotate: -29deg;
  z-index: -111111;
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => (theme.dir === 'rtl' ? '#5C95FF' : '#3E3E3E')};
  text-decoration: none;
  border-bottom: 1px solid #5c95ff;
`;

const StyledScheduleMeeting = styled.p`
  margin: 0;
  position: relative;
  margin-left: ${({ theme }) => (theme.dir === 'rtl' ? '160px' : '-104px')};
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
`;

const PhoneNumber = styled(PhoneField)`
  &:hover {
    .flag-dropdown,
    .form-control {
      border: 1px solid black;
    }
  }
  .MuiFormHelperText-root {
    width: 100%;
    color: rgba(0, 0, 0, 0.54);
  }
  .icon-information-circle {
    display: none;
  }
  width: 328px;
`;

export default UserForm;
