import * as React from 'react';
import styled from 'styled-components';
import { useFormattedCurrency } from 'hooks';
import rtl from 'styled-components-rtl';
import SafeImg from 'components/SafeImg';
import ProductPageModal from 'pages/appClient/productPage/component/ProductPageModal';
import { Product } from 'types/products';
import { ClientRouter } from 'router/routes';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { LogsAPI } from 'api/methods';
import { BEST_SELLER_LIST } from 'constants/common.constants';

interface Props {
  product: Product;
  onClick?: (product: Product) => void;
}
const BestSellerCard: React.FC<Props> = ({ product, onClick }) => {
  const intl = useIntl();
  const formattedPrice = useFormattedCurrency(product.price);
  const formattedMSRP = useFormattedCurrency(product.msrp);
  return (
    <>
      <Card>
        <StyledLink
          onClick={() => {
            LogsAPI.pushDataLayer([
              { ecommerce: null },
              {
                event: ' productClick',
                ecommerce: {
                  impressions: {
                    name: product.name,
                    id: product.id,
                    price: product.price,
                    brand: product.manufacture.name,
                    category: product.category,
                    list: BEST_SELLER_LIST,
                  },
                },
              },
            ]);
            if (onClick) onClick(product);
          }}
          to={ClientRouter.PRODUCT_PAGE.replace(
            ':catName',
            (intl.locale === 'he' && product.categoryAltName) ||
              product.category
          )
            .replace(
              ':subCatName',
              (intl.locale === 'he' && product.subCategoryAltName) ||
                product.subCategory
            )
            .replace(':productId', product.id)}
        >
          <ZoomImgDiv>
            <ImageContainer>
              <Img src={product.thumbnail?.file} alt={product.name} />
            </ImageContainer>
          </ZoomImgDiv>
          <h4>{product.altName || product.name}</h4>
          <SubCategory>
            {(intl.locale === 'he' && product.subCategoryAltName) ||
              product.subCategory}
          </SubCategory>
          {typeof product.supplier !== 'string' && (
            <Subtitle>{product.supplier.companyName}</Subtitle>
          )}
          <Price>
            {formattedPrice}
            {product.msrp > product.price && (
              <MSRPPrice>{formattedMSRP}</MSRPPrice>
            )}
          </Price>
        </StyledLink>
        <StyledProductPageModal>
          <ProductPageModal product={product} />
        </StyledProductPageModal>
      </Card>
    </>
  );
};

export default BestSellerCard;
const Card = styled.div`
  cursor: pointer;
  margin: 15px 18px;
  border-radius: 25px;
  padding-bottom: 10px;
  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  h4 {
    font-family: ${({ theme }) =>
      theme.dir === 'rtl' ? 'Assistant' : 'Roboto'};
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: -0.01px;
    height: 70px;
    margin: 10px 10px 0;
    overflow: hidden;
  }
`;
const SubCategory = styled.span`
  ${rtl`
    margin: 0 10px;
  `}
`;

const Subtitle = styled.h3`
  font-family: ${({ theme }) => (theme.dir === 'rtl' ? 'Assistant' : 'Roboto')};
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  margin-top: 5px;
  margin-bottom: 0px;
  letter-spacing: -0.01px;
  color: #424242;
  ${rtl`padding-left: 10px;`}
`;

const Price = styled.div`
  font-family: ${({ theme }) => (theme.dir === 'rtl' ? 'Assistant' : 'Roboto')};
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 21px;
  color: #424242;
  letter-spacing: -0.01px;
  margin-top: 10px;
  margin-bottom: 5px;
  display: flex;
  ${rtl`padding-left: 10px;`}
`;

const MSRPPrice = styled.span`
  font-family: ${({ theme }) => (theme.dir === 'rtl' ? 'Assistant' : 'Roboto')};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-decoration: line-through;
  line-height: 21px;
  color: #424242;
  letter-spacing: -0.01px;
  margin-top: 0;
  margin-bottom: 5px;
  ${rtl`padding-left: 10px;`}
`;

const Img = styled(SafeImg)`
  border-radius: 25px;
`;

const ZoomImgDiv = styled.div`
  width: 100%;
  height: 309px;
  overflow: hidden;
  border-radius: 25px;
  img {
    transition: transform 1s ease;
    &:hover {
      transform: scale(1.2);
    }
  }
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

const ImageContainer = styled.div`
  & > img {
    width: 100%;
    max-height: 309px;
  }
`;
const StyledProductPageModal = styled.div`
  .close-button {
    margin-bottom: 5px;
  }
  ${rtl`margin: 0px 10px;`}
`;

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`;
