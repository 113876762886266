import styled, { css } from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

interface Props {
  size?: number;
  fullHeight?: boolean;
}

const Loader: React.FC<Props> = ({ size = 40, fullHeight }) => {
  return (
    <Root className="loader" fullHeight={fullHeight}>
      <CircularProgress color="inherit" size={size} />
    </Root>
  );
};

const Root = styled.div<{ fullHeight?: boolean }>`
  ${({ theme }) => theme.utils.centerFlex};
  flex: 1;
  opacity: 0.4;
  width: 100%;

  ${({ fullHeight }) =>
    fullHeight &&
    css`
      height: 100%;
    `}
`;

export default Loader;
