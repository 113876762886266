import * as React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { ClientRouter } from 'router/routes';
import SafeImg from 'components/SafeImg';
import { Category } from 'types/products';
import { sortBy } from 'utils/lodash.utils';
import NoProduct from 'components/@client/NoProduct/NoProduct';
import { Message } from 'i18n';
import { useIntl } from 'react-intl';
import {
  MOBILE_RESOLUTION,
  MOBILE_TABLET_RESOLUTION,
} from 'styles/sharedStyle';

interface Props {
  categories: Category[] | [];
  title?: string;
  count?: number;
}

const CategoriesCard: React.FC<Props> = ({ categories, title, count }) => {
  const intl = useIntl();

  return (
    <Root>
      {title && (
        <Header>
          {title}
          {count && <CounterContainer>{`(${count || 0})`}</CounterContainer>}
        </Header>
      )}
      <Container>
        <Grid container>
          {categories.length ? (
            sortBy(categories, 'list_number').map((data: Category) => {
              return (
                <Grid item xs={6} key={data.name}>
                  <Card>
                    <Link
                      to={ClientRouter.PRODUCT_CATEGORY.replace(
                        ':catName',
                        (intl.locale === 'he' && data.alt_name) || data.name
                      )}
                    >
                      <ImageContainer>
                        <Img src={data.thumbnail} alt={data.name} />
                      </ImageContainer>
                      <h4>
                        {(intl.locale === 'he' && data.alt_name) || data.name}
                      </h4>
                    </Link>
                  </Card>
                </Grid>
              );
            })
          ) : (
            <NoProduct value={Message.NO_CATEGORY} />
          )}
        </Grid>
      </Container>
    </Root>
  );
};

export default CategoriesCard;

const Root = styled.div`
  color: ${({ theme }) => theme.colors.black0};
  padding: 0 22px;

  /* @media ${MOBILE_RESOLUTION} {
    padding: 0;
  } */

  @media ${MOBILE_TABLET_RESOLUTION} {
    padding: 0;
  }
`;

const Container = styled.div``;

export const Header = styled.h2`
  font-size: 1.75rem;
  font-weight: 500;
  text-align: center;
  margin: 30px 0 15px;
`;

export const Card = styled.div`
  cursor: pointer;
  margin: 15px 18px;
  text-align: center;
  a {
    text-decoration: none;
  }
  h4 {
    color: ${({ theme }) => theme.colors.black0};
    font-size: 16px;
    font-weight: 700;
    margin-top: 10px;
  }
  @media ${MOBILE_RESOLUTION} {
    margin: 15px 10px;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 170px;
  border-radius: 14px;
  display: flex;

  @media ${MOBILE_TABLET_RESOLUTION} {
    height: 230px;
  }

  @media ${MOBILE_RESOLUTION} {
    height: 170px;
  }
`;

export const Img = styled(SafeImg)`
  max-height: 100%;
  max-width: 100%;
  margin: auto;
  border-radius: 14px;
  width: 100%;
`;

const CounterContainer = styled.span`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.black0};
`;
