import { Categories } from 'api/responses';
import type { Country } from 'data/countries';
import {
  GetProductsDataResponsePayload,
  GetUsersDataResponsePayload,
} from 'store/types/user-management/suppliers.types';
import type { Handler, Value } from 'types/common.types';
import type { Color } from 'types/quizzes';
import type { Supplier } from './suppliers';

export interface ProductPhoto {
  id: string;
  file: string;
}
export interface ProductSize {
  x: number;
  y: number;
  z: number;
}

export type Product = {
  id: string;
  sku: string;
  assignee?: Handler;
  orderId?: string;
  media: ProductPhoto[];
  name: string;
  description: string;
  discount: number;
  quantity: number;
  status?: string;
  price: number;
  supplier: Supplier | string;
  createdAt: string;
  updatedAt?: string;
  shippingEst?: string | number;
  category: string;
  subCategory: string;
  categoryHebrewName: string;
  subCategoryHebrewName: string;
  subCategoryAltName?: string;
  categoryAltName?: string;
  categoryId: string | number;
  subCategoryId: string | number;
  manufacture: {
    id: number;
    name: string;
    country: Country;
  };
  costPrice: number;
  thumbnail: ProductPhoto;
  link: string;
  inStock: boolean;
  backToStockDate?: Date;
  created_at?: Date;
  updated_at?: Date;
  colors: Color[];
  soldQuantity?: number;
  totalSales?: number;
  unitPrice?: number;
  size?: string;
  altName: string;
  series: string;
  webpageLink: string;
  tags: string[];
  type: string;
  msrp: number;
  notForSale?: boolean;
  rank?: number;
  modellingTime: string | number;
  productImage?: File;
  roomName?: string;
};

export type QuoteComponent = {
  id: string;
  quoteId: string;
  product: Product;
  room?: Value;
  metadata?: { count: string; area: string };
};

export type LocalCartProduct = {
  id: string;
  quantity: number;
};

export type BulkAddToCartProduct = {
  product: number;
  quantity: number;
  checkoutQuantity: number;
};

export type ProductStatus =
  | keyof typeof DatabaseStatus
  | keyof typeof ThreeDTasksStatus;

export type Category = {
  id: number;
  name: string;
  thumbnail: string;
  list_number: number;
  homepage_thumbnail?: string;
  category?: Category;
  product_count?: number;
  cover_photo_mobile?: string;
  cover_photo_web?: string;
  hebrew_name: string;
  alt_name?: string;
  type?: string;
};

export type SearchResults = {
  products: GetProductsDataResponsePayload;
  categories: Categories;
  subCategories: Categories;
  suppliers: GetUsersDataResponsePayload;
  query: {
    count: number;
    text: string;
  };
};

export enum PriceRange {
  LOW = 'low',
  MED = 'med',
  HIGH = 'high',
  EXTRA = 'extra',
}

export enum DatabaseStatus {
  'In database' = 'in_database',
  'Hidden' = 'hidden',
  'Waiting for upload' = 'waiting_for_upload',
  'Missing data' = 'missing_data',
}

export const ProductKpiColors: Record<DatabaseStatus, string> = {
  [DatabaseStatus['In database']]: '#02AD42',
  [DatabaseStatus.Hidden]: '#E8E8E8',
  [DatabaseStatus['Missing data']]: '#F0344A',
  [DatabaseStatus['Waiting for upload']]: '#FFD334',
};

export const ProductChipColors: Record<DatabaseStatus, string> = {
  [DatabaseStatus['In database']]: '#B0E3B3',
  [DatabaseStatus.Hidden]: '#E8E8E8',
  [DatabaseStatus['Missing data']]: '#FFC5B7',
  [DatabaseStatus['Waiting for upload']]: '#FDEBC9',
};

export const ProductGridNames = {
  [DatabaseStatus['In database']]: 'database',
  [DatabaseStatus['Missing data']]: 'missing',
  [DatabaseStatus['Waiting for upload']]: 'upload',
};

export enum ThreeDTasksStatus {
  'Raw data' = 'raw_data',
  'Modelling' = 'modelling',
  'Ready for review' = 'ready_for_review',

  'Missing data' = 'missing_data',
  'Fixes needed' = 'fixes_needed',
  'Hidden' = 'hidden',
  'In database' = 'in_database',
}

export const ThreeDTasksChipColors = {
  [ThreeDTasksStatus['Raw data']]: '#B0E3B3',
  [ThreeDTasksStatus.Modelling]: '#B9DAFF',
  [ThreeDTasksStatus['Ready for review']]: '#FDEBC9',

  [ThreeDTasksStatus['Missing data']]: '#FFC5B7',
  [ThreeDTasksStatus['Fixes needed']]: '#C9FDF3',
  [ThreeDTasksStatus.Hidden]: '#C9FDF',
  [ThreeDTasksStatus['In database']]: '#B0E3B3',
};

export type ProductCoin = {
  id: number;
  type: string;
  altName: string;
  englishName: string;
  originalPrice: string;
  finalPrice: number;
  price: number;
  quantity: number;
};

export type SelectedProductCoin = {
  id: number;
  type: string;
  altName: string;
  englishName: string;
  originalPrice: string;
  finalPrice: number;
  finalTotal: number;
  price: number;
  quantity: number;
};

export type IdeasDetails = {
  id: number;
  name: string | null;
  file: string | null;
  metadata?: string | object;
  type: string;
  created_at: string;
  property?: string;
  products?: Product[] | null;
  viewCount?: string | number;
  user: {
    id: number | null;
    first_name: string | null;
    last_name: string | null;
    profile_picture: string | null;
  } | null;
};

export type BackgroundTask = {
  id: number;
  progress: number;
  status: string;
  completedAt: string;
  startedAt: string;
  result: string | null;
  file: string | null;
};
