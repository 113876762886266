import * as React from 'react';
import no_image_placeholder from 'assets/images/client-products/no_image.png';

const useImageGuard = (
  state?: string,
  fallback: string = no_image_placeholder
) => {
  const [hasError, setHasError] = React.useState<boolean>(false);
  const src = React.useMemo(() => (hasError || !state ? fallback : state), [
    fallback,
    hasError,
    state,
  ]);
  const onError = React.useCallback(() => setHasError(true), []);

  React.useEffect(() => {
    setHasError(false);
  }, [state]);

  return { src, hasError, onError };
};

export default useImageGuard;
