import * as actions from 'store/actions/properties-management/PublishedPropertiesManagement.actions';
import PropertiesType from 'store/constants/properties-management/PropertiesManagement.constants';
import * as Payload from 'store/types/properties-management/PublishedProperties.types';
import { PublishedPropertyTask } from 'types/properties';

export interface PublishedPropertyTasksState {
  loading: Record<PropertiesType, boolean>;
  error: Record<PropertiesType, null | string>;
  results: PublishedPropertyTask[];
  pageCount: number;
}

const REQUESTS = [PropertiesType.GET_PUBLISHED_PROPERTIES_DATA_REQUEST];

const initialState: PublishedPropertyTasksState = {
  loading: REQUESTS.reduce(
    (state, next) => ({ ...state, [next]: false }),
    {} as Record<PropertiesType, boolean>
  ),
  error: REQUESTS.reduce(
    (state, next) => ({ ...state, [next]: null }),
    {} as Record<PropertiesType, null | string>
  ),
  results: [],
  pageCount: 0,
};

const publishedPropertiesTasksReducer = (
  state: PublishedPropertyTasksState = initialState,
  action: actions.PropertiesTasksAction
): PublishedPropertyTasksState => {
  switch (action.type) {
    case PropertiesType.GET_PUBLISHED_PROPERTIES_DATA_REQUEST:
      return updateLoading(
        state,
        PropertiesType.GET_PUBLISHED_PROPERTIES_DATA_REQUEST
      );
    case PropertiesType.GET_PUBLISHED_PROPERTIES_DATA_FULFILLED:
      return updateSuccess(
        state,
        PropertiesType.GET_PUBLISHED_PROPERTIES_DATA_REQUEST,
        action.payload as Payload.GetPublishedPropertiesTasksFulfilledPayload
      );
    case PropertiesType.GET_PUBLISHED_PROPERTIES_DATA_REJECTED:
      return updateError(
        state,
        PropertiesType.GET_PUBLISHED_PROPERTIES_DATA_REQUEST,
        action.payload as Payload.GetPublishedPropertiesTasksRejectedPayload
      );
    default:
      return state;
  }
};

export default publishedPropertiesTasksReducer;

type requestType = PropertiesType.GET_PUBLISHED_PROPERTIES_DATA_REQUEST;

const updateLoading = (
  state: PublishedPropertyTasksState,
  requestType: requestType
) => ({
  ...state,
  loading: {
    ...state.loading,
    [requestType]: true,
  },
  error: {
    ...state.error,
    [requestType]: null,
  },
});

type ErrorPayload = Payload.GetPublishedPropertiesTasksRejectedPayload;
const updateError = (
  state: PublishedPropertyTasksState,
  requestType: requestType,
  { error }: ErrorPayload
) => ({
  ...state,
  loading: {
    ...state.loading,
    [requestType]: false,
  },
  error: {
    ...state.error,
    [requestType]: error,
  },
});

type SuccessPayload = Payload.GetPublishedPropertiesTasksFulfilledPayload;
const updateSuccess = (
  state: PublishedPropertyTasksState,
  requestType: requestType,
  payload: SuccessPayload
) => ({
  ...state,
  loading: {
    ...state.loading,
    [requestType]: false,
  },
  error: {
    ...state.error,
    [requestType]: null,
  },
  ...payload,
});
