import * as React from 'react';
import { useIntl } from 'react-intl';
import Modal, { ModalProps, ModalAction } from 'components/@client/Modal';
import { useAuthentication } from 'hooks';
import { Message } from 'i18n';

const LogoutModal: React.FC<ModalProps> = props => {
  const intl = useIntl();
  const { clientLogout } = useAuthentication();
  const actions: ModalAction[] = [
    {
      label: intl.formatMessage({ id: Message.NO }),
      onClick: () => props?.onClose?.({}, 'backdropClick'),
      variant: 'secondary',
    },
    {
      label: intl.formatMessage({ id: Message.YES }),
      onClick: clientLogout,
      variant: 'primary',
    },
  ];
  return (
    <Modal
      title={intl.formatMessage({ id: Message.LOGOUT_CONFIRM_CAPTION })}
      {...props}
      actions={actions}
    >
      {null}
    </Modal>
  );
};

export default LogoutModal;
