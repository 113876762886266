import * as React from 'react';
import MuiAccordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import styled from 'styled-components';
import ExpandButton from './ExpandButton';

interface Props {
  expanded?: boolean;
  header: JSX.Element;
  onOpen?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const Accordion: React.FC<Props> = ({ expanded, header, children, onOpen }) => {
  return (
    <CustomAccordion elevation={0} expanded={expanded}>
      <CustomAccordionSummary
        expandIcon={<ExpandButton onClick={onOpen} disabled={expanded} />}
      >
        {header}
      </CustomAccordionSummary>
      <CustomAccordionDetails>{children}</CustomAccordionDetails>
    </CustomAccordion>
  );
};

export default Accordion;

const CustomAccordion = styled(MuiAccordion)<{
  elevation: number;
}>`
  &:before {
    background: none;
  }

  margin: 0 !important;
`;

const CustomAccordionSummary = styled(AccordionSummary)<{
  expandIcon: React.ReactNode | string;
}>`
  display: flex;
  width: 100%;
  padding: 5px !important;
  cursor: default !important;

  &.Mui-expanded {
    min-height: unset;
    margin-top: 5px;
    margin-bottom: 27px;
  }

  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }

  .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: unset;
  }
`;

const CustomAccordionDetails = styled(AccordionDetails)`
  padding: 0;
`;
