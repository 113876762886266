// export const rooms = [
//   { id: 1, name: 'Living room' },
//   { id: 2, name: 'Dining room' },
//   { id: 3, name: 'Kitchen' },
//   { id: 4, name: 'Hallway' },
//   { id: 5, name: 'Master bedroom' },
//   { id: 6, name: 'Bedroom1' },
//   { id: 7, name: 'Bedroom2' },
//   { id: 8, name: 'Bedroom3' },
//   { id: 9, name: 'Bedroom4' },
//   { id: 10, name: 'Bathroom' },
//   { id: 11, name: 'Guest toilet' },
//   { id: 12, name: 'Master bath' },
//   { id: 13, name: 'Balcony' },
//   { id: 14, name: 'Yard' },
//   { id: 15, name: 'Home office' },
//   { id: 16, name: 'Boy room' },
//   { id: 17, name: 'Girl room' },
//   { id: 18, name: 'Teenager room' },
//   { id: 19, name: 'Closet room' },
//   { id: 20, name: 'Outside' },
// ];

export const rooms = [
  { id: 1, name: 'Living room' },
  { id: 2, name: 'Dining room' },
  { id: 3, name: 'Kitchen' },
  { id: 4, name: 'Master Bedroom' },
  { id: 5, name: 'Home Office' },
  { id: 6, name: 'Bedroom' },
  { id: 7, name: 'Closet Room' },
  { id: 8, name: 'Boy Room' },
  { id: 9, name: 'Girl Room' },
  { id: 10, name: 'Teenager Room' },
  { id: 11, name: 'Bathroom' },
  { id: 12, name: 'Guest Toilet' },
  { id: 13, name: 'Master Bath' },
  { id: 14, name: 'Balcony' },
  { id: 15, name: 'Yard' },
];
