import * as React from 'react';
import styled from 'styled-components';
import Modal from 'components/Modal/Modal';
import Title from 'components/Modal/Title';
import Actions from 'components/Modal/ModalActions';
import { IAddress } from 'types/common.types';
import AddressesList from './AddressesList';

interface Props {
  isOpen: boolean;
  toggle: () => void;
  addresses: IAddress[];
}

const AddressesModal: React.FC<Props> = ({ isOpen, toggle, addresses }) => {
  return (
    <Container open={isOpen} close={toggle}>
      <Title>Client's Linked addresses</Title>
      <AddressesList addresses={addresses} showCaption />
      <Actions
        actions={[{ action: toggle, label: 'Close', buttonColor: 'light' }]}
      />
    </Container>
  );
};

export default AddressesModal;

const Container = styled(Modal)`
  .MuiPaper-root .MuiDialogTitle-root h5 {
    margin-left: -8px;
  }
`;
