import * as React from 'react';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { Option } from 'components/Autocomplete/Autocomplete.config';

const ButtonComponent: React.FC<{
  options: Option[];
  title: string;
  type: string;
  onClick: (data: Option[], type: string) => void;
  selectedValues?: Option[] | null;
  multiple?: boolean;
}> = ({ options, title, type, onClick, selectedValues, multiple = false }) => {
  const [selectedValue, setSelectedValue] = React.useState<Option[]>([]);

  React.useEffect(() => {
    if (selectedValues && selectedValues.length > 0) {
      setSelectedValue(selectedValues);
    } else {
      setSelectedValue([]);
    }
  }, [onClick, type, selectedValues, setSelectedValue, options, multiple]);

  const handleClick = React.useCallback(
    (data: Option) => {
      if (multiple === true) {
        const newData: Option[] = selectedValue;
        const index = newData.findIndex(a => a?.value === data?.value);

        if (index > -1) {
          newData.splice(index, 1);
        } else {
          newData.push(data);
        }
        setSelectedValue([...newData]);
        onClick(newData, type);
      } else if (selectedValues?.[0] === data) {
        setSelectedValue([]);
        onClick([], type);
      } else {
        setSelectedValue([...[data]]);
        onClick([data], type);
      }
    },
    [multiple, selectedValues, selectedValue, onClick, type]
  );
  return (
    <>
      <Container>
        <RowTitle>
          <Title>{title}</Title>
        </RowTitle>
        <Row>
          {options.map((option: Option, i: number) => (
            <CustomButton
              key={`${option?.name}-${i.toString()}`}
              $isActive={
                selectedValue.findIndex(a => a?.value === option?.value) > -1
              }
              onClick={() => handleClick(option)}
            >
              {option?.name}
            </CustomButton>
          ))}
        </Row>
      </Container>
    </>
  );
};

export default ButtonComponent;

const Container = styled.div`
  /* border-bottom: 0.3px solid rgba(169, 153, 151, 0.33); */
  margin: 0 24px;
  /* padding-bottom: 10px; */
  /* padding-top: 10px; */
`;

const Title = styled.label`
  font-family: ${({ theme }) => theme.dir === 'rtl' && 'Assistant'};
  font-style: normal;
  font-weight: ${({ theme }) => (theme.dir === 'rtl' ? 600 : 500)};
  font-size: 16px;
  line-height: 20px;
  color: #282929;
`;

const Row = styled.div`
  display: block;
  margin-top: 8px;
`;

const RowTitle = styled.div`
  display: block;
  margin-top: 16px;
`;

const CustomButton = styled.button<{ $isActive?: boolean }>`
  font-family: 'roboto';
  height: 36px;
  border: ${({ $isActive }) =>
    $isActive ? '0.5px solid #FC5C42' : '0.5px solid rgba(0, 0, 0, 0.3)'};
  box-sizing: border-box;
  border-radius: 10px;
  background: ${({ $isActive }) => ($isActive ? '#FC5C42' : '#fff')};
  ${rtl`
  margin: 8px 8px 0 0;
    `}
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: ${({ $isActive }) => ($isActive ? '#fff' : '#3E3E3E')};
  padding: 6px 8px;
`;
