import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Message } from 'i18n';
import styled, { css } from 'styled-components';
import useUploadPlan from 'pages/appClient/onboarding/upload-plan/useUploadPlan';
import { Actions } from 'pages/appClient/onboarding/shared';
import * as Image from 'assets/@client/newpre-made-properties/index';
import {
  MOBILE_RESOLUTION,
  MOBILE_TABLET_RESOLUTION,
} from 'styles/sharedStyle';
import { Check } from '@material-ui/icons';
import rtl from 'styled-components-rtl';
import Slider from 'react-slick';
import Icon from 'components/Icon';
import { ArrowBase } from 'components/@client/SlideshowModal/Arrows';
import { useMediaQuery } from '@material-ui/core';

interface Props {
  next: VoidFunction;
  prev: VoidFunction;
}

const DemoFloorPlansSeller: React.FC<Props> = ({ next, prev }) => {
  const [prevIndex, setPrevIndex] = React.useState<number>(0);
  const [selectedItem, setSelectedItem] = React.useState(0);
  const isMobile = useMediaQuery(MOBILE_RESOLUTION);
  const intl = useIntl();

  const currentValue = React.useMemo(() => {
    return isMobile ? 1.2 : 4;
  }, [isMobile]);
  const { onClick, isLoading, setSelectedFloor, selectedFloor } = useUploadPlan(
    next
  );

  const roomsData = [
    {
      id: 1,
      files: [
        {
          file: Image.DemoFloorPlan,
          id: 1,
          name: '2',
          type: 'floor_plan',
        },
      ],
    },
    {
      id: 2,
      files: [
        {
          file: Image.Rooms3,
          id: 2,
          name: '3',
          type: 'floor_plan',
        },
      ],
    },
    {
      id: 3,
      files: [
        {
          file: Image.Rooms4,
          id: 3,
          name: '4',
          type: 'floor_plan',
        },
      ],
    },
    {
      id: 4,
      files: [
        {
          file: Image.Rooms5,
          id: 4,
          name: '5',
          type: 'floor_plan',
        },
      ],
    },
    {
      id: 5,
      files: [
        {
          file: Image.Rooms6,
          id: 5,
          name: '6',
          type: 'floor_plan',
        },
      ],
    },
  ];

  const settings = {
    dots: false,
    infinite: false,
    afterChange: (index: number) => {
      setPrevIndex(index);
    },
  };

  const PrevArrow = (props: {
    onClick?: React.MouseEventHandler<HTMLDivElement>;
  }) => {
    const { onClick: onClickPrev } = props;

    return isMobile ? (
      <></>
    ) : (
      <ArrowContainer
        role="button"
        isOpen={true}
        onClick={onClickPrev}
        prev={true}
        prevIndex={Math.floor(prevIndex)}
        mediaLength={roomsData?.length}
        currentValue={currentValue}
      >
        <ArrowIcon className="prev" />
      </ArrowContainer>
    );
  };

  const NextArrow = (props: {
    onClick?: React.MouseEventHandler<HTMLDivElement>;
  }) => {
    const { onClick: onClickNext } = props;
    return isMobile ? (
      <></>
    ) : (
      <ArrowContainer
        role="button"
        isOpen={true}
        onClick={onClickNext}
        prev={false}
        prevIndex={Math.floor(prevIndex)}
        mediaLength={roomsData?.length}
        currentValue={currentValue}
      >
        <ArrowIcon className="next" />
      </ArrowContainer>
    );
  };

  const NextArrowMobile = () => {
    return (
      <ArrowContainerMobile
        isRight={true}
        role="button"
        prev={false}
        isOpen={false}
        locale={true}
        onClick={() => {
          setSelectedItem(selectedItem + 1);
        }}
      >
        <ArrowIcon className="next" />
      </ArrowContainerMobile>
    );
  };

  const PrevArrowMobile = () => {
    return (
      <ArrowContainerMobile
        isRight={false}
        role="button"
        prev={true}
        isOpen={false}
        locale={true}
        onClick={() => {
          setSelectedItem(selectedItem - 1);
        }}
      >
        <ArrowIcon className="prev" />
      </ArrowContainerMobile>
    );
  };

  return (
    <>
      <Container>
        <Text>
          <FormattedMessage id={Message.PROPERTY_MODAL_TITLE_SELLER} />
        </Text>
        <RoomsParentContainer>
          {isMobile && selectedItem !== 4 && <NextArrowMobile />}
          {isMobile && selectedItem !== 0 && <PrevArrowMobile />}
          {!isMobile ? (
            <Slider
              {...settings}
              slidesToShow={currentValue}
              slidesToScroll={1}
              swipeToSlide={true}
              nextArrow={<NextArrow />}
              prevArrow={<PrevArrow />}
            >
              {roomsData.map((room, index) => {
                return (
                  <MainContainer
                    key={room.id}
                    isActive={selectedFloor?.id === index + 1}
                    onClick={() => setSelectedFloor(room.files[0])}
                  >
                    <div className="checkContainer">
                      <Check />
                    </div>
                    <ImageContainer>
                      <img src={room.files[0].file} alt="not available" />
                    </ImageContainer>
                    <RoomCount dir={intl.locale === 'he' ? 'rtl' : 'ltr'}>
                      <FormattedMessage
                        id={Message.PLAN_BEDROOMS}
                        values={{ name: room.files[0].name }}
                      />
                    </RoomCount>
                    <RoomSubtitle>
                      <FormattedMessage id={Message.ONBOARDING_FLOOR_PLAN} />
                    </RoomSubtitle>
                  </MainContainer>
                );
              })}
            </Slider>
          ) : (
            <MobileSlider
              {...settings}
              slidesToShow={1}
              slidesToScroll={1}
              swipeToSlide={true}
              nextArrow={<NextArrow />}
              prevArrow={<PrevArrow />}
            >
              {' '}
              {roomsData.map((room, index) => {
                return (
                  index === selectedItem && (
                    <MainContainer
                      key={room.id}
                      isActive={selectedFloor?.id === index + 1}
                      onClick={() => setSelectedFloor(room.files[0])}
                    >
                      <div className="checkContainer">
                        <Check />
                      </div>
                      <ImageContainer>
                        <img src={room.files[0].file} alt="not available" />
                      </ImageContainer>
                      <RoomCount dir={intl.locale === 'he' ? 'rtl' : 'ltr'}>
                        <FormattedMessage
                          id={Message.PLAN_BEDROOMS}
                          values={{ name: room.files[0].name }}
                        />
                      </RoomCount>
                      <RoomSubtitle>
                        <FormattedMessage id={Message.ONBOARDING_FLOOR_PLAN} />
                      </RoomSubtitle>
                    </MainContainer>
                  )
                );
              })}
            </MobileSlider>
          )}
        </RoomsParentContainer>
        <Actions
          withPrevious={true}
          prevProps={{ onClick: prev, isLoading }}
          nextProps={{ disabled: !selectedFloor, onClick, isLoading }}
          nextText={
            <FormattedMessage id={Message.PROPERTY_ADD_PROPERTY_BUTTON_NEXT} />
          }
        />
      </Container>
    </>
  );
};

export default DemoFloorPlansSeller;

const Container = styled.section.attrs({ className: 'upload-plan-container' })`
  align-items: center;

  & .slick-list {
    @media (max-width: 768px) {
      margin: 0 0px;
    }
    @media (min-width: 769px) and (max-width: 1030px) {
      margin: 0 50px;
    }
    @media (min-width: 600px) and (max-width: 768px) {
      margin: 0 42px;
    }
    margin: 0 64px;
  }
  & .file-drop-zone {
    padding: 15px;
  }
  .slick-slide {
    max-width: 246px !important;
  }
  .slick-track {
    width: 100% !important;
    display: flex;
  }
  .actions-container {
    @media ${MOBILE_RESOLUTION} {
      position: inherit !important;
    }
  }
`;

const Text = styled.p.attrs({ className: 'upload-plan-text' })`
  font-weight: 600;
  font-size: 28px;
  margin-bottom: 0px;
  text-align: center;
  line-height: 32px;
  margin-top: 24px;
  color: #fc5c42;
  @media (max-width: 800px) {
    font-weight: 500;
    font-size: 22px;
    margin-top: 32px;
  }
`;

const MainContainer = styled.div<{ isActive: boolean }>`
  height: 326px;
  width: 90% !important;
  /* width: 246px !important; */
  max-width: 246px;
  margin: 15px 20px;
  text-align: center;
  box-shadow: 0px 0px 10px rgb(40 41 41 / 15%);
  border-radius: 15px;
  position: relative;
  cursor: pointer;
  &:hover {
    border: 3px solid #fc5c42;
  }
  border: ${({ isActive }) =>
    isActive ? '3px solid #fc5c42' : '3px solid transparent'};
  .checkContainer {
    height: 24px;
    width: 24px;
    position: absolute;
    top: 16px;
    ${rtl`right: 16px;`}
    border-radius: 12px;
    background-color: #fc5c42;
    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
    svg {
      width: 15px;
      height: 23px;
      path {
        stroke: white;
        fill: white;
      }
    }

    /* @media ${MOBILE_RESOLUTION} {
      width: 200px !important;
    } */
  }
`;

const RoomsParentContainer = styled.div`
  /* display: flex;
  flex-direction: row;
  gap: 32px; */
  margin-top: 48px;
`;

const ImageContainer = styled.div`
  margin-top: 32px !important;
  width: 182px;
  height: 182px;
  @media ${MOBILE_TABLET_RESOLUTION} {
    width: 142px;
    height: 150px;
  }
  margin: auto;
  > img {
    width: 100%;
    height: 100%;
  }
`;

const RoomCount = styled.div`
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  display: flex;
  justify-content: center;
  color: #282929;
  margin-top: 24px;
`;

const RoomSubtitle = styled.div`
  color: #3e3e3e;
  font-size: 18px;
  line-height: 22px;
  margin-top: 8px;
  display: flex;
  justify-content: center;
`;

const ArrowContainer = styled(ArrowBase)<{
  prev: boolean;
  prevIndex: number;
  mediaLength: number;
  currentValue: number;
}>`
  z-index: 9;
  top: 42%;
  ${({ prev }) =>
    prev
      ? css`
          ${rtl`right:95.4%;`}
        `
      : css`
          ${rtl`right:0%;`}
        `}
  transform: rotate(${({ prev }) => (prev ? 180 : 0)}deg);
  svg {
    fill: #292929e5;
  }
  & .prev {
    fill: ${({ prevIndex }) => (prevIndex === 0 ? '#D9D9D9' : '#292929E5')};
  }
  & .next {
    fill: ${({ prevIndex, mediaLength, currentValue }) =>
      prevIndex + currentValue === mediaLength ? '#D9D9D9' : '#292929E5'};
  }
`;

const ArrowIcon = styled(Icon).attrs({
  name: 'nav-arrow-right',
})<{ isOpen: boolean }>`
  position: relative;

  ${({ isOpen }) =>
    isOpen
      ? css`
          ${rtl`left:4px;`}
        `
      : css`
          ${rtl`left:4px;`}
        `}
  transform: rotate(
  ${({ theme }) => (theme.dir === 'rtl' ? '180deg' : '0deg')}
);
  z-index: 9;
`;

const MobileSlider = styled(Slider)``;

const ArrowContainerMobile = styled(ArrowBase)<{
  isRight: boolean;
  prev: boolean;
}>`
  background: #ffffff;
  position: absolute;
  top: 45%;
  ${({ theme, isRight }) =>
    theme.dir === 'rtl'
      ? css`
          ${rtl`
  ${isRight ? 'right: 24px;' : 'left: 24px;'}
  `}
        `
      : css`
          ${rtl`
  ${isRight ? 'right: 24px;' : 'left: 24px;'}
  `}
        `}
  z-index: 1;
  transform: rotate(${({ prev }) => (prev ? 180 : 0)}deg);
  svg {
    ${({ theme }) =>
      theme.dir === 'rtl'
        ? css`
            transform: rotate(180deg);
            left: -4px;
          `
        : css`
            ${rtl`
  transform: rotate(0deg);
    left: 4px;
  `}
          `}
    fill: #292929;
    opacity: 0.9;
  }
`;
