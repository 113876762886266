import * as React from 'react';
import { useOnboarding, OnboardingAction } from 'context/onboardingContext';
import { useRouter } from 'hooks';
import { ClientRouter } from 'router/routes';
import useGridItems from './useGridItems';

const useQuizSummary = () => {
  const gridItems = useGridItems();
  const [{ answers, questions, progress }, dispatch] = useOnboarding();
  const { history } = useRouter();

  const progressStep = React.useMemo(() => 75 / questions.length, [questions]);

  const prev = React.useCallback(() => {
    dispatch({
      type: OnboardingAction.SET_PROGRESS,
      payload: progress - progressStep,
    });
    history.push(ClientRouter.QUIZ_QUESTION);
  }, [dispatch, history, progress, progressStep]);

  const next = React.useCallback(
    () => history.push(ClientRouter.ADDITIONAL_INFO),
    [history]
  );

  return { gridItems, answers, progress, prev, next };
};

export default useQuizSummary;
