import * as React from 'react';
import * as Form from 'components/forms';
import { useBreakpoint, useCategories } from 'hooks';
import { CustomMenuItem } from 'styles/sharedStyle';
import { Supplier } from 'types/suppliers';
import styled from 'styled-components';
import { Row } from './FormFields.config';

const min = { w: '227px', h: 40 };
const max = { w: '278px', h: 40 };

interface Props {
  defaultValues?: Supplier | object;
}
const SupplierDetails: React.FC<Props> = ({ children, defaultValues }) => {
  const { isSmallScreen, width } = useBreakpoint(min, max);
  const { Categories: categoryOptions = [] } = useCategories();
  const preventSpaceTyping = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === ' ') {
        e.preventDefault();
      }
    },
    []
  );
  const preventSpaceOnPaste = React.useCallback((e: React.ClipboardEvent) => {
    if (e.clipboardData.getData('text') === ' ') {
      e.preventDefault();
    }
  }, []);

  return (
    <Container>
      <Row>
        <Form.Image
          name="logo"
          label="Logo"
          defaultValue={(defaultValues as Supplier)?.logo}
        />
      </Row>
      <Row className="form-first-row">
        <Form.Field
          width={width}
          className="field-supplier-name"
          label={{ title: 'Supplier name' }}
          name="companyName"
          type="text"
          required
          onPaste={preventSpaceOnPaste}
        />
        <Form.Field
          width={width}
          className="field-supplier-skuPrefix"
          label={{ title: 'SKU Prefix' }}
          name="skuPrefix"
          type="text"
          required
          onPaste={preventSpaceOnPaste}
        />
        <Form.Field
          width={isSmallScreen ? width : '316px'}
          className="field-Registration-no"
          label={{ title: 'Registration No.', parenthesesContent: 'optional' }}
          name="registeredNumber"
          type="text"
          onKeyPress={preventSpaceTyping}
          onPaste={preventSpaceOnPaste}
        />
      </Row>
      <Row className="form-second-row">
        <Form.Select
          inputWidth={width}
          className="field-category"
          label={{ title: 'Category' }}
          name="category"
          required
          onKeyPress={preventSpaceTyping}
          onPaste={preventSpaceOnPaste}
        >
          <CustomMenuItem disabled value="">
            Select category
          </CustomMenuItem>
          {(categoryOptions ?? []).map(({ name, id }) => (
            <CustomMenuItem key={name} value={id}>
              {name}
            </CustomMenuItem>
          ))}
        </Form.Select>
        <Form.Field
          width={isSmallScreen ? (width as string) : '316px'}
          className="field-website"
          label={{ title: 'Website', parenthesesContent: 'optional' }}
          name="website"
          type="text"
          onKeyPress={preventSpaceTyping}
          onPaste={preventSpaceOnPaste}
        />
        {children}
      </Row>
    </Container>
  );
};

export default SupplierDetails;

const Container = styled.div`
  & .field-supplier-skuPrefix {
    input {
      text-transform: uppercase;
    }
  }
`;
