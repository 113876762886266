import { Modal, useMediaQuery } from '@material-ui/core';
import { Cancel, Check, CheckCircle } from '@material-ui/icons';
import * as Image from 'assets/@client/newpre-made-properties/index';
import { ReactComponent as UploadIcon } from 'assets/icons/downloadIcon.svg';
import Button from 'components/@client/Button/Button';
import { ArrowBase } from 'components/@client/SlideshowModal/Arrows';
import Icon from 'components/Icon';
import { OnboardingAction, useOnboarding } from 'context/onboardingContext';
import { useNotifications, useRouter, useSession } from 'hooks';
import { Message } from 'i18n';
import Jimp from 'jimp';
import * as Section from 'pages/appClient/onboarding/shared';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Slider from 'react-slick';
import { ClientRouter } from 'router/routes';
import styled, { css } from 'styled-components';
import rtl from 'styled-components-rtl';
import {
  LAPTOP_MAX_RESOLUTION,
  LAPTOP_MIN_RESOLUTION,
  MAX_TABLET_RESOLUTION,
  MOBILE_TABLET_RESOLUTION,
  SMALL_HEIGHT_RESOLUTION,
  SMALL_RESOLUTION,
  TABLET_RESOLUTION,
} from 'styles/sharedStyle';
import { PropertyMedia } from 'types/properties';
import { isIsrael } from 'utils/addresses.utils';
import { readFile } from 'utils/common.utils';
import MuiSlider from '@mui/material/Slider';
import Loader from 'components/Loader';
import useUploadPlan from './useUploadPlan';

// const floorPlansList = [
//   {
//     id: 0,
//     files: [
//       {
//         file: Image.upload,
//         id: 1,
//         name: '2',
//         type: 'upload_button',
//         floorType: 'Upload Button',
//       },
//     ],
//   },

//   {
//     id: 1,
//     files: [
//       {
//         file: Image.one,
//         id: 1,
//         name: '2',
//         type: 'floor_plan',
//         floorType: '2 Room',
//       },
//     ],
//   },
//   {
//     id: 2,
//     files: [
//       {
//         file: Image.two,
//         id: 2,
//         name: '3',
//         type: 'floor_plan',
//         floorType: '3 Room',
//       },
//     ],
//   },
//   {
//     id: 3,
//     files: [
//       {
//         file: Image.three,
//         id: 3,
//         name: '4',
//         type: 'floor_plan',
//         floorType: '4 Room',
//       },
//     ],
//   },
//   {
//     id: 5,
//     files: [
//       {
//         file: Image.five,
//         id: 5,
//         name: '5',
//         type: 'floor_plan',
//         floorType: '5 Room',
//       },
//     ],
//   },
//   {
//     id: 6,
//     files: [
//       {
//         file: Image.four,
//         id: 6,
//         name: '6',
//         type: 'floor_plan',
//         floorType: '6 Room',
//       },
//     ],
//   },
//   // {
//   //   id: 7,
//   //   files: [
//   //     {
//   //       file: Image.six,
//   //       id: 7,
//   //       name: '5',
//   //       type: 'floor_plan',
//   //     },
//   //   ],
//   // },
// ];

const IsraelFloorPlans = [
  {
    id: 1,
    files: [
      {
        file: Image.Room1,
        id: 1,
        name: '2',
        type: '70m²',
      },
    ],
  },
  {
    id: 2,
    files: [
      {
        file: Image.Room2,
        id: 2,
        name: '3',
        type: '90m²',
      },
    ],
  },
  {
    id: 3,
    files: [
      {
        file: Image.Room3,
        id: 3,
        name: '4',
        type: '90m²',
      },
    ],
  },
  {
    id: 5,
    files: [
      {
        file: Image.Rooms5,
        id: 5,
        name: '3',
        type: '115m²',
      },
    ],
  },
  {
    id: 6,
    files: [
      {
        file: Image.Rooms4,
        id: 6,
        name: '4',
        type: '115m²',
      },
    ],
  },
];

const UsFloorPlans = [
  {
    id: 1,
    files: [
      {
        file: Image.NewRoom1,
        id: 1,
        name: '2',
        type: '70m²',
      },
    ],
  },
  {
    id: 2,
    files: [
      {
        file: Image.NewRoom2,
        id: 2,
        name: '3',
        type: '90m²',
      },
    ],
  },
  {
    id: 3,
    files: [
      {
        file: Image.NewRoom3,
        id: 3,
        name: '4',
        type: '90m²',
      },
    ],
  },
];

const SIZE_LIMIT = 1048576 * 10;
const MAX_PAGES_SIZE = 4;
const NewUploadPlan = () => {
  const { locale, country } = useSession();
  const isMobile = useMediaQuery(MOBILE_TABLET_RESOLUTION);
  const floorPlansList = [
    {
      id: 0,
      files: [
        {
          file: Image.upload,
          id: 1,
          name: '2',
          type: 'upload_button',
          floorType: 'Upload Button',
        },
      ],
    },
    {
      id: 9999,
      files: [
        {
          file: Image.upload,
          id: 1,
          name: '2',
          type: 'upload_button',
          floorType: 'Upload Button',
        },
      ],
    },

    ...(isIsrael(country ?? 'US') ? IsraelFloorPlans : UsFloorPlans),
  ];
  const [{ choosePlan }, dispatch] = useOnboarding();
  const { isFromSupportedCountry } = useSession();
  const { history } = useRouter();

  const { query } = useRouter<{
    scan: string;
  }>();
  const isScans = React.useCallback(() => {
    if (query.scan) {
      dispatch({
        type: OnboardingAction.SET_IS_SCAN,
        payload: Boolean(true),
      });
      history.push(
        isFromSupportedCountry ? ClientRouter.QUIZ_QUESTION : ClientRouter.APP
      );
    }
  }, [dispatch, query.scan, history, isFromSupportedCountry]);

  React.useEffect(() => {
    isScans();
  }, [query.scan, isScans]);

  const {
    image,
    setImage,
    onClick: onNextClick,
    isLoading,
    floorPlanFiles,
    propertyId,
  } = useUploadPlan();
  const targetRef = React.useRef<HTMLInputElement | null>(null);
  const [floor, setFloor] = React.useState<{
    id: number;
    files: PropertyMedia[];
  } | null>(
    propertyId && floorPlanFiles
      ? { id: propertyId, files: floorPlanFiles }
      : null
  );

  const [prevIndex, setPrevIndex] = React.useState<number>(0);
  const [windowWidth, setWindowWidth] = React.useState<number>(
    window.innerWidth
  );
  const [divWidth, setDivWidth] = React.useState<number>(
    country === 'IL'
      ? 400 * (floorPlansList?.length - 1) + (20 * floorPlansList?.length - 1)
      : 372 * (floorPlansList?.length - 1) + (20 * floorPlansList?.length - 1)
  );

  React.useEffect(() => {
    setDivWidth(
      country === 'IL'
        ? 400 * (floorPlansList?.length - 1) + (20 * floorPlansList?.length - 1)
        : 372 * (floorPlansList?.length - 1) + (20 * floorPlansList?.length - 1)
    );
  }, [country, floorPlansList?.length]);

  const currentValue = React.useMemo(() => {
    return isMobile ? 1.2 : 4;
  }, [isMobile]);

  const setDimension = () => {
    setWindowWidth(window.innerWidth);
  };

  React.useEffect(() => {
    window.addEventListener('resize', setDimension);
    return () => {
      window.removeEventListener('resize', setDimension);
    };
  }, [windowWidth, country]);

  const goBack = React.useCallback(() => {
    dispatch({
      type: OnboardingAction.SET_CHOOSE_PLAN,
      payload: Boolean(false),
    });
  }, [dispatch]);

  const setPreFloorPLan = React.useCallback(() => {
    if (floor) {
      const payload: { id: number; files: PropertyMedia[] } | null = floor;
      if (payload)
        return (
          dispatch({ type: OnboardingAction.SET_PLAN_FILES, payload }),
          history.push(ClientRouter.PLAN_CONFIRMED)
        );
    }
    return null;
  }, [dispatch, floor, history]);
  const setPreFloorPLanMobile = React.useCallback(
    (
      item: {
        id: number;
        files: PropertyMedia[];
      } | null
    ) => {
      if (item) {
        const payload: { id: number; files: PropertyMedia[] } | null = item;
        if (payload)
          return (
            dispatch({ type: OnboardingAction.SET_PLAN_FILES, payload }),
            history.push(ClientRouter.PLAN_CONFIRMED)
          );
      }
      return null;
    },
    [dispatch, history]
  );
  const fileInputRef = React.useRef<HTMLInputElement | null>(null);

  const setChoosePlan = React.useCallback(
    (chooseplan: boolean) => {
      dispatch({
        type: OnboardingAction.SET_CHOOSE_PLAN,
        payload: Boolean(chooseplan),
      });
      dispatch({
        type: OnboardingAction.SET_IS_PREMADE_PROPERTY,
        payload: Boolean(true),
      });
    },
    [dispatch]
  );

  const NextArrowMobile = () => {
    return (
      <ArrowContainerMobile
        isRight={true}
        role="button"
        prev={false}
        isOpen={false}
        locale={true}
        onClick={() => {
          setScrollSource('');
          if (locale === 'he') setSelectedItem(selectedItem - 1);
          else setSelectedItem(selectedItem + 1);
        }}
      >
        <ArrowIcon className="next" />
      </ArrowContainerMobile>
    );
  };

  const PrevArrowMobile = () => {
    return (
      <ArrowContainerMobile
        isRight={false}
        role="button"
        prev={true}
        isOpen={false}
        locale={true}
        onClick={() => {
          setScrollSource('');
          if (locale === 'he') setSelectedItem(selectedItem + 1);
          else setSelectedItem(selectedItem - 1);
        }}
      >
        <ArrowIcon className="prev" />
      </ArrowContainerMobile>
    );
  };

  const NextArrow = (props: {
    onClick?: React.MouseEventHandler<HTMLDivElement>;
  }) => {
    const { onClick } = props;
    return isMobile ? (
      <></>
    ) : (
      <ArrowContainer
        role="button"
        isOpen={true}
        onClick={onClick}
        prev={false}
        prevIndex={Math.floor(prevIndex)}
        mediaLength={floorPlansList?.length}
        currentValue={currentValue}
        windowWidth={windowWidth}
        divWidth={divWidth}
      >
        <ArrowIcon className="next" />
      </ArrowContainer>
    );
  };

  const PrevArrow = (props: {
    onClick?: React.MouseEventHandler<HTMLDivElement>;
  }) => {
    const { onClick } = props;

    return isMobile ? (
      <></>
    ) : (
      <ArrowContainer
        role="button"
        isOpen={true}
        onClick={onClick}
        prev={true}
        prevIndex={Math.floor(prevIndex)}
        mediaLength={floorPlansList?.length}
        currentValue={currentValue}
        windowWidth={windowWidth}
        divWidth={divWidth}
      >
        <ArrowIcon className="prev" />
      </ArrowContainer>
    );
  };

  const clearValue = React.useCallback(
    (e?: React.MouseEvent<HTMLButtonElement>) => {
      e?.preventDefault();

      setImage(null);
      setFloor(null);
      setChoosePlan(false);
    },
    [setChoosePlan, setImage]
  );
  const [isResolutionPopupOpen, setIsResolutionPopupOpen] = React.useState(
    false
  );
  const { showNotification } = useNotifications();
  const showError = React.useCallback(
    (
      message = 'The file type is not supported. Please, upload a JPG, JPEG, PNG, or PDF'
    ) => {
      showNotification({
        key: 'fileDropZone/notSupported',
        message,
        severity: 'error',
      });
      return clearValue();
    },
    [showNotification, clearValue]
  );
  const [fileError, setFileError] = React.useState<{
    type?: boolean;
    resolution?: boolean;
    size?: boolean;
    ratio?: boolean;
  }>({});
  const [isImageSet, setIsImageSet] = React.useState(false);
  const onChangeHandler = React.useCallback(
    async (files: File[] | FileList | null) => {
      const file = files?.[0];
      setFileError({});
      if (!file) return null;
      const conditions = [
        !['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'].includes(
          file?.type ?? ''
        ),
        (file?.size ?? 0) > SIZE_LIMIT,
      ];
      if (conditions.some(v => v)) {
        setFileError({
          size: (file?.size ?? 0) > SIZE_LIMIT,
          type: ![
            'application/pdf',
            'image/png',
            'image/jpeg',
            'image/jpg',
          ].includes(file?.type ?? ''),
        });
      }
      // in case file is PDF - check if number of pages is valid
      if (file.type.includes('pdf')) {
        const { data: results, error } = await readFile(file);
        const count = results?.match(/\/Type[\s]*\/Page[^s]/g)?.length ?? 10;
        if (Boolean(error) || count > MAX_PAGES_SIZE) return showError();
        setImage(file);
        setFloor(null);
        setChoosePlan(false);
        setIsImageSet(true);
        dispatch({
          type: OnboardingAction.SET_IS_PREMADE_PROPERTY,
          payload: Boolean(false),
        });
      } else {
        // const tmppath = URL.createObjectURL(file);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          Jimp.read(
            Buffer.from(
              (reader.result?.toString() ?? '')
                .replace(/^data:image\/png;base64,/, '')
                .replace(/^data:image\/jpeg;base64,/, ''),
              'base64'
            ),
            (err, imageFile) => {
              // .replace(/^data:image\/png;base64,/, '')
              // .replace(/^data:image\/jpeg;base64,/, '');
              if (err?.toString().includes('maxResolutionInM')) {
                const img = document.createElement('img');

                img.onload = () => {
                  if (
                    img?.width > 10000 ||
                    img?.height > 10000 ||
                    img.width / img.height > 3 ||
                    img.height / img.width > 3
                  ) {
                    setFileError(e => ({
                      ...e,
                      resolution: img?.width > 10000 || img?.height > 10000,
                      ratio:
                        img.width / img.height > 3 ||
                        img.height / img.width > 3,
                    }));
                  }
                };

                img.src = reader.result?.toString() ?? '';
                setIsResolutionPopupOpen(true);
              } else if (imageFile === undefined) {
                showError();
              } else if (
                imageFile?.bitmap.width > 10000 ||
                imageFile?.bitmap.height > 10000 ||
                imageFile.bitmap.width / imageFile.bitmap.height > 3 ||
                conditions.some(v => v)
              ) {
                setFileError(e => ({
                  ...e,
                  resolution:
                    imageFile?.bitmap.width > 10000 ||
                    imageFile?.bitmap.height > 10000,
                  ratio: imageFile.bitmap.width / imageFile.bitmap.height > 3,
                }));
                setIsResolutionPopupOpen(true);
              } else {
                setImage(file);
                setFloor(null);
                setChoosePlan(false);
                setIsImageSet(true);
                dispatch({
                  type: OnboardingAction.SET_IS_PREMADE_PROPERTY,
                  payload: Boolean(false),
                });
              }
            }
          );
        };
      }

      return null;
    },
    [setChoosePlan, setImage, showError, dispatch]
  );
  React.useEffect(() => {
    if (isMobile) {
      if (image && isImageSet) {
        onNextClick();
        setIsImageSet(false);
      }
    }
  }, [image, isImageSet, onNextClick, isMobile]);
  const [selectedItem, setSelectedItem] = React.useState(
    locale === 'he' ? 0 : 0
  );
  const [scrollSource, setScrollSource] = React.useState('');
  const settings = {
    dots: false,
    infinite: false,
    afterChange: (index: number) => {
      setPrevIndex(index);
    },
  };

  const scrollRef = React.useRef<HTMLDivElement | null>(null);
  React.useEffect(() => {
    if (scrollSource !== 'scroll') {
      if (locale === 'he') {
        scrollRef.current?.scroll({
          left: -(300 * Math.abs(selectedItem)),
        });
      } else scrollRef.current?.scroll({ left: 304 * selectedItem });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  const showArrowPrev = React.useMemo(() => {
    if (selectedItem === 0 || (locale === 'he' && selectedItem === 3)) {
      return false;
    }
    return true;
  }, [selectedItem, locale]);
  const showArrowNext = React.useMemo(() => {
    if (
      selectedItem === floorPlansList.length - 2 ||
      (locale === 'he' && selectedItem < 0 - (floorPlansList.length - 3)) ||
      (locale === 'he' && selectedItem === floorPlansList.length - 1)
    ) {
      return false;
    }
    return true;
  }, [floorPlansList.length, locale, selectedItem]);

  return (
    <Section.Container className="upload-plan-container" withLogin>
      {isLoading && isMobile && (
        <LoaderWrapper>
          <Loader fullHeight />
        </LoaderWrapper>
      )}
      {/* <Section.Header>
        <Section.TitleStack withLoginLink />
      </Section.Header> */}
      {/* <Section.Stepper />
      <SectionAltTitle>a  
        <span>
          <FormattedMessage id={Message.CHOOSE_PLAN_CONTAINER_TITLE_1} />
        </span>
        <span>
          <FormattedMessage id={Message.CHOOSE_PLAN_CONTAINER_TITLE_2} />
        </span>
        <span>
          <FormattedMessage id={Message.CHOOSE_PLAN_CONTAINER_TITLE_4} />
        </span>
      </SectionAltTitle> */}
      <TitleContainer>
        <span className="span-title">
          <FormattedMessage id={Message.ONBOARDING_UPLOAD_YPUR_PLAN} />
        </span>
        <ORText className="middle-text">
          <FormattedMessage
            id={Message.CHOOSE_PLAN_CONTAINER_TITLE_2}
            values={{
              span: (chunks: string) => (
                <span className="title-text">{chunks}</span>
              ),
            }}
          />
        </ORText>{' '}
        <div>
          <FormattedMessage id={Message.CHOOSE_PLAN_CONTAINER_TITLE_4} />
        </div>
      </TitleContainer>

      {showArrowNext && isMobile && <NextArrowMobile />}
      {showArrowPrev && isMobile && <PrevArrowMobile />}

      <SelectivePlanContainer>
        <MediaContainer locale={locale} ref={targetRef}>
          {!isMobile && locale && (
            <Slider
              {...settings}
              slidesToShow={currentValue}
              slidesToScroll={1}
              swipeToSlide={true}
              nextArrow={<NextArrow />}
              prevArrow={<PrevArrow />}
              rtl={locale === 'he'}
              initialSlide={locale === 'he' ? 4 : 0}
            >
              {floorPlansList &&
                floorPlansList.map((item, index) => {
                  return item.id === 9999 ? (
                    <ORText className="middle-text">
                      <FormattedMessage
                        id={Message.CHOOSE_PLAN_CONTAINER_TITLE_2}
                        values={{
                          span: (chunks: string) => (
                            <span className="title-text">{chunks}</span>
                          ),
                        }}
                      />
                    </ORText>
                  ) : (
                    <>
                      <PlansContainer
                        isActive={
                          (index === 0 && image !== null) ||
                          JSON.stringify(floor) === JSON.stringify(item)
                        }
                        key={item?.id}
                        onClick={
                          item.files.length > 0 && item.id !== 0
                            ? e => {
                                setChoosePlan(true);
                                setFloor(item);
                                setImage(null);
                              }
                            : () => {
                                fileInputRef?.current?.click();
                              }
                        }
                      >
                        <div className="checkContainer">
                          <Check />
                        </div>
                        {item?.files.length > 0 && (
                          <ImageContainer>
                            <img
                              src={item.files[0]?.file}
                              alt="not available"
                            />
                          </ImageContainer>
                        )}
                        {item.id === 0 && (
                          <>
                            <UploadButton>
                              <FileInput
                                ref={fileInputRef}
                                onChange={e => onChangeHandler(e.target.files)}
                              />
                              <Button
                                // isLoading={isLoading}
                                className="upload-button"
                                onClick={e => {
                                  e.stopPropagation();
                                  fileInputRef?.current?.click();
                                }}
                              >
                                <UploadIcon className="upload-icon" />
                                <FormattedMessage
                                  id={
                                    Message.CLIENT_AUTH_ONBOARDING_UPLOAD_PLAN
                                  }
                                />
                                {/* <FormattedMessage
                              id={Message.CLIENT_AUTH_ONBOARDING_UPLOAD_PLAN}
                            /> */}
                              </Button>
                            </UploadButton>
                          </>
                        )}
                        {item.files.length > 0 && item.id !== 0 && (
                          <ImageText dir={locale === 'he' ? 'rtl' : 'ltr'}>
                            <FormattedMessage
                              id={Message.PLAN_BEDROOMS}
                              values={{ name: item.files[0].name }}
                            />
                          </ImageText>
                        )}
                        {item.files.length > 0 && item.id !== 0 && (
                          <ImageType>{item.files[0].type}</ImageType>
                        )}
                      </PlansContainer>
                      {index === 0 && (
                        <div
                          style={{
                            marginTop: 18,
                            width: 316,
                            fontSize: '14px',
                            textAlign: 'center',
                          }}
                        >
                          <FormattedMessage
                            id={Message.ONBOARDING_UPLOAD_NOTICE}
                            values={{ linebreak: <br /> }}
                          />
                        </div>
                      )}
                    </>
                  );
                })}
            </Slider>
          )}
          {isMobile && (
            <MobileScrollingDiv
              style={{ display: 'flex', overflowX: 'scroll' }}
              ref={scrollRef}
              onScroll={e => {
                // setIsFromScroll(true);
                if (scrollSource === 'scroll') {
                  if (locale === 'he')
                    setSelectedItem(
                      Math.abs(
                        Math.floor((scrollRef.current?.scrollLeft ?? 0) / 285)
                      )
                    );
                  else {
                    setSelectedItem(
                      Math.abs(
                        Math.floor((scrollRef.current?.scrollLeft ?? 0) / 285)
                      )
                    );
                  }
                }
                setScrollSource('scroll');
              }}
            >
              {floorPlansList
                .filter(e => e.id !== 9999)
                .map((item, index) => {
                  return (
                    <div key={item.id} style={{ width: '100%' }}>
                      <PlansContainer
                        isActive={
                          (index === 0 && image !== null) ||
                          JSON.stringify(floor) === JSON.stringify(item)
                        }
                        key={item?.id}
                        onClick={
                          item.files.length > 0 && item.id !== 0
                            ? e => {
                                setChoosePlan(true);
                                setFloor(item);
                                setImage(null);
                                setPreFloorPLanMobile(item);
                              }
                            : () => {
                                fileInputRef?.current?.click();
                              }
                        }
                      >
                        <div className="checkContainer">
                          <Check />
                        </div>
                        {item?.files.length > 0 && (
                          <ImageContainer>
                            <img
                              src={item.files[0]?.file}
                              alt="not available"
                            />
                          </ImageContainer>
                        )}
                        {item.id === 0 && (
                          <>
                            <UploadButton>
                              <FileInput
                                ref={fileInputRef}
                                onChange={e => {
                                  onChangeHandler(e.target.files);
                                }}
                              />
                              <Button
                                // isLoading={isLoading}
                                className="upload-button"
                                onClick={e => {
                                  e.stopPropagation();
                                  fileInputRef?.current?.click();
                                }}
                              >
                                {locale === 'en' && (
                                  <UploadIcon className="upload-icon" />
                                )}
                                <FormattedMessage
                                  id={
                                    Message.CLIENT_AUTH_ONBOARDING_UPLOAD_PLAN
                                  }
                                />
                                {locale === 'he' && (
                                  <UploadIcon className="upload-icon" />
                                )}
                                {/* <FormattedMessage
                      id={Message.CLIENT_AUTH_ONBOARDING_UPLOAD_PLAN}
                    /> */}
                              </Button>
                            </UploadButton>
                          </>
                        )}
                        {item.files.length > 0 && item.id !== 0 && (
                          <ImageText dir={locale === 'he' ? 'rtl' : 'ltr'}>
                            <FormattedMessage
                              id={Message.PLAN_BEDROOMS}
                              values={{ name: item.files[0].name }}
                            />
                          </ImageText>
                        )}
                        {item.files.length > 0 && item.id !== 0 && (
                          <ImageType>{item.files[0].type}</ImageType>
                        )}
                      </PlansContainer>
                      {index === 0 && (
                        <div
                          style={{
                            marginTop: 16,
                            width: 306,
                            fontSize: '14px',
                            textAlign: 'center',
                          }}
                        >
                          <FormattedMessage
                            id={Message.ONBOARDING_UPLOAD_NOTICE}
                            values={{ linebreak: <br /> }}
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
            </MobileScrollingDiv>
          )}
          {!isMobile && (
            <Section.Actions
              isLoading={isLoading}
              // withPrevious={choosePlan}
              withPrevious
              prevProps={{ onClick: goBack, disabled: true }}
              nextProps={{
                disabled: (!image && !choosePlan) || isLoading,
                onClick: floor ? setPreFloorPLan : onNextClick,
                isLoading,
              }}
            />
          )}
          {isMobile && (
            <div style={{ width: '167px', margin: 'auto', marginTop: '32px' }}>
              <MuiSlider
                max={floorPlansList.length - 2}
                min={0}
                value={
                  locale === 'he'
                    ? Math.abs(floorPlansList.length - 2 + selectedItem)
                    : selectedItem
                }
                onChange={(e, value) => {
                  setScrollSource('');
                  setSelectedItem(
                    locale === 'he'
                      ? (value as number) - (floorPlansList.length - 2)
                      : (value as number)
                  );
                }}
                componentsProps={{
                  rail: {
                    style: {
                      width: '167px',
                      height: '2px',
                      background: '#C4C4C4',
                      borderRadius: '30px',
                    },
                  },
                  thumb: {
                    style: {
                      width: `${167 / floorPlansList.length}px`,
                      height: '2px',
                      borderRadius: '30px',
                      background: 'black',
                    },
                  },
                  track: {
                    style: {
                      width: '167px',
                      height: '2px',
                      background: '#C4C4C4',
                      borderRadius: '30px',
                      border: 'none',
                    },
                  },
                }}
              />
            </div>
          )}
          {/* <ActionButton>
            <Button
              // isLoading={isLoading}
              className="back-button"
              onClick={() => history.push(ClientRouter.UPLOAD_PLAN)}
            >
              <FormattedMessage id={Message.CLIENT_AUTH_ONBOARDING_GO_BUTTON} />
            </Button>
            <Button
              // isLoading={isLoading}
              className="-button"
              onClick={() => history.push(ClientRouter.UPLOAD_PLAN)}
            >
              <FormattedMessage id={Message.CLIENT_AUTH_ONBOARDING_GO_BUTTON} />
            </Button>
          </ActionButton> */}
        </MediaContainer>
      </SelectivePlanContainer>

      {/* <div className="fade-effect">
        <>
          <PlanContainer isMobile={isMobile}>
            <FileDrop onDrop={files => setDropImage(files)}>
              <FileDropZone
                dropImage={dropImage}
                onChange={img => {
                  setImage(img);
                  setFloor(null);
                  setChoosePlan(false);
                }}
                initialValue={image}
                onClick={() => {
                  LogsAPI.postUserLogs({
                    area: userLogData.eventArea.registration,
                    section: userLogData.eventSection.planUpload,
                    name: userLogData.eventName.browseForPlan,
                    path: userLogData.eventPath.appOnBoardingUploadPlanPath,
                  });
                  setFloor(null);
                }}
              />
            </FileDrop>

            <Grid container={!isMobile}>
              {floorPlansList.map(data => {
                const name =
                  locale === SupportedLanguage.HEBREW
                    ? Number(data.files[0].name) + 1
                    : Number(data.files[0].name);
                return (
                  <Grid
                    item
                    sm={4}
                    key={data.id}
                    onClick={() => {
                      LogsAPI.postUserLogs({
                        area: userLogData.eventArea.registration,
                        section: userLogData.eventSection.planUpload,
                        name: `${userLogData.eventName.chooseBedroom}${data.files[0].name}`,
                        path: userLogData.eventPath.appOnBoardingUploadPlanPath,
                      });
                      setChoosePlan(true);
                      setFloor(data);
                      setImage(null);
                    }}
                  >
                    <FloorContainer selected={floor === data}>
                      <div>
                        <SafeImg src={data.files[0].file} />
                      </div>
                      <h3>
                        {name} <FormattedMessage id={Message.PLAN_BEDROOMS} />
                      </h3>
                    </FloorContainer>
                  </Grid>
                );
              })}
            </Grid>
          </PlanContainer>
        </>
      </div> */}
      {/* <TruckProgressBar value={5} /> */}
      {/* <Section.Actions
        isLoading={isLoading}
        withPrevious={choosePlan}
        prevProps={{ onClick: goBack }}
        nextProps={{
          disabled: (!image && !choosePlan) || isLoading,
          onClick: floor ? setPreFloorPLan : onClick,
          isLoading,
        }}
      /> */}
      <Modal
        open={isResolutionPopupOpen}
        BackdropProps={{
          onClick: () => {
            setImage(null);
            setIsResolutionPopupOpen(false);
          },
        }}
      >
        <ModalContent>
          <div className="title">
            <FormattedMessage
              id={Message.ONBOARDING_PLAN_UPLOAD_RESOLUTION_TITLE}
              values={{
                linebreak: isMobile ? <br /> : ' ',
              }}
            />
          </div>
          <div className="subtitle">
            <FormattedMessage
              id={Message.ONBOARDING_PLAN_UPLOAD_RESOLUTION_SUBTITLE}
            />
          </div>
          <div className="notice">
            <ul>
              <li>
                {fileError.type ? <Cancel /> : <CheckCircle />}
                <span>
                  <FormattedMessage id={Message.UPLOAD_PLAN_ERROR_TYPE} />
                </span>
              </li>
              <li>
                {fileError.size ? <Cancel /> : <CheckCircle />}
                <span>
                  <FormattedMessage id={Message.UPLOAD_PLAN_ERROR_SIZE} />
                </span>
              </li>
              <li>
                {fileError.resolution ? <Cancel /> : <CheckCircle />}
                <span>
                  <FormattedMessage id={Message.UPLOAD_PLAN_ERROR_RESOLUTION} />
                </span>
              </li>
              <li>
                {fileError.ratio ? <Cancel /> : <CheckCircle />}
                <span>
                  <FormattedMessage id={Message.UPLOAD_PLAN_ERROR_RATIO} />
                </span>
              </li>
            </ul>
          </div>
          <Button
            className="ok-btn"
            onClick={() => {
              setImage(null);
              setIsResolutionPopupOpen(false);
            }}
          >
            <FormattedMessage
              id={Message.ONBOARDING_PLAN_UPLOAD_RESOLUTION_OK_BUTTON}
            />
          </Button>
        </ModalContent>
      </Modal>
    </Section.Container>
  );
};

export default NewUploadPlan;

const ModalContent = styled.div`
  width: 537px;

  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding-top: 24px;
  text-align: center;
  border: none;
  outline: none;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  padding-bottom: 24px;
  @media (max-width: 767px) {
    width: 327px;
    height: 224px;
  }
  .title {
    font-weight: 500;
    font-size: 22px;
    text-align: center;

    /* Primary Color */

    color: #fc5c42;
    @media (max-width: 767px) {
      font-weight: 500;
      font-size: 20px;
    }
  }
  .ok-btn {
    background: #fc5c42;
    /* Primary Color */
    margin-top: 16px;
    border: 1px solid #fc5c42;
    border-radius: 15px;
    width: 127px;
    height: 48px;
    font-weight: 500;
    font-size: 18px;
    @media (max-width: 767px) {
      height: 40px;
      font-weight: 500;
      font-size: 18px;
    }
  }
  .subtitle {
    font-weight: 400;
    font-size: 16px;
    text-align: center;

    /* First level text */

    color: #282929;
    margin-top: 16px;
    @media (max-width: 767px) {
      font-weight: 500;
      font-size: 16px;
    }
  }
  .notice {
    font-weight: 400;
    font-size: 14px;
    color: #696969;
    margin-top: 8px;
    text-align: center;
    ul {
      width: fit-content;
      margin: auto;
      li {
        position: relative;
        width: fit-content;
        list-style: none;
        svg {
          margin-bottom: -6px;
          fill: #fc5c42;
        }
        span {
          line-height: 24px;
          vertical-align: middle;
        }
      }
    }
    @media (max-width: 767px) {
      font-weight: 400;
      font-size: 14px;
    }
  }
`;

const LoaderWrapper = styled.div`
  top: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  font-size: 0;
  position: fixed;
  background: rgba(100, 100, 100, 0.5);
`;

const FileInput = styled.input.attrs({
  type: 'file',
  name: 'file',
  accept: 'application/pdf, image/png, image/jpeg, image/jpg',
})`
  position: absolute;
  visibility: hidden;
`;
const ORText = styled.div`
  color: #3e3e3e;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 42px;
  @media (min-width: 767px) and (max-height: 1100px) {
    margin: ${({ theme }) => theme.dir !== 'rtl' && ' 0 8px'};
  }
  margin: 0 24px;
  margin-top: -5px;
  @media (max-width: 767px) {
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    margin: 8px 0;
  }
  span {
    color: #fc5c42 !important;
    font-style: normal !important;

    @media (max-width: 767px) {
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
    }
  }
`;

const TitleContainer = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  @media (min-width: 767px) and (max-height: 1200px) {
    margin-top: 80px;
    /* width: ${({ theme }) => theme.dir !== 'rtl' && '95vw'}; */
    text-align: ${({ theme }) => theme.dir !== 'rtl' && 'center'};
  }
  margin-top: 116px;
  width: max-content;
  @media (max-width: 820px) {
    flex-direction: column;
    text-align: center;
    font-size: 18px;
    line-height: 21px;
    margin-top: 48px;
  }
  .span-title {
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 33px;
    color: #fc5c42;
    @media (max-width: 767px) {
      font-size: 22px;
      line-height: 26px;
    }
  }
  .middle-text {
  }
`;

const SelectivePlanContainer = styled.div`
  margin-top: 84px;
  .slick-slide {
    max-width: 364px !important;
  }
  @media (max-width: 767px) {
    margin-top: 49px;
  }
  @media ${LAPTOP_MAX_RESOLUTION} and ${LAPTOP_MIN_RESOLUTION} {
    padding: 0 20px;
  }
`;

const MediaContainer = styled.div<{ locale: string }>`
  position: relative;
  @media (max-width: 767px) {
    max-width: 300px;
    margin: 0 auto;
  }
  && .horizontal-carousel {
    gap: 40px !important;
  }

  ${({ locale }) =>
    locale === 'he'
      ? css`
          && .slick-slide:nth-last-child(2) {
            width: 95px !important;
            & > div {
              position: absolute;
              top: 24%;
            }
            .middle-text {
              margin: 0;
            }
          }
        `
      : css`
          && .slick-slide:nth-child(2) {
            width: 95px !important;
            & > div {
              position: absolute;
              top: 24%;
            }
            .middle-text {
              margin: 0;
            }
          }
        `}

  && .slick-track {
    margin: 0;
    display: flex;
    gap: 20px;
  }
`;

const ArrowContainerMobile = styled(ArrowBase)<{
  isRight: boolean;
  prev: boolean;
}>`
  background: #ffffff;
  position: absolute;
  @media (min-width: 767px) and (max-width: 1440px) {
    top: 60%;
  }
  top: 45%;
  ${({ theme, isRight }) =>
    theme.dir === 'rtl'
      ? css`
          ${rtl`
  ${isRight ? 'right: 24px;' : 'left: 24px;'}
  `}
        `
      : css`
          ${rtl`
  ${isRight ? 'right: 24px;' : 'left: 24px;'}
  `}
        `}
  z-index: 1;
  transform: rotate(${({ prev }) => (prev ? 180 : 0)}deg);
  svg {
    ${({ theme }) =>
      theme.dir === 'rtl'
        ? css`
            transform: rotate(180deg);
            left: -4px;
          `
        : css`
            ${rtl`
  transform: rotate(0deg);
    left: 4px;
  `}
          `}
    fill: #292929;
    opacity: 0.9;
  }
`;

const ArrowContainer = styled(ArrowBase)<{
  prev: boolean;
  prevIndex: number;
  mediaLength: number;
  currentValue: number;
  windowWidth: number;
  divWidth: number;
}>`
  position: absolute;
  top: 400px;
  z-index: 9;
  pointer-events: ${({ prevIndex, theme, windowWidth, divWidth }) =>
    windowWidth >= divWidth ? 'none' : 'auto'};
  ${({ theme, prev }) =>
    theme.dir === 'rtl'
      ? css`
          @media ${TABLET_RESOLUTION} {
            left: ${prev ? '43' : '50'}%;
          }
          @media ${SMALL_RESOLUTION} {
            left: ${prev ? '44' : '50'}%;
          }
          left: ${prev ? '46' : '50'}%;
          transform: rotate(${prev ? 0 : 180}deg);
        `
      : css`
          @media ${TABLET_RESOLUTION} {
            right: ${prev ? '53' : '46'}%;
          }
          @media ${SMALL_RESOLUTION} {
            right: ${prev ? '52' : '46'}%;
          }
          right: ${prev ? '50' : '46'}%;
          transform: rotate(${prev ? 180 : 0}deg);
        `}
  svg {
    fill: 'rgba(41, 41, 41, 0.9)';
  }
  & .prev {
    fill: ${({ prevIndex, theme, windowWidth, divWidth }) =>
      prevIndex === 0 ||
      (theme.dir === 'rtl' && windowWidth >= divWidth) ||
      (theme.dir === 'ltr' && windowWidth >= divWidth)
        ? '#D9D9D9'
        : 'rgba(41, 41, 41, 0.9)'};
  }
  & .next {
    fill: ${({
      prevIndex,
      mediaLength,
      currentValue,
      theme,
      windowWidth,
      divWidth,
    }) =>
      prevIndex + currentValue === mediaLength ||
      (theme.dir === 'rtl' && windowWidth >= divWidth) ||
      (theme.dir === 'ltr' && windowWidth >= divWidth)
        ? '#D9D9D9'
        : 'rgba(41, 41, 41, 0.9)'};
  }
`;

const ArrowIcon = styled(Icon).attrs({
  name: 'nav-arrow-right',
})<{ isOpen: boolean }>`
  position: relative;

  ${({ isOpen }) =>
    isOpen
      ? css`
          ${rtl`left:4px;`}
        `
      : css`
          ${rtl`left:4px;`}
        `}
  transform: rotate(
    ${({ theme }) => (theme.dir === 'rtl' ? '180deg' : '0deg')}
  );
  z-index: 9;
`;

const PlansContainer = styled.div<{ isActive: boolean }>`
  &:hover {
    border: 3px solid #fc5c42;
  }

  cursor: pointer;
  border: ${({ isActive }) =>
    isActive ? '3px solid #fc5c42' : '3px solid transparent'};
  height: 362px;

  border-radius: 15px;
  text-align: center;
  position: relative;
  width: 310px;
  @media ${SMALL_HEIGHT_RESOLUTION} and ${MAX_TABLET_RESOLUTION} {
    width: 310px;
    margin-left: ${({ theme }) => (theme.dir === 'rtl' ? '10px' : '0px')};
    border: ${({ isActive }) =>
      isActive ? '2px solid #fc5c42' : '2px solid transparent'};
  }

  @media ${TABLET_RESOLUTION} {
    width: 310px;
    margin-left: ${({ theme }) => (theme.dir === 'rtl' ? '10px' : '0px')};
    border: ${({ isActive }) =>
      isActive ? '2px solid #fc5c42' : '2px solid transparent'};
  }

  .text-container {
    width: 48px;
    margin: 0 32px;
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    > span {
      :first-letter {
        color: #fc5c42 !important;
      }
    }
  }

  & > span {
    :first-letter {
      color: #fc5c42 !important;
    }
  }
  .checkContainer {
    height: 24px;
    width: 24px;
    position: absolute;
    top: 8px;
    ${rtl`right: 8px;`}
    border-radius: 12px;
    background-color: #fc5c42;
    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
    svg {
      width: 15px;
      height: 23px;
      path {
        stroke: white;
        fill: white;
      }
    }
  }
`;

const ImageContainer = styled.div`
  max-width: 300px;
  width: 100%;
  height: 260px;
  margin: auto;
  > img {
    width: 100%;
    height: 100%;
  }
`;

const UploadButton = styled.div`
  .upload-button {
    height: 48px;
    background-color: white;
    color: #fc5c42;
    border: 1.6791px solid #fc5c42;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    margin-top: 44px;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    /* identical to box height */

    text-align: center;

    color: #fc5c42;
    &:hover {
      color: white;
      svg {
        path {
          stroke: white;
        }
      }
    }
  }

  .upload-icon {
    margin-right: 10px;
  }
`;

const ImageText = styled.div`
  margin-top: 40px;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
`;

const ImageType = styled.div`
  margin-top: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
`;

// const ChoosePlanText = styled.h1`
//   margin: 80px 0 0;
//   font-size: 2rem;
//   text-align: center;
//   span {
//     cursor: pointer;
//     color: ${({ theme }) => theme.colors.primary.lightMobile};
//     text-decoration: underline;
//   }
// `;

const MobileScrollingDiv = styled.div`
  &::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
  }
  /* color: red; */
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
