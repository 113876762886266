import * as React from 'react';
import styled from 'styled-components';
import format from 'date-fns/format';
import DateRangePicker, { DateRange as IDateRange } from 'components/DateRange';
import useDateRange from 'hooks/useDateRange';
import InputButton from 'components/InputButton';
import { menuProps } from 'components/Select/Select.config';
import Icon from 'components/Icon';
import { isEmpty } from 'utils/lodash.utils';
import { CLIENT_DATE_FORMAT } from 'utils/dates.utils';
import { ControlProps } from './types';

interface Props extends Omit<ControlProps<IDateRange>, 'options'> {}

const DateRangeFilter: React.FC<Props> = ({
  onApply,
  placeholder = 'Any time',
  initialState,
}) => {
  const { dateRange, open, toggle, handleDateRange, anchorEl } = useDateRange(
    onApply,
    initialState
  );

  const isInitialRange = React.useMemo(() => !dateRange || isEmpty(dateRange), [
    dateRange,
  ]);

  const start = React.useMemo(
    () =>
      dateRange?.startDate &&
      !isInitialRange &&
      format(dateRange.startDate, CLIENT_DATE_FORMAT),
    [dateRange?.startDate, isInitialRange]
  );

  const end = React.useMemo(
    () => dateRange?.endDate && format(dateRange.endDate, CLIENT_DATE_FORMAT),
    [dateRange?.endDate]
  );

  const displayLabel = (start && end && `${start} | ${end}`) || placeholder;
  const active = !!start && !!end;

  return (
    <Container>
      <InputButton
        onClick={toggle}
        displayPlaceholder
        isOpen={open}
        placeholder={displayLabel}
        disableCounter
        endIcon={<CalendarIcon active={active} />}
        responsive
        active={active}
      />
      <DateRangePicker
        open={open}
        toggle={toggle}
        anchorEl={anchorEl}
        initialDateRange={dateRange}
        onChange={handleDateRange}
        maxDate={new Date()}
        MenuProps={menuProps}
        applyButton
      />
    </Container>
  );
};

export default DateRangeFilter;

const Container = styled.div`
  position: relative;
`;

const CalendarIcon = styled(Icon).attrs<{ active: boolean }>(({ theme }) => ({
  name: 'calendar',
  color: theme.colors.primary.dark,
  size: 28,
}))<{ active: boolean }>`
  fill: ${({ active, theme }) =>
    active ? theme.colors.primary.light : theme.colors.primary.dark};
  padding: 2px;
  @media (min-width: 1500px) {
    padding-right: 5px;
    fill: ${({ theme }) => theme.colors.primary.dark};
  }
`;
