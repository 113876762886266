import CartInfoTypes from 'store/constants/@client/cartInfo.constants';
import { Cart } from 'types/properties';

export const setCartCount = (payload: number) => ({
  type: CartInfoTypes.SET_CART_ITEM_COUNT,
  payload,
});

export const setCartData = (payload: Cart) => ({
  type: CartInfoTypes.SET_CART_DATA,
  payload,
});

export type CartInfoActions = ReturnType<
  typeof setCartCount | typeof setCartData
>;
