import * as React from 'react';
import styled, { css } from 'styled-components';
import no_image_placeholder from 'assets/images/client-products/no_image.png';
import { useImageGuard } from 'hooks';
import { ProductPhoto } from 'types/products';
import Zoom from 'react-medium-image-zoom';
import Thumbnail, { Photo } from './Thumbnail';
import { ReactComponent as Arrow } from './Separator.svg';
import 'react-medium-image-zoom/dist/styles.css';

interface Props {
  alt: string;
  images: ProductPhoto[];
  showArrows?: boolean;
}

const NO_VALUE_IMAGE_SRC = {
  file: no_image_placeholder,
  id: 'no_image_placeholder',
};

const onScrollChange = (id: string, direction: string) => {
  const container = window.document.getElementById(id)
    ? window.document.getElementById(id)
    : null;
  if (container) {
    let scrollCompleted: number = 0;
    const slideVar = setInterval(() => {
      if (direction === 'left') {
        container.scrollLeft += 10;
      } else {
        container.scrollLeft -= 10;
      }
      scrollCompleted += 10;
      if (scrollCompleted >= 100) {
        window.clearInterval(slideVar);
      }
    }, 50);
  }
};

const PhotosSection: React.FC<Props> = ({ alt, images, showArrows }) => {
  const [selectedImage, setSelectedImage] = React.useState<ProductPhoto>(
    images?.[0] ?? NO_VALUE_IMAGE_SRC
  );
  const [isdivRefSet, setIsDivRefSet] = React.useState(false);
  const divRef = React.useRef<HTMLDivElement | null>(null);
  const increasePhotoIndex = React.useCallback(() => {
    if (images) {
      onScrollChange('thumbnail-list', 'left');
      setSelectedImage(prev => images[images.indexOf(prev) + 1]);
    }
  }, [images]);

  const decreasePhotoIndex = React.useCallback(() => {
    if (images) {
      onScrollChange('thumbnail-list', 'right');
      setSelectedImage(prev => images[images.indexOf(prev) - 1]);
    }
  }, [images]);

  const { src, onError } = useImageGuard(
    selectedImage.file,
    NO_VALUE_IMAGE_SRC.file
  );

  return (
    <Container>
      <div className="photos-container">
        <Photo
          ref={el => {
            divRef.current = el;
            setIsDivRefSet(true);
          }}
        >
          {isdivRefSet ? (
            <Zoom portalEl={divRef.current ?? undefined}>
              <img src={src} alt={alt} onError={onError} />
            </Zoom>
          ) : (
            <img src={src} alt={alt} onError={onError} />
          )}
        </Photo>
      </div>
      <ThumbnailsContainer>
        {showArrows && images && (
          <ArrowButton
            onClick={decreasePhotoIndex}
            disabled={images?.indexOf(selectedImage) === 0}
          >
            <LeftArrow />
          </ArrowButton>
        )}

        <Thumbnails id="thumbnail-list">
          {images?.map((photo, i) => (
            <Thumbnail
              key={i.toString()}
              photo={photo.file}
              alt={alt}
              selected={selectedImage.id === photo.id}
              onClick={() => setSelectedImage(photo)}
            />
          ))}
        </Thumbnails>

        {showArrows && images && (
          <ArrowButton
            onClick={increasePhotoIndex}
            disabled={images?.indexOf(selectedImage) === images?.length - 1}
          >
            <Arrow />
          </ArrowButton>
        )}
      </ThumbnailsContainer>
    </Container>
  );
};

export default PhotosSection;

const Container = styled.section.attrs({ className: 'photos-section' })`
  flex: 1;
  max-width: 377px;

  && .photos-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 377px;
  }
`;

const Thumbnails = styled.ul`
  ${({ theme }) => theme.utils.centerFlex}
  margin: initial;
  gap: 20px;
  margin: 6px 0 8px 0;
  overflow: overlay;
  max-width: 291px;
  justify-content: start;
  ${({ theme }) => theme.utils.scrollbar};

  @media (min-width: 1500px) {
    max-width: 377px;
    gap: 20px;
    margin: inherit;
  }

  & li {
    display: block;
  }
`;

const LeftArrow = styled(Arrow)`
  transform: rotate(${({ theme }) => 180}deg);
`;

const ThumbnailsContainer = styled.div`
  margin-top: 15px;
  display: flex;
`;

const ArrowButton = styled.button<{ disabled?: boolean }>`
  border-radius: 50%;
  color: #fff;
  background: transparent;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  ${({ theme }) =>
    theme.dir === 'rtl' &&
    css`
      & :not(.icon-check) {
        transform: rotate(180deg);
      }
    `}

  &:hover {
    cursor: pointer;
  }
  & > svg {
    width: 24px;
    height: 20px;
    path {
      stroke: ${({ theme, disabled }) =>
        disabled ? theme.colors.darkGray : theme.colors.primary.lightMobile};
    }
  }
`;
