import * as React from 'react';
import styled from 'styled-components';
import { PropertyMedia } from 'types/properties';

interface Props {
  items: PropertyMedia[];
  selectedFloor?: number | null;
  onClick: (media: PropertyMedia) => void;
}

const FloorGrid: React.FC<Props> = ({ items, selectedFloor, onClick }) => {
  return (
    <Floors>
      {items.map(item => (
        <Floor
          key={item.id}
          selected={selectedFloor === item.id}
          onClick={() => onClick(item)}
          onKeyPress={() => onClick(item)}
          tabIndex={0}
        >
          <img src={item.file} alt="Floor plan" />
        </Floor>
      ))}
    </Floors>
  );
};

export default FloorGrid;

const Floors = styled.section.attrs({ className: 'select-floor-floors' })`
  margin: 15px 20px 0 20px;
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  row-gap: 25px;
  column-gap: 9px;

  @media (min-height: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
    margin: 0;
  }
`;

export const Floor = styled.div.attrs(({ onClick }) => ({
  className: 'select-floor-single-floor',
  role: onClick && 'button',
}))<{
  selected?: boolean;
  children?: React.ReactNode;
}>`
  max-width: 550px;
  width: 517px;
  height: 442px;
  border-radius: 25px;
  cursor: ${({ onClick }) => onClick && 'pointer'};
  // box-shadow: 0px -3px 25px 5px rgba(0, 88, 171, 0.07);
  filter: drop-shadow(0px -15px 17.869px rgba(0, 0, 0, 0.0417275)),
    drop-shadow(0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725)),
    drop-shadow(0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802));
  border: 2px solid
    ${({ theme, selected = false }) =>
      selected ? theme.colors.primary.lightMobile : 'transparent'};

  & img {
    width: 100%;
    height: 100%;
    border-radius: 25px;
  }

  @media (max-width: 500px) {
    height: 138px;
    width: 156px;

    & img {
      height: 100%;
    }
  }
`;
