import * as React from 'react';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import InputLabel, { InputLabelProps } from '@material-ui/core/InputLabel';

export interface ILabel {
  title: string | React.ReactNode;
  parenthesesContent?: string | React.ReactNode;
  isBold?: boolean;
}

interface Props extends InputLabelProps {
  label: ILabel;
  htmlFor: string;
  required?: boolean;
  isInvalid?: boolean;
}

const FormLabel: React.FC<Props> = ({
  label,
  htmlFor,
  required = false,
  isInvalid = false,
  ...rest
}) => {
  const { title, parenthesesContent } = label;

  return (
    <CustomLabel className={htmlFor} shrink htmlFor={htmlFor} {...rest}>
      {title}
      <Parentheses>
        {parenthesesContent ? ` (${parenthesesContent})` : ''}
      </Parentheses>
      {required && title && htmlFor !== 'discount' ? '*' : ''}
    </CustomLabel>
  );
};

export const CustomLabel = styled(InputLabel)`
  color: ${({ theme }) => theme.colors.primary.dark};
  ${rtl`
    margin-left: 0.5rem;
    transform-origin: top left;
    top: 0;
    left: 0;
  `}
  font-weight: 400;
  font-size: 0.75rem;

  &.MuiFormLabel-root.Mui-focused {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primary.dark};
  }
`;

const Parentheses = styled.span`
  color: ${({ theme }) => theme.colors.gray};
`;

export default FormLabel;
