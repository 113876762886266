import * as React from 'react';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import Icon from 'components/Icon';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useAuthentication, useFormattedCurrency, useSession } from 'hooks';
import TextInput from 'components/@client/form-components/TextInput';
import Checkbox from 'components/@client/form-components/Checkbox';
import { PhoneField } from 'components/forms';
import Tooltip from 'components/Tooltip';
import Button from 'components/@client/Button';
import CardList from 'components/@client/CardList';
import CreditCardProviders from 'components/@client/CreditCardProviders';
import {
  MOBILE_TABLET_RESOLUTION,
  MOBILE_RESOLUTION,
  error as errorColor,
} from 'styles/sharedStyle';
import { useIntl, FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import {
  CheckOutDiscountCodeType,
  CheckOutPersonalDetailPayload,
} from 'types/orders';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  checkoutDiscountSchema,
  checkoutUserSchema,
} from 'utils/validation.utils';
import { IAddress, Setter, SupportedCountry } from 'types/common.types';
import CheckoutCartItem from 'components/@client/CardList/Items/CheckoutCartItem';
import { SelectedProductCoin } from 'types/products';
import { FormHelperText, useMediaQuery } from '@material-ui/core';
import { Skin } from 'types/properties';
import { ClientConfig, METHODS } from 'api/client';
import { ErrorCodeString } from 'utils/common.utils';
import { CheckoutProps } from './Checkout.config';
import AddressForm from './AddressForm';

interface Props extends CheckoutProps {
  isModal?: boolean;
  updateGuestAddress?: (isValid: boolean, guestAddress: IAddress) => void;
  onProccess: VoidFunction;
  guestAddressState: { isValid: boolean; guestAddress: IAddress | null };
  guestToken: string | null;
  updateGuestPersonalDetails: (data: CheckOutPersonalDetailPayload) => void;
  isLoading: boolean;
  cartAPI: (config?: ClientConfig) => void;
  deliveryDate: Date;
  address: IAddress | null;
  skin?: Skin | null;
  setStripeCredentials: Setter<{ publicKey: string; clientKey: string }>;
  coins?: SelectedProductCoin | null;
  hs?: string;
  personalDetail?: CheckOutPersonalDetailPayload | null;
  handleStripeSubmit: VoidFunction;
}

const WebView: React.FC<Props> = ({
  cart,
  canProceed,
  isLoadingCart,
  shippingOptions,
  userId,
  openModal,
  onAddressesChange,
  onPersonalDetailsChange,
  onProccess,
  updateGuestAddress,
  guestAddressState,
  guestToken,
  updateGuestPersonalDetails,
  isLoading,
  cartAPI,
  handleStripeSubmit,
}) => {
  const intl = useIntl();
  const {
    firstName = '',
    lastName = '',
    email,
    phoneNumber = '',
  } = useAuthentication();
  const isMobile = useMediaQuery(MOBILE_RESOLUTION);
  const isTablet = useMediaQuery(MOBILE_TABLET_RESOLUTION);
  const { country } = useSession();
  // const {
  //   cart: cart,
  //   cartAPI,
  //   isLoadingCart: isLoadingDiscount,
  // } = useCheckout();
  const [tooltipTitle, setTooltipTitle] = React.useState<string>('');
  const [open, setOpen] = React.useState<boolean>(false);
  const [isApplyClicked, setIsApplyClicked] = React.useState<boolean>(false);
  const [totalFinalPrice, setTotalFinalPrice] = React.useState<
    number | undefined
  >(0);
  const [discountPrice, setDiscountPrice] = React.useState<number | undefined>(
    0
  );

  const [
    applyDiscountVisiblity,
    setApplyDiscountVisiblity,
  ] = React.useState<boolean>(true);

  const methods = useForm<CheckOutPersonalDetailPayload>({
    resolver: yupResolver(checkoutUserSchema),
    defaultValues: { name: `${firstName} ${lastName}`, phoneNumber, email },
    mode: 'all',
  });

  const { control, handleSubmit } = methods;

  const price = useFormattedCurrency(cart?.subTotal, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const shippingAmount = useFormattedCurrency(0, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const vatAmount = useFormattedCurrency(cart?.vat ?? 0, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const totalDiscountPrice = useFormattedCurrency(discountPrice ?? 0, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const totalAmount = useFormattedCurrency(totalFinalPrice ?? 0, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  const onPersonalDetailsUpdate = React.useCallback(
    (payload: CheckOutPersonalDetailPayload) => {
      onPersonalDetailsChange?.({ ...payload });
      /* eslint-disable-next-line */
      if (true) {
        onProccess();
      } else {
        handleStripeSubmit();
      }
    },
    [onPersonalDetailsChange, onProccess, handleStripeSubmit]
  );

  // Code for Discount Evaluation
  const discount = '';
  const discountMethods = useForm<CheckOutDiscountCodeType>({
    resolver: yupResolver(checkoutDiscountSchema),
    defaultValues: { discount },
    mode: 'all',
  });

  const {
    control: discountControl,
    handleSubmit: handleDiscountSubmit,
  } = discountMethods;

  const applyDiscountCode = React.useCallback(
    (payload: CheckOutDiscountCodeType) => {
      setIsApplyClicked(true);
      cartAPI({
        params: {},
        method: METHODS.PATCH,
        data: {
          coupon_code: payload.discount,
        },
      });
    },
    [cartAPI]
  );

  React.useEffect(() => {
    if (cart?.finalPrice) setTotalFinalPrice(cart?.finalPrice);
  }, [cart?.finalPrice]);
  React.useEffect(() => {
    setDiscountPrice(cart?.totalDiscount);
    if (cart?.coupon === 'Valid') setApplyDiscountVisiblity(false);

    const value = cart && cart?.coupon;
    switch (value) {
      case ErrorCodeString.NOT_FOUND:
        setTooltipTitle(
          intl.formatMessage({
            id: Message.CHECKOUT_TOOLTIP_INVALID_NOTFOUND_TEXT,
          })
        );
        break;
      case ErrorCodeString.EXPIRED:
        setTooltipTitle(
          intl.formatMessage({ id: Message.CHECKOUT_TOOLTIP_EXPIRED_TEXT })
        );
        break;
      case ErrorCodeString.MAX_USES_REACHED:
        setTooltipTitle(
          intl.formatMessage({
            id: Message.CHECKOUT_TOOLTIP_INVALID_MAX_USES_TEXT,
          })
        );
        break;

      default:
        setTooltipTitle(value ?? '');
        break;
    }
  }, [cart, intl]);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  return (
    <>
      <RootDiv>
        <LeftSection>
          <CheckoutContainer>
            <Title>
              {intl.formatMessage({
                id: Message.CHECKOUT_INFO_TITLE,
              })}
            </Title>
            <TitleWithImage>
              <Image name="information-circle-outlined" size={20} />
              <SubTitle>
                {intl.formatMessage({
                  id: Message.CHECKOUT_PERSONAL_DETAIL_TITLE,
                })}
              </SubTitle>
            </TitleWithImage>
            <FormProvider {...methods}>
              <Form onSubmit={handleSubmit(onPersonalDetailsUpdate)}>
                <TextInputField
                  name="name"
                  control={control}
                  label={intl.formatMessage({
                    id: Message.CHECKOUT_PERSONAL_DETAIL_FULLNAME,
                  })}
                  required
                />
                <TextInputField
                  name="email"
                  control={control}
                  label={intl.formatMessage({
                    id: Message.AUTH_LOGIN_EMAIL_FIELD,
                  })}
                  type="email"
                  required
                />
                <PhoneNumber
                  name="phoneNumber"
                  label={{
                    title: intl.formatMessage({ id: Message.PHONE_NUMBER }),
                  }}
                  required
                />
                {methods.errors.phoneNumber && (
                  <FormHelperText
                    error
                    variant="filled"
                    className="error-label"
                  >
                    <Icon name="information-circle" color={errorColor} />
                    {methods.errors.phoneNumber.message}
                  </FormHelperText>
                )}
              </Form>
            </FormProvider>
            <AddressContainer>
              <TitleWithImage>
                <Image name="truck-outlined" size={25} />
                <SubTitle>
                  {intl.formatMessage({
                    id: Message.CHECKOUT_SHIPPING_ADDRESS_TITLE,
                  })}
                </SubTitle>
              </TitleWithImage>
              <AddressDetail>
                <AddressForm
                  updateGuestAddress={updateGuestAddress}
                  userId={userId}
                  onAddressesChange={onAddressesChange}
                />
              </AddressDetail>
            </AddressContainer>
            <ShippingOptionContainer>
              <TitleWithImage>
                <SubTitle>
                  <FormattedMessage
                    id={Message.CHECKOUT_SHIPPING_OPTIONS_TITLE}
                  />
                </SubTitle>
              </TitleWithImage>
              <ShippingOptionDetail>
                {shippingOptions.map(props => (
                  <Checkbox key={props.value as string} {...props} />
                ))}
              </ShippingOptionDetail>
            </ShippingOptionContainer>
          </CheckoutContainer>
        </LeftSection>
        <RightSection>
          <OrderSummaryContainer>
            <SubTitle isPadding={true}>
              <FormattedMessage id={Message.ORDER_SUMMARY} tagName="span" />
            </SubTitle>
            <Row>
              <Col text="uppercase">
                <FormattedMessage
                  id={
                    country === SupportedCountry.USA
                      ? Message.CHECKOUT_SUB_TOTAL_LABEL
                      : Message.CHECKOUT_TOTAL_LABEL
                  }
                  tagName="span"
                />
              </Col>
              <Col weight="500" text="uppercase">
                {price}
              </Col>
            </Row>
            <Row>
              <Col>
                <FormattedMessage
                  id={Message.CHECKOUT_SHIPPING_LABEL}
                  tagName="span"
                />
              </Col>
              <Col>{shippingAmount}</Col>
            </Row>
            <Row>
              <Col>
                <FormattedMessage
                  id={
                    country === SupportedCountry.ISRAEL
                      ? Message.CHECKOUT_VAT_LABEL
                      : Message.CHECKOUT_TAX_LABEL
                  }
                  tagName="span"
                  values={{
                    value: `${cart?.taxRate ?? ''}%`,
                  }}
                />
              </Col>
              <Col>{vatAmount}</Col>
            </Row>
            {!applyDiscountVisiblity ? (
              <Row>
                <Col>
                  <FormattedMessage
                    id={Message.DISCOUNT_CODE}
                    tagName="span"
                    values={{
                      value:
                        country === SupportedCountry.ISRAEL ? '17%' : '10%',
                    }}
                  />
                </Col>
                <Col color="#2CDD73">- {totalDiscountPrice}</Col>
              </Row>
            ) : (
              <>
                <StyledHr />
                <Row justify={isMobile || isTablet ? 'center' : ''}>
                  {!isMobile && !isTablet && (
                    <Col>
                      <FormattedMessage
                        id={Message.DISCOUNT_CODE}
                        tagName="span"
                        values={{
                          value:
                            country === SupportedCountry.ISRAEL ? '17%' : '10%',
                        }}
                      />
                    </Col>
                  )}
                  <Col>
                    <DiscountInputContainer>
                      <FormProvider {...discountMethods}>
                        <DiscountForm
                          onSubmit={handleDiscountSubmit(applyDiscountCode)}
                          checkCode={cart?.coupon}
                        >
                          {cart?.coupon && !isMobile && !isTablet && (
                            <ClickAwayListener onClickAway={handleTooltipClose}>
                              <div>
                                <Tooltip
                                  title={tooltipTitle}
                                  $variant="error"
                                  open={open}
                                  onClose={handleTooltipClose}
                                  disableFocusListener
                                  disableHoverListener
                                  disableTouchListener
                                >
                                  <div>
                                    <InfoOutlinedIcon
                                      fontSize="large"
                                      className="info-warning"
                                      color="error"
                                      onClick={() => setOpen(true)}
                                    />
                                  </div>
                                </Tooltip>
                              </div>
                            </ClickAwayListener>
                          )}
                          <TextInputField
                            name="discount"
                            placeholder={intl.formatMessage({
                              id: Message.CHECKOUT_DISCOUNT_PLACEHOLDER,
                            })}
                            control={discountControl}
                            required
                            type="text"
                            customError={
                              discountMethods?.formState?.errors?.discount
                                ?.message &&
                              intl.formatMessage({
                                id:
                                  discountMethods.formState.errors.discount
                                    .message,
                              })
                            }
                          />
                        </DiscountForm>
                      </FormProvider>
                      <CheckoutDiscountButton
                        isLoading={isLoadingCart && isApplyClicked}
                        disabled={!discountMethods?.formState?.isValid}
                        className="checkout-button"
                        onClick={handleDiscountSubmit(applyDiscountCode)}
                      >
                        <FormattedMessage
                          id={Message.CHECKOUT_DISCOUNT_BUTTON}
                        />
                      </CheckoutDiscountButton>
                    </DiscountInputContainer>
                  </Col>
                </Row>
              </>
            )}
            <Row justify={isMobile || isTablet ? 'center' : ''}>
              {(isMobile || isTablet) && cart?.coupon && (
                <MobileErrorParent>
                  <InfoOutlinedIcon
                    fontSize="small"
                    // className="info-warning"
                    color="error"
                    onClick={() => setOpen(true)}
                  />
                  <MobileErrorMessage>{tooltipTitle}</MobileErrorMessage>
                </MobileErrorParent>
              )}
            </Row>
            <StyledHr />

            <Row>
              <Col weight="bold" size="22px">
                <FormattedMessage
                  id={Message.CHECKOUT_TOTAL_LABEL}
                  tagName="span"
                />
              </Col>
              <Col weight="bold" size="22px">
                {totalAmount}
              </Col>
            </Row>
            <StyledHr />
            <Row
              justify={isMobile ? 'center' : 'end'}
              align={isMobile ? 'center' : 'end'}
            >
              <CheckoutButton
                isLoading={isLoading}
                className="checkout-button"
                disabled={
                  !canProceed ||
                  (!userId && !guestAddressState?.isValid) ||
                  !methods.formState.isValid
                }
                onClick={
                  !guestToken
                    ? handleSubmit(onPersonalDetailsUpdate)
                    : handleSubmit(updateGuestPersonalDetails)
                }
              >
                <FormattedMessage id={Message.CHECKOUT_PROCEED_BUTTON} />
              </CheckoutButton>
            </Row>
            <CreditCardProviders />
          </OrderSummaryContainer>
          <CartContainer>
            <Title>
              {intl.formatMessage({
                id: Message.CHECKOUT_YOUR_CART_TITLE,
              })}
            </Title>
            <CartItemsContainer>
              <CardList
                isLoading={isLoading}
                items={cart?.items ?? []}
                renderItem={item => (
                  <CheckoutCartItem key={item.id} {...item} nameLength={100} />
                )}
              />
            </CartItemsContainer>
          </CartContainer>
        </RightSection>
      </RootDiv>
    </>
  );
};

export default WebView;

const CartItemsContainer = styled.div`
  height: 60vh;
  overflow-y: scroll;
  .card-list {
    margin: 0px;
    .cart-item {
      div:first-child {
        background: #fff;
        box-shadow: none;
        border-radius: initial;
        padding: 0 !important;
      }
    }
  }
  ${({ theme }) => theme.utils.scrollbar};
  .card {
    @media ${MOBILE_RESOLUTION} {
      flex-direction: column;
    }
    .card-image-container {
      max-width: initial;
      box-shadow: none;
      @media ${MOBILE_RESOLUTION} {
        padding: 0 5px;
      }
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(242, 242, 242, 0) 0%,
        #f2f2f2 100%
      );
      border-radius: 10px;
      img {
        height: 123px;
        width: 123px;
        object-fit: contain;
        margin-top: 5px;
        vertical-align: middle;
        border-radius: 0px;
      }
    }
    .card-content {
      margin-top: 15px;
      padding-right: ${({ theme }) => (theme.dir === 'rtl' ? '15px' : '0')};
      padding-left: ${({ theme }) => (theme.dir === 'rtl' ? '0' : '15px')};
      @media ${MOBILE_RESOLUTION} {
        div {
          padding: 0 0px;
        }
      }
      h4 {
        font-family: ${({ theme }) =>
          theme.dir === 'rtl' ? 'Assistant' : 'Roboto'};
        font-style: normal;
        font-weight: ${({ theme }) => (theme.dir === 'rtl' ? 'bold' : '500')};
        font-size: 20px;
        line-height: 20px;
        color: #000000;
        @media ${MOBILE_RESOLUTION} {
          font-size: 16px;
          line-height: 20px;
        }
        margin-bottom: 5px;
      }
      .subtitle-data {
        font-family: ${({ theme }) =>
          theme.dir === 'rtl' ? 'Assistant' : 'Roboto'};
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 20px;
        color: #000000;
        mix-blend-mode: normal;
        opacity: 0.9;
        @media ${MOBILE_RESOLUTION} {
          font-size: 16px;
          line-height: 20px;
        }
        margin-bottom: 10px;
      }
      .cart-item-price {
        font-family: ${({ theme }) =>
          theme.dir === 'rtl' ? 'Assistant' : 'Roboto'};
        font-style: normal;
        font-weight: ${({ theme }) => (theme.dir === 'rtl' ? 'bold' : '500')};
        font-size: 22px;
        line-height: 43px;
        color: #000000;
        @media ${MOBILE_RESOLUTION} {
          font-size: 16px;
        }
      }
    }
  }
`;

const CheckoutContainer = styled.section`
  background: #ffffff;
  box-shadow: 6px 12px 150px rgba(111, 118, 123, 0.126885);
  border-radius: 10px;
  height: 100%;
  padding: 20px;
`;

const OrderSummaryContainer = styled.section`
  margin-bottom: 40px;
  height: auto;
  display: flex;
  margin-top: 0;
  flex-basis: 400px;
  padding: 20px;
  background: #ffffff;
  flex-direction: column;
  box-shadow: 6px 12px 150px rgba(111, 118, 123, 0.126885);
  border-radius: 10px;
  @media ${MOBILE_RESOLUTION} {
    padding: 20px;
    margin-top: 10px;
  }
  .order-summary {
    margin: 0 0 30px 0;
    font-weight: 700;
  }
  .total-price {
    font-weight: 700;
  }
  p {
    max-width: 500px;
    margin: 0 0 10px 0;

    display: flex;
    justify-content: space-between;
  }

  .out-of-stock-caption {
    color: ${({ theme }) => theme.colors.status.error};
    margin-top: 1rem;
    font-size: 0.75rem;
    order: 2;
    text-align: center;

    @media (min-width: 1150px) {
      order: 1;
    }
  }

  a {
    text-decoration: none;
  }
`;

const CheckoutDiscountButton = styled(Button)`
  position: absolute !important;
  top: 4px !important;
  ${rtl`
    right: 0 !important;  
  `}
  height: 40px !important;
  ${rtl`border-radius: 0 15px 15px 0 !important;
  `}
  min-width: 101px !important;
`;
const DiscountInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  && .custom-input-root {
    .MuiInputBase-root {
      height: 40px;
      border-radius: 10px;
      width: 294px;
      :hover {
        height: 38px;
      }
    }
    :hover {
      ${rtl`border-radius: 10px 15px 15px 10px;`}
      border-color: #DD2C2C;
    }
  }
`;

const CartContainer = styled.section`
  background: #ffffff;
  box-shadow: 6px 12px 150px rgba(94, 123, 144, 0.127);
  border-radius: 10px;
  height: 100%;
  padding: 20px 20px 20px 40px;
  @media ${MOBILE_RESOLUTION} {
    margin-top: 10px;
  }
`;

const LeftSection = styled.div`
  width: 50%;
  @media ${MOBILE_RESOLUTION} {
    width: 100%;
    margin: 0;
  }
  margin: 15px;
  background: #ffffff;
  box-shadow: 6px 12px 150px rgba(111, 118, 123, 0.126885);
  border-radius: 10px;
`;

const RightSection = styled.div`
  width: 50%;
  @media ${MOBILE_RESOLUTION} {
    width: 100%;
    margin: 0;
  }
  margin: 15px;
`;

export const Form = styled.form`
  @media (min-width: 1150px) {
    flex: 0.5;
  }
  margin: 0 0 40px 15px;
`;

export const DiscountForm = styled.form<{ checkCode?: string | undefined }>`
  position: relative;
  display: flex;
  && > span {
    margin-left: 0px !important;
  }
  && .text-input-root {
    min-height: unset !important;
    p {
      justify-content: flex-start !important;
    }
  }
  && .MuiOutlinedInput-input {
    ${rtl`
      padding-right: 100px;
    `}
    border-radius: 10px;
  }
  && .MuiOutlinedInput-notchedOutline {
    /* border-color: ${({ checkCode }) =>
      checkCode && checkCode === 'valid' ? 'green' : 'red'} !important; */
    ${({ checkCode }) =>
      checkCode &&
      `border-color : ${checkCode === 'valid' ? 'green' : 'red'} !important`}
  }

  && .info-warning {
    position: relative;
    top: 7px;
    ${rtl`
      left: -5px;
    `};
    cursor: pointer;
    transform: rotate(180deg);
  }
  && .success-checkmark {
    position: absolute;
    top: 7px;
    ${rtl`
      left: -38px;
    `}
  }
`;

const Image = styled(Icon)`
  text-align: center;
  margin-right: 7px;
  color: #000000;
  border: 0.8px solid #ffffff;
`;
const Title = styled.span`
  font-family: ${({ theme }) => (theme.dir === 'rtl' ? 'Assistant' : 'Roboto')};
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 62px;
  color: #000000;
  margin: 25px 0 25px 0;
  display: inline-block;
  @media ${MOBILE_RESOLUTION} {
    font-size: 20px;
    line-height: 0px;
  }
`;
const SubTitle = styled.span<{ isPadding?: boolean }>`
  font-family: ${({ theme }) => (theme.dir === 'rtl' ? 'Assistant' : 'Roboto')};
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 43px;
  color: #000000;
  @media ${MOBILE_RESOLUTION} {
    font-size: 16px;
  }
  ${rtl`
        margin-left: 5px;
      `}
  padding: ${({ isPadding }) => (isPadding ? '20px 0 20px 0' : '0')};

  && .discount-code {
    color: red;
  }
`;
const TitleWithImage = styled.div`
  content: '';
  display: table;
  clear: both;
  display: flex;
  align-items: center;
`;

const RootDiv = styled.div`
  display: flex;
  @media ${MOBILE_RESOLUTION} {
    flex-direction: column;
  }
  justify-content: space-between;
`;

const Row = styled.div<{ justify?: string; align?: string }>`
  display: inline-flex;
  gap: 10px;
  justify-content: ${({ justify }) => (!justify ? 'space-between' : justify)};
  align-items: ${({ align }) => (!align ? 'center' : align)};
  ${rtl`
  margin-left: 5px;
      `}
`;

const Col = styled.div<{
  text?: string;
  weight?: string;
  size?: string;
  color?: string;
}>`
  font-family: ${({ theme }) => (theme.dir === 'rtl' ? 'Assistant' : 'Roboto')};
  font-style: normal;
  font-weight: ${({ weight }) => (!weight ? 'normal' : weight)};
  font-size: ${({ size }) => (!size ? '18px' : size)};
  line-height: 43px;
  text-transform: ${({ text }) => text};
  color: ${({ color }) => (!color ? '#000000' : color)};

  && .discount-code {
    color: red !important;
  }
`;

const StyledHr = styled.hr.attrs({ className: 'card-divider' })`
  margin: 30px 0 30px;
  border: 1px solid #979797;

  @media ${MOBILE_TABLET_RESOLUTION} {
    margin: 16px 0;
  }
`;

const CheckoutButton = styled(Button)`
  background: #ff5241;
  border-radius: 11px;
  .MuiButton-label {
    padding: 1px 26px 1px 26px;

    @media ${MOBILE_TABLET_RESOLUTION} {
      padding: 32px;
    }
  }
`;

const AddressContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
  flex: 0.5;
`;

const AddressDetail = styled.div`
  margin: 10px 0 15px 15px;
`;

const ShippingOptionContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
  flex: 0.5;
`;

const ShippingOptionDetail = styled.div`
  margin: 15px;
`;

const TextInputField = styled(TextInput)`
  margin-top: 16px;
`;

const PhoneNumber = styled(PhoneField)``;

const MobileErrorMessage = styled.p`
  font-size: 14px;
  line-height: 18px;
  color: #3e3e3e;
  ${rtl`
    margin-left: 4px !important;
  `}
`;

const MobileErrorParent = styled.div`
  display: flex;
  ${rtl`
    margin-left: 5px;
  `}

  height: 36px;
  width: 295px;
`;
