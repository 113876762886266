export type Option = Record<string, string | number> | null;
export type Value = { name: string; value: string; id: string };
export type Country = { name: string; iso: string };
export const popperModifiers = [
  {
    name: 'flip',
    enabled: false,
    options: {
      altBoundary: false,
      rootBoundary: 'document',
      padding: 8,
    },
  },
  {
    name: 'preventOverflow',
    enabled: false,
    options: {
      altAxis: false,
      altBoundary: false,
      tether: false,
      rootBoundary: 'document',
      padding: 8,
    },
  },
];
