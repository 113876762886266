import * as React from 'react';
import useFetch from 'hooks/useFetch';

type SubCategory = {
  id: number;
  name: string;
  category: { id: number; name: string };
};

type SubCategoryOption = { id: number; name: string; group: string };

const useSubCategories = () => {
  const [subCategoryOptions, setSubCategoryOptions] = React.useState<
    SubCategoryOption[]
  >([]);
  const onSuccess = React.useCallback((subcategories: SubCategory[] | null) => {
    setSubCategoryOptions(
      subcategories?.map(({ category, name, id }) => ({
        id,
        name,
        group: category.name,
      })) ?? []
    );
  }, []);

  const { isLoading, hasError, callFetch: fetchSubCategories } = useFetch<
    SubCategory[]
  >({
    initialUrl: '/api/sub_categories/',
    config: { useCache: true },
    onSuccess,
  });

  return { subCategoryOptions, isLoading, hasError, fetchSubCategories };
};

export default useSubCategories;
