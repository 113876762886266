import * as React from 'react';
import styled from 'styled-components';
import Modal, { ModalProps } from 'components/@client/Modal';
import type { CartItem } from 'types/properties';
import { FormattedMessage, useIntl } from 'react-intl';
import { Message } from 'i18n';
import { LogsAPI } from 'api/methods';
import { userLogData } from 'mockData/userLogData';

interface Props extends ModalProps {
  deleteCandidate: CartItem | null;
  onDelete: (id?: string) => void;
}

const DeleteItemModal: React.FC<Props> = ({
  open,
  onClose,
  onDelete,
  deleteCandidate,
}) => {
  const intl = useIntl();
  const handleClose = React.useCallback(() => onClose?.({}, 'backdropClick'), [
    onClose,
  ]);

  const onClick = React.useCallback(() => {
    onDelete(deleteCandidate?.id);
    handleClose();
  }, [deleteCandidate?.id, handleClose, onDelete]);

  return (
    <Container
      open={open}
      onClose={handleClose}
      title={intl.formatMessage({
        id: Message.CART_DELETE_CONFIRMATION_MODAL_TITLE,
      })}
      actions={[
        {
          label: intl.formatMessage({ id: Message.CANCEL }),
          variant: 'secondary',
          onClick: () => {
            LogsAPI.postUserLogs({
              name: userLogData.eventName.deleteProductCancel,
              area: userLogData.eventArea.myCart,
              section: userLogData.eventSection.cart,
              path: userLogData.eventPath.appCartCartIdPath,
              metadata: {
                cart_id: deleteCandidate?.id,
                product_sku: deleteCandidate?.product.sku,
              },
            });
            handleClose();
          },
        },
        {
          label: intl.formatMessage({
            id: Message.CART_DELETE_CONFIRMATION_MODAL_OK,
          }),
          variant: 'primary',
          onClick: () => {
            LogsAPI.postUserLogs({
              name: userLogData.eventName.deleteProductConfirm,
              area: userLogData.eventArea.myCart,
              section: userLogData.eventSection.cart,
              path: userLogData.eventPath.appCartCartIdPath,
              metadata: {
                cart_id: deleteCandidate?.id,
                product_sku: deleteCandidate?.product.sku,
              },
            });
            onClick();
          },
        },
      ]}
    >
      <FormattedMessage id={Message.CART_DELETE_CONFIRMATION_MODAL_CAPTION} />
    </Container>
  );
};

export default DeleteItemModal;

const Container = styled(Modal)`
  &&.client-dialog .MuiDialog-paper .MuiDialogTitle-root {
    margin: 10px auto;
    padding: 10px 24px;
    font-size: 1.25rem;
    h2 {
      font-weight: 700;
    }
  }

  & .MuiDialogContent-root {
    padding: 8px 50px;
    margin-bottom: 35px;
    color: rgba(21, 25, 32, 0.5);
  }
`;
