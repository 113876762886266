import livingRoom1 from './living-room1.jpg';
import livingRoom2 from './living-room2.jpg';
import livingRoom3 from './living-room3.jpg';
import livingRoom4 from './living-room4.jpg';
import livingRoom5 from './living-room5.jpg';

export default {
  livingRoom1,
  livingRoom2,
  livingRoom3,
  livingRoom4,
  livingRoom5,
};
