import * as React from 'react';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { useSelect } from 'hooks';
import Select from 'components/Select';
import { Arrow, CustomArrow } from 'components/Button/ArrowButton';
import { ControlProps } from './types';

interface Props extends ControlProps<string[]> {
  disableSelectAll?: boolean;
  selectedPlaceholder?: string;
  disabled?: boolean;
  width?: number;
  customIcon?: boolean;
  overrideDeselectOnReselect?: boolean;
}

const SelectFilter: React.FC<Props> = ({
  disableSelectAll,
  onApply,
  options,
  placeholder,
  selectedPlaceholder,
  multiple = true,
  initialState = [],
  disabled = false,
  width,
  customIcon,
  overrideDeselectOnReselect,
}) => {
  const [selectedItems, setSelected] = React.useState<string[]>([
    ...initialState,
  ]);
  const selectFilterProps = useSelect<string>({
    options,
    selectedItems,
    setSelected,
    multiple: multiple || undefined,
    onApply: onApply as (selection: string | string[]) => void,
    overrideDeselectOnReselect,
  });

  return (
    <Select
      options={options}
      selectedItems={selectedItems}
      placeholder={placeholder}
      selectedPlaceholder={selectedPlaceholder}
      multiple={multiple || undefined}
      disabled={disabled}
      width={width}
      disableSelectAll={disableSelectAll}
      endIcon={
        customIcon ? (
          <CustomArrowIcon open={selectFilterProps.isOpen} fontSize="inherit" />
        ) : (
          <DefaultArrow open={selectFilterProps.isOpen} fontSize="inherit" />
        )
      }
      {...selectFilterProps}
    />
  );
};

export default SelectFilter;

const CustomArrowIcon = styled(Arrow)`
  position: absolute;
  ${rtl`
    right: 0;
  `}
`;

const DefaultArrow = styled(CustomArrow)`
  position: absolute;
  ${rtl`
  right: 0;
`}
`;
