import * as React from 'react';
import styled from 'styled-components';

const Counter: React.FC<{ count: number }> = ({ count }) => {
  if (!count) return null;
  return <Container>{count}</Container>;
};

export default Counter;

const Container = styled.div`
  height: 23px;
  min-width: 23px;
  color: ${({ theme }) => theme.colors.primary.dark};
  border: 1px solid ${({ theme }) => theme.colors.primary.dark};
  border-radius: 4px;
  font-size: 0.875rem;
  ${({ theme }) => theme.utils.centerFlex};
`;
