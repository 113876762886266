import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import Button from 'components/@client/Button';
import { FormProvider, useWatch } from 'react-hook-form';
import styled from 'styled-components';
import { Checkbox, ComboBox, Field } from 'components/forms';
import FlagIcon from 'components/FlagIcon';
import { Message } from 'i18n';
import { countryOptions, CountryRecord, ISOCode } from 'data/countries';
import { BasicOption, CountryOption } from 'components/Filters/Options';
import { summarizeText } from 'utils/string.utils';
import { initialAddressValues } from 'pages/appManagement/common/Addresses';
import useUpdateAddress from 'pages/common/hooks/useUpdateAddress';
import { IAddress, Setter } from 'types/common.types';
import { Option } from 'components/Autocomplete/Autocomplete.config';
import useAddressForm from 'pages/common/hooks/useAddressForm';
import useAddressesList from 'pages/common/hooks/useAddressesList';
import { stateOptions } from 'data/states';
import CityFilter from 'components/forms/CitiesInput';

interface Props {
  userId?: number;
  onAddressesChange?: (addresses: IAddress[] | null) => void;
  updateGuestAddress?: (isValid: boolean, guestAddress: IAddress) => void;
}

const AddressForm: React.FC<Props> = ({
  userId,
  onAddressesChange,
  updateGuestAddress,
}) => {
  const intl = useIntl();
  const [openAddNewAddress, setOpenAddNewAddress] = React.useState<boolean>(
    false
  );
  const [selectAddress, setSelectedAddress] = React.useState<{
    label: string | number | undefined;
    value: string | number | undefined;
  } | null>(null);

  const {
    addresses: userAddresses,
    fetchAddresses: callUpdateList,
  } = useAddressesList(userId, [], false, onAddressesChange);

  const addAddressSuccessfulCallback = React.useCallback(() => {
    callUpdateList();
  }, [callUpdateList]);

  const { methods: addressMethods, saveForm, updateAddress } = useAddressForm({
    userId: userId?.toString(),
    onAddressAddCallback: addAddressSuccessfulCallback,
  });

  const onAddressSubmit = React.useCallback(
    (payload: IAddress) => {
      saveForm({ ...payload, isPrimary: true });
      setOpenAddNewAddress(false);
    },
    [saveForm, setOpenAddNewAddress]
  );
  const { control, handleSubmit, formState } = addressMethods;
  const entries = useWatch({ control });
  const { isValid, isDirty } = formState;
  const addNewOptions = {
    label: intl.formatMessage({
      id: Message.CHECKOUT_ADD_NEW_ADDRESS_TEXT,
    }),
    value: 'ADD_NEW_ADDRESS',
  };

  React.useEffect(() => {
    const addressValues = addressMethods.getValues();
    updateGuestAddress?.(isValid, addressValues);
  }, [addressMethods, isValid, updateGuestAddress]);
  const {
    country,
    setCountry,
    state,
    setState,
    isStateSelectDisabled,
    isSelectedIsrael,
    handlePaste,
    handleAlphaNumericField,
    handleNumericField,
  } = useUpdateAddress(entries as IAddress, initialAddressValues as IAddress);

  const addressOptionList =
    userAddresses?.map(e => ({
      label: !isSelectedIsrael
        ? `${e.address_line_1}, ${e.address_line_2} ${e.zipCode}-${e.city}, ${e.country}`
        : `${e.street}, ${e.apartmentNumber} ${e.zipCode}-${e.city}, ${e.country}`,
      value: e.id,
    })) ?? [];
  const addressOptions = [...addressOptionList, addNewOptions];

  const onAddressesUpdate = React.useCallback(
    (event, newValue) => {
      setOpenAddNewAddress(newValue?.value === 'ADD_NEW_ADDRESS');
      const selectedAddress = userAddresses?.find(
        e => e.id === newValue?.value
      );
      if (selectedAddress)
        updateAddress({
          ...selectedAddress,
          isPrimary: true,
        });
    },
    [userAddresses, setOpenAddNewAddress, updateAddress]
  );

  React.useEffect(() => {
    const selectedAddr = userAddresses
      ?.filter(e => e.isPrimary)
      .map(e => ({
        label: !isSelectedIsrael
          ? `${e.address_line_1}, ${e.address_line_2} ${e.zipCode}-${e.city}, ${e.country}`
          : `${e.street}, ${e.apartmentNumber} ${e.zipCode}-${e.city}, ${e.country}`,
        value: e.id,
      }))[0];
    setSelectedAddress(
      openAddNewAddress
        ? {
            label: intl.formatMessage({
              id: Message.CHECKOUT_ADD_NEW_ADDRESS_TEXT,
            }),
            value: 'ADD_NEW_ADDRESS',
          }
        : selectedAddr ?? null
    );
  }, [
    userAddresses,
    isSelectedIsrael,
    setSelectedAddress,
    openAddNewAddress,
    intl,
  ]);

  return (
    <>
      <AutocompleteComponent
        classes={{ paper: 'paper-class' }}
        value={selectAddress}
        onChange={onAddressesUpdate}
        disablePortal
        options={addressOptions}
        renderInput={params => <TextField {...params} />}
      />
      {(openAddNewAddress || userAddresses?.length === 0) && (
        <FormProvider {...addressMethods}>
          <Form onSubmit={handleSubmit(onAddressSubmit)}>
            <Row>
              <Col size={2}>
                <ComboBox
                  className="combobox country"
                  width="100%"
                  label={{
                    title: (
                      <FormattedMessage id={Message.ADDRESS_FORM_COUNTRY} />
                    ),
                  }}
                  name="country"
                  options={countryOptions}
                  searchPlaceholder={intl.formatMessage({
                    id: Message.COUNTRY_SEARCH_PLACEHOLDER,
                  })}
                  renderOption={CountryOption}
                  placeholder={intl.formatMessage({
                    id: Message.ADDRESS_FORM_SELECT_COUNTRY,
                  })}
                  required
                  selectedValue={country}
                  setSelectedValue={setCountry as Setter<Option | null>}
                  setterKey="iso"
                  optionTextLimit={23}
                  renderValue={option =>
                    option ? (
                      <FlagIcon
                        ISO={option.iso as ISOCode}
                        name={summarizeText(option.name.toString(), 20)}
                      />
                    ) : null
                  }
                />
              </Col>
              <Col size={2}>
                <ComboBox
                  className="combobox state"
                  width="100%"
                  disabled={isStateSelectDisabled}
                  label={{
                    title: 'State',
                    parenthesesContent: 'Only for US',
                  }}
                  name="state"
                  options={stateOptions}
                  searchPlaceholder="Search for state..."
                  renderOption={BasicOption}
                  placeholder="Select state"
                  required
                  selectedValue={state}
                  setSelectedValue={setState}
                  setterKey="iso"
                  optionTextLimit={23}
                />
              </Col>
            </Row>
            <Row>
              <Col size={2}>
                <CityFilter
                  name="city"
                  label={{
                    title: <FormattedMessage id={Message.ADDRESS_FORM_CITY} />,
                  }}
                  placeholder={intl.formatMessage({
                    id: Message.ADDRESS_FORM_SELECT_CITY,
                  })}
                  required
                  countryISO={country as CountryRecord}
                />
              </Col>
              <Col size={2}>
                {isSelectedIsrael ? (
                  <Field
                    width="100%"
                    className="text-input"
                    label={{
                      title: (
                        <FormattedMessage
                          id={Message.ADDRESS_FORM_STREET_ADDRESS}
                        />
                      ),
                    }}
                    name="street"
                    type="text"
                    required
                  />
                ) : (
                  <Field
                    width="100%"
                    className="text-input"
                    label={{
                      title: (
                        <FormattedMessage
                          id={Message.ADDRESS_FORM_ADDRESS_LINE1}
                        />
                      ),
                    }}
                    name="address_line_1"
                    type="text"
                    required
                  />
                )}
              </Col>
            </Row>
            <Row>
              {isSelectedIsrael ? (
                <>
                  <Col size={2}>
                    <Field
                      width="100%"
                      className="text-input"
                      onPaste={handlePaste}
                      onKeyPress={handleAlphaNumericField}
                      label={{
                        title: (
                          <FormattedMessage
                            id={Message.ADDRESS_FORM_HOUSE_NO}
                          />
                        ),
                      }}
                      name="house"
                      type="text"
                      required
                    />
                  </Col>
                  <Col size={2}>
                    <Field
                      width="100%"
                      className="text-input"
                      onPaste={handlePaste}
                      onKeyPress={handleAlphaNumericField}
                      label={{
                        title: (
                          <FormattedMessage
                            id={Message.ADDRESS_FORM_ENTRANCE}
                          />
                        ),
                      }}
                      name="apartmentNumber"
                      type="text"
                    />
                  </Col>
                </>
              ) : (
                <>
                  <Col size={2}>
                    <Field
                      width="100%"
                      className="text-input"
                      label={{
                        title: (
                          <FormattedMessage
                            id={Message.ADDRESS_FORM_ADDRESS_LINE2}
                          />
                        ),
                      }}
                      name="address_line_2"
                      type="text"
                    />
                  </Col>

                  <Col size={2}>
                    <Field
                      width="100%"
                      onKeyPress={handleNumericField}
                      label={{
                        title: (
                          <FormattedMessage
                            id={Message.ADDRESS_FORM_POSTCODE}
                          />
                        ),
                      }}
                      name="zipCode"
                      type="text"
                      className="zipCode-field text-input"
                      required
                    />
                  </Col>
                </>
              )}
            </Row>
            <Row>
              {isSelectedIsrael ? (
                <>
                  <Col size={2}>
                    <Field
                      width="100%"
                      className="text-input"
                      onPaste={handlePaste}
                      onKeyPress={handleNumericField}
                      label={{
                        title: (
                          <FormattedMessage id={Message.ADDRESS_FORM_FLOOR} />
                        ),
                      }}
                      name="floor"
                      type="text"
                    />
                  </Col>
                  <Col size={2}>
                    <Field
                      width="100%"
                      onKeyPress={handleNumericField}
                      label={{
                        title: (
                          <FormattedMessage
                            id={Message.ADDRESS_FORM_POSTCODE}
                          />
                        ),
                      }}
                      name="zipCode"
                      type="text"
                      className="zipCode-field text-input"
                      required
                    />
                  </Col>
                </>
              ) : (
                <>
                  <Col size={2}>
                    <Checkbox
                      name="elevator"
                      label={
                        <FormattedMessage
                          id={Message.ADDRESS_FORM_CHECKBOX_ELEVATOR}
                        />
                      }
                    />
                  </Col>
                  <Col size={2}>
                    {userId && (
                      <CheckoutButton
                        type="submit"
                        className="checkout-button"
                        disabled={!isValid || !isDirty}
                      >
                        <FormattedMessage
                          id={Message.CHECKOUT_ADD_NEW_ADDRESS_BUTTON}
                        />
                      </CheckoutButton>
                    )}
                  </Col>
                </>
              )}
            </Row>
            {isSelectedIsrael && (
              <>
                <Row>
                  <Col size={1}>
                    <Checkbox
                      name="elevator"
                      label={
                        <FormattedMessage
                          id={Message.ADDRESS_FORM_CHECKBOX_ELEVATOR}
                        />
                      }
                    />
                  </Col>
                  <Col size={1}>
                    {userId && (
                      <CheckoutButton
                        type="submit"
                        className="checkout-button"
                        disabled={!isValid || !isDirty}
                      >
                        <FormattedMessage
                          id={Message.CHECKOUT_ADD_NEW_ADDRESS_BUTTON}
                        />
                      </CheckoutButton>
                    )}
                  </Col>
                </Row>
              </>
            )}
          </Form>
        </FormProvider>
      )}
    </>
  );
};

export default AddressForm;

const Form = styled.form`
  margin-top: 15px;
`;

const Row = styled.div`
  display: flex;
  @media ${MOBILE_TABLET_RESOLUTION} {
    display: block;
  }
`;

const Col = styled.div<{
  size: string | number;
}>`
  flex: ${props => props.size};
  margin: 10px;
  @media ${MOBILE_TABLET_RESOLUTION} {
    margin: 10px auto;
  }
`;

const CheckoutButton = styled(Button)`
  background: #ff5241;
  border-radius: 10px;
  width: 100%;
`;

const AutocompleteComponent = styled(Autocomplete)`
  .MuiInputBase-root {
    height: 48px;
    padding-left: 60px;
    font-family: ${({ theme }) =>
      theme.dir === 'rtl' ? 'Assistant' : 'Roboto'};

    background: #ffffff;
    border: 1px solid #ff9287;
    box-sizing: border-box;
    border-radius: 10px;

    .MuiAutocomplete-input {
      padding: 3px !important;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
      opacity: 0.9;
    }
  }
`;
