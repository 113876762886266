import * as React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import styled, { css } from 'styled-components';
import rtl from 'styled-components-rtl';
import { FormProvider, useWatch } from 'react-hook-form';
import * as Response from 'api/responses';
import { AddressResponse } from 'api/transform';
import Breadcrumbs from 'components/@client/Breadcrumbs';
import { FormattedMessage, useIntl } from 'react-intl';
import { Message } from 'i18n';
import Title from 'components/@client/TopTitle';
import { Checkbox, ComboBox, Field } from 'components/forms';
import { Option } from 'components/Autocomplete/Autocomplete.config';
import { BasicOption, CountryOption } from 'components/Filters/Options';
import FlagIcon from 'components/FlagIcon';
import CityFilter from 'components/forms/CitiesInput';
import TextButton from 'components/Button/TextButton';
import Actions from 'components/Modal/ModalActions';
import ConfirmModal from 'components/confirmModal';
import { countryOptions, CountryRecord, ISOCode } from 'data/countries';
import { stateOptions } from 'data/states';
import { useAuthentication, useFetch, useRouter } from 'hooks';
import { initialAddressValues } from 'pages/appManagement/common/Addresses';
import useAddressForm from 'pages/common/hooks/useAddressForm';
import useUpdateAddress from 'pages/common/hooks/useUpdateAddress';
import { ClientRouter } from 'router/routes';
import { ClientRoot, MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';
import { isEmpty } from 'utils/lodash.utils';
import { getRoute } from 'utils/sidebar.utils';
import { summarizeText } from 'utils/string.utils';
import type { Setter, IAddress } from 'types/common.types';
import ConfirmChangesModal from '../ConfirmChangesModal';
import InformationBlock from './InformationBlock';

interface Props {
  isModal?: boolean;
  addAddressSuccessfulCallback?: VoidFunction;
}

const AddressForm: React.FC<Props> = ({
  isModal,
  addAddressSuccessfulCallback,
}) => {
  const isMobile = useMediaQuery(MOBILE_TABLET_RESOLUTION);
  const [deleteAddressIsOpen, toggleModal] = React.useState<boolean>(false);
  const {
    query: { id, backTo },
    history,
  } = useRouter<{ id: string; backTo?: string }>();
  const intl = useIntl();
  const editMode = Boolean(id);
  const link = editMode
    ? {
        to: getRoute([ClientRouter.PROFILE_ADDRESSES, `/${id}`]),
        children: <FormattedMessage id={Message.ADDRESS_FORM_EDIT} />,
      }
    : {
        to: ClientRouter.PROFILE_ADDRESSES,
        children: (
          <FormattedMessage id={Message.ADDRESS_FORM_ADD_NEW_ADDRESS} />
        ),
      };
  const links = [
    backTo
      ? {
          to: backTo,
          children: <FormattedMessage id={Message.CHECKOUT_TITLE} />,
        }
      : {
          to: ClientRouter.PROFILE,
          children: <FormattedMessage id={Message.MENU_ITEM_PROFILE} />,
        },
    link,
  ];
  const { data: defaultValues } = useFetch<IAddress, Response.Address>({
    initialUrl: `/api/addresses/${id}/`,
    skipOnStart: !id,
    transform: AddressResponse,
    initialData: editMode
      ? { ...initialAddressValues, id }
      : initialAddressValues,
  });

  const { id: userId } = useAuthentication();

  const onDeleteSuccess = React.useCallback(() => {
    if (isModal && addAddressSuccessfulCallback) {
      addAddressSuccessfulCallback();
    } else {
      history.push(backTo ?? ClientRouter.PROFILE);
    }
  }, [backTo, history, isModal, addAddressSuccessfulCallback]);

  const {
    isLoading,
    deleteIsLoading,
    methods,
    saveForm,
    deleteAddress,
    successNotification,
  } = useAddressForm({
    userId: userId?.toString(),
    defaultValues: defaultValues as IAddress,
    onDeleteSuccess,
    closeForm: onDeleteSuccess,
  });
  const {
    handleSubmit,
    control,
    formState,
    setValue,
    watch,
    register,
  } = methods;
  const entries = useWatch({ control });
  const { isValid, isDirty, isSubmitSuccessful } = formState;

  const {
    country,
    setCountry,
    state,
    setState,
    isStateSelectDisabled,
    isSelectedIsrael,
    handlePaste,
    handleAlphaNumericField,
    handleNumericField,
  } = useUpdateAddress(entries as IAddress, defaultValues as IAddress);

  const isPrimary = watch('isPrimary');
  const isPrimaryDisabled = Boolean(defaultValues?.isPrimary);
  const setAsPrimary = React.useCallback(() => {
    setValue('isPrimary', !isPrimary, { shouldDirty: true });
  }, [setValue, isPrimary]);
  const formActions = React.useMemo(
    () =>
      !editMode
        ? [
            {
              isLoading,
              label: intl.formatMessage({
                id: Message.SAVE_TITLE,
              }),
              className: 'save-btn form-btn',
              action: handleSubmit(saveForm),
              disabled: !isValid || !isDirty || isLoading,
            },
          ]
        : [
            {
              label: intl.formatMessage({
                id: Message.ADDRESS_FORM_DELETE_ADDRESS,
              }),
              className: 'delete-btn form-btn',
              secondary: true,
              variant: 'outlined' as const,
              action: () => toggleModal(true),
              disabled: isLoading,
              isLoading: deleteIsLoading,
            },
            {
              isLoading,
              label: intl.formatMessage({
                id: Message.SAVE_TITLE,
              }),
              className: 'save-btn form-btn',
              action: handleSubmit(saveForm),
              disabled: !isValid || !isDirty || isLoading,
            },
          ],
    [
      editMode,
      isLoading,
      handleSubmit,
      saveForm,
      isValid,
      isDirty,
      deleteIsLoading,
      intl,
    ]
  );

  return (
    <Root className="client-edit-root" isModal={isModal}>
      {!isModal && <Breadcrumbs links={links} />}
      <ContentWrapper>
        <Header>
          <Title
            title={
              id ? (
                <FormattedMessage id={Message.ADDRESS_FORM_EDIT} />
              ) : (
                <FormattedMessage id={Message.ADDRESS_FORM_ADD_NEW_ADDRESS} />
              )
            }
          />
        </Header>
        <FormProvider {...methods}>
          <FormWrapper isModal={isModal}>
            <Form onSubmit={handleSubmit(saveForm)} className="address-form">
              <ComboBox
                className="combobox country"
                width="100%"
                label={{
                  title: <FormattedMessage id={Message.ADDRESS_FORM_COUNTRY} />,
                }}
                name="country"
                options={countryOptions}
                searchPlaceholder={intl.formatMessage({
                  id: Message.COUNTRY_SEARCH_PLACEHOLDER,
                })}
                renderOption={CountryOption}
                placeholder={intl.formatMessage({
                  id: Message.ADDRESS_FORM_SELECT_COUNTRY,
                })}
                required
                selectedValue={country}
                setSelectedValue={setCountry as Setter<Option | null>}
                setterKey="iso"
                optionTextLimit={23}
                renderValue={option =>
                  option ? (
                    <FlagIcon
                      ISO={option.iso as ISOCode}
                      name={summarizeText(option.name.toString(), 20)}
                    />
                  ) : null
                }
              />
              <ComboBox
                className="combobox state"
                width="100%"
                disabled={isStateSelectDisabled}
                label={{ title: 'State', parenthesesContent: 'Only for US' }}
                name="state"
                options={stateOptions}
                searchPlaceholder="Search for state..."
                renderOption={BasicOption}
                placeholder="Select state"
                required
                selectedValue={state}
                setSelectedValue={setState}
                setterKey="iso"
                optionTextLimit={23}
              />
              <CityFilter
                name="city"
                label={{
                  title: <FormattedMessage id={Message.ADDRESS_FORM_CITY} />,
                }}
                placeholder={intl.formatMessage({
                  id: Message.ADDRESS_FORM_SELECT_CITY,
                })}
                required
                countryISO={country as CountryRecord}
              />
              {!isSelectedIsrael ? (
                <>
                  <Field
                    label={{
                      title: (
                        <FormattedMessage
                          id={Message.ADDRESS_FORM_ADDRESS_LINE1}
                        />
                      ),
                    }}
                    name="address_line_1"
                    type="text"
                    required
                  />
                  <Field
                    label={{
                      title: (
                        <FormattedMessage
                          id={Message.ADDRESS_FORM_ADDRESS_LINE2}
                        />
                      ),
                    }}
                    name="address_line_2"
                    type="text"
                  />
                </>
              ) : (
                <>
                  <Field
                    label={{
                      title: (
                        <FormattedMessage
                          id={Message.ADDRESS_FORM_STREET_ADDRESS}
                        />
                      ),
                    }}
                    name="street"
                    type="text"
                    required
                  />
                </>
              )}
              {isSelectedIsrael && (
                <>
                  <Field
                    onPaste={handlePaste}
                    onKeyPress={handleAlphaNumericField}
                    label={{
                      title: (
                        <FormattedMessage id={Message.ADDRESS_FORM_HOUSE_NO} />
                      ),
                    }}
                    name="house"
                    type="text"
                    required
                  />
                  <Field
                    onPaste={handlePaste}
                    onKeyPress={handleAlphaNumericField}
                    label={{
                      title: (
                        <FormattedMessage id={Message.ADDRESS_FORM_ENTRANCE} />
                      ),
                    }}
                    name="apartmentNumber"
                    type="text"
                    required
                  />
                  <Field
                    onPaste={handlePaste}
                    onKeyPress={handleNumericField}
                    label={{
                      title: (
                        <FormattedMessage id={Message.ADDRESS_FORM_FLOOR} />
                      ),
                    }}
                    name="floor"
                    type="text"
                    required
                  />
                </>
              )}

              <Checkbox
                name="elevator"
                label={
                  <FormattedMessage
                    id={Message.ADDRESS_FORM_CHECKBOX_ELEVATOR}
                  />
                }
              />

              <Field
                onKeyPress={handleNumericField}
                label={{
                  title: (
                    <FormattedMessage id={Message.ADDRESS_FORM_POSTCODE} />
                  ),
                }}
                name="zipCode"
                type="text"
                className="zipCode-field"
                required
              />
              <span className="as-primary-field">
                <label className="checkbox" htmlFor="isPrimary">
                  <input
                    id="isPrimary"
                    hidden={true}
                    type="checkbox"
                    {...register({ name: 'isPrimary', type: 'custom' })}
                  />
                  <TextButton
                    $noDecoration
                    className="btn"
                    disabled={isPrimaryDisabled}
                    onClick={setAsPrimary}
                  >
                    {isPrimary ? (
                      <FormattedMessage
                        id={Message.ADDRESS_FORM_DEFAULT_ADDRESS}
                      />
                    ) : (
                      <FormattedMessage
                        id={Message.ADDRESS_FORM_DEFAULT_ADDRESS_NEW}
                      />
                    )}
                  </TextButton>
                </label>

                {isPrimary && <span>&#10004;</span>}
              </span>
              <Actions actions={formActions} />
            </Form>
            {!isMobile && !isModal && <InformationBlock editMode={editMode} />}
          </FormWrapper>
        </FormProvider>
      </ContentWrapper>
      <ConfirmModal
        isOpen={deleteAddressIsOpen}
        onClose={() => toggleModal(false)}
        title={
          <FormattedMessage
            id={Message.ADDRESS_FORM_BEFORE_LEAVE_DELETE_ADDRESS_TITLE}
          />
        }
        body={[
          intl.formatMessage({
            id: Message.ADDRESS_FORM_BEFORE_LEAVE_DELETE_ADDRESS_TEXT,
          }),
        ]}
        cancelLabel={intl.formatMessage({
          id: Message.CANCEL,
        })}
        onConfirm={{
          action: deleteAddress,
          label: intl.formatMessage({
            id: Message.ADDRESS_FORM_DELETE_ADDRESS,
          }),
          isLoading,
        }}
      />
      <ConfirmChangesModal
        action={handleSubmit(saveForm)}
        isLoading={isLoading}
        when={isDirty && isValid && !isSubmitSuccessful}
        shouldRedirect={isSubmitSuccessful && !isEmpty(successNotification)}
      />
    </Root>
  );
};

export default AddressForm;

const Root = styled(ClientRoot)<{ isModal?: boolean }>`
  .address-form {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1;
    margin: 0 10px;
    .combobox .MuiFormControl-root {
      margin: 0 0 16px;
    }
    .MuiFormControl-root {
      ${rtl`
        margin-left: 0;
      `}
      width: 100%;
    }
    .zipCode-field {
      margin-bottom: 24px;
    }
    .as-primary-field {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
      & .btn {
        color: #2277cc;
        font-weight: 500;
      }
    }
    .modal-dialog-actions {
      & .delete-btn .MuiButton-label {
        color: black;
      }

      .form-btn {
        font-size: 18px;
        flex: 0.3;
      }
      ${({ isModal }) =>
        isModal &&
        css`
          position: relative !important;
          .form-btn {
            flex: 1;
          }
        `}
      @media ${MOBILE_TABLET_RESOLUTION} {
        position: static;
        .form-btn {
          flex: 1;
        }
      }
    }
  }
`;

const ContentWrapper = styled.div.attrs({ className: 'content-wrapper' })`
  position: relative;
`;

const Header = styled.div`
  margin-bottom: 15px;
  @media ${MOBILE_TABLET_RESOLUTION} {
    margin-bottom: 20px;
  }
`;

const FormWrapper = styled.div.attrs({ className: 'form-wrapper' })<{
  isModal?: boolean;
}>`
  @media (min-width: 800px) {
    display: flex;
    justify-content: space-between;
  }
  ${({ isModal }) =>
    isModal &&
    css`
      display: block !important;
    `}
`;

const Form = styled.form`
  flex: 0.33;

  @media (min-width: 800px) {
    max-width: 561px;
  }
`;
