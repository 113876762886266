import { SupplierRouter } from 'router/routes';
import { getName, Tab } from 'utils/sidebar.utils';

type Key = 'grid' | 'chart' | 'file' | 'database' | 'users' | 'bundle';

const tabs = (route: string): Tab[] => [
  {
    label: 'Dashboard',
    to: SupplierRouter.DASHBOARD,
    icon: getName<Key>('grid', route.includes(SupplierRouter.DASHBOARD)),
    exact: false,
  },
  {
    label: 'Statistics',
    to: SupplierRouter.STATISTICS,
    icon: getName<Key>('chart', route.includes(SupplierRouter.STATISTICS)),
    exact: false,
  },
  {
    label: 'Products',
    to: SupplierRouter.MY_DATABASE,
    icon: getName<Key>('database', route.includes(SupplierRouter.MY_DATABASE)),
    exact: false,
  },
  {
    label: 'Orders',
    to: SupplierRouter.ORDERS,
    icon: getName<Key>('bundle', route.includes(SupplierRouter.ORDERS)),
    exact: false,
  },
  {
    label: 'Users',
    to: SupplierRouter.USERS,
    icon: getName<Key>('users', route.includes(SupplierRouter.USERS)),
    exact: false,
  },
];

export default tabs;
