import onboardingImageEllipse from 'assets/@client/onboarding/background-elipse.png';
import { ReactComponent as HrLine } from 'assets/icons/hrLine.svg';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from 'components/@client/Button/Button';
import { OnboardingContextProvider } from 'context/onboardingContext';
import { useOnboardingController, useRouter, useSession } from 'hooks';
import { Message } from 'i18n';
import * as Section from 'pages/appClient/onboarding/shared';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Route, Switch } from 'react-router-dom';
import { ClientRouter, IRouteConfig } from 'router/routes';
import styled, { css } from 'styled-components';
import rtl from 'styled-components-rtl';
import { ClientRoot, MOBILE_RESOLUTION } from 'styles/sharedStyle';
import Icon from 'components/Icon';
import DragHomeButton from './shared/DragHomeButton';

const Onboarding: React.FC<{ routes: IRouteConfig[] }> = ({ routes }) => {
  const { history, location } = useRouter();
  const onBoradingProps = useOnboardingController();
  const isMobile = useMediaQuery(MOBILE_RESOLUTION);
  const { locale } = useSession();
  return (
    <Root>
      {isMobile && location.pathname === ClientRouter.ONBOARDING && (
        <div className="mobile-header">
          <LeftIcon name="nav-arrow-right" onClick={() => history.go(-1)} />
          <div>
            <FormattedMessage id={Message.CREATE_HOME_BUTTON} />
          </div>
        </div>
      )}
      <OnboardingContextProvider>
        <Switch>
          {routes.map(props => (
            <Route key={props.path} {...props} {...onBoradingProps} />
          ))}
          {/* <Redirect
            from={ClientRouter.ONBOARDING}
            to={ClientRouter.UPLOAD_PLAN}
          /> */}
          <MainContainer className="upload-plan-container" withLogin>
            <CustomContainer>
              <div>
                <Title>
                  <FormattedMessage id={Message.CLIENT_AUTH_ONBOARDING_TITLE} />
                </Title>
                {locale === 'he' ? (
                  <HrLine
                    width={isMobile ? 150 : 240}
                    style={{ transform: 'rotateY(180deg)' }}
                  />
                ) : (
                  <HrLine width={isMobile ? 225 : 420} />
                )}
              </div>
              <Description>
                <FormattedMessage
                  id={
                    isMobile
                      ? Message.ONBOARDING_HOME_DESCRIPTION_MOBILE
                      : Message.ONBOARDING_HOME_DESCRIPTION
                  }
                  values={{
                    span: (chunks: string) => (
                      <span className="title-text">{chunks}</span>
                    ),
                    linebreak: <br />,
                  }}
                />
              </Description>
              <ImageContainer>
                <img src={onboardingImageEllipse} alt="background" />
              </ImageContainer>
              <ActionButton>
                <Button
                  // isLoading={isLoading}
                  className="go-button"
                  onClick={() => history.push(ClientRouter.UPLOAD_PLAN)}
                >
                  <FormattedMessage
                    id={Message.CLIENT_AUTH_ONBOARDING_GO_BUTTON}
                  />
                </Button>
              </ActionButton>
            </CustomContainer>
          </MainContainer>
        </Switch>
      </OnboardingContextProvider>
      {isMobile && <DragHomeButton />}
    </Root>
  );
};

export default Onboarding;

const LeftIcon = styled(Icon)`
  ${({ theme }) =>
    theme.dir === 'ltr' &&
    css`
      transform: rotateY(180deg);
    `}
`;

const Root = styled(ClientRoot).attrs({ className: 'register-root' })`
  padding: 0;
  margin: 32px 0;
  position: relative;
  @media ${MOBILE_RESOLUTION} {
    margin-top: 108px;
  }
  .mobile-header {
    position: absolute;
    top: 32px;
    @media ${MOBILE_RESOLUTION} {
      z-index: 1;
      top: 28px;
    }
    ${rtl`left: 24px;`}
    width: 100%;
    display: flex;
    svg {
      margin-top: 5px;
    }
    & > div {
      width: calc(100% - 24px - 32px);
      text-align: center;
      font-weight: 500;
      font-size: 22px;
    }
  }
`;
const MainContainer = styled(Section.Container)`
  margin-top: -120px;
`;
const CustomContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* padding: 0 340px; */
  text-align: center;
  @media (max-width: 767px) {
    padding: 48px 10px;
  }
`;
const Title = styled.h3`
  font-weight: 600;
  font-size: 40px;
  line-height: 47px;
  color: #11516d;
  margin: 72px auto 0;
  width: fit-content;
  @media ${MOBILE_RESOLUTION} {
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    margin: 48px auto 0;
  }
`;

const Description = styled.h3`
  margin: 17px 0 0 0;
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  > span {
    color: #fc5c42;
  }
  @media (max-width: 1440px) {
  }
  @media (max-width: 767px) {
    margin: 21px 0 0 0;
    font-weight: ${({ theme }) => (theme.dir === 'ltr' ? 500 : 400)};
    font-size: ${({ theme }) => (theme.dir === 'ltr' ? '16px' : '18px')};
    line-height: ${({ theme }) => (theme.dir === 'ltr' ? '19px' : '24px')};
  }
`;

const ImageContainer = styled.div`
  margin-top: 81px;
  width: 874px;
  height: 438px;

  > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 767px) {
    width: 338px;
    height: 203px;
    margin-top: 62px;
  }

  // background-image: url(${onboardingImageEllipse});
`;

const ActionButton = styled.div`
  margin-top: 46px;
  @media (max-width: 767px) {
    margin-top: 152px;
  }
  & .go-button {
    width: 193px;
    height: 48px;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    color: rgba(255, 255, 255, 0.9);
  }

  & .upload-icon {
    color: red;
  }
`;
