import * as React from 'react';
import styled, { css } from 'styled-components';
import rtl from 'styled-components-rtl';
import { useMediaQuery } from '@material-ui/core';
import { GenericCarouselImage, MOBILE_RESOLUTION } from 'styles/sharedStyle';
import { FormattedMessage, useIntl } from 'react-intl';
import { Message } from 'i18n';
import { useSession } from 'hooks';
import { Room } from 'types/clients';

interface Props {
  image: string;
  price?: string | React.ReactNode;
  userName?: string;
  name?: string;
  date?: string;
  chipsData?: {
    style: string;
    room: string;
    total_price?: number;
    color: string;
    room_id?: string;
  };
  onClick?: VoidFunction;
  roomsData?: Room[];
  skinDesignedByText?: string | React.ReactNode;
  skinCategoryName?: string | React.ReactNode;
  type?: string;
}

const PinterestIdeasCardMobile: React.FC<Props> = ({
  userName,
  image,
  price,
  name,
  date,
  chipsData,
  roomsData,
  onClick,
  skinDesignedByText = '',
  skinCategoryName = '',
  type,
}) => {
  const isMobile = useMediaQuery(MOBILE_RESOLUTION);
  const { currency } = useSession();
  const intl = useIntl();

  const finalPrice = React.useMemo(() => {
    if (chipsData?.total_price && chipsData?.total_price >= 100000) {
      return intl.formatNumber(100, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        style: 'currency',
        currency,
      });
      // eslint-disable-next-line no-else-return
    } else if (
      chipsData?.total_price &&
      chipsData?.total_price < 100000 &&
      chipsData?.total_price >= 50000
    ) {
      return intl.formatNumber(50, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        style: 'currency',
        currency,
      });
    } else if (
      chipsData?.total_price &&
      chipsData?.total_price < 50000 &&
      chipsData?.total_price >= 30000
    ) {
      return intl.formatNumber(30, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        style: 'currency',
        currency,
      });
    } else if (
      chipsData?.total_price &&
      chipsData?.total_price < 30000 &&
      chipsData?.total_price >= 20000
    ) {
      return intl.formatNumber(20, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        style: 'currency',
        currency,
      });
    } else if (
      chipsData?.total_price &&
      chipsData?.total_price < 20000 &&
      chipsData?.total_price >= 10000
    ) {
      return intl.formatNumber(10, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        style: 'currency',
        currency,
      });
    } else {
      return intl.formatNumber(1, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        style: 'currency',
        currency,
      });
    }
  }, [chipsData?.total_price, currency, intl]);

  const room = roomsData?.find(item => {
    return Number(item.id) === Number(chipsData?.room_id);
  });
  return (
    <>
      <CustomImage imgUrl={image}>
        <ImageContainer>
          <SkinContainer>
            {/* <DisplaySkinCategory>{skinCategoryName}</DisplaySkinCategory> */}
            {/* <DisplaySkinType>
              {room?.name ?? <FormattedMessage id={Message.SKIN_FULL_HOUSE} />}
            </DisplaySkinType> */}
          </SkinContainer>
          <div className="shop-now">
            <HoverButton
              className="center-button"
              onClick={onClick}
              buttonColor="#a50a0a"
            >
              <FormattedMessage id={Message.TAKE_A_LOOK} />
            </HoverButton>
          </div>

          {isMobile ? (
            <ChipsRow mobile={true}>
              {chipsData?.total_price && (
                <HoverButton
                  className="chip"
                  buttonColor="#49BF68"
                  width="auto"
                >
                  {finalPrice}k+
                </HoverButton>
              )}
              {room && room?.name && (
                <HoverButton
                  className="roomchip"
                  buttonColor="#4178E2"
                  width="auto"
                >
                  {room && room?.name}
                </HoverButton>
              )}
            </ChipsRow>
          ) : (
            <ChipsRow>
              {chipsData?.total_price && (
                <HoverButton
                  className="chip"
                  buttonColor="#49BF68"
                  width="auto"
                >
                  {finalPrice}k+
                </HoverButton>
              )}
              {room && room?.name && (
                <HoverButton
                  className="chip"
                  buttonColor="#4178E2"
                  width="auto"
                >
                  {room && room?.name}
                </HoverButton>
              )}
            </ChipsRow>
          )}
        </ImageContainer>
      </CustomImage>
      {/* <SkinDesignedBy>{skinDesignedByText}</SkinDesignedBy> */}
      {/* <CardContainer>
        <Title>{name}</Title>
        <Price>{price}</Price>
        <SubTitleContainer>
          {userName} {date && <>| {date}</>}
        </SubTitleContainer>
      </CardContainer> */}
    </>
  );
};
export default PinterestIdeasCardMobile;

const CustomImage = styled(GenericCarouselImage)`
  &:hover {
    .shop-now {
      opacity: 1;
    }
  }
  margin: 0;
  &:hover {
    border-radius: 20px 20px 0px 0px;
  }
  /* max-width: 327px; */
  height: 200px;
  background: url('${({ imgUrl }) => imgUrl}') rgba(255, 255, 255, 0.5) bottom
    center no-repeat;
  background-size: auto 100%;
  border-radius: 10px;
`;

const ChipsRow = styled.div<{ mobile?: boolean }>`
  bottom: 0;
  position: absolute;
  right: ${({ mobile }) => (mobile ? '0' : '')};
  display: flex;
  ${({ mobile }) =>
    mobile
      ? rtl` 
     margin: auto 14px 8px ;
  `
      : rtl` 
     margin: auto 14px 14px ;
  `}
`;

const HoverButton = styled.button<{
  buttonColor?: string;
  width?: string;
  height?: string;
}>`
  width: ${({ width }) => (!width ? '128px' : width)} !important;
  height: 36px;
  font-style: normal;
  font-family: inherit;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  text-transform: capitalize;
  ${rtl`
  margin-right: 8px;
  padding-right: 16px;
  padding-left: 16px;
  `}
  box-sizing: border-box;
  border-radius: 8px;

  @media ${MOBILE_RESOLUTION} {
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: #ffffff;
    padding: 6px 16px;
    margin-right: 0;
    :first-child {
      margin-right: 8px;
    }
  }

  ${({ buttonColor }) =>
    buttonColor &&
    css`
      background-color: ${buttonColor} !important;
      background: ${buttonColor} !important;
      border: 1px solid ${buttonColor};
    `}
`;

const ImageContainer = styled.div`
  .center-button {
    margin: auto;
    height: 48px !important;
    width: 134px !important;
    border: 0.5px solid #a50a0a;
    box-sizing: border-box;
    border-radius: 15px;
    font-family: ${({ theme }) =>
      theme.dir === 'rtl' ? 'Assistant' : 'Roboto'};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: rgba(255, 255, 255, 0.9);
    cursor: pointer;
  }
  .shop-now {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));

    top: 0;
    border-radius: 20px;
    z-index: 99;
    display: flex;
  }
`;

// const SkinDesignedBy = styled.div`
//   background: linear-gradient(270deg, #fc5c42 0%, rgba(252, 92, 66, 0.8) 100%);
//   padding: 10px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border-radius: 0px 0px 20px 20px;

//   font-family: ${({ theme }) => (theme.dir === 'rtl' ? 'Assistant' : 'Roboto')};
//   font-style: normal;
//   font-weight: 400;
//   font-size: 14px;
//   line-height: 16px;
//   display: flex;
//   align-items: center;
//   text-align: center;
//   color: rgba(255, 255, 255, 0.9);
// `;

// const DisplaySkinCategory = styled.span`
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 500;
//   font-size: 28px;
//   line-height: 33px;
//   display: flex;
//   align-items: center;
//   text-align: center;
//   letter-spacing: 0.15em;
//   color: #fc5c42;
//   text-transform: capitalize;
// `;

// const DisplaySkinType = styled.span`
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 400;
//   font-size: 22px;
//   line-height: 26px;
//   display: flex;
//   align-items: center;
//   text-align: center;
//   letter-spacing: 0.075em;
//   color: #282929;
// `;

const SkinContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 23px;
`;
