import { Message } from 'i18n';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Form from 'components/forms';
import { TextArea } from 'components/forms';
import styled from 'styled-components';
import FormControl from '@mui/material/FormControl';
import rtl from 'styled-components-rtl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useRouter } from 'hooks';
import DragHomeButton from 'pages/appClient/onboarding/shared/DragHomeButton';
import ScheduleMeetingPicker from 'pages/appClient/supplier-info/components/ScheduleMeetingPicker';
import {
  SupplierOnboardingAction,
  useSupplierOnboardingDispatch,
  useSupplierOnboardingState,
} from 'context/supplierOnboardingContext';
import { PopupModal, useCalendlyEventListener } from 'react-calendly';
import { Controller, FormProvider, UseFormMethods } from 'react-hook-form';
import { MandatoryFields } from '../supplier-info-store/SupplierInfoStoreFormMobile';
import SkipConfirmationDialog from '../components/SkipConfirmationDialog';

const SupplierAdditionalInfoMobile: React.FC<{
  onSubmit: VoidFunction;
  methods: UseFormMethods<{
    type: string;
    description: string;
    webpageLink: string;
    length: string | number;
    width: string | number;
    height: string | number;
    measurementUnits: string;
  }>;
}> = ({ onSubmit, methods }) => {
  const [isConfirmationModalVisible, setIsGoBackModalVisible] = React.useState(
    false
  );
  const [schedulePickerOpen, setSchedulePickerOpen] = React.useState(false);
  const [descriptionText, setDescriptionText] = React.useState('');
  const intl = useIntl();
  const dispatch = useSupplierOnboardingDispatch();
  const state = useSupplierOnboardingState();
  const { history } = useRouter();

  useCalendlyEventListener({
    onEventScheduled: e => {
      dispatch({
        type: SupplierOnboardingAction.SET_IS_SHEDULED_MEETING,
        payload: true,
      });
    },
  });

  const Types = [
    { id: 'material', name: 'Material' },
    { id: 'model', name: 'Model' },
    { id: 'internal_no_model', name: 'Internal' },
  ];
  const MeasurementUnits = ['mm', 'cm', 'm', 'in', 'ft'];

  return (
    <>
      <ParentDiv>
        <Header>
          <SkipText onClick={() => setIsGoBackModalVisible(true)}>
            <FormattedMessage id={Message.PROPERTY_ADD_PROPERTY_BUTTON_SKIP} />
          </SkipText>
          <UploadProductText>
            <FormattedMessage
              id={Message.SUPPLIER_UPLOAD_YOUR_FIRST_PRODUCT}
              values={{
                span: (chunks: string) => (
                  <span style={{ color: '#FC5C42' }}>{chunks}</span>
                ),
              }}
            />
          </UploadProductText>
          <MainDetailsText>
            <FormattedMessage id={Message.SUPPLIER_ADDITIONAL_INFO} />
          </MainDetailsText>
        </Header>
        <MainDetails>
          <FormProvider {...methods}>
            <FormWrapperContainer onSubmit={onSubmit}>
              <ScrollableDiv>
                <FormTextareaDiv
                  maxLength={100}
                  handleChange={e => setDescriptionText(e.target.value)}
                  label={{
                    title: (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div style={{ display: 'flex' }}>
                          <div
                            style={{
                              fontWeight: 600,
                              fontSize: 13,
                              lineHeight: '20px',
                              color: '#3E3E3E',
                            }}
                          >
                            {intl.formatMessage({
                              id: Message.SUPPLIER_DESCRIPTION_LABEL,
                            })}
                          </div>
                          &nbsp;
                          <div
                            style={{
                              color: '#696969',
                              fontSize: 13,
                              lineHeight: '20px',
                            }}
                          >
                            (
                            {intl.formatMessage({
                              id: Message.ADDRESS_FORM_OPTIONAL,
                            })}
                            )
                          </div>
                        </div>
                        <div
                          style={{
                            fontWeight: 300,
                            fontSize: 13,
                            lineHeight: '20px',
                          }}
                        >
                          {descriptionText?.length}/100
                        </div>
                      </div>
                    ),
                  }}
                  name="description"
                  placeholder={intl.formatMessage({
                    id: Message.SUPPLIER_DESCRIPTION_PLACEHOLDER,
                  })}
                />
                <FormFieldDiv
                  label={{
                    title: (
                      <div style={{ display: 'flex' }}>
                        <div
                          style={{
                            fontWeight: 600,
                            fontSize: 16,
                            lineHeight: '20px',
                            color: '#3E3E3E',
                          }}
                        >
                          {intl.formatMessage({
                            id: Message.SUPPLIER_WEBPAGE_LINK_LABEL,
                          })}
                        </div>
                        &nbsp;
                        <div
                          style={{
                            color: '#696969',
                            fontSize: 16,
                            lineHeight: '20px',
                            fontWeight: 400,
                          }}
                        >
                          (
                          {intl.formatMessage({
                            id: Message.ADDRESS_FORM_OPTIONAL,
                          })}
                          )
                        </div>
                      </div>
                    ),
                    isBold: true,
                  }}
                  name="webpageLink"
                  type="text"
                  placeholder={intl.formatMessage({
                    id: Message.SUPPLIER_WEBPAGE_LINK_PLACEHOLDER,
                  })}
                />
                <div style={{ marginTop: 8 }}>
                  <SelectLabel>
                    {intl.formatMessage({
                      id: Message.SUPPLIER_TYPES_LABEL,
                    })}
                  </SelectLabel>
                  <CustomFormControl>
                    <Controller
                      control={methods.control}
                      name="type"
                      render={({ onChange, value, name, ref }) => (
                        <Select
                          inputRef={ref}
                          // value={}
                          defaultValue={state.addProductDetails.type ?? ''}
                          onChange={val => onChange(val.target.value)}
                          displayEmpty
                          name="type"
                          inputProps={{ 'aria-label': 'Without label' }}
                        >
                          <MenuItem disabled value="">
                            {' '}
                            {intl.formatMessage({
                              id: Message.SUPPLIER_TYPES_PLACEHOLDER,
                            })}
                          </MenuItem>
                          {(Types ?? []).map(type => (
                            <MenuItem key={type.id} value={type.id}>
                              {' '}
                              {type.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </CustomFormControl>
                </div>
                <FormFieldDiv
                  label={{
                    title: intl.formatMessage({
                      id: Message.SUPPLIER_TOTAL_LENGTH_LABEL,
                    }),
                    isBold: true,
                  }}
                  name="length"
                  type="text"
                  placeholder={intl.formatMessage({
                    id: Message.SUPPLIER_TOTAL_LENGTH_PLACEHOLDER,
                  })}
                />
                <FormFieldDiv
                  label={{
                    title: intl.formatMessage({
                      id: Message.SUPPLIER_TOTAL_WIDTH_LABEL,
                    }),
                    isBold: true,
                  }}
                  name="width"
                  type="text"
                  placeholder={intl.formatMessage({
                    id: Message.SUPPLIER_TOTAL_WIDTH_PLACEHOLDER,
                  })}
                />
                <FormFieldDiv
                  label={{
                    title: intl.formatMessage({
                      id: Message.SUPPLIER_TOTAL_HEIGHT_LABEL,
                    }),
                    isBold: true,
                  }}
                  name="height"
                  type="text"
                  placeholder={intl.formatMessage({
                    id: Message.SUPPLIER_TOTAL_HEIGHT_PLACEHOLDER,
                  })}
                />
                <div style={{ marginTop: 8 }}>
                  <SelectLabel>
                    {intl.formatMessage({
                      id: Message.SUPPLIER_MEASUREMENT_UNITS_LABEL,
                    })}
                  </SelectLabel>
                  <CustomFormControl>
                    <Controller
                      control={methods.control}
                      name="measurementUnits"
                      render={({ onChange, value, name, ref }) => (
                        <Select
                          // value={}
                          defaultValue={
                            state.addProductDetails.measurementUnits ?? ''
                          }
                          inputRef={ref}
                          onChange={val => onChange(val.target.value)}
                          displayEmpty
                          name={name}
                          inputProps={{ 'aria-label': 'Without label' }}
                        >
                          <MenuItem disabled value="">
                            {' '}
                            {intl.formatMessage({
                              id:
                                Message.SUPPLIER_MEASUREMENT_UNITS_PLACEHOLDER,
                            })}
                          </MenuItem>
                          {(MeasurementUnits ?? []).map(unitName => (
                            <MenuItem key={unitName} value={unitName}>
                              {unitName}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </CustomFormControl>
                </div>
                <MandatoryFields>
                  <FormattedMessage id={Message.SUPPLIER_MANDATORY_FIELDS} />
                </MandatoryFields>
              </ScrollableDiv>
              <FooterParent>
                <BackButtonParent>
                  <BackButton onClick={() => history.goBack()}>
                    <BackButtonText>
                      <FormattedMessage
                        id={Message.PROPERTY_ADD_PROPERTY_BUTTON_PREVIOUS}
                      />
                    </BackButtonText>
                  </BackButton>
                </BackButtonParent>
                <NextButtonParent>
                  <NextButton disabled={!methods.formState.isValid}>
                    <NextButtonText>
                      <FormattedMessage
                        id={Message.PROPERTY_ADD_PROPERTY_BUTTON_NEXT}
                      />
                    </NextButtonText>
                  </NextButton>
                </NextButtonParent>
              </FooterParent>
            </FormWrapperContainer>
          </FormProvider>
        </MainDetails>
      </ParentDiv>
      <DragHomeButton />
      <ScheduleMeetingPicker onClick={() => setSchedulePickerOpen(true)} />
      <PopupModal
        open={schedulePickerOpen}
        url="https://calendly.com/dan1707"
        rootElement={document.getElementById('root')!}
        onModalClose={() => {
          setSchedulePickerOpen(false);
        }}
      />
      <SkipConfirmationDialog
        isOpen={isConfirmationModalVisible}
        toggle={() => setIsGoBackModalVisible(prev => !prev)}
      />
    </>
  );
};

export default SupplierAdditionalInfoMobile;

const ParentDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: 24px; */
  flex-direction: column;
  /* margin: 0 20px; */
  .MuiFormControl-root {
    width: 327px;
  }
  font-family: 'Roboto Condensed';
  .combobox {
    label {
      margin-bottom: 0;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #3e3e3e;
      margin-left: 0;
    }
  }
  .image-picker {
    width: 327px;
    height: 129px;
    margin-bottom: 0;
    border: 1px dashed #e8eaeb;
    label {
      span {
        font-size: 16px;
        line-height: 20px;
        /* color: rgba(41, 41, 41, 0.2); */
      }
      svg {
        fill: #d9d9d9;
      }
    }
  }
`;

const SkipText = styled.div`
  text-decoration-line: underline;
  color: #696969;
  cursor: pointer;
  font-size: 18px;
  line-height: 22px;
  margin-left: auto;
  margin-top: 8px;
`;

export const MainDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const UploadProductText = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  margin-top: 16px;
`;

export const MainDetailsText = styled.div`
  color: #3e3e3e;
  font-size: 18px;
  line-height: 22px;
  margin-top: 4px;
`;

export const FormWrapperContainer = styled.form.attrs({
  className: 'form-container',
})`
  margin-top: 16px !important;
  display: flex;
  flex-direction: column;
  @media (min-width: 1150px) {
    margin-top: 20px;
  }

  .phoneNumber {
    margin: -6px 0 24px;
    & .flag-dropdown,
    & .flag-dropdown.open {
      height: 48px;
    }

    & .form-control {
      height: 48px;
    }
  }
  .country-list {
    & .search {
      & .search-box {
        height: 48px;
      }
    }
  }
  && .submit-btn {
    @media (min-width: 1150px) {
      margin-top: 0;
      width: unset;
      ${rtl`
          margin-left: auto;
        `}
    }
  }
  & .divider-wrapper {
    margin: 14px auto;
  }

  & .anchor-link {
    color: #2277cc;
    font-weight: 500;
  }

  & .actions-container {
    @media (min-height: 800px) {
      margin-top: 0;
    }
    @media (min-width: 1150px) {
      margin-top: 15px;
    }
  }
`;

const FormFieldDiv = styled(Form.Field)`
  margin-top: 8px;
  label {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #3e3e3e;
    margin-left: 0;
  }
  .MuiInputBase-root {
    width: 327px !important;
    margin-top: 20px;
  }
`;

export const InfoView = styled.div`
  height: 21.33px;
  width: 21.33px;
  margin-left: 6px;
  cursor: pointer;
`;

export const InfoImage = styled.img`
  height: 100%;
  width: 100%;
`;

export const ImagePickerDiv = styled.div`
  display: flex;
  justify-content: center;
  label {
    width: 100%;
  }
`;

export const LogoParent = styled.div``;

export const UploadLogoText = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #3e3e3e;
`;

export const FooterParent = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: center;
  position: absolute;
  bottom: 24px;
  width: 100%;
  padding: 0 24px;
`;

export const BackButtonParent = styled.div``;

export const BackButton = styled.button`
  border: 1px solid #d9d9d9;
  border-radius: 15px;
  padding: 9px 47px;
  height: 40px;
  width: 134px;
  text-align: center;
  background-color: white;
  cursor: pointer;
`;

export const BackButtonText = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: #696969;
`;

export const NextButtonParent = styled.div`
  text-align: center;
`;

export const NextButton = styled.button`
  :disabled {
    background: #d9d9d9;
    cursor: default;
  }
  background: #fc5c42;
  border: 1px solid #d9d9d9;
  border-radius: 15px;
  padding: 9px 48px;
  height: 40px;
  width: 134px;
  text-align: center;
  cursor: pointer;
`;

export const NextButtonText = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: rgba(255, 255, 255, 0.9);
`;

export const FormTextareaDiv = styled(TextArea)`
  label {
    font-size: 14px;
    line-height: 22px;
    color: #3e3e3e;
    margin-left: 0;
    display: unset;
  }
  textarea {
    width: 327px;
    height: 76px;
    background: #fafcfe;
    border: 1px solid #e8eaeb;
    border-radius: 10px;
    margin-top: 0px;
  }
`;

export const SelectLabel = styled.div`
  font-size: 12px;
  line-height: 22px;
  color: #3e3e3e;
  margin-bottom: 0px;
  font-weight: 600;
`;

export const CustomFormControl = styled(FormControl)`
  .MuiInputBase-root {
    width: 327px;
    height: 48px;
    border-radius: 10px;
    .MuiSelect-select {
      background: #fafcfe;
    }
    input {
      background: #fafcfe;
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: #e8eaeb !important;
    }
  }
`;

const Header = styled.div`
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ScrollableDiv = styled.div`
  height: calc(100vh - 220px);
  margin-bottom: 16px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  padding: 0 24px;
`;
