import * as React from 'react';
import PopperMenu from 'components/Popper';
import ArrowButton from 'components/Button/ArrowButton';
import { Supplier } from 'types/suppliers';

const LogoutModal = React.lazy(() => import('./LogoutModal'));
const EditSupplierModal = React.lazy(
  () => import('pages/appManagement/common/EditUserModals/EditSupplierModal')
);

interface Props {
  isSupplier: boolean;
  supplier: Supplier | null;
  callEditSupplier: VoidFunction;
  isLoading: boolean;
}

const Preferences: React.FC<Props> = ({
  isSupplier = false,
  supplier,
  callEditSupplier,
  isLoading,
}) => {
  const [isLogoutOpen, setIsLogoutOpen] = React.useState<boolean>(false);
  const toggleModal = React.useCallback((): void => {
    setIsLogoutOpen(prev => !prev);
  }, []);

  const [editClientModalOpen, setClientModalOpen] = React.useState<boolean>(
    false
  );
  const closeEditModal = React.useCallback(() => {
    setClientModalOpen(false);
  }, []);

  return (
    <>
      <PopperMenu
        top={26}
        button={ArrowButton}
        items={
          isSupplier
            ? [
                {
                  component: 'Edit User',
                  onClick: () => setClientModalOpen(true),
                },

                { onClick: toggleModal, component: 'Log out' },
              ]
            : [
                {
                  component: 'Edit User',
                },
                { component: 'Settings' },
                { onClick: toggleModal, component: 'Log out' },
              ]
        }
      />
      <React.Suspense fallback={null}>
        {isLogoutOpen && (
          <LogoutModal isOpen={isLogoutOpen} toggle={toggleModal} />
        )}
        {editClientModalOpen && !isLoading && supplier && isSupplier && (
          <EditSupplierModal
            isOpen={editClientModalOpen}
            toggle={closeEditModal}
            supplier={supplier}
            onSuccess={() => {
              callEditSupplier();
              closeEditModal();
            }}
          />
        )}
      </React.Suspense>
    </>
  );
};

export default Preferences;
