import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { useRouter, useSession } from 'hooks';
import { ClientRouter } from 'router/routes';
import { SupportedLanguage } from 'types/common.types';
import { LogsAPI } from 'api/methods';
import { userLogData } from 'mockData/userLogData';
import { useOnboardingState } from 'context/onboardingContext';
import WebView from './PlanConfirmed.web';
import useOnboardingQuiz from './useOnboardingQuiz';
import useConfirmPlan from '../confirm-plan/useConfirmPlan';

const PlanConfirmed = () => {
  const { isLoading } = useOnboardingQuiz();
  const { history } = useRouter();
  const { selectedFloor, goBack, image } = useConfirmPlan();
  const { locale } = useSession();

  const next = React.useCallback(() => {
    LogsAPI.postUserLogs({
      area: userLogData.eventArea.registration,
      section: userLogData.eventSection.planUpload,
      name: userLogData.eventName.letsStartQuiz,
      path: userLogData.eventPath.appOnBoardingConfirmPlanPath,
    });
    history.push(ClientRouter.QUIZ_QUESTION);
  }, [history]);
  const { isScan } = useOnboardingState();
  if (!isScan)
    if (!selectedFloor) return <Redirect to={ClientRouter.UPLOAD_PLAN} />;
  const name =
    locale === SupportedLanguage.HEBREW
      ? Number(selectedFloor?.name) + 1
      : Number(selectedFloor?.name);
  return (
    <WebView
      next={next}
      isLoading={isLoading}
      name={name}
      image={image}
      selectedFloor={selectedFloor}
      goBack={() => {
        LogsAPI.pushDataLayer([{ event: 'BeckToUploadPlan' }]);
        LogsAPI.postUserLogs({
          area: userLogData.eventArea.registration,
          section: userLogData.eventSection.planUpload,
          name: userLogData.eventName.backToUpload,
          path: userLogData.eventPath.appOnBoardingPlanConfirmPath,
        });
        goBack();
      }}
    />
  );
};

export default PlanConfirmed;
