import * as React from 'react';
import styled from 'styled-components';
import { Filter, PaperPlus } from 'assets/@client/profile/addresses';
import InformationField from 'components/@client/InformationField';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';

interface Props {
  editMode?: boolean;
}

const InformationBlock: React.FC<Props> = ({ editMode = false }) => {
  return (
    <Container>
      {editMode ? (
        <InformationField
          icon={<Filter />}
          title={<FormattedMessage id={Message.ADDRESS_FORM_EDIT_TO_SHIP} />}
        >
          <FormattedMessage
            id={Message.ADDRESS_FORM_EDIT_INFO_BOX_TEXT}
            values={{ linebreak: <br /> }}
          />
        </InformationField>
      ) : (
        <InformationField
          icon={<PaperPlus />}
          title={<FormattedMessage id={Message.USER_ADD_NEW_ADDRESS_BUTTON} />}
        >
          <FormattedMessage
            id={Message.ADDRESS_FORM_ADD_INFO_BOX_TEXT}
            values={{ linebreak: <br /> }}
          />
        </InformationField>
      )}
    </Container>
  );
};

export default InformationBlock;

const Container = styled.section`
  flex: 0.3;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  min-height: 200px;
  padding: 30px;
  align-self: flex-start;
`;
