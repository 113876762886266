import * as React from 'react';
import Modal from 'components/@client/Modal';
import styled, { css } from 'styled-components';
import { useIntl } from 'react-intl';
import { Message } from 'i18n';
import { FormProvider, useForm } from 'react-hook-form';
import { Field } from 'components/forms';
import Actions from 'components/Modal/ModalActions';
import { preferencesDefaultValues } from 'pages/appManagement/common/NewQuizModal/NewQuizModal.config';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { alphaNumericHebrewRegExpAddress } from 'utils/validation.utils';
import { useFetch } from 'hooks';
import { METHODS } from 'api/client';
import { MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';
import { useMediaQuery } from '@material-ui/core';

interface Props {
  open?: boolean;
  onClose?: VoidFunction;
  propertyId: string;
  propertyName: string;
  updateProperty?: VoidFunction;
}

const EditPropertyName: React.FC<Props> = ({
  open,
  onClose,
  propertyId,
  propertyName,
  updateProperty,
}) => {
  const intl = useIntl();
  const isMobile = useMediaQuery(MOBILE_TABLET_RESOLUTION);

  const propertySchema = yup.object().shape({
    name: yup
      .string()
      .required('This field is mandatory')
      .trim()
      .matches(alphaNumericHebrewRegExpAddress, {
        message: 'This field must contain alphabetic letters or numbers.',
      }),
  });

  const methods = useForm<typeof preferencesDefaultValues>({
    resolver: yupResolver(propertySchema),
    defaultValues: { ...preferencesDefaultValues, name: propertyName },
    mode: 'all',
  });
  const { formState, handleSubmit } = methods;

  const onSuccess = () => {
    if (updateProperty) {
      updateProperty();
    }
    if (onClose) {
      onClose();
    }
  };

  const { isLoading, callFetch } = useFetch({
    initialUrl: `/api/properties/${propertyId}/`,
    skipOnStart: true,
    config: {
      method: METHODS.PATCH,
    },
    onSuccess,
  });

  const onSubmit = React.useCallback(
    (e: { name: string }) => {
      callFetch({
        url: `/api/properties/${propertyId}/`,
        data: e,
        method: METHODS.PATCH,
      });
    },
    [callFetch, propertyId]
  );

  return (
    <MainContainer
      title={intl.formatMessage({ id: Message.PROPERTY_EDIT_PROPERTY })}
      open={open || false}
      onClose={onClose}
      actions={[]}
      isMobile={!isMobile}
    >
      <Root>
        <FormProvider {...methods}>
          <CustomForm onSubmit={handleSubmit(onSubmit)}>
            <Field
              width="100%"
              className="form-field"
              label={{
                title: intl.formatMessage({
                  id: Message.PROPERTY_CARD_NAME,
                }),
              }}
              placeholder={intl.formatMessage({
                id: Message.PROPERTY_CARD_NAME,
              })}
              name="name"
              type="text"
              required
            />
            <Actions
              showCancelButton
              onCancel={onClose}
              cancelLabel={intl.formatMessage({
                id: Message.CANCEL,
              })}
              actions={[
                {
                  isLoading,
                  label: intl.formatMessage({
                    id: Message.SAVE_TITLE,
                  }),
                  type: 'submit',
                  disabled: !formState.isValid,
                },
              ]}
            />
          </CustomForm>
        </FormProvider>
      </Root>
    </MainContainer>
  );
};

export default EditPropertyName;

const MainContainer = styled(Modal)<{ isMobile: boolean }>`
  ${({ isMobile }) =>
    isMobile &&
    css`
      .MuiDialog-paper {
        min-width: 500px;
      }
    `};
  .MuiDialogTitle-root {
    padding: 16px 0;
  }
`;

const Root = styled.div`
  text-align: center;
`;

const CustomForm = styled.form`
  && .form-field {
    margin: 0 0 40px;
    label {
      font-size: 1rem;
      font-weight: bold;
      margin-bottom: 4px;
      width: fit-content;
    }
  }
`;
