import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import history from 'router/history';
import {
  clients,
  orders,
  properties,
  suppliers,
  teamPatriciaUsers,
} from 'store/reducers/user-management';
import { database } from 'store/reducers/suppliers-interface';
import { threeD } from 'store/reducers/3d-management';
import quizzes from 'store/reducers/quiz-management/quizManagement.reducer';
import designStyles from 'store/reducers/quiz-management/designStyles.reducer';
import propertiesTasks from 'store/reducers/properties-management/PropertiesManagement.reducer';
import unitsTasks from 'store/reducers/properties-management/UnitsManagement.reducer';
import publishedPropertiesTasks from 'store/reducers/properties-management/publishedProperties.reducer';
import archivedPropertiesTasks from 'store/reducers/properties-management/archivedProperties.reducer';
import auth from 'store/reducers/authentication.reducer';
import session from 'store/reducers/session.reducer';
import notifications from 'store/reducers/notifications.reducer';
import userInfo from 'store/reducers/@client/userInfo.reducer';
import cartInfo from 'store/reducers/@client/cartInfo.reducer';
import renders from 'store/reducers/user-management/renders.reducer';
import { RootReducer } from 'store/types';

const createRootReducer = (): RootReducer =>
  combineReducers({
    router: connectRouter(history),
    notifications,
    auth,
    clients,
    suppliers,
    teamPatriciaUsers,
    orders,
    quizzes,
    designStyles,
    properties,
    propertiesTasks,
    unitsTasks,
    publishedPropertiesTasks,
    archivedPropertiesTasks,
    database,
    session,
    threeD,
    userInfo,
    cartInfo,
    renders,
  });

const rootReducer = createRootReducer();
export default rootReducer;
