import { useMediaQuery } from '@material-ui/core';
import { MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';
import { useCategories, useFetch } from 'hooks';
import { METHODS } from 'api/client';
import { SubCategory } from 'types/common.types';
import MobileProducts from './MobileProducts';
import DesktopProducts from './DesktopProducts';

const Products = () => {
  const { Categories: categoryOptions = [] } = useCategories();
  const { data: subCategories, isLoading } = useFetch<Array<SubCategory>>({
    initialUrl: 'api/sub_categories/',
    config: {
      // useCache: true,
      method: METHODS.GET,
      params: {
        with_products: true,
      },
    },
  });

  const getSubOrCategoryObject = (catName: string, isSubCategory?: Boolean) => {
    if (isSubCategory) {
      return subCategories?.find(
        e => e.name === catName || e.alt_name === catName
      );
    }

    return categoryOptions?.find(
      e => e.name === catName || e.alt_name === catName
    );
  };

  const getFilteredCategories = (catName: string) =>
    !isLoading
      ? subCategories?.filter(subCategory =>
          subCategory.category?.name === catName ||
          subCategory.category?.hebrew_name === catName ||
          subCategory.category?.alt_name === catName
            ? subCategory
            : null
        )
      : [];
  const isMobile = useMediaQuery(MOBILE_TABLET_RESOLUTION);

  return isMobile ? (
    <MobileProducts
      categories={categoryOptions ?? []}
      getFilteredCategories={getFilteredCategories}
    />
  ) : (
    <DesktopProducts
      categoryOptions={categoryOptions ?? []}
      getFilteredCategories={getFilteredCategories}
      getSubOrCategoryObject={getSubOrCategoryObject}
    />
  );
};

export default Products;
