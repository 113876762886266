import * as React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import rtl from 'styled-components-rtl';
import { ReactComponent as USAFlag } from 'assets/icons/usaflag.svg';
import { ReactComponent as IsraelFlag } from 'assets/icons/israelflag.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/phone.svg';
import { ReactComponent as Mail } from 'assets/icons/mail.svg';

const ContactInfoSection = () => {
  return (
    <InfoSection>
      <InfoTitle>
        <FormattedMessage id={Message.CONTACT_INFO_TITLE} />
      </InfoTitle>
      <div className="info-container">
        <InfoDescription>
          <div className="work-info">
            <CountryContainer>
              <span>
                <FormattedMessage id={Message.CONTACT_INFO_COUNTRY_USA} />
              </span>
              <USAFlag />
            </CountryContainer>
            <div className="work-day-container">
              <TimeInfo className="mr">
                <Label className="work-label">
                  <FormattedMessage id={Message.CONTACT_INFO_WORK_DAY_TITLE} />
                </Label>
                <div className="work-day">
                  <Label>
                    <FormattedMessage id={Message.CONTACT_INFO_WORK_DAYS_USA} />{' '}
                  </Label>
                </div>
                <Label className="work-phone">
                  <PhoneIcon />
                  <FormattedMessage id={Message.CONTACT_INFO_WORK_PHONE_USA} />
                </Label>
              </TimeInfo>
              <TimeInfo>
                <Label className="work-label">
                  <FormattedMessage id={Message.CONTACT_INFO_WORK_HOUR_TITLE} />
                </Label>
                <br />
                <div className="work-day">
                  <Label>
                    <FormattedMessage
                      id={Message.CONTACT_INFO_WORK_HOURS_USA}
                    />
                  </Label>
                </div>
                <CustomAnchor href="mailto:team@patricia-ai.com">
                  <Label className="work-phone">
                    <Mail />
                    <FormattedMessage id={Message.CONTACT_INFO_WORK_EMAIL} />
                  </Label>
                </CustomAnchor>
              </TimeInfo>
            </div>
          </div>{' '}
        </InfoDescription>
        <InfoDescription>
          <div className="work-info">
            <CountryContainer>
              <FormattedMessage id={Message.CONTACT_INFO_COUNTRY_ISRAEL} />
              <IsraelFlag />
            </CountryContainer>
            <div className="work-day-container">
              <TimeInfo className="mr">
                <Label className="work-label">
                  <FormattedMessage id={Message.CONTACT_INFO_WORK_DAY_TITLE} />
                </Label>
                <div className="work-day">
                  <Label>
                    <FormattedMessage
                      id={Message.CONTACT_INFO_WORK_DAYS_ISRAEL}
                    />{' '}
                  </Label>
                </div>
                <Label className="work-phone" style={{ marginTop: 33 }}>
                  <PhoneIcon />
                  <FormattedMessage
                    id={Message.CONTACT_INFO_WORK_PHONE_ISRAEL}
                  />
                </Label>
              </TimeInfo>
              <TimeInfo>
                <Label className="work-label">
                  <FormattedMessage id={Message.CONTACT_INFO_WORK_HOUR_TITLE} />{' '}
                </Label>
                <div className="work-day">
                  <Label>
                    <FormattedMessage
                      id={Message.CONTACT_INFO_WORK_HOURS_ISRAEL}
                    />
                  </Label>
                </div>
                <CustomAnchor href="mailto:team@patricia-ai.com">
                  <Label className="work-phone">
                    <Mail />
                    <FormattedMessage id={Message.CONTACT_INFO_WORK_EMAIL} />
                  </Label>
                </CustomAnchor>
              </TimeInfo>
            </div>
          </div>{' '}
        </InfoDescription>
      </div>
    </InfoSection>
  );
};

export default ContactInfoSection;

const InfoSection = styled.div`
  width: 100%;
  & .info-container {
    display: flex;
  }
  ${rtl`
    padding: 24px 0 11px 24px;
  `}
`;

export const CustomAnchor = styled.a`
  text-decoration: none;
  color: inherit;
`;

const InfoTitle = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-decoration-line: underline;

  /* Primary Color */

  color: #fc5c42;
`;
const InfoDescription = styled.div`
  width: 50%;
  margin: 36px 0 40px 0;
  border-right: 1.5px solid rgba(208, 208, 208, 0.47);
  border-left: 1.5px solid rgba(208, 208, 208, 0.47);
  /* padding: 0 0 0 100px; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .work-info {
    display: flex;
    flex-direction: column;
    /* && div:first-child {
      margin-right: 32px;
    } */

    .work-day-container {
      display: flex;
      & .mr {
        ${rtl`
          margin-right: 32px;
          `}
      }
    }
    /* @media (min-width: 1025px) and (max-width: 1510px) {
      padding: 12px;
    } */
  }
`;
const CountryContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  line-height: 19px;
  ${rtl`
  text-align: left;
  `}

  > svg {
    ${rtl`
      margin-left: 8px;
    `}
  }
`;

const TimeInfo = styled.div`
  font-size: 18px;
  line-height: 21px;
  color: #696969;
  margin-top: 24px !important;
  && .work-label {
    color: #000 !important;
    font-weight: 700;
    font-size: 18px;
  }
  && .work-day {
    margin-top: 8px !important;
    label {
      font-size: 18px;
    }
  }
  && .work-phone {
    margin-top: 33px !important;
    display: flex;
    align-items: center;
    svg {
      width: 20px !important;
      height: 20px;
      stroke: #696969;
      ${rtl`
      margin-right: 10px
    `};
    }
  }
  && .work-phone:hover {
    color: tomato;
    svg {
      stroke: tomato;
    }
  }
`;
const Label = styled.label`
  font-size: 16px;
  /* @media (max-width: 1510px) {
    font-size: 14px;
  } */
`;
