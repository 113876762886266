import { Message } from 'i18n';
import * as React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { formatDate } from 'utils/dates.utils';
import CalendarIcon from '../CalendarIcon/CalendarIcon';

interface Props {
  label: string | React.ReactNode;
  date?: string;
  value?: string | React.ReactNode;
  icon?: React.ReactNode;
}

const DateField: React.FC<Props> = ({ date, label, icon, value }) => {
  const intl = useIntl();
  return (
    <Container>
      {icon ?? <CalendarIcon day={formatDate(date, 'dd')} />}
      <span className="date-field-label">{label}</span>
      <span className="date-field-date">
        {date && intl.formatDate(date, { dateStyle: 'long' })}
        {value && value}
        {!date && !value && intl.formatMessage({ id: Message.NO_VALUE })}
      </span>
    </Container>
  );
};

export default DateField;

const Container = styled.div.attrs({ className: 'date-field' })`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 450px;
  color: ${({ theme }) => theme.colors.black0};

  .date-field-label,
  .date-field-date {
    flex: 1;
    font-weight: 500;
    margin-top: 5px;
    ${rtl`
      margin-left: 15px;
    `}
    .patricia-link {
      color: ${({ theme }) => theme.colors.primary.lightMobile};
      text-decoration: underline;
    }
  }

  & svg {
    height: 50px;
    width: 50px;
    @media (max-width: 800px) {
      height: 30px;
      width: 30px;
    }
  }
`;
