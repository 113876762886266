import { Select } from 'components/forms';
import { useBreakpoint } from 'hooks';
import { CustomMenuItem } from 'styles/sharedStyle';
import useRoles from '../useRoles';

const min = { w: '213px', h: 40 };
const max = { w: '278px', h: 40 };
const RoleSelect = () => {
  const { width } = useBreakpoint(min, max);
  const roleOptions = useRoles();

  return (
    <Select
      inputWidth={width}
      className="field-roles"
      label={{ title: 'Role' }}
      name="role"
      required
      maxHeight={112}
    >
      <CustomMenuItem value="" disabled>
        Select Role
      </CustomMenuItem>
      {roleOptions?.map(role => (
        <CustomMenuItem value={role.id} key={role.id}>
          {role.name}
        </CustomMenuItem>
      ))}
    </Select>
  );
};

export default RoleSelect;
