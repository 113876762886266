import * as React from 'react';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import Popover from '@material-ui/core/Popover';
import MuiListItem from '@material-ui/core/ListItem';
import styled from 'styled-components';
import CheckboxItem from 'components/Checkbox/CheckboxItem';
import SelectActions from 'components/Select/SelectActions';
import { isEmpty } from 'utils/lodash.utils';
import InputButton from 'components/InputButton';
import { transformOrigin, anchorOrigin } from './Select.config';

interface Props {
  options: string[];
  selectedItems: string[] | string;
  placeholder?: string;
  selectedPlaceholder?: string;
  multiple?: true;
  disableSelectAll?: boolean;
  onClick: (item: string) => void;
  onDelete?: (val: string) => void | null;
  isOpen: boolean;
  toggle: () => void;
  anchorEl?: HTMLElement | null;
  onApply: () => void;
  disabled?: boolean;
  onClear?: () => void;
  width?: number;
  endIcon?: React.ReactNode;
}

const Select: React.FC<Props> = ({
  options,
  isOpen,
  selectedItems,
  placeholder = 'Select',
  selectedPlaceholder,
  disableSelectAll,
  multiple,
  endIcon,
  onClick,
  toggle,
  anchorEl,
  onApply,
  onClear,
  disabled,
  width = 230,
}) => {
  const displayPlaceholder = React.useMemo(() => isEmpty(selectedItems), [
    selectedItems,
  ]);

  const BackdropProps = React.useMemo(
    () => ({
      onClick: onApply || toggle,
      style: { background: 'transparent' },
    }),
    [onApply, toggle]
  );

  const onClose = React.useCallback(
    (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
      if (reason === 'escapeKeyDown') return undefined;
      return multiple ? onApply() : toggle();
    },
    [multiple, onApply, toggle]
  );

  const PaperProps = React.useMemo(
    () => ({
      style: {
        borderRadius: 10,
        boxShadow: 'rgb(13 13 13 / 4%) 1px -1px 12px 6px',
        width,
      },
    }),
    [width]
  );

  return (
    <>
      <InputButton
        isOpen={isOpen}
        onClick={toggle}
        disabled={disabled}
        displayPlaceholder={displayPlaceholder}
        selectedPlaceholder={selectedPlaceholder}
        placeholder={placeholder}
        selectedItems={selectedItems as string[]}
        width={width}
        endIcon={endIcon}
        className="select-component-button"
      />
      <Popover
        PaperProps={PaperProps}
        open={isOpen}
        BackdropProps={BackdropProps}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        getContentAnchorEl={null}
        onClose={onClose}
        disablePortal
      >
        <div>
          <StyledList>
            {multiple && !disableSelectAll && (
              <ListItem button onClick={() => onClick('All')}>
                <CheckboxItem
                  value="All"
                  checked={selectedItems.length === options.length}
                />
              </ListItem>
            )}
            {options.map(option => (
              <ListItem button key={option} onClick={() => onClick(option)}>
                <CheckboxItem
                  value={option}
                  checked={selectedItems.includes(option)}
                  onClick={() => onClick(option)}
                />
              </ListItem>
            ))}
          </StyledList>
          {multiple && (
            <>
              <CustomDivider key="Divider" />
              <SelectActions
                key="Actions"
                onClear={onClear}
                onApply={onApply}
              />
            </>
          )}
        </div>
      </Popover>
    </>
  );
};

export default React.memo<React.FC<Props>>(Select);

const CustomDivider = styled(Divider)`
  padding: 0.3rem;
  width: 100%;
`;
const StyledList = styled(List)`
  max-height: 400px;
  overflow: auto;
  ${({ theme }) => theme.utils.scrollbar}
`;

export const ListItem = styled(MuiListItem)`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 1rem;

  &.Mui-selected {
    background: white;
  }

  .checkbox-input {
    margin-right: 7px;
  }
`;
