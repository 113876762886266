import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { useOnboardingState } from 'context/onboardingContext';
import { ClientRouter } from 'router/routes';
import { isEmpty } from 'utils/lodash.utils';
import { baseURL } from 'constants/config';
import { useAuthentication, useRouter, useSession } from 'hooks';
import DoneViews from './Views';

const Done = () => {
  const { answers, rooms } = useOnboardingState();
  const { isScan } = useOnboardingState();
  // eslint-disable-next-line
  let _window: any = window;
  const { token, id: user_id } = useAuthentication();
  const { location } = useRouter<{}>();
  const { country: region } = useSession();
  if (isScan) {
    if (_window.vuplex !== undefined && _window.HAS_LIDAR === true) {
      _window.vuplex.postMessage({
        token,
        userId: user_id,
        baseWebHost: location.pathname,
        baseEp: `${baseURL}/api`,
        cartId: 0,
        region,
        isMobile: true,
        ambientOcclusionEnabled: false,
        selected_scan: true,
        answers,
        room_id: rooms[0].id,
      });
      location.pathname = ClientRouter.APP;
    }
  }

  if (isEmpty(answers)) return <Redirect to={ClientRouter.UPLOAD_PLAN} />;
  return <DoneViews />;
};

export default Done;
