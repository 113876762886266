import * as React from 'react';

const useDisableOverflow = (open: boolean) => {
  React.useEffect(() => {
    const html = document.querySelector('html') as HTMLHtmlElement;
    if (open) html.style.overflowY = 'hidden';
    else html.style.overflowY = '';
    return () => {
      html.style.overflowY = '';
    };
  }, [open]);
};

export default useDisableOverflow;
