import React, { Suspense } from 'react';
import styled from 'styled-components';
import {
  useSession,
  useValidateUser,
  // useRecentProperty,
} from 'hooks';
import { ClientRoot, MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';
import Loader from 'components/Loader';
import { Type } from 'constants/config';

// import TourDeviceNotSupportedModal from 'components/@client/TourDeviceNotSupportedModal';
// import { METHODS } from 'api/client';
// import { storySections } from 'api/transform';
// import { Stories as StoriesType } from 'types/common.types';
// import { RECENT_PROPERTY_ID } from 'constants/localstorage.constants';
// import { getLocalStorage } from 'utils/common.utils';
import * as deviceInfo from 'react-device-detect';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import Header from './Header';
// import OldHeader from './Header/Header';
// import StoriesComponent from './Stories';
// import Experts from './Experts';
// import BecomeASupplier from './BecomeASupplier';
// import Products from './Products';
// import Magazine from './Magazine';
// import PropertyModal from './PropertyPublishModal';
import NotSupportedRegionPlaceholder from './NotSupportedRegionPlaceholder';
// import Ideas from './Ideas';
// import NewSkins from './NewSkins';

const Header = React.lazy(() => import('./Header'));
const BecomeASupplier = React.lazy(() => import('./BecomeASupplier'));
const OldHeader = React.lazy(() => import('./Header/Header'));
const TourDeviceNotSupportedModal = React.lazy(
  () => import('components/@client/TourDeviceNotSupportedModal')
);
const NewSkins = React.lazy(() => import('./NewSkins'));
const Ideas = React.lazy(() => import('./Ideas'));
const Products = React.lazy(() => import('./Products'));
const Magazine = React.lazy(() => import('./Magazine'));

const Home = () => {
  const { isAuthenticated, validating, fullName, type } = useValidateUser();
  const { isFromSupportedCountry, isGettingLocation } = useSession();
  const isMobile =
    useMediaQuery(MOBILE_TABLET_RESOLUTION) || deviceInfo.isMobile;
  // const { recentProperty } = useRecentProperty();
  // const [isModalOpen, setIsModalOpen] = React.useState(false);
  // const [isSetPropertyMedia, setPropertyMedia] = React.useState('');
  // React.useEffect(() => {
  //   if (recentProperty) {
  //     // setIsModalOpen(
  //     //   recentProperty?.id !== getLocalStorage(RECENT_PROPERTY_ID, null)
  //     // );
  //     const mediaUrlProperty =
  //       recentProperty && recentProperty?.media
  //         ? recentProperty?.media[0].file
  //         : '';
  //     setPropertyMedia(mediaUrlProperty);
  //   }
  // }, [recentProperty]);

  // const { data: storyData } = useFetch({
  //   initialUrl: '/api/stories/',
  //   config: {
  //     method: METHODS.GET,
  //     isPublic: true,
  //   },
  //   transform: storySections,
  // });

  if (!validating || isGettingLocation) return <Loader fullHeight />;

  return isAuthenticated ? (
    <Root>
      <Suspense fallback={<Loader />}>
        <Header isAuthenticated={isAuthenticated} fullName={fullName} />
      </Suspense>

      {(isFromSupportedCountry && !isGettingLocation) ||
      type === Type.TEAM_PATRICIA ? (
        <CustomClientRoot className="client-root">
          <Suspense fallback={<Loader />}>
            <TourDeviceNotSupportedModal />
          </Suspense>

          {/* <Section /> */}
          <Suspense fallback={<Loader />}>
            <Ideas />
          </Suspense>
          {/* <StoriesComponent storyData={storyData as StoriesType[]} /> */}
          <Suspense fallback={<Loader />}>
            <NewSkins isAuthenticated={isAuthenticated} />
          </Suspense>
          <Suspense fallback={<Loader />}>
            <Products />
          </Suspense>

          {/* <Experts /> */}
          {type !== Type.SUPPLIER &&
            type !== Type.SELLER &&
            type !== Type.SELLER_CLIENT && <BecomeASupplier />}
          {/* {isModalOpen && (
            <PropertyModal
              setIsModalOpen={setIsModalOpen}
              isModalOpen={isModalOpen}
              media={isSetPropertyMedia} 
            />
          )} */}
        </CustomClientRoot>
      ) : (
        <>
          {isGettingLocation ? (
            <Loader fullHeight />
          ) : (
            <NotSupportedRegionPlaceholder />
          )}
        </>
      )}
    </Root>
  ) : (
    <Root>
      <Suspense fallback={<Loader />}>
        <OldHeader isAuthenticated={isAuthenticated} fullName={fullName} />
      </Suspense>
      {(isFromSupportedCountry && !isGettingLocation) ||
      type === Type.TEAM_PATRICIA ? (
        <CustomClientRoot className="client-root">
          <Suspense fallback={<Loader />}>
            <TourDeviceNotSupportedModal />
          </Suspense>

          {/* <Section /> */}
          <Suspense fallback={<Loader />}>
            <Ideas />
          </Suspense>
          {/* <StoriesComponent storyData={storyData as StoriesType[]} /> */}
          {/* <Skins /> */}
          <Suspense fallback={<Loader />}>
            <NewSkins isAuthenticated={isAuthenticated} />
          </Suspense>
          <Suspense fallback={<Loader />}>
            <Products />
          </Suspense>
          {/* <Experts /> */}
          {type !== 'SUPPLIER' && (
            <Suspense fallback={<Loader />}>
              <BecomeASupplier />
            </Suspense>
          )}
          {!isMobile ? (
            <Suspense fallback={<Loader />}>
              <Magazine />
            </Suspense>
          ) : null}
          {/* {isModalOpen && (
            <PropertyModal
              setIsModalOpen={setIsModalOpen}
              isModalOpen={isModalOpen}
              media={isSetPropertyMedia} 
            />
          )} */}
        </CustomClientRoot>
      ) : (
        <>
          {isGettingLocation ? (
            <Loader fullHeight />
          ) : (
            <NotSupportedRegionPlaceholder />
          )}
        </>
      )}
    </Root>
  );
};

export default Home;

const CustomClientRoot = styled(ClientRoot)`
  max-width: 1600px;
  color: #000000;
  position: relative;
  width: 100%;
  margin: 0 auto;
  @media (max-width: 800px) {
    padding: 20px 5px;
  }
`;

const Root = styled.div`
  ul {
    gap: 0;
    padding: 0;
  }

  .horizontal-carousel {
    width: 100%;
  }

  @media (max-width: 1439px) {
    .stories-carousel li:last-child {
      display: flex;
    }
  }

  @media (max-width: 1150px) {
    .products-carousel li:last-child {
      display: flex;
      flex-direction: column;
    }
  }
  .stories-carousel {
    padding: 20px;

    ul {
      margin: 0;
      padding: 0;
    }
  }

  .client-root {
    padding: 0px 0px;

    @media ${MOBILE_TABLET_RESOLUTION} {
      padding: 0 20px 20px 20px;
      z-index: 9;
    }
  }

  .section-title-wrapper {
    margin-top: 10px;
    margin-bottom: 0;
  }
`;
