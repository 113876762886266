import * as React from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Message } from 'i18n';
import { AuthForm, Fields } from 'pages/auth/Auth.config';
import { Link } from 'react-router-dom';
import { useAuthentication } from 'hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Form from 'components/forms';
import eyeIcon from 'assets/@client/onboarding/eye-down.png';
import eyeUpIcon from 'assets/@client/onboarding/eye-up.png';
import noop from 'lodash/noop';
import Icon from 'components/Icon';
import { loginSchema } from 'utils/validation.utils';
import { LoginPayload } from 'store/types/authentication.types';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import PatriciaLogo from 'assets/@client/supplier-login-flow/patricia-red-text.png';
import { ClientRouter } from 'router/routes';
import LoginImage from 'assets/@client/supplier-login-flow/login-main-image.png';
import {
  MOBILE_TABLET_MIN_RESOLUTION,
  MOBILE_TABLET_RESOLUTION,
} from 'styles/sharedStyle';

const SupplierLoginMobile: React.FC = () => {
  const intl = useIntl();
  const [isPasswordVidsible, setIsPasswordVisible] = React.useState(false);
  const { login, loading: isLoading, resetError } = useAuthentication();
  const methods = useForm<LoginPayload>({
    resolver: yupResolver(loginSchema),
    defaultValues: {
      email: '',
      password: '',
      rememberUser: true,
    },
    mode: 'all',
  });
  const { email, password } = useWatch({
    control: methods.control,
  });
  const { handleSubmit, formState } = methods;
  const { isValid, submitCount } = formState;

  const onSubmit = (payload: LoginPayload) => {
    login({ ...payload, loginType: 'SUPPLIER' });
  };

  React.useEffect(() => {
    if (submitCount) {
      resetError();
    }
  }, [email, password, submitCount, resetError]);

  return (
    <>
      <ParentDiv LoginImage={LoginImage}>
        <TransparentDiv>
          <a href={ClientRouter.APP}>
            <PatriciaLogoContainer src={PatriciaLogo} alt="patricia-logo" />
          </a>
          <LoginTitle>
            <FormattedMessage id={Message.SUPPLIER_LOGIN_TITLE} />
          </LoginTitle>
          <LoginSubTitle>
            <FormattedMessage
              id={Message.SUPPLIER_LOGIN_SUBTITLE}
              values={{
                span: (chunks: string) => (
                  <Link to={ClientRouter.SUPPLIER_REGISTER}>
                    <SingInText>{chunks}</SingInText>
                  </Link>
                ),
              }}
            />
          </LoginSubTitle>
          <LoginForm>
            <FormProvider {...methods}>
              <AuthForm>
                <FieldsDiv>
                  <FormFieldDiv
                    autoComplete="off"
                    showValidAdornment
                    onKeyDown={noop}
                    label={{
                      title: intl.formatMessage({
                        id: Message.SUPPLIER_LOGIN_EMAIL_LABEL,
                      }),
                      isBold: true,
                    }}
                    name="email"
                    type="email"
                    dir="ltr"
                    placeholder={intl.formatMessage({
                      id: Message.SUPPLIER_LOGIN_EMAIL_PLACEHOLDER,
                    })}
                  />
                  <FormFieldDiv
                    autoComplete="off"
                    endAdornment={
                      <IconParent
                        onClick={() =>
                          setIsPasswordVisible(!isPasswordVidsible)
                        }
                      >
                        <img
                          alt="temp"
                          src={isPasswordVidsible ? eyeUpIcon : eyeIcon}
                        />
                      </IconParent>
                    }
                    onKeyDown={noop}
                    label={{
                      title: intl.formatMessage({
                        id: Message.AUTH_LOGIN_PASSWORD_FIELD,
                      }),
                      isBold: true,
                    }}
                    name="password"
                    dir="ltr"
                    type={isPasswordVidsible ? 'text' : 'password'}
                    placeholder={intl.formatMessage({
                      id: Message.SUPPLIER_LOGIN_PASSWORD_PLACEHOLDER,
                    })}
                  />
                </FieldsDiv>
              </AuthForm>
            </FormProvider>
          </LoginForm>
          <ContinueButton
            onClick={handleSubmit(onSubmit)}
            disabled={!isValid || isLoading}
          >
            {isLoading ? (
              <CustomIcon name="spinner" size={20} />
            ) : (
              <ContinueText>
                <FormattedMessage id={Message.SUPPLIER_LOGIN_CONTINUE_BUTTON} />
              </ContinueText>
            )}
          </ContinueButton>
          <RegisterParent>
            <UnregisteredText>
              <FormattedMessage id={Message.SUPPLIER_LOGIN_NEW_TO_APP} />
            </UnregisteredText>
            <Link to={ClientRouter.SUPPLIER_REGISTER}>
              <RegisterNowText>
                <FormattedMessage id={Message.SUPPLIER_LOGIN_SIGN_UP_HERE} />
              </RegisterNowText>
            </Link>
          </RegisterParent>
        </TransparentDiv>
      </ParentDiv>
    </>
  );
};

export default SupplierLoginMobile;

const ParentDiv = styled.div<{ LoginImage: string }>`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto Condensed';
  direction: ltr;
  ::before {
    background-image: url(${LoginImage});
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.6;
    background-position: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    content: '';
  }
`;

const TransparentDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(250, 250, 250, 0.8);
  border-radius: 20px;
  position: relative;
  flex-direction: column;
  padding: 16px 8px;
  width: 93vw;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    width: 584px;
    height: 659px;
  }
`;

const PatriciaLogoContainer = styled.img`
  height: 140px;
  width: 88px;
`;

const LoginTitle = styled.div`
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: #282929;
  margin-top: 16px;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    margin-top: 24px;
    font-size: 30px;
    line-height: 34px;
  }
`;

const LoginSubTitle = styled.div`
  font-size: 18px;
  line-height: 22px;
  color: #3e3e3e;
  margin-top: 4px;
  display: flex;
  gap: 5px;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    margin-top: 13px;
    font-size: 24px;
    line-height: 28px;
  }
`;

const SingInText = styled.div`
  color: #5c95ff;
  font-size: 18px;
  line-height: 22px;
  text-decoration-line: underline;
  cursor: pointer;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    font-size: 24px;
    line-height: 28px;
  }
`;

const LoginForm = styled.div`
  margin-top: 16px;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    margin-top: 24px;
  }
`;

const FieldsDiv = styled(Fields)`
  gap: 0;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    gap: 16px;
  }
`;

const FormFieldDiv = styled(Form.Field)`
  label {
    font-size: 16px;
    line-height: 20px;
    color: #696969;
    margin-left: 0;
    font-family: 'Roboto Condensed';
    @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
      font-size: 18px;
    }
  }
  .MuiInputBase-root {
    width: 327px !important;
    margin-top: 20px;
    @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
      width: 410px !important;
    }
  }
`;

const IconParent = styled.div`
  position: absolute;
  right: 16px;
  top: 15px;
  cursor: pointer;
`;

const ContinueButton = styled.button`
  :disabled {
    cursor: default;
    background: #d9d9d9;
  }
  cursor: pointer;
  background: #fc5c42;
  border: 0.5px solid #d9d9d9;
  border-radius: 15px;
  width: 327px;
  height: 48px;
  margin-top: 24px;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    width: 410px;
  }
`;

const CustomIcon = styled(Icon)`
  ${({ theme }) => theme.utils.spinAnimation};
  position: absolute;
  fill: #f9fafa;
`;

const ContinueText = styled.div`
  color: #ffffff;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
`;

const RegisterParent = styled.div`
  display: flex;
  gap: 4px;
  margin-top: 24px;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    margin-top: 16px;
  }
`;

const UnregisteredText = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: #696969;
`;

const RegisterNowText = styled.div`
  text-decoration-line: underline;
  color: #5c95ff;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
`;
