import * as React from 'react';
import { METHODS } from 'api/client';
import {
  useNotifications,
  useFetch,
  useRouter,
  useAuthentication,
} from 'hooks';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ProjectInformationSchema } from 'utils/validation.utils';
import { size } from 'utils/lodash.utils';
import type { addressType, ProjectInformationType } from 'types/common.types';
import { ClientRouter } from 'router/routes';
import { removeEmptyValues } from 'utils/common.utils';
import { ProjectDetail } from 'types/projects';

export type developerObject = {
  label: string;
  value?: number;
};

const useProjectInformation = (data?: ProjectDetail | null) => {
  const methods = useForm<ProjectInformationType>({
    resolver: yupResolver(ProjectInformationSchema),
    defaultValues: {
      name: data?.projectName,
      number: data?.number,
      address: data?.street,
      city: data?.city,
    },
    mode: 'all',
    shouldUnregister: false,
    reValidateMode: 'onChange',
  });
  const { reset, register } = methods;
  const { history } = useRouter();
  const { showNotification } = useNotifications();
  const [isEditSuccess, setIsEditSuccess] = React.useState(false);
  const [developer, setdeveloper] = React.useState<developerObject>({
    label: '',
  });

  const [searchFlag, setSearchFlag] = React.useState(false);
  const { supplierId, seller_supplier } = useAuthentication();
  const [image, setImage] = React.useState<File | null>(null);
  const [csv, setCSV] = React.useState<File | null>(null);

  const onSuccess = React.useCallback(() => {
    const html = document.querySelector('html') as HTMLHtmlElement;
    html.style.overflowY = '';
    setImage(null);

    history.push(ClientRouter.PROPERTIES_SELLER_LISTING);
  }, [setImage, history]);

  const onFailure = React.useCallback(
    (error: string) => {
      if (error.includes('project with this number already exists')) {
        showNotification({
          key: 'project-add-fail',
          message: 'There is already a project with such number.',
          severity: 'error',
        });
      }
    },
    [showNotification]
  );

  const { isLoading, callFetch } = useFetch({
    initialUrl: '/api/projects/',
    skipOnStart: true,
    config: {
      method: METHODS.POST,
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      },
    },
    onSuccess,
    onFailure,
  });

  const onSuccessEditProject = () => {
    setIsEditSuccess(true);
  };

  const { isLoading: isLoadingEdit, callFetch: editProject } = useFetch({
    initialUrl: '/api/projects/',
    skipOnStart: true,
    config: {
      method: METHODS.PATCH,
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      },
    },
    onFailure,
    onSuccess: onSuccessEditProject,
  });

  const handleEdit = React.useCallback(
    (payload: ProjectInformationType) => {
      if (image && size(image?.name) > 124) {
        showNotification({
          key: 'uploadPlan/uploadPlanRejected',
          message:
            'File name has to be shorter than 124 characters, Please rename your file.',
          severity: 'error',
        });
      } else {
        const formData = new FormData();

        const address: addressType = {
          street: payload.address,
          city: payload.city,
        };
        formData.append('name', payload.name);
        formData.append('number', payload.number);
        formData.append(
          'developer',
          JSON.stringify(developer?.value ?? data?.developerDetails?.id)
        );
        formData.append(
          'supplier',
          supplierId ?? seller_supplier?.toString() ?? ''
        );
        formData.append('address', JSON.stringify(address));
        if (image) formData.append('cover_photo', image as Blob);
        if (csv) formData.append('csv_standart', csv as Blob);
        editProject({
          url: `/api/projects/${payload?.id}/`,
          data: formData,
        });
      }
    },
    [
      image,
      showNotification,
      data?.developerDetails?.id,
      developer?.value,
      supplierId,
      seller_supplier,
      csv,
      editProject,
    ]
  );

  const handleSave = React.useCallback(
    (payload: ProjectInformationType) => {
      if (!image || size(image.name) > 124) {
        showNotification({
          key: 'uploadPlan/uploadPlanRejected',
          message:
            'File name has to be shorter than 124 characters, Please rename your file.',
          severity: 'error',
        });
      } else {
        const formData = new FormData();

        const address: addressType = {
          street: payload.address,
          city: payload.city,
        };
        formData.append('name', payload.name);
        formData.append('number', payload.number);
        formData.append('developer', JSON.stringify(developer?.value));
        formData.append(
          'supplier',
          supplierId ?? seller_supplier?.toString() ?? ''
        );
        formData.append('address', JSON.stringify(address));
        // formData.append('supplier',JSON.stringify(''))
        if (image) formData.append('cover_photo', image as Blob);
        if (csv) formData.append('csv_standart', csv as Blob);
        callFetch(
          removeEmptyValues({
            data: formData,
          })
        );
      }
    },
    [
      image,
      showNotification,
      developer?.value,
      supplierId,
      seller_supplier,
      csv,
      callFetch,
    ]
  );

  const onAddressesUpdate = React.useCallback((event, newValue) => {
    setdeveloper(newValue);
    if (newValue != null) {
      setSearchFlag(true);
    } else {
      setSearchFlag(false);
    }
  }, []);

  return {
    isLoading,
    methods,
    reset,
    handleSave,
    handleEdit,
    onAddressesUpdate,
    image,
    csv,
    developer,
    register,
    searchFlag,
    isEditSuccess,
    isLoadingEdit,
    setImage,
    setCSV,
  };
};

export default useProjectInformation;
