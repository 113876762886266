import * as React from 'react';
import Avatar from 'components/@client/Avatar';
import { useAuthentication } from 'hooks';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { Button, ButtonProps } from '@material-ui/core';
import NoUserAvatar from 'assets/@client/nav/no_user_avatar.svg';
import Icon from 'components/Icon/Icon';

const MenuButton = (props: ButtonProps) => {
  const {
    avatar,
    profilePicture,
    fullName,
    token: isAuthenticated,
    loading,
  } = useAuthentication();

  return (
    <>
      <Frame {...props}>
        <Icon name="menu" color="white" />
        {!loading && (
          <Avatar
            className="dropdown-avatar"
            name={fullName}
            alt="avatar"
            src={isAuthenticated ? profilePicture ?? avatar : NoUserAvatar}
          />
        )}
      </Frame>
    </>
  );
};

export default MenuButton;

const Frame = styled(Button)`
  && {
    width: 85px;
    height: 37px;
    background: black;
    border: 0.8px solid #e9e9e9;

    border-radius: 40px;
    position: relative;
    ${rtl`
        margin-left: 30px;
    `}
    @media (max-width:900px) {
      ${rtl`
        margin-left: 15px;
    `}
    }
    &[open] {
      border: 0.8px solid #939090;
    }
  }

  .avatar-wrapper {
    position: initial;
  }

  .dropdown-avatar {
    width: 31px;
    height: 31px;
    position: absolute;
    right: 2.5px;
    top: 2.5px;
    font-size: 0.8rem;
    cursor: pointer;
  }

  .icon-menu {
    position: absolute;
    left: 10px;
    top: 9px;
  }
`;
