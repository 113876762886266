import * as React from 'react';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import Icons from 'assets/@client/file-icons';
import { MOBILE_RESOLUTION } from 'styles/sharedStyle';

export enum FileType {
  'application/pdf' = 'PDF',
  'image/png' = 'PNG',
  'image/jpeg' = 'JPG',
  'image/jpg' = 'JPG',
}

interface Props {
  type?: FileType;
  fileName?: string;
}

const FileDisplay: React.FC<Props> = ({
  type = FileType['application/pdf'],
  fileName = 'File-name.pdf',
}) => {
  return (
    <Container>
      <img src={Icons[type]} alt={type} />
      <Name>{fileName}</Name>
    </Container>
  );
};

export default FileDisplay;

const Container = styled.div.attrs({ className: 'file-display' })`
  display: flex;
  align-items: center;
  height: 110px;
`;

const Name = styled.div.attrs({ className: 'file-display-name' })`
  ${rtl`
    margin-left: 16px;
  `}
  font-size: 1rem;
  font-weight: 500;
  color: #2277cc;
  text-decoration: underline;
  @media ${MOBILE_RESOLUTION} {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 200px;
  }
`;
