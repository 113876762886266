import * as React from 'react';
import NavigationList from 'components/@client/NavigationList';
import LogoutModal from 'components/@client/LogoutModal';
import UserDetails from 'components/@client/UserDetails';
import { useRouter, useSession, useAuthentication } from 'hooks';
import { ClientRouter } from 'router/routes';
import { ClientRoot } from 'styles/sharedStyle';
import { Type } from 'constants/config';
import {
  topNavigationTabs,
  middleNavigationTabs,
  supplierNavTabs,
  teamPatriciaNavTabs,
} from './myAccount.config';

const MyAccount = () => {
  const { onLanguageChange } = useSession();
  const { type, supplierId, seller_supplier } = useAuthentication();

  const { history, query, pathname } = useRouter<{ dialog?: 'logout' }>();
  const isLogoutOpen = Boolean(query.dialog === 'logout');
  const onLogoutClose = () => history.replace(pathname);

  let topNavigationTabsNew = topNavigationTabs;
  if (type === 'SUPPLIER' || type === 'SELLER') {
    if (
      (type === 'SUPPLIER' &&
        (supplierId === '7' ||
          supplierId === '16' ||
          seller_supplier === 7 ||
          seller_supplier === 16)) ||
      (type === 'SELLER' &&
        (supplierId === '7' ||
          supplierId === '16' ||
          seller_supplier === 7 ||
          seller_supplier === 16))
    ) {
      topNavigationTabsNew[1].to = ClientRouter.PROPERTIES_SELLER;
    }
  }
  if (type !== 'SUPPLIER') {
    topNavigationTabsNew = topNavigationTabs.filter(
      el => el.visibleOnly !== 'SUPPLIER'
    );
  }
  return (
    <ClientRoot>
      <UserDetails onClick={() => history.push(ClientRouter.PROFILE)} />
      <NavigationList items={topNavigationTabsNew} />
      <NavigationList items={middleNavigationTabs(onLanguageChange)} />
      {type === Type.SUPPLIER && <NavigationList items={supplierNavTabs} />}
      {type === Type.TEAM_PATRICIA && (
        <NavigationList items={teamPatriciaNavTabs} />
      )}
      <LogoutModal onClose={onLogoutClose} open={isLogoutOpen} />
    </ClientRoot>
  );
};

export default MyAccount;
