import * as React from 'react';
import styled from 'styled-components';
import MuiAvatar, { AvatarProps } from '@material-ui/core/Avatar';
import { nameInitials } from 'utils/string.utils';
import { isEmpty } from 'utils/lodash.utils';
import defaultTheme from 'styles/theme';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';

const defaultBgColor = defaultTheme.colors.primary.lightMobile;
interface Props extends AvatarProps {
  name: string;
  alt: string;
  backgroundColor?: string;
  editMode?: boolean;
  customSrc?: string;
}

const Avatar: React.FC<Props> = ({
  name,
  src,
  backgroundColor = defaultBgColor,
  editMode = false,
  onClick,
  customSrc,
  ...props
}) => {
  const sx: React.CSSProperties = React.useMemo(
    () => ({
      background: !src && !isEmpty(name) ? backgroundColor : '',
      width: 64,
      height: 64,
      textTransform: 'uppercase',
      fontSize: '1.5rem',
      cursor: onClick ? 'pointer' : 'default',
    }),
    [backgroundColor, name, onClick, src]
  );
  return (
    <Wrapper>
      {editMode && (
        <EditMode>
          <FormattedMessage id={Message.EDIT_BUTTON} />
        </EditMode>
      )}
      <MuiAvatar src={customSrc ?? src} sx={sx} onClick={onClick} {...props}>
        {!isEmpty(name) ? nameInitials(name) : null}
      </MuiAvatar>
    </Wrapper>
  );
};

export default Avatar;

const Wrapper = styled.div.attrs({ className: 'avatar-wrapper' })`
  position: relative;
`;

const EditMode = styled.div.attrs({ className: 'edit-mode-backdrop' })`
  position: absolute;
  ${({ theme }) => theme.utils.centerFlex};
  height: 100%;
  width: 100%;
  background: rgba(79, 79, 79, 0.56);
  content: 'Edit';
  color: #ffffff;
  font-size: 0.85rem;
  font-weight: 400;
  z-index: 10;
  border-radius: 50%;
  user-select: none;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background: rgba(79, 79, 79, 0.3);
  }
`;
