import styled from 'styled-components';
import type { AutocompleteRenderOptionState } from '@material-ui/core/Autocomplete';
import { ListItem } from 'components/Autocomplete';
import Icon from 'components/Icon';
import type { Option } from 'components/Autocomplete/Autocomplete.config';
import { summarizeText } from 'utils/string.utils';
import SafeImg from 'components/SafeImg/SafeImg';

const DesignStyleProductOption = (existingIds: string[]) => (
  props: React.HTMLAttributes<HTMLLIElement>,
  option: Option,
  _?: AutocompleteRenderOptionState
) => {
  return (
    <ListItem key={option?.name} tabIndex={0} {...props}>
      <ItemName>
        <div className="item-wrapper">
          <CustomImg
            src={((option?.thumbnail as unknown) as { file: string })?.file}
            alt={(option?.name as string) ?? ''}
          />
          {summarizeText((option?.name as string) ?? '', 25)}
        </div>
        <Indicator exists={existingIds?.includes(option?.id as string)} />
      </ItemName>
    </ListItem>
  );
};

export default DesignStyleProductOption;

const CustomImg = styled(SafeImg)`
  width: 35px;
  height: 35px;
  background: transparent;
  border-radius: 25%;
  margin-right: 8px;
`;

const ItemName = styled.span`
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .item-wrapper {
    display: flex;
    align-items: center;
    max-width: '100px';
  }

  .plus {
    display: inline-block;
    opacity: 0.7;
    font-size: 1.125rem;
    margin-right: 5px;
  }
`;

const Indicator = styled(Icon).attrs(({ theme, exists }) => ({
  name: exists ? 'checkmark-circle' : 'plus',
  color: exists ? theme.colors.status.success : theme.colors.primary.dark,
  size: 14,
}))`
  opacity: 0.7;
  margin-left: auto;
  margin-right: 5px;
`;
