import * as React from 'react';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import Container from 'components/Modal/Container';
import CloseButton from 'components/Modal/CloseButton';
import ClearButton from 'components/Button/TextButton';
import ShowResultsButton from 'components/@client/Button';
import { useDisableOverflow } from 'hooks';
import type { Setter, Value } from 'types/common.types';
import FilterChip from '../FilterChip';

interface Props {
  isModalOpen: boolean;
  close: () => void;
  rooms: Value[] | null;
  setSelectedRoom: Setter<Array<number>>;
  selectedRoom: Array<number>;
  filterByRoom: (room: Array<number>) => void;
  title?: string;
}

const ChipsModal: React.FC<Props> = ({
  isModalOpen,
  close,
  rooms,
  setSelectedRoom,
  selectedRoom = [],
  filterByRoom,
  title,
}) => {
  const [selectedModalChip, setSelectedModalChip] = React.useState<
    Array<number>
  >(selectedRoom);

  const onClear = React.useCallback(() => {
    setSelectedModalChip([]);
  }, [setSelectedModalChip]);

  const onShowResults = React.useCallback(() => {
    filterByRoom(selectedModalChip);
    setSelectedRoom(selectedModalChip);
    close();
  }, [filterByRoom, selectedModalChip, setSelectedRoom, close]);

  useDisableOverflow(isModalOpen);

  return (
    <Root>
      <Container
        // $width={720}
        $borderRadius="16px"
        open={isModalOpen}
        onBackdropClick={close}
        disablePortal
      >
        <Content>
          <CloseButton onClick={close} className="close-button" />
          <Title>{title ?? 'Filter by Room'}</Title>
          <ChipsContainer>
            {rooms?.map(room => {
              return (
                <FilterChip
                  key={room.id}
                  label={room.name}
                  onClick={() => {
                    if (selectedModalChip.includes(room.id))
                      setSelectedModalChip(prev =>
                        prev.filter(e => e !== room.id)
                      );
                    else setSelectedModalChip(prev => [room.id]);
                  }}
                  selected={selectedModalChip.includes(room.id)}
                />
              );
            })}
          </ChipsContainer>
          <Actions>
            <ClearButton
              disableRipple
              className="clear-button"
              onClick={onClear}
            >
              Clear
            </ClearButton>
            <ShowResultsButton
              className="show-button"
              disabled={selectedRoom === selectedModalChip}
              onClick={onShowResults}
            >
              Show results
            </ShowResultsButton>
          </Actions>
        </Content>
      </Container>
    </Root>
  );
};

export default ChipsModal;

const Root = styled.div.attrs({ className: 'modal-root' })`
  && {
    .dialog-space {
      padding: 0 0 30px 0;
      height: auto;
      z-index: 100;
    }

    .dialog-backdrop {
      background: rgba(92, 87, 87, 0.57);
    }

    .MuiDialog-root {
      z-index: 1000 !important;
    }
  }
`;

const Content = styled.div`
  height: 100%;
  width: 100%;
  position: relative;

  && {
    .close-button {
      position: absolute;
      top: 24px;
      ${rtl`
      right: 30px;
      `}

      .close-icon {
        font-size: 38px;
        opacity: 1;
      }
    }
  }
`;

const Title = styled.h2`
  width: 90%;
  margin: 0 auto !important;
  padding: 24px 50px;
  font-weight: 500;
  font-size: 28px;
  color: #fc5c42;
  margin: 0;
  ${({ theme }) => theme.utils.centerFlex};
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.3);
`;

const ChipsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  padding: 24px 48px;
  width: 100%;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  justify-content: space-between;
  padding: 20px 50px;

  && {
    .clear-button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: #000;
      padding: 12px 24px;
      text-decoration-thickness: 1px;
      font-size: 16px !important;
      font-weight: 500 !important;
    }

    .show-button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 6px 24px;
      border-radius: 15px;
      font-size: 16px !important;
    }
  }
`;
