import * as React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { SelectProps } from '@material-ui/core/Select';
import styled, { css } from 'styled-components';
import Select from 'components/Select/BasicSelect';
import { menuProps } from 'components/Select/Select.config';
import { useBreakpoint } from 'hooks';
import FormLabel, { ILabel } from '../FormLabel/FormLabel';

interface Props extends Omit<SelectProps, 'variant'> {
  label?: ILabel;
  name: string;
  required?: true;
  maxHeight?: number;
  inputWidth?: string | number;
}

const min = { h: 262, w: 267 };
const max = { h: 322, w: 376 };

const FormSelect: React.FC<Props> = ({
  name,
  label,
  disabled,
  required,
  children,
  maxHeight,
  inputWidth,
  defaultValue,

  ...props
}) => {
  const [open, setOpen] = React.useState(false);
  const { control } = useFormContext();
  const { field, meta } = useController({ name, control, defaultValue });
  const { ref, ...fieldProps } = field;
  const { invalid } = meta;

  const { width } = useBreakpoint(min, max);

  const customMenuProps = {
    MenuListProps: {
      style: {
        padding: '8px 0 8px 8px',
      },
    },
    PaperProps: { style: { width: inputWidth ?? width, marginTop: '8px' } },
  };

  return (
    <Container $width={inputWidth}>
      {label && <FormLabel label={label} htmlFor={name} required={required} />}
      <Select
        {...fieldProps}
        {...props}
        inputRef={ref}
        displayEmpty
        MenuProps={{ ...menuProps, ...customMenuProps }}
        $isInvalid={invalid}
        open={open}
        disabled={disabled}
        onClick={() => !disabled && setOpen(prevState => !prevState)}
      >
        {children}
      </Select>
    </Container>
  );
};

export default FormSelect;

const Container = styled.div<{ $width?: string | number }>`
  display: flex;
  flex-direction: column;
  position: relative;
  ${({ $width }) =>
    $width &&
    css`
      width: ${$width} !important;
    `};

  label {
    position: relative;
    bottom: 2px;
  }

  & .Mui-disabled {
    background: ${({ theme }) => theme.colors.darkGray};
  }
`;
