import * as React from 'react';
import useRouter from './useRouter';

interface Props {
  when: Boolean;
}
const usePromptAPI = ({ when }: Props) => {
  const [nextLocation, setNextLocation] = React.useState<null | string>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const { history } = useRouter();
  const blockUrl = React.useRef<null | VoidFunction>();

  React.useEffect(() => {
    blockUrl.current = history.block(next => {
      if (when) {
        setNextLocation(next.pathname);
        setIsOpen(true);
      }
      return !when;
    });
    return blockUrl.current;
  }, [history, when]);

  const onCancel = React.useCallback(() => {
    setNextLocation(null);
    setIsOpen(false);
  }, []);

  const navigateToNextLocation = React.useCallback(() => {
    blockUrl.current?.();
    history.push(nextLocation ?? '');
  }, [history, nextLocation, blockUrl]);

  return {
    setIsOpen,
    onCancel,
    isOpen,
    navigateToNextLocation,
    nextLocation,
  };
};

export default usePromptAPI;
