import * as React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import styled, { css } from 'styled-components';
import rtl from 'styled-components-rtl';
import Modal from 'components/Modal';
import Loader from 'components/Loader';
import type { Setter } from 'types/common.types';
import CartModal from 'components/@client/IframeModals/CartModal/CartModal';
import BuyMorePatriciaCoinsDetail from 'pages/appClient/buy-more-patricia-coins/BuyMorePatriciaCoinDetail';
import InstructionsModal from 'components/@client/IframeModals/InstructionsModal';
import { MOBILE_RESOLUTION } from 'styles/sharedStyle';
import { ModalName, Payload } from '../config';

interface Props {
  currentModal: ModalName;
  isOpen: boolean;
  data: Payload;
  setCurrentModal: Setter<ModalName | null>;
}

const TourPlayerModal: React.FC<Props> = ({
  setCurrentModal,
  data,
  currentModal,
  isOpen,
}) => {
  const close = React.useCallback(() => setCurrentModal(null), [
    setCurrentModal,
  ]);
  const closeModal = React.useCallback(() => {
    setCurrentModal(null);
  }, [setCurrentModal]);
  const isMobile = useMediaQuery(MOBILE_RESOLUTION);
  const modalsMap = React.useRef<Record<ModalName, JSX.Element | null>>({
    [ModalName.TOUR_INSTRUCTIONS_MODAL]: <InstructionsModal close={close} />,
    [ModalName.QUOTE_MODAL]: null,
    [ModalName.CART_MODAL]: <CartModal changeModal={setCurrentModal} />,
    [ModalName.BUYCOIN_MODAL]: (
      <BuyMorePatriciaCoinsDetail
        justifyContent="center"
        changeModal={setCurrentModal}
      />
    ),
    [ModalName.ADDRESSES_MODAL]: null,
    [ModalName.BUY_NOW_MODAL]: null,
    [ModalName.BUY_SKIN_MODAL]: null,
    [ModalName.SUPPORT_MODAL]: null,
    [ModalName.SHARE_MODAL]: null,
    [ModalName.ADD_PROPERTY_MODAL]: null,
  });
  return (
    <ModalWrapper
      currentModal={currentModal}
      fullScreen={isMobile}
      open={isOpen}
      close={closeModal}
      whiteClose={currentModal === ModalName.TOUR_INSTRUCTIONS_MODAL}
    >
      <React.Suspense fallback={<Loader fullHeight />}>
        <Root>
          {modalsMap.current[currentModal]} {data && JSON.stringify(data)}
        </Root>
      </React.Suspense>
    </ModalWrapper>
  );
};

export default React.memo(TourPlayerModal);

const Root = styled.div``;

const ModalWrapper = styled(Modal)<{ currentModal: ModalName }>`
  .MuiDialog-paperWidthSm {
    max-width: none;
    width: 100%;
  }

  ${({ currentModal }) =>
    currentModal === ModalName.TOUR_INSTRUCTIONS_MODAL &&
    css`
      .MuiDialog-paperWidthSm {
        max-width: none;
        width: 100%;
        background: #000000;
        opacity: 80%;
      }

      .MuiPaper-root {
        margin: 0;
        height: 100%;
        max-height: initial;
      }
    `}

  && .MuiDialogActions-root {
    position: absolute;
    color: #fff;
    border-radius: 60%;
    ${rtl`
      right: 30px;
    `}
  }
`;
