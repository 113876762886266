import * as React from 'react';
import styled, { css } from 'styled-components';

interface Props {
  label?: string;
  onClick?: () => void;
  selected?: boolean;
  moreAreas?: boolean;
}

const FilterChip: React.FC<Props> = ({
  label,
  onClick,
  selected = false,
  moreAreas = false,
}) => {
  return (
    <Chip onClick={onClick} isSelected={selected} moreAreas={moreAreas}>
      {label}
    </Chip>
  );
};

export default FilterChip;

const selectedStyle = css`
  border: 0.5px solid #4e6c54;
  color: rgba(255, 255, 255, 0.9);
  background: #4e6c54;
  gap: 16px;
  @media (max-width: 800px) {
    border: 1px solid #fc5c42;
    color: #fc5c42;
    background: white;
  }
`;

const defaultStyle = `
border: 1px solid #D9D9D9;
  color: #090808;
  background:  #white;
`;

const Chip = styled.div<{ isSelected: boolean; moreAreas: boolean }>`
  height: 36px;
  border-radius: 10px;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 6px 24px;
  white-space: nowrap;
  gap: 16px;
  cursor: pointer;
  ${({ isSelected }) => (isSelected ? selectedStyle : defaultStyle)};
  transition: all 0.2s;
  ${({ moreAreas, isSelected }) =>
    moreAreas && !isSelected && 'color: #4E6C54'};
`;
