import {
  all,
  put,
  call,
  debounce,
  takeLatest,
  select,
} from 'redux-saga/effects';
import {
  ClientsAPI,
  OrdersAPI,
  PropertiesAPI,
  QuizResultsAPI,
} from 'api/methods';
import ClientsDataType from 'store/constants/user-management/clients.constants';
import * as clientsActions from 'store/actions/user-management/clients.actions';
import { showNotification } from 'store/actions/notifications.actions';
import * as selectors from 'store/selectors/clients.selector';
import * as Payload from 'store/types/user-management/clients.types';
import { GetOrdersDataResponsePayload } from 'store/types/user-management/orders.types';
import { Client, Room } from 'types/clients';
import { removeEmptyValues } from 'utils/common.utils';
import { newSortByMap } from 'utils/transform.utils';
import { createClientInfo } from 'mockData/client';
import { formatDate, FILTER_DATE_FORMAT } from 'utils/dates.utils';
import { API_ERROR_MESSAGE } from 'constants/common.constants';
import { Cart } from 'types/properties';
import { GetPropertiesDataParams } from '../types/user-management/properties.types';

function* errorHandler(
  e: Error & { status: number },
  type: ClientsDataType,
  rejectAction: (payload: {
    error: string;
  }) => { type: ClientsDataType; payload: { error: string } }
) {
  const error = e.message || API_ERROR_MESSAGE;
  yield put(showNotification({ key: type, message: error, severity: 'error' }));
  yield put(rejectAction({ error }));
}

const sortUsersMap = {
  full_name: 'full_name',
};

function* getUsersDataRequest({
  payload,
}: ReturnType<typeof clientsActions.getUsersDataRequest>) {
  try {
    const {
      page = 0,
      size = 10,
      search,
      status,
      countries,
      dateRange,
      sortBy,
      types,
      filterBy,
      is_archived = false,
      type,
      seller_supplier,
      archived_date__date__gte,
      archived_date__date__lte,
      created_at__date__gte,
      created_at__date__lte,
      events__created_at__date__gte,
      events__created_at__date__lte,
      events__name,
      return_time,
      properties__created_at__date__gte,
      properties__created_at__date__lte,
      events__created_at__date,
      retention__day,
      render_request__created_at__date__gte,
      render_request__created_at__date__lte,
    } = payload;

    const params = removeEmptyValues({
      page: page + 1,
      size,
      search,
      is_archived,
      types,
      countries: (countries as { iso: string; name: string }[])?.map(
        country => country.iso
      ),
      from_date: formatDate(dateRange?.startDate, FILTER_DATE_FORMAT, ''),
      to_date: formatDate(dateRange?.endDate, FILTER_DATE_FORMAT, ''),
      filter_by: filterBy,
      status,
      type,
      seller_supplier,
      archived_date__date__gte,
      archived_date__date__lte,
      created_at__date__gte,
      created_at__date__lte,
      events__created_at__date__gte,
      events__created_at__date__lte,
      events__name,
      return_time,
      properties__created_at__date__gte,
      properties__created_at__date__lte,
      events__created_at__date,
      retention__day,
      render_request__created_at__date__gte,
      render_request__created_at__date__lte,
      ...newSortByMap<typeof sortUsersMap>(sortUsersMap, sortBy),
    });
    const {
      results: users,
      count,
    }: Payload.GetUsersDataResponsePayload = yield call(
      ClientsAPI.getClientsUsers,
      {
        params: { ...params, is_archived: is_archived ?? false },
      } as Payload.GetUsersDataParams
    );
    const pageCount = Math.ceil(count / size);
    yield put(
      clientsActions.getUsersDataFulfilled({
        users,
        pageCount,
        userCount: count,
      })
    );
  } catch (e) {
    yield call(
      errorHandler,
      e,
      ClientsDataType.GET_USERS_DATA_REJECTED,
      clientsActions.getUsersDataRejected
    );
  }
}

function* getUserBasicDataRequest({
  payload,
}: ReturnType<typeof clientsActions.getUserDataRequest>) {
  try {
    const { userId } = payload;
    const clients: Record<string, Client> = yield select(
      selectors.allClientsMap
    );
    const selectedClient: Client = yield clients[userId] ?? {};
    const updatedUser: Client = yield call(ClientsAPI.getClientInfo, userId);
    const users = Object.values({
      ...clients,
      [userId]: { ...selectedClient, ...updatedUser },
    });
    yield put(clientsActions.getUserDataFulfilled({ users }));
  } catch (e) {
    yield call(
      errorHandler,
      e,
      ClientsDataType.GET_USER_DATA_REJECTED,
      clientsActions.getUserDataRejected
    );
  }
}

function* getRoomsDataRequest() {
  try {
    const roomData: Room[] = yield call(ClientsAPI.getRooms);
    yield put(clientsActions.getRoomsDataFulfilled({ rooms: roomData }));
  } catch (e) {
    yield call(
      errorHandler,
      e,
      ClientsDataType.GET_ROOMS_DATA_REJECTED,
      clientsActions.getUserDataRejected
    );
  }
}

function* getUserDataRequest({
  payload,
}: ReturnType<typeof clientsActions.getUserDataRequest>) {
  try {
    const { userId } = payload;
    const clients: Record<string, Client> = yield select(
      selectors.allClientsMap
    );
    const selectedClient: Client = yield call(ClientsAPI.getClientInfo, userId);
    const [products, orders, properties]: (
      | Payload.GetProductsDataResponsePayload
      | GetOrdersDataResponsePayload
      | Payload.GetPropertiesDataResponsePayload
    )[] = yield all([
      call(ClientsAPI.getClientProducts, {
        params: { size: 3, page: 1, client: userId },
      }),
      call(OrdersAPI.getOrders, {
        params: { size: 100, page: 1, client: userId },
      }),
      call(PropertiesAPI.getProperties, {
        params: {
          size: 3,
          page: 1,
          client:
            selectedClient.userType !== 'SELLER_CLIENT' ? userId : undefined,
          co_owner:
            selectedClient.userType === 'SELLER_CLIENT' ? userId : undefined,
        },
      }),
    ]);

    const response: Client = yield call(
      createClientInfo,
      selectedClient,
      properties as Payload.GetPropertiesDataResponsePayload,
      orders as GetOrdersDataResponsePayload
    );
    const clientInfo: Client = {
      ...response,
      products: products as Payload.GetProductsDataResponsePayload,
      orders: orders as GetOrdersDataResponsePayload,
      properties: properties as Payload.GetPropertiesDataResponsePayload,
    };

    const updatedClient: Client = { ...selectedClient, ...clientInfo };
    const users = Object.values({ ...clients, [userId]: updatedClient });
    yield put(clientsActions.getUserDataFulfilled({ users }));
  } catch (e) {
    yield call(
      errorHandler,
      e,
      ClientsDataType.GET_USER_DATA_REJECTED,
      clientsActions.getUserDataRejected
    );
  }
}

function* getProductsDataRequest({
  payload,
}: ReturnType<typeof clientsActions.getProductsDataRequest>) {
  try {
    const {
      page = 0,
      size = 10,
      search,
      status,
      dateRange,
      client,
      sortBy,
    } = payload;
    const params = removeEmptyValues({
      page: page + 1,
      size,
      search,
      status,
      client,
      from_date: formatDate(dateRange?.startDate, FILTER_DATE_FORMAT, ''),
      to_date: formatDate(dateRange?.endDate, FILTER_DATE_FORMAT, ''),
      ...newSortByMap<typeof sortUsersMap>(sortUsersMap, sortBy),
    });
    const {
      results,
      count,
    }: Payload.GetProductsDataResponsePayload = yield call(
      ClientsAPI.getClientProducts,
      yield {
        params,
      }
    );
    const pageCount = Math.ceil(count / size);
    const products = { results, pageCount };
    const clients: Record<string, Client> = yield select(
      selectors.allClientsMap
    );
    const selectedClient: Client = yield clients[client] ??
      call(ClientsAPI.getClientInfo, client);

    const updatedClient: Client = { ...selectedClient, products };
    const users = Object.values({
      ...clients,
      [selectedClient?.userID]: updatedClient,
    });
    yield put(clientsActions.getProductsDataFulfilled({ users }));
  } catch (e) {
    yield call(
      errorHandler,
      e,
      ClientsDataType.GET_PRODUCTS_DATA_REJECTED,
      clientsActions.getProductsDataRejected
    );
  }
}
function* getCartItemsDataRequest({
  payload,
}: ReturnType<typeof clientsActions.getCartItemsDataRequest>) {
  try {
    const cart: Cart = yield call(ClientsAPI.getCartItems, payload);
    yield put(clientsActions.getCartItemsDataFulfilled({ cart }));
  } catch (e) {
    yield call(
      errorHandler,
      e,
      ClientsDataType.GET_CART_ITEM_DATA_REJECTED,
      clientsActions.getCartItemsDataRejected
    );
  }
}

const propertiesSortMap = {};

function* getPropertiesDataRequest({
  payload,
}: ReturnType<typeof clientsActions.getPropertiesDataRequest>) {
  try {
    const {
      page = 0,
      size = 10,
      search,
      dateRange,
      withMedia,
      client,
      sortBy,
      co_owner,
    } = payload;
    const params = removeEmptyValues({
      page: page + 1,
      size,
      search,
      media_only: withMedia,
      client: co_owner !== '' ? '' : client,
      from_date: formatDate(dateRange?.startDate, FILTER_DATE_FORMAT, ''),
      to_date: formatDate(dateRange?.endDate, FILTER_DATE_FORMAT, ''),
      ...newSortByMap<typeof propertiesSortMap>(propertiesSortMap, sortBy),
      co_owner,
    });
    const {
      results,
      count,
    }: Payload.GetPropertiesDataResponsePayload = yield call(
      PropertiesAPI.getProperties,
      { params } as GetPropertiesDataParams
    );
    const pageCount = Math.ceil(count / size);
    const properties = { results, pageCount };
    const clients: Record<string, Client> = yield select(
      selectors.allClientsMap
    );
    const selectedClient: Client = yield clients[client] ??
      call(ClientsAPI.getClientInfo, client);

    const updatedClient: Client = { ...selectedClient, properties };
    const users = Object.values({
      ...clients,
      [selectedClient?.userID]: updatedClient,
    });
    yield put(clientsActions.getPropertiesDataFulfilled({ users }));
  } catch (e) {
    yield call(
      errorHandler,
      e,
      ClientsDataType.GET_PROPERTIES_DATA_REJECTED,
      clientsActions.getPropertiesDataRejected
    );
  }
}

const sortQuizResultsMap = {
  createdAt: 'created_at',
  quizId: 'quiz_id',
  designStyle: 'design_style',
};

function* getQuizResultDataRequest({
  payload,
  type,
}: ReturnType<typeof clientsActions.getQuizResultsDataRequest>) {
  try {
    const { page = 0, size = 10, sortBy, client } = payload;
    const params = removeEmptyValues({
      page: page + 1,
      size,
      client,
      ...newSortByMap<typeof sortQuizResultsMap>(sortQuizResultsMap, sortBy),
    }) as Payload.GetQuizResultsRequestPayload;

    const {
      results,
      count,
    }: Payload.GetQuizResultsDataResponsePayload = yield call(
      QuizResultsAPI.getQuizResults,
      {
        params,
      }
    );
    const pageCount = Math.ceil(count / size);
    const quizzes = { results, pageCount };
    const clients: Record<string, Client> = yield select(
      selectors.allClientsMap
    );
    const selectedClient: Client = yield clients[client] ??
      call(ClientsAPI.getClientInfo, client);

    const updatedClient: Client = { ...selectedClient, quizzes };
    const users = Object.values({
      ...clients,
      [selectedClient?.userID]: updatedClient,
    });
    yield put(clientsActions.getQuizResultsDataFulfilled({ users }));
  } catch (e) {
    yield call(
      errorHandler,
      e,
      type,
      clientsActions.getQuizResultsDataRejected
    );
  }
}

const sortUserActivityMap = {
  createdAt: 'created_at',
};

function* getUserActivitiesDataRequest({
  type,
  payload,
}: ReturnType<typeof clientsActions.getUserActivitiesDataRequest>) {
  try {
    const dataParams = removeEmptyValues({
      user: payload.user,
      size: payload.size,
      search: payload.search,
      from_date: formatDate(
        payload.dateRange?.startDate,
        FILTER_DATE_FORMAT,
        ''
      ),
      to_date: formatDate(payload.dateRange?.endDate, FILTER_DATE_FORMAT, ''),
      page: payload.page + 1,
      ...newSortByMap<typeof sortUserActivityMap>(
        sortUserActivityMap,
        payload.sortBy
      ),
    });
    const { results, count } = yield call(
      ClientsAPI.getClientActivities,
      dataParams as Payload.GetUserActivityRequestPayload
    );
    const pageCount = Math.ceil(count / payload.size);
    const userActivities = { results, pageCount };
    const clients: Record<string, Client> = yield select(
      selectors.allClientsMap
    );
    const selectedClient: Client = yield clients[payload.user] ??
      call(ClientsAPI.getClientInfo, payload.user);
    const updatedClient: Client = { ...selectedClient, userActivities };
    const users = Object.values({
      ...clients,
      [selectedClient?.userID]: updatedClient,
    });
    yield put(clientsActions.getUserActivitiesDataFulfilled({ users }));
  } catch (e) {
    yield call(
      errorHandler,
      e,
      type,
      clientsActions.getUserActivitiesDataRejected
    );
  }
}

function* clientsSaga() {
  yield all([
    debounce(500, ClientsDataType.GET_USERS_DATA_REQUEST, getUsersDataRequest),
    takeLatest(
      ClientsDataType.GET_USER_BASIC_DATA_REQUEST,
      getUserBasicDataRequest
    ),
    takeLatest(ClientsDataType.GET_ROOMS_DATA_REQUEST, getRoomsDataRequest),
    takeLatest(ClientsDataType.GET_USER_DATA_REQUEST, getUserDataRequest),
    takeLatest(
      ClientsDataType.GET_PRODUCTS_DATA_REQUEST,
      getProductsDataRequest
    ),
    takeLatest(
      ClientsDataType.GET_PROPERTIES_DATA_REQUEST,
      getPropertiesDataRequest
    ),
    takeLatest(
      ClientsDataType.GET_QUIZ_RESULTS_DATA_REQUEST,
      getQuizResultDataRequest
    ),
    takeLatest(
      ClientsDataType.GET_CART_ITEM_DATA_REQUEST,
      getCartItemsDataRequest
    ),
    takeLatest(
      ClientsDataType.GET_USER_ACTIVITY_DATA_REQUEST,
      getUserActivitiesDataRequest
    ),
  ]);
}

export default clientsSaga;
