import * as actions from 'store/actions/properties-management/PropertiesManagement.actions';
import PropertiesType from 'store/constants/properties-management/PropertiesManagement.constants';
import * as Payload from 'store/types/properties-management/PropertiesManagement.types';
import { PropertyTask } from 'types/properties';

export interface PropertyTasksState {
  loading: Record<PropertiesType, boolean>;
  error: Record<PropertiesType, null | string>;
  results: PropertyTask[];
  pageCount: number;
}

const REQUESTS = [PropertiesType.GET_PROPERTIES_DATA_REQUEST];

const initialState: PropertyTasksState = {
  loading: REQUESTS.reduce(
    (state, next) => ({ ...state, [next]: false }),
    {} as Record<PropertiesType, boolean>
  ),
  error: REQUESTS.reduce(
    (state, next) => ({ ...state, [next]: null }),
    {} as Record<PropertiesType, null | string>
  ),
  results: [],
  pageCount: 0,
};

const propertiesTasksReducer = (
  state: PropertyTasksState = initialState,
  action: actions.PropertiesTasksAction
): PropertyTasksState => {
  switch (action.type) {
    case PropertiesType.GET_PROPERTIES_DATA_REQUEST:
      return updateLoading(state, PropertiesType.GET_PROPERTIES_DATA_REQUEST);
    case PropertiesType.GET_PROPERTIES_DATA_FULFILLED:
      return updateSuccess(
        state,
        PropertiesType.GET_PROPERTIES_DATA_REQUEST,
        action.payload as Payload.GetPropertiesTasksFulfilledPayload
      );
    case PropertiesType.GET_PROPERTIES_DATA_REJECTED:
      return updateError(
        state,
        PropertiesType.GET_PROPERTIES_DATA_REQUEST,
        action.payload as Payload.GetPropertiesTasksRejectedPayload
      );
    default:
      return state;
  }
};

export default propertiesTasksReducer;

type requestType = PropertiesType.GET_PROPERTIES_DATA_REQUEST;

const updateLoading = (
  state: PropertyTasksState,
  requestType: requestType
) => ({
  ...state,
  loading: {
    ...state.loading,
    [requestType]: true,
  },
  error: {
    ...state.error,
    [requestType]: null,
  },
});

type ErrorPayload = Payload.GetPropertiesTasksRejectedPayload;
const updateError = (
  state: PropertyTasksState,
  requestType: requestType,
  { error }: ErrorPayload
) => ({
  ...state,
  loading: {
    ...state.loading,
    [requestType]: false,
  },
  error: {
    ...state.error,
    [requestType]: error,
  },
});

type SuccessPayload = Payload.GetPropertiesTasksFulfilledPayload;
const updateSuccess = (
  state: PropertyTasksState,
  requestType: requestType,
  payload: SuccessPayload
) => ({
  ...state,
  loading: {
    ...state.loading,
    [requestType]: false,
  },
  error: {
    ...state.error,
    [requestType]: null,
  },
  ...payload,
});
