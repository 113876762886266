import { FormattedMessage } from 'react-intl';
import {
  ClientRouter,
  ManagementRouter,
  SupplierDashBoardRoutes,
  SupplierRouter,
} from 'router/routes';
import { MenuItem } from 'components/Popper';
import LanguageToggle from 'components/@client/LanguageToggle';
import { stringify } from 'utils/string.utils';
import rtl from 'styled-components-rtl';
import { Message } from 'i18n';
import styled from 'styled-components';
import {
  LocalMallOutlined,
  LogoutOutlined,
  MapsHomeWorkOutlined,
  PermIdentityOutlined,
  PhoneInTalkOutlined,
  ShoppingCartOutlined,
} from '@mui/icons-material';
import Icon from 'components/Icon/Icon';
import { Storefront } from '@material-ui/icons';

const IconContainer = styled.div`
  ${rtl`
  margin-right: 10px;
`}

  & .icon-furnitures {
    stroke: black;
    stroke-width: 10px;
    width: 1.4rem;
    height: 1.4rem;
  }
  width: 24px;
  height: 24px;
`;

const Container = styled.div`
  display: flex;
`;

export const supplierNavTabs: MenuItem[] = [
  {
    component: (
      <Container>
        <IconContainer>
          <Storefront />
        </IconContainer>

        <FormattedMessage id={Message.HOME_SUPPLIER_CMS_TITLE} />
      </Container>
    ),
    to: SupplierRouter.DASHBOARD,
  },
];
export const teamPatriciaNavTabs: MenuItem[] = [
  {
    component: (
      <Container>
        <IconContainer>
          <Storefront />
        </IconContainer>

        <FormattedMessage id={Message.HOME_MANAGEMENT_TITLE} />
      </Container>
    ),
    to: ManagementRouter.APP,
  },
];

export const topNavigationTabs: MenuItem[] = [
  {
    component: (
      <Container>
        <IconContainer>
          <PermIdentityOutlined />
        </IconContainer>

        <FormattedMessage id={Message.MENU_ITEM_PROFILE} />
      </Container>
    ),
    to: ClientRouter.PROFILE,
    displayNormalText: true,
  },
  {
    component: (
      <Container>
        <IconContainer>
          <MapsHomeWorkOutlined />
        </IconContainer>

        <FormattedMessage id={Message.MENU_ITEM_PROPERTIES} />
      </Container>
    ),
    to: ClientRouter.PROPERTIES,
    displayNormalText: true,
  },
  {
    component: (
      <Container>
        <IconContainer>
          <Icon
            color="#000"
            name="supplier-shop"
            viewBox="0 0 24 24"
            width={22}
            height={22}
          />
        </IconContainer>

        <FormattedMessage id={Message.HOME_SUPPLIER_CMS_TITLE} />
      </Container>
    ),
    to: SupplierDashBoardRoutes.INFO,
    displayNormalText: true,
    visibleOnly: 'SUPPLIER',
  },
];

export const middleNavigationTabs: (
  onLanguageChange: VoidFunction
) => MenuItem[] = onLanguageChange => [
  {
    component: (
      <Container>
        <IconContainer>
          <ShoppingCartOutlined />
        </IconContainer>

        <FormattedMessage id={Message.MENU_ITEM_CART} />
      </Container>
    ),
    to: ClientRouter.CART,
    displayNormalText: true,
  },
  {
    component: (
      <Container>
        <IconContainer>
          <LocalMallOutlined />
        </IconContainer>
        <FormattedMessage id={Message.MENU_ITEM_ORDERS} />
      </Container>
    ),
    to: ClientRouter.ORDERS,
    displayNormalText: true,
  },
  {
    component: (
      <Container>
        <IconContainer>
          <Icon size={18} color="#000" name="furnitures" />
        </IconContainer>

        <FormattedMessage id={Message.MENU_ITEM_PRODUCTS} />
      </Container>
    ),
    to: ClientRouter.PRODUCTS,
    displayNormalText: true,
  },
  // {
  //   component: <FormattedMessage id={Message.MENU_ITEM_SUPPORT} />,
  //   to: ClientRouter.HELP,
  // },
  {
    component: (
      <Container>
        <IconContainer>
          <PhoneInTalkOutlined />
        </IconContainer>

        <FormattedMessage id={Message.MENU_ITEM_CONTACT} />
      </Container>
    ),
    to: ClientRouter.CONTACT,
    displayNormalText: true,
  },
  {
    component: <LanguageToggle />,
    onClick: onLanguageChange,
    displayNormalText: true,
  },
  {
    component: (
      <Container>
        <IconContainer>
          <LogoutOutlined fontSize="small" />
        </IconContainer>

        <FormattedMessage id={Message.MENU_ITEM_LOGOUT} />
      </Container>
    ),
    to: stringify({ dialog: 'logout' }),
    displayNormalText: true,
  },
];
