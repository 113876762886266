import * as React from 'react';
import styled from 'styled-components';
import LoginWelcomeImage from 'assets/@client/supplier-login-flow/supplier-login-welcome.png';
import CalendarIcon from 'assets/@client/supplier-login-flow/calendar_icon.png';
import { FormattedMessage, useIntl } from 'react-intl';
import { Message } from 'i18n';
import { PopupModal, useCalendlyEventListener } from 'react-calendly';
import { useRouter } from 'hooks';
import { ClientRouter } from 'router/routes';
import { MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';
import { useMediaQuery } from '@mui/material';
import {
  SupplierOnboardingAction,
  useSupplierOnboardingDispatch,
} from 'context/supplierOnboardingContext';
import SupplierLoginThanksMobile from './SupplierLoginThanksMobile';

const SupplierLoginThanks: React.FC = () => {
  const { history } = useRouter();
  const intl = useIntl();
  const isMobile = useMediaQuery(MOBILE_TABLET_RESOLUTION);
  const [open, setOpen] = React.useState(false);
  const dispatch = useSupplierOnboardingDispatch();
  useCalendlyEventListener({
    onEventScheduled: e => {
      dispatch({
        type: SupplierOnboardingAction.SET_IS_SHEDULED_MEETING,
        payload: true,
      });
    },
  });

  return (
    <>
      {isMobile ? (
        <SupplierLoginThanksMobile />
      ) : (
        <ParentLoginWelcome>
          <LeftContainer>
            <ParentDivText>
              <WelcomeTitle>
                <FormattedMessage id={Message.SUPPLIER_LOGIN_THANKS_TITLE} />
              </WelcomeTitle>
              <SubTitle>
                <FormattedMessage
                  id={Message.SUPPLIER_LOGIN_THANKS_FIRST_SUBTITLE}
                  values={{ linebreak: <br /> }}
                />
              </SubTitle>
              <ButtonContainer>
                <LetsDoThisButton
                  onClick={() => {
                    history.push(ClientRouter.SUPPLIER_STORE_INFO);
                  }}
                >
                  <LetsDoThisText>
                    <FormattedMessage
                      id={Message.SUPPLIER_LOGIN_THANKS_BUTTON}
                    />
                  </LetsDoThisText>
                </LetsDoThisButton>
                <StyledButton
                  className="calendly-button"
                  onClick={() => setOpen(true)}
                >
                  <StyledScheduleMeeting>
                    {intl.formatMessage({
                      id: Message.SUPPLIER_SCHEDULE_MEETING,
                    })}
                  </StyledScheduleMeeting>
                  <StyledImage src={CalendarIcon} alt="calendar-icon" />
                </StyledButton>
              </ButtonContainer>
              <PopupModal
                open={open}
                url="https://calendly.com/dan1707"
                rootElement={document.getElementById('root')!}
                onModalClose={() => {
                  setOpen(false);
                }}
              />
            </ParentDivText>
          </LeftContainer>
          <RightContainer>
            <img
              style={{ height: '100%' }}
              src={LoginWelcomeImage}
              alt="login-seller"
            />
          </RightContainer>
        </ParentLoginWelcome>
      )}
    </>
  );
};
export default SupplierLoginThanks;

const ButtonContainer = styled.div`
  display: flex;
`;

const ParentLoginWelcome = styled.div`
  display: flex;
  width: 100%;
  margin-top: -65px;
  font-family: 'Roboto Condensed';
`;
const ParentDivText = styled.div`
  margin: auto;
  .calendly-button {
    background: #fafcfe;
    border: 1px solid #fc5c42;
    border-radius: 15px;
    padding: 13px 16px;
    text-transform: none;
    color: #fc5c42;
    white-space: nowrap;
    line-height: 22px;
    height: 48px;
    display: flex;
    .calendly-popup-btn {
      line-height: 22px;
      border: none;
      color: inherit;
      border: none;
      background: inherit;
      font-size: inherit;
    }
    p {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
    }
  }
  .calendly-icon {
    color: #696969;
    margin-right: 6px;
  }
`;

const StyledScheduleMeeting = styled.p`
  margin: 0;
  position: relative;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
`;
const LeftContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RightContainer = styled.div`
  width: 50%;
`;

const WelcomeTitle = styled.div`
  font-weight: 600;
  font-size: 40px;
  line-height: 44px;
  color: #282929;
`;

const SubTitle = styled.div`
  font-size: 24px;
  line-height: 28px;
  color: #3e3e3e;
  margin-top: 24px;
`;

const LetsDoThisButton = styled.button`
  cursor: pointer;
  background: #fc5c42;
  border: 0.5px solid #d9d9d9;
  border-radius: 15px;
  padding: 12px 21px;
  width: 184px;
  height: 48px;
  margin-top: 24px;
`;

const StyledButton = styled.button`
  margin: 0 16px;
  margin-top: 24px;
  cursor: pointer;
`;

const LetsDoThisText = styled.div`
  display: flex;
  color: #ffffff;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
`;

const StyledImage = styled.img`
  margin-left: 4px;
`;
