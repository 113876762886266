import Loader from 'components/Loader/Loader';
import { useAuthentication } from 'hooks';
import { Message } from 'i18n';
import * as Section from 'pages/appClient/onboarding/shared';
import * as React from 'react';
import * as deviceInfo from 'react-device-detect';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import DigitalKey from 'assets/@client/onboarding/digital-key-horizontal.png';
import DigitalKeyHe from 'assets/@client/onboarding/digital-key-horizontal-he.png';
// import Divider from 'components/Divider/Divider';
import { MAX_TABLET_RESOLUTION, MOBILE_RESOLUTION } from 'styles/sharedStyle';
import FormWrapper from './FormWrapper';
import Illustrations from './Illustrations';
import NewSignUpForm from './NewSignUpForm';
// import OnboardingAuthProviders from './OnboardingAuthProviders';

const AdditionalInfo = () => {
  const { loading } = useAuthentication();
  const isMobile = !deviceInfo.isDesktop;
  const Wrapper = isMobile ? Illustrations : React.Fragment;
  const intl = useIntl();

  // eslint-disable-next-line
  const _window: any = window;
  return (
    <Root loader={loading}>
      {loading ? (
        <LoaderWrapper>
          <Loader fullHeight />
        </LoaderWrapper>
      ) : null}
      <Section.Container>
        <Wrapper>
          <Section.Header>
            <Section.TitleStack
              hideLogo={true}
              label={
                <FormattedMessage
                  id={Message.ONBOARDING_REGISTRATION_TITLE}
                  values={{
                    span: (chunks: string) => (
                      <span className="title-text">{chunks}</span>
                    ),
                  }}
                />
              }
            />
          </Section.Header>
          <div className="mobile-key-img">
            <img
              src={intl.locale === 'he' ? DigitalKeyHe : DigitalKey}
              alt="digital key"
              style={{ marginRight: 8 }}
            />
          </div>
          <FormWrapper>
            <ResponsiveWrapper>
              {/* {!_window.vuplex && (
                <>
                  <OnboardingAuthProviders isOnBoarding={true} />
                  <Divider>
                    <FormattedMessage id={Message.ORCONNECTWITH} />
                  </Divider>
                </>
              )} */}
              <NewSignUpForm />
            </ResponsiveWrapper>
          </FormWrapper>
        </Wrapper>
      </Section.Container>
    </Root>
  );
};

export default AdditionalInfo;

const Root = styled.div<{ loader: boolean }>`
  overflow: visible;
  width: 100%;
  opacity: ${({ loader }) => (loader ? 0.5 : 1)};
  span {
    color: ${({ theme }) => theme.colors.primary.lightMobile};
  }
  .mobile-key-img {
    margin: 0 auto;
    height: 65px;
    width: 139px;
    display: none;
    @media ${MAX_TABLET_RESOLUTION} {
      display: block;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .title-stack {
    margin-top: 32px;
    @media ${MOBILE_RESOLUTION} {
      margin-top: 24px;
      margin-bottom: 8px;
      line-height: 34px;
    }
  }
  .form-container {
    margin-top: 32px;
    @media ${MOBILE_RESOLUTION} {
      margin-top: 24px;
    }
  }
  .section-title-label {
    @media ${MOBILE_RESOLUTION} {
      font-size: 30px;
    }
  }
  .country-list {
    @media ${MOBILE_RESOLUTION} {
      width: 327px !important;
    }
    span {
      color: #696969;
    }
  }
  .country-list .country {
    @media ${MAX_TABLET_RESOLUTION} {
      margin-left: 10px;
    }
  }
`;

const LoaderWrapper = styled.div`
  z-index: 1;
  width: 100%;
  height: 100%;
  font-size: 0;
  position: absolute;
`;

const ResponsiveWrapper = styled.section`
  @media (min-width: 1150px) {
    display: flex;
    flex-direction: column;
    margin: auto;
    & .divider-wrapper {
      flex-direction: row;
      flex: 0.3;
      width: 384px;
      margin: 24px auto;
      > span {
        color: #696969;
        line-height: 22px;
        font-size: 18px;
      }
    }

    & .provider-button-wrapper {
      width: 100%;

      &:last-child {
        margin-bottom: 30px;
      }
    }
    & .provider-buttons {
      flex-direction: column-reverse;
      flex: 0.6;
      margin-bottom: 0;
      direction: ltr;
    }

    & .provider-button {
      &.Facebook {
        background: #2f80ed;
      }
      &.Google {
        background: #23643e;
      }
      &.Apple {
        background: rgba(0, 0, 0, 1);
      }
      color: white;
      max-height: 50px;
      width: 100%;

      & > span {
        gap: 15px;
        justify-content: center;
      }
      & img {
        border-radius: 50%;
        ${rtl`
          margin-right: 0;
        `}
      }
    }

    & .illustrations-wrapper {
      display: flex;
      justify-content: center;
      flex: 0.7;

      & .plant {
        position: absolute;
        left: 0;
      }
    }
  }
  @media ${MOBILE_RESOLUTION} {
    & .provider-buttons {
      margin-bottom: 16px;
      direction: ltr;
    }
    & .divider-wrapper {
      margin: 16px auto;
      > span {
        color: #696969;
        line-height: 145%;
        font-size: 18px;
      }
    }
  }
  position: relative;
`;
