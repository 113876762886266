import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import {
  createFilterOptions,
  FilterOptionsState,
} from '@material-ui/core/useAutocomplete';
import { PopperProps } from '@material-ui/core/Popper';
import styled from 'styled-components';
import noop from 'lodash/noop';
import Container from 'components/Autocomplete/Container';
import { CustomArrow } from 'components/Button/ArrowButton';
import CheckboxItem from 'components/Checkbox/CheckboxItem';
import { isEmpty } from 'utils/lodash.utils';
import theme from 'styles/theme';
import CircularProgress from '@material-ui/core/CircularProgress';
import type {
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
  AutocompleteRenderOptionState,
  AutocompleteRenderInputParams,
} from '@material-ui/core/Autocomplete';
import type { Option } from 'components/Autocomplete/Autocomplete.config';
import type { Setter } from 'types/common.types';
import useDebounce from './useDebounce';

type UseAutocompleteConfig = {
  disableSelectAll?: true;
  isOpen?: boolean;
  multiple?: boolean;
  options: Option[];
  placeholder: string;
  value?: Option[] | Option | string;
  width?: number | string;
  height?: number;
  allowedInput?: (val: string) => string;
  onClose?: () => void;
  onChange?: (
    event: React.SyntheticEvent<Element, Event>,
    value: string | Option | (string | Option)[] | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<Option | Option[]>
  ) => void;
  onClickAway?: () => void;
  renderOption?:
    | ((
        props: React.HTMLAttributes<HTMLLIElement>,
        option: Option,
        state: AutocompleteRenderOptionState
      ) => React.ReactNode)
    | undefined;
  setValue?: Setter<Option> | Setter<Option[]>;
  setOpen?: Setter<boolean>;
  debouncedInputValue?: string;
  autocompleteDelay?: number;
  loading?: boolean;
};

const useAutocomplete = ({
  disableSelectAll,
  isOpen,
  multiple,
  options,
  placeholder,
  value,
  width = 300,
  allowedInput,
  onChange: handleChange,
  onClickAway,
  onClose,
  renderOption: handleOption,
  setValue,
  setOpen,
  autocompleteDelay = 250,
  loading,
}: UseAutocompleteConfig) => {
  const [inputValue, setInputValue] = React.useState<string>('');
  const debouncedInputValue = useDebounce(inputValue, autocompleteDelay);

  const selectAllChecked = React.useMemo(
    () => value?.length === options?.length,
    [options?.length, value?.length]
  );

  const onInputChange = React.useCallback(
    (e, val) => {
      setInputValue(allowedInput ? allowedInput(val) : val);
      if (isEmpty(val)) setOpen?.(false);
      else setOpen?.(true);
    },
    [allowedInput, setOpen]
  );

  const onInputClear = React.useCallback(() => {
    setInputValue('');
    setOpen?.(false);
  }, [setOpen]);

  const onChange = React.useCallback(
    (
      event: React.SyntheticEvent<Element, Event>,
      newValue: string | Option | (string | Option)[] | null,
      reason: AutocompleteChangeReason,
      details?: AutocompleteChangeDetails<Option | Option[]>
    ) => {
      if (
        (event as React.KeyboardEvent).key === 'Backspace' &&
        reason !== 'select-option'
      )
        return undefined;

      if (!multiple) {
        const setItem = setValue as Setter<Option> | undefined;
        setItem?.(newValue as Option);
        handleChange?.(event, newValue, reason, details);
        return reason !== 'clear' ? onClose?.() : undefined;
      }

      const setItems = setValue as Setter<Option[]> | undefined;
      const newOptions = newValue as Option[];
      const isSelectAllClicked = newOptions.slice(-1)[0]?.name === 'All';

      if (isSelectAllClicked)
        return setItems?.(
          newOptions.length - 1 === options.length ? [] : options
        );

      return setItems?.(newOptions);
    },
    [options, setValue, multiple, onClose, handleChange]
  );

  const renderOption = React.useCallback(
    (
      props: React.HTMLAttributes<HTMLLIElement>,
      option: Option,
      state: AutocompleteRenderOptionState
    ) => {
      if (
        (disableSelectAll || !multiple || option?.name !== 'All') &&
        handleOption
      )
        return handleOption(props, option, state);
      return (
        <CustomItem key={option?.name} {...props}>
          <CheckboxItem value="All" checked={selectAllChecked} />
        </CustomItem>
      );
    },
    [disableSelectAll, multiple, handleOption, selectAllChecked]
  );

  const endAdornment = React.useMemo(
    () => (
      <InputAdornment position="end">
        {isEmpty(value ?? inputValue) ? (
          <CustomArrow open={isOpen} />
        ) : (
          <IconButton
            onClick={() =>
              (setValue && (setValue as Setter<Option[]>))?.([]) ||
              setInputValue('')
            }
          >
            <ClearIcon />
          </IconButton>
        )}
      </InputAdornment>
    ),
    [isOpen, setValue, value, inputValue]
  );

  const renderInput = React.useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField
        placeholder={placeholder}
        {...params}
        ref={params.InputProps.ref}
        variant="outlined"
        InputLabelProps={params.InputLabelProps}
        InputProps={{
          ...params.InputProps,
          notched: false,
          endAdornment,
          style: {
            margin: 0,
            outline: 'none !important',
            backgroundColor: 'white',
            fontSize: '0.875rem',
            fontWeight: 400,
            height: 48,
            width: 320,
            borderRadius: 8,
            border: `1px solid ${theme.colors.darkGray}`,
            color: theme.colors.gray,
            padding: '0 10px',
            cursor: 'pointer',
          },
          classes: { notchedOutline: 'input' },
        }}
        fullWidth
        disabled={true}
      />
    ),
    [placeholder, endAdornment]
  );

  const filterOptions = React.useCallback(
    (selection: Option[], state: FilterOptionsState<Option>) => {
      const filter = createFilterOptions<Option>();
      return disableSelectAll
        ? filter(selection, state)
        : [{ name: 'All' }, ...filter(selection, state)];
    },
    [disableSelectAll]
  );

  const PopperComponent = React.useCallback(
    ({ anchorEl, children }: PopperProps) => (
      <Container
        width={width}
        borderRadius={{ top: 10, bottom: 10 }}
        anchorEl={anchorEl}
        onClickAway={onClickAway || noop}
      >
        {loading ? <CustomLoader color="inherit" size={20} /> : children}
      </Container>
    ),
    [onClickAway, width, loading]
  );

  return {
    inputValue,
    debouncedInputValue,
    onChange,
    onInputChange,
    onInputClear,
    renderOption,
    renderInput,
    filterOptions,
    PopperComponent,
    setInputValue,
  };
};

export default useAutocomplete;

const CustomItem = styled.li`
  display: flex;
  align-items: center;
`;

const CustomLoader = styled(CircularProgress)`
  && {
    margin: 20px;
  }
`;
