import * as React from 'react';
import { useRouter } from 'hooks';
import styled, { css } from 'styled-components';
import rtl from 'styled-components-rtl';
import Icon from 'components/Icon';
import { MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';

const Title: React.FC<{
  title: string | React.ReactNode;
  backTo?: string;
  isModal?: boolean;
  onClose?: VoidFunction;
  customIcon?: string | React.ReactNode;
  showBack?: boolean;
}> = ({ title, backTo, isModal, onClose, customIcon, showBack = true }) => {
  const { history } = useRouter();
  const goBack = React.useCallback(
    () => (backTo ? history.replace(backTo) : history.goBack()),
    [backTo, history]
  );
  return (
    <Root isModal={isModal}>
      {onClose ? (
        <span
          tabIndex={0}
          role="button"
          className="back-btn"
          onClick={onClose}
          onKeyPress={onClose}
        >
          {customIcon && customIcon}
          {!customIcon && showBack && <Icon name="left-arrow" size={20} />}
        </span>
      ) : (
        <span
          tabIndex={0}
          role="button"
          className="back-btn"
          onClick={goBack}
          onKeyPress={goBack}
        >
          {customIcon && customIcon}
          {!customIcon && showBack && <Icon name="left-arrow" size={20} />}
        </span>
      )}

      <h3 className="title">{title}</h3>
    </Root>
  );
};

export default React.memo(Title);

const Root = styled.div.attrs({ className: 'client-top-title' })<{
  isModal?: boolean;
}>`
  margin: 48px 0px 0px 0px;
  width: 100%;
  display: flex;
  .title {
    margin: 0;
    align-self: center;
    font-weight: 500;
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.primary.clientTextColor};

    @media ${MOBILE_TABLET_RESOLUTION} {
      font-weight: 500;
      font-size: 24px;
    }
  }

  .back-btn {
    ${({ isModal }) =>
      !isModal &&
      css`
        display: none;
      `}
    align-items: center;
    cursor: pointer;
    ${rtl`
      margin: auto 4px auto 0;
      `}
    transform: rotate(${({ theme }) => (theme.dir === 'rtl' ? '180deg' : 0)});

    @media ${MOBILE_TABLET_RESOLUTION} {
      display: flex;
    }
  }
`;
