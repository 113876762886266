import * as React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import PopperMenu from 'components/Popper';
import { useAuthentication, useRouter, useSession } from 'hooks';
import LogoutModal from 'components/@client/LogoutModal';
import MenuButton from './MenuButton';
import { userItems, guestItems } from './menu.config';

interface Props {
  isAuthenticated: boolean;
}

const Menu: React.FC<Props> = ({ isAuthenticated }) => {
  const intl = useIntl();
  const { type, supplierId: ids, seller_supplier } = useAuthentication();
  const { isFromSupportedCountry } = useSession();
  const { history, query, pathname } = useRouter<{ dialog: 'logout' }>();
  const isLogoutOpen = query.dialog === 'logout';
  const toggleModal = React.useCallback((): void => {
    history.push(pathname);
  }, [history, pathname]);

  const { onLanguageChange } = useSession();

  const items = React.useMemo(
    () =>
      isAuthenticated
        ? userItems(
            pathname,
            onLanguageChange,
            intl,
            type,
            ids,
            seller_supplier
          )
        : guestItems(onLanguageChange, isFromSupportedCountry),
    [
      intl,
      isAuthenticated,
      isFromSupportedCountry,
      onLanguageChange,
      pathname,
      type,
      ids,
      seller_supplier,
    ]
  );

  return (
    <>
      <StyledPopperMenu
        disablePortal
        className="nav-popper"
        button={MenuButton}
        top={8}
        items={items}
      />
      <LogoutModal open={isLogoutOpen} onClose={toggleModal} />
    </>
  );
};

export default Menu;

const StyledPopperMenu = styled(PopperMenu)`
  button {
    background: black;
  }
  .list-paper {
    border-radius: 8px;
  }
  .menu-popper {
    margin: 5px !important;
  }
`;
