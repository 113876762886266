import Skeleton from '@material-ui/core/Skeleton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';

const SkinSkeleton = () => {
  const isMobile = useMediaQuery(MOBILE_TABLET_RESOLUTION);
  return (
    <Item>
      <PhotoSkeleton
        width={isMobile ? 143 : 305}
        height={isMobile ? 200 : 309}
      />
      <TextSkeleton width={100} />
    </Item>
  );
};

export default SkinSkeleton;

const Item = styled.li`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const PhotoSkeleton = styled(Skeleton).attrs({
  variant: 'rectangular',
})`
  border-radius: 25px;
  margin: 10px;
`;

const TextSkeleton = styled(Skeleton).attrs({ variant: 'text' })`
  ${rtl`
    margin-left: 15px;
  `}
`;
