import { SessionAction } from 'store/actions/session.actions';
import { Session } from 'store/constants/session.constants';
import { GetLocationFulfilledPayload } from 'store/types/session.types';
import {
  SupportedLanguage,
  SupportedCountry,
  SupportedCurrency,
} from 'types/common.types';

export interface SessionState {
  locale: SupportedLanguage;
  country: SupportedCountry | null;
  currency: SupportedCurrency;
  loading: boolean;
  error: string | null;
  showWelcomeMessage?: boolean;
}

const initialState: SessionState = {
  locale: SupportedLanguage.ENGLISH,
  country: null,
  currency: SupportedCurrency.USD,
  loading: false,
  error: null,
  showWelcomeMessage: true,
};

const sessionReducer = (
  state: SessionState = initialState,
  action: SessionAction
): SessionState => {
  switch (action.type) {
    case Session.SELECT_LANGUAGE:
      return { ...state, locale: action.payload as SupportedLanguage };
    case Session.GET_LOCATION_REQUEST:
      return { ...state, loading: true };
    case Session.GET_LOCATION_FULFILLED:
      return {
        ...state,
        loading: false,
        error: null,
        ...(action.payload as GetLocationFulfilledPayload),
      };
    case Session.GET_LOCATION_REJECTED:
      return { ...state, loading: false, error: action.payload as string };
    case Session.SET_SHOW_WELCOME_MESSAGE:
      return { ...state, showWelcomeMessage: action.payload as boolean };
    default:
      return state;
  }
};

export default sessionReducer;
