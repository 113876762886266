import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import socialIcons from 'assets/@client/social';
import SafeImg from 'components/SafeImg';
import Link from 'components/Link/Link';
import { useRouter } from 'hooks';
import HeroImage from 'assets/@client/magazine/hero_image.png';
import { userLogData } from 'mockData/userLogData';
import { LogsAPI } from 'api/methods';
import magazine from '../home/mock/magazine';

const MagazinePage = () => {
  const { query } = useRouter<{ magazineName: string }>();

  const magazineDetails = magazine.find(m => m.name === query.magazineName);

  return (
    <Root>
      <ImageContainer>
        <img src={HeroImage} alt="" />
      </ImageContainer>
      <DetailContainer>
        <h1>{magazineDetails?.name}</h1>
        <ProfileContainer>
          <div>
            <SafeImg />
            <span>מאת: {magazineDetails?.description}</span>
          </div>
          <SocialIcons>
            {socialIcons.map(({ alt, src, href }) => (
              <Link
                href={href}
                key={alt}
                onClick={() => {
                  LogsAPI.postUserLogs({
                    name: userLogData.eventName.shareArticle,
                    area: userLogData.eventArea.magazine,
                    section: userLogData.eventSection.magzine,
                    path: userLogData.eventPath.appMagazinePath,
                    metadata: {
                      article_Name: magazineDetails?.name,
                      share_plateform: alt,
                    },
                  });
                }}
              >
                <img src={src} alt={alt} />
              </Link>
            ))}
          </SocialIcons>
        </ProfileContainer>
        {magazineDetails?.component()}
      </DetailContainer>
    </Root>
  );
};

export default MagazinePage;

const Root = styled.div`
  direction: rtl;
`;

const ProfileContainer = styled.div`
  & > div {
    margin: 20px 0;
    display: flex;
    span {
      ${rtl`
        margin: auto 20px;
      `}
    }
    img {
      width: 40px;
      height: 40px;
    }
  }
`;

const ImageContainer = styled.div`
  width: 100vw;
  margin: 0 calc(-50vw + 50%);
  img {
    width: 100%;
    max-height: 400px;
  }
`;

const DetailContainer = styled.div`
  margin: 40px auto;
  max-width: 690px;
  padding: 0 20px;
  color: ${({ theme }) => theme.colors.black0};
  h1 {
    font-size: 3rem;
  }
  p,
  ul,
  li {
    font-size: 1.25rem;
    line-height: 2rem;
    letter-spacing: 0.05rem;
  }
  ul li {
    list-style-position: outside;
    margin-left: 1em;
  }
`;

const SocialIcons = styled.div`
  & :first-child {
    margin-right: 0;
  }
  & > a {
    margin: 0 10px;
    & > img {
      width: 29px;
      height: 29px;
    }
  }
`;
