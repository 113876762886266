import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import { ClientRouter } from 'router/routes';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import useIdeaKPIs from 'hooks/useIdeaKPIs';
import Breadcrumbs from 'components/@client/Breadcrumbs';
import SectionTitle from 'components/@client/SectionTitle';
import NotFound from 'components/@client/NotFound';
import { Supplier } from 'types/suppliers';
import Loader from 'components/Loader/Loader';
import { IdeasDetails } from 'types/products';
import DetailComponent from './DetailComponent';
import IdeaList from '../ideas';
import ProductSection from './ProductSection';

const DesktopIdeas: React.FC<{ idea?: IdeasDetails }> = ({ idea }) => {
  const {
    onGetIdeaDetails,
    ideaDetails,
    isLoading,
    hasError,
    getIdeaProducts,
    products,
  } = useIdeaKPIs(idea);
  const [supplierData, setSupplierData] = React.useState<Set<string>>();

  React.useEffect(() => {
    if (!idea) onGetIdeaDetails();
    getIdeaProducts();
  }, [getIdeaProducts, idea, onGetIdeaDetails]);

  React.useEffect(() => {
    const dt = new Set<string>();
    ideaDetails?.products?.forEach(e => {
      dt.add(JSON.stringify(e.supplier as Supplier));
    });
    setSupplierData(dt);
  }, [ideaDetails]);

  return (
    <>
      <Breadcrumbs
        links={[
          {
            to: ClientRouter.IDEAS_PAGE,
            children: <FormattedMessage id={Message.IDEAS_TITLE} />,
          },
          {
            to: ClientRouter.IDEA_PAGE,
            children: (
              <FormattedMessage
                id={
                  ideaDetails?.name?.replace(/\.[^/.]+$/, '') ??
                  (hasError ? Message.IDEAS_NOT_FOUND : Message.LOADING)
                }
              />
            ),
          },
        ]}
      />
      {isLoading && <Loader />}
      {hasError && <NotFound />}
      {ideaDetails && (
        <>
          <DetailComponent
            imageList={
              supplierData &&
              Array.from(supplierData).map(e => JSON.parse(e) as Supplier)
            }
            ideaDetails={{ ...ideaDetails, products }}
            isLoading={isLoading}
          />
          {products && products.length > 0 && (
            <>
              <IdeaContainer>
                <ProductSection
                  title={
                    <Title>
                      <FormattedMessage
                        id={Message.IDEAS_PRODUCT_TITLE}
                        values={{
                          count: (
                            <span className="count">
                              ({products.filter(a => !a.notForSale).length})
                            </span>
                          ),
                        }}
                      />
                    </Title>
                  }
                  product={products?.filter(a => !a.notForSale)}
                />
              </IdeaContainer>
            </>
          )}

          <IdeaContainer>
            <SectionTitle
              label={<FormattedMessage id={Message.IDEA_TAB_TITLE_TAB1} />}
            />
            <IdeaList isHeader={false} />
          </IdeaContainer>
        </>
      )}
    </>
  );
};
const IdeaContainer = styled.div`
  margin-top: 48px;
  .section-title-wrapper {
    margin: 0;
    margin-bottom: 48px;
    margin-right: 18px;
    hr {
      background: #a990dd;
      border: 2px solid #a990dd;
      height: 2px;
    }
  }
`;

const Title = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  align-items: center;
  color: #282929;
  .count {
    font-size: 20px;
    line-height: 23px;
    color: #fc5c42;
    ${rtl`
      padding-left: 8px;
    `}
  }
`;

export default DesktopIdeas;
