import * as React from 'react';
import { ClientRouter } from 'router/routes';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';
import { Message } from 'i18n';
import * as Form from 'components/forms';
import PatriciaLogo from 'assets/@client/supplier-login-flow/patricia-red-text.png';
import { countryOptions, ISOCode } from 'data/countries';
import { phonecodes } from 'data/phonecodes';
import { CountryOption } from 'components/Filters/Options';
import FlagIcon from 'components/FlagIcon/FlagIcon';
import { Country, Option } from 'components/Autocomplete/Autocomplete.config';
import { Setter } from 'types/common.types';
import Icon from 'components/Icon';
import { summarizeText } from 'utils/string.utils';
import { FormProvider, useForm } from 'react-hook-form';
import { useFetch, useAuthentication, useNotifications } from 'hooks';
import { METHODS } from 'api/client';
import {
  SupplierOnboardingAction,
  useSupplierOnboardingDispatch,
  useSupplierOnboardingState,
} from 'context/supplierOnboardingContext';
import { storeInfoSchema } from 'utils/validation.utils';
import { useMediaQuery } from '@mui/material';
import {
  MOBILE_RESOLUTION,
  MOBILE_TABLET_MIN_RESOLUTION,
  MOBILE_TABLET_RESOLUTION,
} from 'styles/sharedStyle';
import history from 'router/history';
import { Type } from 'constants/config';
import InputAdornment from '@mui/material/InputAdornment';
import { PhoneField } from 'components/forms';
import ImagePicker from '../components/ImagePicker';
import SupplierInfoStoreFormMobile from './SupplierInfoStoreFormMobile';
import CustomTooltip from '../components/CustomTooltip';

const SupplierInfoStore: React.FC = () => {
  const intl = useIntl();
  const isMobile = useMediaQuery(MOBILE_RESOLUTION);
  const [country, setCountry] = React.useState<Option | Country | null>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const { showNotification } = useNotifications();
  const state = useSupplierOnboardingState();
  const dispatch = useSupplierOnboardingDispatch();
  const { id, type, validateUser, supplierId } = useAuthentication();

  const onFailure = React.useCallback(
    (message: string) => {
      showNotification({
        key: 'clients/storeInfoRejected',
        message,
        severity: 'error',
      });
    },
    [showNotification]
  );

  const { callFetch: submitSupplierData, isLoading } = useFetch({
    initialUrl: '/api/suppliers/',
    config: {
      method: METHODS.POST,
    },
    onSuccess: () => {
      validateUser();
      history.push(ClientRouter.SUPPLIER_MAIN_DETAILS);
    },
    onFailure,
    skipOnStart: true,
  });

  const methods = useForm({
    resolver: yupResolver(storeInfoSchema),
    defaultValues: {
      storeName: state.storeInfo.storeName ?? '',
      countries: state.storeInfo.countries ?? null,
      phoneNumber: state.storeInfo.phoneNumber ?? '',
      website: state.storeInfo.website ?? '',
      logo: state.storeInfo.logo ?? null,
      taxId: state.storeInfo.taxId ?? '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
  });
  const { handleSubmit, setValue, formState } = methods;

  React.useEffect(() => {
    if (state?.storeInfo?.phoneNumber) {
      setValue('phoneNumber', state?.storeInfo?.phoneNumber, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
    if (state?.storeInfo?.countries) {
      setCountry(state.storeInfo.countries);
    }
  }, [setValue, state.storeInfo.countries, state.storeInfo?.phoneNumber]);
  React.useEffect(() => {
    if (state?.storeInfo?.logo) {
      setValue('logo', state.storeInfo.logo);
    }
  }, [setValue, state.storeInfo.logo]);
  const { isValid, isDirty } = formState;
  const onFormSubmit = React.useCallback(
    data => {
      dispatch({
        type: SupplierOnboardingAction.SET_STORE_INFO,
        payload: {
          ...data,
          countries: countryOptions.filter(e => e.iso === data.countries)[0],
        },
      });
      const formData = new FormData();
      if (data?.logo) formData.append('logo', data.logo);
      formData.append('user', JSON.stringify(id));
      formData.append('company_name', data.storeName);
      formData.append('countries', data.countries);
      formData.append(
        'additional_phone_number',
        `${phonecodes.filter(e => e.code === country?.iso)[0].dial_code}${
          data.phoneNumber
        }`
      );
      formData.append('webpage_link', data.website);
      formData.append('registered_id', data.taxId);
      if (state?.selectedCategories?.length)
        state.selectedCategories.map(category =>
          formData.append('categories', JSON.stringify(category))
        );
      submitSupplierData({
        url:
          type === Type.SUPPLIER
            ? `/api/suppliers/${supplierId}/`
            : `/api/suppliers/`,
        method: type === Type.SUPPLIER ? METHODS.PATCH : METHODS.POST,
        data: formData,
      });
    },

    [
      country?.iso,
      dispatch,
      id,
      state.selectedCategories,
      submitSupplierData,
      supplierId,
      type,
    ]
  );

  return isMobile ? (
    <SupplierInfoStoreFormMobile
      onSubmit={handleSubmit(onFormSubmit)}
      methods={methods}
      isValid={isValid}
      isDirty={isDirty}
      isLoading={isLoading}
      setCountry={e => setCountry(e)}
      country={country}
    />
  ) : (
    <ParentStoreInfo>
      <a href={ClientRouter.APP}>
        <PatriciaLogoContainer src={PatriciaLogo} alt="patricia-logo" />
      </a>
      <StoreInfoParent>
        <StoreInfo>
          <StoreInfoText>
            <FormattedMessage
              id={Message.SUPPLIER_STORE_INFO}
              values={{
                span: (chunks: string) => (
                  <span style={{ color: '#FC5C42' }}>{chunks}</span>
                ),
              }}
            />
          </StoreInfoText>
          <FormProvider {...methods}>
            <CustomForm onSubmit={handleSubmit(onFormSubmit)}>
              <FirstDiv>
                <FormFieldDiv
                  label={{
                    title: intl.formatMessage({
                      id: Message.SUPPLIER_STORE_NAME_LABEL,
                    }),
                    isBold: true,
                  }}
                  name="storeName"
                  type="text"
                  placeholder={intl.formatMessage({
                    id: Message.SUPPLIER_STORE_NAME_PLACEHOLDER,
                  })}
                  endAdornment={false}
                  required
                />
                <CountryDiv
                  className="combobox country"
                  width="250px"
                  label={{
                    title: (
                      <FormattedMessage id={Message.ADDRESS_FORM_COUNTRY} />
                    ),
                  }}
                  name="countries"
                  options={countryOptions}
                  searchPlaceholder={intl.formatMessage({
                    id: Message.COUNTRY_SEARCH_PLACEHOLDER,
                  })}
                  renderOption={CountryOption}
                  placeholder={intl.formatMessage({
                    id: Message.ADDRESS_FORM_SELECT_COUNTRY,
                  })}
                  required
                  selectedValue={country}
                  setSelectedValue={
                    ((option: Option | Country) => {
                      setCountry(option);
                    }) as Setter<Option>
                  }
                  setterKey="iso"
                  optionTextLimit={23}
                  renderValue={option => {
                    return option ? (
                      <FlagIcon
                        ISO={option.iso as ISOCode}
                        name={summarizeText(option.name?.toString(), 20)}
                      />
                    ) : null;
                  }}
                />
              </FirstDiv>
              <SecondDiv>
                <PhoneFieldDiv
                  label={{
                    title: intl.formatMessage({
                      id: Message.PHONE_NUMBER,
                    }),
                    isBold: true,
                  }}
                  name="phoneNumber"
                  type="text"
                  endAdornment={false}
                  placeholder={intl.formatMessage({
                    id: Message.SUPPLIER_PHONE_NUMBER_PLACEHOLDER,
                  })}
                  onChange={e => {
                    setValue('phoneNumber', e.target.value, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                    // setPhoneLocal(e.target.value);
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      {country ? (
                        <FlagIcon
                          ISO={
                            countryOptions.filter(e => e.iso === country.iso)[0]
                              .iso as ISOCode
                          }
                          name={summarizeText(
                            `+${
                              phonecodes.filter(e => e.code === country.iso)[0]
                                .dial_code
                            }`,
                            20
                          )}
                        />
                      ) : null}
                    </InputAdornment>
                  }
                  required
                />
                <FormFieldDiv
                  label={{
                    title: CustomTooltip({
                      label: intl.formatMessage({
                        id: Message.SUPPLIER_TAX_ID_LABEL,
                      }),
                      title: (
                        <FormattedMessage
                          id={Message.SUPPLIER_TAX_ID_TOOLTIP_TEXT}
                          values={{
                            linebreak: <br />,
                          }}
                        />
                      ),
                      open,
                      setOpen,
                    }),
                  }}
                  name="taxId"
                  type="text"
                  endAdornment={false}
                  placeholder={intl.formatMessage({
                    id: Message.SUPPLIER_TAX_ID_PLACEHOLDER,
                  })}
                />
              </SecondDiv>
              <ThirdDiv>
                <FormFieldDiv
                  label={{
                    title: intl.formatMessage({
                      id: Message.SUPPLIER_WEBSITE_LABEL,
                    }),
                    isBold: true,
                  }}
                  name="website"
                  type="text"
                  placeholder={intl.formatMessage({
                    id: Message.SUPPLIER_WEBSITE_PLACEHOLDER,
                  })}
                />
              </ThirdDiv>
              <ImagePickerDiv>
                <LogoParent>
                  <UploadLogoText>
                    <FormattedMessage
                      id={Message.SUPPLIER_UPLOAD_YOUR_LOGO_LABEL}
                    />
                  </UploadLogoText>
                  <ImagePicker
                    name="logo"
                    label="Click here to upload image"
                    control={methods.control}
                    onFileChange={e => {
                      if (e?.target?.files?.[0])
                        setValue('logo', e?.target.files[0], {
                          shouldDirty: true,
                          shouldValidate: true,
                        });
                    }}
                    defaultValue={state?.storeInfo?.logo}
                  />
                </LogoParent>
              </ImagePickerDiv>
              <MandatoryFields>
                <FormattedMessage id={Message.SUPPLIER_MANDATORY_FIELDS} />
              </MandatoryFields>
              <NextButtonParent>
                <NextButton
                  type="submit"
                  disabled={!isValid || !isDirty || isLoading}
                >
                  {isLoading ? (
                    <CustomIcon name="spinner" size={20} />
                  ) : (
                    <NextButtonText>
                      <FormattedMessage
                        id={Message.PROPERTY_ADD_PROPERTY_BUTTON_NEXT}
                      />
                    </NextButtonText>
                  )}
                </NextButton>
              </NextButtonParent>
            </CustomForm>
          </FormProvider>
        </StoreInfo>
      </StoreInfoParent>
    </ParentStoreInfo>
  );
};
export default SupplierInfoStore;

const ParentStoreInfo = styled.div`
  margin-top: -60px;
  font-family: 'Roboto Condensed';
  .image-picker {
    width: 250px;
    height: 200px;
    margin-bottom: 0;
    border: 1px dashed #e8eaeb;
    label {
      span {
        font-size: 16px;
        line-height: 20px;
        /* color: rgba(41, 41, 41, 0.2); */
      }
      svg {
        fill: #d9d9d9;
      }
    }
  }
  .MuiInputBase-root {
    width: 250px !important;
  }
  label {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #3e3e3e;
    margin-left: 0;
    font-family: 'Roboto Condensed';
  }
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    margin-top: 0;
  }
`;

const CustomForm = styled.form`
  margin-top: 48px;
`;

const PatriciaLogoContainer = styled.img`
  height: 140px;
  width: 88px;
  margin-top: 24px;
  margin-left: 184px;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    margin-top: 24px;
    margin-left: 24px;
    position: absolute;
  }
`;

const StoreInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StoreInfoText = styled.div`
  font-weight: 600;
  font-size: 40px;
  line-height: 44px;
`;

const FormFieldDiv = styled(Form.FormFieldSupplier)`
  label {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #3e3e3e;
    margin-left: 0;
  }
  .MuiInputBase-root {
    margin-top: 20px;
  }
  .MuiFormHelperText-contained {
    margin: 0px;
    width: 230px;
  }
  .icon-information-circle {
    margin-right: 4px;
    width: 12px;
    height: 12px;
  }
  .MuiFormHelperText-contained {
    margin: 0px;
    padding-top: 6px;
  }
`;
const PhoneFieldDiv = styled(Form.FormFieldSupplier)`
  label {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #3e3e3e;
    margin-left: 0;
  }
  .MuiInputBase-root {
    margin-top: 20px;
  }
  input {
    padding-left: -5px;
    padding:0px 5px;
  }
  .MuiInputAdornment-root {
    padding-right:0px;
  }
  .MuiInputAdornment-positionStart {
    margin-right:1px
  }
  .MuiFormHelperText-contained {
    margin: 0px;
    width: 230px;
  }
  .icon-information-circle {
    height: 11px;
  }
  .MuiFormHelperText-contained {
    margin: 0px;
    padding-top: 6px;
`;

const FirstDiv = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  .combobox {
    label {
      margin-bottom: 0;
    }
    .MuiFormControl-root {
      margin-top: -2px;
    }
  }
  .dropdown-placeholder {
    color: #3e3e3e;
  }
  .combobox .MuiFormControl-root {
    margin-top: 0px;
  }
`;

const SecondDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin-top: 28px;
`;

const ThirdDiv = styled.div`
  margin-top: 28px;
  display: flex;
  justify-content: center;
`;

export const MandatoryFields = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: #696969;
  margin-top: 24px;
  text-align: center;
`;

export const NextButton = styled.button`
  :disabled {
    background: #d9d9d9;
    cursor: default;
  }
  background: #fc5c42;
  border: 1px solid #d9d9d9;
  border-radius: 15px;
  padding: 6px 24px;
  height: 48px;
  width: 134px;
  margin-top: 32px;
  text-align: center;
  cursor: pointer;
`;

export const NextButtonText = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: rgba(255, 255, 255, 0.9);
`;

export const NextButtonParent = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const ImagePickerDiv = styled.div`
  display: flex;
  justify-content: center;
  label {
    width: 100%;
  }
  .label-image-picker {
    color: rgba(41, 41, 41, 0.2);
  }
`;

const LogoParent = styled.div``;

const UploadLogoText = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #3e3e3e;
  margin-top: 28px;
`;

const CountryDiv = styled(Form.ComboBox)`
  .combobox-search-input {
    width: 230px !important;
  }
`;

const CustomIcon = styled(Icon)`
  ${({ theme }) => theme.utils.spinAnimation};
  position: absolute;
  fill: #f9fafa;
`;

const StoreInfoParent = styled.div`
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  }
`;
export const PhoneInput = styled(PhoneField)`
  .phone-number-label {
    font-size: 18px;
  }
`;
