import DatabaseType from 'store/constants/suppliers-interface/database.constants';
import * as Payload from 'store/types/suppliers-interface/database.types';

export const getProductsDataRequest = (
  payload: Payload.GetProductsDataRequestPayload
) => ({
  type: DatabaseType.GET_PRODUCTS_DATA_REQUEST,
  payload,
});
export const getProductsDataRejected = (
  payload: Payload.GetProductsDataRejectedPayload
) => ({
  type: DatabaseType.GET_PRODUCTS_DATA_REJECTED,
  payload,
});
export const getProductsDataFulfilled = (
  payload: Payload.GetProductsDataFulfilledPayload
) => ({
  type: DatabaseType.GET_PRODUCTS_DATA_FULFILLED,
  payload,
});

export const getFilesDataRequest = (
  payload: Payload.GetFilesDataRequestPayload
) => ({
  type: DatabaseType.GET_FILES_DATA_REQUEST,
  payload,
});
export const getFilesDataRejected = (
  payload: Payload.GetFilesDataRejectedPayload
) => ({
  type: DatabaseType.GET_FILES_DATA_REJECTED,
  payload,
});
export const getFilesDataFulfilled = (
  payload: Payload.GetFilesDataFulfilledPayload
) => ({
  type: DatabaseType.GET_FILES_DATA_FULFILLED,
  payload,
});

export type DatabaseAction = ReturnType<
  | typeof getProductsDataRequest
  | typeof getProductsDataRejected
  | typeof getProductsDataFulfilled
  | typeof getFilesDataRequest
  | typeof getFilesDataRejected
  | typeof getFilesDataFulfilled
>;
