import {
  startOfWeek,
  startOfMonth,
  endOfWeek,
  endOfMonth,
  isBefore,
  addDays,
  isSameDay,
  isWithinInterval,
  isSameMonth,
  addMonths,
  isValid,
  min,
  max,
  parseISO,
  toDate,
  getYear,
  format,
  intervalToDuration,
} from 'date-fns';
import { DateRange } from 'components/DateRange/types';
import { NO_VALUE } from 'constants/common.constants';

export const DEFAULT_DATE_FORMAT = 'MM/dd/yyyy';

export const CLIENT_DATE_FORMAT = 'dd/MM/yyyy';

export const TABLE_DATE_FORMAT = 'dd-MMM-yyyy';

export const TABLE_TIME_FORMAT = 'dd-MMM-yyyy HH:mm';

export const FILTER_DATE_FORMAT = 'yyyy-MM-dd';

export const CLIENT_TEXT_DATE_FORMAT = 'dd MMM yyyy';

export const TIME_FORMAT = 'HH:mm';

export const chunks = <T>(array: ReadonlyArray<T>, size: number): T[][] =>
  Array.from({ length: Math.ceil(array.length / size) }, (_v, i) =>
    array.slice(i * size, i * size + size)
  );

export const getDaysInMonth = (date: Date) => {
  const startWeek = startOfWeek(startOfMonth(date));
  const endWeek = endOfWeek(endOfMonth(date));
  const days: Date[] = [];
  for (let curr = startWeek; isBefore(curr, endWeek); null) {
    days.push(curr);
    curr = addDays(curr, 1);
  }
  return days;
};

export const isStartOfRange = ({ startDate }: DateRange, day: Date) =>
  startDate && (isSameDay(day, startDate) as boolean);

export const isEndOfRange = ({ endDate }: DateRange, day: Date) =>
  (endDate && isSameDay(day, endDate)) as boolean;

export const inDateRange = ({ startDate, endDate }: DateRange, day: Date) =>
  (startDate &&
    endDate &&
    (isWithinInterval(day, { start: startDate, end: endDate }) ||
      isSameDay(day, startDate) ||
      isSameDay(day, endDate))) as boolean;

export const isRangeSameDay = ({ startDate, endDate }: DateRange) => {
  if (startDate && endDate) {
    return isSameDay(startDate, endDate);
  }
  return false;
};

type Falsy = false | null | undefined | 0 | '';

export const parseOptionalDate = (
  date: Date | string | Falsy,
  defaultValue: Date
) => {
  if (!date) return defaultValue;

  let parsed: Date;
  if (typeof date === 'string') parsed = parseISO(date);
  else parsed = toDate(date);
  if (!isValid(parsed)) return defaultValue;
  return parsed;
};

export const getValidatedMonths = (
  range: DateRange,
  minDate: Date,
  maxDate: Date
) => {
  const { startDate, endDate } = range;
  if (startDate && endDate) {
    const newStart = max([startDate, minDate]);
    const newEnd = min([endDate, maxDate]);

    return [
      newStart,
      isSameMonth(newStart, newEnd) ? addMonths(newStart, 1) : newEnd,
    ];
  }
  return [startDate, endDate];
};

export const isSameRange = (first: DateRange, second: DateRange) => {
  const { startDate: fStart, endDate: fEnd } = first;
  const { startDate: sStart, endDate: sEnd } = second;
  if (fStart && sStart && fEnd && sEnd) {
    return isSameDay(fStart, sStart) && isSameDay(fEnd, sEnd);
  }
  return false;
};

export const generateYears = (count: number) => {
  return Array.from({ length: count + 1 }).map(
    (_, i) => getYear(new Date()) - count + i
  );
};

export const transformDateString = (str: unknown) =>
  isValid(parseISO(str as string)) ? parseISO(str as string) : str;

export const formatDate = (
  date: Date | number | undefined | string,
  dateFormat = TABLE_TIME_FORMAT,
  placeholder = NO_VALUE
): string => {
  if (!date) return placeholder;

  const dateValue = transformDateString(date) as number | Date;
  return isValid(dateValue) ? format(dateValue, dateFormat) : placeholder;
};

export const formatTime = (
  timeValue: string | number,
  placeholder = NO_VALUE
): string => {
  const formatNumber = (num: number = 0) => (num > 9 ? num : `0${num}`);
  const d = Number(timeValue);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  const hDisplay = h ?? 0;
  const mDisplay = m ?? 0;
  const sDisplay = s ?? 0;
  const timeResult = `${formatNumber(hDisplay)}:${formatNumber(
    mDisplay
  )}:${formatNumber(sDisplay)}`;

  return timeResult !== '00:00:00' ? timeResult : placeholder;
};

interface DateDistance {
  startDate: Date | number | string;
  endDate?: Date;
}

export const getTimeDistance = ({
  startDate,
  endDate = new Date(),
}: DateDistance): Duration => {
  const dateValue = transformDateString(startDate) as number | Date;
  return intervalToDuration({
    start: dateValue,
    end: endDate,
  });
};

export { parseISO };
