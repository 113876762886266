import * as React from 'react';
import { useFormContext, useController } from 'react-hook-form';
import styled from 'styled-components';
import FormLabel, { ILabel } from '../FormLabel';

interface Props {
  name: string;
  label?: ILabel;
  placeholder?: string;
  required?: boolean;
  className?: string;
  handleChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  maxLength?: number;
}

const FormTextArea: React.FC<Props> = ({
  name,
  label,
  placeholder,
  required,
  className,
  handleChange,
  maxLength,
}) => {
  const { control } = useFormContext();
  const { field } = useController({ control, name });
  return (
    <Container className={className}>
      {label && <FormLabel label={label} htmlFor={name} required={required} />}
      <TextAreaField
        {...field}
        ref={field.ref}
        name={name}
        placeholder={placeholder}
        onChange={e => {
          handleChange?.(e);
          field.onChange(e);
        }}
        maxLength={maxLength}
      />
    </Container>
  );
};

export default FormTextArea;

const Container = styled.div``;

const TextAreaField = styled.textarea`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.darkGray};
  color: ${({ theme }) => theme.colors.primary.primaryTextColor};
  margin-top: 4px;
  resize: none;
  padding: 15px;
  font-size: 0.875rem;
  font-family: ${({ theme }) => (theme.dir === 'rtl' ? 'Assistant' : 'Roboto')};
  min-height: 90px;
  width: 100%;

  &::placeholder {
    color: ${({ theme }) => theme.colors.silver};
  }
  &:focus,
  &:hover {
    outline: 1px solid rgba(255, 255, 255, 0.3);
    border: 1px solid ${({ theme }) => theme.colors.primary.dark};
  }
`;
