import * as actions from 'store/actions/suppliers-interface/database.actions';
import DatabaseType from 'store/constants/suppliers-interface/database.constants';
import * as Payload from 'store/types/suppliers-interface/database.types';
import type { FileTask } from 'types/suppliers';
import type { Product } from 'types/products';

export interface MyDatabaseState {
  loading: Record<DatabaseType, boolean>;
  error: Record<DatabaseType, null | string>;
  results: Product[];
  files: { results: FileTask[]; pageCount: number };
  pageCount: number;
}

const REQUESTS = [
  DatabaseType.GET_PRODUCTS_DATA_REQUEST,
  DatabaseType.GET_FILES_DATA_REQUEST,
];

const initialState: MyDatabaseState = {
  loading: REQUESTS.reduce(
    (state, next) => ({ ...state, [next]: false }),
    {} as Record<DatabaseType, boolean>
  ),
  error: REQUESTS.reduce(
    (state, next) => ({ ...state, [next]: null }),
    {} as Record<DatabaseType, null | string>
  ),
  results: [],
  files: { results: [], pageCount: 0 },
  pageCount: 0,
};

const myDatabaseReducer = (
  state: MyDatabaseState = initialState,
  action: actions.DatabaseAction
): MyDatabaseState => {
  switch (action.type) {
    case DatabaseType.GET_PRODUCTS_DATA_REQUEST:
      return updateLoading(state, DatabaseType.GET_PRODUCTS_DATA_REQUEST);
    case DatabaseType.GET_PRODUCTS_DATA_FULFILLED:
      return updateSuccess(
        state,
        DatabaseType.GET_PRODUCTS_DATA_REQUEST,
        action.payload as Payload.GetProductsDataFulfilledPayload
      );
    case DatabaseType.GET_PRODUCTS_DATA_REJECTED:
      return updateError(
        state,
        DatabaseType.GET_PRODUCTS_DATA_REQUEST,
        action.payload as Payload.GetProductsDataRejectedPayload
      );
    case DatabaseType.GET_FILES_DATA_REQUEST:
      return updateLoading(state, DatabaseType.GET_FILES_DATA_REQUEST);
    case DatabaseType.GET_FILES_DATA_FULFILLED:
      return updateSuccess(
        state,
        DatabaseType.GET_FILES_DATA_REQUEST,
        action.payload as Payload.GetFilesDataFulfilledPayload
      );
    case DatabaseType.GET_FILES_DATA_REJECTED:
      return updateError(
        state,
        DatabaseType.GET_FILES_DATA_REQUEST,
        action.payload as Payload.GetFilesDataRejectedPayload
      );
    default:
      return state;
  }
};

export default myDatabaseReducer;

type requestType =
  | DatabaseType.GET_PRODUCTS_DATA_REQUEST
  | DatabaseType.GET_FILES_DATA_REQUEST;

const updateLoading = (state: MyDatabaseState, requestType: requestType) => ({
  ...state,
  loading: {
    ...state.loading,
    [requestType]: true,
  },
  error: {
    ...state.error,
    [requestType]: null,
  },
});

type ErrorPayload =
  | Payload.GetProductsDataRejectedPayload
  | Payload.GetFilesDataRejectedPayload;
const updateError = (
  state: MyDatabaseState,
  requestType: requestType,
  { error }: ErrorPayload
) => ({
  ...state,
  loading: {
    ...state.loading,
    [requestType]: false,
  },
  error: {
    ...state.error,
    [requestType]: error,
  },
});

type SuccessPayload =
  | Payload.GetProductsDataFulfilledPayload
  | Payload.GetFilesDataFulfilledPayload;
const updateSuccess = (
  state: MyDatabaseState,
  requestType: requestType,
  payload: SuccessPayload
) => ({
  ...state,
  loading: {
    ...state.loading,
    [requestType]: false,
  },
  error: {
    ...state.error,
    [requestType]: null,
  },
  ...payload,
});
