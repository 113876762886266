import * as React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { useController, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import Checkbox from 'components/Checkbox';

interface Props {
  disabled?: boolean;
  name: string;
  label: string | JSX.Element;
  defaultChecked?: true;
  onChange?: VoidFunction;
}

const FormCheckbox: React.FC<Props> = ({
  disabled,
  name,
  label,
  defaultChecked,
  onChange,
}) => {
  const { control } = useFormContext();
  const { field } = useController({ control, name });
  const { ref, ...props } = field;

  return (
    <CustomFormControl className="input-checkbox">
      <Checkbox
        {...props}
        inputRef={ref}
        className="checkbox-input"
        disabled={disabled}
        color="default"
        size="small"
        checked={field.value}
        defaultChecked={defaultChecked}
        onChange={(
          _e?: React.ChangeEvent<HTMLInputElement>,
          isChecked?: boolean
        ) => {
          onChange?.();
          field.onChange(isChecked);
        }}
      />
      <FormLabel>{label}</FormLabel>
    </CustomFormControl>
  );
};

export default FormCheckbox;

const CustomFormControl = styled(FormControl)`
  flex-direction: row;
  align-items: center;

  label {
    font-size: 0.8rem;
    margin-left: 6px;
  }

  .checkbox-input {
    ${rtl`
      margin-right: 7px;
    `}
  }
  .MuiFormLabel-root.Mui-focused {
    color: rgba(0, 0, 0, 0.54);
  }
`;
