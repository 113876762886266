export const additionalChargeSubCategory = [
  'Sinks',
  'Tubs',
  'Floor',
  'Wall',
  'Stoves',
  'Mixers',
  'Shower Heads',
  'Shower Rails',
];
