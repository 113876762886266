import * as React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Slide from '@material-ui/core/Slide';
import styled from 'styled-components';
import Modal, { ModalProps } from 'components/@client/Modal';
import { MOBILE_RESOLUTION } from 'styles/sharedStyle';
import PaymentForm from 'components/@client/PaymentForm/PaymentForm';
import type { Cart, Skin } from 'types/properties';
import { IAddress } from 'types/common.types';
import { CheckOutPersonalDetailPayload } from 'types/orders';
import { useSession } from 'hooks';
import { useIntl } from 'react-intl';
import StripeForm from 'components/@client/StripePayment/StripeForm';

interface Props extends ModalProps {
  cart?: Cart | null;
  personalDetail?: CheckOutPersonalDetailPayload | null;
  address?: IAddress | null;
  skin?: Skin | null;
  deliveryDate?: Date;
  hs?: string;
  publicKey: string;
  clientSecretKey: string;
}

const PaymentModal: React.FC<Props> = ({
  cart,
  personalDetail,
  address,
  skin,
  deliveryDate,
  hs,
  publicKey,
  clientSecretKey,
  ...props
}) => {
  const isMobile = useMediaQuery(MOBILE_RESOLUTION);
  const { country } = useSession();
  const intl = useIntl();
  const checkCountry = intl.locale === 'he' || country === 'IL';
  return (
    <Container
      fullWidth
      fullScreen={isMobile}
      TransitionComponent={Slide}
      transitionDuration={400}
      title=""
      {...props}
      actions={[]}
      conditionalMargin={checkCountry}
    >
      {country ? (
        <PaymentForm
          deliveryDate={deliveryDate}
          personalDetail={personalDetail}
          address={address}
          cart={cart}
          skin={skin}
          hs={hs}
        />
      ) : (
        <StripeForm
          deliveryDate={deliveryDate}
          personalDetail={personalDetail}
          address={address}
          cart={cart}
          skin={skin}
          hs={hs}
          publicKey={publicKey}
          clientSecretKey={clientSecretKey}
        />
      )}
    </Container>
  );
};

export default PaymentModal;

const Container = styled(Modal)<{ conditionalMargin: boolean }>`
  .MuiDialogContent-root {
    width: fit-content;
    @media (min-width: 1150px) {
      overflow-y: hidden;
    }

    @media (max-width: 500px) {
      padding: 0;
    }
  }
  &&.client-dialog .MuiDialog-paper {
    border-radius: 12px;
    width: fit-content;
  }

  &&.client-dialog .MuiDialog-paper .MuiDialogTitle-root {
    font-size: 1.25rem;
    h2 {
      font-weight: 700;
    }
  }

  & .MuiDialogContent-root {
    padding: 0;
    margin: 0;
    /* margin: ${({ conditionalMargin }) =>
      conditionalMargin ? '0px' : '40px'}; */
    color: rgba(21, 25, 32, 0.5);
    @media (max-width: 500px) {
      padding: 0;
    }
  }
`;
