import Desktop from './skins-desktop-grid.png';
import DesktopHebrew from './skins-desktop-grid_hebrew.png';
import Tablet from './skins-tablet-grid.png';
import Mobile from './skins-mobile-grid.png';
import HeaderBg1 from './skins-header-bg1.png';
import HeaderBg2 from './skins-header-bg2.png';
import HeaderBg3 from './skins-header-bg3.png';
import SkinLock from './skin-lock.png';
import Image_1 from './image_1.png';
import Image_2 from './image_2.png';

const PhotoGrid = {
  Desktop,
  DesktopHebrew,
  Tablet,
  Mobile,
  HeaderBg1,
  HeaderBg2,
  HeaderBg3,
  SkinLock,
  Image_1,
  Image_2,
};
export default PhotoGrid;
