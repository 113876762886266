import * as React from 'react';
import styled from 'styled-components';
import ImagePreview from 'components/ImagePreview';
import { useController, useFormContext } from 'react-hook-form';
import { useImagePreview } from 'hooks';
import Chip from 'components/Chip';
import Tooltip from 'components/Tooltip';
import { summarizeText } from 'utils/string.utils';

interface Props {
  name: string;
  label: string;
  required?: boolean;
  defaultValue?: string;
  multiple?: boolean;
  acceptFileType?: string;
  filesValue?: (data: File[]) => void;
}

const FileField: React.FC<Props> = ({
  label,
  name,
  required,
  defaultValue,
  multiple = false,
  acceptFileType = '*',
  filesValue,
}) => {
  const { setValue, control } = useFormContext();

  const {
    field: { ref },
  } = useController({ name, control });
  const [isHovering, setIsHovering] = React.useState<boolean>(false);

  const { selectedFiles, clearPreview } = useImagePreview(
    ref.current?.files ?? null,
    name,
    defaultValue,
    multiple
  );

  const onClear = React.useCallback(() => {
    clearPreview();
    setValue(name, undefined);
  }, [clearPreview, setValue, name]);

  React.useEffect(() => {
    setValue(name, selectedFiles);
    if (filesValue) {
      filesValue(selectedFiles);
    }
  }, [selectedFiles, setValue, name, filesValue]);

  return (
    <>
      <Container
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <label htmlFor={name}>
          <ImagePreview
            alt={name}
            isHovering={isHovering}
            label={label}
            src=""
            onClear={onClear}
            required={required}
          />
          <input
            ref={ref}
            name={name}
            id={name}
            hidden
            type="file"
            accept={acceptFileType}
            multiple
          />
        </label>
      </Container>
      {selectedFiles.length > 0 && (
        <div>
          Files:
          {selectedFiles.map((file: File) => {
            return (
              <Tooltip $variant="info" key={file.name} title={file.name}>
                <Chip label={summarizeText(file.name, 10)} />
              </Tooltip>
            );
          })}
        </div>
      )}
    </>
  );
};

export default FileField;

const Container = styled.div`
  position: relative;
  ${({ theme }) => theme.utils.centerFlex};
  cursor: pointer;
  font-size: 0.875rem;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.silver};
  height: 150px;
  width: 150px;
  background: #f7f7f7;
  background-clip: content-box;
  border: 1px dashed #707070;
  border-radius: 8px;
  & label {
    max-width: 150px;
    max-height: 150px;
    position: relative;
    user-select: none;
    ${({ theme }) => theme.utils.centerFlex}
    flex-direction: column;
    gap: 7px;
    align-self: center;
    cursor: pointer;
    height: 100%;
    width: 100%;
  }
`;
