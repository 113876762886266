import * as React from 'react';
import { AutocompleteRenderGroupParams } from '@material-ui/core/Autocomplete';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListSubheader from '@material-ui/core/ListSubheader';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import styled from 'styled-components';

const LISTBOX_PADDING = 8;

const renderRow = (props: ListChildComponentProps) => {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };
  if (dataSet.group) {
    return (
      <ListSubheader
        key={dataSet.key}
        className="group-title"
        component="div"
        style={inlineStyle}
      >
        {dataSet.group}
      </ListSubheader>
    );
  }

  return <ListItem {...dataSet.props} noWrap style={inlineStyle} />;
};

function useResetCache(data: React.ReactChild) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement>((props, ref) => {
  const { children, ...other } = props;
  const itemData: React.ReactChild[] = [];
  (children as React.ReactChild[]).forEach(
    (item: React.ReactChild & { children?: React.ReactChild[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    }
  );
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 40 : 50;
  const gridRef = useResetCache(itemCount);

  const getChildSize = (child: React.ReactChild & { group?: unknown }) => {
    if (child.group) return 50;
    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > LISTBOX_PADDING) return LISTBOX_PADDING * itemSize;
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  return (
    <div ref={ref} className="list-box-component">
      <OuterElementContext.Provider value={other}>
        <CustomList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={index => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </CustomList>
      </OuterElementContext.Provider>
    </div>
  );
});

export default ListboxComponent;

export const Group = ({ group, ...params }: AutocompleteRenderGroupParams) => (
  <div {...params}>
    <ListSubheader key={params.key} className="group-title" component="div">
      {group}
    </ListSubheader>
    {params.children}
  </div>
);

const CustomList = styled(VariableSizeList).attrs({ className: 'custom-list' })`
  background: white;
  ${({ theme }) => theme.utils.scrollbar};

  @media (max-height: 900px) {
    max-height: 170px;
  }
  & .group-title {
    background-color: #ffffff;
    color: ${({ theme }) => theme.colors.primary.dark};
  }
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  padding: 0;
  padding-left: 8px;

  .checkbox-input {
    margin-right: 15px;
  }
`;
