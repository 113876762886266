import styled from 'styled-components';
import Logo from 'components/Logo';
import LogoStack from 'pages/common/layout/Footer/LogoStack';
import { TermsOfUseFooter } from 'styles/sharedStyle';
import { Link } from 'react-router-dom';

const PrivacyPolicyEn: React.FC = () => {
  return (
    <Container>
      <Link to="/">
        <Logo className="logo" direction="ltr" />
      </Link>
      <div>
        <div>
          <Body>
            <Title>Privacy Policy</Title>
            <p>
              This Privacy Policy (hereinafter: &quot;
              <strong>Privacy Policy</strong>&quot;(hereinafter: "Privacy
              Policy") applies to the manner in which we at Visual Estate Ltd.
              (hereinafter: "the Company") use personal information collected
              (as defined below), received or maintained by us in connection
              with the use of the Platform for Sale. Online of products in the
              field of home design and delivery of products for the home,
              operated by the company on the company's website and / or in any
              other media configuration, including the content and services
              offered on it (hereinafter: "the website" or "the services")
            </p>
            <ol>
              <li>
                <strong>introduction.</strong> We recognize the importance of
                privacy. This privacy policy sets out the procedures by which
                the company collects and uses information about you and other
                users of the site, including personal information..&quot;
                <strong>Personal information</strong>&quot; means any
                information that may be used, alone or together with other
                information, for personal identification of a person, including,
                First name and last name Email address and personal and other
                information.
              </li>
              <br />

              <li>
                <strong>Terms of Use</strong> This Privacy Policy is part of the
                Terms of Use of the Website, published under the tab
                [https://www.patricia-ai.com/app/terms-of-use]
                (hereinafter:&quot;
                <strong> Terms of Use </strong>&quot;) All highlighted terms not
                defined in the Policy This privacy will carry the meaning given
                to them in the terms of use. It will be clarified that this
                policy is worded in female language for convenience only but it
                (and of course the site), applies to all genders alike.
              </li>
              <br />

              <li>
                <strong>Consent and change of privacy policy</strong> You are
                not legally obligated to provide us with personal information,
                and you hereby confirm that the provision of the information by
                you is done of your own free will. Your use of the Services
                constitutes your agreement to the terms of this Privacy Policy
                and the collection, processing and sharing of information
                (including personal information), as set forth in this Privacy
                Policy. We reserve the right, in our sole discretion, to change
                this Privacy Policy at any time. Such change will take effect
                three (3) days after the publication of the amended Privacy
                Policy on the Site, and your continued use of the Services
                thereafter means that you accept these changes.
              </li>
              <br />

              <li>
                <ul>
                  <strong>
                    The type of personal information we collect and how it is
                    collected.{' '}
                  </strong>
                  <li>
                    <u>Information provided by you: </u>
                    Browsing part of the site does not require the provision of
                    identifying information. However, some of the services on
                    the site require identification for the purpose of
                    performing actions on the site, such as: registering on the
                    site or registering for some of the services and / or
                    purchasing products and services through the site. When
                    registering for the site and / or ordering other products
                    and services through the site, you will be asked to provide
                    us with identifying information such as: full name, age,
                    e-mail address, telephone (including mobile phone), ID card,
                    exact shipping address (if necessary in case of product
                    purchase ), Details of means of payment and any additional
                    details we may require during the ordering process. And any
                    additional information provided by you as part of the use of
                    the Site. The required information fields must be explicitly
                    marked. Please note that by law you are not obliged to
                    provide the requested details, but providing incorrect
                    details or providing partial details may deprive you of the
                    opportunity to use the services on the site and / or impair
                    the quality of the service.
                    <br />
                    <br />
                    When you provide personal information of a third party, and
                    / or the details of the person / body to whom the products
                    you ordered will be sent, you declare and confirm that you
                    are authorized to do so and that you have received express
                    consent to provide this information to the Company.
                    <br />
                    <br />
                    <u>Information collected during the use of the site: </u>
                    During your use of the site, information (including personal
                    information) about you may accumulate as well as information
                    about your practices on the site, including the date and
                    time you entered the site, information or advertisements you
                    read, pages and products you viewed, computer location and
                    web address data. Geo-location, purchases you made through
                    the site, type of browser you use, type of end device you
                    use (including device type of operating system), Advertising
                    ID assigned to your device, end device ID number (Device
                    ID), the offers and services that interested you, and more.
                    In addition, the Company may collect and use the services of
                    third parties to collect and analyze anonymous, statistical,
                    or aggregate information regarding your use of the Site,
                    including information regarding your information (such as
                    gender or age group) and your activity on the Site.
                  </li>
                </ul>
              </li>
              <br />
              <li>
                <ol>
                  <strong>How we use the information we collect</strong>
                  <li>
                    <u>Use of personal information: </u>
                    We may use personal information (whether provided by you or
                    collected by us as part of your use of the site and the
                    services offered on the site) as mentioned above, in the
                    following ways:
                    <ol>
                      <li>
                        In order to provide you with the requested services.{' '}
                      </li>
                      <br />
                      <li>
                        In order to operate and improve our site and the
                        services included in it and for the purpose of control,
                        research and development of new services.{' '}
                      </li>
                      <br />
                      <li>
                        In order to provide you with a more consistent,
                        continuous and personal user experience in the
                        communication between us and in the use of our services.{' '}
                      </li>
                      <br />
                      <li>
                        In order to contact you regarding the services for which
                        you have contacted us (including regarding the purchases
                        you have made through the website).{' '}
                      </li>
                      <br />
                      <li>
                        For the purpose of purchasing products and services on
                        the websites - including publishing information and
                        content on your behalf. It is clarified that content
                        that you submit for advertising on the site and / or
                        through the site may be exposed to Internet surfers, and
                        in particular to the site's surfers. If you post your
                        personal information as part of such publications you
                        may receive messages without request. Therefore, we
                        recommend that you publish only information that you
                        want to be accessible to everyone.
                      </li>
                      <li>
                        To adapt ads and content that will be displayed to you
                        when you visit the site and other sites to your areas of
                        interest and / or to a sex group or age and / or
                        destruction of your residence.{' '}
                      </li>
                      <li>
                        In order to send you products and samples for
                        experimentation and to mail you, including through
                        direct mail, information, promotions, marketing and
                        advertising materials about the company and / or anyone
                        on its behalf, including subsidiaries, business
                        partners, and / or other companies, at the company's
                        discretion.{' '}
                      </li>
                    </ol>
                  </li>
                  <br />
                  <li>
                    <u>Use of anonymous information: </u>
                    We may use anonymous information (as defined below) or
                    disclose it to third party service providers, in order to
                    improve our services and your user experience in the use of
                    the services. We may also disclose anonymous information
                    (For or without consideration) to third parties, including
                    advertisers and publishers. "Anonymous information" means
                    information that does not allow the identification of an
                    individual user, such as cumulative information about the
                    use of the website and / or services.
                  </li>
                  <br />
                  <li>
                    <u>Use of credit card information:</u>
                    When you order or purchase a product or service and the
                    clearing is done by us, we use the credit card information
                    provided to us when making the order only to make and
                    deliver your order, and confirm receipt by you. The details
                    of the credit card (as well as any additional personal
                    information required such as name, ID card, and address
                    (physical and / or e-mail) to send an invoice) will be made
                    through the site directly to the secure clearing services
                    system and the details will not be stored anywhere else. At
                    the end of the process, your credit card information will be
                    deleted and will not be saved in the system. For each new
                    order you will be asked to fill in the credit card details
                    again. We do not disclose your credit card information, and
                    we do not disclose your credit card information to any other
                    third party, except in cases where it is required to
                    complete the execution of your order and except as set forth
                    in this Privacy Policy. It will also be clarified that some
                    of the complexes on the site allow you to purchase and / or
                    order a product or service directly from the marketer (for
                    example by transferring to the marketer's site), in which
                    case your credit card is not cleared by us. For any act,
                    omission or action relating to such purchase
                  </li>
                </ol>
                <br /> <br />
                <li>
                  <strong>How we share the information.</strong>
                  <br /> <br />
                  <p>
                    We will share information with third parties in the cases
                    listed below: We will share information with third parties
                    in the cases listed below:{' '}
                  </p>
                  <ol>
                    <li>When registering on the site;</li>
                    <br />
                    <li>
                      When you purchase products and services from third parties
                      within the site, or when you take part in third-party
                      content activities, or in joint activities of the company
                      and third parties displayed on the site, to send
                      advertisements on behalf of the company and / or other
                      advertisers, including the following companies: -
                      <span className="bgc">
                        [[Beitili, Urban , ID Design, Kuala Furniture, Carmel
                        Rugs, Swing Fans, Modi Import and Marketing Ceramics, B,
                        S, H]
                      </span>{' '}
                      and / or other companies, at the Company's discretion. In
                      these cases, the information they need will be provided to
                      these partners or advertisers, including for the purpose
                      of completing the acquisition process, the information and
                      / or accessibility of the information for you, managing
                      the relevant content activity, and / or providing
                      additional information and maintaining contact with you;
                    </li>
                    <br />
                    <li>
                      To the extent necessary for the proper provision of the
                      Site Services and the delivery of the Products.
                    </li>
                    <br />
                    <li>
                      For the purpose of providing the products / services you
                      purchased on the site. Also, in the event that you
                      participate in another activity shared by the company and
                      a third party, I will allow and the third party will also
                      be exposed to information regarding your surfing and your
                      participation in the said activity.
                    </li>
                    <br />
                    <li>
                      To service providers and / or agents on behalf of the
                      company for operational, marketing, research, information
                      security, etc. purposes.
                    </li>
                    <br />
                    <li>
                      In any event the Company deems that the provision of the
                      information is necessary to prevent serious damage to your
                      body or property or to the body or property of a third
                      party.
                    </li>
                    <br />
                    <li>
                      To the state authorities or to a third party by virtue of
                      the provisions of the law.
                    </li>
                    <br />
                    <li>
                      If a court order is obtained instructing the teacher to
                      provide your details or information about you as stated to
                      a third party.
                    </li>
                    <br />
                    <li>
                      In the event that you violate and / or attempt to violate
                      the terms of use of the website and / or any of the
                      services offered on it, or in a situation in which you
                      will operate on the website and / or in any way that is
                      perceived by the company as illegal.
                    </li>
                    <br />
                    <li>
                      In any dispute, claim, claim, demand or legal proceeding,
                      if and to the extent that there are any between you and
                      the Company.
                    </li>
                    <br />
                    <li>
                      To affiliated companies of the Company, provided that they
                      use the information in accordance with this Privacy
                      Policy.
                    </li>
                    <br />
                    <li>
                      Anonymously for the purpose of collecting general or
                      individual information and / or analyzing aggregate and
                      statistical information for the company by third parties.
                    </li>
                  </ol>
                </li>
                <br />
                <li>
                  <strong>Collection of information by third parties. </strong>
                  When you use the website, the company may use third-party
                  software to provide content, services, for advertising
                  purposes and / or as part of additional functions such as data
                  processing. By using the Site, you acknowledge that these
                  third party software may collect such information and that the
                  Company may use this information for the purposes set forth in
                  this Privacy Policy.
                </li>
                <br />
                <li>
                  <strong>Google Analytics</strong>. The site may use a tool
                  called <strong>Google Analytics</strong>"Google Analytics"
                  GoogleAnalytics in order to collect information about the use
                  of the service. GoogleAnalytics collects information such as
                  how often visitors visit the service, the pages they visit and
                  which sites they use before entering the service. We use the
                  information obtained from Google Analytics in order to
                  maintain and improve our service and products. We do not
                  combine the information collected through the use of
                  GoogleAnalytics with personally identifiable information. For
                  how Google collects and uses information about you, see the
                  Privacy Policy and Privacy Policy posted at
                  <a href="http://www.google.com/analytics/terms/us.html">
                    http://www.google.com/analytics/terms/us.html
                  </a>{' '}
                  and at{' '}
                  <a href="http://www.google.com/policies/privacy/">
                    http://www.google.com/policies/privacy/
                  </a>
                  .
                </li>
                <br />
                <br />
                <li>
                  <strong>cookies.</strong>
                  <br />
                  <br />
                  <ol>
                    <li>
                      As part of our services, we may use "cookies", anonymous
                      identification files and other tracking technologies, so
                      that we can provide our services, present you with
                      customized information and improve the user experience in
                      using the services. "Cookie" is a small text file used,
                      For example, to collect information about activity in the
                      services and to retrieve information previously provided
                      (for example, for identification when logging in.) Certain
                      cookies and other technologies may be used to retrieve
                      personal information, such as an IP address previously
                      specified by a user. Whether or not to approve them and
                      how to remove them.
                    </li>
                    <br />
                    <li>
                      It is possible that some of the ads, which you view while
                      visiting the site or the network (including social
                      networks) after your visit to the site, come from
                      third-party servers, which manage the advertising
                      submission system on the site. The management of the
                      advertisements requires these companies, among other
                      things, to place cookies on your computer and to place web
                      beacons in the advertisements (hereinafter:
                      <strong>"tracking technologies"</strong>).The role of
                      surveillance technologies, among other things, is to
                      assist in the collection of information about the viewing
                      and use of websites where there is advertising and about
                      the advertisements to which they are exposed and which are
                      clicked on. The information collected seeks to match the
                      advertisements that will be presented to you to topics
                      that will interest you. If you do not want the tracking
                      technologies to be activated When using the site, you can
                      avoid it by changing the settings in your browser. To do
                      this, use the browser help file. You can delete the
                      cookies on your computer at any time. Please note that it
                      is suggested that you do so only if you are convinced that
                      you do not want to match the advertisements on the site to
                      your preferences. Disabling cookies may also prevent you
                      from using some of the services and features on other
                      websites.{' '}
                    </li>
                    <br />
                    <li>
                      If you do not wish to receive cookies, you can avoid this
                      by changing the settings in your browser. To do this,
                      please use the browser help file. You should know,
                      however, that disabling cookies may result in you not
                      being able to use some of the services and features on the
                      Site or other websites. In addition, you may delete the
                      cookies on your computer at any time. It is suggested that
                      you do so, only if you are convinced that you do not want
                      the site to be tailored to your preferences.
                    </li>
                  </ol>
                </li>
                <br />
                <br />
                <li>
                  <strong>securing.</strong> We place emphasis on securing
                  personal information. We operate in accordance with accepted
                  standards, including the use of physical and technical
                  security measures, to protect personal information, including
                  through data encryption. However, neither online transmission
                  nor electronic storage is 100% safe. Thus, while we strive to
                  implement acceptable measures to protect your personal
                  information, we can not guarantee its complete security or
                  confidentiality.
                </li>
                <br />
                <li>
                  <strong>User rights.</strong>
                  <ol>
                    <li>
                      Pursuant to the Protection of Privacy Law, 5741-1981 (the
                      "Privacy Law"), every person is entitled to review
                      himself, or by his authorized representative in writing or
                      by his guardian, the information held in a database.
                      Complete, clear or up-to-date, may contact the owner of
                      the database to correct or delete the information.{' '}
                    </li>
                    <br />
                    <li>
                      Also, if the information in the company's databases is
                      used to contact you personally, based on your affiliation
                      with a population group, determined by the
                      characterization of one or more persons whose names are
                      included in the database, then you are entitled under the
                      Privacy Act to demand in writing that the information
                      relating to you be deleted from the database. The Company
                      will delete within a reasonable time in this case the
                      information it needs only to contact you with commercial
                      offers as stated above. The information necessary for the
                      company to conduct its business - including documentation
                      of commercial and other transactions you have performed on
                      the site and / or as part of the services - will continue
                      to be retained by the company by law, but will no longer
                      be used to contact you.
                    </li>
                  </ol>
                </li>
                <br />
                <li>
                  Links to third party sites confirms that you know that
                  providing information and registering on sites that you access
                  through or through use or existing links on the site is not
                  subject to the privacy policy but to the privacy policy of
                  those linked sites and the provisions of any law.
                </li>
                <br />
                <li>
                  <strong>Merger, sale or bankruptcy. </strong>In the event that
                  our company is acquired by - or merged with - a third party
                  company, or in the event of bankruptcy or a similar event, if
                  the right to transfer or transfer the personal information
                  about you (whether provided by you or collected by us) to a
                  third party in connection with such events.
                </li>
                <br />
                <li>
                  <strong>Questions and Inquiries.</strong>For any comments or
                  questions regarding the Company's Privacy Policy, please
                  contact us at{' '}
                  <a href="mailto:team@patricia-ai.com">
                    <span className="bgc">[team@patricia-ai.com]</span>
                  </a>
                </li>
              </li>
            </ol>
            <br /> <br />
            <p>
              <strong>Last updated: December 2021</strong>
            </p>
            <LogoStack />
          </Body>
        </div>
      </div>
    </Container>
  );
};

export default PrivacyPolicyEn;

const Container = styled.div`
  padding: 10px 7px;
  margin: 0 6px;
  font-family: 'Roboto', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI',
    ' Oxygen', 'Ubuntu', ' Cantarell', 'Fira Sans', ' Droid Sans',
    ' Helvetica Neue', 'sans-serif';
  .back-btn {
    color: red;
    padding-right: 20px;
  }
  ${TermsOfUseFooter}

  .bgc {
    background-color: yellow;
  }
`;

const Title = styled.p`
  font-size: 25px;
  text-decoration: underline;
  text-align: center;
  font-weight: bold;
`;

const Body = styled.section`
  background-color: #f8f8f8;
  padding: 5px 5px;
  margin-top: 12px;
`;
