import * as React from 'react';
import { Button } from '@mui/material';
import styled from 'styled-components';
import { ReactComponent as PatriciaLogoMobile } from 'assets/@client/supplier/patriciamobile-seller-landing.svg';
import { ReactComponent as UploadIcon } from 'assets/@client/supplier/upload-icon-mobile.svg';
import { ReactComponent as DownArrow } from 'assets/@client/supplier/down-arrorw-mobile.svg';
import MacbookImage from 'assets/@client/supplier/MacBook-Pro-mobile.png';
import MobileImage from 'assets/@client/supplier/iphone-design-mobile.png';
import { ReactComponent as ShopIcon } from 'assets/@client/supplier/shop-mobile.svg';
import { ReactComponent as HdImageIcon } from 'assets/@client/supplier/hd-mobile.svg';
// import { ReactComponent as Phone } from 'assets/@client/supplier/telephone-icon-mobile.svg';
import { ReactComponent as EmailOutlined } from 'assets/@client/supplier/email-icon-mobile.svg';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ReactComponent as MapPin } from 'assets/@client/supplier/location-pin-drop.svg';
// import { ReactComponent as WPIcon } from 'assets/socialIcons/Whatsapp.svg';

import { ReactComponent as CustomerSupportIcon } from 'assets/@client/supplier/customer-support-icon.svg';
import { useAsyncDebounce, useMountedLayoutEffect } from 'react-table';
import { METHODS } from 'api/client';
import { Message } from 'i18n';
import { FormattedMessage } from 'react-intl';
import { useFetch, useRouter } from 'hooks';
import FooterB2B from 'pages/common/layout/Footer/FooterB2b';
import rtl from 'styled-components-rtl';
import ImageInsideMacbook from 'assets/@client/supplier/image-inside-macbook.png';
// eslint-disable-next-line import/no-named-as-default
import { isIsrael } from 'utils/addresses.utils';
import UserFormMobile from './UserFormMobile';
// eslint-disable-next-line import/no-cycle
import { LandingProps } from './SellerLandingPageContainer';

const SellerLandingMobile: React.FC<LandingProps> = ({ supplierData }) => {
  const { query } = useRouter<{
    supplierName: string;
  }>();
  // const intl = useIntl();

  const myRef = React.useRef<HTMLDivElement>(null);
  const executeScroll = React.useCallback(
    () => myRef.current?.scrollIntoView(),
    []
  );
  useMountedLayoutEffect(executeScroll);

  const [headerTopPosition, setHeaderTopPosition] = React.useState(false);
  const setHeaderPositionDebounce = useAsyncDebounce(setHeaderTopPosition, 10);
  const handleScroll = React.useCallback(() => {
    const currentPosition = window.pageYOffset;
    if (currentPosition > 600 && !headerTopPosition) {
      setHeaderPositionDebounce(true);
    } else {
      setHeaderPositionDebounce(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);
  const { data } = useFetch<
    Array<{
      color: string;
      hero_gif: string;
      id: number;
      supplier_id: number;
      supplier_logo: string;
      calendly_event_link: string;
    }>
  >({
    initialUrl: `/api/landing_pages/`,
    config: {
      params: {
        supplier_name: query.supplierName,
      },
      method: METHODS.GET,
    },
  });
  return (
    <>
      <>
        {/* <StyledHeader topPosition={headerTopPosition}>
          <img
            src={
              supplierData?.supplier?.logo ??
              'https://patricia-ai-data-staging.s3.amazonaws.com/media/suppliers/download_EZ0EHhg.png'
            }
            alt="Seller logo"
            className="header-image"
          />
          <div
            className="design-home-free"
            onClick={() => window.scrollTo({ top: 1240 })}
          >
            <span>
              <FormattedMessage
                id={Message.LANDING_PAGE_UPLOAD_FLOOR_PLAN_BUTTON}
              />
            </span>
          </div>
        </StyledHeader> */}
        <LogoContainer>
          <div>
            <PatriciaLogoContainer
            // logoColor={data?.[0].color}
            />
            <Image src={MacbookImage} alt="floor-design" />
            <img
              src={data?.[0].hero_gif ?? ImageInsideMacbook}
              alt="hero gif"
              className="hero-gif"
            />
            <MobileImageContainer src={MobileImage} alt="iphone-image" />
          </div>
        </LogoContainer>
        <PointsContainer>
          <SupplierLogo src={supplierData?.supplier?.logo} alt="logo" />
          <StyledHeaderText>
            <FormattedMessage
              id={Message.LANDING_PAGE_TEXT_MOBILE}
              values={{ linebreak: <br /> }}
            />
          </StyledHeaderText>
          <StyledSubHeader>
            <FormattedMessage
              id={Message.LANDING_PAGE_SUB_TEXT_MOBILE}
              values={{ linebreak: <br /> }}
            />
          </StyledSubHeader>
          {/* <h3>
            <FormattedMessage id={Message.LANDING_PAGE_TEXT} />
          </h3> */}
          {/* <ul>
            <li>
              <FormattedMessage id={Message.LANDING_PAGE_TEXT_POINT_1} />
            </li>
            <li>
              <FormattedMessage id={Message.LANDING_PAGE_TEXT_POINT_2} />
            </li>
            <li>
              <FormattedMessage id={Message.LANDING_PAGE_TEXT_POINT_3} />
            </li>
          </ul> */}

          <Button
            className="tired-btn"
            onClick={() => window.scrollTo({ top: 1393 })}
          >
            <FormattedMessage id={Message.LANDING_PAGE_BUTTON} />
            <ChevronRightIcon className="right-arrow" />
          </Button>
        </PointsContainer>
        <HoItWorksSection sectionBackground={data?.[0].color}>
          <h4 className="title">
            <FormattedMessage id={Message.LANDING_PAGE_MIDDLE_HEADING} />
          </h4>
          <div className="flex-display">
            <div className="upload-plan-section flex-section">
              <UploadIcon className="cloud-icon" />
              <h4 className="section-title">
                <FormattedMessage id={Message.LANDING_PAGE_UPLOAD_FLOOR_PLAN} />
              </h4>
              <p className="section-description">{supplierData?.step_1}</p>
              <DownArrow className="down-arrow-icon" />
            </div>

            <div className="upload-plan-section flex-section">
              <ShopIcon className="down-arrow-icon" />
              <h4 className="section-title">
                <FormattedMessage id={Message.LANDING_PAGE_MEETING_SHOWROOM} />
              </h4>
              <p className="section-description">{supplierData?.step_2}</p>
              <DownArrow className="down-arrow-icon" />
            </div>
            <div className="upload-plan-section flex-section">
              <HdImageIcon className="down-arrow-icon" />
              <h4 className="section-title">
                <FormattedMessage
                  id={Message.LANDING_PAGE_HIGH_QUALITY_IMAGES}
                />
              </h4>
              <p className="section-description">{supplierData?.step_3}</p>
            </div>
          </div>
        </HoItWorksSection>
        <div id="userForm">
          <UserFormMobile
            supplier={supplierData?.supplier.id ?? 0}
            calendlyUrl={data?.[0].calendly_event_link}
            locations={supplierData?.locations}
          />
        </div>
        {/* **************************************************************************** */}
        <SupportSection supportSectionBackground={data?.[0].color}>
          <div className="contact-us-title">
            <FormattedMessage id={Message.LANDING_PAGE_CONTACT_US} />
          </div>
          <div className="flex-display">
            {/* <div className="flex-section">
              <Phone className="phone-icon icon-colour" />
              <h4>
                <FormattedMessage id={Message.LANDING_PAGE_PHONE_TEXT} />
              </h4>
            </div>

            {supplierData?.phone_numbers?.map(cell => {
              return (
                <p key={cell?.phone_number}>
                  <div className="phone-type">
                    {cell?.type?.toLowerCase() === 'phone number' && (
                      <a
                        className="phone normalPhoneText"
                        href={`tel: ${cell?.phone_number}`}
                      >
                        {cell?.phone_number}
                      </a>
                    )}
                    {cell?.type?.toLowerCase() === 'whatsapp' && (
                      <>
                        <WPIcon
                          className="image-icon"
                          onClick={e => {
                            e.stopPropagation();
                            window.open(
                              `https://wa.me/${cell?.phone_number}`,
                              '_blank'
                            );
                          }}
                        />
                        <a
                          className="phone"
                          href={`https://wa.me/${cell?.phone_number}`}
                        >
                          {cell?.phone_number}
                        </a>
                      </>
                    )}
                  </div>
                </p>
              );
            })}

            <div className="vertical-divider" /> */}

            <div className="flex-section">
              <MapPin className="map-icon icon-colour" />
              <h4>
                <FormattedMessage id={Message.LANDING_PAGE_LOCATION} />
              </h4>
            </div>
            {supplierData?.locations?.map(location => (
              <p className="location-text" key={location.id?.toString()}>
                {`${location?.street},  ${location?.city},  ${
                  location?.state
                }, ${isIsrael(location?.country) ? 'ישראל' : location.country}`}
              </p>
            ))}
            <div className="vertical-divider" />

            <div className="flex-section">
              <EmailOutlined className="email-icon icon-colour" />
              <h4>
                <FormattedMessage id={Message.LANDING_PAGE_EMAIL} />
              </h4>
            </div>

            {/* <p>
              <a
                className="mail"
                href={`mailto: ${intl.formatMessage({
                  id: Message.LANDING_PAGE_EMAIL_TEXT,
                })}`}
              >
                <FormattedMessage id={Message.LANDING_PAGE_EMAIL_TEXT} />
              </a>
            </p> */}
            <p>
              <a className="mail" href="mailto: id.haifa@iddesign.co.il">
                id.haifa@iddesign.co.il
              </a>
            </p>
            <p>
              <a className="mail" href="mailto: Id.ramat-gan@iddesign.co.il">
                Id.ramat-gan@iddesign.co.il
              </a>
            </p>
            <p>
              <a className="mail" href="mailto: id.netania@iddesign.co.il">
                id.netania@iddesign.co.il
              </a>
            </p>
            <div className="vertical-divider" />

            <div className="flex-section">
              <CustomerSupportIcon className="customer-icon icon-colour" />
              <h4>
                <FormattedMessage id={Message.LANDING_PAGE_CUSTOMER_SERVICE} />
              </h4>
            </div>
            <div className="flex-section">
              <div className="working-time">
                <div className="working-time-flex">
                  <div>
                    <FormattedMessage
                      id={Message.LANDING_PAGE_CUSTOMER_SERVICE_WEEK}
                    />
                  </div>
                  <div>
                    <FormattedMessage
                      id={Message.LANDING_PAGE_CUSTOMER_SERVICE_WEEK_TIME}
                    />
                  </div>
                </div>
                {/* <div className="working-time-flex">
                  <div>
                    <FormattedMessage
                      id={Message.LANDING_PAGE_CUSTOMER_SERVICE_WEEK_DAY}
                    />
                  </div>
                  <div>
                    <FormattedMessage
                      id={Message.LANDING_PAGE_CUSTOMER_SERVICE_WEEK_DAY_1_TIME}
                    />
                  </div>
                </div>
                <div className="working-time-flex">
                  <div>
                    <FormattedMessage
                      id={Message.LANDING_PAGE_CUSTOMER_SERVICE_WEEKEND}
                    />
                  </div>
                  <div style={{ fontWeight: 500 }}>
                    <FormattedMessage
                      id={Message.LANDING_PAGE_CUSTOMER_SERVICE_WEEKEND_STATUS}
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </SupportSection>
        <FooterB2B />
      </>
    </>
  );
};

const SupportSection = styled.div<{ supportSectionBackground?: string }>`
  text-align: center;
  padding-bottom: 32px;
  background: ${({ supportSectionBackground }) => supportSectionBackground};
  .contact-us-title {
    position: relative;
    top: 32px;
    left: -98px;
    font-size: 30px;
    line-height: 34px;
    font-weight: 500;
    ${rtl`left: -98px`};
    color: rgba(255, 255, 255, 0.9);
  }
  .flex-display {
    margin-top: 32px;
    .vertical-divider {
      height: 1px;
      width: 155px;
      background: #676766;
      margin: auto;
    }
    .location-text {
      max-width: 205px;
      margin: 0 auto 16px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 22px;
      display: -webkit-box;
      color: rgba(255, 255, 255, 0.9);
    }
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      color: #282929;
      margin-top: -3px;
      .phone-type {
        direction: ltr;
        display: flex;
        justify-content: center;
        align-items: center;
        svg.image-icon {
          fill: #3e3e3e;
          width: 22px;
          height: 22px;
          margin-left: -30px;
        }
      }
      .normalPhoneText {
        margin-left: -10px;
      }
      a {
        color: white;
        .phone {
          color: inherit;
          text-decoration: none;
          :hover {
            text-decoration: underline;
          }
        }
        text-decoration: none;
        :hover {
          text-decoration: underline;
        }
      }
    }
    .flex-section {
      display: flex;
      justify-content: center;
      color: rgba(255, 255, 255, 0.9);
      .icon-colour {
        border: 0px solid #282929;
        margin: 20px 8px 0px 0px;
        ${rtl`margin: 20px 8px 0px 0px;`}
        font-weight: 600;
        font-size: 22px;
        line-height: 26px;
      }
      .phone-icon {
        width: 18.51px;
        height: 18.5px;
        path {
          stroke: rgba(255, 255, 255, 0.9);
        }
      }
      .map-icon {
        width: 16px;
        height: 24px;
        path {
          stroke: rgba(255, 255, 255, 0.9);
        }
      }
      .email-icon {
        width: 24px;
        height: 16px;
        path {
          stroke: rgba(255, 255, 255, 0.9);
        }
      }
      .customer-icon {
        width: 19px;
        height: 20px;
        path {
          fill: rgba(255, 255, 255, 0.9);
        }
      }

      .working-time {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        margin-top: 8px;
      }
      .working-time-flex {
        display: flex;
        white-space: nowrap;
        text-align: start;
        div:nth-child(1) {
          width: 116px;
        }
      }
    }
  }
`;
const Image = styled.img`
  position: absolute;
  height: 168.38563537597656px;
  width: 305.91px;
  top: 62px;
  right: 0;
  left: -34px;
  margin: 0 auto;
  ${rtl`right: 31.54px`}
  @media only screen and (max-width: 1024px) {
    left: -34px;
    right: 0;
    margin: 0 auto;
  }
`;
// const StyledHeader = styled.header<{ topPosition: boolean }>`
//   position: fixed;
//   top: 0;
//   z-index: 9;
//   background: #fafcfe;
//   box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
//   display: flex;
//   justify-content: center;
//   transition: top 0.3s;
//   width: 100vw;
//   height: 64px;
//   padding: 8px 24px;
//   ${({ topPosition }) =>
//     topPosition &&
//     css`
//       justify-content: space-between;
//     `}
//   .header-image {
//     height: 48px;
//   }
//   .design-home-free {
//     width: 175px;
//     height: 40px;
//     margin: auto 0;
//     border: 1px solid #fc5c42;
//     border-radius: 100px;
//     align-items: right;
//     text-align: center;
//     display: ${({ topPosition }) => (topPosition ? 'block' : 'none')};
//     transition: display 0.3s;
//     /* Primary Color */

//     color: #fc5c42;
//     font-weight: 500;
//     font-size: 18px;
//     line-height: 22px;
//     padding: 10px 0;
//   }
// `;

const PointsContainer = styled.div`
  width: 340px;
  margin: 0 auto;
  h3 {
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    margin-top: 42px;
    margin-left: 24px;
    margin-bottom: 0;
    ${rtl`margin-left: 24px;`}
  }
  ul {
    line-height: 24px;
    color: #3e3e3e;
    margin-top: 8px;
    margin-left: 50px;
    margin-right: 24px;
    margin-bottom: 0;
    ${rtl`margin-left: 50px;`}
  }
  li {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  .tired-btn {
    height: 48px;
    margin-top: 16px;
    color: white;
    background: #fc5c42;
    border: 1px solid #fc5c42;
    border-radius: 100px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-transform: none;
    white-space: nowrap;
    ::placeholder {
      margin-left: 8px;
    }
    &:hover {
      color: #fc5c42;
    }
    .right-arrow {
      ${rtl`margin-left: -8px`}
      font-size: 30px;
      rotate: ${({ theme }) => (theme.dir === 'rtl' ? '180deg' : '0deg')};
    }
  }
`;

const LogoContainer = styled.div`
  margin-top: 24px;
  & > div {
    height: 310px;
    width: 310px;
    position: relative;
    margin: 0 auto;
    @media only screen and (max-width: 1024px) {
      text-align: center;
    }
  }
  .hero-gif {
    border-radius: 4px;
    width: 246px;
    height: 138px;
    top: 70px;
    left: 64px;
    position: absolute;
    @media only screen and (max-width: 1024px) {
      left: -32.5px;
      right: 0;
      margin: 0 auto;
    }
  }
`;
const HoItWorksSection = styled.div<{ sectionBackground?: string }>`
  text-align: center;
  margin-top: 32px;
  background: ${({ sectionBackground }) => sectionBackground};
  padding: 32px 0;
  width: 100vw;
  svg {
    path {
      fill: white;
    }
  }
  .title {
    margin: 0 auto;
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    white-space: nowrap;
    color: rgba(255, 255, 255, 0.9);
  }
  .flex-display {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .upload-plan-section {
  }
  .cloud-icon {
    margin-top: 32px;
  }
  .down-arrow-icon {
    margin-top: 24px;
  }
  .flex-section {
    .section-title {
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
      height: 26px;
      color: rgba(255, 255, 255, 0.9);
      margin-bottom: 0px;
      margin-top: 16px;
    }
    .section-description {
      line-height: 20px;
      color: rgba(255, 255, 255, 0.9);
      margin: auto;
      margin-top: 16px;
      @media (min-width: 767px) and (max-width: 1025px) {
        max-width: 380px;
      }
      max-width: 244px;
      margin-bottom: 0px;
    }
  }
`;

const PatriciaLogoContainer = styled(PatriciaLogoMobile)<{
  logoColor?: string;
}>`
  position: relative;
  top: 0;

  path {
    fill: ${({ logoColor }) => logoColor};
  }
`;

const MobileImageContainer = styled.img`
  position: absolute;
  width: 162.99px;
  height: 108px;
  left: 206px;
  top: 156px;
  @media only screen and (max-width: 1024px) {
    right: -185px;
    left: 0;
    top: 161px;
    margin: 0 auto;
  }
`;

const StyledHeaderText = styled.div`
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;
  color: #282929;
  font-family: 'Assistant';
  margin-top: 16px;
`;

const StyledSubHeader = styled.div`
  color: #3e3e3e;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-top: 16px;
`;

const SupplierLogo = styled.img`
  height: 48px;
  width: 111px;
  margin: 16px auto 0;
  display: flex;
`;

export default SellerLandingMobile;
