import { ListItem } from 'components/Autocomplete';
import { TextContent } from 'styles/sharedStyle';
import { isPrimitive } from 'utils/common.utils';
import type { AutocompleteRenderOptionState } from '@material-ui/core/Autocomplete';
import type { Option } from 'components/Autocomplete/Autocomplete.config';

const UserSearchOption = (
  props: React.HTMLAttributes<HTMLLIElement>,
  option: Option,
  { inputValue }: AutocompleteRenderOptionState
) => {
  const secondaryValue = option
    ? Object.values(option).find(value => {
        return (
          isPrimitive(value) &&
          value !== option.name &&
          value?.toString()?.includes(inputValue)
        );
      }) || option.email
    : '';
  return (
    <ListItem key={option?.name} tabIndex={0} {...props}>
      <div style={{ maxWidth: 40 }}>
        <TextContent as="span" isTruncate>
          <strong>{option?.name}</strong>
          {secondaryValue && <span>, {secondaryValue}</span>}
        </TextContent>
      </div>
    </ListItem>
  );
};

export default UserSearchOption;
