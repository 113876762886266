export enum Authentication {
  LOGIN_REQUEST = 'authentication/loginRequest',
  LOGIN_WITH_PROVIDER_REQUEST = 'authentication/loginWithProviderRequest',
  LOGIN_FULFILLED = 'authentication/loginFulfilled',
  LOGIN_REJECTED = 'authentication/loginRejected',
  REDIRECT_LOGIN_WITH_PROVIDER_REQUEST = 'authentication/redirectLoginWithProviderRequest',

  LOGOUT_REQUEST = 'authentication/logout',
  CLIENT_LOGOUT_REQUEST = 'authentication/logout',

  VERIFY_TOKEN_REQUEST = 'authentication/verifyTokenRequestRequest',
  VERIFY_TOKEN_SUCCESS = 'authentication/verifyTokenRequestFulfilled',
  UPDATE_USER_INFO = 'authentication/UPDATE_USER',

  REGISTER_REQUEST = 'authentication/registerRequest',
  ONBOARDING_REQUEST = 'authentication/onboardingRequest',
  REGISTER_FULFILLED = 'authentication/registerFulfilled',
  REGISTER_REJECTED = 'authentication/registerRejected',
  REDIRECT_ONBOARDING_REQUEST = 'authentication/redirectOnboardingRequest',

  PASSWORD_RESET_LINK_REQUEST = 'authentication/passwordResetLinkRequest',
  PASSWORD_RESET_LINK_FULFILLED = 'authentication/passwordResetLinkFulfilled',
  PASSWORD_RESET_LINK_REJECTED = 'authentication/passwordResetLinkRejected',

  RESET_PASSWORD_REQUEST = 'authentication/resetPasswordLinkRequest',
  RESET_PASSWORD_FULFILLED = 'authentication/resetPasswordLinkFulfilled',
  RESET_PASSWORD_REJECTED = 'authentication/resetPasswordLinkRejected',

  RESET_ERROR = 'authentication/RESET_ERROR',
  SET_SUPPLIER_ID = 'authentication/SET_SUPPLIER_ID',
}
