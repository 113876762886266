import * as React from 'react';
import { SupplierOnboardingContextProvider } from 'context/supplierOnboardingContext';
import { IRouteConfig } from 'router/routes';
import SelectCategory from './SelectCategory';

const SupplierSelectCategory: React.FC<{ routes: IRouteConfig[] }> = ({
  routes,
}) => {
  return (
    <SupplierOnboardingContextProvider>
      <SelectCategory routes={routes} />
    </SupplierOnboardingContextProvider>
  );
};

export default SupplierSelectCategory;
