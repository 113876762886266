import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';
import {
  getDate,
  isSameMonth,
  isToday,
  format,
  isWithinInterval,
  isEqual,
  startOfWeek,
  lastDayOfWeek,
} from 'date-fns';
import {
  chunks,
  getDaysInMonth,
  isStartOfRange,
  isEndOfRange,
  inDateRange,
} from 'utils/dates.utils';
import Header from './Header';
import Day from './Day';

import { NavigationAction, DateRange, MenuProps } from './types';

const WEEK_DAYS = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

interface MonthProps {
  value: Date;
  marker: symbol;
  dateRange: DateRange;
  minDate: Date;
  maxDate: Date;
  navState: [boolean, boolean];
  setValue: (date: Date) => void;
  helpers: {
    inHoverRange: (day: Date) => boolean;
  };
  handlers: {
    onDayClick: (day: Date) => void;
    onDayHover: (day: Date) => void;
    onMonthNavigate: (marker: symbol, action: NavigationAction) => void;
  };
  MenuProps: MenuProps;
  years: number[];
}

const Month: React.FC<MonthProps> = (props: MonthProps) => {
  const {
    helpers,
    handlers,
    value: date,
    dateRange,
    marker,
    setValue: setDate,
    minDate,
    maxDate,
    MenuProps: menuProps,
    years,
  } = props;

  const [back, forward] = props.navState;

  return (
    <Container elevation={0} style={{ borderRadius: 0, boxShadow: 'none' }}>
      <div>
        <Header
          date={date}
          setDate={setDate}
          nextDisabled={!forward}
          prevDisabled={!back}
          onClickPrevious={() =>
            handlers.onMonthNavigate(marker, NavigationAction.Previous)
          }
          onClickNext={() =>
            handlers.onMonthNavigate(marker, NavigationAction.Next)
          }
          MenuProps={menuProps}
          years={years}
        />
        <WeekDays>
          {WEEK_DAYS.map(day => (
            <span key={day}>{day}</span>
          ))}
        </WeekDays>
        <Days>
          {chunks(getDaysInMonth(date), 7).map((week, idx) => (
            <Week key={idx.toString()}>
              {week.map(day => {
                const isStart = isStartOfRange(dateRange, day);
                const isEnd = isEndOfRange(dateRange, day);
                const highlighted =
                  inDateRange(dateRange, day) || helpers.inHoverRange(day);
                return (
                  <Day
                    key={format(day, 'MM-dd-yyyy')}
                    filled={isStart || isEnd}
                    outlined={isToday(day)}
                    highlighted={highlighted}
                    visible={isSameMonth(date, day)}
                    disabled={
                      !isSameMonth(date, day) ||
                      !isWithinInterval(day, { start: minDate, end: maxDate })
                    }
                    startOfRange={isStart}
                    endOfRange={isEnd}
                    isStartOfWeek={isEqual(day, startOfWeek(day))}
                    isEndOfWeek={isEqual(day, lastDayOfWeek(day))}
                    onClick={() => handlers.onDayClick(day)}
                    onHover={() => handlers.onDayHover(day)}
                    value={getDate(day)}
                  />
                );
              })}
            </Week>
          ))}
        </Days>
      </div>
    </Container>
  );
};

export default Month;

const Container = styled(Paper)`
  width: 290px;
`;

const WeekDays = styled.div`
  color: #242e4c;
  display: flex;
  font-weight: 500;
  font-size: 1rem;
  width: 100%;
  gap: 15.5px;
  margin-top: 10px;
  padding: 0 30px;
`;

const Days = styled.div`
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
  height: 100%;
`;

const Week = styled.div`
  display: flex;
  justify-content: center;
`;
