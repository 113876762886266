import FormControl from '@material-ui/core/FormControl';
import styled, { css } from 'styled-components';

const Field = styled(FormControl).attrs<{
  $width?: string | number;
}>({ className: 'form-field-container' })<{
  $width?: string | number;
}>`
  ${({ $width }) =>
    $width &&
    css`
      width: ${$width} !important;
    `}
`;

export default Field;
