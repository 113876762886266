import { useEffect, useState } from 'react';
import { ClientRouter } from 'router/routes';
import useRouter from './useRouter';

const useOnboarding = () => {
  const [stepNo, setStepNo] = useState(0);
  const { location } = useRouter();

  useEffect(() => {
    if (location.pathname === ClientRouter.QUIZ_QUESTION) {
      setStepNo(1);
    }
  }, [location.pathname]);

  return { stepNo, setStepNo };
};

export default useOnboarding;
