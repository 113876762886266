import * as React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import rtl from 'styled-components-rtl';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReactComponent as StarIcon } from 'assets/@client/quizzes/star.svg';
import Breadcrumbs from 'components/@client/Breadcrumbs';
import { Message } from 'i18n';
import CardList, {
  PropertyItem,
  PropertyEmptyState,
} from 'components/@client/CardList';
import Button from 'components/@client/Button';
import Icon from 'components/Icon/Svg';
import InfoBox from 'components/@client/InfoBox/InfoBox';
import Title from 'components/@client/TopTitle';
import { useAuthentication, useRouter, useSession } from 'hooks';
import { ClientRouter } from 'router/routes';
import {
  ClientRoot,
  MOBILE_RESOLUTION,
  MOBILE_TABLET_RESOLUTION,
  SMALL_RESOLUTION,
} from 'styles/sharedStyle';
import { getRoute } from 'utils/sidebar.utils';
import { isEmpty } from 'utils/lodash.utils';
import { LogsAPI } from 'api/methods';
import { userLogData } from 'mockData/userLogData';
import Pagination from '@mui/material/Pagination';
import { Type, baseURL } from 'constants/config';
import _ from 'lodash';
import { OnboardingContextProvider } from 'context/onboardingContext';
import MobileHeader from 'components/@client/MobileHeader/MobileHeader';
import scanIcon from 'assets/@client/scan/scan-icon.svg';
import Modal from 'components/@client/Modal';
import { rooms as roomListData } from 'mockData/rooms';
import MultipleChips from 'components/MultipleChips/MultipleChips';
import { Room } from 'types/common.types';
import useProperties from './useProperties';
import AddProperty from './add-property/AddProperty';

const links = [
  {
    to: ClientRouter.PROPERTIES,
    children: <FormattedMessage id={Message.PROPERTIES_TITLE} />,
  },
];

const Properties = () => {
  const intl = useIntl();
  const { token, id: user_id } = useAuthentication();
  const { location } = useRouter<{}>();
  const { country: region } = useSession();
  const {
    properties,
    isLoading,
    callFetch: refreshPropertyList,
    pagination,
    setPagination,
  } = useProperties(true);
  // const { type: types } = useAuthentication();
  const { history, pathname, query } = useRouter<{
    modal: 'add-property';
  }>();
  const goTo = React.useCallback(
    (id: string) => history.push(getRoute([pathname, `/${id}`])),
    [history, pathname]
  );
  // eslint-disable-next-line
  let _window: any = window;
  const [isLidar, setIsLidar] = React.useState(_window.HAS_LIDAR);
  const addMessageListener = React.useCallback(() => {
    _window.vuplex.addEventListener('message', (event: { data: string }) => {
      const evData: { data: { lidar: boolean } } = JSON.parse(event.data);

      setIsLidar(evData.data.lidar);
      _window.HAS_LIDAR = evData.data.lidar;
    });
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (_window.vuplex) {
      addMessageListener();
    } else {
      window.addEventListener('vuplexready', addMessageListener);
    }
    // eslint-disable-next-line
  }, []);

  const onRefresh = React.useCallback(() => {
    refreshPropertyList();
  }, [refreshPropertyList]);

  const isMobile = useMediaQuery('(max-width: 1000px)');
  const isAddingProperty = query.modal === 'add-property';
  const { type } = useAuthentication();

  React.useEffect(() => {
    if (!isAddingProperty && !isLoading) onRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddingProperty]);

  const debounOnChange = _.debounce(
    (value: string) =>
      setPagination(prev => ({ ...prev, page: 1, name: value })),
    500
  );

  const showVuplex = () => {
    if (_window.vuplex !== undefined && _window.HAS_LIDAR === true)
      _window.vuplex.postMessage({
        token,
        userId: user_id,
        baseWebHost: location.pathname,
        baseEp: `${baseURL}/api`,
        cartId: 0,
        region,
        isMobile: true,
        ambientOcclusionEnabled: false,
        selected_scan: true,
        room_id: roomList[0].id,
      });
    location.pathname = ClientRouter.APP;
  };
  const [isRoomSelectionOpen, setIsRoomSelectionOpen] = React.useState(false);
  const [roomList, setRoomList] = React.useState<Room[]>([]);
  const getRooms = React.useCallback((data: Room[]) => {
    setRoomList(data);
  }, []);

  return (
    <>
      <OnboardingContextProvider>
        <Root>
          {isMobile ? (
            <MobileHeader
              title={<FormattedMessage id={Message.PROPERTIES_TITLE} />}
            />
          ) : (
            <Breadcrumbs links={links} />
          )}
          {isMobile ? (
            <div className="title-stack">
              {_window.vuplex !== undefined && isLidar && (
                <Button
                  className="add-scan-button"
                  startIcon={
                    <img
                      src={scanIcon}
                      className="icon-button"
                      alt={scanIcon}
                      color="#fff"
                    />
                  }
                  onClick={() => setIsRoomSelectionOpen(true)}
                  disabled={type === Type.SELLER_CLIENT}
                >
                  <FormattedMessage id={Message.SCAN_YOUR_HOME} />
                </Button>
              )}

              {!!properties?.results?.length && (
                <Link to={ClientRouter.ADD_PROPERTY}>
                  <Button
                    className={
                      _window.vuplex !== undefined && isLidar === true
                        ? 'add-property-button-mobile'
                        : 'add-property-button-mobile-scan'
                    }
                    startIcon={
                      <Icon name="plus" className="icon-button" color="#fff" />
                    }
                    onClick={() =>
                      LogsAPI.postUserLogs({
                        name: userLogData.eventName.addNewProperty,
                        area: userLogData.eventArea.myProperties,
                        section: userLogData.eventSection.properties,
                        path: userLogData.eventPath.appModalAddPropertyPath,
                      })
                    }
                  >
                    <FormattedMessage
                      id={Message.PROPERTY_ADD_PROPERTY_ADD_BUTTON}
                    />
                  </Button>
                </Link>
              )}
            </div>
          ) : (
            <div className="title-stack">
              <Title
                title={<FormattedMessage id={Message.PROPERTIES_TITLE} />}
                backTo={ClientRouter.MY_ACCOUNT}
              />
              {_window.vuplex !== undefined && isLidar && (
                <Button
                  className="add-scan-button"
                  startIcon={
                    <img
                      src={scanIcon}
                      className="icon-button"
                      alt={scanIcon}
                      color="#fff"
                    />
                  }
                  onClick={() => setIsRoomSelectionOpen(true)}
                >
                  <FormattedMessage id={Message.SCAN_YOUR_HOME} />
                </Button>
              )}
              {!!properties?.results?.length && (
                <Link to={ClientRouter.ADD_PROPERTY}>
                  <Button
                    className="add-property-button"
                    startIcon={
                      <Icon name="plus" className="icon-button" color="#fff" />
                    }
                    onClick={() =>
                      LogsAPI.postUserLogs({
                        name: userLogData.eventName.addNewProperty,
                        area: userLogData.eventArea.myProperties,
                        section: userLogData.eventSection.properties,
                        path: userLogData.eventPath.appModalAddPropertyPath,
                      })
                    }
                  >
                    <FormattedMessage id={Message.PROPERTIES_BUTTON} />
                  </Button>
                </Link>
              )}
            </div>
          )}
          <SellerSearch>
            <div className="search-input-div">
              <input
                placeholder={intl.formatMessage({
                  id: Message.PROPERTIES_SEARCH_PLACEHOLDER,
                })}
                // value={searchText}
                onChange={e => debounOnChange(e.target.value)}
              />
            </div>
            <Button
              className="search-button"
              disabled={!pagination.name.length}
            >
              <FormattedMessage id={Message.PROPERTIES_SEARCH_BUTTON} />
            </Button>
          </SellerSearch>
          <PageContainer empty={isEmpty(properties)}>
            <ListContainer>
              <CardList
                EmptyState={PropertyEmptyState}
                isLoading={isLoading}
                items={properties?.results}
                renderItem={property => (
                  <PropertyItem
                    key={property.id}
                    property={property}
                    onRefresh={onRefresh}
                    onClick={() => {
                      if (property.status === 'published') {
                        LogsAPI.postUserLogs({
                          name: userLogData.eventName.addProperty,
                          area: userLogData.eventArea.myProperties,
                          section: userLogData.eventSection.properties,
                          path: userLogData.eventPath.appPropertyPath,
                          metadata: { property_id: property.id },
                        });
                        goTo(property.id);
                      }
                    }}
                  />
                )}
              />
              <PropertyPagination
                count={Math.ceil((properties?.count ?? 1) / 5)}
                variant="outlined"
                shape="rounded"
                boundaryCount={1}
                siblingCount={2}
                onChange={(e, page) =>
                  setPagination(prev => ({ ...prev, page }))
                }
              />
            </ListContainer>
            {!isMobile && !isEmpty(properties) && (
              <div>
                <InfoBox
                  icon={<StarIcon />}
                  title={
                    <FormattedMessage id={Message.PROPERTIES_INFO_BOX_TITLE} />
                  }
                  text={
                    <FormattedMessage id={Message.PROPERTIES_INFO_BOX_TEXT} />
                  }
                />
              </div>
            )}
          </PageContainer>
        </Root>
        <CustomModal
          open={isRoomSelectionOpen}
          type={type}
          onClose={() => setIsRoomSelectionOpen(false)}
          actions={[
            {
              label: <FormattedMessage id={Message.SUBMIT} />,
              onClick: () => showVuplex(),
              disabled: !roomList.length,
            },
          ]}
        >
          <FormattedMessage
            id={Message.UPLOAD_ROOM_QUESTION}
            values={{
              span: (chunks: string) => (
                <span className="title-text">{chunks}</span>
              ),
              linebreak: isMobile ? <br /> : ' ',
            }}
          />
          <MultipleChips
            options={roomListData}
            getValue={getRooms}
            defaultValue={roomList}
            allChips
            allChipName="Entire Home"
            singleSelect={true}
          />
        </CustomModal>
        {isAddingProperty && <AddProperty open={isAddingProperty} />}
      </OnboardingContextProvider>
    </>
  );
};

export default Properties;

const CustomModal = styled(Modal)<{
  type: string | null | undefined;
}>`
  &&.client-dialog .MuiDialog-paper {
    .MuiSvgIcon-root {
      font-size: 30.86px;
    }
    min-width: 600px;
    padding: 10px 24px 10px 24px;
    max-width: fit-content;
    @media (min-width: 1000px) {
      ${({ type }) =>
        type !== 'SELLER' &&
        type !== 'SUPPLIER' &&
        css`
          width: 598px;
          max-width: 598px;
          height: 600px;
          padding: 0;
        `}
    }
    @media (max-height: 700px) {
      .upload-plan-container {
        height: 620px;
      }
    }
    @media (max-width: 800px) {
      border-radius: 20px;
      max-width: 928px;
      max-height: 90vh;
    }
    @media (max-width: 820px) {
      border-radius: 20px;
      max-width: 928px;
      width: 928px;
    }
    @media (max-width: 500px) {
      min-width: unset;
      margin: 0;
      padding: 32px 0;
      text-align: center;
    }
  }
  &.client-dialog .MuiDialog-paper .MuiDialogTitle-root {
    margin: 0;
  }

  && .MuiDialogContent-root {
    margin: 0;
    overflow: overlay;
    @media (max-width: 380px) {
      padding: 8px 0px !important;
    }
    padding: 8px 8px !important;
    ${({ theme }) => theme.utils.scrollbar};
  }
  &&.client-dialog .MuiDialog-paper .MuiDialogActions-root {
    padding: 0;
  }
`;

const Root = styled(ClientRoot)`
  && {
    .mobile-header-icon {
      @media (min-width: 767px) and (max-width: 1024px) {
        width: 40%;
      }
      width: 30%;
      ${rtl`
margin-left: 0;
`};
    }
  }
  & .title-stack {
    display: flex;
    justify-content: space-between;
    @media (max-width: 1150px) {
      & .title {
        font-size: 1.125rem;
      }
    }
    @media ${MOBILE_TABLET_RESOLUTION} {
      margin-top: 24px;
      gap: 5px;
    }

    .add-scan-button {
      @media (min-width: 1000px) {
        ${rtl`
      align-self: center;
      margin-left: auto;
      margin-right:24px;
      font-weight: 500 !important;
    font-size: 18px;
    line-height: 22px;
    width: 220px !important;

      `}
      }
      @media (max-width: 1000px) {
        width: 170px !important;
        height: 40px;
        font-weight: 500 !important;
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        ${rtl`
      padding-left: 16px;
      `}

        @media (max-width: 500px) {
          padding: 10px;
          font-size: 14px;
        }
        .MuiButton-startIcon {
          margin-left: 4px;
        }
      }
    }

    & a {
      text-decoration: none;
      ${rtl`
      align-self: center;
      `}
      @media ${MOBILE_TABLET_RESOLUTION} {
        ${rtl`
        align-self: center;
        margin: auto;
        
        `}
      }

      .add-property-button {
        width: 207px;
        font-weight: 500 !important;
        font-size: 18px;
        line-height: 22px;
        font-weight: 400;
        ${rtl`
        padding-left: 0px;
        `}

        @media (max-width: 500px) {
          padding: 10px;
          font-size: 0.75rem;
        }
      }
      .icon-button {
        margin-left: ${({ theme }) => (theme.dir === 'rtl' ? '12px' : '4px')};
        margin-right: ${({ theme }) => (theme.dir === 'rtl' ? '-8px' : '0')};
      }

      .add-property-button-mobile {
        width: 140px;
        height: 40px;
        font-weight: 500 !important;
        font-size: 18px;
        line-height: 22px;
        font-weight: 400;
        ${rtl`
        padding-left: 16px;
        `}
        background: white;
        color: #3e3e3e;
        border: 1px solid #696969;
        path {
          fill: #3e3e3e;
        }
        .MuiButton-startIcon {
          width: 16px;
        }
        @media (max-width: 1024px) {
          padding: 10px;
          font-size: 15px;
        }
        @media (max-width: 500px) {
          padding: 10px;
          font-size: 0.75rem;
        }
      }

      .add-property-button-mobile-scan {
        width: 156px;
        height: 40px;
        font-weight: 500 !important;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        ${rtl`
        padding-left: 16px;
        `}
        .MuiButton-startIcon {
          width: 16px;
        }
        @media (max-width: 500px) {
          padding: 10px;
          font-size: 16px;
        }
      }
    }

    & .client-top-title {
      width: unset;
      .title {
        color: #282929;
      }
    }
  }

  @media ${SMALL_RESOLUTION} {
    padding: 20px 25px;
  }
  @media ${MOBILE_RESOLUTION} {
    padding: 20px 25px;
  }
`;

const SellerSearch = styled.div`
  ${rtl`
  width: 331px;
  height: 40px;
  margin 32px 0;
  display: flex;
  .search-input-div {
    background: #fafcfe;
    border-width: 2px 0px 2px 2px;
    border-style: solid;
    border-color: #e8eaeb;
    border-radius: 10px 0px 0px 10px;
    width: 249px;
    height: 100%;
    input {
      &:focus {
        outline: none;
      }
      border-radius: 10px 0px 0px 10px;
      padding: 10px 16px;
      height: 100%;
      width: 100%;
      border: none;
    }
  }

  .search-button {
    &:disabled{
      border:none;
    }
    text-align: center;
    padding: 3% 0;
    background: #fc5c42;

    width: 82px;
    height: inherit;
    border: 1px solid #fc5c42;
    border-radius: 0px 10px 10px 0px;
  }
  `}
`;

const PageContainer = styled.div<{ empty: boolean }>`
  display: ${({ empty }) => (empty ? 'block' : 'flex')};
  justify-content: space-between;

  & .info-box {
    top: unset;
  }
`;

const ListContainer = styled.div`
  flex: 0.7;

  @media ${MOBILE_RESOLUTION} {
    width: 100%;
    margin: 0 auto;
  }
`;

const PropertyPagination = styled(Pagination)`
  margin-top: 48px;
  ${({ theme }) =>
    theme.dir === 'rtl' &&
    css`
       {
        float: right;
        direction: ltr;
      }
    `}
`;
