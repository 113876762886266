import * as React from 'react';
import styled from 'styled-components';
import { useRouter } from 'hooks';
import { useLocation } from 'react-router';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { ClientRouter } from 'router/routes';
import Breadcrumbs from 'components/@client/Breadcrumbs/Breadcrumbs';
import Grid from '@material-ui/core/Grid';
import SectionTitle from 'components/@client/SectionTitle';
import { Product } from 'types/products';
import type { GetProductsDataResponsePayload as GetSupplierProductsResponse } from 'store/types/user-management/suppliers.types';
import PhotosSection from 'pages/common/modals/ProductDetailsModal/PhotosSection';
import ShowMoreButton from 'components/@client/Button/ShowMoreButton';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import { ClientConfig } from 'api/client';
import Loader from 'components/Loader';
import NoProduct from 'components/@client/NoProduct/NoProduct';
import { userLogData } from 'mockData/userLogData';
import { LogsAPI } from 'api/methods';
import ProductDetails from './component/ProductDetails';
import BestSellerCard from '../products/components/BestSellerCard';

interface Props {
  product: Product | null;
  categoryProducts: GetSupplierProductsResponse | null;
  loadMoreProducts: (newConfig: ClientConfig) => void;
  isLoading: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionTitle: {
      '& > div > div': {
        fontSize: '2rem',
      },
    },
    imageContainer: {
      '& > section': {
        maxWidth: '100%',
        margin: '40px 30px',

        '& > div:first-child': {
          height: '700px',
          width: '100%',

          '@media (max-width : 1400px)': {
            height: '600px',
          },
          '@media (max-width : 1200px)': {
            height: '500px',
          },
          '@media (max-width : 1000px)': {
            height: '450px',
          },
          '@media (max-width : 900px)': {
            height: '400px',
          },

          minWidth: 'inherit',
          '& > img': {
            height: 'auto',
            maxHeight: '616px',
          },
        },

        '& > div > svg': {
          width: '30px',
          height: '30px',
        },
        '& > div > ul': {
          maxWidth: 'none',
          justifyContent: 'flex-start',
          '& > li > div': {
            width: '76px',
            height: '76px',
          },
        },
      },
    },
  })
);
const DesktopProductPage: React.FC<Props> = ({
  product,
  categoryProducts,
  isLoading,
  loadMoreProducts,
}) => {
  const location = useLocation();
  const [page, setPage] = React.useState(1);
  const pageSize = 12;
  const [productList, setProductList] = React.useState<Product[] | undefined>();
  const showMoreBtn = React.useMemo(
    () =>
      categoryProducts &&
      productList &&
      categoryProducts?.count > productList.length,
    [productList, categoryProducts]
  );

  const classes = useStyles();
  const { query } = useRouter<{
    catName: string;
    subCatName: string;
    productId: string;
  }>();
  React.useEffect(() => {
    setProductList(prevProductList =>
      Array.isArray(prevProductList) &&
      Array.isArray(categoryProducts?.results) &&
      categoryProducts
        ? [...prevProductList, ...categoryProducts?.results]
        : categoryProducts?.results
    );
  }, [categoryProducts]);
  React.useEffect(() => {
    loadMoreProducts({
      params: { category: query.catName, size: pageSize, page, ordering: '?' },
    });
  }, [page, query.catName, pageSize, loadMoreProducts]);

  const links = [
    {
      to: ClientRouter.PRODUCTS,
      children: <FormattedMessage id={Message.CATEGORIES} />,
    },
    {
      to: ClientRouter.PRODUCT_CATEGORY.replace(':catName', query.catName),
      children: query.catName,
    },
    {
      to: ClientRouter.PRODUCT_SUB_CATEGORY.replace(
        ':catName',
        query.catName
      ).replace(':subCatName', query.subCatName),
      children: query.subCatName,
    },
    {
      to: location.pathname,
      children: product?.altName || product?.name,
    },
  ];

  const onClickPage = (productData: Product, quantity: number) => {
    LogsAPI.postUserLogs({
      name: userLogData.eventName.addToCart,
      area: userLogData.eventArea.catalog,
      section: userLogData.eventSection.productPage,
      path: userLogData.eventPath.appProductSubCategoryNameProductIdPath,
      metadata: {
        product_id: productData.id,
        product_name: productData.name,
        product_sku: productData.sku,
      },
    });
    LogsAPI.pushDataLayer([
      { ecommerce: null },
      {
        event: 'addToCart',
        ecommerce: {
          add: {
            products: [
              {
                name: product?.name,
                id: product?.id,
                price: product?.price,
                brand: product?.manufacture.name,
                category: product?.subCategory,
                quantity,
              },
            ],
          },
        },
      },
    ]);
  };

  return (
    <MainContainer>
      {product && (
        <div>
          <div>
            <Breadcrumbs links={links} />
          </div>
          <Grid container>
            <Grid sm={6}>
              <div className={classes.imageContainer}>
                <PhotosSection images={product?.media} alt={product.name} />
              </div>
            </Grid>
            <Grid sm={6}>
              <ProductDetailsContainer>
                <ProductDetails
                  product={product}
                  onClickAddToCart={quantity => onClickPage(product, quantity)}
                />
              </ProductDetailsContainer>
            </Grid>
          </Grid>
          <div className={classes.sectionTitle}>
            <SectionTitle
              label={
                <FormattedMessage
                  id={Message.PRODUCT_MORE_FROM}
                  values={{ cat: query.catName }}
                />
              }
              borderColor="#87B9E7"
            />
          </div>
          <Grid container>
            {productList?.length ? (
              productList.map(data => (
                <Grid item md={3} sm={3} key={data.id}>
                  <BestSellerCard product={data} />
                </Grid>
              ))
            ) : (
              <NoProduct value={Message.NO_PRODUCT_AVAILABLE} />
            )}
          </Grid>
          {!!showMoreBtn && !isLoading && (
            <div>
              <ShowMoreBtnDiv>
                <ShowMoreButton
                  onClick={() => setPage(prevPage => prevPage + 1)}
                >
                  <FormattedMessage id={Message.SKINS_SHOW_MORE_BUTTON} />
                </ShowMoreButton>
              </ShowMoreBtnDiv>
            </div>
          )}
          {productList && isLoading && <Loader />}
        </div>
      )}
    </MainContainer>
  );
};

export default DesktopProductPage;

const MainContainer = styled.div`
  padding: 20px 10px;
`;

const ProductDetailsContainer = styled.div`
  margin-top: 40px;
  padding: 0 30px;
`;

const ShowMoreBtnDiv = styled.div`
  text-align: center;
  padding: 15px;
`;
