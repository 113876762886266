import * as React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
});

const Row: React.FC<{ style?: object }> = ({ children, style }) => {
  return <View style={{ ...styles.row, ...style }}>{children}</View>;
};

export default Row;
