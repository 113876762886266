import DesignStylesDataType from 'store/constants/quiz-management/designStyles.constants';
import * as Payload from 'store/types/designStyles.types';

export const getDesignStylesDataRequest = (
  payload: Payload.GetDesignStylesDataRequestPayload
) => ({
  type: DesignStylesDataType.GET_DESIGN_STYLES_DATA_REQUEST,
  payload,
});

export const getDesignStylesDataFulfilled = (
  payload: Payload.GetDesignStylesFulfilledPayload
) => ({
  type: DesignStylesDataType.GET_DESIGN_STYLES_DATA_FULFILLED,
  payload,
});

export const getDesignStylesDataRejected = (
  payload: Payload.GetDesignStylesRejectedPayload
) => ({
  type: DesignStylesDataType.GET_DESIGN_STYLES_DATA_REJECTED,
  payload,
});

export type QuizzesAction = ReturnType<
  | typeof getDesignStylesDataRequest
  | typeof getDesignStylesDataFulfilled
  | typeof getDesignStylesDataRejected
>;
