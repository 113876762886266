import { Button } from '@mui/material';
import * as React from 'react';
import styled, { css } from 'styled-components';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ReactComponent as PatriciaLogo } from 'assets/@client/supplier/patricia-seller-landing.svg';
import { ReactComponent as UploadIcon } from 'assets/@client/supplier/cloud-upload.svg';
import { ReactComponent as MacbookImage } from 'assets/@client/supplier/laptop-macbook.svg';
import MobileImage from 'assets/@client/supplier/iphone-mobile.png';
import ImageInsideMacbook from 'assets/@client/supplier/image-inside-macbook.png';
import LeftArrow from 'assets/@client/supplier/left-arrow.svg';
// import { ReactComponent as WPIcon } from 'assets/socialIcons/Whatsapp.svg';
import { ReactComponent as ShopIcon } from 'assets/@client/supplier/shop.svg';
import { ReactComponent as HdImageIcon } from 'assets/@client/supplier/hd-image.svg';
import { ReactComponent as MapPin } from 'assets/@client/supplier/location-pin-drop.svg';
import { ReactComponent as CustomerSupportIcon } from 'assets/@client/supplier/customer-support-icon.svg';
// import { ReactComponent as Phone } from 'assets/@client/supplier/phone-icon.svg';
import { ReactComponent as EmailIcon } from 'assets/@client/supplier/email-icon.svg';
import { useAsyncDebounce } from 'react-table';
import { Message } from 'i18n';
import { FormattedMessage } from 'react-intl';
import { useRouter } from 'hooks';
import rtl from 'styled-components-rtl';
import FooterB2B from 'pages/common/layout/Footer/FooterB2b';
import {
  LAPTOP_MAX_RESOLUTION,
  LAPTOP_MIN_RESOLUTION,
} from 'styles/sharedStyle';
import { isIsrael } from 'utils/addresses.utils';
import UserForm from './UserForm';
// eslint-disable-next-line import/no-cycle
import { LandingProps } from './SellerLandingPageContainer';

const SellerLanding: React.FC<LandingProps> = ({
  supplierData,
  headerTopPosition,
  setHeaderTopPosition,
  getSupplierData,
}) => {
  const { query } = useRouter<{
    supplierName: string;
  }>();
  React.useEffect(() => {
    getSupplierData({ params: { supplier_name: query.supplierName } });
  }, [getSupplierData, query.supplierName]);

  const setHeaderPositionDebounce = useAsyncDebounce(setHeaderTopPosition, 10);
  const handleScroll = React.useCallback(() => {
    const currentPosition = window.pageYOffset;
    if (currentPosition > 844 && !headerTopPosition) {
      setHeaderPositionDebounce(true);
    } else {
      setHeaderPositionDebounce(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <>
      <HeaderSection>
        <LeftContainer>
          <div className="left-text">
            <LogoContainer src={supplierData?.supplier?.logo} alt="logo" />
            <StyledHeader>
              <FormattedMessage
                id={Message.LANDING_PAGE_TEXT}
                values={{ linebreak: <br /> }}
              />
            </StyledHeader>
            <StyledSubHeader>
              <FormattedMessage
                id={Message.LANDING_PAGE_SUB_TEXT}
                values={{ linebreak: <br /> }}
              />
            </StyledSubHeader>
            {/* <ul>
              <li>
                <FormattedMessage id={Message.LANDING_PAGE_TEXT_POINT_1} />
              </li>
              <li>
                <FormattedMessage id={Message.LANDING_PAGE_TEXT_POINT_2} />
              </li>
              <li>
                <FormattedMessage id={Message.LANDING_PAGE_TEXT_POINT_3} />
              </li>
            </ul> */}

            <Button
              sx={{ textTransform: 'none' }}
              className="tired-btn"
              onClick={() => window.scrollTo({ top: 1240 })}
            >
              <FormattedMessage id={Message.LANDING_PAGE_BUTTON} />
              <ChevronRightIcon className="right-arrow" />
            </Button>
          </div>
        </LeftContainer>
        <RightContainer>
          <div className="macbook-parent-div">
            <PatriciaLogoContainer
            // logoColor={supplierData?.color}
            />
            <MacbookImageContainer />
            <img
              src={supplierData?.hero_gif ?? ImageInsideMacbook}
              alt="hero gif"
              className="hero-gif"
            />
            <MobileImageContainer src={MobileImage} alt="iphone-image" />
          </div>
        </RightContainer>
      </HeaderSection>
      <HoItWorksSection sectionBackground={supplierData?.color}>
        <h4 className="title">
          <FormattedMessage id={Message.LANDING_PAGE_MIDDLE_HEADING} />
        </h4>
        <div className="flex-display">
          <div className="content-image-section">
            <div className="upload-plan-section flex-section">
              <UploadIcon />
              <h4 className="section-title">
                <FormattedMessage id={Message.LANDING_PAGE_UPLOAD_FLOOR_PLAN} />
              </h4>
              <p className="section-description">{supplierData?.step_1}</p>
            </div>
          </div>
          <div>
            <img src={LeftArrow} alt="right-arrow" className="right-arrow" />
          </div>
          <div className="content-image-section">
            <div className="shop-section flex-section">
              <ShopIcon />
              <h4 className="section-title">
                <FormattedMessage id={Message.LANDING_PAGE_MEETING_SHOWROOM} />
              </h4>
              <p className="section-description">{supplierData?.step_2}</p>
            </div>
          </div>
          <div>
            <img src={LeftArrow} alt="right-arrow" className="right-arrow" />
          </div>
          <div className="content-image-section">
            <div className="hd-image-section flex-section">
              <HdImageIcon />
              <h4 className="section-title">
                <FormattedMessage
                  id={Message.LANDING_PAGE_HIGH_QUALITY_IMAGES}
                />
              </h4>
              <p className="section-description">{supplierData?.step_3}</p>
            </div>
          </div>
        </div>
      </HoItWorksSection>
      <UserForm
        supplier={supplierData?.supplier.id ?? 0}
        calendlyUrl={supplierData?.calendly_event_link}
        locations={supplierData?.locations}
      />
      <SupportSection supportSectionBackground={supplierData?.color}>
        <div className="title">
          <span>
            <FormattedMessage id={Message.LANDING_PAGE_FOOTER_HEADING} /> <br />
          </span>
          <FormattedMessage id={Message.LANDING_PAGE_FOOTER_HEADING_TEXT} />
        </div>
        <div className="flex-display">
          {/* <div className="flex-section">
            <Phone className="phone-icon icon-color" />
            <h4 style={{ marginTop: 18 }} className="contact-section-heading">
              <FormattedMessage id={Message.LANDING_PAGE_PHONE_TEXT} />
            </h4>
            {supplierData?.phone_numbers?.map(cell => {
              return (
                <p key={cell?.phone_number}>
                  <div className="phone-type">
                    {cell?.type?.toLowerCase() === 'phone number' && (
                      <a
                        className="phone normalPhoneText"
                        href={`tel: ${cell?.phone_number}`}
                      >
                        {cell?.phone_number}
                      </a>
                    )}
                    {cell?.type?.toLowerCase() === 'whatsapp' && (
                      <>
                        <WPIcon
                          style={{ marginLeft: -40 }}
                          className="image-icon"
                          onClick={e => {
                            e.stopPropagation();
                            window.open(
                              `https://wa.me/${cell?.phone_number}`,
                              '_blank'
                            );
                          }}
                        />
                        <a
                          className="phone"
                          href={`https://wa.me/${cell?.phone_number}`}
                        >
                          {cell?.phone_number}
                        </a>
                      </>
                    )}
                  </div>
                </p>
              );
            })}
          </div>
          <div className="vertical-divider" /> */}
          <div className="flex-section">
            <MapPin className="map-icon icon-color" />
            <h4 className="contact-section-heading">
              <FormattedMessage id={Message.LANDING_PAGE_LOCATION} />
            </h4>
            {supplierData?.locations?.map(location => (
              <p className="address-text" key={location.id?.toString()}>
                {`${location?.street},  ${location?.city},  ${
                  location?.state
                }, ${isIsrael(location?.country) ? 'ישראל' : location.country}`}
              </p>
            ))}
          </div>
          <div className="vertical-divider" />
          <div className="flex-section">
            <EmailIcon className="email-icon icon-color" />
            <h4 style={{ marginTop: 23 }} className="contact-section-heading">
              <FormattedMessage id={Message.LANDING_PAGE_EMAIL} />
            </h4>
            {/* <p>
              <a
                className="mail"
                href={`mailto: ${
                  supplierData?.email ?? supplierData?.supplier?.user?.email
                }`}
              >
                {supplierData?.email ?? supplierData?.supplier?.user?.email}
              </a>
            </p> */}
            <p>
              <a className="mail" href="mailto: id.haifa@iddesign.co.il">
                id.haifa@iddesign.co.il
              </a>
            </p>
            <p>
              <a className="mail" href="mailto: Id.ramat-gan@iddesign.co.il">
                Id.ramat-gan@iddesign.co.il
              </a>
            </p>
            <p>
              <a className="mail" href="mailto: id.netania@iddesign.co.il">
                id.netania@iddesign.co.il
              </a>
            </p>
          </div>
          <div className="vertical-divider" />
          <div className="flex-section">
            <CustomerSupportIcon className="customer-icon icon-color" />
            <h4 className="contact-section-heading">
              <FormattedMessage id={Message.LANDING_PAGE_CUSTOMER_SERVICE} />
            </h4>
            <div className="working-time">
              <div>
                <div className="working-time-flex">
                  <div>
                    <FormattedMessage
                      id={Message.LANDING_PAGE_CUSTOMER_SERVICE_WEEK}
                    />
                  </div>
                  <div className="timing-text">
                    <FormattedMessage
                      id={Message.LANDING_PAGE_CUSTOMER_SERVICE_WEEK_TIME}
                    />
                  </div>
                </div>
                {/* <div className="working-time-flex">
                  <div>
                    <FormattedMessage
                      id={Message.LANDING_PAGE_CUSTOMER_SERVICE_WEEK_DAY}
                    />
                  </div>
                  <div className="timing-text">
                    <FormattedMessage
                      id={Message.LANDING_PAGE_CUSTOMER_SERVICE_WEEK_DAY_1_TIME}
                    />
                  </div>
                </div>
                <div className="working-time-flex">
                  <div>
                    <FormattedMessage
                      id={Message.LANDING_PAGE_CUSTOMER_SERVICE_WEEKEND}
                    />
                  </div>
                  <div style={{ fontWeight: 500 }}>
                    <FormattedMessage
                      id={Message.LANDING_PAGE_CUSTOMER_SERVICE_WEEKEND_STATUS}
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </SupportSection>
      <FooterB2B />
    </>
  );
};

const SupportSection = styled.div<{ supportSectionBackground?: string }>`
  text-align: center;
  background: ${({ supportSectionBackground }) => supportSectionBackground};
  padding-bottom: 42px;
  .title {
    color: white;
    font-size: 40px;
    line-height: 56px;
    font-weight: 300;
    padding-top: 48px;
    span {
      font-weight: 500;
    }
  }
  .flex-display {
    display: flex;
    justify-content: center;
    margin-top: 48px;

    .flex-section {
      h4 {
        margin: 8px 0 auto;
        font-weight: 600;
        font-size: 22px;
        line-height: 26px;
      }
      p {
        margin: 0;
        margin-bottom: 8px;
        color: #3e3e3e;
        max-width: 195px;
        .phone-type {
          display: flex;
          justify-content: center;
          align-items: center;
          direction: ltr;
          svg.image-icon {
            fill: white;
            width: 19.56px;
            height: 19.56px;
            /* margin-left: -30px; */
          }
        }
        .normalPhoneText {
          margin-left: -20px;
        }
        a {
          color: white;
          text-decoration: none;
          :hover {
            text-decoration: underline;
          }
        }
      }
      .address-text {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 22px;
        display: -webkit-box;
        color: rgba(255, 255, 255, 0.9);
      }
      .icon-color {
        color: #282929;
        h4 {
          color: #3e3e3e;
        }
      }
      .phone-icon {
        width: 37px;
        height: 37px;
        path {
          stroke: white;
        }
      }
      .map-icon {
        width: 32px;
        height: 48px;
        path {
          stroke: white;
        }
      }
      .email-icon {
        width: 47px;
        height: 32px;
        path {
          stroke: white;
        }
      }
      .customer-icon {
        width: 48px;
        height: 48px;
        path {
          fill: white;
        }
      }
      .working-time {
        width: 239px;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        margin-top: 16px;
      }
      .contact-section-heading {
        margin-bottom: 16px;
        color: white;
      }
    }
    .working-time-flex {
      color: #3e3e3e;
      display: flex;
      white-space: nowrap;
      letter-spacing: -1px;
      text-align: left;
      margin-top: 8px;
      div:nth-child(1) {
        width: 130px;
        text-align: start;
        color: white;
      }
      div:nth-child(2) {
        ${rtl`margin-left: 16px;`}
        color: white;
      }
    }
    .timing-text {
      direction: ltr;
      color: white;
    }
    .vertical-divider {
      min-height: 174px;
      width: 1px;
      background: #676766;
      margin: 0 88px;
      margin-bottom: 6px;
    }
  }
`;

const HeaderSection = styled.div`
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.01),
      rgba(255, 255, 255, 0.01)
    ),
    #fafafa;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  height: 844px;
  width: 100vw;
  /* margin-top: 80px; */
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${LAPTOP_MAX_RESOLUTION} and ${LAPTOP_MIN_RESOLUTION} {
    padding: 0 20px;
  }
`;
const RightContainer = styled.div`
  @media (max-width: 1500px) {
    width: 55%;
    scale: 0.7;
  }
  @media (max-width: 1200px) {
    scale: 0.5;
    width: 52%;
  }
  .macbook-parent-div {
    position: relative;
  }
  ${rtl`margin-left: 8px;`}
  .hero-gif {
    width: 596.6px;
    height: 335.58px;
    top: 120px;
    ${rtl`left: 112px;`}
    border-radius: 4px;
    position: absolute;
  }
`;
const LeftContainer = styled.div`
  width: 832px;
  /* height: 384px; */
  .left-text {
  }
  margin: 0;
  color: #282929;
  h3 {
    font-weight: 600;
    font-size: 76px;
    line-height: 80px;
    margin: 0;
  }
  ul {
    margin-left: 43px;
    margin-top: 24px;
    ${({ theme }) =>
      theme.dir === 'rtl' &&
      css`
        padding-right: 38px;
      `};
  }
  li {
    font-weight: 400;
    font-size: 30px;
    line-height: 46px;
  }
  .tired-btn {
    height: 64px;
    font-weight: ${({ theme }) => (theme.dir === 'rtl' ? 600 : 500)};
    font-family: ${({ theme }) => theme.dir === 'rtl' && 'Assistant'};
    font-size: 22px;
    line-height: 26px;
    background: #fc5c42;
    border-radius: 35px;
    color: #ffffff;
    text-transform: none;
    margin-top: 32px;
    border-radius: 35px;
    padding: 0 32px;
    &:hover {
      background: #fc5c42;
      background: #a50a0a;
      color: #ffffff;
    }
    .right-arrow {
      font-size: 35px;
      ${({ theme }) =>
        theme.dir === 'rtl' &&
        css`
          rotate: 180deg;
        `}
    }
  }
`;

const HoItWorksSection = styled.div<{ sectionBackground?: string }>`
  height: 416px;
  width: 100vw;
  background: ${({ sectionBackground }) => sectionBackground};
  padding-top: 48px;
  padding-bottom: 48px;
  text-align: center;
  padding-right: 192px;
  padding-left: 191px;
  @media (min-width: 1025px) and (max-width: 1510px) {
    padding-right: 30px;
    padding-left: 30px;
  }
  svg {
    path {
      fill: white;
    }
  }
  .title {
    color: rgba(255, 255, 255, 0.9);
    font-weight: 500;
    font-size: 40px;
    line-height: 44px;
    margin: 0;
  }
  .flex-display {
    margin: 0 auto;
    display: flex;
    margin-top: 48px;
    max-width: 1600px;
    justify-content: center;
  }
  .content-image-section {
    display: flex;
    min-width: calc(33% - 25px);
    align-items: center;
    margin: 0 10px;
    justify-content: center;
    @media (min-width: 1025px) and (max-width: 1510px) {
      min-width: calc(33% - 67px);
    }
  }
  .upload-plan-section {
    margin-top: 27px;
  }
  .flex-section {
    .section-title {
      margin: 16px 0;
      font-weight: 500;
      font-size: 34px;
      line-height: 38px;
      color: rgba(255, 255, 255, 0.9);
      letter-spacing: -1px;
      @media (min-width: 1025px) and (max-width: 1510px) {
        font-size: 28px;
      }
    }
    .section-title-subtext {
      font-weight: 400;
      font-size: 22px;
      line-height: 26px;
      color: rgba(255, 255, 255, 0.9);
      margin-top: 30px;
      @media (min-width: 1025px) and (max-width: 1510px) {
        font-size: 18px;
      }
    }
    .section-description {
      font-weight: 400;
      font-size: 22px;
      line-height: 26px;
      margin: 0;
      max-height: 25px;
      @media (min-width: 1025px) and (max-width: 1510px) {
        font-size: 18px;
      }
      color: rgba(255, 255, 255, 0.9);
    }
    p {
      letter-spacing: -1px;
    }
  }
  .right-arrow {
    width: 48px;
    height: 32px;
    margin: 90px 15px;
    object-fit: contain;
    ${({ theme }) =>
      theme.dir === 'ltr' &&
      css`
        rotate: 180deg;
      `}
  }
`;

const PatriciaLogoContainer = styled(PatriciaLogo)<{ logoColor?: string }>`
  position: relative;
  margin: ${({ theme }) =>
    theme.dir === 'rtl' ? '0 108px 0 272px;' : '0 155px 0 232px'};
  path {
    fill: ${({ logoColor }) => logoColor};
  }
`;

const MacbookImageContainer = styled(MacbookImage)`
  position: absolute;
  top: 98px;
  ${rtl`left: 42px;`}
`;

const MobileImageContainer = styled.img`
  position: absolute;
  bottom: 27px;
  right: ${({ theme }) => (theme.dir === 'rtl' ? '-98px' : '-46px')};
`;

const StyledHeader = styled.div`
  font-weight: 600;
  font-size: 76px;
  line-height: 80px;
  color: #282929;
  font-family: 'Assistant';
  @media ${LAPTOP_MIN_RESOLUTION} and ${LAPTOP_MAX_RESOLUTION} {
    font-size: 46px;
    line-height: 52px;
  }
`;

const StyledSubHeader = styled.div`
  color: #282929;
  font-weight: 400;
  font-size: 30px;
  line-height: 46px;
  font-family: 'Assistant';
  margin-top: 56px;
  @media ${LAPTOP_MIN_RESOLUTION} and ${LAPTOP_MAX_RESOLUTION} {
    font-size: 24px;
    line-height: 28px;
    margin-top: 30px;
  }
`;

const LogoContainer = styled.img`
  height: 88px;
  width: 204px;
  margin-bottom: 48px;
`;

export default SellerLanding;
