import * as React from 'react';
import styled, { css } from 'styled-components';
import Icon from 'components/Icon';
import { MOBILE_RESOLUTION } from 'styles/sharedStyle';
import { Tabs } from './InstructionsModal.config';

interface Props {
  activeTab: string | null;
  tabs: Tabs[];
  onTabClick: (tab: string) => void;
}

const SideNavigation = ({ activeTab, tabs, onTabClick }: Props) => {
  const isActiveTab = (label: string) => label === activeTab;

  return (
    <Container>
      <NavigationList>
        {tabs.map(({ iconName, label, children }) => {
          const isActive = isActiveTab(label);
          return (
            <NavigationItem
              tabIndex={0}
              key={label}
              $isActiveTab={isActive}
              onClick={() => onTabClick(label)}
              onKeyPress={() => onTabClick(label)}
            >
              <Icon
                name={iconName}
                size={15}
                width={18}
                color={isActive ? '#0066D8' : '#4D6992'}
              />
              <ListItemText>{children}</ListItemText>
            </NavigationItem>
          );
        })}
      </NavigationList>
    </Container>
  );
};

export default SideNavigation;

const Container = styled.aside`
  height: auto;
`;

const NavigationList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  top: 20px;
  text-align: center;

  @media (min-width: 1500px) {
    top: 30px;
  }
`;

const NavigationItem = styled.li<{
  $isActiveTab: boolean;
}>`
  padding: 11.5px 11px 12.5px 16px;
  margin-bottom: 14px;
  display: inline-block;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s;
  border-radius: 4px;
  color: #ffffff;
  opacity: 50%;

  &:hover,
  &:focus {
    outline: none;

    & .icon {
      fill: rgba(0, 102, 216, 0.7);
      transition: fill 0.2s;
    }
  }

  @media ${MOBILE_RESOLUTION} {
    padding: 0px;
  }
  ${({ $isActiveTab }) =>
    $isActiveTab &&
    css`
      color: #ffffff;
      opacity: 100%;
      span {
        border-bottom: 2px solid #ffffff;
      }
      &:hover {
        color: #ffffff;
      }
    `}
`;

const ListItemText = styled.span`
  margin-left: 10px;
  font-size: 24px;
  padding-top: 2px;
  font-weight: bold;
  user-select: none;
  width: 100%;
  padding-bottom: 12px;
  line-height: 34.8px;

  @media ${MOBILE_RESOLUTION} {
    font-size: 15px;
  }
`;
