import { ReactComponent as ContentCopy } from 'assets/socialIcons/Copy.svg';
import { ReactComponent as FBIcon } from 'assets/socialIcons/Facebook.svg';
import { ReactComponent as WPIcon } from 'assets/socialIcons/Whatsapp.svg';
import { ReactComponent as TrashIcon } from 'assets/socialIcons/Trash.svg';
import { ReactComponent as UploadIcon } from 'assets/socialIcons/Upload.svg';
import Tooltip from 'components/Tooltip';
import * as React from 'react';
import Button from '@material-ui/core/Button';

import styled from 'styled-components';

export interface ActionsToolbarProps<T extends { src: string } | undefined> {
  item?: T;
  withRemove: boolean;
  withDownload?: boolean;
  withShare?: boolean;
  isCopyBtn?: boolean;
  isFBBtn?: boolean;
  isWPBtn?: boolean;
  isCentralReplaceBtn?: boolean;
  onRemove?: (media: T | undefined) => void;
  onUpload?: () => void;
  file?: string;
}

const ImageActionsToolbar = <T extends { src: string }>({
  item,
  withRemove,
  withDownload = true,
  withShare,
  isCopyBtn = false,
  isFBBtn = false,
  isWPBtn = false,
  isCentralReplaceBtn = false,
  onRemove,
  onUpload,
  file = '',
}: ActionsToolbarProps<T>) => {
  const remove = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    onRemove?.(item);
  };
  const [isCopy, setIsCopy] = React.useState(false);
  const copySuccess = () => {
    return (
      <List>
        <CopiedText>Copied!</CopiedText>
      </List>
    );
  };
  return (
    <Container>
      {isCentralReplaceBtn && (
        <UploadButton
          type="button"
          onClick={() => onUpload?.()}
          startIcon={<UploadIcon />}
        >
          Replace
        </UploadButton>
      )}
      {isCopyBtn && (
        <div
          onClick={() => {
            setIsCopy(true);
            navigator.clipboard.writeText(file);
            setTimeout(() => {
              setIsCopy(false);
            }, 1000);
          }}
          className="sharing-icon"
        >
          {isCopy ? (
            <Tooltip placement="right" $variant="social" title={copySuccess()}>
              <TooltipText>
                <ContentCopy />
              </TooltipText>
            </Tooltip>
          ) : (
            <ContentCopy />
          )}
        </div>
      )}
      {isFBBtn && (
        <div
          onClick={() =>
            window.open(
              `https://www.facebook.com/sharer/sharer.php?u=${file}`,
              '_blank'
            )
          }
          className="sharing-icon"
        >
          <FBIcon color="white" />
        </div>
      )}
      {isWPBtn && (
        <div
          onClick={() => {
            window.open(`https://api.whatsapp.com/send?text=${file}`, '_blank');
          }}
          className="sharing-icon"
        >
          <WPIcon />
        </div>
      )}

      {withRemove && (
        <div onClick={() => remove} className="sharing-icon">
          <TrashIcon />
        </div>
      )}
    </Container>
  );
};

export default ImageActionsToolbar;

const Container = styled.div`
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 30px;
  gap: 10px;
  @media (min-width: 1500px) {
    gap: 10px;
    height: 42px;
  }

  & .icon {
    fill: ${({ theme }) => theme.colors.primary.contrastText};

    @media (max-width: 1500px) {
      width: 15px;
      height: 15px;
    }
  }
  & .dropbtn {
    color: ${({ theme }) => theme.colors.primary.contrastText};
    border: none;
    padding: 10;
  }

  && .sharing-icon {
    height: 42px;
    width: 42px;
    svg {
      fill: #282929;
    }
  }

  && .sharing-icon:hover {
    background-color: #fc5c42;
    border-radius: 25px;

    svg {
      fill: #fff;
    }
  }
`;

const List = styled.ul<{ row?: boolean }>`
  display: ${({ row = false }) => (row ? 'flex' : 'block')};
  li {
    list-style: none;
    padding: 0;
    margin: 14px ${({ row = false }) => (row ? 15 : 0)}px;
    font-size: 15px;
    color: ${({ theme }) => theme.colors.primary.dark};
  }
`;

const TooltipText = styled.span`
  color: ${({ theme }) => theme.colors.primary.lightMobile};
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
  svg {
    color: ${({ theme }) => theme.colors.primary.contrastText};
  }
`;

const CopiedText = styled.span`
  color: white;
`;

const UploadButton = styled(Button)`
  & {
    background: #a50a0a !important;
    border: 1px solid #a50a0a !important;
    border-radius: 10px !important;
    width: 119px;
    height: 32px;
    text-transform: none !important;
  }
  .MuiButton-label {
    font-family: ${({ theme }) =>
      theme.dir === 'rtl' ? 'Assistant' : 'Roboto'};
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: rgba(255, 255, 255, 0.9);
    padding: 7px 0px;
  }
  .MuiButton-startIcon {
    width: 16px;
    height: 16px;
  }
`;
