import * as React from 'react';
import ReactFacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { ReactFacebookLoginProps } from 'react-facebook-login';

interface FacebookLoginProps extends ReactFacebookLoginProps {
  render: (props: {
    onClick: React.MouseEventHandler<HTMLButtonElement>;
  }) => JSX.Element;
}

// This component is because the original custom component don't have types.
const FacebookLogin: React.FC<FacebookLoginProps> = props => {
  return <ReactFacebookLogin {...props} />;
};

export default React.memo(FacebookLogin);
