import * as React from 'react';
import Skeleton from '@material-ui/core/Skeleton';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import no_image_placeholder from 'assets/images/client-products/no_image.png';
import InProgressPropertyStatus from 'assets/@client/properties/inprogress_property.png';
import InProgressPropertyHebrewStatus from 'assets/@client/properties/inprogress_property_hebrew.png';
import { ReactComponent as Envelop } from 'assets/@client/properties/envelop.svg';
import Icon from 'components/Icon';
import SafeImg from 'components/SafeImg';
import {
  MOBILE_TABLET_RESOLUTION,
  MOBILE_RESOLUTION,
} from 'styles/sharedStyle';
import { FormattedMessage, useIntl } from 'react-intl';
import { Message } from 'i18n';
import { Button } from '@material-ui/core';
import { useFetch } from 'hooks';
import { METHODS } from 'api/client';
import Loader from 'components/Loader/Loader';

interface Props {
  img?: string;
  overlay?: string | React.ReactNode;
  alt?: string;
  isLoading?: boolean;
  tabIndex?: number;
  additionalCharge?: boolean;
  inProgressOverlay?: number;
  onClick?:
    | React.MouseEventHandler<HTMLDivElement>
    | React.KeyboardEventHandler<HTMLDivElement>;
  id?: string;
  onRefresh?: VoidFunction;
}

const Card: React.FC<Props> = ({
  img = no_image_placeholder,
  alt = 'No image available',
  overlay,
  children,
  isLoading = false,
  inProgressOverlay = 0,
  tabIndex = 0,
  onClick,
  additionalCharge,
  id,
  onRefresh,
}) => {
  const withOverlay = Boolean(overlay);
  const intl = useIntl();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const { callFetch, isLoading: reuploadLoading } = useFetch({
    initialUrl: `/api/properties/{id}/floor_plan/`,
    skipOnStart: true,
    config: {
      method: METHODS.POST,
    },
    onSuccess: () => onRefresh?.(),
  });
  return (
    <>
      <Container>
        <StyledFrame
          tabIndex={tabIndex}
          onClick={onClick as React.MouseEventHandler<HTMLDivElement>}
          onKeyPress={onClick as React.KeyboardEventHandler<HTMLDivElement>}
        >
          <ImageContainer>
            {isLoading ? (
              <ImageSkeleton />
            ) : (
              <StyledImage src={img} alt={alt} />
            )}
            {withOverlay && <div className="overlay">{overlay}</div>}
          </ImageContainer>
          <Content>{children}</Content>
        </StyledFrame>
        {additionalCharge && (
          <AdditionalText>
            <FormattedMessage id={Message.CART_ADDITIONAL_TEXT} />
          </AdditionalText>
        )}
        {inProgressOverlay === 1 && (
          <CustomeOverlay>
            <Image
              src={
                intl.locale === 'he'
                  ? InProgressPropertyHebrewStatus
                  : InProgressPropertyStatus
              }
              alt="in-progress"
            />
          </CustomeOverlay>
        )}
        {inProgressOverlay === -1 && (
          <CustomeOverlay className="error-div">
            {reuploadLoading ? (
              <Loader />
            ) : (
              <>
                <Envelop className="envelop" />
                <FormattedMessage
                  tagName="div"
                  id={Message.PROPERTY_STATUS_ERROR}
                  values={{
                    linebreak: <br />,
                    span: (chunks: string) => (
                      <span style={{ color: 'red' }}>{chunks}</span>
                    ),
                  }}
                />
                <Button
                  disabled={reuploadLoading}
                  onClick={() => inputRef?.current?.click()}
                >
                  <FormattedMessage id={Message.PROPERTY_REUPLOAD_BUTTON} />
                </Button>
              </>
            )}
          </CustomeOverlay>
        )}
      </Container>
      <input
        type="file"
        ref={inputRef}
        style={{ display: 'none' }}
        onChange={e => {
          const formData = new FormData();
          formData.append('file', e.target.files?.[0] as Blob);
          callFetch({
            url: `/api/properties/${id}/floor_plan/`,
            data: formData,
          });
        }}
      />
      <StyledHr />
    </>
  );
};

export { default as CardField } from './CardField';
export default Card;

const Container = styled.div`
  background: #fff;
  box-shadow: 0px -3px 25px 5px rgba(0, 88, 171, 0.07);
  border-radius: 16px;
  @media (min-width: 767px) {
    padding: 30px 20px;
  }
  @media ${MOBILE_TABLET_RESOLUTION} {
    min-width: 315px;
    margin: auto;
  }
  max-width: 620px;
  .error-div {
    .loader {
      position: initial;
    }
    .MuiCircularProgress-indeterminate {
      animation: none;
      ${rtl`margin-right: 420px;`}
      position: absolute;
      top: 32px;
    }
    svg {
      position: absolute;
      top: 23px;
      ${rtl`right: 30px;`}
      @media (max-width: 767px) {
        position: initial;
      }
    }
  }
`;

const StyledFrame = styled.div.attrs({ className: 'card' })`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};

  @media ${MOBILE_TABLET_RESOLUTION} {
    padding: 10px 20px;
  }
`;

const StyledImage = styled(SafeImg).attrs({ className: 'card-image' })`
  height: 160px;
  width: 215px;
  border-radius: 25px;
  object-fit: cover;
  background: rgba(239, 239, 239, 1);

  @media ${MOBILE_TABLET_RESOLUTION} {
    width: 135px;
    height: 135px;
  }
`;

export const StyledHr = styled.hr.attrs({ className: 'card-divider' })`
  height: 0.3px;
  border-top: none;
  margin: 32px 0;
  max-width: 550px;

  @media ${MOBILE_TABLET_RESOLUTION} {
    margin: 19px 0;
  }
`;

const Content = styled.div.attrs({ className: 'card-content' })`
  padding: 5px 0;
  ${rtl`
    margin-left: 22px;
  `}

  @media ${MOBILE_TABLET_RESOLUTION} {
    ${rtl`
      margin-left: 11px;
    `}
  }
`;

const ImageSkeleton = styled(Skeleton).attrs({
  variant: 'rectangular',
  className: 'card-image-skeleton',
})`
  height: 160px;
  width: 215px;
  border-radius: 25px;

  @media ${MOBILE_TABLET_RESOLUTION} {
    width: 103px;
    height: 94px;
  }
`;

export const FieldStack = styled.div`
  display: flex;
  align-items: flex-end;
  @media ${MOBILE_RESOLUTION} {
    display: block;
  }
`;

export const FieldIcon = styled(Icon)`
  fill: #130f26 !important;
  margin: 0 3px 0 15px;
  position: relative;
  top: -2px;
`;

const ImageContainer = styled.div.attrs({ className: 'card-image-container' })`
  position: relative;
  border-radius: 25px;
  height: 100%;

  & .overlay {
    position: absolute;
    ${({ theme }) => theme.utils.centerFlex};
    border-radius: inherit;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: rgba(255, 255, 255, 1);
    font-size: 0.875rem;
    font-weight: 700;
    background: rgba(134, 133, 133, 0.56);

    @media (min-width: 1150px) {
      font-size: 1rem;
    }
  }
`;
const AdditionalText = styled.div.attrs({ className: 'card additional-text' })`
  color: ${({ theme }) => theme.colors.primary.lightMobile};
  font-size: 0.74rem;
  font-weight: 500;
  margin-top: 2px;
`;

const CustomeOverlay = styled.div`
  padding: 14px;
  position: absolute !important;
  top: 0;
  left: 0;
  height: 100%;
  background: rgba(40, 41, 41, 0.8);
  border-radius: 16px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 24px;
  img {
    position: absolute;
    ${rtl`right: 35px;`}
    @media (max-width: 767px) {
      ${rtl`right: auto;`}
      height: 90%;
    }
  }
  .error {
    color: red;
    width: 22px;
    height: 40px;
    position: absolute;
    top: 35px;
  }
  .envelop {
  }
  div {
    position: absolute;
    font-size: 18px;
    width: 147px;
    top: 85px;
    // background: black;
    /* color: black; */
    height: 103px;
    ${rtl`right: 65px;`}
    @media (max-width: 767px) {
      ${rtl`right: auto;`}
      top: 55px;
    }
  }
  button {
    position: absolute;
    ${rtl`right: 78px;`}
    color: white;
    // border: 3px solid;
    border-radius: 10px;
    top: 130px;
    background: #fc5c42;
    width: 120px;
    @media (max-width: 767px) {
      ${rtl`right: auto;`}
      top: 90px;
      width: 100px;
    }
  }
`;

const Image = styled.img`
  /* margin-top: 31px; */
`;
