import * as React from 'react';
import { useSpring, animated } from '@react-spring/web';
import { useGesture } from '@use-gesture/react';
import ScheduleMeetingIcon from 'assets/@client/supplier-login-flow/schedule_meeting.png';
import styled from 'styled-components';
import styles from 'pages/appClient/onboarding/shared/styles.module.css';

const Image: React.FC = React.memo(() => {
  const [props, api] = useSpring(() => ({ x: 1, y: 1, scale: 1 }));
  const innerWidth = window?.innerWidth;
  const bind = useGesture({
    onDrag: ({ offset: [x, y] }) =>
      api.start({ x: x < innerWidth / 2 ? 0 : innerWidth - 58, y }),
  });
  return (
    <>
      <animated.img
        className={`${styles.drag} ${styles.img}`}
        src={ScheduleMeetingIcon}
        {...bind()}
        style={props}
      />
    </>
  );
});

const ScheduleMeetingPicker: React.FC<{ onClick?: VoidFunction }> = ({
  onClick,
}) => {
  return (
    <ImageContainer onClick={onClick}>
      <Image />
    </ImageContainer>
  );
};

export default ScheduleMeetingPicker;

const ImageContainer = styled.div`
  position: fixed;
  bottom: 178px;
  left: 0;
  z-index: 9;
`;
