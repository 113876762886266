import { METHODS } from 'api/client';
import useFetch from 'hooks/useFetch';
import React from 'react';
import useAuthentication from './useAuthentication';

export type Manufacturer = {
  id: number;
  name: string;
  logo: string;
  country: string;
};

const useManufacturers = () => {
  const { token } = useAuthentication();
  const { data: manufacturers, isLoading, hasError, callFetch } = useFetch<
    Manufacturer[]
  >({
    initialUrl: '/api/manufacturers/',
    config: {
      useCache: true,
      method: METHODS.GET,
    },
  });
  React.useEffect(() => {
    if (token) callFetch();
  }, [token, callFetch]);
  return { manufacturers, isLoading, hasError };
};

export default useManufacturers;
