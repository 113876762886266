import * as React from 'react';
import styled, { css } from 'styled-components';
import SafeImg from 'components/SafeImg';
import rtl from 'styled-components-rtl';
import ProductPageModal from 'pages/appClient/productPage/component/ProductPageModal';
import { Product } from 'types/products';
import { ClientRouter } from 'router/routes';
import { Link } from 'react-router-dom';
import FormattedCurrency from 'components/@client/FormattedCurrency/FormattedCurrency';
import { useIntl } from 'react-intl';
import { LogsAPI } from 'api/methods';
import { PRODUCT_LISTING } from 'constants/common.constants';
import { summarizeText } from 'utils/string.utils';
import { useFormattedCurrency } from 'hooks';

interface Props {
  product: Product;
}
const MobileProductCard: React.FC<Props> = ({ product }) => {
  const intl = useIntl();
  const formattedMSRP = useFormattedCurrency(product.msrp);

  return (
    <>
      <Card>
        <Link
          onClick={() => {
            LogsAPI.pushDataLayer([
              { ecommerce: null },
              {
                event: ' productClick',
                ecommerce: {
                  impressions: {
                    name: product.name,
                    id: product.id,
                    price: product.price,
                    brand: product.manufacture.name,
                    category: product.category,
                    list: PRODUCT_LISTING,
                  },
                },
              },
            ]);
          }}
          to={ClientRouter.PRODUCT_PAGE.replace(
            ':catName',
            (intl.locale === 'he' && product.categoryAltName) ||
              product.category
          )
            .replace(
              ':subCatName',
              (intl.locale === 'he' && product.subCategoryAltName) ||
                product.subCategory
            )
            .replace(':productId', product.id)}
        >
          <Img>
            <SafeImg src={product.thumbnail?.file} alt={product.name} />
          </Img>
          <p>{summarizeText(product.altName || product.name, 15)}</p>
          <SubCategory>
            {(intl.locale === 'he' && product.subCategoryAltName) ||
              product.subCategory}
          </SubCategory>
          {typeof product.supplier !== 'string' && (
            <p>{product.supplier.companyName}</p>
          )}
          <PriceContainer discountPrice={!!product.discount}>
            <b>
              <span>
                <FormattedCurrency value={product.price} />
              </span>
              {product.msrp > product.price && (
                <MSRPPrice>{formattedMSRP}</MSRPPrice>
              )}
            </b>
          </PriceContainer>
        </Link>
        <ProductPageModal product={product} />
      </Card>
    </>
  );
};

export default MobileProductCard;

const Card = styled.div`
  a {
    color: ${({ theme }) => theme.colors.black0};
    text-decoration: none;
  }
  cursor: pointer;
  margin: 15px 17px;
  p {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    word-break: break-word;
  }
`;

const SubCategory = styled.span`
  font-size: 12px;
`;

const Img = styled.div`
  img {
    max-height: 100%;
    max-width: 100%;
    margin: auto;
  }
  height: 211px;
  max-width: 143px;
  border-radius: 15px;
  display: flex;
  align-items: center;
`;

const PriceContainer = styled.p<{ discountPrice: boolean }>`
  & > b :first-child {
    ${({ discountPrice }) =>
      discountPrice &&
      css`
        text-decoration: line-through;
      `}
  }
`;

const MSRPPrice = styled.span`
  font-family: ${({ theme }) => (theme.dir === 'rtl' ? 'Assistant' : 'Roboto')};
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  text-decoration: line-through;
  line-height: 21px;
  color: #424242;
  letter-spacing: -0.01px;
  margin-top: 0;
  margin-bottom: 5px;
  ${rtl`padding-left: 10px;`}
`;
