import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styled, { css } from 'styled-components';
import rtl from 'styled-components-rtl';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SafeImg from 'components/SafeImg';
import {
  MOBILE_TABLET_RESOLUTION,
  TextContent,
  PriceTextContent,
} from 'styles/sharedStyle';
import { summarizeText } from 'utils/string.utils';
import type { Product } from 'types/products';
import { OrderDetail } from 'types/orders';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import { useAuthentication, useFormattedCurrency } from 'hooks';
import ProductPageModal from 'pages/appClient/productPage/component/ProductPageModal';
import { ClientRouter } from 'router/routes';
import { Supplier } from 'types/suppliers';
import countries from 'data/countries';
import { SupportedCurrency } from 'types/common.types';
import { Type } from 'constants/config';
import { QuoteComponent } from 'api/responses';

export enum ProductView {
  CAROUSEL = 'CAROUSEL',
  LIST = 'LIST',
}

export interface ViewProps {
  view?: ProductView;
  isWide?: boolean;
}
interface Props extends LinkProps, ViewProps {
  item: Product | OrderDetail;
  roomName?: string;
  isProduct?: boolean;
  onProdClick?: (productSku: string) => void;
  isMatrix?: boolean;
  isPropertyPage?: boolean;
}

const ProductListItem: React.FC<Props> = ({
  item,
  to,
  view = ProductView.CAROUSEL,
  isWide,
  roomName,
  isProduct,
  onProdClick,
  isMatrix,
  isPropertyPage,
}) => {
  const currency =
    (item?.supplier as Supplier)?.country === countries.IL
      ? SupportedCurrency.ILS
      : SupportedCurrency.USD;
  const viewProps = { view, isWide };
  const isMobile = useMediaQuery(MOBILE_TABLET_RESOLUTION);
  const price = useFormattedCurrency(
    (item as OrderDetail).totalRowPrice ?? item.price,
    { currency }
  );
  const textWidth = !isMobile ? 20 : 10;
  const { type } = useAuthentication();
  return (
    <ListItem
      style={{ width: isMobile ? '156px' : '250px' }}
      {...viewProps}
      onClick={() => onProdClick && onProdClick(item.sku)}
    >
      <CustomLink
        to={
          isProduct
            ? ClientRouter.PRODUCT_PAGE.replace(':catName', item.category)
                .replace(':subCatName', item.subCategory)
                .replace(':productId', item.id)
            : to
        }
      >
        <Background {...viewProps} />
        <ImageContainer {...viewProps}>
          <Img
            src={item.thumbnail?.file ?? item.media?.[0]?.file}
            alt={item.name}
            {...viewProps}
          />
        </ImageContainer>
      </CustomLink>

      <TextWrapper className="text-wrapper">
        {roomName && (
          <TextContent
            as="div"
            className="roomClass"
            size={20}
            lineHeight={25}
            weight={400}
          >
            {roomName}
          </TextContent>
        )}
        <TextContent
          as="div"
          size={isMobile ? 16 : 18}
          lineHeight={isMobile ? 20 : 30}
          weight={500}
          className="product-name"
        >
          {summarizeText(item.altName ?? item.name, textWidth)}
        </TextContent>
        {/* {!isMatrix && (
          <TextContent className="category-name" as="div">
            {locale === 'he'
              ? item.categoryHebrewName ?? item.category
              : item.category}
          </TextContent>
        )} */}

        {!isMatrix &&
          ((item as OrderDetail).quantity && !isMobile && !isPropertyPage ? (
            <div className="quantity-stack">
              <TextContent
                className="quantity"
                as="div"
                size={24}
                lineHeight={25}
                weight={400}
              >
                <FormattedMessage id={Message.CHECKOUT_ITEM_QUANTITY_LABEL} />:{' '}
                {item.quantity}
              </TextContent>
              {isPropertyPage && (
                <TextContent
                  className="roomClass"
                  as="div"
                  size={16}
                  lineHeight={20}
                  weight={400}
                >
                  {item.subCategoryAltName ?? item.subCategory}
                </TextContent>
              )}
              {type !== Type.SELLER_CLIENT && (
                <TextContent as="div" size={18} weight={900}>
                  {price}
                </TextContent>
              )}
            </div>
          ) : (
            <>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <TextContent
                  className="roomClass"
                  as="div"
                  size={16}
                  lineHeight={20}
                  weight={400}
                >
                  {item.subCategoryAltName ?? item.subCategory}
                </TextContent>
                <TextContent
                  className="roomClass"
                  as="div"
                  size={16}
                  lineHeight={20}
                  weight={400}
                >
                  {((item as unknown) as QuoteComponent).metadata?.count}
                </TextContent>
              </div>
              {type !== Type.SELLER_CLIENT && (
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <PriceTextContent as="div" size={20} weight={500}>
                    {price}
                  </PriceTextContent>
                  <TextContent
                    className="roomClass"
                    as="div"
                    size={16}
                    lineHeight={20}
                    weight={400}
                  >
                    {((item as unknown) as QuoteComponent).metadata?.area}
                  </TextContent>
                </div>
              )}
              <ProductPageModal
                showAddToCartBtn={true}
                product={item}
                isPropertyPage={isPropertyPage}
              />
            </>
          ))}
        {isMatrix && (
          <ProductPageModal
            showAddToCartBtn={false}
            product={item}
            isMatrix={isMatrix}
          />
        )}
      </TextWrapper>
    </ListItem>
  );
};

export default ProductListItem;

const ListItem = styled.li<ViewProps>`
  position: relative;
  overflow-y: hidden;

  ${({ view, isWide }) =>
    view === ProductView.LIST &&
    css`
      width: 20%;
      .text-wrapper {
        ${rtl`
          margin-left: 0;
        `}
        margin-top: 10px;
        @media ${MOBILE_TABLET_RESOLUTION} {
          margin-top: 8px;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #696969;
        }
      }
      .product-name {
        @media ${MOBILE_TABLET_RESOLUTION} {
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #282929;
          margin-top: 4px;
        }
      }
      .category-name {
        @media ${MOBILE_TABLET_RESOLUTION} {
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #696969;
          margin-top: 4px;
        }
      }

      & .roomClass,
      .quantity {
        font-size: 17px;
        font-weight: 400;
        line-height: 25px;
        color: #747474;
      }

      overflow-y: initial;

      @media ${MOBILE_TABLET_RESOLUTION} {
        width: ${isWide ? '327px' : '156px'};
        &:not(nth-last-of-type) {
          margin-bottom: 20px;
        }
      }
    `}
`;

const CustomLink = styled(Link)`
  font: inherit;
  color: inherit;
  text-decoration: none;
`;

const Background = styled.div<ViewProps>`
  z-index: 10;
  position: absolute;
  background: rgba(239, 239, 239, 0.25);
  width: 160px;
  height: 280px;
  border-radius: 25px;
  margin: 10px;

  ${({ view, isWide }) =>
    view === ProductView.LIST &&
    css`
      margin: 0px;
      width: inherit;
      @media ${MOBILE_TABLET_RESOLUTION} {
        width: ${isWide ? '100%' : 'inherit'};
        height: 168px;
      }
    `}
`;

const ImageContainer = styled(Background)`
  position: relative;
  z-index: 5;
  opacity: 1;
  ${({ theme }) => theme.utils.centerFlex};
`;

const TextWrapper = styled.div`
  ${rtl`
    margin-left: 15px;
  `}

  .quantity-stack {
    display: flex;
    align-items: center;
    margin-top: 5px;
    & .quantity {
      ${rtl`
        margin-right: 20px;
      `}
    }
  }
  .view-details-btn {
    padding: 8px 16px;
    background: #fc5c42;
    border: 1px solid #fc5c42;
    border-radius: 8px;
    width: 100%;
    height: 40px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
  }
`;

const Img = styled(SafeImg)<ViewProps>`
  height: 100%;
  width: 100% !important;
  border-radius: 25px;
  object-fit: contain;

  ${({ view, isWide }) =>
    view === ProductView.LIST &&
    css`
      height: 100%;
      border-radius: 10px;

      @media ${MOBILE_TABLET_RESOLUTION} {
        width: ${isWide ? 'auto' : '100%'};
        border-radius: 10px;
        height: auto;
        max-height: 100%;
      }
    `}
`;
