import * as React from 'react';
import styled from 'styled-components';
import { isEmpty, size } from 'utils/lodash.utils';
import CardList, {
  CartEmptyState,
  CartItem,
} from 'components/@client/CardList';
import { ClientRouter } from 'router/routes';
import Button from 'components/@client/Button';
import {
  MOBILE_TABLET_RESOLUTION,
  MOBILE_RESOLUTION,
} from 'styles/sharedStyle';
import CreditCardProviders from 'components/@client/CreditCardProviders';
import {
  DeleteItemModal,
  DeleteItemModalAll,
  RestrictionModal,
} from 'pages/appClient/cart/property/modals';
import usePropertyCart from 'pages/appClient/cart/property/hooks/usePropertyCart';
import CheckoutAddressModal from 'components/@client/IframeModals/CheckoutAddressModal/CheckoutAddressModal';
import ResponseModal from 'components/@client/IframeModals/ResponseModal/ResponseModal';
import { ModalName } from 'pages/common/Unity/config';
import { Setter, SupportedCountry } from 'types/common.types';
import Title from 'components/@client/TopTitle';
import { useFormattedCurrency, useRouter, useSession } from 'hooks';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import rtl from 'styled-components-rtl';
import IconButton, { IconName } from 'components/Button/IconButton';
import SecondaryButton from 'components/Button/SecondaryButton';
import { StyledHr } from 'components/@client/CardList/Card';

interface Props {
  changeModal: Setter<ModalName | null>;
}

const CartModal: React.FC<Props> = ({ changeModal }) => {
  const { country } = useSession();
  const [isRestricted, setIsRestricted] = React.useState<boolean>(false);
  const [isAddressOpen, setIsAddressOpen] = React.useState<boolean>(false);
  const { history, query } = useRouter<{
    checkout: string;
    cartId: string;
  }>();

  const onSuccessCheckoutCallback = React.useCallback(
    (id: number | null) => {
      if (id) {
        history.push(`?checkout=${id}`);
        setIsRestricted(false);
        setIsAddressOpen(true);
      }
    },
    [history]
  );

  const {
    cart,
    deleteCandidate,
    isLoading,
    isUpdating,
    isProceedingToCheckout,
    restrictedItems,
    deleteCandidateAll,
    deleteItem,
    emptyItem,
    proceedToCheckout,
    toggleModal,
    updateQuantity,
    toggleModalAllItem,
  } = usePropertyCart(false, onSuccessCheckoutCallback);

  const isDeleteOpen = Boolean(deleteCandidate);
  const isDeleteAllOpen = Boolean(
    deleteCandidateAll?.ids && deleteCandidateAll?.ids?.length > 0
  );
  const price = useFormattedCurrency(cart?.subTotal ?? 0, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const shippingAmount = useFormattedCurrency(0, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const vatAmount = useFormattedCurrency(cart?.vat ?? 0, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const totalAmount = useFormattedCurrency(cart?.finalPrice ?? 0, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  const changeModalOpen = React.useCallback(() => {
    toggleModalAllItem('null');
  }, [toggleModalAllItem]);

  const onClick = React.useCallback(() => {
    if (!isEmpty(restrictedItems)) return setIsRestricted(true);
    return proceedToCheckout();
  }, [proceedToCheckout, restrictedItems]);

  const hasItems = size(cart?.items) > 0;

  const outOfStocked = React.useMemo(
    () => cart?.items.every(item => item.product.quantity === 0),
    [cart?.items]
  );

  const onCloseRestrictionModal = React.useCallback(() => {
    setIsRestricted(false);
  }, []);

  const renderComponent = () => {
    if (query.payment) {
      return <ResponseModal payment={query.payment.toString()} />;
    }
    if (query.checkout && isAddressOpen) {
      return <CheckoutAddressModal cart={cart} />;
    }
    if (hasItems) {
      return (
        <>
          <TopContainer>
            <TopRow display="flex">
              <Col>
                {hasItems && (
                  <span className="cart-counter">
                    <FormattedMessage
                      id={Message.CART_TOTAL_ITEMS_LABEL}
                      values={{ value: size(cart?.items) }}
                    />
                  </span>
                )}
              </Col>
              <Col>
                {cart?.items && cart?.items.length > 0 && (
                  <SecondaryButton
                    $xlarge
                    className="secondary-button"
                    onClick={changeModalOpen}
                  >
                    <IconButton
                      iconName={IconName.custom_icon}
                      customIcon="trash-outlined"
                    />
                    <FormattedMessage id={Message.CLEAR_CART} />
                  </SecondaryButton>
                )}
              </Col>
            </TopRow>
          </TopContainer>
          <Stack count={cart?.items?.length}>
            <LeftSection>
              <Row display="flex">
                <CartItemsDiv>
                  <CardList
                    isLoading={isLoading}
                    EmptyState={
                      <CartEmptyState
                        to={ClientRouter.PROPERTY.replace(
                          ':propertyId',
                          cart?.property?.id ?? ''
                        )}
                      />
                    }
                    items={cart?.items ?? []}
                    renderItem={item => (
                      <CartItem
                        key={item.id}
                        isLoading={isUpdating}
                        onQuantityChange={updateQuantity}
                        onDelete={toggleModal}
                        {...item}
                      />
                    )}
                  />
                </CartItemsDiv>
              </Row>
            </LeftSection>
            {hasItems && (
              <RightSection>
                <OrderSummaryContainer>
                  <SubTitle>
                    <FormattedMessage
                      id={Message.ORDER_SUMMARY}
                      tagName="span"
                    />
                  </SubTitle>
                  <Row>
                    <Col text="uppercase">
                      <FormattedMessage
                        id={
                          country === SupportedCountry.USA
                            ? Message.CHECKOUT_SUB_TOTAL_LABEL
                            : Message.CHECKOUT_TOTAL_LABEL
                        }
                        tagName="span"
                      />
                    </Col>
                    <Col weight="500" text="uppercase">
                      {price}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormattedMessage
                        id={Message.CHECKOUT_SHIPPING_LABEL}
                        tagName="span"
                      />
                    </Col>
                    <Col>{shippingAmount}</Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormattedMessage
                        id={
                          country === SupportedCountry.ISRAEL
                            ? Message.CHECKOUT_VAT_LABEL
                            : Message.CHECKOUT_TAX_LABEL
                        }
                        tagName="span"
                        values={{
                          value:
                            country === SupportedCountry.ISRAEL ? '17%' : '10%',
                        }}
                      />
                    </Col>
                    <Col>{vatAmount}</Col>
                  </Row>
                  <StyledHr />
                  <Row>
                    <Col weight="bold" fontSize="22px">
                      <FormattedMessage
                        id={Message.CHECKOUT_TOTAL_LABEL}
                        tagName="span"
                      />
                    </Col>
                    <Col weight="bold" fontSize="22px">
                      {totalAmount}
                    </Col>
                  </Row>
                  <StyledHr />
                  <Row justify="end" align="end">
                    <CheckoutButton
                      className="checkout-button"
                      disabled={outOfStocked}
                      isLoading={isProceedingToCheckout}
                      onClick={onClick}
                    >
                      <FormattedMessage id={Message.CART_PLACE_ORDER_BUTTON} />
                    </CheckoutButton>
                  </Row>
                  <Row>
                    {outOfStocked && (
                      <p className="out-of-stock-caption">
                        <FormattedMessage
                          id={Message.CART_OUT_OF_STOCK_CAPTION}
                        />
                      </p>
                    )}
                  </Row>
                </OrderSummaryContainer>
                <CreditCardProviders />
              </RightSection>
            )}
          </Stack>
        </>
      );
    }
    return null;
  };
  return (
    <>
      <Container>
        <Title
          title={<FormattedMessage id={Message.CART_TITLE} />}
          isModal
          onClose={() => changeModal(null)}
        />
        {renderComponent()}
      </Container>

      {isDeleteOpen && (
        <DeleteItemModal
          open={isDeleteOpen}
          onClose={() => toggleModal()}
          onDelete={deleteItem}
          deleteCandidate={deleteCandidate}
        />
      )}

      <DeleteItemModalAll
        open={isDeleteAllOpen}
        onClose={() => toggleModalAllItem()}
        onDelete={emptyItem}
        deleteCandidateAll={deleteCandidateAll?.ids && deleteCandidateAll?.ids}
      />
      {isRestricted && (
        <RestrictionModal
          open={isRestricted}
          items={restrictedItems}
          isProceeding={isProceedingToCheckout}
          onProceed={proceedToCheckout}
          onClose={onCloseRestrictionModal}
        />
      )}
    </>
  );
};

export default CartModal;

const Container = styled.section`
  .card {
    position: relative;
  }

  & .cart-counter {
    font-size: 0.875rem;
    color: rgba(187, 187, 187, 1);
  }

  & .credit-card-providers {
    order: 3;
  }

  & .checkout-button {
    border-radius: 12px;
    font-size: 1.125rem;
    width: auto;
    margin-top: 16px;
    align-self: flex-end;
    order: 2;
  }

  & .actions {
    ${rtl`
  right: 10%;
  top: 20%;
  
  `}
    height: auto;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    background: white !important;
    box-shadow: 0px -3px 25px 5px rgba(0, 88, 171, 0.07);
    border-radius: 10px;
    .order-summary {
      margin: 0 0 30px 0;
      font-weight: 700;
    }
    .total-price {
      font-weight: 700;
    }
    p {
      max-width: 500px;
      margin: 0 0 10px 0;

      display: flex;
      justify-content: space-between;
    }

    .out-of-stock-caption {
      color: ${({ theme }) => theme.colors.status.error};
      margin-top: 1rem;
      font-size: 0.75rem;
      order: 2;
      text-align: center;

      @media (min-width: 1150px) {
        order: 1;
      }
    }

    a {
      text-decoration: none;
    }
    @media (min-width: 1150px) {
      ${rtl`
    position: fixed;
    right: 10%;
    top: 20%;
    
    `}
      width: auto;
      height: auto;
      margin-top: 0;
      flex-basis: 400px;
      padding: 22px;
      background: rgba(248, 247, 247, 1);
      border-radius: 4px;
      display: flex;
      flex-direction: column;
    }
  }
`;

const Stack = styled.div<{ count?: number | undefined }>`
  display: flex;
  flex-direction: column;
  @media (min-width: 1150px) {
    flex-direction: ${({ count }) => (count ? 'row' : null)};
    justify-content: space-around;
    width: 100%;
  }
`;

const TopContainer = styled.div`
  width: 59%;
`;

const CartItemsDiv = styled.div.attrs({ dir: 'ltr' })`
  width: 100%;
  .card-list {
    max-width: initial;
    width: 100%;
    margin-top: 15px;
    padding: 10px;
    direction: ${({ theme }) => theme.dir};
    .cart-item {
      background: #ffffff;
      box-shadow: 6px 12px 150px rgb(111 118 123 / 13%);
      border-radius: 10px;
      margin-bottom: 20px;
      div {
        padding: 0px;
      }
      .additional-text {
        padding: 15px;
      }
      img {
        width: 123px;
        height: 123px;
        margin: 20px;
      }
      .card-content {
        h4 {
          font-family: ${({ theme }) =>
            theme.dir === 'rtl' ? 'Assistant' : 'Roboto'};
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 14px;
          color: #000000;
        }
        .subtitle-data {
          font-family: ${({ theme }) =>
            theme.dir === 'rtl' ? 'Assistant' : 'Roboto'};
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 14x;
          color: #000000;
          mix-blend-mode: normal;
          opacity: 0.9;
        }
        div:last-child {
          margin-right: ${({ theme }) =>
            theme.dir === 'ltr' ? '40px' : '0px'};
          margin-left: ${({ theme }) => (theme.dir === 'rtl' ? '40px' : '0px')};
        }
        div {
          margin-top: 10px;
        }
        .cart-price-container {
          .cart-item-price {
            font-family: ${({ theme }) =>
              theme.dir === 'rtl' ? 'Assistant' : 'Roboto'};
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 20px;
            color: #000000;
          }
          .view-details-btn {
            font-family: ${({ theme }) =>
              theme.dir === 'rtl' ? 'Assistant' : 'Roboto'};
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            text-decoration-line: underline;
            color: #ff5241;
            mix-blend-mode: normal;
            opacity: 0.9;
          }
          .x-button {
            border: 1.2px solid #000000;
            box-sizing: border-box;
            transform: rotate(45deg);
            width: 23px;
            height: 23px;
            svg {
              width: 10.89px;
              height: 10.89px;
              border: 1.54286px solid #000000;
              transform: rotate(45deg);
            }
          }
        }
      }
      .card-divider {
        display: none;
      }
    }
  }
  .secondary-button {
    ${rtl`
    float: right;
    
    `}
  }
`;

const LeftSection = styled.div`
  @media ${MOBILE_TABLET_RESOLUTION} {
    width: 100%;
  }
  width: 59%;
  margin-right: ${({ theme }) => (theme.dir === 'ltr' ? '20px' : '0px')};
  margin-left: ${({ theme }) => (theme.dir === 'rtl' ? '20px' : '0px')};
`;

const RightSection = styled.div`
  width: 41%;
  height: 631px;
  @media ${MOBILE_TABLET_RESOLUTION} {
    width: 100%;
  }
`;

const Row = styled.div<{
  justify?: string;
  align?: string;
  display?: string;
}>`
  display: ${({ display }) => (!display ? 'inline-flex' : display)};
  gap: 10px;
  justify-content: ${({ justify }) => (!justify ? 'space-between' : justify)};
  align-items: ${({ align }) => (!align ? 'center' : align)};
`;

const TopRow = styled.div<{
  justify?: string;
  align?: string;
  display?: string;
}>`
  display: ${({ display }) => (!display ? 'inline-flex' : display)};
  gap: 10px;
  justify-content: ${({ justify }) => (!justify ? 'space-between' : justify)};
  align-items: ${({ align }) => (!align ? 'center' : align)};
  ${rtl`
  margin-right: 20px;
    `}

  margin-left: ${({ theme }) => (theme.dir === 'ltr' ? '0px' : '20px')};
`;

const Col = styled.div<{
  text?: string;
  weight?: string;
  fontSize?: string;
  color?: string;
}>`
  font-family: ${({ theme }) => (theme.dir === 'rtl' ? 'Assistant' : 'Roboto')};
  font-style: normal;
  font-weight: ${({ weight }) => (!weight ? 'normal' : weight)};
  font-size: ${({ fontSize }) => (!fontSize ? '18px' : fontSize)};
  line-height: 43px;
  text-transform: ${({ text }) => text};
  color: ${({ color }) => (!color ? '#000000' : color)};
`;

const OrderSummaryContainer = styled.section`
  margin-bottom: 40px;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 10px;
  .order-summary {
    margin: 0 0 30px 0;
    font-weight: 700;
  }
  .total-price {
    font-weight: 700;
  }
  p {
    max-width: 500px;
    margin: 0 0 10px 0;

    display: flex;
    justify-content: space-between;
  }

  .out-of-stock-caption {
    color: ${({ theme }) => theme.colors.status.error};
    margin-top: 1rem;
    font-size: 0.75rem;
    order: 2;
    text-align: center;

    @media (min-width: 1150px) {
      order: 1;
    }
  }

  background: #ffffff;
  box-shadow: 6px 12px 150px rgba(111, 118, 123, 0.126885);
  border-radius: 10px;
  padding: 20px;
`;

const SubTitle = styled.span`
  font-family: ${({ theme }) => (theme.dir === 'rtl' ? 'Assistant' : 'Roboto')};
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 43px;
  color: #000000;
  @media ${MOBILE_RESOLUTION} {
    font-size: 16px;
  }
`;

const CheckoutButton = styled(Button)`
  background: #ff5241;
  border-radius: 11px;
  .MuiButton-label {
    padding: 1px 76px 1px 76px;
  }
`;
