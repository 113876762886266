import type { MenuProps } from '@material-ui/core/Menu';
import type { PopoverOrigin } from '@material-ui/core';

export const anchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
};

export const transformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'left',
};

export const menuProps: Partial<MenuProps> = {
  MenuListProps: {
    style: {
      padding: '0.5rem 1rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      borderRadius: 10,
      width: '100%',
      margin: 0,
    },
  },

  PaperProps: {
    style: {
      margin: 0,
      borderRadius: 10,
      boxShadow: 'rgb(13 13 13 / 4%) 1px -1px 12px 6px',
      maxHeight: 300,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};
