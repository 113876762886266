import * as React from 'react';
import { useSpring, animated } from '@react-spring/web';
import { useGesture } from '@use-gesture/react';
import styled from 'styled-components';
import Home from 'assets/icons/home.svg';
import styles from './styles.module.css';
import ConfirmationDialog from '../components/ConfirmationDialog';

const Image: React.FC = React.memo(() => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = React.useState(false);
  const [props, api] = useSpring(() => ({ x: 1, y: 1, scale: 1 }));
  const innerWidth = window?.innerWidth;
  const bind = useGesture({
    onDrag: ({ offset: [x, y] }) =>
      api.start({ x: x < innerWidth / 2 ? 0 : innerWidth - 58, y }),
    onPinch: ({ offset: [x, y] }) =>
      api.start({ x: x < innerWidth / 2 ? 0 : innerWidth - 58, y }),
  });
  return (
    <>
      <div onClick={() => setIsConfirmModalOpen(true)}>
        <animated.img
          className={`${styles.drag} ${styles.img}`}
          src={Home}
          {...bind()}
          style={props}
        />
      </div>
      <ConfirmationDialog
        isOpen={isConfirmModalOpen}
        toggle={() => setIsConfirmModalOpen(!isConfirmModalOpen)}
      />
    </>
  );
});

const DragHomeButton: React.FC = () => {
  return (
    <ImageContainer>
      <Image />
    </ImageContainer>
  );
};

export default DragHomeButton;

const ImageContainer = styled.div`
  position: fixed;
  bottom: 130px;
  left: 0;
  z-index: 9;
`;
