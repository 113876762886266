import * as React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import { ClientRouter, SupplierDashBoardRoutes } from 'router/routes';
import PatriciaLogo from 'assets/@client/supplier-login-flow/patricia-red-text.png';
import CongratulationsImage from 'assets/@client/supplier-login-flow/supplier-login-congratulations.png';
import RightImage from 'assets/@client/supplier-login-flow/supplier-login-right.png';
import { useRouter } from 'hooks';
import { useMediaQuery } from '@mui/material';
import {
  MOBILE_RESOLUTION,
  MOBILE_TABLET_MIN_RESOLUTION,
  MOBILE_TABLET_RESOLUTION,
} from 'styles/sharedStyle';
import { MainDetailsParent } from '../supplier-info/supplier-main-details/SupplierMainDetails';
import SupplierLoginCongratulationsMobile from './SupplierLoginCongratulationsMobile';

enum TYPES {
  UPLOAD_PRODUCT = 'upload_product',
  SKIP = 'skip',
  SCHEDULED_MEETING = 'schedule_meeting',
}
const SupplierLoginCongratulations: React.FC = () => {
  const location = useRouter();
  const isMobile = useMediaQuery(MOBILE_RESOLUTION);
  const isTablet = useMediaQuery(
    `${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION}`
  );
  const { type } = location.query;

  const CongratsText = React.useMemo(() => {
    let MSG;
    if (type === TYPES.UPLOAD_PRODUCT) {
      MSG = (
        <FormattedMessage
          id={Message.SUPPLIER_LOGIN_CONGRATULATIONS_STORE_OPEN_TITLE}
        />
      );
    } else if (type === TYPES.SKIP) {
      MSG = (
        <FormattedMessage
          id={Message.SUPPLIER_LOGIN_CONGRATULATIONS_JOIN_TITLE}
        />
      );
    } else if (type === TYPES.SCHEDULED_MEETING) {
      MSG = (
        <FormattedMessage
          id={Message.SUPPLIER_LOGIN_CONGRATULATIONS_PROVIDED_TITLE}
        />
      );
    }
    return MSG;
  }, [type]);

  const CongratsFirstText = React.useMemo(() => {
    let MSG;
    if (type === TYPES.UPLOAD_PRODUCT) {
      MSG = (
        <FormattedMessage
          id={Message.SUPPLIER_LOGIN_FIRST_CONGRATULATIONS_SUBTITLE}
          values={{
            linebreak: <br />,
            span: (chunks: string) => (
              <span style={{ fontWeight: 500 }}>{chunks}</span>
            ),
          }}
        />
      );
    } else if (type === TYPES.SKIP) {
      MSG = (
        <FormattedMessage
          id={Message.SUPPLIER_LOGIN__CONGRATULATIONS_SUBTITLE_SKIP}
          values={{ linebreak: <br /> }}
        />
      );
    } else if (type === TYPES.SCHEDULED_MEETING) {
      MSG = (
        <FormattedMessage
          id={Message.SUPPLIER_LOGIN__CONGRATULATIONS_SUBTITLE_SCHEDULED}
          values={{ linebreak: <br /> }}
        />
      );
    }
    return MSG;
  }, [type]);

  const ParentMargin = React.useMemo(() => {
    if (!isTablet) {
      if (type === TYPES.UPLOAD_PRODUCT) {
        return '6px';
      }
      if (type === TYPES.SKIP) {
        return '28px';
      }
      return '178px';
    }
    return '0px';
  }, [isTablet, type]);

  return (
    <>
      {isMobile ? (
        <SupplierLoginCongratulationsMobile />
      ) : (
        <ParentLoginWelcome>
          <a href={ClientRouter.APP}>
            <PatriciaLogoContainer src={PatriciaLogo} alt="patricia-logo" />
          </a>
          <MainDetailsParent>
            <ParentDiv topSpace={ParentMargin}>
              {type === TYPES.UPLOAD_PRODUCT || type === TYPES.SKIP ? (
                <SupplierLoginCongratulationsImage
                  src={CongratulationsImage}
                  alt="congrats-logo"
                />
              ) : (
                <SupplierLoginRightImage src={RightImage} alt="right-image" />
              )}

              {(type === TYPES.UPLOAD_PRODUCT || type === TYPES.SKIP) && (
                <CongratsTitle>
                  <FormattedMessage
                    id={Message.SUPPLIER_LOGIN_CONGRATULATIONS_TITLE}
                  />
                </CongratsTitle>
              )}

              <WelcomeTitle>{CongratsText}</WelcomeTitle>
              <SubTitle>
                {CongratsFirstText}
                {/* <FormattedMessage
            id={Message.SUPPLIER_LOGIN_FIRST_CONGRATULATIONS_SUBTITLE}
            values={{ linebreak: <br /> }}
          /> */}
              </SubTitle>
              {type === TYPES.UPLOAD_PRODUCT && (
                <SecondSubTitle>
                  <FormattedMessage
                    id={Message.SUPPLIER_LOGIN_SECOND_CONGRATULATIONS_SUBTITLE}
                    values={{ linebreak: <br /> }}
                  />
                </SecondSubTitle>
              )}

              {type === TYPES.UPLOAD_PRODUCT || type === TYPES.SKIP ? (
                <GoToYourShopButton
                  onClick={() =>
                    location.history.push(SupplierDashBoardRoutes.INFO)
                  }
                >
                  <GoToYourShopText>
                    <FormattedMessage
                      id={Message.SUPPLIER_LOGIN_WELCOME_CONGRATULATIONS_BUTTON}
                    />
                  </GoToYourShopText>
                </GoToYourShopButton>
              ) : (
                <GoToHomeButton
                  onClick={() =>
                    location.history.push(SupplierDashBoardRoutes.INFO)
                  }
                >
                  <GoToHomeText>
                    <FormattedMessage
                      id={Message.SUPPLIER_LOGIN_WELCOME_GO_HOME_BUTTON}
                    />
                  </GoToHomeText>
                </GoToHomeButton>
              )}

              {(type === TYPES.UPLOAD_PRODUCT || type === TYPES.SKIP) && (
                <ReturnToHome
                  onClick={() => location.history.push(ClientRouter.APP)}
                >
                  <FormattedMessage
                    id={
                      Message.SUPPLIER_LOGIN_FIRST_CONGRATULATIONS_RETURN_TO_HOME
                    }
                  />
                </ReturnToHome>
              )}
            </ParentDiv>
          </MainDetailsParent>
        </ParentLoginWelcome>
      )}
    </>
  );
};
export default SupplierLoginCongratulations;

const ParentLoginWelcome = styled.div`
  margin-top: -60px;
  direction: ltr;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    margin-top: 0;
  }
  font-family: 'Roboto Condensed';
`;
const ParentDiv = styled.div<{ topSpace: string }>`
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  margin-top: ${({ topSpace }) => topSpace};
  //46px;
`;

const PatriciaLogoContainer = styled.img`
  height: 140px;
  width: 88px;
  margin-top: 24px;
  margin-left: 184px;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    margin-left: 24px;
    position: absolute;
  }
`;

const SupplierLoginCongratulationsImage = styled.img`
  width: 300px;
  height: 222px;
`;
const SupplierLoginRightImage = styled.img`
  width: 88px;
  height: 66px;
`;

const GoToYourShopButton = styled.button`
  cursor: pointer;
  background: #fc5c42;
  border: 0.5px solid #d9d9d9;
  border-radius: 15px;
  padding: 6px 48px;
  width: 223px;
  height: 48px;
  margin-top: 24px;
`;

const GoToHomeButton = styled.button`
  cursor: pointer;
  background: #fc5c42;
  border: 0.5px solid #d9d9d9;
  border-radius: 15px;
  padding: 6px 48px;
  width: 234px;
  height: 48px;
  margin-top: 24px;
`;

const ReturnToHome = styled.div`
  cursor: pointer;
  text-decoration-line: underline;
  color: #696969;
  font-size: 16px;
  line-height: 20px;
  margin-top: 16px;
`;

const GoToYourShopText = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.9);
  width: 130px;
`;
const GoToHomeText = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.9);
  width: 140px;
`;
const CongratsTitle = styled.div`
  font-weight: 600;
  font-size: 40px;
  line-height: 44px;
  color: #2cdd73;
  margin-top: 16px;
`;

const WelcomeTitle = styled.div`
  font-weight: 600;
  font-size: 40px;
  line-height: 44px;
  color: #282929;
  margin-top: 16px;
`;

const SubTitle = styled.div`
  font-size: 24px;
  line-height: 28px;
  color: #3e3e3e;
  margin-top: 16px;
`;

const SecondSubTitle = styled.div`
  font-size: 24px;
  line-height: 28px;
  color: #3e3e3e;
  margin-top: 16px;
`;
