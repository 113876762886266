import * as React from 'react';
import Popper, { PopperProps } from '@material-ui/core/Popper';
import { Link, LinkProps } from 'react-router-dom';
import {
  Fade,
  List,
  ListItem,
  Paper,
  ClickAwayListener,
} from '@material-ui/core';
import styled from 'styled-components';
import { removeEmptyValues } from 'utils/common.utils';

export type MenuItem = {
  onClick?: (props: unknown) => void | undefined;
  to?: LinkProps['to'];
  component: JSX.Element | string;
  disabled?: boolean;
  divider?: boolean;
  displayNormalText?: boolean;
  icon?: string;
  visibleOnly?: string;
};

interface IProps extends Partial<PopperProps> {
  className?: string;
  button: React.FC<{
    open: boolean;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  }>;
  items: MenuItem[];
  top?: number;
}

const PopperMenu: React.FC<IProps> = ({
  className = '',
  button: Button,
  items,
  top,
  placement = 'bottom-end',
  disablePortal,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);

  const closeMenu = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(prev => (prev ? null : event.currentTarget));
    },
    []
  );

  return (
    <ClickAwayListener onClickAway={closeMenu}>
      <Root className={className}>
        <Button onClick={handleClick} open={open} />
        <DropDown
          open={open}
          className="menu-popper"
          anchorEl={anchorEl}
          placement={placement}
          top={top}
          disablePortal={disablePortal}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={150}>
              <List
                className="list-paper"
                component={ListContainer}
                onClick={closeMenu}
              >
                {items.map(
                  ({ onClick, component, disabled, divider, to }, idx) => {
                    const Element = to ? CustomLink : React.Fragment;
                    const props = removeEmptyValues({
                      to,
                    }) as { to: string };

                    return (
                      <Element key={idx.toString()} {...props}>
                        <Item onClick={onClick} disabled={disabled}>
                          {component}
                        </Item>
                        {divider && <Divider />}
                      </Element>
                    );
                  }
                )}
              </List>
            </Fade>
          )}
        </DropDown>
      </Root>
    </ClickAwayListener>
  );
};

export default React.memo(PopperMenu);

const Root = styled.div`
  position: relative;
`;

const DropDown = styled(Popper)<{ top?: number }>`
  && {
    z-index: 2000;
    top: ${({ top = 16 }) => top}px !important;
    .MuiPaper-root {
      ${({ theme }) => theme.shadow.l};
    }
  }
`;

const ListContainer = styled(Paper)`
  width: 200px;
  right: 5px;
`;

const Item = styled(ListItem).attrs(() => ({ button: true }))`
  && {
    display: flex;
    justify-content: start;
    padding: 1rem;
    font-weight: 400;
    font-size: 14px;
  }
`;

const CustomLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const Divider = styled.hr`
  background: #dee0e3;
  border: none;
  height: 1px;
  margin: 0 12px;
`;
