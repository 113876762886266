import * as React from 'react';
import styled from 'styled-components';
import providers from 'assets/@client/cart/credit-card-providers.png';

const CreditCardProviders = () => {
  return (
    <>
      <Container>
        <img
          src={providers}
          alt="A list of logos of credit card providers we accept: VISA, MasterCard, PayPal, American Express, and VISA Electron."
        />
      </Container>
    </>
  );
};

export default CreditCardProviders;

const Container = styled.article.attrs({ className: 'credit-card-providers' })`
  text-align: center;
  h4 {
    font-weight: 500;
    font-size: 1.125rem;
    margin-bottom: 10px;
  }
  img {
    margin: 10px;
    width: 250px;
  }
  p {
    font-size: 1rem;
  }
`;
