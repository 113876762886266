import * as React from 'react';
import { useFormattedCurrency } from 'hooks';
import styled from 'styled-components';
import {
  MOBILE_RESOLUTION,
  MOBILE_TABLET_RESOLUTION,
} from 'styles/sharedStyle';
import countries from 'data/countries';
import { SupportedCurrency } from 'types/common.types';

interface Props {
  label: string | React.ReactNode;
  value: number;
  country?: string;
}

const SummaryField: React.FC<Props> = ({ label, value, country }) => {
  const currency =
    country === countries.IL ? SupportedCurrency.ILS : SupportedCurrency.USD;
  const price = useFormattedCurrency(value, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency,
  });
  return (
    <Container>
      <span className="order-summary-label">{label}</span>
      <span className="order-summary-value">{price}</span>
    </Container>
  );
};

export default SummaryField;

const Container = styled.p.attrs({ className: 'order-summary-field' })`
  display: flex;
  justify-content: space-between;
  max-width: 350px;
  font-size: 1.375rem;

  & .order-summary-value {
    font-weight: 500;
  }

  @media ${MOBILE_TABLET_RESOLUTION} {
    margin: 15px 0;
  }

  @media ${MOBILE_RESOLUTION} {
    margin: 15px 0;
    & span {
      font-size: 14px;
    }
  }
`;
