import * as React from 'react';

// This hook is used in PrivateRoute or Public page that includes users in order to validate token and get user data.

const useWindowScroll = () => {
  const [topPosition, setTopPosition] = React.useState<boolean>(false);
  const handleScroll = React.useCallback(() => {
    if (
      window.document.body.scrollTop > 50 ||
      window.document.documentElement.scrollTop > 50
    ) {
      setTopPosition(true);
    } else {
      setTopPosition(false);
    }
  }, []);
  React.useEffect(() => {
    window.onscroll = () => {
      handleScroll();
    };
  }, [handleScroll]);

  return {
    topPosition,
  };
};

export default useWindowScroll;
