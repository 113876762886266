import * as React from 'react';
import {
  ReactFacebookLoginInfo,
  ReactFacebookFailureResponse,
} from 'react-facebook-login';
import ProviderButton from 'components/Button/ProviderButton';
import FacebookIcon from 'assets/icons/facebook.svg';
import { facebookClientId } from 'constants/config';
import FacebookIconOnboarding from 'assets/icons/facebook-onboarding.png';
import styled from 'styled-components';
import { useNotifications, useRouter } from 'hooks';
import { useDispatch } from 'store/selectors';
import * as Authentication from 'store/actions/authentication.actions';
import { OnboardingFields } from 'store/types/authentication.types';
import { ClientRouter } from 'router/routes';
import { userLogData } from 'mockData/userLogData';
import { LogsAPI } from 'api/methods';
import FacebookLogin from './FacebookLogin';
import type { AuthContext } from '../types';

interface Props {
  authContext: AuthContext;
  forceLabel?: boolean;
  onboardingFields?: OnboardingFields;
  isOnBoarding?: boolean;
}

const FacebookProvider: React.FC<Props> = ({
  authContext,
  forceLabel,
  onboardingFields,
  isOnBoarding,
}) => {
  const dispatch = useDispatch();
  const { showNotification } = useNotifications();
  const { query } = useRouter<{ redirect: string }>();

  const onSuccess = React.useCallback(
    (res: ReactFacebookLoginInfo | ReactFacebookFailureResponse) => {
      if (!query.redirect) {
        dispatch(
          Authentication.loginWithProviderRequest({
            backend: 'facebook',
            token: (res as ReactFacebookLoginInfo).accessToken,
            onboardingFields,
          })
        );
      } else {
        dispatch(
          Authentication.redirectLoginWithProviderRequest({
            redirect: ClientRouter.CART,
            registerPayload: {
              backend: 'facebook',
              token: (res as ReactFacebookLoginInfo).accessToken,
              onboardingFields,
            },
          })
        );
      }
    },
    [dispatch, onboardingFields, query.redirect]
  );

  const onFailure = React.useCallback(
    (e: ReactFacebookFailureResponse) => {
      showNotification({
        key: 'login/facebook',
        message:
          'An error has been occurred with Facebook login, please try again later',
        severity: 'error',
      });
    },
    [showNotification]
  );

  const facebookSingIn = (
    e: React.MouseEvent<HTMLButtonElement | HTMLImageElement>,
    onClick: React.MouseEventHandler<HTMLButtonElement | HTMLImageElement>
  ) => {
    LogsAPI.postUserLogs({
      area: userLogData.eventArea.registration,
      section: userLogData.eventSection.userInfo,
      name: userLogData.eventName.facebook,
      path: userLogData.eventPath.appOnBoardingAdditionalInfoPath,
    });

    onClick(e);
  };

  return (
    <FacebookLogin
      appId={facebookClientId}
      callback={onSuccess}
      onFailure={onFailure}
      fields="name,email"
      render={({ onClick }) => (
        <>
          {isOnBoarding ? (
            <FacebookOnboarding
              src={FacebookIconOnboarding}
              alt="facebook-icon"
              onClick={e => facebookSingIn(e, onClick)}
            />
          ) : (
            <div className="provider-button-wrapper">
              <ProviderButton
                provider="Facebook"
                icon={FacebookIcon}
                authContext={authContext}
                forceLabel={forceLabel}
                onClick={e => facebookSingIn(e, onClick)}
              />
            </div>
          )}
        </>
      )}
    />
  );
};

export default FacebookProvider;

const FacebookOnboarding = styled.img`
  cursor: pointer;
`;
