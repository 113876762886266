import styled from 'styled-components';
import SearchBarComponent from 'components/@client/SearchBarComponent/SearchBarComponent';
import Title from 'components/@client/TopTitle';
import { MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';
import { ClientRouter } from 'router/routes';
import { useRouter } from 'hooks';

interface Props {
  title: string;
}

const MobileHeader: React.FC<Props> = ({ title }) => {
  const { location } = useRouter();
  return (
    <Container>
      <Title title={title} />
      {location.pathname !== ClientRouter.APP && (
        <SearchContainer>
          <SearchBarComponent />
        </SearchContainer>
      )}
    </Container>
  );
};

export default MobileHeader;

const Container = styled.section`
  & > :first-child {
    display: flex;
    svg {
      cursor: pointer;
      margin: auto 0;
    }
  }
`;

const SearchContainer = styled.div`
  & > div {
    width: 100%;
  }
  margin: 28px 0px 18px;

  @media ${MOBILE_TABLET_RESOLUTION} {
    margin: 10px 0px 18px;
  }
`;
