import * as React from 'react';
import { useOnboarding } from 'context/onboardingContext';
import type { MediaFile } from 'types/common.types';

const useGridItems = () => {
  const [{ answers, questions }] = useOnboarding();

  const extractImages = React.useCallback(
    ([questionId, answerId]: [string, number | null]) =>
      (questions
        .find(({ id }) => id.toString() === questionId.toString())
        ?.answers.find(({ id }) => id?.toString() === answerId?.toString())
        ?.answer as MediaFile)?.file,
    [questions]
  );

  const gridItems = React.useMemo(
    () => Object.entries(answers).map(extractImages),
    [answers, extractImages]
  );

  return Array.from(
    { length: gridItems.length },
    (_, i) => gridItems[i]
  ).filter(e => Boolean(e));
};

export default useGridItems;
