import qs from 'qs';
import { NO_USERNAME_PLACEHOLDER, NO_VALUE } from 'constants/common.constants';
import countries from 'data/countries';
import type { Client } from 'types/clients';
import type { Supplier } from 'types/suppliers';
import type { TeamMember } from 'types/teamPatricia';
import type { Property, PropertyOwner } from 'types/properties';
import {
  Handler,
  SupportedCountry,
  SupportedCurrency,
} from 'types/common.types';
import { capitalize, isNumber, lowerCase } from './lodash.utils';

export const summarizeText = (string: string, limit: number) => {
  if (!string) return '';
  if (string.length <= limit) return string;
  return `${string.substring(0, limit)}...`;
};

type LocaleStringOptions = {
  value?: number | string;
  currency?: string;
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
  notation?: string;
  placeholder?: string;
  country?: string;
};

export const toCurrencyString = ({
  value,
  maximumFractionDigits = 2,
  minimumFractionDigits = 2,
  notation = 'standard',
  placeholder = NO_VALUE,
  country = countries[SupportedCountry.USA],
}: LocaleStringOptions) =>
  value || value === 0
    ? value.toLocaleString(
        country === countries[SupportedCountry.ISRAEL] ? 'he-IL' : 'en-US',
        {
          style: 'currency',
          currency:
            country === countries[SupportedCountry.ISRAEL]
              ? SupportedCurrency.ILS
              : SupportedCurrency.USD,
          minimumFractionDigits,
          maximumFractionDigits,
          notation,
        }
      )
    : placeholder;

export const toPercentageString = ({
  value,
  maximumFractionDigits = 0,
  placeholder = '',
}: LocaleStringOptions) =>
  isNumber(value)
    ? value.toLocaleString('en-US', {
        style: 'percent',
        maximumFractionDigits,
      })
    : placeholder;

export const stringify = <T extends object>(
  params: T,
  options: qs.IStringifyOptions = {}
) => qs.stringify(params, { addQueryPrefix: true, ...options });

export const parse = (
  params: string,
  options?: qs.IParseOptions & {
    decoder?: undefined;
  }
) => qs.parse(params, { ignoreQueryPrefix: true, ...options });

export const getUserName = (
  user?:
    | Client
    | TeamMember
    | Supplier
    | Handler
    | PropertyOwner
    | { firstName?: string; lastName?: string },
  fallback = 'User'
) =>
  user?.firstName
    ? `${user?.firstName} ${user?.lastName ?? ''}`.trimEnd()
    : fallback;

export const prefixPhoneNumber = (phone: string) =>
  phone.startsWith('+') ? phone : `+${phone}`;

export const nameInitials = (name: string = NO_USERNAME_PLACEHOLDER) =>
  name
    .replaceAll('-', ' ')
    .split(' ')
    .slice(0, 2)
    .map(n => n[0] ?? ' ');

export const getPropertyIds = (properties: Property[]) => {
  const isAboveLimit = properties.length > 2;
  const propString = properties
    .slice(0, 2)
    .map(property => property.id)
    .join(', ');
  return isAboveLimit
    ? propString.concat(`... +${properties.length - 2}`)
    : propString;
};

const toILS = (value: number) =>
  toCurrencyString({
    value,
    currency: 'ILS',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

export const toILSRange = (a: number, b: number) => `${toILS(a)} - ${toILS(b)}`;

export const formatEnumToStr = (str: string) => capitalize(lowerCase(str));
