import * as React from 'react';
import styled from 'styled-components';
import LoginImage from 'assets/@client/supplier-login-flow/login-main-image.png';
import PatriciaLogo from 'assets/@client/supplier-login-flow/patricia-red-text.png';
import { FormattedMessage, useIntl } from 'react-intl';
import { Message } from 'i18n';
import eyeIcon from 'assets/@client/onboarding/eye-down.png';
import eyeUpIcon from 'assets/@client/onboarding/eye-up.png';
import noop from 'lodash/noop';
import { useAuthentication } from 'hooks';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Form from 'components/forms';
import Icon from 'components/Icon';
import { ClientRouter } from 'router/routes';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';
import { loginSchema } from 'utils/validation.utils';
import { LoginPayload } from 'store/types/authentication.types';
import { AuthForm, Fields } from 'pages/auth/Auth.config';
import SupplierLoginMobile from './SupplierLoginMobile';

const SupplierLogin: React.FC = () => {
  const intl = useIntl();
  const isMobile = useMediaQuery(MOBILE_TABLET_RESOLUTION);
  const methods = useForm<LoginPayload>({
    resolver: yupResolver(loginSchema),
    defaultValues: {
      email: '',
      password: '',
      rememberUser: true,
    },
    mode: 'all',
  });
  const [isPasswordVidsible, setIsPasswordVisible] = React.useState(false);
  const { handleSubmit, formState } = methods;
  const { isValid, submitCount } = formState;
  const { email, password } = useWatch({
    control: methods.control,
  });
  const { login, loading: isLoading, resetError } = useAuthentication();
  const onSubmit = (payload: LoginPayload) => {
    login({ ...payload, loginType: 'SUPPLIER' });
  };

  React.useEffect(() => {
    if (submitCount) {
      resetError();
    }
  }, [email, password, submitCount, resetError]);
  return (
    <>
      {isMobile ? (
        <SupplierLoginMobile />
      ) : (
        <ParentLogin>
          <LeftContainer>
            <a href={ClientRouter.APP}>
              <PatriciaLogoContainer src={PatriciaLogo} alt="patricia-logo" />
            </a>
            <LoginTitle>
              <FormattedMessage id={Message.SUPPLIER_LOGIN_TITLE} />
            </LoginTitle>
            <LoginSubTitle>
              <FormattedMessage
                id={Message.SUPPLIER_LOGIN_SUBTITLE}
                values={{
                  span: (chunks: string) => (
                    <Link to={ClientRouter.SUPPLIER_REGISTER}>
                      <SingInText>{chunks}</SingInText>
                    </Link>
                  ),
                }}
              />
            </LoginSubTitle>
            <LoginForm>
              <FormProvider {...methods}>
                <AuthForm>
                  <FieldsDiv>
                    <FormFieldDiv
                      autoComplete="off"
                      showValidAdornment
                      onKeyDown={noop}
                      label={{
                        title: intl.formatMessage({
                          id: Message.SUPPLIER_LOGIN_EMAIL_LABEL,
                        }),
                        isBold: true,
                      }}
                      name="email"
                      type="email"
                      dir="ltr"
                      placeholder={intl.formatMessage({
                        id: Message.SUPPLIER_LOGIN_EMAIL_PLACEHOLDER,
                      })}
                    />
                    <FormFieldDiv
                      autoComplete="off"
                      endAdornment={
                        <IconParent
                          onClick={() =>
                            setIsPasswordVisible(!isPasswordVidsible)
                          }
                        >
                          <img
                            alt="temp"
                            src={isPasswordVidsible ? eyeUpIcon : eyeIcon}
                          />
                        </IconParent>
                      }
                      onKeyDown={noop}
                      label={{
                        title: intl.formatMessage({
                          id: Message.AUTH_LOGIN_PASSWORD_FIELD,
                        }),
                        isBold: true,
                      }}
                      name="password"
                      dir="ltr"
                      type={isPasswordVidsible ? 'text' : 'password'}
                      placeholder={intl.formatMessage({
                        id: Message.SUPPLIER_LOGIN_PASSWORD_PLACEHOLDER,
                      })}
                    />
                  </FieldsDiv>
                </AuthForm>
              </FormProvider>
            </LoginForm>
            <ContinueButton
              onClick={handleSubmit(onSubmit)}
              disabled={!isValid || isLoading}
            >
              {isLoading ? (
                <CustomIcon name="spinner" size={20} />
              ) : (
                <ContinueText>
                  <FormattedMessage
                    id={Message.SUPPLIER_LOGIN_CONTINUE_BUTTON}
                  />
                </ContinueText>
              )}
            </ContinueButton>
            <RegisterParent>
              <UnregisteredText>
                <FormattedMessage id={Message.SUPPLIER_LOGIN_NEW_TO_APP} />
              </UnregisteredText>
              <Link to={ClientRouter.SUPPLIER_REGISTER}>
                <RegisterNowText>
                  <FormattedMessage id={Message.SUPPLIER_LOGIN_SIGN_UP_HERE} />
                </RegisterNowText>
              </Link>
            </RegisterParent>
          </LeftContainer>
          <RightContainer>
            <img
              style={{ height: '100%' }}
              src={LoginImage}
              alt="login-seller"
            />
          </RightContainer>
        </ParentLogin>
      )}
    </>
  );
};

export default SupplierLogin;

const ParentLogin = styled.div`
  display: flex;
  /* height: 100vh; */
  margin-top: -65px;
  font-family: 'Roboto Condensed';
  direction: ltr;
`;

const LeftContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 217px;
`;

const RightContainer = styled.div`
  width: 50%;
`;

const IconParent = styled.div`
  position: absolute;
  right: 16px;
  top: 15px;
  cursor: pointer;
`;

export const PatriciaLogoContainer = styled.img`
  height: 140px;
  width: 88px;
`;

const LoginTitle = styled.div`
  font-weight: 600;
  font-size: 40px;
  line-height: 44px;
  color: #282929;
  margin: 24px 20px 0 20px;
  text-align: center;
`;

const LoginSubTitle = styled.div`
  font-size: 24px;
  line-height: 28px;
  color: #3e3e3e;
  margin-top: 8px;
  display: flex;
  gap: 5px;
`;

const ContinueButton = styled.button`
  :disabled {
    cursor: default;
    background: #d9d9d9;
  }
  cursor: pointer;
  background: #fc5c42;
  border: 0.5px solid #d9d9d9;
  border-radius: 15px;
  padding: 6px 24px;
  width: 410px;
  height: 48px;
  margin-top: 24px;
`;

const ContinueText = styled.div`
  color: #ffffff;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
`;

const RegisterParent = styled.div`
  display: flex;
  gap: 4px;
  margin-top: 16px;
`;

const UnregisteredText = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: #696969;
`;

const RegisterNowText = styled.div`
  text-decoration-line: underline;
  color: #5c95ff;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
`;

const LoginForm = styled.div`
  margin-top: 24px;
`;

const FieldsDiv = styled(Fields)`
  gap: 16px;
`;

const FormFieldDiv = styled(Form.Field)`
  label {
    font-size: 16px;
    line-height: 20px;
    color: #696969;
    margin-left: 0;
  }
  .MuiInputBase-root {
    width: 410px !important;
    margin-top: 20px;
  }
`;

const SingInText = styled.div`
  color: #5c95ff;
  font-size: 24px;
  line-height: 28px;
  text-decoration-line: underline;
  cursor: pointer;
`;

const CustomIcon = styled(Icon)`
  ${({ theme }) => theme.utils.spinAnimation};
  position: absolute;
  fill: #f9fafa;
`;
