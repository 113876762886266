import { Country } from 'components/Autocomplete/Autocomplete.config';
import { ProductDataPayload } from 'pages/appSupplier/my-database/modals/ProductFormModal/ProductFormModal.config';
import * as React from 'react';

export type StoreInfo = {
  storeName: string;
  countries: Country | null;
  phoneNumber: string;
  website: string;
  logo: string | null;
  taxId: string;
};
interface State {
  selectedCategories: number[];
  addProductDetails: Partial<ProductDataPayload>;
  isScheduledMeeting: boolean;
  storeInfo: StoreInfo;
}

const initialState: State = {
  selectedCategories: [],
  addProductDetails: {},
  isScheduledMeeting: false,
  storeInfo: {
    storeName: '',
    countries: null,
    phoneNumber: '',
    website: '',
    logo: null,
    taxId: '',
  },
};

export enum SupplierOnboardingAction {
  SET_SELECTED_CATEGORIES = 'setSelectedCategories',
  SET_ADD_PRODUCT_DETAILS = 'setAddProductDetails',
  SET_IS_SHEDULED_MEETING = 'setIsScheduledMeeting',
  SET_STORE_INFO = 'setStoreInfo',
}

type Payload = number[] | Partial<ProductDataPayload> | boolean;

export interface Action {
  type: SupplierOnboardingAction;
  payload: Payload;
}

type SupplierOnboardingReducer = (state: State, action: Action) => State;

const onboardingReducer: SupplierOnboardingReducer = (
  state,
  { type, payload }
) => {
  switch (type) {
    case SupplierOnboardingAction.SET_SELECTED_CATEGORIES:
      return {
        ...state,
        selectedCategories: payload as number[],
      };
    case SupplierOnboardingAction.SET_IS_SHEDULED_MEETING:
      return {
        ...state,
        isScheduledMeeting: payload as boolean,
      };
    case SupplierOnboardingAction.SET_ADD_PRODUCT_DETAILS:
      return {
        ...state,
        addProductDetails: {
          ...state.addProductDetails,
          ...(payload as Partial<ProductDataPayload>),
        },
      };
    case SupplierOnboardingAction.SET_STORE_INFO:
      return {
        ...state,
        storeInfo: {
          ...state.storeInfo,
          ...(payload as StoreInfo),
        },
      };
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

const defaultDispatch: React.Dispatch<Action> = () => initialState;
const SupplierOnboardingStateContext = React.createContext<State>(initialState);
const SupplierOnboardingDispatchContext = React.createContext(defaultDispatch);

const SupplierOnboardingContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer<React.Reducer<State, Action>>(
    onboardingReducer,
    initialState
  );

  return (
    <SupplierOnboardingStateContext.Provider value={state}>
      <SupplierOnboardingDispatchContext.Provider value={dispatch}>
        {children}
      </SupplierOnboardingDispatchContext.Provider>
    </SupplierOnboardingStateContext.Provider>
  );
};

const useSupplierOnboardingState = () => {
  return React.useContext(SupplierOnboardingStateContext);
};

const useSupplierOnboardingDispatch = () => {
  return React.useContext(SupplierOnboardingDispatchContext);
};

const useSupplierOnboarding: () => [State, React.Dispatch<Action>] = () => {
  return [useSupplierOnboardingState(), useSupplierOnboardingDispatch()];
};

export {
  SupplierOnboardingContextProvider,
  useSupplierOnboardingState,
  useSupplierOnboardingDispatch,
  useSupplierOnboarding,
};
