import Skeleton from '@material-ui/core/Skeleton';
import styled, { css } from 'styled-components';
import rtl from 'styled-components-rtl';
import { MOBILE_TABLET_RESOLUTION, SMALL_RESOLUTION } from 'styles/sharedStyle';
import { ViewProps, ProductView } from './ProductItem';

const ProductSkeleton = (props?: ViewProps) => {
  const itemsProps: ItemsProps = {
    $view: props?.view,
    $isWide: props?.isWide,
  };

  return (
    <Item {...itemsProps}>
      <PhotoSkeleton {...itemsProps} />
      <TextSkeleton width={100} />
      <TextSkeleton width={50} />
    </Item>
  );
};

export default ProductSkeleton;

interface ItemsProps {
  $view?: ProductView;
  $isWide?: boolean;
}

const Item = styled.li<ItemsProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${({ $view, $isWide }) =>
    $view === ProductView.LIST &&
    css`
      width: 30% !important;

      @media ${MOBILE_TABLET_RESOLUTION} {
        width: ${$isWide ? '100%' : '44%'} !important;
      }
    `}
`;

const PhotoSkeleton = styled(Skeleton).attrs({
  width: 160,
  height: 155,
  variant: 'rectangular',
})<ItemsProps>`
  border-radius: 25px;
  margin: 10px;

  ${({ $view, $isWide }) =>
    $view === ProductView.LIST &&
    css`
      margin: 0 !important;
      width: 100% !important;
      height: 21vw !important;

      @media ${SMALL_RESOLUTION} {
        height: 24vw !important;
      }

      @media ${MOBILE_TABLET_RESOLUTION} {
        height: 40vw !important;
      }
    `}
`;

const TextSkeleton = styled(Skeleton).attrs({ variant: 'text' })`
  ${rtl`
    margin-left: 15px;
  `}
`;
