import * as React from 'react';
import { useIntl } from 'react-intl';
import rtl from 'styled-components-rtl';
import { useController, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import FormHelperText from '@material-ui/core/FormHelperText';
import { error as errorColor } from 'styles/sharedStyle';
import Icon from 'components/Icon/Icon';
import ReactPhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Label, { ILabel } from 'components/forms/FormLabel';
import { useSession } from 'hooks';
import { Message } from 'i18n';

interface Props extends PhoneInputProps {
  name: string;
  title?: string;
  required?: boolean;
  width?: string;
  className?: string;
  label?: ILabel;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  country?: string;
  disableDropdown?: boolean;
  showError?: boolean | undefined;
}

const PhoneInput = ({
  name,
  inputClass = '',
  required = false,
  title = '',
  label,
  country,
  enableSearch = true,
  disableSearchIcon = true,
  width,
  className,
  onKeyDown,
  onChange,
  disableDropdown = false,
  value,
  showError,
  inputProps,
}: Props) => {
  const intl = useIntl();
  const { control, formState } = useFormContext();
  const errorMessage = formState.errors[name]?.message;
  const { country: defaultCountry } = useSession();
  const {
    field,
    meta: { invalid },
  } = useController({ name, control });

  const defaultValue = React.useMemo(() => {
    return value;
  }, [value]);

  return (
    <Styled $width={width} className={className ?? name}>
      <Label
        label={label ?? { title }}
        htmlFor={name}
        isInvalid={invalid}
        className="phone-number-label"
        required={required}
      />
      <ReactPhoneInput
        country={country?.toLowerCase() ?? defaultCountry?.toLowerCase()}
        specialLabel={title}
        {...field}
        searchPlaceholder={intl.formatMessage({
          id: Message.COUNTRY_SEARCH_PLACEHOLDER,
        })}
        searchNotFound={intl.formatMessage(
          { id: Message.SEARCH_NO_RESULTS },
          { inputValue: 'this search' }
        )}
        inputClass={`phone-input ${inputClass}`}
        containerClass="phone-input-container"
        enableSearch={enableSearch}
        disableSearchIcon={disableSearchIcon}
        onKeyDown={onKeyDown}
        disableDropdown={disableDropdown}
        onChange={(a, b, c, d) => {
          field.onChange(a, b, c, d);
          onChange?.(a, b, c, d);
        }}
        value={`+${defaultValue ?? field.value}`}
        inputProps={inputProps}
      />
      {errorMessage && !showError && (
        <PhoneNumberError>
          <FormHelperText error variant="filled" className="error-label">
            <Icon name="information-circle" color={errorColor} />
            {formState.errors[name]?.message}
          </FormHelperText>
        </PhoneNumberError>
      )}
    </Styled>
  );
};

export default PhoneInput;

const Styled = styled.div.attrs({ dir: 'ltr' })<{ $width?: string }>`
  width: ${({ $width = 'inherit' }) => $width};
  min-height: 48px;
  & .phone-number-label {
    direction: ${({ theme }) => theme.dir};
    color: rgb(63, 66, 84);    
    font-size: 1rem;
    font-weight: bold;
    font-family: ${({ theme }) =>
      theme.dir === 'rtl' ? 'Assistant' : 'Roboto'};
  }

  & .react-tel-input {
    margin-top: 4px;
    & .selected-flag {
      width: 55px;
      & .arrow {
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 7px solid #555;
        left: 28px;
        top: 30% !important;
        margin-top: 0px !important;      
      }
      & .arrow.up {
        border-top: none;
        border-bottom: 7px solid #555;
      }
    }
    & .flag-dropdown.open .selected-flag {
      background-color: inherit;
    }
    & .flag {
      width: 16px !important;
    } 
  }
    & .flag-dropdown,
    & .flag-dropdown.open {
      bottom: 1px;
      z-index: 2;
      border-radius: 10px 0 0 10px;
      background-color: inherit;
      border-color: ${({ theme }) => theme.colors.darkGray};
      width: initial;
      height: 48px;
      &:hover {
        cursor: pointer;
      }
      & .selected-flag:hover {
        background-color: inherit;
      }
    }

    & .form-control {
      border-color: ${({ theme }) => theme.colors.darkGray};
      width: ${({ $width = 'inherit' }) => $width};
      padding-left: 60px;
      z-index: 1;
      border-radius: 10px;
      height: 48px;
    }

    &:hover {
      .form-control,
      .flag-dropdown {
        border-color: #000000;
      }
    }
  }
  .country-list {
    ${({ theme }) => theme.utils.scrollbar}
    &::-webkit-scrollbar {
      width: 6px;
    }
    overflow-y: auto;
    width: ${({ $width = '376px' }) => $width};
    border-radius: 10px;
    @media (max-width: 1500px) {
      width: 267px;
    }
    & .search {
      padding: 10px;
      &::after {
        position: absolute;
        height: 48px;
        z-index: 1;
        width: 20px;
        ${({ theme }) => theme.utils.centerFlex};
        content: '';
        background-position: center;
        background-repeat: no-repeat;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAABnElEQVQ4T73UvUscQRQA8DcfXO5IkyuFBFSQkDZVsLDJpUknREGwEBWWcDszR7AIBGQqEYXbm5m9UgsFQf0LxML/wNrGOkXgFsFKb+eFhQQuyd6ebg6nnTc/3gdvCIz5kDF78HSgtfYdAKwCwLOsCu+955wfhWF4XlRVbobGmG+U0lqlUtkNguAmA9rtdo0x1gSAl0mSfNFa+zz4H9A5t4yIL6SUcd4DY8w8pfS1EGJ7JKi1pvV6/URKuUAIwWGlGWMOOeefm83m7d8xf2QYRdEbxti8lHKrqE9ZFQDwQwhxVgh2Op05xtiMEGKvCLTWNhBxQil1OCrDaUrpilJqswg0xqwDwLVS6qIQRERirT2tVqtLQRDc56FZjHPuuNfrLWut7wrB7DKO4w9pmjaklF/zBuOcU4iYSCkPRk75d0DW9DRN3yPiTqvVusrgKIomGWMbAPCJUvoxDMPLB4NZYLfbfdXv99cIIVMAQBDxOyLuc86fe+9PKKWLeWipXY7j+O0wtBT4q9e5aGlwAJ0dXNP/Ah81lLIf79gz/AmLnqYVBudKSgAAAABJRU5ErkJggg==);
        left: 16px;
        top: 10px;
      }
      & .search-box {
        height: 48px;
        border-radius: 9px;
        margin-left: unset;
        padding-left: 30px;
        width: 100%;
        border-color: ${({ theme }) => theme.colors.darkGray};
        &::placeholder {
          color: ${({ theme }) => theme.colors.darkGray};
        }
      }
    }
    & .error-label {
      margin: 6px 0 0;
      display: flex;
      align-items: center;
      & .icon-information-circle {
        ${rtl`
        margin-right: 4px;
      `}
      }
    
    }
    .phone-input-container{
        margin-top:0px;
      }
`;

const PhoneNumberError = styled.div`
  .MuiFormHelperText-contained {
    margin-left: 0px;
    margin-right: 0px;
  }
  .icon-information-circle {
    margin-right: 4px;
    width: 12px;
    height: 12px;
  }
`;
