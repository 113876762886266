import * as React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Message } from 'i18n';
import rtl from 'styled-components-rtl';
import { ClientSelectFilter as StatusFilter } from 'components/Filters';
import { MOBILE_RESOLUTION } from 'styles/sharedStyle';
import { SkinFilter, SkinFilterType } from 'types/properties';
import { Option } from 'components/Autocomplete/Autocomplete.config';
import {
  ClientBasicOption,
  ClientCheckboxItem,
  SkinColorOption,
} from 'components/Filters/Options';
import { SkinSortType } from '../Skins.config';

interface Props {
  updateView: (data: SkinFilter) => void;
  sortByOptions: Option[];

  designStyles: Option[];
  applyDesignStyle: (selectStyles: Option | Option[]) => void;
  colorOptions: Option[];
  budgetOptions: Option[];
  roomOptions: Option[];
  creators: Array<{
    id: string;
    first_name: string;
    last_name: string;
  }>;
}

const DeskTopFilter: React.FC<Props> = ({
  updateView,
  sortByOptions,
  designStyles,
  applyDesignStyle,
  creators,
  colorOptions,
  budgetOptions,
  roomOptions,
}) => {
  const intl = useIntl();

  const filterName = React.useMemo(
    () => ({
      [SkinFilterType.SORT]: intl.formatMessage({
        id: Message.SKIN_FILTER_BY_SORT,
      }),
      [SkinFilterType.DESIGNED_BY]: intl.formatMessage({
        id: Message.SKIN_FILTER_BY_DESIGNED_BY,
      }),
      [SkinFilterType.DESIGN_STYLE]: intl.formatMessage({
        id: Message.SKIN_FILTER_BY_DESIGN_STYLE,
      }),
      [SkinFilterType.BUDGET]: intl.formatMessage({
        id: Message.SKIN_FILTER_BY_BUDGET,
      }),
      [SkinFilterType.POPULAR]: intl.formatMessage({
        id: Message.SKIN_FILTER_BY_POPULAR,
      }),
      [SkinFilterType.ROOM]: intl.formatMessage({
        id: Message.SKIN_FILTER_BY_ROOM,
      }),
      [SkinFilterType.COLOR]: intl.formatMessage({
        id: Message.SKIN_FILTER_BY_COLOR,
      }),
    }),
    [intl]
  );

  const onSortApply = React.useCallback(
    (values: Option[]) => {
      if (values[0]?.value === SkinSortType.NEW) {
        updateView({
          ordering: '-created_at',
        });
      } else if (values[0]?.value === SkinSortType.POPULAR) {
        updateView({
          ordering: '-rating',
        });
      } else if (values[0]?.value === SkinSortType.HIGHTOLOW) {
        updateView({
          ordering: '-price',
        });
      } else if (values[0]?.value === SkinSortType.LOWTOHIGH) {
        updateView({
          ordering: 'price',
        });
      } else {
        updateView({
          ordering: '-staff_pick,-updated_at',
        });
      }
    },
    [updateView]
  );

  const onDesignStyleApply = React.useCallback(
    (values: Option[]) => {
      updateView({
        style: values.map(e => e?.value.toString() ?? '') ?? [],
      });
    },
    [updateView]
  );

  const onApplyColor = React.useCallback(
    (values: Option[]) => {
      updateView({
        color: values.map(e => e?.id.toString() ?? '') ?? [],
      });
    },
    [updateView]
  );

  const onApplyRoom = React.useCallback(
    (values: Option[]) => {
      updateView({
        room: values.map(e => e?.value.toString() ?? '') ?? [],
      });
    },
    [updateView]
  );

  const onDesignedByApply = React.useCallback(
    (values: Option[]) => {
      updateView({
        user: values.map(e => e?.value.toString() ?? '') ?? [],
      });
    },
    [updateView]
  );
  const onBudgetApply = React.useCallback(
    (values: Option[]) => {
      if (values[0]?.value)
        // eslint-disable-next-line default-case
        switch (values[0].value) {
          case 1:
            updateView({ budget_from: 0, budget_to: 10000 });
            break;
          case 2:
            updateView({ budget_from: 10000, budget_to: 25000 });
            break;
          case 3:
            updateView({ budget_from: 25000, budget_to: 100000 });
            break;
          case 4:
            updateView({ budget_from: 100000, budget_to: undefined });
            break;
        }
      else updateView({ budget_from: undefined, budget_to: undefined });
    },
    [updateView]
  );

  return (
    <Container>
      <Filters>
        <CustomSelect>
          <StatusFilter
            options={sortByOptions}
            placeholder={filterName[SkinFilterType.SORT]}
            selectedPlaceholder={filterName[SkinFilterType.SORT]}
            onApply={onSortApply}
            renderOption={ClientBasicOption}
            multiple={false}
            customIcon={true}
            buttonClassName="selectButton"
            placement="bottom"
            modifiers={[
              {
                name: 'flip',
                enabled: true,
                options: {
                  altBoundary: false,
                  rootBoundary: 'document',
                  padding: 8,
                },
              },
            ]}
            popperOptions={{
              strategy: 'fixed',
            }}
          />
        </CustomSelect>
        <VerticalLine />

        <CustomSelect>
          <StatusFilter
            options={creators?.map(e => ({
              name: `${e.first_name} ${e.last_name}`,
              value: e.id,
            }))}
            placeholder={filterName[SkinFilterType.DESIGNED_BY]}
            selectedPlaceholder={filterName[SkinFilterType.DESIGNED_BY]}
            renderOption={ClientCheckboxItem}
            onApply={onDesignedByApply}
            customIcon={true}
            disableSelectAll
            buttonClassName="selectButton"
            placement="bottom"
            modifiers={[
              {
                name: 'flip',
                enabled: true,
                options: {
                  altBoundary: false,
                  rootBoundary: 'document',
                  padding: 8,
                },
              },
            ]}
            popperOptions={{
              strategy: 'fixed',
            }}
          />
        </CustomSelect>
        <VerticalLine />

        <CustomSelect>
          <StatusFilter
            options={designStyles}
            placeholder={filterName[SkinFilterType.DESIGN_STYLE]}
            selectedPlaceholder={filterName[SkinFilterType.DESIGN_STYLE]}
            onApply={onDesignStyleApply}
            customIcon={true}
            renderOption={ClientCheckboxItem}
            disableSelectAll
            buttonClassName="selectButton"
            placement="bottom"
            modifiers={[
              {
                name: 'flip',
                enabled: true,
                options: {
                  altBoundary: false,
                  rootBoundary: 'document',
                  padding: 8,
                },
              },
            ]}
            popperOptions={{
              strategy: 'fixed',
            }}
          />
        </CustomSelect>
        <VerticalLine />

        <CustomSelect>
          <StatusFilter
            options={budgetOptions}
            placeholder={filterName[SkinFilterType.BUDGET]}
            selectedPlaceholder={filterName[SkinFilterType.BUDGET]}
            renderOption={ClientCheckboxItem}
            onApply={onBudgetApply}
            customIcon={true}
            disableSelectAll
            buttonClassName="selectButton"
            multiple={false}
            placement="bottom"
            modifiers={[
              {
                name: 'flip',
                enabled: true,
                options: {
                  altBoundary: false,
                  rootBoundary: 'document',
                  padding: 8,
                },
              },
            ]}
            popperOptions={{
              strategy: 'fixed',
            }}
          />
        </CustomSelect>
        <VerticalLine />

        <CustomSelect>
          <StatusFilter
            options={roomOptions}
            placeholder={filterName[SkinFilterType.ROOM]}
            selectedPlaceholder={filterName[SkinFilterType.ROOM]}
            renderOption={ClientCheckboxItem}
            onApply={onApplyRoom}
            disableSelectAll
            customIcon={true}
            buttonClassName="selectButton"
            placement="bottom"
            modifiers={[
              {
                name: 'flip',
                enabled: true,
                options: {
                  altBoundary: false,
                  rootBoundary: 'document',
                  padding: 8,
                },
              },
            ]}
            popperOptions={{
              strategy: 'fixed',
            }}
          />
        </CustomSelect>
        <VerticalLine />

        <CustomSelect>
          <StatusFilter
            options={colorOptions}
            placeholder={filterName[SkinFilterType.COLOR]}
            selectedPlaceholder={filterName[SkinFilterType.COLOR]}
            renderOption={SkinColorOption}
            onApply={onApplyColor}
            customIcon={true}
            disableSelectAll
            buttonClassName="selectButton"
            placement="bottom"
            modifiers={[
              {
                name: 'flip',
                enabled: true,
                options: {
                  altBoundary: false,
                  rootBoundary: 'document',
                  padding: 8,
                },
              },
            ]}
            popperOptions={{
              strategy: 'fixed',
            }}
          />
        </CustomSelect>
      </Filters>
    </Container>
  );
};

export default DeskTopFilter;

const Container = styled.div`
  background: rgba(238, 238, 238, 0.25);
  -moz-box-shadow: 0 0 25px 0 #eeeeee;
  -webkit-box-shadow: 0 0 25px 0 #eeeeee;
  box-shadow: 0 0 25px 0 #eeeeee;
  .custom-popper {
    box-shadow: 0px 0px 15px rgb(0 0 0 / 10%);
    margin-top: 4px !important;
  }
  .dropdown-placeholder {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #676766;
  }
`;

const Filters = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 71px;
  @media ${MOBILE_RESOLUTION} {
    display: block;
    gap: 0;
  }
`;

const CustomSelect = styled.div`
  ${rtl`
    padding-left: 72px;
    padding-right: 72px;
  `}

  display: flex;
  &:hover {
    svg {
      /* color: #fc5c42; */
    }
  }
  .selectButton {
    border: none;
    width: unset;
    background-color: #fbfbfb;
    color: #676766;
    font-size: 18px;
    font-weight: 700;
    &:hover {
      color: #fc5c42;
      border-bottom: 2px solid #fc5c42;
      border-radius: 0px;
    }
  }
  .isopen {
    /* color: ${({ theme }) => theme.colors.primary.lightMobile}; */
    .dropdown-placeholder {
      color: #fc5c42 !important;
    }
    border-bottom: 2px solid ${({ theme }) => theme.colors.primary.lightMobile};
    border-radius: 0;
  }
  .checkbox-input {
    background: #ffffff;
    border-radius: 2px;
  }
  .color-name {
    font-family: ${({ theme }) =>
      theme.dir === 'rtl' ? 'Assistant' : 'Roboto'};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #282929;
  }
`;

const VerticalLine = styled.div`
  height: 44px;
  border: 1.5px solid rgba(208, 208, 208, 0.47);
  border-radius: 22px;
  background: rgba(208, 208, 208, 0.47);
`;
