import * as React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import NavButton from 'components/@client/NavButton';
import {
  useRecentProperty,
  useRouter,
  // useScrollPosition,
  useAuthentication,
  useSession,
} from 'hooks';
import {
  ClientRouter,
  ManagementRouter,
  SupplierDashBoardRoutes,
} from 'router/routes';
import SearchBarComponent from 'components/@client/SearchBarComponent';
import { userLogData } from 'mockData/userLogData';
import { LogsAPI } from 'api/methods';
import useProperties from 'pages/appClient/properties/useProperties';
import { ReactComponent as BrandLogoRed } from 'assets/icons/brandLogo-red.svg';
// import { useSpring, config } from 'react-spring';
import { Type } from 'constants/config';
import tabs from './client.config';
import Menu from './Menu';

const DesktopNavBar: React.FC = () => {
  const { pathname } = useRouter();
  const intl = useIntl();
  const { isFromSupportedCountry } = useSession();
  const { recentProperty } = useRecentProperty();
  const { token, type, supplierId, seller_supplier } = useAuthentication();
  const { properties, callFetch: refreshProperty } = useProperties(true);
  let items = React.useMemo(
    () => tabs(pathname, !!token, recentProperty, intl),
    [pathname, token, recentProperty, intl]
  );

  if (type !== 'SUPPLIER' && type !== Type.TEAM_PATRICIA) {
    items = items.filter(el => el.icon !== 'supplier-shop');
  } else if (type === 'SUPPLIER') {
    items = items.filter(el => !el.to.includes(ManagementRouter.APP));
  } else {
    items = items.filter(el => !el.to.includes(SupplierDashBoardRoutes.INFO));
  }

  if (
    (type === 'SELLER' &&
      (supplierId === '7' ||
        supplierId === '16' ||
        seller_supplier === 7 ||
        seller_supplier === 16)) ||
    (type === 'SUPPLIER' &&
      (supplierId === '7' ||
        supplierId === '16' ||
        seller_supplier === 7 ||
        seller_supplier === 16))
  ) {
    //  furnitures
    const ind = items.findIndex(item => {
      return item.icon === 'buildings-outlined';
    });
    items[ind].to = '/app/sellerProperties';
  }

  React.useEffect(() => {
    if (token) {
      refreshProperty();
    }
  }, [token, refreshProperty]);

  // const scrollPosition = useScrollPosition();
  const isItemsHidden = pathname === ClientRouter.BECOME_A_SELLER;

  // const fadeStyles = useSpring({
  //   config: { ...config.stiff },

  //   from: {
  //     opacity: 0,
  //   },

  //   to: {
  //     opacity: isItemsHidden ? 0 : 1,

  //     pointerEvents: isItemsHidden ? 'none' : 'auto',
  //   },
  // });

  if (
    pathname.includes('/onboarding') ||
    pathname.includes('/auth') ||
    pathname.includes('/tour') ||
    pathname.includes('/terms-of-use') ||
    pathname.includes('privacy-policy') ||
    pathname.includes('/app/landing/') ||
    pathname.includes('/app/supplier') ||
    (pathname.includes('/app/sellerProperties') &&
      !pathname.includes('/app/sellerProperties/project'))
  )
    return null;

  return (
    <DesktopAppBar position="fixed" component="nav" isBelowBanner={false}>
      <Toolbar component="ul">
        <Column>
          {/* <animated.div style={fadeStyles}> */}
          <CustomPatriciaText
            to={ClientRouter.APP}
            onClick={() => {
              LogsAPI.postUserLogs({
                name: userLogData.eventName.becomeAnExpert,
                area: userLogData.eventArea.homePage,
                section: userLogData.eventSection.footer,
                path: userLogData.eventPath.becomeExpertPath,
              });
            }}
          >
            <Logo />
          </CustomPatriciaText>
          {/* </animated.div> */}
        </Column>
        <Column>{isFromSupportedCountry && <SearchBarComponent />}</Column>
        <Column>
          {isFromSupportedCountry &&
            items.map(item => (
              <NavButton
                component="div"
                key={item.to}
                item={{
                  ...item,
                  to:
                    token || item.public === true ? item.to : ClientRouter.AUTH,
                }}
                isMobile={false}
                isHidden={isItemsHidden}
                showBadge={item.showBadge}
                onClick={item.onClick}
                propertyBadgeCount={token && properties?.count}
              />
            ))}
          <Menu isAuthenticated={!!token} />
        </Column>
      </Toolbar>
    </DesktopAppBar>
  );
};

export default DesktopNavBar;

const CustomPatriciaText = styled(Link)``;

const DesktopAppBar = styled(AppBar)<{ isBelowBanner: boolean }>`
  && {
    ${({ isBelowBanner }) =>
      isBelowBanner &&
      css`
        top: 56px;
        left: auto;
        right: 0;
        z-index: 1100;
        position: fixed;
      `}
    height: 64px;
    background-image: linear-gradient(rgba(0, 0, 0, 1), rgba(25, 25, 25, 1));
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
    z-index: 5;
    & > ul {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      padding: 0 80px;

      @media (max-width: 1150px) {
        padding: 0 20px;
      }

      li {
        flex: 0.25;
      }

      li:nth-of-type(1) {
        justify-content: flex-start;
      }

      li:nth-of-type(2) {
        flex: 0.5;
      }
    }
    .list-paper {
      border-radius: 0 0 8px 8px;
    }
    .language {
      margin: ${({ theme }) =>
        theme.dir === 'rtl' ? '0 0 0 .25em' : '0 -.3em 0 0'};
      & > * {
        width: 35px;
        display: flex;
        justify-content: center;
      }
      .icon-button {
        padding: 0;
        margin: 1px;
      }
    }

    @media (max-width: 900px) {
      .icon-button {
        padding: 2px;
        margin: 2px;
      }
    }

    .country-button {
      cursor: pointer;
      background: transparent;
      border: none;
      width: 18px;

      svg,
      img {
        color: white;
        width: 100%;
        fill: white;
      }
    }
  }
`;

const Column = styled.li.attrs(({ className = '' }) => ({
  className: `nav-column ${className}`.trim(),
}))`
  ${({ theme }) => theme.utils.centerFlex};
`;

const Logo = styled(BrandLogoRed)`
  height: 39px;
  .a {
    fill: ${({ theme }) => theme.colors.primary.lightMobile};
  }
`;
