import * as React from 'react';
import styled from 'styled-components';
import Breadcrumbs from 'components/@client/Breadcrumbs/Breadcrumbs';
import Content from 'components/@client/NotFound';
import { ClientRouter } from 'router/routes';
import Title from 'components/@client/TopTitle/Title';

const NotFound = () => {
  return (
    <Container>
      <Breadcrumbs
        links={[{ to: ClientRouter.APP, children: 'Page not found' }]}
      />
      <Title title="Page not found" backTo={ClientRouter.APP} />
      <Content />
    </Container>
  );
};

export default NotFound;

const Container = styled.section`
  padding: 20px;
`;
