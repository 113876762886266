import * as React from 'react';
import styled from 'styled-components';
import CheckVector from 'assets/@client/onboarding/CheckVector.png';
import * as Section from 'pages/appClient/onboarding/shared';
import confirmationImage from 'assets/@client/onboarding/confirmation-image.gif';
import Button from 'components/@client/Button/Button';
import { useRouter } from 'hooks';
import { ClientRouter } from 'router/routes';
import { Message } from 'i18n';
import { FormattedMessage } from 'react-intl';

const CreateKeyConfirmation: React.FC = () => {
  const { history } = useRouter();

  return (
    <Container>
      <ImageContainer>
        <img src={CheckVector} alt="check" />
      </ImageContainer>
      <div className="title">
        <FormattedMessage
          id={Message.KEY_CONFIRMATION_STARTED_WORKING}
          values={{
            span: (chunks: string) => (
              <span className="title-text">{chunks}</span>
            ),
          }}
        />
      </div>
      <div className="main-image">
        <img alt="confirmation" src={confirmationImage} />
      </div>
      <div className="subtitle">
        <FormattedMessage
          id={Message.CREATE_DIGITAL_KEY}
          values={{
            span: (chunks: string) => (
              <span className="title-text">{chunks}</span>
            ),
          }}
        />
      </div>
      <Button
        className="key-btn"
        onClick={() => history.push(ClientRouter.ADDITIONAL_INFO)}
      >
        <FormattedMessage id={Message.LETS_MAKE_KEY_BUTTON} />
      </Button>
    </Container>
  );
};

const Container = styled(Section.Container)`
  text-align: center;

  span {
    color: ${({ theme }) => theme.colors.primary.lightMobile};
  }
  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 33px;

    /* First level text */

    color: #282929;
    @media (max-width: 767px) {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
    }
  }
  .main-image {
    width: 522px;
    height: 454px;
    margin: 16px auto;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @media (max-width: 767px) {
      width: 327px;
      height: 285px;
      margin: 24px auto;
    }
  }
  .subtitle {
    width: 539px;
    height: 66px;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 33px;
    text-align: center;
    margin: 8px auto 0;

    /* Second Text level color */

    color: #3e3e3e;
    @media (max-width: 767px) {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      width: 327px;
      height: 40px;
    }
  }
  .key-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 24px;
    gap: 10px;
    width: 252px;
    height: 48px;
    background: #fc5c42;
    /* Primary Color */

    border: 0.5px solid #fc5c42;
    border-radius: 15px;

    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    /* identical to box height */

    text-align: center;

    /* First White Text (90%) */
    span {
      color: rgba(255, 255, 255, 0.9);
    }
    margin: 32px auto 0;
  }
  @media (max-width: 767px) {
    padding: 0 24px;
    overflow: hidden;
  }
`;
const ImageContainer = styled.div`
  width: min-content;
  margin: auto;
  margin-top: 48px;
  margin-bottom: 48px;
  width: 88px;
  height: 58px;
`;

export default CreateKeyConfirmation;
