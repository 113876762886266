import * as React from 'react';
import {
  IRouteConfig,
  SupplierDashBoardRoutes,
  SupplierMyDatabaseRoutes,
  SupplierOrdersRoutes,
  SupplierRouter,
  SupplierUsersRoutes,
} from './routes';

export const supplierRoutes = [
  {
    path: SupplierRouter.HOME,
    exact: true,
    component: React.lazy(
      () => import(/* webpackChunkName: "page-Home" */ 'pages/appSupplier/Home')
    ),
    routes: [] as IRouteConfig[],
  },
  {
    path: SupplierRouter.DASHBOARD,
    exact: false,
    component: React.lazy(
      () =>
        import(
          /* webpackChunkName: "page-Dashboard" */ 'pages/appSupplier/dashboard'
        )
    ),
    routes: [
      {
        path: SupplierDashBoardRoutes.INFO,
        exact: true,
        component: React.lazy(
          () =>
            import(
              /* webpackChunkName: "page-Dashboard-info" */ 'pages/appSupplier/dashboard/info'
            )
        ),
        routes: [],
      },
      {
        path: SupplierDashBoardRoutes.SELLING_PRODUCTS,
        exact: true,
        component: React.lazy(
          () =>
            import(
              /* webpackChunkName: "page-Dashboard-selling-products" */ 'pages/appSupplier/dashboard/selling-products'
            )
        ),
        routes: [],
      },
    ] as IRouteConfig[],
  },
  {
    path: SupplierRouter.STATISTICS,
    exact: true,
    component: React.lazy(
      () =>
        import(
          /* webpackChunkName: "page-Statistics" */ 'pages/appSupplier/statistics'
        )
    ),
    routes: [] as IRouteConfig[],
  },
  {
    path: SupplierRouter.MY_DATABASE,
    exact: false,
    component: React.lazy(
      () =>
        import(
          /* webpackChunkName: "page-My-Database" */ 'pages/appSupplier/my-database'
        )
    ),
    routes: [
      {
        path: SupplierMyDatabaseRoutes.ALL,
        exact: false,
        component: React.lazy(
          () =>
            import(
              /* webpackChunkName: "page-AllDatabase" */ 'pages/appSupplier/my-database/all'
            )
        ),
        routes: [],
      },
      {
        path: SupplierMyDatabaseRoutes.IN_PROGRESS,
        exact: true,
        component: React.lazy(
          () =>
            import(
              /* webpackChunkName: "page-InProgressDatabase" */ 'pages/appSupplier/my-database/in-progress'
            )
        ),
        routes: [],
      },
    ] as IRouteConfig[],
  },
  {
    path: SupplierRouter.ORDERS,
    exact: false,
    component: React.lazy(
      () =>
        import(/* webpackChunkName: "page-Orders" */ 'pages/appSupplier/orders')
    ),
    routes: [
      {
        path: SupplierOrdersRoutes.ALL_ORDERS,
        exact: true,
        component: React.lazy(
          () =>
            import(
              /* webpackChunkName: "page-AllOrders-new_task" */ 'pages/appSupplier/orders/all-orders'
            )
        ),
      },
      {
        path: SupplierOrdersRoutes.DISPUTE_ORDERS,
        exact: true,
        component: React.lazy(
          () =>
            import(
              /* webpackChunkName: "page-DisputeOrders-archived" */ 'pages/appSupplier/orders/dispute-orders'
            )
        ),
      },
    ],
  },
  {
    path: SupplierRouter.FILES,
    exact: true,
    component: React.lazy(
      () =>
        import(/* webpackChunkName: "page-Files" */ 'pages/appSupplier/files')
    ),
    routes: [] as IRouteConfig[],
  },
  {
    path: SupplierRouter.USERS,
    exact: false,
    component: React.lazy(
      () =>
        import(/* webpackChunkName: "page-Users" */ 'pages/appSupplier/users')
    ),
    routes: [
      {
        path: SupplierUsersRoutes.CLIENTS,
        exact: false,
        component: React.lazy(
          () =>
            import(
              /* webpackChunkName: "page-AllDatabase" */ 'pages/appSupplier/users/SellerClient'
            )
        ),
        routes: [],
      },
      {
        path: SupplierUsersRoutes.SELLERS,
        exact: false,
        component: React.lazy(
          () =>
            import(
              /* webpackChunkName: "page-InProgressDatabase" */ 'pages/appSupplier/users/Seller'
            )
        ),
        routes: [],
      },
    ] as IRouteConfig[],
  },
  {
    path: SupplierRouter.USER_INFO,
    exact: false,
    component: React.lazy(
      () =>
        import(
          /* webpackChunkName: "page-Users" */ 'pages/appManagement/user-management/clients/client'
        )
    ),
    routes: [] as IRouteConfig[],
  },
  {
    path: SupplierRouter.NOT_FOUND,
    exact: true,
    component: React.lazy(
      () => import(/* webpackChunkName: "page-404" */ 'pages/common/404')
    ),
    routes: [] as IRouteConfig[],
  },
] as IRouteConfig[];
