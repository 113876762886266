import { ManagementRouter } from 'router/routes';
import { getName, Tab } from 'utils/sidebar.utils';

type Key = 'grid' | 'user' | 'buildings' | '3d-content' | 'quiz';

const tabs = (route: string): Tab[] => [
  {
    label: 'Dashboard',
    to: ManagementRouter.DASHBOARD,
    icon: getName<Key>('grid', route.includes(ManagementRouter.DASHBOARD)),
    exact: false,
  },
  {
    label: 'User Management',
    to: ManagementRouter.USER_MANAGEMENT,
    icon: getName<Key>(
      'user',
      route.includes(ManagementRouter.USER_MANAGEMENT)
    ),
    exact: false,
  },
  {
    label: 'Property Management',
    to: ManagementRouter.PROPERTY_MANAGEMENT,
    icon: getName<Key>(
      'buildings',
      route.includes(ManagementRouter.PROPERTY_MANAGEMENT)
    ),
    exact: false,
  },
  {
    label: '3D Content Management',
    to: ManagementRouter.THREE_D_CONTENT_MANAGEMENT,
    icon: getName<Key>(
      '3d-content',
      route.includes(ManagementRouter.THREE_D_CONTENT_MANAGEMENT)
    ),
    exact: false,
  },
  {
    label: 'Quiz Management',
    to: ManagementRouter.QUIZ_MANAGEMENT,
    icon: getName<Key>(
      'quiz',
      route.includes(ManagementRouter.QUIZ_MANAGEMENT)
    ),
    exact: false,
  },
];

export default tabs;
