import * as React from 'react';
import styled from 'styled-components';
import { Checkbox } from 'components/forms';
import { IAddress } from 'types/common.types';

interface Props {
  defaultValues?: IAddress | object;
  addressesCount?: number;
}

const AddressCheckboxes: React.FC<Props> = ({
  addressesCount = 0,
  defaultValues,
}) => {
  return (
    <Checkboxes>
      <Checkbox name="elevator" label="Elevator" />
      <Checkbox
        name="isPrimary"
        label="Set primary address"
        disabled={
          (defaultValues as IAddress)?.isPrimary || addressesCount === 0
        }
      />
    </Checkboxes>
  );
};

export default AddressCheckboxes;

const Checkboxes = styled.div`
  display: flex;
  gap: 75px;

  .input-checkbox {
    display: block;
    position: relative;

    label {
      position: absolute;
      white-space: nowrap;
      top: 5px;
      margin-left: 5px;
      font-size: 14px;
      color: ${({ theme }) => theme.colors.primary.primaryTextColor};

      @media (min-width: 1500px) {
        top: 5px;
      }
    }
  }
`;
