import * as React from 'react';
import styled from 'styled-components';
import sellerPrivate from 'assets/@client/properties/seller-private.png';
import sellerProject from 'assets/@client/properties/seller-project.png';
import ModyBackground from 'assets/@client/properties/mody-background.png';
import ModyVision from 'assets/@client/properties/mody-vision.png';
import rtl from 'styled-components-rtl';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import { ClientRouter } from 'router/routes';
import {
  ClientRoot,
  MAX_TABLET_RESOLUTION,
  MOBILE_RESOLUTION,
  MOBILE_TABLET_MIN_RESOLUTION,
  MOBILE_TABLET_RESOLUTION,
  SMALL_WIDTH_RESOLUTION,
} from 'styles/sharedStyle';
import _ from 'lodash';
import { OnboardingContextProvider } from 'context/onboardingContext';
import { useRouter } from 'hooks';

const SellerPropertys = () => {
  const { history } = useRouter<{
    modal: 'add-property';
  }>();
  const [isClicked, setIsClicked] = React.useState(false);
  return (
    <>
      <OnboardingContextProvider>
        <Root>
          <ParentContainer />
          <ModyContainer>
            <ModyHeader isClicked={isClicked}>
              <FormattedMessage id={Message.MODY_HEADER_TEXT} />
            </ModyHeader>
            <ModyVisionImage
              src={ModyVision}
              alt="mody-vision"
              isClicked={isClicked}
            />
            {isClicked ? (
              <Images>
                <MainContainer
                  isActive={false}
                  onClick={() => {
                    history.push(ClientRouter.PROPERTIES_SELLER_LISTING);
                  }}
                >
                  <ImageContainer>
                    <img src={sellerProject} alt="not available" />
                  </ImageContainer>
                  <ImageText>
                    <FormattedMessage id={Message.PROJECT} />
                  </ImageText>
                </MainContainer>
                <MainContainer
                  isActive={false}
                  onClick={() => {
                    history.push(ClientRouter.PROPERTIES);
                  }}
                >
                  <ImageContainer>
                    <img src={sellerPrivate} alt="not available" />
                  </ImageContainer>
                  <ImageText>
                    <FormattedMessage id={Message.PRIVATE} />
                  </ImageText>
                </MainContainer>
              </Images>
            ) : (
              <ModyButton onClick={() => setIsClicked(true)}>
                <FormattedMessage id={Message.MODY_BUTTON_TEXT} />
              </ModyButton>
            )}
          </ModyContainer>
        </Root>
      </OnboardingContextProvider>
    </>
  );
};

export default SellerPropertys;

const Root = styled(ClientRoot)`
  background-image: url(${ModyBackground});
  margin-top: -60px;
  padding: 0;
  background-size: cover;
  background-position: center;
`;

const MainContainer = styled.div<{ isActive: boolean }>`
  position: relative;
  cursor: pointer;
  .checkContainer {
    height: 24px;
    width: 24px;
    position: absolute;
    top: 16px;
    ${rtl`right: 16px;`}
    border-radius: 12px;
    background-color: #fc5c42;
    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
    svg {
      width: 15px;
      height: 23px;
      path {
        stroke: white;
        fill: white;
      }
    }

    /* @media ${MOBILE_RESOLUTION} {
      width: 200px !important;
    } */
  }
`;

const ImageContainer = styled.div`
  width: 282px;
  height: 346px;
  @media ${MOBILE_RESOLUTION} {
    width: 160px;
    height: 244px;
  }
  margin: auto;
  > img {
    width: 100%;
    height: 100%;
    object-fit: none;
    border-radius: 30px;
    box-shadow: 0px 2px 15px 5px rgba(234, 234, 234, 0.15);
    @media ${MOBILE_RESOLUTION} {
      border: 12px solid rgba(0, 0, 0, 0.1);
    }
  }
`;

const Images = styled.div`
  display: flex;
  justify-content: center;
  gap: 64px;
  margin: 80px auto 0;
  @media ${MOBILE_TABLET_RESOLUTION} {
    justify-content: flex-start;
  }

  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${SMALL_WIDTH_RESOLUTION} {
    justify-content: flex-start;
  }

  @media ${MOBILE_RESOLUTION} {
    gap: 18px;
    margin: 42px auto 0;
    width: fit-content;
    justify-content: flex-start;
  }
`;

const ImageText = styled.div`
  position: absolute;
  left: 28px;
  top: 28px;
  font-family: ${({ theme }) => (theme.dir === 'rtl' ? 'Assistant' : 'Roboto')};
  font-size: 36px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;

  @media ${MOBILE_RESOLUTION} {
    font-size: 18px;
    top: 10px;
  }
`;

const ModyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  position: relative;
  z-index: 1;
`;

const ModyButton = styled.button`
  border: 2px solid #d9d9d9;
  border-radius: 40px;
  width: 468px;
  height: 76px;
  background: transparent;
  font-weight: 500;
  font-size: 32px;
  line-height: 36px;
  color: rgba(255, 255, 255, 0.9);
  font-family: 'Assistant';
  margin-top: 64px;
  cursor: pointer;
  &:hover {
    background: #c00013;
    border: 2px solid #c00013;
  }
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MAX_TABLET_RESOLUTION} {
    font-size: 32px;
    line-height: 36px;
  }
  @media ${MOBILE_RESOLUTION} {
    width: 340px;
    height: 48px;
    font-size: 22px;
    line-height: 26px;
    margin-top: 32px;
  }
`;
const ParentContainer = styled.div`
  position: absolute;
  top: -60px;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100vw;
  height: 100vh;
  z-index: 0;
`;

const ModyHeader = styled.div<{ isClicked: boolean }>`
  font-weight: 600;
  font-size: 80px;
  line-height: 84px;
  color: #ffffff;
  font-family: 'Assistant';
  opacity: ${({ isClicked }) => isClicked && '0.7'};
  @media ${MOBILE_RESOLUTION} {
    font-size: 32px;
    line-height: 36px;
  }
`;

const ModyVisionImage = styled.img<{ isClicked: boolean }>`
  margin-top: 32px;
  opacity: ${({ isClicked }) => isClicked && '0.7'};
  @media ${MOBILE_RESOLUTION} {
    height: 120px;
    width: 240px;
  }
`;
