import * as React from 'react';
import { FormattedMessage, FormattedDate, useIntl } from 'react-intl';
import * as Response from 'api/responses';
import * as Transform from 'api/transform';
import { CheckboxProps } from 'components/@client/form-components/Checkbox';
import {
  useAuthentication,
  useFetch,
  useFormattedCurrency,
  useRouter,
  useSession,
} from 'hooks';
import { ClientRouter } from 'router/routes';
import type { CheckoutCart } from 'types/properties';
import { IAddress, SupportedCountry } from 'types/common.types';
import { getRoute } from 'utils/sidebar.utils';
import { METHODS } from 'api/client';
import { Message } from 'i18n';
import addDays from 'date-fns/addDays';
import isAfter from 'date-fns/isAfter';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DesktopDatePicker';
import { MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';
import rtl from 'styled-components-rtl';
import { userLogData } from 'mockData/userLogData';
import { LogsAPI } from 'api/methods';
import { CheckOutPersonalDetailPayload } from 'types/orders';
import { getLocalStorage } from 'utils/common.utils';
import { GUEST_TOKEN } from 'constants/localstorage.constants';

export interface CheckoutProps {
  address: IAddress | null;
  backTo: string;
  cart: CheckoutCart | null;
  canProceed: boolean;
  propertyCart: string;
  isLoadingCart: boolean;
  priceFields: { label: string; value: number }[];
  shippingOptions: CheckboxProps[];
  userId?: number;
  openModal: VoidFunction;
  onAddressesChange?: (addresses: IAddress[] | null) => void;
  onPersonalDetailsChange?: (
    details: CheckOutPersonalDetailPayload | null
  ) => void;
  deliveryDate: Date;
  guestToken?: string | null;
}

export const useCheckout = () => {
  const intl = useIntl();
  const [address, setAddress] = React.useState<IAddress | null>(null);
  const [
    personalDetail,
    setPersonalDetail,
  ] = React.useState<CheckOutPersonalDetailPayload | null>(null);
  const { query } = useRouter<{ cartId: string; checkout: string }>();
  const { id: userId } = useAuthentication();
  const { currency, country } = useSession();
  const propertyCart = ClientRouter.CART;
  const guestToken = getLocalStorage(GUEST_TOKEN, null);
  const [shipping, setShipping] = React.useState<string>('standard');
  const [shippingDate, setShippingDate] = React.useState<Date>(
    addDays(new Date(), 22)
  );

  const [isNotForSaleModalOpen, setIsNotForSaleModalOpen] = React.useState(
    true
  );

  const onShippingChange = React.useCallback(({ target }) => {
    if (target.value !== 'lateShipping') {
      LogsAPI.postUserLogs({
        name: userLogData.eventName.shipping_method,
        area: userLogData.eventArea.myCart,
        section: userLogData.eventSection.cart,
        path: userLogData.eventPath.appCartCartCheckOutId,
      });
    } else {
      LogsAPI.postUserLogs({
        name: userLogData.eventName.laterDelivery,
        area: userLogData.eventArea.myCart,
        section: userLogData.eventSection.cart,
        path: userLogData.eventPath.appCartCartCheckOutId,
      });
    }
    setShipping(target.value || target.defaultValue);
  }, []);
  const standardDeliveryDate = addDays(new Date(), 21);
  const onShippingDateChange = React.useCallback(value => {
    if (isAfter(new Date(value), addDays(new Date(), 22))) {
      setShipping('lateShipping');
      setShippingDate(value);
    }
  }, []);

  const {
    data: cart,
    isLoading: isLoadingCart,
    callFetch: cartAPI,
    updateUrl,
  } = useFetch<CheckoutCart, Response.CheckoutCart>({
    initialUrl: `/api/checkout_cart/${query.checkout}/`,
    transform: Transform.checkoutCart,
    skipOnStart: !guestToken,
    config: {
      isGuestAllowed: true,
    },
  });

  const freeShipping = useFormattedCurrency(0);

  const subTotal = cart?.subTotal ?? 0;
  const VAT = cart?.vat ?? 0;
  const discount = cart?.totalDiscount ?? 0;
  const total = cart?.finalPrice ?? 0;
  const taxRate = cart?.taxRate ?? 0;

  const canProceed = React.useMemo(
    () =>
      (Boolean(shipping) &&
        (address || guestToken) &&
        cart?.items.every(({ quantity }) => quantity !== 0)) ??
      false,
    [cart?.items, shipping, address, guestToken]
  );

  const priceFields = [
    {
      label: intl.formatMessage({ id: Message.CHECKOUT_SUB_TOTAL_LABEL }),
      value: subTotal,
    },
    {
      label: intl.formatMessage(
        {
          id:
            country === SupportedCountry.ISRAEL
              ? Message.CHECKOUT_VAT_LABEL
              : Message.CHECKOUT_TAX_LABEL,
        },
        { value: currency === 'ILS' ? '17%' : '10%' }
      ),
      value: VAT,
    },
    {
      label: intl.formatMessage({ id: Message.CHECKOUT_SHIPPING_LABEL }),
      value: 0,
    },
    {
      label: intl.formatMessage({ id: Message.CHECKOUT_DISCOUNT_LABEL }),
      value: discount,
    },
    {
      label: intl.formatMessage({ id: Message.CHECKOUT_TOTAL_LABEL }),
      value: total,
    },
  ];
  const shippingOptions = React.useMemo(
    () => [
      {
        name: 'shipping',
        checked: shipping === 'standard',
        onChange: onShippingChange,
        onKeyPress: onShippingChange,
        value: 'standard',
        children: (
          <div>
            <strong>{freeShipping}</strong>
            <span>
              <FormattedMessage id={Message.CHECKOUT_STANDARD_SHIPPING_TITLE} />
            </span>
            <p>
              <FormattedMessage
                id={Message.CHECKOUT_SHIPPING_LABEL_DESCRIPTION}
              />{' '}
              <FormattedDate value={standardDeliveryDate} dateStyle="long" />
            </p>
          </div>
        ),
      },
      {
        name: 'shipping',
        checked: shipping === 'lateShipping',
        onChange: onShippingChange,
        onKeyPress: onShippingChange,
        value: 'lateShipping',
        children: (
          <ShippingButton>
            <div>
              <strong>{freeShipping}</strong>
              <span>
                <FormattedMessage id={Message.CHECKOUT_LATE_SHIPPING_TITLE} />{' '}
              </span>
            </div>
            <div>
              <ShippingMessageDatePicker>
                <span>
                  <FormattedMessage
                    id={Message.CHECKOUT_LATE_SHIPPING_LABEL_DESCRIPTION}
                  />
                  <FormattedDate value={shippingDate} dateStyle="long" />
                </span>
                <div>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <StyledDatePicker
                      minDate={addDays(new Date(), 22)}
                      value={shippingDate}
                      onChange={onShippingDateChange}
                      renderInput={params => (
                        <StyledTextField
                          $isActive={shipping === 'lateShipping'}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </ShippingMessageDatePicker>
            </div>
          </ShippingButton>
        ),
      },
    ],
    [
      onShippingChange,
      shipping,
      freeShipping,
      shippingDate,
      standardDeliveryDate,
      onShippingDateChange,
    ]
  );

  const onAddressesChange = React.useCallback(
    (data: IAddress[] | null) => {
      const primaryAddress = data?.find(item => item.isPrimary) ?? null;

      cartAPI({
        method: METHODS.PATCH,
        data: {
          address: primaryAddress?.id ?? null,
        },
      });
      setAddress(primaryAddress);
    },
    [cartAPI]
  );

  const onPersonalDetailsChange = React.useCallback(
    (data: CheckOutPersonalDetailPayload | null) => {
      setPersonalDetail(data);
    },
    []
  );

  return {
    address,
    backTo: getRoute([
      ClientRouter.CHECKOUT.replace(':cartId', query.cartId ?? ''),
      `?checkout=${cart?.id}`,
    ]),
    cart: cart ?? null,
    canProceed,
    propertyCart,

    isLoadingCart,
    priceFields,
    shippingOptions,
    userId,
    onAddressesChange,
    onPersonalDetailsChange,
    personalDetail,
    deliveryDate:
      shipping === 'lateShipping' ? shippingDate : standardDeliveryDate,
    guestToken,
    cartAPI,
    isNotForSaleModalOpen:
      isNotForSaleModalOpen &&
      Boolean(cart?.items.find(e => e.product.notForSale)) &&
      Boolean(address),
    setIsNotForSaleModalOpen,
    updateUrl,
    taxRate,
  };
};

const StyledDatePicker = styled(DatePicker)``;

const StyledTextField = styled(TextField)<{ $isActive?: boolean }>`
  vertical-align: middle !important;
  .MuiButtonBase-root.MuiIconButton-root {
    ${rtl`
      margin: -20px 0px 0px -20px;
    `}

    @media ${MOBILE_TABLET_RESOLUTION} {
      ${rtl`
        margin: -10px 0px 0px -20px;
      `}
    }
    & > svg {
      @media ${MOBILE_TABLET_RESOLUTION} {
        color: transparent;
      }
    }

    & > svg {
      fill: ${({ $isActive }) => ($isActive ? '#fc4c52' : '')};
    }
  }
  z-index: 9999999;
  & > div > input {
    width: 0;
    height: 0;
  }
  .MuiOutlinedInput-notchedOutline {
    display: none;
  }
`;

const ShippingButton = styled.div`
  width: 100%;
`;

const ShippingMessageDatePicker = styled.p`
  display: flex;
  ${rtl`
  display: inline-flex;
      `}
`;
