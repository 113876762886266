import * as actions from 'store/actions/properties-management/UnitsManagement.actions';
import PropertiesType from 'store/constants/properties-management/PropertiesManagement.constants';
import * as Payload from 'store/types/properties-management/PropertiesManagement.types';
import { UnitTask } from 'types/properties';

export interface UnitTasksState {
  loading: Record<PropertiesType, boolean>;
  error: Record<PropertiesType, null | string>;
  results: UnitTask[];
  pageCount: number;
}

const REQUESTS = [PropertiesType.GET_UNITS_DATA_REQUEST];

const initialState: UnitTasksState = {
  loading: REQUESTS.reduce(
    (state, next) => ({ ...state, [next]: false }),
    {} as Record<PropertiesType, boolean>
  ),
  error: REQUESTS.reduce(
    (state, next) => ({ ...state, [next]: null }),
    {} as Record<PropertiesType, null | string>
  ),
  results: [],
  pageCount: 0,
};

const unitsTasksReducer = (
  state: UnitTasksState = initialState,
  action: actions.UnitsTasksAction
): UnitTasksState => {
  switch (action.type) {
    case PropertiesType.GET_UNITS_DATA_REQUEST:
      return updateLoading(state, PropertiesType.GET_UNITS_DATA_REQUEST);
    case PropertiesType.GET_UNITS_DATA_FULFILLED:
      return updateSuccess(
        state,
        PropertiesType.GET_UNITS_DATA_REQUEST,
        action.payload as Payload.GetUnitsTasksFulfilledPayload
      );
    case PropertiesType.GET_UNITS_DATA_REJECTED:
      return updateError(
        state,
        PropertiesType.GET_UNITS_DATA_REQUEST,
        action.payload as Payload.GetUnitsTasksRejectedPayload
      );
    default:
      return state;
  }
};

export default unitsTasksReducer;

type requestType = PropertiesType.GET_UNITS_DATA_REQUEST;

const updateLoading = (state: UnitTasksState, requestType: requestType) => ({
  ...state,
  loading: {
    ...state.loading,
    [requestType]: true,
  },
  error: {
    ...state.error,
    [requestType]: null,
  },
});

type ErrorPayload = Payload.GetUnitsTasksRejectedPayload;
const updateError = (
  state: UnitTasksState,
  requestType: requestType,
  { error }: ErrorPayload
) => ({
  ...state,
  loading: {
    ...state.loading,
    [requestType]: false,
  },
  error: {
    ...state.error,
    [requestType]: error,
  },
});

type SuccessPayload = Payload.GetUnitsTasksFulfilledPayload;
const updateSuccess = (
  state: UnitTasksState,
  requestType: requestType,
  payload: SuccessPayload
) => ({
  ...state,
  loading: {
    ...state.loading,
    [requestType]: false,
  },
  error: {
    ...state.error,
    [requestType]: null,
  },
  ...payload,
});
