import * as React from 'react';
import { FormProvider } from 'react-hook-form';
import styled from 'styled-components';
import Accordion from 'components/Accordion';
import { Header } from 'components/forms';
import { IAddress, Setter } from 'types/common.types';
import * as Fields from 'pages/appManagement/common/FormFields';
import useAddressForm from 'pages/common/hooks/useAddressForm';

interface Props {
  addressesCount?: number;
  defaultValues?: IAddress;
  isOpen?: boolean;
  title: string | JSX.Element;
  userId?: string;
  setIsOpen?: Setter<boolean>;
  closeForm?: () => void;
}

const AddressesForm: React.FC<Props> = ({
  addressesCount,
  defaultValues,
  isOpen,
  title,
  userId,
  setIsOpen,
  closeForm,
}) => {
  const { isLoading, methods, close, saveForm } = useAddressForm({
    closeForm,
    defaultValues,
    addressesCount,
    userId,
  });

  const { handleSubmit } = methods;

  return (
    <Accordion
      expanded={isOpen}
      onOpen={() => setIsOpen?.(true)}
      header={<Header title={title} />}
    >
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(saveForm)}>
          <Fields.Address
            addressesCount={addressesCount}
            defaultValues={defaultValues}
            isLoading={isLoading}
            onCancel={close}
            withActions
            withCheckboxes
          />
        </Form>
      </FormProvider>
    </Accordion>
  );
};

export default AddressesForm;

const Form = styled.form``;
