import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';

interface Props {
  value: string;
}
const NoProduct: React.FC<Props> = ({ value }) => {
  return (
    <Root>
      <FormattedMessage id={value} />
    </Root>
  );
};

export default NoProduct;

const Root = styled.div`
  text-align: center;
  width: 100%;
  font-size: 1.5rem;
  @media ${MOBILE_TABLET_RESOLUTION} {
    font-size: 1.25rem;
  }
`;
