import * as React from 'react';
import { useFormattedCurrency } from 'hooks';

interface Props {
  value: number;
}

const FormattedCurrency: React.FC<Props> = ({ value }) => {
  return <>{useFormattedCurrency(value)}</>;
};

export default FormattedCurrency;
