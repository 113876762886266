import * as React from 'react';
import { InputBaseProps } from '@material-ui/core/InputBase';
import { useController, useFormContext } from 'react-hook-form';
import ErrorMessage from 'components/forms/FormErrorMessage';
import Label, { ILabel } from '../FormLabel/FormLabel';
import Field from '../FormFieldContainer/FormFieldContainer';
import FormInput from './FormInput';

interface Props extends InputBaseProps {
  name: string;
  label?: ILabel;
  required?: boolean;
  showValidAdornment?: boolean;
  width?: string | number;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  maxLength?: number;
}

const FormField: React.FC<Props> = ({
  showValidAdornment,
  label,
  name,
  required,
  width,
  className,
  maxLength,
  ...props
}) => {
  const { control } = useFormContext();
  const { meta } = useController({ name, control });
  const { invalid } = meta;

  return (
    <Field $width={width} className={className}>
      {label && (
        <Label
          label={label}
          htmlFor={name}
          required={required}
          isInvalid={invalid}
        />
      )}
      <FormInput
        showValidAdornment={showValidAdornment}
        name={name}
        maxLength={maxLength}
        {...props}
      />
      <ErrorMessage name={name} />
    </Field>
  );
};

export default React.memo(FormField);
