import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import Masony from '@mui/lab/Masonry';
import MasonryItemm from '@mui/lab/MasonryItem';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import no_image_placeholder from 'assets/images/client-products/no_image.png';
import {
  LAPTOP_MAX_RESOLUTION,
  LAPTOP_MIN_RESOLUTION,
  MOBILE_RESOLUTION,
  TABLET_RESOLUTION,
} from 'styles/sharedStyle';
import { useRouter } from 'hooks';
import { ClientRouter } from 'router/routes';
import type { Skin } from 'types/properties';
import { toCurrencyString } from 'utils/string.utils';
import PinterestIdeasCard from 'components/@client/PinterestIdeasCard/PinterestIdeasCard';
import Loader from './components/SkinsLoader';

interface Props {
  isLoading: boolean;
  groupedSkinListItems: Skin[];
}

const SkinsContainerNew: React.FC<Props> = ({
  groupedSkinListItems,
  isLoading,
}) => {
  const { history } = useRouter();
  const convertPrice = React.useCallback((price, country) => {
    return price > 0 ? (
      `${toCurrencyString({
        value: price,
        country,
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
      })}`
    ) : (
      <FormattedMessage id={Message.SKIN_CARD_PRICE_FREE} />
    );
  }, []);

  const columns = React.useMemo(() => {
    if (window.screen.width < 576) return Math.floor(window.screen.width / 327);
    if (window.screen.width >= 576 && window.screen.width <= 820)
      return Math.floor(window.screen.width / 288);
    if (window.screen.width < 1600)
      return Math.floor(window.screen.width / 318);
    return 5;
  }, []);

  return (
    <>
      <MyDivContainer>
        {isLoading ? (
          <Loader />
        ) : (
          <Masony columns={columns} spacing="20px">
            {groupedSkinListItems.map((e: Skin, i: number) => {
              return (
                <MyGridItem
                  key={`${e.id + i.toString()}`}
                  className="photo-item"
                >
                  <PinterestIdeasCard
                    isSkinCard={true}
                    image={e?.desktopThumbnail ?? no_image_placeholder}
                    userName={e?.user?.name ?? ''}
                    name={e.name.toString()}
                    price={convertPrice(e.price, e.country)}
                    date={e?.createdAt}
                    chipsData={e.chipsData}
                    onClick={(type: string | null) => {
                      if (type) {
                        window.open(
                          ClientRouter.IDEA_PAGE.replace(
                            ':id',
                            e?.id.toString() ?? ''
                          ),
                          type
                        );
                      } else {
                        history.push(
                          ClientRouter.SKIN.replace(
                            ':id',
                            e?.id.toString() ?? ''
                          )
                        );
                      }
                    }}
                  />
                </MyGridItem>
              );
            })}
          </Masony>
        )}
      </MyDivContainer>
    </>
  );
};

export default React.memo(SkinsContainerNew);

const MyGridItem = styled(MasonryItemm)`
  width: 288px !important;
  @media ${TABLET_RESOLUTION} {
    width: 100% !important;
  }
  height: fit-content;
  min-height: 285px;
`;

const MyDivContainer = styled.div`
  max-width: 1600px;
  margin: 32px auto;
  background: #fff;
  width: 100%;
  @media ${MOBILE_RESOLUTION} {
    ${rtl`
    margin: 0 14px 0 5px;
  `}
  }
  @media ${LAPTOP_MAX_RESOLUTION} and ${LAPTOP_MIN_RESOLUTION} {
    padding: 0 20px;
  }

  ::-webkit-scrollbar {
    display: none;
  }
`;
