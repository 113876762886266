import * as React from 'react';
import styled, { css } from 'styled-components';
import { Slide } from '@material-ui/core';
import { WhiteLogo } from 'components/Logo';
import { Tab } from 'utils/sidebar.utils';
import { ClientRouter } from 'router/routes';
import NavList from './NavList';

interface Props {
  items: Tab[];
  background?: string;
  displayLogo?: true;
  fixed?: true;
  narrow?: true;
}

const Sidebar: React.FC<Props> = ({
  items,
  displayLogo,
  background = '#FF3100',
  narrow,
  ...props
}) => {
  return (
    <Slide direction="right" in={true} mountOnEnter unmountOnExit>
      <Container background={background} narrow={narrow} {...props}>
        {displayLogo && (
          <a href={ClientRouter.APP}>
            <LogoContainer />
          </a>
        )}
        <NavList items={items} narrow={narrow} />
      </Container>
    </Slide>
  );
};

export default Sidebar;

const Container = styled.aside<{
  background: string;
  fixed?: true;
  narrow?: true;
}>`
  display: flex;
  overflow: auto;
  align-items: center;
  text-align: center;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 2;
  background: ${({ background }) => background};
  width: ${({ narrow }) => (narrow ? '85px' : '90px')};
  ${({ theme }) => theme.shadow.m}

  ${({ fixed }) =>
    fixed &&
    css`
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
    `}

  & .icon-quiz-outlined, .icon-quiz-filled {
    position: relative;
    left: 4px;
  }
`;

const LogoContainer = styled(WhiteLogo)`
  position: relative;
  top: 10px;
  width: 50px;
  height: 100px;
`;
