import * as React from 'react';
import styled from 'styled-components';
import Loader from 'components/Loader';

export interface GradientImageCardProps {
  isLoading?: boolean;
  imageUrl?: string;
}

const GradientImageCard: React.FC<GradientImageCardProps> = ({
  isLoading = false,
  imageUrl = '',
  children,
  ...otherProps
}) => {
  return (
    <Container imageUrl={imageUrl} {...otherProps}>
      {isLoading ? <Loader /> : <BackgroundImage />}
      {children}
    </Container>
  );
};

const BackgroundImage = styled.div.attrs({ className: 'gradient-image' })`
  background: linear-gradient(
    to bottom,
    rgba(56, 56, 56, 0.7),
    rgba(255, 255, 255, 0.3)
  );
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-size: cover;
  position: absolute;
`;

const Container = styled.div.attrs({ className: 'gradient-image-container' })<{
  imageUrl: string;
}>`
  background-image: url('${({ imageUrl }) => imageUrl}');
  width: 100%;
  height: 310px;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  position: relative;

  & a {
    mix-blend-mode: difference;
    z-index: 30;
  }

  & a.enter-home-btn {
    mix-blend-mode: unset;
  }
`;

export default GradientImageCard;
