import React, { useState } from 'react';
import { Product } from 'types/products';
import styled, { css } from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import Button from 'components/@client/Button';
import Icon from 'components/Icon';
import Modal, { ModalAction } from 'components/@client/Modal';
import { ReactComponent as InfoIcon } from 'assets/icons/ideaInfo.svg';
import { ArrowBase } from 'components/@client/SlideshowModal/Arrows';
import FormattedCurrency from 'components/@client/FormattedCurrency/FormattedCurrency';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import { useAddToCart, useSession } from 'hooks';
import history from 'router/history';
import { ReactComponent as Checkmark } from 'components/@client/form-components/pick.svg';
import {
  MOBILE_RESOLUTION,
  MOBILE_TABLET_RESOLUTION,
} from 'styles/sharedStyle';
import SafeImg from 'components/SafeImg/SafeImg';
import rtl from 'styled-components-rtl';
import { ClientRouter } from 'router/routes';
import { useMediaQuery } from '@material-ui/core';
import MuiSlider from '@mui/material/Slider';

interface Props {
  product?: Product[];
  title?: string | React.ReactNode;
  // onMediaClick?: (index: number) => void;
}

const ProductSection: React.FC<Props> = ({ product, title }) => {
  // const { media } = product;
  const [prevIndex, setPrevIndex] = useState<number>(0);
  // const mediaInfo = React.useMemo(() => sortBy(media ?? [], 'type'), [media]);
  const isMobile = useMediaQuery(MOBILE_TABLET_RESOLUTION);
  const { locale } = useSession();
  const [selectedItem, setSelectedItem] = React.useState(
    locale === 'he' ? (product?.length ?? 0) - 2 : 0
  );
  const [mouseDownCoordinates, setMouseDownCoordinates] = React.useState({
    x: 0,
    y: 0,
  });
  const {
    addToCart,
    isAuthenticatedForCart,
    isLoading: isCartLoading,
    preregisterUser,
    setSuccessModalOpen,
    successModalOpen,
    lastProductAdded,
  } = useAddToCart();

  const currentValue = React.useMemo(() => {
    if (window.screen.width <= 320) return 1;
    if (window.screen.width <= 480) return 1.2;
    if (window.screen.width >= 480 && window.screen.width <= 600) return 2;
    if (window.screen.width >= 600 && window.screen.width <= 820) return 3;
    if (window.screen.width > 820 && window.screen.width < 1200) return 4;
    if (window.screen.width >= 1200 && window.screen.width < 1600) return 5;
    return 6;
  }, []);

  const NextArrow = (props: {
    onClick?: React.MouseEventHandler<HTMLDivElement>;
  }) => {
    const { onClick } = props;
    return isMobile ? (
      <></>
    ) : (
      <ArrowContainer
        role="button"
        isOpen={true}
        onClick={onClick}
        prev={false}
        prevIndex={Math.floor(prevIndex)}
        mediaLength={product?.length}
        currentValue={currentValue}
      >
        <ArrowIcon className="next" />
      </ArrowContainer>
    );
  };

  const PrevArrow = (props: {
    onClick?: React.MouseEventHandler<HTMLDivElement>;
  }) => {
    const { onClick } = props;
    return isMobile ? (
      <></>
    ) : (
      <ArrowContainer
        role="button"
        isOpen={true}
        onClick={onClick}
        prev={true}
        prevIndex={Math.floor(prevIndex)}
        mediaLength={product?.length}
        currentValue={currentValue}
      >
        <ArrowIcon className="prev" />
      </ArrowContainer>
    );
  };

  const settings = {
    dots: false,
    infinite: false,
    afterChange: (index: number) => {
      setPrevIndex(index);
      setSelectedItem(index);
    },
  };

  const actions: ModalAction[] = [
    {
      label: <FormattedMessage id={Message.CART_KEEP_SHOPPING_BUTTON} />,
      onClick: () => {
        setSuccessModalOpen(false);
      },
      variant: 'secondary',
    },
    {
      label: <FormattedMessage id={Message.CART_CHECKOUT_BUTTON} />,
      onClick: () => {
        if (isAuthenticatedForCart) {
          history.push(ClientRouter.CART);
        } else {
          preregisterUser();
          // history.push(`${ClientRouter.ADDITIONAL_INFO}?redirect=CART`);
        }
      },
      isLoading: isCartLoading,
      variant: 'primary',
    },
  ];

  return (
    <Container>
      <MediaContainer>
        {title && title}

        <Slider
          {...settings}
          slidesToShow={currentValue}
          slidesToScroll={1}
          swipeToSlide={true}
          nextArrow={<NextArrow />}
          prevArrow={<PrevArrow />}
        >
          {product &&
            product.map((item, index) => {
              return (
                <ProductContainer key={item.id} data-testid="product-item">
                  <div
                    onMouseDown={e => {
                      setMouseDownCoordinates({ x: e.clientX, y: e.clientY });
                      e.preventDefault();
                    }}
                    onClick={e => {
                      if (
                        e.clientX === mouseDownCoordinates.x ||
                        e.clientY === mouseDownCoordinates.y
                      )
                        history.push(
                          `${history.location.pathname}?productId=${item.id}`
                        );
                      e.preventDefault();
                    }}
                  >
                    <ImageContainer>
                      <img src={item?.thumbnail?.file} alt="Not available" />
                    </ImageContainer>
                    <ProductCategory>{item?.category}</ProductCategory>
                    {item?.name?.length < 22 ? (
                      <ProductTitle>{item?.name}</ProductTitle>
                    ) : (
                      <ProductTitle>{item?.name?.slice(0, 22)}...</ProductTitle>
                    )}{' '}
                    <ProductPrice>
                      <FormattedCurrency value={item?.price} />
                      {item.msrp > item.price && (
                        <>
                          <ProductDiscount>
                            <FormattedCurrency value={item?.msrp} />
                          </ProductDiscount>
                        </>
                      )}
                    </ProductPrice>
                  </div>
                  <ActionButtonContainer>
                    <Button
                      // isLoading={isLoading}
                      className="cart-button"
                      onClick={e => {
                        e.preventDefault();
                        if (item) addToCart(item);
                      }}
                    >
                      <FormattedMessage id={Message.ADD_TO_CART} />
                    </Button>
                    <InfoIcon
                      onClick={() =>
                        history.push(
                          `${history.location.pathname}?productId=${item.id}`
                        )
                      }
                    />
                  </ActionButtonContainer>
                </ProductContainer>
              );
            })}
        </Slider>
        {isMobile && (
          <div style={{ width: '167px', margin: 'auto', marginTop: '24px' }}>
            <MuiSlider
              max={(product && product.length) ?? 0 - 2}
              min={0}
              value={selectedItem}
              componentsProps={{
                rail: {
                  style: {
                    width: '167px',
                    height: '2px',
                    background: '#C4C4C4',
                    borderRadius: '30px',
                  },
                },
                thumb: {
                  style: {
                    width: `${167 / ((product && product.length) ?? 0)}px`,
                    height: '2px',
                    borderRadius: '30px',
                    background: 'black',
                  },
                },
                track: {
                  style: {
                    width: '167px',
                    height: '2px',
                    background: '#C4C4C4',
                    borderRadius: '30px',
                    border: 'none',
                  },
                },
              }}
            />
          </div>
        )}
      </MediaContainer>
      <ConfirmModal
        className="confirm-modal"
        onClose={() => setSuccessModalOpen(false)}
        actions={actions}
        open={successModalOpen}
      >
        <Content>
          <div>
            <SafeImg
              src={lastProductAdded?.thumbnail?.file}
              alt={lastProductAdded?.name}
            />
          </div>
          <Checkmark className="checkmark" />
          <FormattedMessage id={Message.ADDED_TO_CART} />
        </Content>
      </ConfirmModal>
    </Container>
  );
};

export default ProductSection;

const Container = styled.div``;

const MediaContainer = styled.div`
  position: relative;

  && .horizontal-carousel {
    gap: 40px !important;
  }

  && .slick-track {
    margin: 0;
  }
`;

const ArrowContainer = styled(ArrowBase)<{
  prev: boolean;
  prevIndex: number;
  mediaLength?: number;
  currentValue: number;
}>`
  position: absolute;
  top: -40px;
  ${({ theme, prev }) =>
    theme.dir === 'rtl'
      ? css`
          left: ${prev ? '58' : '0'}px;
          transform: rotate(${prev ? 0 : 180}deg);
        `
      : css`
          right: ${prev ? '58' : '0'}px;
          transform: rotate(${prev ? 180 : 0}deg);
        `}

  & .prev {
    fill: ${({ prevIndex }) => (prevIndex === 0 ? '#D9D9D9' : '#292929E5')};
  }
  & .next {
    fill: ${({ prevIndex, mediaLength, currentValue }) =>
      prevIndex + currentValue === mediaLength ? '#D9D9D9' : '#292929E5'};
  }
`;

const ArrowIcon = styled(Icon).attrs({
  name: 'nav-arrow-right',
})<{ isOpen: boolean }>`
  position: relative;
  left: ${({ isOpen }) => (isOpen ? -4 : 4)}px;
  ${({ theme, isOpen }) => theme.utils.rotateIcon({ isOpen })}
`;

const ProductContainer = styled.div`
  margin-top: 36px;
  ${rtl`
  direction: ltr;
  `}
`;

const ImageContainer = styled.div`
  padding: 8px;
  height: 280px;
  width: 243px;
  > img {
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    object-fit: contain;
  }
`;
const ProductCategory = styled.h3`
  color: #696969;
  margin: 8px 0 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
`;

const ProductTitle = styled.h3`
  width: 100%;
  margin: 4px 0 0;
  color: #282929;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
`;

const ProductPrice = styled.div`
  margin: 4px 0 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #fc5c42;
  display: flex;
`;

const ProductDiscount = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #696969;
  text-decoration-line: line-through;
  ${rtl`
  margin-left: 8px;
`}
`;
const ActionButtonContainer = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
  & .cart-button {
    height: 30px;
    width: 128px;
    background: #fc5c42;
    border: 1px solid #fc5c42;
    border-radius: 8px;
    ${rtl`
    margin-right: 8px;
  `}
  }
  & svg {
    border: 1px solid #3e3e3e;
    border-radius: 8px;
    :hover {
      border: 1px solid #fc5c42;
      & path {
        fill: #fc5c42;
      }
      & rect {
        stroke: #fc5c42;
      }
    }
  }
`;

const ConfirmModal = styled(Modal)`
  & .MuiDialogActions-root {
    @media ${MOBILE_RESOLUTION} {
      display: block;
      text-align: center;
      & > button {
        ${rtl`
        margin: 10px !important;
      `}
      }
    }
    & > button {
      min-width: 150px;
    }
  }
`;
const Content = styled.p`
  text-align: center;
  img {
    max-width: 80px;
    max-height: 80px;
    margin-bottom: 10px;
  }
  margin-bottom: 20px;
  font-size: 1.25rem;
  color: ${({ theme }) => theme.colors.black0};
  svg {
    path {
      fill: ${({ theme }) => theme.colors.primary.lightMobile};
    }
    ${rtl`
      margin-right: 10px;
    `}
  }
`;

// const Image = styled(GenericCarouselImage)`
//   width: 100%;
//   height: 100%;
//   margin: 0 !important;
//   border-radius: 10px !important;
// `;
