import * as React from 'react';
import { DialogContent, List } from '@material-ui/core';
import styled from 'styled-components';
import Loader from 'components/Loader';
import { IAddress } from 'types/common.types';
import { isEmpty } from 'utils/lodash.utils';
import DeleteAddressModal from './DeleteAddressModal';
import AddressListItem from './AddressListItem';

export enum Variant {
  ADDRESSES_MODAL = 'Addresses Modal',
  ADDRESS_FORM = 'Address Form',
}

interface Props {
  addresses?: IAddress[] | null;
  isLoading?: boolean;
  variant?: Variant;
  showCaption?: true;
  withActions?: boolean;
  onEdit?: (address: IAddress) => void;
  fetchAddresses?: (reason?: 'creation' | 'remove' | 'cancel') => void;
}

const AddressesList: React.FC<Props> = ({
  addresses,
  isLoading,
  variant = Variant.ADDRESSES_MODAL,
  showCaption,
  withActions,
  onEdit,
  fetchAddresses,
}) => {
  const [deleteCandidate, setDeleteCandidate] = React.useState<IAddress | null>(
    null
  );
  const toggleRemove = React.useCallback(
    (address: IAddress | null = null) => setDeleteCandidate(address),
    []
  );
  const isModalOpen = React.useMemo(() => Boolean(deleteCandidate), [
    deleteCandidate,
  ]);

  return (
    <>
      <Content $isAddressesModal={variant === Variant.ADDRESSES_MODAL}>
        {showCaption && <Caption>Addresses</Caption>}
        {isLoading ? (
          <Loader />
        ) : (
          <CustomList>
            {isEmpty(addresses)
              ? 'No addresses available'
              : addresses?.map(address => (
                  <AddressListItem
                    key={address.id}
                    address={address}
                    withHomeIcon={
                      variant === Variant.ADDRESS_FORM ||
                      Boolean(address.isPrimary)
                    }
                    withActions={withActions}
                    onEdit={onEdit}
                    onDelete={toggleRemove}
                  />
                ))}
          </CustomList>
        )}
      </Content>
      {isModalOpen && (
        <DeleteAddressModal
          address={deleteCandidate}
          isOpen={isModalOpen}
          toggle={() => toggleRemove()}
          fetchAddresses={fetchAddresses}
        />
      )}
    </>
  );
};

const Content = styled(DialogContent)<{ $isAddressesModal?: boolean }>`
  margin: ${({ $isAddressesModal }) => ($isAddressesModal ? '30px 0' : 0)};
  padding: ${({ $isAddressesModal }) => ($isAddressesModal ? '' : '0 0 0 5px')};
  ${({ theme }) => theme.utils.scrollbar}
`;

const CustomList = styled(List)`
  padding: 0 0 10px 0;
`;

const Caption = styled.div`
  margin: 0 7px 17px 0px;
  font-size: 0.95rem;
  font-weight: 800;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.silver};
`;

export default AddressesList;
