import * as React from 'react';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import Title from '@material-ui/core/DialogTitle';
import Content from '@material-ui/core/DialogContent';
import Actions from '@material-ui/core/DialogActions';
import SecondaryButton from 'components/@client/Button/SecondaryButton';
import Button from 'components/@client/Button';
import { useDisableOverflow } from 'hooks';

export type ModalAction = {
  isLoading?: boolean;
  disabled?: boolean;
  label: string | React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  variant?: 'primary' | 'secondary';
};

export interface ModalProps extends DialogProps {
  actions?: ModalAction[];
  disableClose?: boolean;
  isFooterBtn?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  children,
  title,
  actions = [{ label: 'Cancel' }, { label: 'OK' }],
  isFooterBtn = true,
  disableClose,
  ...props
}) => {
  const onClose = React.useCallback(() => {
    props.onClose?.({}, 'backdropClick');
  }, [props]);

  useDisableOverflow(props.open);
  return (
    <Container {...props}>
      <Header>
        {!disableClose && (
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
      </Header>
      {title && <Title>{title}</Title>}
      {children && <Content>{children}</Content>}
      {isFooterBtn && actions && (
        <Actions>
          {actions.map(
            ({
              variant = 'primary',
              isLoading = false,
              disabled = false,
              label,
              onClick = onClose,
            }) =>
              variant === 'primary' ? (
                <Button
                  className="modal-action-button"
                  isLoading={isLoading}
                  disabled={disabled}
                  key={label?.toString()}
                  onClick={onClick}
                >
                  {label}
                </Button>
              ) : (
                <SecondaryButton
                  isLoading={isLoading}
                  key={label?.toString()}
                  onClick={onClick}
                >
                  {label}
                </SecondaryButton>
              )
          )}
        </Actions>
      )}
    </Container>
  );
};

export default Modal;

const Container = styled(Dialog).attrs<ModalProps>({
  className: 'client-dialog',
  BackdropProps: { className: 'client-dialog-backdrop' },
})`
  position: relative;
  &.client-dialog .MuiDialog-paper {
    padding: 10px;
    border-radius: 16px;

    & .MuiDialogTitle-root {
      margin: 24px;
    }

    & .MuiDialogActions-root {
      padding: 0 3rem 24px 3rem;
      justify-content: center;
      font-family: ${({ theme }) => theme.dir === 'rtl' && 'Assistant'};

      && .MuiButton-label {
        font-family: ${({ theme }) => theme.dir === 'rtl' && 'Assistant'};
      }

      & > button {
        flex: 1;
        font-family: ${({ theme }) => theme.dir === 'rtl' && 'Assistant'};
        max-width: 150px;
        :not(:last-of-type) {
          ${rtl`
            margin-right: 10px;
          `};
        }
      }
    }
  }
  .client-dialog-backdrop {
    background: rgba(92, 87, 87, 0.47);
  }
`;

const Header = styled.header`
  position: absolute;
  top: 15px;
  ${rtl`right: 11px;`}
`;
