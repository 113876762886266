import { Client } from 'types/clients';
import { GetOrdersDataResponsePayload } from 'store/types/user-management/orders.types';
import * as Payload from 'store/types/user-management/clients.types';

export const createClientInfo = (
  user: Client,
  properties: Payload.GetPropertiesDataResponsePayload,
  orders: GetOrdersDataResponsePayload
): Client => {
  return {
    ...user,
    KPIs: [
      {
        label: 'Purchased products',
        value: orders.purchased_products,
        unit: undefined,
      },
      {
        label: 'Time at property',
        // TODO: fetch time at property from backend
        value: 0,
        unit: 'min (Avg)',
      },
      {
        label: 'Number of properties',
        value: properties.count,
        unit: undefined,
      },
      {
        label: 'Total money spent',
        value: orders.total_money_spent,
        unit: 'currency',
      },
    ],
  };
};
