import * as actions from 'store/actions/user-management/orders.actions';
import ordersDataType from 'store/constants/user-management/orders.constants';
import * as Payload from 'store/types/user-management/orders.types';
import { Order } from 'types/orders';

export interface OrdersState {
  loading: Record<ordersDataType, boolean>;
  error: Record<ordersDataType, null | string>;
  orders: Order[];
  pageCount: number;
}

const REQUESTS = [ordersDataType.GET_ORDERS_DATA_REQUEST];

const initialState: OrdersState = {
  loading: REQUESTS.reduce(
    (state, next) => ({ ...state, [next]: false }),
    {} as Record<ordersDataType, boolean>
  ),
  error: REQUESTS.reduce(
    (state, next) => ({ ...state, [next]: null }),
    {} as Record<ordersDataType, null | string>
  ),
  orders: [],
  pageCount: 0,
};

const ordersReducer = (
  state: OrdersState = initialState,
  action: actions.OrdersAction
): OrdersState => {
  switch (action.type) {
    case ordersDataType.GET_ORDERS_DATA_REQUEST:
      return {
        ...updateLoading(state, ordersDataType.GET_ORDERS_DATA_REQUEST),
      };
    case ordersDataType.GET_ORDERS_DATA_FULFILLED:
      return {
        ...updateSuccess(
          state,
          ordersDataType.GET_ORDERS_DATA_REQUEST,
          action.payload as Payload.GetOrdersDataFulfilledPayload
        ),
      };
    case ordersDataType.GET_ORDERS_DATA_REJECTED:
      return {
        ...updateError(
          state,
          ordersDataType.GET_ORDERS_DATA_REQUEST,
          action.payload as Payload.GetOrdersDataRejectedPayload
        ),
      };
    default:
      return state;
  }
};

export default ordersReducer;

type requestType = ordersDataType.GET_ORDERS_DATA_REQUEST;
const updateLoading = (state: OrdersState, requestType: requestType) => ({
  ...state,
  loading: {
    ...state.loading,
    [requestType]: true,
  },
  error: {
    ...state.error,
    [requestType]: null,
  },
});

type ErrorPayload = Payload.GetOrdersDataRejectedPayload;
const updateError = (
  state: OrdersState,
  requestType: requestType,
  { error }: ErrorPayload
) => ({
  ...state,
  loading: {
    ...state.loading,
    [requestType]: false,
  },
  error: {
    ...state.error,
    [requestType]: error,
  },
});
type SuccessPayload = Payload.GetOrdersDataFulfilledPayload;
const updateSuccess = (
  state: OrdersState,
  requestType: requestType,
  payload: SuccessPayload
) => ({
  ...state,
  loading: {
    ...state.loading,
    [requestType]: false,
  },
  error: {
    ...state.error,
    [requestType]: null,
  },
  ...payload,
});
