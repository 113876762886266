import * as React from 'react';
import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import Title from 'components/@client/TopTitle';
import SafeImg from 'components/SafeImg';
import rtl from 'styled-components-rtl';
import { Product } from 'types/products';
import type { GetProductsDataResponsePayload as GetSupplierProductsResponse } from 'store/types/user-management/suppliers.types';
import { ClientConfig } from 'api/client';
import ProductDetails from './component/ProductDetails';
import ProductsCard from '../products/components/ProductsCard';

interface Props {
  product: Product;
  categoryProducts?: GetSupplierProductsResponse | null;
  loadMoreProducts: (newConfig: ClientConfig) => void;
  isLoading?: boolean;
}

const MobileProductPage: React.FC<Props> = ({
  product,
  categoryProducts,
  loadMoreProducts,
  isLoading,
}) => {
  return (
    <Root>
      <CarouselContainer>
        <Header>
          <Title title="" />
        </Header>
        {product?.media ? (
          <Carousel
            className="product-carousel"
            showArrows={false}
            showStatus={false}
            showThumbs={false}
            infiniteLoop
          >
            {product.media.map((item, i) => (
              <Img key={item.id}>
                <SafeImg src={item.file} alt={item.id} />
              </Img>
            ))}
          </Carousel>
        ) : (
          <Img>
            <SafeImg />
          </Img>
        )}
      </CarouselContainer>
      <ProductDetails product={product} />
      <ProductsCard
        productPage
        products={categoryProducts}
        loadMoreProducts={loadMoreProducts}
        isLoading={isLoading}
      />
    </Root>
  );
};

export default MobileProductPage;

const Root = styled.div`
  padding: 20px 14px;
`;

const CarouselContainer = styled.div`
  .carousel-root {
    padding-top: 28px !important;
  }
  & .slider-wrapper > ul {
    direction: ltr;
    li {
      height: 380px;
      display: flex;
      & > div {
        margin: auto;
      }
    }
  }
`;

const Header = styled.div`
  position: absolute;
  z-index: 99;

  .client-top-title {
    margin: unset !important;
  }
  ${rtl`
  margin-left: 24px;
  `}
`;

const Img = styled.div`
  ${({ theme }) => theme.utils.centerFlex};
  & img {
    width: auto !important;
    max-width: 100%;
    max-height: 380px;
  }
`;
