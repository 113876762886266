import { all, call, put, debounce } from 'redux-saga/effects';
import {
  ThreeDTasksDataType,
  ThreeDInDbDataType,
} from 'store/constants/3d-content-management/threeD.constants';
import * as Payload from 'store/types/3d-content-management/threeD.types';
import * as ThreeDActions from 'store/actions/3d-content-management/threeD.actions';
import { showNotification } from 'store/actions/notifications.actions';
import { ThreeDAPI } from 'api/methods';
import { removeEmptyValues } from 'utils/common.utils';
import { newSortByMap } from 'utils/transform.utils';

function* errorHandler(
  e: Error,
  type: ThreeDTasksDataType | ThreeDInDbDataType,
  rejectAction: (payload: {
    error: string;
  }) => {
    type: ThreeDTasksDataType | ThreeDInDbDataType;
    payload: { error: string };
  }
) {
  const error = e.message || 'ERROR: Cannot refresh Data source';
  yield put(showNotification({ key: type, message: error, severity: 'error' }));
  yield put(rejectAction({ error }));
}

const sortProductsMap = {
  name: 'name',
};

function* getThreeDTasksDataRequest({
  payload,
  type: actionType,
}: ReturnType<typeof ThreeDActions.getThreeDTasksDataRequest>) {
  const {
    page = 0,
    size = 10,
    search,
    categories: category,
    sortBy,
    subCategories: sub_category,
    assignedTo: assigned_to,
    status,
    supplier,
    manufacturer,
    type,
  } = payload;
  try {
    const params = removeEmptyValues({
      page: page + 1,
      size,
      search,
      category,
      sub_category,
      assigned_to,
      status,
      supplier,
      manufacturer,
      type,
      ...newSortByMap<typeof sortProductsMap>(sortProductsMap, sortBy),
    }) as Payload.Get3DTasksDataRequestPayload;

    const {
      results: tasks,
      count,
    }: Payload.Get3DTasksDataResponsePayload = yield call(
      ThreeDAPI.getAllTasks,
      { params }
    );
    const pageCount = Math.ceil(count / size);

    yield put(ThreeDActions.getThreeDTasksDataFulfilled({ tasks, pageCount }));
  } catch (e) {
    yield call(
      errorHandler,
      e,
      actionType,
      ThreeDActions.getThreeDTasksDataRejected
    );
  }
}

function* getThreeDInDbDataRequest({
  payload,
  type: actionType,
}: ReturnType<typeof ThreeDActions.getThreeDInDbDataRequest>) {
  const {
    page = 0,
    size = 10,
    search,
    categories: category,
    sortBy,
    subCategories: sub_category,
    assignedTo: assigned_to,
    supplier,
    updated_from,
    updated_to,
  } = payload;
  try {
    const params = removeEmptyValues({
      page: page + 1,
      size,
      search,
      sub_category,
      category,
      assigned_to,
      supplier,
      updated_from,
      updated_to,
      ...newSortByMap<typeof sortProductsMap>(sortProductsMap, sortBy),
    }) as Payload.Get3DInDbDataRequestPayload;

    const {
      results: inDb,
      count,
    }: Payload.Get3DInDbDataResponsePayload = yield call(ThreeDAPI.getInDb, {
      params,
    });
    const pageCount = Math.ceil(count / size);

    yield put(ThreeDActions.getThreeDInDbDataFulfilled({ inDb, pageCount }));
  } catch (e) {
    yield call(
      errorHandler,
      e,
      actionType,
      ThreeDActions.getThreeDInDbDataRejected
    );
  }
}

function* threeDSaga() {
  yield all([
    debounce(
      500,
      ThreeDTasksDataType.GET_3D_TASKS_REQUEST,
      getThreeDTasksDataRequest
    ),
    debounce(
      500,
      ThreeDInDbDataType.GET_3D_IN_DB_REQUEST,
      getThreeDInDbDataRequest
    ),
  ]);
}

export default threeDSaga;
