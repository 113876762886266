import * as React from 'react';
import { View, Image, StyleSheet } from '@react-pdf/renderer';
import Logo from 'assets/icons/brandLogo-red.png';
import FullLogo from 'assets/images/documents/header_logo.png';

const styles = StyleSheet.create({
  header: {
    marginTop: 44,
    marginLeft: 44,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  headerContent: {
    width: '100%',
    height: '100%',
    marginLeft: 40,
    flexWrap: 'wrap',
  },
  image: {
    height: 75,
    width: 49,
  },
  orderHeader: {
    marginTop: 48,
    marginLeft: 48,
    marginBottom: 33,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  oderImage: {
    width: 280,
    height: 90,
  },
});

interface Props {
  contentStyles?: object;
  fixed?: true;
  isForOrder?: boolean;
}

const Header: React.FC<Props> = ({
  children,
  contentStyles,
  fixed,
  isForOrder = false,
}) => (
  <View fixed={fixed} style={isForOrder ? styles.orderHeader : styles.header}>
    <View style={isForOrder ? styles.oderImage : styles.image}>
      <Image
        src={isForOrder ? FullLogo : Logo}
        style={{ width: '100%', height: '100%' }}
      />
    </View>
    <View style={{ ...styles.headerContent, ...contentStyles }}>
      {children}
    </View>
  </View>
);

export default Header;
