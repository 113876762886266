import * as React from 'react';
import Tooltip from 'components/Tooltip';
import InfoIcon from 'assets/@client/supplier-login-flow/info-icon.png';
import styled from 'styled-components';

const DesktopTooltip: React.FC<{ label: string; title: React.ReactNode }> = ({
  label,
  title,
}) => {
  return (
    <StyledTooltip>
      <div>{label}</div>
      <Tooltip arrow title={title} $variant="supplierInfo" placement="top">
        <InfoView style={{ marginTop: 0 }}>
          <InfoImage src={InfoIcon} alt="info-icon" />
        </InfoView>
      </Tooltip>
    </StyledTooltip>
  );
};

export default DesktopTooltip;

const StyledTooltip = styled.div`
  display: flex;
`;

const InfoView = styled.div`
  height: 21.33px;
  width: 21.33px;
  margin-left: 6px;
  cursor: pointer;
`;

const InfoImage = styled.img`
  height: 100%;
  width: 100%;
`;
