import * as React from 'react';
import MobileHeader from 'components/@client/MobileHeader/MobileHeader';
import { FormattedMessage } from 'react-intl';
import rtl from 'styled-components-rtl';
import styled, { css } from 'styled-components';
import { Message } from 'i18n';
import Button from 'components/@client/Button';
import CardList, {
  MobileCartItem,
  CartEmptyState,
} from 'components/@client/CardList/MobileCardList';
import CreditCardProviders from 'components/@client/CreditCardProviders';
import CartButtonIcon from 'assets/@client/cart/cart-button.svg';
import {
  useAuthentication,
  useFormattedCurrency,
  useRecentProperty,
  useSession,
  useScrollPosition,
} from 'hooks';
import { ClientRouter } from 'router/routes';
import { SupportedCountry } from 'types/common.types';
import { isEmpty, size, reduce } from 'utils/lodash.utils';
import { userLogData } from 'mockData/userLogData';
import { LogsAPI } from 'api/methods';
import SecondaryButton from 'components/Button/SecondaryButton';
import usePropertyCart from './hooks/usePropertyCart';
import {
  DeleteItemModal,
  DeleteItemModalAll,
  RestrictionModal,
} from './modals';

const MobileCart: React.FC<{ isLoading: boolean }> = ({
  isLoading: isCartLoading,
}) => {
  const [isRestricted, setIsRestricted] = React.useState<boolean>(false);
  const [isCheckoutBtn, setIsCheckoutBtn] = React.useState<boolean>(false);
  const { country } = useSession();
  const { recentProperty } = useRecentProperty();
  const { type } = useAuthentication();
  const {
    cart,
    deleteCandidate,
    isLoading,
    isUpdating,
    isProceedingToCheckout,
    restrictedItems,
    deleteItem,
    emptyItem,
    deleteCandidateAll,
    proceedToCheckout,
    toggleModal,
    toggleModalAllItem,
    updateQuantity,
    pagination,
    setPagination,
  } = usePropertyCart(type !== 'CLIENT');
  const scrollPosition = useScrollPosition();

  const isDeleteOpen = Boolean(deleteCandidate);
  const isDeleteAllOpen = Boolean(
    deleteCandidateAll?.ids && deleteCandidateAll?.ids?.length > 0
  );
  const price = useFormattedCurrency(cart?.subTotal ?? 0, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const finalPrice = useFormattedCurrency(cart?.finalPrice ?? 0, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const shippingAmount = useFormattedCurrency(0, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const vatAmount = useFormattedCurrency(cart?.vat ?? 0, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const changeModalOpen = React.useCallback(() => {
    toggleModalAllItem('null');
  }, [toggleModalAllItem]);

  const hasItems = size(cart?.items) > 0;
  const outOfStocked = React.useMemo(
    () => cart?.items.every(item => item.product.quantity === 0),
    [cart?.items]
  );

  const onClick = React.useCallback(() => {
    LogsAPI.postUserLogs({
      name: userLogData.eventName.checkOutButton,
      area: userLogData.eventArea.myCart,
      section: userLogData.eventSection.cart,
      path: userLogData.eventPath.appCartCartIdPath,
      metadata: { cart_id: cart?.id },
    });
    LogsAPI.pushDataLayer([
      { ecommerce: null },
      {
        event: 'checkout',
        ecommerce: {
          checkout: {
            products: cart?.items.map(item => ({
              name: item?.product.englishName,
              id: item?.product.id,
              price: item?.product.finalPrice,
              brand: item?.product.supplier.companyName,
              category: item?.product.subCategory,
              quantity: item.quantity,
            })),
          },
        },
      },
    ]);

    if (!isEmpty(restrictedItems)) return setIsRestricted(true);
    return proceedToCheckout();
  }, [proceedToCheckout, restrictedItems, cart]);
  const totalItems = React.useMemo(() => {
    return cart ? reduce(cart?.items, (sum, n) => sum + n.quantity, 0) : 0;
  }, [cart]);

  React.useEffect(() => {
    const element = document.querySelector('#CardPlaceHolderContainer');
    const rect = element?.getBoundingClientRect();
    if (rect && cart?.items && cart?.items?.length > 3) {
      setIsCheckoutBtn(
        !(
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <=
            (window.innerWidth || document.documentElement.clientWidth)
        )
      );
    }
  }, [cart?.items, scrollPosition]);

  return (
    <>
      <Container>
        <MobileHeader title={<FormattedMessage id={Message.CART_TITLE} />} />
        <CountContainer>
          <CountItem>
            <FormattedMessage
              id={Message.CART_TOTAL_ITEMS_LABEL_MOBILE}
              values={{
                value: (
                  <>
                    <span className="cart-count">{totalItems}</span>
                  </>
                ),
              }}
            />
          </CountItem>
          <TotalPrice>{finalPrice}</TotalPrice>
        </CountContainer>
        <CardList
          isLoading={isLoading || isCartLoading}
          EmptyState={
            <CartEmptyState
              to={
                recentProperty?.id
                  ? ClientRouter.PROPERTY.replace(
                      ':propertyId',
                      recentProperty?.id
                    )
                  : ClientRouter.PRODUCTS
              }
            />
          }
          items={cart?.items ?? []}
          renderItem={item => (
            <>
              <MobileCartItem
                key={item.id}
                isLoading={isUpdating}
                onQuantityChange={updateQuantity}
                onDelete={toggleModal}
                {...item}
              />
            </>
          )}
        />
        {cart?.items && cart?.items.length > 0 && (
          <ActionContainer>
            <ClearCart
              $xlarge
              className="secondary-button"
              onClick={changeModalOpen}
            >
              <img src={CartButtonIcon} alt="Clear Cart" />
              <FormattedMessage id={Message.CLEAR_CART} />
            </ClearCart>
            {type !== 'CLIENT' && cart && cart?.items?.length >= 10 && (
              <LoadMoreButton
                disabled={outOfStocked}
                isLoading={isProceedingToCheckout}
                onClick={() => {
                  setPagination({
                    page: pagination.page + 1,
                    size: 10,
                  });
                }}
              >
                Load More...
              </LoadMoreButton>
            )}
          </ActionContainer>
        )}
        {hasItems && (
          <>
            <OrderSummaryContainer>
              <Title>
                <FormattedMessage id={Message.ORDER_SUMMARY} />
              </Title>
              <PaymentDetail>
                <PriceList>
                  <PriceLable>
                    <FormattedMessage id={Message.BUYCOIN_SUB_TOTAL_LABEL} />
                  </PriceLable>
                  <PriceValue>{price}</PriceValue>
                </PriceList>
                <PriceList>
                  <PriceLable>
                    <FormattedMessage id={Message.CHECKOUT_SHIPPING_LABEL} />
                  </PriceLable>
                  <PriceValue>{shippingAmount}</PriceValue>
                </PriceList>
                <PriceList>
                  <PriceLable>
                    <FormattedMessage
                      id={
                        country === SupportedCountry.ISRAEL
                          ? Message.CHECKOUT_VAT_LABEL
                          : Message.CHECKOUT_TAX_LABEL
                      }
                      values={{
                        value: `${cart?.taxRate ?? ''}%`,
                      }}
                    />
                  </PriceLable>
                  <PriceValue>{vatAmount}</PriceValue>
                </PriceList>
                <StyledHr />
                <PriceList>
                  <PriceGrandTotalLable>
                    <FormattedMessage id={Message.CHECKOUT_TOTAL_LABEL} />
                  </PriceGrandTotalLable>
                  <PriceGrandTotalValue>{finalPrice}</PriceGrandTotalValue>
                </PriceList>
                <StyledHr id="CardPlaceHolderContainer" />
                <CheckoutButton isCheckoutBtn={isCheckoutBtn}>
                  <Button
                    disabled={outOfStocked}
                    isLoading={isProceedingToCheckout}
                    onClick={onClick}
                    className="checkout-button"
                    fullWidth
                  >
                    <FormattedMessage id={Message.CART_PLACE_ORDER_BUTTON} />
                  </Button>
                </CheckoutButton>
              </PaymentDetail>
            </OrderSummaryContainer>
            <CardPlaceHolderContainer>
              <CreditCardProviders />
            </CardPlaceHolderContainer>
          </>
        )}
      </Container>

      <DeleteItemModal
        open={isDeleteOpen}
        onClose={() => toggleModal()}
        onDelete={deleteItem}
        deleteCandidate={deleteCandidate}
      />
      <DeleteItemModalAll
        open={isDeleteAllOpen}
        onClose={() => toggleModalAllItem()}
        onDelete={emptyItem}
        deleteCandidateAll={deleteCandidateAll?.ids && deleteCandidateAll?.ids}
      />
      <RestrictionModal
        open={isRestricted}
        items={restrictedItems}
        isProceeding={isProceedingToCheckout}
        onProceed={proceedToCheckout}
        onClose={() => setIsRestricted(false)}
      />
    </>
  );
};

export default MobileCart;

const Container = styled.div`
  && {
    .mobile-header {
      margin-top: 0;
    }
    .mobile-header-icon {
      ${rtl`
  margin-left: 0;
  `};
    }
  }
`;

const CountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 7px;
`;

const CountItem = styled.div`
  font-family: ${({ theme }) => (theme.dir === 'rtl' ? 'Assistant' : 'Roboto')};
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #676766;

  ${rtl`
  padding-right: 4px;
  border-right: 1px solid #676766;
  `};
  .cart-count {
    color: #fc5c42;
  }
`;

const TotalPrice = styled.div`
  font-family: ${({ theme }) => (theme.dir === 'rtl' ? 'Assistant' : 'Roboto')};
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #fc5c42;
  ${rtl`
  padding-left: 4px;
  `};
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const LoadMoreButton = styled(Button)`
  width: 40%;
  align-self: center;
  background: #ff5241;
  border-radius: 11px;
`;

const OrderSummaryContainer = styled.section`
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 33px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-top: 24px;
  padding: 16px;
`;

const Title = styled.span`
  font-family: ${({ theme }) => (theme.dir === 'rtl' ? 'Assistant' : 'Roboto')};
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #282929;
`;

const PriceList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PriceLable = styled.div`
  font-family: ${({ theme }) => (theme.dir === 'rtl' ? 'Assistant' : 'Roboto')};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #3e3e3e;
`;

const PriceValue = styled.div`
  font-family: ${({ theme }) => (theme.dir === 'rtl' ? 'Assistant' : 'Roboto')};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #282929;
`;

const PaymentDetail = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledHr = styled.hr`
  width: 100%;
  height: 0;
  background: #a99997;
  opacity: 0.3;
  border-style: solid;
  border-color: #a99997;
`;

const PriceGrandTotalLable = styled.div`
  font-family: ${({ theme }) => (theme.dir === 'rtl' ? 'Assistant' : 'Roboto')};
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #282929;
`;

const PriceGrandTotalValue = styled.div`
  font-family: ${({ theme }) => (theme.dir === 'rtl' ? 'Assistant' : 'Roboto')};
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #282929;
`;

const CheckoutButton = styled.div<{
  isCheckoutBtn: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;

  .MuiButtonBase-root {
    background: #fc5c42;
    border: 1px solid #fc5c42;
    box-shadow: 0px 1px 47px rgb(0 0 0 / 9%);
    backdrop-filter: blur(13px);
    border-radius: 15px;
    height: 48px;
    width: 203px;
    .MuiButton-label {
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
      text-align: center;
      color: rgba(255, 255, 255, 0.9);
    }
    ${({ isCheckoutBtn }) =>
      isCheckoutBtn &&
      css`
        position: fixed;
        bottom: 112px;
        width: 327px;
      `}
    &:hover {
      background-color: #a50a0a;
      transition: background-color 0.3s;
      box-shadow: none;
      border: 1px solid;
    }

    &:disabled {
      background-color: #e8e8e8;
      color: white;
      border: 1px solid;
    }
  }
`;

const CardPlaceHolderContainer = styled.section`
  margin-top: 24px;
`;

const ClearCart = styled(SecondaryButton)`
  && {
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    height: 40px;
    width: initial;
    padding: 10px;
    .MuiButton-label {
      font-family: ${({ theme }) =>
        theme.dir === 'rtl' ? 'Assistant' : 'Roboto'};
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      color: #696969;
      gap: 8px;
    }
  }
`;
