import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import Icon from 'components/Icon';

interface Props {
  title: string | JSX.Element;
  iconName?: string;
}

const FormHeader: React.FC<Props> = ({
  title,
  iconName = 'location1',
  children,
}) => {
  return (
    <Header>
      {iconName && <CustomIcon size={14} name={iconName} />}
      <Title>{title}</Title>
      {children}
    </Header>
  );
};

export default FormHeader;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 20px 0;
`;

const CustomIcon = styled(Icon)`
  margin-bottom: 4px;
`;

const Title = styled(Typography)`
  font-size: 14px !important;
  margin-left: 5px !important;
  font-weight: bold !important;
`;
