import { Message } from 'i18n';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import MissingFloorPlanEN from 'assets/images/projects/missing_floor_plan_en.svg';
import MissingFloorPlanHE from 'assets/images/projects/missing_floor_plan_he.svg';
import IconButton, { IconName } from 'components/Button/IconButton';
import { TABLET_RESOLUTION } from 'styles/sharedStyle';

interface Props {
  key: number;
  img: string;
  buildingName: string;
  alt: string;
  type: string;
  associatedProperties: number;
  onClick?: VoidFunction;
  status?: string;
}

const TypesCard: React.FC<Props> = ({
  key,
  img,
  buildingName,
  alt,
  type,
  associatedProperties,
  onClick,
  status,
}) => {
  const intl = useIntl();

  const missingFloorPlanImage = React.useMemo(() => {
    return intl.locale === 'he' ? MissingFloorPlanHE : MissingFloorPlanEN;
  }, [intl.locale]);
  const [mouseDownPosition, setMouseDownPosition] = React.useState<number>();
  return (
    <>
      <MainContainer
        key={key}
        onClick={e => {
          if (e.clientX === mouseDownPosition) onClick?.();
        }}
        onMouseDown={e => {
          setMouseDownPosition(e.clientX);
        }}
      >
        <Image src={img ?? missingFloorPlanImage} alt={alt} />
        <DetailsContainer>
          <Title dir={intl.locale === 'he' ? 'rtl' : 'ltr'}>
            <FormattedMessage id={Message.BUILDING} />
            <span>{buildingName}</span>
          </Title>
          <Title dir={intl.locale === 'he' ? 'rtl' : 'ltr'}>
            <FormattedMessage id={Message.TYPE} />
            <span>{type}</span>
          </Title>
          <Title dir={intl.locale === 'he' ? 'rtl' : 'ltr'}>
            <FormattedMessage id={Message.STATUS_TEXT} />:
            <span>
              <FormattedMessage id={`client.project.unit.status_${status}`} />
            </span>
          </Title>
          <AssociateText dir={intl.locale === 'he' ? 'rtl' : 'ltr'}>
            <FormattedMessage id={Message.ASSOCIATED_PROPERTIES} />
            <span>{associatedProperties}</span>
          </AssociateText>
        </DetailsContainer>
        <div className="maincontainer-hover">
          <StyledIconButton
            customIcon="edit"
            $size="small"
            iconSize={16}
            iconName={IconName.custom_icon}
          />
          <EditText>
            <FormattedMessage id={Message.EDIT_UNIT} />
          </EditText>
        </div>
      </MainContainer>
    </>
  );
};

export default TypesCard;

const MainContainer = styled.div`
  margin-top: 32px;
  display: flex;
  display: flex;
  flex-direction: column;
  /* height: 270px; */
  width: 250px;
  box-shadow: 0px 0px 10px rgba(41, 41, 41, 0.1);
  border-radius: 5px;
  padding: 16px;
  position: relative;
  cursor: pointer;
  @media ${TABLET_RESOLUTION} {
    height: 239px;
    width: 217px;
  }
  .maincontainer-hover {
    display: none;
  }
  :hover {
    .maincontainer-hover {
      display: flex;
      background: linear-gradient(
        180deg,
        rgba(40, 41, 41, 0.21) 0%,
        rgba(40, 41, 41, 0.7) 63.33%
      );
      border: 2px solid #fc5c42;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 5px;
      top: 0;
      left: 0;
      text-align: center;
      align-items: center;
      justify-content: center;
      .MuiIconButton-label {
        svg {
          fill: rgba(255, 255, 255, 0.9);
        }
      }
    }
  }
`;

const Image = styled.img`
  width: 218px;
  height: 142px;
  object-fit: contain;
  @media ${TABLET_RESOLUTION} {
    width: 185px;
    height: 125px;
  }
`;

const DetailsContainer = styled.div`
  margin-top: 16px;
  gap: 8px;
  display: flex;
  flex-direction: column;
  @media ${TABLET_RESOLUTION} {
    margin-top: 8px;
  }
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #3e3e3e;
  span {
    margin: ${({ theme }) =>
      theme.dir === 'rtl'
        ? '0px 4px 0px 0px !important'
        : '0px 0px 0px 4px  !important'};
  }
`;

const AssociateText = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: #3e3e3e;
  span {
    margin: ${({ theme }) =>
      theme.dir === 'rtl'
        ? '0px 4px 0px 0px !important'
        : '0px 0px 0px 4px  !important'};
  }
`;

const EditText = styled.div`
  font-weight: ${({ theme }) => (theme.dir === 'rtl' ? 600 : 500)};
  font-size: 20px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.9);
`;

const StyledIconButton = styled(IconButton)``;
