import * as React from 'react';
import { Tooltip } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import styled from 'styled-components';
import Checkbox from 'components/Checkbox';

interface Props {
  title?: string;
  value: React.ReactNode;
  checked: boolean;
  onClick?: React.KeyboardEventHandler<HTMLButtonElement>;
}

const CheckboxItem: React.FC<Props> = ({
  title = '',
  value,
  checked,
  onClick,
}) => {
  return (
    <Container>
      <Checkbox
        className="checkbox-input"
        tabIndex={-1}
        onKeyPress={onClick}
        checked={checked}
      />
      {title ? (
        <Tooltip title={title}>
          <ListItemText primary={value} />
        </Tooltip>
      ) : (
        <ListItemText primary={value} />
      )}
    </Container>
  );
};

export default React.memo(CheckboxItem);

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 2px 8px;
  width: 100%;
`;
