enum ClientsDataType {
  GET_USERS_DATA_REQUEST = 'clients/getUsersDataRequest',
  GET_USERS_DATA_REJECTED = 'clients/getUsersDataRejected',
  GET_USERS_DATA_FULFILLED = 'clients/getUsersDataFulfilled',

  GET_ROOMS_DATA_REQUEST = 'clients/getRoomsDataRequest',
  GET_ROOMS_DATA_REJECTED = 'clients/getRoomsDataRejected',
  GET_ROOMS_DATA_FULFILLED = 'clients/getRoomsDataFulfilled',

  GET_USER_BASIC_DATA_REQUEST = 'clients/getUserDataRequest0',
  GET_USER_DATA_REQUEST = 'clients/getUserDataRequest1',
  GET_USER_DATA_REJECTED = 'clients/getUserDataRejected',
  GET_USER_DATA_FULFILLED = 'clients/getUserDataFulfilled',

  GET_PRODUCTS_DATA_REQUEST = 'clients/getProductsDataRequest',
  GET_PRODUCTS_DATA_REJECTED = 'clients/getProductsDataRejected',
  GET_PRODUCTS_DATA_FULFILLED = 'clients/getProductsDataFulfilled',

  GET_PROPERTIES_DATA_REQUEST = 'clients/getPropertiesDataRequest',
  GET_PROPERTIES_DATA_REJECTED = 'clients/getPropertiesDataRejected',
  GET_PROPERTIES_DATA_FULFILLED = 'clients/getPropertiesDataFulfilled',

  GET_QUIZ_RESULTS_DATA_REQUEST = 'clients/getQuizResultsDataRequest',
  GET_QUIZ_RESULTS_DATA_REJECTED = 'clients/getQuizResultsDataRejected',
  GET_QUIZ_RESULTS_DATA_FULFILLED = 'clients/getQuizResultsDataFulfilled',

  GET_CART_ITEM_DATA_REQUEST = 'clients/getCartItemDataRequest',
  GET_CART_ITEM_DATA_REJECTED = 'clients/getCartItemDataRejected',
  GET_CART_ITEM_DATA_FULFILLED = 'clients/getCartItemDataFulfilled',

  GET_USER_ACTIVITY_DATA_REQUEST = 'clients/getUserActivitiesDataRequest',
  GET_USER_ACTIVITY_DATA_REJECTED = 'clients/getUserActivitiesDataRejected',
  GET_USER_ACTIVITY_DATA_FULFILLED = 'clients/getUserActivitiesDataFulfilled',
}

export default ClientsDataType;
