import * as React from 'react';
import styled from 'styled-components';
import useConfirmPlan from 'pages/appClient/onboarding/confirm-plan/useConfirmPlan';
import { Actions, Floor } from 'pages/appClient/onboarding/shared';
import CheckVector from 'assets/@client/onboarding/CheckVector.png';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';

interface Props {
  next: VoidFunction;
  prev: (startOver?: boolean) => void;
  isLoading: boolean;
}

const ConfirmPlan: React.FC<Props> = ({ next, prev, isLoading }) => {
  const { selectedFloor, onClick } = useConfirmPlan(prev, next);

  return (
    <Container>
      <ImageContainer>
        <img src={CheckVector} alt="check" />
      </ImageContainer>
      <HeaderTitle>
        <FormattedMessage
          id={Message.ONBOARDING_PLAN_UPLOAD_SUCCESS}
          values={{
            span: (chunks: string) => (
              <>
                <br />
                <span className="title-text">{chunks}</span>
              </>
            ),
          }}
        />
      </HeaderTitle>
      <SelectedFloor>
        <img src={selectedFloor?.file} alt="Selected floor plan" />
      </SelectedFloor>

      <Actions
        nextProps={{ disabled: !selectedFloor, onClick, isLoading }}
        nextText={<FormattedMessage id={Message.FINISH} />}
      />
    </Container>
  );
};

export default ConfirmPlan;

const Container = styled.div`
  .actions-container {
    background: transparent;
    padding: 0;
    margin-top: 24px;
    @media (max-width: 800px) {
      position: relative !important;
      bottom: unset !important;
      margin-top: 32px;
    }
    button {
      font-size: 18px;
    }
  }

  .file-display {
    margin: 0 auto;
    width: max-content;
    padding: 3px;
  }
`;

const SelectedFloor = styled(Floor)`
  margin: 24px auto 0 auto;
  border-radius: 0;
  width: 386px;
  height: 331px;
  border: none;
  @media (max-width: 800px) {
    width: 311px;
    height: 301px;
  }
  img {
    border-radius: 0;
  }
`;

const ImageContainer = styled.div`
  width: min-content;
  margin: auto;
  margin-top: 24px;
  margin-bottom: 16px;
  width: 63px;
  height: 45px;
  @media (max-width: 800px) {
    margin-top: 46px;
    margin-bottom: 24px;
  }
  img {
    width: 100%;
    height: 100%;
  }
`;

const HeaderTitle = styled.div`
  text-align: center;
  font-weight: ${({ theme }) => (theme.dir === 'rtl' ? 600 : 500)};
  font-size: 28px;
  line-height: 32px;
  @media (max-width: 800px) {
    font-weight: 500;
    font-size: 18px;
  }
  span {
    color: #2cdd73;
  }
`;
