import { Notification } from 'store/constants/notifications.constants';
import { ShowNotificationPayload } from 'store/types/notification.types';

export const showNotification = (payload: ShowNotificationPayload) => ({
  type: Notification.SHOW_NOTIFICATION,
  payload,
});

export const closeNotification = (payload: string) => ({
  type: Notification.CLOSE_NOTIFICATION,
  payload,
});

export const resetNotifications = () => ({
  type: Notification.RESET_NOTIFICATIONS,
  payload: null,
});

export type NotificationAction = ReturnType<
  typeof showNotification | typeof closeNotification | typeof resetNotifications
>;
