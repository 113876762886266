import Facebook from './facebook.svg';
import Instagram from './instagram.png';
import LinkedIn from './linkedin.png';
import TikTok from './tiktok.png';

const socialIcons = [
  {
    src: Instagram,
    alt: 'Instagram',
    href: 'https://www.instagram.com/patricia.home.design/',
  },
  {
    src: Facebook,
    alt: 'Facebook',
    href: 'https://www.facebook.com/patricia.home.design',
  },
  {
    src: LinkedIn,
    alt: 'LinkedIn',
    href: 'https://www.linkedin.com/company/74855741',
  },
  {
    src: TikTok,
    alt: 'TikTok',
    href: 'https://www.patricia-ai.com/',
  },
];
export default socialIcons;
