import * as React from 'react';
import {
  AutocompleteCloseReason,
  AutocompleteRenderOptionState,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';
import Autocomplete from 'components/Autocomplete';
import DeleteIcon from 'components/DeleteIcon';
import Icon from 'components/Icon';
import useAutocomplete from 'hooks/useAutocomplete';
import { isEmpty, isString } from 'utils/lodash.utils';
import StyledTheme from 'styles/theme';
import { convertValueToContained } from 'utils/common.utils';
import type { Option } from 'components/Autocomplete/Autocomplete.config';
import type { ControlProps } from './types';
import Loader from '../Loader/Loader';

interface Props<T extends Option[]>
  extends ControlProps<T | Option[] | Option | string> {
  clearInputOnApply?: boolean;
  loading?: boolean;
  width?: number;
  allowedInput?: (val: string) => string;
  onChange?: (options: T | Option) => void;
  onSearchChanged?: (term: string) => void;
  renderOption: (
    props: React.HTMLAttributes<HTMLLIElement>,
    newValue: Option,
    state: AutocompleteRenderOptionState
  ) => JSX.Element;
  filterById?: boolean;
}

const AutocompleteFilter = <T extends Option[]>({
  clearInputOnApply,
  options,
  loading,
  renderOption,
  onApply,
  placeholder = 'Select',
  width = 260,
  allowedInput,
  onSearchChanged,
  filterById = false,
}: Props<T>) => {
  const [isOpen, setOpen] = React.useState(false);
  const autocompleteProps = useAutocomplete({
    options: options as Option[],
    renderOption,
    disableSelectAll: true,
    placeholder,
    allowedInput,
    setOpen,
    isOpen,
    width,
    loading,
  });
  const {
    inputValue,
    onInputClear,
    setInputValue,
    debouncedInputValue,
  } = autocompleteProps;

  const onClose = React.useCallback(
    (
      _event?: React.SyntheticEvent<Element, Event>,
      reason?: AutocompleteCloseReason
    ) => {
      setOpen(false);
      if (clearInputOnApply && reason === 'select-option') onInputClear();
    },
    [clearInputOnApply, onInputClear]
  );

  const handleKeyPress = React.useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter') {
        onClose();
        onApply(inputValue);
      }
    },
    [onClose, onApply, inputValue]
  );

  React.useEffect(() => {
    onSearchChanged?.(debouncedInputValue);
  }, [onSearchChanged, debouncedInputValue]);

  const getOptionLabel = React.useCallback(o => inputValue ?? o?.name ?? '', [
    inputValue,
  ]);

  const onClear = React.useCallback(() => {
    onInputClear();
    onApply('');
  }, [onApply, onInputClear]);

  return (
    <Autocomplete
      loading={loading}
      openOnFocus={false}
      open={isOpen}
      PaperComponent={CustomPaper}
      onClose={onClose}
      options={options as Option[]}
      {...autocompleteProps}
      onChange={(_e, value) => {
        const newInput =
          Object.values(value ?? {})?.find(
            (field: string | number | { name: string } | undefined) => {
              const fieldContainedValue = convertValueToContained(field);
              const inputContainedValue = convertValueToContained(
                inputValue
              ) as string;

              return filterById
                ? (isString(fieldContainedValue) &&
                    fieldContainedValue.includes(inputContainedValue)) ||
                    (field as number)
                : (isString(fieldContainedValue) &&
                    fieldContainedValue.includes(inputContainedValue)) ||
                    (field as { name: string })?.name;
            }
          ) ?? '';
        setInputValue(newInput);
        onApply?.(newInput);
      }}
      getOptionLabel={getOptionLabel}
      renderInput={params => (
        <CustomField
          placeholder={placeholder}
          {...params}
          ref={params.InputProps.ref}
          variant="outlined"
          onMouseDownCapture={e => e.stopPropagation()}
          onKeyPress={handleKeyPress}
          InputLabelProps={params.InputLabelProps}
          InputProps={{
            ...params.InputProps,
            className: 'search-input',
            notched: false,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: loading ? (
              <Loader size={25} />
            ) : (
              !isEmpty(inputValue) && (
                <InputAdornment position="end">
                  <DeleteIcon onClick={onClear} />
                </InputAdornment>
              )
            ),
            style: {
              margin: 0,
              outline: 'none !important',
              backgroundColor: 'white',
              fontSize: '0.875rem',
              fontWeight: 400,
              height: 48,
              maxWidth: 325,
              minWidth: width,
              width: 'auto',
              borderRadius: 8,
              border: `1px solid ${StyledTheme.colors.darkGray}`,
              color: StyledTheme.colors.gray,
              padding: '0 10px',
              cursor: 'pointer',
            },
            classes: {
              notchedOutline: 'input',
              focused: 'focus',
              input: 'input',
            },
          }}
          fullWidth
        />
      )}
    />
  );
};

export default AutocompleteFilter;

const CustomField = styled(TextField)`
  && .input {
    color: ${({ theme }) => theme.colors.primary.primaryTextColor};
    border-color: transparent;
  }

  && ::placeholder {
    color: #3b3b3b;
  }
  && .focus {
    border-color: ${({ theme }) => theme.colors.primary.dark} !important;
  }

  && .MuiInputAdornment-positionStart {
    margin-right: 3px;
  }

  && .MuiAutocomplete-endAdornment {
    display: contents;
  }
`;

const CustomPaper = styled(Paper)`
  && {
    box-shadow: none !important;
    margin: 4px;
    color: #586069;
    font-size: 0.875rem;
    position: relative;
    border: none;
    border-radius: 10px;
  }
`;

const SearchIcon = styled(Icon).attrs(({ theme }) => ({
  name: 'search',
  size: 16.7,
  color: theme.colors.primary.dark,
}))`
  margin-right: 8px;
  margin-left: 4px;
`;
