import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, useMediaQuery } from '@material-ui/core';
import styled, { css } from 'styled-components';
import { Message } from 'i18n';
import { useBreakpoint } from 'hooks';
import { MOBILE_RESOLUTION } from 'styles/sharedStyle';
import type { AuthContext } from 'pages/auth/providers/types';

interface Props {
  icon: string;
  provider: string;
  authContext?: AuthContext;
  forceLabel?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}
const min = { w: 16, h: 16 };
const max = { w: 25, h: 25 };
const ProviderButton: React.FC<Props> = ({
  icon,
  provider,
  authContext = 'Register',
  forceLabel = false,
  onClick,
}) => {
  const isMobile = useMediaQuery(MOBILE_RESOLUTION);
  const { height } = useBreakpoint(min, max);
  return (
    <CustomButton
      className={`provider-button ${provider}`}
      key={provider}
      variant="contained"
      color="inherit"
      type="button"
      $forceLabel={forceLabel}
      onClick={onClick}
    >
      <img
        src={icon}
        alt={`${authContext} with ${provider}`}
        height={isMobile || forceLabel ? '20' : height}
      />
      {(!isMobile || forceLabel) && (
        <span>
          <FormattedMessage
            id={Message.AUTH_PROVIDERS_BUTTON}
            values={{
              authContext: authContext === 'Login' ? 'Sign in' : 'Sign up',
              provider,
            }}
          />
        </span>
      )}
    </CustomButton>
  );
};

export default ProviderButton;

const CustomButton = styled(Button)<{ $forceLabel?: boolean }>`
  && {
    color: ${({ theme }) => theme.colors.primary.primaryTextColor};
    background: #ffffff;
    box-shadow: 0px 2px 4px #00000029;
    text-transform: none;
    border-radius: 6px;
    padding: 15px;
    max-height: 53px;
    min-width: 240px;
    font-size: 1rem;
    cursor: pointer !important;

    @media (max-width: 1500px) {
      padding: 15px 20px;
      min-width: 200px;
    }

    @media (max-width: 500px) {
      ${({ theme }) => theme.utils.centerFlex};

      ${({ $forceLabel }) =>
        !$forceLabel &&
        css`
          max-height: 50px;
          min-width: 50px;
          width: 50px !important;
          height: 50px !important;
        `}
    }
  }

  span {
    display: flex;
    gap: 27px;
    justify-content: flex-start;

    @media (max-width: 500px) {
      ${({ theme }) => theme.utils.centerFlex};
    }
  }

  @media (max-width: 1500px) {
    span {
      gap: 27px;
      font-size: 0.75rem;
    }
  }
`;
