import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { QuizQuestion } from 'api/responses';
import { Message } from 'i18n';
import { useMediaQuery } from '@material-ui/core';
import { MOBILE_RESOLUTION } from 'styles/sharedStyle';
import { useOnboarding } from 'context/onboardingContext';

const QuestionLabel: React.FC<{ question: QuizQuestion | null }> = ({
  question,
}) => {
  const isDefaultBudgetQuestion = question?.question?.includes('budget');
  const isDefaultColorQuestion = question?.question?.includes('color palette');
  const isDefaultRoomQuestion = question?.question?.includes('rooms');
  const isPanoramaQuestion = question?.question?.toLowerCase().includes('view');

  const isMobile = useMediaQuery(MOBILE_RESOLUTION);
  const [{ choosePlan, isScan }, _] = useOnboarding();

  return (
    <Container>
      {isDefaultBudgetQuestion ||
      isDefaultColorQuestion ||
      isPanoramaQuestion ||
      isDefaultRoomQuestion ? (
        <>
          {isDefaultBudgetQuestion && (
            <FormattedMessage
              id={Message.UPLOAD_BUDGET_QUESTION}
              values={{
                span: (chunks: string) => (
                  <span className="title-text">{chunks}</span>
                ),
              }}
            />
          )}
          {isDefaultColorQuestion && (
            <FormattedMessage
              id={Message.UPLOAD_COLOR_QUESTION}
              values={{
                span: (chunks: string) => (
                  <span className="title-text">{chunks}</span>
                ),
              }}
            />
          )}
          {isPanoramaQuestion && (
            <FormattedMessage
              id={Message.ONBOARDING_QUIZ_PANORAMA_QUESTION}
              values={{
                span: (chunks: string) => (
                  <span className="title-text">{chunks}</span>
                ),
              }}
            />
          )}
          {isDefaultRoomQuestion && (
            <FormattedMessage
              id={
                isScan
                  ? Message.UPLOAD_ROOM_QUESTION
                  : Message.UPLOAD_ROOMS_QUESTION
              }
              values={{
                span: (chunks: string) => (
                  <span className="title-text">{chunks}</span>
                ),
                linebreak: isMobile ? <br /> : ' ',
              }}
            />
          )}
        </>
      ) : (
        <FormattedMessage
          id={
            choosePlan
              ? Message.DESIGN_STYLES_QUESTION
              : Message.DESIGN_STYLES_QUESTION
          }
          values={{
            span: (chunks: string) => (
              <span className="title-text">{chunks}</span>
            ),
            linebreak: isMobile ? <br /> : ' ',
          }}
        />
      )}
    </Container>
  );
};

const Container = styled.p`
  @media (min-width: 767px) and (max-height: 1050px) {
    margin-top: -60px !important;
  }
  margin-top: 40px;
  margin-bottom: 0;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 34px !important;
  line-height: 40px;
  color: #282929;
  span {
    color: ${({ theme }) => theme.colors.primary.lightMobile};
  }
  @media (min-width: 1150px) {
    font-size: 1.625rem;
  }
  @media (max-width: 767px) {
    font-weight: 500;
    font-size: 22px !important;
    line-height: 26px;
  }
`;

export default QuestionLabel;
