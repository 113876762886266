import { useMediaQuery } from '@material-ui/core';
import { TABLET_RESOLUTION, ClientRoot } from 'styles/sharedStyle';
import * as deviceInfo from 'react-device-detect';
import styled from 'styled-components';
import { IdeasDetails } from 'types/products';
import MobileIdea from './MobileIdeas';
import DesktopIdeas from './DesktopIdeas';

const IdeasDetail: React.FC<{ location: { state: IdeasDetails } }> = ({
  location,
}) => {
  const isMobile = useMediaQuery(TABLET_RESOLUTION) || !deviceInfo.isDesktop;
  return (
    <CustomRoot>
      {isMobile ? (
        <MobileIdea idea={location.state} />
      ) : (
        <DesktopIdeas idea={location.state} />
      )}
    </CustomRoot>
  );
};

const CustomRoot = styled(ClientRoot)`
  max-width: 1650px;
  margin: auto;
  @media ${TABLET_RESOLUTION} {
    padding: 5px;
  }
`;

export default IdeasDetail;
