import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Message } from 'i18n';
import {
  MOBILE_RESOLUTION,
  MOBILE_TABLET_RESOLUTION,
} from 'styles/sharedStyle';
import Button from 'components/@client/Button';
import {
  Setter,
  SupportedCountry,
  SupportedLanguage,
} from 'types/common.types';
import { ModalName } from 'pages/common/Unity/config';
import CreditCardProviders from 'components/@client/CreditCardProviders';
import Loader from 'components/Loader/Loader';
import {
  useFormattedCurrency,
  useSession,
  useProductCoin,
  useAuthentication,
  useNotifications,
  useFetch,
  useRouter,
} from 'hooks';
import CloseButton from 'components/Modal/CloseButton';
import { METHODS } from 'api/client';
import { removeEmptyValues } from 'utils/common.utils';
import CoinList from './CoinList';
import PaymentModal from './PaymentModal';

interface Props {
  justifyContent?: string;
  changeModal?: Setter<ModalName | null>;
}

const BuyMorePatriciaCoinsDetail: React.FC<Props> = ({
  justifyContent,
  changeModal,
}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const { id } = useAuthentication();
  const { locale, country } = useSession();
  const { showNotification } = useNotifications();
  const [stripeCredentials, setStripeCredentials] = React.useState<{
    publicKey: string;
    clientKey: string;
  }>({ publicKey: '', clientKey: '' });
  const { fullName, email, phoneNumber } = useAuthentication();
  const {
    productCoinList,
    isLoading,
    callFetch,
    onQuantityChange,
    defaultSelected,
    onSelectChange,
    orderSummary,
    deliveryDate,
    selectedCoins,
  } = useProductCoin(true);

  const {
    data,
    callFetch: getHandshakeToken,
    isLoading: isCheckoutLoading,
  } = useFetch<{ hs: string }>({
    initialUrl: '/api/payment/handshake/coin_checkout/',

    onSuccess: () => setOpen(true),
    skipOnStart: true,
  });

  React.useEffect(() => {
    callFetch();
  }, [callFetch]);

  const price = useFormattedCurrency(orderSummary.subTotal, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency: 'USD',
  });
  const shippingAmount = useFormattedCurrency(orderSummary.shipping, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency: 'USD',
  });
  const vatAmount = useFormattedCurrency(orderSummary.vat, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency: 'USD',
  });
  const totalAmount = useFormattedCurrency(orderSummary.finalTotal, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    currency: 'USD',
  });

  const {
    query: { skinId },
  } = useRouter<{ cartId: string }>();

  const onSuccess = React.useCallback(
    res => {
      showNotification({
        key: 'clients/stripePaymentSuccess',
        message: 'Payment initiated successfully',
        severity: 'success',
      });
      setStripeCredentials({
        publicKey: res.publicKey,
        clientKey: res.clientSecret,
      });
      setOpen(true);
    },
    [showNotification, setStripeCredentials]
  );

  const onFailure = React.useCallback(() => {
    showNotification({
      key: 'clients/stripePaymentFailure',
      message: 'error in initiating payment',
      severity: 'error',
    });
  }, [showNotification]);

  const { callFetch: stripePayment, isLoading: stripeIsLoading } = useFetch({
    initialUrl: '/api/payment/create-stripe-checkout-session/',
    skipOnStart: true,
    config: {
      method: METHODS.POST,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    },
    onSuccess,
    onFailure,
  });

  const { id: userId } = useAuthentication();

  const handleStripeSubmit = React.useCallback(() => {
    return stripePayment({
      data: removeEmptyValues({
        amount: 1100,
        currency: 'usd',
        description: 'Data about purchase',
        user_id: userId,
        trBgColor: 'fcfcfc',
        cred_type: 1,
        nologo: 1,
        index: 11,
        lang: locale === SupportedLanguage.HEBREW ? 'il' : 'us',
        trButtonColor: 'FF3100',
        mobile: phoneNumber,
        contact: fullName,
        email: email ?? '',
        u71: 1,
        tranmode: 'A',
        tour_section: 1,
        skin_id: skinId,
        coins: selectedCoins?.id,
        new_process: 1,
        Ilang: country === 'IL' ? 'heb' : 'eng',
        coin_product: JSON.stringify(selectedCoins),
        product_price: 11,
        product_quantity: 1,
      }),
    });
  }, [
    stripePayment,
    userId,
    country,
    locale,
    skinId,
    selectedCoins,
    phoneNumber,
    fullName,
    email,
  ]);

  const onProccess = React.useCallback(() => {
    getHandshakeToken({
      method: METHODS.POST,
      data: {
        coin_product: selectedCoins?.id,
        quantity: selectedCoins?.quantity,
      },
    });
  }, [getHandshakeToken, selectedCoins?.id, selectedCoins?.quantity]);
  const { data: userCoinData } = useFetch<{ coins: number }>({
    initialUrl: `/api/users/${id}`,
  });
  return (
    <>
      <Container>
        {changeModal && (
          <ModalCloseButton
            onClick={() => {
              changeModal(null);
            }}
            className="close-button"
          />
        )}
        <TopRow display="flex" justify={justifyContent ?? 'space-between'}>
          <Title>
            <FormattedMessage
              id={Message.BUY_PATRICIA_COINS_TITLE}
              values={{
                name: fullName,
                count: (
                  <span className="count">{userCoinData?.coins} Coins</span>
                ),
              }}
            />
          </Title>
        </TopRow>
        <Stack>
          <LeftSection>
            {isLoading ? (
              <Loader />
            ) : (
              <CoinList
                data={productCoinList ?? []}
                onQuantityChange={onQuantityChange}
                defaultSelected={defaultSelected}
                onSelectChange={onSelectChange}
              />
            )}
          </LeftSection>
          <RightSection>
            <OrderSummaryContainer>
              <SubTitle>
                <FormattedMessage id={Message.ORDER_SUMMARY} tagName="span" />
              </SubTitle>
              <Row className="subtotal-text">
                <Col>
                  <FormattedMessage
                    id={
                      country === SupportedCountry.USA
                        ? Message.BUYCOIN_SUB_TOTAL_LABEL
                        : Message.CHECKOUT_TOTAL_LABEL
                    }
                    tagName="span"
                  />
                </Col>
                <Col weight="500" text="uppercase">
                  {price}
                </Col>
              </Row>
              <Row className="shipping-text">
                <Col>
                  <FormattedMessage
                    id={Message.CHECKOUT_SHIPPING_LABEL}
                    tagName="span"
                  />
                </Col>
                <Col>{shippingAmount}</Col>
              </Row>
              <Row className="vat-text">
                <Col>
                  <FormattedMessage
                    id={
                      country === SupportedCountry.ISRAEL
                        ? Message.CHECKOUT_VAT_LABEL
                        : Message.CHECKOUT_TAX_LABEL
                    }
                    tagName="span"
                    values={{
                      value:
                        country === SupportedCountry.ISRAEL ? '17%' : '10%',
                    }}
                  />
                </Col>
                <Col>{vatAmount}</Col>
              </Row>
              <StyledHr className="card-divider" />
              <Row>
                <Col weight="bold" fontSize="22px">
                  <FormattedMessage
                    id={Message.CHECKOUT_TOTAL_LABEL}
                    tagName="span"
                  />
                </Col>
                <Col weight="bold" fontSize="22px">
                  {totalAmount}
                </Col>
              </Row>
              <StyledHr className="card-divider-end" />
              <Row justify="end" align="end">
                <CheckoutButton
                  className="checkout-button"
                  disabled={
                    orderSummary.subTotal === 0 || orderSummary.finalTotal === 0
                  }
                  isLoading={isCheckoutLoading || stripeIsLoading}
                  onClick={country ? onProccess : handleStripeSubmit}
                >
                  {}
                  <FormattedMessage id={Message.CHECKOUT_PROCEED_BUTTON} />
                </CheckoutButton>
              </Row>
              <Row justify="center" align="center">
                <ArticleImage>
                  <CreditCardProviders />
                </ArticleImage>
              </Row>
            </OrderSummaryContainer>
          </RightSection>
        </Stack>
      </Container>
      <PaymentModal
        deliveryDate={deliveryDate}
        coins={selectedCoins}
        publicKey={stripeCredentials.publicKey}
        clientSecretKey={stripeCredentials.clientKey}
        personalDetail={{
          name: fullName,
          email: email ?? '',
          phoneNumber: phoneNumber ?? '',
        }}
        hs={data?.hs}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
};

const Container = styled.section`
  margin-top: 24px;
  width: 100%;
`;

const TopRow = styled.div<{
  justify?: string;
  align?: string;
  display?: string;
}>`
  display: ${({ display }) => (!display ? 'inline-flex' : display)};
  gap: 10px;
  justify-content: ${({ justify }) => (!justify ? 'space-between' : justify)};
  align-items: ${({ align }) => (!align ? 'center' : align)};
`;

const Title = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #282929;
  .count {
    color: #fc5c42;
  }
`;

const Stack = styled.div`
  display: flex;
  gap: 30px;
  margin-top: 48px;
`;

const LeftSection = styled.div`
  @media ${MOBILE_TABLET_RESOLUTION} {
    width: 100%;
  }
  width: 50%;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 33px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: 100%;
  padding-bottom: 48px;
`;

const RightSection = styled.div`
  width: 50%;
  @media ${MOBILE_TABLET_RESOLUTION} {
    width: 100%;
  }
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 33px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: 100%;
  padding-bottom: 48px;
`;

const OrderSummaryContainer = styled.section`
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 0 24px 0 24px;

  .subtotal-text {
    margin-top: 48px;
  }
  .shipping-text,
  .vat-text {
    margin-top: 14px;
  }
  .total-price {
    font-weight: 700;
  }
  .card-divider {
    height: 0.3px;
    border-top: none;
    margin: 48px 0 24px 0;
    max-width: initial;
    background: #a99997;
  }
  .card-divider-end {
    height: 0.3px;
    border-top: none;
    margin: 24px 0 24px 0;
    max-width: initial;
    background: #a99997;
  }
  p {
    max-width: 500px;
    margin: 0 0 10px 0;

    display: flex;
    justify-content: space-between;
  }

  .out-of-stock-caption {
    color: ${({ theme }) => theme.colors.status.error};
    margin-top: 1rem;
    font-size: 0.75rem;
    order: 2;
    text-align: center;

    @media (min-width: 1150px) {
      order: 1;
    }
  }
`;

const SubTitle = styled.span`
  font-family: ${({ theme }) => (theme.dir === 'rtl' ? 'Assistant' : 'Roboto')};
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 43px;
  color: #282929;
  @media ${MOBILE_RESOLUTION} {
    font-size: 16px;
  }
  padding-top: 48px;
`;

const CheckoutButton = styled(Button)`
  background: #fc5c42;
  border: 1px solid #fc5c42;
  box-sizing: border-box;
  border-radius: 15px;
  .MuiButton-label {
    padding: 12px 36px 12px 36px;
    font-family: 'Assistant';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.9);
  }
`;

const Row = styled.div<{
  justify?: string;
  align?: string;
  display?: string;
}>`
  display: ${({ display }) => (!display ? 'inline-flex' : display)};
  gap: 10px;
  justify-content: ${({ justify }) => (!justify ? 'space-between' : justify)};
  align-items: ${({ align }) => (!align ? 'center' : align)};
`;

const Col = styled.div<{
  text?: string;
  weight?: string;
  fontSize?: string;
  color?: string;
}>`
  font-family: ${({ theme }) => (theme.dir === 'rtl' ? 'Assistant' : 'Roboto')};
  font-style: normal;
  font-weight: ${({ weight }) => (!weight ? 'normal' : weight)};
  font-size: ${({ fontSize }) => (!fontSize ? '18px' : fontSize)};
  line-height: 43px;
  text-transform: ${({ text }) => text};
  color: ${({ color }) => (!color ? '#000000' : color)};
`;

const StyledHr = styled.hr`
  height: 0.3px;
  border-top: none;
  margin: 32px 0;
  max-width: 550px;

  @media ${MOBILE_TABLET_RESOLUTION} {
    margin: 19px 0;
  }
`;

const ArticleImage = styled.div`
  margin-top: 19px;
`;

const ModalCloseButton = styled(CloseButton)`
  && {
    position: absolute;
    right: 24px;
    top: 24px;
  }
`;

export default BuyMorePatriciaCoinsDetail;
