import * as React from 'react';
import { useFetch, useNotifications } from 'hooks';
import { METHODS } from 'api/client';
import { BaseUserResponse } from 'types/common.types';

const useUpdateUser = (
  id: number,
  updateUserAction: (data: BaseUserResponse) => void
) => {
  const { showNotification, notifications } = useNotifications();
  const onSuccess = React.useCallback(
    data => {
      showNotification({
        key: 'clients/editUserFulfilled',
        message: 'Your info has been updated successfully.',
        severity: 'success',
      });
      updateUserAction(data as BaseUserResponse);
    },
    [showNotification, updateUserAction]
  );
  const onFailure = React.useCallback(
    (message: string) => {
      showNotification({
        key: 'clients/editUserRejected',
        message,
        severity: 'error',
      });
    },
    [showNotification]
  );
  const { callFetch: updateUser, isLoading } = useFetch({
    initialUrl: `/api/users/${id}/`,
    config: { method: METHODS.PATCH },
    skipOnStart: true,
    onSuccess,
    onFailure,
  });

  return {
    updateUser,
    isLoading,
    successNotification: notifications['clients/editUserFulfilled'],
  };
};

export default useUpdateUser;
