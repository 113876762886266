import * as React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import illustration from 'assets/@client/checkout/success-illustration.svg';
import Button from 'components/@client/Button';
import { useFetch, useRouter } from 'hooks';
import { Message } from 'i18n';
import { ClientRouter } from 'router/routes';
import { Orders } from 'api/responses';
import { LogsAPI } from 'api/methods';

const Success = () => {
  const { pathname } = useRouter();
  React.useEffect(() => {
    if (window.top.location.pathname !== pathname)
      window.top.location.pathname = ClientRouter.CHECKOUT_SUCCESS;
  }, [pathname]);

  const { callFetch } = useFetch({
    skipOnStart: true,
    config: {
      isGuestAllowed: true,
    },
    initialUrl: `/api/orders/?size=1`,
    onSuccess: (data: Orders | null) => {
      if (data?.results[0])
        LogsAPI.pushDataLayer([
          { ecommerce: null },
          {
            ecommerce: {
              purchase: {
                actionField: {
                  id: data?.results[0].transaction_id,
                  affiliation: 'Online Store',
                  revenue: data?.results[0].total_price,
                  tax: data?.results[0].vat,
                  shipping: 0,
                },
                products: data?.results[0].order_details.map(e => ({
                  name: e.product.english_name,
                  id: e.product.id,
                  price: e.product.price,
                  brand: e.product.manufacturer?.name,
                  category: e.product.sub_category?.name,
                  quantity: e.quantity,
                })),
              },
            },
          },
        ]);
    },
  });

  React.useEffect(() => {
    callFetch();
  }, [callFetch]);

  return (
    <Container>
      <Content>
        <FormattedMessage id={Message.CHECKOUT_SUCCESS_CAPTION} tagName="p" />
        <img
          src={illustration}
          alt="A cardboard box being carried through the clouds by a bunch of multicolor balloons."
        />
      </Content>
      <Link className="orders-link" to={ClientRouter.ORDERS}>
        <Button className="orders-button" fullWidth>
          <FormattedMessage id={Message.CHECKOUT_SUCCESS_BUTTON} />
        </Button>
      </Link>
    </Container>
  );
};

export default Success;

const Container = styled.section`
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .orders-link {
    text-decoration: none;
    width: 100%;
    text-align: center;

    & > .orders-button {
      border-radius: 12px;
      max-width: 350px;
      margin: 0 auto;
    }
  }
`;

const Content = styled.figure`
  margin: 0 auto;
  text-align: center;
  & p {
    margin: 20px auto;
    max-width: 25ch;
    text-align: center;
    color: rgba(66, 66, 66, 1);
    font-size: 1.25rem;
    font-weight: 500;

    @media (min-width: 800px) {
      max-width: 35ch;
    }
  }

  img {
    text-align: center;
    margin: 20px auto;
    max-width: 100%;

    @media (max-width: 500px) {
      max-width: 110%;
    }
  }
`;
