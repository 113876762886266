import * as React from 'react';
import { ProjectInformationType, Setter } from 'types/common.types';
import CloseIcon from '@material-ui/icons/Close';
import styled, { css } from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import { Autocomplete, TextField, Button } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { Message } from 'i18n';
import { ReactComponent as UploadIcon } from 'assets/@client/supplier/cloud-upload-btn.svg';
import { ReactComponent as UploadDoneIcon } from 'assets/@client/supplier/cloud-upload-done.svg';
import TextInput from 'components/@client/form-components/TextInput';
import useProjectInformation from 'hooks/useProjectInformation';
import { FormProvider } from 'react-hook-form';
import Loader from 'components/Loader/Loader';
import Icon from 'components/Icon/Icon';
import { METHODS } from 'api/client';
import rtl from 'styled-components-rtl';
import { useFetch, useNotifications, useRouter } from 'hooks';
import { developer } from 'types/properties';
import { ProjectDetail } from 'types/projects';

import { MOBILE_RESOLUTION } from 'styles/sharedStyle';

const EditProjectForm: React.FC<{
  setIsOpenEditProjectForm: Setter<boolean>;
  datas: ProjectDetail | null;
  getProjectDetails: VoidFunction;
}> = ({ setIsOpenEditProjectForm, datas, getProjectDetails }) => {
  const {
    methods,
    handleEdit,
    image,
    csv,
    searchFlag,
    developer: developerObj,
    onAddressesUpdate,
    isEditSuccess,
    isLoadingEdit,
    setImage,
    setCSV,
  } = useProjectInformation(datas);

  const { query } = useRouter<{
    id: string;
    projectId: string;
  }>();
  const [selectAddress, setSelectedAddress] = React.useState<{
    label: string | number | undefined;
    value?: string | number | undefined;
  } | null>({
    label: datas?.developerDetails?.name,
    value: datas?.developerDetails?.id,
  });
  const intl = useIntl();
  const [searchValue, setSearchValue] = React.useState('');
  const { control, handleSubmit, getValues, formState } = methods;
  const { isValid } = formState;
  const [developerLogo, setDeveloperLogo] = React.useState<File | null>(null);

  React.useEffect(() => {
    if (isEditSuccess) {
      setIsOpenEditProjectForm(false);
      getProjectDetails();
    }
  }, [getProjectDetails, isEditSuccess, setIsOpenEditProjectForm]);

  React.useEffect(() => {
    if (searchFlag === true) {
      setSelectedAddress(developerObj);
    } else {
      setSelectedAddress({
        label: datas?.developerDetails?.name,
        value: datas?.developerDetails?.id,
      });
    }
  }, [developerObj, datas, searchFlag]);
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const csvInputRef = React.useRef<HTMLInputElement>(null);
  const developerLogoInputRef = React.useRef<HTMLInputElement>(null);

  const { showNotification } = useNotifications();
  const onFailure = React.useCallback(() => {
    showNotification({
      key: 'properties/getPropertiesDataRejected',
      message:
        'We had a problem fetching your projects, please try again later',
      severity: 'error',
    });
  }, [showNotification]);
  const { data: developers, callFetch: serchList } = useFetch<developer[]>({
    initialUrl: `api/developers/`,
    onFailure,
  });

  const onFailures = React.useCallback(() => {
    showNotification({
      key: 'properties/getPropertiesDataRejected',
      message:
        'We had a problem fetching your developer, please try again later',
      severity: 'error',
    });
  }, [showNotification]);
  const [
    callSubmitOnSuccess,
    setCallSubmitOnSuccess,
  ] = React.useState<ProjectInformationType | null>(null);
  const onSearchSuccess = React.useCallback(
    (data: { id: string; name: string } | null) => {
      serchList();
      onAddressesUpdate('', { label: data?.name, value: data?.id });
      setSelectedAddress({ label: data?.name, value: data?.id });
      if (callSubmitOnSuccess !== null) {
        handleEdit(callSubmitOnSuccess);
      }
    },
    [callSubmitOnSuccess, handleEdit, onAddressesUpdate, serchList]
  );

  const { callFetch: addDevelopers, isLoading: isDevelopersLoading } = useFetch(
    {
      initialUrl: 'api/developers/',
      skipOnStart: true,
      onSuccess: onSearchSuccess,
      onFailure: onFailures,
    }
  );
  const onSubmit = React.useCallback(
    (payload: ProjectInformationType) => {
      if (developerLogo) {
        const developerData = new FormData();
        // developerData.append('id', developerObj.value?.toString() ?? '');
        developerData.append('logo', developerLogo as Blob);
        addDevelopers({
          url: `/api/developers/${
            developerObj.value ?? datas?.developerDetails?.id
          }/`,
          method: METHODS.PATCH,
          data: developerData,
        });
        setCallSubmitOnSuccess({ ...payload, id: query?.projectId });
      } else {
        handleEdit({ ...payload, id: query?.projectId });
      }
    },

    [
      addDevelopers,
      datas?.developerDetails?.id,
      developerLogo,
      developerObj?.value,
      handleEdit,
      query?.projectId,
    ]
  );
  const submitForm = handleSubmit(onSubmit);

  const addDeveloper = () => {
    addDevelopers({
      url: 'api/developers/',
      method: METHODS.POST,
      data: {
        name: searchValue,
      },
    });
  };

  const addressOptionList =
    developers?.map(e => ({
      label: e.name,
      value: e.id,
    })) ?? [];

  const addressOptions = [...addressOptionList];

  return (
    <MainContainer>
      <CrossIcon onClick={() => setIsOpenEditProjectForm(false)}>
        <CloseIcon />
      </CrossIcon>
      <DetailsContainer>
        <FormTitle>
          <FormattedMessage id={Message.PEROJECT_DETAILS} />
        </FormTitle>
      </DetailsContainer>
      <input
        type="file"
        className="uploadFocus"
        id="fileTestinput1"
        accept="image/png, image/jpeg, image/jpg"
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const fileList = e.target.files;
          if (!fileList) return;
          setImage(fileList[0]);
        }}
      />
      <input
        type="file"
        style={{ display: 'none' }}
        ref={csvInputRef}
        accept=".csv, application/vnd.ms-excel"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const fileList = e.target.files;
          if (!fileList) return;
          setCSV(fileList[0]);
        }}
      />
      <input
        type="file"
        style={{ display: 'none' }}
        ref={developerLogoInputRef}
        accept="image/png, image/jpeg, image/jpg"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const fileList = e.target.files;
          if (!fileList) return;
          setDeveloperLogo(fileList[0]);
        }}
      />
      <FormProvider {...methods}>
        <form className="sign-up-form" onSubmit={submitForm}>
          <div className="row">
            <div className="form-group first-name project-input-width">
              <TextInput
                name="name"
                control={control}
                label={intl.formatMessage({ id: Message.PROJECT_NAME })}
                required
              />
            </div>
            <CoverPhoto>
              <FormattedMessage id={Message.DEVELOPER_NAME_FORM} />
            </CoverPhoto>
            <AutocompleteComponent
              sx={{ width: 100 }}
              classes={{ paper: 'paper-class' }}
              ListboxProps={{ style: { maxHeight: '10rem' } }}
              onInputChange={(_, value) => {
                setSearchValue(value);
              }}
              noOptionsText={
                <div className="d-flex align-items-center justify-content-between mt-2 mainClass">
                  <div className="mainClass2">
                    <span className="fw-bold without-error">
                      {intl.formatMessage({ id: Message.NO_RESULTS })}{' '}
                    </span>
                    "<span className="error">{searchValue}</span>"
                  </div>
                  <span
                    className="add-developer justify-content-center"
                    onClick={() => addDeveloper()}
                  >
                    <Icon name="plus" className="icon-button" color="#5c95ff" />
                    {intl.formatMessage({
                      id: Message.ADD_NEW_DEVELOPER,
                    })}
                  </span>
                </div>
              }
              value={selectAddress}
              onChange={onAddressesUpdate}
              disablePortal
              options={addressOptions}
              renderInput={params => <TextField {...params} />}
              renderOption={(
                props: React.HTMLAttributes<HTMLLIElement>,
                option: unknown,
                _state
              ) => (
                <>
                  <li
                    {...props}
                    key={
                      (option as {
                        label: string;
                        value: string | number;
                      })?.value
                    }
                  >
                    {
                      (option as {
                        label: string;
                        value: string | number;
                      }).label
                    }
                  </li>
                </>
              )}
            />
          </div>

          <CSVFile>Developer Logo</CSVFile>
          <div className="upload-file">
            <div className="upload-btn-container">
              <div className="upload-floor-plan">Upload Logo</div>
              <UploadButton
                isImageSelected={Boolean(developerLogo)}
                className="upload-floor-btn"
                onClick={() => developerLogoInputRef.current?.click()}
              >
                {developerLogo ? <UploadDoneIcon /> : <UploadIcon />}
                <FormattedMessage
                  id={
                    developerLogo
                      ? Message.REPLACE_FILE
                      : Message.LANDING_PAGE_CHOOSE_FILE_BUTTON
                  }
                />
              </UploadButton>
            </div>
          </div>

          <div className="row">
            <div className="form-group email-wrap phone project-input-width">
              <TextInput
                name="number"
                control={control}
                label={intl.formatMessage({ id: Message.PROJECT_NUMBER })}
                required
              />
            </div>
          </div>

          <div className="row address">
            <div className="form-group project-address form-email project-input-width">
              <TextInput
                name="address"
                control={control}
                label={intl.formatMessage({
                  id: Message.PROJECT_ADDRESS,
                })}
                type="text"
                required
              />
            </div>
            <div className="form-group project-city form-email project-input-width">
              <TextInput
                name="city"
                control={control}
                label={intl.formatMessage({
                  id: Message.ADDRESS_FORM_CITY,
                })}
                type="text"
                required
              />
            </div>
          </div>
          <CoverPhoto>
            <FormattedMessage id={Message.COVER_PHOTO} />
          </CoverPhoto>
          <div className="upload-file">
            <div className="upload-btn-container">
              <div className="upload-floor-plan">
                <FormattedMessage id={Message.UPLOAD_COVER_PHOTO} />
              </div>
              <UploadButton
                isImageSelected={Boolean(image || datas?.coverPhoto)}
                className="upload-floor-btn"
                onClick={() => fileInputRef.current?.click()}
              >
                {image || datas?.coverPhoto ? (
                  <UploadDoneIcon />
                ) : (
                  <UploadIcon />
                )}
                <FormattedMessage
                  id={
                    image || datas?.coverPhoto
                      ? Message.REPLACE_FILE
                      : Message.LANDING_PAGE_CHOOSE_FILE_BUTTON
                  }
                />
              </UploadButton>
            </div>
            <CSVFile>
              <FormattedMessage id={Message.CSV_FILE} />
            </CSVFile>
            <div className="upload-file">
              <div className="upload-btn-container">
                <div className="upload-floor-plan">
                  <FormattedMessage id={Message.UPLOAD_MATRIX_FILE} />
                </div>
                <UploadButton
                  isImageSelected={Boolean(csv || datas?.csvStandart)}
                  className="upload-floor-btn"
                  onClick={() => csvInputRef.current?.click()}
                >
                  {csv || datas?.csvStandart ? (
                    <UploadDoneIcon />
                  ) : (
                    <UploadIcon />
                  )}
                  <FormattedMessage
                    id={
                      csv || datas?.csvStandart
                        ? Message.REPLACE_FILE
                        : Message.LANDING_PAGE_CHOOSE_FILE_BUTTON
                    }
                  />
                </UploadButton>
              </div>
            </div>
            <FinishButton
              type="submit"
              disabled={
                !getValues().address ||
                !getValues().name ||
                !getValues().number ||
                !getValues().city ||
                (!datas?.coverPhoto && !image) ||
                (!datas?.developerDetails?.id && !developerObj) ||
                !isValid ||
                isLoadingEdit ||
                isDevelopersLoading
              }
            >
              {isLoadingEdit || isDevelopersLoading ? (
                <Loader />
              ) : (
                <FormattedMessage id={Message.APPLY_CHANGES} />
              )}
            </FinishButton>
          </div>
        </form>
      </FormProvider>
    </MainContainer>
  );
};

export default EditProjectForm;

const MainContainer = styled.div`
  padding: 24px 32px;
  width: 421px;
  .sign-up-form {
    .MuiInputBase-root {
      border: 0 !important;
    }
  }
  .MuiAutocomplete-listbox {
    ${({ theme }) => theme.utils.scrollbar};
  }
  @media ${MOBILE_RESOLUTION} {
    width: 100%;
  }
  ${({ theme }) => theme.utils.scrollbar}
  .error {
    color: #fc5c42;
  }
  .loader {
    span {
      height: 30px !important;
      width: 30px !important;
    }
  }
  .uploadFocus {
    &:focus-within {
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1) !important;
      border-radius: 10px !important;
    }
  }
  .without-error {
    color: black;
  }
  .add-developer {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #5c95ff;
    padding-top: 50px;
    cursor: pointer;
    .icon-button {
      ${rtl` padding-right: 6px;`}
      padding-top: 5px;
      color: #5c95ff !important;
      width: 16px !important;
    }
  }
  .mainClass {
    text-align: ${({ theme }) => (theme.dir === 'rtl' ? 'right' : 'left')};
  }

  .mainClass2 {
    margin-bottom: 20px;
  }
  .MuiFormHelperText-root {
    margin: 2px auto;
    width: 100%;
  }
  .phoneNumber {
    margin-top: 8px;
  }

  .MuiInputBase-root {
    border: 1px solid #ffffff;
    &:focus-within {
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1) !important;
      border-radius: 10px !important;
    }
  }
  .MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0px 0px 0px 14px;
  }

  .custom-input-label {
    text-align: ${({ theme }) => (theme.dir === 'rtl' ? 'right' : 'left')};
    margin-left: 0px;
    font-size: 19px !important;
    font-weight: 400 !important;
    color: #696969 !important;
    margin: 0px 0px 9px 0px !important;
  }
  .text-input-root {
    min-height: 36px !important;
  }
  .phone-number-label {
    text-align: ${({ theme }) => (theme.dir === 'rtl' ? 'right' : 'left')};
    margin-left: 0px;
    margin-top: 8px;
    font-size: 19px !important;
    color: #696969 !important;
    font-weight: 400 !important;
  }
  .react-tel-input .country-list .country {
    text-align: ${({ theme }) => (theme.dir === 'rtl' ? 'right' : 'left')};
  }
  .project-input-width {
    width: 357px;
    margin-top: 14px;
    @media ${MOBILE_RESOLUTION} {
      width: 100%;
    }
  }
  .address {
    display: flex;
    gap: 8px;
  }
  .MuiInputBase-input {
    background: #fafcfe;
  }
  .custom-input-root:hover {
    border: none !important;
  }

  .MuiInputBase-root {
    border: 1px solid #ffffff !important;
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgb(230 230 230);
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: rgb(230 230 230);
  }
  .MuiOutlinedInput-input {
    padding: 0 14px;
    height: 48px;
  }

  h4 {
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    color: #282929;
    margin: 0 auto;
    border-bottom: 1px solid #282929;
    width: 161px;
  }
  h5 {
    font-weight: 600;
    font-family: 'Roboto';
    font-size: 18px;
    line-height: 22px;
    color: #fc5c42;
    margin: 8px auto 0;
    width: 117px;
  }
  h6 {
    font-weight: 400;
    font-family: 'Roboto';
    font-size: 16px;
    line-height: 20px;
    margin: 4px auto;
    color: #3e3e3e;
    width: 184px;
  }
  .project-address {
    margin-top: 0px;
    width: 228px !important;
    @media ${MOBILE_RESOLUTION} {
      width: 160px !important;
    }
  }
  .MuiAutocomplete-root {
    width: 357px;
    @media ${MOBILE_RESOLUTION} {
      width: 100%;
    }
  }
  .project-city {
    margin-top: 0px;
    width: 121px !important;
  }

  .upload-file .upload-btn-container .upload-floor-plan {
    font-size: 16px !important;
  }
  .upload-file {
    text-align: center;
    width: 357px;
    @media ${MOBILE_RESOLUTION} {
      width: 100%;
    }
    margin-top: 8px;
    .upload-btn-container {
      display: flex;
      justify-content: center;
      .upload-floor-plan {
        width: 51%;
        height: 48px;
        border-radius: 10px 0px 0px 10px;
        background: #fafcfe;
        border: 1px solid #e8eaeb;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #282929;
        padding: 13px 16px;
        @media ${MOBILE_RESOLUTION} {
          padding: 3px 0px !important;
        }
        ${({ theme }) =>
          theme.dir === 'rtl' &&
          css`
            border-radius: 0px 10px 10px 0px;
          `};
      }
      .upload-floor-btn {
        background: #fafcfe;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        border: 1px solid #fc5c42;
        border-radius: 0px 10px 10px 0px;
        color: #fc5c42;
        width: 169px;
        height: 48px;
        text-transform: none;
        svg {
          ${rtl` margin-right: 8px;`}
        }
        ${({ theme }) =>
          theme.dir === 'rtl' &&
          css`
            border-radius: 10px 0px 0px 10px;
          `};
      }
    }
    .text-container {
      margin-top: 8px;
      line-height: ${({ theme }) => (theme.dir === 'rtl' ? '18px' : '16px')};
      font-size: 14px;
      color: #3e3e3e;
    }
    .terms-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: ${({ theme }) => (theme.dir === 'rtl' ? '32px' : '35px')};
      line-height: 22px;
      font-size: 18px;
      color: #3e3e3e;
    }
  }
`;

const CrossIcon = styled(IconButton)`
  position: absolute !important;
  ${rtl`
  left: 6px;
  `}
  top: 6px;
  svg {
    font-size: 30.86px;
  }
`;

const DetailsContainer = styled.div``;

const FormTitle = styled.div`
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: #fc5c42;
  text-align: center;
`;

const AutocompleteComponent = styled(Autocomplete)`
  .MuiAutocomplete-inputRoot {
    .MuiAutocomplete-input {
      max-width: 265px !important;
    }
  }
  .MuiInputBase-root {
    height: 48px;
    padding-left: 60px;
    font-family: ${({ theme }) =>
      theme.dir === 'rtl' ? 'Assistant' : 'Roboto'};

    background: #ffffff;
    border: 1px solid #ff9287;
    box-sizing: border-box;
    border-radius: 10px;
    input {
      height: auto;
    }
    .MuiAutocomplete-input {
      padding: 3px !important;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
      opacity: 0.9;
    }
  }
  .MuiOutlinedInput-root {
    background: #fafcfe;
    border: none;
    padding-right: ${({ theme }) =>
      theme.dir === 'rtl' ? '9px !important' : '20px !important'};
  }

  .MuiAutocomplete-endAdornment {
    right: ${({ theme }) =>
      theme.dir === 'rtl' ? '282px !important' : '9px !important'};
  }
`;

const UploadButton = styled(Button)<{ isImageSelected: boolean }>`
  ${({ isImageSelected }) =>
    isImageSelected &&
    css`
      background: #2cdd73 !important;
      color: white !important;
      border: 1px solid #2cdd73 !important;
      width: 50%;
    `}
`;

const FinishButton = styled.button`
  margin-top: 24px;
  width: 167px;
  height: 48px;
  background: #fc5c42;
  border-radius: 15px;
  border: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  cursor: pointer;
  :hover {
    background: #a50a0a;
  }
  :disabled {
    background: #d9d9d9;
    color: #ffffff;
    border: 1px solid #d9d9d9;
  }
`;

const CoverPhoto = styled.div`
  text-align: ${({ theme }) => (theme.dir === 'rtl' ? 'right' : 'left')};
  margin-left: 0px;
  font-size: ${({ theme }) =>
    theme.dir === 'rtl' ? '14px' : '15px'} !important;
  font-weight: ${({ theme }) =>
    theme.dir === 'rtl' ? '400' : '500'} !important;
  color: #696969 !important;
  margin: 0px 0px 9px 0px !important;
  font-family: Assistant;
`;

const CSVFile = styled.div`
  text-align: ${({ theme }) => (theme.dir === 'rtl' ? 'right' : 'left')};
  margin-left: 0px;
  font-weight: 400 !important;
  font-size: 14px;
  line-height: ${({ theme }) => (theme.dir === 'rtl' ? '20px' : '16px')};
  font-family: ${({ theme }) => (theme.dir === 'rtl' ? 'Assistant' : 'Roboto')};
  color: #696969 !important;
  margin-top: 8px;
`;
