import SuppliersDataType from 'store/constants/user-management/suppliers.constants';
import * as Payload from 'store/types/user-management/suppliers.types';

export const getUsersDataRequest = (
  payload: Payload.GetUsersDataRequestPayload
) => ({
  type: SuppliersDataType.GET_USERS_DATA_REQUEST,
  payload,
});

export const getUsersDataFulfilled = (
  payload: Payload.GetUsersDataFulfilledPayload
) => ({
  type: SuppliersDataType.GET_USERS_DATA_FULFILLED,
  payload,
});

export const getUsersDataRejected = (
  payload: Payload.GetUsersDataRejectedPayload
) => ({
  type: SuppliersDataType.GET_USERS_DATA_REJECTED,
  payload,
});

export const getUserDataRequest = (
  payload: Payload.GetUserDataRequestPayload
) => ({
  type: SuppliersDataType.GET_USER_DATA_REQUEST,
  payload,
});

export const getUserDataFulfilled = (
  payload: Payload.GetUserDataFulfilledPayload
) => ({
  type: SuppliersDataType.GET_USER_DATA_FULFILLED,
  payload,
});

export const getUserDataRejected = (
  payload: Payload.GetUserDataRejectedPayload
) => ({
  type: SuppliersDataType.GET_USER_DATA_REJECTED,
  payload,
});
// supplier products
export const getProductsDataRequest = (
  payload: Payload.GetProductsDataRequestPayload
) => ({
  type: SuppliersDataType.GET_PRODUCTS_DATA_REQUEST,
  payload,
});
export const getProductsDataRejected = (
  payload: Payload.GetProductsDataRejectedPayload
) => ({
  type: SuppliersDataType.GET_PRODUCTS_DATA_REJECTED,
  payload,
});
export const getProductsDataFulfilled = (
  payload: Payload.GetProductsDataFulfilledPayload
) => ({
  type: SuppliersDataType.GET_PRODUCTS_DATA_FULFILLED,
  payload,
});
// orders
export const getOrdersDataRequest = (
  payload: Payload.GetOrdersDataRequestPayload
) => ({
  type: SuppliersDataType.GET_ORDERS_DATA_REQUEST,
  payload,
});

export const getOrdersDataFulfilled = (
  payload: Payload.GetOrdersDataFulfilledPayload
) => ({
  type: SuppliersDataType.GET_ORDERS_DATA_FULFILLED,
  payload,
});

export const getOrdersDataRejected = (
  payload: Payload.GetOrdersDataRejectedPayload
) => ({
  type: SuppliersDataType.GET_ORDERS_DATA_REJECTED,
  payload,
});

// files
export const getFilesDataRequest = (
  payload: Payload.GetFilesDataRequestPayload
) => ({
  type: SuppliersDataType.GET_FILES_DATA_REQUEST,
  payload,
});

export const getFilesDataFulfilled = (
  payload: Payload.GetFilesDataFulfilledPayload
) => ({
  type: SuppliersDataType.GET_FILES_DATA_FULFILLED,
  payload,
});

export const getFilesDataRejected = (
  payload: Payload.GetFilesDataRejectedPayload
) => ({
  type: SuppliersDataType.GET_FILES_DATA_REJECTED,
  payload,
});

export type SuppliersAction = ReturnType<
  | typeof getUsersDataRequest
  | typeof getUsersDataFulfilled
  | typeof getUsersDataRejected
  | typeof getUserDataRequest
  | typeof getUserDataFulfilled
  | typeof getUserDataRejected
  | typeof getOrdersDataRequest
  | typeof getOrdersDataFulfilled
  | typeof getOrdersDataRejected
  | typeof getFilesDataRequest
  | typeof getFilesDataFulfilled
  | typeof getFilesDataRejected
>;
