import styled from 'styled-components';
import Logo from 'components/Logo';
import LogoStack from 'pages/common/layout/Footer/LogoStack';
import { TermsOfUseFooter } from 'styles/sharedStyle';
import { Link } from 'react-router-dom';

const PrivacyPolicyHe: React.FC = () => {
  return (
    <div>
      <Container>
        <Link to="/">
          <Logo className="logo" direction="ltr" />
        </Link>
        <div>
          <div>
            <Body>
              <Title>מדיניות פרטיות</Title>
              <p>
                מדיניות פרטיות זו (להלן: &quot;
                <strong>מדיניות פרטיות</strong>&quot;) חלה על האופן שבו אנו
                ויז&#x27;ואל אסטייט בע&quot;מ (להלן: &quot;
                <strong>החברה</strong>&quot;).משתמשים במידע אישי הנאסף (כהגדרתו
                להלן) , מתקבל או נשמר על ידינו בקשר עם שימוש בפלטפורמה למכירה
                מקוונת של מוצרים בתחום עיצוב הבית ואספקת מוצרים לבית, המופעלת
                עלידי החברה באתר החברה ו/או בכל תצורת מדיה אחרת ,לרבות התכנים
                והשירותים המוצעים בו (יחד להלן:&quot;
                <strong>האתר</strong>&quot; ואו &quot;
                <strong>השירותים</strong>&quot; )
              </p>
              <ol>
                <li>
                  <strong>מבוא.</strong> אנו מכירים בחשיבותה של הפרטיות. מדיניות
                  פרטיות זו מפרטת את הנהלים על פיהם החברה אוספת ומשתמשת במידע
                  אודותיך ואודותמשתמשות האתר האחרות, לרבות במידע אישי.&quot;
                  <strong>מידע אישי</strong>&quot; משמעו כל מידע שעשוי לשמש,לבדו
                  או יחד עם מידע אחר, לזיהוי אישי של אדם, לרבות, מבלי לרגוע
                  מכלליות האמור, שם פרטי ושם משפחה כתובת דו"אל ופרטי מידע אישיים
                  ואחרים.
                </li>
                <br />

                <li>
                  <strong>תנאי שימוש</strong> מדיניות פרטיות זו הנה חלק מתנאי
                  השימוש של האתר, המפורסם תחת לשונית
                  [https://www.patricia-ai.com/app/terms-of-use] (להלן:&quot;
                  <strong>תנאי שימוש</strong>&quot;) כל מונחים מודגשים שאינם
                  מוגדרים במדיניות פרטיות זו, יישאו את המשמעות שניתנה להם בתנאי
                  השימוש. יובהר, כי מדיניות זו מנוסחת בלשון נקבה מטעמי נוחות
                  בלבד אולם היא (וכמובן האתר), מתייחסת לכלל המגדרים כאחד.
                </li>
                <br />

                <li>
                  <strong>הסכמה ושינוי מדיניות הפרטיות</strong> אינך מחויבת
                  משפטית למסור לנו מידע אישי, והנך מאשרת בזו כי מסירת המידע על
                  ידך מתבצעת מרצונךהחופשי. שימושך בשירותים מהווה אתהסכמתך לתנאי
                  מדיניות פרטיות זו ולאיסוף, עיבוד ושיתוף מידע (לרבות מידע
                  אישי), כמפורט במדיניותפרטיות זו. אנו שומרים על הזכות, על פי
                  שיקול דעתנו, לשנות מדיניות פרטיות זו בכל עת.שינוי כאמור ייכנס
                  לתוקף שלושה (3) ימים לאחר פרסוםמדיניות הפרטיות המתוקנת באתר,
                  והמשך שימושך בשירותים לאחר מכן משמעו כי הנך מקבלת שינויים אלו.
                </li>
                <br />

                <li>
                  <ul>
                    <strong>
                      סוג המידע האישי הנאסף על ידינו ואופן איסופו.{' '}
                    </strong>
                    <li>
                      <u>מידע שנמסר על ידיך: </u>
                      הגלישה בחלק מהאתר אינה מחייבת מסירה של פרטים מזהים. עם זאת
                      , חלק מהשירותים באתר דורשים הזדהות לצורך ביצוע פעולות
                      באתר, כגון: הרשמה לאתר או הרשמה לחלק מהשירותים ו/או רכישת
                      מוצרים ושירותים דרך האתר. בעת ההרשמה לאתר ו/אובעת ביצוע
                      הזמנת מוצרים ושירותים אחרים באמצעות האתר, תתבקשי למסור לנו
                      פרטים מזהים כגון:{' '}
                      <span className="bgc">
                        שם מלא, גיל, כתובת דואר אלקטרוני,טלפון (לרבות טלפון
                        נייד), תעודת זהות, כתובת מדויקת למשלוח (במידת הצורך
                        במקרה של רכישת מוצר), פרטי אמצעי תשלום וכל פרט נוסף שאנו
                        עשויים לדרוש במהלך תהליך ההזמנה.
                      </span>{' '}
                      וכל מידע נוסף שיימסר על ידיך במסגרת השימוש באתר.{' '}
                      <span className="bgc">
                        שדות המידע שחובה להשלים יסומנובמפורש.
                      </span>{' '}
                      מי לב כי על פי חוק אינך חייב למסור את הפרטים המבוקשים,
                      אולם מסירת פרטים שגויים או מסירת פרטים חלקיים עלולה
                      למנועממך את האפשרות להשתמש בשירותים באתר ו/או לפגוע באיכות
                      השירות.
                      <br />
                      <br />
                      כאשר אתה מוסר מידע אישיים של צד שלישי, ו/או פרטיו של
                      האדם/הגוף אליו יישלחו המוצרים שהזמנת, אתה מצהיר ומאשר כי
                      הנך מוסמך לעשות כן וכי קיבלת הסכמה מפורשת למסירת פרטים אלו
                      לחברה, לשם השימוש בפרטים אלה בהתאם למדיניות זו.
                      <br />
                      <br />
                      הנתונים שימסרו על ידך בעת ההרשמה לאתר, לשירותים המוצעים
                      באתר ו/או בעת עדכון פרטיך (כפי שיעשה מעת לעת), יישמרו
                      במאגרהמידע של החברה. אינך נדרש על-פי חוק למסור את המידע
                      האישי, אולם בלא מסירת הפרטים המופיעים בשדות החובה, לא תוכל
                      להשתמשבשירותים הטעונים הרשמה.
                      <br />
                      <br />
                      <u>מידע שנאסף במהלך השימוש באתר: </u>
                      בעת השימוש שלך באתר, יכול שיצטבר מידע (כולל מידע אישי)
                      אודותיך וכן מידע אודות נוהגיך באתר,לרבות תאריך ושעה שנכנסת
                      לאתר, מידע או פרסומות שקראת, העמודים והמוצרים שבהם צפית,
                      מקום המחשב ונתוני כתובת האינטרנט (IPaddress) שבאמצעותם
                      ניגשת לאתר, מיקום גיאוגרפי (Geo-location ,)רכישות שביצעת
                      באמצעות האתר, סוג הדפדפן בו הינך עושה שימוש,סוג מכשיר הקצה
                      בו הינך משתמש (לרבות סוג מערכת ההפעלה במכשיר), Advertising
                      ID המוקצה למכשירך, מספר זיהוי של מכשיר הקצה (Device ID),
                      ההצעות והשירותים שעניינו אותך, ועוד. בנוסף, החברה רשאית
                      לאסוף ולהסתייע בשירותיהם של צדדים שלישיים כדי לאסוף
                      ולנתחמידע אנונימי, סטטיסטי, או מצרפי (אגרגטיבי) בקשר עם
                      השימוש שלך באתר, לרבות מידע שנוגע לפרטיך (כגון השתייכות
                      למגדר או קבוצות גילמסוימת) ולפעילותך באתר.
                    </li>
                  </ul>
                </li>
                <br />
                <li>
                  <ol>
                    <strong>כיצד אנו משתמשים במידע שאנו אוספים</strong>
                    <li>
                      <u>שימוש במידע אישי: </u>
                      אנו עשויים להשתמש במידע אישי (בין שנמסר על ידך ובין שנאסף
                      על ידינו במסגרת השימוש שלך באתר ובשירותיםהמוצעים באתר)
                      כנזכר לעיל, בדרכים הבאות:
                      <ol>
                        <li>על מנת להעניק לך את השירותים המבוקשים. </li>
                        <br />
                        <li>
                          על מנת להפעיל ולשפר את האתר שלנו ואת השירותים הנכללים
                          בהם ולצורך בקרה, מחקר ופיתוח שירותים חדשים.{' '}
                        </li>
                        <br />
                        <li>
                          על מנת להעניק לך חווית משתמש עקבית, מתמשכת ואישית יותר
                          במסגרת התקשורת ביננו ובמסגרת השימוש בשירותינו.{' '}
                        </li>
                        <br />
                        <li>
                          על מנת ליצור עמך קשר בנוגע לשירותים לגביהם פנית אלינו
                          (לרבות בנוגע לרכישות שביצעת דרך האתר).{' '}
                        </li>
                        <br />
                        <li>
                          לצורך רכישת מוצרים ושירותים באתרים - לרבות פרסום מידע
                          ותכנים מטעמך. יובהר, כי תכנים שאתה מגישה לפרסום באתר
                          ו/אובאמצעות האתר עשויים להיות חשופים לגולשים באינטרנט,
                          ובפרט לגולשי האתר. אם תפרסם פרטים אישיים שלך
                          כחלקמהפרסומים כאמור אתה עשויה לקבל הודעות מבלי שתבקש.
                          על כן, אנו ממליצים לך לפרסם רק מידע שאת מעוניינת שיהיה
                          נגישלכולם.
                        </li>
                        <li>
                          כדי להתאים מודעות ותכנים שיוצגו לך בעת הביקור באתר
                          ובאתרים אחרים לתחומי ההתעניינות שלך ו/או לקבוצת מין או
                          גיל ו/אומקום מגוריך.{' '}
                        </li>
                        <li>
                          על מנת לשלוח לך מוצרים ודוגמאות להתנסות וכן לדוור לך,
                          <span className="bgc">
                            לרבות דרך דיוור ישיר ,
                          </span>{' '}
                          מידעים, מבצעים , חומרים שיווקיים ופרסומייםאודות החברה
                          ו/או מי מטעמה , לרבות חברות בנות, שותפיה העסקיים ,
                          ו/או חברות נוספות, על פי שיקול דעת החברה.{' '}
                        </li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      <u>שימוש במידע אנונימי: </u>
                      אנו עשויים להשתמש במידע אנונימי (כהגדרתו להלן) או לגלותו
                      לנותני שירות צד ג', על מנת לשפר את שירותינו ואתחוויית
                      המשתמש שלך במסגרת השימוש בשירותים. כמו כן, אנו עשויים
                      לגלות מידע אנונימי (בתמורה או ללא תמורה) לצדדים
                      שלישיים,לרבות למפרסמים ושותפים. &quot;
                      <strong>מידע אנונימי</strong>&quot; משמעו מידע שאינו מאפשר
                      זיהוי של משתמש אינדיבידואלי, כגון מידע מצטבר אודות
                      השימושבאתר ו/או בשירותים.
                    </li>
                    <br />
                    <li>
                      <u>שימוש בפרטי כרטיס האשראי:</u>
                      כאשר אתה מזמין או רוכש מוצר או שירות והסליקה נעשית על
                      ידינו, אנו משתמשים בפרטי כרטיס האשראיהנמסרים לנו בשעת
                      ביצוע ההזמנה רק על מנת לבצע ולספק את הזמנתך, ולאשרר את
                      קבלתה על ידך. הזנת הפרטים של כרטיס האשראי(וכן כל מידע אישי
                      נוסף שיידרש כגון שם, תעודת זהות, וכתובת (פיזית ו/או דואר
                      אלקטרוני) לשליחת חשבונית) תבוצע דרך האתר ישירותלמערכת
                      שירותי הסליקה המאובטחת והפרטים לא יישמרו בכל מקום אחר.
                      בסוף התהליך יימחקו פרטי כרטיס האשראי שלך ולא יישמרובמערכת.
                      לכל הזמנה חדשה תתבקש למלא את פרטי כרטיס האשראי מחדש. איננו
                      חשופים לפרטי כרטיס האשראי שלך, ואנו לא מוסריםאת פרטי כרטיס
                      האשראי שלך לאף צד שלישי אחר, למעט במקרים בהם הדבר נדרש כדי
                      להשלים את ביצוע הזמנתך ולמעט כמפורטבמדיניות פרטיות זו. עוד
                      יובהר, כי חלק מהמתחמים באתר מאפשרים לבצע רכישה ו/או הזמנה
                      של מוצר או שירות ישירות מהמשווק (למשלעל ידי העברה לאתר
                      המשווק), ובמקרים אלו סליקת כרטיס האשראי שלך לא נעשית על
                      ידינו וכן לא מעוברים לנו כל פרטים בנושא,והחברה אינה לוקחת
                      אחריות על כל מעשה, מחדל או פעולה הנוגעת לרכישה כאמור.
                    </li>
                  </ol>
                  <br /> <br />
                  <li>
                    <strong>אופן שיתוף המידע על ידינו.</strong>
                    <br /> <br />
                    <p>
                      אנו נשתף מידע עם צדדים שלישים במקרים המפורטים להלן: אנו
                      נשתף מידע עם צדדים שלישים במקרים המפורטים להלן:{' '}
                    </p>
                    <ol>
                      <li>בעת ההרשמה לאתר;</li>
                      <br />
                      <li>
                        בעת שתרכשי מוצרים ושירותים מצדדים שלישיים במסגרת האתר,
                        או בעת שתיקחי חלק בפעילויות תוכן של צד שלישי, או
                        בפעילויותמשותפות לחברה ולצדדים שלישיים המוצגים באתר, לשם
                        משלוח דברי פרסומת מטעם החברה ו/או מטעמם של מפרסמים
                        אחרים, לרבות החברות להלן: -
                        <span className="bgc">
                          [ביתילי, אורבן, איי.די.דיזיין, קואלה רהיטים, שטיחי
                          כרמל, סווינג מאווררים, מודי יבוא ושיווק קרמיקה,
                          בי,אס,אייץ']
                        </span>{' '}
                        ו/אוחברות נוספות, על פי שיקול דעת החברה. במקרים אלה
                        יועבר לשותפים או למפרסמים אלה המידע הדרוש להם לשם, לרבות
                        לצורךהשלמת תהליך הרכישה, המידע ו/או הנגשת המידע עבורך,
                        ניהול פעילות התוכן הרלבנטית, ו/או מסירת מידע נוסף ושמירת
                        הקשר עמך;
                      </li>
                      <br />
                      <li>
                        ככל שהדבר דרוש לצורך אספקה תקינה של שירותי האתר ואספקת
                        המוצרים.
                      </li>
                      <br />
                      <li>
                        לצורך אספקת המוצרים/השירותים אותם רכשת באתר. כמו כן,
                        במקרה שבו תשתתפי בפעילות אחרת המשותפת לחברה ולצד שלישי,
                        אזאפשר וגם הצד השלישי ייחשף למידע הנוגע לגלישתך
                        והשתתפותך בפעילות האמורה.
                      </li>
                      <br />
                      <li>
                        לספקי שירותים ו/או שלוחים מטעמה של החברה לצרכי תפעול,
                        שיווק, מחקר, אבטחת מידע וכיו"ב.
                      </li>
                      <br />
                      <li>
                        בכל מקרה שהחברה תסבור כי מסירת המידע נחוצה כדי למנוע נזק
                        חמור לגופך או לרכושך או לגופו או לרכושו של צד שלישי.
                      </li>
                      <br />
                      <li>לרשויות המדינה או לצד ג' מכוח הוראות הדין.</li>
                      <br />
                      <li>
                        אם יתקבל צו שיפוטי המורה למסור את פרטיך או המידע אודותיך
                        כאמור לצד שלישי.
                      </li>
                      <br />
                      <li>
                        במקרה שתפר ו/או תנסה להפר את תנאי השימוש באתר ו/או באיזה
                        מהשירותים המוצעים בו, או במצב בו תפעל באתר ו/או בקשר
                        אליובאופן הנחזה על ידי החברה כמנוגד לדין.
                      </li>
                      <br />
                      <li>
                        בכל מחלוקת, תביעה, טענה, דרישה או הליך משפטי, אם וככל
                        שיהיו בינך לבין החברה.
                      </li>
                      <br />
                      <li>
                        לחברות קשורות של החברה, ובלבד שהן תשתמשנה במידע בהתאם
                        למדיניות פרטיות זו.
                      </li>
                      <br />
                      <li>
                        באופן אנונימי לצורך איסוף מידע כללי או פרטני ו/או ניתוח
                        מידע מצרפי וסטטיסטי עבור החברה על ידי צדדים שלישיים.
                      </li>
                    </ol>
                  </li>
                  <br />
                  <li>
                    <strong>איסוף מידע על ידי צדדים שלישיים. </strong>
                    כאשר את משתמש באתר, החברה רשאית לעשות שימוש בתוכנות צד ג׳ על
                    מנת לספק תכנים, שירותים,לצרכי פרסום ו/או במסגרת פונקציות
                    נוספות כגון עיבוד נתונים. בעת שימושך באתר את מאשרת כי תוכנות
                    צד ג' אלו יהיו רשאיות לאסוף אתהמידע כאמור וכי החברה תהיה
                    רשאית לעשות שימוש במידע זה למטרות המפורטות במדיניות פרטיות
                    זו.
                  </li>
                  <br />
                  <li>
                    <strong>Google Analytics</strong>. האתר עשוי{' '}
                    <strong>להשתמש</strong> בכלי הנקרא "Google Analytics" על
                    מנתלאסוף מידע אודות השימוש בשירות. GoogleAnalytics אוסף מידע
                    כגון תדירות ביקורי המשתמשים בשירות, העמודים בהם הם מבקרים
                    ובאלו אתרים הם משתמשים לפני הכניסה לשירות. אנומשתמשים במידע
                    המתקבל מ-Google Analytics על מנת לתחזק ולשפר את השירות ואת
                    מוצרינו. איננו משלבים בין המידע שנאסף במסגרתהשימוש
                    GooglesAnalytics ובין מידע אישי מזהה. לאופן האיסוף והשימוש
                    במידע אודותיך על ידי Google ראה את הסכם השימוש
                    ומדיניותהפרטיות המפורסמים תחת
                    <a href="http://www.google.com/analytics/terms/us.html">
                      http://www.google.com/analytics/terms/us.html
                    </a>{' '}
                    וכן תחת{' '}
                    <a href="http://www.google.com/policies/privacy/">
                      http://www.google.com/policies/privacy/
                    </a>
                    .
                  </li>
                  <br />
                  <br />
                  <li>
                    <strong>עוגיות.</strong>
                    <br />
                    <br />
                    <ol>
                      <li>
                        sבמסגרת שירותינו, אנו עשויים להשתמש ב-״עוגיות", קבצי
                        זיהוי אנונימיים וטכנולוגיות מעקב אחרות, על מנת שנוכל
                        לספק את שירותינו,להציג בפניך מידע מותאם אישית ועל מנת
                        לשפר את חוויית המשתמש במסגרת השימוש בשירותים. "עוגיה"
                        הנה קובץ טקסט קטן המשמש,לדוגמה, לאיסוף מידע אודות פעילות
                        בשירותים ועל מנת לאחזר מידע שנמסר קודם לכן (לדוגמה,
                        לצורך זיהוי בעת כניסה לשירות). עוגיותמסוימות וטכנולוגיות
                        אחרות עשויות לשמש לצורך אחזור מידע אישי, כגון כתובת IP
                        שצוינה קודם לכן על ידי משתמש. רוב הדפדפניםמאפשרים לשלוט
                        בעוגיות, לרבות אם לאשרן או לא לאשרן ואופן הסרתן. ניתן
                        להגדיר את רוב הדפדפנים באופן שתקבל הודעה אם לאשרעוגיה,
                        או שבאפשרותך לחסום עוגיות בדפדפן.
                      </li>
                      <br />
                      <li>
                        ייתכן כי חלק מהמודעות, שבהם אתv צופה בעת הביקור באתר או
                        ברשת (לרבות ברשתות החברתיות) לאחר ביקורך באתר מגיעות
                        משרתיםשל צדדים שלישיים, המנהלים את מערך הגשת הפרסומות
                        באתר. ניהול הפרסומות מצריך מחברות אלו, בין היתר, הצבת
                        עוגיות במחשבךושיבוץ web beacons במודעות הפרסומת (להלן:
                        <strong>"טכנולוגיות מעקב"</strong>). תפקידן של
                        טכנולוגיות המעקב, בין היתר, הינו סיוע באיסוף מידעאודות
                        הצפייה והשימוש באתרי אינטרנט בהם קיים פרסום ואודות
                        הפרסומות אליהן נחשפת ועליהן הקלקת. המידע הנאסף מבקש
                        להתאיםאת הפרסומות שיוצגו בפניך לנושאים שיעניינו אותך. אם
                        אינך רוצה שטכנולוגיות המעקב יופעלו בעת שימושך באתר, תוכל
                        להימנע מכך עלידי שינוי ההגדרות בדפדפן שלך. לשם כך היעזר
                        בקובץ העזרה של הדפדפן. את יכול למחוק את העוגיות במחשבך
                        בכל רגע. לתשומת ליבך,מוצע שתעשה כן, רק אם את משוכנע
                        שאינך רוצה להתאים את הפרסומות באתר להעדפותיך. ייתכן גם
                        שנטרול העוגיות עלול לגרום לכךשלא תוכל להשתמש בחלק
                        מהשירותים והתכונות באתרי אינטרנט אחרים.{' '}
                      </li>
                      <br />
                      <li>
                        אם אינך מעוניין לקבל עוגיות, תוכל להימנע מכך על ידי
                        שינוי ההגדרות בדפדפן שלך. לשם כך אנא העזר בקובץ העזרה של
                        הדפדפן. עליךלדעת, עם זאת, שנטרול העוגיות עלול לגרום לכך
                        שלא תוכל להשתמש בחלק מהשירותים והתכונות באתר או באתרי
                        אינטרנט אחרים.בנוסף לכך, את יכול למחוק את העוגיות במחשב
                        בכל רגע. מוצע שתעשה כן, רק אם את משוכנע שאינך רוצה שהאתר
                        יותאם להעדפותיך.הואיל והעוגיות מונעות ממך לעתים את הצורך
                        להזין שמות משתמש וסיסמאות, אל תמחק אותן אלא אם את משוכנע
                        שרשמת תחילה את כלהפרטים הדרושים לשימוש באתרים במקום
                        בטוח.
                      </li>
                    </ol>
                  </li>
                  <br />
                  <br />
                  <li>
                    <strong>אבטחה.</strong> אנו שמים דגש על אבטחת המידע האישי.
                    אנו פועלים בהתאם לסטנדרטים מקובלים, לרבות שימוש באמצעי בטחון
                    פיזיים וטכניים, עלמנת להגן על המידע האישי, לרבות באמצעות
                    הצפנת נתונים. עם זאת, אף אמצעי תשדורת מקוונת ואף אמצעי אחסון
                    אלקטרוני אינם בטוחים ב-100%. לפיכך, אף שאנו שואפים ליישם
                    אמצעים מקובלים לצורך הגנת המידע האישי שלך, איננו יכולים
                    להבטיח את אבטחתו או סודיותו המלאים.
                  </li>
                  <br />
                  <li>
                    <strong>זכויות משתמשים.</strong>
                    <ol>
                      <li>
                        בהתאם לחוק הגנת הפרטיות, התשמ"א- 1981"( חוק הפרטיות"),
                        כל אדם זכאי לעיין בעצמו, או על ידי בא-כוחו שהרשהו בכתב
                        או על ידיאפוטרופוסו, במידע שעליו המוחזק במאגר מידע. אדם
                        שעיין במידע שעליו ומצא כי אינו נכון, שלם, ברור או
                        מעודכן, רשאי לפנות לבעלמאגר המידע בבקשה לתקן את המידע או
                        למוחקו.{' '}
                      </li>
                      <br />
                      <li>
                        כמו כן, אם המידע שבמאגרי החברה משמש לצורך פניה אישית
                        אליך, בהתבסס על השתייכותך לקבוצת אוכלוסין, שנקבעה על פי
                        אפיון אחדאו יותר של בני אדם ששמותיהם כלולים במאגר, או אז
                        את זכאי על-פי חוק הפרטיות לדרוש בכתב שהמידע המתייחס אליך
                        יימחק ממאגרהמידע. החברה תמחק תוך זמן סביר במקרה זה מידע
                        הדרוש לה רק כדי לפנות אליך בהצעות מסחריות כאמור לעיל.
                        מידע הדרוש לחברהלשם ניהול עסקיה - לרבות תיעוד פעולות
                        מסחריות ואחרות שביצעת באתר ו/או במסגרת השירותים - יוסיף
                        להישמר על ידי החברה על-פי דין,אך לא ישמש עוד לצורך פניות
                        אליך.
                      </li>
                    </ol>
                  </li>
                  <br />
                  <li>
                    <strong>קישורים לאתרים של צד ג'.</strong>ת מאשרת כי ידוע לך
                    כי מסירת פרטים והרשמה באתרים שאליהם תגיע באמצעות או דרך
                    שימוש או קישורהקיימים באתר אינה כפופה למדיניות הפרטיות אלא
                    למדיניות הפרטיות של אותם אתרים מקושרים ולהוראות כל דין.
                  </li>
                  <br />
                  <li>
                    <strong>מיזוג, מכירה או פשיטת רגל. </strong>במידה שחברתנו
                    תירכש על ידי- או תמוזג עם- חברת צד ג', או במקרה של פשיטת רגל
                    או אירוע דומה, אםשומרים על הזכות להעביר או להמחות את המידע
                    אישי אודותיך (בין שנמסר על ידך ובין שנאסף על ידינו) לצד
                    שלישי בקשר עם אירועים כאמור.
                  </li>
                  <br />
                  <li>
                    <strong>שאלות ופניות.</strong>בכל הערה או שאלה בנוגע
                    למדיניות הפרטיות של החברה, נא פנה אלינו לכתובת{' '}
                    <a href="mailto:team@patricia-ai.com">
                      <span className="bgc">[team@patricia-ai.com]</span>
                    </a>
                  </li>
                </li>
              </ol>
              <br /> <br />
              <p>
                <strong>עודכן לאחרונה: דצמבר 2021</strong>
              </p>
              <LogoStack hebrew />
            </Body>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default PrivacyPolicyHe;

const Container = styled.div`
  direction: rtl;
  padding: 10px 7px;
  margin: 0 6px;
  font-family: 'Roboto', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI',
    ' Oxygen', 'Ubuntu', ' Cantarell', 'Fira Sans', ' Droid Sans',
    ' Helvetica Neue', 'sans-serif';
  .back-btn {
    color: red;
    padding-right: 20px;
  }
  ${TermsOfUseFooter}

  .bgc {
    background-color: yellow;
  }
`;

const Title = styled.p`
  font-size: 25px;
  text-decoration: underline;
  text-align: center;
  font-weight: bold;
`;

const Body = styled.section`
  background-color: #f8f8f8;
  padding: 5px 5px;
  margin-top: 12px;
`;
