import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Message } from 'i18n';
import { MOBILE_RESOLUTION } from 'styles/sharedStyle';
import SecondaryButton from 'components/Button/SecondaryButton';
import SideNavigation from './SideNavigation';
import {
  NavigationTab,
  tabs,
  WorkList,
  GeneralList,
  DesignList,
} from './InstructionsModal.config';
import TabContent from './TabContent';

const InstructionsModalNew: React.FC<{ close: VoidFunction }> = ({ close }) => {
  const [activeTab, setActiveTab] = React.useState<string>(NavigationTab.WORK);

  const onTabClick = React.useCallback((tab: string) => setActiveTab(tab), []);

  return (
    <Container>
      <SideNavigation
        tabs={tabs}
        activeTab={activeTab as NavigationTab}
        onTabClick={onTabClick}
      />
      {activeTab === NavigationTab.WORK && (
        <TabContent list={WorkList} type={activeTab} />
      )}
      {activeTab === NavigationTab.GENERAL && (
        <TabContent list={GeneralList} type={activeTab} />
      )}
      {activeTab === NavigationTab.DESIGN && (
        <TabContent list={DesignList} type={activeTab} />
      )}
      <ModalFooter>
        <CloseButton $xlarge onClick={close}>
          <FormattedMessage
            id={Message.TOUR_INSTRUCTIONS_MODAL_CLOSE_BUTTON_TEXT}
          />
        </CloseButton>
      </ModalFooter>
    </Container>
  );
};

export default InstructionsModalNew;

const Container = styled.div`
  width: auto;
  height: 80vh;
  .client-top-title {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 0;

    & .back-btn {
      display: none;
    }

    @media ${MOBILE_RESOLUTION} {
      height: unset;
      h3 {
        font-size: 1.25rem;
      }
    }
  }
  .navigation-tabs {
    align-item: center;
    justify-content: center;
    border-bottom: 0px solid #ffffff;
    a:hover {
      color: #ffffff;
      border-bottom: 2px solid #ffffff;
    }
  }
`;

const CloseButton = styled(SecondaryButton)`
  && {
    background-color: #322f2e;
    color: #fff;
    margin-bottom: 20px;
    &:hover {
      background-color: #322f2e;
    }
  }
`;

const ModalFooter = styled.div`
  margin: 0;
  padding: 0;
  text-align: center;

  @media (min-width: 1500px) {
    top: 30px;
  }
`;
