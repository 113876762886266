import { all, call, put, debounce } from 'redux-saga/effects';
import DatabaseType from 'store/constants/suppliers-interface/database.constants';
import * as Payload from 'store/types/suppliers-interface/database.types';
import * as DatabaseActions from 'store/actions/suppliers-interface/database.actions';
import { showNotification } from 'store/actions/notifications.actions';
import { SuppliersAPI } from 'api/methods';
import { removeEmptyValues } from 'utils/common.utils';
import { newSortByMap } from 'utils/transform.utils';

function* errorHandler(
  e: Error,
  type: DatabaseType,
  rejectAction: (payload: {
    error: string;
  }) => { type: DatabaseType; payload: { error: string } }
) {
  const error = e.message || 'ERROR: Cannot refresh Data source';
  yield put(showNotification({ key: type, message: error, severity: 'error' }));
  yield put(rejectAction({ error }));
}

function* getProductsDataRequest({
  payload,
  type: actionType,
}: ReturnType<typeof DatabaseActions.getProductsDataRequest>) {
  const {
    page = 0,
    size = 10,
    search,
    sortBy,
    categories: category,
    subCategories: sub_category,
    supplier,
    status,
  } = payload;
  try {
    const databaseSortByMap = { name: 'name' };
    const params = removeEmptyValues({
      page: page + 1,
      size,
      search,
      category,
      sub_category,
      supplier,
      status,
      ...newSortByMap<typeof databaseSortByMap>(databaseSortByMap, sortBy),
    }) as Payload.GetProductsDataRequestPayload;

    const {
      results,
      count,
    }: Payload.GetProductsDataResponsePayload = yield call(
      SuppliersAPI.getSupplierProducts,
      { params }
    );
    const pageCount = Math.ceil(count / size);

    yield put(DatabaseActions.getProductsDataFulfilled({ results, pageCount }));
  } catch (e) {
    yield call(
      errorHandler,
      e,
      actionType,
      DatabaseActions.getProductsDataRejected
    );
  }
}

function* getFilesDataRequest({
  payload,
  type: actionType,
}: ReturnType<typeof DatabaseActions.getFilesDataRequest>) {
  const { page = 0, size = 10, search, status, user, sortBy } = payload;
  try {
    const params = removeEmptyValues({
      page: page + 1,
      size,
      search,
      status,
      user,
      ...newSortByMap(
        {
          id: 'id',
          type: '',
          status: 'status',
          file_name: 'file_name',
          fileName: 'file_name',
          taskId: '',
          failed: '',
          succeed: '',
          missingData: '',
          missingDataFile: '',
          created: '',
          updated: '',
          uploaded: '',
          total: '',
          detailsCount: '',
          created_at: 'created_at',
          updated_at: 'updated_at',
          file: '',
        },
        sortBy
      ),
    }) as Payload.GetFilesDataParams;

    const {
      results,
      count,
    }: Payload.GetFilesDataResponsePayload = yield call(
      SuppliersAPI.getSupplierFileTasks,
      { params } as Payload.GetFilesDataParams
    );
    const pageCount = Math.ceil(count / size);

    yield put(
      DatabaseActions.getFilesDataFulfilled({ files: { results, pageCount } })
    );
  } catch (e) {
    yield call(
      errorHandler,
      e,
      actionType,
      DatabaseActions.getProductsDataRejected
    );
  }
}

function* DatabaseSaga() {
  yield all([
    debounce(
      500,
      DatabaseType.GET_PRODUCTS_DATA_REQUEST,
      getProductsDataRequest
    ),
    debounce(500, DatabaseType.GET_FILES_DATA_REQUEST, getFilesDataRequest),
  ]);
}

export default DatabaseSaga;
