import * as React from 'react';
import ProviderButton from 'components/Button/ProviderButton';
import AppleIcon from 'assets/icons/apple.svg';
import { appleClientId } from 'constants/config';
import { useNotifications, useRouter } from 'hooks';
import { useDispatch } from 'store/selectors';
import styled from 'styled-components';
import AppleIconOnboarding from 'assets/icons/apple-onboarding.png';
import * as Authentication from 'store/actions/authentication.actions';
import { OnboardingFields } from 'store/types/authentication.types';
import { ClientRouter } from 'router/routes';
import { userLogData } from 'mockData/userLogData';
import { LogsAPI } from 'api/methods';
import AppleLoginButton from './AppleLogin';
import type { AuthContext } from '../types';

interface Props {
  authContext: AuthContext;
  forceLabel?: boolean;
  onboardingFields?: OnboardingFields;
  isOnBoarding?: boolean;
}

const AppleProvider: React.FC<Props> = ({
  authContext,
  forceLabel,
  onboardingFields,
  isOnBoarding,
}) => {
  const dispatch = useDispatch();
  const { showNotification } = useNotifications();
  const { query } = useRouter<{ redirect: string }>();
  const hostName = `${window.location.protocol}//${window.location.host}`;

  const onSuccess = React.useCallback(
    res => {
      if (res.error) {
        showNotification({
          key: 'login/Apple',
          message:
            'An error has been occurred with Apple login, please try again later',
          severity: 'error',
        });
      } else if (!query.redirect) {
        dispatch(
          Authentication.loginWithProviderRequest({
            backend: 'apple',
            token: res,
            onboardingFields,
          })
        );
      } else {
        dispatch(
          Authentication.redirectLoginWithProviderRequest({
            redirect: ClientRouter.CART,
            registerPayload: {
              backend: 'apple',
              token: res,
              onboardingFields,
            },
          })
        );
      }
    },
    [dispatch, onboardingFields, query.redirect, showNotification]
  );

  const appleSingIn = (
    e: React.MouseEvent<HTMLButtonElement | HTMLImageElement>,
    onClick: React.MouseEventHandler<HTMLButtonElement | HTMLImageElement>
  ) => {
    LogsAPI.postUserLogs({
      area: userLogData.eventArea.registration,
      section: userLogData.eventSection.userInfo,
      name: userLogData.eventName.apple,
      path: userLogData.eventPath.appOnBoardingAdditionalInfoPath,
    });
    onClick(e);
  };

  return (
    <AppleLoginButton
      clientId={appleClientId}
      callback={onSuccess}
      redirectURI={hostName}
      scope="name email"
      responseMode="form_post"
      usePopup
      render={({ onClick }) => (
        <>
          {isOnBoarding ? (
            <AppleOnboarding
              src={AppleIconOnboarding}
              alt="apple-icon"
              onClick={e => appleSingIn(e, onClick)}
            />
          ) : (
            <div className="provider-button-wrapper">
              <ProviderButton
                provider="Apple"
                icon={AppleIcon}
                authContext={authContext}
                forceLabel={forceLabel}
                onClick={e => appleSingIn(e, onClick)}
              />
            </div>
          )}
        </>
      )}
    />
  );
};

export default AppleProvider;

const AppleOnboarding = styled.img`
  cursor: pointer;
`;
