import * as React from 'react';
import Container from 'components/Modal';
import Title from 'components/Modal/Title';
import Content from 'components/Modal/ModalContent';
import Actions from 'components/Modal/ModalActions';

interface Props {
  body: string[];
  className?: string;
  height?: string | number;
  width?: string | number;
  isOpen: boolean;
  withClose?: boolean;
  title: string | React.ReactNode;
  cancelLabel?: string;
  onClose: () => void;
  onConfirm: {
    action: () => void;
    label: string;
    isLoading?: boolean;
    buttonColor?: 'dark' | 'light' | 'lightMobile';
  };
}
const ConfirmationModal: React.FC<Props> = ({
  isOpen,
  onClose,
  withClose = true,
  body,
  height,
  width,
  title,
  className,
  cancelLabel = 'Cancel',
  onConfirm = {
    action: () => {},
    label: 'Confirm',
  },
}) => {
  return (
    <Container
      className={className}
      close={withClose ? onClose : undefined}
      minHeight={height}
      open={isOpen}
      width={width}
    >
      <Title>{title}</Title>
      <Content body={body} />
      <Actions
        showCancelButton
        actions={[onConfirm]}
        onCancel={onClose}
        cancelLabel={cancelLabel}
      />
    </Container>
  );
};

export default React.memo(ConfirmationModal);
