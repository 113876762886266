import styled from 'styled-components';

const Magazine1 = () => {
  return (
    <Root>
      <div>
        <p>
          <b>
            בשנים האחרונות, עולמות עיצוב הבית והלייף סטייל משתנים ומתחדשים
            במהירות. לצד בשורות מרהיבות בעיצוב וטרנדים ייחודיים המתכתבים עם
            עולמות האופנה העולמיים, הולכת ומתהווה מגמת קנייה דיגיטלית, המספקת
            ללקוחות חוויית רכישה ייחודית, בנוחות ובקלות. החל מתאורה , דרך חדר
            הרחצה ועד לעיצוב הסלון המשפחתי, כל מה שרציתם לדעת על רכישת רהיטים
            אונליין, מחכה לכם במאמר הבא
          </b>
        </p>
      </div>
      <div>
        <p>
          העולם נע בקצב מהיר ועמו גם אנחנו, הצרכנים. מתשלום חשבונות, רכישת
          מצרכים ועד לספרים, מוצרי פנאי ולייף סטייל, הרגלי הצריכה שלנו משתנים
          ומתפתחים והופכים מקוונים. חוויית הרכישה הדיגיטלית מתבססת ומחליפה את
          הקנייה הפיזית בהציעה מבחר רב של פריטים, מגוון, מחיר אטרקטיבי, נוחות
          וגמישות חסרי תחרות.
          <br />
          כיצד בוחרים רהיטים אונליין? מהם הטיפים עליהם יש לשים דגש על מנת לבצע
          רכישה חכמה?
          <b>
            מומחי העיצוב של "פטרישיה", עוזרת וירטואלית חדשנית המציעה חוויה
            וירטואלית מותאמת אישית לעיצוב הבית , מלבישים לכם את הבית מא' ועד ת'
            בכמה שלבים פשוטים, כאן מתחילים
          </b>
        </p>
      </div>
      <div>
        <p>
          <b>אין כמו לחזור הביתה - מעצבים את הבית מחדש</b>
          <br />
          בין אם אתם מתגוררים לבד, או חולקים את המרחב הביתי עם משפחה גדולה, הבית
          הוא העוגן והבסיס המשפחתי. ביתנו הוא המקום הראשון עמו אנחנו פותחים יום
          חדש ואליו אנחנו שבים בסופו של יום. לכן, עבור כולנו, החלל הביתי ,
          הסלון, המטבח, חדרי השינה והאמבטיה, הם בעלי משמעות אישית וככאלה חשוב
          לנו שיהיו נעימים, מזמינים ותואמים את צרכי הבית והדרים בו. אם עברתם לא
          מזמן לבית חדש, או אתם חושבים להתחדש ולעצב את הבית, בחירת רהיטים חדשים
          אונליין בוודאי תוסיף צבע ועניין, חמימות ואלגנטיות ותהווה השקעה כדאית
          וארוכת טווח.
        </p>
      </div>
      <div>
        <p>
          <b>מתחילים ביסודות - כיצד בוחרים רהיטים בהצלחה?</b>
          <br />
          עוברים לבית חדש ומרגש? החלטתם כי הגיעה העת להתחדש ולעצב את הבית? נהדר!
          כך או כך, כעת נותר לבחור ברהיטים שיוסיפו טאצ' ייחודי ואישי למרחב
          הביתי. אם אתם מתלבטים בין נוחות לאסתטיקה, או פרקטיות לעומת עיצוב, חשוב
          שתדעו כי כיום אין צורך להתפשר וניתן בקלות לשלב בין שניהם. בבחירה
          ברהיטים מעוצבים יש להביא בחשבון אלמנטים כמו מקום, סגנון עיצובי וצרכים
          אינדיבידואליים המשתנים בהתאם לצרכי המשפחה. חשוב להקדיש מחשבה וזמן
          לתכנון מראש , מה שימנע בהמשך הוצאות מיותרות ומפח נפש. הפתרון הפשוט הוא
          לעבוד אל מול תכנית ברורה ומוגדרת מראש, כך תוכלו להקצות בקלות, מקום
          ייעודי עבור כל רהיט שתרכשו ותוכלו להבטיח בחירה מוצלחת, ומותאמת צרכים.
        </p>
      </div>
      <div>
        <p>
          <b>רכישת רהיטים אונליין - צועדים קדימה</b>
          <br />
          מבלי ששמנו לב, מגמת קניית הרהיטים במרחב האינטרנטי הולכת ותופסת תאוצה
          בשנים האחרונות. לצד רכישת מוצרי חשמל, אלקטרוניקה וביגוד, יותר ויותר
          אנשים, בעולם ובישראל בפרט, רוכשים רהיטים אונליין ויעידו על כך בברור
          מותגי הרהיטים השונים המציעים מבחר פריטים בסגנונות שונים, תואמי תקציב
          וצרכים. עם זאת, נראה שבשונה מקנייה של מוצרים אחרים, רכישת רהיטים
          אונליין מלווה באתגרים. הקושי מובן, לאור העובדה כי מדובר בפריטי עיצוב
          משמעותיים, המלווים אותנו לאורך זמן. יתרה מכך, בחירת רהיטים מצריכה
          חישוב שטח, הבנת המידות המדויקות, התאמת צבעים ועוד ועוד. כיצד בוחרים
          נכונה?
        </p>
      </div>
      <div>
        <p>
          <b>דיוק, מקצועיות ושימוש בהדמיות - עצות זהב לקניית רהיטים אונליין</b>
        </p>
        <ul>
          <li>
            <b>העדיפו את המומחים - </b>מומלץ לבחור בחברה ייעודית, המתמחה בעיצוב
            וריהוט ותוכל לספק לכם כלים מקצועיים לבחירה נכונה, ליווי ומענה מותאם
            צרכים.
          </li>
          <br />
          <li>
            <b>מגוון - </b>היכולת לספק לכם מנעד עיצובים ומותגים ברמות תמחור
            משתנות, תקל עליכם בבחירה האופטימלית עבורכם ותהפוך את חווית רכישת
            הרהיטים למהנה וצבעונית.
          </li>
          <br />
          <li>
            <b>העתיד כבר כאן - </b>כל מי שבחר בעבר רהיטים יכול להעיד על כך כי
            נוכחות פיזית בחנות, אינה מבטיחה בחירה נכונה. נהפוך הוא, מכיוון
            והמרחב הביתי הוא הגורם המשמעותי, עליו יש לשים את הדגש. שימוש כלים
            דיגיטליים כמו הדמיית מחשב יאפשר לכם לדייק את הבחירה בעיצוב נכון אשר
            ילווה אתכם למשך שנים רבות.
          </li>
        </ul>
      </div>
      <div>
        <p>
          <b>הכירו את פטרישיה, העוזרת החדשה שלכם בעיצוב והלבשת הבית</b>
        </p>
        <p>
          נעים להכיר, פטרישיה, סייעת וירטואלית המגדירה מחדש את חווית העיצוב
          בסטנדרט דיגיטלי ייחודי. אני שמחה להזמין אתכם לחוויה וירטואלית חדשנית
          המציעה לכם מרחב מקוון ומותאם אישית המאפשר לכם עיצוב, קנייה והלבשת
          הבית, הכל בקלות ובנוחות, מבלי לצאת מהבית!
          <br />
          ברור לי, כי רכישת רהיטים אונליין מצריכה תכנון מקדים , חשיבה והבנת
          המרחב הביתי. לכן, ריכזתי עבורכם אונליין, תחת מטריה אחת, מנעד רחב של
          שירותי עיצוב וחברות ריהוט, כך שתוכלו לבחור בין מגוון פריטי ריהוט
          ועיצוב. על מנת לסייע לכם בבחירה, אני אלווה אתכם לאורך כל התהליך, מבלי
          לבזבז זמן בחיפושים מיותרים, החל משלב התוכניות ועד לרגע שכל הפריטים
          המעוצבים יגיעו עד לפתח הדלת.
          <b>סקרנים ? קליק ובית החלומות שלכם בדרך אליכם</b>
        </p>
      </div>
    </Root>
  );
};

export default Magazine1;

const Root = styled.div``;
