import { Message } from 'i18n';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { ReactComponent as ContentCopy } from 'assets/@client/project/share-icon.svg';
import { MOBILE_RESOLUTION, TABLET_RESOLUTION } from 'styles/sharedStyle';
import rtl from 'styled-components-rtl';
import Dialog from '@material-ui/core/Dialog';
import AddCoOwner from './AddCoOwner';

interface Props {
  key: string;
  id: string;
  img?: string | null;
  propertyName: string;
  alt: string;
  typeName?: string;
  buildingName?: string;
  lastModified?: string;
  co_owner?: number[];
  onClick: VoidFunction;
}

const PropertyCard: React.FC<Props> = ({
  key,
  id,
  img,
  propertyName,
  alt,
  typeName,
  buildingName,
  lastModified,
  co_owner,
  onClick,
}) => {
  const [isOpenCoOwnerForm, setIsOpenCoOwnerForm] = React.useState(false);
  const [isHovering, setIsHovering] = React.useState<boolean>(false);
  const [mouseDownPosition, setMouseDownPosition] = React.useState<number>();
  return (
    <div
      key={key}
      onMouseDown={e => {
        setMouseDownPosition(e.clientX);
      }}
    >
      <Container>
        <ContentCopy
          className="icon-clients-action"
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          onClick={e => {
            e.preventDefault();
            if (isHovering === true) setIsOpenCoOwnerForm(true);
          }}
        />
        <MainContainer
          onClick={e => {
            // e.preventDefault();
            if (
              (isHovering === true || isOpenCoOwnerForm === false) &&
              e.clientX === mouseDownPosition
            )
              onClick();
          }}
        >
          <ImageParent>
            <StyledImage src={img ?? ''} alt={alt} />
          </ImageParent>
          <RightContainer>
            <MainDiv>
              <Title>
                <FormattedMessage id={Message.PROPERTY_CARD_NAME} />
              </Title>
              <SubTitle>{propertyName}</SubTitle>
            </MainDiv>
            <MainDiv>
              <Title>
                <FormattedMessage id={Message.TYPE_NAME} />
              </Title>
              <SubTitle>
                <span>
                  <FormattedMessage id={Message.BUILDING_NAME_TITLE} />
                  {buildingName} <FormattedMessage id={Message.TYPE_TITLE} />
                  {typeName}
                </span>
              </SubTitle>
            </MainDiv>
            <MainDiv>
              <Title>
                <FormattedMessage id={Message.PROPERTY_CARD_LAST_MODIFIED} />
              </Title>
              <SubTitle>{lastModified}</SubTitle>
            </MainDiv>
          </RightContainer>
          <Modal open={isOpenCoOwnerForm}>
            <AddCoOwner
              setIsOpenCoOwnerForm={setIsOpenCoOwnerForm}
              propertyId={id}
              co_owners={co_owner}
            />
          </Modal>
        </MainContainer>
      </Container>
    </div>
  );
};

export default PropertyCard;

const MainContainer = styled.div`
  display: flex;
  gap: 24px;
  height: 234px;
  width: 550px;
  box-shadow: 0px 0px 10px rgba(41, 41, 41, 0.1);
  border-radius: 16px;
  padding: 32px;
  cursor: pointer;
  direction: ${({ theme }) => theme.dir};
  @media ${TABLET_RESOLUTION} {
    width: 454px;
  }
  @media ${MOBILE_RESOLUTION} {
    width: 400px;
    padding: 22px;
  }
`;

const Container = styled.div`
  position: relative;
  & .icon-clients-action {
    position: absolute;
    ${rtl`right: 36px;`}
    top: 16px;
    cursor: pointer;
    &:hover {
      fill: #fc5c42;
      circle {
        fill: #fc5c42;
      }
      path {
        stroke: white;
      }
    }
  }
`;

const ImageParent = styled.div`
  width: 220px;
  height: 170px;
`;

const StyledImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

const Modal = styled(Dialog)`
  .MuiDialog-paper {
    border-radius: 15px;
    overflow-y: hidden !important;
  }
  .client-dialog .MuiDialog-paper {
    overflow-y: hidden;
  }
`;

const Title = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: #3e3e3e;
`;

const SubTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #3e3e3e;
  margin-top: 4px;
`;

const MainDiv = styled.div``;

const RightContainer = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
`;
