import { useRouter } from 'hooks';
import styled from 'styled-components';
import { RolePermission } from 'types/teamPatricia';
import { Permission } from 'constants/config';

const NotAuthorized = () => {
  const { query } = useRouter<{ permission: Permission }>();
  const sectionName = RolePermission[query.permission];

  return (
    <Container>
      <h1>
        You don't have access to {sectionName ?? 'this section'}. Contact your
        admin for more info.
      </h1>
    </Container>
  );
};

export default NotAuthorized;

const Container = styled.div`
  width: 85vw;
  height: 80%;
  ${({ theme }) => theme.utils.centerFlex};

  & h1 {
    font-size: 1.75rem;
    opacity: 0.6;
  }
`;
