import * as React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { METHODS } from 'api/client';
import * as Transform from 'api/transform';
import { useFetch } from 'hooks';
import { Message } from 'i18n';
import { Category, DatabaseStatus } from 'types/products';
import CategoriesCard from './CategoriesCard';
import ProductsCard from './ProductsCard';

interface Props {
  categories: Category[] | [];
}

const MobileHomePage: React.FC<Props> = ({ categories }) => {
  const intl = useIntl();

  const {
    data: randomProducts,
    callFetch: loadMoreProducts,
    isLoading,
  } = useFetch({
    initialUrl: `api/products/`,
    skipOnStart: true,
    config: {
      method: METHODS.GET,
      params: {
        size: 12,
        page: 1,
        status: DatabaseStatus['In database'],

        ordering: '?',
      },
    },
    transform: Transform.PaginatedProducts,
  });

  return (
    <Root>
      <CategoriesCard
        categories={categories}
        title={intl.formatMessage({
          id: Message.SHOP_BY_DEPARTMENT,
        })}
      />
      <ProductsCard
        title={intl.formatMessage({
          id: Message.PATRICIA_BEST_RECOMMENDED,
        })}
        products={randomProducts}
        loadMoreProducts={loadMoreProducts}
        isLoading={isLoading}
      />
    </Root>
  );
};

export default MobileHomePage;

const Root = styled.div``;
