import * as React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useFetch, useRouter } from 'hooks';
import styled from 'styled-components';
import { METHODS } from 'api/client';
import * as Transform from 'api/transform';
import { MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';
import Loader from 'components/Loader/Loader';
import DesktopProductPage from './DesktopProductPage';
import MobileProductPage from './MobileProductPage';

const ProductPage = () => {
  const isMobile = useMediaQuery(MOBILE_TABLET_RESOLUTION);
  const { query } = useRouter<{ catName: string; productId: number }>();

  const { data: product, callFetch, isLoading } = useFetch({
    initialUrl: `api/products/${query.productId}/`,
    skipOnStart: true,
    config: {
      method: METHODS.GET,
    },
    transform: Transform.transformProduct,
  });

  const {
    data: categoryProducts,
    callFetch: loadMoreProducts,
    isLoading: isProductsLoading,
  } = useFetch({
    initialUrl: `api/products/`,
    skipOnStart: true,
    config: {
      method: METHODS.GET,
      params: { category: query.catName, size: 10, page: 1, ordering: '?' },
    },
    transform: Transform.PaginatedProducts,
  });

  React.useEffect(() => {
    callFetch({ url: `api/products/${query.productId}/` });
  }, [query.productId, callFetch]);

  return isMobile ? (
    <>
      {product && !isLoading ? (
        <MobileProductPage
          product={product}
          categoryProducts={categoryProducts}
          loadMoreProducts={loadMoreProducts}
          isLoading={isProductsLoading}
        />
      ) : (
        <Loader />
      )}
    </>
  ) : (
    <>
      {product && !isLoading ? (
        <DesktopProductPage
          product={product}
          categoryProducts={categoryProducts}
          loadMoreProducts={loadMoreProducts}
          isLoading={isProductsLoading}
        />
      ) : (
        <LoaderDiv>
          <Loader />
        </LoaderDiv>
      )}
    </>
  );
};

export default ProductPage;

const LoaderDiv = styled.div`
  margin-top: 25%;
`;
