import * as React from 'react';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import { useDisableOverflow } from 'hooks';

export type ModalAction = {
  isLoading?: boolean;
  label: string | React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  variant?: 'primary' | 'secondary';
};

export interface ModalProps extends DialogProps {
  actions?: ModalAction[];
  disableClose?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  children,
  title,
  actions = [],
  disableClose,
  ...props
}) => {
  const onClose = React.useCallback(() => {
    props.onClose?.({}, 'backdropClick');
  }, [props]);

  useDisableOverflow(props.open);
  return (
    <Container {...props}>
      <Header>
        {!disableClose && (
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
      </Header>
      <VideoContainer>
        <VideoFrame
          controls
          autoPlay
          src="https://patricia-ai-data-production.s3.eu-central-1.amazonaws.com/media/videos/CoverVideo.mp4"
        >
          <track
            default
            kind="captions"
            // src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
          />
          Sorry, your browser doesn't support embedded videos.
        </VideoFrame>
      </VideoContainer>
    </Container>
  );
};

export default Modal;

const Container = styled(Dialog).attrs<ModalProps>({
  className: 'client-dialog',
  BackdropProps: { className: 'client-dialog-backdrop' },
})`
  position: relative;
  &.client-dialog .MuiDialog-paper {
    border-radius: 16px;
    background-color: transparent;

    & .MuiDialogTitle-root {
      margin: 24px;
    }

    & .MuiDialogActions-root {
      padding: 0 3rem 24px 3rem;
      justify-content: center;
      font-family: ${({ theme }) => theme.dir === 'rtl' && 'Assistant'};

      && .MuiButton-label {
        font-family: ${({ theme }) => theme.dir === 'rtl' && 'Assistant'};
      }

      & > button {
        flex: 1;
        font-family: ${({ theme }) => theme.dir === 'rtl' && 'Assistant'};
        max-width: 150px;
        :not(:last-of-type) {
          ${rtl`
            margin-right: 10px;
          `};
        }
      }
    }
  }
  .client-dialog-backdrop {
    background: rgba(92, 87, 87, 0.47);
  }
`;

const Header = styled.header`
  position: absolute;
  top: 15px;
  ${rtl`
  right: 15px;  
  `}

  color: white;
  z-index: 1;
`;
const VideoContainer = styled.div`
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
`;

const VideoFrame = styled.video`
  height: 100%;
  width: 100%;
  object-fit: fill;
`;
