const offer = [
  {
    id: 'offer1',
    title: '3D CATALOG',
    subTitleBold: 'Create ',
    subTitle: 'Your Own 3D Catalog',
    description: `Excite your clients with a 3D catalog, tailored to each homeowner's unique design vision`,
    video:
      'https://patricia-ai-data-production.s3.eu-central-1.amazonaws.com/media/videos/catalog.mp4',
  },
  {
    id: 'offer2',
    title: 'SHOWROOM',
    subTitleBold: 'Take 3D Products to your showroom',
    description:
      'Wow your customers with a virtual home tour experience with your products',
    image:
      'https://images.pexels.com/photos/6580232/pexels-photo-6580232.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
    video:
      'https://patricia-ai-data-production.s3.eu-central-1.amazonaws.com/media/videos/showroom.mp4',
  },
  {
    id: 'offer1',
    title: 'MARKETPLACE',
    subTitleBold: 'Shop the Room',
    description:
      'Clients love a good bundle! Get the perfect go-to with Promote products bundles for free',
    image:
      'https://images.pexels.com/photos/312029/pexels-photo-312029.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
    video:
      'https://patricia-ai-data-production.s3.eu-central-1.amazonaws.com/media/videos/marketplace.mp4',
  },
  {
    id: 'offer2',
    title: 'SKINS',
    subTitleBold: 'Shop the Style',
    description: `Your products, a designer-feel made by top interior designers will ease your client's mind to shop`,
    image:
      'https://images.pexels.com/photos/276528/pexels-photo-276528.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
    video:
      'https://patricia-ai-data-production.s3.eu-central-1.amazonaws.com/media/videos/skins.mp4',
  },
];

export default offer;
