import { useMediaQuery } from '@material-ui/core';
import CheckVector from 'assets/@client/onboarding/CheckVector.png';
import { Message } from 'i18n';
import * as Section from 'pages/appClient/onboarding/shared';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { MOBILE_RESOLUTION } from 'styles/sharedStyle';
import { PropertyMedia } from 'types/properties';

interface Props {
  next: VoidFunction;
  isLoading?: boolean;
  name: number;
  image: File | null;
  selectedFloor?: PropertyMedia | null;
  goBack: VoidFunction;
}

const WebView: React.FC<Props> = ({
  next,
  isLoading,
  name,
  image,
  selectedFloor,
  goBack,
}) => {
  const intl = useIntl();
  const isMobile = useMediaQuery(MOBILE_RESOLUTION);

  return (
    <Container>
      <ImageContainer>
        <img src={CheckVector} alt="check" />
      </ImageContainer>
      <Section.Header>
        <Title>
          {image ? (
            <>
              <FormattedMessage
                id={Message.ONBOARDING_PLAN_UPLOAD_SUCCESS}
                values={{
                  span: (chunks: string) => (
                    <span className="title-text">{chunks}</span>
                  ),
                }}
              />
            </>
          ) : (
            <>
              <FormattedMessage
                id={Message.ONBOARDING__SELECTED_PREMADE_MESSAGE}
                values={{
                  span: (chunks: string) => (
                    <span className="title-text">{chunks}</span>
                  ),
                  name: selectedFloor?.name,
                  linebreak: isMobile ? <br /> : '',
                }}
              />
            </>
          )}
        </Title>
      </Section.Header>

      <SelectedFloor>
        <img src={selectedFloor?.file} alt="Selected floor plan" />
      </SelectedFloor>

      <Section.Actions
        isLoading={isLoading}
        withPrevious
        prevProps={{ onClick: goBack }}
        nextProps={{
          onClick: next,
          disabled: isLoading,
        }}
        nextText={intl.formatMessage({
          id: Message.PROPERTY_ADD_PROPERTY_BUTTON_NEXT,
        })}
      />
    </Container>
  );
};

export default WebView;

const ImageContainer = styled.div`
  width: min-content;
  margin: auto;
  @media (min-width: 767px) and (max-height: 1050px) {
    margin-top: 24px;
  }
  margin-top: 64px;
  margin-bottom: 48px;
  width: 88px;
  height: 65px;
`;

const Container = styled(Section.Container).attrs({
  className: 'confirm-plan-container',
})`
  & .file-display {
    margin: 20px 0;
    justify-content: center;
  }
  & p {
    font-size: 1.125rem;

    ${rtl`
      margin-left: 50px;
    `}
    @media (min-width: 767px) and (max-width: 1024px) {
      margin-top: -3rem;
    }

    @media (min-height: 700px) {
      margin-bottom: 20px;
    }

    @media (min-height: 800px) {
      margin-bottom: 40px;
    }
  }
  & .actions-container {
    margin-top: 0;
    & .action-button {
      width: 190px;
      height: 57px;
      font-size: 1.313rem;
    }
    & .action-button-back {
      width: 190px;
      height: 57px;
      font-size: 1.313rem;
    }
    @media (min-height: 500px) {
      margin-top: 20px;
    }
  }
`;

// const CustomFileDisplay = styled(FileDisplay)`
//   justify-content: center;
// `;

const SelectedFloor = styled(Section.Floor)`
  max-width: calc(319px - 20px);
  margin: 10px auto 0 auto;
  img {
    object-fit: cover;
  }
  @media (min-height: 700px) {
    @media (min-width: 767px) and (max-width: 1440px) {
      max-width: 100vw;
    }
    max-width: 319px;
    @media (min-width: 767px) and (max-height: 1050px) {
      margin-top: 24px;
      margin-bottom: 30px;
    }
    margin-top: 45px;
  }
  @media (max-width: 767px) {
    width: 327px;
    height: 273px;
    margin-bottom: 100px;
  }

  @media (min-width: 1150px) {
    max-width: calc(550px - 90px);
    height: auto;
  }
`;

const Title = styled.h2`
  margin: 0 20px;
  font-weight: 700;
  font-size: 1.25rem;
  text-align: center;
  & span {
    color: #2cdd73;
  }
  & svg {
    position: relative;
    left: 6px;
    top: 3px;
  }
  @media (max-width: 767px) {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
  }
`;

// const Text = styled.p`
//   text-align: center;
//   font-size: 2rem;
//   font-weight: 600;
//   margin-top: 30px;
// `;
