import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import { ReactSVG } from 'react-svg';
import { MOBILE_RESOLUTION, TABLET_RESOLUTION } from 'styles/sharedStyle';
import BlockingImage from 'assets/@client/accessibility/blocking-flickers.svg';
import AdaptationBlindPeople from 'assets/@client/accessibility/adaptation_blind_people.svg';
import AdaptationKeyboardNavigationEnabling from 'assets/@client/accessibility/adaptation_keyboard_navigation_enabling.svg';
import AdaptingAssistiveTechnologiesNVDAJAWS from 'assets/@client/accessibility/adapting_assistive_technologies_NVDA_JAWS.svg';
import ChangeFontMoreReadable from 'assets/@client/accessibility/change_font_more_readable.svg';
import ContrastDarkBackground from 'assets/@client/accessibility/contrast_dark_background.svg';
import ContrastLightBackground from 'assets/@client/accessibility/contrast_light_background.svg';
import DeclarationOfAccessibility from 'assets/@client/accessibility/declaration_of_accessibility.svg';
import DisplaysAlternativeDescriptionImages from 'assets/@client/accessibility/displays_alternative_description_images.svg';
import EnlargeCursorChangeColorBlackWhite from 'assets/@client/accessibility/enlarge_cursor_change_color_black_white.svg';
import HighlightingHeadlinesSite from 'assets/@client/accessibility/highlighting_headlines_site.svg';
import HighlightingLinkSite from 'assets/@client/accessibility/highlighting_link_site.svg';
import IncreaseSiteFont from 'assets/@client/accessibility/Increase_site_font.svg';
import IncreaseTheDisplayAbout200 from 'assets/@client/accessibility/Increase_the_display_about_200.svg';
import SendingAccessibilityFeedback from 'assets/@client/accessibility/Sending_accessibility_feedback.svg';

const Accessibility = () => {
  return (
    <Container>
      <MainTitle>
        <FormattedMessage id={Message.WEBSITE_ACCESSIBILITY_TITLE} />
      </MainTitle>
      <Title>
        <FormattedMessage id={Message.WEBSITE_ACCESSIBILITY_INTRO_TITLE} />
      </Title>
      <Body>
        <FormattedMessage
          id={Message.WEBSITE_ACCESSIBILITY_INTRO_SECTION}
          values={{
            linebreak: <br />,
            p: (chunks: string) => <p>{chunks}</p>,
          }}
        />
      </Body>
      <Title>
        <FormattedMessage
          id={Message.WEBSITE_ACCESSIBILITY_SITE_ACCESSABILITY_TITLE}
        />
      </Title>
      <Body>
        <FormattedMessage
          id={Message.WEBSITE_ACCESSIBILITY_SITE_ACCESSABILITY_SECTION}
          values={{
            linebreak: <br />,
            p: (chunks: string) => <p>{chunks}</p>,
          }}
        />
      </Body>
      <Title>
        <FormattedMessage id={Message.WEBSITE_ACCESSIBILITY_MENU_TITLE} />
      </Title>
      <Body>
        <List>
          <ListItem>
            <Image src={BlockingImage} />
            <ListText>
              <FormattedMessage
                id={Message.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_1}
              />
            </ListText>
          </ListItem>
          <ListItem>
            <Image src={IncreaseSiteFont} alt="Blocking the Flickers" />
            <ListText>
              <FormattedMessage
                id={Message.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_2}
              />
            </ListText>
          </ListItem>
          <ListItem>
            <Image
              src={AdaptingAssistiveTechnologiesNVDAJAWS}
              alt="Blocking the Flickers"
            />
            <ListText>
              <FormattedMessage
                id={Message.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_3}
              />
            </ListText>
          </ListItem>
          <ListItem>
            <Image
              src={AdaptationKeyboardNavigationEnabling}
              alt="Blocking the Flickers"
            />
            <ListText>
              <FormattedMessage
                id={Message.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_4}
              />
            </ListText>
          </ListItem>
          <ListItem>
            <Image src={ChangeFontMoreReadable} alt="Blocking the Flickers" />
            <ListText>
              <FormattedMessage
                id={Message.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_5}
              />
            </ListText>
          </ListItem>
          <ListItem>
            <Image src={AdaptationBlindPeople} alt="Blocking the Flickers" />
            <ListText>
              <FormattedMessage
                id={Message.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_6}
              />
            </ListText>
          </ListItem>
          <ListItem>
            <Image src={ContrastLightBackground} alt="Blocking the Flickers" />
            <ListText>
              <FormattedMessage
                id={Message.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_7}
              />
            </ListText>
          </ListItem>
          <ListItem>
            <Image src={ContrastDarkBackground} alt="Blocking the Flickers" />
            <ListText>
              <FormattedMessage
                id={Message.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_8}
              />
            </ListText>
          </ListItem>
          <ListItem>
            <Image
              src={HighlightingHeadlinesSite}
              alt="Blocking the Flickers"
            />
            <ListText>
              <FormattedMessage
                id={Message.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_9}
              />
            </ListText>
          </ListItem>
          <ListItem>
            <Image src={HighlightingLinkSite} alt="Blocking the Flickers" />
            <ListText>
              <FormattedMessage
                id={Message.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_10}
              />
            </ListText>
          </ListItem>
          <ListItem>
            <Image
              src={IncreaseTheDisplayAbout200}
              alt="Blocking the Flickers"
            />
            <ListText>
              <FormattedMessage
                id={Message.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_11}
              />
            </ListText>
          </ListItem>
          <ListItem>
            <Image
              src={EnlargeCursorChangeColorBlackWhite}
              alt="Blocking the Flickers"
            />
            <ListText>
              <FormattedMessage
                id={Message.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_12}
              />
            </ListText>
          </ListItem>
          <ListItem>
            <Image
              src={SendingAccessibilityFeedback}
              alt="Blocking the Flickers"
            />
            <ListText>
              <FormattedMessage
                id={Message.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_13}
              />
            </ListText>
          </ListItem>
          <ListItem>
            <Image
              src={DeclarationOfAccessibility}
              alt="Blocking the Flickers"
            />
            <ListText>
              <FormattedMessage
                id={Message.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_14}
              />
            </ListText>
          </ListItem>
          <ListItem>
            <Image
              src={DisplaysAlternativeDescriptionImages}
              alt="Blocking the Flickers"
            />
            <ListText>
              <FormattedMessage
                id={Message.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_15}
              />
            </ListText>
          </ListItem>
        </List>
      </Body>
      <Title>
        <FormattedMessage
          id={Message.WEBSITE_ACCESSIBILITY_COORDINATOR_TITLE}
        />
      </Title>
      <Body>
        <FormattedMessage
          id={Message.WEBSITE_ACCESSIBILITY_COORDINATOR_DESC}
          values={{
            linebreak: <br />,
            p: (chunks: string) => <p>{chunks}</p>,
          }}
        />
        <div>
          <FormattedMessage
            id={Message.WEBSITE_ACCESSIBILITY_COORDINATOR_FIELD_NAME}
          />
        </div>
        <div>
          <FormattedMessage
            id={Message.WEBSITE_ACCESSIBILITY_COORDINATOR_FIELD_TELEPHONE}
          />
        </div>
        <div>
          <FormattedMessage
            id={Message.WEBSITE_ACCESSIBILITY_COORDINATOR_FIELD_EMAIL}
          />
        </div>
        <div>
          <FormattedMessage
            id={Message.WEBSITE_ACCESSIBILITY_COORDINATOR_FIELD_MAIL_ADDRESS}
          />
        </div>
      </Body>
      <Title>
        <FormattedMessage
          id={Message.WEBSITE_ACCESSIBILITY_ARRANGEMENTS_DEPANDANCY_TITLE}
        />
      </Title>
      <Body />
    </Container>
  );
};

export default Accessibility;

const Container = styled.div`
  padding: 10px 7px;
  margin: 0 6px;
  font-family: ${({ theme }) => (theme.dir === 'rtl' ? 'Assistant' : 'Roboto')};
  .back-btn {
    color: red;
    padding-right: 20px;
  }
  direction: ${({ theme }) => theme.dir};
`;

const MainTitle = styled.h1`
  font-size: 45px;
  line-height: 1.1em;
  padding: 0;
  color: #111;
  font-weight: 300;
  @media ${TABLET_RESOLUTION} {
    font-size: 30px;
  }
  @media ${MOBILE_RESOLUTION} {
    font-size: 25px;
  }
`;

const Title = styled.h2`
  display: block;
  line-height: 1em;
  font-weight: 300;
  font-size: 35px;
  margin: 0 auto 5px;
  color: #17384c;
  position: relative;
  @media ${TABLET_RESOLUTION} {
    font-size: 25px;
  }
  @media ${MOBILE_RESOLUTION} {
    font-size: 20px;
  }
`;
const Body = styled.section`
  padding: 5px 5px;
  margin-bottom: 30px;
  font-size: 20px;
  @media ${MOBILE_RESOLUTION} {
    font-size: 13px;
  }
`;
const List = styled.ul`
  padding: 0;
  margin: 30px auto 30px;
  list-style: none;
`;
const ListItem = styled.li`
  padding: 20px 10px 10px 10px;
  position: relative;
  width: calc(87% / 4);
  display: inline-block;
  margin: 0 0 20px 20px;
  vertical-align: top;
  border-bottom: 1px solid #e3e3e3;
  box-sizing: border-box;
  min-height: 300px;
  @media ${TABLET_RESOLUTION} {
    width: 33.33%;
    margin: 0 0 20px 0px;
    min-height: 300px;
  }
  @media ${MOBILE_RESOLUTION} {
    width: 50%;
    margin: 0 0 20px 0px;
    min-height: 220px;
  }
`;
const ListText = styled.span`
  display: block;
  padding: 9px 0 0 0px;
  vertical-align: top;
  text-align: center;
  height: 85px;
  font-size: 20px;
  line-height: 1.3em;
  @media ${TABLET_RESOLUTION} {
    font-size: 20px;
    padding: 70px 0 0 0px;
    height: auto;
  }
  @media ${MOBILE_RESOLUTION} {
    font-size: 13px;
    padding: 9px 0 0 0px;
    height: auto;
  }
`;
const Image = styled(ReactSVG)`
  width: 100px;
  height: 100px;
  vertical-align: top;
  display: block;
  position: relative;
  right: 0;
  top: 8px;
  text-align: center;
  margin: 0 auto 20px;
  vertical-align: top;
  svg {
    width: 100px;
    height: 100px;
  }
  & path {
    fill: #25aae1;
    stroke: #25aae1;
  }
  @media ${TABLET_RESOLUTION} {
    width: 50px;
    height: 50px;
    svg {
      width: 100px;
      height: 100px;
    }
  }
  @media ${MOBILE_RESOLUTION} {
    width: 50px;
    height: 50px;
    svg {
      width: 50px;
      height: 50px;
    }
  }
`;
