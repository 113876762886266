import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ClientRouter } from 'router/routes';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import Button from 'components/@client/Button';
import { MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';
import { useAuthentication } from 'hooks';
import { Type } from 'constants/config';
import EmptyState from 'assets/@client/properties/properties-empty-state.svg';

const ProjectEmptyState = () => {
  const { type } = useAuthentication();
  return (
    <EmptyStateWrapper>
      <Link
        to={
          type === Type.SELLER
            ? ClientRouter.ADD_PROJECT
            : ClientRouter.ONBOARDING
        }
      >
        <CustomButton className="btn">
          <FormattedMessage
            id={
              type === Type.SELLER
                ? Message.PROPERTIES_BUTTON
                : Message.CREATE_HOME_BUTTON
            }
          />
        </CustomButton>
      </Link>
      <p>
        <FormattedMessage
          id={Message.PROPERTIES_EMPTY_STATE_CAPTION}
          values={{ linebreak: <br /> }}
        />
      </p>
      <IllustrationWrapper>
        <img
          src={EmptyState}
          alt={`Illustrated nature scene. Yellow tent on a grassy hill with trees,
            a walking trail, and bright blue mountains in the background.`}
        />
      </IllustrationWrapper>
    </EmptyStateWrapper>
  );
};

export default ProjectEmptyState;

const EmptyStateWrapper = styled.div`
  max-width: 710px;
  margin: auto;
  text-align: center;
  a {
    width: 100%;
    text-decoration: none;
  }
`;

const CustomButton = styled(Button)`
  width: 250px;
  margin: 40px 0 !important;
  @media ${MOBILE_TABLET_RESOLUTION} {
    width: 150px;
  }
`;

const IllustrationWrapper = styled.div`
  max-width: 650px;
  margin-bottom: 10px;
  & img {
    width: 100%;
  }
`;
