import * as React from 'react';
import { MenuProps as menuProps } from '@material-ui/core/Menu';

export interface DateRange {
  startDate?: Date;
  endDate?: Date;
}

export type SetDateRangeReason = 'apply' | 'clear' | 'select-range';

export type Setter<T> =
  | React.Dispatch<React.SetStateAction<T>>
  | ((value: T) => void);

export enum NavigationAction {
  Previous = -1,
  Next = 1,
}

export type DefinedRange = {
  startDate: Date;
  endDate: Date;
  label: string;
};

export type Marker = symbol;

export interface MenuProps extends Partial<menuProps> {}
