import * as React from 'react';
import {
  useOnboarding,
  OnboardingAction,
  useOnboardingState,
} from 'context/onboardingContext';
import { useRouter } from 'hooks';
import { ClientRouter } from 'router/routes';

const useQuizQuestion = (nextStep?: VoidFunction) => {
  const { history } = useRouter();
  const { isPremadeProperty } = useOnboardingState();
  const [
    { questions, currentQuestion, progress, choosePlan },
    dispatch,
  ] = useOnboarding();

  const hasPrev = currentQuestion > 0;
  const question = React.useMemo(() => questions[currentQuestion], [
    currentQuestion,
    questions,
  ]);
  const hasNext = React.useMemo(() => Boolean(questions[currentQuestion + 1]), [
    currentQuestion,
    questions,
  ]);
  const progressStep = React.useMemo(
    () => (isPremadeProperty ? 300 : 50 / questions.length),
    [questions, isPremadeProperty]
  );

  const prev = React.useCallback(() => {
    dispatch({
      type: OnboardingAction.SET_CURRENT_QUESTION,
      payload: {
        currentQuestion: currentQuestion - 1,
        progress: progress - progressStep,
      },
    });
  }, [currentQuestion, dispatch, progress, progressStep]);

  const next = React.useCallback(
    (i: number | null) => {
      const payload = {
        question: question?.id,
        answer: i !== null ? question?.answers?.[i]?.id ?? null : null,
        currentQuestion: hasNext ? currentQuestion + 1 : currentQuestion,
        progress: progress + progressStep,
      };
      dispatch({ type: OnboardingAction.SET_QUIZ_ANSWER, payload });
      if (hasNext) return null;
      if (!nextStep)
        return choosePlan
          ? history.push(ClientRouter.ADDITIONAL_INFO)
          : history.push(ClientRouter.ADDITIONAL_INFO);
      return nextStep();
    },
    [
      currentQuestion,
      dispatch,
      hasNext,
      history,
      nextStep,
      progress,
      progressStep,
      question?.answers,
      question?.id,
      choosePlan,
    ]
  );

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentQuestion]);
  return { question, hasPrev, progress, prev, next, progressStep, questions };
};

export default useQuizQuestion;
