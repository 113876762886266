import { useMediaQuery } from '@material-ui/core';
import { ReactComponent as HrLine } from 'assets/icons/hrLine.svg';
import Button from 'components/Button/PrimaryButton';
import { useRouter } from 'hooks';
import confirmationImage from 'assets/@client/onboarding/confirmation-image.gif';
import { Message } from 'i18n';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { ClientRouter } from 'router/routes';
import styled, { css } from 'styled-components';
import { MOBILE_RESOLUTION } from 'styles/sharedStyle';
import { Property } from 'types/properties';

const UploadPlanView: React.FC<{ property: Property | null }> = () => {
  const isMobile = useMediaQuery(MOBILE_RESOLUTION);
  const { history } = useRouter();
  return (
    <CustomContainer>
      <div className="title">
        <FormattedMessage
          id={Message.ONBOARDING_DONE_KEY_CREATED_SUCCESSFULL}
          values={{ linebreak: isMobile ? <br /> : '' }}
        />
        <div className="stroke-container">
          <HrLine />
        </div>
      </div>
      <div className="subtitle1">
        <FormattedMessage id={Message.ONBOARDING_DONE_SUBTITLE1} />
        <div className="notice">
          <FormattedMessage
            id={Message.ONBOARDING_DONE_EMAIL_NOTICE}
            values={{
              span: (chunks: string) => (
                <span style={{ fontWeight: 500 }}>{chunks}</span>
              ),
            }}
          />
        </div>
      </div>
      <div className="subtitle2">
        <FormattedMessage id={Message.ONBOARDING_DONE_SUBTITLE2} />
        <div className="subtitle">
          <FormattedMessage
            id={
              isMobile
                ? Message.ONBOARDING_DONE_SUBTITLE_MOBILE
                : Message.ONBOARDING_DONE_SUBTITLE
            }
            values={{
              span: (chunks: string) => <span>{chunks}</span>,
              linebreak: <br />,
            }}
          />
        </div>
      </div>
      <div className="ideas-btn">
        <Button onClick={() => history.push(ClientRouter.IDEAS_PAGE)}>
          <FormattedMessage id={Message.GO_TO_IDEAS_BUTTON} />
        </Button>
      </div>
      <Link to={ClientRouter.APP} className="home-link">
        <FormattedMessage id={Message.ONBOARDING_DONE_RETURN_HOME_BUTTON} />
      </Link>

      <div className="card">
        <div className="plan-img">
          <img alt="plan img" src={confirmationImage} />
        </div>
      </div>
    </CustomContainer>
  );
};

const CustomContainer = styled.div`
  padding: 0 24px;
  & .title {
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    text-align: center;

    @media (max-width: 767px) {
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
    }

    /* Good Validation color */
    color: #2cdd73;
    .stroke-container {
      line-height: 0px;
      ${({ theme }) =>
        theme.dir === 'rtl' &&
        css`
          transform: rotateY(180deg);
        `}
    }
    svg {
      @media (max-width: 767px) {
        width: 204px;
      }
      ${({ theme }) =>
        theme.dir === 'ltr' &&
        css`
          width: 624px;
          height: 14px;
        `}
      path {
        stroke: #2cdd73;
        fill: #2cdd73;
      }
    }
  }
  & .ideas-btn {
    margin-top: 32px;
    @media (max-width: 767px) {
      margin-top: 24px;
    }
    margin-bottom: 16px;
    button {
      font-weight: 500;
      font-size: 22px;
      width: ${({ theme }) => (theme.dir === 'rtl' ? '179px' : '208px')};
      height: 48px;
      background: #fc5c42;
      /* Primary Color */

      border: 0.5px solid #fc5c42;
      border-radius: 15px;

      @media (max-width: 767px) {
        width: 202px;
        height: 48px;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
      }
    }
  }
  & .subtitle1 {
    margin-top: 32px;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;

    text-align: center;
    @media (max-width: 767px) {
      font-weight: 500;
      font-size: 18px;
      margin-top: 22px;
      margin-top: 16px;
    }
    /* First level text */

    color: #282929;
    & .notice {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;

      /* identical to box height */
      @media (max-width: 767px) {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        margin-top: 4px;
      }
      text-align: center;

      /* Second Text level color */

      color: #3e3e3e;
    }
  }
  & .subtitle2 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    text-align: center;
    margin-top: 24px;
    /* Secondary color */
    @media (max-width: 767px) {
      font-weight: 500;
      font-size: 18px;
      margin-top: 16px;
      line-height: 22px;
    }
    color: #11516d;
    & .subtitle {
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      text-align: center;
      @media (max-width: 767px) {
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        margin-top: 4px;
      }
      /* Secondary color */
      span {
        font-weight: 500;
      }
      color: #11516d;
    }
  }
  & .stroke-container {
  }

  & .home-link {
    font-weight: 400;
    font-size: 18px;
    /* identical to box height */

    text-align: center;
    text-decoration-line: underline;
    color: #696969;
    margin-top: 24px;

    @media (max-width: 767px) {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
    }
  }
  & .card {
    width: 484px;
    height: 420px;
    margin: 0 auto 0;
    border-radius: 20px;
    justify-content: space-between;
    margin-bottom: 24px;
    @media (max-width: 767px) {
      width: 79vw;
      height: fit-content;
    }
  }
  & .plan-img {
    @media (max-width: 767px) {
      width: 327px;
      height: 285px;
    }
    img {
      height: 420px;
      width: 484px;
      object-fit: cover;
      margin-top: 24px;
      @media (max-width: 767px) {
        width: 100%;
        height: 100%;
      }
    }
  }
`;

export default UploadPlanView;
