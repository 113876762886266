import * as React from 'react';
import { useImageGuard } from 'hooks';

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {}

const SafeImg: React.FC<Props> = ({ src: originalSrc, alt, ...props }) => {
  const { src, onError } = useImageGuard(originalSrc);
  return <img {...props} alt={alt} src={src} onError={onError} />;
};

export default SafeImg;
