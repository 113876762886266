import isNumber from 'lodash/isNumber';
import { SupportedCountry, SupportedLanguage } from 'types/common.types';
import { EnumType } from 'typescript';
import {
  capitalize,
  identity,
  isString,
  lowerCase,
  pickBy,
} from './lodash.utils';

export const isProduction = process.env.REACT_APP_ENV === 'production';
export const isTest = process.env.NODE_ENV === 'test';
export const isDevelopment = process.env.NODE_ENV === 'development';

export const isRTL =
  document.documentElement &&
  document.documentElement.getAttribute('dir') === 'rtl';

export const getLocalStorage = <T>(key: string, initialValue: T | null): T => {
  const resource = localStorage.getItem(key);
  return resource ? JSON.parse(resource) : initialValue;
};
export const saveLocalStorage = <T>(key: string, value: T): void => {
  localStorage.setItem(key, JSON.stringify(value));
};
export const RemoveKeyLocalStorage = (key: string): void => {
  localStorage.removeItem(key);
};

export const sleep = <T>(response: T, tm = 1000): Promise<T> =>
  new Promise(resolve => setTimeout(() => resolve(response), tm));

export const removeEmptyValues = (collection: object) =>
  pickBy(collection, identity);

export const isPrimitive = (value: unknown) =>
  typeof value === 'string' || 'number' || 'boolean';

export const isFunction = (fn: unknown): fn is Function =>
  typeof fn === 'function';

export const asyncWrapper = <T>(promise: Promise<T>) =>
  promise
    .then(data => ({ data, error: null }))
    .catch((error: Error) => ({ data: null, error }));
export const loadScript = (url: string, callback: () => void) => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = url;
  document.getElementsByTagName('head')[0].appendChild(script);
  script.onload = callback;
};

export const stringifyError = (errors: Record<string, string[]>) => {
  const [key, values] = Object.entries(errors)[0];
  if (values?.[0]?.includes('This field'))
    return values[0].replace('This field', capitalize(lowerCase(key)));
  if (values?.[0] === 'Team Patricia role with this name already exists.')
    return values[0].replace('Team Patricia', 'A');
  if (values?.[0] === 'Invite members list with this email already exists.')
    return 'This email has already been invited.';
  if (typeof values[0] !== 'string') {
    return JSON.stringify(errors);
  }
  return values[0];
};

export const getEnumKeyByValue = (Enum: unknown) => {
  return new Map<unknown, string>(
    Object.entries(Enum as EnumType).map(([key, value]: [string, unknown]) => [
      value,
      key,
    ])
  );
};

export const invertEnum = (map: Record<string, string>) =>
  Object.entries(map).reduce(
    (acc, [key, value]) => ({ [value as string]: key, ...acc }),
    {} as Record<string, string>
  );

export const convertValueToContained = (value: string | number | unknown) => {
  if (isString(value) || isNumber(value)) {
    return value.toString().toLowerCase();
  }
  return value;
};

export const readFile = (file: File) =>
  asyncWrapper<string>(
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    })
  );

export const isSupportedLanguage = (lang: string) =>
  Object.values(SupportedLanguage).includes(lang as SupportedLanguage);

export const isSupportedCountry = (country: string | null) =>
  Object.values(SupportedCountry).includes(country as SupportedCountry);

export const removeHtmlTags = (str: string) => str.replace(/(<([^>]+)>)/gi, '');

export const isPublicApi = (url?: string) => {
  const publicApiArray = [
    '/api/users/get_location/',
    '/api/categories/',
    'api/suppliers/logos/',
    '/api/sub_categories/',
  ];
  return Boolean(publicApiArray.filter(e => url?.includes(e))?.length);
};

export const callVuplex = (message: string) => {
  // eslint-disable-next-line
  const _window: any = window;
  if (_window.vuplex) {
    _window.vuplex.postMessage(message);
  }
};

export enum ErrorCodeString {
  NOT_FOUND = 'Invalid - Not found',
  MAX_USES_REACHED = 'Invalid - Max uses reached',
  EXPIRED = 'Invalid - Expired',
  VALID = 'Valid',
}

export const checkIsMobileNumber = (
  e: React.KeyboardEvent<HTMLInputElement>
) => {
  if (
    ![
      '+',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '0',
      'Backspace',
      'ArrowUp',
      'ArrowDown',
      'ArrowRight',
      'ArrowLeft',
    ].includes(e.key) &&
    !e.ctrlKey
  )
    e.preventDefault();
};
