import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import noop from 'lodash/noop';
import styled, { css } from 'styled-components';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'components/Button';
import * as Form from 'components/forms';
// import Divider from 'components/Divider';
import TextButton from 'components/Button/TextButton';
import {
  useSession,
  useAuthentication,
  useValidateUser,
  useLocalStorage,
  useRouter,
} from 'hooks';
import { Message } from 'i18n';
import { LoginPayload } from 'store/types/authentication.types';
import { loginSchema } from 'utils/validation.utils';
import { MOBILE_RESOLUTION } from 'styles/sharedStyle';
import { Link, Redirect } from 'react-router-dom';
import { AuthRoute, ClientRouter } from 'router/routes';
import { LocalCartProduct } from 'types/products';
import { CART_KEY } from 'constants/localstorage.constants';
import Loader from 'components/Loader/Loader';
// import Providers from './providers';
import modybg from 'assets/@client/auth/seller-login-bg.png';
import modyLogo from 'assets/@client/auth/mody-vision-logo.png';
import eyeIcon from 'assets/@client/onboarding/eye-down.png';
import eyeUpIcon from 'assets/@client/onboarding/eye-up.png';
import { isEmpty } from 'lodash';
import { useMediaQuery } from '@mui/material';
import { AuthForm, Fields } from './Auth.config';

const Login = () => {
  const intl = useIntl();
  const { isAuthenticated } = useValidateUser();
  const [cartState] = useLocalStorage<LocalCartProduct[]>(CART_KEY, []);
  // eslint-disable-next-line
  const _window: any = window;
  const methods = useForm<LoginPayload>({
    resolver: yupResolver(loginSchema),
    defaultValues: {
      email: '',
      password: '',
      rememberUser: true,
    },
    mode: 'all',
  });
  const { email, password } = useWatch({
    control: methods.control,
  });

  const { login, loading: isLoading, resetError } = useAuthentication();
  const { getLocation, country, setShowWelcomeMessage } = useSession();
  const { handleSubmit, formState } = methods;
  const { isValid, submitCount } = formState;
  const { query } = useRouter<{
    redirect?: string;
    isSellerClient?: boolean | string;
  }>();
  const onSubmit = (payload: LoginPayload) => {
    setShowWelcomeMessage(true);
    login({
      ...payload,
      cart_data: cartState,
      redirect: query.redirect,
      email: payload.email.trim(),
      password: payload.password.trim(),
    });
  };
  const isMobile = useMediaQuery('(max-width: 1024px)');
  React.useEffect(() => {
    if (submitCount) {
      resetError();
    }
  }, [email, password, submitCount, resetError]);
  const [showPassword, setShowPassword] = React.useState(false);
  React.useEffect(() => {
    if (!country) getLocation();
  }, [country, getLocation]);
  if (isAuthenticated) {
    return <Redirect to={ClientRouter.APP} />;
  }

  if (
    (query.isSellerClient as string) === 'true' ||
    query.isSellerClient === true
  ) {
    return (
      // <IntlProvider locale="he">
      <SellerClientLoginContainer
        disabledButton={!isEmpty(formState.errors) || isLoading}
      >
        <div style={{ width: '50%' }}>
          <div className="form-container">
            <img src={modyLogo} alt="logo" className="title-logo" />
            <div className="title">
              ברוכים הבאים, <br />
              היכונו לחווית עיצוב {isMobile && <br />}
              <span>חדשנית</span>
            </div>
            <FormProvider {...methods}>
              <AuthForm onSubmit={handleSubmit(onSubmit)}>
                <Fields>
                  <Form.Field
                    showValidAdornment
                    onKeyDown={noop}
                    label={{
                      title: 'כתובת אימייל',
                      isBold: true,
                    }}
                    name="email"
                    type="email"
                    dir="ltr"
                    placeholder="הכנס כתובת דואר אלקטרוני"
                    inputProps={{ 'data-testid': 'email' }}
                  />

                  <div className="row">
                    <Form.Field
                      showValidAdornment
                      onKeyDown={noop}
                      label={{
                        title: 'סיסמא*',
                        isBold: true,
                      }}
                      name="password"
                      type={showPassword ? 'text' : 'password'}
                      placeholder={intl.formatMessage({
                        id: Message.AUTH_LOGIN_PASSWORD_FIELD_PLACEHOLDER,
                      })}
                      inputProps={{ 'data-testid': 'password' }}
                      dir="ltr"
                    />
                    <div
                      onClick={() => setShowPassword(prev => !prev)}
                      className="eye-div"
                    >
                      <img
                        alt="temp"
                        style={{
                          top: 38,
                        }}
                        src={showPassword ? eyeUpIcon : eyeIcon}
                      />
                    </div>
                  </div>
                </Fields>
                <Button
                  className="main-button"
                  variant="contained"
                  color="inherit"
                  type="submit"
                  disabled={!isEmpty(formState.errors) || isLoading}
                >
                  היכנס
                </Button>
              </AuthForm>
            </FormProvider>
          </div>
        </div>
        <div style={{ width: '50%' }}>
          <img src={modybg} alt="bg" className="bg-image" />
        </div>
      </SellerClientLoginContainer>
      // </IntlProvider>
    );
  }

  return (
    <Root>
      {isLoading ? (
        <LoaderWrapper>
          <Loader fullHeight />
        </LoaderWrapper>
      ) : null}
      <Form.Container
        title={intl.formatMessage({ id: Message.AUTH_LOGIN_TITLE })}
        info={intl.formatMessage({ id: Message.AUTH_LOGIN_CAPTION })}
      >
        <FormProvider {...methods}>
          <AuthForm onSubmit={handleSubmit(onSubmit)}>
            <Fields>
              <Form.Field
                showValidAdornment
                onKeyDown={noop}
                label={{
                  title: intl.formatMessage({
                    id: Message.AUTH_LOGIN_EMAIL_FIELD,
                  }),
                  isBold: true,
                }}
                name="email"
                type="email"
                placeholder={intl.formatMessage({
                  id: Message.AUTH_LOGIN_EMAIL_FIELD_PLACEHOLDER,
                })}
                dir="ltr"
                inputProps={{
                  'data-testid': 'email',
                }}
              />
              <Form.Field
                showValidAdornment
                onKeyDown={noop}
                label={{
                  title: intl.formatMessage({
                    id: Message.AUTH_LOGIN_PASSWORD_FIELD,
                  }),
                  isBold: true,
                }}
                name="password"
                type="password"
                dir="ltr"
                placeholder={intl.formatMessage({
                  id: Message.AUTH_LOGIN_PASSWORD_FIELD_PLACEHOLDER,
                })}
                inputProps={{ 'data-testid': 'password' }}
              />
            </Fields>
            <Support>
              <Form.Checkbox
                name="rememberUser"
                label={<FormattedMessage id={Message.AUTH_LOGIN_REMEMBER_ME} />}
                defaultChecked
              />
              <Link to={AuthRoute.FORGOT_PASSWORD}>
                <TextButton className="forgot-password-button">
                  <FormattedMessage
                    id={Message.AUTH_LOGIN_FORGOT_PASSWORD_BUTTON}
                  />
                </TextButton>
              </Link>
            </Support>
            <Button
              className="main-button"
              variant="contained"
              color="inherit"
              type="submit"
              disabled={!isValid || isLoading}
            >
              <FormattedMessage id={Message.AUTH_LOGIN_SUBMIT_BUTTON} />
            </Button>

            {/* {!_window.vuplex && (
              <>
                <Divider>
                  <FormattedMessage id={Message.OR} />
                </Divider>
                {/* <AuthProvidersWrapper>
                  <Providers authContext="Login" />
                </AuthProvidersWrapper>{' '} 
              </>
            )} */}
          </AuthForm>
        </FormProvider>
      </Form.Container>
    </Root>
  );
};

export default Login;

const Root = styled.div`
  overflow: auto;
`;

const SellerClientLoginContainer = styled.div<{ disabledButton?: boolean }>`
  display: flex;
  height: 100vh;
  direction: rtl;
  .bg-image {
    height: 100%;
    width: 100%;
  }
  .form-container {
    margin: auto;
    width: fit-content;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    position: relative;
    .row {
      position: relative;
      .eye-div {
        position: absolute;
        top: 40px;
        right: 30px;
      }
    }
    .title-logo {
      width: 354px;
      height: 149px;
      margin-bottom: 24px;
    }
    .title {
      font-weight: 600;
      font-size: 40px;
      line-height: 44px;
      color: #3e3e3e;
      margin-bottom: 32px;
    }
    .MuiFormControl-root {
      direction: rtl;
      label {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #696969;
        left: unset;
        right: 0;
        transform: translate(0, 1.5px);
      }
    }
    .MuiInputBase-root {
      width: 410px;
      margin-top: 1.5rem;
    }
    .main-button {
      width: 410px;
      height: 48px;

      /* Second Text level color */

      background: #3e3e3e;
      /* Second Text level color */

      border: 0.5px solid #3e3e3e;
      border-radius: 15px;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      margin-top: 36px;
      color: rgba(255, 255, 255, 0.9);
      cursor: pointer;
      ${({ disabledButton }) =>
        disabledButton &&
        css`
          background: #d9d9d9;
          /* Disabled */
          color: #ffffff;
          border: 0.5px solid #d9d9d9;
          border-radius: 15px;
        `}
    }
  }
  @media (max-width: 1024px) {
    background: url(${modybg});
    background-size: cover;
    && > :nth-child(1) {
      width: 100% !important;
      .form-container {
        background: rgba(250, 250, 250, 0.8);
        border-radius: 20px;
        padding: 48px 24px;
        margin: auto auto;
        .title-logo {
          width: 247px;
          height: 104px;
        }
        .title {
          font-weight: 600;
          font-size: 24px;
          line-height: 28px;
          span {
            font-style: normal;
            font-weight: 600;
            font-size: 34px;
            background: linear-gradient(
              90deg,
              #282929 -7.66%,
              #353636 19.66%,
              #434444 46.98%,
              #525252 77.21%,
              #858585 103.95%
            );
            background-clip: text;
            text-fill-color: transparent;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        .MuiInputBase-root {
          width: 295px;
          background: #fafcfe;
          border-radius: 10px;
        }
        .MuiFormControl-root {
          margin: 0;
        }
        .main-button {
          width: 295px;
        }
      }
    }
    && > :nth-child(2) {
      display: none;
      width: 0px;
    }
  }
`;

const LoaderWrapper = styled.div`
  z-index: 1;
  width: 100%;
  height: 100%;
  font-size: 0;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
`;
const Support = styled.section.attrs({ className: 'auth-support-section' })`
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
  padding: 0 40px;
  width: 100%;
  overflow: auto;

  a {
    color: inherit;
  }

  .forgot-password-button {
    font-family: ${({ theme }) => theme.dir === 'rtl' && 'Assistant'};
  }

  @media (min-width: 1500px) {
    padding: 0 85px;
    margin: 48px 0;
  }

  @media ${MOBILE_RESOLUTION} {
    padding: 0 15px;
  }

  & .MuiFormControlLabel-root {
    margin-left: 0;
  }

  & .MuiTypography-root {
    font-size: 0.85rem;
    font-weight: 500;
  }
`;
