import * as React from 'react';
import { useRouter } from 'hooks';
import * as Transform from 'api/transform';
import * as Response from 'api/responses';
import { IdeasDetails } from 'types/products';
import useFetch from './useFetch';

const useIdeaKPIs = (idea?: IdeasDetails) => {
  const [ideaList, setIdeaList] = React.useState<IdeasDetails[]>([]);

  const [pagination, setPagination] = React.useState<{
    count: number;
    next: string | number | null;
    previous: string | number | null;
  }>({
    count: 0,
    next: null,
    previous: null,
  });

  const { query } = useRouter<{
    id: string;
  }>();
  const { callFetch, data: ideaDetails, isLoading, hasError } = useFetch<
    IdeasDetails,
    Response.IdeasDetails
  >({
    initialUrl: `/api/ideas/${query.id}`,
    skipOnStart: true,
    transform: Transform.ideaDetailsData,
  });

  const onSuccess = React.useCallback((res: Response.ideaList | null) => {
    setIdeaList(prev =>
      res?.results ? [...prev, ...Transform.ideaList(res.results)] : prev
    );
    setPagination({
      count: res?.count ?? 0,
      next: res?.next ?? null,
      previous: res?.previous ?? null,
    });
  }, []);

  const {
    callFetch: onLoad,
    isLoading: isListLoading,
  } = useFetch<Response.ideaList | null>({
    initialUrl: '/api/ideas/',
    skipOnStart: true,
    onSuccess,
  });
  const {
    callFetch: loadProducts,
    data: products,
    isLoading: isProductsLoading,
  } = useFetch({
    initialUrl: `/api/ideas/${query.id}/products/`,
    skipOnStart: true,
    transform: Transform.transformideaProducts,
  });

  const onGetIdeaDetails = React.useCallback(() => {
    callFetch({ url: `/api/ideas/${query.id}/` });
  }, [callFetch, query.id]);

  const getIdeaProducts = React.useCallback(() => {
    loadProducts({ url: `/api/ideas/${query.id}/products/` });
  }, [loadProducts, query.id]);

  return {
    ideaDetails: idea ?? ideaDetails,
    loadMoreIdea: callFetch,
    isLoading,
    onGetIdeaDetails,
    onLoad,
    ideaList,
    isListLoading,
    pagination,
    hasError,
    getIdeaProducts,
    products,
    isProductsLoading,
  };
};

export default useIdeaKPIs;
