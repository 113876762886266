import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import Skeleton from '@material-ui/core/Skeleton';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled, { css } from 'styled-components';
import TextButton from 'components/Button/TextButton';
import { ISOCode } from 'data/countries';
import { getCountryName, stringifyAddress } from 'utils/addresses.utils';
import { IAddress } from 'types/common.types';

interface Props {
  address: IAddress;
  isLoading?: boolean;
  action?: {
    label: string | React.ReactNode;
    disabled?: boolean;
    onClick: VoidFunction;
  };
  linkProps?: LinkProps;
}

const AddressCard: React.FC<Props> = ({
  address,
  isLoading,
  action,
  linkProps,
}) => {
  return (
    <Container>
      <div className="addresses-details">
        <span className="addresses-details-item">
          {getCountryName(address.country as ISOCode)}
        </span>
        <span className="addresses-details-item">
          {stringifyAddress(address, true)}
        </span>
        <span className="addresses-details-item">
          {action && (
            <ActionButton
              $noDecoration
              className="btn"
              disabled={action.disabled}
              onClick={action.onClick}
            >
              {action.label}
            </ActionButton>
          )}
        </span>
      </div>
      <div className="addresses-actions">
        {linkProps && <Link className="link" {...linkProps} />}
        {isLoading ? (
          <span>
            <CircularProgress />
          </span>
        ) : (
          address.isPrimary && <span>&#10004;</span>
        )}
      </div>
    </Container>
  );
};

export const LoaderSkeleton = () => (
  <div className="address-card">
    <div className="addresses-details">
      <Skeleton variant="text" width={50} />
      <Skeleton variant="text" width={100} />
      <Skeleton variant="text" width={100} />
    </div>
  </div>
);

export default AddressCard;

const Container = styled.section.attrs({ className: 'address-card' })`
  min-height: 138px;
  display: flex;
  justify-content: space-between;
  background: #f4f6f9;
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 30px;
  .addresses-details {
    width: 90%;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    max-width: 30ch;
    .addresses-details-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .addresses-details-item:first-of-type {
      margin-bottom: 6px;
    }
    .addresses-details-item:last-of-type {
      margin-top: auto;
    }
  }
  .addresses-actions {
    width: 10%;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .link {
      color: #2277cc;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

const ActionButton = styled(TextButton)`
  font-weight: 500;
  font-family: ${({ theme }) => theme.dir === 'rtl' && 'Assistant'};
  ${({ disabled }) =>
    !disabled &&
    css`
      color: #2277cc !important;
    `}
`;
