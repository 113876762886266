import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import {
  format,
  differenceInCalendarMonths,
  isWithinInterval,
  startOfMonth,
} from 'date-fns';
import Icon from 'components/Icon';
import styled from 'styled-components';
import SelectActions from 'components/Select/SelectActions';
import { generateYears } from 'utils/dates.utils';
import Month from './Month';
import DefinedRanges from './DefinedRanges';
import {
  DateRange,
  DefinedRange,
  Setter,
  NavigationAction,
  Marker,
  MenuProps as menuProps,
  SetDateRangeReason,
} from './types';

export const MARKERS: { [key: string]: Marker } = {
  FIRST_MONTH: Symbol('firstMonth'),
  SECOND_MONTH: Symbol('secondMonth'),
};

interface MenuProps {
  dateRange: DateRange;
  ranges: DefinedRange[];
  minDate: Date;
  maxDate: Date;
  firstMonth: Date;
  secondMonth: Date;
  setFirstMonth: Setter<Date>;
  setSecondMonth: Setter<Date>;
  setDateRange: (
    range: DateRange | undefined,
    reason?: SetDateRangeReason
  ) => void;
  helpers: {
    inHoverRange: (day: Date) => boolean;
  };
  handlers: {
    onDayClick: (day: Date) => void;
    onDayHover: (day: Date) => void;
    onMonthNavigate: (marker: symbol, action: NavigationAction) => void;
  };
  MenuProps: menuProps;
  yearsShown: number;
  applyButton: true | undefined;
  isValidRange: boolean;
  onClick: (dateRange: DateRange | undefined) => void;
  onClear?: () => void;
}

const Menu: React.FC<MenuProps> = (props: MenuProps) => {
  const {
    ranges,
    dateRange,
    minDate,
    maxDate,
    firstMonth,
    setFirstMonth,
    secondMonth,
    setSecondMonth,
    setDateRange,
    helpers,
    handlers,
    MenuProps,
    yearsShown,
  } = props;

  const { startDate, endDate } = dateRange;
  const commonProps = {
    dateRange,
    minDate,
    maxDate,
    helpers,
    handlers,
    MenuProps,
    yearsShown,
  };

  const years = React.useMemo(() => generateYears(yearsShown), [yearsShown]);

  const canNavigateCloser =
    differenceInCalendarMonths(secondMonth, firstMonth) >= 2;

  const canNavigateFurther = React.useCallback(
    (date: Date) =>
      isWithinInterval(date, {
        start: minDate,
        end: startOfMonth(maxDate),
      }),
    [maxDate, minDate]
  );

  return (
    <Paper>
      <Main>
        <div>
          <Header>
            <Date>
              <Title>
                {startDate
                  ? format(startDate, 'MMMM dd, yyyy')
                  : 'Choose Start Date'}
              </Title>
            </Date>
            <ArrowContainer>
              <Icon width={28} name="right-arrow" color="red" />
            </ArrowContainer>
            <Date>
              <Title>
                {endDate ? format(endDate, 'MMMM dd, yyyy') : 'Choose End Date'}
              </Title>
            </Date>
          </Header>
          <HorizontalDivider />
          <Calendar>
            <Month
              {...commonProps}
              value={firstMonth}
              setValue={setFirstMonth}
              navState={[canNavigateFurther(firstMonth), canNavigateCloser]}
              marker={MARKERS.FIRST_MONTH}
              MenuProps={MenuProps}
              years={years}
            />
            <VerticalDivider />
            <Month
              {...commonProps}
              value={secondMonth}
              setValue={setSecondMonth}
              navState={[canNavigateCloser, canNavigateFurther(secondMonth)]}
              marker={MARKERS.SECOND_MONTH}
              MenuProps={MenuProps}
              years={years}
            />
          </Calendar>
          <HorizontalDivider />
          <Footer>
            <TimePicker />
            <ActionWrapper>
              <SelectActions
                onClear={() => setDateRange(undefined, 'clear')}
                onApply={() => setDateRange(dateRange, 'apply')}
              />
            </ActionWrapper>
          </Footer>
        </div>
        <VerticalDivider />
        <DefinedRanges
          selectedRange={dateRange}
          ranges={ranges}
          setRange={setDateRange}
        />
      </Main>
    </Paper>
  );
};

export default Menu;

const Title = styled(Typography).attrs({ variant: 'h6' })`
  && {
    font-size: 1rem;
    font-weight: 800;
  }
`;

const Footer = styled.section`
  display: flex;
  height: 70px;
  flex: 0.7 0.3;
`;

const TimePicker = styled.div`
  display: flex;
  width: 75%;
`;

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 25%;
`;

const ArrowContainer = styled.div`
  position: relative;
  top: 3px;
`;

const Header = styled.section`
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

const Date = styled.div`
  display: flex;
  align-items: center;
  color: #242e4c;
  font-weight: 800;
`;

const Calendar = styled.section`
  display: flex;
  justify-content: center;
`;

const VerticalDivider = styled.div`
  border-left: 1px solid #e5e9f4;
`;

const HorizontalDivider = styled(Divider)`
  background-color: rgba(229, 233, 244, 0.7);
  height: 2px;
`;

const Main = styled.section`
  display: flex;
`;
