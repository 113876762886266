import * as React from 'react';
import styled, { css } from 'styled-components';
import rtl from 'styled-components-rtl';
import { FormattedMessage, useIntl } from 'react-intl';
import { Message } from 'i18n';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Breadcrumbs from 'components/@client/Breadcrumbs';
import Button from 'components/@client/Button';
import Title from 'components/@client/TopTitle';
import TextButton from 'components/Button/TextButton';
import TextInput from 'components/@client/form-components/TextInput';
import ImagePicker from 'components/@client/form-components/ImagePicker';
import FormDivider from 'components/Divider/FormDivider';
import PhoneInput from 'components/forms/PhoneInput';
import { useAuthentication, useRouter, useFetch } from 'hooks';
import { ClientRouter } from 'router/routes';
import {
  ClientRoot,
  MOBILE_RESOLUTION,
  MOBILE_TABLET_RESOLUTION,
} from 'styles/sharedStyle';
import { userProfileSchema } from 'utils/validation.utils';
import { prefixPhoneNumber } from 'utils/string.utils';
import { isEmpty } from 'utils/lodash.utils';
import { LogsAPI } from 'api/methods';
import { userLogData } from 'mockData/userLogData';
import { Divider } from '@mui/material';
import { BaseUserResponse } from 'types/common.types';
import useUpdateUser from './hooks/useUpdateUser';
import { UserBasicForm } from './config';
import UserAddresses from './AddressSection';
import ConfirmChangesModal from './ConfirmChangesModal';
import InformationBlock from './InformationBlock';
import { ReactComponent as UserIcon } from '../../../assets/@client/profile/addresses/user-image-icon.svg';
import DeleteAccount from './DeleteAccount';

const links = [
  {
    to: ClientRouter.PROFILE,
    children: <FormattedMessage id={Message.MENU_ITEM_PROFILE} />,
  },
];
const Profile = () => {
  const intl = useIntl();
  const isMobile = useMediaQuery(MOBILE_TABLET_RESOLUTION);
  const {
    firstName = '',
    lastName = '',
    email,
    id,
    phoneNumber = '',
    profilePicture,
    updateUser: updateUserAction,
    type,
  } = useAuthentication();
  // eslint-disable-next-line
  let _window: any = window;

  const { history } = useRouter();
  const { updateUser, isLoading, successNotification } = useUpdateUser(
    id as number,
    (data: BaseUserResponse) => {
      methods.reset({}, { dirtyFields: true });
      updateUserAction(data);
    }
  );
  const methods = useForm<UserBasicForm>({
    mode: 'all',
    defaultValues: { firstName, lastName, phoneNumber, profilePicture },
    resolver: yupResolver(userProfileSchema),
  });

  const { handleSubmit, control, formState, setValue } = methods;

  const { isValid, isDirty, isSubmitSuccessful } = formState;
  const onSubmit = React.useCallback(
    (info: UserBasicForm) => {
      LogsAPI.postUserLogs({
        area: userLogData.eventArea.myProfile,
        section: userLogData.eventSection.mainPage,
        name: userLogData.eventName.saveButton,
        path: userLogData.eventPath.appMyAccountEditPath,
      });
      updateUser({
        data: {
          first_name: info.firstName,
          last_name: info.lastName,
          phone_number: info.phoneNumber
            ? prefixPhoneNumber(info.phoneNumber)
            : '',
          profile_picture: info.profilePicture || null,
        },
      });
    },
    [updateUser]
  );

  const { data: userCoinData } = useFetch<{ coins: number }>({
    initialUrl: `/api/users/${id}/`,
  });
  const [isOpen, setIsOpen] = React.useState(false);
  const setImageData = React.useCallback(
    (data: string | undefined) => {
      LogsAPI.postUserLogs({
        area: userLogData.eventArea.myProfile,
        section: userLogData.eventSection.mainPage,
        name: userLogData.eventName.profilePicture,
        path: userLogData.eventPath.appMyAccountEditPath,
      });
      if (profilePicture !== data)
        setValue('profilePicture', data, {
          shouldDirty: profilePicture !== data,
        });
    },
    [setValue, profilePicture]
  );
  return (
    <Root>
      <Breadcrumbs links={links} />
      <ContentWrapper>
        <Header>
          <Title title={<FormattedMessage id={Message.MENU_ITEM_PROFILE} />} />
        </Header>
        <FormProvider {...methods}>
          <FormWrapper>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <ImagePickerDiv>
                <ImagePicker
                  name="profilePicture"
                  label="Profile Picture"
                  control={control}
                  setImageData={setImageData}
                  defaultValue={profilePicture}
                />
              </ImagePickerDiv>
              <TextInput
                name="firstName"
                control={control}
                label={intl.formatMessage({ id: Message.FIRST_NAME })}
                required
              />
              <TextInput
                name="lastName"
                control={control}
                label={intl.formatMessage({ id: Message.LAST_NAME })}
                required
              />
              <PhoneInput
                name="phoneNumber"
                label={{
                  title: intl.formatMessage({ id: Message.PHONE_NUMBER }),
                }}
                disableCountryCode
              />
              <h5 className="email-title">
                <FormattedMessage id={Message.EMAIL_TITLE} />
              </h5>
              <p className="email-value"> {email}</p>
              {!_window.vuplex && (
                <BuyCoinButton
                  className="buy-coin-link"
                  onClick={() => {
                    history.push(ClientRouter.BUY_MORE_PATRICIA_COINS);
                  }}
                >
                  <FormattedMessage
                    id={Message.BUY_PATRICIA_COINS_BUTTON_TITLE}
                    values={{
                      count: (
                        <span className="count">
                          &nbsp; {userCoinData?.coins} &nbsp;
                        </span>
                      ),
                    }}
                  />
                </BuyCoinButton>
              )}

              <FormDivider className="divider" />
              <UserAddresses userId={id as number} />
              <DeleteAccount open={isOpen} onClose={() => setIsOpen(false)} />
              {!isMobile && type !== 'SUPPLIER' && (
                <>
                  <br />
                  <DividerContainer />
                  <DeleteButtonContainer>
                    <div className="delete-text">
                      <UserIcon className="user-icon" />
                      <div className="delete-h1-p">
                        <h1 className="delete-title">
                          <FormattedMessage
                            id={Message.USER_DELETE_ACCOUNT_HEADING_1}
                          />
                        </h1>
                        <p>
                          <FormattedMessage
                            id={Message.USER_DELETE_ACCOUNT_HEADING_2}
                          />
                        </p>
                      </div>
                    </div>

                    <Button
                      type="button"
                      className="delete-add-btn"
                      onClick={() => setIsOpen(true)}
                    >
                      <p>
                        <FormattedMessage
                          id={Message.USER_DELETE_ACCOUNT_BUTTON}
                        />
                      </p>
                    </Button>
                  </DeleteButtonContainer>
                </>
              )}
              <Button
                type="submit"
                className="submit-btn"
                disabled={!isValid || !isDirty}
                isLoading={isLoading}
              >
                <FormattedMessage id={Message.SAVE_TITLE} />
              </Button>
              {isMobile && type !== 'SUPPLIER' && (
                <>
                  <br />
                  <DividerContainer />
                  <DeleteButtonContainer>
                    <div className="delete-text">
                      <UserIcon className="user-icon" />
                      <div>
                        <h1 className="delete-title">
                          <FormattedMessage
                            id={Message.USER_DELETE_ACCOUNT_HEADING_1}
                          />
                        </h1>
                        <p>
                          <FormattedMessage
                            id={Message.USER_DELETE_ACCOUNT_HEADING_2}
                          />
                        </p>
                      </div>
                    </div>

                    <Button
                      type="button"
                      className="delete-add-btn"
                      onClick={() => setIsOpen(true)}
                    >
                      <p>
                        <FormattedMessage
                          id={Message.USER_DELETE_ACCOUNT_BUTTON}
                        />
                      </p>
                    </Button>
                  </DeleteButtonContainer>
                </>
              )}
            </Form>
            {!isMobile && <InformationBlock />}
          </FormWrapper>
        </FormProvider>
      </ContentWrapper>
      <ConfirmChangesModal
        action={handleSubmit(onSubmit)}
        isLoading={isLoading}
        when={isDirty && isValid && !isSubmitSuccessful}
        shouldRedirect={isSubmitSuccessful && !isEmpty(successNotification)}
      />
    </Root>
  );
};

export default Profile;
const Root = styled(ClientRoot)`
  .phoneNumber {
    margin: -6px 0 24px;
    & .flag-dropdown,
    & .flag-dropdown.open {
      height: 48px;
    }

    & .form-control {
      height: 48px;
    }
  }
  .country-list {
    & .search {
      & .search-box {
        height: 48px;
      }
    }
  }

  .email-title {
    color: #797979;
    font-size: 20px;
    margin-bottom: 4px;
    margin-top: 0;
  }
  .email-title {
    color: ${({ theme }) => theme.colors.black0};
    font-size: 16px;
  }
  .buy-coin-link {
  }
  && .submit-btn {
    width: 100%;
    @media (max-width: 767px) {
      margin-bottom: 16px;
    }
    @media (min-width: 800px) {
      position: absolute;
      ${rtl`
      
      top: 0;
      right: 0;
      `}
      width: 56px;
    }
  }
`;

const ContentWrapper = styled.div`
  position: relative;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px;
  max-width: unset;

  @media (min-width: 800px) {
    margin-bottom: 10px;
    max-width: 561px;
  }
`;

const DeleteButtonContainer = styled.div`
  display: flex;
  @media ${MOBILE_RESOLUTION} {
    display: flex;
    flex-direction: column;
    margin-left: 24px;
    margin-bottom: 24px;
  }
  margin-top: 14px;
  justify-content: space-between;
  .delete-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #282929;
  }
  .delete-subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #696969;
    margin-top: -12px !important;
  }
  .delete-text {
    display: flex;
  }
  .delete-h1-p {
    ${rtl`
     margin-left:12px;
      `}
  }
  .delete-add-btn {
    width: 156px;
    height: 40px;
    background: #edf0f5;
    border-radius: 15px;
    color: ${({ theme }) => theme.colors.black0};
    font-size: 18px;

    ${rtl`
     margin-right:28px;
      `}
    &:hover {
      background-color: rgba(138, 156, 182, 0.5);
    }

    @media ${MOBILE_TABLET_RESOLUTION} {
      font-size: 16px;
    }
  }
  .user-icon {
    width: 23.98px;
    height: 23.59px;
    margin-right: 8.2px;
    margin-top: 11px;
  }
`;
const DividerContainer = styled(Divider)`
  /* margin-top: 13px !important; */
`;

const FormWrapper = styled.div.attrs({ className: 'form-wrapper' })`
  @media (min-width: 800px) {
    display: flex;
    justify-content: space-between;
  }
`;

const Form = styled.form`
  flex: 0.7;

  @media (min-width: 800px) {
    max-width: 561px;
    & .text-input-root,
    & .phoneNumber {
      max-width: 60%;
    }
  }
`;
const ImagePickerDiv = styled.div`
  padding: 0 90px;
`;

const BuyCoinButton = styled(TextButton)`
  font-weight: 500;
  font-family: ${({ theme }) => theme.dir === 'rtl' && 'Assistant'};
  ${({ disabled }) =>
    !disabled &&
    css`
      color: #2277cc !important;
    `}
  text-decoration: auto !important;
  text-transform: initial !important;
  &:hover {
    text-decoration: underline !important;
  }
`;
