import * as React from 'react';
import * as Response from 'api/responses';
import * as Transform from 'api/transform';
import { useAuthentication, useFetch } from 'hooks';
import { Cart } from 'types/properties';
import {
  setCartCount,
  setCartData,
} from 'store/actions/@client/cartInfo.actions';
import { useDispatch } from 'react-redux';
import { removeEmptyValues } from 'utils/common.utils';

type UsePropertyCartsConfig = {
  onSuccess?: (carts: Cart | null) => void;
  propertyId?: string | null;
  skipOnStart?: boolean;
  isForCount?: boolean;
};

const usePropertyCarts = (config: UsePropertyCartsConfig = {}) => {
  const { onSuccess, skipOnStart = false, isForCount } = config;
  const { id } = useAuthentication();
  const dispatch = useDispatch();

  const { data: carts, isLoading, callFetch: fetchCart } = useFetch<
    Cart,
    Response.Cart | Response.PaginatedCart
  >({
    initialUrl: isForCount
      ? `/api/shopping_cart_item/total/`
      : `/api/shopping_cart_item/`,
    config: {
      params: removeEmptyValues({ user: id, size: isForCount ? 1 : null }),
    },
    transform: isForCount
      ? undefined
      : (response: Response.Cart | Response.PaginatedCart) => {
          return Transform.transformCart(response as Response.Cart);
        },
    onSuccess: isForCount
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (res: any) => {
          dispatch(setCartCount(res?.total));
        }
      : onSuccess,
    skipOnStart,
  });

  React.useEffect(() => {
    if (carts && !isForCount) {
      dispatch(setCartData(carts));
    }
  }, [carts, dispatch, isForCount]);

  const setCartCounts = React.useCallback(
    (count: number) => {
      dispatch(setCartCount(count));
    },
    [dispatch]
  );

  return {
    carts,
    isLoading,
    fetchCart,
    setCartCounts,
  };
};

export default usePropertyCarts;
