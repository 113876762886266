import * as React from 'react';
import styled from 'styled-components';
import InformationField from 'components/@client/InformationField';

interface Props {
  icon: JSX.Element;
  title: string | React.ReactNode;
  text: string | React.ReactNode;
}
const InfoBox: React.FC<Props> = ({ icon, title, text }) => {
  return (
    <Container className="info-box">
      <InformationField icon={icon} title={title}>
        {text}
      </InformationField>
    </Container>
  );
};

export default InfoBox;

const Container = styled.section.attrs({ className: 'info-box' })`
  flex: 0.3;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  min-height: 200px;
  padding: 50px 30px;
  align-self: flex-start;
  max-width: 400px;
  top: 105px;
  margin-block-start: 1em;
`;
