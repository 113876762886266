import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { useImageGuard } from 'hooks';
import { summarizeText } from 'utils/string.utils';
import { LogsAPI } from 'api/methods';
import { userLogData } from 'mockData/userLogData';
import { Skin } from 'types/properties';

interface Props extends LinkProps {
  item: Skin;
}

const SkinItem: React.FC<Props> = ({ item, to }) => {
  const { src, onError } = useImageGuard(item.thumbnail);
  return (
    <ListItem>
      <CustomLink
        to={to}
        onClick={() =>
          LogsAPI.postUserLogs({
            name: userLogData.eventName.openSkin,
            area: userLogData.eventArea.homePage,
            section: userLogData.eventSection.skins,
            path: userLogData.eventPath.appPath,
            metadata: { skin_type: item.skinCategory },
          })
        }
      >
        <Background />
        <ImageContainer>
          <img src={src} alt={item.name} onError={onError} />
        </ImageContainer>
        <TextWrapper>
          <Label>{summarizeText(item.name, 15)}</Label>
        </TextWrapper>
      </CustomLink>
    </ListItem>
  );
};

export default SkinItem;

const ListItem = styled.li`
  position: relative;
`;

const CustomLink = styled(Link)`
  font: inherit;
  color: inherit;
  text-decoration: none;
`;

const Background = styled.div`
  z-index: 10;
  position: absolute;
  background: transparent;
  width: 143px;
  height: 200px;
  border-radius: 25px;

  @media (min-width: 800px) {
    width: 305px;
    height: 309px;
  }
`;

const ImageContainer = styled(Background)`
  position: relative;
  z-index: 5;
  opacity: 1;
  & > img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 25px;
  }
`;

const TextWrapper = styled.div`
  ${rtl`
    margin: 10px 0 10px 15px;
  `}
`;

const Label = styled.p`
  font-size: 0.875rem;
  font-weight: 700;
  margin: 0;
  padding: 0;

  @media (min-width: 800px) {
    font-size: 1.25rem;
  }
`;
