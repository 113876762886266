import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import AuthIcon from 'assets/@client/auth';
import lamp from 'assets/@client/auth/lamp.svg';
import background from 'assets/@client/auth/background.png';
import Button from 'components/@client/Button';
import Divider from 'components/Divider';
import { useRouter, useSession } from 'hooks';
import { Message } from 'i18n';
import { TitleStack } from 'pages/appClient/onboarding/shared';
// import Providers from 'pages/auth/providers';
// import { AuthProvidersWrapper } from 'styles/sharedStyle';
import { AuthRoute, ClientRouter } from 'router/routes';
import LanguageToggle from 'components/@client/LanguageToggle/LanguageToggle';

const Auth = () => {
  const { history } = useRouter();
  const { onLanguageChange } = useSession();
  // eslint-disable-next-line
  const _window: any = window;
  return (
    <Root>
      <Controller>
        <TitleStack
          label={<FormattedMessage id={Message.CLIENT_AUTH_TITLE} />}
          forceLogo
        />
        <CustomButton
          startIcon={<AuthIcon.Property />}
          onClick={() => history.push(ClientRouter.ONBOARDING)}
          title={ClientRouter.ONBOARDING}
        >
          <FormattedMessage id={Message.CLIENT_AUTH_ONBOARDING_BUTTON} />
        </CustomButton>
        <Center>
          <Divider>
            <FormattedMessage id={Message.OR} />
          </Divider>
        </Center>
        <CustomButton
          role="link"
          className="mail"
          startIcon={<AuthIcon.Mail />}
          onClick={() => history.push(AuthRoute.LOGIN)}
        >
          <FormattedMessage id={Message.CLIENT_AUTH_EMAIL_PASSWORD_BUTTON} />
        </CustomButton>
        {/* {!_window.vuplex && (
          <AuthProvidersWrapper>
            <Providers authContext="Login" forceLabel />
          </AuthProvidersWrapper>
        )} */}
        <LanguageButton onClick={onLanguageChange}>
          <LanguageToggle />
          <Lamp />
        </LanguageButton>
      </Controller>
      <img
        src={background}
        alt="A thriving green hanging plant is the focal point of an office with an orange wall, two clocks, a wall hanging, and a grey desk. There's a computer, a lamp, and a pencil cup on the desk and a plant next to it on the floor."
      />
    </Root>
  );
};

export default Auth;

const Root = styled.section`
  position: relative;
  height: 100vh;
  display: flex;
  overflow: auto;
  & > img {
    object-fit: cover;
  }

  @media (max-width: 1150px) {
    & > img {
      display: none;
    }
  }

  @media (min-width: 800px) {
    & > * {
      flex: 1;
    }

    & .title-stack {
      flex-direction: column;

      & a {
        align-self: center;
      }
    }
  }

  & .MuiButton-label {
    justify-content: flex-start;
  }
  & .title-stack {
    margin-top: 0;
    width: 100%;
    padding: 0 20px;
  }

  & .provider-buttons {
    flex-direction: column-reverse;
    flex: 0.3;
    align-self: unset;
    width: 100%;
    max-width: 320px;
    z-index: 1;

    @media (min-width: 800px) {
      align-self: center;
    }
  }

  & .provider-button-wrapper {
    width: 100%;
    margin: 22px 0 0;
  }

  & .provider-button {
    width: 100%;
    display: flex;
    align-items: center;

    & span {
      font-size: 0.875rem;
    }

    &.Facebook {
      background: #2f80ed;
    }
    &.Google {
      background: #23643e;

      & span {
      }
    }
    &.Apple {
      background: rgba(0, 0, 0, 1);
    }
    color: white;
    max-height: 50px;
    width: 100%;

    & > span {
      gap: 0;
    }
    & img {
      border-radius: 50%;

      ${rtl`
        margin-right: 17px;
      `}
      max-width: 800px;
    }
  }
`;

const Controller = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;

  & a {
    position: absolute;
  }

  @media (max-width: 700px) {
    h2 {
      min-height: 59px;
      align-items: center;
      display: flex;
    }
  }

  @media (min-width: 700px) {
    & .title-stack {
      flex-direction: column;
      & a {
        position: relative;
        margin-bottom: 40px;
        align-self: center;
        svg {
          height: 119px;
          width: 79px;
        }
      }
    }
  }
`;

const CustomButton = styled(Button)`
  width: 100%;
  max-width: 320px;

  && {
    padding: 15px 15px;
    border-radius: 5px;

    @media (min-width: 1500px) {
      padding: 15px 7px;
    }
  }

  &&:first-of-type {
    margin-top: 54px;

    @media (min-width: 1150px) {
      margin-top: 20px;
    }

    @media (min-width: 1500px) {
      margin-top: 80px;
    }
  }

  &&.mail {
    background: transparent;
    border: 1.5px solid rgba(66, 66, 66, 1);
    color: rgba(66, 66, 66, 1);
  }

  && .MuiButton-startIcon {
    ${rtl`
      margin-right: 8px;
      margin-left: -4px;
    `}
  }
`;

const Center = styled.div`
  & .divider-wrapper {
    margin: 40px auto;
    border-color: rgba(245, 245, 245, 1);
    width: 300px;

    @media (min-width: 1150px) {
      margin: 20px auto;
    }
  }
`;

const Lamp = styled.img.attrs({
  src: lamp,
  alt: 'A lamp',
})`
  position: absolute;
  bottom: 0;
  right: 30px;
  height: 18%;

  @media (max-height: 600px) {
    display: none;
  }

  @media (min-width: 800px) {
    display: none;
  }
  @media (max-height: 660px) and (max-width: 400px) {
    display: none;
  }
  @media (min-height: 700px) and (min-width: 500px) {
    width: 200px;
  } ;
`;

const LanguageButton = styled.button.attrs({
  className: 'language-button',
  type: 'button',
})`
  background: transparent;
  border: none;
  color: #2675ad;
  font-size: 1.25rem;
  margin: 20px auto;
`;
