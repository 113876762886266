import * as React from 'react';

const useAddressToggle = (initialState: boolean = false) => {
  const [showAddress, setShowAddress] = React.useState<boolean>(initialState);

  const onAddressToggle = React.useCallback(
    (_, isOpen: boolean) => {
      setShowAddress(isOpen);
    },
    [setShowAddress]
  );

  return { showAddress, onAddressToggle };
};

export default useAddressToggle;
