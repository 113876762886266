import * as React from 'react';
import { Grid } from '@material-ui/core';
import Radio, { RadioProps } from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import QuantityCounter from 'components/@client/QuantityCounter';
import { ProductCoin } from 'types/products';
import { toCurrencyString } from 'utils/string.utils';

interface Props {
  data: ProductCoin[];
  onQuantityChange: (id: string, quantity: number) => void;
  onSelectChange: (id: string) => void;
  defaultSelected: string;
}

const CoinList: React.FC<Props> = ({
  data,
  onQuantityChange,
  onSelectChange,
  defaultSelected,
}) => {
  const [selectedValue, setSelectedValue] = React.useState<string>('');

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setSelectedValue((event.target as HTMLInputElement).value);
      onSelectChange((event.target as HTMLInputElement).value);
    },
    [onSelectChange]
  );

  React.useEffect(() => {
    setSelectedValue(defaultSelected);
  }, [defaultSelected]);

  return (
    <List>
      {data.map((e: ProductCoin, i: number) => {
        const labelType = e.englishName.substring(
          e.englishName.indexOf('(') + 1,
          e.englishName.lastIndexOf(')')
        );
        const labelName =
          e.englishName.indexOf('(') > -1
            ? e.englishName.substr(0, e.englishName.indexOf('('))
            : e.englishName;

        const labelOffer =
          e.englishName.indexOf('Save') > -1
            ? e.englishName.substring(e.englishName.indexOf('Save'))
            : null;

        return (
          <ListItem key={`${e.id.toString() + i.toString()}`}>
            <ListItemDetail width="65%">
              <ProductTitle>
                <RadioControlLabel
                  className="radio-button-label"
                  disabledColor={selectedValue !== e.id.toString()}
                  control={
                    <StyledRadio
                      checked={selectedValue === e.id.toString()}
                      onChange={handleChange}
                      className="radio-button"
                      sx={{
                        color: '#D9D9D9',
                        '&.Mui-checked': {
                          color: '#FC5C42',
                        },
                      }}
                    />
                  }
                  label={
                    <div className="label-wrapper">
                      <span className="label-name">{`${labelName}`}</span>
                      {labelType && (
                        <span className="label-type">{`(${labelType})`}</span>
                      )}
                      {labelOffer && (
                        <span className="label-offer">{`${labelOffer}`}</span>
                      )}
                    </div>
                  }
                  value={e?.id.toString()}
                />
              </ProductTitle>
            </ListItemDetail>
            <ListItemDetail width="20%">
              <ProductQuantity>
                <QuantityCounter
                  itemId={e.id.toString()}
                  maxQuantity={10}
                  isLoading={selectedValue !== e.id.toString()}
                  value={e.quantity ?? 1}
                  onClick={onQuantityChange}
                  plusIconClass="plus-icon"
                  minusIconClass="minus-icon"
                />
              </ProductQuantity>
            </ListItemDetail>
            <ListItemDetail textAlign="end">
              <PriceWrapper>
                <ProductPrice disabled={selectedValue !== e.id.toString()}>
                  {toCurrencyString({
                    value: e.price,
                  })}
                </ProductPrice>
              </PriceWrapper>
            </ListItemDetail>
          </ListItem>
        );
      })}
    </List>
  );
};

const List = styled(Grid)`
  list-style: none;
`;

const ListItem = styled.div`
  padding: 0;
  align-items: center;
  justify-content: space-between;
  display: flex;
  margin: 48px 48px auto 48px;
`;
const ListItemDetail = styled.div<{ textAlign?: string; width?: string }>`
  position: relative;
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
  width: ${({ width }) => (!width ? '15%' : width)};
  ${rtl`
  text-align: 'left';
  `}
`;

const ProductTitle = styled.span`
  label {
    font-family: ${({ theme }) =>
      theme.dir === 'rtl' ? 'Assistant' : 'Roboto'};
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #282929;
  }
`;

const ProductQuantity = styled.span`
  font-family: ${({ theme }) => (theme.dir === 'rtl' ? 'Assistant' : 'Roboto')};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #282929;
  button {
    span {
      font-family: ${({ theme }) =>
        theme.dir === 'rtl' ? 'Assistant' : 'Roboto'};
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }
  }
`;

const PriceWrapper = styled.div`
  text-align: end;
`;

const ProductPrice = styled.span<{ disabled?: boolean }>`
  font-family: ${({ theme }) => (theme.dir === 'rtl' ? 'Assistant' : 'Roboto')};
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: right;
  color: ${({ disabled }) => (disabled ? '#D9D9D9' : '#fc5c42')};
`;

const StyledRadio = styled(Radio)<RadioProps>`
  && {
    background-color: #ffffff;
    width: 10px;
    height: 10px;
    font-family: ${({ theme }) =>
      theme.dir === 'rtl' ? ' Assistant' : 'inherit'};
    padding: 0 !important;
    ${rtl`
    margin-right: 18px;
    `}
    align-self: center;
    text-transform: none;

    &:hover {
      transition: background-color 0.3s;
      box-shadow: none;
    }

    &:disabled {
      background-color: #e8e8e8;
      color: white;
    }
  }
`;

const RadioControlLabel = styled(FormControlLabel)<{ disabledColor?: boolean }>`
  .label-wrapper {
    .label-name {
      font-family: ${({ theme }) =>
        theme.dir === 'rtl' ? 'Assistant' : 'Roboto'};
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: ${({ disabledColor }) => (disabledColor ? '#D9D9D9' : '#282929')};
      ${rtl`
      padding-right: 15px;
      `}
    }
    .label-type {
      font-family: ${({ theme }) =>
        theme.dir === 'rtl' ? 'Assistant' : 'Roboto'};
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: ${({ disabledColor }) => (disabledColor ? '#D9D9D9' : '#696969')};
      ${rtl`
      padding-right: 14px;
      `}
    }
    .label-offer {
      font-family: ${({ theme }) =>
        theme.dir === 'rtl' ? 'Assistant' : 'Roboto'};
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: ${({ disabledColor }) => (disabledColor ? '#D9D9D9' : '#fc5c42')};
    }
  }
`;

export default CoinList;
