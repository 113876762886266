import * as React from 'react';

interface Props {
  count?: number;
  Item: () => JSX.Element;
}

const SkeletonList: React.FC<Props> = ({ count = 5, Item }) => {
  return (
    <>
      {Array.from({ length: count }).map((_, i) => (
        <Item key={i.toString()} />
      ))}
    </>
  );
};

export default SkeletonList;
