import * as React from 'react';
import type { Setter } from 'types/common.types';
import { isProduction } from '../utils/common.utils';

const handleStorageError = (e: Error) => {
  if (!isProduction) {
    throw new Error(`local storage failed to save state`);
  }
  // eslint-disable-next-line no-console
  console.error('error with localStorage: ', e.message);
};

type ReturnType<T> = [T, Setter<T>];

const useLocalStorage = <T>(key: string, initialValue: T): ReturnType<T> => {
  const [value, setValue] = React.useState<T>(() => {
    if (typeof initialValue === 'undefined')
      throw Error('no valid key was provided');
    try {
      const resource = localStorage.getItem(key);
      return resource ? JSON.parse(resource) : initialValue;
    } catch (e) {
      handleStorageError(e);
      return initialValue;
    }
  });

  React.useEffect(() => {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      handleStorageError(e);
    }
  }, [value, key]);

  return [value, setValue];
};

export default useLocalStorage;
