import * as React from 'react';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { Control, useController, useFormContext } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Icon from 'components/Icon/Icon';
import Label from 'components/forms/FormLabel';
import { useSession } from 'hooks';
import { error as errorColor } from 'styles/sharedStyle';
import { SupportedLanguage } from 'types/common.types';

interface Props {
  control: Control;
  name: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  type?: string;
  customError?: string;
  inputPropsData?: Record<string, string>;
  onkeyup?: React.KeyboardEventHandler<HTMLInputElement>;
  dir?: string;
}

const TextInput: React.FC<Props> = ({
  control,
  label,
  name,
  required,
  disabled,
  placeholder,
  type,
  customError,
  inputPropsData,
  onkeyup,
  dir,
}) => {
  const { formState } = useFormContext();
  const { locale } = useSession();
  const {
    meta,
    field: { ref, ...inputProps },
  } = useController({ name, control });
  const { invalid: error } = meta;
  const errorMessage = customError || formState.errors[name]?.message;

  return (
    <Root error={error}>
      <Label
        label={{ title: label }}
        htmlFor={name}
        required={required}
        isInvalid={meta.invalid}
        className="custom-input-label"
      />
      <TextField
        error={error}
        classes={{
          root: 'custom-input-root',
        }}
        id={name}
        placeholder={placeholder}
        variant="outlined"
        inputRef={ref}
        fullWidth
        required={required}
        type={type}
        onKeyUp={onkeyup}
        dir={
          locale === SupportedLanguage.HEBREW && dir !== 'ltr' ? 'rtl' : 'ltr'
        }
        disabled={disabled}
        {...inputProps}
        inputProps={inputPropsData}
      />
      {errorMessage && (
        <FormHelperText error variant="filled" className="error-label">
          <Icon name="information-circle" color={errorColor} />
          <span className="error-text" data-testid={`${name}-error`}>
            {errorMessage}
          </span>
        </FormHelperText>
      )}
    </Root>
  );
};

export default React.memo(TextInput);

const Root = styled.div.attrs({ className: 'text-input-root' })<{
  error: boolean;
}>`
  min-height: 98px;
  padding-bottom: 10px;
  && .custom-input-label {
    color: rgb(63, 66, 84);
    font-size: 1rem;
    font-weight: bold;
    font-family: ${({ theme }) =>
      theme.dir === 'rtl' ? 'Assistant' : 'Roboto'};
    margin-bottom: 4px;
  }

  && .custom-input-root {
    .MuiInputBase-root {
      max-height: 48px;
    }
    &:hover {
      border: 1px solid #000;
      border-radius: 10px;
      ${rtl`
        text-align: left;
      `}
    }
    .MuiInputLabel-formControl {
      ${rtl`
        left: 0;
      `}
    }
    .MuiInputLabel-outlined {
      ${rtl`
        transform: translate(14px, 16px) scale(1);
        transform-origin: top left;
      `}

      &.MuiInputLabel-shrink {
        ${rtl`
          transform: translate(14px, -6px) scale(0.75);
          transform-origin: top left;
          `}
      }
    }
    & .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme, error }) =>
        error ? theme.colors.status.error : theme.colors.darkGray} !important;
      border-width: 1px !important;
      border-radius: 10px;
      ${rtl`
        text-align: left;
      `}
    }
  }
  & .error-label {
    margin: 6px 0 0;
    display: flex;
    align-items: center;
    & .icon-information-circle {
      ${rtl`
        margin-right: 4px;
      `}
      width: 12px;
      height: 12px;
    }
    .error-text {
      text-align: center;
    }
  }
`;
