import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

interface Props {
  name: string;
}

const FormErrorMessage: React.FC<Props> = ({ name }) => {
  const { formState } = useFormContext();
  const { errors } = formState;

  return (
    <>{errors[name]?.message && <Message>{errors[name].message}</Message>}</>
  );
};

const Message = styled.div`
  margin: 3px 12px;
  font-size: 0.825rem;
  color: ${({ theme }) => theme.colors.status.error};
`;

export default FormErrorMessage;
