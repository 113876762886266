import * as React from 'react';
import * as Response from 'api/responses';
import * as Transform from 'api/transform';
import { useFetch, useNotifications, useRouter } from 'hooks';
import { Property } from 'types/properties';

const useProperty = () => {
  const { showNotification } = useNotifications();
  const { query } = useRouter<{
    id: string;
    propertyId: string;
  }>();
  const initialId = React.useRef<string | null>(query.id ?? query.propertyId);

  const onFailure = React.useCallback(() => {
    showNotification({
      key: 'properties/getPropertyDataRejected',
      message:
        'We had a problem fetching this property, please try again later',
      severity: 'error',
    });
  }, [showNotification]);

  const { data: property, isLoading, hasError, callFetch } = useFetch<
    Property,
    Response.Property
  >({
    initialUrl: `/api/properties/${query.id ?? query.propertyId}/`,
    onFailure,
    transform: Transform.transformProperty,
  });

  React.useEffect(() => {
    if (initialId.current !== (query.id ?? query.propertyId))
      callFetch({ url: `/api/properties/${query.id ?? query.propertyId}/` });
    initialId.current = null;
  }, [callFetch, query.id, query.propertyId]);

  return { property, isLoading, hasError, callFetch };
};

export default useProperty;
