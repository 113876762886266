import * as React from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Message } from 'i18n';
import { useManufacturers, useSubCategories, useRouter } from 'hooks';
import { ClientRouter } from 'router/routes';
import { PopupModal, useCalendlyEventListener } from 'react-calendly';
import { BasicOption } from 'components/Filters/Options';
import * as Form from 'components/forms';
import type { Option } from 'components/Autocomplete/Autocomplete.config';
import PatriciaLogo from 'assets/@client/supplier-login-flow/patricia-red-text.png';
import { FormProvider, useForm } from 'react-hook-form';
import rtl from 'styled-components-rtl';
import { yupResolver } from '@hookform/resolvers/yup';
import { supplierProductStep1Schema } from 'utils/validation.utils';
import {
  SupplierOnboardingAction,
  useSupplierOnboardingDispatch,
  useSupplierOnboardingState,
} from 'context/supplierOnboardingContext';
import {
  MOBILE_RESOLUTION,
  MOBILE_TABLET_MIN_RESOLUTION,
  MOBILE_TABLET_RESOLUTION,
} from 'styles/sharedStyle';
import { useMediaQuery } from '@mui/material';
import ImagePicker from '../components/ImagePicker';
import SkipConfirmationDialog from '../components/SkipConfirmationDialog';
import SupplierMainDetailsMobile from './SupplierMainDetailsMobile';
import CustomTooltip from '../components/CustomTooltip';

const SupplierMainDetails: React.FC = () => {
  const { history } = useRouter();
  const intl = useIntl();
  const state = useSupplierOnboardingState();
  const { subCategoryOptions } = useSubCategories();

  const { manufacturers } = useManufacturers();
  const [isManufacturer, setIsManufacturer] = React.useState<Option | null>(
    null
  );
  const [isCategory, setIsCategory] = React.useState<Option | null>(null);
  const isMobile = useMediaQuery(MOBILE_RESOLUTION);

  React.useEffect(() => {
    setIsCategory(
      subCategoryOptions
        .filter(e => state.addProductDetails.subCategory === e.id.toString())
        .map(({ name, id, group }) => ({
          name: `${name} - ${group}`,
          value: id,
        }))[0]
    );
    setIsManufacturer(
      manufacturers?.filter(
        e => e.id.toString() === state.addProductDetails.manufacturer
      )[0] ?? null
    );
  }, [subCategoryOptions, state.addProductDetails, manufacturers]);

  const [isConfirmationModalVisible, setIsGoBackModalVisible] = React.useState(
    false
  );
  const [open, setOpen] = React.useState(false);
  const [manufacturerNameTitle, setManufacturerNameTitle] = React.useState(
    false
  );
  const [retailerSKUTitle, setRetailerSKUTitle] = React.useState(false);
  const [mediaURLTitle, setMediaURLTitle] = React.useState(false);
  const [msrpTitle, setMSRPTitle] = React.useState(false);

  const dispatch = useSupplierOnboardingDispatch();
  const methods = useForm({
    defaultValues: {
      englishName: state.addProductDetails.englishName ?? '',
      media: state.addProductDetails.media ?? '',
      manufacturer: state.addProductDetails.manufacturer ?? '',
      subCategory: state.addProductDetails.subCategory ?? '',
      mediaUrls: state.addProductDetails.mediaUrls ?? [],
      msrp: state.addProductDetails.msrp ?? '',
      price: state.addProductDetails.price ?? '',
      quantity: state.addProductDetails.quantity ?? '',
      sku: state.addProductDetails.sku ?? '',
    },
    reValidateMode: 'onChange',
    mode: 'all',
    resolver: yupResolver(supplierProductStep1Schema),
  });
  const { handleSubmit, getValues, setValue, control, formState } = methods;

  const onFormSubmit = React.useCallback(
    data => {
      dispatch({
        type: SupplierOnboardingAction.SET_ADD_PRODUCT_DETAILS,
        payload: {
          ...data,
        },
      });
      history.push(ClientRouter.SUPPLIER_ADDITIONAL_INFO);
    },
    [dispatch, history]
  );
  useCalendlyEventListener({
    onEventScheduled: e => {
      dispatch({
        type: SupplierOnboardingAction.SET_IS_SHEDULED_MEETING,
        payload: true,
      });
    },
  });
  React.useEffect(() => {
    if (isManufacturer?.id)
      setValue('manufacturer', isManufacturer?.id.toString(), {
        shouldValidate: true,
        shouldDirty: true,
      });

    if (isCategory?.value)
      setValue('subCategory', isCategory?.value.toString(), {
        shouldDirty: true,
        shouldValidate: true,
      });
  }, [isManufacturer, isCategory, setValue]);

  return (
    <>
      {isMobile ? (
        <SupplierMainDetailsMobile
          onSubmit={handleSubmit(onFormSubmit)}
          methods={methods}
        />
      ) : (
        <ParentMainDetails>
          <a href={ClientRouter.APP}>
            <PatriciaLogoContainer src={PatriciaLogo} alt="patricia-logo" />
          </a>
          <MainDetailsParent>
            <MainDetails>
              <SkipText onClick={() => setIsGoBackModalVisible(true)}>
                <FormattedMessage
                  id={Message.PROPERTY_ADD_PROPERTY_BUTTON_SKIP}
                />
              </SkipText>
              <UploadProductText>
                <FormattedMessage
                  id={Message.SUPPLIER_UPLOAD_YOUR_FIRST_PRODUCT}
                  values={{
                    span: (chunks: string) => (
                      <span style={{ color: '#FC5C42' }}>{chunks}</span>
                    ),
                  }}
                />
              </UploadProductText>
              <MainDetailsText>
                <FormattedMessage id={Message.SUPPLIER_MAIN_DETAILS} />
              </MainDetailsText>
              <FormProvider {...methods}>
                <FormWrapperContainer onSubmit={handleSubmit(onFormSubmit)}>
                  <FirstDiv>
                    <FormFieldDiv
                      label={{
                        title: intl.formatMessage({
                          id: Message.SUPPLIER_PRODUCT_NAME_LABEL,
                        }),
                        isBold: true,
                      }}
                      name="englishName"
                      type="text"
                      placeholder={intl.formatMessage({
                        id: Message.SUPPLIER_PRODUCT_NAME_PLACEHOLDER,
                      })}
                    />
                    <ComboBoxDiv
                      className="combobox state"
                      width={250}
                      label={{
                        title: CustomTooltip({
                          label: 'Manufacturer name',
                          title: intl.formatMessage({
                            id: Message.SUPPLIER_MANUFACTURER_NAME_TOOLTIP_TEXT,
                          }),
                          open: manufacturerNameTitle,
                          setOpen: setManufacturerNameTitle,
                        }),
                      }}
                      name="manufacturer"
                      options={manufacturers ?? []}
                      searchPlaceholder="Search Manufacturer..."
                      renderOption={BasicOption}
                      placeholder="Select Manufacturer"
                      selectedValue={isManufacturer}
                      setSelectedValue={setIsManufacturer}
                      setterKey="iso"
                    />
                    <FormFieldDiv
                      label={{
                        title: CustomTooltip({
                          label: intl.formatMessage({
                            id: Message.SUPPLIER_RETAILER_SKU_LABEL,
                          }),
                          title: (
                            <FormattedMessage
                              id={Message.SUPPLIER_RETAILER_SKU_TOOLTIP_TEXT}
                              values={{
                                linebreak: <br />,
                              }}
                            />
                          ),
                          open: retailerSKUTitle,
                          setOpen: setRetailerSKUTitle,
                        }),
                      }}
                      name="sku"
                      type="text"
                      placeholder={intl.formatMessage({
                        id: Message.SUPPLIER_RETAILER_SKU_PLACEHOLDER,
                      })}
                    />
                    <ComboBoxDiv
                      className="combobox state"
                      width={250}
                      label={{ title: 'Category' }}
                      name="subCategory"
                      options={(subCategoryOptions ?? []).map(
                        ({ name, id, group }) => ({
                          name: `${name} - ${group}`,
                          value: id,
                        })
                      )}
                      searchPlaceholder="Search Category..."
                      renderOption={BasicOption}
                      placeholder="Select category"
                      selectedValue={isCategory}
                      setSelectedValue={setIsCategory}
                      setterKey="iso"
                    />
                  </FirstDiv>
                  <SecondDiv>
                    <FormFieldDiv
                      label={{
                        title: intl.formatMessage({
                          id: Message.SUPPLIER_QUANTITY_LABEL,
                        }),
                        isBold: true,
                      }}
                      name="quantity"
                      type="text"
                      placeholder={intl.formatMessage({
                        id: Message.SUPPLIER_QUANTITY_PLACEHOLDER,
                      })}
                    />
                    <FormFieldDiv
                      label={{
                        title: CustomTooltip({
                          label: intl.formatMessage({
                            id: Message.SUPPLIER_MSRP_LABEL,
                          }),
                          title: intl.formatMessage({
                            id: Message.SUPPLIER_MSRP_TOOLTIP_TEXT,
                          }),
                          open: msrpTitle,
                          setOpen: setMSRPTitle,
                        }),
                      }}
                      name="msrp"
                      type="text"
                      placeholder={intl.formatMessage({
                        id: Message.SUPPLIER_MSRP_PLACEHOLDER,
                      })}
                    />
                    <FormFieldDiv
                      label={{
                        title: intl.formatMessage({
                          id: Message.SUPPLIER_SALES_PRICE_LABEL,
                        }),
                        isBold: true,
                      }}
                      name="price"
                      type="text"
                      placeholder={intl.formatMessage({
                        id: Message.SUPPLIER_SALES_PRICE_PLACEHOLDER,
                      })}
                    />
                  </SecondDiv>
                  <ThirdDiv>
                    <Form.TagsField
                      name="mediaUrls"
                      required={false}
                      defaultValue={state.addProductDetails?.mediaUrls}
                      label={CustomTooltip({
                        label: 'Media URLs',
                        title: (
                          <FormattedMessage
                            id={Message.SUPPLIER_MEDIA_URLS_TOOLTIP_TEXT}
                            values={{
                              linebreak: <br />,
                            }}
                          />
                        ),
                        open: mediaURLTitle,
                        setOpen: setMediaURLTitle,
                      })}
                    />
                    <ImagePickerDiv>
                      <LogoParent>
                        <UploadLogoText>
                          <FormattedMessage
                            id={Message.SUPPLIER_UPLOAD_MEDIA_LABEL}
                          />
                        </UploadLogoText>
                        <ImagePicker
                          name="media"
                          label="Upload media"
                          control={control}
                          onFileChange={e => {
                            if (e?.target?.files?.[0])
                              setValue('media', e?.target.files[0]);
                          }}
                        />
                      </LogoParent>
                    </ImagePickerDiv>
                  </ThirdDiv>
                  <NoteText>
                    <FormattedMessage
                      id={Message.SUPPLIER_NOTE_TEXT}
                      values={{
                        span: (chunks: string) => (
                          <span
                            style={{ color: '#5C95FF', cursor: 'pointer' }}
                            onClick={() => setOpen(true)}
                          >
                            {chunks}
                          </span>
                        ),
                      }}
                    />
                  </NoteText>
                  <FooterButtons>
                    <FooterParent>
                      <BackButtonParent>
                        <BackButton onClick={() => history.goBack()}>
                          <BackButtonText>
                            <FormattedMessage
                              id={Message.PROPERTY_ADD_PROPERTY_BUTTON_PREVIOUS}
                            />
                          </BackButtonText>
                        </BackButton>
                      </BackButtonParent>
                      <NextButtonParent>
                        <NextButton
                          disabled={
                            !formState.isValid || !getValues().mediaUrls?.length
                          }
                        >
                          <NextButtonText>
                            <FormattedMessage
                              id={Message.PROPERTY_ADD_PROPERTY_BUTTON_NEXT}
                            />
                          </NextButtonText>
                        </NextButton>
                      </NextButtonParent>
                    </FooterParent>
                  </FooterButtons>
                </FormWrapperContainer>
              </FormProvider>
            </MainDetails>
          </MainDetailsParent>
          <SkipConfirmationDialog
            isOpen={isConfirmationModalVisible}
            toggle={() => setIsGoBackModalVisible(prev => !prev)}
          />
          <PopupModal
            open={open}
            url="https://calendly.com/dan1707"
            rootElement={document.getElementById('root')!}
            onModalClose={() => {
              setOpen(false);
            }}
          />
        </ParentMainDetails>
      )}
    </>
  );
};
export default SupplierMainDetails;

export const ParentMainDetails = styled.div`
  margin-top: -60px;
  font-family: 'Roboto Condensed';
  .image-picker {
    width: 250px;
    height: 76px;
    margin-bottom: 0;
    border: 1px dashed #e8eaeb;
    label {
      span {
        font-size: 16px;
        line-height: 20px;
        /* color: rgba(41, 41, 41, 0.2); */
      }
      svg {
        fill: #d9d9d9;
      }
    }
    @media (max-width: 800px) and (min-width: 767px) {
      width: 225px;
    }
  }
  label {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #3e3e3e;
    font-family: 'Roboto Condensed';
  }
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    margin-top: 0;
  }
  .dropdown-placeholder {
    color: #3e3e3e;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #e8eaeb !important;
    border-width: 1px !important;
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #e8eaeb !important;
    border-width: 1px !important;
  }
`;

export const PatriciaLogoContainer = styled.img`
  height: 140px;
  width: 88px;
  margin-top: 24px;
  margin-left: 184px;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    margin-top: 24px;
    margin-left: 24px;
    position: absolute;
  }
`;

export const MainDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -35px;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    margin-top: 0;
  }
`;

export const UploadProductText = styled.div`
  font-weight: 600;
  font-size: 40px;
  line-height: 44px;
`;

export const MainDetailsText = styled.div`
  color: #3e3e3e;
  font-size: 24px;
  line-height: 28px;
  margin-top: 16px;
`;

export const FormContainer = styled.div`
  margin-top: 28px;
  label {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #3e3e3e;
  }
  .MuiInputBase-root {
    width: 250px !important;
  }
`;

export const FormWrapperContainer = styled.form.attrs({
  className: 'form-container',
})`
  margin-top: 48px !important;

  @media (min-width: 1150px) {
    margin-top: 20px;
  }

  .phoneNumber {
    margin: -6px 0 24px;
    & .flag-dropdown,
    & .flag-dropdown.open {
      height: 48px;
    }

    & .form-control {
      height: 48px;
    }
  }
  .country-list {
    & .search {
      & .search-box {
        height: 48px;
      }
    }
  }
  && .submit-btn {
    @media (min-width: 1150px) {
      margin-top: 0;
      width: unset;
      ${rtl`
        margin-left: auto;
      `}
    }
  }
  & .divider-wrapper {
    margin: 14px auto;
  }

  & .anchor-link {
    color: #2277cc;
    font-weight: 500;
  }

  & .actions-container {
    @media (min-height: 800px) {
      margin-top: 0;
    }
    @media (min-width: 1150px) {
      margin-top: 15px;
    }
  }
`;

const FirstDiv = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    flex-wrap: wrap;
    width: 80vw;
    margin: auto;
  }
`;

const FormFieldDiv = styled(Form.Field)`
  label {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #3e3e3e;
    margin-left: 0;
  }
  .MuiInputBase-root {
    width: 250px !important;
    margin-top: 20px;
    @media (max-width: 800px) and (min-width: 767px) {
      width: 225px !important;
    }
  }
`;

const SecondDiv = styled.div`
  display: flex;
  // justify-content: center;
  gap: 20px;
  margin-top: 37px;
`;

const ThirdDiv = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 37px;
  .maindetail-tag {
    width: 520px;
    @media (max-width: 800px) and (min-width: 767px) {
      width: 100%;
    }
  }
`;

export const ImagePickerDiv = styled.div`
  display: flex;
  justify-content: center;
  label {
    width: 100%;
  }
`;

export const LogoParent = styled.div``;

export const UploadLogoText = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #3e3e3e;
`;

export const FooterButtons = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const NextButtonParent = styled.div`
  text-align: center;
`;

export const BackButtonParent = styled.div``;

export const BackButton = styled.button`
  border: 1px solid #d9d9d9;
  border-radius: 15px;
  padding: 6px 24px;
  height: 48px;
  width: 134px;
  text-align: center;
  background-color: white;
  cursor: pointer;
`;

export const NextButton = styled.button`
  :disabled {
    background: #d9d9d9;
    cursor: default;
  }
  background: #fc5c42;
  border: 1px solid #d9d9d9;
  border-radius: 15px;
  padding: 6px 24px;
  height: 48px;
  width: 134px;
  text-align: center;
  cursor: pointer;
`;

export const BackButtonText = styled.div`
  font-size: 18px;
  line-height: 21px;
  color: #696969;
`;

export const NextButtonText = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: rgba(255, 255, 255, 0.9);
`;

const SkipText = styled.div`
  position: absolute;
  right: 224px;
  top: 72px;
  text-decoration-line: underline;
  color: #696969;
  cursor: pointer;
  font-size: 18px;
  line-height: 22px;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    right: 65px;
    top: 164px;
  }
`;

const ComboBoxDiv = styled(Form.ComboBox)`
  .MuiInputBase-root {
    width: 230px !important;
  }
  label {
    margin-bottom: -2px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #3e3e3e;
    margin-left: 0;
  }
`;
const NoteText = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #3e3e3e;
  margin-top: 48px;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    text-align: center;
  }
`;

export const FooterParent = styled.div`
  display: flex;
  gap: 200px;
  margin-top: 194px;
  margin-bottom: 20px;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    margin-top: 48px;
    gap: 100px;
  }
`;

export const InfoView = styled.div`
  height: 21.33px;
  width: 21.33px;
  margin-left: 6px;
  cursor: pointer;
`;
export const InfoImage = styled.img`
  height: 100%;
  width: 100%;
`;

export const MainDetailsParent = styled.div`
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  }
`;
