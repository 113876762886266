import * as React from 'react';
import { METHODS } from 'api/client';
import { useNotifications, useFetch, useAuthentication } from 'hooks';
import { useOnboarding } from 'context/onboardingContext';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { clientSellerSchema } from 'utils/validation.utils';
import { Type } from 'constants/config';
import type { ClientInformationType } from 'types/common.types';
import { removeEmptyValues } from 'utils/common.utils';

const useAddCoOwner = (
  callBack?: VoidFunction,
  propertyId?: string,
  co_owners?: number[] | undefined
) => {
  const methods = useForm<ClientInformationType>({
    resolver: yupResolver(clientSellerSchema),
    defaultValues: { firstName: '', lastName: '', email: '', phone: '' },
    mode: 'all',
    shouldUnregister: false,
    reValidateMode: 'onChange',
  });
  const { reset, control, setValue, formState } = methods;
  const email = useWatch({ control, name: 'email' });
  const {
    seller_supplier: useSellerSupplier,
    supplierId,
  } = useAuthentication();
  const { errors } = formState;
  const isPassword = React.useMemo(() => Boolean(email) && !errors.email, [
    email,
    errors.email,
  ]);
  const [coOwners, setCoOwners] = React.useState(co_owners);
  const [storePass, setStorePass] = React.useState('');
  const { showNotification } = useNotifications();
  const [{ selectedFloor, image }] = useOnboarding();
  const onUpdatePassword = React.useCallback(() => {
    let generatedPassword: string = '0';
    const newVal = (Math.random() + 1)
      .toString(36)
      .slice(0, 10)
      .toUpperCase()
      .split('.');
    generatedPassword = newVal && newVal[1];
    setStorePass(generatedPassword);
    setValue('password', generatedPassword, {
      shouldValidate: true,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue]);

  const onSuccessPropertyPatch = React.useCallback(
    res => {
      callBack?.();
      showNotification({
        key: 'property co-owner added successfully',
        message: 'Property co-owner added successfully',
        severity: 'success',
      });
    },
    [showNotification, callBack]
  );

  const onFailurePropertyPatch = React.useCallback(
    (error: string) => {
      if (typeof error === 'string') {
        showNotification({
          key: 'phone-error',
          message: error,
          severity: 'error',
        });
      } else {
        showNotification({
          key: 'phone-error',
          message: error,
          severity: 'error',
        });
      }
    },
    [showNotification]
  );

  const { callFetch: addPropertyPatch } = useFetch({
    initialUrl: `/api/properties/${propertyId}`,
    skipOnStart: true,
    config: {
      method: METHODS.PATCH,
      headers: {
        Accept: 'application/json',
      },
    },
    onFailure: onFailurePropertyPatch,
    onSuccess: onSuccessPropertyPatch,
  });

  const onSuccess = React.useCallback(
    res => {
      coOwners?.push(res.id);
      setCoOwners(coOwners);
      addPropertyPatch({
        url: `/api/properties/${propertyId}/`,
        data: {
          co_owners: coOwners,
        },
      });
    },
    [propertyId, addPropertyPatch, coOwners, setCoOwners]
  );

  const onFailure = React.useCallback(
    (error: string) => {
      if (typeof error === 'string') {
        showNotification({
          key: 'phone-error',
          message: error,
          severity: 'error',
        });
      } else {
        showNotification({
          key: 'phone-error',
          message: error,
          severity: 'error',
        });
      }
    },
    [showNotification]
  );

  const { isLoading, callFetch } = useFetch({
    initialUrl: '/api/users/',
    skipOnStart: true,
    config: {
      method: METHODS.POST,
      headers: {
        Accept: 'application/json',
      },
    },
    onSuccess,
    onFailure,
  });

  const handleSave = React.useCallback(
    (payload: ClientInformationType) => {
      const param = {
        first_name: payload.firstName,
        last_name: payload.lastName,
        phone_number: payload?.phone ? `+${payload?.phone}` : payload?.phone,
        type: Type.SELLER_CLIENT,
        seller_supplier: useSellerSupplier ?? supplierId,
        ...(isPassword
          ? {
              email: payload.email,
              password: payload.password ? payload.password : storePass,
            }
          : {}),
      };
      callFetch(
        removeEmptyValues({
          data: param,
        })
      );
    },
    [useSellerSupplier, supplierId, isPassword, storePass, callFetch]
  );

  return {
    isLoading,
    methods,
    selectedFloor,
    reset,
    storePass,
    handleSave,
    onUpdatePassword,
    isPassword,
    image,
  };
};

export default useAddCoOwner;
