import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthRoute, IRouteConfig, ManagementRouter } from 'router/routes';
import Loader from 'components/Loader';
import { useRouter, useValidateUser } from 'hooks';
import { Type } from 'constants/config';

interface Props extends IRouteConfig {
  userTypes: Type[];
}

const PrivateRoute: React.FC<Props> = ({
  path,
  component: Component,
  exact,
  routes,
  userTypes,
  permissions = [],
}) => {
  const { isAuthenticated, validating, allowUserAccess } = useValidateUser();
  const { location, query } = useRouter<{ isSellerClient?: boolean }>();

  const redirectTo: string | boolean = React.useMemo(() => {
    switch (true) {
      case !isAuthenticated:
        return `${AuthRoute.LOGIN}?redirect=${location.pathname}&isSellerClient=${query.isSellerClient}`;
      case !allowUserAccess({
        allowedPermissions: permissions,
        allowedTypes: userTypes,
      }):
        return `${ManagementRouter.NOT_AUTHORIZED}?permission=${permissions[0]}`;
      default:
        return false;
    }
  }, [
    isAuthenticated,
    allowUserAccess,
    permissions,
    userTypes,
    location.pathname,
    query,
  ]);

  if (!validating) return <Loader fullHeight />;
  return (
    <Route
      path={path}
      exact={exact}
      render={routeProps =>
        redirectTo ? (
          <Redirect to={redirectTo} />
        ) : (
          <Component {...routeProps} routes={routes} />
        )
      }
    />
  );
};

export default PrivateRoute;
