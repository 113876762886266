import { all, call, put, debounce } from 'redux-saga/effects';
import { showNotification } from 'store/actions/notifications.actions';
import { newSortByMap } from 'utils/transform.utils';
import { removeEmptyValues } from 'utils/common.utils';
import { FILTER_DATE_FORMAT, formatDate } from 'utils/dates.utils';
import RendersActionTypes from 'store/constants/user-management/renders.constants';
import * as RendersAction from 'store/actions/user-management/renders.actions';
import {
  GetRendersDataRequestPayload,
  GetRendersDataResponsePayload,
} from 'store/types/user-management/renders.types';
import { RendersAPI } from 'api/methods';

function* errorHandler(
  e: Error,
  type: RendersActionTypes,
  rejectAction: (payload: {
    error: string;
  }) => { type: RendersActionTypes; payload: { error: string } }
) {
  const error = e.message || 'ERROR: Cannot refresh Data source';
  yield put(showNotification({ key: type, message: error, severity: 'error' }));
  yield put(rejectAction({ error }));
}

const sortRendersMap = {
  durationTime: 'duration_time',
  formatType: 'format_type',
  updatedAt: 'updated_at',
  createdAt: 'created_at',
  id: 'id',
  status: 'status',
  retryCount: 'retry_count',
  failMailCount: 'fail_mail_count',
  user: 'user',
  property: 'property',
  originalProperty: 'original_property',
};

function* getPropertiesDataRequest({
  payload,
  type,
}: ReturnType<typeof RendersAction.getRendersList>) {
  try {
    const {
      page = 0,
      size = 10,
      sortBy,
      search,
      dateRange,
      format_type,
      status,
    } = payload;
    const params = removeEmptyValues({
      page: page + 1,
      size,
      search,
      status,
      created_at__date__gte: formatDate(
        dateRange?.startDate,
        FILTER_DATE_FORMAT,
        ''
      ),
      created_at__date__lte: formatDate(
        dateRange?.endDate,
        FILTER_DATE_FORMAT,
        ''
      ),
      format_type,
      ...newSortByMap<typeof sortRendersMap>(sortRendersMap, sortBy),
    }) as GetRendersDataRequestPayload;

    const {
      results: renders,
      count,
    }: GetRendersDataResponsePayload = yield call(RendersAPI.getRenders, {
      params,
    });
    const pageCount = Math.ceil(count / size);
    yield put(RendersAction.getRendersListFulfilled({ renders, pageCount }));
  } catch (e) {
    yield call(errorHandler, e, type, RendersAction.getRendersListRejected);
  }
}

function* rendersSaga() {
  yield all([
    debounce(
      500,
      RendersActionTypes.LOAD_RENDERS_LIST,
      getPropertiesDataRequest
    ),
  ]);
}

export default rendersSaga;
