import * as React from 'react';
import styled from 'styled-components';

interface Props {
  title: string | React.ReactNode;
  icon?: React.ReactNode;
}

const InformationField: React.FC<Props> = ({ children, icon, title }) => {
  return (
    <Container>
      {icon && <div>{icon}</div>}
      <h3>{title}</h3>
      <p>{children}</p>
    </Container>
  );
};

export default InformationField;

const Container = styled.article.attrs({ className: 'information-field' })`
  color: ${({ theme }) => theme.colors.black0};
  svg {
    fill: rgb(235, 87, 87);
  }
  & h3 {
    font-weight: 500;
    font-size: 1.25rem;
    margin: 5px 0 0 0;
    padding: 0;
  }

  & p {
    margin: 0;
    padding: 0;
    font-size: 1.125rem;
    font-weight: 300;
  }

  &:not(:first-child) {
    margin-top: 50px;
  }
`;
