import styled from 'styled-components';
import rtl from 'styled-components-rtl';

const Header = styled.section`
  @media (min-width: 1150px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    width: max-content;
    margin: auto;

    & .title-stack {
      margin: auto 0;
    }

    & .progress-bar {
      ${rtl`
          margin: auto 0 auto auto;
      `}
      width: 15%;
      height: 10px;
    }
  }
`;

export default Header;
