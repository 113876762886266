import * as React from 'react';
import {
  Field,
  Select,
  TagsField,
  TextArea,
  File,
  ComboBox,
} from 'components/forms';
import { useBreakpoint, useSubCategories, useManufacturers } from 'hooks';
import { CustomMenuItem } from 'styles/sharedStyle';
import { numericRegExp } from 'utils/validation.utils';
import { Product } from 'types/products';
import FormHeader from 'components/forms/FormHeader/FormHeader';
import { BasicOption } from 'components/Filters/Options';
import styled, { css } from 'styled-components';
import FormDivider from 'components/Divider/FormDivider';
import Button from 'components/Button/PrimaryButton';
import SecondaryButton from 'components/Button/SecondaryButton';
import TruckProgressBar from 'components/@client/TruckProgressBar/TruckProgressBar';
import { ProductDataPayload } from 'pages/appSupplier/my-database/modals/ProductFormModal/ProductFormModal.config';
import type { Option } from 'components/Autocomplete/Autocomplete.config';
import { Delete } from '@material-ui/icons';
import { Setter } from 'types/common.types';
import { Row } from './FormFields.config';
import ColorField from './ColorField';

const min = { w: '227px', h: 40 };
const max = { w: '278px', h: 40 };

interface Props {
  defaultValues?: Product | null;
  showActionButton: (value: number) => void;
  currentValue: ProductDataPayload;
  changeSectionValue?: (value: boolean) => void;
  defaultValue?: Option;
  updateFormValue: (name: string, value: string) => void;
  setDeletedMedias: Setter<string[]>;
  deletedMedias: string[];
}
const ProductDetails: React.FC<Props> = ({
  defaultValues,
  showActionButton,
  currentValue,
  changeSectionValue,
  updateFormValue,
  deletedMedias,
  setDeletedMedias,
}) => {
  const { width } = useBreakpoint(min, max);
  const { subCategoryOptions } = useSubCategories();
  const { manufacturers } = useManufacturers();
  const [isStep, setIsStep] = React.useState<number>(1);
  const [mediaUrls, setMediaUrls] = React.useState<boolean>(false);
  const [tagsValue, setTagsValue] = React.useState<boolean>(false);
  const [uploadFiles, setUploadFiles] = React.useState<boolean>(false);
  const [specFiles, setSpecFiles] = React.useState<boolean>(false);
  const [isManufacturer, setIsManufacturer] = React.useState<Option | null>(
    null
  );
  const [isCategory, setIsCategory] = React.useState<Option | null>(null);

  React.useEffect(() => {
    if (defaultValues) {
      const manufaturerData = {
        name: defaultValues.manufacture.name,
        id: defaultValues.manufacture.id,
      };
      setIsManufacturer(manufaturerData);

      const categoryData = {
        name: `${defaultValues.subCategory} - ${defaultValues.category}`,
        value: defaultValues.subCategoryId,
      };
      setIsCategory(categoryData);
    }
  }, [defaultValues, subCategoryOptions]);

  React.useEffect(() => {
    if (isManufacturer?.id)
      updateFormValue('manufacturer', isManufacturer?.id.toString());

    if (isCategory?.value)
      updateFormValue('subCategory', isCategory?.value.toString());
  }, [isManufacturer, isCategory, updateFormValue]);

  const MeasurementUnits = ['mm', 'cm', 'm', 'in', 'ft'];
  const Types = [
    { id: 'material', name: 'Material' },
    { id: 'model', name: 'Model' },
    { id: 'internal_no_model', name: 'Internal' },
  ];

  const preventSpaceTyping = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === ' ') {
        e.preventDefault();
      }
    },
    []
  );

  const preventSpaceOnPaste = React.useCallback((e: React.ClipboardEvent) => {
    if (e.clipboardData.getData('text') === ' ') {
      e.preventDefault();
    }
  }, []);

  const handleNumericField = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (!e.key.match(numericRegExp) || e.key === ' ') {
        e.preventDefault();
      }
    },
    []
  );

  const handleFloatField = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === ' ') {
        e.preventDefault();
      }
    },
    []
  );

  const showButton = React.useCallback(
    (value: number) => {
      showActionButton(value);
    },
    [showActionButton]
  );

  const progressValue = () => {
    if (isStep === 3) {
      return 100;
    }
    if (isStep === 2) {
      return 66.66;
    }
    return 33.33;
  };

  const mediaTagsValue = React.useCallback(
    value => {
      const data = defaultValues?.media?.map(media => media?.file);
      if (
        (data && JSON.stringify(data) !== JSON.stringify(value)) ||
        !defaultValues ||
        (!data && defaultValues)
      ) {
        setMediaUrls(!!value.length);
      }
    },
    [defaultValues]
  );

  const tagsValues = React.useCallback(
    value => {
      const data = defaultValues?.tags?.map(tag => tag);
      if (
        (data && JSON.stringify(data) !== JSON.stringify(value)) ||
        !defaultValues ||
        (!data && defaultValues)
      ) {
        setTagsValue(!!value.length);
      }
    },
    [defaultValues]
  );

  const uploadFilesValue = React.useCallback(value => {
    setUploadFiles(!!value.length);
  }, []);

  const filesValue = React.useCallback(value => {
    setSpecFiles(!!value.length);
  }, []);

  React.useEffect(() => {
    if (changeSectionValue) {
      changeSectionValue(
        !!(mediaUrls || tagsValue || uploadFiles || specFiles)
      );
    }
  }, [changeSectionValue, mediaUrls, tagsValue, uploadFiles, specFiles]);

  const disabledButton = React.useMemo(() => {
    if (isStep === 1) {
      return !(
        currentValue.englishName &&
        currentValue.manufacturer &&
        currentValue.sku &&
        currentValue.subCategory &&
        currentValue.quantity &&
        currentValue.msrp &&
        currentValue.price &&
        (mediaUrls || uploadFiles)
      );
    }
    if (isStep === 2) {
      return !(
        currentValue.type &&
        currentValue.length &&
        currentValue.width &&
        currentValue.height &&
        currentValue.measurementUnits
      );
    }
    return false;
  }, [currentValue, isStep, mediaUrls, uploadFiles]);

  return (
    <>
      <StepsContainer show={!(isStep === 1 || defaultValues)}>
        <FormHeader title="Main details" iconName="" />
        <FormDivider margin="18px 0" />
        <Row className="form-first-row">
          <Field
            width={width}
            label={{ title: 'Product name' }}
            name="englishName"
            type="text"
            required
            onPaste={preventSpaceOnPaste}
          />
          <ComboBox
            className="combobox state"
            width={width}
            label={{ title: 'Manufacturer name' }}
            name="manufacturer"
            options={manufacturers ?? []}
            searchPlaceholder="Search for Manufacturer name..."
            renderOption={BasicOption}
            placeholder="Select Manufacturer"
            required
            selectedValue={isManufacturer}
            setSelectedValue={setIsManufacturer}
            setterKey="iso"
          />
          <Field
            width={width}
            label={{ title: 'Manufacturer SKU' }}
            name="sku"
            type="text"
            required
            onKeyPress={preventSpaceTyping}
            onPaste={preventSpaceOnPaste}
          />
        </Row>
        <Row className="form-second-row">
          <ComboBox
            className="combobox state"
            width={width}
            label={{ title: 'Category' }}
            name="subCategory"
            options={(subCategoryOptions ?? []).map(({ name, id, group }) => ({
              name: `${name} - ${group}`,
              value: id,
            }))}
            searchPlaceholder="Search Category..."
            renderOption={BasicOption}
            placeholder="Select category"
            required
            selectedValue={isCategory}
            setSelectedValue={setIsCategory}
            setterKey="iso"
          />
          <Field
            onPaste={preventSpaceOnPaste}
            onKeyPress={handleNumericField}
            label={{ title: 'Quantity' }}
            name="quantity"
            type="text"
            required
          />
        </Row>
        <Row>
          <Field
            onPaste={preventSpaceOnPaste}
            onKeyPress={handleFloatField}
            label={{ title: 'MSRP' }}
            name="msrp"
            type="text"
            required
          />
          <Field
            onPaste={preventSpaceOnPaste}
            onKeyPress={handleFloatField}
            label={{ title: 'Sales Price' }}
            name="price"
            type="text"
            required
          />
        </Row>
        <RowContainer className="form-third-row">
          <TagsField
            name="mediaUrls"
            label="Media URLs"
            defaultValue={defaultValues?.media?.map(data => data.file)}
            tagsValue={mediaTagsValue}
            required={false}
          />
        </RowContainer>
        {defaultValues?.media && (
          <Row>
            {defaultValues.media
              .filter(e => !deletedMedias.includes(e.id))
              .map(e => (
                <div key={e.id} style={{ position: 'relative' }}>
                  <div
                    onClick={() => {
                      setDeletedMedias(prev => [...prev, e.id]);
                    }}
                    style={{
                      position: 'absolute',
                      top: 10,
                      right: 10,
                      cursor: 'poiter',
                    }}
                  >
                    <Delete
                      style={{
                        stroke: 'white',
                        cursor: 'poiter',
                      }}
                    />
                  </div>
                  <img style={{ width: 250 }} src={e.file} alt={e.file} />
                </div>
              ))}
          </Row>
        )}
        <Row>
          <File
            name="media"
            label="Upload Media"
            acceptFileType="video/*,image/*"
            multiple
            filesValue={uploadFilesValue}
            required
          />
        </Row>
      </StepsContainer>
      <StepsContainer show={!(isStep === 2 || defaultValues)}>
        <FormHeader title="Additional info" iconName="" />
        <FormDivider margin="18px 0" />
        <RowContainer>
          <TextArea
            label={{ title: 'Description', parenthesesContent: 'optional' }}
            name="description"
            placeholder="description"
          />
        </RowContainer>
        <Row className="form-second-row">
          <Field
            width={width}
            label={{ title: 'Web Page link', parenthesesContent: 'optional' }}
            name="webpageLink"
            type="text"
            onPaste={preventSpaceOnPaste}
          />
          <Select
            inputWidth={width}
            label={{ title: 'Types' }}
            name="type"
            onKeyPress={preventSpaceTyping}
            onPaste={preventSpaceOnPaste}
            required
          >
            <CustomMenuItem disabled value="">
              Select type
            </CustomMenuItem>
            {(Types ?? []).map(type => (
              <CustomMenuItem key={type.id} value={type.id}>
                {type.name}
              </CustomMenuItem>
            ))}
          </Select>
        </Row>
        <Row className="form-third-row">
          <HomeColumns>
            <Field
              onPaste={preventSpaceOnPaste}
              onKeyPress={handleFloatField}
              label={{ title: 'Total Length' }}
              name="length"
              type="text"
              required
            />
            <Field
              onPaste={preventSpaceOnPaste}
              onKeyPress={handleFloatField}
              label={{ title: 'Total Width' }}
              name="width"
              type="text"
              required
            />
            <Field
              onPaste={preventSpaceOnPaste}
              onKeyPress={handleFloatField}
              label={{ title: 'Total Height' }}
              name="height"
              type="text"
              required
            />
          </HomeColumns>
          <Select
            inputWidth={width}
            label={{ title: 'Measurement Units' }}
            name="measurementUnits"
            onKeyPress={preventSpaceTyping}
            onPaste={preventSpaceOnPaste}
            required
          >
            <CustomMenuItem disabled value="">
              Select measurement units
            </CustomMenuItem>
            {(MeasurementUnits ?? []).map(name => (
              <CustomMenuItem key={name} value={name}>
                {name}
              </CustomMenuItem>
            ))}
          </Select>
        </Row>
      </StepsContainer>
      <StepsContainer show={!(isStep === 3 || defaultValues)}>
        <FormHeader title="Marketing info - optional" iconName="" />
        <FormDivider margin="18px 0" />
        <Row className="form-first-row">
          <File
            name="specification"
            label="Upload Specification Files"
            multiple
            filesValue={filesValue}
          />
        </Row>
        <Row className="form-second-row">
          <Field
            width={width}
            label={{ title: 'Series' }}
            name="series"
            type="text"
            onPaste={preventSpaceOnPaste}
          />
          <ColorField name="color" width={width} required={false} />
          <TagsField
            name="tags"
            label="Tags"
            required={false}
            defaultValue={defaultValues?.tags?.map(data => data)}
            tagsValue={tagsValues}
          />
        </Row>
      </StepsContainer>
      {!defaultValues && <TruckProgressBar value={progressValue()} />}
      {!defaultValues && (
        <ButtonContainer flexEnd={isStep}>
          {isStep > 1 && (
            <SecondaryButton
              onClick={e => {
                setIsStep(isStep - 1);
                showButton(isStep - 1);
                e.preventDefault();
              }}
            >
              Back
            </SecondaryButton>
          )}
          {isStep < 3 && (
            <Button
              disabled={disabledButton}
              onClick={e => {
                setIsStep(isStep + 1);
                showButton(isStep + 1);
                e.preventDefault();
              }}
            >
              Next
            </Button>
          )}
        </ButtonContainer>
      )}
    </>
  );
};

export default ProductDetails;

const HomeColumns = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1 !important;

  div {
    margin-left: 13px;
    flex: 0.27 !important;

    div {
      margin-left: unset;
    }
  }
`;

const RowContainer = styled.div`
  max-width: 90%;
  margin-bottom: 24px;
`;

const ButtonContainer = styled.div<{
  flexEnd?: number;
}>`
  display: flex;
  margin-top: 20px;
  ${({ flexEnd }) =>
    flexEnd === 1
      ? css`
          justify-content: flex-end;
        `
      : css`
          justify-content: space-between;
        `};
`;

const StepsContainer = styled.div<{
  show?: boolean;
}>`
  ${({ show }) =>
    show &&
    css`
      display: none;
    `};
`;
