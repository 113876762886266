import * as React from 'react';
import MuiButton, { ButtonProps } from '@material-ui/core/Button';
import styled from 'styled-components';
import Icon from 'components/Icon';

export const BaseButton = styled(MuiButton).attrs(() => ({
  color: 'inherit',
}))`
  && {
    border-radius: 10px;
    height: 48px;
    box-shadow: none;
  }
`;

interface ICustomProps extends Partial<ButtonProps> {
  isLoading?: boolean;
}

const Button: React.FC<ICustomProps> = ({
  isLoading = false,
  children,
  ...muProps
}): React.ReactElement => {
  return (
    // FIXME: open issue with MU https://github.com/mui-org/material-ui/pull/21552
    // @ts-ignore
    <StyledButton {...muProps}>
      {isLoading ? <CustomIcon name="spinner" size={20} /> : children}
    </StyledButton>
  );
};

export default Button;

const StyledButton = styled(BaseButton)<ButtonProps>`
  && {
    background-color: ${({ theme }) => theme.colors.primary.lightMobile};
    color: ${({ theme }) => theme.colors.primary.contrastText};
    width: 115px;
    align-self: center;
    text-transform: none;

    &:hover {
      background-color: #a50a0a;
      transition: background-color 0.3s;
      box-shadow: none;
    }

    &:disabled {
      background-color: #e8e8e8;
      color: white;
    }
  }
`;

export const CustomIcon = styled(Icon).attrs<{ $secondary?: boolean }>(
  ({ theme, $secondary }) => ({
    color: $secondary
      ? theme.colors.primary.light
      : theme.colors.primary.contrastText,
  })
)`
  ${({ theme }) => theme.utils.spinAnimation};
  position: absolute;
  top: 50%;
  left: 50%;
`;
