import type { Dimension } from 'types/common.types';
import type { QuizAnswer, QuizQuestion } from 'types/quizzes';

export enum QuizStep {
  PREFERENCES = 'preferences',
  QUESTIONS = 'questions',
}

export const StepDimensions: Record<
  QuizStep,
  Record<'min' | 'max', Dimension>
> = {
  [QuizStep.PREFERENCES]: { min: { h: 400, w: 525 }, max: { h: 400, w: 525 } },
  [QuizStep.QUESTIONS]: {
    min: { h: '95vh', w: 1046 },
    max: { h: 696, w: 1046 },
  },
};

export const preferencesDefaultValues = {
  id: '',
  name: '',
  designStyles: [{ id: '', name: '' }],
  countries: [{ name: '', value: '' }],
};

export const questionDefaultValues: QuizQuestion = {
  questionLabel: '',
  answers: [
    {
      image: '',
      priceTag: '',
      tags: [],
      color: '',
      designStyle: '',
      panorama: null,
    },
  ] as QuizAnswer[],
};
