import * as React from 'react';
import { useOnboarding, OnboardingAction } from 'context/onboardingContext';
import {
  useNotifications,
  useRouter,
  useUploadFile,
  useAuthentication,
} from 'hooks';
import { ClientRouter } from 'router/routes';
import type { PropertyMedia } from 'types/properties';
import { LogsAPI } from 'api/methods';
import { userLogData } from 'mockData/userLogData';

const useUploadPlan = (next?: VoidFunction) => {
  const [
    { image, floorPlanFiles, propertyId, newPropertyType, selectedFloor },
    dispatch,
  ] = useOnboarding();
  const { history } = useRouter();
  const { showNotification } = useNotifications();
  const { type } = useAuthentication();

  const setImage = React.useCallback(
    (payload: File | null | string) => {
      dispatch({ type: OnboardingAction.SET_IMAGE, payload });
    },
    [dispatch]
  );

  const setNewPropertyType = React.useCallback(
    (payload: string) => {
      dispatch({ type: OnboardingAction.SET_NEW_PROPERTY_TYPE, payload });
    },
    [dispatch]
  );

  const setPlanFiles = React.useCallback(
    (payload: { id: number; files: PropertyMedia[] }) => {
      dispatch({ type: OnboardingAction.SET_PLAN_FILES, payload });
    },
    [dispatch]
  );

  const setSelectedFloor = React.useCallback(
    (payload: PropertyMedia | null = null) =>
      dispatch({ type: OnboardingAction.SET_SELECTED_FLOOR, payload }),
    [dispatch]
  );

  const onSuccess = React.useCallback(
    (task: { id: number; files: PropertyMedia[] }) => {
      setPlanFiles(task);
      if (next) return next();
      return history.push(
        task?.files.length > 1
          ? ClientRouter.SELECT_FLOOR
          : ClientRouter.PLAN_CONFIRMED
      );
    },
    [history, next, setPlanFiles]
  );

  const onFailure = React.useCallback(
    e => {
      LogsAPI.postUserLogs({
        area: userLogData.eventArea.registration,
        section: userLogData.eventSection.planUpload,
        name: userLogData.eventName.nextFailed,
        path: userLogData.eventPath.appOnBoardingUploadPlanPath,
        metadata: { failure_reason: (e as Error).message },
      });
      showNotification({
        key: 'uploadPlan/uploadPlanRejected',
        message:
          'We had an error uploading your floor plan, please try again later.',
        severity: 'error',
      });
    },
    [showNotification]
  );

  const { handleUpload, isUploading: isLoading } = useUploadFile({
    url: '/api/property_files/upload_floor_plan_file/',
    onSuccess,
    onFailure,
  });

  const onClick = React.useCallback(async () => {
    if (type === 'SELLER' && next) {
      return next();
    }
    if (!image) return null;

    return handleUpload({ file: image });
  }, [next, handleUpload, image, type]);

  return {
    image,
    setImage,
    isLoading,
    onClick,
    floorPlanFiles,
    propertyId,
    setNewPropertyType,
    newPropertyType,
    setSelectedFloor,
    selectedFloor,
  };
};

export default useUploadPlan;
