import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { METHODS } from 'api/client';
import Button from 'components/@client/Button';
import AddressCard, { LoaderSkeleton } from 'components/@client/AddressCard';
import { useFetch } from 'hooks';
import useAddressesList from 'pages/common/hooks/useAddressesList';
import { ClientRouter } from 'router/routes';
import { getRoute } from 'utils/sidebar.utils';
import { isEmpty } from 'utils/lodash.utils';
import { stringify } from 'utils/string.utils';
import { IAddress, UserLogType } from 'types/common.types';
import { MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import AddressForm from 'pages/appClient/profile/addresses/AddressForm';
import { LogsAPI } from 'api/methods';
import { userLogData } from 'mockData/userLogData';

interface Props {
  userId?: number;
  backTo?: string;
  onAddressesChange?: (addresses: IAddress[] | null) => void;
  isModal?: boolean;
  logData?: UserLogType;
}

const UserAddresses: React.FC<Props> = ({
  backTo,
  userId,
  onAddressesChange,
  isModal,
  logData,
}) => {
  const selectedLoader = React.useRef<null | string>(null);

  const {
    addresses: userAddresses,
    isLoading: isLoadingList,
    fetchAddresses: callUpdateList,
  } = useAddressesList(userId, [], false, onAddressesChange);

  const updateAddressList = React.useCallback(
    _data => {
      selectedLoader.current = null;
      callUpdateList();
    },
    [callUpdateList]
  );
  const { callFetch, isLoading } = useFetch({
    initialUrl: '/api/addresses/',
    skipOnStart: true,
    config: { method: METHODS.PATCH },
    onSuccess: updateAddressList,
  });
  const updatePrimary = React.useCallback(
    (id: string) => {
      selectedLoader.current = id;
      callFetch({
        url: `/api/addresses/${id}/`,
        data: { is_primary: true, user: userId },
      });
    },
    [callFetch, userId]
  );

  const addAddressSuccessfulCallback = React.useCallback(() => {
    callUpdateList();
  }, [callUpdateList]);

  return (
    <>
      {!userAddresses?.length && isModal ? (
        <AddressForm
          isModal={isModal}
          addAddressSuccessfulCallback={addAddressSuccessfulCallback}
        />
      ) : (
        <ShippingAddressesList>
          <h5 className="addresses-title">
            <FormattedMessage id={Message.USER_SHIPPING_ADDRESSES} />
          </h5>
          {isLoadingList && isEmpty(userAddresses) && <LoaderSkeleton />}
          {userAddresses?.map(address => (
            <AddressCard
              key={address.id}
              address={address}
              isLoading={isLoading && selectedLoader.current === address.id}
              linkProps={{
                to: getRoute([
                  ClientRouter.PROFILE_ADDRESSES,
                  `/${address.id}`,
                  backTo ? stringify({ backTo }) : '',
                ]),
                children: <FormattedMessage id={Message.EDIT_BUTTON} />,
              }}
              action={{
                label: address.isPrimary ? (
                  <FormattedMessage
                    id={Message.USER_DEFAULT_SHIPPING_ADDRESS}
                  />
                ) : (
                  <FormattedMessage
                    id={Message.USER_SET_DEFAULT_ADDRESS_BUTTON}
                  />
                ),
                disabled: address.isPrimary || isLoading,
                onClick: () => updatePrimary(address.id as string),
              }}
            />
          ))}
          <Link
            className="add-addresses-link"
            to={
              backTo
                ? getRoute([
                    ClientRouter.PROFILE_ADDRESSES,
                    stringify({ backTo }),
                  ])
                : ClientRouter.PROFILE_ADDRESSES
            }
          >
            <Button
              type="button"
              className="add-btn"
              onClick={() => {
                LogsAPI.postUserLogs(
                  logData ?? {
                    area: userLogData.eventArea.myProfile,
                    section: userLogData.eventSection.mainPage,
                    name: userLogData.eventName.addNewAddress,
                    path: userLogData.eventPath.appMyAccountEditPath,
                  }
                );
              }}
            >
              <FormattedMessage id={Message.USER_ADD_NEW_ADDRESS_BUTTON} />
            </Button>
          </Link>
        </ShippingAddressesList>
      )}
    </>
  );
};

export default React.memo(UserAddresses);

const ShippingAddressesList = styled.div.attrs({
  className: 'shipping-addresses-list',
})`
  margin-bottom: 16px;

  .add-addresses-link {
    text-decoration: none;
  }
  .add-btn {
    width: 100%;
    background-color: #f4f6f9;
    color: ${({ theme }) => theme.colors.black0};
    font-size: 18px;

    &:hover {
      background-color: rgba(138, 156, 182, 0.5);
    }

    @media ${MOBILE_TABLET_RESOLUTION} {
      font-size: 16px;
    }
  }

  .addresses-title {
    font-family: inherit;
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 0.875rem;
  }
`;
