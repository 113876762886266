import * as React from 'react';
import styled from 'styled-components';
import { useAuthentication, useSession } from 'hooks';
import {
  IAddress,
  SupportedCurrency,
  SupportedLanguage,
} from 'types/common.types';
import { SelectedProductCoin } from 'types/products';
import type { Cart, Skin } from 'types/properties';
import { stringifyAddress } from 'utils/addresses.utils';
import { isProduction } from 'utils/common.utils';
import { formatDate, FILTER_DATE_FORMAT } from 'utils/dates.utils';
import { CheckOutPersonalDetailPayload } from 'types/orders';

interface Props {
  cart?: Cart | null;
  personalDetail?: CheckOutPersonalDetailPayload | null;
  skin?: Skin | null;
  coins?: SelectedProductCoin | null;
  address?: IAddress | null;
  deliveryDate?: Date;
  tourSection?: boolean;
  hs?: string;
}

const PaymentForm: React.FC<Props> = ({
  cart,
  coins,
  personalDetail,
  address,
  skin,
  deliveryDate,
  tourSection,
  hs,
}) => {
  const { id } = useAuthentication();
  const { currency, locale, country } = useSession();
  const iframeFormRef = React.useRef<HTMLFormElement>(null);
  const iframeRef = React.useRef<HTMLIFrameElement | null>(null);

  const purchaseDataJson = React.useCallback(() => {
    if (skin) {
      return [
        {
          product_name: skin?.name,
          product_quantity: 1,
          product_price: skin?.finalPrice,
        },
      ];
    }
    if (coins) {
      return [
        {
          product_name: coins?.englishName,
          product_quantity: coins?.quantity,
          product_price: coins?.finalPrice,
        },
      ];
    }
    return cart?.items.map(item => ({
      product_name: `${item.product.englishName} ${item.product.sku}`,
      product_quantity: item.quantity,
      product_price: item.product.finalPrice,
    }));
  }, [cart?.items, skin, coins]);

  const purchaseData = React.useMemo(() => purchaseDataJson(), [
    purchaseDataJson,
  ]);

  const sum = React.useMemo(
    () =>
      skin?.finalPrice.toFixed(2) ??
      coins?.finalTotal.toFixed(2) ??
      cart?.finalPrice.toFixed(2),
    [cart?.finalPrice, skin?.finalPrice, coins?.finalTotal]
  );

  const shippingAddress = React.useMemo(() => stringifyAddress(address) ?? '', [
    address,
  ]);

  React.useEffect(() => {
    if (iframeFormRef.current) iframeFormRef.current.submit();
  }, []);

  return (
    <Container>
      <form
        ref={iframeFormRef}
        id="payment-form"
        target="payment-iframe"
        method="post"
        action={`https://direct.tranzila.com/${
          isProduction ? 'ttxpatriciaa' : 'patriciatest'
        }/iframenew.php?ppnewwin=2`}
      >
        <input
          type="hidden"
          name="json_purchase_data"
          value={encodeURIComponent(JSON.stringify(purchaseData))}
        />
        <input type="hidden" name="trBgColor" value="fcfcfc" />
        <input type="hidden" name="sum" value={sum} />
        <input
          type="hidden"
          name="currency"
          value={currency === SupportedCurrency.ILS && !coins ? '1' : '2'}
        />
        <input type="hidden" name="cred_type" value="1" />
        <input type="hidden" name="nologo" value="1" />
        <input
          type="hidden"
          name="lang"
          value={locale === SupportedLanguage.HEBREW ? 'il' : 'us'}
        />
        <input type="hidden" name="trButtonColor" value="FF3100" />
        <input
          type="hidden"
          name="mobile"
          value={personalDetail?.phoneNumber}
        />
        <input type="hidden" name="email" value={personalDetail?.email} />
        <input type="hidden" name="contact" value={personalDetail?.name} />
        <input type="hidden" name="address" value={shippingAddress} />
        <input type="hidden" name="city" value={address?.city} />
        <input type="hidden" name="u71" value="1" />
        <input type="hidden" name="tranmode" value="A" />
        {deliveryDate && (
          <input
            type="hidden"
            name="shipping_date"
            value={formatDate(deliveryDate, FILTER_DATE_FORMAT)}
          />
        )}
        {tourSection && <input type="hidden" name="tour_section" value="1" />}
        {skin ? (
          <>
            <input type="hidden" name="skin_id" value={skin?.id} />
            <input type="hidden" name="user_id" value={id} />
          </>
        ) : (
          <>
            {coins ? (
              <>
                <input type="hidden" name="coins" value={coins?.id} />
                <input type="hidden" name="user_id" value={id} />
              </>
            ) : (
              <input type="hidden" name="checkout_cart_id" value={cart?.id} />
            )}
          </>
        )}
        <input type="hidden" name="thtk" value={cart?.hs ?? hs} />
        <input type="hidden" name="new_prrocess" value={1} />
        <input
          type="hidden"
          name="Ilang"
          value={country === 'IL' ? 'heb' : 'eng'}
        />
      </form>
      <PaymentIframe
        ref={iframeRef}
        title="payment-iframe"
        id="payment-iframe"
        name="payment-iframe"
        width="370px"
        height="400px"
      />
    </Container>
  );
};

export default PaymentForm;

const Container = styled.div.attrs({ className: 'payment-form-container' })`
  width: 100%;
  margin: auto;
  text-align: center;
  margin-top: 30px;
`;

const PaymentIframe = styled.iframe`
  border: none !important;
`;
