import * as React from 'react';
import styled from 'styled-components';
import LoginWelcomeImage from 'assets/@client/supplier-login-flow/supplier-login-welcome.png';
import { FormattedMessage, useIntl } from 'react-intl';
import { Message } from 'i18n';
import { ClientRouter } from 'router/routes';
import { useRouter } from 'hooks';
import {
  MOBILE_RESOLUTION,
  MOBILE_TABLET_MIN_RESOLUTION,
  MOBILE_TABLET_RESOLUTION,
} from 'styles/sharedStyle';
import { PopupModal, useCalendlyEventListener } from 'react-calendly';
import CalendarIcon from 'assets/@client/supplier-login-flow/calendar_icon.png';
import { useMediaQuery } from '@mui/material';
import {
  SupplierOnboardingAction,
  useSupplierOnboardingDispatch,
} from 'context/supplierOnboardingContext';

const SupplierLoginThanksMobile: React.FC = () => {
  const { history } = useRouter();
  const intl = useIntl();
  const dispatch = useSupplierOnboardingDispatch();
  const isMobile = useMediaQuery(MOBILE_RESOLUTION);
  const isTablet = useMediaQuery(
    `${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION}`
  );
  const [open, setOpen] = React.useState(false);
  useCalendlyEventListener({
    onEventScheduled: e => {
      dispatch({
        type: SupplierOnboardingAction.SET_IS_SHEDULED_MEETING,
        payload: true,
      });
    },
  });
  return (
    <ParentDiv LoginWelcomeImage={LoginWelcomeImage}>
      <TransparentDiv>
        <WelcomeTitle>
          <FormattedMessage id={Message.SUPPLIER_LOGIN_THANKS_TITLE} />
        </WelcomeTitle>
        <SubTitle>
          <FormattedMessage
            id={
              isMobile
                ? Message.SUPPLIER_LOGIN_THANKS_FIRST_SUBTITLE_MOBILE
                : Message.SUPPLIER_LOGIN_THANKS_FIRST_SUBTITLE
            }
            values={{ linebreak: <br /> }}
          />
        </SubTitle>
        {isTablet ? (
          <ParentButtons>
            <LetsDoThisButton
              onClick={() => {
                history.push(ClientRouter.SUPPLIER_STORE_INFO);
              }}
            >
              <LetsDoThisText>
                <FormattedMessage id={Message.SUPPLIER_LOGIN_THANKS_BUTTON} />
              </LetsDoThisText>
            </LetsDoThisButton>
            <StyledButton
              className="calendly-button"
              onClick={() => setOpen(true)}
            >
              <StyledScheduleMeeting>
                {intl.formatMessage({
                  id: Message.SUPPLIER_SCHEDULE_MEETING,
                })}
              </StyledScheduleMeeting>
              <StyledImage src={CalendarIcon} alt="calendar-icon" />
            </StyledButton>
          </ParentButtons>
        ) : (
          <LetsDoThisButton
            onClick={() => {
              history.push(ClientRouter.SUPPLIER_STORE_INFO);
            }}
          >
            <LetsDoThisText>
              <FormattedMessage id={Message.SUPPLIER_LOGIN_THANKS_BUTTON} />
            </LetsDoThisText>
          </LetsDoThisButton>
        )}
        <PopupModal
          open={open}
          url="https://calendly.com/dan1707"
          rootElement={document.getElementById('root')!}
          onModalClose={() => {
            setOpen(false);
          }}
        />
      </TransparentDiv>
    </ParentDiv>
  );
};

export default SupplierLoginThanksMobile;

const ParentDiv = styled.div<{ LoginWelcomeImage: string }>`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto Condensed';
  ::before {
    background-image: url(${LoginWelcomeImage});
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.6;
    background-position: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    content: '';
  }
  .calendly-button {
    background: #fafcfe;
    border: 1px solid #fc5c42;
    border-radius: 15px;
    padding: 11px 20px;
    text-transform: none;
    color: #fc5c42;
    white-space: nowrap;
    line-height: 22px;
    height: 48px;
    display: flex;
    width: 237px;
    .calendly-popup-btn {
      line-height: 22px;
      border: none;
      color: inherit;
      border: none;
      background: inherit;
      font-size: inherit;
    }
    p {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
    }
  }
`;

const TransparentDiv = styled.div`
  background: rgba(250, 250, 250, 0.8);
  border-radius: 20px;
  position: relative;
  flex-direction: column;
  padding: 16px;
  width: 90vw;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    height: 336px;
    width: 606px;
    padding: 50px;
  }
`;

const WelcomeTitle = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #282929;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    font-size: 40px;
    line-height: 44px;
  }
`;

const SubTitle = styled.div`
  font-size: 18px;
  line-height: 22px;
  color: #3e3e3e;
  margin-top: 8px;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    font-size: 24px;
    line-height: 28px;
  }
`;

const LetsDoThisText = styled.div`
  display: flex;
  color: #ffffff;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  width: 124px;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    font-size: 20px;
    line-height: 24px;
    width: 140px;
  }
`;

const LetsDoThisButton = styled.button`
  cursor: pointer;
  background: #fc5c42;
  border: 0.5px solid #d9d9d9;
  border-radius: 15px;
  padding: 9px 16px;
  width: 156px;
  height: 40px;
  margin-top: 24px;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    height: 48px;
    padding: 11px 24px;
    width: 185px;
  }
`;

const StyledButton = styled.button`
  margin: 0 24px;
  margin-top: 24px;
  cursor: pointer;
`;

const StyledScheduleMeeting = styled.p`
  margin: 0;
  position: relative;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
`;

const StyledImage = styled.img`
  margin-left: 4px;
`;

const ParentButtons = styled.div`
  display: flex;
`;
