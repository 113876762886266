import styled from 'styled-components';
import { oliveGreen } from 'styles/sharedStyle';
import Button from './Button';

const ShowMoreButton = styled(Button).attrs({ className: 'show-more-button' })`
  &&.show-more-button {
    background-color: ${oliveGreen};
    border-radius: 12px;
    clear: both;
    white-space: nowrap;

    @media (min-width: 1150px) {
      max-width: 415px;
      height: 83px;
      font-size: 1.5rem;
    }

    &:hover {
      background-color: ${oliveGreen}CC;
    }
  }
`;

export default ShowMoreButton;
