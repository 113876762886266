import * as React from 'react';
import _ from 'lodash';
import { METHODS } from 'api/client';
import * as Response from 'api/responses';
import * as Transform from 'api/transform';
import {
  useFetch,
  useRouter,
  useNotifications,
  useAuthentication,
} from 'hooks';
import usePropertyCarts from 'pages/appClient/cart/usePropertyCarts';
import { ClientRouter } from 'router/routes';
import type { Cart, CartItem, PaginatedCart } from 'types/properties';
import { getLocalStorage } from 'utils/common.utils';
import { GUEST_TOKEN } from 'constants/localstorage.constants';
import useUpdateItem from './useUpdateItem';

const usePropertyCart = (
  isTeamUser: boolean = false,
  onSuccessCheckoutCallback?: (id: number | null) => void
) => {
  const { token } = useAuthentication();
  const guestToken = getLocalStorage(GUEST_TOKEN, null);
  const { showNotification } = useNotifications();
  const { query, history } = useRouter<{ cartId: string }>();
  const [cart, setCart] = React.useState<Cart | null>(null);
  const [pagination, setPagination] = React.useState<{
    page: number;
    size: number;
  }>({ page: 1, size: 10 });

  const [deleteCandidate, setDeleteCandidate] = React.useState<CartItem | null>(
    null
  );

  const { setCartCounts } = usePropertyCarts({
    skipOnStart: true,
  });
  const [deleteCandidateAll, setDeleteCandidateAll] = React.useState<{
    ids?: string[];
  }>({ ids: [] });
  const restrictedItems = React.useMemo(
    () =>
      cart?.items.filter(
        ({ quantity, product }) =>
          product.quantity < quantity || product.quantity === 0
      ) ?? [],
    [cart?.items]
  );
  const toggleModal = React.useCallback(
    (id?: string) => {
      setDeleteCandidate(cart?.items.find(item => id === item.id) ?? null);
    },
    [cart?.items]
  );
  const toggleModalAllItem = React.useCallback(
    (id?: string) => {
      if (deleteCandidateAll.ids && deleteCandidateAll.ids.length > 0) {
        setDeleteCandidateAll({ ids: [] });
      } else {
        setDeleteCandidateAll({ ids: cart?.items.map(item => item?.id) ?? [] });
      }
    },
    [cart?.items, deleteCandidateAll]
  );
  const initialId = React.useRef<string | null>(query?.cartId ?? null);

  const cartItems = React.useCallback(
    (data: PaginatedCart | null) => {
      if (cart?.items && cart?.items?.length > 0)
        return _.map(cart?.items ?? [], item => {
          return _.extend(item, _.find(data?.results ?? [], { id: item.id }));
        });

      return data?.results;
    },
    [cart]
  );

  const onSuccess = React.useCallback(
    (data: PaginatedCart | null) => {
      if (!data) setCart(null);
      if (!isTeamUser) {
        if (
          !cart &&
          data?.results &&
          data?.results.some(
            ({ quantity, product }) => product.quantity < quantity
          )
        ) {
          showNotification({
            key: 'cart/insufficientQuantity',
            severity: 'warning',
            message:
              'There were a few stock changes to some of your cart items, please update them before checkout',
          });
        }
      }

      setCart(prev => {
        const temp = {
          ...prev,
          ...data,
          items: cartItems(data),
        } as Cart;
        return temp;
      });
      setCartCounts(data?.count ?? 0);
    },
    [cart, isTeamUser, showNotification, cartItems, setCartCounts]
  );

  const { isLoading, callFetch: fetchCart } = useFetch<
    PaginatedCart,
    Response.PaginatedCart
  >({
    initialUrl: `/api/shopping_cart_item/`,
    transform: Transform.transformPaginatedCart,
    onSuccess,
    skipOnStart: true,
    config: {
      isGuestAllowed: true,
      params: isTeamUser
        ? {
            ...pagination,
          }
        : {
            size: 1000,
          },
    },
  });

  React.useEffect(() => {
    if (isTeamUser) {
      fetchCart({
        params: {
          ...pagination,
        },
      });
    }
  }, [fetchCart, isTeamUser, pagination]);

  React.useEffect(() => {
    if (token || guestToken)
      fetchCart({
        params: isTeamUser
          ? {
              ...pagination,
            }
          : {
              size: 1000,
            },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, guestToken]);

  const onCheckoutRequestSuccess = React.useCallback(
    (response: { checkout_cart: number } | null) => {
      history.push(
        `${ClientRouter.CHECKOUT}?checkout=${response?.checkout_cart}`
      );
    },
    [history]
  );

  const onCheckoutRequestFailure = React.useCallback(
    (message: string) => {
      showNotification({
        key: 'cart/checkoutRequest',
        message,
        severity: 'error',
      });
    },
    [showNotification]
  );

  const {
    isLoading: isProceedingToCheckout,
    callFetch: requestCheckout,
  } = useFetch({
    initialUrl: `/api/shopping_cart_item/checkout/`,
    config: {
      method: METHODS.POST,
      isGuestAllowed: true,
    },
    onSuccess: data => {
      if (onSuccessCheckoutCallback)
        onSuccessCheckoutCallback(
          (data as { checkout_cart: number }).checkout_cart
        );
      else onCheckoutRequestSuccess(data as { checkout_cart: number });
    },
    onFailure: onCheckoutRequestFailure,
    skipOnStart: true,
  });

  const proceedToCheckout = React.useCallback(
    () =>
      requestCheckout({
        url: `/api/shopping_cart_item/checkout/`,
        method: METHODS.POST,
        isGuestAllowed: true,
      }),
    [requestCheckout]
  );
  const subTotal = cart?.subTotal;
  const total = cart?.subTotal;
  const onUpdate = React.useCallback(
    (updated: Response.CartItem | null) => {
      if (!updated && cart?.items && cart?.items?.length > 0) {
        setCart(null);
      }
      fetchCart({
        params: isTeamUser
          ? {
              ...pagination,
            }
          : {
              size: 1000,
            },
      });
    },
    [fetchCart, pagination, isTeamUser, cart]
  );

  const { emptyItem } = useUpdateItem(() => {
    setCartCounts(0);
    setCart(null);
  });
  const { updateQuantity, deleteItem, isUpdating } = useUpdateItem(onUpdate);
  React.useEffect(() => {
    if (!isUpdating) fetchCart();
  }, [isUpdating, fetchCart]);
  React.useEffect(() => {
    if (initialId.current !== query.cartId && query.cartId)
      fetchCart({ url: `/api/property_shopping_cart/${query.cartId}/` });
    initialId.current = null;
  }, [query.cartId, fetchCart]);
  return {
    cart,
    deleteCandidate,
    deleteCandidateAll,
    isLoading,
    isProceedingToCheckout,
    isUpdating,
    subTotal,
    total,
    restrictedItems,
    deleteItem,
    emptyItem,
    proceedToCheckout,
    toggleModal,
    toggleModalAllItem,
    updateQuantity,
    setPagination,
    pagination,
  };
};

export default usePropertyCart;
