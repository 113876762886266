import {
  Switch,
  withStyles,
  SwitchProps,
  SwitchClassKey,
  Theme,
  createStyles,
} from '@material-ui/core';
import defaultTheme from 'styles/theme';
import styled from 'styled-components';

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface Props extends SwitchProps {
  classes: Styles;
  title?: string;
}

const MuToggleSwitch = withStyles((muTheme: Theme) =>
  createStyles({
    root: {
      width: 38,
      height: 22,
      padding: 0,
      margin: 0,
    },
    switchBase: {
      '&&.MuiIconButton-root': {
        backgroundColor: 'transparent',
      },
      '&&': {
        padding: 3,
        position: 'absolute',
      },
      '&$checked': {
        transform: 'translateX(16px)',
        color: muTheme.palette.common.white,
        '& + $track': {
          backgroundColor: defaultTheme.colors.primary.dark,
          opacity: 1,
          border: 'none',
        },
        '&&.MuiSwitch-thumb': {
          backgroundColor: '#fff',
        },
      },
      '&$focusVisible $thumb': {
        color: muTheme.palette.secondary.main,
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 16,
      height: 16,
      backgroundColor: defaultTheme.colors.primary.dark,
      boxShadow: 'none',
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${defaultTheme.colors.primary.dark}`,
      backgroundColor: muTheme.palette.grey[50],
      opacity: 1,
    },
    checked: {},
    focusVisible: {},
  })
)(({ classes, title, ...props }: Props) => {
  return (
    <Container>
      {title && <CustomTitle>{title}</CustomTitle>}
      <Switch
        focusVisibleClassName={classes.focusVisible}
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const CustomTitle = styled.span`
  color: ${({ theme }) => theme.colors.primary.dark};
  font-weight: 400;
  margin-right: 15px;
  font-size: 0.75rem;
  white-space: nowrap;
  @media (min-width: 1500px) {
    font-size: 0.875rem;
  }
`;

const SwitchButton = styled(MuToggleSwitch)`
  && {
    .Mui-checked {
      .MuiSwitch-thumb {
        background-color: #fff;
      }
    }

    .Mui-disabled + span {
      opacity: 0.2;
    }
  }
`;

export default SwitchButton;
