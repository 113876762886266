import { Authentication } from 'store/constants/authentication.constants';
import * as Payload from 'store/types/authentication.types';
import { BaseUser as UserType } from 'types/common.types';

export const loginRequest = (payload: Payload.LoginPayload) => ({
  type: Authentication.LOGIN_REQUEST,
  payload,
});

export const loginWithProviderRequest = (
  payload: Payload.LoginWithProviderPayload
) => ({
  type: Authentication.LOGIN_WITH_PROVIDER_REQUEST,
  payload,
});

export const redirectLoginWithProviderRequest = (
  payload: Payload.RedirectLoginWithProviderPayload
) => ({
  type: Authentication.REDIRECT_LOGIN_WITH_PROVIDER_REQUEST,
  payload,
});

export const loginFulfilled = (payload: Payload.AccountPayload) => ({
  type: Authentication.LOGIN_FULFILLED,
  payload,
});

export const loginRejected = (payload: Error) => ({
  type: Authentication.LOGIN_REJECTED,
  payload,
});

export const logOutRequest = () => ({
  type: Authentication.LOGOUT_REQUEST,
  payload: null,
});

export const clientLogOutRequest = () => ({
  type: Authentication.CLIENT_LOGOUT_REQUEST,
  payload: null,
});

export const resetError = () => ({
  type: Authentication.RESET_ERROR,
  payload: null,
});
// update user
export const updateUser = (user: UserType) => ({
  type: Authentication.UPDATE_USER_INFO,
  payload: user,
});
// verify token
export const verifyTokenRequest = () => ({
  type: Authentication.VERIFY_TOKEN_REQUEST,
  payload: null,
});

export const verifyTokenRequestFulfilled = (
  payload: Payload.AccountPayload
) => ({
  type: Authentication.VERIFY_TOKEN_SUCCESS,
  payload,
});

export const registerRequest = (payload: Payload.RegisterPayload) => ({
  type: Authentication.REGISTER_REQUEST,
  payload,
});

export const onboardingRequest = (payload: Payload.RegisterPayload) => ({
  type: Authentication.ONBOARDING_REQUEST,
  payload,
});

export const redirectOnboardingRequest = (
  payload: Payload.RedirectRegisterPayload
) => ({
  type: Authentication.REDIRECT_ONBOARDING_REQUEST,
  payload,
});

export const registerFulfilled = (payload: Payload.AccountPayload) => ({
  type: Authentication.REGISTER_FULFILLED,
  payload,
});

export const registerRejected = (payload: Error) => ({
  type: Authentication.REGISTER_REJECTED,
  payload,
});

export const passwordResetLinkRequest = (
  payload: Payload.ForgotPasswordPayload
) => ({
  type: Authentication.PASSWORD_RESET_LINK_REQUEST,
  payload,
});

export const passwordResetLinkFulfilled = () => ({
  type: Authentication.PASSWORD_RESET_LINK_FULFILLED,
  payload: null,
});

export const passwordResetLinkRejected = (payload: Error) => ({
  type: Authentication.PASSWORD_RESET_LINK_FULFILLED,
  payload,
});

export const resetPasswordRequest = (
  payload: Payload.ResetPasswordPayload
) => ({
  type: Authentication.RESET_PASSWORD_REQUEST,
  payload,
});

export const resetPasswordFulfilled = () => ({
  type: Authentication.RESET_PASSWORD_FULFILLED,
  payload: null,
});

export const resetPasswordRejected = (payload: Error) => ({
  type: Authentication.RESET_PASSWORD_FULFILLED,
  payload,
});

export const setSupplierID = (payload: { supplier: string }) => ({
  type: Authentication.SET_SUPPLIER_ID,
  payload,
});

export type AuthenticationAction = ReturnType<
  | typeof loginRequest
  | typeof loginWithProviderRequest
  | typeof loginFulfilled
  | typeof loginRejected
  | typeof logOutRequest
  | typeof resetError
  | typeof verifyTokenRequest
  | typeof verifyTokenRequestFulfilled
  | typeof registerRequest
  | typeof redirectOnboardingRequest
  | typeof registerFulfilled
  | typeof registerRejected
  | typeof updateUser
  | typeof passwordResetLinkRequest
  | typeof passwordResetLinkFulfilled
  | typeof passwordResetLinkRejected
  | typeof resetPasswordRequest
  | typeof resetPasswordFulfilled
  | typeof resetPasswordRejected
  | typeof redirectLoginWithProviderRequest
  | typeof setSupplierID
>;
