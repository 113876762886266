import { Session } from 'store/constants/session.constants';
import { GetLocationFulfilledPayload } from 'store/types/session.types';
import { SupportedLanguage } from 'types/common.types';

export const selectLanguage = (payload: SupportedLanguage) => ({
  type: Session.SELECT_LANGUAGE,
  payload,
});

export const getLocationRequest = () => ({
  type: Session.GET_LOCATION_REQUEST,
  payload: null,
});

export const getLocationFulfilled = (payload: GetLocationFulfilledPayload) => ({
  type: Session.GET_LOCATION_FULFILLED,
  payload,
});

export const getLocationRejected = (payload: string) => ({
  type: Session.GET_LOCATION_REJECTED,
  payload,
});

export const setShowWelcomeMessage = (payload: boolean) => ({
  type: Session.SET_SHOW_WELCOME_MESSAGE,
  payload,
});

export type SessionAction = ReturnType<
  | typeof selectLanguage
  | typeof getLocationRequest
  | typeof getLocationFulfilled
  | typeof getLocationRejected
  | typeof setShowWelcomeMessage
>;
