import { FormattedMessage } from 'react-intl';
import * as Images from 'assets/@client/tour/tour-instructions';
import * as HeImages from 'assets/@client/tour/tour-instructions-he';
// Work Images
import DragMoveImage from 'assets/@client/tour/tour-instructions/work/drag_move.svg';
import DragMoveImage2 from 'assets/@client/tour/tour-instructions/work/drag_move2.svg';
import MoveFastReject from 'assets/@client/tour/tour-instructions/work/move_fast_reject.svg';
import MoveFastReject2 from 'assets/@client/tour/tour-instructions/work/move_fast_reject2.svg';
import ZoomInImages from 'assets/@client/tour/tour-instructions/work/zoom_in.svg';
import UnionImages from 'assets/@client/tour/tour-instructions/work/Union.svg';
import ArrowLeftRight from 'assets/@client/tour/tour-instructions/work/arrow_left_right.svg';
import ArrowForwardBackward from 'assets/@client/tour/tour-instructions/work/arrow_forward_backward.svg';
// Design Images
import ShoppingCartImage from 'assets/@client/tour/tour-instructions/design/shopping-cart.svg';
import ThreeDImage from 'assets/@client/tour/tour-instructions/design/3D.svg';
import RotationImage from 'assets/@client/tour/tour-instructions/design/rotation.svg';
import InfoImage from 'assets/@client/tour/tour-instructions/design/info.svg';
import DuplicateImage from 'assets/@client/tour/tour-instructions/design/duplicate.svg';
import DeleteItemImage from 'assets/@client/tour/tour-instructions/design/delete_item.svg';
// General Images
import PatricisSkinsImage from 'assets/@client/tour/tour-instructions/general/patricis_skins.svg';
import AddItemImage from 'assets/@client/tour/tour-instructions/general/add_item.svg';
import ShoppingImage from 'assets/@client/tour/tour-instructions/general/shopping_cart.svg';
import SunlightViewSetting from 'assets/@client/tour/tour-instructions/general/sunlight_view_setting.svg';
import ScreenshortImage from 'assets/@client/tour/tour-instructions/general/screenshort.svg';
import Screenshort2Image from 'assets/@client/tour/tour-instructions/general/screenshort2.svg';
import SettingImage from 'assets/@client/tour/tour-instructions/general/setting.svg';
import UndoImage from 'assets/@client/tour/tour-instructions/general/undo.svg';
import RedoImage from 'assets/@client/tour/tour-instructions/general/redo.svg';
import CaracterHeightImage from 'assets/@client/tour/tour-instructions/general/caracter_height.svg';
import BackToStartImage from 'assets/@client/tour/tour-instructions/general/back_to_start.svg';
import DollhouseImage from 'assets/@client/tour/tour-instructions/general/dollhouse.svg';
import MeasurementsImage from 'assets/@client/tour/tour-instructions/general/Measurements.svg';
import MiniMapImage from 'assets/@client/tour/tour-instructions/general/mini_map.svg';

import { Message } from 'i18n';

export const pagesEn = [
  {
    image: Images.one,
    title: (
      <FormattedMessage id={Message.TOUR_INSTRUCTIONS_MODAL_FIRST_PAGE_TITLE} />
    ),
    text: [
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_FIRST_PAGE_BULLET_ONE}
        key={Message.TOUR_INSTRUCTIONS_MODAL_FIRST_PAGE_BULLET_ONE}
      />,
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_FIRST_PAGE_BULLET_TWO}
        key={Message.TOUR_INSTRUCTIONS_MODAL_FIRST_PAGE_BULLET_TWO}
      />,
    ],
  },
  {
    image: Images.two,
    title: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_SECOND_PAGE_TITLE}
      />
    ),
    text: [
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_SECOND_PAGE_BULLET_ONE}
        key={Message.TOUR_INSTRUCTIONS_MODAL_SECOND_PAGE_BULLET_ONE}
      />,
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_SECOND_PAGE_BULLET_TWO}
        key={Message.TOUR_INSTRUCTIONS_MODAL_SECOND_PAGE_BULLET_TWO}
      />,
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_SECOND_PAGE_BULLET_THREE}
        key={Message.TOUR_INSTRUCTIONS_MODAL_SECOND_PAGE_BULLET_THREE}
      />,
    ],
  },
  {
    image: Images.three,
    title: (
      <FormattedMessage id={Message.TOUR_INSTRUCTIONS_MODAL_THIRD_PAGE_TITLE} />
    ),
    text: [
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_THIRD_PAGE_BULLET_ONE}
        key={Message.TOUR_INSTRUCTIONS_MODAL_THIRD_PAGE_BULLET_ONE}
      />,
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_THIRD_PAGE_BULLET_TWO}
        key={Message.TOUR_INSTRUCTIONS_MODAL_THIRD_PAGE_BULLET_TWO}
      />,
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_THIRD_PAGE_BULLET_THREE}
        key={Message.TOUR_INSTRUCTIONS_MODAL_THIRD_PAGE_BULLET_THREE}
      />,
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_THIRD_PAGE_BULLET_FOUR}
        key={Message.TOUR_INSTRUCTIONS_MODAL_THIRD_PAGE_BULLET_FOUR}
      />,
    ],
  },
  {
    image: Images.four,
    title: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_FOURTH_PAGE_TITLE}
      />
    ),
    text: [
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_FOURTH_PAGE_BULLET_ONE}
        key={Message.TOUR_INSTRUCTIONS_MODAL_FOURTH_PAGE_BULLET_ONE}
      />,
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_FOURTH_PAGE_BULLET_TWO}
        key={Message.TOUR_INSTRUCTIONS_MODAL_FOURTH_PAGE_BULLET_TWO}
      />,
    ],
  },
  {
    image: Images.five,
    title: (
      <FormattedMessage id={Message.TOUR_INSTRUCTIONS_MODAL_FIFTH_PAGE_TITLE} />
    ),
    text: [
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_FIFTH_PAGE_BULLET_ONE}
        key={Message.TOUR_INSTRUCTIONS_MODAL_FIFTH_PAGE_BULLET_ONE}
      />,
    ],
  },
  {
    image: Images.six,
    title: (
      <FormattedMessage id={Message.TOUR_INSTRUCTIONS_MODAL_SIXTH_PAGE_TITLE} />
    ),
    text: [
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_SIXTH_PAGE_BULLET_ONE}
        key={Message.TOUR_INSTRUCTIONS_MODAL_SIXTH_PAGE_BULLET_ONE}
      />,
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_SIXTH_PAGE_BULLET_TWO}
        key={Message.TOUR_INSTRUCTIONS_MODAL_SIXTH_PAGE_BULLET_TWO}
      />,
    ],
  },
  {
    image: Images.seven,
    title: '',
    text: [],
  },
  {
    image: Images.eight,
    title: '',
    text: [],
  },
  {
    image: Images.nine,
    title: '',
    text: [],
  },
];

export const pagesHe = [
  {
    image: HeImages.one,
    title: (
      <FormattedMessage id={Message.TOUR_INSTRUCTIONS_MODAL_FIRST_PAGE_TITLE} />
    ),
    text: [
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_FIRST_PAGE_BULLET_ONE}
        key={Message.TOUR_INSTRUCTIONS_MODAL_FIRST_PAGE_BULLET_ONE}
      />,
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_FIRST_PAGE_BULLET_TWO}
        key={Message.TOUR_INSTRUCTIONS_MODAL_FIRST_PAGE_BULLET_TWO}
      />,
    ],
  },
  {
    image: HeImages.two,
    title: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_SECOND_PAGE_TITLE}
      />
    ),
    text: [
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_SECOND_PAGE_BULLET_ONE}
        key={Message.TOUR_INSTRUCTIONS_MODAL_SECOND_PAGE_BULLET_ONE}
      />,
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_SECOND_PAGE_BULLET_TWO}
        key={Message.TOUR_INSTRUCTIONS_MODAL_SECOND_PAGE_BULLET_TWO}
      />,
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_SECOND_PAGE_BULLET_THREE}
        key={Message.TOUR_INSTRUCTIONS_MODAL_SECOND_PAGE_BULLET_THREE}
      />,
    ],
  },
  {
    image: HeImages.three,
    title: (
      <FormattedMessage id={Message.TOUR_INSTRUCTIONS_MODAL_THIRD_PAGE_TITLE} />
    ),
    text: [
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_THIRD_PAGE_BULLET_ONE}
        key={Message.TOUR_INSTRUCTIONS_MODAL_THIRD_PAGE_BULLET_ONE}
      />,
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_THIRD_PAGE_BULLET_TWO}
        key={Message.TOUR_INSTRUCTIONS_MODAL_THIRD_PAGE_BULLET_TWO}
      />,
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_THIRD_PAGE_BULLET_THREE}
        key={Message.TOUR_INSTRUCTIONS_MODAL_THIRD_PAGE_BULLET_THREE}
      />,
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_THIRD_PAGE_BULLET_FOUR}
        key={Message.TOUR_INSTRUCTIONS_MODAL_THIRD_PAGE_BULLET_FOUR}
      />,
    ],
  },
  {
    image: HeImages.four,
    title: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_FOURTH_PAGE_TITLE}
      />
    ),
    text: [
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_FOURTH_PAGE_BULLET_ONE}
        key={Message.TOUR_INSTRUCTIONS_MODAL_FOURTH_PAGE_BULLET_ONE}
      />,
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_FOURTH_PAGE_BULLET_TWO}
        key={Message.TOUR_INSTRUCTIONS_MODAL_FOURTH_PAGE_BULLET_TWO}
      />,
    ],
  },
  {
    image: HeImages.five,
    title: (
      <FormattedMessage id={Message.TOUR_INSTRUCTIONS_MODAL_FIFTH_PAGE_TITLE} />
    ),
    text: [
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_FIFTH_PAGE_BULLET_ONE}
        key={Message.TOUR_INSTRUCTIONS_MODAL_FIFTH_PAGE_BULLET_ONE}
      />,
    ],
  },
  {
    image: HeImages.six,
    title: (
      <FormattedMessage id={Message.TOUR_INSTRUCTIONS_MODAL_SIXTH_PAGE_TITLE} />
    ),
    text: [
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_SIXTH_PAGE_BULLET_ONE}
        key={Message.TOUR_INSTRUCTIONS_MODAL_SIXTH_PAGE_BULLET_ONE}
      />,
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_SIXTH_PAGE_BULLET_TWO}
        key={Message.TOUR_INSTRUCTIONS_MODAL_SIXTH_PAGE_BULLET_TWO}
      />,
    ],
  },
  {
    image: HeImages.seven,
    title: '',
    text: [],
  },
  {
    image: HeImages.eight,
    title: '',
    text: [],
  },
  {
    image: HeImages.nine,
    title: '',
    text: [],
  },
];

export type TabIcon = 'walk' | 'design' | 'general';

export enum NavigationTab {
  WORK = 'Walk',
  DESIGN = 'Design',
  GENERAL = 'General',
}

export interface Tabs {
  iconName: string;
  label: string;
  children?: React.ReactNode;
}

export const tabIcons: Record<string, TabIcon> = {
  [NavigationTab.WORK]: 'walk',
  [NavigationTab.DESIGN]: 'design',
  [NavigationTab.GENERAL]: 'general',
};

export const tabTitles: Record<string, React.ReactNode> = {
  [NavigationTab.WORK]: (
    <FormattedMessage id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_WALK} />
  ),
  [NavigationTab.DESIGN]: (
    <FormattedMessage id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_DESIGN} />
  ),
  [NavigationTab.GENERAL]: (
    <FormattedMessage id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL} />
  ),
};

export const tabs: Tabs[] = Object.values(NavigationTab).map(label => ({
  iconName: tabIcons[label],
  label,
  children: tabTitles[label],
}));

export const getContentType = (tab: NavigationTab) =>
  ({
    [NavigationTab.WORK]: 'walk',
    [NavigationTab.DESIGN]: 'design',
    [NavigationTab.GENERAL]: 'general',
  }[tab]);

export interface WorkProps {
  image: string | null;
  title: string | React.ReactNode;
  icon?: string;
  description?: string | React.ReactNode;
}

export interface OtherProps {
  image: string;
  title: string;
  description: string;
}

export const WorkList: WorkProps[] = [
  {
    image: DragMoveImage2,
    title: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_WALK1}
      />
    ),
    icon: DragMoveImage,
  },
  {
    image: MoveFastReject2,
    title: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_WALK2}
      />
    ),
    icon: MoveFastReject,
  },
  {
    image: UnionImages,
    title: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_WALK3}
      />
    ),
    icon: ZoomInImages,
  },
  {
    image: null,
    title: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_WALK4}
      />
    ),
    icon: ArrowLeftRight,
  },
  {
    image: null,
    title: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_WALK5}
      />
    ),
    icon: ArrowForwardBackward,
  },
];

export const DesignList: WorkProps[] = [
  {
    image: ShoppingCartImage,
    title: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_DESIGN1}
      />
    ),
    description: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_DESIGN1}
      />
    ),
  },
  {
    image: ThreeDImage,
    title: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_DESIGN2}
      />
    ),
    description: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_DESIGN2}
      />
    ),
  },
  {
    image: RotationImage,
    title: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_DESIGN3}
      />
    ),
    description: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_DESIGN3}
      />
    ),
  },
  {
    image: InfoImage,
    title: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_DESIGN4}
      />
    ),
    description: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_DESIGN4}
      />
    ),
  },
  {
    image: DuplicateImage,
    title: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_DESIGN5}
      />
    ),
    description: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_DESIGN5}
      />
    ),
  },
  {
    image: DeleteItemImage,
    title: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_DESIGN6}
      />
    ),
    description: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_DESIGN6}
      />
    ),
  },
];

export const GeneralList: WorkProps[] = [
  {
    image: PatricisSkinsImage,
    title: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL1}
      />
    ),
    description: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL1}
      />
    ),
  },
  {
    image: AddItemImage,
    title: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL2}
      />
    ),
    description: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL2}
      />
    ),
  },
  {
    image: ShoppingImage,
    title: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL3}
      />
    ),
    description: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL3}
      />
    ),
  },
  {
    image: SunlightViewSetting,
    title: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL4}
      />
    ),
    description: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL4}
      />
    ),
  },
  {
    image: MiniMapImage,
    title: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL5}
      />
    ),
    description: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL5}
      />
    ),
  },
  {
    image: ScreenshortImage,
    title: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL6}
      />
    ),
    description: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL6}
      />
    ),
  },
  {
    image: Screenshort2Image,
    title: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL7}
      />
    ),
    description: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL7}
      />
    ),
  },
  {
    image: SettingImage,
    title: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL8}
      />
    ),
    description: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL8}
      />
    ),
  },
  {
    image: UndoImage,
    title: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL9}
      />
    ),
    description: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL9}
      />
    ),
  },
  {
    image: RedoImage,
    title: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL10}
      />
    ),
    description: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL10}
      />
    ),
  },
  {
    image: CaracterHeightImage,
    title: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL11}
      />
    ),
    description: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL11}
      />
    ),
  },
  {
    image: BackToStartImage,
    title: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL12}
      />
    ),
    description: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL12}
      />
    ),
  },
  {
    image: DollhouseImage,
    title: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL13}
      />
    ),
    description: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL13}
      />
    ),
  },
  {
    image: MeasurementsImage,
    title: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL14}
      />
    ),
    description: (
      <FormattedMessage
        id={Message.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL14}
      />
    ),
  },
];
