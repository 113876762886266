import Skeleton from '@material-ui/core/Skeleton';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';

const countSkeleton = [1, 2];

const IdeasSkeletonMobile = () => {
  const TextSkeleton = () => {
    return (
      <Skeleton
        variant="text"
        width={156}
        height={48}
        style={{ marginTop: '-80px' }}
      />
    );
  };

  const ChipSkeleton = () => {
    return (
      <Skeleton
        variant="text"
        width={156}
        height={48}
        style={{ marginTop: '-10px' }}
      />
    );
  };

  const CardSkeleton = () => {
    return (
      <Skeleton
        style={{ borderRadius: 15 }}
        variant="text"
        width={156}
        height={434}
      />
    );
  };

  return (
    <>
      {countSkeleton.map(item => {
        return (
          <ParentSkeleton key={item} container wrap="nowrap">
            <div style={{ marginTop: '-50px' }}>
              <CardSkeleton />
              <TextSkeleton />
              <ChipSkeleton />
            </div>
            <div style={{ marginTop: '-50px' }}>
              <CardSkeleton />
              <TextSkeleton />
              <ChipSkeleton />
            </div>
          </ParentSkeleton>
        );
      })}
    </>
  );
};

const ParentSkeleton = styled(Grid)`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

export default IdeasSkeletonMobile;
