import * as React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { Type } from 'constants/config';
import {
  useAuthentication,
  useRecentProperty,
  useRouter,
  useSession,
  useValidateUser,
} from 'hooks';
import usePropertyCarts from 'pages/appClient/cart/usePropertyCarts';
import Layout from 'pages/common/layout';
import NotAuthorized from 'pages/common/NotAuthorized';
import { ClientRouter, ManagementRouter, SupplierRouter } from 'router/routes';
import routeConfig from 'router/routes.config';
import { SupportedLanguage } from 'types/common.types';
import { CART_KEY } from 'constants/localstorage.constants';
import { getLocalStorage } from 'utils/common.utils';
import * as actions from 'store/actions/user-management/clients.actions';
import { useDispatch } from 'store/selectors';
import Loader from 'components/Loader/Loader';
import PrivateRoute from './PrivateRoute';

const ManagementApp: React.FC = () => {
  const { selectLanguage } = useSession();

  React.useEffect(() => {
    selectLanguage(SupportedLanguage.ENGLISH);
  }, [selectLanguage]);
  return (
    <Layout userType={Type.TEAM_PATRICIA}>
      <React.Suspense fallback={null}>
        <Switch>
          {routeConfig.managementRoutes.map(route => (
            <PrivateRoute
              {...route}
              key={route.path}
              userTypes={[Type.TEAM_PATRICIA]}
            />
          ))}
          <Route
            path={ManagementRouter.NOT_AUTHORIZED}
            component={NotAuthorized}
          />
          <Redirect to={ManagementRouter.DASHBOARD} />
        </Switch>
      </React.Suspense>
    </Layout>
  );
};

const ClientApp = () => {
  useValidateUser();
  const { type, token: isAuthenticated } = useAuthentication();
  const { history } = useRouter();
  const { isFromSupportedCountry, getLocation } = useSession();
  const { recentProperty, getRecentProperty, isLoading } = useRecentProperty();
  const { fetchCart, setCartCounts } = usePropertyCarts({
    skipOnStart: true,
    isForCount: true,
  });
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (isAuthenticated) {
      fetchCart();
    } else {
      setCartCounts(getLocalStorage(CART_KEY, []).length ?? 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  React.useEffect(() => {
    getLocation();
  }, [getLocation]);

  React.useEffect(() => {
    dispatch(actions.getRoomsDataRequest());
  }, [dispatch]);

  React.useEffect(() => {
    if (type !== Type.TEAM_PATRICIA && !isFromSupportedCountry)
      history.replace(ClientRouter.APP);
  }, [history, isFromSupportedCountry, type]);

  React.useEffect(() => {
    if (isAuthenticated && !recentProperty && !isLoading) getRecentProperty();
  }, [getRecentProperty, isAuthenticated, isLoading, recentProperty]);

  return (
    <Layout userType={Type.CLIENT}>
      <Switch>
        {routeConfig.clientRoutes.map(route => {
          const { component: Component, routes, path, exact } = route;
          return route.isPublic ? (
            <Route
              path={path}
              exact={exact}
              key={route.path}
              render={routeProps => (
                <Component {...routeProps} routes={routes} />
              )}
            />
          ) : (
            <PrivateRoute
              {...route}
              key={route.path}
              userTypes={[
                Type.CLIENT,
                Type.TEAM_PATRICIA,
                Type.SUPPLIER,
                Type.SELLER,
                Type.SELLER_CLIENT,
                Type.PROFESSIONAL,
              ]}
            />
          );
        })}
        <Redirect from="/" exact to={ClientRouter.APP} />
        <Redirect to={ClientRouter.NOT_FOUND} />
      </Switch>
    </Layout>
  );
};

const SupplierApp = () => {
  const { selectLanguage } = useSession();

  React.useEffect(() => {
    selectLanguage(SupportedLanguage.ENGLISH);
  }, [selectLanguage]);
  return (
    <Layout userType={Type.SUPPLIER}>
      <React.Suspense fallback={null}>
        <Switch>
          {routeConfig.supplierRoutes.map(route => (
            <PrivateRoute
              {...route}
              key={route.path}
              userTypes={[Type.TEAM_PATRICIA, Type.SUPPLIER]}
            />
          ))}
          <Redirect to={SupplierRouter.DASHBOARD} />
        </Switch>
      </React.Suspense>
    </Layout>
  );
};

const RouterGate: React.FC = () => {
  const location = useLocation();
  // Scroll to top if path changes
  React.useLayoutEffect(() => {
    // window.scrollTo({ top: 0, behavior: 'smooth' });
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <React.Suspense fallback={<Loader />}>
      <Switch>
        {routeConfig.authRoutes.map(route => (
          <Route key={route.path} {...route} />
        ))}
        <Route path={ClientRouter.APP} component={ClientApp} />
        <Route path={ManagementRouter.APP} component={ManagementApp} />
        <Route path={SupplierRouter.APP} component={SupplierApp} />
        <Redirect to={ClientRouter.APP} />
      </Switch>
    </React.Suspense>
  );
};

export default RouterGate;
