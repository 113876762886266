import * as React from 'react';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import styled from 'styled-components';
import { setMonth, getMonth, setYear, getYear } from 'date-fns';
import Select from 'components/Select/BasicSelect';
import { MenuProps } from './types';

interface HeaderProps {
  date: Date;
  setDate: (date: Date) => void;
  nextDisabled: boolean;
  prevDisabled: boolean;
  onClickNext: () => void;
  onClickPrevious: () => void;
  MenuProps?: MenuProps;
  years: number[];
}

export const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

const Header: React.FunctionComponent<HeaderProps> = ({
  date,
  setDate,
  nextDisabled,
  prevDisabled,
  onClickNext,
  onClickPrevious,
  MenuProps: menuProps,
  years,
}: HeaderProps) => {
  const [isMonthOpen, setIsMonthOpen] = React.useState(false);
  const [isYearOpen, setIsYearOpen] = React.useState(false);
  const handleMonthChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    setDate(setMonth(date, parseInt(event.target.value as string, 10)));
  };

  const handleYearChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    setDate(setYear(date, parseInt(event.target.value as string, 10)));
  };

  return (
    <Container>
      <Button disabled={prevDisabled} onClick={onClickPrevious}>
        <ChevronLeft fontSize="large" />
      </Button>
      <Select
        className="date-picker-month-select"
        value={getMonth(date)}
        onChange={handleMonthChange}
        MenuProps={menuProps}
        open={isMonthOpen}
        onClick={() => setIsMonthOpen(prev => !prev)}
      >
        {MONTHS.map((month, idx) => (
          <MenuItem key={month} value={idx}>
            {month}
          </MenuItem>
        ))}
      </Select>
      <Select
        value={getYear(date)}
        onChange={handleYearChange}
        MenuProps={menuProps}
        open={isYearOpen}
        onClick={() => setIsYearOpen(prev => !prev)}
      >
        {years.map(year => (
          <MenuItem key={year} value={year}>
            {year}
          </MenuItem>
        ))}
      </Select>
      <Button disabled={nextDisabled} onClick={onClickNext}>
        <ChevronRight fontSize="large" />
      </Button>
    </Container>
  );
};

export default Header;

const Container = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 15px;
  padding: 0 20px;

  && .MuiSelect-select {
    font-size: 11px;
  }
`;

const Button = styled(IconButton)`
  && .MuiSvgIcon-fontSizeLarge {
    color: ${({ disabled }) =>
      disabled ? 'rgba(255, 49, 0, 0.5)' : '#ff3100'};
    transition: color 0.2s;
  }
  padding: 10;
  &:hover {
    background: none;
  }
`;
