import * as React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useAuthentication, useRouter, useSession, useFetch } from 'hooks';
import { Message } from 'i18n';
import { ClientRouter } from 'router/routes';
import { isEmpty, isString } from 'utils/lodash.utils';
import Loader from 'components/Loader';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { MOBILE_RESOLUTION } from 'styles/sharedStyle';
import TourUnityLoader from 'assets/@client/tour/tour-unity-loader.gif';
import * as deviceInfo from 'react-device-detect';
import { Redirect } from 'react-router-dom';
import {
  baseURL,
  firebaseWebApi,
  VagonApiKey,
  VagonSecret,
} from 'constants/config';
import axios from 'axios';
import { PropertyMedia } from 'types/properties';
import { LogsAPI } from 'api/methods';
import PopupModal from './modals/Modal';
import useGetTourPlayerInfo from './hooks/useGetTourPlayerInfo';
import {
  Types,
  ModalName,
  allowedOriginList,
  Data,
  Payload,
  targetOrigin,
} from './config';

interface AppResponseObject {
  applications: Array<{ id: String; type: String; attributes: Object }>;
  client_code: Number;
  count: Number;
  page: Number;
  timestamp: String;
  // address: IAddress;
  // isLoading?: boolean;
  // action?: {
  //   label: string | React.ReactNode;
  //   disabled?: boolean;
  //   onClick: VoidFunction;
  // };
  // linkProps?: LinkProps;
}
interface StreamResponse {
  streams: Array<{
    id: String;
    type: String;
    attributes: { uid: string; status: string };
  }>;
}

const CryptoJS = require('crypto-js');

const TourPlayerFrame = () => {
  const [openModal, setCurrentModal] = React.useState<ModalName | null>(null);
  const [iframeLoaded, setLoading] = React.useState<boolean>(false);
  const intl = useIntl();
  const { country: region } = useSession();
  const currentPayload = React.useRef<Payload>(null);
  const iframeRef = React.useRef<HTMLIFrameElement>(null);
  const [isUnityLoading, setIsUnityLoading] = React.useState<boolean>(true);
  const [isVupllexMessageSent, setIsVuplexMessageSent] = React.useState(false);
  const {
    query: { propertyId, skinId, units, openWindowBuild },
    history,
    location,
  } = useRouter<{
    propertyId: string;
    cartId: string;
    units?: boolean;
    openWindowBuild?: boolean;
  }>();
  const [streamID, setStreamID] = React.useState<string | null>(null);
  const { response, isLoading, onFailure } = useGetTourPlayerInfo({
    history,
    propertyId,
  });

  React.useEffect(() => {
    window.onbeforeunload = () => {
      LogsAPI.postUserLogs({
        action: 'calculation',
        area: 'tour',
        section: 'summary',
        name: 'tour_ended',
        metadata: propertyId,
        page: `/app/tour/${propertyId}`,
      });
      return 'Please wait...';
    };
    window.onpopstate = () => {
      LogsAPI.postUserLogs({
        action: 'calculation',
        area: 'tour',
        section: 'summary',
        name: 'tour_ended',
        metadata: propertyId,
        page: `/app/tour/${propertyId}`,
      });
    };
    return () => {
      window.onbeforeunload = null;
      window.onpopstate = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { token, id: user_id, logOut, type: userType } = useAuthentication();
  // eslint-disable-next-line
  let _window: any = window;
  const isVagonShow =
    userType === 'SELLER' &&
    process.env.REACT_APP_ENV !== 'production' &&
    streamID;

  // const onSuccess = React.useCallback(
  //   (carts: Cart | null) => {
  //     if (carts?.id) history.replace(stringify({ cartId: carts.id }));
  //   },
  //   [history]
  // );

  // usePropertyCarts({ onSuccess, propertyId });

  const is_mobile = useMediaQuery(MOBILE_RESOLUTION);

  const deepLink = localStorage.getItem('tour:deepLink');
  const tourLink = localStorage.getItem('tour:tourLink');

  const generateRandomString = (length: number) => {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };
  const randomString = generateRandomString(16);

  const {
    callFetch: callGetApplications,
    // isLoading: isFetchLoading,
  } = useFetch({
    initialUrl: 'https://api.vagon.io/app-stream-management/v2/applications',
    skipOnStart: true,
    config: {},
    onSuccess: (res: AppResponseObject | null) => {
      const application_id = res?.applications[0]?.id;
      const timestamp = new Date().getTime();
      const nonce = randomString;
      const api_key = VagonApiKey;
      const path = '/app-stream-management/v2/streams';
      const payloadData = `${api_key}GET${path}${timestamp}${nonce}`;
      const secret = VagonSecret;
      const signature = CryptoJS.HmacSHA256(payloadData, secret).toString(
        CryptoJS.digest
      );
      const auth_value = `HMAC ${api_key}:${signature}:${nonce}:${timestamp}`;
      getStreams({
        params: { application_id },
        headers: { Authorization: auth_value },
      });
    },
  });

  const { callFetch: getStreams } = useFetch({
    initialUrl: 'https://api.vagon.io/app-stream-management/v2/streams',
    skipOnStart: true,
    config: {},
    onSuccess: (res: StreamResponse | null) => {
      if (res?.streams?.length) {
        const strID = res?.streams[0].attributes.uid;
        const status = res?.streams[0].attributes.status;
        if (status !== 'paused') {
          setStreamID(strID);
        } else {
          setStreamID(null);
        }
      }
    },
  });

  React.useEffect(() => {
    const timestamp = new Date().getTime();
    const nonce = randomString;
    const api_key = VagonApiKey;
    const path = '/app-stream-management/v2/applications';
    const payloadData = `${api_key}GET${path}${timestamp}${nonce}`;
    const secret = VagonSecret;
    const signature = CryptoJS.HmacSHA256(payloadData, secret).toString(
      CryptoJS.digest
    );
    const auth_value = `HMAC ${api_key}:${signature}:${nonce}:${timestamp}`;
    if (userType === 'SELLER') {
      callGetApplications({ headers: { Authorization: auth_value } });
    }

    /* eslint-disable-next-line */
  }, []);

  const passTourPlayerInfo = React.useCallback(
    (type: Types.UNITY_LOADED_SUCCESSFULLY | Types.RELOAD_TOUR_REQUEST) => {
      setIsUnityLoading(false);

      const data = JSON.stringify({
        type:
          type === Types.UNITY_LOADED_SUCCESSFULLY
            ? Types.GET_PROPERTY_INFO
            : Types.RELOAD_TOUR,
        payload: {
          skin_id: skinId,
          user_id,
          ...(units
            ? { unit_id: propertyId }
            : { property_id: response?.id?.toString() }),
          token,
          is_mobile,
          region,
          cartId: 0,
          start_position: (location.state as PropertyMedia)?.metadata,
        },
      });

      iframeRef.current?.contentWindow?.postMessage(data, targetOrigin);
      _window.Vagon?.sendApplicationMessage(data);
    },
    [
      propertyId,
      response,
      token,
      user_id,
      units,
      is_mobile,
      region,
      skinId,
      location.state,
      _window.Vagon,
    ]
  );

  const navigateUser = React.useCallback(
    (type: Types.NAVIGATE_PROFILE_PAGE | Types.NAVIGATE_PROPERTIES_PAGE) => {
      const route = {
        [Types.NAVIGATE_PROFILE_PAGE]: ClientRouter.PROFILE,
        [Types.NAVIGATE_PROPERTIES_PAGE]: ClientRouter.PROPERTIES,
      }[type];

      history.push(route);
    },
    [history]
  );

  const messageListener = React.useCallback(
    (event: MessageEvent<string>) => {
      // console.log('webglevent: ', event);
      if (!allowedOriginList.includes(event.origin) || !isString(event.data)) {
        return;
      }
      const { type, payload }: Data = JSON.parse(event.data);
      if (!type) {
        onFailure(false);
        return;
      }

      currentPayload.current = payload;
      const functionsMap = {
        [Types.RELOAD_TOUR_REQUEST]: () => {
          passTourPlayerInfo(Types.RELOAD_TOUR_REQUEST);
        },
        [Types.UNITY_LOADED_SUCCESSFULLY]: () => {
          passTourPlayerInfo(Types.UNITY_LOADED_SUCCESSFULLY);
        },
        [Types.SHOW_QUOTE_MODAL]: () => {
          setCurrentModal(ModalName.QUOTE_MODAL);
        },
        [Types.SHOW_CART_MODAL]: () => {
          setCurrentModal(ModalName.CART_MODAL);
        },
        [Types.SHOW_BUYCOIN_MODAL]: () => {
          setCurrentModal(ModalName.BUYCOIN_MODAL);
        },
        [Types.SHOW_BUY_NOW_MODAL]: () => {
          setCurrentModal(ModalName.BUY_NOW_MODAL);
        },
        [Types.SHOW_BUY_SKIN_MODAL]: () => {
          setCurrentModal(ModalName.BUY_SKIN_MODAL);
        },
        [Types.SHOW_SUPPORT_MODAL]: () => {
          setCurrentModal(ModalName.SUPPORT_MODAL);
        },
        [Types.SHOW_SHARE_MODAL]: () => {
          setCurrentModal(ModalName.SHARE_MODAL);
        },
        [Types.SHOW_ADD_PROPERTY_MODAL]: () => {
          setCurrentModal(ModalName.ADD_PROPERTY_MODAL);
        },
        [Types.NAVIGATE_PROPERTIES_PAGE]: () => {
          navigateUser(Types.NAVIGATE_PROPERTIES_PAGE);
        },
        [Types.NAVIGATE_PROFILE_PAGE]: () => {
          navigateUser(Types.NAVIGATE_PROFILE_PAGE);
        },
        [Types.LOGOUT_USER_REQUEST]: () => {
          logOut();
        },
      }[type];

      functionsMap?.();
    },
    [passTourPlayerInfo, onFailure, navigateUser, logOut]
  );

  const vagonMessageListener = React.useCallback(
    (event: { message: string }) => {
      // console.log('vagon event: ', event);
      const { message } = event;
      if (!message) {
        onFailure(false);
        return;
      }

      const functionsMapVagon = {
        [Types.RELOAD_TOUR_REQUEST]: () => {
          passTourPlayerInfo(Types.RELOAD_TOUR_REQUEST);
        },
        [Types.UNITY_LOADED_SUCCESSFULLY]: () => {
          passTourPlayerInfo(Types.UNITY_LOADED_SUCCESSFULLY);
        },
        [Types.SHOW_QUOTE_MODAL]: () => {
          setCurrentModal(ModalName.QUOTE_MODAL);
        },
        [Types.SHOW_CART_MODAL]: () => {
          setCurrentModal(ModalName.CART_MODAL);
        },
        [Types.SHOW_BUYCOIN_MODAL]: () => {
          setCurrentModal(ModalName.BUYCOIN_MODAL);
        },
        [Types.SHOW_BUY_NOW_MODAL]: () => {
          setCurrentModal(ModalName.BUY_NOW_MODAL);
        },
        [Types.SHOW_BUY_SKIN_MODAL]: () => {
          setCurrentModal(ModalName.BUY_SKIN_MODAL);
        },
        [Types.SHOW_SUPPORT_MODAL]: () => {
          setCurrentModal(ModalName.SUPPORT_MODAL);
        },
        [Types.SHOW_SHARE_MODAL]: () => {
          setCurrentModal(ModalName.SHARE_MODAL);
        },
        [Types.SHOW_ADD_PROPERTY_MODAL]: () => {
          setCurrentModal(ModalName.ADD_PROPERTY_MODAL);
        },
        [Types.NAVIGATE_PROPERTIES_PAGE]: () => {
          navigateUser(Types.NAVIGATE_PROPERTIES_PAGE);
        },
        [Types.NAVIGATE_PROFILE_PAGE]: () => {
          navigateUser(Types.NAVIGATE_PROFILE_PAGE);
        },
        [Types.LOGOUT_USER_REQUEST]: () => {
          logOut();
        },
      }[message];
      functionsMapVagon?.();
    },
    [passTourPlayerInfo, onFailure, navigateUser, logOut]
  );

  React.useEffect(() => {
    if (isVagonShow) {
      _window.Vagon?.onApplicationMessage(vagonMessageListener);
      window.removeEventListener('message', messageListener);
    } else {
      window.addEventListener('message', messageListener);
    }
    return () => {
      window.removeEventListener('message', messageListener);
    };
  }, [messageListener, _window.Vagon, vagonMessageListener, isVagonShow]);

  const sendMessageToCSharp = React.useCallback(() => {
    if (!isVupllexMessageSent && region && propertyId && user_id) {
      _window.vuplex.postMessage({
        token,
        propertyId,
        skin_id: skinId,
        userId: user_id,
        baseWebHost: location.pathname,
        baseEp: `${baseURL}/api`,
        cartId: 0,
        region,
        isMobile: true,
        ambientOcclusionEnabled: false,
        start_position: (location.state as PropertyMedia)?.metadata,
      });
      setIsVuplexMessageSent(true);
      location.pathname = ClientRouter.APP;
    }
  }, [
    region,
    skinId,
    user_id,
    propertyId,
    token,
    _window,
    location,
    isVupllexMessageSent,
    setIsVuplexMessageSent,
  ]);

  React.useEffect(() => {
    if (openWindowBuild && propertyId && region && token) {
      _window.open(
        `patricia://${token}&${user_id}&${propertyId}&${baseURL}/api&${region}&${0}`
      );
      history.goBack();
    } else if (_window.vuplex) {
      sendMessageToCSharp();
    } else {
      window.addEventListener('vuplexready', sendMessageToCSharp);
    }
  }, [
    sendMessageToCSharp,
    _window,
    token,
    user_id,
    propertyId,
    openWindowBuild,
    region,
    history,
  ]);
  const isModalOpen = Boolean(openModal);

  const isWebglSupported = React.useMemo(() => {
    try {
      const canvas = document.createElement('canvas');
      return (
        !!window.WebGLRenderingContext &&
        (canvas.getContext('webgl') || canvas.getContext('experimental-webgl'))
      );
    } catch (e) {
      return false;
    }
  }, []);

  if (isLoading && !iframeLoaded) return <Loader fullHeight />;

  if (
    !_window.vuplex &&
    (deviceInfo.isAndroid || deviceInfo.isIOS) &&
    token &&
    region &&
    user_id
  ) {
    if (!isVupllexMessageSent) {
      setIsVuplexMessageSent(true);
      const hostName = `${window.location.protocol}//${window.location.host}`;

      const redirectUrl = `${hostName}${ClientRouter.MOBILE_TOUR_APP_ROUTE.replace(
        ':ambientOcclusionEnabled',
        'false'
      )
        .replace(':baseEp', `${baseURL}/api`)
        .replace(':baseWebHost', hostName)
        .replace(':cartId', '0')
        .replace(':token', token)
        .replace(':isMobile', 'true')
        .replace(':propertyId', propertyId)
        .replace(':skinId', (skinId as unknown) as string)
        .replace(':region', region)
        .replace(':userId', (user_id as unknown) as string)
        .replace(
          ':start_position',
          JSON.stringify((location.state as PropertyMedia)?.metadata)
        )}`;
      if (tourLink !== redirectUrl || isEmpty(deepLink)) {
        axios
          .post(
            `https://firebasedynamiclinks.googleapis.com/v1/shortLinks/?key=${firebaseWebApi}`,
            {
              suffix: {
                option: 'UNGUESSABLE',
              },
              dynamicLinkInfo: {
                domainUriPrefix: 'patriciaai.page.link',
                link: redirectUrl,
                androidInfo: {
                  androidPackageName: 'com.patriciaai.com.PatriciaTourPlayer',
                  androidMinPackageVersionCode: '1',
                },
                iosInfo: {
                  iosBundleId: 'com.patricia-ai.com.PatriciaTourPlayer',
                  iosCustomScheme: 'https://patriciaai.page.link/iDzQ',
                  iosIpadBundleId: 'com.patricia-ai.com.PatriciaTourPlayer',
                  iosAppStoreId: '1597629731',
                },
              },
            },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          )
          .then(r => {
            localStorage.setItem('tour:tourLink', redirectUrl);
            localStorage.setItem('tour:deepLink', r.data.shortLink);
            window.location.href = r.data.shortLink;
          });
      } else if (deepLink) {
        window.location.href = deepLink;
      }
    }
    return <Redirect to={ClientRouter.APP} />;
  }

  if (_window.vuplex && isVupllexMessageSent)
    return <Redirect to={{ pathname: ClientRouter.APP }} />;
  if (_window.vuplex && !isVupllexMessageSent) {
    return <Loader />;
  }

  return (
    <Root>
      {isWebglSupported ? (
        <>
          {isVagonShow ? (
            <TourPlayer
              title="Patricia"
              id="vagonFrame"
              onLoad={() => setLoading(true)}
              // ref={iframeRef}
              src={`https://streams.vagon.io/streams/${streamID}`}
            />
          ) : (
            <TourPlayer
              onLoad={() => setLoading(true)}
              ref={iframeRef}
              src={targetOrigin}
            />
          )}
        </>
      ) : (
        <>
          <WebglNotSupportedRoot>
            <h1>Seems like webgl is not supported in your browser. :(</h1>
            <br />
            <h3>
              Please click{' '}
              <a href="https://support.biodigital.com/hc/en-us/articles/218322977-How-to-turn-on-WebGL-in-my-browser">
                here
              </a>{' '}
              to know more and steps to enable webgl in your browser.
            </h3>
          </WebglNotSupportedRoot>
        </>
      )}

      {isWebglSupported && (
        <UnityLoader $display={isUnityLoading}>
          <LoaderGif
            src={TourUnityLoader}
            alt={intl.formatMessage({ id: Message.LOADING })}
          />
          <LoaderText>
            <FormattedMessage
              id={Message.TOUR_PLAYER_LOADING_TEXT}
              values={{ linebreak: <br /> }}
            />
          </LoaderText>
        </UnityLoader>
      )}

      <PopupModal
        setCurrentModal={setCurrentModal}
        isOpen={isModalOpen}
        currentModal={openModal as ModalName}
        data={currentPayload.current}
      />
    </Root>
  );
};

export default TourPlayerFrame;

const Root = styled.div.attrs({
  className: 'unity-page',
})``;
const TourPlayer = styled.iframe.attrs({
  className: 'unity-iframe',
})`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  border: none;
`;

const UnityLoader = styled.div<{ $display: boolean }>`
  display: ${({ $display }) => ($display ? 'flex' : 'none')};
  z-index: 9999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100vw;
  background: #fafafa;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const WebglNotSupportedRoot = styled.div`
  margin: auto;
  margin-top: 30%;
  width: fit-content;
`;

const LoaderGif = styled.img`
  height: 37.5rem;
  width: 37.5rem;
  margin: 0 auto;
  margin-bottom: none;
  display: block;
  @media ${MOBILE_RESOLUTION} {
    height: 18.75rem;
    width: 18.75rem;
  }
`;

const LoaderText = styled.h3`
  color: #000;
  text-align: center;
  font-family: ${({ theme }) => (theme.dir === 'rtl' ? 'inherit' : 'DM Sans')};
  font-size: 22px;
  line-height: 26px;
`;
