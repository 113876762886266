// import { Message } from 'i18n';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import {
  MOBILE_TABLET_RESOLUTION,
  MOBILE_RESOLUTION,
  SMALL_WIDTH_RESOLUTION,
  TABLET_RESOLUTION,
  SMALL_MOBILE_RESOLUTION,
} from 'styles/sharedStyle';
import * as deviceInfo from 'react-device-detect';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import offer from './mock/offer';

const Offers = () => {
  const isMobile =
    useMediaQuery(MOBILE_TABLET_RESOLUTION) || deviceInfo.isMobile;

  return (
    <Root>
      <Container>
        <CardContainer>
          {offer.map((item, index) => {
            return (
              <>
                <Card cardIndex={index} key={item.id}>
                  <TextSection cardIndex={index}>
                    <Title className="commonPadding">
                      <p>{item?.title}</p>
                    </Title>
                    <SubTitle className="commonPadding">
                      <p>
                        <span>{item?.subTitleBold}</span>
                        {item?.subTitle}
                      </p>
                    </SubTitle>
                    <Description className="commonPadding">
                      <FormattedMessage id={item?.description} />
                    </Description>
                  </TextSection>
                  <VideoContainer cardIndex={index}>
                    {/* <img src={item.image} alt="Not available" /> */}
                    <VideoFrame
                      isMobile={isMobile}
                      autoPlay
                      loop
                      muted
                      playsInline
                    >
                      <source src={item?.video} type="video/mp4" />
                    </VideoFrame>
                  </VideoContainer>
                </Card>
              </>
            );
          })}
        </CardContainer>
      </Container>
    </Root>
  );
};

export default Offers;

const Root = styled.section`
  width: 100%;

  @media ${MOBILE_TABLET_RESOLUTION} {
    padding: 0px;
  }
`;

const Container = styled.div`
  padding: 0px;
`;

const CardContainer = styled.div`
  margin-top: 0px;
`;

const Card = styled.div<{ cardIndex: number }>`
  display: flex;
  position: relative;
  color:${({ cardIndex }) => (cardIndex % 2 === 0 ? '#3b4848' : '#d5d4cf')}
}
};
flex-direction:${({ cardIndex }) =>
  cardIndex % 2 === 0 ? 'row-reverse' : 'row'}
}

::before{
  position: absolute;
    top: 0;
    width: 100%;
    background-color:${({ cardIndex }) =>
      cardIndex % 2 === 0 ? '#d9dcdf' : '#191919'};
    content: "";
    height: 100%;

    @media ${MOBILE_TABLET_RESOLUTION} {
   width: 100%;
  }
    
}
@media ${MOBILE_TABLET_RESOLUTION} {
    flex-direction: column;
  }
`;

const TextSection = styled.div<{ cardIndex: number }>`
  position: relative;
  width: 50%;
  padding: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  && .commonPadding {
    padding: 10px 0px;
  }

  @media (max-width: 1400px) {
    padding: 100px 100px;
  }

  @media ${TABLET_RESOLUTION} {
    padding: 100px 70px;
  }

  @media ${SMALL_WIDTH_RESOLUTION} {
    padding: 50px;
  }

  @media ${MOBILE_TABLET_RESOLUTION} {
    width: 100%;
    padding: 50px 100px;
    text-align: center;
    line-height: 3rem;
    && .commonPadding {
      padding: 0px;
    }
  }
  @media ${MOBILE_RESOLUTION} {
    padding: 50px;
  }

  @media ${SMALL_MOBILE_RESOLUTION} {
    padding: 25px;
  }
`;

const Title = styled.div`
  color: #fc5c42;
  font-size: 2.5rem;

  @media (max-width: 1630px) {
    font-size: 2rem;
  }

  @media (max-width: 1400px) {
    font-size: 1.6rem;
  }

  @media ${TABLET_RESOLUTION} {
    font-size: 1.4rem;
  }
`;
const SubTitle = styled.div`
  font-size: 4.5rem;

  @media (max-width: 1630px) {
    font-size: 3.5rem;
  }
  @media (max-width: 1400px) {
    font-size: 3rem;
  }
  @media ${TABLET_RESOLUTION} {
    font-size: 2.5rem;
  }
  & > p > span {
    font-weight: bold;
  }
`;
const Description = styled.div`
  font-size: 2.5rem;
  @media (max-width: 1630px) {
    font-size: 2rem;
  }

  @media (max-width: 1400px) {
    font-size: 1.6rem;
  }
  @media (max-width: 1200px) {
    font-size: 1.4rem;
  }
  & > p {
    font-size: 1.5rem;
    @media ${SMALL_WIDTH_RESOLUTION} {
      font-size: 2rem;
    }
  }
`;

const VideoContainer = styled.div<{ cardIndex: number }>`
  position: relative;
  width: 50%;
  height: auto;
  display: flex;
  align-items: center;
  object-fit: cover;
  padding: 100px 0px;
  & > video {
    width: 100%;
    max-height: 750px;
    ${({ cardIndex }) =>
      cardIndex % 2 === 0
        ? 'border-top-right-radius'
        : 'border-bottom-left-radius'}: 50px;
  }

  @media ${MOBILE_TABLET_RESOLUTION} {
    width: 100%;
    padding: 0px;
    & > video {
      width: 80%;
    }
    justify-content: ${({ cardIndex }) =>
      cardIndex % 2 === 0 ? 'start' : 'end'};
  }
  @media ${MOBILE_RESOLUTION} {
    width: 100%;
  }
`;

const VideoFrame = styled.video<{ isMobile: boolean }>`
  object-fit: fill;
  ${({ isMobile }) =>
    isMobile &&
    css`
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    `}
`;
