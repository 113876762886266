const getEnv = () =>
  process.env.REACT_APP_BASE_URL
    ? JSON.parse(process.env.REACT_APP_BASE_URL)[
        process.env.REACT_APP_ENV ?? 'development'
      ]
    : {
        url: 'https://patricia-app-dev.herokuapp.com',
        googleApi: '',
        googleOAuthId: '',
        unityURL:
          'https://patricia-webgl-app-staging.s3.eu-central-1.amazonaws.com/index.html',
        appleOAuthId: 'com.patricia-ai.patricia',
        vagonApiKey: '2505858b-4f77-4245-8a4b-d4e6c8d6f3a9',
        vagonSecret: '27e1a7ba89e549c8bb692104a26185f3',
      };

export const baseURL = getEnv().url;
export const GoogleAPI = getEnv().googleApi;
export const googleClientId = getEnv().googleOAuthId;
export const facebookClientId = getEnv().facebookOAuthId;
export const appleClientId = getEnv().appleOAuthId;
export const unityUrl = getEnv().unityURL;
export const firebaseWebApi = getEnv().firebaseApi;
export const unityProductUrl = getEnv().unityProductApi;
export const VagonApiKey = getEnv().vagonApiKey;
export const VagonSecret = getEnv().vagonSecret;

// roles & permissions
export enum Permission {
  USER_MANAGEMENT = 'USER_MANAGEMENT',
  PROPERTIES_MANAGEMENT = 'PROPERTIES_MANAGEMENT',
  MANAGEMENT_3D_CONTENT = 'MANAGEMENT_3D_CONTENT',
  SUPPLIER_SERVICE = 'SUPPLIER_SERVICE',
  QUIZ_MANAGEMENT = 'QUIZ_MANAGEMENT',
}
export enum Type {
  TEAM_PATRICIA = 'TEAM_PATRICIA',
  SUPPLIER = 'SUPPLIER',
  CLIENT = 'CLIENT',
  SELLER_CLIENT = 'SELLER_CLIENT',
  SELLER = 'SELLER',
  PROFESSIONAL = 'PROFESSIONAL',
}
