import Button from 'components/@client/Button/Button';
import { FieldIcon } from 'components/@client/CardList/Card';
import confirmationImage from 'assets/@client/onboarding/confirmation-image.gif';
import { useRouter, useSession } from 'hooks';
import { Message } from 'i18n';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { ClientRouter } from 'router/routes';
import styled from 'styled-components';
import { Property } from 'types/properties';
import { useOnboarding } from 'context/onboardingContext';

const PropertyCard: React.FC<{ property: Property | null }> = ({
  property,
}) => {
  const [{ selectedFloor }] = useOnboarding();
  const { locale } = useSession();
  const { history } = useRouter();

  return (
    <CustomDiv>
      <div className="card d-flex justify-content-center">
        <div className="plan-img">
          <img alt="plan img" src={confirmationImage} />
        </div>
      </div>
      <div className="property-data">
        <div className="property-name">
          <FormattedMessage id={Message.PROPERTY_CARD_NAME} />

          <div
            className="property-name-data"
            dir={locale === 'he' ? 'rtl' : 'ltr'}
          >
            <FormattedMessage
              id={Message.PLAN_BEDROOMS}
              values={{ name: selectedFloor?.name }}
            />
          </div>
        </div>
        <div className="property-name">
          <FormattedMessage id={Message.PROPERTY_SIZE} />
          <div className="property-name-data">{selectedFloor?.type}</div>
        </div>
        <div className="property-name">
          <FormattedMessage id={Message.PROPERTY_CARD_DESIGN_STYLE} />
          <div className="property-name-data">
            <FieldIcon name="discover" size={25} />{' '}
            {property?.quizResult?.designStyle?.name}
          </div>
        </div>
      </div>
      <Button
        className="tour-btn"
        onClick={() =>
          history.push(
            ClientRouter.TOUR.replace(':propertyId', property?.id ?? '')
          )
        }
      >
        <FormattedMessage id={Message.PROPERTY_BUTTON_TAKE_TOUR} />
      </Button>
    </CustomDiv>
  );
};

const CustomDiv = styled.div`
  & .card {
    width: 484px;
    height: 420px;
    margin: 0 auto 0;
    border-radius: 20px;
    justify-content: space-between;
    margin-bottom: 24px;
    @media (max-width: 767px) {
      height: fit-content;
      width: 100%;
    }
  }
  & .plan-img {
    @media (max-width: 767px) {
      width: 327px;
      height: 285px;
      margin: 0 auto;
    }
    img {
      height: 420px;
      width: 484px;
      object-fit: cover;
      margin-top: 32px;
      @media (max-width: 767px) {
        width: 100%;
        height: 100%;
      }
    }
  }
  & .property-data {
    width: 484px;
    gap: 32px;
    text-align: center;
    margin: 64px auto 32px;
    display: flex;
    justify-content: center;
    @media (max-width: 767px) {
      width: 327px;
      gap: 23px;
    }
  }
  & .property-name {
    font-style: normal;
    width: 118px;
    font-weight: 400;
    font-size: 18px;
    line-height: 16px;
    color: #696969;
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
  & .property-name-data {
    font-weight: 600;
    font-size: 20px;
    line-height: 21px;
    color: #282929;
    margin-top: 6px;
    justify-content: center;
    display: flex;
    @media (max-width: 767px) {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #3e3e3e;
    }
  }
  & .tour-btn {
    width: ${({ theme }) => (theme.dir === 'rtl' ? '313px' : '385px')};
    height: 48px;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 16px;
    @media (max-width: 767px) {
      width: 202px;
      height: 48px;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
    }
  }
  & .icon-discover {
    width: 17.73px;
    @media (max-width: 767px) {
      width: 13.73px;
      margin: 0 4px 0 0 !important;
    }
  }
`;

export default PropertyCard;
