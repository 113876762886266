import * as React from 'react';
import MuiChip, { ChipProps as MuiChipProps } from '@material-ui/core/Chip';
import DeleteIcon from 'components/DeleteIcon';
import styled from 'styled-components';

export interface ChipProps extends MuiChipProps {
  $dense?: true;
  $color?: string;
  component?: React.ElementType;
}

const Chip = styled(MuiChip).attrs<ChipProps>(
  ({ component = 'span', className }) => ({
    deleteIcon: <DeleteIcon />,
    component,
    className,
  })
)<ChipProps>`
  &&.MuiChip-root {
    height: ${({ $dense }) => ($dense ? '23px' : '25px')};
  }

  && {
    background: ${({ $color = '#dedede' }) => $color};
    border-radius: 8px;
    color: ${({ $color, theme }) =>
      $color ? theme.colors.primary.primaryTextColor : '#576f83'};
    margin: 0.2rem;
    font-size: 0.875rem;
    font-weight: 400;
    text-transform: capitalize;
    height: 28px;
    z-index: 5;
    min-width: 50px;
    max-width: 180px;
    width: auto;

    @media (max-width: 1500px) {
      font-size: 0.75rem;
    }
  }
`;

export default Chip;
