import * as React from 'react';
import { Text, StyleSheet, View } from '@react-pdf/renderer';
import theme from 'styles/theme';

const styles = StyleSheet.create({
  table: {
    color: theme.colors.primary.primaryTextColor,
    width: '100vw',
    borderStyle: 'solid',
    borderColor: '#E8E8E8',
    borderBottomWidth: 1,
    borderRightWidth: 0,
    // marginLeft: '20px',
    // marginRight: '20px',
    fontFamily: 'Varela Round',
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
    borderStyle: 'solid',
    borderColor: '#E8E8E8',
    borderBottomWidth: 1,
    marginBottom: '0',
    marginTop: '0',
  },
  tableCol: {
    flexGrow: 1,
    borderStyle: 'solid',
    borderLeftWidth: 0,
    borderTopWidth: 0,
    height: 30,
    width: 150,
  },
  name: {
    flexGrow: 1,
  },
  header: {
    backgroundColor: '#FC5C42',
    height: '40px',
    borderBottomWidth: 0,
  },
  center: {
    textAlign: 'center',
  },
  tableCell: { marginTop: 10, fontSize: 10 },
});

export const Table: React.FC = ({ children }) => {
  return <View style={styles.table}>{children}</View>;
};

export const TableRow: React.FC<{
  header?: true;
  data?: { label: string; style: object }[];
  fullWidth?: boolean;
  pageBreak?: boolean;
  rowStyle?: {};
}> = ({
  children,
  header,
  data,
  fullWidth = false,
  pageBreak = false,
  rowStyle,
}) => {
  const headerStyles = header ? styles.header : {};
  return (
    <View
      style={{
        ...styles.tableRow,
        ...headerStyles,
        width: fullWidth ? '100%' : 'auto',
        ...rowStyle,
      }}
      break={pageBreak}
    >
      {data
        ? data.map(({ label, style }) => (
            <TableCell key={label} style={style}>
              {label}
            </TableCell>
          ))
        : children}
    </View>
  );
};

export const TableCell: React.FC<{ style?: object }> = ({
  style,
  children,
}) => {
  return (
    <View style={{ ...styles.tableCol, ...style }}>
      <Text style={styles.tableCell}>{children}</Text>
    </View>
  );
};
