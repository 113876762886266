import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import FileDisplay, { FileType } from 'components/@client/FileDisplay';
import * as Section from 'pages/appClient/onboarding/shared';
import { ClientRouter } from 'router/routes';
import TruckProgressBar from 'components/@client/TruckProgressBar/TruckProgressBar';
import { useSession } from 'hooks';
import { SupportedLanguage } from 'types/common.types';
import { useOnboardingState } from 'context/onboardingContext';
import useConfirmPlan from './useConfirmPlan';

const ConfirmPlan = () => {
  const { selectedFloor, goBack, onClick, image } = useConfirmPlan();
  const { locale } = useSession();
  const { isScan } = useOnboardingState();
  if (!isScan)
    if (!selectedFloor) return <Redirect to={ClientRouter.UPLOAD_PLAN} />;

  const name =
    locale === SupportedLanguage.HEBREW
      ? Number(selectedFloor?.name) + 1
      : Number(selectedFloor?.name);

  return (
    <Container className="confirm-plan-container">
      <Section.Header>
        <Section.TitleStack
          label={<FormattedMessage id={Message.UPLOAD_PLAN_CONTAINER_TITLE} />}
        />
      </Section.Header>
      {image ? (
        <FileDisplay
          type={FileType[image.type as keyof typeof FileType]}
          fileName={image.name}
        />
      ) : (
        <Text dir={locale === 'he' ? 'rtl' : 'ltr'}>
          <FormattedMessage
            id={Message.PLAN_BEDROOMS}
            values={{ name: { name } }}
          />
        </Text>
      )}
      <SelectedFloor>
        <img src={selectedFloor?.file} alt="Selected floor plan" />
      </SelectedFloor>
      <TruckProgressBar value={15} />
      <Section.Actions
        withPrevious
        prevProps={{ onClick: goBack }}
        nextProps={{ disabled: !selectedFloor, onClick }}
      />
    </Container>
  );
};

export default ConfirmPlan;

const Container = styled(Section.Container)`
  & .file-display {
    margin: 20px 0;
    justify-content: center;
  }
`;

const SelectedFloor = styled(Section.Floor)`
  max-width: calc(319px - 20px);
  margin: 10px auto 0 auto;

  @media (min-height: 700px) {
    max-width: 319px;
    margin-top: 45px;
  }

  @media (min-width: 1150px) {
    max-width: calc(550px - 90px);
    height: auto;
  }
`;

const Text = styled.p`
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  margin-top: 30px;
`;
