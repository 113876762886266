import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import styled from 'styled-components';
import Autocomplete from 'components/Autocomplete';
import Container from 'components/Autocomplete/Container';
import InputButton from 'components/InputButton';
import SearchInput from 'components/SearchInput';
import SelectActions from 'components/Select/SelectActions';
import { useBreakpoint, useAutocomplete } from 'hooks';
import { isEmpty } from 'utils/lodash.utils';
import type {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteRenderOptionState,
} from '@material-ui/core/Autocomplete';
import type { Option } from 'components/Autocomplete/Autocomplete.config';
import type { Ref, Setter } from 'types/common.types';

interface Props {
  disabled?: boolean;
  anchorEl: HTMLElement | null;
  disableSelectAll?: true;
  endIcon?: React.ReactNode;
  options: Option[];
  open: boolean;
  inputRef?: Ref<unknown>;
  multiple?: boolean;
  selectedPlaceholder?: string;
  placeholder?: string;
  responsive?: boolean;
  searchPlaceholder?: string;
  startIcon?: React.ReactNode;
  value: Option[] | Option | null;
  width?: number | string;
  height?: number;
  handleClose: () => void;
  onClick: (event: React.SyntheticEvent<HTMLDivElement, Event>) => void;
  onChange?: (
    event: React.SyntheticEvent<Element, Event>,
    value: string | Option | (string | Option)[] | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<Option | Option[]>
  ) => void;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  onClear?: () => void;
  onApply?: () => void;
  groupBy?: (option: Option) => string;
  renderValue?: (value: Option) => React.ReactNode | string;
  renderOption?:
    | ((
        props: React.HTMLAttributes<HTMLLIElement>,
        option: Option,
        state: AutocompleteRenderOptionState
      ) => React.ReactNode)
    | undefined;
  setValue?: Setter<Option[]> | Setter<Option | null>;
  disablePortal?: boolean;
}

const Combobox: React.FC<Props> = ({
  disabled,
  anchorEl,
  disableSelectAll,
  endIcon,
  inputRef,
  multiple,
  options,
  open,
  placeholder = 'Select',
  responsive = true,
  searchPlaceholder = 'Select',
  selectedPlaceholder,
  startIcon,
  value,
  width = 230,
  height = 48,
  handleClose,
  onBlur,
  onChange,
  onClick,
  onClear,
  onApply,
  renderOption,
  renderValue,
  setValue,
  disablePortal,
  groupBy,
}) => {
  const { inputValue, onInputClear, ...comboBoxProps } = useAutocomplete({
    renderOption,
    multiple,
    options,
    placeholder: searchPlaceholder,
    setValue,
    onChange,
    value,
    disableSelectAll,
    isOpen: open,
    width,
    height,
    onClose: handleClose,
  });

  const PaperComponent = React.useCallback(
    props => (
      <CustomPaper {...props}>
        {props.children}
        {multiple && (
          <Actions>
            <CustomDivider />
            <SelectActions onClear={onClear} onApply={onApply} />
          </Actions>
        )}
      </CustomPaper>
    ),
    [onClear, multiple, onApply]
  );

  const { isSmallScreen } = useBreakpoint();

  const displayValue = React.useMemo(() => {
    if (Array.isArray(value)) return value.map(item => item?.name);
    if (renderValue && value) return [renderValue(value)];
    return value ? [(value as Option)?.name] : [];
  }, [value, renderValue]);

  const $width = React.useMemo(() => {
    if (typeof width === 'string') return width;
    if (width) return `${width - 66}px`;
    return 'inherit';
  }, [width]);

  return (
    <Wrapper>
      <InputButton
        disabled={disabled}
        isOpen={open}
        placeholder={placeholder}
        displayPlaceholder={isEmpty(displayValue)}
        onClick={onClick}
        selectedItems={displayValue as string[]}
        selectedPlaceholder={selectedPlaceholder}
        disableCounter={!multiple || (responsive && isSmallScreen)}
        responsive={responsive}
        startIcon={startIcon}
        endIcon={endIcon}
        isHideEndIcon={isSmallScreen && responsive}
        active={!isEmpty(value)}
        width={width}
        height={height}
      />
      <Container
        borderRadius={{ bottom: 0, top: 12 }}
        open={open}
        anchorEl={anchorEl}
        onClickAway={() => (onApply && onApply()) || handleClose()}
        width={width}
        disablePortal={disablePortal}
      >
        <Autocomplete
          open
          PaperComponent={PaperComponent}
          multiple={multiple}
          onBlur={onBlur}
          value={multiple ? value : undefined}
          disableCloseOnSelect
          options={options}
          {...comboBoxProps}
          inputRef={inputRef}
          inputValue={inputValue}
          placeholder={searchPlaceholder}
          groupBy={groupBy}
          renderInput={params => (
            <SearchInput
              placeholder={searchPlaceholder}
              inputValue={inputValue}
              onInputClear={onInputClear}
              width={$width}
              {...params}
            />
          )}
        />
      </Container>
    </Wrapper>
  );
};

export default React.memo(Combobox);

const CustomPaper = styled(Paper).attrs({ className: 'combo-box-list-paper' })`
  && {
    box-shadow: none !important;
    margin: 0;
    color: #586069;
    font-size: 0.875rem;
    position: relative;
    border: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

const Actions = styled.div`
  position: relative;
  background-color: white;
`;

const CustomDivider = styled(Divider)`
  padding: 0.3rem;
  width: 100%;
`;

const Wrapper = styled.div`
  position: relative;
`;
