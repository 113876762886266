import * as React from 'react';
import MuiAvatar from '@material-ui/core/Avatar';
import styled, { css } from 'styled-components';
import { useAuthentication, useFetch } from 'hooks';
import { getUserName } from 'utils/string.utils';
import { Type } from 'constants/config';
import { METHODS } from 'api/client';
import { SupplierUser } from 'api/transform';
import SafeImg from 'components/SafeImg/SafeImg';
import { isMobile } from 'react-device-detect';
import { Menu } from '@material-ui/icons';
import Preferences from './Preferences';

const Header: React.FC<{ onDrawerOpen?: VoidFunction }> = ({
  onDrawerOpen,
}) => {
  const {
    firstName,
    lastName,
    loading,
    role,
    profilePicture,
    avatar,
    type,
    supplierId,
  } = useAuthentication();

  const { data, isLoading, callFetch } = useFetch({
    initialUrl: `api/suppliers/${supplierId}/`,
    skipOnStart: true,
    config: {
      method: METHODS.GET,
    },
    transform: SupplierUser,
  });

  React.useEffect(() => {
    if (type === Type.SUPPLIER)
      if (supplierId) callFetch({ url: `api/suppliers/${supplierId}/` });
  }, [callFetch, type, supplierId]);

  const callEditSupplier = React.useCallback(() => {
    callFetch();
  }, [callFetch]);

  const displayName = getUserName({ firstName, lastName });
  return (
    <>
      <Container className="header" type={type === Type.SUPPLIER}>
        {isMobile && (
          <div
            onClick={onDrawerOpen}
            style={{ position: 'absolute', left: '12px' }}
          >
            <Menu />
          </div>
        )}
        {type === Type.SUPPLIER && (
          <>
            <SupplierLogo>
              <SafeImg src={data?.logo} alt={data?.companyName ?? ''} />
            </SupplierLogo>
            <SupplierInfo>
              {data?.companyName && <>{data?.companyName},</>} Welcome to
              Patricia Seller Central
            </SupplierInfo>
          </>
        )}
        <Account>
          <Avatar src={profilePicture ?? avatar} />
          <TextStack>
            <Name>
              {loading ? 'Loading...' : displayName}
              <Role>{role ?? ''}</Role>
            </Name>
            <Preferences
              isSupplier={type === Type.SUPPLIER}
              supplier={data}
              callEditSupplier={callEditSupplier}
              isLoading={isLoading}
            />
          </TextStack>
        </Account>
      </Container>
    </>
  );
};

export default Header;

const Container = styled.header<{
  type?: boolean;
}>`
  ${({ type }) =>
    type
      ? css`
          justify-content: space-between;
        `
      : css`
          justify-content: flex-end;
        `}
  display: flex;
  align-items: center;
  position: fixed;
  background: white;
  box-shadow: 3px 2px 10px 3px #a0a0a029;
  height: 60px;
  width: 100%;
  z-index: 1;
  padding: 1rem 2rem 1rem 90px;
  left: 0;
  top: 0;
`;

const SupplierLogo = styled.section`
  height: 50px;
  display: flex;
  margin-left: 1rem;
  img {
    margin: auto;
    max-width: 100%;
    max-height: 100%;
  }
`;

const SupplierInfo = styled.section`
  font-size: 1.2rem;
  text-align: center;
  margin: 0 0.5rem;
`;

const Account = styled.section`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const Avatar = styled(MuiAvatar)`
  background: black;
  border-radius: 50%;

  && {
    height: 40px;
    width: 40px;
  }
`;

const TextStack = styled.div`
  display: flex;
  align-items: center;
`;

const Name = styled.p`
  margin: 0;
  font-size: 1rem;
  color: black;
  font-weight: 500;
  padding: 0.5rem;
`;

const Role = styled.span`
  margin: 0;
  margin-top: 0.3rem;
  display: block;
  font-size: 0.75rem;
  color: #2b2b2b;
  font-weight: 300;
`;
