import Button, { ButtonProps } from '@material-ui/core/Button';
import styled, { css } from 'styled-components';

const TextButton = styled(Button).attrs(() => ({
  variant: 'text',
  color: 'inherit',
  size: 'small',
  type: 'button',
}))<{
  $red?: boolean;
  $noTextTransform?: boolean;
  $noDecoration?: boolean & ButtonProps;
}>`
  && {
    color: ${({ theme, $red }) =>
      $red ? theme.colors.primary.light : 'inherit'};
    ${({ $noDecoration }) =>
      $noDecoration
        ? css`
            padding: 0;
            text-decoration: none;
          `
        : css`
            text-decoration: underline;
          `};
    font-size: 0.85rem;
    font-weight: 800;
    text-transform: ${({ $noTextTransform }) =>
      $noTextTransform ? 'none' : 'capitalize'};

    &:hover {
      text-decoration: underline;
      background: transparent;
    }
  }
`;

export default TextButton;
