import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Breadcrumbs from 'components/@client/Breadcrumbs';
import Title from 'components/@client/TopTitle/Title';
import Button from 'components/@client/Button';
import Loader from 'components/Loader/Loader';
import { Message } from 'i18n';
import PriceField from 'pages/appClient/checkout/PriceField';
import { useFetch, useSession } from 'hooks';
import { CheckoutContainer } from 'pages/appClient/checkout/CheckoutContainer';
import PaymentModal from 'pages/appClient/checkout/PaymentModal';
import NotFound from 'pages/common/404/NotFound';
import { ClientRoot, MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';
import { ClientRouter } from 'router/routes';
import { METHODS } from 'api/client';
import { SupportedCountry } from 'types/common.types';
import SkinCheckoutItem from './SkinCheckoutItem';
import useSkin from '../skin/useSkin';

const SkinCheckout = () => {
  const intl = useIntl();
  const [isPaying, setIsPaying] = React.useState(false);
  const openPaymentModal = React.useCallback(() => setIsPaying(true), []);
  const closePaymentModal = React.useCallback(() => setIsPaying(false), []);

  const isMobile = MOBILE_TABLET_RESOLUTION;
  const { skin, isLoading = true, hasError } = useSkin();
  const { currency, country } = useSession();

  const { data, callFetch, isLoading: isCheckoutLoading } = useFetch<{
    hs: string;
  }>({
    initialUrl: '/api/payment/handshake/skin_checkout/',
    skipOnStart: true,
    onSuccess: () => openPaymentModal(),
  });

  const skinPriceFields = React.useMemo(
    () => [
      {
        label: intl.formatMessage({ id: Message.CHECKOUT_SUB_TOTAL_LABEL }),
        value: skin?.subTotal ?? 0,
      },
      {
        label: intl.formatMessage(
          {
            id:
              country === SupportedCountry.ISRAEL
                ? Message.CHECKOUT_VAT_LABEL
                : Message.CHECKOUT_TAX_LABEL,
          },
          { value: currency === 'ILS' ? '17%' : '10%' }
        ),
        value: skin?.vat ?? 0,
      },
      {
        label: intl.formatMessage({ id: Message.CHECKOUT_DISCOUNT_LABEL }),
        value: 0,
      },
      {
        label: intl.formatMessage({ id: Message.CHECKOUT_TOTAL_LABEL }),
        value: skin?.finalPrice ?? 0,
      },
    ],
    [intl, skin?.subTotal, skin?.vat, skin?.finalPrice, country, currency]
  );

  return isLoading ? (
    <Loader fullHeight />
  ) : (
    <>
      <ClientRoot>
        {(!skin && !isLoading) || hasError ? (
          <NotFound />
        ) : (
          <>
            <Breadcrumbs
              links={[
                {
                  to: ClientRouter.SKINS,
                  children: intl.formatMessage({ id: Message.SKINS_TITLE }),
                },
                {
                  to: ClientRouter.SKIN.replace(
                    ':id',
                    skin?.id.toString() ?? ''
                  ),
                  children: skin?.name ?? '',
                },
                {
                  to: ClientRouter.SKIN_CHECKOUT,
                  children: intl.formatMessage({ id: Message.CHECKOUT_TITLE }),
                },
              ]}
            />

            {isMobile ? (
              <Title
                title={skin?.name ?? ''}
                backTo={ClientRouter.SKIN.replace(
                  ':id',
                  skin?.id.toString() ?? ''
                )}
              />
            ) : (
              <Title title="Checkout" backTo={ClientRouter.APP} />
            )}
            <CheckoutContainer className="skin-cart-item">
              <section className="actions">
                <div>
                  <SkinCheckoutItem skin={skin} />
                </div>
                <section className="cart-section shipping-section">
                  {skinPriceFields.map(priceField => (
                    <PriceField
                      key={priceField.label}
                      fractionDigits={2}
                      {...priceField}
                    />
                  ))}
                  <hr />
                  <Button
                    isLoading={isCheckoutLoading}
                    className="checkout-button"
                    onClick={() =>
                      callFetch({
                        method: METHODS.POST,
                        data: {
                          skin: skin?.id,
                        },
                      })
                    }
                  >
                    <FormattedMessage id={Message.CART_CHECKOUT_BUTTON} />
                  </Button>
                </section>
              </section>
            </CheckoutContainer>
          </>
        )}
      </ClientRoot>
      <PaymentModal
        open={isPaying}
        skin={skin}
        onClose={closePaymentModal}
        hs={data?.hs}
        publicKey=""
        clientSecretKey=""
      />
    </>
  );
};

export default SkinCheckout;
