import { Message } from 'i18n';
import * as React from 'react';
import { FormProvider, UseFormMethods } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { countryOptions, ISOCode } from 'data/countries';
import { phonecodes } from 'data/phonecodes';
import FlagIcon from 'components/FlagIcon/FlagIcon';
import Icon from 'components/Icon';
import { Setter } from 'types/common.types';
import { summarizeText } from 'utils/string.utils';
import { CountryOption } from 'components/Filters/Options';
import { Country, Option } from 'components/Autocomplete/Autocomplete.config';
import * as Form from 'components/forms';
import DragHomeButton from 'pages/appClient/onboarding/shared/DragHomeButton';
import {
  StoreInfo,
  useSupplierOnboardingState,
} from 'context/supplierOnboardingContext';
import { PhoneField } from 'components/forms';
import InputAdornment from '@mui/material/InputAdornment';
import ImagePicker from '../components/ImagePicker';
import MobileTooltip from '../components/MobileTooltip';

const SupplierInfoStoreFormMobile: React.FC<{
  onSubmit: VoidFunction;
  methods: UseFormMethods<StoreInfo>;
  isValid: boolean;
  isDirty: boolean;
  isLoading: boolean;
  // phoneLocal: string;
  // setPhoneLocal: Setter<string>;
  setCountry: Setter<Option | Country | null>;
  country: Option | Country | null;
}> = ({
  onSubmit,
  methods,
  isValid,
  isDirty,
  isLoading,
  setCountry,
  country,
}) => {
  const intl = useIntl();
  const [open, setOpen] = React.useState<boolean>(false);
  const { setValue } = methods;
  const state = useSupplierOnboardingState();
  React.useEffect(() => {
    if (state?.storeInfo?.countries) {
      setCountry(state.storeInfo.countries);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.storeInfo.countries]);

  return (
    <>
      <ParentDiv>
        <StoreInfoText>
          <FormattedMessage
            id={Message.SUPPLIER_STORE_INFO}
            values={{
              span: (chunks: string) => (
                <span style={{ color: '#FC5C42' }}>{chunks}</span>
              ),
            }}
          />
        </StoreInfoText>
        <FormProvider {...methods}>
          <CustomForm onSubmit={onSubmit}>
            <InsideForm>
              <FormFieldDiv
                label={{
                  title: intl.formatMessage({
                    id: Message.SUPPLIER_STORE_NAME_LABEL,
                  }),
                  isBold: true,
                }}
                name="storeName"
                type="text"
                placeholder={intl.formatMessage({
                  id: Message.SUPPLIER_STORE_NAME_PLACEHOLDER,
                })}
                required
              />
              <CountryDiv
                className="combobox country"
                width="327px"
                label={{
                  title: <FormattedMessage id={Message.ADDRESS_FORM_COUNTRY} />,
                }}
                name="countries"
                options={countryOptions}
                searchPlaceholder={intl.formatMessage({
                  id: Message.COUNTRY_SEARCH_PLACEHOLDER,
                })}
                renderOption={CountryOption}
                placeholder={intl.formatMessage({
                  id: Message.ADDRESS_FORM_SELECT_COUNTRY,
                })}
                required
                selectedValue={country}
                setSelectedValue={
                  ((option: Option) => {
                    setCountry(option);
                  }) as Setter<Option>
                }
                setterKey="iso"
                optionTextLimit={23}
                renderValue={option =>
                  option ? (
                    <FlagIcon
                      ISO={option.iso as ISOCode}
                      name={summarizeText(option.name.toString(), 20)}
                    />
                  ) : null
                }
              />
              {/* <FormFieldDiv
                label={{
                  title: intl.formatMessage({
                    id: Message.PHONE_NUMBER,
                  }),
                  isBold: true,
                }}
                name="phoneNumber"
                type="text"
                placeholder={intl.formatMessage({
                  id: Message.SUPPLIER_PHONE_NUMBER_PLACEHOLDER,
                })}
                required
              /> */}
              <PhoneFieldDiv
                label={{
                  title: intl.formatMessage({
                    id: Message.PHONE_NUMBER,
                  }),
                  isBold: true,
                }}
                name="phoneNumber"
                type="text"
                placeholder={intl.formatMessage({
                  id: Message.SUPPLIER_PHONE_NUMBER_PLACEHOLDER,
                })}
                onChange={e => {
                  setValue('phoneNumber', e.target.value, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                  // setPhoneLocal(e.target.value);
                }}
                startAdornment={
                  <InputAdornment position="start">
                    {country ? (
                      <FlagIcon
                        ISO={
                          countryOptions.filter(e => e.iso === country.iso)[0]
                            .iso as ISOCode
                        }
                        name={summarizeText(
                          `+${
                            phonecodes.filter(e => e.code === country.iso)[0]
                              .dial_code
                          }`,
                          20
                        )}
                      />
                    ) : null}
                  </InputAdornment>
                }
                required
              />
              <FormFieldDiv
                label={{
                  title: MobileTooltip({
                    label: intl.formatMessage({
                      id: Message.SUPPLIER_TAX_ID_LABEL,
                    }),
                    title: (
                      <FormattedMessage
                        id={Message.SUPPLIER_TAX_ID_TOOLTIP_TEXT}
                        values={{
                          linebreak: <br />,
                        }}
                      />
                    ),
                    open,
                    setOpen,
                    marginWidth: 327,
                    arrowMargin: true,
                  }),
                }}
                name="taxId"
                type="text"
                placeholder={intl.formatMessage({
                  id: Message.SUPPLIER_TAX_ID_PLACEHOLDER,
                })}
              />
              <FormFieldDiv
                label={{
                  title: intl.formatMessage({
                    id: Message.SUPPLIER_WEBSITE_LABEL,
                  }),
                  isBold: true,
                }}
                name="website"
                type="text"
                placeholder={intl.formatMessage({
                  id: Message.SUPPLIER_WEBSITE_PLACEHOLDER,
                })}
              />
              <ImagePickerDiv>
                <LogoParent>
                  <UploadLogoText>
                    <FormattedMessage
                      id={Message.SUPPLIER_UPLOAD_YOUR_LOGO_LABEL}
                    />
                  </UploadLogoText>
                  <ImagePicker
                    name="logo"
                    label="Click here to upload image"
                    control={methods.control}
                    onFileChange={e => {
                      if (e?.target?.files?.[0])
                        setValue('logo', e?.target.files[0], {
                          shouldDirty: true,
                          shouldValidate: true,
                        });
                    }}
                    defaultValue={state?.storeInfo?.logo}
                  />
                </LogoParent>
              </ImagePickerDiv>
              <MandatoryFields>
                <FormattedMessage id={Message.SUPPLIER_MANDATORY_FIELDS} />
              </MandatoryFields>
            </InsideForm>
            <NextButtonParent>
              <NextButton
                type="submit"
                disabled={!isValid || !isDirty || isLoading}
              >
                {isLoading ? (
                  <CustomIcon name="spinner" size={20} />
                ) : (
                  <NextButtonText>
                    <FormattedMessage
                      id={Message.PROPERTY_ADD_PROPERTY_BUTTON_NEXT}
                    />
                  </NextButtonText>
                )}
              </NextButton>
            </NextButtonParent>
          </CustomForm>
        </FormProvider>
      </ParentDiv>
      <DragHomeButton />
    </>
  );
};
export default SupplierInfoStoreFormMobile;

const ParentDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto Condensed';
  flex-direction: column;
  .MuiFormControl-root {
    width: 327px;
  }
  .dropdown-placeholder {
    color: #3e3e3e;
  }
  .combobox {
    margin-top: 8px;
    label {
      margin-bottom: 0;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #3e3e3e;
      margin-left: 0;
      font-family: 'Roboto Condensed';
    }
  }
  .image-picker {
    width: 327px;
    height: 129px;
    margin-bottom: 0;
    border: 1px dashed #e8eaeb;
    label {
      span {
        font-size: 16px;
        line-height: 20px;
        /* color: rgba(41, 41, 41, 0.2); */
      }
      svg {
        fill: #3e3e3e;
        opacity: 1;
      }
    }
  }
  .dashed-icon {
    width: 48px;
    height: 48px;
    border: 1px dashed #3e3e3e;
  }
`;

const InsideForm = styled.div`
  height: calc(100vh - 180px);
  overflow: scroll;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
`;

const StoreInfoText = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  margin-top: 24px;
`;

const CustomForm = styled.form`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
`;

const FormFieldDiv = styled(Form.Field)`
  margin-top: 8px;
  label {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #3e3e3e;
    margin-left: 0;
    font-family: 'Roboto Condensed';
  }
  .MuiInputBase-root {
    margin-top: 20px;
  }
`;
const PhoneFieldDiv = styled(Form.Field)`
  margin-top: 8px;
  label {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #3e3e3e;
    margin-left: 0;
    font-family: 'Roboto Condensed';
  }
  .MuiInputBase-root {
    margin-top: 20px;
  }
  input {
    padding-left: 0px;
  }
`;
const CountryDiv = styled(Form.ComboBox)``;

const ImagePickerDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 8px;
  label {
    width: 100%;
  }
  .label-image-picker {
    color: rgba(41, 41, 41, 0.2);
  }
`;

const LogoParent = styled.div`
  width: 100%;
`;

const UploadLogoText = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #3e3e3e;
`;

export const MandatoryFields = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: #696969;
  margin-top: 8px;
`;

export const NextButtonParent = styled.div`
  text-align: center;
  position: absolute;
  bottom: 24px;
  width: 100%;
`;

export const NextButton = styled.button`
  :disabled {
    background: #d9d9d9;
    cursor: default;
  }
  background: #fc5c42;
  border: 1px solid #d9d9d9;
  border-radius: 15px;
  padding: 9px 48px;
  height: 40px;
  width: 134px;
  text-align: center;
  cursor: pointer;
`;

const CustomIcon = styled(Icon)`
  ${({ theme }) => theme.utils.spinAnimation};
  position: absolute;
  fill: #f9fafa;
`;

export const NextButtonText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.9);
`;
export const PhoneInput = styled(PhoneField)`
  margin-top: 8px;
`;
