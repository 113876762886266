import * as React from 'react';
import styled from 'styled-components';
import { Property } from 'types/properties';
import { ReactComponent as HrLine } from 'assets/icons/hrLine.svg';
import { Link } from 'react-router-dom';
import { ClientRouter } from 'router/routes';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import rtl from 'styled-components-rtl';
import { useMediaQuery } from '@material-ui/core';
import { MOBILE_RESOLUTION } from 'styles/sharedStyle';
import PropertyCard from './PropertyCard';

const PremadePlanView: React.FC<{ property: Property | null }> = ({
  property,
}) => {
  const isMobile = useMediaQuery(MOBILE_RESOLUTION);

  return (
    <CustomContainer>
      <div className="title">
        <FormattedMessage
          id={Message.ONBOARDING_DONE_KEY_CREATED_SUCCESSFULL}
          values={{ linebreak: isMobile ? <br /> : '' }}
        />
        <div>
          <HrLine />
        </div>
      </div>
      <PropertyCard property={property} />
      <Link to={ClientRouter.APP} className="home-link">
        <FormattedMessage id={Message.ONBOARDING_DONE_RETURN_HOME_BUTTON} />
      </Link>
    </CustomContainer>
  );
};

const CustomContainer = styled.div`
  & .title {
    font-style: normal;
    font-weight: 600;
    font-size: 40px;

    text-align: center;

    /* Good Validation color */
    color: #2cdd73;
    @media (max-width: 767px) {
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
    }
    & > div {
      margin-top: -16px;
    }
    svg {
      width: ${({ theme }) => (theme.dir === 'rtl' ? '385px' : '642px')};
      height: ${({ theme }) => (theme.dir === 'rtl' ? '7px' : '14px')};
      @media (max-width: 767px) {
        width: 206px;
        height: 7px;
      }
      path {
        stroke: #2cdd73;
        fill: #2cdd73;
      }
    }
  }
  & .main-div {
    display: flex;
    width: fit-content;
    margin: 48px auto 0;
    @media (max-width: 767px) {
      margin: 23px auto 0;
    }
    img {
      width: 65px;
      height: 144px;
      ${rtl`margin-right: 32px;`}
      @media (max-width: 767px) {
        ${rtl`margin-right: 11px;`}
      }
    }
    & .data {
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
      text-align: left;
      @media (max-width: 767px) {
        font-weight: 500;
        font-size: 16px;
      }
      span {
        color: ${({ theme }) => theme.colors.primary.lightMobile};
      }
    }

    & .data-line2 {
      margin-top: 32px;
      font-weight: 600;
      font-size: 24px;
      line-height: 31px;
      @media (max-width: 767px) {
        font-weight: 600;
        font-size: 18px;
      }
      & .notice {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #696969;
        margin-top: 4px;
      }
      span {
        margin-top: 8px;
        @media (max-width: 767px) {
          font-weight: 600;
          font-size: 18px;
        }
      }
      img {
        height: 24px;
        width: 24px;
        transform: translateY(4px);
        @media (max-width: 767px) {
          height: 14px;
          width: 14px;
          transform: translateY(2px);
        }
      }
    }
  }

  & .home-link {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-decoration-line: underline;
    color: #696969;
  }
`;

export default PremadePlanView;
