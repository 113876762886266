import { useState } from 'react';
import styled from 'styled-components';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import {
  MOBILE_RESOLUTION,
  MOBILE_TABLET_RESOLUTION,
  SMALL_WIDTH_RESOLUTION,
  TABLET_RESOLUTION,
} from 'styles/sharedStyle';
import { isMobile } from 'react-device-detect';
import { PopupModal, useCalendlyEventListener } from 'react-calendly';
import {
  SupplierOnboardingAction,
  useSupplierOnboardingDispatch,
} from 'context/supplierOnboardingContext';
import { integration } from './mock';
import { CTAButton } from './Header/Components/Button';

const Integration = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useSupplierOnboardingDispatch();
  useCalendlyEventListener({
    onEventScheduled: e => {
      dispatch({
        type: SupplierOnboardingAction.SET_IS_SHEDULED_MEETING,
        payload: true,
      });
    },
  });
  return (
    <Root>
      <Container>
        <ProcessContainer>
          <ImageContainer>
            <img
              width="100%"
              src="https://patricia-ai-data-production.s3.eu-central-1.amazonaws.com/media/gifs/procedure.gif"
              alt="Not available"
            />
          </ImageContainer>
          <StepsContainer>
            <Timeline className="timeline-container">
              <TimelineItem className="timeline-item">
                <TimelineOppositeContent className="time-week">
                  <FormattedMessage id={Message.SUPPLIER_INTEGRATION_WEEK} />
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot className="timeline-ball">
                    <p>1</p>
                  </TimelineDot>
                  <TimelineConnector className="timeline-line" />
                </TimelineSeparator>
                <TimelineContent className="timeline-text">
                  <p>
                    {integration?.onboardTitle}
                    <span>{integration?.boostDesc}</span>
                  </p>
                </TimelineContent>
                <TimelineContent className="timeline-cycle-number">
                  <span>#1</span>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent className="time-week">
                  <FormattedMessage id={Message.SUPPLIER_INTEGRATION_WEEK} />
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot className="timeline-ball">
                    <p>2</p>
                  </TimelineDot>
                  <TimelineConnector className="timeline-line" />
                </TimelineSeparator>
                <TimelineContent className="timeline-text">
                  <p>
                    {integration?.model}
                    <span>{integration?.modeldesc}</span>
                  </p>
                </TimelineContent>
                <TimelineContent className="timeline-cycle-number">
                  <span>#2</span>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot className="timeline-ball">
                    <p>3</p>
                  </TimelineDot>
                </TimelineSeparator>
                <TimelineContent className="timeline-text">
                  <p>
                    {integration?.boost}
                    <span>{integration?.boostDesc}</span>
                  </p>
                </TimelineContent>
              </TimelineItem>
            </Timeline>
          </StepsContainer>
        </ProcessContainer>
        <div className="btn-container">
          <CTAButton
            text={Message.SUPPLIER_ACTION_BUTTON}
            width={isMobile ? 350 : 500}
            onClick={() => setIsModalOpen(true)}
          />
        </div>
        <PopupModal
          open={isModalOpen}
          url="https://calendly.com/dan1707"
          rootElement={document.getElementById('root')!}
          onModalClose={() => {
            setIsModalOpen(false);
          }}
        />
      </Container>
    </Root>
  );
};

export default Integration;

const Root = styled.section`
  width: 100%;
  background: #fff;
`;
const Container = styled.div`
  margin: 20px 0px;

  && .btn-container {
    width: 100%;
    margin-bottom: 100px;
    text-align: center;
    @media ${MOBILE_TABLET_RESOLUTION} {
      margin-top: 50px;
    }
  }
`;

const ProcessContainer = styled.div`
  padding: 50px;
  display: flex;
  && .timeline-container {
    padding: 6px 70px;
    min-height: 400px;
    margin: 10px 0 10px 0;
    justify-content: space-between;
    & > li::before {
      display: none;
    }

  }

@media ${TABLET_RESOLUTION}{

  && .timeline-container {
    padding: 6px 30px;
  }
}



  @media ${SMALL_WIDTH_RESOLUTION} {
    && .timeline-container {
      padding: 6px 50px;
    }
    && .timeline-ball {
    width: 60px;
    height: 60px;
    p {
      font-size: 30px;
    }
  }
  && .time-week {
    position: absolute;
    top: 160px;
    left: -50px;  
    
  }
  && .timeline-cycle-number {
    position: absolute;
    top: 160px;
    left: 40px;  
    
    
  }

  && .timeline-line {
    width: 5px;
    height: 100px;
    background-color: black;
  }
  && .timeline-text {
    font-size: 1.5rem;
  }

  @media ${MOBILE_TABLET_RESOLUTION}{
    padding: 0px 30px;
    flex-direction: column-reverse;
    && .timeline-container {
      padding: 25px 100px;
   
    }

    && .timeline-line {
    width: 5px;
    height: 60px;
    background-color: black;
  }
  && .timeline-text {
    font-size: 1.3rem;
  margin-top: 20px;
  }
  }

  @media (max-width: 600px) {
    && .timeline-container {
      padding: 25px 60px;
   
    }
  }

  @media ${MOBILE_RESOLUTION} {
    && .timeline-container {
      padding: 25px 30px;
   
    }
  }
`;

const ImageContainer = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${SMALL_WIDTH_RESOLUTION} {
    width: 50%;
  }

  @media ${MOBILE_TABLET_RESOLUTION} {
    width: 100%;
  }
`;
const StepsContainer = styled.div`
  width: 40%;
  && .timeline-ball {
    width: 80px;
    height: 80px;
    background-color: black;
    justify-content: center;
    align-items: center;
    p {
      font-size: 40px;
      font-weight: bold;
    }
  }
  && .time-week {
    position: absolute;
    top: 160px;
    left: -50px;
    font-size: 1.5rem;
    font-weight: bolder;
    color: grey;
  }
  && .timeline-cycle-number {
    position: absolute;
    top: 160px;
    left: 40px;

    /* max-width: 100px; */
    font-size: 1.5rem;
    font-weight: bold;
    color: grey;
  }

  && .timeline-line {
    width: 5px;
    height: 150px;
    background-color: black;
  }
  && .timeline-text {
    font-size: 2rem;
    /* width: fit-content; */
    & > p {
      color: #fc5c42;
      font-weight: bold;
    }
    & > p > span {
      font-weight: 400;
      color: black;
    }
  }

  @media (max-width: 1400px) {
    && .timeline-text {
      font-size: 1.6rem;
    }
  }

  @media ${SMALL_WIDTH_RESOLUTION} {
    width: 50%;
    && .timeline-text {
      font-size: 1.5rem;
    }
  }

  @media ${MOBILE_TABLET_RESOLUTION} {
    width: 100%;
    && .timeline-text {
      font-size: 1.3rem;
    }
  }
`;
