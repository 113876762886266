import * as React from 'react';
import { HeaderContainer } from 'documents/components';
import { Order } from 'types/orders';

const Header: React.FC<Order> = ({
  id,
  supplier,
  createdAt,
  shippingAddress,
  shippingDate,
}) => {
  return (
    <HeaderContainer
      isForOrder={true}
      fixed
      contentStyles={{ marginTop: 35, paddingRight: 80 }}
    />
  );
};

export default Header;
