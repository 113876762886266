import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import FileDisplay, { FileType } from 'components/@client/FileDisplay';
import { useOnboarding, OnboardingAction } from 'context/onboardingContext';
import { useRouter } from 'hooks';
import { Message } from 'i18n';
import * as Section from 'pages/appClient/onboarding/shared';
import { ClientRouter } from 'router/routes';
import { isEmpty } from 'utils/lodash.utils';
import type { PropertyMedia } from 'types/properties';

const SelectFloor = () => {
  const { history } = useRouter();
  const [{ image, selectedFloor, floorPlanFiles }, dispatch] = useOnboarding();

  const setSelectedFloor = React.useCallback(
    (payload: PropertyMedia | null = null) =>
      dispatch({ type: OnboardingAction.SET_SELECTED_FLOOR, payload }),
    [dispatch]
  );

  const goBack = React.useCallback(() => {
    history.push(ClientRouter.UPLOAD_PLAN);
  }, [history]);

  const proceed = React.useCallback(() => {
    history.push(ClientRouter.PLAN_CONFIRMED);
  }, [history]);

  if (isEmpty(floorPlanFiles))
    return <Redirect to={ClientRouter.UPLOAD_PLAN} />;

  return (
    <Container>
      <FileDisplay
        type={FileType['application/pdf']}
        fileName={image ? `${image.name}` : undefined}
      />
      <LineBreak />
      <FormattedMessage id={Message.SELECT_FLOOR_CAPTION} tagName="p" />
      <Section.FloorGrid
        items={floorPlanFiles ?? []}
        selectedFloor={selectedFloor?.id}
        onClick={floor => setSelectedFloor?.(floor)}
      />
      <Section.Actions
        withPrevious
        prevProps={{ onClick: goBack }}
        nextProps={{
          disabled: selectedFloor === null,
          onClick: proceed,
        }}
      />
    </Container>
  );
};

export default SelectFloor;

const Container = styled(Section.Container)`
  & .actions-container {
    margin-top: 20px;

    @media (min-height: 800px) {
      margin-top: 80px;
    }
  }

  @media (min-width: 1150px) {
    padding: 10px 0 0 0;
    & .file-display {
      padding: 0 30px;
    }

    & p {
      padding: 0 30px;
    }
  }
`;

const LineBreak = styled.hr`
  border-top: 0;
  border-bottom: 1px solid #a99997;
`;
