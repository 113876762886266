import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import styled from 'styled-components';
import useUploadPlan from 'pages/appClient/onboarding/upload-plan/useUploadPlan';
import { ReactComponent as UploadIcon } from 'assets/icons/downloadIcon.svg';
import Button from 'components/@client/Button/Button';
import { useNotifications, useSession } from 'hooks';
import { readFile } from 'utils/common.utils';
import * as Image from 'assets/@client/newpre-made-properties/index';
import Jimp from 'jimp';
import { Modal, useMediaQuery } from '@material-ui/core';
import { MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';
import { Cancel, Check, CheckCircle } from '@material-ui/icons';

interface Props {
  next: VoidFunction;
}

const SIZE_LIMIT = 1048576 * 10;
const MAX_PAGES_SIZE = 4;
const UploadPlan: React.FC<Props> = ({ next }) => {
  const { image, setImage, onClick, isLoading } = useUploadPlan(next);

  const [isResolutionPopupOpen, setIsResolutionPopupOpen] = React.useState(
    false
  );
  const isMobile = useMediaQuery(MOBILE_TABLET_RESOLUTION);
  const isSmallMobile = useMediaQuery('(max-width: 480px)');

  const fileInputRef = React.useRef<HTMLInputElement | null>(null);
  const { showNotification } = useNotifications();
  const { locale } = useSession();
  const showError = React.useCallback(
    (
      message = 'The file type is not supported. Please, upload a JPG, JPEG, PNG, or PDF'
    ) => {
      showNotification({
        key: 'fileDropZone/notSupported',
        message,
        severity: 'error',
      });
      return setImage(null);
    },
    [setImage, showNotification]
  );
  const [fileError, setFileError] = React.useState<{
    type?: boolean;
    resolution?: boolean;
    size?: boolean;
    ratio?: boolean;
  }>({});
  const onChangeHandler = React.useCallback(
    async (files: File[] | FileList | null) => {
      setFileError({});

      const file = files?.[0];
      if (!file) return null;
      const conditions = [
        !['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'].includes(
          file?.type ?? ''
        ),
        (file?.size ?? 0) > SIZE_LIMIT,
      ];
      if (conditions.some(v => v)) {
        setFileError({
          size: (file?.size ?? 0) > SIZE_LIMIT,
          type: ![
            'application/pdf',
            'image/png',
            'image/jpeg',
            'image/jpg',
          ].includes(file?.type ?? ''),
        });
      }
      if (file.type.includes('pdf')) {
        const { data: results, error } = await readFile(file);
        const count = results?.match(/\/Type[\s]*\/Page[^s]/g)?.length ?? 10;
        if (Boolean(error) || count > MAX_PAGES_SIZE) return showError();
        return setImage(file);
      }
      // const tmppath = URL.createObjectURL(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        Jimp.read(
          Buffer.from(
            (reader.result?.toString() ?? '')
              .replace(/^data:image\/png;base64,/, '')
              .replace(/^data:image\/jpeg;base64,/, ''),
            'base64'
          ),
          (err, imageFile) => {
            if (err?.toString().includes('maxResolutionInM')) {
              setFileError(e => ({
                ...e,
                resolution: true,
              }));
              setIsResolutionPopupOpen(true);
            } else if (imageFile === undefined) {
              showError();
            } else if (
              imageFile?.bitmap.width > 10000 ||
              imageFile?.bitmap.height > 10000 ||
              imageFile.bitmap.width / imageFile.bitmap.height > 3 ||
              conditions.some(v => v)
            ) {
              setFileError(e => ({
                ...e,
                resolution:
                  imageFile?.bitmap.width > 10000 ||
                  imageFile?.bitmap.height > 10000,
                ratio: imageFile.bitmap.width / imageFile.bitmap.height > 3,
              }));
              setIsResolutionPopupOpen(true);
            } else {
              setImage(file);
            }
          }
        );
      };

      return null;
    },
    [setImage, showError]
  );

  return (
    <>
      <Container>
        <Text>
          <FormattedMessage id={Message.CHOOSE_PLAN_CONTAINER_TITLE_1} />
        </Text>
        {/* <FileDropZone
          dropImage={dropImage}
          onChange={setImage}
          initialValue={image}
          onClick={() => {
            LogsAPI.postUserLogs({
              name: `${userLogData.eventName.browseAddPlan}_existing_user`,
              area: userLogData.eventArea.myProperties,
              section: userLogData.eventSection.properties,
              path: userLogData.eventPath.appModalAddPropertyPath,
            });
          }}
        /> */}
        <PlansContainer
          isActive={Boolean(image)}
          onClick={() => {
            fileInputRef?.current?.click();
          }}
        >
          <div className="checkContainer">
            <Check />
          </div>
          <ImageContainer>
            <img src={Image.upload} alt="not available" />
          </ImageContainer>
          <UploadButton>
            <FileInput
              ref={fileInputRef}
              onChange={e => onChangeHandler(e.target.files)}
            />
            <Button
              // isLoading={isLoading}
              className="upload-button"
              onClick={e => {
                e.stopPropagation();
                fileInputRef?.current?.click();
              }}
            >
              {locale === 'en' && <UploadIcon className="upload-icon" />}
              <FormattedMessage
                id={Message.CLIENT_AUTH_ONBOARDING_UPLOAD_PLAN}
              />
              {locale === 'he' && <UploadIcon className="upload-icon" />}
            </Button>
          </UploadButton>
        </PlansContainer>
        <div
          style={{
            marginTop: isMobile ? 16 : 8,
            width: 306,
            fontSize: isSmallMobile ? '12px' : '14px',
            textAlign: 'center',
            fontWeight: 400,
          }}
        >
          <FormattedMessage
            id={Message.ONBOARDING_UPLOAD_NOTICE}
            values={{ linebreak: <br /> }}
          />
        </div>
        <Actions>
          <Button isLoading={isLoading} disabled={!image} onClick={onClick}>
            <FormattedMessage id={Message.PROPERTY_ADD_PROPERTY_BUTTON_NEXT} />
          </Button>
        </Actions>
      </Container>

      <Modal
        open={isResolutionPopupOpen}
        BackdropProps={{
          onClick: () => {
            setImage(null);
            setIsResolutionPopupOpen(false);
          },
        }}
      >
        <ModalContent>
          <div className="title">
            <FormattedMessage
              id={Message.ONBOARDING_PLAN_UPLOAD_RESOLUTION_TITLE}
              values={{
                linebreak: isMobile ? <br /> : ' ',
              }}
            />
          </div>
          <div className="subtitle">
            <FormattedMessage
              id={Message.ONBOARDING_PLAN_UPLOAD_RESOLUTION_SUBTITLE}
            />
          </div>
          <div className="notice">
            <ul>
              <li>
                {fileError.type ? <Cancel /> : <CheckCircle />}
                <span>
                  <FormattedMessage id={Message.UPLOAD_PLAN_ERROR_TYPE} />
                </span>
              </li>
              <li>
                {fileError.size ? <Cancel /> : <CheckCircle />}
                <span>
                  <FormattedMessage id={Message.UPLOAD_PLAN_ERROR_SIZE} />
                </span>
              </li>
              <li>
                {fileError.resolution ? <Cancel /> : <CheckCircle />}
                <span>
                  <FormattedMessage id={Message.UPLOAD_PLAN_ERROR_RESOLUTION} />
                </span>
              </li>
              <li>
                {fileError.ratio ? <Cancel /> : <CheckCircle />}
                <span>
                  <FormattedMessage id={Message.UPLOAD_PLAN_ERROR_RATIO} />
                </span>
              </li>
            </ul>
          </div>
          <Button
            className="ok-btn"
            onClick={() => {
              setImage(null);
              setIsResolutionPopupOpen(false);
            }}
          >
            <FormattedMessage
              id={Message.ONBOARDING_PLAN_UPLOAD_RESOLUTION_OK_BUTTON}
            />
          </Button>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UploadPlan;

const Container = styled.section.attrs({ className: 'upload-plan-container' })`
  display: flex;
  flex-direction: column;
  align-items: center;

  & .file-drop-zone {
    padding: 15px;
  }
  @media (max-width: 480px) {
    margin-bottom: 50px;
  }
`;

const Text = styled.p.attrs({ className: 'upload-plan-text' })`
  font-weight: ${({ theme }) => (theme.dir === 'rtl' ? 600 : 500)};
  font-size: 28px;
  margin-bottom: 0px;
  text-align: center;
  line-height: 32px;
  margin-top: 24px;
  @media (max-width: 800px) {
    font-weight: 500;
    font-size: 22px;
    margin-top: 32px;
  }
`;

const Actions = styled.section.attrs({ className: 'upload-plan-actions' })`
  margin: auto;
  margin-top: 32px;
  @media (max-width: 800px) {
    margin-bottom: 14px;
  }
  align-self: flex-end;
  .MuiButton-label {
    font-weight: 500;
    font-size: 18px;
  }
  .MuiButtonBase-root {
    width: 134px;
  }
`;

const PlansContainer = styled.div<{ isActive: boolean }>`
  &:hover {
    border: 3px solid #fc5c42;
  }

  cursor: pointer;
  border: ${({ isActive }) =>
    isActive ? '3px solid #fc5c42' : '3px solid transparent'};
  width: 316px;
  height: 356px;
  border-radius: 15px;
  text-align: center;
  position: relative;
  margin-top: 24px;
  padding-top: 13px;
  @media (max-width: 800px) {
    width: 327px;
    height: 365px;
    padding-top: 6px;
    margin-top: 68px;
  }
  @media (max-width: 480px) {
    width: 80vw;
  }
  .text-container {
    width: 48px;
    margin: 0 32px;
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    > span {
      :first-letter {
        color: #fc5c42 !important;
      }
    }
  }

  & > span {
    :first-letter {
      color: #fc5c42 !important;
    }
  }
  .checkContainer {
    height: 24px;
    width: 24px;
    position: absolute;
    top: 16px;
    right: 16px;
    border-radius: 12px;
    background-color: #fc5c42;
    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
    svg {
      width: 15px;
      height: 23px;
      path {
        stroke: white;
        fill: white;
      }
    }
    @media (max-width: 480px) {
      top: 10px;
      right: 10px;
    }
  }
`;

const UploadButton = styled.div`
  .upload-button {
    width: 196px;
    height: 48px;
    background-color: white;
    color: #fc5c42;
    border: 1.6791px solid #fc5c42;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    margin-top: 16px;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    /* identical to box height */

    text-align: center;

    color: #fc5c42;
    &:hover {
      color: white;
      svg {
        path {
          stroke: white;
        }
      }
    }
  }

  .upload-icon {
    margin-right: 8px;
  }
`;

const FileInput = styled.input.attrs({
  type: 'file',
  name: 'file',
  accept: 'application/pdf, image/png, image/jpeg, image/jpg',
})`
  position: absolute;
  visibility: hidden;
`;

const ImageContainer = styled.div`
  width: 300px;
  height: 260px;
  margin: auto;
  @media (max-width: 800px) {
    width: 311px;
    height: 285px;
  }
  @media (max-width: 480px) {
    width: 73vw;
  }
  > img {
    width: 100%;
    height: 100%;
  }
`;

const ModalContent = styled.div`
  width: 537px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding-top: 24px;
  text-align: center;
  border: none;
  outline: none;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  padding-bottom: 24px;

  @media (max-width: 767px) {
    width: 327px;
    height: 224px;
  }
  .title {
    font-weight: 500;
    font-size: 22px;
    text-align: center;

    /* Primary Color */

    color: #fc5c42;
    @media (max-width: 767px) {
      font-weight: 500;
      font-size: 20px;
    }
  }
  .ok-btn {
    background: #fc5c42;
    /* Primary Color */
    margin-top: 16px;
    border: 1px solid #fc5c42;
    border-radius: 15px;
    width: 127px;
    height: 48px;
    font-weight: 500;
    font-size: 18px;
    @media (max-width: 767px) {
      height: 40px;
      font-weight: 500;
      font-size: 18px;
    }
  }
  .subtitle {
    font-weight: 400;
    font-size: 16px;
    text-align: center;

    /* First level text */

    color: #282929;
    margin-top: 16px;
    @media (max-width: 767px) {
      font-weight: 500;
      font-size: 16px;
    }
  }
  .notice {
    font-weight: 400;
    font-size: 14px;
    color: #696969;
    margin-top: 8px;
    text-align: center;
    ul {
      width: fit-content;
      margin: auto;
      li {
        position: relative;
        width: fit-content;
        list-style: none;
        svg {
          margin-bottom: -6px;
          fill: #fc5c42;
        }
        span {
          line-height: 24px;
          vertical-align: middle;
        }
      }
    }
    @media (max-width: 767px) {
      font-weight: 400;
      font-size: 14px;
    }
  }
`;
