import * as React from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Message } from 'i18n';
import Emoji from 'assets/@client/emoji';
import rtl from 'styled-components-rtl';

const LanguageToggle = () => {
  const intl = useIntl();
  return (
    <Container>
      <strong>
        <img
          className="country-button us-button"
          src={intl.locale === 'en' ? Emoji.US : Emoji.IL}
          alt="Unites States flag emoji"
        />
        <span>
          <FormattedMessage id={Message.PRIMARY_LANGUAGE} />
        </span>
      </strong>
      <span className="separator">/</span>
      <span>
        <img
          className="country-button il-button"
          src={intl.locale === 'en' ? Emoji.IL : Emoji.US}
          alt="Israel flag emoji"
        />
        <span>
          <FormattedMessage id={Message.SECONDARY_LANGUAGE} />
        </span>
      </span>
    </Container>
  );
};

export default LanguageToggle;

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  > * {
    img {
      height: 1.4rem;
      width: 1.4rem !important;
      ${rtl`
        margin: auto 2px auto auto;
      `}
    }
    display: flex;
  }
  strong {
    span {
      margin-left: 9px;
    }
  }
  .separator {
    margin: 0 1ch;
  }

  span {
    cursor: pointer;
  }
`;
