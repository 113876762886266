import * as React from 'react';
import styled from 'styled-components';
import comingSoon from './coming-soon.svg';

const ComingSoon = () => {
  return (
    <Container>
      <img src={comingSoon} alt="Coming soon" />
      <p>Coming soon</p>
    </Container>
  );
};

export default ComingSoon;

const Container = styled.section`
  ${({ theme }) => theme.utils.centerFlex};
  flex-direction: column;
  width: 100%;
  height: 90%;
  color: rgba(112, 112, 112, 0.48);
  font-size: 2.5rem;

  p {
    margin: 0;
  }
`;
