import styled, { css } from 'styled-components';
import rtl from 'styled-components-rtl';
import type { Option } from 'components/Autocomplete/Autocomplete.config';
import Checkbox from 'components/Checkbox';

const SkinsColorOption = (values: Option[] = []) => (
  option: Option,
  onClick?: React.MouseEventHandler<HTMLLIElement>
) => {
  return (
    <MyListItem
      id={option?.name.toString()}
      className="colorOption"
      onClick={onClick}
    >
      <CardContainer>
        <Checkbox
          className="checkbox-input"
          tabIndex={-1}
          // onKeyPress={onClick}
          checked={values.some(item => item?.name === option?.name)}
        />
        <ItemName
          className="color-name"
          isSelected={values.some(item => item?.name === option?.name)}
        >
          {option?.name as string}
        </ItemName>
        <ColorDiv
          className="color-code"
          name={option?.name.toString()}
          divColor={option?.value.toString()}
        />
      </CardContainer>
    </MyListItem>
  );
};

export default SkinsColorOption;

const ColorDiv = styled.div<{ divColor?: string; name?: string }>`
  background-color: ${({ divColor }) => divColor};
  border: ${({ name }) => name === 'White' && '0.5px solid #282929'};
  width: 16px;
  height: 16px;
  ${rtl`
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  `}
`;
const CardContainer = styled.div`
  background-color: inherit;

  width: 200px;

  display: flex;
  margin: 5px 0;
`;

const ItemName = styled.span<{ isSelected: boolean }>`
  white-space: nowrap;
  ${rtl`margin-left: 14px;`}
  font-size:16px;
  font-weight: 600;
  height: 20px;
  width: 110px;
  ${({ theme, isSelected }) =>
    isSelected &&
    css`
      color: ${theme.colors.primary.lightMobile};
    `}
`;

const MyListItem = styled.li`
  &:hover {
    background-color: #f2f2f2;
  }
  position: relative !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  ${rtl`
  padding-left: 14px;
  margin-right:14px;
  `}
  .icon.checked {
    background-color: ${({ theme }) => theme.colors.primary.lightMobile};
  }
  height: 40px;
  width: 177px;
`;
