import { useMediaQuery } from '@mui/material';
import { ClientConfig } from 'api/client';
import { OnboardingContextProvider } from 'context/onboardingContext';
import * as React from 'react';
import { MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';
import { BaseUserResponse, Setter } from 'types/common.types';
// eslint-disable-next-line import/no-cycle
import SellerLanding from './SellerLanding';
// eslint-disable-next-line import/no-cycle
import SellerLandingMobile from './SellerLandingMobile';

export interface LandingProps {
  supplierData?: {
    color: string;
    hero_gif: string;
    step_1?: string;
    step_2?: string;
    step_3?: string;
    email?: string;
    phone_numbers?: [
      {
        phone_number?: string;
        type?: string;
      }
    ];
    locations?: [
      {
        apartment_number: string;
        floor: number;
        house: string;
        street: string;
        city: string;
        country: string;
        state: string;
        zip_code: string;
        id?: number;
      }
    ];
    calendly_event_link: string;
    id: number;
    supplier_id: number;
    supplier: {
      additional_phone_number: string;
      logo: string;
      user: BaseUserResponse;
      id: number;
    };
  };
  headerTopPosition?: boolean;
  setHeaderTopPosition: Setter<boolean>;
  getSupplierData: (data?: ClientConfig) => void;
}

const SellerLandingPageContainer: React.FC<LandingProps> = props => {
  const isMobile = useMediaQuery(MOBILE_TABLET_RESOLUTION);
  return (
    <OnboardingContextProvider>
      {isMobile ? (
        <SellerLandingMobile {...props} />
      ) : (
        <SellerLanding {...props} />
      )}
    </OnboardingContextProvider>
  );
};

export default SellerLandingPageContainer;
