import * as React from 'react';
import client, { METHODS } from 'api/client';
import axios from 'axios';
import type { AxiosRequestConfig, CancelTokenSource } from 'axios';

type Props<Response extends unknown> = {
  url: string;
  onSuccess?: (response: Response) => void;
  onFailure?: (error: string) => void;
};

type State = {
  progress: number;
  isUploadSuccess: boolean;
  isUploading: boolean;
  error: string | null;
};

const initialState = {
  progress: 0,
  isUploadSuccess: false,
  isUploading: false,
  error: null,
};

type Action =
  | { type: 'request' }
  | { type: 'update'; progress: number }
  | { type: 'success' }
  | { type: 'failure'; error: string };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'request':
      return {
        ...state,
        isUploading: true,
        isUploadSuccess: false,
        error: null,
      };
    case 'success':
      return {
        ...state,
        isUploading: false,
        isUploadSuccess: true,
        error: null,
      };
    case 'update':
      return { ...state, progress: action.progress };
    case 'failure':
      return {
        ...state,
        isUploading: false,
        isUploadSuccess: false,
        error: action.error,
        progress: 0,
      };
    default:
      return state;
  }
}

const useUploadFile = <Response extends unknown>({
  url,
  onSuccess,
  onFailure,
}: Props<Response>) => {
  const [
    { progress, isUploadSuccess, isUploading, error },
    dispatch,
  ] = React.useReducer(reducer, initialState);
  const cancelTokenSource = React.useRef<CancelTokenSource>(
    axios.CancelToken.source()
  ).current;

  const handleUpload = React.useCallback(
    async ({
      file,
      config = {},
    }: {
      file: File | FileList;
      data?: unknown;
      dataKey?: string;
      config?: AxiosRequestConfig;
    }) => {
      const formData = new FormData();
      formData.append('file', file as Blob);

      dispatch({ type: 'request' });
      try {
        const response = await client<Response>({
          url,
          method: METHODS.POST,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: formData,
          onUploadProgress: (ev: ProgressEvent) => {
            const percentage = Math.ceil((ev.loaded / ev.total) * 100);
            dispatch({ type: 'update', progress: percentage });
          },
          cancelToken: cancelTokenSource.token,
          ...config,
        });
        dispatch({ type: 'success' });
        onSuccess?.(response);
      } catch (e) {
        const errorMessage = e.message || 'ERROR: Cannot upload Data source';
        dispatch({ type: 'failure', error: errorMessage });
        onFailure?.(errorMessage);
      }
    },
    [url, onSuccess, onFailure, cancelTokenSource.token]
  );

  React.useEffect(() => {
    return () => {
      cancelTokenSource.cancel();
    };
  }, [cancelTokenSource]);

  return {
    isUploadSuccess,
    isUploading,
    progress,
    error,
    handleUpload,
    cancelTokenSource,
  };
};

export default useUploadFile;
