import * as React from 'react';
import styled from 'styled-components';
import { useValidateUser } from 'hooks';
import Header from './Header/Header';
// import Problems from './Problems';
import Offers from './Offers';
import Integration from './Integration';

const BecomeSeller = () => {
  const { isAuthenticated, fullName } = useValidateUser();
  return (
    <Container dir="ltr">
      <Header isAuthenticated={isAuthenticated} fullName={fullName} />
      <Offers />
      <Integration />
    </Container>
  );
};

export default BecomeSeller;

const Container = styled.section`
  ${({ theme }) => theme.utils.centerFlex};
  flex-direction: column;
  width: 100%;
  color: rgba(112, 112, 112, 0.48);
  font-size: 2.5rem;
  color: #fff;
  p {
    margin: 0;
  }
`;
