import * as React from 'react';
import styled from 'styled-components';
import LoginImage from 'assets/@client/supplier-login-flow/login-main-image.png';
import PatriciaLogo from 'assets/@client/supplier-login-flow/patricia-red-text.png';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ClientRouter } from 'router/routes';
import {
  MOBILE_TABLET_MIN_RESOLUTION,
  MOBILE_TABLET_RESOLUTION,
} from 'styles/sharedStyle';
import FormWrapper from '../onboarding/additional-info/FormWrapper';
import SignUpSupplier from './SignUpSupplier';
import SignUpSupplierMobile from './SignUpSupplierMobile';

const SupplierRegistration: React.FC = () => {
  const isMobile = useMediaQuery(MOBILE_TABLET_RESOLUTION);
  return (
    <>
      {isMobile ? (
        <ParentMobile LoginImage={LoginImage}>
          <TransparentDiv>
            <a href={ClientRouter.APP}>
              <PatriciaLogoContainer src={PatriciaLogo} alt="patricia-logo" />
            </a>
            <LoginTitleMobile>
              <FormattedMessage id={Message.SUPPLIER_REGISTER_TITLE} />
            </LoginTitleMobile>
            <LoginSubTitleMobile>
              <FormattedMessage id={Message.SUPPLIER_REGISTER_SUBTITLE} />
            </LoginSubTitleMobile>
            <FormWrapper loginType="SUPPLIER">
              <SignUpSupplierMobile />
            </FormWrapper>
          </TransparentDiv>
        </ParentMobile>
      ) : (
        <ParentLogin>
          <LeftContainer>
            <a href={ClientRouter.APP}>
              <PatriciaLogoContainer src={PatriciaLogo} alt="patricia-logo" />
            </a>
            <LoginTitle>
              <FormattedMessage id={Message.SUPPLIER_REGISTER_TITLE} />
            </LoginTitle>
            <LoginSubTitle>
              <FormattedMessage id={Message.SUPPLIER_REGISTER_SUBTITLE} />
            </LoginSubTitle>
            <FormContainer>
              <FormWrapper loginType="SUPPLIER">
                <SignUpSupplier />
              </FormWrapper>
            </FormContainer>
          </LeftContainer>
          <RightContainer>
            <img
              style={{ height: '100%' }}
              src={LoginImage}
              alt="login-seller"
            />
          </RightContainer>
        </ParentLogin>
      )}
    </>
  );
};

export default SupplierRegistration;

const ParentLogin = styled.div`
  display: flex;
  margin-top: -65px;
  font-family: 'Roboto Condensed';
  direction: ltr;
`;

const ParentMobile = styled.div<{ LoginImage: string }>`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto Condensed';
  direction: ltr;
  ::before {
    background-image: url(${LoginImage});
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.6;
    background-position: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    content: '';
  }
`;

const LeftContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 95px;
`;

const RightContainer = styled.div`
  width: 50%;
`;

const PatriciaLogoContainer = styled.img`
  height: 140px;
  width: 88px;
`;

const LoginTitle = styled.div`
  font-weight: 600;
  font-size: 40px;
  line-height: 44px;
  color: #282929;
  margin-top: 24px;
`;

const LoginSubTitle = styled.div`
  font-size: 24px;
  line-height: 28px;
  color: #3e3e3e;
  margin-top: 8px;
`;

const FormContainer = styled.div`
  width: 384px;
`;

const TransparentDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(250, 250, 250, 0.8);
  border-radius: 20px;
  position: relative;
  flex-direction: column;
  padding: 16px;
  width: 90vw;
  .form-container {
    margin-top: 16px;
    @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
      margin-top: 24px;
    }
  }
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    width: 512px;
    height: 890px;
  }
`;

const LoginTitleMobile = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #282929;
  margin-top: 16px;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    margin-top: 24px;
    font-size: 30px;
    line-height: 34px;
  }
`;

const LoginSubTitleMobile = styled.div`
  font-size: 18px;
  line-height: 22px;
  margin-top: 4px;
  color: #3e3e3e;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    font-size: 24px;
    line-height: 28px;
    margin-top: 8px;
  }
`;
