import * as Response from 'api/responses';
import { Supplier } from 'types/suppliers';

export const createSupplierInfo = (supplier: Supplier): Response.Supplier => {
  return {
    ...supplier,
    KPIs: [
      {
        label: 'Product uploaded',
        // TODO: fetch product uploaded from backend
        value: 0,
        unit: undefined,
      },
      {
        label: 'Time at property',
        // TODO: fetch time at property from backend
        value: 0,
        unit: 'min (Avg)',
      },
      {
        label: 'Number of properties',
        // TODO: fetch number of properties from backend
        value: 0,
        unit: undefined,
      },
      {
        label: 'Total money spent',
        value: 0,
        unit: 'currency',
      },
    ],
    files: { results: [], pageCount: 0 },
  };
};
