import styled from 'styled-components';
import { BaseButton, ButtonProps } from 'components/@client/Button';

const SecondaryButton = styled(BaseButton).attrs<ButtonProps>({
  variant: 'outlined',
  color: 'inherit',
  size: 'medium',
})<ButtonProps>`
  && {
    color: rgba(121, 121, 121, 1);
    text-transform: capitalize;
    min-width: 115px;
    height: 48px;
  }
` as React.FC<ButtonProps>;

export default SecondaryButton;
