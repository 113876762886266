import * as actions from 'store/actions/3d-content-management/threeD.actions';
import {
  ThreeDTasksDataType,
  ThreeDInDbDataType,
} from 'store/constants/3d-content-management/threeD.constants';
import * as Payload from 'store/types/3d-content-management/threeD.types';
import type { Product } from 'types/products';

type threeDProperty = ThreeDTasksDataType | ThreeDInDbDataType;

export interface ThreeDState {
  loading: Record<threeDProperty, boolean>;
  error: Record<threeDProperty, null | string>;
  tasks: Product[];
  inDb: Product[];
  pageCount: number;
}

const REQUESTS = [
  ThreeDTasksDataType.GET_3D_TASKS_REQUEST,
  ThreeDInDbDataType.GET_3D_IN_DB_REQUEST,
];

const initialState: ThreeDState = {
  loading: REQUESTS.reduce(
    (state, next) => ({ ...state, [next]: false }),
    {} as Record<threeDProperty, boolean>
  ),
  error: REQUESTS.reduce(
    (state, next) => ({ ...state, [next]: null }),
    {} as Record<threeDProperty, null | string>
  ),
  tasks: [],
  inDb: [],
  pageCount: 0,
};

const threeDTasksReducer = (
  state: ThreeDState = initialState,
  action: actions.ThreeDAction
): ThreeDState => {
  switch (action.type) {
    // ALL TASKS
    case ThreeDTasksDataType.GET_3D_TASKS_REQUEST:
      return updateLoading(state, ThreeDTasksDataType.GET_3D_TASKS_REQUEST);
    case ThreeDTasksDataType.GET_3D_TASKS_FULFILLED:
      return updateSuccess(
        state,
        ThreeDTasksDataType.GET_3D_TASKS_REQUEST,
        action.payload as Payload.Get3DTasksDataFulfilledPayload
      );
    case ThreeDTasksDataType.GET_3D_TASKS_REJECTED:
      return updateError(
        state,
        ThreeDTasksDataType.GET_3D_TASKS_REQUEST,
        action.payload as Payload.Get3DTasksDataRejectedPayload
      );

    // IN DB
    case ThreeDInDbDataType.GET_3D_IN_DB_REQUEST:
      return updateLoading(state, ThreeDInDbDataType.GET_3D_IN_DB_REQUEST);
    case ThreeDInDbDataType.GET_3D_IN_DB_FULFILLED:
      return updateSuccess(
        state,
        ThreeDInDbDataType.GET_3D_IN_DB_REQUEST,
        action.payload as Payload.Get3DInDbDataFulfilledPayload
      );
    case ThreeDInDbDataType.GET_3D_IN_DB_REJECTED:
      return updateError(
        state,
        ThreeDInDbDataType.GET_3D_IN_DB_REQUEST,
        action.payload as Payload.Get3DInDbDataRejectedPayload
      );
    default:
      return state;
  }
};

export default threeDTasksReducer;

type requestType =
  | ThreeDTasksDataType.GET_3D_TASKS_REQUEST
  | ThreeDInDbDataType.GET_3D_IN_DB_REQUEST;

const updateLoading = (state: ThreeDState, requestType: requestType) => ({
  ...state,
  loading: {
    ...state.loading,
    [requestType]: true,
  },
  error: {
    ...state.error,
    [requestType]: null,
  },
});

type ErrorPayload =
  | Payload.Get3DTasksDataRejectedPayload
  | Payload.Get3DInDbDataRejectedPayload;
const updateError = (
  state: ThreeDState,
  requestType: requestType,
  { error }: ErrorPayload
) => ({
  ...state,
  loading: {
    ...state.loading,
    [requestType]: false,
  },
  error: {
    ...state.error,
    [requestType]: error,
  },
});

type SuccessPayload =
  | Payload.Get3DTasksDataFulfilledPayload
  | Payload.Get3DInDbDataFulfilledPayload;
const updateSuccess = (
  state: ThreeDState,
  requestType: requestType,
  payload: SuccessPayload
) => ({
  ...state,
  loading: {
    ...state.loading,
    [requestType]: false,
  },
  error: {
    ...state.error,
    [requestType]: null,
  },
  ...payload,
});
