import * as React from 'react';
import { Permission, Type } from 'constants/config';

export type BaseUser = {
  userID: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  avatar?: string;
  profilePicture?: string;
};

export type Handler = Omit<BaseUser, 'email' | 'phoneNumber'> | null;

export type KPI = {
  name?: string;
  label: string;
  value: number | string;
  unit?: string;
  link?: string;
};

export type TeamPatriciaKPIInfo = {
  avg_publishing_time: string;
  best_publishing_time: string;
  published_properties_single_day: number;
  total_archived_properties: number;
};

export type RequestParams<T> = {
  params?: T;
};

export type Setter<T> = React.Dispatch<React.SetStateAction<T>>;

export type IAddress = {
  id?: string;
  country: string;
  state?: string;
  city: string;
  street?: string;
  address_line_1?: string;
  address_line_2?: string;
  apartmentNumber?: string;
  house?: string;
  floor?: string;
  zipCode?: string;
  elevator?: boolean;
  isPrimary?: boolean;
};

export type ProjectAddress = {
  id?: string;
  country: string;
  state?: string;
  city: string;
  street?: string;
  house?: string;
  floor?: string;
  zipCode?: string;
  elevator?: boolean;
  created_at?: string;
  updated_at?: string;
};

export type UserForm = Omit<BaseUser, 'userID'> &
  Partial<Omit<IAddress, 'id' | 'isPrimary' | 'elevator'>>;

export type Dimension = { h: string | number; w: string | number };

export interface ModalProps {
  open: boolean;
  toggle: () => void;
}

export type MediaFile = {
  id: string | number;
  file: string;
};

export type LogoData = { id: number; company_name: string; logo: string };

export enum productModalViews {
  CLIENT_VIEW = 'CLIENT_VIEW',
  SUPPLIER_USER_VIEW = 'SUPPLIER_USER_VIEW',
  SUPPLIER_SUPPLIER_VIEW = 'SUPPLIER_SUPPLIER_VIEW',
}

export enum OrderDetailsView {
  USER_MANAGEMENT = 'USER_MANAGEMENT_VIEW',
  SUPPLIER_INTERFACE = 'SUPPLIER_INTERFACE_VIEW',
}

export type Ref<T> = React.MutableRefObject<T>;

export type PermissionsParams = {
  permissions: Permission | Permission[];
  types: Type | Type[];
};

export type Value = { id: number; name: string };
export type BaseProperty = {
  id: string;
  name: string;
  link?: string;
};
// UserManagement
export type BaseUserResponse = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  avatar?: string;
  profile_picture?: string;
  country?: string;
};
export type HandlerResponse = Omit<
  BaseUserResponse,
  'email' | 'phone_number'
> | null;

export enum SupportedLanguage {
  HEBREW = 'he',
  ENGLISH = 'en',
}

export enum SupportedCountry {
  ISRAEL = 'IL',
  USA = 'US',
}

export enum SupportedCurrency {
  ILS = 'ILS',
  USD = 'USD',
}

export enum TAX_RATE {
  IL = 17 / 100,
  US = 10 / 100,
}

export type SubCategory = {
  category: {
    id: number;
    name: string;
    thumbnail: string;
    list_number: number;
    homepage_thumbnail?: string;
    cover_photo_mobile?: string;
    cover_photo_web?: string;
    hebrew_name: string;
    alt_name?: string;
  };
  id: number;
  name: string;
  thumbnail: string;
  hebrew_name: string;
  cover_photo_mobile?: string;
  cover_photo_web?: string;
  alt_name?: string;
};

export type Stories = {
  storySectionTitle: string;
  stories: Story[];
};

export type Story = {
  id?: number;
  storyName?: string;
  storyType?: string;
  url: string;
  type: string;
  storyOrder?: number;
  duration: number;
};

export type Room = {
  id: number;
  name: string;
};

export type UserLogType = {
  name: string;
  area: string;
  section: string;
  path?: string;
  action?: string;
  category?: string;
  metadata?: Object;
  user?: string;
  id?: number;
  createdAt?: string;
  page?: string;
};

export type ClientInformationType = {
  firstName: string;
  lastName: string;
  email?: string;
  password?: string;
  supplier?: number;
  phone?: string;
  branch?: string;
};

export type ProjectInformationType = {
  id?: string;
  name: string;
  number: string;
  cover_photo: string;
  unit_file: string;
  developer: number;
  supplier?: number;
  address: string;
  city: string;
  csv_standart?: string;
};

export type addressType = {
  street: string;
  city: string;
};
