import * as React from 'react';
import styled, { css } from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { Product } from 'types/products';
import type { GetProductsDataResponsePayload as GetSupplierProductsResponse } from 'store/types/user-management/suppliers.types';
import Loader from 'components/Loader/Loader';
import { FormattedMessage, useIntl } from 'react-intl';
import { Message } from 'i18n';
import SectionTitle from 'components/@client/SectionTitle/SectionTitle';
import { useRouter } from 'hooks';
import { ClientConfig } from 'api/client';
import ShowMoreButton from 'components/@client/Button/ShowMoreButton';
import NoProduct from 'components/@client/NoProduct/NoProduct';
import { LogsAPI } from 'api/methods';
import { PRODUCT_LISTING } from 'constants/common.constants';
import MobileProductCard from './MobileProductCard';

interface Props {
  title?: string;
  products?: GetSupplierProductsResponse | null;
  productPage?: boolean;
  loadMoreProducts?: (newConfig: ClientConfig) => void;
  isLoading?: boolean;
}

const ProductsCard: React.FC<Props> = ({
  title,
  products,
  productPage,
  loadMoreProducts,
  isLoading,
}) => {
  const intl = useIntl();
  const { query } = useRouter<{
    catName: string;
    subCatName: string;
    productId: string;
    searchName: string;
  }>();
  const [page, setPage] = React.useState(1);
  const [productList, setProductList] = React.useState<Product[] | undefined>();

  const showMoreBtn = React.useMemo(
    () => products && productList && products?.count > productList?.length,
    [productList, products]
  );

  React.useEffect(() => {
    if (loadMoreProducts) {
      if (query.subCatName && !query.productId)
        loadMoreProducts({
          params: { sub_category: query.subCatName, size: 12, page },
        });
      else
        loadMoreProducts({
          params: { category: query.catName, size: 12, page, ordering: '?' },
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  React.useEffect(() => {
    LogsAPI.pushDataLayer([
      { ecommerce: null },
      {
        ecommerce: {
          impressions: products?.results.map((r, i) => ({
            name: r.name,
            id: r.id,
            price: r.price,
            brand: r.manufacture.name,
            category: r.category,
            list: PRODUCT_LISTING,
            position: i + 1,
          })),
        },
      },
    ]);
    setProductList(prevProductList =>
      Array.isArray(prevProductList) &&
      Array.isArray(products?.results) &&
      products
        ? [...prevProductList, ...products?.results]
        : products?.results
    );
  }, [products]);

  return (
    <Root>
      {title && <Header>{title}</Header>}
      {productPage && (
        <SectionTitle
          label={intl.formatMessage(
            { id: Message.PRODUCT_MORE_FROM },
            { cat: query.catName }
          )}
          borderColor="#A990DD"
        />
      )}
      <Container productPage={!!productPage}>
        <Grid container={!productPage}>
          {productList && !productList.length ? (
            <NoProduct value={Message.NO_PRODUCT_AVAILABLE} />
          ) : (
            productList
              ?.filter(x => x.id !== query.productId)
              .map((data: Product) => {
                return (
                  <Grid item xs={6} sm={4} key={data.id}>
                    <MobileProductCard product={data} />
                  </Grid>
                );
              })
          )}
          {!productList && <Loader />}
          {!!showMoreBtn &&
            (isLoading ? (
              <Loader />
            ) : (
              <Button>
                <ShowMoreButton
                  onClick={() => setPage(prevPage => prevPage + 1)}
                >
                  <FormattedMessage id={Message.SKINS_SHOW_MORE_BUTTON} />
                </ShowMoreButton>
              </Button>
            ))}
        </Grid>
      </Container>
    </Root>
  );
};

export default ProductsCard;

const Root = styled.div`
  padding: 0;
`;

const Container = styled.div<{ productPage: boolean }>`
  & > div {
    overflow: auto;
    display: flex;
    & > div {
      ${({ productPage }) =>
        productPage &&
        css`
          min-width: 200px;
        `}
    }
  }
`;

const Header = styled.h2`
  font-size: 1.75rem;
  font-weight: 500;
  text-align: center;
  margin: 30px 0 15px;
`;

const Button = styled.div`
  margin: auto;
  text-align: center;
  & > button {
    margin: 20px 0;
  }
`;
