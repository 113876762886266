import * as React from 'react';
import styled from 'styled-components';
import Chip from '@mui/material/Chip';
import CheckIcon from '@mui/icons-material/Check';
import { sortBy } from 'utils/lodash.utils';
import { useMediaQuery } from '@material-ui/core';
import { MOBILE_RESOLUTION } from 'styles/sharedStyle';

type ChipType = {
  id: number;
  name: string;
};

interface Props {
  title?: string;
  getValue?: (data: ChipType[]) => void;
  icon?: JSX.Element;
  defaultValue?: ChipType[];
  label?: string;
  options: { id: number; name: string }[];
  allChips?: boolean;
  allChipName?: string;
  singleSelect?: boolean;
}

const MultipleChips: React.FC<Props> = ({
  icon = <CheckIcon fontSize="medium" />,
  getValue,
  options,
  defaultValue,
  allChips,
  allChipName,
  singleSelect = false,
}) => {
  const [chips, setChips] = React.useState<ChipType[]>(defaultValue || []);
  const equalChips = JSON.stringify(chips) === JSON.stringify(options);
  const isMobile = useMediaQuery(MOBILE_RESOLUTION);

  const all = {
    id: 0,
    name: allChipName || 'All',
  };

  React.useEffect(() => {
    if (getValue) {
      getValue(chips);
    }
  }, [getValue, chips]);

  const onClick = React.useCallback(
    data => {
      const chipsData = chips;
      if (data.id === 0 || data.name === 'All') {
        if (equalChips) {
          setChips([]);
        } else setChips(options);
      } else if (chipsData.includes(data)) {
        const custom = chipsData.filter(x => x !== data);
        setChips(custom);
      } else {
        const custom = singleSelect ? [data] : [...chipsData, data];
        setChips(sortBy(custom, 'id'));
      }
    },
    [chips, equalChips, options, singleSelect]
  );

  const chipsData = React.useMemo(() => {
    const chipsPerRow = isMobile ? 3 : 5;
    const tempArray = [];
    for (let i = 0; i < Math.ceil(options.length / chipsPerRow); i++) {
      const tempChipsArray = [];
      for (
        let j = 0;
        j < chipsPerRow && Boolean(options[i * chipsPerRow + j]);
        j++
      ) {
        tempChipsArray.push(
          <ChipContainer
            selected={
              chips.includes(options[i * chipsPerRow + j]) || equalChips
            }
          >
            <CustomChip
              label={options[i * chipsPerRow + j].name}
              color={
                chips.includes(options[i * chipsPerRow + j]) || equalChips
                  ? 'primary'
                  : 'default'
              }
              clickable
              size="small"
              onClick={() => onClick(options[i * chipsPerRow + j])}
            />
          </ChipContainer>
        );
      }
      tempArray.push(<ChipRowContainer>{tempChipsArray}</ChipRowContainer>);
    }
    return tempArray;
  }, [chips, equalChips, onClick, options, isMobile]);

  return (
    <Root>
      {allChips && !singleSelect && (
        <AllChipContainer>
          <ChipContainer selected={chips.includes(all) || equalChips}>
            <Chip
              label={all.name}
              color={chips.includes(all) || equalChips ? 'primary' : 'default'}
              clickable
              size="medium"
              onClick={() => onClick(all)}
            />
          </ChipContainer>
        </AllChipContainer>
      )}
      <ChipsContainer> {chipsData}</ChipsContainer>
    </Root>
  );
};

export default MultipleChips;

const Root = styled.div`
  width: 748px;
  @media (max-width: 767px) {
    width: 90vw;
    margin: auto;
  }
`;
const ChipsContainer = styled.div``;
const ChipRowContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 16px 0;
  @media (max-width: 767px) {
    margin: 8px 0;
  }
`;
const CustomChip = styled(Chip)`
  @media (max-width: 767px) {
    height: 36px;
    width: fit-content;

    padding: 6px 12px !important;

    span {
      font-size: 14px;
      line-height: 24px;
      padding: 6px 4px !important;
    }
  }
`;

const ChipContainer = styled.div<{
  selected: boolean;
}>`
  > div {
    background-color: ${({ theme, selected }) =>
      selected ? theme.colors.primary.lightMobile : 'white'};
    font-size: 16px;
    line-height: 24px;
    color: #282929;
    font-weight: ${({ selected }) => (selected ? 500 : 400)};
    height: 40px;
    padding: 6px 24px;
    border: 0.5px solid #b3b3b3;
    border-radius: 10px;
    margin: 0 8px;
    &: hover {
      background-color: ${({ theme, selected }) =>
        selected ? theme.colors.primary.lightMobile : '#f2f2f2'};
      color: black;
      font-weight: 500;
    }
    @media (max-width: 767px) {
      margin: 0 4px;
      height: 36px;
    }
  }
`;

const AllChipContainer = styled.div`
  text-align: center;
  margin: auto;
  margin-bottom: 32px;
  margin-top: 44px;
  padding-bottom: 32px;
  width: 336px;
  border-bottom: 2px solid #fc5c42;
  @media (max-width: 767px) {
    width: 167px;
  }
`;
