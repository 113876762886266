import styled from 'styled-components';
import { Buy, Heart } from 'assets/@client/profile';
import InformationField from 'components/@client/InformationField';
import { Message } from 'i18n';
import { FormattedMessage } from 'react-intl';

const InformationBlock = () => {
  return (
    <Container>
      <InformationField
        icon={<Heart />}
        title={<FormattedMessage id={Message.PROFILE_INFO_BOX_TITLE} />}
      >
        <FormattedMessage id={Message.PROFILE_INFO_BOX_DETAILS} />
      </InformationField>
      <InformationField
        icon={<Buy />}
        title={
          <FormattedMessage id={Message.PROFILE_INFO_BOX_SHIPPING_TITLE} />
        }
      >
        <FormattedMessage id={Message.PROFILE_INFO_BOX_SHIPPING_DETAILS} />
      </InformationField>
    </Container>
  );
};

export default InformationBlock;

const Container = styled.section`
  flex: 0.3;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  min-height: 200px;
  padding: 50px 30px;
  align-self: flex-start;
`;
