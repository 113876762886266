import { METHODS } from 'api/client';
import { LogsAPI } from 'api/methods';
import { useOnboarding, useOnboardingState } from 'context/onboardingContext';
import { useFetch, useNotifications, useRouter, useValidateUser } from 'hooks';
import { Message } from 'i18n';
import { userLogData } from 'mockData/userLogData';
import * as Section from 'pages/appClient/onboarding/shared';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';
import { ClientRouter } from 'router/routes';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { isEmpty } from 'utils/lodash.utils';
import useQuizSummary from './useQuizSummary';

const QuizSummary = () => {
  const { history } = useRouter();
  const { isAuthenticated } = useValidateUser();
  const { answers, gridItems, prev, next } = useQuizSummary();
  const { selectedFloor, questions, maxPrice, rooms } = useOnboardingState();
  const { showNotification } = useNotifications();
  const [{ choosePlan }] = useOnboarding();

  const onSuccess = React.useCallback(() => {
    const html = document.querySelector('html') as HTMLHtmlElement;
    html.style.overflowY = '';
    history.push(ClientRouter.PROPERTIES);
  }, [history]);

  const onFailure = React.useCallback(
    (message: string) => {
      showNotification({
        key: 'client/processPropertyQuiz',
        message,
        severity: 'error',
      });
    },
    [showNotification]
  );

  const { isLoading, callFetch: processPropertyQuiz } = useFetch({
    initialUrl: '/api/properties/process_property_quiz/',
    skipOnStart: true,
    onSuccess,
    onFailure,
  });

  const onAddProperty = React.useCallback(() => {
    const propertyDetails = choosePlan
      ? { pre_made_property: selectedFloor?.id }
      : { property_file: selectedFloor?.id };
    processPropertyQuiz({
      method: METHODS.POST,
      data: {
        ...propertyDetails,
        answers: Object.values(answers).filter(Boolean),
        quiz: questions?.[0]?.quiz,
        max_price: maxPrice,
        rooms: rooms.map(x => x.id),
      },
    });
  }, [
    answers,
    processPropertyQuiz,
    questions,
    selectedFloor?.id,
    choosePlan,
    maxPrice,
    rooms,
  ]);

  const colors = React.useMemo(() => {
    const question = questions.find(e => e.question.includes('color'));
    return question?.answers.find(e => e.id === answers[question.id])?.colors;
  }, [questions, answers]);

  if (isEmpty(answers)) return <Redirect to={ClientRouter.UPLOAD_PLAN} />;
  return (
    <Container className="quiz-container summary">
      <Section.PhotoGrid items={gridItems} colors={colors} />

      <CustomActions
        withPrevious
        isLoading={isLoading}
        nextText={
          <FormattedMessage id={Message.PROPERTY_ADD_PROPERTY_BUTTON_NEXT} />
        }
        prevProps={{
          onClick: () => {
            LogsAPI.postUserLogs({
              area: userLogData.eventArea.registration,
              section: userLogData.eventSection.planUpload,
              name: userLogData.eventName.backToQuestions,
              path: userLogData.eventPath.appOnBoardingQuizSummaryPath,
            });
            prev();
          },
        }}
        nextProps={{
          onClick: () => {
            LogsAPI.postUserLogs({
              area: userLogData.eventArea.registration,
              section: userLogData.eventSection.planUpload,
              name: userLogData.eventName.nextStep,
              path: userLogData.eventPath.appOnBoardingQuizSummaryPath,
            });
            if (isAuthenticated) onAddProperty();
            else next();
          },
        }}
      />
    </Container>
  );
};

export default QuizSummary;

const Container = styled(Section.Container)`
  width: 100%;
  margin: auto;
  text-align: center;
  & .section-title-label {
    @media (min-height: 700px) {
      margin: auto;
      max-width: unset;
      font-size: 1.25rem;
    }
    @media (min-width: 1150px) {
      max-width: unset;
      text-align: start;
      font-weight: 700;
    }
  }

  & .progress-bar {
    margin-top: 20px;
  }

  & .quiz-summary-photo-grid {
    margin-top: 10px;
    ${rtl`
      margin-left: auto;
      margin-right: auto;
    `}

    @media (min-height: 700px) {
      margin-top: 40px;
    }

    @media (min-height: 800px) {
      margin-top: 50px;
    }
  }

  & .actions-container {
    margin-top: 10px;
    @media (min-height: 700px) {
      margin-top: 40px;
    }

    @media (min-height: 800px) {
      margin-top: 80px;
    }

    @media (max-width: 767px) {
      position: fixed;
      bottom: 24px !important;
      width: 100% !important;
    }

    @media only screen and (min-height: 850px) and (min-width: 1150px) {
      margin-top: 10px;
    }
  }
`;
const CustomActions = styled(Section.Actions)``;
