import * as React from 'react';
import styled from 'styled-components';
import { ReactComponent as Checkmark } from './pick.svg';

export interface CheckboxProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const Checkbox: React.FC<CheckboxProps> = ({ children, ...props }) => {
  return (
    <Container>
      {props.value !== 'lateShipping' && children}
      <input tabIndex={0} type="checkbox" {...props} />
      <Checkmark className="checkmark" />
      {props.value === 'lateShipping' && children}
    </Container>
  );
};

export default Checkbox;

const Container = styled.label.attrs({ className: 'checkbox' })`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 1rem;
  user-select: none;
  height: auto;
  min-height: 25px;

  /* Hide the browser's default checkbox */
  > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 100%;
    width: 100%;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 15px;
    padding: 5px;
    transition: 0.3s all;

    & path {
      opacity: 0;
    }
  }

  /* On mouse-over, add a grey background color */
  :hover input ~ .checkmark,
  input:focus ~ .checkmark {
    background-color: #ccc;
  }
  :hover input:not(:checked) ~ .checkmark path {
    opacity: 0;
  }

  /* When the checkbox is checked, add a background */
  input:checked ~ .checkmark {
    background-color: #fc5c42;

    path {
      opacity: 1;
    }
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    left: 9px;
    top: 7px;
    width: 9px;
    height: 10px;
  }
`;
