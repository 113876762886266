import * as React from 'react';
import * as Response from 'api/responses';
import { useAuthentication, useFetch, useNotifications } from 'hooks';
import { GetProjectDataResponsePayload } from 'store/types/user-management/properties.types';
import { Type } from 'constants/config';

const useProperties = (skipOnStart: boolean = false) => {
  const { type, token } = useAuthentication();
  const [pagination, setPagination] = React.useState({
    page: 1,
    size: 5,
    name: '',
  });
  const { showNotification } = useNotifications();

  const onFailure = React.useCallback(() => {
    showNotification({
      key: 'properties/getPropertiesDataRejected',
      message:
        'We had a problem fetching your projects, please try again later',
      severity: 'error',
    });
  }, [showNotification]);

  const { data: properties, isLoading, callFetch } = useFetch<
    GetProjectDataResponsePayload,
    Response.Projects
  >({
    initialUrl: `/api/projects/`,
    skipOnStart,
    config: {
      params:
        type === Type.SELLER
          ? pagination
          : {
              page: pagination.page,
              size: pagination.size,
              name: pagination.name,
            },
    },
    onFailure,
  });
  React.useEffect(() => {
    if (token)
      callFetch({
        params:
          type === Type.SELLER
            ? pagination
            : {
                page: pagination.page,
                size: pagination.size,
                name: pagination.name,
              },
      });
  }, [pagination, type, callFetch, token]);
  return {
    properties,
    isLoading,
    callFetch,
    pagination,
    setPagination,
  };
};

export default useProperties;
