import * as React from 'react';
import { Type } from 'constants/config';
import ExternalSidebar from 'pages/common/layout/ExternalSidebar';
import Main from 'pages/common/layout/Main';
import Header from 'pages/common/layout/Header';
// import { Drawer } from '@material-ui/core';
// import { MOBILE_RESOLUTION } from 'styles/sharedStyle';
// import { useMediaQuery } from '@mui/material';
import { isMobile } from 'react-device-detect';
import styled, { css } from 'styled-components';
import ClientLayout from './@client/ClientLayout';

interface Props {
  userType: Type;
}
const Layout: React.FC<Props> = ({ userType, children }) => {
  // const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  if (userType === Type.CLIENT) return <ClientLayout>{children}</ClientLayout>;
  // if (isMobile)
  //   return (
  //     <>
  //       <Header onDrawerOpen={() => setIsDrawerOpen(true)} />
  //       <Drawer
  //         anchor="left"
  //         open={isDrawerOpen}
  //         onClose={() => setIsDrawerOpen(false)}
  //         PaperProps={{
  //           style: {
  //             overflow: 'auto',
  //           },
  //         }}
  //       >
  //         <ExternalSidebar userType={userType} />
  //       </Drawer>
  //       <MobileMain userType={userType} className="main" isMobile={isMobile}>
  //         {children}
  //       </MobileMain>
  //     </>
  //   );
  if (isMobile)
    return (
      <>
        <Header />
        <ExternalSidebar userType={userType} />
        <MobileMain userType={userType} className="main">
          {children}
        </MobileMain>
      </>
    );
  return (
    <>
      <Header />
      <ExternalSidebar userType={userType} />
      <Main userType={userType} className="main">
        {children}
      </Main>
    </>
  );
};

const MobileMain = styled.main<{
  userType: Type;
  withNavbar?: boolean;
  withBrandCarousel?: boolean;
  isMobile?: boolean;
}>`
  /* height: calc(100vh - 60px); */
  width: calc(100vw - 90px);
  overflow: scroll;
  margin: 0;
  top: 60px;
  left: 90px;
  position: relative;
  z-index: 0;
  ${isMobile &&
  css`
    /* left: 0; */
    width: unset;
  `}
  /* overflow-y: scroll;  */
  ${({ theme }) => theme.utils.scrollbar}
`;

export default Layout;
