import * as React from 'react';
import styled, { css } from 'styled-components';
import { useIntl, FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
// import Title from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { ClientSelectFilter as StatusFilter } from 'components/Filters';
import { Option } from 'components/Autocomplete/Autocomplete.config';
import { useWindowScroll } from 'hooks';
import rtl from 'styled-components-rtl';
import { SkinFilter, SkinFilterType } from 'types/properties';
import Modal from 'components/@client/Modal';
import Button from 'components/@client/Button';
import { ClientBasicOption } from 'components/Filters/Options';
import ButtonComponent from './ButtonComponent';
import { SkinSortType } from '../Skins.config';

const MobileFilter: React.FC<{
  updateView: (data: SkinFilter) => void;
  sortByOptions: Option[];
  designStyles: Option[];
  applyDesignStyle: (selectStyles: Option | Option[]) => void;
  colorOptions: Option[];
  roomOptions: Option[];
  budgetOptions: Option[];
  creators: Array<{
    id: string;
    first_name: string;
    last_name: string;
  }>;
}> = ({
  updateView,
  sortByOptions,
  designStyles,
  applyDesignStyle,
  creators,
  roomOptions,
  colorOptions,
  budgetOptions,
}) => {
  const intl = useIntl();
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [selectedDesignStyle, setSelectedDesignStyle] = React.useState<
    Option[] | null
  >(null);
  const [selectedBudget, setSelectedBudget] = React.useState<Option[] | null>(
    null
  );
  const [selectedDesignBy, setSelectedDesignBy] = React.useState<
    Option[] | null
  >(null);
  const [selectedColor, setSelectedColor] = React.useState<Option[] | null>(
    null
  );
  const [selectedRoom, setSelectedRoom] = React.useState<Option[] | null>(null);
  const [isClearEnabled, setIsClearEnabled] = React.useState(false);
  const [count, setCount] = React.useState<number>(0);
  const { topPosition } = useWindowScroll();

  React.useEffect(() => {
    if (count) {
      if (
        selectedDesignStyle?.length ||
        selectedBudget?.length ||
        selectedDesignBy?.length ||
        selectedColor?.length ||
        selectedRoom?.length
      ) {
        setIsClearEnabled(true);
      } else {
        setIsClearEnabled(false);
      }
    }
  }, [
    count,
    selectedBudget?.length,
    selectedColor?.length,
    selectedDesignBy?.length,
    selectedDesignStyle?.length,
    selectedRoom?.length,
  ]);

  const filterName = React.useMemo(
    () => ({
      [SkinFilterType.SORT]: intl.formatMessage({
        id: Message.SKIN_FILTER_BY_SORT,
      }),
      [SkinFilterType.BUDGET]: intl.formatMessage({
        id: Message.SKIN_FILTER_BY_BUDGET,
      }),
      [SkinFilterType.DESIGNED_BY]: intl.formatMessage({
        id: Message.SKIN_FILTER_BY_DESIGNED_BY,
      }),
      [SkinFilterType.DESIGN_STYLE]: intl.formatMessage({
        id: Message.SKIN_FILTER_BY_DESIGN_STYLE,
      }),
      [SkinFilterType.POPULAR]: intl.formatMessage({
        id: Message.SKIN_FILTER_BY_POPULAR,
      }),
      [SkinFilterType.ROOM]: intl.formatMessage({
        id: Message.SKIN_FILTER_BY_ROOM,
      }),
      [SkinFilterType.COLOR]: intl.formatMessage({
        id: Message.SKIN_FILTER_BY_COLOR,
      }),
    }),
    [intl]
  );

  const onChange = React.useCallback(
    (values: Option[], type: string) => {
      setCount(count + 1);
      switch (type) {
        case filterName[SkinFilterType.DESIGN_STYLE]:
          setSelectedDesignStyle(values);
          break;
        case filterName[SkinFilterType.BUDGET]:
          setSelectedBudget(values);
          break;
        case filterName[SkinFilterType.DESIGNED_BY]:
          setSelectedDesignBy(values);
          break;
        case filterName[SkinFilterType.COLOR]:
          setSelectedColor(values);
          break;
        case filterName[SkinFilterType.ROOM]:
          setSelectedRoom(values);
          break;

        default:
          break;
      }
    },
    [count, filterName]
  );

  const onSortApply = React.useCallback(
    (values: Option[]) => {
      if (values[0]?.value === SkinSortType.NEW) {
        updateView({
          ordering: '-created_at',
        });
      }
      if (values[0]?.value === SkinSortType.POPULAR) {
        updateView({
          ordering: '-rating',
        });
      }
      if (values[0]?.value === SkinSortType.HIGHTOLOW) {
        updateView({
          ordering: '-price',
        });
      }
      if (values[0]?.value === SkinSortType.LOWTOHIGH) {
        updateView({
          ordering: '-price',
        });
      }
    },
    [updateView]
  );

  const toggleModal = React.useCallback(() => {
    setIsModalOpen(!isModalOpen);
  }, [isModalOpen]);

  const onBudgetApply = React.useCallback((values: Option[]) => {
    if (values[0]?.value)
      // eslint-disable-next-line default-case
      switch (values[0].value) {
        case 1:
          return { budget_from: 0, budget_to: 10000 };
        case 2:
          return { budget_from: 10000, budget_to: 25000 };
        case 3:
          return { budget_from: 25000, budget_to: 100000 };
        case 4:
          return { budget_from: 100000, budget_to: undefined };
      }
    return { budget_from: undefined, budget_to: undefined };
  }, []);

  const onApply = React.useCallback(() => {
    updateView({
      user: selectedDesignBy?.map(e => e?.value.toString() ?? '') ?? [],
      color: selectedColor?.map(e => e?.value.toString() ?? '') ?? [],
      style: selectedDesignStyle?.map(e => e?.value.toString() ?? '') ?? [],
      room: selectedRoom?.map(e => e?.value.toString() ?? '') ?? [],
      ...(selectedBudget ? onBudgetApply(selectedBudget) : {}),
    });
    setIsModalOpen(false);
  }, [
    updateView,
    onBudgetApply,
    selectedDesignBy,
    selectedColor,
    selectedDesignStyle,
    selectedBudget,
    selectedRoom,
  ]);

  const clearAllFilters = () => {
    onChange([], filterName[SkinFilterType.DESIGN_STYLE]);
    onChange([], filterName[SkinFilterType.DESIGNED_BY]);
    onChange([], filterName[SkinFilterType.BUDGET]);
    onChange([], filterName[SkinFilterType.COLOR]);
    onChange([], filterName[SkinFilterType.ROOM]);
  };

  return (
    <>
      <Container position={topPosition}>
        <DropDownFilter>
          <StatusFilter
            options={sortByOptions}
            placeholder={filterName[SkinFilterType.SORT]}
            selectedPlaceholder={filterName[SkinFilterType.SORT]}
            onApply={onSortApply}
            renderOption={ClientBasicOption}
            multiple={false}
            customIcon={true}
            buttonClassName="skin-sort-filter"
            placement="bottom-end"
            modifiers={[
              {
                name: 'flip',
                enabled: true,
                options: {
                  altBoundary: false,
                  rootBoundary: 'document',
                  padding: 8,
                },
              },
            ]}
            popperOptions={{
              strategy: 'fixed',
            }}
          />
        </DropDownFilter>

        <IconButton className="filter-btn" onClick={toggleModal}>
          {isClearEnabled ? (
            <Badge color="secondary" variant="dot">
              {intl.formatMessage({
                id: Message.SKIN_FILTER_MODAL_TITLE,
              })}
            </Badge>
          ) : (
            intl.formatMessage({
              id: Message.SKIN_FILTER_MODAL_TITLE,
            })
          )}
        </IconButton>
      </Container>
      <React.Suspense fallback={null}>
        <ModalComponent
          isFooterBtn={false}
          open={isModalOpen}
          disableClose={true}
          onClose={() => toggleModal()}
          fullScreen={true}
        >
          <HeaderContainerParent>
            <HeaderContainer>
              <IconContainer onClick={toggleModal}>
                <ArrowBackIosNewIcon />
              </IconContainer>
              <Title>
                {intl.formatMessage({
                  id: Message.SKIN_FILTER_MODAL_TITLE,
                })}
              </Title>
              <ClearButtonParent>
                <ClearButton
                  disabled={!isClearEnabled}
                  onClick={clearAllFilters}
                >
                  <FormattedMessage id={Message.SKIN_FILTER_MODAL_CLEAR} />
                </ClearButton>
              </ClearButtonParent>
            </HeaderContainer>
          </HeaderContainerParent>
          <AllButtonsParent>
            <ButtonComponent
              selectedValues={selectedDesignBy}
              multiple={true}
              title={filterName[SkinFilterType.DESIGNED_BY]}
              options={
                creators?.map(e => ({
                  name: `${e.first_name} ${e.last_name}`,
                  value: e.id,
                })) ?? []
              }
              type={filterName[SkinFilterType.DESIGNED_BY]}
              onClick={onChange}
            />
            <ButtonComponent
              selectedValues={selectedDesignStyle}
              multiple={true}
              title={filterName[SkinFilterType.DESIGN_STYLE]}
              options={designStyles}
              type={filterName[SkinFilterType.DESIGN_STYLE]}
              onClick={onChange}
            />
            <ButtonComponent
              selectedValues={selectedBudget}
              title={filterName[SkinFilterType.BUDGET]}
              options={budgetOptions}
              type={filterName[SkinFilterType.BUDGET]}
              onClick={onChange}
            />
            <ButtonComponent
              selectedValues={selectedRoom}
              multiple={true}
              title={filterName[SkinFilterType.ROOM]}
              options={roomOptions}
              type={filterName[SkinFilterType.ROOM]}
              onClick={onChange}
            />
            <ButtonComponent
              selectedValues={selectedColor}
              multiple={true}
              title={filterName[SkinFilterType.COLOR]}
              options={colorOptions}
              type={filterName[SkinFilterType.COLOR]}
              onClick={onChange}
            />
          </AllButtonsParent>
          <ButtonContainer>
            <Button className="action-filter-button" onClick={onApply}>
              <FormattedMessage id={Message.SKIN_FILTER_MODAL_SHOW_FILTER} />
            </Button>
          </ButtonContainer>
        </ModalComponent>
      </React.Suspense>
    </>
  );
};

export default MobileFilter;

const DropDownFilter = styled.div`
  width: 50%;
  & {
    .skin-sort-filter {
      background: #fafcfe;
      border: 1px solid rgba(208, 208, 208, 0.12);
      justify-content: center;
      width: 100%;
      border-radius: 0px;
      gap: 4px;
      .dropdown-placeholder {
        font-family: ${({ theme }) =>
          theme.dir === 'rtl' ? 'Assistant' : 'Roboto'};
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #676766;
      }
      svg {
        fill: #676766;
      }
    }
  }
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #282929;
  width: 20%;
  text-align: center;
`;

const ModalComponent = styled(Modal)`
  .MuiDialog-paper {
    border-radius: 0 !important;
    padding: 0 !important;
  }
  &&.client-dialog .MuiDialog-paper .MuiDialogTitle-root {
    margin: 10px auto;
    padding: 10px 24px;
    font-size: 1.25rem;
    h2 {
      font-family: ${({ theme }) => theme.dir === 'rtl' && 'Assistant'};
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 110.1%;
      color: #424242;
    }
  }

  & .MuiDialogContent-root {
    padding: 0 !important;
    margin: 0 !important;
    background: #ffffff;
    overflow-y: initial;
  }
`;

const ButtonContainer = styled.div`
  display: block;
  text-align: center;
  background: #ffffff;
  border-radius: 7px;
  margin-top: 24px;
  .action-filter-button {
    font-family: ${({ theme }) => theme.dir === 'rtl' && 'Assistant'};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.9);
    padding: 15px;
    background: #fc5c42;
    border-radius: 15px;
    width: 148px;
    height: 40px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  border-bottom: 0.5px solid #e8eaeb;
  padding-bottom: 18px;
  .MuiDialogTitle-root {
    /* margin: 14px !important; */
    padding: 0 !important;
    font-family: ${({ theme }) => theme.dir === 'rtl' && 'Assistant'};
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 110.1%;
    color: #424242;
  }
  .all-btn {
    font-family: ${({ theme }) => theme.dir === 'rtl' && 'Assistant'};
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 110.1%;
    text-decoration-line: underline;
    color: #424242;
    width: 40%;
  }
`;

const Container = styled.div<{ position: boolean }>`
  display: flex;
  width: 100%;
  margin-top: ${({ position }) => (position ? '62' : '24')}px;
  justify-content: center;
  border: 1px solid #d0d0d01f;
  align-items: center;
  .MuiBadge-badge {
    background: linear-gradient(180deg, #fc5c42 0%, #ff816d 123.75%);
  }
  .filter-btn {
    font-family: ${({ theme }) => theme.dir === 'rtl' && 'Assistant'};
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 174.3%;
    color: #676767;
    width: 50%;
    height: 48px;
    padding: 14px 75px;
    background: #fafcfe;
    border: 1px solid rgba(208, 208, 208, 0.12);
    .MuiIconButton-label {
      font-family: ${({ theme }) =>
        theme.dir === 'rtl' ? 'Assistant' : 'Roboto'};
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #676766;
    }
    .MuiBadge-badge {
      ${rtl`
      left: 32px;
    `}
      top: 3px;
    }
  }
  ${({ position }) =>
    position === true &&
    css`
      position: fixed;
      z-index: 9999;
      opacity: 1;
      top: 0;
      background: #fff;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      .close-btn {
        background: #fff;
      }
    `}
  .custom-popper {
    background: #ffffff;
    width: 144px;
    height: 152px;
    margin-top: 4px !important;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    left: ${({ theme }) =>
      theme.dir === 'rtl' ? '30px !important' : '-30px !important'};
    span {
      font-size: 14px;
      line-height: 18px;
      color: #282929;
    }
    li {
      height: 34px;
      width: auto;
    }
  }
`;

const IconContainer = styled.div`
  width: 40%;
  svg {
    fill: #282929;
    transform: rotate(
      ${({ theme }) => (theme.dir === 'rtl' ? '180deg' : '0deg')}
    );
  }
`;

const ClearButton = styled.button`
  width: 69px;
  height: 36px;
  border: 1px solid #fc5c42;
  border-radius: 10px;
  ${rtl`
    float: right;
    `}
  font-size: 16px;
  line-height: 20px;
  background: #ffffff;
  font-family: ${({ theme }) => (theme.dir === 'rtl' ? 'Assistant' : 'Roboto')};
  color: #fc5c42;
  :disabled {
    color: #d9d9d9;
    border: 1px solid #d9d9d9;
  }
`;

const ClearButtonParent = styled.div`
  width: 40%;
`;

const HeaderContainerParent = styled.div`
  margin: 0 24px;
`;

const AllButtonsParent = styled.div`
  height: calc(100vh - 260px);
  overflow: auto;
`;
