import * as React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import ImagePreview from 'components/ImagePreview';
import { useImagePreview } from 'hooks';

interface Props extends React.HTMLAttributes<HTMLInputElement> {
  label?: string;
  name: string;
  required?: boolean;
}

const ImageField: React.FC<Props> = ({
  name,
  label,
  required,
  defaultValue,
  ...props
}) => {
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const [isHovering, setIsHovering] = React.useState<boolean>(false);
  const { setValue, control } = useFormContext();
  useController({
    control,
    name,
    defaultValue,
  });

  const { preview, clearPreview } = useImagePreview(
    inputRef.current?.files ?? null,
    name,
    defaultValue as string
  );

  const onClear = React.useCallback(() => {
    clearPreview();
    setValue(name, undefined);
  }, [clearPreview, name, setValue]);

  React.useEffect(() => {
    setValue(name, preview);
  }, [name, preview, setValue]);

  return (
    <Container
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <label htmlFor={name}>
        <ImagePreview
          alt={name}
          isHovering={isHovering}
          label={label}
          src={preview}
          onClear={onClear}
        />
        <input
          ref={inputRef}
          defaultValue=""
          name={name}
          id={name}
          hidden
          required={required}
          type="file"
          accept="image/*"
          {...props}
        />
      </label>
    </Container>
  );
};

export default ImageField;

const Container = styled.div`
  position: relative;
  ${({ theme }) => theme.utils.centerFlex};
  cursor: pointer;
  font-size: 0.875rem;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.silver};
  height: 150px;
  width: 150px;
  background: #f7f7f7;
  background-clip: content-box;
  border: 1px dashed#707070;
  border-radius: 8px;

  & label {
    max-width: 150px;
    max-height: 150px;
    position: relative;
    user-select: none;
    ${({ theme }) => theme.utils.centerFlex}
    flex-direction: column;
    gap: 7px;
    align-self: center;
    cursor: pointer;
    height: 100%;
    width: 100%;
  }
`;
