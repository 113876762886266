import { error } from 'styles/sharedStyle';
import type { SortingRule } from 'react-table';
import type { DateRange } from 'components/DateRange';
import type { IAddress, BaseUser } from 'types/common.types';
import type { Product } from 'types/products';
import type { Supplier } from 'types/suppliers';

export type OrdersRequestParams = {
  page: number;
  size: number;
  sortBy?: SortingRule<Order>;
  ordering?: string;
  dateRange?: DateRange;
  countries?: { iso: string; name: string }[];
  supplier?: { name: string; id: string }[] | string[] | string;
  status?: string[];
  search?: string;
};

export type OrderDetail = Product & {
  orderDetailsId: string;
  quantity: number;
  createdAt: string;
  shippingAddress?: IAddress | null;
  unitPrice: number;
  totalRowPrice: number;
  subTotal?: number;
  vat?: number;
  finalPrice: number;
};

export type Order = {
  id: string;
  createdAt: string;
  property: string;
  status: OrderStatus | SupplierOrderStatus | string;
  amount: number;
  supplier?: Supplier;
  deliveryDate?: string;
  shippingAddress: IAddress | null;
  shippingDate?: string;
  shippingEst?: string;
  totalPrice?: number;
  country?: string;
  client?: BaseUser;
  products: OrderDetail[];
  trackingNumber: string;
  subTotal: number;
  vat: number;
  finalPrice: number;
  discount?: number;
};

export type CancelOrderPayload = {
  email: string;
  phoneNumber: string;
};

export type OrderAutoCompleteType = {
  id: string;
  shippingAddress: IAddress;
  client?: BaseUser;
};

export enum OrderStatus {
  approved = 'Approved',
  received = 'Received',
  shipped = 'Shipped',
  done = 'Done',
  not_approved = 'Not approved',
  dispute = 'Dispute',
  in_progress = 'In Progress',
}

export enum SupplierOrderStatus {
  not_approved = 'Not approved',
  approved = 'Approved',
  shipped = 'Shipped',
  received = 'Received',
  dispute = 'Dispute',
  done = 'Done',
  pending = 'Pending',
}

export const OrderColors: Record<OrderStatus, string> = {
  [OrderStatus.received]: '#1355CC',
  [OrderStatus.shipped]: '#FFD800',
  [OrderStatus.done]: '#02AD42',
  [OrderStatus.in_progress]: '#1355CC',
  [OrderStatus.dispute]: error,
  [OrderStatus.not_approved]: error,
  [OrderStatus.approved]: '#1355CC',
};

export const OrderDetailStatusColors = {
  [SupplierOrderStatus.received]: '#FDEBC9',
  [SupplierOrderStatus.approved]: '#FDEBC9',
  [SupplierOrderStatus.shipped]: '#B9DAFF',
  [SupplierOrderStatus.done]: '#B0E3B3',
  [SupplierOrderStatus.dispute]: '#FFC5B7',
  [SupplierOrderStatus.not_approved]: '#FFC5B7',
};

export type OrderStatusType = {
  Approved: string;
  Shipped: string;
  Done: string;
  Received: string;
  'Not approved': string;
  Dispute: string;
  'In Progress': string;
};

export type CheckOutPersonalDetailPayload = {
  name: string;
  email: string;
  phoneNumber: string;
};

export type CheckOutDiscountCodeType = {
  discount?: string;
};
