import * as React from 'react';
import styled from 'styled-components';
import illustration from 'assets/@client/cart/cart-empty-state.svg';
import Button from 'components/@client/Button';
import { Link } from 'react-router-dom';
import { useRouter } from 'hooks';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';

const CartEmptyState: React.FC<{ to: string }> = ({ to }) => {
  const { pathname } = useRouter();

  return (
    <Container>
      <p>
        <FormattedMessage id={Message.CART_EMPTY_STATE_TITLE} />
      </p>
      <img
        src={illustration}
        alt="An upside down shopping cart with nothing falling out because it’s empty. Get to shopping!"
      />
      {!pathname.includes('/app/tour') && (
        <Link className="start-button" to={to}>
          <Button className="start-button">
            <FormattedMessage id={Message.CART_EMPTY_STATE_BUTTON} />
          </Button>
        </Link>
      )}
    </Container>
  );
};

export default CartEmptyState;

const Container = styled.article`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  & p {
    margin: auto;
    font-size: 1.25rem;
    font-weight: 700;
    max-width: 22ch;

    @media (min-width: 1150px) {
      font-size: 2.5rem;
    }
  }

  img {
    margin-top: 20px;
    max-width: 400px;
    width: 88%;

    @media (min-width: 1150px) {
      margin-top: 30px;
    }
  }

  & .start-button {
    text-decoration: none;
    margin-top: 50px;
    font-size: 1.125rem;
    max-width: 21ch;
    font-weight: 700;
    width: 100%;
    font-family: ${({ theme }) =>
      theme.dir === 'rtl' ? 'Assistant' : 'Roboto'};

    & button {
      margin-top: 0;
    }
  }
`;
