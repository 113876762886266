import QuizzesDataType from 'store/constants/quiz-management/quizManagement.constants';
import * as Payload from 'store/types/quizManagement.types';

export const getQuizzesDataRequest = (
  payload: Payload.GetQuizzesDataRequestPayload
) => ({
  type: QuizzesDataType.GET_QUIZZES_DATA_REQUEST,
  payload,
});

export const getQuizzesDataFulfilled = (
  payload: Payload.GetQuizzesFulfilledPayload
) => ({
  type: QuizzesDataType.GET_QUIZZES_DATA_FULFILLED,
  payload,
});

export const getQuizzesDataRejected = (
  payload: Payload.GetQuizzesRejectedPayload
) => ({
  type: QuizzesDataType.GET_QUIZZES_DATA_REJECTED,
  payload,
});

export type QuizzesAction = ReturnType<
  | typeof getQuizzesDataRequest
  | typeof getQuizzesDataFulfilled
  | typeof getQuizzesDataRejected
>;
