import * as React from 'react';
import Container from 'components/Modal';
import Title from 'components/Modal/Title';
import Content from 'components/Modal/ModalContent';
import Actions from 'components/Modal/ModalActions';
import { IAddress } from 'types/common.types';
import { useFetch, useNotifications } from 'hooks';
import { METHODS } from 'api/client';

interface Props {
  address: IAddress | null;
  isOpen: boolean;
  fetchAddresses?: (reason?: 'creation' | 'remove' | 'cancel') => void;
  toggle: () => void;
}

const DeleteAddressModal: React.FC<Props> = ({
  address,
  isOpen,
  toggle,
  fetchAddresses,
}) => {
  const { showNotification } = useNotifications();
  const body = React.useMemo(
    () => [
      'You are about to remove this address,',
      'Are you sure you want to perform this action?',
    ],
    []
  );

  const onSuccess = React.useCallback(() => {
    fetchAddresses?.('remove');
    toggle();
    showNotification({
      key: 'clients/removeAddressFulfilled',
      message: 'Address has been removed successfully',
      severity: 'success',
    });
  }, [fetchAddresses, showNotification, toggle]);

  const onFailure = React.useCallback(
    (message: string) => {
      showNotification({
        key: 'clients/removeAddressRejected',
        message,
        severity: 'error',
      });
    },
    [showNotification]
  );

  const { callFetch, isLoading } = useFetch({
    initialUrl: `/api/addresses/${address?.id}/`,
    skipOnStart: true,
    onSuccess,
    onFailure,
  });

  const action = React.useCallback(
    () => callFetch({ method: METHODS.DELETE }),
    [callFetch]
  );

  return (
    <Container open={isOpen} close={toggle}>
      <Title>Remove Address</Title>
      <Content body={body} />
      <Actions
        showCancelButton
        actions={[{ label: 'Remove Address', action, isLoading }]}
        onCancel={toggle}
      />
    </Container>
  );
};

export default DeleteAddressModal;
