import { useMediaQuery } from '@material-ui/core';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

interface Props {
  title?: string;
  min: number;
  max: number;
  step?: number;
  getValue?: (data: number) => void;
  icon?: React.ReactNode | string;
  defaultValue?: number;
  label?: string;
}

const SliderRange: React.FC<Props> = ({
  min,
  max,
  step,
  getValue,
  icon,
  title,
  defaultValue,
  label,
}) => {
  const [value, setValue] = React.useState<number>(defaultValue || 0);
  const intl = useIntl();
  const handleSliderChange = React.useCallback((event, newValue) => {
    setValue(newValue);
  }, []);
  const isMobile = useMediaQuery('(max-width: 500px)');

  React.useEffect(() => {
    if (getValue) {
      getValue(value);
    }
  }, [getValue, value]);

  const internationalNumberFormat = new Intl.NumberFormat('en-US');

  const valuetext = (data: number) => {
    return `${label}${internationalNumberFormat.format(data)}`;
  };

  return (
    <>
      {title && (
        <Typography id="input-slider" gutterBottom>
          {title}
        </Typography>
      )}
      <Grid
        container
        spacing={3}
        alignItems="center"
        style={{ maxWidth: isMobile ? 366 : 900, margin: 'auto' }}
      >
        <CustomGrid item xs style={{ display: 'flex' }} dir="ltr">
          {!isMobile && <div>{valuetext(min)}</div>}
          <SliderContainer
            componentsProps={{
              rail: {
                style: {
                  maxWidth: isMobile ? '300px' : '764px',
                  height: '10px',
                  background: '#E5E5E5',
                  borderRadius: '19px',
                },
              },
              thumb: {
                style: {
                  width: isMobile ? '48px' : '57px',
                  height: '24px',
                  borderRadius: '21px',
                  border: '5px solid #FFFFFF',
                  background:
                    'linear-gradient(340.36deg, #FF5241 0.1%, #FF6636 129.88%)',
                  '&:hover': {
                    background:
                      'linear-gradient(340.36deg, #A50A0A 0.1%, #A50A0A 129.88%)',
                  },
                },
              },
            }}
            value={typeof value === 'number' ? value : 0}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
            step={step}
            min={min}
            max={max}
            dir="rtl"
          />
          {!isMobile && <div>{valuetext(max)}</div>}
        </CustomGrid>
      </Grid>
      {isMobile && intl.locale === 'he' && (
        <MobileValuesContainer>
          <div>{valuetext(max)}</div>
          <div>{valuetext(min)}</div>
        </MobileValuesContainer>
      )}
      {isMobile && intl.locale === 'en' && (
        <MobileValuesContainer>
          <div>{valuetext(min)}</div>
          <div>{valuetext(max)}</div>
        </MobileValuesContainer>
      )}
    </>
  );
};

export default SliderRange;

const CustomGrid = styled(Grid)`
  @media (max-width: 767px) {
    padding: 0 34px 0 30px !important;
  }
  div {
    margin: -2px 32px;
    @media (max-width: 500px) {
      margin: 6px 20px;
    }
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 145%;
    /* or 17px */
    padding: 0;
    @media (min-width: 767px) and (max-width: 1050px) {
      padding-top: 14px;
    }
    padding-top: 8px;
    text-align: right;

    color: #3e3e3e;
  }
`;

const MobileValuesContainer = styled.div`
  display: flex;
  width: 327px;
  justify-content: space-between;

  margin: auto;
  font-weight: 400;
  font-size: 12px;
  color: #484545;
  opacity: 0.5;
`;

const SliderContainer = styled(Slider)`
  @media (min-width: 1200px) {
    width: 764px !important;
  }
  color: ${({ theme }) => theme.colors.primary.lightMobile} !important;
  .MuiSlider-thumb {
    &: hover {
      background: linear-gradient(
        340.36deg,
        #a50a0a 0.1%,
        #a50a0a 129.88%
      ) !important;
    }
  }
`;
