import * as React from 'react';
import { useIntl, FormatNumberOptions } from 'react-intl';
import useSession from 'hooks/useSession';

const useFormattedCurrency = (
  value?: number,
  overrides?: FormatNumberOptions,
  fallback: number = 0
) => {
  const intl = useIntl();
  const { currency } = useSession();
  const formattedNumber = React.useMemo(
    () =>
      intl.formatNumber(value ?? fallback, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        style: 'currency',
        ...overrides,
        currency: overrides?.currency ?? currency,
      }),
    [currency, fallback, intl, overrides, value]
  );

  return formattedNumber;
};

export default useFormattedCurrency;
