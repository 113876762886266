import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { error as errorColor } from 'styles/sharedStyle';
import FormHelperText from '@material-ui/core/FormHelperText';
import Icon from 'components/Icon/Icon';

interface Props {
  name: string;
}

const FormErrorMessageSupplier: React.FC<Props> = ({ name }) => {
  const { formState } = useFormContext();
  const { errors } = formState;

  return (
    <>
      {errors[name]?.message && (
        <FormHelperText error variant="filled">
          <Icon name="information-circle" color={errorColor} />
          <span className="error-text">{errors[name]?.message}</span>
        </FormHelperText>
      )}
    </>
  );
};
export default FormErrorMessageSupplier;
