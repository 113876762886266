import countries from 'data/countries';
import { SupportedCountry, TAX_RATE } from 'types/common.types';
import { ProductSize } from 'types/products';

export const getTaxRate = (country: SupportedCountry = SupportedCountry.USA) =>
  TAX_RATE[
    country === countries[SupportedCountry.ISRAEL]
      ? SupportedCountry.ISRAEL
      : SupportedCountry.USA
  ];

export const calculatePrice = (price: number, quantity: number) =>
  price * quantity;

export const halfOf = (num: number) => (num === 0 ? 0 : num / 2);

export const getSizeObject = (
  productSize?: ProductSize | string | undefined
) => {
  let size: ProductSize | undefined;
  if (typeof productSize === 'string') {
    try {
      const sizeJson = JSON.parse(productSize);
      if (Object.keys(sizeJson).includes('size')) {
        size = sizeJson.size;
      } else {
        size = sizeJson;
      }
    } catch (e) {
      // eslint-disable-next-line no-eval
      const myObject = (0, eval)(`(${productSize})`);
      size = myObject;
    }
  } else {
    size = productSize;
  }
  return size;
};
