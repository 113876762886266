import * as actions from 'store/actions/@client/cartInfo.actions';
import CartInfoType from 'store/constants/@client/cartInfo.constants';
import { Cart } from 'types/properties';

export interface CartInfoState {
  itemCount: number;
  cartData?: Cart;
}

const initialState: CartInfoState = {
  itemCount: 0,
};

const cartInfoReducer = (
  state: CartInfoState = initialState,
  action: actions.CartInfoActions
): CartInfoState => {
  switch (action.type) {
    case CartInfoType.SET_CART_ITEM_COUNT:
      return { ...state, itemCount: action.payload as number };
    case CartInfoType.SET_CART_DATA:
      return {
        ...state,
        itemCount: (action.payload as Cart).items.length,
        cartData: action.payload as Cart,
      };
    default:
      return state;
  }
};

export default cartInfoReducer;
