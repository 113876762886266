import * as React from 'react';
import styled from 'styled-components';

const SellYourProducts = () => {
  React.useEffect(() => {
    const typeformScript = document.createElement('script');
    typeformScript.src = '//embed.typeform.com/next/embed.js';
    document.body.appendChild(typeformScript);
  }, []);

  return (
    <Root>
      <div data-tf-widget="a7eZmSJy" />
    </Root>
  );
};

export default SellYourProducts;

const Root = styled.div`
  > div {
    width: 100%;
    height: 100%;
    position: absolute;
  }
`;
