import * as React from 'react';
import { ListItem } from 'components/Autocomplete';
import CheckboxItem from 'components/Checkbox/CheckboxItem';
import FlagIcon from 'components/FlagIcon';
import { ISOCode } from 'data/countries';
import { summarizeText } from 'utils/string.utils';
import type { Option } from 'components/Autocomplete/Autocomplete.config';

const CountryOption = (
  values: Option[] = [],
  optionTextLimit = 13,
  isMultiple = true
) => (props: React.HTMLAttributes<HTMLLIElement>, newValue: Option) => {
  const isShowToolTip = (flagName?: string) =>
    flagName?.length! > optionTextLimit;

  const value = (
    <FlagIcon
      ISO={newValue?.iso as ISOCode}
      name={summarizeText(newValue?.name.toString() || '', optionTextLimit)}
    />
  );

  return (
    <ListItem key={newValue?.iso} tabIndex={0} {...props}>
      {isMultiple ? (
        <CheckboxItem
          title={
            isShowToolTip(newValue?.name as string)
              ? newValue?.name.toString()
              : ''
          }
          value={value}
          checked={values.some(item => item?.name === newValue?.name)}
        />
      ) : (
        <>{value}</>
      )}
    </ListItem>
  );
};

export default CountryOption;
