import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import styled from 'styled-components';
import NotFound from 'components/@client/NotFound';
import { TABLET_RESOLUTION } from 'styles/sharedStyle';
import useIdeaKPIs from 'hooks/useIdeaKPIs';
import SectionTitle from 'components/@client/SectionTitle';
import Loader from 'components/Loader/Loader';
import { Supplier } from 'types/suppliers';
import MobileHeader from 'components/@client/MobileHeader';
import { IdeasDetails } from 'types/products';
import DetailComponent from './DetailComponent';
import IdeaList from '../ideas';
import ProductSection from './ProductSection';

const MobileIdea: React.FC<{ idea?: IdeasDetails }> = ({ idea }) => {
  const {
    onGetIdeaDetails,
    ideaDetails,
    isLoading,
    hasError,
    products,
    getIdeaProducts,
  } = useIdeaKPIs(idea);
  const [supplierData, setSupplierData] = React.useState<Set<string>>();

  React.useEffect(() => {
    if (!idea) onGetIdeaDetails();
    getIdeaProducts();
  }, [getIdeaProducts, idea, onGetIdeaDetails]);

  React.useEffect(() => {
    const dt = new Set<string>();
    ideaDetails?.products?.forEach(e => {
      dt.add(JSON.stringify(e.supplier as Supplier));
    });
    setSupplierData(dt);
  }, [ideaDetails]);
  return (
    <Container>
      <MobileHeader
        title={
          hasError ? (
            <FormattedMessage id={Message.IDEAS_NOT_FOUND} />
          ) : (
            <FormattedMessage id={Message.IDEAS_TITLE} />
          )
        }
      />
      {hasError && <NotFound />}
      {isLoading && <Loader />}
      {ideaDetails && (
        <>
          <DetailComponent
            imageList={
              supplierData &&
              Array.from(supplierData)
                .slice(0, 3)
                .map(e => JSON.parse(e) as Supplier)
            }
            ideaDetails={{ ...ideaDetails, products }}
            isLoading={isLoading}
          />
          {products && products?.length > 0 && (
            <>
              <IdeaContainer>
                <ProductSection
                  title={
                    <Title>
                      <FormattedMessage
                        id={Message.IDEAS_PRODUCT_TITLE}
                        values={{ count: '' }}
                      />
                    </Title>
                  }
                  product={products?.filter(a => !a.notForSale)}
                />
              </IdeaContainer>
            </>
          )}
          <SectionWrapper>
            <SectionTitle
              label={<FormattedMessage id={Message.IDEA_TAB_TITLE_TAB1} />}
            />
          </SectionWrapper>
          <IdeaContainer>
            <IdeaList isHeader={false} />
          </IdeaContainer>
        </>
      )}
    </Container>
  );
};

const Container = styled.section`
  margin-top: 0;
`;

const SectionWrapper = styled.div`
  margin-left: 24px;
  margin-right: 24px;
  .section-title-label {
    @media ${TABLET_RESOLUTION} {
      color: #424242;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 21px;
    }
  }

  hr {
    background: #a990dd;
    border: 2px solid #a990dd;
    height: 2px;
    @media ${TABLET_RESOLUTION} {
      margin-left: 8px;
    }
  }
`;
const IdeaContainer = styled.div`
  margin: auto 24px;
  margin-top: 32px;
`;

const Title = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  align-items: center;
  color: #282929;
`;
export default MobileIdea;
