import * as React from 'react';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import TextButton from 'components/Button/TextButton';
import { UserLogType } from 'types/common.types';
import { LogsAPI } from 'api/methods';
import { userLogData } from 'mockData/userLogData';

interface Props {
  itemId: string;
  isLoading?: boolean;
  maxQuantity: number;
  value: number;
  onClick?: (
    id: string,
    value: number,
    reason: 'decrement' | 'increment'
  ) => void;
  logData?: UserLogType;
  plusIconClass?: string;
  minusIconClass?: string;
  isMaterial?: boolean;
}

const QuantityCounter: React.FC<Props> = ({
  isLoading,
  itemId,
  maxQuantity,
  value = 1,
  onClick,
  logData,
  plusIconClass,
  minusIconClass,
  isMaterial,
}) => {
  return (
    <Container>
      <CustomTextButton
        className="text-button"
        aria-label="Decrement quantity counter"
        disabled={value <= 1 || maxQuantity === 0}
        type="button"
        onClick={() => {
          if (logData)
            LogsAPI.postUserLogs({
              ...logData,
              name: userLogData.eventName.quantityLess,
            } as UserLogType);
          onClick?.(itemId, value - 1, 'decrement');
        }}
      >
        {minusIconClass ? <span className={minusIconClass}>-</span> : '-'}
      </CustomTextButton>
      <Value active={value > 1 && maxQuantity !== 0}>{value}</Value>
      <CustomTextButton
        className="text-button"
        aria-label="Increment quantity counter"
        disabled={value >= maxQuantity}
        type="button"
        onClick={() => {
          if (logData)
            LogsAPI.postUserLogs({
              ...logData,
              name: userLogData.eventName.quantityAdd,
            } as UserLogType);

          onClick?.(itemId, value + 1, 'increment');
        }}
      >
        {plusIconClass ? <span className={plusIconClass}>+</span> : '+'}
      </CustomTextButton>
      {isMaterial && (
        <span>
          m<sup>2</sup>
        </span>
      )}
    </Container>
  );
};

export default QuantityCounter;

const Container = styled.div.attrs({ className: 'quantity-counter' })`
  display: flex;
  align-items: center;
  padding: 8px 0;

  border-radius: 6px;
  max-width: 140px;

  & .text-button {
    border: none;
    background: transparent;
    font-size: 1.375rem;
    font-weight: 500;
    transition: all 0.3s;
    height: 100%;

    text-decoration: none;
    line-height: 1;
    letter-spacing: unset;

    &:hover {
      & span {
        cursor: pointer;
      }
      text-decoration: none;
      cursor: pointer;
    }

    &:first-of-type {
      ${rtl`
        padding: 0 15px 0 15px;
      `}
    }

    &:last-of-type {
      ${rtl`
        padding: 0 15px 0 15px;
      `}
    }
  }
`;

const Value = styled.span<{ active?: boolean }>`
  cursor: default;

  color: ${({ active = true }) => (active ? 'inherit' : 'grey')};
`;

const CustomTextButton = styled(TextButton)`
  .MuiButton-label {
    border: 1px solid ${({ disabled }) => (disabled ? 'grey' : 'black')};
    border-radius: 30px;
    height: 20px;
    width: 20px;
  }
`;
