export const filterSortptions = Object.values({
  LOW: 'Popolarity',
  POPULAR: 'Newest',
  LOWTOHIGH: 'Prica low to high',
  HIGHTOLOW: 'Prica high to low',
});

export enum SkinSortType {
  NEW = 'new',
  POPULAR = 'popular',
  LOWTOHIGH = 'priceLow',
  HIGHTOLOW = 'priceHigh',
}

export const filterDesignStyleOptions = [
  {
    label: 'Classic',
    value: 'Classic',
  },
  {
    label: 'Modern',
    value: 'Modern',
  },
  {
    label: 'Industrial',
    value: 'Industrial',
  },
  {
    label: 'Asian',
    value: 'Asian',
  },
  {
    label: 'Contemporary',
    value: 'Contemporary',
  },
  {
    label: 'Eclectic',
    value: 'Eclectic',
  },
  {
    label: 'Mediterranean',
    value: 'Mediterranean',
  },
  {
    label: 'Midcentury',
    value: 'Midcentury',
  },
  {
    label: 'Rustic',
    value: 'Rustic',
  },
  {
    label: 'Scandinavian',
    value: 'Scandinavian',
  },
  {
    label: 'Nordic',
    value: 'Nordic',
  },
];

export const filterDesignByOptions = [
  {
    label: 'Classic',
    value: 'Classic',
  },
  {
    label: 'Modern',
    value: 'Modern',
  },
  {
    label: 'Industrial',
    value: 'Industrial',
  },
  {
    label: 'Asian',
    value: 'Asian',
  },
  {
    label: 'Contemporary',
    value: 'Contemporary',
  },
  {
    label: 'Eclectic',
    value: 'Eclectic',
  },
  {
    label: 'Mediterranean',
    value: 'Mediterranean',
  },
  {
    label: 'Midcentury',
    value: 'Midcentury',
  },
  {
    label: 'Rustic',
    value: 'Rustic',
  },
  {
    label: 'Scandinavian',
    value: 'Scandinavian',
  },
  {
    label: 'Nordic',
    value: 'Nordic',
  },
];

export const filterBudgetOptions = [
  {
    label: '0-10K',
    value: '0-10K',
  },
  {
    label: '10K - 25K',
    value: '10K - 25K',
  },
  {
    label: '25K - 100K',
    value: '25000 - 10,00,000',
  },
  {
    label: '100K +',
    value: '10,00,000 - +',
  },
];

export const filterPopularOptions = [
  {
    label: 'Modern',
    value: 'Modern',
  },
  {
    label: 'Country',
    value: 'Country',
  },
  {
    label: 'Classic',
    value: 'Classic',
  },
  {
    label: 'Scandinavian',
    value: 'Scandinavian',
  },
];

export const filterRoomOptions = [
  {
    label: 'Modern',
    value: 'Modern',
  },
  {
    label: 'Country',
    value: 'Country',
  },
  {
    label: 'Classic',
    value: 'Classic',
  },
  {
    label: 'Scandinavian',
    value: 'Scandinavian',
  },
];
