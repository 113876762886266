enum PropertiesType {
  GET_PROPERTIES_DATA_REQUEST = 'properties-management/getPropertiesDataRequest',
  GET_PROPERTIES_DATA_REJECTED = 'properties-management/getPropertiesDataRejected',
  GET_PROPERTIES_DATA_FULFILLED = 'properties-management/getPropertiesDataFulfilled',
  GET_UNITS_DATA_REQUEST = 'properties-management/getUnitsDataRequest',
  GET_UNITS_DATA_REJECTED = 'properties-management/getUnitsDataRejected',
  GET_UNITS_DATA_FULFILLED = 'properties-management/getUnitsDataFulfilled',
  GET_PUBLISHED_PROPERTIES_DATA_REQUEST = 'properties-management/getPublishedPropertiesDataRequest',
  GET_PUBLISHED_PROPERTIES_DATA_REJECTED = 'properties-management/getPublishedPropertiesDataRejected',
  GET_PUBLISHED_PROPERTIES_DATA_FULFILLED = 'properties-management/getPublishedPropertiesDataFulfilled',
  GET_ARCHIVED_PROPERTIES_DATA_REQUEST = 'properties-management/getArchivededPropertiesDataRequest',
  GET_ARCHIVED_PROPERTIES_DATA_REJECTED = 'properties-management/geArchivedhedPropertiesDataRejected',
  GET_ARCHIVED_PROPERTIES_DATA_FULFILLED = 'properties-management/gArchivedshedPropertiesDataFulfilled',
}

export default PropertiesType;
