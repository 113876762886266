import * as React from 'react';
import { map } from 'utils/lodash.utils';

const useImagePreview = (
  files: FileList | null,
  fieldName?: string,
  initialValue: string = '',
  multiple?: boolean
) => {
  const [preview, setPreview] = React.useState<string>(initialValue);
  const [selectedFiles, setSelectedFiles] = React.useState<File[] | []>([]);
  const file = React.useMemo(() => new FileReader(), []);

  const clearPreview = React.useCallback(() => {
    setPreview('');
    setSelectedFiles([]);
  }, []);

  React.useEffect(() => {
    if (initialValue) setPreview(initialValue);

    if (multiple && files) {
      map(files, data => {
        setSelectedFiles(prev => [...prev, data]);
      });
    }
    if (files?.[0]) file.readAsDataURL(files[0]);

    file.onload = () => {
      setPreview((file?.result as string) ?? '');
    };

    return () => clearPreview();
  }, [files, fieldName, file, initialValue, clearPreview, multiple]);

  const onFileChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement> | undefined) => {
      if (event?.target.files?.[0]) {
        file.readAsDataURL(event?.target.files[0]);
      }
    },
    [file]
  );

  return { selectedFiles, preview, clearPreview, onFileChange };
};

export default useImagePreview;
