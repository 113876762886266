import * as React from 'react';
import { Link } from 'react-router-dom';
import Skeleton from '@material-ui/core/Skeleton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import styled, { css } from 'styled-components';
import rtl from 'styled-components-rtl';
import { OrderColors, OrderStatus } from 'types/orders';
import { MOBILE_RESOLUTION } from 'styles/sharedStyle';
import OrderHeading from './OrderHeading';

const OrdersSkeletonItem = () => {
  const isMobile = useMediaQuery(MOBILE_RESOLUTION);
  return (
    <CardContainer className="order">
      <FlexCardContainer>
        <OrderCard as="div" className="order-card">
          <ArrowWrapper>
            <Skeleton
              variant="circular"
              height={isMobile ? 20 : 40}
              width={isMobile ? 20 : 40}
            />
          </ArrowWrapper>
          <OrderHeading isLoading />
          <Products>
            {Array.from({ length: 3 }).map((_, idx) => (
              <ProductContainer key={idx.toString()}>
                <Skeleton variant="rectangular" width="%33" height={150} />
              </ProductContainer>
            ))}
          </Products>
          <div className="description-box">
            <Skeleton variant="text" width={250} />
          </div>
          <div className="description-box">
            <Skeleton variant="text" width={330} />
          </div>
        </OrderCard>
      </FlexCardContainer>
      <Border className="border bottom" />
    </CardContainer>
  );
};

export { OrderHeading };
export default OrdersSkeletonItem;

const CardContainer = styled.li`
  width: 50%;
  @media ${MOBILE_RESOLUTION} {
    width: 100%;
  }
  &:last-of-type .border {
    border: none;
  }
`;

const FlexCardContainer = styled.div`
  display: flex;
`;

const OrderCard = styled(Link)<{ status?: OrderStatus }>`
  text-decoration: none;
  width: 100%;
  position: relative;
  height: 396px;
  background: #ffffff;
  box-shadow: 0 -3px 25px 5px rgba(0, 88, 171, 0.07);
  border-radius: 16px;
  padding: 28px 24px;
  min-width: 550px;
  @media ${MOBILE_RESOLUTION} {
    height: 267px;
    margin-bottom: 20px;
    padding: 18px 14px;
    min-width: inherit;
  }

  & .order-title {
    font-weight: 500;
    font-size: 20px;
    margin: 0;
    color: ${({ theme }) => theme.colors.primary.clientTextColor};
  }
  & .order-date {
    font-weight: 500;
    font-size: 16px;
    color: #797676;
    margin-bottom: 8px;
  }
  & .status-indicator {
    border-radius: 10px;
    width: 100%;
    height: 8px;
    margin: 20px 0;
    background-color: ${({ status = OrderStatus.received }) =>
      OrderColors[status]};

    @media ${MOBILE_RESOLUTION} {
      margin: 10px 0 20px;
    }
  }
  & .description-box {
    display: flex;
    & .description {
      width: 40%;
      color: ${({ theme }) => theme.colors.primary.clientTextColor};
    }
  }
`;
const Products = styled.div`
  display: flex;
  max-height: 40%;
  width: 100%;
  margin-bottom: 50px;

  @media ${MOBILE_RESOLUTION} {
    margin-bottom: 20px;
    max-height: 30%;
  }
`;
const ProductContainer = styled.div<{ counter?: number }>`
  position: relative;
  width: 33%;
  background: #efefef;
  border-radius: 25px;
  &:not(:last-of-type) {
    ${rtl`
      margin-right: 35px;
    `}
  }
  @media ${MOBILE_RESOLUTION} {
    &:not(:last-of-type) {
      ${rtl`
        margin-right: 16px;
      `}
    }
  }
  overflow: hidden;
  & img {
    height: 100%;
    width: 100%;
  }
  ${({ counter = 0 }) =>
    counter > 0 &&
    css`
      &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(63, 63, 63, 0.51);
        color: #ffffff;
        font-weight: 500;
        font-size: 20px;
        border-radius: 25px;
        content: '+${counter}';
        display: flex;
        justify-content: center;
        align-items: center;
      }
    `};
`;
const ArrowWrapper = styled.span`
  position: absolute;
  top: 20px;
  right: 20px;
  transition: opacity 0.125s ease-in;
  &:hover {
    opacity: 0.8;
  }
`;
const Border = styled.div`
  &.right {
    border-right: 1px solid #dfd9d8;
    height: 230px;
    margin: auto 40px;
  }
  &.bottom {
    width: 90%;
    margin: 40px auto;
    border-bottom: 1px solid #dfd9d8;
    @media ${MOBILE_RESOLUTION} {
      margin: 0 auto 20px;
    }
  }
`;
