import * as React from 'react';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import no_image_placeholder from 'assets/images/client-products/no_image.png';
import { TextContent, GenericCarouselImage } from 'styles/sharedStyle';
import { summarizeText } from 'utils/string.utils';
import { Expert } from 'pages/appClient/home/mock/experts';

interface Props {
  item: Expert;
  onClick?: VoidFunction;
}

const ExpertsItem: React.FC<Props> = ({ item, onClick }) => {
  return (
    <ListItem onClick={onClick}>
      <Image imgUrl={item.image ?? no_image_placeholder} />
      <TextWrapper>
        <TextContent as="div" size={18} weight={700}>
          {summarizeText(item.name, 30)}
        </TextContent>
        <TextContent
          className="expert-description"
          as="div"
          size={14}
          weight={400}
          lineHeight={15}
        >
          {item.description}
        </TextContent>
      </TextWrapper>
    </ListItem>
  );
};

export default ExpertsItem;

const ListItem = styled.li`
  position: relative;
  cursor: pointer;
`;

const Image = styled(GenericCarouselImage)`
  width: 127px;
  height: 195px;

  @media (min-width: 800px) {
    width: 305px;
    height: 309px;
  }
`;

const TextWrapper = styled.div`
  ${rtl`
    margin: 0px 0px 6px 15px;
  `}

  .expert-description {
    width: 180px;
    margin-top: 5px;
  }
`;
