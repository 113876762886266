import * as React from 'react';
import Container from 'components/Modal/Container';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import Button from 'components/@client/Button';
import Website from 'assets/@client/tour/website.png';
import Devices from 'assets/@client/tour/devices.png';
import SafeImg from 'components/SafeImg/SafeImg';
import { useRouter } from 'hooks';
import rtl from 'styled-components-rtl';
import { ClientRouter } from 'router/routes';
import { MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';
import { ReactComponent as Checkmark } from 'components/@client/form-components/pick.svg';

const TourDeviceNotSupportedModal: React.FC = () => {
  const { query, location, history } = useRouter<{
    isRedirectFromTour: boolean;
  }>();
  return (
    <MainContainer
      open={query.isRedirectFromTour}
      onClose={() => {
        history.replace(location.pathname);
      }}
    >
      <ParentDiv>
        <DeviceNotSupportedHeader>
          <h3>
            <FormattedMessage id={Message.TOUR_WE_ARE_SORRY} />
          </h3>
          <h4>
            <FormattedMessage id={Message.TOUR_DEVICE_DENIED_HEADING} />
          </h4>
        </DeviceNotSupportedHeader>

        <SupportedDeviceTitle>
          <h4>
            <FormattedMessage id={Message.TOUR_SUPPORTED_DEVICE_TITLE} />
          </h4>
        </SupportedDeviceTitle>
        <SupportedListContainer>
          <div>
            <ImageContainer>
              <CustomImg src={Devices} />
            </ImageContainer>
          </div>
          <div>
            <ImageContainer>
              <CustomImg src={Website} />
            </ImageContainer>
          </div>
        </SupportedListContainer>

        <SupportedListTextContainer>
          <div>
            <CheckMarkContainer>
              <Checkmark className="checkmark" />
            </CheckMarkContainer>
            <h4>
              <FormattedMessage id={Message.TOUR_SUPPORTED_DEVICES} />
            </h4>
          </div>
          <div>
            <CheckMarkContainer>
              <Checkmark className="checkmark" />
            </CheckMarkContainer>
            <h4>
              <FormattedMessage id={Message.TOUR_SUPPORTED_BROWSER} />
            </h4>
          </div>
        </SupportedListTextContainer>
        <BackButtonDiv>
          <Button
            className="action-button"
            onClick={() => {
              history.replace(ClientRouter.APP);
            }}
          >
            <FormattedMessage id={Message.UPLOAD_BUTTON_BACK_TO_HOME} />
          </Button>
        </BackButtonDiv>
      </ParentDiv>
    </MainContainer>
  );
};

export default TourDeviceNotSupportedModal;

const MainContainer = styled(Container)`
  .MuiPaper-root {
    max-width: 100%;
  }
`;

const ParentDiv = styled.div``;

const DeviceNotSupportedHeader = styled.div`
  text-align: center;
  & > h4 {
    font-size: 2rem;
    margin: 5px 0;
    font-weight: 500;
    @media ${MOBILE_TABLET_RESOLUTION} {
      font-size: 1rem;
    }
  }
  & > h3 {
    font-size: 2.5rem;
    margin: 5px 0;
    font-weight: 600;

    @media ${MOBILE_TABLET_RESOLUTION} {
      font-size: 1.5rem;
    }
  }
`;

const BackButtonDiv = styled.div`
  text-align: center;

  & > button {
    font-size: 1.5rem;
    @media ${MOBILE_TABLET_RESOLUTION} {
      font-size: 1rem;
    }
    font-weight: 400;
    padding: 0 20px;

    height: inherit !important;
    border-radius: 20px !important;
  }
`;

const SupportedDeviceTitle = styled.div`
  text-align: center;
  & > h4 {
    font-size: 1.5rem;
    @media ${MOBILE_TABLET_RESOLUTION} {
      font-size: 1rem;
    }
    color: ${({ theme }) => theme.colors.primary.lightMobile};
  }
`;

const ImageContainer = styled.div`
  max-height: 200px;
  max-weight: 200px;
  @media ${MOBILE_TABLET_RESOLUTION} {
    max-height: 60px;
    max-weight: 60px;
  }
`;

const CustomImg = styled(SafeImg)`
  margin: auto;
  max-height: 200px;
  max-weight: 200px;
  @media ${MOBILE_TABLET_RESOLUTION} {
    max-height: 60px;
    max-weight: 60px;
  }
`;

const SupportedListContainer = styled.div`
  display: flex;

  max-height: 400px;
  justify-content: space-around;
  text-align: center;
  h4 {
    font-size: 1rem;
  }
`;

const SupportedListTextContainer = styled.div`
  & > div {
    display: flex;
    margin-top: 10px;
  }
  display: flex;
  @media ${MOBILE_TABLET_RESOLUTION} {
    display: block;
    margin: 20px 0;
  }
  max-height: 400px;

  justify-content: space-around;
  text-align: center;
  h4 {
    @media ${MOBILE_TABLET_RESOLUTION} {
    }
    margin: 5px 0;
    font-size: 1rem;
  }
`;

const CheckMarkContainer = styled.div`
  width: 25px;
  height: 25px;
  border: 1px solid white;
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.primary.lightMobile};
  padding: 3px;
  ${rtl`
    margin: 0 5px 0 10px;
  `}
`;
