import TeamUserDataType from 'store/constants/user-management/teamPatricia.constants';
import * as Payload from 'store/types/user-management/teamPatricia.types';

export const getUsersDataRequest = (
  payload: Payload.GetUsersDataRequestPayload
) => ({
  type: TeamUserDataType.GET_USERS_DATA_REQUEST,
  payload,
});

export const getUsersDataFulfilled = (
  payload: Payload.GetUsersDataFulfilledPayload
) => ({
  type: TeamUserDataType.GET_USERS_DATA_FULFILLED,
  payload,
});

export const getUsersDataRejected = (
  payload: Payload.GetUsersDataRejectedPayload
) => ({
  type: TeamUserDataType.GET_USERS_DATA_REJECTED,
  payload,
});

export const getRolesDataRequest = (
  payload: Payload.GetRolesDataRequestPayload
) => ({
  type: TeamUserDataType.GET_ROLES_DATA_REQUEST,
  payload,
});

export const getRolesDataFulfilled = (
  payload: Payload.GetRolesDataFulfilledPayload
) => ({
  type: TeamUserDataType.GET_ROLES_DATA_FULFILLED,
  payload,
});

export const getRolesDataRejected = (
  payload: Payload.GetUsersDataRejectedPayload
) => ({
  type: TeamUserDataType.GET_USERS_DATA_REJECTED,
  payload,
});

export const addUserDataRequest = (
  payload: Payload.AddUserDataRequestPayload
) => ({
  type: TeamUserDataType.ADD_USER_DATA_REQUEST,
  payload,
});

export const addUserDataFulfilled = (
  payload?: Payload.AddUserDataFulfilledPayload
) => ({
  type: TeamUserDataType.ADD_USER_DATA_FULFILLED,
  payload,
});

export const addUserDataRejected = (
  payload: Payload.AddUserDataRejectedPayload
) => ({
  type: TeamUserDataType.ADD_USER_DATA_REJECTED,
  payload,
});

export type TeamPatriciaAction = ReturnType<
  | typeof getUsersDataRequest
  | typeof getUsersDataFulfilled
  | typeof getUsersDataRejected
  | typeof getRolesDataRequest
  | typeof getRolesDataFulfilled
  | typeof getRolesDataRejected
  | typeof addUserDataRequest
  | typeof addUserDataFulfilled
  | typeof addUserDataRejected
>;
