import * as React from 'react';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuthentication, useLocalStorage, useRouter } from 'hooks';
import { registerInitialValues } from 'pages/auth/Auth.config';
import { RegisterPayload } from 'store/types/authentication.types';
import { prefixPhoneNumber } from 'utils/string.utils';
import { registerSchema, registerSupplierSchema } from 'utils/validation.utils';
import { useOnboardingState } from 'context/onboardingContext';
import { LocalCartProduct } from 'types/products';
import { CART_KEY } from 'constants/localstorage.constants';
import { isEmpty } from 'utils/lodash.utils';
import { ClientRouter } from 'router/routes';

const FormWrapper: React.FC<{ loginType?: string }> = ({
  children,
  loginType,
}) => {
  const {
    answers,
    selectedFloor,
    questions,
    maxPrice,
    rooms,
    isScan,
    isPremadeProperty,
  } = useOnboardingState();
  const { onboard, redirectOnBoard } = useAuthentication();
  const { query } = useRouter<{ redirect: string }>();
  const methods = useForm<RegisterPayload>({
    resolver: yupResolver(
      loginType === 'SUPPLIER' ? registerSupplierSchema : registerSchema
    ),
    defaultValues: registerInitialValues,
    mode: 'all',
  });

  const [cartState] = useLocalStorage<LocalCartProduct[]>(CART_KEY, []);
  let tempAnswers: Record<number, number | null> = {};
  Object.keys(answers).forEach(e => {
    tempAnswers = {
      ...tempAnswers,
      [e]:
        questions.find(q => q.id.toString() === e)?.answers[
          Math.floor(Math.random() * 3)
        ]?.id ?? null,
    };
  });
  const finalAnswers = Object.values(answers).find(Boolean)
    ? answers
    : tempAnswers;
  const { handleSubmit } = methods;
  const propertyDetails = isPremadeProperty
    ? { preMadeProperty: selectedFloor?.id }
    : { propertyFile: selectedFloor?.id };
  const onSubmit = ({ phoneNumber, ...payload }: RegisterPayload) => {
    if (loginType === 'SUPPLIER' && phoneNumber) {
      return redirectOnBoard({
        redirect: ClientRouter.SUPPLIER_LOGIN_WELCOME,
        registerPayload: {
          ...payload,
          phoneNumber: prefixPhoneNumber(phoneNumber),
        },
      });
    }
    if (query.redirect) {
      if (phoneNumber) {
        return redirectOnBoard({
          redirect: ClientRouter.CART,
          registerPayload: {
            ...payload,
            phoneNumber: prefixPhoneNumber(phoneNumber),
            ...propertyDetails,
            answers: finalAnswers,
            quiz: questions?.find(e => e?.quiz)?.quiz,
            cartData: cartState,
            uploadedProperty: !isEmpty(answers),
            maxPrice,
            rooms: rooms.map(x => x.id),
            isScan,
          },
        });
      }
      return redirectOnBoard({
        redirect: ClientRouter.CART,
        registerPayload: {
          ...payload,
          ...propertyDetails,
          answers: finalAnswers,
          quiz: questions?.find(e => e?.quiz)?.quiz,
          cartData: cartState,
          uploadedProperty: !isEmpty(answers),
          maxPrice,
          rooms: rooms.map(x => x.id),
          isScan,
        },
      });
    }
    if (phoneNumber) {
      return onboard({
        ...payload,
        phoneNumber: prefixPhoneNumber(phoneNumber),
        ...propertyDetails,
        answers: finalAnswers,
        quiz: questions?.find(e => e?.quiz)?.quiz,
        cartData: cartState,
        uploadedProperty: !isEmpty(answers),
        maxPrice,
        rooms: rooms.map(x => x.id),
        isScan,
      });
    }
    return onboard({
      ...payload,
      ...propertyDetails,
      answers: finalAnswers,
      quiz: questions?.find(e => e?.quiz)?.quiz,
      cartData: cartState,
      uploadedProperty: !isEmpty(answers),
      maxPrice,
      rooms: rooms.map(x => x.id),
      isScan,
    });
  };
  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>{children}</Form>
    </FormProvider>
  );
};

export default FormWrapper;

const Form = styled.form.attrs({
  className: 'form-container',
})`
  margin-top: 40px;
  @media (min-width: 1150px) {
    margin-top: 20px;
  }

  .phoneNumber {
    margin: -6px 0 24px;
    & .flag-dropdown,
    & .flag-dropdown.open {
      height: 48px;
    }

    & .form-control {
      height: 48px;
    }
  }
  .country-list {
    & .search {
      & .search-box {
        height: 48px;
      }
    }
  }
  && .submit-btn {
    @media (min-width: 1150px) {
      margin-top: 0;
      width: unset;
      ${rtl`
        margin-left: auto;
      `}
    }
  }
  & .divider-wrapper {
    margin: 14px auto;
  }

  & .anchor-link {
    color: #2277cc;
    font-weight: 500;
  }

  & .actions-container {
    @media (min-height: 800px) {
      margin-top: 0;
    }
    @media (min-width: 1150px) {
      margin-top: 15px;
    }
  }
`;
