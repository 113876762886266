import { createStyles, withStyles } from '@material-ui/core';
import { Tooltip } from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';

// interface Props {
//   forceLogo?: boolean;
//   label?: string | React.ReactNode;
//   withLoginLink?: boolean;
//   dense?: boolean;
// }

const Stepper: React.FC = () => {
  return (
    <>
      <Container className="container">
        <div className="stepper-wrapper">
          <div className="stepper-item completed">
            <StyledTooltip
              title={
                <div className="tooltip-content">
                  <span style={{ fontWeight: 500, color: '#282929' }}>
                    Step 1
                  </span>
                  <p
                    style={{
                      marginTop: 4,
                      marginBottom: 0,
                      fontWeight: 400,
                      color: '#696969',
                    }}
                  >
                    Floor Plan Select
                  </p>
                </div>
              }
              key={1}
              placement="top"
              arrow
              classes={{
                popper: 'idea-tooltip-popper',
              }}
              hidden={false}
              open={true}
            >
              <div className="step-counter">
                <div className="fill" />
              </div>
            </StyledTooltip>
          </div>
          <div className="stepper-item completed">
            <div className="step-counter">{/* <div className="fill" /> */}</div>
          </div>
          <div className="stepper-item completed">
            <div className="step-counter">{/* <div className="fill" /> */}</div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Stepper;

const StyledTooltip = withStyles(() =>
  createStyles({
    tooltip: {
      backgroundColor: '#fff !important',
      borderRadius: '10px !important',
      padding: '8px !important',
      maxWidth: 'unset !important',
      border: '1.5px solid #11516D !important',
      display: 'flex !important',
      justifyContent: 'center !important',
      alignItems: 'center !important',
      color: '#282929 !important',
      textAlign: 'center',
      fontSize: '14px !important',
      lineHeight: '16px !important',
      // flexDirection: 'column !important',
    },
    popper: {
      // zIndex: '5 !important',
    },
    arrow: {
      border: 'red !important',
      stroke: '#11516D !important',
    },
  })
)(Tooltip);

const Container = styled.div`
  width: 100%;
  margin-top: 100px;

  .progressbar {
    counter-reset: step;
  }

  .progressbar li {
    list-style-type: none;
    float: left;
    width: 33.33%;
    position: relative;
    text-align: center;
    font-weight: 600;
  }

  .progressbar li:before {
    /* CSS for creating steper block before the li item*/
    content: counter(step);
    counter-increment: step;
    height: 30px;
    width: 30px;
    line-height: 30px;
    border: 2px solid #ddd;
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    background-color: white;
  }
  .progressbar li:after {
    /* CSS for creating horizontal line*/
    content: ’’;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: red;
    top: 15px;
    left: -50%;
    z-index: -1;
  }
  .progressbar li:first-child:after {
    content: none;
  }
  .progressbar li.active {
    color: #27ae60;
  }
  .progressbar li.active:before {
    border-color: #27ae60;
  }
  .progressbar li.active + li:after {
    background-color: #27ae60;
  }

  .c-stepper {
    display: flex;
    flex-wrap: wrap;
  }

  .c-stepper__item {
    flex: 1;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .c-stepper__item:before {
    --size: 3rem;
    content: '';
    position: relative;
    z-index: 1;
    display: block;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    margin: 1rem auto 0;
  }
  .c-stepper__item:not(:last-child):after {
    content: '';
    position: relative;
    top: 1.5rem;
    left: 50%;
    height: 2px;
    background-color: #e0e0e0;
    order: -1;
  }
  //new wrapper
  .stepper-wrapper {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    /* margin-bottom: 20px; */
    /* padding-top: 67px; */
    padding: 67px 350px 0px;
  }
  .stepper-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }

  .stepper-item::before {
    position: absolute;
    content: '';
    border-bottom: 2px solid #7a9aa8;
    width: 100%;
    top: 12px;
    left: -50%;
    z-index: 2;
  }

  .stepper-item::after {
    position: absolute;
    content: '';
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 10px;
    left: 50%;
    z-index: 2;
  }

  .stepper-item .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #fff;
    margin-bottom: 6px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border: 2px solid #7a9aa8;

    & .fill {
      width: 10px;
      height: 10px;
      background: #11516d;
      transform: matrix(1, 0, 0, -1, 0, 0);
      border-radius: 10px;
    }
    & .fill > .tooltip {
      width: 120px;
      background-color: #555;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px 0;
      position: absolute;
      z-index: 1;
      bottom: 125%;
      left: 50%;
      margin-left: -60px;
      opacity: 0;
      transition: opacity 0.3s;
    }
  }

  .stepper-item.active {
    font-weight: bold;
    border: 0;
  }

  .stepper-item.completed .step-counter {
    /* background-color: #4bb543; */
  }

  .stepper-item.completed::after {
    position: absolute;
    content: '';
    border-bottom: 2px solid #7a9aa8;
    width: 100%;
    top: 10px;
    left: 50%;
    z-index: 3;
    height: 4px;
  }

  .stepper-item:first-child::before {
    content: none;
  }
  .stepper-item:last-child::after {
    content: none;
  }
`;
