import { all, call, put, debounce } from 'redux-saga/effects';
import { OrdersAPI } from 'api/methods';
import { showNotification } from 'store/actions/notifications.actions';
import OrdersDataType from 'store/constants/user-management/orders.constants';
import * as OrderActions from 'store/actions/user-management/orders.actions';
import * as Payload from 'store/types/user-management/orders.types';
import { newSortByMap } from 'utils/transform.utils';
import { removeEmptyValues } from 'utils/common.utils';
import { FILTER_DATE_FORMAT, formatDate } from 'utils/dates.utils';

function* errorHandler(
  e: Error,
  type: OrdersDataType,
  rejectAction: (payload: {
    error: string;
  }) => { type: OrdersDataType; payload: { error: string } }
) {
  const error = e.message || 'ERROR: Cannot refresh Data source';
  yield put(showNotification({ key: type, message: error, severity: 'error' }));
  yield put(rejectAction({ error }));
}

const sortOrdersMap = {
  client: 'client_name',
  clientId: 'client_id',
  createdAt: 'created_at',
  supplier: 'supplier',
  shippingAddress: 'shipping_address',
  status: 'status',
  amount: 'amount',
};

function* getOrdersDataRequest({
  payload,
  type,
}: ReturnType<typeof OrderActions.getOrdersDataRequest>) {
  try {
    const {
      page = 0,
      size = 10,
      search,
      status,
      supplier,
      dateRange,
      sortBy,
    } = payload;
    const params = removeEmptyValues({
      page: page + 1,
      size,
      search,
      status,
      supplier: (supplier as { name: string; id: string }[])?.map(
        ({ id }) => id
      ),
      from_date: formatDate(dateRange?.startDate, FILTER_DATE_FORMAT, ''),
      to_date: formatDate(dateRange?.endDate, FILTER_DATE_FORMAT, ''),
      ...newSortByMap<typeof sortOrdersMap>(sortOrdersMap, sortBy),
    }) as Payload.GetOrdersDataRequestPayload;

    const {
      results: orders,
      count,
    }: Payload.GetOrdersDataResponsePayload = yield call(OrdersAPI.getOrders, {
      params,
    });
    const pageCount = Math.ceil(count / size);
    yield put(OrderActions.getOrdersDataFulfilled({ orders, pageCount }));
  } catch (e) {
    yield call(errorHandler, e, type, OrderActions.getOrdersDataRejected);
  }
}

function* ordersSaga() {
  yield all([
    debounce(500, OrdersDataType.GET_ORDERS_DATA_REQUEST, getOrdersDataRequest),
  ]);
}

export default ordersSaga;
