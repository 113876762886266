import countries, { ISOCode } from 'data/countries';
import { IAddress } from 'types/common.types';

export const stringifyAddress = (
  address?: IAddress | null,
  excludeCountry = false
): string | null => {
  if (!address) return null;
  const {
    street,
    apartmentNumber,
    house,
    floor,
    zipCode,
    city,
    country,
    state,
    address_line_1,
    address_line_2,
  } = address;
  return isIsrael(country)
    ? [
        `${house} ${street} St.`,
        apartmentNumber,
        floor ? `${floor} Floor` : null,
        zipCode,
        city,
        state,
        excludeCountry ? null : country,
      ]
        .filter(Boolean)
        .join(', ')
    : [
        address_line_1,
        address_line_2 ?? '',
        zipCode,
        city,
        state,
        excludeCountry ? null : country,
      ]
        .filter(Boolean)
        .join(', ');
};

export const isUSA = (iso: string) =>
  countries[iso as ISOCode] === countries.US || iso === 'US';

export const isIsrael = (iso: string) =>
  countries[iso as ISOCode] === countries.IL || iso === 'IL';

export const getCountryName = (code: ISOCode) => countries[code];
