import * as React from 'react';
import styled from 'styled-components';
import type { ISOCode, Country } from 'data/countries';
import './flags.css';

interface Props {
  ISO: ISOCode;
  name?: Country;
}

const FlagIcon: React.FC<Props> = ({ ISO, name }) => {
  const className = React.useMemo(() => {
    const [letterA, letterB] = ISO.toLowerCase().split('');
    return `flag-sprite flag-${letterA} flag-_${letterB}`;
  }, [ISO]);
  return (
    <Container>
      <Flag className={className} />
      {name && <span>{name}</span>}
    </Container>
  );
};

export default FlagIcon;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: auto;
  color: ${({ theme }) => theme.colors.primary.dark};
`;

const Flag = styled.span`
  border-radius: 3px;
`;
