import { AuthAPI, ClientAPI, ClientsAPI, LogsAPI } from 'api/methods';
import { setHeaderToken } from 'api/client';
import { replace, push } from 'connected-react-router';
import { all, put, takeLatest, call, select } from 'redux-saga/effects';
import {
  ManagementRouter,
  AuthRoute,
  ClientRouter,
  SupplierRouter,
} from 'router/routes';
import {
  AUTH_CONFIG_ROOT_KEY,
  CART_KEY,
  GUEST_TOKEN,
} from 'constants/localstorage.constants';
import { Type } from 'constants/config';
import { isEmpty } from 'utils/lodash.utils';
import * as Action from 'store/actions/authentication.actions';
import * as Notifications from 'store/actions/notifications.actions';
import { Authentication } from 'store/constants/authentication.constants';
import * as Payload from 'store/types/authentication.types';
import {
  callVuplex,
  getLocalStorage,
  isSupportedCountry,
  saveLocalStorage,
} from 'utils/common.utils';
import { clearRecentProperty } from 'store/actions/@client/userInfo.actions';
import { sessionSelector } from 'store/selectors/session.selector';
import { SessionState } from 'store/reducers/session.reducer';
import { userLogData } from 'mockData/userLogData';
import { Client } from 'types/clients';

const getAppInterface = (userType: Type): string =>
  ({
    TEAM_PATRICIA: ManagementRouter.DASHBOARD,
    SUPPLIER: SupplierRouter.DASHBOARD,
    CLIENT: ClientRouter.APP,
    SELLER_CLIENT: ClientRouter.APP,
    SELLER: ClientRouter.APP,
    PROFESSIONAL: ClientRouter.APP,
  }[userType]);

function* loginError(error: Error, key: Authentication) {
  yield call(setHeaderToken);
  yield put(Action.loginRejected(error));
  yield put(
    Notifications.showNotification({
      key,
      message: (error as Error).message,
      severity: 'error',
    })
  );
}

function* loginRequest(
  { payload, type: key }: Action.AuthenticationAction,
  account: Payload.AccountPayload,
  redirect?: string
) {
  const { country }: SessionState = yield select(sessionSelector);
  if (account.type === Type.CLIENT && !isSupportedCountry(country))
    throw new Error('Unable to login with the provided credentials');

  // save user data to locale storage
  const { onboardingFields, ...loginData } = payload as Payload.LoginPayload;
  const savedData = (loginData as Payload.LoginPayload).rememberUser
    ? account
    : {};
  yield call(saveLocalStorage, AUTH_CONFIG_ROOT_KEY, savedData);
  yield call(saveLocalStorage, CART_KEY, []);
  yield call(saveLocalStorage, GUEST_TOKEN, null);
  yield call(setHeaderToken, account?.token);
  yield call(LogsAPI.heapIdentify, account.id.toString());
  yield call(LogsAPI.heapAddUserProperties, {
    first_name: account.firstName,
    last_name: account.lastName,
    email: account.email,
  });
  yield put(Action.loginFulfilled(account));
  yield put(Notifications.resetNotifications());

  if (onboardingFields) {
    const property: { property_id: number } = yield call(
      ClientAPI.processPropertyQuiz,
      onboardingFields
    );
    if (onboardingFields?.preMadeProperty && property?.property_id) {
      yield put(replace(ClientRouter.DONE, { isUploadPlan: false }));
    } else if (redirect) yield put(replace(redirect));
    else yield put(replace(ClientRouter.DONE, { isUploadPlan: true }));
  } else if (redirect) {
    yield put(replace(redirect));
  } else if (loginData?.loginType === 'SUPPLIER') {
    yield put(replace(ClientRouter.SUPPLIER_LOGIN_WELCOME));
  } else if (!redirect) {
    const APP_INTERFACE = onboardingFields
      ? ClientRouter.DONE
      : getAppInterface(account.type);
    yield put(replace(APP_INTERFACE));
  }
}

// Normal Login without provider (email - password)
function* login({ payload, type }: Action.AuthenticationAction) {
  try {
    const account: Payload.AccountPayload = yield call(
      AuthAPI.login,
      payload as Payload.LoginPayload
    );

    yield call(
      loginRequest,
      {
        payload: { ...payload, rememberUser: true },
        type,
      } as Action.AuthenticationAction,
      account,
      (payload as Payload.LoginPayload).redirect
    );
    if (account.type === 'SUPPLIER') {
      const userData: Client = yield call(
        ClientsAPI.getClientInfo,
        account.id.toString()
      );
      yield put(
        Action.setSupplierID({ supplier: userData.supplier as string })
      );
    }
  } catch (error) {
    yield call(loginError, error, type);
  }
}

// Login with provider (Google - Facebook)
function* loginWithProvider({ payload, type }: Action.AuthenticationAction) {
  try {
    const {
      onboardingFields: _,
      ...providerData
    } = (payload as Payload.RedirectLoginWithProviderPayload).redirect
      ? (payload as Payload.RedirectLoginWithProviderPayload).registerPayload
      : (payload as Payload.LoginWithProviderPayload);

    const account: Payload.AccountPayload = yield call(
      AuthAPI.loginWithProvider,
      { ...providerData, onboardingFields: _ }
    );
    if ((payload as Payload.RedirectLoginWithProviderPayload).redirect) {
      yield call(
        loginRequest,
        {
          payload: {
            ...(payload as Payload.RedirectLoginWithProviderPayload)
              .registerPayload,
            rememberUser: true,
          },
          type,
        },
        account,
        (payload as Payload.RedirectLoginWithProviderPayload).redirect
      );
    } else {
      yield call(
        loginRequest,
        {
          payload: { ...payload, rememberUser: true },
          type,
        } as Action.AuthenticationAction,
        account
      );
    }
  } catch (error) {
    yield call(loginError, error, type);
  }
}

function* verifyToken() {
  try {
    const accountPayload = getLocalStorage<Payload.AccountPayload>(
      AUTH_CONFIG_ROOT_KEY,
      null
    );
    // if no token in local storage handle error in catch
    if (isEmpty(accountPayload)) throw new Error('Failed to login');
    const { token } = accountPayload;
    // update headers
    yield call(setHeaderToken, token);
    // call verify token
    const currentUser: Payload.AccountPayload = yield call(
      AuthAPI.verifyToken,
      {
        token,
      } as { token: string }
    );

    // save token to store

    yield call(LogsAPI.heapIdentify, currentUser.id.toString());
    yield call(LogsAPI.heapAddUserProperties, {
      first_name: currentUser.firstName,
      last_name: currentUser.lastName,
      email: currentUser.email,
    });

    yield put(Action.verifyTokenRequestFulfilled(currentUser));
    if (currentUser.type === 'SUPPLIER') {
      const userData: Client = yield call(
        ClientsAPI.getClientInfo,
        currentUser.id.toString()
      );
      yield put(
        Action.setSupplierID({ supplier: userData.supplier as string })
      );
    }
  } catch (error) {
    yield call(setHeaderToken);
    yield call(saveLocalStorage, AUTH_CONFIG_ROOT_KEY, null);
    yield put(Action.loginRejected(error));
  }
}

function* logout({ type }: Action.AuthenticationAction) {
  yield call(saveLocalStorage, AUTH_CONFIG_ROOT_KEY, {});
  yield call(setHeaderToken);
  yield call(callVuplex, 'User logged out');
  if (type === Authentication.CLIENT_LOGOUT_REQUEST)
    yield put(clearRecentProperty());
  yield put(
    push(
      type === Authentication.CLIENT_LOGOUT_REQUEST
        ? ClientRouter.APP
        : AuthRoute.LOGIN
    )
  );
}

function* register({ payload, type: key }: Action.AuthenticationAction) {
  try {
    const reqPayload = (payload as Payload.RedirectRegisterPayload)
      .registerPayload
      ? (payload as Payload.RedirectRegisterPayload).registerPayload
      : payload;
    const account: Payload.AccountPayload = yield call(AuthAPI.register, {
      ...reqPayload,
      verifyEmail: (reqPayload as Payload.RegisterPayload).email,
    } as Payload.RegisterPayload);
    yield call(LogsAPI.pushDataLayer, [{ event: 'RegisterStep6' }]);
    yield call(saveLocalStorage, AUTH_CONFIG_ROOT_KEY, account);
    yield call(saveLocalStorage, GUEST_TOKEN, null);
    yield call(setHeaderToken, account?.token);
    yield call(saveLocalStorage, CART_KEY, []);
    yield call(LogsAPI.heapIdentify, account.id.toString());
    yield call(LogsAPI.heapAddUserProperties, {
      first_name: account.firstName,
      last_name: account.lastName,
      email: account.email,
    });
    yield put(Action.registerFulfilled(account));

    if (key === Authentication.ONBOARDING_REQUEST) {
      if (
        account.propertyId &&
        (reqPayload as Payload.RegisterPayload).preMadeProperty
      ) {
        yield call(LogsAPI.pushDataLayer, [
          { event: 'RegisterStep7WithPreMade' },
        ]);
        yield put(replace(ClientRouter.DONE, { isUploadPlan: false }));
      } else {
        yield call(LogsAPI.pushDataLayer, [
          { event: 'RegisterStep7WithFloorPlan' },
        ]);
        yield put(replace(ClientRouter.DONE, { isUploadPlan: true }));
      }
    } else if ((payload as Payload.RedirectRegisterPayload).redirect) {
      yield put(replace((payload as Payload.RedirectRegisterPayload).redirect));
    } else {
      const APP_INTERFACE = getAppInterface(account.type);
      yield put(replace(APP_INTERFACE));
    }
  } catch (error) {
    yield put(Action.registerRejected(error));
    yield put(
      Notifications.showNotification({
        key,
        message: (error as Error).message,
        severity: 'error',
      })
    );
    yield call(LogsAPI.postUserLogs, {
      area: userLogData.eventArea.registration,
      section: userLogData.eventSection.userInfo,
      name: userLogData.eventName.nextFailed,
      path: userLogData.eventPath.appOnBoardingAdditionalInfoPath,
      metadata: { failure_reason: (error as Error).message },
    });
  }
}

function* passwordResetLinkRequest({
  payload,
  type: key,
}: Action.AuthenticationAction) {
  try {
    yield call(AuthAPI.resetLink, payload as Payload.ForgotPasswordPayload);
    yield put(Action.passwordResetLinkFulfilled());
    yield put(push(AuthRoute.RESET_LINK_SENT));
  } catch (error) {
    yield put(Action.registerRejected(error));
    yield put(
      Notifications.showNotification({
        key,
        message: (error as Error).message,
        severity: 'error',
      })
    );
  }
}

function* resetPasswordRequest({
  payload,
  type: key,
}: Action.AuthenticationAction) {
  try {
    yield call(AuthAPI.resetPassword, payload as Payload.ResetPasswordPayload);
    yield put(Action.resetPasswordFulfilled());
    yield put(push(AuthRoute.RESET_SUCCESS));
  } catch (error) {
    yield put(Action.registerRejected(error));
    yield put(
      Notifications.showNotification({
        key,
        message: (error as Error).message,
        severity: 'error',
      })
    );
  }
}

function* authenticationSaga() {
  yield all([
    takeLatest(Authentication.LOGIN_REQUEST, login),
    takeLatest(
      [
        Authentication.LOGIN_WITH_PROVIDER_REQUEST,
        Authentication.REDIRECT_LOGIN_WITH_PROVIDER_REQUEST,
      ],
      loginWithProvider
    ),
    takeLatest(
      [Authentication.LOGOUT_REQUEST, Authentication.CLIENT_LOGOUT_REQUEST],
      logout
    ),
    takeLatest(Authentication.VERIFY_TOKEN_REQUEST, verifyToken),
    takeLatest(
      [
        Authentication.REGISTER_REQUEST,
        Authentication.ONBOARDING_REQUEST,
        Authentication.REDIRECT_ONBOARDING_REQUEST,
      ],
      register
    ),
    takeLatest(
      Authentication.PASSWORD_RESET_LINK_REQUEST,
      passwordResetLinkRequest
    ),
    takeLatest(Authentication.RESET_PASSWORD_REQUEST, resetPasswordRequest),
  ]);
}

export default authenticationSaga;
