import styled from 'styled-components';
import { MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';
import LinksStack, { Divider } from './LinksStack';
import LogoStack from './LogoStack';

const Footer = () => {
  return (
    <Container id="footer">
      <Divider />
      <Content>
        <LogoStack />
        <Divider />
        <LinksStack />
      </Content>
    </Container>
  );
};

export default Footer;

const Container = styled.footer`
  background: white;
  z-index: 999;
  position: relative;
  & hr {
    margin: 0;
  }
  /* Prevent from bottom navbar to hide items */
  @media ${MOBILE_TABLET_RESOLUTION} {
    padding-bottom: 120px;
  }
`;

const Content = styled.section`
  font-family: ${({ theme }) => (theme.dir === 'rtl' ? 'inherit' : 'DM Sans')};
  display: flex;
  justify-content: space-evenly;
  max-width: 1600px;
  margin: auto;

  & > hr {
    margin: -8px 0;
  }

  @media ${MOBILE_TABLET_RESOLUTION} {
    display: block;

    & > hr {
      margin: 8px 0;
    }
  }
`;
