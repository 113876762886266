import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import FileDisplay, { FileType } from 'components/@client/FileDisplay';
import { Message } from 'i18n';
import * as Section from 'pages/appClient/onboarding/shared';
import type { PropertyMedia } from 'types/properties';
import { Setter } from 'types/common.types';

const SelectFloor: React.FC<{
  floorPlanFiles: PropertyMedia[];
  image?: File;
  selectedFloor?: PropertyMedia;
  setSelectedFloor: Setter<PropertyMedia>;
  setIsDocumentProper: Setter<boolean>;
  setSelectPage: Setter<PropertyMedia[]>;
}> = ({
  floorPlanFiles,
  image,
  selectedFloor,
  setSelectedFloor,
  setSelectPage,
  setIsDocumentProper,
}) => {
  const proceed = React.useCallback(() => {
    setSelectPage([]);
    setIsDocumentProper(true);
  }, [setSelectPage, setIsDocumentProper]);

  return (
    <Container>
      <FileDisplay
        type={FileType['application/pdf']}
        fileName={image ? `${image.name}` : undefined}
      />
      <LineBreak />
      <FormattedMessage id={Message.SELECT_FLOOR_CAPTION} tagName="p" />
      <Section.FloorGrid
        items={floorPlanFiles ?? []}
        selectedFloor={selectedFloor?.id}
        onClick={floor => setSelectedFloor(floor)}
      />
      <Section.Actions
        withPrevious
        prevProps={{ onClick: () => setSelectPage([]) }}
        nextProps={{
          disabled: selectedFloor === null,
          onClick: proceed,
        }}
      />
    </Container>
  );
};

export default SelectFloor;

const Container = styled.div`
  & .actions-container {
    margin-top: 20px;

    @media (min-height: 800px) {
      margin-top: 80px;
    }
  }

  @media (min-width: 1150px) {
    padding: 10px 0 0 0;
    & .file-display {
      padding: 0 30px;
    }

    & p {
      padding: 0 30px;
    }
  }
`;

const LineBreak = styled.hr`
  border-top: 0;
  border-bottom: 1px solid #a99997;
`;
