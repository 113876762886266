import * as React from 'react';
import styled, { css } from 'styled-components';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import footerB2B from 'assets/@client/supplier/patricia-logo.png';
import { IAddress } from 'types/common.types';
import { SelectedProductCoin } from 'types/products';
import type { Cart, Skin } from 'types/properties';
import { CheckOutPersonalDetailPayload } from 'types/orders';
import CheckoutForm from './CheckoutForm';

interface Props {
  cart?: Cart | null;
  personalDetail?: CheckOutPersonalDetailPayload | null;
  skin?: Skin | null;
  coins?: SelectedProductCoin | null;
  address?: IAddress | null;
  deliveryDate?: Date;
  tourSection?: boolean;
  hs?: string;
  publicKey: string;
  clientSecretKey: string;
}

const StripeForm: React.FC<Props> = ({ publicKey, clientSecretKey, skin }) => {
  const stripePromise = loadStripe(publicKey);
  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecretKey,
  };

  return (
    <StripeContainer>
      <LogoContainer dir="ltr">
        <Image src={footerB2B} alt="brand-logo" />
      </LogoContainer>
      <TextContainer>
        Powered by Stripe In Partnership with Patricia-AI
      </TextContainer>

      <div className="card-container">
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm />
        </Elements>
      </div>
    </StripeContainer>
  );
};

export default StripeForm;

const StripeContainer = styled.div`
  .card-container {
    margin: auto;
    overflow: clip;
  }
  .p-Input-input {
    border: none;
  }
`;

const LogoContainer = styled.div.attrs({
  className: 'logo-container',
})`
  display: flex;
`;
const Image = styled.img`
  width: 32px;
  height: 44px;
  margin: 6px auto;
`;

const TextContainer = styled.h2`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  align-items: center;
  margin: 20px auto;
  color: #fc5c42;
  ${({ theme }) =>
    theme.dir === 'rtl' &&
    css`
      margin: 38px 8px 0px 0px;
    `};
`;
