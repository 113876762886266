import * as React from 'react';
import addDays from 'date-fns/addDays';
import * as Response from 'api/responses';
import * as Transform from 'api/transform';
import { ProductCoin, SelectedProductCoin } from 'types/products';
import { SupportedCountry } from 'types/common.types';
import useFetch from './useFetch';
import useSession from './useSession';

const useProductCoin = (is_public = true) => {
  const [productCoinList, setProductCoinList] = React.useState<ProductCoin[]>(
    []
  );
  const [
    selectedCoins,
    setSelectedCoins,
  ] = React.useState<SelectedProductCoin | null>(null);
  const [defaultSelected, setDefaultSelected] = React.useState<string>('');
  const [orderSummary, setOrderSummary] = React.useState<{
    subTotal: number;
    shipping: number;
    vat: number;
    finalTotal: number;
  }>({
    subTotal: 0,
    shipping: 0,
    vat: 0,
    finalTotal: 0,
  });

  const { country } = useSession();

  const { data = [], isLoading, callFetch } = useFetch<
    ProductCoin[],
    Response.ProductCoins
  >({
    initialUrl: '/api/products/',
    config: { params: { coin: is_public } },
    transform: Transform.transformProductCoinPaginated,
  });

  React.useEffect(() => {
    setProductCoinList(data?.map(e => ({ ...e, quantity: 1 })) ?? []);
    const firstSelect = data?.find(a => a.englishName.split(' ')[0] === '7');
    setDefaultSelected(firstSelect?.id?.toString() ?? '');
  }, [data]);

  const onPaymentUpdate = React.useCallback(
    (sub_total: number, shipping_charge: number, vat_charge: number) => {
      const vat_per = country === SupportedCountry.ISRAEL ? 17 : 10;
      const vatCharge = (sub_total * vat_per) / 100;
      setOrderSummary({
        subTotal: sub_total,
        shipping: shipping_charge,
        vat: vatCharge,
        finalTotal: sub_total + shipping_charge + vatCharge,
      });
      const select = productCoinList?.find(a => a.quantity > 0);
      if (select) {
        const selectedCoin: SelectedProductCoin = {
          ...select,
          finalTotal: sub_total + shipping_charge + vatCharge,
        };
        setSelectedCoins(selectedCoin);
      }
    },
    [productCoinList, country]
  );

  React.useEffect(() => {
    const selectedData = productCoinList.filter(
      a => a.id.toString() === defaultSelected
    );
    const subTotalPrice = selectedData.reduce(
      (total, obj) => obj.price * obj.quantity + total,
      0
    );
    onPaymentUpdate(subTotalPrice, 0, 0);
  }, [productCoinList, onPaymentUpdate, defaultSelected]);

  const onQuantityChange = React.useCallback(
    (id: string, quantity: number) => {
      const index = productCoinList?.findIndex(a => a.id.toString() === id);
      productCoinList[index].quantity = quantity;
      setProductCoinList([...productCoinList]);
    },
    [productCoinList]
  );

  const onSelectChange = React.useCallback((id: string) => {
    setDefaultSelected(id);
  }, []);

  const deliveryDate = addDays(new Date(), 21);

  return React.useMemo(
    () => ({
      productCoinList,
      isLoading,
      onQuantityChange,
      callFetch,
      defaultSelected,
      onSelectChange,
      orderSummary,
      deliveryDate,
      selectedCoins,
    }),
    [
      productCoinList,
      isLoading,
      onQuantityChange,
      callFetch,
      defaultSelected,
      onSelectChange,
      orderSummary,
      deliveryDate,
      selectedCoins,
    ]
  );
};

export default useProductCoin;
