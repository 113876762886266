import * as React from 'react';
import AppleLogin, { AppleLoginProps } from 'react-apple-login';

interface ReactAppleLoginProps extends AppleLoginProps {
  render: (props: {
    onClick: React.MouseEventHandler<HTMLButtonElement>;
  }) => JSX.Element;
}

// This component is because the original custom component don't have types.
const AppleLoginButton: React.FC<ReactAppleLoginProps> = props => {
  return <AppleLogin {...props} />;
};

export default React.memo(AppleLoginButton);
