import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Message } from 'i18n';
import styled, { css } from 'styled-components';
import rtl from 'styled-components-rtl';
import {
  useMediaQuery,
  createStyles,
  withStyles,
  Chip,
} from '@material-ui/core';
import VisibilityIcon from '@mui/icons-material/Visibility';
// import { ReactSVG } from 'react-svg';
import { useAuthentication, useRouter, useAddToCart, useSession } from 'hooks';
import { ClientRouter } from 'router/routes';
import { MOBILE_RESOLUTION, TABLET_RESOLUTION } from 'styles/sharedStyle';
import IconButton from '@material-ui/core/IconButton';
import Button from 'components/@client/Button';
import { LocalOffer as LocalOfferOutlined } from '@mui/icons-material';
// import ReplyRightIcon from 'assets/icons/ReplyRight.svg';
import { IdeasDetails as IdeasDetailsProps } from 'types/products';
import SwitchButton from 'components/SwitchButton';
import { Tooltip } from '@mui/material';
import Modal, { ModalAction } from 'components/@client/Modal';
import SafeImg from 'components/SafeImg';
import { ReactComponent as Checkmark } from 'components/@client/form-components/pick.svg';
import { Supplier } from 'types/suppliers';
import * as action from 'store/actions/user-management/clients.actions';
import { useSelector, useDispatch } from 'store/selectors';
import * as selectors from 'store/selectors/clients.selector';
import { IdeaChipsContainer } from 'components/@client/PinterestIdeasCard';
import { ReactComponent as ResizeIcon } from 'assets/socialIcons/Resize.svg';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import ProductToolTip from '../properties/property/Components/ProductToolTip';
import ProductPageModal from '../productPage/component/ProductPageModal';

interface Props {
  ideaDetails: IdeasDetailsProps | null;
  isLoading: boolean;
  imageList?: Supplier[];
}

const StyledTooltip = withStyles(() =>
  createStyles({
    tooltip: {
      backgroundColor: '#ffffff !important',
      borderRadius: '10px !important',
      padding: '24px !important',
      maxWidth: 'unset !important',
    },
    popper: {},
    arrow: {
      color: '#ffffff !important',
      width: '38px !important',
      height: '8px !important',
      overflow: 'visible !important',
      '&::before': {
        height: '26px !important',
        width: '35px !important',
      },
    },
  })
)(Tooltip);

const DetailComponent: React.FC<Props> = ({
  ideaDetails,
  isLoading,
  imageList,
}) => {
  const { history } = useRouter();
  const { token } = useAuthentication();
  const { currency } = useSession();
  const intl = useIntl();
  const [openToolltip, setOpenToolTip] = React.useState<number | undefined>();

  const { query } = useRouter<{
    productId: string;
  }>();
  const [metaData, setMetaData] = React.useState<{
    total_price: number;
    position: { x: number; y: number };
    rotation: { x: number; y: number };
    room_id?: string;
    products: Array<{ id: string; position: { x: number; y: number } }>;
  }>();
  const {
    addToCart,
    isAuthenticatedForCart,
    isLoading: isCartLoading,
    preregisterUser,
    setSuccessModalOpen,
    successModalOpen,
    lastProductAdded,
  } = useAddToCart();
  const [roomName, setRoomName] = React.useState<string | undefined>('');
  const [showToggle, setShowToggle] = React.useState<boolean>(false);
  React.useEffect(() => {
    setMetaData(
      ideaDetails?.metadata && typeof ideaDetails?.metadata === 'string'
        ? JSON.parse(ideaDetails?.metadata)
        : ideaDetails?.metadata
    );
  }, [ideaDetails]);

  const onShowToggle = React.useCallback(
    (_, isOpen: boolean) => {
      setShowToggle(isOpen);
    },
    [setShowToggle]
  );

  const to = React.useCallback(() => {
    if (!token) history.push(ClientRouter.UPLOAD_PLAN);
    else
      history.push(
        ClientRouter.TOUR.replace(':propertyId', ideaDetails?.property ?? ''),
        {
          metadata: {
            position: metaData?.position,
            rotation: metaData?.rotation,
          },
        }
      );
  }, [history, ideaDetails, token, metaData]);

  const isMobile = useMediaQuery(TABLET_RESOLUTION);
  const rooms = useSelector(selectors.allRoomsItems);
  const dispatch = useDispatch();

  const actions: ModalAction[] = [
    {
      label: <FormattedMessage id={Message.CART_KEEP_SHOPPING_BUTTON} />,
      onClick: () => {
        setSuccessModalOpen(false);
      },
      variant: 'secondary',
    },
    {
      label: <FormattedMessage id={Message.CART_CHECKOUT_BUTTON} />,
      onClick: () => {
        if (isAuthenticatedForCart) {
          history.push(ClientRouter.CART);
        } else {
          preregisterUser();
          // history.push(`${ClientRouter.ADDITIONAL_INFO}?redirect=CART`);
        }
      },
      isLoading: isCartLoading,
      variant: 'primary',
    },
  ];

  const finalPrice = React.useMemo(() => {
    let customPrice: number = 0;
    if (metaData?.total_price && metaData?.total_price >= 100000) {
      customPrice = 100;
      // eslint-disable-next-line no-else-return
    } else if (
      metaData?.total_price &&
      metaData?.total_price < 100000 &&
      metaData?.total_price >= 50000
    ) {
      customPrice = 50;
    } else if (
      metaData?.total_price &&
      metaData?.total_price < 50000 &&
      metaData?.total_price >= 30000
    ) {
      customPrice = 30;
    } else if (
      metaData?.total_price &&
      metaData?.total_price < 30000 &&
      metaData?.total_price >= 20000
    ) {
      customPrice = 20;
    } else if (
      metaData?.total_price &&
      metaData?.total_price < 20000 &&
      metaData?.total_price >= 10000
    ) {
      customPrice = 10;
    }
    if (intl.locale === 'he') {
      const newVal = `+ K${intl.formatNumber(customPrice, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        style: 'currency',
        currency,
      })}`;
      return newVal
        .replace('\u200F', `\u200F${newVal[newVal.length - 1]}`)
        .substring(0, newVal.length - 1);
    }
    return `${intl.formatNumber(customPrice, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      style: 'currency',
      currency,
    })}K +`;
  }, [metaData?.total_price, currency, intl]);

  React.useEffect(() => {
    if (rooms?.length === 0) dispatch(action.getRoomsDataRequest());
  }, [dispatch, rooms?.length]);

  React.useEffect(() => {
    if (metaData?.room_id) {
      const room = rooms?.find(item => {
        return Number(item.id) === Number(metaData?.room_id);
      });
      setRoomName(room && room?.name);
    }
  }, [metaData?.room_id, rooms]);

  const checkNotForSales = React.useCallback(
    (id: string) => {
      const p = ideaDetails?.products?.find(a => a?.id.toString() === id);
      return p && !p?.notForSale;
    },
    [ideaDetails]
  );

  const placement = React.useCallback(product => {
    const x = product.position.x * 100;
    const y = product.position.y * 100;
    if (y < 20) return 'top';
    if (y > 70) return 'bottom';
    if (x < 20) return 'top-start';
    if (x > 70) return 'top-end';

    return 'top';
  }, []);

  const productList = React.useMemo(() => {
    return metaData?.products?.filter(product => {
      return (
        product.position.x > 0.25 &&
        product.position.x < 0.7 &&
        product.position.y > 0.1 &&
        checkNotForSales(product?.id)
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkNotForSales, metaData?.products, ideaDetails]);

  const [fullScreen, setFullScreen] = React.useState<string | null>(null);

  return (
    <RootDiv>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <Container>
          <TopDiv>
            <ImageContainer>
              <Image
                id="fullscreen-image"
                src={ideaDetails?.file?.toString()}
              />
              {!isMobile && (
                <SwitchButtonWrapper>
                  <SwitchButton
                    onChange={onShowToggle}
                    classes={{
                      root: 'switch-root',
                      switchBase: 'switchBase-price-tag',
                      thumb: 'switchBase-thumb',
                      track: 'switchBase-track',
                      checked: 'switchBase-checked',
                    }}
                    checked={showToggle}
                  />
                  <SwitchLabel>
                    <FormattedMessage id={Message.IDEA_PRICE_TAG_LABEL} />{' '}
                  </SwitchLabel>
                </SwitchButtonWrapper>
              )}
              {!showToggle && (
                <TagContainer>
                  {productList?.slice(0, 10)?.map((product, i) => {
                    return (
                      <StyledTooltip
                        title={
                          <ProductToolTip
                            isLoading={isCartLoading}
                            product={ideaDetails?.products?.find(
                              e => e.id === product.id
                            )}
                            addToCart={addToCart}
                            onClose={() => setOpenToolTip(undefined)}
                          />
                        }
                        key={`${product.id + i}`}
                        placement={placement(product)}
                        arrow
                        classes={{
                          popper: 'idea-tooltip-popper',
                        }}
                        open={openToolltip === i}
                      >
                        <TagComponent
                          onClick={() => {
                            if (openToolltip === i) setOpenToolTip(undefined);
                            else setOpenToolTip(i);
                          }}
                          x={product.position.x}
                          y={product.position.y}
                          data-testid="tag-button"
                        >
                          <LocalOfferButtons isClicked={openToolltip === i}>
                            <LocalOfferOutlined />
                          </LocalOfferButtons>
                        </TagComponent>
                      </StyledTooltip>
                    );
                  })}
                </TagContainer>
              )}
              <CustomResizeIcon
                onClick={() =>
                  setFullScreen(ideaDetails?.file?.toString() ?? '')
                }
              />
            </ImageContainer>
          </TopDiv>
          {isMobile && (
            <SwitchButtonWrapper>
              <SwitchButton
                onChange={onShowToggle}
                classes={{
                  root: 'switch-root',
                  switchBase: 'switchBase-price-tag',
                  thumb: 'switchBase-thumb',
                  track: 'switchBase-track',
                  checked: 'switchBase-checked',
                }}
                checked={showToggle}
              />
              <SwitchLabel>
                <FormattedMessage id={Message.IDEA_PRICE_TAG_LABEL} />{' '}
              </SwitchLabel>
            </SwitchButtonWrapper>
          )}
          <ParentDiv>
            <ContentDetail>
              {/* <Row>
              <Col>
                <Title>{ideaDetails?.name?.replace(/\.[^/.]+$/, '')}</Title>
              </Col>
            </Row> */}
              <Detail>
                <CustomName>
                  <DesignedBy>
                    <Title>
                      <FormattedMessage id={Message.IDEA_DESIGNED_BY_LABEL} />
                    </Title>{' '}
                    <SubTitle>{`${ideaDetails?.user?.first_name ?? ''} ${
                      ideaDetails?.user?.last_name ?? ''
                    }`}</SubTitle>{' '}
                    |
                  </DesignedBy>
                  <DateLabel>{ideaDetails?.created_at}</DateLabel>
                </CustomName>
                {!isMobile && (
                  <>
                    <div>
                      <ViewButtons>
                        {intl.locale === 'he' ? (
                          <>
                            {ideaDetails?.viewCount} <VisibilityIcon />
                          </>
                        ) : (
                          <>
                            <VisibilityIcon /> {ideaDetails?.viewCount}
                          </>
                        )}
                      </ViewButtons>
                    </div>
                  </>
                )}
              </Detail>
            </ContentDetail>
            {(metaData?.total_price || metaData?.room_id) && (
              <IdeaChipsContainerDiv>
                {metaData?.total_price && (
                  <ChipPrice label={finalPrice} variant="outlined" />
                )}
                {metaData?.room_id && (
                  <Chip label={roomName} variant="outlined" />
                )}
              </IdeaChipsContainerDiv>
            )}
            <ButtonContainer>
              <StartTourButton onClick={to}>
                <FormattedMessage id={Message.IDEA_START_TOUR_BUTTON} />
              </StartTourButton>
              {isMobile && (
                <>
                  <div>
                    <ViewButtons>
                      <VisibilityIcon /> {ideaDetails?.viewCount}
                    </ViewButtons>
                  </div>
                </>
              )}
            </ButtonContainer>
            {imageList && imageList?.length > 0 && (
              <BrandContainer>
                <BrandImageTitle>
                  <FormattedMessage id={Message.IDEA_BRAND_IMAGE_TITLE} />
                </BrandImageTitle>
                <BrandImageList>
                  {imageList &&
                    imageList.map(item => {
                      return <BrandImage key={item.id} src={item?.logo} />;
                    })}
                </BrandImageList>
              </BrandContainer>
            )}
          </ParentDiv>
        </Container>
      )}
      {Boolean(query.productId) && (
        <ProductPageModal
          hideEveryButton={true}
          product={query.productId}
          defaultOpen={Boolean(query.productId)}
        />
      )}
      {fullScreen && (
        <FullScreenModal
          open={Boolean(fullScreen)}
          onClose={() => setFullScreen(null)}
        >
          <div className="fullscreen-container">
            <TransformWrapper>
              <TransformComponent>
                <img
                  src={fullScreen}
                  alt="property media"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    margin: 'auto',
                  }}
                />
              </TransformComponent>
            </TransformWrapper>
          </div>
        </FullScreenModal>
      )}
      <ConfirmModal
        className="confirm-modal"
        onClose={() => setSuccessModalOpen(false)}
        actions={actions}
        open={successModalOpen}
      >
        <Content>
          <div>
            <SafeImg
              src={lastProductAdded?.thumbnail?.file}
              alt={lastProductAdded?.name}
            />
          </div>
          <Checkmark className="checkmark" />
          <FormattedMessage id={Message.ADDED_TO_CART} />
        </Content>
      </ConfirmModal>
    </RootDiv>
  );
};

const FullScreenModal = styled(Modal)`
  z-index: 999999 !important;
  div:first-child {
    background-color: white !important;
  }

  .MuiPaper-root {
    max-width: unset;
    .MuiDialogContent-root {
      overflow: hidden;
    }
    .MuiDialogActions-root {
      display: none;
    }
    @media (max-width: 800px) {
      margin: 0;
      padding: 0 !important;
      max-width: unset;
      .MuiDialogContent-root {
        overflow: hidden;
        padding: 40px 0 0 !important;
      }
      .MuiDialogActions-root {
        display: none;
      }
    }
  }
  .fullscreen-container {
    height: 100vh;
    display: flex;
    margin: auto;
    .react-transform-wrapper {
      height: auto !important;
    }
  }
`;

const CustomResizeIcon = styled(ResizeIcon)`
  stroke: rgba(255, 255, 255, 0.9);
  fill: #ffffff;
  position: absolute;
  /* float: right; */
  bottom: 10px;
  right: 10px;
  z-index: 9999;
  width: 32px;
  height: 32px;
  background: #3e3e3e;
  border-radius: 20px;
`;

const RootDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
`;

const Container = styled.section`
  @media (min-width: 768px) and (max-width: 1200px) {
    width: auto;
  }
  @media ${MOBILE_RESOLUTION} {
    width: 100%;
  }
`;

const Content = styled.p`
  text-align: center;
  img {
    max-width: 80px;
    max-height: 80px;
    margin-bottom: 10px;
  }
  margin-bottom: 20px;
  font-size: 1.25rem;
  color: ${({ theme }) => theme.colors.black0};
  svg {
    path {
      fill: ${({ theme }) => theme.colors.primary.lightMobile};
    }
    ${rtl`
      margin-right: 10px;
    `}
  }
`;

const TopDiv = styled.div`
  display: flex;
  @media ${TABLET_RESOLUTION} {
    flex-direction: column;
  }
  justify-content: center;
  align-items: center;
`;

const ContentDetail = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: column;
  gap: 4px;
  margin-top: 24px;
  @media ${TABLET_RESOLUTION} {
    ${rtl`
    margin-left: 24px;
    `}
  }
`;

const ParentDiv = styled.div`
  margin: 0 auto;
  width: 1010px;
  @media (min-width: 768px) and (max-width: 1200px) {
    width: auto;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: left;
  align-items: center;
`;

const Image = styled.img`
  position: relative;
  width: 1010px;
  height: 568px;
  border-radius: 18.8802px;
  @media ${TABLET_RESOLUTION} {
    width: 100%;
    min-height: 285px;
    height: auto;
    border-radius: 0;
  }
  @media ${MOBILE_RESOLUTION} {
    width: 100%;
    min-height: 100px;
    height: auto;
    border-radius: 0;
  }
`;

const BrandImageList = styled.div`
  gap: 48px;
  display: inline-flex;
  flex-wrap: wrap;
  @media ${TABLET_RESOLUTION} {
    gap: 8px;
  }
`;

const BrandImage = styled.img`
  width: auto;
  height: 48px;
`;

const TagComponent = styled.span<{ x: number; y: number }>`
  position: absolute;
  width: 36px;
  height: 36px;
  bottom: calc(${({ y }) => y * 100}% - 18px);
  left: calc(${({ x }) => x * 100}% - 18px);
`;

const TagContainer = styled.div`
  width: 100%;
`;

const StartTourButton = styled(Button)`
  && {
    background: #fc5c42;
    border: 1px solid #fc5c42;
    border-radius: 15px;
    min-width: 203px;
    padding: 0;
    .MuiButton-label {
      padding: 10px 40px;
      font-family: ${({ theme }) =>
        theme.dir === 'rtl' ? ' Assistant' : 'Roboto'};
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
      text-align: center;
      color: rgba(255, 255, 255, 0.9);
      @media ${MOBILE_RESOLUTION} {
        padding: 8px 24px;
        font-size: 16px;
        line-height: 24px;
      }
    }

    @media ${MOBILE_RESOLUTION} {
      height: 40px;
      min-width: initial;
      border-radius: 10px;
    }
  }
`;

const ConfirmModal = styled(Modal)`
  & .MuiDialogActions-root {
    @media ${MOBILE_RESOLUTION} {
      display: block;
      text-align: center;
      & > button {
        margin: 10px !important;
      }
    }
    & > button {
      min-width: 150px;
    }
  }
`;

const LocalOfferButtons = styled(IconButton)<{
  marginRight?: string;
  isClicked: boolean;
}>`
  ${({ isClicked }) =>
    !isClicked &&
    css`
      background: linear-gradient(
          0deg,
          rgba(252, 92, 66, 0.2),
          rgba(252, 92, 66, 0.2)
        ),
        radial-gradient(
          61.46% 61.46% at 50% 50%,
          #fc5c42 0%,
          rgba(252, 92, 66, 0) 100%
        );
    `}
  width: 36px;
  height: 36px;
  border-radius: 50% !important;
  border: ${({ theme, isClicked }) =>
    isClicked && `1px solid ${theme.colors.primary.lightMobile} !important`};
  svg {
    transform: matrix(-1, 0, 0, 1, 0, 0);
    fill: ${({ theme, isClicked }) =>
      isClicked ? theme.colors.primary.lightMobile : 'white'};
    transform: rotate(0deg);
    width: 16px;
    height: 16px;
  }
`;

const ViewButtons = styled(IconButton)`
  && {
    display: flex;
    align-items: center;
    text-align: center;
  }
  && .MuiIconButton-label {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #282929;
    gap: 4px;
  }
`;

// const Price = styled.span`
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 500;
//   font-size: 24px;
//   line-height: 28px;
//   text-align: center;
//   color: #fc5c42;
// `;

const Detail = styled.div`
  display: flex;
  gap: 14px;
  align-items: center;
  justify-content: space-between;
  @media ${TABLET_RESOLUTION} {
    gap: 0;
  }
`;

const CustomName = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  direction: ${({ theme }) => theme.dir};
`;

const DesignedBy = styled.span`
  font-family: ${({ theme }) =>
    theme.dir === 'rtl' ? ' Assistant' : 'Roboto'};
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #3e3e3e;
  @media ${TABLET_RESOLUTION} {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
`;

const Title = styled(DesignedBy)`
  font-weight: ${({ theme }) => theme.dir === 'rtl' && 600};
  line-height: ${({ theme }) => theme.dir === 'rtl' && '24px'};
`;

const SubTitle = styled(DesignedBy)`
  font-weight: ${({ theme }) => theme.dir === 'rtl' && 400};
  line-height: ${({ theme }) => theme.dir === 'rtl' && '24px'};
`;
const DateLabel = styled.span`
  font-family: ${({ theme }) =>
    theme.dir === 'rtl' ? ' Assistant' : 'Roboto'};
  font-style: normal;
  font-weight: ${({ theme }) => (theme.dir === 'rtl' ? 400 : 500)};
  font-size: 18px;
  line-height: ${({ theme }) => (theme.dir === 'rtl' ? '22px' : '21px')};
  color: #3e3e3e;
  @media ${TABLET_RESOLUTION} {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
`;

const BrandImageTitle = styled.label`
  font-family: ${({ theme }) =>
    theme.dir === 'rtl' ? ' Assistant' : 'Roboto'};
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #282929;
  @media ${TABLET_RESOLUTION} {
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
  }
`;

const SwitchButtonWrapper = styled.div`
  position: absolute;
  bottom: 16px;
  ${rtl`
  left: 16px;
  `}
  display: flex;
  gap: 8px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.8);
  align-items: center;
  justify-content: center;

  @media ${TABLET_RESOLUTION} {
    position: relative;
    bottom: 0;
    left: 0;
    margin-top: 8px;
  }

  .MuiTouchRipple-root {
    background: #ffffff;
    border-radius: 16px;
    -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
    -ms-transform: matrix(-1, 0, 0, 1, 0, 0);
    transform: matrix(-1, 0, 0, 1, 0, 0);
    margin: 2px 0 0 2px;
    padding: 0px;
    width: 12px;
    height: 12px;
  }
  .switchBase-track {
    background: #37eb54;
    border-radius: 16px;
    -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
    -ms-transform: matrix(-1, 0, 0, 1, 0, 0);
    transform: matrix(-1, 0, 0, 1, 0, 0);
    border: none;
    width: 32px;
    height: 16px;
  }
  .switchBase-thumb {
    background-color: transparent !important;
  }
  .switchBase-thumb {
    background-color: transparent !important;
  }
  .switchBase-price-tag {
    padding: 0;
  }
  .switch-root {
    transform: rotate(180deg);
    width: 32px;
    height: 16px;
    ${rtl`
    margin: 4px 0 4px 8.8px;
    `}
    @media ${TABLET_RESOLUTION} {
      height: 18px;
    }
  }
`;

const SwitchLabel = styled.label`
  font-family: ${({ theme }) =>
    theme.dir === 'rtl' ? ' Assistant' : 'Roboto'};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #3e3e3e;
  ${rtl`
  margin-right: 8px;
  `}
  @media ${TABLET_RESOLUTION} {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #676766;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 14px;
  @media ${TABLET_RESOLUTION} {
    align-items: end;
    ${rtl`
    margin: 16px 24px 0 24px;
    `}
  }
`;

const BrandContainer = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media ${TABLET_RESOLUTION} {
    gap: 16px;
    margin-top: 32px;
    ${rtl`
    margin-left: 24px;
    `}
  }
`;

const IdeaChipsContainerDiv = styled(IdeaChipsContainer)`
  direction: ltr;
  width: fit-content;
  @media ${TABLET_RESOLUTION} {
    margin-left: 24px;
  }
`;

const ChipPrice = styled(Chip)`
  direction: ${({ theme }) => theme.dir === 'rtl' && 'rtl'};
`;
export default DetailComponent;
