import * as React from 'react';
import styled from 'styled-components';
import { Message } from 'i18n';
import { useRouter } from 'hooks';
import { useIntl } from 'react-intl';
import { SubCategory } from 'types/common.types';
import { Route } from 'react-router-dom';
import { ClientRouter } from 'router/routes';
import { Category } from 'types/products';
import MobileHeader from './MobileHeader';
import SubCategoryPage from './components/SubCategoryPage';
import ProductPage from './components/ProductPage';
import MobileHomePage from './components/MobileHomePage';

interface Props {
  categories: Category[] | [];
  getFilteredCategories: (catName: string) => Array<SubCategory> | undefined;
}

const MobileProducts: React.FC<Props> = ({
  categories,
  getFilteredCategories,
}) => {
  const { query } = useRouter<{ catName: string; subCatName: string }>();

  const intl = useIntl();

  const header = React.useMemo(() => {
    if (query.subCatName) return query.subCatName;
    if (query.catName) return query.catName;
    return intl.formatMessage({
      id: Message.PRODUCT_PAGE,
    });
  }, [intl, query.catName, query.subCatName]);

  return (
    <Container>
      <MobileHeader title={header} />
      <Route
        exact
        path={ClientRouter.PRODUCTS}
        render={props => <MobileHomePage {...props} categories={categories} />}
      />
      <Route
        exact
        path={ClientRouter.PRODUCT_CATEGORY}
        render={props => (
          <SubCategoryPage
            {...props}
            title={query.catName}
            subCategory={getFilteredCategories(query.catName)}
          />
        )}
      />
      <Route
        exact
        path={ClientRouter.PRODUCT_SUB_CATEGORY}
        render={props => (
          <ProductPage
            {...props}
            title={query.subCatName}
            subCategory={
              getFilteredCategories(query.catName)?.filter(
                e => e.name === query.subCatName
              )[0]
            }
          />
        )}
      />
    </Container>
  );
};

export default MobileProducts;

const Container = styled.section`
  margin-top: 30px;
  padding: 0 25px;
`;
