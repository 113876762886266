import * as React from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import { ButtonVariantDefaults } from '@material-ui/core';
import styled from 'styled-components';
import SecondaryButton from 'components/Button/SecondaryButton';
import ActionButton from 'components/Modal/ActionButton';
import TextButton from 'components/Button/TextButton';
import Tooltip from 'components/Tooltip';
import { KeyboardArrowLeft } from '@material-ui/icons';

export type ModalAction = {
  label: string;
  action?: () => void;
  startIcon?: React.ReactNode;
  buttonColor?: 'dark' | 'light' | 'lightMobile';
  secondary?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  variant?: keyof ButtonVariantDefaults;
  type?: 'submit' | 'button';
  className?: string;
};

interface Props {
  onBack?: () => void;
  showCancelButton?: boolean;
  actions: ModalAction[];
  onCancel?: () => void;
  cancelLabel?: string;
}

const Actions: React.FC<Props> = ({
  children,
  onBack,
  showCancelButton,
  actions,
  cancelLabel = 'Cancel',
  onCancel,
}) => {
  return (
    <Container>
      {onBack && (
        <TextButton
          className="back-link"
          onClick={onBack}
          startIcon={<KeyboardArrowLeft />}
        >
          Back
        </TextButton>
      )}
      {children}
      {showCancelButton && (
        <SecondaryButton className="secondary-button" onClick={onCancel}>
          {cancelLabel}
        </SecondaryButton>
      )}
      {actions.map(
        ({
          label,
          action,
          buttonColor,
          disabled,
          isLoading,
          startIcon,
          secondary = false,
          variant = 'contained',
          type = 'button',
          className,
        }) =>
          label === 'Edit product' && disabled ? (
            <Tooltip title="No data changed" $variant="info">
              <div>
                <ActionButton
                  startIcon={startIcon}
                  $buttonColor={buttonColor}
                  key={label}
                  onClick={action}
                  disabled={disabled}
                  isLoading={isLoading}
                  variant={variant}
                  type={type}
                  $secondary={secondary}
                  className={className}
                >
                  {label}
                </ActionButton>
              </div>
            </Tooltip>
          ) : (
            <ActionButton
              startIcon={startIcon}
              $buttonColor={buttonColor}
              key={label}
              onClick={action}
              disabled={disabled}
              isLoading={isLoading}
              variant={variant}
              type={type}
              $secondary={secondary}
              className={className}
            >
              {label}
            </ActionButton>
          )
      )}
    </Container>
  );
};

export default Actions;

const Container = styled(DialogActions).attrs({
  className: 'modal-dialog-actions',
})`
  padding: 1rem 1rem 0.5rem 0;
  gap: 8px;
  width: 100%;

  & .back-link {
    margin-right: auto;
  }
  @media (max-width: 768px) {
    padding: 0;
    justify-content: space-between;
  }
`;
