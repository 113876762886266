import * as Response from 'api/responses';
import { Addresses as addressTransformer } from 'api/transform';
import { METHODS } from 'api/client';
import { useFetch } from 'hooks';
import type { IAddress } from 'types/common.types';

const useAddressesList = (
  userId?: string | number,
  initialState: IAddress[] = [],
  skipOnStart = true,
  onSuccess?: (data: IAddress[] | null) => void
) => {
  const { callFetch: fetchAddresses, isLoading, data } = useFetch<
    IAddress[],
    Response.Address[]
  >({
    initialUrl: `/api/addresses/`,
    initialData: initialState,
    config: { params: { user: userId }, method: METHODS.GET },
    skipOnStart,
    transform: addressTransformer,
    onSuccess,
  });

  return { addresses: data, isLoading, fetchAddresses };
};

export default useAddressesList;
