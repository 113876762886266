import styled, { css } from 'styled-components';
import { BaseButton } from 'components/Button';

const SecondaryButton = styled(BaseButton).attrs({
  variant: 'outlined',
  color: 'inherit',
  size: 'medium',
})<{ $large?: true; $xlarge?: true }>`
  && {
    color: rgba(121, 121, 121, 1);
    text-transform: capitalize;
    width: 115px;
    height: 48px;

    &:hover {
      background: white;
    }

    ${({ $large }) =>
      $large &&
      css`
        width: 122px;
        height: 48px;
      `}

    ${({ $xlarge }) =>
      $xlarge &&
      css`
        width: 152px;
        height: 48px;
      `}
  }
`;

export default SecondaryButton;
