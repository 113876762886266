import * as React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { METHODS } from 'api/client';
import * as Response from 'api/responses';
import * as Transform from 'api/transform';
import { useFetch, useNotifications } from 'hooks';
import { addressesSchema } from 'utils/validation.utils';
import { initialAddressValues } from 'pages/appManagement/common/Addresses/defaultValues';
import type { IAddress } from 'types/common.types';

interface Props {
  closeForm?: (reason?: 'creation' | 'cancel') => void;
  onDeleteSuccess?: VoidFunction;
  defaultValues?: IAddress;
  addressesCount?: number;
  userId?: string;
  onAddressAddCallback?: VoidFunction;
}
const useAddressForm = ({
  closeForm,
  defaultValues,
  addressesCount = 0,
  userId,
  onDeleteSuccess,
  onAddressAddCallback,
}: Props) => {
  const { showNotification, notifications } = useNotifications();

  const onSuccess = React.useCallback(() => {
    showNotification({
      key: 'clients/editAddressFulfilled',
      message: 'Address has been successfully updated',
      severity: 'success',
    });
    closeForm?.('creation');
    onAddressAddCallback?.();
  }, [closeForm, showNotification, onAddressAddCallback]);

  const onFailure = React.useCallback(
    (message: string) => {
      showNotification({
        key: 'clients/editAddressRejected',
        message,
        severity: 'error',
      });
    },
    [showNotification]
  );

  const { isLoading, callFetch } = useFetch<Response.Address>({
    initialUrl: `/api/addresses/${defaultValues?.id}/`,
    skipOnStart: true,
    onSuccess,
    onFailure,
  });

  const methods = useForm<IAddress>({
    resolver: yupResolver(addressesSchema),
    defaultValues: {
      ...initialAddressValues,
      ...defaultValues,
      isPrimary: defaultValues?.isPrimary || addressesCount === 0,
    },
    mode: 'all',
  });
  const { reset } = methods;

  const close = React.useCallback(() => {
    closeForm?.('cancel');
    reset({
      ...initialAddressValues,
      ...defaultValues,
      country: '',
      state: '',
    });
  }, [closeForm, reset, defaultValues]);

  const saveForm = React.useCallback(
    (payload: IAddress) => {
      callFetch({
        url: defaultValues?.id
          ? `/api/addresses/${defaultValues?.id}/`
          : '/api/addresses/',
        method: defaultValues?.id ? METHODS.PATCH : METHODS.POST,
        data: Transform.AddressRequest({
          ...payload,
          user: !defaultValues?.id ? userId : undefined,
        }),
      });
    },
    [callFetch, defaultValues?.id, userId]
  );

  const updateAddress = React.useCallback(
    (payload: IAddress) => {
      callFetch({
        url: `/api/addresses/${payload.id}/`,

        method: METHODS.PATCH,
        data: Transform.AddressRequest({
          ...payload,
          user: userId,
        }),
      });
    },
    [callFetch, userId]
  );

  const {
    isLoading: deleteIsLoading,
    callFetch: deleteAddress,
  } = useFetch<Response.Address>({
    initialUrl: `/api/addresses/${defaultValues?.id}/`,
    config: {
      method: METHODS.DELETE,
    },
    skipOnStart: true,
    onSuccess: onDeleteSuccess,
    onFailure,
  });

  React.useEffect(() => {
    reset({
      ...defaultValues,
      isPrimary: defaultValues?.isPrimary || addressesCount === 0,
    });
  }, [addressesCount, defaultValues, reset]);

  return {
    isLoading,
    deleteIsLoading,
    methods,
    close,
    saveForm,
    deleteAddress,
    successNotification: notifications['clients/editAddressFulfilled'],
    updateAddress,
  };
};

export default useAddressForm;
