import * as React from 'react';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import type { OutlinedInputProps } from '@material-ui/core/OutlinedInput';
import type { Theme } from '@material-ui/core/styles';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { useFormContext, useController } from 'react-hook-form';
import { isFunction } from 'utils/common.utils';
import ValidationIndicator from './ValidationIndicator';

interface Props extends OutlinedInputProps {
  name: string;
  showValidAdornment?: boolean;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  maxLength?: number;
}

const FormInput: React.FC<Props> = ({
  name,
  showValidAdornment,
  onKeyPress,
  onKeyDown,
  maxLength,
  ...props
}) => {
  const { control } = useFormContext();
  const { field, meta } = useController({ name, control });
  const { invalid, isDirty } = meta;
  const invalidated = invalid || isDirty;
  const borderStyles = React.useMemo(
    () => ({
      borderRight: invalidated ? 'none' : 'inherit',
      borderTopRightRadius: invalidated ? 0 : 'inherit',
      borderBottomRightRadius: invalidated ? 0 : 'inherit',
    }),
    [invalidated]
  );
  return (
    <CustomInput
      $invalidated={invalidated}
      $invalid={invalid}
      {...field}
      {...props}
      defaultValue={field.value?.length >= 0 ? undefined : ''}
      inputRef={field.ref}
      inputProps={{
        style: borderStyles,
        onKeyPress,
        onKeyDown: e =>
          isFunction(onKeyDown)
            ? onKeyDown?.(e as React.KeyboardEvent<HTMLInputElement>)
            : e.key === 'Enter' && e.preventDefault(),
        autoComplete: 'new-password',
        maxLength,
        ...props.inputProps,
      }}
      startAdornment={props.startAdornment}
      endAdornment={
        props.endAdornment && (
          <ValidationIndicator
            showValidAdornment={showValidAdornment}
            name={name}
          />
        )
      }
    />
  );
};

export default FormInput;

const Input = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: '1.1rem',
        borderRadius: 8,
      },
    },
    input: {
      outline: 'none',
      borderRadius: 10,
      position: 'relative',
      backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
      fontSize: 16,
      width: '100%',
      padding: '10px 12px',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      '&::placeholder': {
        fontSize: '0.9rem',
      },
    },
    adornedEnd: {
      background: '#fcfcfb',
    },
  })
)(OutlinedInput);

const CustomInput = styled(Input)<{
  $invalidated?: boolean;
  $invalid?: boolean;
}>`
  &.MuiInputBase-root {
    ${({ $invalidated }) => rtl`padding-right: ${$invalidated && 0}`};
    height: 48px;
  }

  &.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: ${({ $invalid, theme }) =>
      `1px solid ${
        $invalid ? theme.colors.primary.light : theme.colors.primary.dark
      }`};
  }

  .MuiOutlinedInput-notchedOutline {
    border-radius: 10px;
    border-color: ${({ theme }) => theme.colors.darkGray};
    border: ${({ $invalid, theme }) =>
      $invalid && `1px solid ${theme.colors.primary.light}`};
  }

  .MuiInputAdornment-root {
    padding-right: ${({ $invalidated }) => $invalidated && '14px'};
  }

  && .Mui-disabled {
    background: ${({ theme }) => theme.colors.darkGray} !important;
  }
`;
