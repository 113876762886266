import * as actions from 'store/actions/properties-management/ArchivedPropertiesManagement.actions';
import PropertiesType from 'store/constants/properties-management/PropertiesManagement.constants';
import * as Payload from 'store/types/properties-management/ArchivedProperties.types';
import { ArchivedPropertyTask } from 'types/properties';

export interface ArchivedPropertyTasksState {
  loading: Record<PropertiesType, boolean>;
  error: Record<PropertiesType, null | string>;
  results: ArchivedPropertyTask[];
  pageCount: number;
}

const REQUESTS = [PropertiesType.GET_ARCHIVED_PROPERTIES_DATA_REQUEST];

const initialState: ArchivedPropertyTasksState = {
  loading: REQUESTS.reduce(
    (state, next) => ({ ...state, [next]: false }),
    {} as Record<PropertiesType, boolean>
  ),
  error: REQUESTS.reduce(
    (state, next) => ({ ...state, [next]: null }),
    {} as Record<PropertiesType, null | string>
  ),
  results: [],
  pageCount: 0,
};

const archivedPropertiesTasksReducer = (
  state: ArchivedPropertyTasksState = initialState,
  action: actions.PropertiesTasksAction
): ArchivedPropertyTasksState => {
  switch (action.type) {
    case PropertiesType.GET_ARCHIVED_PROPERTIES_DATA_REQUEST:
      return updateLoading(
        state,
        PropertiesType.GET_ARCHIVED_PROPERTIES_DATA_REQUEST
      );
    case PropertiesType.GET_ARCHIVED_PROPERTIES_DATA_FULFILLED:
      return updateSuccess(
        state,
        PropertiesType.GET_ARCHIVED_PROPERTIES_DATA_REQUEST,
        action.payload as Payload.GetArchivedPropertiesTasksFulfilledPayload
      );
    case PropertiesType.GET_ARCHIVED_PROPERTIES_DATA_REJECTED:
      return updateError(
        state,
        PropertiesType.GET_ARCHIVED_PROPERTIES_DATA_REQUEST,
        action.payload as Payload.GetArchivedPropertiesTasksRejectedPayload
      );
    default:
      return state;
  }
};

export default archivedPropertiesTasksReducer;

type requestType = PropertiesType.GET_ARCHIVED_PROPERTIES_DATA_REQUEST;

const updateLoading = (
  state: ArchivedPropertyTasksState,
  requestType: requestType
) => ({
  ...state,
  loading: {
    ...state.loading,
    [requestType]: true,
  },
  error: {
    ...state.error,
    [requestType]: null,
  },
});

type ErrorPayload = Payload.GetArchivedPropertiesTasksRejectedPayload;
const updateError = (
  state: ArchivedPropertyTasksState,
  requestType: requestType,
  { error }: ErrorPayload
) => ({
  ...state,
  loading: {
    ...state.loading,
    [requestType]: false,
  },
  error: {
    ...state.error,
    [requestType]: error,
  },
});

type SuccessPayload = Payload.GetArchivedPropertiesTasksFulfilledPayload;
const updateSuccess = (
  state: ArchivedPropertyTasksState,
  requestType: requestType,
  payload: SuccessPayload
) => ({
  ...state,
  loading: {
    ...state.loading,
    [requestType]: false,
  },
  error: {
    ...state.error,
    [requestType]: null,
  },
  ...payload,
});
