import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import styled, { css } from 'styled-components';
import Card from 'components/@client/CardList/Card';
import QuantityCounter from 'components/@client/QuantityCounter';
import Tooltip from 'components/Tooltip';
import { useFormattedCurrency } from 'hooks';
import { Message } from 'i18n';
import type { CartItem as ICartItem } from 'types/properties';
import { summarizeText } from 'utils/string.utils';
import { MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';
import ProductPageModal from 'pages/appClient/productPage/component/ProductPageModal';
import { additionalChargeSubCategory } from 'mockData/additionalChargeSubCategory';
import { LogsAPI } from 'api/methods';
import { userLogData } from 'mockData/userLogData';
import { useMediaQuery } from '@material-ui/core';

interface Props extends ICartItem {
  dense?: boolean;
  isLoading?: boolean;
  nameLength?: number;
  onQuantityChange?: (id: string, quantity: number) => void;
  onDelete?: (id: string) => void;
}

const CartItem: React.FC<Props> = ({
  dense,
  id,
  isLoading,
  nameLength = 40,
  quantity,
  product,
  onDelete,
  onQuantityChange,
}) => {
  const intl = useIntl();
  const handleDelete = React.useCallback(() => onDelete?.(id), [id, onDelete]);
  const price = useFormattedCurrency(product?.finalPrice * quantity);
  const isMobile = useMediaQuery(MOBILE_TABLET_RESOLUTION);

  const additionalCharge = additionalChargeSubCategory.includes(
    product.subCategory.name
  );

  return (
    <Container dense={dense}>
      <CustomCard
        img={product.thumbnail?.file}
        alt={product.altName ?? product.englishName}
        overlay={
          product.quantity === 0 && (
            <FormattedMessage id={Message.CART_ITEM_OUT_OF_STOCK_LABEL} />
          )
        }
        tabIndex={-1}
        additionalCharge={additionalCharge}
        onClick={() => {
          LogsAPI.postUserLogs({
            name: userLogData.eventName.productClick,
            area: userLogData.eventArea.myCart,
            section: userLogData.eventSection.cart,
            path: userLogData.eventPath.appCartCartIdPath,
            metadata: { cart_id: id, product_sku: product.id },
          });
        }}
      >
        {onDelete && isMobile && (
          <IconButton
            className="x-button-mobile"
            onClick={() => {
              LogsAPI.postUserLogs({
                name: userLogData.eventName.deleteProduct,
                area: userLogData.eventArea.myCart,
                section: userLogData.eventSection.cart,
                path: userLogData.eventPath.appCartCartIdPath,
                metadata: { cart_id: id, product_sku: product.id },
              });
              LogsAPI.pushDataLayer([
                { ecommerce: null },
                {
                  event: 'removeFromCart',
                  ecommerce: {
                    remove: {
                      products: [
                        {
                          name: product?.englishName,
                          id: product?.id,
                          price: product?.finalPrice,
                          brand: product?.supplier.companyName,
                          category: product?.subCategory,
                          quantity,
                        },
                      ],
                    },
                  },
                },
              ]);
              handleDelete();
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
        <div>
          <Tooltip
            title={product?.altName ?? product?.englishName}
            $variant="info"
          >
            <CustomTitle>
              {summarizeText(
                product?.altName ?? product?.englishName,
                isMobile ? 13 : 30
              )}
            </CustomTitle>
          </Tooltip>
          <p className="design-style" />
          {!isMobile && (
            <p className="subtitle-data">
              {intl.locale === 'he'
                ? product.subCategory.hebrewName
                : product.subCategory.name}
            </p>
          )}
          {isMobile && <p className="cart-item-price">{price}</p>}
          <div className="cart-item-actions">
            {onQuantityChange ? (
              <>
                <QuantityCounter
                  itemId={id}
                  isLoading={isLoading}
                  maxQuantity={product?.quantity}
                  value={quantity}
                  onClick={onQuantityChange}
                  logData={{
                    area: userLogData.eventArea.myCart,
                    section: userLogData.eventSection.cart,
                    name: userLogData.eventName.quantityAdd,
                    path: userLogData.eventPath.appCartCartIdPath,
                    metadata: {
                      cart_id: id,
                      product_sku: product.sku,
                    },
                  }}
                  isMaterial={product.type === 'material'}
                />
                <StockInformer
                  isSufficientQuantity={product.quantity < quantity}
                >
                  <FormattedMessage id={Message.CART_MAXIMUM_TEXT} />{' '}
                  {product.quantity}{' '}
                  <FormattedMessage id={Message.CART_ITEM_STOCK_LABEL} />
                </StockInformer>
              </>
            ) : (
              <span>
                <FormattedMessage id={Message.CHECKOUT_ITEM_QUANTITY_LABEL} />:{' '}
                {quantity}
              </span>
            )}
            {isMobile && (
              <ProductPageModal
                btnOnClick={() => {
                  LogsAPI.postUserLogs({
                    name: userLogData.eventName.viewDetails,
                    area: userLogData.eventArea.myCart,
                    section: userLogData.eventSection.cart,
                    path: userLogData.eventPath.appCartCartIdPath,
                    metadata: { cart_id: id, product_sku: product.id },
                  });
                }}
                product={product.id}
              />
            )}
          </div>
        </div>
        <div>
          {!isMobile && (
            <div className="cart-price-container">
              <p className="cart-item-price">{price}</p>
              <ProductPageModal
                btnOnClick={() => {
                  LogsAPI.postUserLogs({
                    name: userLogData.eventName.viewDetails,
                    area: userLogData.eventArea.myCart,
                    section: userLogData.eventSection.cart,
                    path: userLogData.eventPath.appCartCartIdPath,
                    metadata: { cart_id: id, product_sku: product.id },
                  });
                }}
                product={product.id}
              />
            </div>
          )}
          {onDelete && !isMobile && (
            <IconButton
              className="x-button"
              onClick={() => {
                LogsAPI.postUserLogs({
                  name: userLogData.eventName.deleteProduct,
                  area: userLogData.eventArea.myCart,
                  section: userLogData.eventSection.cart,
                  path: userLogData.eventPath.appCartCartIdPath,
                  metadata: { cart_id: id, product_sku: product.id },
                });
                LogsAPI.pushDataLayer([
                  { ecommerce: null },
                  {
                    event: 'removeFromCart',
                    ecommerce: {
                      remove: {
                        products: [
                          {
                            name: product?.englishName,
                            id: product?.id,
                            price: product?.finalPrice,
                            brand: product?.supplier.companyName,
                            category: product?.subCategory,
                            quantity,
                          },
                        ],
                      },
                    },
                  },
                ]);
                handleDelete();
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </div>
      </CustomCard>
    </Container>
  );
};

export default CartItem;

const Container = styled.li.attrs({ className: 'cart-item' })<{
  dense?: boolean;
}>`
  position: relative;
  & img {
    height: 170px;
    object-fit: contain;
    @media ${MOBILE_TABLET_RESOLUTION} {
      height: 123px;
      width: 119px;
    }
  }
  & .cart-item-actions {
    margin-top: 14px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  & h4 {
    color: ${({ theme }) => theme.colors.black0};
    font-size: 1.125rem;
    font-weight: 500;
    margin: 0;
    padding: 0;

    @media (min-width: 1150px) {
      font-size: 1.5rem;
    }
  }

  & p {
    font-weight: 500;
    font-size: 0.875rem;
    margin: 4px 0 0 0;
    padding: 0;

    @media (min-width: 1150px) {
      font-size: 1.375rem;
    }
  }

  & .card-divider {
    max-width: initial;
  }

  & .subtitle-data {
    font-weight: 400;
    font-size: 0.675rem;
    margin: 4px 0 0 0;
    padding: 0;

    @media (min-width: 1150px) {
      font-size: 1.175rem;
    }
  }

  & .design-style {
    color: rgba(187, 187, 187, 1);
    font-weight: 400;

    @media (min-width: 1150px) {
      font-size: 1.125rem;
    }
  }

  & .x-button {
    height: 30px;
    width: 30px;
    border: 1px solid black;
    border-radius: 20px;
    margin: auto;
  }
  & .x-button-mobile {
    height: 30px;
    width: 30px;
    border: 1px solid black;
    border-radius: 20px;
    position: absolute;
    top: 70px;
    right: 40px;
    @media ${MOBILE_TABLET_RESOLUTION} {
      border: 0;
      img {
        height: 30px;
        width: 30px;
      }
    }
  }

  & div {
    max-width: initial;
    .card-content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .cart-price-container {
        text-align: center;
        display: block !important;
        margin: auto 30px;
        .view-details-btn {
          background: transparent;
          color: ${({ theme }) => theme.colors.primary.lightMobile};
          text-decoration: underline;
        }
      }

      div: last-child {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  ${({ dense }) =>
    dense &&
    css`
      margin: 0 50px;
      max-width: 370px;
      h4,
      p {
        font-size: 1rem !important;
      }

      img {
        width: 120px;
        height: 120px;
      }
    `}
`;

const StockInformer = styled.span.attrs({ className: 'stock-informer' })<{
  isSufficientQuantity?: boolean;
}>`
  margin-top: 6px;
  user-select: none;
  color: ${({ isSufficientQuantity = false }) =>
    isSufficientQuantity ? 'rgba(252, 92, 66, 1)' : 'rgba(187, 187, 187, 1)'};
`;

const CustomCard = styled(Card)`
  width: 100%;
  max-width: initial !important;
  color: black;
`;

const CustomTitle = styled.h4`
  line-height: unset;
`;
