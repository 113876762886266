import * as React from 'react';
import * as Response from 'api/responses';
import * as Transform from 'api/transform';
import { removeEmptyValues } from 'utils/common.utils';
import type { QuoteComponent } from 'types/products';
import useFetch from './useFetch';

import useNotifications from './useNotifications';

const productsSize = 8;

type QuoteComponentResults = { results: QuoteComponent[]; count: number };

const useGetQuoteComponents = (
  propertyId: string,
  selectedRoom: number | null = 1
) => {
  const [currentQuotesComponents, setCurrentQuoteComponent] = React.useState<
    QuoteComponent[] | null
  >(null);
  const [currentPage, setCurrentPage] = React.useState(1);
  const { showNotification } = useNotifications();
  const isGetMoreRef = React.useRef(false);
  const initialParamsRef = React.useRef({
    size: productsSize,
    page: 1,
    room: selectedRoom ?? undefined,
    property: propertyId,
    product__dummy: 'false',
  });

  const onSuccess = React.useCallback((data: QuoteComponentResults | null) => {
    if (isGetMoreRef.current) {
      setCurrentPage(prevPage => prevPage + 1);
    } else {
      setCurrentPage(2);
    }

    setCurrentQuoteComponent(prevProducts => [
      ...(isGetMoreRef.current ? prevProducts ?? [] : []),
      ...(data?.results ?? []),
    ]);

    isGetMoreRef.current = false;
  }, []);

  const onFailure = React.useCallback(
    () =>
      showNotification({
        key: 'products/fetchClientProducts',
        message:
          'We had a problem fetching suggested products, please try again later',
        severity: 'error',
      }),
    [showNotification]
  );

  const { data: productsData, isLoading, callFetch } = useFetch<
    QuoteComponentResults,
    Response.QuoteComponents
  >({
    initialUrl: '/api/quote_components/',
    initialData: { count: 0, results: [] },
    config: { params: initialParamsRef.current },
    transform: Transform.QuoteComponents,
    onFailure,
    onSuccess,
  });

  const {
    count: productsCount,
    results: fetchedProducts,
  } = productsData as QuoteComponentResults;

  const getMoreProducts = React.useCallback(
    (room: number | null) => {
      isGetMoreRef.current = true;
      callFetch({
        params: removeEmptyValues({
          ...initialParamsRef.current,
          page: currentPage,
          room: room !== 0 ? room : null,
        }),
      });
    },
    [callFetch, currentPage]
  );

  const getProducts = React.useCallback(
    (room: number | null = selectedRoom) => {
      callFetch({
        params: removeEmptyValues({
          ...initialParamsRef.current,
          room: room !== 0 ? room : null,
        }),
      });
    },
    [callFetch, selectedRoom]
  );

  return {
    quoteComponents: currentQuotesComponents ?? fetchedProducts,
    productsCount,
    isGetMoreRef,
    isLoading,
    getProducts,
    getMoreProducts,
  };
};

export default useGetQuoteComponents;
