import * as React from 'react';
import styled from 'styled-components';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Type, unityProductUrl } from 'constants/config';
import { useAuthentication, useRouter, useSession } from 'hooks';
import { ClientRouter } from 'router/routes';
import {
  MOBILE_TABLET_MIN_RESOLUTION,
  MOBILE_TABLET_RESOLUTION,
} from 'styles/sharedStyle';
import Main from 'pages/common/layout/Main';
import Footer from 'pages/common/layout/Footer';
import { useScrollDirection } from 'react-use-scroll-direction';
import SearchBarComponent from 'components/@client/SearchBarComponent/SearchBarComponent';
import Modal from 'components/Modal';
import { Types } from 'pages/common/Unity/config';
import DesktopNavBar from './DesktopNavBar';
import MobileNavBar from './MobileNavBar';

const ClientLayout: React.FC = ({ children }) => {
  const isMobile = useMediaQuery(MOBILE_TABLET_RESOLUTION);
  const isTablet = useMediaQuery(
    `${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION}`
  );
  const { pathname, query, history } = useRouter<{ product3dView: string }>();
  const { isScrollingUp, isScrollingDown } = useScrollDirection();
  const iframe3DViewRef = React.useRef<HTMLIFrameElement>(null);
  const [searchHeight, setSearchHeight] = React.useState('0px');
  const setSearchBarHeight = React.useCallback(
    (h: string) => {
      setSearchHeight(h);
    },
    [setSearchHeight]
  );
  // eslint-disable-next-line
  let _window: any = window;

  const { isFromSupportedCountry, isGettingLocation } = useSession();

  React.useEffect(() => {
    if (isScrollingUp) {
      setSearchBarHeight('40px');
    } else if (isScrollingDown) {
      setSearchBarHeight('0px');
    }
  }, [isScrollingUp, isScrollingDown, setSearchBarHeight]);

  React.useEffect(() => {
    if (query.product3dView) {
      iframe3DViewRef?.current?.contentWindow?.postMessage(
        JSON.stringify({
          type: Types.GET_PRODUCT_INFO,
          payload: {
            product_Id: Number(query.product3dView),
            pause: false,
          },
        }),
        unityProductUrl
      );
    } else {
      iframe3DViewRef?.current?.contentWindow?.postMessage(
        JSON.stringify({
          type: Types.GET_PRODUCT_INFO,
          payload: {
            pause: true,
          },
        }),
        unityProductUrl
      );
    }
  }, [query]);

  const addMessageListener = React.useCallback(() => {
    _window.vuplex.addEventListener('message', (event: { data: string }) => {
      const evData: { data: { lidar: boolean } } = JSON.parse(event.data);

      _window.HAS_LIDAR = evData.data.lidar;
    });
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (_window.vuplex) {
      addMessageListener();
    } else {
      window.addEventListener('vuplexready', addMessageListener);
    }
    // eslint-disable-next-line
  }, []);

  // const closeSaleModal = React.useCallback(() => {
  //   setIsSaleModalOpen(false);
  //   // setIsOpen(true);
  // }, []);

  // const [countIntervalId, setCountIntervalId] = React.useState<number>();
  // const [showBanner, setShowBanner] = React.useState(true);

  // React.useEffect(() => {
  //   if (pathname === ClientRouter.APP && !countIntervalId) {
  //     const intervalId = _window.setInterval(
  //       () => setShowBanner(prev => !prev),
  //       10000
  //     );
  //     setCountIntervalId(intervalId);
  //   } else if (pathname !== ClientRouter.APP && countIntervalId) {
  //     clearInterval(countIntervalId);
  //   }
  // }, [_window, countIntervalId, pathname]);

  const { type } = useAuthentication();
  return (
    <div style={{ overflow: 'hidden' }}>
      {/* {pathname === ClientRouter.APP &&
        !isMobile &&
        !isGettingLocation &&
        locale === 'he' && (
          <div
            style={{ backgroundColor: '#FAFCFE', cursor: 'pointer' }}
            onClick={() => history.push(ClientRouter.SALES_PAGE)}
          >
            {showBanner && (
              <Fade
                in={showBanner}
                timeout={1000}
                style={{
                  top: 0,
                  left: 'auto',
                  right: 0,
                  zIndex: 1100,
                  position: 'fixed',
                  backgroundColor: '#FAFCFE',
                }}
              >
                <SliderDiv isMobile={isMobile}>
                  <div>
                    <VectorContainer>
                      <img src={vector} alt="flower" />
                      <img className="middle" src={vector} alt="flower" />
                      <img src={vector} alt="flower" />
                    </VectorContainer>
                    פסח 2022 ב-Patricia{' '}
                    <span className="orange">
                      מוצרים נבחרים במחירים מיוחדים שיעשו לכם את החג
                    </span>
                    <VectorContainer>
                      <img src={vector} alt="flower" />
                      <img className="middle" src={vector} alt="flower" />
                      <img src={vector} alt="flower" />
                    </VectorContainer>
                  </div>
                </SliderDiv>
              </Fade>
            )}
            {!showBanner && (
              <Fade
                in={!showBanner}
                timeout={1000}
                style={{
                  top: 0,
                  left: 'auto',
                  right: 0,
                  zIndex: 1100,
                  position: 'fixed',
                  backgroundColor: '#FAFCFE',
                }}
              >
                <SliderDiv isMobile={isMobile}>
                  <div>
                    <VectorContainer>
                      <img src={vector} alt="flower" />
                      <img className="middle" src={vector} alt="flower" />
                      <img src={vector} alt="flower" />
                    </VectorContainer>
                    פסח 2022 ב-Patricia{' '}
                    <span className="orange">משלוחים חינם לאורך כל הפסח!</span>
                    <VectorContainer>
                      <img src={vector} alt="flower" />
                      <img className="middle" src={vector} alt="flower" />
                      <img src={vector} alt="flower" />
                    </VectorContainer>
                  </div>
                </SliderDiv>
              </Fade>
            )}
          </div>
        )} */}
      {/* {pathname === ClientRouter.APP &&
        isMobile &&
        !isGettingLocation &&
        locale === 'he' && (
          <SliderComponent
            locale={locale}
            itemLength={2}
            onClick={() => history.push(ClientRouter.SALES_PAGE)}
          >
            <SliderDiv isMobile={isMobile}>
              <div>
                <VectorContainer>
                  <img src={vector} alt="flower" />
                  <img className="middle" src={vector} alt="flower" />
                  <img src={vector} alt="flower" />
                </VectorContainer>
                פסח 2022 ב-Patricia{' '}
                <span className="orange">
                  מוצרים נבחרים במחירים מיוחדים שיעשו לכם את החג
                </span>
                <VectorContainer>
                  <img src={vector} alt="flower" />
                  <img className="middle" src={vector} alt="flower" />
                  <img src={vector} alt="flower" />
                </VectorContainer>
              </div>
            </SliderDiv>
            <SliderDiv isMobile={isMobile}>
              <div>
                <VectorContainer>
                  <img src={vector} alt="flower" />
                  <img className="middle" src={vector} alt="flower" />
                  <img src={vector} alt="flower" />
                </VectorContainer>
                פסח 2022 ב-Patricia{' '}
                <span className="orange">משלוחים חינם לאורך כל הפסח!</span>
                <VectorContainer>
                  <img src={vector} alt="flower" />
                  <img className="middle" src={vector} alt="flower" />
                  <img src={vector} alt="flower" />
                </VectorContainer>
              </div>
            </SliderDiv>
            <SliderDiv isMobile={isMobile}>
              <div>
                <VectorContainer>
                  <img src={vector} alt="flower" />
                  <img className="middle" src={vector} alt="flower" />
                  <img src={vector} alt="flower" />
                </VectorContainer>
                פסח 2022 ב-Patricia{' '}
                <span className="orange">
                  מוצרים נבחרים במחירים מיוחדים שיעשו לכם את החג
                </span>
                <VectorContainer>
                  <img src={vector} alt="flower" />
                  <img className="middle" src={vector} alt="flower" />
                  <img src={vector} alt="flower" />
                </VectorContainer>
              </div>
            </SliderDiv>
            <SliderDiv isMobile={isMobile}>
              <div>
                <VectorContainer>
                  <img src={vector} alt="flower" />
                  <img className="middle" src={vector} alt="flower" />
                  <img src={vector} alt="flower" />
                </VectorContainer>
                פסח 2022 ב-Patricia{' '}
                <span className="orange">משלוחים חינם לאורך כל הפסח!</span>
                <VectorContainer>
                  <img src={vector} alt="flower" />
                  <img className="middle" src={vector} alt="flower" />
                  <img src={vector} alt="flower" />
                </VectorContainer>
              </div>
            </SliderDiv>
          </SliderComponent>
        )} */}
      {!isMobile &&
        !isGettingLocation &&
        !pathname.includes('iframe') &&
        !(
          type === Type.SELLER_CLIENT && pathname.includes('app/properties/')
        ) && <DesktopNavBar />}
      {isTablet &&
        pathname.includes('/app/sellerProperties') &&
        !pathname.includes('iframe') &&
        !isGettingLocation && <DesktopNavBar />}

      {isMobile &&
        !pathname.includes('/tour') &&
        pathname === ClientRouter.BECOME_A_SELLER && (
          <CustomSearchBar searchHeight={searchHeight}>
            <SearchBarComponent setSearchBarHeight={setSearchBarHeight} />
          </CustomSearchBar>
        )}
      <ClientMain
        withBrandCarousel={pathname === ClientRouter.APP}
        userType={Type.CLIENT}
        className="main"
        isMobile={isMobile && !isTablet}
        withNavbar={
          !pathname.includes('/onboarding') &&
          !pathname.includes('/auth') &&
          !pathname.includes('/tour') &&
          !pathname.includes('/terms-of-use') &&
          !pathname.includes('/privacy-policy') &&
          !pathname.includes('/become-a-seller') &&
          !pathname.includes('/skins') &&
          !pathname.includes('/app/landing/') &&
          !pathname.includes('iframe')
        }
        pathname={pathname}
      >
        <ClientPageRoot
          fullWidth={
            pathname.includes('/app/auth') ||
            pathname === ClientRouter.APP ||
            pathname.includes('/become-a-seller') ||
            pathname === ClientRouter.BECOME_A_SELLER ||
            pathname === ClientRouter.SKINS ||
            pathname.includes(ClientRouter.IDEAS_PAGE) ||
            pathname === ClientRouter.CONTACT ||
            pathname.includes('/app/properties/') ||
            pathname.includes(ClientRouter.ONBOARDING) ||
            pathname.includes('/app/landing/') ||
            pathname.includes('/app/supplier') ||
            pathname.includes('/app/skins/') ||
            pathname.includes('iframe') ||
            pathname.includes('sellerProperties/project/') ||
            pathname.includes('/app/sellerProperties')
          }
        >
          {!pathname.includes('app/tour/') &&
            pathname.includes(ClientRouter.APP) &&
            !isMobile &&
            !_window.vuplex && (
              <Custom3DViewModal
                width="100vw"
                height="100vh"
                fullScreen={isMobile}
                open={true}
                show={Boolean(query.product3dView)}
                close={() => {
                  history.replace(pathname);
                }}
                overrideZIndex={false}
                actionStyles={{
                  position: 'fixed',
                  top: '10px',
                  right: '10px',
                }}
              >
                <iframe
                  ref={iframe3DViewRef}
                  title="3D product view"
                  src="https://patricia-webgl-product-preview.s3.eu-central-1.amazonaws.com/index.html"
                  style={{ height: '100vh', width: '100%', border: 'none' }}
                  id="3dviewframe"
                />
              </Custom3DViewModal>
            )}

          {children}
        </ClientPageRoot>
      </ClientMain>

      {!pathname.includes('/tour') &&
        !pathname.includes('/auth') &&
        !pathname.includes('/terms-of-use') &&
        !pathname.includes('/privacy-policy') &&
        !pathname.includes(ClientRouter.ONBOARDING) &&
        !pathname.includes('/app/landing/') &&
        !pathname.includes('/app/supplier') &&
        !pathname.includes('iframe') &&
        !pathname.includes('app/sellerProperties') &&
        !(
          type === Type.SELLER_CLIENT && pathname.includes('app/properties/')
        ) && <Footer />}
      {isMobile &&
        !pathname.includes('/app/sellerProperties') &&
        isFromSupportedCountry &&
        !isGettingLocation &&
        !pathname.includes('/landing/') &&
        !pathname.includes('iframe') &&
        !pathname.includes('iframe') &&
        type !== Type.SELLER_CLIENT && <MobileNavBar />}
      {isMobile &&
        !isTablet &&
        pathname.includes('/app/sellerProperties') &&
        isFromSupportedCountry &&
        !pathname.includes('iframe') &&
        !pathname.includes('/landing/') &&
        !isGettingLocation && <MobileNavBar />}
    </div>
  );
};

export default ClientLayout;

const ClientPageRoot = styled.section.attrs({ className: 'client-page-root' })<{
  fullWidth?: boolean;
}>`
  color: #000000;
  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: ${({ fullWidth = false }) => (fullWidth ? 'unset' : '1600px')};
`;

const ClientMain = styled(Main)<{ pathname: string; isMobile: boolean }>`
  overflow: ${({ pathname }) =>
    pathname === ClientRouter.CART ? 'scroll' : 'hidden'};
  ${({ theme }) => theme.utils.scrollbar};
  width: 100%;
  min-height: 100vh;
  height: auto;
  /* Prevent from top navbar to hide items */
  padding-top: ${({ withBrandCarousel, withNavbar, isMobile }) => {
    return withBrandCarousel || !withNavbar || isMobile ? 0 : 60;
  }}px;

  top: 0;
  left: 0;
  background: #ffffff;
`;

const CustomSearchBar = styled.div<{ searchHeight: string }>`
  height: ${({ searchHeight }) => searchHeight};
  transition: height 0.4s ease-in-out;
  position: fixed;
  top: 64px;
  width: 100vw;
  z-index: 9999;
  overflow: hidden;
`;

export const Custom3DViewModal = styled(Modal)<{ show: boolean }>`
  width: 100vw;
  height: 100vh;
  display: ${({ show }) => (show ? 'block' : 'none')};
  margin-top: 0px important;
  .MuiDialog-paperScrollPaper {
    max-height: 100%;
  }
  z-index: 9999 !important;
`;
// const ModalContainer = styled(FreeDeliveryModal)`
//   &&.client-dialog .MuiDialog-paper .MuiDialogTitle-root {
//     margin: 10px auto;
//     padding: 10px 24px;
//     font-size: 1.25rem;
//     h2 {
//       font-weight: 700;
//     }
//   }

//   && .MuiDialog-paper {
//     overflow: visible;
//   }
//   & .MuiDialog-paperWidthSm {
//     max-width: 450px;
//   }

//   & .MuiDialogContent-root {
//     padding: 8px 50px;
//     margin-bottom: 35px;
//     color: rgba(21, 25, 32, 0.5);
//   }
// `;

// const SalesModal = styled(SaleModal)`
//   &&.client-dialog .MuiDialog-paper .MuiDialogTitle-root {
//     margin: 10px auto;
//     padding: 10px 24px;
//     font-size: 1.25rem;
//     h2 {
//       font-weight: 700;
//     }
//   }

//   && .MuiDialog-paper {
//     overflow: visible;
//   }
//   & .MuiDialog-paperWidthSm {
//     max-width: 450px;
//   }

//   & .MuiDialogContent-root {
//     padding: 8px 50px;
//     margin-bottom: 35px;
//     color: rgba(21, 25, 32, 0.5);
//   }
// `;

// const VectorContainer = styled.div`
//   margin: 2px 13px;

//   img {
//     margin: auto 6px;
//     width: 14px;
//   }
//   .middle {
//     margin: -1px 0;
//     width: 18px;
//   }
// `;

// const StyledKeyFrames = (itemLength: number, locale: string) => keyframes`
//   0% {
//     transform: translate3d(0, 0, 0);
//   }

//   100% {

//     transform: translate3d(${
//       locale === 'en' ? -25 * itemLength : 25 * itemLength
//     }%, 0, 0);
//   }
// `;

// const SliderComponent = styled.div<{
//   itemLength: number;
//   locale: string;
// }>`
//   display: flex;
//   overflow-y: hidden;
//   white-space: nowrap;
//   width: 400vw;
//   height: 56px;
//   animation: ${({ itemLength, locale }) => StyledKeyFrames(itemLength, locale)}
//     ${({ itemLength }) => itemLength * 30}s linear infinite;
// `;

// const SliderDiv = styled.div<{ isMobile: boolean }>`
//   & > div {
//     margin: auto;
//     display: flex;
//     color: rgba(17, 81, 109, 1);
//     .orange {
//       color: ${({ theme }) => theme.colors.primary.lightMobile};
//       margin-right: 14px;
//     }
//   }
//   font-weight: 600;
//   overflow: hidden;
//   font-size: 18px;
//   display: flex;
//   width: ${({ isMobile }) => (isMobile ? 'auto' : '100vw')};
//   margin: auto 0;
//   height: 56px;
// `;
