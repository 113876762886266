import * as React from 'react';
import Button from 'components/@client/Button';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';

interface Props {
  isLocked?: boolean;
  isLoading?: boolean;
  free?: boolean;
  onClick: VoidFunction;
}

const SkinApplyButton: React.FC<Props> = ({
  free,
  isLocked,
  isLoading,
  onClick,
}) => {
  return (
    <Button onClick={onClick} className="buy-button" isLoading={isLoading}>
      {free ? (
        <FormattedMessage id={Message.SKIN_APPLY_SKIN} />
      ) : (
        <FormattedMessage
          id={!isLocked ? Message.SKIN_APPLY_SKIN : Message.SKIN_BUY_IT_NOW}
        />
      )}
    </Button>
  );
};

export default SkinApplyButton;
