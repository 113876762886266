import * as React from 'react';
import styled from 'styled-components';
import Breadcrumbs from 'components/@client/Breadcrumbs';
import { ClientRouter } from 'router/routes';
import { FormattedMessage, useIntl } from 'react-intl';
import { Message } from 'i18n';
import {
  useAuthentication,
  useFetch,
  useNotifications,
  useRouter,
} from 'hooks';
import contactImage from 'assets/@client/contactUs/contact.png';
import EmailIcon from 'assets/@client/contactUs/email.png';
import PhoneIcon from 'assets/@client/contactUs/phone.png';
import { FormProvider, useForm } from 'react-hook-form';
import TextInput from 'components/@client/form-components/TextInput';
import Button from 'components/Button';
import { ContactTechnicalDetails } from 'types/clients';
import { PhoneField } from 'components/forms';
import { yupResolver } from '@hookform/resolvers/yup';
import rtl from 'styled-components-rtl';
import {
  contactCustomerSchema,
  contactTechnicalSchema,
} from 'utils/validation.utils';
import { ReactComponent as SuccessCheck } from 'assets/icons/successCheck.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/infoIcon.svg';
import { removeEmptyValues } from 'utils/common.utils';
import { METHODS } from 'api/client';
import { ClientSelectFilter as StatusFilter } from 'components/Filters';
import { Option } from 'components/Autocomplete/Autocomplete.config';
import history from 'router/history';
import { ClientBasicContactOption } from 'components/Filters/Options';
import { useMediaQuery } from '@material-ui/core';
import { MOBILE_RESOLUTION } from 'styles/sharedStyle';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import TextArea from './components/FormTextArea';
import { Form } from '../checkout/Checkout-new.web';
import ContactInfoSection from './ContactInfoSection';

export enum SupportData {
  CUSTOMER_SUPPORT = 'Customer support',
  TECHNICAL_SUPPORT = 'Technical support',
}

const Contact = () => {
  const { query } = useRouter<{ backTo?: string; id: string }>();
  const intl = useIntl();
  const isMobile = useMediaQuery(MOBILE_RESOLUTION);

  const menuData = [
    {
      id: 1,
      name: intl.formatMessage({
        id: Message.CONTACT_INFO_DEPARTMENT_OPTION1,
      }),
      value: SupportData.CUSTOMER_SUPPORT,
    },
    {
      id: 2,
      name: intl.formatMessage({
        id: Message.CONTACT_INFO_DEPARTMENT_OPTION2,
      }),
      value: SupportData.TECHNICAL_SUPPORT,
    },
  ];

  const problemAreaData = [
    {
      id: 1,
      name: intl.formatMessage({
        id: Message.CONTACT_INFO_PUBLIC_AREA_OPTION1,
      }),
      value: 'Website on the computer',
    },
    {
      id: 2,
      name: intl.formatMessage({
        id: Message.CONTACT_INFO_PUBLIC_AREA_OPTION2,
      }),
      value: 'Website on the phone',
    },
    {
      id: 3,
      name: intl.formatMessage({
        id: Message.CONTACT_INFO_PUBLIC_AREA_OPTION3,
      }),
      value: 'Website on the tablet',
    },
    {
      id: 4,
      name: intl.formatMessage({
        id: Message.CONTACT_INFO_PUBLIC_AREA_OPTION4,
      }),
      value: 'Website in the phone app',
    },
    {
      id: 5,
      name: intl.formatMessage({
        id: Message.CONTACT_INFO_PUBLIC_AREA_OPTION5,
      }),
      value: 'In 3D on the computer',
    },
    {
      id: 6,
      name: intl.formatMessage({
        id: Message.CONTACT_INFO_PUBLIC_AREA_OPTION6,
      }),
      value: 'In 3D on the tablet',
    },
    {
      id: 7,
      name: intl.formatMessage({
        id: Message.CONTACT_INFO_PUBLIC_AREA_OPTION7,
      }),
      value: 'In 3D on the phone',
    },
  ];
  const deviceData = [
    {
      name: intl.formatMessage({
        id: Message.CONTACT_INFO_DEVICE_INFO_OPTION1,
      }),
      value: 'iPhone',
    },
    {
      name: intl.formatMessage({
        id: Message.CONTACT_INFO_DEVICE_INFO_OPTION2,
      }),
      value: 'Android',
    },
    {
      name: intl.formatMessage({
        id: Message.CONTACT_INFO_DEVICE_INFO_OPTION3,
      }),
      value: 'Apple Tablet',
    },
    {
      name: intl.formatMessage({
        id: Message.CONTACT_INFO_DEVICE_INFO_OPTION4,
      }),
      value: 'Android Tablet',
    },
  ];

  const [supportType, setSupportType] = React.useState(menuData[0].value);
  const [problemArea, setProblemArea] = React.useState<string>(
    problemAreaData[0].value
  );
  const [device, setDevice] = React.useState(deviceData[0].value);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const { firstName } = useAuthentication();

  const methods = useForm<ContactTechnicalDetails>({
    resolver:
      supportType === 'Customer support'
        ? yupResolver(contactCustomerSchema)
        : yupResolver(contactTechnicalSchema),
    defaultValues: {},
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });

  const { control, handleSubmit } = methods;

  const handleChange = React.useCallback(
    (obj: Option[]) => {
      const supportValue = obj[0]?.value as SupportData;
      setSupportType(supportValue);
      methods.reset({
        email: '',
        phoneNumber: '',
        deviceversion: '',
        description: '',
      });
      setDevice(deviceData[0].value);
      setProblemArea(problemAreaData[0].value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const links = [
    {
      to: query.backTo ?? ClientRouter.CONTACT,
      children: query.backTo ? (
        'My inspiration quizzes'
      ) : (
        <FormattedMessage id={Message.MENU_ITEM_CONTACT} />
      ),
    },
  ];

  const { showNotification } = useNotifications();

  const onFailure = React.useCallback(
    (message: string) => {
      showNotification({
        key: 'contactUs',
        message,
        severity: 'error',
      });
    },
    [showNotification]
  );

  const { callFetch, isLoading } = useFetch<{ status: string }>({
    initialUrl: '/api/general/contact_us/',
    skipOnStart: true,
    config: {
      method: METHODS.POST,
    },
    onSuccess: (data: { status: string } | null) => {
      methods.reset({
        email: '',
        phoneNumber: '',
        deviceversion: '',
        description: '',
      });
      setProblemArea(problemAreaData[0].value);
      setDevice(deviceData[0].value);
      setShowSuccess(true);
    },
    onFailure,
  });

  const onSubmit = React.useCallback(
    (payload: ContactTechnicalDetails) => {
      const data = {
        department: supportType,
        email: payload.email,
        description: payload?.description,
        problem_area: problemArea,
        device,
        device_version: payload?.deviceversion,
        phone_number: payload?.phoneNumber
          ? `+${payload?.phoneNumber}`
          : payload?.phoneNumber,
      };

      callFetch({ data: removeEmptyValues(data), method: METHODS.POST });
    },
    [callFetch, device, problemArea, supportType]
  );
  const formContainerRef = React.useRef(null);

  const isShowDevice =
    supportType === 'Technical support' &&
    problemArea !== problemAreaData[0].value &&
    problemArea !== problemAreaData[4].value;

  if (isMobile)
    return (
      <MainContainer>
        <FormContainer ref={formContainerRef}>
          <FormProvider {...methods}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <PageHeaderSection
                fillColor={
                  supportType === SupportData.CUSTOMER_SUPPORT
                    ? '#fc5c42'
                    : '#11516D'
                }
              >
                <CustomArrowContainer onClick={() => history.goBack()}>
                  {intl.locale === 'he' ? (
                    <KeyboardArrowRight fontSize="large" />
                  ) : (
                    <KeyboardArrowLeft fontSize="large" />
                  )}
                </CustomArrowContainer>
                <HeaderTitleContainer>
                  <FormattedMessage id={Message.MENU_ITEM_CONTACT} />
                </HeaderTitleContainer>
                <ContactDetailContainer>
                  <MailContainer>
                    <EmailIconContainer src={EmailIcon} alt="email-icon" />
                    <StyledSpan>team@patricia-ai.com</StyledSpan>
                  </MailContainer>
                  <MainPhoneContainer>
                    <MailContainer>
                      <PhoneIconContainer src={PhoneIcon} alt="phone-icon" />
                      <StyledSpan>+972 52 656 7777</StyledSpan>
                    </MailContainer>
                    <MailContainer>
                      <PhoneIconContainer src={PhoneIcon} alt="phone-icon" />
                      <StyledSpan>+1 846 665 654</StyledSpan>
                    </MailContainer>
                  </MainPhoneContainer>
                </ContactDetailContainer>
                <CustomServiceTypeSelection>
                  <p className="desc">
                    <FormattedMessage id={Message.CONTACT_INFO_DEPARTMENT} />
                  </p>
                  <StatusFilter
                    options={menuData && menuData}
                    placeholder={supportType}
                    selectedPlaceholder={supportType}
                    onApply={handleChange}
                    renderOption={ClientBasicContactOption}
                    multiple={false}
                    customIcon={true}
                    buttonClassName="selectButton"
                    initialState={[
                      {
                        name: intl.formatMessage({
                          id: Message.CONTACT_INFO_DEPARTMENT_OPTION1,
                        }),
                        value: supportType,
                      },
                    ]}
                    deselectOnReselect={false}
                    parentRef={formContainerRef}
                  />
                </CustomServiceTypeSelection>
              </PageHeaderSection>
              {/* customer support form start */}
              {showSuccess ? (
                <ContentContainer>
                  <SuccessCheck />
                  <TextSection>
                    <SectionTitle>
                      {' '}
                      <FormattedMessage
                        id={Message.CONTACT_INFO_THANK_YOU}
                      />{' '}
                      {firstName ?? firstName},
                    </SectionTitle>{' '}
                    <br />
                    <SectionSubTitle>
                      <FormattedMessage id={Message.CONTACT_INFO_BACK} />
                    </SectionSubTitle>
                  </TextSection>

                  <Button
                    className="contact-back-button"
                    variant="contained"
                    color="inherit"
                    type="submit"
                    disabled={false}
                    onClick={() => {
                      history.push(ClientRouter.APP);
                    }}
                    isLoading={isLoading}
                  >
                    <FormattedMessage id={Message.UPLOAD_BUTTON_BACK_TO_HOME} />
                  </Button>
                </ContentContainer>
              ) : (
                <MobileFormContainer>
                  {supportType === SupportData.CUSTOMER_SUPPORT && (
                    <TitleWithIcon>
                      <InfoIcon fill="#282929" />
                      <SubTitle>
                        {intl.formatMessage({
                          id: Message.CHECKOUT_PERSONAL_DETAIL_TITLE,
                        })}
                      </SubTitle>
                    </TitleWithIcon>
                  )}
                  <div className="email-container">
                    <TextInputField
                      name="email"
                      control={control}
                      placeholder={intl.formatMessage({
                        id: Message.CONTACT_EMAIL_ADDRESS,
                      })}
                      label={intl.formatMessage({
                        id: Message.CONTACT_EMAIL_ADDRESS,
                      })}
                      type="email"
                      required
                    />
                  </div>
                  {supportType === SupportData.CUSTOMER_SUPPORT && (
                    <>
                      <PhoneNumber
                        name="phoneNumber"
                        label={{
                          title: intl.formatMessage({
                            id: Message.PHONE_NUMBER,
                          }),
                        }}
                        required
                        prefix="+"
                      />
                    </>
                  )}
                  {supportType === SupportData.TECHNICAL_SUPPORT && (
                    <div className="dropdown-container">
                      <p className="desc">
                        <FormattedMessage
                          id={Message.CONTACT_INFO_PUBLIC_AREA}
                        />
                      </p>
                      <CustomSelect>
                        <StatusFilter
                          options={problemAreaData && problemAreaData}
                          placeholder={problemArea}
                          selectedPlaceholder={problemArea}
                          onApply={e => {
                            setProblemArea(e[0]?.value as string);
                          }}
                          renderOption={ClientBasicContactOption}
                          multiple={false}
                          customIcon={true}
                          buttonClassName="selectButton"
                          initialState={[
                            {
                              name: intl.formatMessage({
                                id: Message.CONTACT_INFO_PUBLIC_AREA_OPTION1,
                              }),
                              value: problemArea,
                            },
                          ]}
                        />
                      </CustomSelect>
                    </div>
                  )}

                  {isShowDevice && (
                    <>
                      <div className="dropdown-container ">
                        <p className="desc mt">
                          <FormattedMessage
                            id={Message.CONTACT_INFO_DEVICE_INFO}
                          />
                        </p>
                        <CustomSelect className="device-type">
                          <StatusFilter
                            width={158}
                            options={deviceData && deviceData}
                            placeholder={device}
                            selectedPlaceholder={device}
                            onApply={item => {
                              setDevice(item[0]?.value as string);
                            }}
                            renderOption={ClientBasicContactOption}
                            multiple={false}
                            customIcon={true}
                            buttonClassName="selectButton"
                            initialState={[
                              {
                                name: intl.formatMessage({
                                  id: Message.CONTACT_INFO_DEVICE_INFO_OPTION1,
                                }),
                                value: device,
                              },
                            ]}
                          />
                        </CustomSelect>
                      </div>
                    </>
                  )}
                  {isShowDevice && (
                    <div className="mt">
                      <TextInputField
                        name="deviceVersion"
                        control={control}
                        label={intl.formatMessage({
                          id: Message.CONTACT_INFO_DEVICE_INFO_VERSION,
                        })}
                        type="text"
                        required
                      />
                    </div>
                  )}

                  <div
                    className="description-container"
                    style={{
                      marginTop:
                        supportType === SupportData.CUSTOMER_SUPPORT ? 24 : 0,
                    }}
                  >
                    <TextArea
                      label={{
                        title: intl.formatMessage({
                          id: Message.CONTACT_INFO_DESCRIPTION,
                        }),
                        parenthesesContent: '',
                      }}
                      name="description"
                      placeholder={intl.formatMessage({
                        id: Message.CONTACT_ENTER_YOUR_MESSAGE,
                      })}
                      control={control}
                      limit={supportType === 'Customer support' ? 2000 : 4000}
                      subTitle={
                        supportType === SupportData.TECHNICAL_SUPPORT &&
                        !isMobile
                          ? `Please provide a detailed description of the error you encountered.\n The more information you provide the easier and faster it will be for our developers to find and fix the issue`
                          : null
                      }
                    />
                  </div>
                </MobileFormContainer>
              )}
            </Form>
          </FormProvider>
          {!showSuccess && (
            <ActionContainer>
              <Button
                className="main-button"
                variant="contained"
                color="inherit"
                type="submit"
                disabled={false}
                onClick={handleSubmit(onSubmit)}
                isLoading={isLoading}
              >
                <FormattedMessage id={Message.CONTACT_SEND} />
              </Button>
            </ActionContainer>
          )}
        </FormContainer>
      </MainContainer>
    );
  return (
    <Root>
      {/* <div data-tf-widget="Mxe0eAiC" /> */}
      <Container>
        <Breadcrumbs links={links} />
        <TopTitle>
          <FormattedMessage id={Message.MENU_ITEM_CONTACT} />
        </TopTitle>
        <ContactCard>
          <ContactInfo>
            <ImageContainer>
              <img src={contactImage} alt="name" />
            </ImageContainer>
            <ContactInfoSection />
          </ContactInfo>
          {!showSuccess && (
            <FormContainer ref={formContainerRef}>
              <FormProvider {...methods}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <div className="dropdown-container">
                    <p className="desc">
                      <FormattedMessage id={Message.CONTACT_INFO_DEPARTMENT} />
                    </p>
                    <CustomSelect>
                      <StatusFilter
                        options={menuData && menuData}
                        placeholder={supportType}
                        selectedPlaceholder={supportType}
                        onApply={handleChange}
                        renderOption={ClientBasicContactOption}
                        multiple={false}
                        customIcon={true}
                        buttonClassName="selectButton"
                        initialState={[
                          {
                            name: intl.formatMessage({
                              id: Message.CONTACT_INFO_DEPARTMENT_OPTION1,
                            }),
                            value: supportType,
                          },
                        ]}
                        deselectOnReselect={false}
                        parentRef={formContainerRef}
                      />
                    </CustomSelect>
                  </div>
                  {supportType === 'Customer support' && (
                    <TitleWithIcon>
                      <InfoIcon fill="#282929" />
                      <SubTitle>
                        {intl.formatMessage({
                          id: Message.CHECKOUT_PERSONAL_DETAIL_TITLE,
                        })}
                      </SubTitle>
                    </TitleWithIcon>
                  )}
                  <div className="email-container">
                    <TextInputField
                      name="email"
                      control={control}
                      label={intl.formatMessage({
                        id: Message.AUTH_LOGIN_EMAIL_FIELD,
                      })}
                      type="email"
                      required
                    />
                  </div>
                  {supportType === 'Customer support' && (
                    <>
                      <PhoneNumber
                        name="phoneNumber"
                        label={{
                          title: intl.formatMessage({
                            id: Message.PHONE_NUMBER,
                          }),
                        }}
                        required
                        prefix="+"
                      />
                    </>
                  )}

                  {supportType === SupportData.TECHNICAL_SUPPORT && (
                    <div className="dropdown-container">
                      <p className="desc">
                        <FormattedMessage
                          id={Message.CONTACT_INFO_PUBLIC_AREA}
                        />
                      </p>
                      <CustomSelect>
                        <StatusFilter
                          options={problemAreaData && problemAreaData}
                          placeholder={problemArea}
                          selectedPlaceholder={problemArea}
                          onApply={e => {
                            setProblemArea(e[0]?.value as string);
                          }}
                          renderOption={ClientBasicContactOption}
                          multiple={false}
                          customIcon={true}
                          buttonClassName="selectButton"
                          initialState={[
                            {
                              name: intl.formatMessage({
                                id: Message.CONTACT_INFO_PUBLIC_AREA_OPTION1,
                              }),
                              value: problemArea,
                            },
                          ]}
                        />
                      </CustomSelect>
                    </div>
                  )}
                  <div className="commonRow">
                    {isShowDevice && (
                      <>
                        <div className="dropdown-container ">
                          <p className="desc mtm">
                            <FormattedMessage
                              id={Message.CONTACT_INFO_DEVICE_INFO}
                            />
                          </p>
                          <CustomSelect className="device-type">
                            <StatusFilter
                              width={158}
                              options={deviceData && deviceData}
                              placeholder={device}
                              selectedPlaceholder={device}
                              onApply={item => {
                                setDevice(item[0]?.value as string);
                              }}
                              renderOption={ClientBasicContactOption}
                              multiple={false}
                              customIcon={true}
                              buttonClassName="selectButton"
                              initialState={[
                                {
                                  name: intl.formatMessage({
                                    id:
                                      Message.CONTACT_INFO_DEVICE_INFO_OPTION1,
                                  }),
                                  value: device,
                                },
                              ]}
                            />
                          </CustomSelect>
                        </div>
                      </>
                    )}
                    {isShowDevice && (
                      <div className="mtm">
                        <TextInputField
                          name="deviceVersion"
                          control={control}
                          label={intl.formatMessage({
                            id: Message.CONTACT_INFO_DEVICE_INFO_VERSION,
                          })}
                          type="text"
                          required
                        />
                      </div>
                    )}
                  </div>
                  <div
                    className="description-container"
                    style={{
                      // marginTop: supportType === 'Customer support' ? 24 : 0,
                      marginTop: 24,
                    }}
                  >
                    <TextArea
                      label={{
                        title: intl.formatMessage({
                          id: Message.CONTACT_INFO_DESCRIPTION,
                        }),
                      }}
                      name="description"
                      placeholder="description"
                      control={control}
                      limit={supportType === 'Customer support' ? 2000 : 4000}
                      subTitle={
                        supportType === 'Customer support'
                          ? null
                          : `Please provide a detailed description of the error you encountered.\n The more information you provide the easier and faster it will be for our developers to find and fix the issue`
                      }
                    />
                  </div>
                </Form>
              </FormProvider>
              <ActionContainer>
                <Button
                  className="main-button"
                  variant="contained"
                  color="inherit"
                  type="submit"
                  disabled={false}
                  onClick={handleSubmit(onSubmit)}
                  isLoading={isLoading}
                >
                  <FormattedMessage id={Message.CONTACT_SEND} />
                </Button>
              </ActionContainer>
            </FormContainer>
          )}
          {showSuccess && (
            <ContentContainer>
              <SuccessCheck />
              <TextSection>
                <SectionTitle>
                  {' '}
                  <FormattedMessage id={Message.CONTACT_INFO_THANK_YOU} />{' '}
                  {firstName ?? firstName},
                </SectionTitle>{' '}
                <br />
                <SectionSubTitle>
                  <FormattedMessage id={Message.CONTACT_INFO_BACK} />
                </SectionSubTitle>
              </TextSection>

              <Button
                className="contact-back-button"
                variant="contained"
                color="inherit"
                type="submit"
                disabled={false}
                onClick={() => {
                  history.push(ClientRouter.APP);
                }}
                isLoading={isLoading}
              >
                <FormattedMessage id={Message.UPLOAD_BUTTON_BACK_TO_HOME} />
              </Button>
            </ContentContainer>
          )}
        </ContactCard>
      </Container>
    </Root>
  );
};

export default Contact;

const Root = styled.section`
  /* > div {
    width: 100%;
  } */
`;
const Container = styled.div`
  margin-top: 24px;
  margin: 24px 80px 0px;
`;
const CustomSelect = styled.div`
  /* ${rtl`
    padding-left: 72px;
    padding-right: 72px;
  `} */
  position: relative;
  display: flex;
  /* &:hover {
    svg {
      color: #fc5c42;
    }
  } */
  .selectButton {
    background: #fafcfe;
    border: 1px solid #e8eaeb;
    border-radius: 10px;
    color: inherit;
    width: 100%;
    height: 50px !important;
  }

  .isopen {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  }

  div:nth-child(2) {
    /* background-color: red; */
    /* border: 1px solid grey; */
    width: 384px;
    @media (max-width: 800px) {
      width: 100%;
    }
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  }
`;
const TopTitle = styled.div`
  margin-top: 24px;
  font-weight: 500;
  font-size: 48px;
  line-height: 56px;
  color: #282929;
  padding: 0 80px;
`;

const ContactCard = styled.div`
  /* margin-top: 48px; */
  height: auto;
  margin: 48px 20px 160px 80px;
  display: flex;
  flex-direction: row;
  background: #ffffff;
  box-shadow: 0px 0px 33px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;

const ContactInfo = styled.div`
  width: 70%;
`;
const ImageContainer = styled.div`
  height: 419px;
  && > img {
    width: 100%;
    height: 100%;
    border-top-left-radius: 10px;
  }
`;

const FormContainer = styled.div`
  width: 30%;
  padding: 48px;
  height: 652px;
  overflow-y: scroll;
  overflow-x: hidden;
  @media (max-width: 800px) {
    width: 100%;
    padding: 0;
    height: 100%;
    > form {
      margin: 0 !important;
      max-width: unset !important;
    }
  }
  @media (min-width: 1025px) and (max-width: 1510px) {
    height: 725px;
  }
  > form {
    margin: 0 0 24px 0;
    max-width: 385px;
  }
  .MuiFormHelperText-root {
    line-height: unset !important;
  }

  .mtm {
    width: 100%;
    margin-top: 24px;
    ${rtl`
      margin-left: 24px;
      @media(max-width:800px){
        margin-left:0;
      }
    `}
    line-height: 16px;
  }

  .commonRow {
    display: flex;
  }

  .email-container {
    margin-top: 24px;
  }

  .custom-input-root .MuiInputBase-root {
    max-height: 50px !important;
  }

  .custom-input-root:hover {
    border: none !important;
    border-radius: 10px !important;
  }
  #deviceVersion {
    height: 50px !important;
  }

  .dropdown-container {
    min-height: 74px;
    > div > div {
      width: 100%;
      height: 50px;
    }
    .desc {
      font-family: 'Roboto';
      margin: 0 0 8px;
      color: #747575;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
    }
    .mtm {
      margin-top: 24px;
    }
    .device-type {
      width: 158px !important;
      @media (max-width: 800px) {
        width: 100% !important;
        position: relative;
      }
      div:nth-child(2) {
        /* background-color: red; */
        /* border: 1px solid grey; */
        width: 100%;
      }
    }
    .MuiOutlinedInput-root {
      max-height: 50px !important;
      width: 100%;
      .MuiSelect-select {
        padding: 16.5px 14px;
      }
      .dropdown-icon {
        ${rtl`
          margin-right: 14px;
        `}
        transform: rotate(90deg);
      }
    }
  }

  .text-input-root > label {
    margin: 0 0 8px 0 !important;
    color: #747575 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    transform: unset !important;
    /* line-height: 16px; */

    /* .custom-input-label > .MuiInputLabel-animated > .MuiInputLabel-shrink {
      transform: unset !important;
    }
    .MuiInputLabel-shrink {
      transform: unset;
    } */
  }

  > form > div > .phone-number-label {
    margin: 0 0 8px 0 !important;
    color: #747575 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    transform: unset !important;
    > svg {
      ${rtl`
        margin-left: 8px;
      `}
    }
  }

  & .description-container {
    margin-top: 24px;
    @media (max-width: 800px) {
      margin-top: 16px;
      label {
        color: #747575 !important;
        line-height: 16px;
      }
    }
    div > textarea {
      min-height: 173px !important;
      margin-top: 0;
    }
    div > div > h4 {
      direction: ltr;
    }
  }

  & .phone-number-label .MuiInputLabel-animated .MuiInputLabel-shrink {
    margin-left: 0px;
  }

  & .description {
    font-size: 14px;
    font-weight: 400;
    color: #747575 !important;
    margin: 0 0 8px 0;
    transform: unset !important;
  }
  & .react-tel-input {
    margin-top: 8px;
  }
`;

const TextInputField = styled(TextInput)`
  margin-top: 16px;
  padding: 15px 14px;
`;

const ActionContainer = styled.div`
  /* margin-left:  */
  @media (max-width: 800px) {
    margin: 24px;
  }
  > button {
    width: ${({ theme }) =>
      theme.dir === 'rtl' ? '100px !important' : '133px !important'};
    height: 40px !important;
  }
`;

const TitleWithIcon = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 0;
  @media (max-width: 800px) {
    margin: 16px 0;
    svg {
      width: 25px;
      height: 25px;
    }
  }
`;
const SubTitle = styled.span`
  font-family: ${({ theme }) => (theme.dir === 'rtl' ? 'Assistant' : 'Roboto')};
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #000000;
  ${rtl`
    margin-left: 8px;
  `}
`;
const PhoneNumber = styled(PhoneField)``;

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 30%;
  padding: 80px;
  text-align: center;
  @media (max-width: 800px) {
    width: 100%;
    padding: 80px 0;
  }
  & .contact-back-button {
    height: 40px;
    width: 210px;
  }
`;

const TextSection = styled.div`
  margin: 48px 0;
  font-size: 24px;
  line-height: 28px;
  color: #282929;
  /* First level text */
`;

const SectionTitle = styled.span`
  font-weight: 700;
`;
const SectionSubTitle = styled.span`
  font-weight: 500;
`;

const MainContainer = styled.div``;

const PageHeaderSection = styled.div<{ fillColor: string }>`
  background: ${({ fillColor }) => fillColor};
  padding-top: 16px;
  padding-bottom: 24px;
  position: relative;
`;

const HeaderTitleContainer = styled.div`
  text-align: center;
  font-weight: 500;
  font-size: 28px;
  color: rgba(255, 255, 255, 0.9);
  line-height: 33px;
`;

const ContactDetailContainer = styled.div`
  margin-top: 24px;
`;
const MailContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.9);
`;

const MainPhoneContainer = styled.div`
  display: inline-flex;
  margin-top: 16px;
  width: 100%;
`;

const CustomServiceTypeSelection = styled.div`
  margin-top: 25px;
  margin-left: 24px;
  margin-right: 24px;
  position: relative;
  .selectButton {
    width: 100%;
  }
  .desc {
    margin-top: 0px;
    margin-bottom: 8px;
    color: white;
    font-weight: 400;
    font-size: 14px;
  }
  .custom-popper {
    width: 100%;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  }
  .dropdown-placeholder {
    font-weight: 400;
    font-size: 14px;
    color: #282929;
  }
  .selectButton {
    svg {
      font-size: 1.6rem;
      path {
        fill: #676766;
      }
    }
  }
`;

const MobileFormContainer = styled.div`
  margin: 16px 24px;
  .email-container {
    margin-top: 0;
    label {
      color: #282929 !important;
      line-height: 16px;
    }
  }
  .phone-number-label {
    font-size: 14px;
    line-height: 16px;
    color: #282929;
    font-weight: 400;
    transform: unset;
    margin: 0;
    display: none;
  }
`;

const CustomArrowContainer = styled.div`
  position: absolute;
  top: 15px;
  ${rtl`left: 24px;`}
  svg {
    fill: rgba(255, 255, 255, 0.9);
  }
`;

const EmailIconContainer = styled.img`
  height: 12.1px;
  width: 16.27px;
`;

const PhoneIconContainer = styled.img`
  height: 15.41px;
  width: 15.42px;
`;

const StyledSpan = styled.span`
  line-height: 19px;
  ${rtl`margin-left: 10px;`}
  direction: ltr;
`;
