import styled, { css } from 'styled-components';
import { Link as LinkRoute } from 'react-router-dom';
import footerB2B from 'assets/@client/supplier/patricia-logo.png';

const FooterB2B = () => {
  return (
    <LogoContainer dir="ltr">
      <LinkRoute to="/">
        <Image src={footerB2B} alt="brand-logo" />
      </LinkRoute>
      <TextContainer>Powered by Patricia-AI</TextContainer>
    </LogoContainer>
  );
};

export default FooterB2B;

const LogoContainer = styled.div.attrs({
  className: 'logo-stack-container-logo-container',
})`
  display: flex;
  justify-content: center;
`;
const Image = styled.img`
  width: 42px;
  height: 64px;
  margin: 24px auto;
`;

const TextContainer = styled.h2`
  margin: 46px 0px 0px 8px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #fc5c42;
  ${({ theme }) =>
    theme.dir === 'rtl' &&
    css`
      margin: 38px 8px 0px 0px;
    `};
`;
