export const initialAddressValues = {
  country: '',
  state: '',
  city: '',
  street: '',
  apartmentNumber: '',
  address_line_1: '',
  address_line_2: '',
  house: '',
  floor: '',
  zipCode: '',
  elevator: false,
  isPrimary: false,
};
