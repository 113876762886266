import { METHODS } from 'api/client';
import * as Responses from 'api/responses';
import { transformCart } from 'api/transform';
import { CART_KEY, GUEST_TOKEN } from 'constants/localstorage.constants';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { ClientRouter } from 'router/routes';
import { setCartCount } from 'store/actions/@client/cartInfo.actions';
import { LocalCartProduct, Product } from 'types/products';
import { Cart } from 'types/properties';
import { getLocalStorage, saveLocalStorage } from 'utils/common.utils';
import useAuthentication from './useAuthentication';
import useFetch from './useFetch';
import useLocalStorage from './useLocalStorage';

const useAddToCart = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { token: isAuthenticated, id } = useAuthentication();
  const guestToken = getLocalStorage(GUEST_TOKEN, null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [lastProductAdded, setLastProductAdded] = React.useState<Product>();

  const setCartCounts = React.useCallback(
    (count: number) => {
      dispatch(setCartCount(count));
    },
    [dispatch]
  );
  const onCartSuccess = React.useCallback(
    (data: Cart | null) => {
      if (data) setCartCounts(data.items.length);
    },
    [setCartCounts]
  );

  const { callFetch: fetchCart } = useFetch<Cart, Responses.Cart>({
    initialUrl: `/api/shopping_cart_item/`,
    config: { params: { user: id } },
    transform: transformCart,
    onSuccess: onCartSuccess,
    skipOnStart: true,
  });

  const onSuccess = React.useCallback(() => {
    setIsOpen(prev => !prev);
    fetchCart();
  }, [fetchCart]);
  const { isLoading, callFetch: processProductCart } = useFetch({
    initialUrl: '/api/shopping_cart_item/',
    skipOnStart: true,
    onSuccess,
  });

  const [cartState, setCartState] = useLocalStorage<LocalCartProduct[]>(
    CART_KEY,
    []
  );

  const { isLoading: isPreRegistering, callFetch: preregisterUser } = useFetch({
    initialUrl: '/api/checkout_cart/guest_user_checkout/',
    skipOnStart: true,
    onSuccess: (data: { token: string } | null) => {
      if (data) saveLocalStorage(GUEST_TOKEN, data.token);
      history.push(ClientRouter.CART);
    },
  });

  const registerGuest = React.useCallback(() => {
    preregisterUser({
      method: METHODS.POST,
      data: {
        pre_register: true,
        checkout_data: cartState.map(e => ({
          product: e.id,
          quantity: e.quantity,
          checkout_quantity: e.quantity,
        })),
      },
    });
  }, [cartState, preregisterUser]);

  const addToCart = React.useCallback(
    (product: Product, quantity: number = 1) => {
      setLastProductAdded(product);
      if (isAuthenticated || guestToken) {
        processProductCart({
          method: METHODS.POST,
          isGuestAllowed: true,
          data: {
            product: product.id,
            quantity,
          },
        });
      } else {
        const dupArry = [...cartState];
        const elIndex = dupArry.indexOf(
          dupArry.filter(e => e.id === product.id)[0]
        );
        if (elIndex !== -1) {
          dupArry[elIndex].quantity += quantity;
          setCartState([...dupArry]);
          setCartCounts(dupArry.length);
        } else {
          setCartState([...cartState, { id: product.id, quantity }]);
          setCartCounts(dupArry.length + 1);
        }
        onSuccess();
      }
    },
    [
      cartState,
      guestToken,
      isAuthenticated,
      onSuccess,
      processProductCart,
      setCartCounts,
      setCartState,
    ]
  );

  return {
    addToCart,
    isLoading: isLoading || isPreRegistering,
    successModalOpen: isOpen,
    setSuccessModalOpen: setIsOpen,
    isAuthenticatedForCart: isAuthenticated || guestToken,
    preregisterUser: registerGuest,
    lastProductAdded,
  };
};

export default useAddToCart;
