import * as React from 'react';
import Popover from '@material-ui/core/Popover';
import {
  addMonths,
  subMonths,
  isSameDay,
  isWithinInterval,
  isAfter,
  isBefore,
  isSameMonth,
  addYears,
  max,
  min,
} from 'date-fns';
import styled from 'styled-components';
import { getValidatedMonths, parseOptionalDate } from 'utils/dates.utils';
import { isEmpty } from 'utils/lodash.utils';

import {
  DateRange,
  NavigationAction,
  DefinedRange,
  Marker,
  MenuProps,
  SetDateRangeReason,
} from './types';

import {
  defaultRanges,
  defaultMenuProps,
  defaultYearsShown,
  defaultMinDate,
} from './defaults';

import Menu, { MARKERS } from './Menu';

interface DateRangePickerProps {
  open: boolean;
  toggle: () => void;
  initialDateRange?: DateRange;
  definedRanges?: DefinedRange[];
  minDate?: Date | string;
  maxDate?: Date | string;
  onChange: (dateRange: DateRange) => void;
  MenuProps?: MenuProps;
  yearsShown?: number;
  applyButton?: true;
  anchorEl: HTMLElement | null;
}

const DateRangePicker: React.FC<DateRangePickerProps> = (
  props: DateRangePickerProps
) => {
  const today = React.useRef(new Date());

  const {
    open,
    onChange,
    anchorEl,
    initialDateRange = {},
    minDate = defaultMinDate,
    maxDate,
    definedRanges = defaultRanges,
    MenuProps: menuProps = defaultMenuProps,
    yearsShown = defaultYearsShown,
    applyButton,
    toggle,
  } = props;

  const minDateValid = parseOptionalDate(minDate, addYears(today.current, -10));
  const maxDateValid = parseOptionalDate(maxDate, addYears(today.current, 10));
  const [initialFirstMonth, initialSecondMonth] = getValidatedMonths(
    initialDateRange,
    minDateValid,
    maxDateValid
  );

  const [dateRange, setDateRange] = React.useState<DateRange>({
    ...initialDateRange,
  });
  const [hoverDay, setHoverDay] = React.useState<Date>();
  const [firstMonth, setFirstMonth] = React.useState<Date>(
    initialFirstMonth || subMonths(today.current, 1)
  );
  const [secondMonth, setSecondMonth] = React.useState<Date>(
    initialSecondMonth || addMonths(firstMonth, 1)
  );

  const isValidRange = React.useMemo(
    () => !!dateRange.startDate && !!dateRange.endDate,
    [dateRange.endDate, dateRange.startDate]
  );

  const setFirstMonthValidated = React.useCallback(
    (date: Date) => {
      if (isBefore(date, secondMonth)) setFirstMonth(date);
    },
    [secondMonth]
  );

  const setSecondMonthValidated = React.useCallback(
    (date: Date) => {
      if (isAfter(date, firstMonth)) setSecondMonth(date);
    },
    [firstMonth]
  );

  const setDateRangeValidated = React.useCallback(
    (range: DateRange | undefined, reason?: SetDateRangeReason) => {
      if (reason === 'clear' || (reason === 'select-range' && isEmpty(range))) {
        setFirstMonth(subMonths(today.current, 1));
        setSecondMonth(today.current);
      }
      if (!range || isEmpty(range)) {
        const newRange = {};
        setDateRange(newRange);
        if (reason === 'apply' || reason === 'select-range') {
          onChange(newRange);
          toggle();
        }
        return;
      }
      const { startDate, endDate } = range;
      if (startDate && endDate) {
        const newStart = max([startDate, minDateValid]);
        const newEnd = min([endDate, maxDateValid]);
        const newRange = { startDate: newStart, endDate: newEnd };
        setDateRange(newRange);
        onChange(newRange);
        toggle();
        setFirstMonth(newStart);
        setSecondMonth(
          isSameMonth(newStart, newEnd) ? addMonths(newStart, 1) : newEnd
        );
      } else {
        if (reason === 'apply') {
          setDateRange(initialDateRange);
          toggle();
          return;
        }
        if (!applyButton) {
          const emptyRange = {};
          onChange(emptyRange);
          toggle();
          return;
        }

        setFirstMonth(today.current);
        setSecondMonth(addMonths(firstMonth, 1));
      }
    },
    [
      applyButton,
      firstMonth,
      maxDateValid,
      minDateValid,
      onChange,
      toggle,
      initialDateRange,
    ]
  );

  const onDayClick = React.useCallback(
    (day: Date) => {
      const { startDate, endDate } = dateRange;
      if (startDate && !endDate && !isBefore(day, startDate)) {
        const newRange = { startDate, endDate: day };
        if (!applyButton) onChange(newRange);
        setDateRange(newRange);
      } else setDateRange({ startDate: day, endDate: undefined });
      setHoverDay(day);
    },
    [applyButton, dateRange, onChange]
  );

  const onMonthNavigate = React.useCallback(
    (marker: Marker, action: NavigationAction) => {
      if (marker === MARKERS.FIRST_MONTH) {
        const firstNew = addMonths(firstMonth, action);
        if (isBefore(firstNew, secondMonth)) setFirstMonth(firstNew);
      } else {
        const secondNew = addMonths(secondMonth, action);
        if (isBefore(firstMonth, secondNew)) setSecondMonth(secondNew);
      }
    },
    [firstMonth, secondMonth]
  );

  const onDayHover = React.useCallback(
    (date: Date) => {
      const { startDate, endDate } = dateRange;
      if (startDate && !endDate)
        if (!hoverDay || !isSameDay(date, hoverDay)) setHoverDay(date);
    },
    [dateRange, hoverDay]
  );

  const inHoverRange = React.useCallback(
    (day: Date) =>
      (dateRange.startDate &&
        !dateRange.endDate &&
        hoverDay &&
        isAfter(hoverDay, dateRange.startDate) &&
        isWithinInterval(day, {
          start: dateRange.startDate,
          end: hoverDay,
        })) as boolean,
    [dateRange.endDate, dateRange.startDate, hoverDay]
  );

  const helpers = { inHoverRange };
  const handlers = React.useMemo(
    () => ({
      onDayClick,
      onDayHover,
      onMonthNavigate,
    }),
    [onDayClick, onDayHover, onMonthNavigate]
  );

  return (
    <CustomPopover
      onClose={(_, reason) => {
        if (reason === 'escapeKeyDown') toggle();
      }}
      open={open}
      anchorEl={anchorEl}
      PaperProps={{ elevation: 0 }}
      BackdropProps={{
        onClick: () => {
          setDateRangeValidated(dateRange, 'apply');
        },
        style: { background: 'transparent' },
      }}
      transitionDuration="auto"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Container>
        {open && (
          <Menu
            dateRange={dateRange}
            minDate={minDateValid}
            maxDate={maxDateValid}
            ranges={definedRanges}
            firstMonth={firstMonth}
            secondMonth={secondMonth}
            setFirstMonth={setFirstMonthValidated}
            setSecondMonth={setSecondMonthValidated}
            setDateRange={setDateRangeValidated}
            helpers={helpers}
            handlers={handlers}
            MenuProps={menuProps}
            yearsShown={yearsShown}
            isValidRange={isValidRange}
            applyButton={applyButton}
            onClick={() => onChange(dateRange)}
          />
        )}
      </Container>
    </CustomPopover>
  );
};

export default DateRangePicker;
export type { DefinedRange, DateRange };

const Container = styled.div`
  position: relative;
`;

const CustomPopover = styled(Popover)`
  margin-top: 10px;
  && .MuiPaper-root {
    border-radius: 12px;
    ${({ theme }) => theme.shadow.m}
  }
`;
