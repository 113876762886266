import * as React from 'react';
import styled from 'styled-components';
import { useDisableOverflow, usePromptAPI } from 'hooks';
import Modal, { ModalAction } from 'components/@client/Modal';
import { MOBILE_RESOLUTION } from 'styles/sharedStyle';
import { FormattedMessage, useIntl } from 'react-intl';
import { Message } from 'i18n';

interface Props {
  action: VoidFunction;
  isLoading: boolean;
  when: boolean;
  shouldRedirect: boolean;
}

const ConfirmChangesModal: React.FC<Props> = ({
  when,
  isLoading,
  action,
  shouldRedirect,
}) => {
  const { navigateToNextLocation, isOpen, setIsOpen } = usePromptAPI({
    when,
  });
  const intl = useIntl();
  useDisableOverflow(isOpen);
  React.useEffect(() => {
    if (isOpen && shouldRedirect) {
      navigateToNextLocation();
    }
  }, [shouldRedirect, navigateToNextLocation, isOpen]);
  const actions: ModalAction[] = [
    {
      label: <FormattedMessage id={Message.DISCARD} />,
      onClick: navigateToNextLocation,
      variant: 'secondary',
    },
    {
      label: <FormattedMessage id={Message.SAVE_CHANGES} />,
      onClick: action,
      variant: 'primary',
    },
  ];

  return (
    <ConfirmModal
      className="confirm-modal"
      onClose={() => setIsOpen(false)}
      title={intl.formatMessage({
        id: Message.ADDRESS_FORM_CONFIRM_MODAL_CHANGES_TITLE,
      })}
      actions={actions}
      open={isOpen}
    >
      <Content>
        <FormattedMessage
          id={Message.ADDRESS_FORM_CONFIRM_MODAL_CHANGES_TEXT}
          values={{ linebreak: <br /> }}
        />
      </Content>
    </ConfirmModal>
  );
};

export default React.memo(ConfirmChangesModal);

const Content = styled.p`
  margin: 0 24px 30px;
  font-size: 18px;
  color: rgba(21, 25, 32, 0.5);
  font-family: ${({ theme }) => theme.dir === 'rtl' && 'Assistant'};

  @media ${MOBILE_RESOLUTION} {
    font-size: 12px;
    margin: 0 24px 20px;
  }
`;

const ConfirmModal = styled(Modal)`
  & .MuiDialogTitle-root {
    padding-bottom: 0;
    font-family: ${({ theme }) => theme.dir === 'rtl' && 'Assistant'};

    @media ${MOBILE_RESOLUTION} {
      margin-bottom: 10px !important;
    }

    & h2 {
      font-size: 1.875rem;
      font-weight: 700;
      letter-spacing: 0;
      font-family: ${({ theme }) => theme.dir === 'rtl' && 'Assistant'};

      @media ${MOBILE_RESOLUTION} {
        font-size: 1.25rem;
      }
    }
  }

  & .MuiDialogContent-root {
    padding: 0 24px !important;
    font-family: ${({ theme }) => theme.dir === 'rtl' && 'Assistant'};
  }

  .MuiDialogActions-spacing > :not(:first-child) {
    margin-left: 20px;

    @media ${MOBILE_RESOLUTION} {
      margin-left: 8px;
    }
  }

  & .MuiDialogActions-root {
    margin-bottom: 24px;
    font-family: ${({ theme }) => theme.dir === 'rtl' && 'Assistant'};

    @media ${MOBILE_RESOLUTION} {
      padding: 24px 24px 0 !important;
      display: block;
      text-align: center;
      & > button {
        margin: 10px !important;
      }
    }
    & > button {
      min-width: 150px;
    }
  }
`;
