import * as React from 'react';
import * as Response from 'api/responses';
import ColorDisplay from 'components/ColorDisplay';
import { ColorSearchOption } from 'components/Filters/Options';
import * as Form from 'components/forms';
import { useFetch } from 'hooks';
import { Color } from 'types/quizzes';
import type { Option } from 'components/Autocomplete/Autocomplete.config';

interface Props {
  disabled?: boolean;
  name: string;
  width?: string | number;
  defaultValue?: Option;
  required?: boolean;
}

const ColorField: React.FC<Props> = ({
  name: fieldName,
  disabled,
  width,
  defaultValue,
  required = true,
}) => {
  const [color, setColor] = React.useState<Option | null>(null);
  const [options, setOptions] = React.useState<Option[]>([]);
  const onSuccess = React.useCallback(
    (colors: Response.Color[] | null) => {
      // options.current = [
      //   ...(colors ?? [])?.map(({ name, id, color_hex }) => ({
      //     id,
      //     name,
      //     color: color_hex,
      //   })),
      // ];
      setOptions([
        ...(colors ?? [])?.map(({ name, id, color_hex }) => ({
          id,
          name,
          color: color_hex,
        })),
      ]);
      if (defaultValue?.length)
        setColor(
          () =>
            options.find(
              option => option?.id.toString() === defaultValue.toString()
            ) ?? null
        );
    },
    [defaultValue, options]
  );

  useFetch<Response.Color[]>({
    initialUrl: '/api/colors/',
    onSuccess,
    config: {
      useCache: true,
    },
  });

  return (
    <Form.ComboBox
      disabled={disabled}
      name={fieldName}
      label={{ title: 'Color tag' }}
      width={width}
      options={options}
      placeholder="Choose color"
      setterKey="id"
      searchPlaceholder="Search for color..."
      renderOption={ColorSearchOption}
      renderValue={option => <ColorDisplay {...(option as Color)} />}
      selectedValue={color}
      setSelectedValue={setColor}
      required={required}
    />
  );
};

export default ColorField;
