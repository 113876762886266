import * as React from 'react';

const IconSvg = React.lazy(() => import('./Svg'));

export interface IconProps {
  name: string;
  className?: string;
  width?: number;
  height?: number;
  size?: number;
  color?: string;
  hoverColor?: string;
  viewBox?: string;
  [others: string]: unknown;
}

export default ({ name, ...props }: IconProps) => (
  <React.Suspense fallback={<i id={name}>&nbsp;</i>}>
    <IconSvg {...props} name={name} />
  </React.Suspense>
);
