import * as React from 'react';
import styled, { css } from 'styled-components';
import IconBtn from '@material-ui/core/IconButton';
// Icons
import CustomIcon from 'components/Icon';

const MoreVertIcon = React.lazy(() => import('@material-ui/icons/MoreVert'));
const KeyboardArrowDown = React.lazy(
  () => import('@material-ui/icons/KeyboardArrowDown')
);

export enum IconName {
  more_vert_icon = 'MoreVertIcon',
  key_board_arrow_down = 'KeyboardArrowDown',
  custom_icon = 'CustomIcon',
}

const icons = {
  MoreVertIcon,
  KeyboardArrowDown,
  CustomIcon,
  none: null,
};

interface Props {
  disabled?: boolean;
  disableRipple?: boolean;
  iconName: IconName | null;
  shouldRotate?: boolean;
  open?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  customIcon?: string | null;
  iconSize?: number;
  $size?: 'small' | 'medium' | 'large';
  label?: React.ReactNode | string;
  title?: string;
  variant?: 'default' | 'outlined';
  viewBox?: string;
}

const IconButton: React.FC<Props> = ({
  children,
  disabled = false,
  disableRipple,
  onClick,
  iconName,
  open,
  shouldRotate,
  className = '',
  customIcon,
  $size = 'medium',
  iconSize = 18,
  variant = 'default',
  title,
  label,
  viewBox,
}) => {
  const Icon = icons[iconName ?? 'none'];

  return (
    <Button
      title={title}
      disabled={disabled}
      disableRipple={disableRipple}
      onClick={onClick}
      className={`${className} icon-button`}
      $size={$size}
      variant={variant}
      id="edit-new-project-button"
    >
      <IconContainer {...{ shouldRotate, open }}>
        <React.Suspense fallback={null}>
          {Icon && (
            <Icon size={iconSize} name={customIcon ?? ''} viewBox={viewBox} />
          )}
        </React.Suspense>
        <Label>{label}</Label>
        {children}
      </IconContainer>
    </Button>
  );
};

export default React.memo<Props>(IconButton);

const Button = styled(IconBtn)<{
  $size: 'small' | 'medium' | 'large';
  variant: 'default' | 'outlined';
}>`
  border-radius: 10px !important;
  ${({ $size }) => {
    if ($size === 'small')
      return css`
        min-width: 32px;
        min-height: 32px;
      `;
    if ($size === 'medium')
      return css`
        min-width: 40px;
        min-height: 40px;
      `;
    return css`
      min-width: 48px;
      min-height: 48px;
    `;
  }}
  ${({ theme }) => theme.utils.centerFlex};
  ${({ variant, theme }) =>
    variant === 'outlined' &&
    css`
      border: 1px solid ${theme.colors.primary.dark} !important;
    `}
`;
const IconContainer = styled.span<{ shouldRotate?: boolean; open?: boolean }>`
  ${({ theme }) => theme.utils.centerFlex};
`;

const Label = styled.span`
  font-size: 14px;
  margin-left: 2px;
  margin-top: 4px;
`;
