import * as React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import styled, { css } from 'styled-components';
import { CSSProperties } from '@material-ui/styles';

interface Props extends DialogProps {
  $large?: true;
  $width?: string | number;
  $height?: string | number;
  $minHeight?: string | number;
  $hasBack?: boolean;
  $borderRadius?: string;
  disablePadding?: true;
  dialogStyles?: CSSProperties;
  sideNavigation?: React.ReactNode;
  verticalSpaceBetween?: true;
  overrideZIndex?: boolean;
}

const Container: React.FC<Props> = ({
  children,
  disablePadding,
  open,
  sideNavigation,
  verticalSpaceBetween,
  dialogStyles,
  overrideZIndex = true,
  ...props
}) => {
  const spaceProps = { verticalSpaceBetween, disablePadding };
  return (
    <CustomDialog
      BackdropComponent={CustomBackdrop}
      open={open}
      $dialogStyles={dialogStyles}
      overrideZIndex={overrideZIndex}
      disableScrollLock={true}
      {...props}
    >
      <>
        {sideNavigation ? (
          <FlexWrapper>
            {sideNavigation}
            <DialogSpace {...spaceProps}>{children}</DialogSpace>
          </FlexWrapper>
        ) : (
          <DialogSpace {...spaceProps}>{children}</DialogSpace>
        )}
      </>
    </CustomDialog>
  );
};

export default React.memo(Container);

const CustomDialog = styled(Dialog).attrs<{
  $width: string | number;
  $height: string | number;
  $minHeight: string | number;
  $hasBack?: boolean;
  $borderRadius: string;
  $dialogStyles?: CSSProperties;
}>(({ $width, $height, $minHeight, $borderRadius, $dialogStyles = {} }) => ({
  PaperProps: {
    elevation: 0,
    style: {
      boxShadow: 'rgb(13 13 13 / 4%) 1px -1px 12px 6px',
      height: $height && $height,
      minHeight: $minHeight,
      minWidth: $width && $width,
      overflow: 'overlay',
      transition: 'all 0.3s',
      borderRadius: $borderRadius ?? 'initial',
      ...$dialogStyles,
    },
  },
}))<{
  $hasBack?: boolean;
  $dialogStyles?: CSSProperties;
  overrideZIndex: boolean;
}>`
  ${({ overrideZIndex, $hasBack }) =>
    overrideZIndex &&
    css`
      z-index: ${$hasBack ? 10 : 5} !important;
    `}
  .MuiPaper-root {
    ${({ theme }) => theme.utils.scrollbar}

    .MuiDialogTitle-root {
      h5 {
        font-size: 28px;
      }
    }
  }

  &.archive {
    .MuiPaper-root {
      width: 489px;
      height: 360px;

      .MuiDialogTitle-root {
        margin-bottom: 15px;
        padding: 0 50px;
      }

      .MuiDialogContent-root {
        height: unset;
        padding: 0 50px;
        color: ${({ theme }) => theme.colors.silver};
        margin-bottom: 10px;
      }

      .dialog-space {
        padding: 25px 30px 0 0;
      }
    }
  }
`;

const DialogSpace = styled.div.attrs({
  className: 'dialog-space',
})<{
  verticalSpaceBetween?: true;
  disablePadding?: true;
}>`
  padding: ${({ disablePadding }) =>
    disablePadding ? '15px 26px 0 0' : '1.875rem 1.875rem 2.5rem 1.875rem'};
  height: auto;
  width: 100%;
  display: ${({ verticalSpaceBetween }) => verticalSpaceBetween && 'flex'};
  flex-direction: ${({ verticalSpaceBetween }) =>
    verticalSpaceBetween && 'column'};
  justify-content: ${({ verticalSpaceBetween }) =>
    verticalSpaceBetween && 'space-between'};
  flex: ${({ verticalSpaceBetween }) => verticalSpaceBetween && '1'};
`;

const CustomBackdrop = styled(Backdrop).attrs({
  className: 'dialog-backdrop',
})`
  background-color: rgba(255, 255, 255, 0.9);
`;

const FlexWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;
