import type { DesignStyle, PriceTagResponse, QuizAnswer } from 'api/responses';
import * as React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styled from 'styled-components';
import type { MediaFile } from 'types/common.types';
import { isEmpty } from 'utils/lodash.utils';
import { toILSRange } from 'utils/string.utils';
import { Carousel } from 'react-responsive-carousel';
import Slider from '@mui/material/Slider';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import Slide from './QuizAnswerSlide';

export const PriceTag: Record<PriceTagResponse, string> = {
  low: toILSRange(1000, 15000),
  medium: toILSRange(16000, 50000),
  high: toILSRange(51000, 100000),
  extra: toILSRange(51000, 100000),
};

interface Props<T extends QuizAnswer> {
  items: T[];
  onClickItem?: (index: number) => void;
  isColorPallleteSection?: boolean;
  selectedAnswer?: number | null;
  isPanoramaSelection?: boolean;
}

const QuizAnswerSlides = <T extends QuizAnswer>({
  items,
  onClickItem,
  isColorPallleteSection = false,
  selectedAnswer = null,
  isPanoramaSelection = false,
}: Props<T>) => {
  const [selectedItem, setSelectedItem] = React.useState(0);
  const reset = React.useCallback(() => {
    setSelectedItem(0);
  }, []);
  const carouselProps = React.useMemo(
    () => ({
      className: 'quiz-answer-slides',
      infiniteLoop: false,
      swipeable: true,
      useKeyboardArrows: true,
      showThumbs: false,
      showStatus: false,
      showArrows: false,
      emulateTouch: true,
      showIndicators: false,
      centerMode: true,
      centerSlidePercentage: 87,
      interval: 1000000000,
      width: 327,
      selectedItem,
      onChange: setSelectedItem,
      onClickItem,
    }),
    [onClickItem, selectedItem]
  );

  React.useEffect(reset, [reset, items]);

  const getDesignStyleName = (name: string) => {
    if (name === 'Modern') {
      return Message.MODERN;
    }
    if (name === 'Classic') {
      return Message.CLASSIC;
    }
    if (name === 'Scandinavian') {
      return Message.SCANDINAVIAN;
    }
    return name;
  };

  return (
    <Container>
      {isEmpty(items) ? (
        <div>No answers available</div>
      ) : (
        <>
          <Carousel {...carouselProps}>
            {items.map((slide, i) => (
              <div key={slide.id}>
                <Slide
                  onClickItem={() => onClickItem?.(i)}
                  withColors={slide.colors}
                  withLabel={Boolean(slide.price_tag)}
                  name={PriceTag[slide.price_tag]}
                  answer={
                    isPanoramaSelection
                      ? ({
                          id: slide.panorama?.id,
                          file: slide.panorama?.thumbnail,
                        } as MediaFile)
                      : (slide.answer as MediaFile)
                  }
                  selectedAnswer={i === selectedAnswer}
                />
                <div className="design-subtitle-mobile">
                  {!isPanoramaSelection && (
                    <FormattedMessage
                      id={getDesignStyleName(
                        (slide.design_style as DesignStyle)?.name
                      )}
                      tagName="span"
                    />
                  )}
                </div>
              </div>
            ))}
          </Carousel>
        </>
      )}
      <div style={{ width: '167px', margin: 'auto', marginTop: '24px' }}>
        <Slider
          max={items.length - 1}
          min={0}
          value={selectedItem}
          onChange={(e, value) => setSelectedItem(value as number)}
          componentsProps={{
            rail: {
              style: {
                width: '167px',
                height: '2px',
                background: '#C4C4C4',
                borderRadius: '30px',
              },
            },
            thumb: {
              style: {
                width: `${167 / items.length}px`,
                height: '2px',
                borderRadius: '30px',
                background: 'black',
              },
            },
            track: {
              style: {
                width: '167px',
                height: '2px',
                background: '#C4C4C4',
                borderRadius: '30px',
                border: 'none',
              },
            },
          }}
        />
      </div>
    </Container>
  );
};

export default QuizAnswerSlides;

const Container = styled.section.attrs({
  className: 'quiz-answer-slides-container',
})`
  direction: ltr;
  margin: 50px 24px 0 24px;
  position: relative;
  .design-subtitle-mobile {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    margin-top: 8px;
    color: #282929;
  }
`;
