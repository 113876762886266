import * as React from 'react';
import styled from 'styled-components';
import Combobox from 'components/ComboBox';
import Icon from 'components/Icon';
import { useCombobox } from 'hooks';
import type { Option } from 'components/Autocomplete/Autocomplete.config';
// import { CustomArrowIcon } from 'components/Filters/SelectFilter';
import { ControlProps } from './types';

interface Props extends ControlProps<Option[] | Option> {
  iconName?: string;
  searchPlaceholder?: string;
  selectedPlaceholder?: string;
  responsive?: boolean;
  disabled?: boolean;
  renderOption: (
    selectedItems: Option[]
  ) => (
    props: React.HTMLAttributes<HTMLLIElement>,
    newValue: Option
  ) => JSX.Element;
  width?: number;
  controlled?: boolean;
  customIcon?: boolean;
}

const ComboboxFilter: React.FC<Props> = ({
  controlled,
  searchPlaceholder,
  selectedPlaceholder,
  placeholder,
  iconName,
  initialState = [],
  options,
  responsive = true,
  disabled,
  groupBy,
  renderOption,
  onApply,
  width,
  multiple = true,
  customIcon,
}) => {
  const [value, setValue] = React.useState<Option[]>([
    ...(initialState as Option[]),
  ]);
  const {
    anchorEl,
    handleClear,
    handleClick,
    handleClose,
    handleApply,
    open,
  } = useCombobox({ value, setValue, onApply });

  const option = React.useMemo(() => renderOption(value), [
    renderOption,
    value,
  ]);

  React.useEffect(() => {
    if (controlled) setValue(initialState as Option[]);
  }, [controlled, initialState]);

  React.useEffect(() => {
    setValue([]);
  }, [options, handleApply]);

  return (
    <Combobox
      anchorEl={anchorEl}
      open={open}
      multiple={multiple}
      disableSelectAll
      options={options as Option[]}
      value={value}
      selectedPlaceholder={selectedPlaceholder}
      onClick={handleClick}
      handleClose={handleClose}
      setValue={setValue}
      placeholder={placeholder}
      searchPlaceholder={searchPlaceholder}
      renderOption={option}
      onClear={handleClear}
      responsive={responsive}
      disabled={disabled}
      groupBy={groupBy}
      onApply={handleApply}
      width={width}
      startIcon={
        iconName && (
          <div>
            <CustomIcon name={iconName} active={value.length > 0} />
          </div>
        )
      }
      // endIcon={customIcon && <CustomArrowIcon open={open} />}
    />
  );
};

export default ComboboxFilter;

const CustomIcon = styled(Icon).attrs<{ active: boolean }>(({ theme }) => ({
  color: theme.colors.primary.dark,
  size: 28,
}))<{ active: boolean }>`
  fill: ${({ theme }) => theme.colors.primary.dark};
  padding: 2px;
  display: flex;
  align-self: center;

  @media (min-width: 1500px) {
    padding-right: 5px;
    fill: ${({ theme }) => theme.colors.primary.dark};
    opacity: 0.5;
  }
`;
