import * as React from 'react';
import styled from 'styled-components';
import { isEmpty } from 'utils/lodash.utils';
import { MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';
import type { Product, QuoteComponent } from 'types/products';
import { useRouter } from 'hooks';
import ProductItem, { ProductView } from './ProductItem';
import SkeletonList, { isThirdItem } from './SkeletonList';

interface Props {
  quoteComponents?: QuoteComponent[] | Product[] | null;
  isLoading: boolean;
  loadingItemCount?: number;
  emptyStateMessage?: string | JSX.Element;
  propertyId?: string;
  onProdClick?: (productSku: string) => void;
  selectedRoom?: number | null;
  isMatrix?: boolean;
}

const List: React.FC<Props> = ({
  quoteComponents = [],
  isLoading,
  loadingItemCount = 6,
  emptyStateMessage = 'No items available',
  propertyId,
  onProdClick,
  selectedRoom,
  isMatrix = false,
}) => {
  const viewProps = {
    view: ProductView.LIST,
  };
  const { history } = useRouter();

  if (isMatrix)
    return (
      <>
        <StyledList style={{ columnGap: '20px' }}>
          {isLoading ? (
            <SkeletonList loadingItemCount={loadingItemCount} {...viewProps} />
          ) : (
            <>
              {!isEmpty(quoteComponents) ? (
                (quoteComponents as Product[])?.map((product, i) => (
                  <ProductItem
                    isProduct={false}
                    key={product.id}
                    to={`${history.location.pathname}?productId=${product.id}`}
                    item={product}
                    isWide={false}
                    roomName={product.roomName ?? ''}
                    {...viewProps}
                    onProdClick={onProdClick}
                    isMatrix={isMatrix}
                  />
                ))
              ) : (
                <EmptyStateItem>{emptyStateMessage}</EmptyStateItem>
              )}
            </>
          )}
        </StyledList>
      </>
    );
  return (
    <>
      <StyledList style={{ columnGap: '20px' }}>
        {isLoading ? (
          <SkeletonList loadingItemCount={loadingItemCount} {...viewProps} />
        ) : (
          <>
            {!isEmpty(quoteComponents) ? (
              (quoteComponents as QuoteComponent[])?.map(
                ({ product, id, room }, i) => (
                  <ProductItem
                    isProduct={false}
                    key={id}
                    to={`${history.location.pathname}?productId=${product.id}`}
                    item={product}
                    isWide={isThirdItem(i)}
                    roomName={room?.name}
                    {...viewProps}
                    onProdClick={onProdClick}
                    isPropertyPage={true}
                  />
                )
              )
            ) : (
              <EmptyStateItem>{emptyStateMessage}</EmptyStateItem>
            )}
          </>
        )}
      </StyledList>
    </>
  );
};

export default List;

const StyledList = styled.ul`
  padding: 20px 160px;
  list-style: none;
  display: flex;
  row-gap: 20px;
  column-gap: 5%;
  flex-wrap: wrap;

  @media ${MOBILE_TABLET_RESOLUTION} {
    padding: 24px;
    column-gap: 15px !important;
    row-gap: 16px;
    justify-content: center;
    margin: 0;
  }
`;

const EmptyStateItem = styled.li`
  width: 100%;
  text-align: center;
  min-height: 150px;
  font-size: 1.25rem;
  opacity: 0.6;
`;
