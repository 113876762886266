import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Masony from '@mui/lab/Masonry';
import MasonryItemm from '@mui/lab/MasonryItem';
import useIdeaKPIs from 'hooks/useIdeaKPIs';
import styled, { css } from 'styled-components';
import PinterestIdeasCard from 'components/@client/PinterestIdeasCard/PinterestIdeasCard';
import { ClientRoot, MOBILE_RESOLUTION } from 'styles/sharedStyle';
import * as selectors from 'store/selectors/clients.selector';
import Breadcrumbs from 'components/@client/Breadcrumbs/Breadcrumbs';
import { Message } from 'i18n';
import { ClientRouter } from 'router/routes';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'store/selectors';
import { useRouter } from 'hooks';
import { useMediaQuery } from '@material-ui/core';
import Loader from 'components/Loader/Loader';
import IdeasSkeleton from './IdeasSkeleton';
import IdeasSkeletonMobile from './IdeaSkeletonMobile';

interface Props {
  isHeader?: boolean;
  isForHome?: boolean;
}

const Ideas: React.FC<Props> = ({ isHeader = true, isForHome = false }) => {
  const {
    ideaList,
    isListLoading: isLoading,
    pagination,
    onLoad: callFetch,
  } = useIdeaKPIs();
  const isMobile = useMediaQuery(MOBILE_RESOLUTION);

  const [pageNo, setPageNo] = React.useState(1);
  const { history, query } = useRouter<{
    id: string;
  }>();

  React.useEffect(() => {
    if (!isLoading)
      callFetch({
        params: isForHome
          ? {
              page: pageNo,
              exclude: query.id,
              size: 10,
              ordering: '-staff_pick,-updated_at',
            }
          : {
              page: pageNo,
              exclude: query.id,
              size: 40,
              ordering: '-staff_pick,-updated_at',
            },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo]);

  const loadNext = React.useCallback(() => {
    if (pagination?.next) setPageNo(prev => prev + 1);
  }, [pagination?.next]);

  const rooms = useSelector(selectors.allRoomsItems);

  const getColumns = React.useMemo(() => {
    // if (window.screen.width < 767) return 1;
    // if (window.screen.width < 576) return Math.floor(window.screen.width / 327);
    // if (window.screen.width >= 576 && window.screen.width <= 820)
    //   return Math.floor(window.screen.width / 288);
    // if (window.screen.width < 1600)
    //   return Math.floor(window.screen.width / 318);
    // return 5;
    if (window.screen.width < 767) return Math.floor(window.screen.width / 175);
    if (window.screen.width > 767 && window.screen.width < 1600)
      return Math.floor(window.screen.width / 325);
    return 5;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.screen.width]);

  const cards = React.useMemo(
    () => (
      <Masony columns={getColumns} spacing={isMobile ? '15px' : '20px'}>
        {ideaList?.map((e, i) => (
          <MyGridItem key={`${e.id + i}`} className="photo-item">
            <PinterestIdeasCard
              image={e.file ?? ''}
              userName={`${e?.user?.first_name} ${e?.user?.last_name}`}
              name=""
              onClick={(type: string | null) => {
                if (type) {
                  window.open(
                    ClientRouter.IDEA_PAGE.replace(
                      ':id',
                      e?.id.toString() ?? ''
                    ),
                    type
                  );
                } else {
                  history.push(
                    ClientRouter.IDEA_PAGE.replace(
                      ':id',
                      e?.id.toString() ?? ''
                    ),
                    e
                  );
                }
              }}
              date={e?.created_at}
              chipsData={e?.metadata}
              roomsData={rooms}
              views={e.viewCount?.toString()}
              type="idea"
            />
          </MyGridItem>
        ))}
      </Masony>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getColumns, ideaList, isMobile, rooms]
  );

  return (
    <CustomRoot isHeader={isHeader} isForHome={isForHome} columns={getColumns}>
      {isHeader && !isForHome && (
        <Breadcrumbs
          force
          links={[
            {
              to: ClientRouter.IDEAS_PAGE,
              children: <FormattedMessage id={Message.HOME_IDEAS_TITLE} />,
            },
          ]}
        />
      )}
      {isLoading && !isMobile && !isForHome && <IdeasSkeleton />}
      {isLoading && isMobile && !isForHome && <IdeasSkeletonMobile />}
      <MyDivContainer
        isHeader={isHeader}
        columns={getColumns}
        isForHome={isForHome}
      >
        <InfiniteScroll
          dataLength={isForHome ? 10 : ideaList.length}
          loader={
            isForHome ? (
              ''
            ) : (
              <div>
                <Loader />
              </div>
            )
          }
          next={isForHome ? () => {} : loadNext}
          hasMore={!!pagination?.next}
        >
          {cards}
        </InfiniteScroll>
      </MyDivContainer>
    </CustomRoot>
  );
};

const MyGridItem = styled(MasonryItemm)`
  width: 304px !important;
  @media ${MOBILE_RESOLUTION} {
    width: 156px !important;
  }
  height: fit-content;
  min-height: 285px;
`;

const MyDivContainer = styled.div<{
  isHeader: boolean;
  columns: number;
  isForHome: boolean;
}>`
  overflow-y: visible;
  margin-top: ${({ isHeader }) => (isHeader ? '50px' : '0')};
  .infinite-scroll-component {
    width: fit-content;
    margin: 0 auto;
    overflow: visible !important;
  }
  .MuiMasonry-root {
    overflow: hidden;
    ${({ theme }) => theme.utils.scrollbar}
    margin: ${({ isForHome }) => (isForHome ? '0' : '0 auto')};
    width: ${({ columns }) => {
      if (columns === 2) return '628px';

      if (columns === 3) return '952px';
      if (columns === 4) return '1276px';
      return '100%';
    }};
    @media (min-width: 767px) and (max-width: 1024px) {
      width: ${({ columns }) => {
        if (columns === 2) return '828px';
        if (columns === 3) return '1152px';
        if (columns === 4) return '1476px';
        return '100%';
      }};
    }
    @media (max-width: 767px) {
      margin: 0 auto;
      width: ${({ columns }) => {
        if (columns === 1) return '156px';
        if (columns === 2) return '327px';
        if (columns === 3) return '498px';
        if (columns === 4) return '669px';
        return '156px';
      }};
    }
  }

  ::-webkit-scrollbar {
    display: none;
  }
`;

const CustomRoot = styled(ClientRoot)<{
  isHeader: boolean;
  isForHome: boolean;
  columns: number;
}>`
  max-width: 1650px;
  margin: auto;
  ${({ isForHome, columns }) =>
    isForHome &&
    css`
      / width: 1650px; /
      padding-top: 0;
      margin-top: -26px;
      padding-bottom: 0;
      margin-bottom: -19px;
    `}
  ${({ isHeader }) =>
    !isHeader &&
    css`
      padding: 0;
    `}
`;

export default React.memo(Ideas);
