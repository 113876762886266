enum Message {
  APP_NAME = 'global.APP_NAME',
  LOADING = 'global.LOADING',
  OR = 'global.OR',
  ORCONNECTWITH = 'global.CONNECTWITH',
  CANCEL = 'global.CANCEL',
  SUBMIT = 'global.SUBMIT',
  DISCARD = 'global.DISCARD',
  YES = 'global.YES',
  NO = 'global.NO',
  DONE = 'global.DONE',
  NO_VALUE = 'global.NO_VALUE',
  AUTH_PROVIDERS_BUTTON = 'global.auth.AUTH_PROVIDERS_BUTTON',
  COUNTRY_SEARCH_PLACEHOLDER = 'global.COUNTRY_SEARCH_PLACEHOLDER',
  SEARCH_NO_RESULTS = 'global.SEARCH_NO_RESULTS',
  NOT_SUPPORTED_REGION = 'client.session.NOT_SUPPORTED_REGION',
  LOGOUT_CONFIRM_CAPTION = 'client.auth.LOGOUT_CONFIRM_CAPTION',
  PRIMARY_LANGUAGE = 'client.layout.PRIMARY_LANGUAGE',
  SECONDARY_LANGUAGE = 'client.layout.SECONDARY_LANGUAGE',
  SEARCH_PLACEHOLDER = 'client.layout.SEARCH_PLACEHOLDER',
  MENU_ITEM_LOGIN = 'client.layout.MENU_ITEM_LOGIN',
  MENU_ITEM_TEAM_MEMBER_LOGIN = 'client.layout.MENU_ITEM_TEAM_MEMBER_LOGIN',
  MENU_ITEM_MENU_ITEM_REGISTRATION = 'client.layout.MENU_ITEM_REGISTRATION',
  MENU_ITEM_PROFILE = 'client.layout.MENU_ITEM_PROFILE',
  MENU_ITEM_BUY_MORE_PATRICIA_COINS = 'client.layout.MENU_ITEM_BUY_MORE_PATRICIA_COINS',
  MENU_ITEM_PROPERTIES = 'client.layout.MENU_ITEM_PROPERTIES',
  MENU_ITEM_ORDERS = 'client.layout.MENU_ITEM_ORDERS',
  MENU_ITEM_PRODUCTS = 'client.layout.MENU_ITEM_PRODUCTS',
  MENU_ITEM_CART = 'client.layout.MENU_ITEM_CART',
  MENU_ITEM_SUPPORT = 'client.layout.MENU_ITEM_SUPPORT',
  MENU_ITEM_CONTACT = 'client.layout.MENU_ITEM_CONTACT',
  CONTACT_INFO_TITLE = 'client.layout.CONTACT_INFO_TITLE',
  CONTACT_INFO_DEPARTMENT = 'client.layout.CONTACT_INFO_DEPARTMENT',
  CONTACT_INFO_DEPARTMENT_OPTION1 = 'client.layout.CONTACT_INFO_DEPARTMENT_OPTION1',
  CONTACT_INFO_DEPARTMENT_OPTION2 = 'client.layout.CONTACT_INFO_DEPARTMENT_OPTION2',
  CONTACT_INFO_PUBLIC_AREA = 'client.layout.CONTACT_INFO_PUBLIC_AREA',
  CONTACT_INFO_PUBLIC_AREA_OPTION1 = 'client.layout.CONTACT_INFO_PUBLIC_AREA_OPTION1',
  CONTACT_INFO_PUBLIC_AREA_OPTION2 = 'client.layout.CONTACT_INFO_PUBLIC_AREA_OPTION2',
  CONTACT_INFO_PUBLIC_AREA_OPTION3 = 'client.layout.CONTACT_INFO_PUBLIC_AREA_OPTION3',
  CONTACT_INFO_PUBLIC_AREA_OPTION4 = 'client.layout.CONTACT_INFO_PUBLIC_AREA_OPTION4',
  CONTACT_INFO_PUBLIC_AREA_OPTION5 = 'client.layout.CONTACT_INFO_PUBLIC_AREA_OPTION5',
  CONTACT_INFO_PUBLIC_AREA_OPTION6 = 'client.layout.CONTACT_INFO_PUBLIC_AREA_OPTION6',
  CONTACT_INFO_PUBLIC_AREA_OPTION7 = 'client.layout.CONTACT_INFO_PUBLIC_AREA_OPTION7',
  CONTACT_INFO_COUNTRY_USA = 'client.layout.CONTACT_INFO_COUNTRY_USA',
  CONTACT_INFO_COUNTRY_ISRAEL = 'client.layout.CONTACT_INFO_COUNTRY_ISRAEL',
  CONTACT_INFO_WORK_DAY_TITLE = 'client.layout.CONTACT_INFO_WORK_DAY_TITLE',
  CONTACT_INFO_WORK_HOUR_TITLE = 'client.layout.CONTACT_INFO_WORK_HOUR_TITLE',
  CONTACT_INFO_WORK_DAYS_USA = 'client.layout.CONTACT_INFO_WORK_DAYS_USA',
  CONTACT_INFO_WORK_HOURS_USA = 'client.layout.CONTACT_INFO_WORK_HOURS_USA',
  CONTACT_INFO_WORK_DAYS_ISRAEL = 'client.layout.CONTACT_INFO_WORK_DAYS_ISRAEL',
  CONTACT_INFO_WORK_HOURS_ISRAEL = 'client.layout.CONTACT_INFO_WORK_HOURS_ISRAEL',
  CONTACT_INFO_WORK_PHONE_USA = 'client.layout.CONTACT_INFO_WORK_PHONE_USA',
  CONTACT_INFO_WORK_PHONE_ISRAEL = 'client.layout.CONTACT_INFO_WORK_PHONE_ISRAEL',
  CONTACT_INFO_WORK_EMAIL = 'client.layout.CONTACT_INFO_WORK_EMAIL',
  CONTACT_INFO_DEVICE_INFO = 'client.layout.CONTACT_INFO_DEVICE_INFO',
  CONTACT_INFO_DEVICE_INFO_OPTION1 = 'client.layout.CONTACT_INFO_DEVICE_INFO_OPTION1',
  CONTACT_INFO_DEVICE_INFO_OPTION2 = 'client.layout.CONTACT_INFO_DEVICE_INFO_OPTION2',
  CONTACT_INFO_DEVICE_INFO_OPTION3 = 'client.layout.CONTACT_INFO_DEVICE_INFO_OPTION3',
  CONTACT_INFO_DEVICE_INFO_OPTION4 = 'client.layout.CONTACT_INFO_DEVICE_INFO_OPTION4',
  CONTACT_INFO_DEVICE_INFO_VERSION = 'client.layout.CONTACT_INFO_DEVICE_INFO_VERSION',
  CONTACT_INFO_DESCRIPTION = 'client.layout.CONTACT_INFO_DESCRIPTION',
  CONTACT_INFO_THANK_YOU = 'client.layout.CONTACT_INFO_THANK_YOU',
  CONTACT_INFO_BACK = 'client.layout.CONTACT_INFO_BACK',
  CONTACT_SEND = 'client.layout.CONTACT_SEND',
  CONTACT_EMAIL_ADDRESS = 'client.layout.CONTACT_EMAIL_ADDRESS',
  CONTACT_ENTER_YOUR_MESSAGE = 'client.layout.CONTACT_ENTER_YOUR_MESSAGE',
  MENU_ITEM_LOGOUT = 'client.layout.MENU_ITEM_LOGOUT',
  FOOTER_APP_DESCRIPTION = 'client.layout.FOOTER_APP_DESCRIPTION',
  FOOTER_COPYRIGHT_STATEMENT = 'client.layout.FOOTER_COPYRIGHT_STATEMENT',
  FOOTER_ABOUT_MENU_TITLE = 'client.layout.FOOTER_ABOUT_MENU_TITLE',
  FOOTER_ABOUT_MENU_OUR_STORY = 'client.layout.FOOTER_ABOUT_MENU_OUR_STORY',
  FOOTER_ABOUT_MENU_TEAM = 'client.layout.FOOTER_ABOUT_MENU_TEAM',
  FOOTER_ABOUT_MENU_CAREERS = 'client.layout.FOOTER_ABOUT_MENU_CAREERS',
  FOOTER_ABOUT_MENU_NEWS = 'client.layout.FOOTER_ABOUT_MENU_NEWS',
  FOOTER_ABOUT_MENU_MAGAZINE = 'client.layout.FOOTER_ABOUT_MENU_MAGAZINE',
  FOOTER_SUPPORT_MENU_TITLE = 'client.layout.FOOTER_SUPPORT_MENU_TITLE',
  FOOTER_SUPPORT_MENU_QA = 'client.layout.FOOTER_SUPPORT_MENU_OUR_QA',
  FOOTER_SUPPORT_MENU_TERMS = 'client.layout.FOOTER_SUPPORT_MENU_TERMS',
  FOOTER_SUPPORT_MENU_PRIVACY = 'client.layout.FOOTER_SUPPORT_MENU_PRIVACY',
  FOOTER_SUPPORT_MENU_CONTACT = 'client.layout.FOOTER_SUPPORT_MENU_CONTACT',
  FOOTER_SUPPORT_MENU_ACCESSIBILITY = 'client.layout.FOOTER_SUPPORT_MENU_ACCESSIBILITY',
  FOOTER_COMMUNITY_MENU_TITLE = 'client.layout.FOOTER_COMMUNITY_MENU_TITLE',
  FOOTER_COMMUNITY_MENU_EXPERT = 'client.layout.FOOTER_COMMUNITY_MENU_OUR_QA',
  FOOTER_COMMUNITY_MENU_SUPPLIER = 'client.layout.FOOTER_COMMUNITY_MENU_TERMS',
  FOOTER_COMMUNITY_MENU_SUPPLIER_INTERFACE = 'client.layout.FOOTER_COMMUNITY_MENU_PRIVACY',
  FOOTER_COMMUNITY_BECOME_A_SUPPLIER = 'client.layout.FOOTER_COMMUNITY_BECOME_A_SUPPLIER',
  FOOTER_CONTACT_MENU_TITLE = 'client.layout.FOOTER_CONTACT_MENU_TITLE',
  FOOTER_CONTACT_MENU_CUSTOMER_SERVICE = 'client.layout.FOOTER_CONTACT_MENU_CUSTOMER_SERVICE',
  FOOTER_CONTACT_MENU_PRIMARY_OFFICE_TITLE = 'client.layout.FOOTER_CONTACT_MENU_PRIMARY_OFFICE_TITLE',
  FOOTER_CONTACT_MENU_SECONDARY_OFFICE_TITLE = 'client.layout.FOOTER_CONTACT_MENU_SECONDARY_OFFICE_TITLE',
  FOOTER_CONTACT_MENU_WORK_DAYS = 'client.layout.FOOTER_CONTACT_MENU_WORK_DAYS',
  FOOTER_CONTACT_MENU_WORK_HOURS = 'client.layout.FOOTER_CONTACT_MENU_WORK_HOURS',
  FOOTER_CONTACT_MENU_PRIMARY_OFFICE_WORK_DAYS = 'client.layout.FOOTER_CONTACT_MENU_PRIMARY_OFFICE_WORK_DAYS',
  FOOTER_CONTACT_MENU_SECONDARY_OFFICE_WORK_DAYS = 'client.layout.FOOTER_CONTACT_MENU_SECONDARY_OFFICE_WORK_DAYS',
  FOOTER_CONTACT_MENU_PRIMARY_OFFICE_WORK_HOURS = 'client.layout.FOOTER_CONTACT_MENU_PRIMARY_OFFICE_WORK_HOURS',
  FOOTER_CONTACT_MENU_SECONDARY_OFFICE_WORK_HOURS = 'client.layout.FOOTER_CONTACT_MENU_SECONDARY_OFFICE_WORK_HOURS',
  FOOTER_CONTACT_MENU_PRIMARY_OFFICE_PHONE = 'client.layout.FOOTER_CONTACT_MENU_PRIMARY_OFFICE_PHONE',
  FOOTER_CONTACT_MENU_SECONDARY_OFFICE_PHONE = 'client.layout.FOOTER_CONTACT_MENU_SECONDARY_OFFICE_PHONE',
  FOOTER_CONTACT_MENU_PRIMARY_OFFICE_EMAIL = 'client.layout.FOOTER_CONTACT_MENU_PRIMARY_OFFICE_EMAIL',
  FOOTER_CONTACT_MENU_SECONDARY_OFFICE_EMAIL = 'client.layout.FOOTER_CONTACT_MENU_SECONDARY_OFFICE_EMAIL',
  FOOTER_CONTACT_QUICK_SERVICE = 'client.layout.FOOTER_CONTACT_QUICK_SERVICE',
  FOOTER_CONTACT_WEEKLY_HOURS = 'client.layout.FOOTER_CONTACT_WEEKLY_HOURS',
  FREE_DELIVERY = 'client.layout.FREE_DELIVERY',
  FREE_DELIVERY_CONTENT_1 = 'client.layout.FREE_DELIVERY_CONTENT_1',
  FREE_DELIVERY_CONTENT_2 = 'client.layout.FREE_DELIVERY_CONTENT_2',
  FREE_DELIVERY_CONTENT_3 = 'client.layout.FREE_DELIVERY_CONTENT_3',
  HOME_HERO_TITLE = 'client.homepage.HOME_HERO_TITLE',
  HOME_HERO_TITLE_WELCOME = 'client.homepage.HOME_HERO_TITLE_WELCOME',
  HOME_HERO_CAPTION = 'client.homepage.HOME_HERO_CAPTION',
  HOME_WATCH_DEMO = 'client.homepage.WATCH_DEMO',
  HOME_HERO_CAPTION_SHOP = 'client.homepage.HOME_HERO_CAPTION_SHOP',
  HOME_HERO_SUMMARY = 'client.homepage.HOME_HERO_SUMMARY',
  CREATE_HOME_BUTTON = 'client.homepage.CREATE_HOME_BUTTON',
  ENTER_HOME_BUTTON = 'client.homepage.ENTER_HOME_BUTTON',
  HOME_STORIES_TITLE = 'client.homepage.HOME_STORIES_TITLE',
  HOME_STORIES_MEET_PATRICIA = 'client.homepage.HOME_STORIES_MEET_PATRICIA',
  HOME_STORIES_HOW_TO_START = 'client.homepage.HOME_STORIES_HOW_TO_START',
  HOME_STORIES_BRANDS = 'client.homepage.HOME_STORIES_BRANDS',
  HOME_STORIES_SKINS = 'client.homepage.HOME_STORIES_SKINS',
  HOME_STORIES_PRODUCTS_DESIGNS = 'client.homepage.HOME_STORIES_PRODUCTS_DESIGNS',
  HOME_IDEAS_TITLE = 'client.homepage.HOME_IDEAS_TITLE',
  HOME_SKINS_TITLE = 'client.homepage.HOME_SKINS_TITLE',
  HOME_SKINS_TITLE_BUTTON = 'client.homepage.HOME_SKINS_TITLE_BUTTON',
  HOME_SKINS_EMPTY_STATE_MESSAGE = 'client.homepage.HOME_SKINS_EMPTY_STATE_MESSAGE',
  HOME_MAGAZINE_TITLE = 'client.homepage.HOME_MAGAZINE_TITLE',
  HOME_EXPERTS_TITLE = 'client.homepage.HOME_EXPERTS_TITLE',
  HOME_BECOME_A_SUPPLIER_TITLE = 'client.HOME_BECOME_A_SUPPLIER_TITLE',
  HOME_PRODUCTS_TITLE = 'client.homepage.HOME_PRODUCTS_TITLE',
  HOME_PRODUCTS_TITLE_BUTTON = 'client.homepage.PRODUCTS_TITLE_BUTTON',
  HOME_MAGAZINE_BANNER_TITLE = 'client.homepage.HOME_MAGAZINE_BANNER_TITLE',
  HOME_MAGAZINE_BANNER_CAPTION = 'client.homepage.HOME_MAGAZINE_BANNER_CAPTION',
  HOME_MAGAZINE_BANNER_ACTION = 'client.homepage.HOME_MAGAZINE_BANNER_ACTION',
  HOME_SUPPLIER_CMS_TITLE = 'client.HOME_SUPPLIER_CMS_TITLE',

  HOME_SKINS_BANNER_TITLE = 'client.homepage.HOME_SKINS_BANNER_TITLE',
  HOME_SKINS_BANNER_CAPTION = 'client.homepage.HOME_SKINS_BANNER_CAPTION',
  HOME_SKINS_BANNER_ACTION = 'client.homepage.HOME_SKINS_BANNER_ACTION',
  HOME_EXPERTS_BANNER_TITLE = 'client.homepage.HOME_EXPERTS_BANNER_TITLE',
  HOME_EXPERTS_BANNER_CAPTION = 'client.homepage.HOME_EXPERTS_BANNER_CAPTION',
  HOME_BECOME_A_SUPPLIER_BANNER_CAPTION = 'client.HOME_BECOME_A_SUPPLIER_BANNER_CAPTION',
  HOME_BECOME_A_SUPPLIER_BANNER_CAPTION_MOBILE = 'client.HOME_BECOME_A_SUPPLIER_BANNER_CAPTION_MOBILE',
  HOME_EXPERTS_BANNER_ACTION = 'client.homepage.HOME_EXPERTS_BANNER_ACTION',
  HOME_BECOME_A_SUPPLIER_BANNER_ACTION = 'clint.HOME_BECOME_A_SUPPLIER_BANNER_ACTION',
  HOME_ENTER_YOUR_HOME_TOOLTIP_TEXT = 'client.HOME_ENTER_YOUR_HOME_TOOLTIP_TEXT',
  SHOP_NOW = 'client.homepage.SHOP_NOW',
  SEARCH_RESULT_FOR = 'client.homepage.SEARCH_RESULT_FOR',
  YOUR_SEARCH_RESULT_FOR = 'client.homepage.YOUR_SEARCH_RESULT_FOR',
  PROPERTIES_TITLE = 'client.properties.PROPERTIES_TITLE',
  PROPERTIES_BUTTON = 'client.properties.PROPERTIES_BUTTON',
  PROPERTIES_INFO_BOX_TITLE = 'client.properties.PROPERTIES_INFO_BOX_TITLE',
  PROPERTIES_INFO_BOX_TEXT = 'client.properties.PROPERTIES_INFO_BOX_TEXT',
  PROPERTIES_EMPTY_STATE_CAPTION = 'client.properties.PROPERTIES_EMPTY_STATE_CAPTION',
  PROPERTIES_EMPTY_STATE_BUTTON_LABEL = 'client.properties.PROPERTIES_EMPTY_STATE_BUTTON_LABEL',
  PROPERTY_CARD_NAME = 'client.properties.PROPERTY_CARD_NAME',
  PROPERTY_CARD_QUIZ_NAME = 'client.properties.PROPERTY_CARD_QUIZ_NAME',
  PROPERTY_CARD_LAST_MODIFIED = 'client.properties.PROPERTY_CARD_LAST_MODIFIED',
  PROPERTY_CARD_DESIGN_STYLE = 'client.properties.PROPERTY_CARD_DESIGN_STYLE',
  PROPERTY_SIZE = 'client.properties.PROPERTY_SIZE',
  PROPERTY_NOT_FOUND = 'client.properties.PROPERTY_NOT_FOUND',
  PROPERTY_ADD_PROPERTY_MODAL_TITLE = 'client.properties.PROPERTY_ADD_PROPERTY_MODAL_TITLE',
  PROPERTY_ADD_CLIENT_MODAL_TITLE = 'client.properties.PROPERTY_ADD_CLIENT_MODAL_TITLE',
  PROPERTY_ADD_PROPERTY_BUTTON_NEXT = 'client.properties.PROPERTY_ADD_PROPERTY_BUTTON',
  PROPERTY_ADD_PROPERTY_BUTTON_UPLOAD = 'client.properties.PROPERTY_ADD_PROPERTY_BUTTON_UPLOAD',
  PROPERTY_ADD_PROPERTY_BUTTON_PREVIOUS = 'client.properties.PROPERTY_ADD_PROPERTY_BUTTON_PREVIOUS',
  PROPERTY_ADD_PROPERTY_BUTTON_SKIP = 'client.properties.PROPERTY_ADD_PROPERTY_BUTTON_SKIP',
  PROPERTY_ADD_PROPERTY_MODAL_QUIZ_TITLE = 'client.properties.PROPERTY_ADD_PROPERTY_MODAL_QUIZ_TITLE',
  PROPERTY_ADD_PROPERTY_MODAL_SELECT_QUIZ = 'client.properties.PROPERTY_ADD_PROPERTY_MODAL_SELECT_QUIZ',
  PROPERTY_ADD_PROPERTY_MODAL_QUIZ_RESULT = 'client.properties.PROPERTY_ADD_PROPERTY_MODAL_QUIZ_RESULT',
  PROPERTY_ADD_PROPERTY_MODAL_START_QUIZ = 'client.properties.PROPERTY_ADD_PROPERTY_MODAL_START_QUIZ',
  PROPERTY_ADD_PROPERTY_ADD_BUTTON = 'client.properties.PROPERTY_ADD_PROPERTY_ADD_BUTTON',
  PROPERTY_EDIT_PROPERTY = 'client.properties.PROPERTY_EDIT_PROPERTY',
  PROPERTY_BUTTON_TAKE_TOUR = 'client.properties.PROPERTY_BUTTON_TAKE_TOUR',
  PROPERTY_PRODUCTS_TITLE = 'client.properties.PROPERTY_PRODUCTS_TITLE',
  PROPERTY_MEDIA_MODAL_TITLE = 'client.properties.PROPERTY_MEDIA_MODAL_TITLE',
  PROPERTY_CHIP_MODAL_TITLE = 'client.properties.PROPERTY_CHIP_MODAL_TITLE',
  PROPERTY_MODAL_TITLE_SELLER = 'client.properties.PROPERTY_MODAL_TITLE_SELLER',
  PROPERTY_MODAL_UPLOAD_FLOOR_PLAN = 'client.properties.PROPERTY_MODAL_UPLOAD_FLOOR_PLAN',
  PROPERTY_MODAL_UPLOAD_FLOOR_PLAN_SUBTITLE = 'client.properties.PROPERTY_MODAL_UPLOAD_FLOOR_PLAN_SUBTITLE',
  PROPERTY_MODAL_DO_IT_YOURSELF_TITLE = 'client.properties.PROPERTY_MODAL_DO_IT_YOURSELF_TITLE',
  PROPERTY_MODAL_DO_IT_YOURSELF_SUBTITLE = 'client.properties.PROPERTY_MODAL_DO_IT_YOURSELF_SUBTITLE',
  PROPERTY_MODAL_DEMO_FLOOR_PLANS_TITLE = 'client.properties.PROPERTY_MODAL_DEMO_FLOOR_PLANS_TITLE',
  PROPERTY_MODAL_DEMO_FLOOR_PLANS_SUBTITLE = 'client.properties.PROPERTY_MODAL_DEMO_FLOOR_PLANS_SUBTITLE',
  PROPERTY_MODAL_COMING_SOON = 'client.properties.PROPERTY_MODAL_COMING_SOON',
  PROPERTY_MODAL_CUSTOMER_DETAILS = 'client.properties.PROPERTY_MODAL_CUSTOMER_DETAILS',
  PROPERTY_MODAL_VALIDATION_MESSAGE = 'client.properties.PROPERTY_MODAL_VALIDATION_MESSAGE',
  PROPERTY_PRODUCTS_NO_ITEMS = 'client.properties.PROPERTY_PRODUCTS_NO_ITEMS',
  PROPERTY_BUTTON_SHOW_MORE = 'client.properties.PROPERTY_BUTTON_SHOW_MORE',
  PROPERTY_ORDERS_TITLE = 'client.properties.PROPERTY_ORDERS_TITLE',
  PROPERTY_CANCEL_ORDERS_TITLE = 'client.properties.PROPERTY_CANCEL_ORDERS_TITLE',
  PROPERTY_CANCEL_ORDERS_DESCRIPTION_LABLE = 'client.properties.PROPERTY_CANCEL_ORDERS_DESCRIPTION_LABLE',
  PROPERTY_CANCEL_ORDERS_CONTACTINFO_LABLE = 'client.properties.PROPERTY_CANCEL_ORDERS_CONTACTINFO_LABLE',
  PROPERTY_CANCEL_ORDERS_DESCRIPTION = 'client.properties.PROPERTY_CANCEL_ORDERS_DESCRIPTION',
  ORDERS_CARD_ORDER_NUMBER = 'client.properties.ORDERS_CARD_ORDER_NUMBER',
  ORDERS_CARD_TRACKING_NUMBER = 'client.orders.ORDERS_CARD_TRACKING_NUMBER',
  PROPERTY_ORDERS_CARD_ORDER_DATE = 'client.properties.PROPERTY_ORDERS_CARD_ORDER_DATE',
  PROPERTY_ORDERS_NO_ORDERS_TITLE = 'client.properties.PROPERTY_ORDERS_NO_ORDERS_TITLE',
  PROPERTY_ORDERS_NO_ORDERS_TEXT1 = 'client.properties.PROPERTY_ORDERS_NO_ORDERS_TEXT1',
  PROPERTY_ORDERS_NO_ORDERS_SKINS = 'client.properties.PROPERTY_ORDERS_NO_ORDERS_SKINS',
  PROPERTY_ORDERS_NO_ORDERS_TEXT2 = 'client.properties.PROPERTY_ORDERS_NO_ORDERS_TEXT2',
  MORE_PROPERTIES_MODAL_TITLE = 'client.properties.MORE_PROPERTIES_MODAL_TITLE',
  MORE_PROPERTIES_MODAL_TEXT = 'client.properties.MORE_PROPERTIES_MODAL_TEXT',
  MORE_PROPERTIES_MODAL_BUTTON = 'client.properties.MORE_PROPERTIES_MODAL_BUTTON',
  CAROUSEL_MEDIA_TITLE = 'client.properties.CAROUSEL_MEDIA_TITLE',
  MEDIA_SECTION_TITLE = 'client.properties.MEDIA_SECTION_TITLE',
  CART_TITLE = 'client.cart.CART_TITLE',
  CART_TOTAL_ITEMS_LABEL = 'client.cart.CART_TOTAL_ITEMS_LABEL',
  CART_TOTAL_ITEMS_LABEL_MOBILE = 'client.cart.CART_TOTAL_ITEMS_LABEL_MOBILE',
  CART_ITEM_OUT_OF_STOCK_LABEL = 'client.cart.CART_ITEM_OUT_OF_STOCK_LABEL',
  CART_ITEM_STOCK_LABEL = 'client.cart.CART_ITEM_STOCK_LABEL',
  CART_ITEM_STOCK_LABEL_FULL = 'client.cart.CART_ITEM_STOCK_LABEL_FULL',
  CART_ITEM_STOCK_LABEL_FEW_IN_STOCK = 'client.cart.CART_ITEM_STOCK_LABEL_FEW_IN_STOCK',
  CART_MAXIMUM_TEXT = 'client.cart.CART_MAXIMUM_TEXT',
  CART_ADDITIONAL_TEXT = 'client.cart.CART_ADDITIONAL_TEXT',
  CHECKOUT_INFO_TITLE = 'client.cart.CHECKOUT_INFO_TITLE',
  CHECKOUT_ORDER_SUMMARY_TITLE = 'client.cart.CHECKOUT_ORDER_SUMMARY_TITLE',
  CHECKOUT_YOUR_CART_TITLE = 'client.cart.CHECKOUT_YOUR_CART_TITLE',
  BILLING_SHIPPING_ADDRESS_SAME_LABEL = 'client.checkout.CHECKOUT_PERSONAL_DETAIL_TITLE',
  CHECKOUT_PERSONAL_DETAIL_TITLE = 'client.cart.CHECKOUT_PERSONAL_DETAIL_TITLE',
  CHECKOUT_PERSONAL_DETAIL_FULLNAME = 'client.cart.CHECKOUT_PERSONAL_DETAIL_FULLNAME',
  CHECKOUT_SHIPPING_ADDRESS_TITLE = 'client.cart.CHECKOUT_SHIPPING_ADDRESS_TITLE',
  CHECKOUT_SUB_TOTAL_LABEL = 'client.cart.CHECKOUT_SUB_TOTAL_LABEL',
  BUYCOIN_SUB_TOTAL_LABEL = 'client.cart.BUYCOIN_SUB_TOTAL_LABEL',
  CHECKOUT_VAT_LABEL = 'client.checkout.CHECKOUT_VAT_LABEL',
  CHECKOUT_TAX_LABEL = 'client.checkout.CHECKOUT_TAX_LABEL',
  CHECKOUT_SHIPPING_LABEL = 'client.checkout.CHECKOUT_SHIPPING_LABEL',
  CHECKOUT_DISCOUNT_LABEL = 'client.checkout.CHECKOUT_DISCOUNT_LABEL',
  CHECKOUT_TOTAL_LABEL = 'client.checkout.CHECKOUT_TOTAL_LABEL',
  CHECKOUT_PROCEED_BUTTON = 'client.checkout.CHECKOUT_PROCEED_BUTTON',
  CHECKOUT_DISCOUNT_BUTTON = 'client.checkout.CHECKOUT_DISCOUNT_BUTTON',
  CHECKOUT_ADD_NEW_ADDRESS_BUTTON = 'client.checkout.CHECKOUT_ADD_NEW_ADDRESS_BUTTON',
  CHECKOUT_ADD_NEW_ADDRESS_TEXT = 'client.checkout.CHECKOUT_ADD_NEW_ADDRESS_TEXT',
  CHECKOUT_TOOLTIP_INVALID_NOTFOUND_TEXT = 'client.checkout.CHECKOUT_TOOLTIP_INVALID_NOTFOUND_TEXT',
  CHECKOUT_TOOLTIP_INVALID_MAX_USES_TEXT = 'client.checkout.CHECKOUT_TOOLTIP_INVALID_MAX_USES_TEXT',
  CHECKOUT_TOOLTIP_EXPIRED_TEXT = 'client.checkout.CHECKOUT_TOOLTIP_EXPIRED_TEXT',
  CART_CHECKOUT_BUTTON = 'client.cart.CART_CHECKOUT_BUTTON',
  CART_PLACE_ORDER_BUTTON = 'client.cart.CART_PLACE_ORDER_BUTTON',
  CART_KEEP_SHOPPING_BUTTON = 'client.CART.CART_KEEP_SHOPPING_BUTTON',
  ADDED_TO_CART = 'client.CART.ADDED_TO_CART',
  CREDIT_CARD_PROVIDER_TITLE = 'client.cart.CREDIT_CARD_PROVIDER_TITLE',
  CREDIT_CARD_PROVIDER_TEXT = 'client.cart.CREDIT_CARD_PROVIDER_TEXT',
  CART_RESTRICTION_MODAL_TITLE = 'client.cart.CHECKOUT_RESTRICTION_MODAL',
  CART_RESTRICTION_MODAL_MISSING_ITEMS_ERROR = 'client.cart.CART_RESTRICTION_MODAL_MISSING_ITEMS_ERROR',
  CART_RESTRICTION_MODAL_MISSING_ITEMS_SUBTITLE = 'client.cart.CART_RESTRICTION_MODAL_MISSING_ITEMS_SUBTITLE',
  CART_RESTRICTION_MODAL_CONTINUE_BUTTON = 'client.cart.CART_RESTRICTION_MODAL_CONTINUE_BUTTON.',
  CART_RESTRICTION_MODAL_EDIT_CART_BUTTON = 'client.cart.CART_RESTRICTION_MODAL_EDIT_CART_BUTTON',
  CART_EMPTY_STATE_TITLE = 'client.cart.CART_EMPTY_STATE_TITLE.',
  CART_EMPTY_STATE_BUTTON = 'client.cart.CART_EMPTY_STATE_BUTTON',
  FREE_DELIVERY_BUTTON = 'client.cart.FREE_DELIVERY_BUTTON',
  CART_OUT_OF_STOCK_CAPTION = 'client.cart.CART_OUT_OF_STOCK_CAPTION',
  CART_DELETE_CONFIRMATION_MODAL_TITLE = 'client.cart.CART_DELETE_CONFIRMATION_MODAL_TITLE',
  CART_DELETE_CONFIRMATION_MODAL_CAPTION = 'client.cart.CART_DELETE_CONFIRMATION_MODAL_CAPTION',
  CART_ALL_DELETE_CONFIRMATION_MODAL_CAPTION = 'client.cart.CART_ALL_DELETE_CONFIRMATION_MODAL_CAPTION',
  CART_DELETE_CONFIRMATION_MODAL_OK = 'client.cart.CART_DELETE_CONFIRMATION_MODAL_OK',
  CHECKOUT_DISCOUNT_PLACEHOLDER = 'client.checkout.CART_DISCOUNT_PLACEHOLDER',
  CHECKOUT_TITLE = 'client.checkout.CHECKOUT_TITLE',
  CHECKOUT_ITEM_QUANTITY_LABEL = 'client.cart.CHECKOUT_ITEM_QUANTITY_LABEL',
  CHECKOUT_SHIPPING_OPTIONS_TITLE = 'client.checkout.CHECKOUT_SHIPPING_OPTIONS_TITLE',
  CHECKOUT_STANDARD_SHIPPING_TITLE = 'client.checkout.CHECKOUT_STANDARD_SHIPPING_TITLE',
  CHECKOUT_LATE_SHIPPING_TITLE = 'client.checkout.CHECKOUT_LATE_SHIPPING_TITLE',
  CHECKOUT_SHIPPING_LABEL_DESCRIPTION = 'client.checkout.CHECKOUT_STANDARD_SHIPPING_DESCRIPTION',
  CHECKOUT_LATE_SHIPPING_LABEL_DESCRIPTION = 'client.checkout.CHECKOUT_LATE_SHIPPING_LABEL_DESCRIPTION',
  CHECKOUT_EXPRESS_SHIPPING_TITLE = 'client.checkout.CHECKOUT_EXPRESS_SHIPPING_TITLE',
  CHECKOUT_MISSING_ADDRESS_CAPTION = 'client.checkout.CHECKOUT_MISSING_ADDRESS_CAPTION',
  CHECKOUT_MISSING_ADDRESS_ACTION = 'client.checkout.CHECKOUT_MISSING_ADDRESS_ACTION',
  CHECKOUT_SUCCESS_CAPTION = 'client.checkout.CHECKOUT_SUCCESS_CAPTION',
  CHECKOUT_SUCCESS_BUTTON = 'client.checkout.CHECKOUT_SUCCESS_BUTTON',
  BREADCRUMBS_HOME_BUTTON = 'client.breadcrumbs.BREADCRUMBS_HOME_BUTTON',
  USER_SHIPPING_ADDRESSES = 'client.user.USER_SHIPPING_ADDRESSES',
  USER_DEFAULT_SHIPPING_ADDRESS = 'client.user.USER_DEFAULT_SHIPPING_ADDRESS',
  USER_SET_DEFAULT_ADDRESS_BUTTON = 'client.user.USER_SET_DEFAULT_ADDRESS_BUTTON',
  USER_ADD_NEW_ADDRESS_BUTTON = 'client.user.USER_ADD_NEW_ADDRESS_BUTTON',

  USER_DELETE_ACCOUNT_HEADING_1 = 'client.user.USER_DELETE_ACCOUNT_HEADING_1',
  USER_DELETE_ACCOUNT_HEADING_2 = 'client.user.USER_DELETE_ACCOUNT_HEADING_2',
  USER_DELETE_ACCOUNT_BUTTON = 'client.user.USER_DELETE_ACCOUNT_BUTTON',

  USER_DELETE_ACCOUNT_GREETING_TEXT = 'client.user.USER_DELETE_ACCOUNT_GREETING_TEXT',
  USER_DELETE_ACCOUNT_ENTER_EMAIL_SUBTITLE = 'client.user.USER_DELETE_ACCOUNT_ENTER_EMAIL_SUBTITLE',
  USER_DELETE_ACCOUNT_EMAIL_ADDRESS = 'client.user.USER_DELETE_ACCOUNT_EMAIL_ADDRESS',
  USER_DELETE_ACCOUNT_CANCEL_BUTTON = 'client.user.USER_DELETE_ACCOUNT_CANCEL_BUTTON',
  USER_DELETE_ACCOUNT_NEXT_BUTTON = 'client.user.USER_DELETE_ACCOUNT_NEXT_BUTTON',
  USER_DELETE_ACCOUNT_DELETE_BUTTON = 'client.user.USER_DELETE_ACCOUNT_DELETE_BUTTON',
  USER_DELETE_ACCOUNT_SECOND_MODAL_TITLE = 'client.user.USER_DELETE_ACCOUNT_SECOND_MODAL_TITLE',
  USER_DELETE_ACCOUNT_SECOND_MODAL_SUBTITLE = 'client.user.USER_DELETE_ACCOUNT_SECOND_MODAL_SUBTITLE',

  EDIT_BUTTON = 'client.EDIT_BUTTON',
  BUTTON_SHOW_ALL_MEDIA = 'client.BUTTON_SHOW_ALL_MEDIA',
  USER_DETAILS = 'client.account.ACCOUNT_USER_DETAILS',
  ACCOUNT_SUGGESTED_PRODUCTS_TITLE = 'client.account.ACCOUNT_SUGGESTED_PRODUCTS_TITLE',
  ACCOUNT_SUGGESTED_PRODUCTS_EMPTY_STATE = 'client.account.ACCOUNT_SUGGESTED_PRODUCTS_EMPTY_STATE',
  ACCOUNT_SUGGESTED_SKINS_TITLE = 'client.account.ACCOUNT_SUGGESTED_SKINS_TITLE',
  ACCOUNT_SUGGESTED_SKINS_EMPTY_STATE = 'client.account.ACCOUNT_SUGGESTED_SKINS_EMPTY_STATE',
  PROFILE_INFO_BOX_TITLE = 'client.PROFILE_INFO_BOX_TITLE',
  PROFILE_INFO_BOX_DETAILS = 'client.PROFILE_INFO_BOX_DETAILS',
  PROFILE_INFO_BOX_SHIPPING_TITLE = 'client.PROFILE_INFO_BOX_SHIPPING_TITLE',
  PROFILE_INFO_BOX_SHIPPING_DETAILS = 'client.PROFILE_INFO_BOX_SHIPPING_DETAILS',
  FIRST_NAME = 'client.FIRST_NAME',
  LAST_NAME = 'client.LAST_NAME',
  PHONE_NUMBER = 'client.PHONE_NUMBER',
  EMAIL_TITLE = 'client.EMAIL_TITLE',
  SAVE_TITLE = 'client.SAVE_TITLE',
  SAVE_CHANGES = 'client.SAVE_CHANGES',
  ADDRESS_FORM_EDIT = 'client.ADDRESS_FORM_EDIT',
  ADDRESS_FORM_EDIT_TO_SHIP = 'client.ADDRESS_FORM_EDIT_TO_SHIP',
  ADDRESS_FORM_EDIT_INFO_BOX_TEXT = 'client.ADDRESS_FORM_EDIT_INFO_BOX_TEXT',
  ADDRESS_FORM_ADD_INFO_BOX_TEXT = 'client.ADDRESS_FORM_ADD_INFO_BOX_TEXT',
  ADDRESS_FORM_ADD_NEW_ADDRESS = 'client.ADDRESS_FORM_ADD_NEW_ADDRESS',
  ADDRESS_FORM_COUNTRY = 'client.ADDRESS_FORM_COUNTRY',
  ADDRESS_FORM_SELECT_COUNTRY = 'client.ADDRESS_FORM_SELECT_COUNTRY',
  ADDRESS_FORM_CITY = 'client.ADDRESS_FORM_CITY',
  ADDRESS_FORM_SELECT_CITY = 'client.ADDRESS_FORM_SELECT_CITY',
  ADDRESS_FORM_STREET_ADDRESS = 'client.ADDRESS_FORM_STREET_ADDRESS',
  ADDRESS_FORM_ADDRESS_LINE1 = 'client.ADDRESS_FORM_ADDRESS_LINE1',
  ADDRESS_FORM_ADDRESS_LINE2 = 'client.ADDRESS_FORM_ADDRESS_LINE2',
  ADDRESS_FORM_HOUSE_NO = 'client.ADDRESS_FORM_HOUSE_NO',
  ADDRESS_FORM_ENTRANCE = 'client.ADDRESS_FORM_ENTRANCE',
  ADDRESS_FORM_FLOOR = 'client.ADDRESS_FORM_FLOOR',
  ADDRESS_FORM_CHECKBOX_ELEVATOR = 'client.ADDRESS_FORM_CHECKBOX_ELEVATOR',
  ADDRESS_FORM_POSTCODE = 'client.ADDRESS_FORM_POSTCODE',
  ADDRESS_FORM_OPTIONAL = 'client.ADDRESS_FORM_OPTIONAL',
  ADDRESS_FORM_DEFAULT_ADDRESS = 'client.ADDRESS_FORM_DEFAULT_ADDRESS',
  ADDRESS_FORM_DEFAULT_ADDRESS_NEW = 'client.ADDRESS_FORM_DEFAULT_ADDRESS_NEW',
  ADDRESS_FORM_DELETE_ADDRESS = 'client.ADDRESS_FORM_DELETE_ADDRESS',
  ADDRESS_FORM_BEFORE_LEAVE_DELETE_ADDRESS_TITLE = 'CLIENT.ADDRESS_FORM_BEFORE_LEAVE_DELETE_ADDRESS_TITLE',
  ADDRESS_FORM_BEFORE_LEAVE_DELETE_ADDRESS_TEXT = 'client.ADDRESS_FORM_BEFORE_LEAVE_DELETE_ADDRESS_TEXT',
  ADDRESS_FORM_CONFIRM_MODAL_CHANGES_TITLE = 'client.ADDRESS_FORM_CONFIRM_MODAL_CHANGES_TITLE',
  ADDRESS_FORM_CONFIRM_MODAL_CHANGES_TEXT = 'client.ADDRESS_FORM_CONFIRM_MODAL_CHANGES_TEXT',
  INPUT_NAME_ERROR = 'client.INPUT_NAME_ERROR',
  CLIENT_AUTH_TITLE = 'client.auth.CLIENT_AUTH_TITLE',
  CLIENT_AUTH_ONBOARDING_BUTTON = 'client.auth.CLIENT_AUTH_ONBOARDING_BUTTON',
  CLIENT_AUTH_ONBOARDING_TITLE = 'client.auth.CLIENT_AUTH_ONBOARDING_TITLE',
  CLIENT_AUTH_ONBOARDING_DESCRIPTION_1 = 'client.auth.CLIENT_AUTH_ONBOARDING_DESCRIPTION_1',
  CLIENT_AUTH_ONBOARDING_DESCRIPTION_2 = 'client.auth.CLIENT_AUTH_ONBOARDING_DESCRIPTION_2',
  CLIENT_AUTH_ONBOARDING_DESCRIPTION_3 = 'client.auth.CLIENT_AUTH_ONBOARDING_DESCRIPTION_3',
  CLIENT_AUTH_ONBOARDING_UPLOAD_PLAN = 'client.auth.CLIENT_AUTH_ONBOARDING_UPLOAD_PLAN',

  CLIENT_AUTH_ONBOARDING_GO_BUTTON = 'client.auth.CLIENT_AUTH_ONBOARDING_GO_BUTTON',
  CLIENT_AUTH_EMAIL_PASSWORD_BUTTON = 'client.auth.CLIENT_AUTH_EMAIL_PASSWORD_BUTTON',
  PRODUCT_PAGE = 'client.PRODUCT_PAGE',
  PRODUCT_PAGE_SEARCH = 'client.PRODUCT_PAGE_SEARCH',
  TOUR_PLAYER_LOADING_TEXT = 'client.tour.TOUR_PLAYER_LOADING_TEXT',
  AUTH_LOGIN_SUBMIT_BUTTON = 'global.auth.AUTH_LOGIN_SUBMIT_BUTTON',
  AUTH_LOGIN_FORGOT_PASSWORD_BUTTON = 'global.auth.AUTH_LOGIN_FORGOT_PASSWORD_BUTTON',
  AUTH_LOGIN_REMEMBER_ME = 'global.auth.AUTH_LOGIN_REMEMBER_ME',
  AUTH_LOGIN_EMAIL_FIELD = 'global.auth.AUTH_LOGIN_EMAIL_FIELD',
  AUTH_LOGIN_EMAIL_FIELD_PLACEHOLDER = 'global.auth.AUTH_LOGIN_EMAIL_FIELD_PLACEHOLDER',
  AUTH_LOGIN_PASSWORD_FIELD = 'global.auth.AUTH_LOGIN_PASSWORD_FIELD',
  AUTH_LOGIN_PASSWORD_FIELD_PLACEHOLDER = 'global.auth.AUTH_LOGIN_PASSWORD_FIELD_PLACEHOLDER',
  AUTH_LOGIN_CAPTION = 'global.auth.AUTH_LOGIN_CAPTION',
  AUTH_LOGIN_TITLE = 'global.auth.AUTH_LOGIN_TITLE',
  AUTH_REGISTRATION_VERIFY_EMAIL_FIELD = 'global.auth.AUTH_REGISTRATION_VERIFY_EMAIL_FIELD',
  AUTH_REGISTRATION_VERIFY_PASSWORD_FIELD = 'global.auth.AUTH_REGISTRATION_VERIFY_PASSWORD_FIELD',
  AUTH_FORGOT_PASSWORD_PASSWORD_FIELD = 'global.auth.AUTH_FORGOT_PASSWORD_PASSWORD_FIELD',
  AUTH_FORGOT_PASSWORD_VERIFY_FIELD = 'global.auth.AUTH_FORGOT_PASSWORD_VERIFY_FIELD',
  AUTH_REGISTRATION_READ_TERMS_AND_POLICY = 'global.auth.AUTH_REGISTRATION_READ_TERMS_AND_POLICY',
  AUTH_FORGOT_PASSWORD_TITLE = 'global.auth.AUTH_FORGOT_PASSWORD_TITLE',
  AUTH_FORGOT_PASSWORD_INFO = 'global.auth.AUTH_FORGOT_PASSWORD_INFO',
  AUTH_FORGOT_PASSWORD_BUTTON_LABEL = 'global.auth.AUTH_FORGOT_PASSWORD_BUTTON_LABEL',
  AUTH_FORGOT_PASSWORD_BACK_LINK = 'global.auth.AUTH_FORGOT_PASSWORD_BACK_LINK',
  AUTH_RESET_LINK_SENT_TITLE = 'global.auth.AUTH_RESET_LINK_SENT_TITLE',
  AUTH_RESET_LINK_SENT_INFO = 'global.auth.AUTH_RESET_LINK_SENT_INFO',
  AUTH_RESET_PASSWORD_TITLE = 'global.auth.AUTH_RESET_PASSWORD_TITLE',
  AUTH_RESET_PASSWORD_BUTTON_LABEL = 'global.auth.AUTH_RESET_PASSWORD_BUTTON_LABEL',
  AUTH_RESET_PASSWORD_SUCCESS_TITLE = 'global.auth.AUTH_RESET_PASSWORD_SUCCESS_TITLE',
  SHOP_BY_DEPARTMENT = 'client.SHOP_BY_DEPARTMENT',
  ORDERS_TITLE = 'client.ORDERS_TITLE',
  ORDERS_INFO_BOX_TITLE = 'client.ORDERS_INFO_BOX_TITLE',
  ORDERS_INFO_BOX_TEXT = 'client.ORDERS_INFO_BOX_TEXT',
  ORDER_TITLE_NUMBER = 'client.ORDER_TITLE_NUMBER',
  ORDER_DATE = 'client.ORDER_DATE',
  ORDER_DATE_SHIPPING = 'client.ORDER_DATE_SHIPPING',
  ORDER_INFO_BOX_TITLE = 'client.orders.ORDER_INFO_BOX_TITLE',
  ORDER_INFO_BOX_TEXT = 'client.orders.ORDER_INFO_BOX_TEXT',
  ORDER_SHIPPING_TITLE = 'client.orders.ORDER_SHIPPING_TITLE',
  ORDER_PRODUCT_COLOR_LABEL = 'client.order.ORDER_PRODUCT_COLOR_LABEL',
  ORDER_SUMMARY = 'client.order.ORDER_SUMMARY',
  DISCOUNT_CODE = 'client.order.DISCOUNT_CODE',
  ORDER_TAXES = 'client.order.ORDER_TAXES',
  ORDER_DOWNLOAD_RECEIPT = 'client.order.ORDER_DOWNLOAD_RECEIPT',
  ORDER_ITEMS = 'client.order.ORDER_ITEMS',
  ORDER_DELIVERY_ON = 'client.order.ORDER_DELIVERY_ON',
  ORDER_DELIVERED_ON = 'client.order.ORDER_DELIVERED_ON',
  ORDER_STATUS_WAITING_SHIPPING = 'client.order.ORDER_STATUS_WAITING_SHIPPING',
  ORDER_STATUS_SHIPPING = 'client.order.ORDER_STATUS_SHIPPING',
  ORDER_STATUS_ARRIVED = 'client.order.ORDER_STATUS_ARRIVED',
  ORDER_STATUS_PENDING = 'client.order.ORDER_STATUS_PENDING',
  ORDER_STATUS_IN_PROGRESS = 'client.order.ORDER_STATUS_IN_PROGRESS',
  ORDER_STATUS_ITS_DELIVERED = 'client.order.ORDER_STATUS_ITS_DELIVERED',
  ORDER_STATUS_NOT_APPROVED = 'client.order.ORDER_STATUS_NOT_APPROVED',
  ORDER_STATUS_IN_DISPUTE = 'client.order.ORDER_STATUS_IN_DISPUTE',
  THINGS_THAT_ARE_ALWAYS_WORTHWHILE_AT_HOME = 'client.THINGS_THAT_ARE_ALWAYS_WORTHWHILE_AT_HOME',
  PATRICIA_BEST_RECOMMENDED = 'client.products.PATRICIA_BEST_RECOMMENDED',
  SKINS_TITLE = 'client.SKINS_TITLE',
  SKINS_HEADER_TITLE = 'client.header.SKINS_HEADER_TITLE',
  SKINS_HEADER_TITLE_MOBILE = 'client.header.SKINS_HEADER_TITLE_MOBILE',
  SINGLE_SKINS_HEADER_TITLE = 'client.header.SINGLE_SKINS_HEADER_TITLE',
  SINGLE_SKINS_HEADER_SUB_TEXT_FOR_WEB = 'client.header.SINGLE_SKINS_HEADER_SUB_TEXT_FOR_WEB',
  SINGLE_SKINS_HEADER_SUB_TEXT_FOR_WEB_READ_LESS = 'client.header.SINGLE_SKINS_HEADER_SUB_TEXT_FOR_WEB_READ_LESS',
  SINGLE_SKINS_ABOUT_TITLE = 'client.header.SINGLE_SKINS_ABOUT_TITLE',
  SINGLE_SKINS_ABOUT_SUB_TITLE = 'client.header.SINGLE_SKINS_ABOUT_SUB_TITLE',
  SINGLE_SKINS_SKIN_NAME = 'client.header.SINGLE_SKINS_SKIN_NAME',
  SINGLE_SKINS_ABOUT_PRICE = 'client.header.SINGLE_SKINS_ABOUT_PRICE',
  SINGLE_SKINS_PRODUCTS_SKIN = 'client.header.SINGLE_SKINS_PRODUCTS_SKIN',
  SINGLE_SKINS_FREE = 'client.header.SINGLE_SKINS_FREE',
  SINGLE_SKINS_ABOUT_THE_DESIGNER = 'client.header.SINGLE_SKINS_ABOUT_THE_DESIGNER',
  SINGLE_SKINS_PRODUCTS_IN_THIS_SKIN = 'client.header.SINGLE_SKINS_PRODUCTS_IN_THIS_SKIN',
  SINGLE_SKINS_READ_MORE = 'client.header.SINGLE_SKINS_READ_MORE',
  SINGLE_SKINS_READ_LESS = 'client.header.SINGLE_SKINS_READ_LESS',
  SKINS_HEADER_TEXT_BOLD = 'client.header.SKINS_HEADER_TEXT_BOLD',
  SKINS_HEADER_TEXT = 'client.header.SKINS_HEADER_TEXT',
  SKINS_HEADER_TEXT_FOR_WEB = 'client.header.SKINS_HEADER_TEXT_FOR_WEB',
  SKINS_HEADER_TEXT_FOR_MOBILE = 'client.header.SKINS_HEADER_TEXT_FOR_MOBILE',
  SKINS_HEADER_SUB_TEXT_FOR_WEB = 'client.header.SKINS_HEADER_SUB_TEXT_FOR_WEB',
  SKINS_SHOW_MORE_BUTTON = 'client.mobile.SKINS_SHOW_MORE_BUTTON',
  SKIN_CARD_PRICE = 'client.card.SKIN_CARD_PRICE',
  SKIN_CARD_PRICE_FREE = 'client.card.SKIN_CARD_PRICE_FREE',
  SKIN_DESIGNED_BY_TEXT = 'client.card.SKIN_DESIGNED_BY_TEXT',
  SKIN_NOT_FOUND = 'client.SKIN_NOT_FOUND',
  SKIN_FULL_HOUSE = 'client.SKIN_FULL_HOUSE',
  SKIN_LOCK_TEXT = 'client.SKIN_LOCK_TEXT',
  SKIN_BUY_IT_NOW = 'client.SKIN_BUY_IT_NOW',
  SKINS_YOU_MAY_ALSO_LIKE = 'client.SKINS_YOU_MAY_ALSO_LIKE',
  SKIN_NO_DESCRIPTION_AVAILABLE = 'client.skin.SKIN_NO_DESCRIPTION_AVAILABLE',
  SKIN_GOOD_TO_KNOW_TITLE = 'client.SKIN_GOOD_TO_KNOW_TITLE',
  SKIN_APPLY_SKIN = 'client.skin.SKIN_APPLY_SKIN',
  SKIN_BUY_NOW = 'client.skin.SKIN_BUY_NOW',
  SKIN_FILTER_BY_BUDGET = 'client.skin.SKIN_FILTER_BY_BUDGET',
  SKIN_FILTER_BY_DESIGNED_BY = 'client.skin.SKIN_FILTER_BY_DESIGNED_BY',
  SKIN_FILTER_BY_DESIGN_STYLE = 'client.skin.SKIN_FILTER_BY_DESIGN_STYLE',
  SKIN_FILTER_BY_POPULAR = 'client.skin.SKIN_FILTER_BY_POPULAR',
  SKIN_FILTER_BY_ROOM = 'client.skin.SKIN_FILTER_BY_ROOM',
  SKIN_FILTER_BY_SORT = 'client.skin.SKIN_FILTER_BY_SORT',
  SKIN_FILTER_BY_COLOR = 'client.skin.SKIN_FILTER_BY_COLOR',
  SKIN_FILTER_MODAL_TITLE = 'client.skin.SKIN_FILTER_MODAL_TITLE',
  SKIN_FILTER_MODAL_ALL = 'client.skin.SKIN_FILTER_MODAL_ALL',
  SKIN_FILTER_MODAL_CLEAR = 'client.skin.SKIN_FILTER_MODAL_CLEAR',
  SKIN_FILTER_MODAL_SHOW_FILTER = 'client.skin.SKIN_FILTER_MODAL_SHOW_FILTER',
  SKIN_MORE_FROM = 'client.skin.SKIN_MORE_FROM',
  SUPPLIER_HEADER_VIDEO = 'client.supplier.SUPPLIER_HEADER_VIDEO',
  SUPPLIER_HEADER_BUTTON = 'client.supplier.SUPPLIER_HEADER_BUTTON',
  SUPPLIER_HEADER_MAIN_TITLE = 'client.supplier.SUPPLIER_HEADER_MAIN_TITLE',
  SUPPLIER_HEADER_TITLE = 'client.supplier.SUPPLIER_HEADER_TITLE',
  SUPPLIER_INTEGRATION_WEEK = 'client.supplier.SUPPLIER_INTEGRATION_WEEK',
  SUPPLIER_HEADER_TITLE_CAPTION = 'client.supplier.SUPPLIER_HEADER_TITLE_CAPTION',
  SUPPLIER_HEADER_TITLE_CAPTION_BOLD = 'client.supplier.SUPPLIER_HEADER_TITLE_CAPTION_BOLD',
  GET_STARTED_SUPPLIER = 'client.supplier.GET_STARTED_SUPPLIER',
  SUPPLIER_ACTION_BUTTON = 'client.supplier.SUPPLIER_ACTION_BUTTON',
  SUPPLIER_OFFERS_MAIN_TITLE = 'client.supplier.SUPPLIER_OFFERS_MAIN_TITLE',

  TOUR_INSTRUCTIONS_MODAL_FIRST_PAGE_TITLE = 'client.tour.TOUR_INSTRUCTIONS_MODAL_FIRST_PAGE_TITLE',
  TOUR_INSTRUCTIONS_MODAL_SECOND_PAGE_TITLE = 'client.tour.TOUR_INSTRUCTIONS_MODAL_SECOND_PAGE_TITLE',
  TOUR_INSTRUCTIONS_MODAL_THIRD_PAGE_TITLE = 'client.tour.TOUR_INSTRUCTIONS_MODAL_THIRD_PAGE_TITLE',
  TOUR_INSTRUCTIONS_MODAL_FOURTH_PAGE_TITLE = 'client.tour.TOUR_INSTRUCTIONS_MODAL_FOURTH_PAGE_TITLE',
  TOUR_INSTRUCTIONS_MODAL_FIFTH_PAGE_TITLE = 'client.tour.TOUR_INSTRUCTIONS_MODAL_FIFTH_PAGE_TITLE',
  TOUR_INSTRUCTIONS_MODAL_SIXTH_PAGE_TITLE = 'client.tour.TOUR_INSTRUCTIONS_MODAL_SIXTH_PAGE_TITLE',
  TOUR_INSTRUCTIONS_MODAL_FIRST_PAGE_BULLET_ONE = 'client.tour.TOUR_INSTRUCTIONS_MODAL_FIRST_PAGE_BULLET_ONE',
  TOUR_INSTRUCTIONS_MODAL_FIRST_PAGE_BULLET_TWO = 'client.tour.TOUR_INSTRUCTIONS_MODAL_FIRST_PAGE_BULLET_TWO',
  TOUR_INSTRUCTIONS_MODAL_SECOND_PAGE_BULLET_ONE = 'client.tour.TOUR_INSTRUCTIONS_MODAL_SECOND_PAGE_BULLET_ONE',
  TOUR_INSTRUCTIONS_MODAL_SECOND_PAGE_BULLET_TWO = 'client.tour.TOUR_INSTRUCTIONS_MODAL_SECOND_PAGE_BULLET_TWO',
  TOUR_INSTRUCTIONS_MODAL_SECOND_PAGE_BULLET_THREE = 'client.tour.TOUR_INSTRUCTIONS_MODAL_SECOND_PAGE_BULLET_THREE',
  TOUR_INSTRUCTIONS_MODAL_THIRD_PAGE_BULLET_ONE = 'client.tour.TOUR_INSTRUCTIONS_MODAL_THIRD_PAGE_BULLET_ONE',
  TOUR_INSTRUCTIONS_MODAL_THIRD_PAGE_BULLET_TWO = 'client.tour.TOUR_INSTRUCTIONS_MODAL_THIRD_PAGE_BULLET_TWO',
  TOUR_INSTRUCTIONS_MODAL_THIRD_PAGE_BULLET_THREE = 'client.tour.TOUR_INSTRUCTIONS_MODAL_THIRD_PAGE_BULLET_THIRD',
  TOUR_INSTRUCTIONS_MODAL_THIRD_PAGE_BULLET_FOUR = 'client.tour.TOUR_INSTRUCTIONS_MODAL_THIRD_PAGE_BULLET_FOURTH',
  TOUR_INSTRUCTIONS_MODAL_FOURTH_PAGE_BULLET_ONE = 'client.tour.TOUR_INSTRUCTIONS_MODAL_FOURTH_PAGE_BULLET_ONE',
  TOUR_INSTRUCTIONS_MODAL_FOURTH_PAGE_BULLET_TWO = 'client.tour.TOUR_INSTRUCTIONS_MODAL_FOURTH_PAGE_BULLET_TWO',
  TOUR_INSTRUCTIONS_MODAL_FIFTH_PAGE_BULLET_ONE = 'client.tour.TOUR_INSTRUCTIONS_MODAL_FIFTH_PAGE_BULLET_ONE',
  TOUR_INSTRUCTIONS_MODAL_SIXTH_PAGE_BULLET_ONE = 'client.tour.TOUR_INSTRUCTIONS_MODAL_SIXTH_PAGE_BULLET_ONE',
  TOUR_INSTRUCTIONS_MODAL_SIXTH_PAGE_BULLET_TWO = 'client.tour.TOUR_INSTRUCTIONS_MODAL_SIXTH_PAGE_BULLET_TWO',
  TOUR_INSTRUCTIONS_MODAL_DO_NOT_SHOW_AGAIN_CHECKBOX = 'client.tour.TOUR_INSTRUCTIONS_MODAL_DO_NOT_SHOW_AGAIN_CHECKBOX',
  TOUR_INSTRUCTIONS_MODAL_DONE_BUTTON = 'client.tour.TOUR_INSTRUCTIONS_MODAL_DONE_BUTTON',
  TOUR_INSTRUCTIONS_MODAL_CLOSE_BUTTON_TEXT = 'client.tour.TOUR_INSTRUCTIONS_MODAL_CLOSE_BUTTON_TEXT',
  TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_WALK = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_WALK',
  TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_DESIGN = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_DESIGN',
  TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL',
  TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_WALK1 = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_WALK1',
  TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_WALK2 = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_WALK2',
  TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_WALK3 = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_WALK3',
  TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_WALK4 = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_WALK4',
  TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_WALK5 = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_WALK5',
  TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_DESIGN1 = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_DESIGN1',
  TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_DESIGN2 = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_DESIGN2',
  TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_DESIGN3 = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_DESIGN3',
  TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_DESIGN4 = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_DESIGN4',
  TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_DESIGN5 = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_DESIGN5',
  TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_DESIGN6 = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_DESIGN6',
  TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_DESIGN1 = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_DESIGN1',
  TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_DESIGN2 = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_DESIGN2',
  TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_DESIGN3 = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_DESIGN3',
  TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_DESIGN4 = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_DESIGN4',
  TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_DESIGN5 = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_DESIGN5',
  TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_DESIGN6 = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_DESIGN6',
  TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL1 = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL1',
  TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL2 = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL2',
  TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL3 = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL3',
  TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL4 = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL4',
  TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL5 = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL5',
  TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL6 = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL6',
  TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL7 = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL7',
  TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL8 = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL8',
  TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL9 = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL9',
  TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL10 = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL10',
  TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL11 = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL11',
  TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL12 = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL12',
  TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL13 = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL13',
  TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL14 = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_TITLE_GENERAL14',
  TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL1 = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL1',
  TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL2 = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL2',
  TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL3 = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL3',
  TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL4 = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL4',
  TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL5 = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL5',
  TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL6 = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL6',
  TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL7 = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL7',
  TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL8 = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL8',
  TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL9 = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL9',
  TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL10 = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL10',
  TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL11 = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL11',
  TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL12 = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL12',
  TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL13 = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL13',
  TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL14 = 'client.tour.TOUR_INSTRUCTIONS_MODAL_TAB_CONTENT_GENERAL14',
  SHOP_BEST_SELLERS = 'client.products.SHOP_BEST_SELLERS',
  FILE_DROP_ZONE_TITLE = 'client.components.FILE_DROP_ZONE_TITLE',
  FILE_DROP_ZONE_DESCRIPTION = 'client.components.FILE_DROP_ZONE_DESCRIPTION',
  UPLOAD_PLAN_CONTAINER_TITLE = 'client.onboarding.UPLOAD_PLAN_CONTAINER_TITLE',
  CHOOSE_PLAN_CONTAINER_TITLE = 'client.onboarding.CHOOSE_PLAN_CONTAINER_TITLE',
  CHOOSE_PLAN_CONTAINER_TITLE_1 = 'client.onboarding.CHOOSE_PLAN_CONTAINER_TITLE_1',
  CHOOSE_PLAN_CONTAINER_TITLE_2 = 'client.onboarding.CHOOSE_PLAN_CONTAINER_TITLE_2',
  CHOOSE_PLAN_CONTAINER_TITLE_3 = 'client.onboarding.CHOOSE_PLAN_CONTAINER_TITLE_3',
  CHOOSE_PLAN_CONTAINER_TITLE_4 = 'client.onboarding.CHOOSE_PLAN_CONTAINER_TITLE_4',
  MOBILE_UPLOAD_PLAN_CONTAINER_TITLE = 'client.onboarding.MOBILE_UPLOAD_PLAN_CONTAINER_TITLE',
  MOBILE_UPLOAD_PLAN_CONTAINER_BOLD_TITLE = 'client.onboarding.MOBILE_UPLOAD_PLAN_CONTAINER_BOLD_TITLE',
  MOBILE_UPLOAD_PLAN_CONTAINER_FLOOR_PLAN_TITLE = 'client.onboarding.MOBILE_UPLOAD_PLAN_CONTAINER_FLOOR_PLAN_TITLE',
  MOBILE_UPLOAD_PLAN_CONTAINER_BOLD_TITLE_HEBREW = 'client.onboarding.MOBILE_UPLOAD_PLAN_CONTAINER_BOLD_TITLE_HEBREW',
  MOBILE_UPLOAD_PLAN_CONTAINER_TITLE_HEBREW = 'client.onboarding.MOBILE_UPLOAD_PLAN_CONTAINER_TITLE_HEBREW',
  MOBILE_CHOOSE_PLAN_CONTAINER_TITLE = 'client.onboarding.MOBILE_CHOOSE_PLAN_CONTAINER_TITLE',
  CHOOSE_PLAN_CONTAINER_TEXT = 'client.onboarding.CHOOSE_PLAN_CONTAINER_TEXT',
  CHOOSE_PLAN_TEXT_1 = 'client.onboarding.CHOOSE_PLAN_TEXT_1',
  CHOOSE_PLAN_TEXT_2 = 'client.onboarding.CHOOSE_PLAN_TEXT_2',
  PLAN_BEDROOMS = 'client.onboarding.PLAN_BEDROOMS',
  UPLOAD_PLAN_CONTAINER_TEXT = 'client.onboarding.UPLOAD_PLAN_CONTAINER_TEXT',
  UPLOAD_PLAN_ALREADY_USER = 'client.onboarding.UPLOAD_PLAN_ALREADY_USER',
  UPLOAD_PLAN_LOGIN_LINK = 'client.onboarding.UPLOAD_PLAN_LOGIN_LINK',
  SELECT_FLOOR_CAPTION = 'client.onboarding.SELECT_FLOOR_CAPTION',
  UPLOAD_FILE_SUCCESS = 'client.onboarding.UPLOAD_FILE_SUCCESS',
  UPLOAD_LETS_START_TITLE = 'client.onboarding.UPLOAD_LETS_START_TITLE',
  UPLOAD_LETS_START_BUTTON = 'client.onboarding.UPLOAD_LETS_START_BUTTON',
  UPLOAD_HELP_PATRICIA_TITLE = 'client.onboarding.UPLOAD_HELP_PATRICIA_TITLE',
  UPLOAD_CHOOSE_YOUR_STYLE_TITLE = 'client.onboarding.UPLOAD_CHOOSE_YOUR_STYLE_TITLE',
  UPLOAD_BUDGET_QUESTION = 'client.onboarding.UPLOAD_BUDGET_QUESTION',
  UPLOAD_BUDGET_BUTTON = 'client.onboarding.UPLOAD_BUDGET_BUTTON',
  UPLOAD_ROOMS_QUESTION = 'client.onboarding.UPLOAD_ROOMS_QUESTION',
  UPLOAD_ROOM_QUESTION = 'client.onboarding.UPLOAD_ROOM_QUESTION',
  DESIGN_STYLES_QUESTION = 'client.onboarding.DESIGN_STYLES_QUESTION',
  UPLOAD_COLOR_QUESTION = 'client.onboarding.UPLOAD_COLOR_QUESTION',
  UPLOAD_THANKS_FOR_QUIZ_TITLE = 'client.onboarding.UPLOAD_THANKS_FOR_QUIZ_TITLE',
  UPLOAD_WERE_DONE_TITLE = 'client.onboarding.UPLOAD_WERE_DONE_TITLE',
  UPLOAD_WERE_DONE_TEXT = 'client.onboarding.UPLOAD_WERE_DONE_TEXT',
  EMAIL_GO_TO_PROMOTIONS = 'client.onboarding.EMAIL_GO_TO_PROMOTIONS',
  UPLOAD_TAKE_24_TEXT = 'client.onboarding.UPLOAD_TAKE_24_TEXT',
  DONE_GOTO_EXAMPLE_HOME_TEXT = 'client.onboarding.DONE_GOTO_EXAMPLE_HOME_TEXT',
  UPLOAD_BUTTON_BACK_TO_HOME = 'client.onboarding.UPLOAD_BUTTON_BACK_TO_HOME',
  BROWSE_PRODUCT_CATALOG = 'client.onboarding.BROWSE_PRODUCT_CATALOG',
  START_DEMO_TOUR = 'client.onboarding.START_DEMO_TOUR',
  ONBOARDING_REGISTRATION_TITLE = 'client.onboarding.ONBOARDING_REGISTRATION_TITLE',
  ONBOARDING_REGISTRATION_SUBTITLE = 'client.onboarding.ONBOARDING_REGISTRATION_SUBTITLE',
  ADD_TO_CART = 'client.product.ADD_TO_CART',
  DISCOUNT_PERCENT = 'client.product.DISCOUNT_PERCENT',
  PRODUCT_MORE_FROM = 'client.product.PRODUCT_MORE_FROM',
  HOME_SECTION_HEADER_1 = 'client.homepage.HOME_SECTION_HEADER_1',
  HOME_SECTION_HEADER_2 = 'client.homepage.HOME_SECTION_HEADER_2',
  HOME_SECTION_HEADER_3 = 'client.homepage.HOME_SECTION_HEADER_3',
  HOME_SECTION_TEXT_2 = 'client.homepage.HOME_SECTION_TEXT_2',
  HOME_SECTION_TEXT_3 = 'client.homepage.HOME_SECTION_TEXT_3',
  NO_PRODUCT_AVAILABLE = 'client.products.NO_PRODUCT_AVAILABLE',
  VIEW_DETAILS = 'client.products.VIEW_DETAILS',
  CLEAR_CART = 'client.products.CLEAR_CART',
  NO_CATEGORY = 'client.products.NO_CATEGORY',
  NO_SUB_CATEGORY = 'client.products.NO_SUB_CATEGORY',
  FREE_SHIPPING = 'client.products.FREE_SHIPPING',
  GET_IT_BY = 'client.products.GET_IT_BY',
  SHIP_TO = 'client.products.SHIP_TO',
  GOOD_TO_KNOW = 'client.products.GOOD_TO_KNOW',
  DIMENSIONS = 'client.products.DIMENSIONS',
  DESCRIPTION = 'client.products.DESCRIPTION',
  HEIGHT_CM = 'client.products.HEIGHT_CM',
  LENGTH_CM = 'client.products.LENGTH_CM',
  DEPTH_CM = 'client.products.DEPTH_CM',
  HEIGHT_INCH = 'client.products.HEIGHT_INCH',
  LENGTH_INCH = 'client.products.LENGTH_INCH',
  DEPTH_INCH = 'client.products.DEPTH_INCH',
  CATEGORIES = 'client.products.CATEGORIES',
  SUB_CATEGORIES = 'client.products.SUB_CATEGORIES',
  PRODUCTS = 'client.products.PRODUCTS',
  BRANDS = 'client.products.BRANDS',
  NO_RESULT = 'client.search.NO_RESULT',
  THREED_BUTTON_TEXT = 'client.search.THREED_BUTTON_TEXT',
  TOUR_DEVICE_DENIED_HEADING = 'client.tour.TOUR_DEVICE_DENIED_HEADING',
  TOUR_SUPPORTED_DEVICE_TITLE = 'client.tour.TOUR_SUPPORTED_DEVICE_TITLE',
  TOUR_SUPPORTED_DEVICES = 'client.tour.TOUR_SUPPORTED_DEVICES',
  TOUR_SUPPORTED_BROWSER = 'client.tour.TOUR_SUPPORTED_BROWSER',
  TOUR_WE_ARE_SORRY = 'client.tour.TOUR_WE_ARE_SORRY',
  TOUR_ADD_ENTIRE_ROOM_TO_CART = 'client.tour.TOUR_ADD_ENTIRE_ROOM_TO_CART',
  DELETE_PROPERTY_TEXT = 'client.tour.DELETE_PROPERTY_TEXT',
  GO_TO_MY_PROPERTIES = 'client.tour.GO_TO_MY_PROPERTIES',
  PROPERTY_NOTIFICATION_MODAL_TITLE = 'client.tour.PROPERTY_NOTIFICATION_MODAL_TITLE',
  WEBSITE_ACCESSIBILITY_TITLE = 'client.accessibility.WEBSITE_ACCESSIBILITY_TITLE',
  WEBSITE_ACCESSIBILITY_INTRO_TITLE = 'client.accessibility.WEBSITE_ACCESSIBILITY_INTRO_TITLE',
  WEBSITE_ACCESSIBILITY_INTRO_SECTION = 'client.accessibility.WEBSITE_ACCESSIBILITY_INTRO_SECTION',
  WEBSITE_ACCESSIBILITY_SITE_ACCESSABILITY_TITLE = 'client.accessibility.WEBSITE_ACCESSIBILITY_SITE_ACCESSABILITY_TITLE',
  WEBSITE_ACCESSIBILITY_SITE_ACCESSABILITY_SECTION = 'client.accessibility.WEBSITE_ACCESSIBILITY_SITE_ACCESSABILITY_SECTION',
  WEBSITE_ACCESSIBILITY_MENU_TITLE = 'client.accessibility.WEBSITE_ACCESSIBILITY_MENU_TITLE',
  WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_1 = 'client.accessibility.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_1',
  WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_2 = 'client.accessibility.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_2',
  WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_3 = 'client.accessibility.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_3',
  WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_4 = 'client.accessibility.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_4',
  WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_5 = 'client.accessibility.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_5',
  WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_6 = 'client.accessibility.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_6',
  WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_7 = 'client.accessibility.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_7',
  WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_8 = 'client.accessibility.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_8',
  WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_9 = 'client.accessibility.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_9',
  WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_10 = 'client.accessibility.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_10',
  WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_11 = 'client.accessibility.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_11',
  WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_12 = 'client.accessibility.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_12',
  WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_13 = 'client.accessibility.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_13',
  WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_14 = 'client.accessibility.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_14',
  WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_15 = 'client.accessibility.WEBSITE_ACCESSIBILITY_MENU_LIST_ITEM_15',
  WEBSITE_ACCESSIBILITY_COORDINATOR_TITLE = 'client.accessibility.WEBSITE_ACCESSIBILITY_COORDINATOR_TITLE',
  WEBSITE_ACCESSIBILITY_COORDINATOR_DESC = 'client.accessibility.WEBSITE_ACCESSIBILITY_COORDINATOR_DESC',
  WEBSITE_ACCESSIBILITY_COORDINATOR_FIELD_NAME = 'client.accessibility.WEBSITE_ACCESSIBILITY_COORDINATOR_FIELD_NAME',
  WEBSITE_ACCESSIBILITY_COORDINATOR_FIELD_TELEPHONE = 'client.accessibility.WEBSITE_ACCESSIBILITY_COORDINATOR_FIELD_TELEPHONE',
  WEBSITE_ACCESSIBILITY_COORDINATOR_FIELD_EMAIL = 'client.accessibility.WEBSITE_ACCESSIBILITY_COORDINATOR_FIELD_EMAIL',
  WEBSITE_ACCESSIBILITY_COORDINATOR_FIELD_MAIL_ADDRESS = 'client.accessibility.WEBSITE_ACCESSIBILITY_COORDINATOR_FIELD_MAIL_ADDRESS',
  WEBSITE_ACCESSIBILITY_ARRANGEMENTS_DEPANDANCY_TITLE = 'client.accessibility.WEBSITE_ACCESSIBILITY_ARRANGEMENTS_DEPANDANCY_TITLE',
  IDEA_PRICE_TAG_LABEL = 'client.tour.IDEA_PRICE_TAG_LABEL',
  IDEA_TAB_TITLE_TAB1 = 'client.tour.IDEA_TAB_TITLE_TAB1',
  IDEA_TAB_TITLE_TAB2 = 'client.tour.IDEA_TAB_TITLE_TAB2',
  IDEA_TAB_TOUR_BUTTON = 'client.tour.IDEA_TAB_TOUR_BUTTON',
  IDEA_IMAGE_TITLE = 'client.tour.IDEA_IMAGE_TITLE',
  IDEA_DESIGNED_BY_LABEL = 'client.tour.IDEA_DESIGNED_BY_LABEL',
  IDEA_START_TOUR_BUTTON = 'client.tour.IDEA_START_TOUR_BUTTON',
  IDEA_BRAND_IMAGE_TITLE = 'client.tour.IDEA_BRAND_IMAGE_TITLE',
  IDEAS_TITLE = 'client.tour.IDEAS_TITLE',
  IDEAS_NOT_FOUND = 'client.IDEAS_NOT_FOUND',
  IDEAS_PRODUCT_TITLE = 'IDEAS_PRODUCT_TITLE',

  SKINS_SORT_NEW = 'client.skins.SKINS_SORT_NEW',
  SKINS_SORT_POPULAR = 'client.skins.SKINS_SORT_POPULAR',
  SKINS_SORT_PRICE_LOW = 'client.skins.SKINS_SORT_PRICE_LOW',
  SKINS_SORT_PRICE_HIGH = 'client.skins.SKINS_SORT_PRICE_HIGH',

  SKINS_FILTER_COLOR_RED = 'client.skins.SKINS_FILTER_COLOR_RED',
  SKINS_FILTER_COLOR_BLACK = 'client.skins.SKINS_FILTER_COLOR_BLACK',
  SKINS_FILTER_COLOR_BLUE = 'client.skins.SKINS_FILTER_COLOR_BLUE',
  SKINS_FILTER_COLOR_GREY = 'client.skins.SKINS_FILTER_COLOR_GREY',
  SKINS_FILTER_COLOR_YELLOW = 'client.skins.SKINS_FILTER_COLOR_YELLOW',
  SKINS_FILTER_COLOR_ORANGE = 'client.skins.SKINS_FILTER_COLOR_ORANGE',
  SKINS_FILTER_COLOR_PURPLE = 'client.skins.SKINS_FILTER_COLOR_PURPLE',
  SKINS_FILTER_COLOR_PINK = 'client.skins.SKINS_FILTER_COLOR_PINK',
  SKINS_FILTER_COLOR_BROWN = 'client.skins.SKINS_FILTER_COLOR_BROWN',
  SKINS_FILTER_COLOR_GREEN = 'client.skins.SKINS_FILTER_COLOR_GREEN',
  BUY_PATRICIA_COINS_TITLE = 'client.patriciaCoins.BUY_PATRICIA_COINS_TITLE',
  BUY_PATRICIA_COINS_BUTTON_TITLE = 'client.patriciaCoins.BUY_PATRICIA_COINS_BUTTON_TITLE',

  ORDER_PDF_ORDER_SUMMARY_FOR = 'client.orderDetails.ORDER_SUMMARY_FOR',
  TAKE_A_LOOK = 'client.ideas.TAKE_A_LOOK',
  ONBOARDING_HOME_DESCRIPTION = 'client.onboarding.ONBOARDING_HOME_DESCRIPTION',
  ONBOARDING_HOME_DESCRIPTION_MOBILE = 'client.onboarding.ONBOARDING_HOME_DESCRIPTION_MOBILE',
  ONBOARDING_UPLOAD_YPUR_PLAN = 'client.onboarding.ONBOARDING_UPLOAD_YOUR_PLAN',
  ONBOARDING_UPLOAD_NOTICE = 'client.onboarding.ONBOARIDNG_UPLOAD_NOTICE',
  ONBOARDING_FLOOR_PLAN = 'client.onboarding.ONBOARIDNG_FLOOR_PLAN',
  ONBOARDING__SELECTED_PREMADE_MESSAGE = 'client.onboarding.ONBOARDING__SELECTED_PREMADE_MESSAGE',
  ONBOARDING_PLAN_UPLOAD_SUCCESS = 'client.onboarding.ONBOARDING_PLAN_UPLOAD_SUCCESS',
  ONBOARDING_AMOUNT_OF_MONEY_TO_SPEND = 'client.onboarding.ONBOARDING_AMOUNT_OF_MONEY_TO_SPEND',
  CHOOSE_IMAGE_SUBTEXT = 'client.onboarding.CHOOSE_IMAGE_SUBTEXT',
  SELECT_ONE_COLOR = 'client.onboarding.SELECT_ONE_COLOR',
  YOUR_MOODBOARD = 'client.onboarding.YOUR_MOODBOARD',
  KEY_CONFIRMATION_STARTED_WORKING = 'client.onboarding.KEY_CONFIRMATION_STARTED_WORKING',
  CREATE_DIGITAL_KEY = 'client.onboarding.CREATE_DIGITAL_KEY',
  LETS_MAKE_KEY_BUTTON = 'client.onboarding.LETS_MAKE_KEY_BUTTON',
  ONBOARDING_LEAVE_CINFIRM_TITLE = 'client.onboarding.ONBOARDING_LEAVE_CINFIRM_TITLE',
  ONBOARDING_LEAVE_CONFIRM_SUBTITLE1 = 'client.onboarding.ONBOARDING_LEAVE_CONFIRM_SUBTITLE1',
  ONBOARDING_LEAVE_CONFIRM_SUBTITLE2 = 'client.onboarding.ONBOARDING_LEAVE_CONFIRM_SUBTITLE2',
  OONBOARDING_LEAVE_CONFIRMATION_STAY_BUTTON = 'client.onboarding.OONBOARDING_LEAVE_CONFIRMATION_STAY_BUTTON',
  OONBOARDING_LEAVE_CONFIRMATION_LEAVE_BUTTON = 'client.onboarding.OONBOARDING_LEAVE_CONFIRMATION_LEAVE_BUTTON',
  ONBOARDING_TOOLTIP_STEP_1 = 'client.onboarding.ONBOARDING_TOOLTIP_STEP_1',
  ONBOARDING_TOOLTIP_DESIGN_STYLE_STEP_6 = 'client.onboarding.ONBOARDING_TOOLTIP_DESIGN_STYLE_STEP_6',
  ONBOARDING_TOOLTIP_FLOOR_PLAN_SELECT = 'client.onboarding.ONBOARDING_TOOLTIP_FLOOR_PLAN_SELECT',
  ONBOARDING_TOOLTIP_DESIGN_STYLE_SELECT = 'client.onboarding.ONBOARDING_TOOLTIP_DESIGN_STYLE_SELECT',
  ONBOARDING_TOOLTIP_FLOOR_ROOM_SELECT = 'client.onboarding.ONBOARDING_TOOLTIP_FLOOR_ROOM_SELECT',
  ONBOARDING_TOOLTIP_FLOOR_ROOM_CONFIRM = 'client.onboarding.ONBOARDING_TOOLTIP_FLOOR_ROOM_CONFIRM',
  ONBOARDING_TOOLTIP_BUDGET_SELECT = 'client.onboarding.ONBOARDING_TOOLTIP_BUDGET_SELECT',
  ONBOARDING_TOOLTIP_BUDGET = 'client.onboarding.ONBOARDING_TOOLTIP_BUDGET',
  ONBOARDING_TOOLTIP_CONFIRMATION = 'client.onboarding.ONBOARDING_TOOLTIP_CONFIRMATION',
  ONBOARDING_TOOLTIP_COLOR_SELECT = 'client.onboarding.ONBOARDING_TOOLTIP_COLOR_SELECT',
  ONBOARDING_TOOLTIP_DESIGN_SELECT = 'client.onboarding.ONBOARDING_TOOLTIP_DESIGN_SELECT',
  ONBOARDING_TOOLTIP_DESIGN_STYLE = 'client.onboarding.ONBOARDING_TOOLTIP_DESIGN_STYLE',
  ONBOARDING_TOOLTIP_MAKE_KEY = 'client.onboarding.ONBOARDING_TOOLTIP_MAKE_KEY',
  FINISH = 'FINISH',
  ONBOARDING_YOUR_MOODBOARD = 'client.onboarding.ONBOARDING_YOUR_MOODBOARD',
  MOODBOARD_DESCRIPTION = 'client.onboarding.MOODBOARD_DESCRIPTION',
  ONBOARDING_DONE_KEY_CREATED_SUCCESSFULL = 'client.onboarding.ONBOARDING_DONE_KEY_CREATED_SUCCESSFULL',
  ONBOARDING_DONE_SUBTITLE1 = 'client.onboarding.ONBOARDING_DONE_SUBTITLE1',
  ONBOARDING_DONE_EMAIL_NOTICE = 'client.onboarding.ONBOARDING_DONE_EMAIL_NOTICE',
  ONBOARDING_DONE_SUBTITLE2 = 'client.onboarding.ONBOARDING_DONE_SUBTITLE2',
  ONBOARDING_DONE_SUBTITLE = 'client.onboarding.ONBOARDING_DONE_SUBTITLE',
  ONBOARDING_DONE_SUBTITLE_MOBILE = 'client.onboarding.ONBOARDING_DONE_SUBTITLE_MOBILE',

  ONBOARDING_DONE_RETURN_HOME_BUTTON = 'client.onboarding.ONBOARDING_DONE_RETURN_HOME_BUTTON',

  ONBOARDING_DONE_KEY_OWNER = 'client.onboarduing.ONBOARDING_DONE_KEY_OWNER',
  ONBOARDING_DONE_PROPERTY_NUMBER = 'client.onboarduing.ONBOARDING_DONE_PROPERTY_NUMBER',
  ONBOARDING_PATRICIA_GIFT = 'client.onboarding.ONBOARDING_PATRICIA_GIFT',
  ONBOARDING_DONE_COIN_NOTICE = 'client.onboarding.ONBOARDING_DONE_COIN_NOTICE',
  SELECT_ALL = 'SELECT_ALL',
  CLIENT_FIRST_NAME = 'client.CLIENT_FIRST_NAME',
  CLIENT_LAST_NAME = 'client.CLIENT_LAST_NAME',
  CLIENT_EMAIL_TITLE = 'client.CLIENT_EMAIL_TITLE',
  CLIENT_PHONE_NUMBER_TITLE = 'client.CLIENT_PHONE_NUMBER_TITLE',
  CLIENT_PASSWORD = 'client.CLIENT_PASSWORD',
  PROPERTIES_SEARCH_BUTTON = 'client.properties.PROPERTIES_SEARCH_BUTTON',
  PROPERTIES_SEARCH_PLACEHOLDER = 'client.properties.PROPERTIES_SEARCH_PLACEHOLDER',
  CLIENT_EMAIL_ERROR = 'client.CLIENT_EMAIL_ERROR',
  CLIENT_PHONE_ERROR = 'client.CLIENT_PHONE_ERROR',
  GO_TO_IDEAS_BUTTON = 'client.GO_TO_IDEAS_BUTTON',
  ONBOARDING_PLAN_UPLOAD_RESOLUTION_TITLE = 'client.onboarding.ONBOARDING_PLAN_UPLOAD_RESOLUTION_TITLE',
  ONBOARDING_PLAN_UPLOAD_RESOLUTION_SUBTITLE = 'client.onboarding.ONBOARDING_PLAN_UPLOAD_RESOLUTION_SUBTITLE',
  ONBOARDING_PLAN_UPLOAD_RESOLUTION_NOTICE_DESKTOP = 'client.onboarding.ONBOARDING_PLAN_UPLOAD_RESOLUTION_NOTICE_DESKTOP',
  ONBOARDING_PLAN_UPLOAD_RESOLUTION_NOTICE_MOBILE = 'client.onboarding.ONBOARDING_PLAN_UPLOAD_RESOLUTION_NOTICE_MOBILE',
  ONBOARDING_PLAN_UPLOAD_RESOLUTION_OK_BUTTON = 'client.onboarding.ONBOARDING_PLAN_UPLOAD_RESOLUTION_OK_BUTTON',
  REGISTRATION_PHONE_NUMBER = 'client.onboarding.REGISTRATION_PHONE_NUMBER',
  IDEAS_CARD_VIEWS = 'client.ideas.IDEAS_CARD_VIEWS',

  ONBOARDING_QUIZ_PANORAMA_QUESTION = 'client.onboarding.ONBOARDING_QUIZ_PANORAMA_QUESTION',

  UPDATE_AVAILABLE_TITLE = 'client.common.UPDATE_AVAILABLE_TITLE',
  UPDATE_AVAILABLE_DESCRIPTION = 'client.common.UPDATE_AVAILABLE_DESCRIPTION',
  UPDATE_BUTTON_TEXT = 'client.common_UPDATE_BUTTON_TEXT',

  LANDING_PAGE_HEADING = 'client.seller_landing.LANDING_PAGE_HEADING',
  LANDING_PAGE_TEXT = 'client.seller_landing.LANDING_PAGE_TEXT',
  LANDING_PAGE_TEXT_MOBILE = 'client.seller_landing.LANDING_PAGE_TEXT_MOBILE',
  LANDING_PAGE_SUB_TEXT = 'client.seller_landing.LANDING_PAGE__SUB_TEXT',
  LANDING_PAGE_SUB_TEXT_MOBILE = 'client.seller_landing.LANDING_PAGE_SUB_TEXT_MOBILE',
  LANDING_PAGE_TEXT_POINT_1 = 'client.seller_landing.LANDING_PAGE_TEXT_POINT_1',
  LANDING_PAGE_TEXT_POINT_2 = 'client.seller_landing.LANDING_PAGE_TEXT_POINT_2',
  LANDING_PAGE_TEXT_POINT_3 = 'client.seller_landing.LANDING_PAGE_TEXT_POINT_3',
  LANDING_PAGE_BUTTON = 'client.seller_landing.LANDING_PAGE_BUTTON',
  LANDING_PAGE_MIDDLE_HEADING = 'client.seller_landing.LANDING_PAGE_MIDDLE_HEADING',
  LANDING_PAGE_UPLOAD_FLOOR_PLAN_BUTTON = 'client.seller_landing.LANDING_PAGE_UPLOAD_FLOOR_PLAN_BUTTON',
  LANDING_PAGE_MEETING_SHOWROOM = 'client.seller_landing.LANDING_PAGE_MEETING_SHOWROOM',
  LANDING_PAGE_HIGH_QUALITY_IMAGES = 'client.seller_landing.LANDING_PAGE_HIGH_QUALITY_IMAGES',
  LANDING_PAGE_UPLOAD_FLOOR_PLAN_TEXT = 'client.seller_landing.LANDING_PAGE_UPLOAD_FLOOR_PLAN_TEXT',
  LANDING_PAGE_MEETING_SHOWROOM_TEXT = 'client.seller_landing.LANDING_PAGE_MEETING_SHOWROOM_TEXT',
  LANDING_PAGE_HIGH_QUALITY_IMAGES_TEXT = 'client.seller_landing.LANDING_PAGE_HIGH_QUALITY_IMAGES_TEXT',

  LANDING_PAGE_FORM_HEADING = 'client.seller_landing.LANDING_PAGE_FORM_HEADING',
  LANDING_PAGE_HEADING_TEXT = 'client.seller_landing.LANDING_PAGE_HEADING_TEXT',
  LANDING_PAGE_FIRST_NAME = 'client.seller_landing.LANDING_PAGE_FIRST_NAME',
  LANDING_PAGE_LAST_NAME = 'client.seller_landing.LANDING_PAGE_LAST_NAME',
  LANDING_PAGE_SCHEDULE_MEETING = 'client.seller_landing.LANDING_PAGE_SCHEDULE_MEETING',
  LANDING_PAGE_FORM_EMAIL = 'client.seller_landing.LANDING_PAGE_FORM_EMAIL',
  LANDING_PAGE_PHONE_NUMBER = 'client.seller_landing.LANDING_PAGE_PHONE_NUMBER',
  LANDING_PAGE_UPLPAD_FLOOR_PLAN_BUTTON = 'client.seller_landing.LANDING_PAGE_UPLPAD_FLOOR_PLAN_BUTTON',
  LANDING_PAGE_CHOOSE_FILE_BUTTON = 'client.seller_landing.LANDING_PAGE_CHOOSE_FILE_BUTTON',
  LANDING_PAGE_CHANGE_FILE_BUTTON = 'client.seller_landing.LANDING_PAGE_CHANGE_FILE_BUTTON',
  LANDING_PAGE_FILE_UPLOAD_TERMS = 'client.seller_landing.LANDING_PAGE_FILE_UPLOAD_TERMS',
  LANDING_PAGE_FILE_UPLOAD_FORMAT = 'client.seller_landing.LANDING_PAGE_FILE_UPLOAD_FORMAT',
  LANDING_PAGE_ACCEPT_TERMS = 'client.seller_landing.LANDING_PAGE_ACCEPT_TERMS',
  LANDING_PAGE_FINISH_BUTTON = 'client.seller_landing.LANDING_PAGE_FINISH_BUTTON',

  LANDING_PAGE_FOOTER_HEADING = 'client.seller_landing.LANDING_PAGE_FOOTER_HEADING',
  LANDING_PAGE_UPLOAD_FLOOR_PLAN = 'client.seller_landing.LANDING_PAGE_UPLOAD_FLOOR_PLAN',
  LANDING_PAGE_FOOTER_HEADING_TEXT = 'client.seller_landing.LANDING_PAGE_FOOTER_HEADING_TEXT',
  LANDING_PAGE_PHONE_TEXT = 'client.seller_landing.LANDING_PAGE_PHONE_TEXT',
  LANDING_PAGE_PHONE_NO = 'client.seller_landing.LANDING_PAGE_PHONE_NO',
  LANDING_PAGE_LOCATION = 'client.seller_landing.LANDING_PAGE_LOCATION',
  LANDING_PAGE_LOCATION_TEXT = 'client.seller_landing.LANDING_PAGE_LOCATION_TEXT',
  LANDING_PAGE_EMAIL = 'client.seller_landing.LANDING_PAGE_EMAIL',
  LANDING_PAGE_EMAIL_TEXT = 'client.seller_landing.LANDING_PAGE_EMAIL_TEXT',

  LANDING_PAGE_CUSTOMER_SERVICE = 'client.seller_landing.LANDING_PAGE_CUSTOMER_SERVICE',
  LANDING_PAGE_CUSTOMER_SERVICE_WEEK = 'client.seller_landing.LANDING_PAGE_CUSTOMER_SERVICE_WEEK',
  LANDING_PAGE_CUSTOMER_SERVICE_WEEK_DAY = 'client.seller_landing.LANDING_PAGE_CUSTOMER_SERVICE_WEEK_DAY',
  LANDING_PAGE_CUSTOMER_SERVICE_WEEKEND = 'client.seller_landing.LANDING_PAGE_CUSTOMER_SERVICE_WEEKEND',

  LANDING_PAGE_CUSTOMER_SERVICE_WEEK_TIME = 'client.seller_landing.LANDING_PAGE_CUSTOMER_SERVICE_WEEK_TIME',
  LANDING_PAGE_CUSTOMER_SERVICE_WEEK_DAY_1_TIME = 'client.seller_landing.LANDING_PAGE_CUSTOMER_SERVICE_WEEK_DAY_1_TIME',
  LANDING_PAGE_CUSTOMER_SERVICE_WEEKEND_STATUS = 'client.seller_landing.LANDING_PAGE_CUSTOMER_SERVICE_WEEKEND_STATUS',
  LANDING_PAGE_CONTACT_US = 'client.seller_landing.LANDING_PAGE_CONTACT_US',
  LANDING_PAGE_FOOTER_TEXT = 'client.seller_landing.LANDING_PAGE_FOOTER_TEXT',
  LANDING_PAGE_THANKYOU_SUCCESS = 'client.seller_landing.LANDING_PAGE_THANKYOU_SUCCESS',
  LANDING_PAGE_THANKYOU_SUCCESS_SUBTITLE = 'client.seller_landing.LANDING_PAGE_THANKYOU_SUCCESS_SUBTITLE',
  LANDING_PAGE_THANKYOU_SUCCESS_APPOINTMENT_TEXT = 'client.seller_landing.LANDING_PAGE_THANKYOU_SUCCESS_APPOINTMENT_TEXT',
  LANDING_PAGE_IMAGE_COPIED_NOTIFICATION = 'client.seller_landing.LANDING_PAGE_IMAGE_COPIED_NOTIFICATION',
  LANDING_PAGE_IMAGE_DELETED_NOTIFICATION = 'client.seller_landing.LANDING_PAGE_IMAGE_DELETED_NOTIFICATION',
  VALIDATION_UPPER_CASE = 'client.VALIDATION_UPPER_CASE',
  VALIDATION_ALPHA_SPACE = 'client.VALIDATION_ALPHA_SPACE',
  VALIDATION_SPACE = 'client.VALIDATION_SPACE',
  SUPPLIER_LOGIN_TITLE = 'client.SUPPLIER_LOGIN_TITLE',
  SUPPLIER_LOGIN_WELCOME_TITLE = 'client.SUPPLIER_LOGIN_WELCOME_TITLE',
  SUPPLIER_LOGIN_THANKS_TITLE = 'client.SUPPLIER_LOGIN_THANKS_TITLE',
  SUPPLIER_LOGIN_CONGRATULATIONS_TITLE = 'client.SUPPLIER_LOGIN_CONGRATULATIONS_TITLE',
  SUPPLIER_LOGIN_CONGRATULATIONS_STORE_OPEN_TITLE = 'client.SUPPLIER_LOGIN_CONGRATULATIONS_STORE_OPEN_TITLE',
  SUPPLIER_LOGIN_CONGRATULATIONS_STORE_OPEN_TITLE_MOBILE = 'client.SUPPLIER_LOGIN_CONGRATULATIONS_STORE_OPEN_TITLE_MOBILE',
  SUPPLIER_LOGIN_CONGRATULATIONS_JOIN_TITLE = 'client.SUPPLIER_LOGIN_CONGRATULATIONS_JOIN_TITLE',
  SUPPLIER_LOGIN_CONGRATULATIONS_PROVIDED_TITLE = 'client.SUPPLIER_LOGIN_CONGRATULATIONS_PROVIDED_TITLE',
  SUPPLIER_LOGIN_CONGRATULATIONS_PROVIDED_TITLE_MOBILE = 'client.SUPPLIER_LOGIN_CONGRATULATIONS_PROVIDED_TITLE_MOBILE',
  SUPPLIER_REGISTER_TITLE = 'client.SUPPLIER_REGISTER_TITLE',
  SUPPLIER_REGISTER_SUBTITLE = 'client.SUPPLIER_REGISTER_SUBTITLE',
  SUPPLIER_LOGIN_SUBTITLE = 'client.SUPPLIER_LOGIN_SUBTITLE',
  SUPPLIER_LOGIN_WELCOME_SUBTITLE = 'client.SUPPLIER_LOGIN_WELCOME_SUBTITLE',
  SUPPLIER_LOGIN_WELCOME_SUBTITLE_MOBILE = 'client.SUPPLIER_LOGIN_WELCOME_SUBTITLE_MOBILE',
  SUPPLIER_LOGIN_THANKS_FIRST_SUBTITLE = 'client.SUPPLIER_LOGIN_THANKS_FIRST_SUBTITLE',
  SUPPLIER_LOGIN_THANKS_FIRST_SUBTITLE_MOBILE = 'client.SUPPLIER_LOGIN_THANKS_FIRST_SUBTITLE_MOBILE',
  SUPPLIER_LOGIN_THANKS_SECOND_SUBTITLE = 'client.SUPPLIER_LOGIN_THANKS_SECOND_SUBTITLE',
  SUPPLIER_LOGIN_CONTINUE_BUTTON = 'client.SUPPLIER_LOGIN_CONTINUE_BUTTON',
  SUPPLIER_LOGIN_FIRST_CONGRATULATIONS_SUBTITLE = 'client.SUPPLIER_LOGIN_FIRST_CONGRATULATIONS_SUBTITLE',
  SUPPLIER_LOGIN_SECOND_CONGRATULATIONS_SUBTITLE = 'client.SUPPLIER_LOGIN_SECOND_CONGRATULATIONS_SUBTITLE',
  SUPPLIER_LOGIN_SECOND_CONGRATULATIONS_SUBTITLE_MOBILE = 'client.SUPPLIER_LOGIN_SECOND_CONGRATULATIONS_SUBTITLE_MOBILE',
  SUPPLIER_LOGIN__CONGRATULATIONS_SUBTITLE_SKIP = 'client.SUPPLIER_LOGIN__CONGRATULATIONS_SUBTITLE_SKIP',
  SUPPLIER_LOGIN__CONGRATULATIONS_SUBTITLE_SKIP_MOBILE = 'client.SUPPLIER_LOGIN__CONGRATULATIONS_SUBTITLE_SKIP_MOBILE',
  SUPPLIER_LOGIN__CONGRATULATIONS_SUBTITLE_SCHEDULED = 'client.SUPPLIER_LOGIN__CONGRATULATIONS_SUBTITLE_SCHEDULED',
  SUPPLIER_LOGIN__CONGRATULATIONS_SUBTITLE_SCHEDULED_MOBILE = 'client.SUPPLIER_LOGIN__CONGRATULATIONS_SUBTITLE_SCHEDULED_MOBILE',
  SUPPLIER_LOGIN_NEW_TO_APP = 'client.SUPPLIER_LOGIN_NEW_TO_APP',
  SUPPLIER_LOGIN_SIGN_UP_HERE = 'client.SUPPLIER_LOGIN_SIGN_UP_HERE',
  SUPPLIER_LOGIN_EMAIL_PLACEHOLDER = 'client.SUPPLIER_LOGIN_EMAIL_PLACEHOLDER',
  SUPPLIER_LOGIN_EMAIL_LABEL = 'client.SUPPLIER_LOGIN_EMAIL_LABEL',
  SUPPLIER_LOGIN_PASSWORD_PLACEHOLDER = 'client.SUPPLIER_LOGIN_PASSWORD_PLACEHOLDER',
  SUPPLIER_REGISTRATION_PHONE_NUMBER_LABEL = 'client.SUPPLIER_REGISTRATION_PHONE_NUMBER_LABEL',
  SUPPLIER_WHAT_DO_YOU_SELL = 'client.SUPPLIER_WHAT_DO_YOU_SELL',
  SUPPLIER_SELECT_CATEGORY = 'client.SUPPLIER_SELECT_CATEGORY',
  SUPPLIER_LOGIN_WELCOME_LETSDOTHIS_BUTTON = 'client.SUPPLIER_LOGIN_WELCOME_LETSDOTHIS_BUTTON',
  SUPPLIER_LOGIN_WELCOME_CONGRATULATIONS_BUTTON = 'client.SUPPLIER_LOGIN_WELCOME_CONGRATULATIONS_BUTTON',
  SUPPLIER_LOGIN_WELCOME_GO_HOME_BUTTON = 'client.SUPPLIER_LOGIN_WELCOME_GO_HOME_BUTTON',
  SUPPLIER_LOGIN_FIRST_CONGRATULATIONS_RETURN_TO_HOME = 'client.SUPPLIER_LOGIN_FIRST_CONGRATULATIONS_RETURN_TO_HOME',
  SUPPLIER_LOGIN_THANKS_BUTTON = 'client.SUPPLIER_LOGIN_THANKS_BUTTON',
  SUPPLIER_STORE_INFO = 'client.SUPPLIER_STORE_INFO',
  SUPPLIER_STORE_NAME_LABEL = 'client.SUPPLIER_STORE_NAME_LABEL',
  SUPPLIER_STORE_NAME_PLACEHOLDER = 'client.SUPPLIER_STORE_NAME_PLACEHOLDER',
  SUPPLIER_PHONE_NUMBER_PLACEHOLDER = 'client.SUPPLIER_PHONE_NUMBER_PLACEHOLDER',
  SUPPLIER_TAX_ID_LABEL = 'client.SUPPLIER_TAX_ID_LABEL',
  SUPPLIER_TAX_ID_PLACEHOLDER = 'client.SUPPLIER_TAX_ID_PLACEHOLDER',
  SUPPLIER_WEBSITE_LABEL = 'client.SUPPLIER_WEBSITE_LABEL',
  SUPPLIER_WEBSITE_PLACEHOLDER = 'client.SUPPLIER_WEBSITE_PLACEHOLDER',
  SUPPLIER_MANDATORY_FIELDS = 'client.SUPPLIER_MANDATORY_FIELDS',
  SUPPLIER_UPLOAD_YOUR_LOGO_LABEL = 'client.SUPPLIER_UPLOAD_YOUR_LOGO_LABEL',
  SUPPLIER_TAX_ID_TOOLTIP_TEXT = 'client.SUPPLIER_TAX_ID_TOOLTIP_TEXT',
  SUPPLIER_MANUFACTURER_NAME_TOOLTIP_TEXT = 'client.SUPPLIER_MANUFACTURER_NAME_TOOLTIP_TEXT',
  SUPPLIER_RETAILER_SKU_TOOLTIP_TEXT = 'client.SUPPLIER_RETAILER_SKU_TOOLTIP_TEXT',
  SUPPLIER_MSRP_TOOLTIP_TEXT = 'client.SUPPLIER_MSRP_TOOLTIP_TEXT',
  SUPPLIER_MEDIA_URLS_TOOLTIP_TEXT = 'client.SUPPLIER_MEDIA_URLS_TOOLTIP_TEXT',
  SUPPLIER_MEDIA_URLS_TOOLTIP_TEXT_MOBILE = 'client.SUPPLIER_MEDIA_URLS_TOOLTIP_TEXT_MOBILE',
  SUPPLIER_UPLOAD_YOUR_FIRST_PRODUCT = 'client.SUPPLIER_UPLOAD_YOUR_FIRST_PRODUCT',
  SUPPLIER_MAIN_DETAILS = 'client.SUPPLIER_MAIN_DETAILS',
  SUPPLIER_PRODUCT_NAME_LABEL = 'client.SUPPLIER_PRODUCT_NAME_LABEL',
  SUPPLIER_PRODUCT_NAME_PLACEHOLDER = 'client.SUPPLIER_PRODUCT_NAME_PLACEHOLDER',
  SUPPLIER_RETAILER_SKU_LABEL = 'client.SUPPLIER_RETAILER_SKU_LABEL',
  SUPPLIER_RETAILER_SKU_PLACEHOLDER = 'client.SUPPLIER_RETAILER_SKU_PLACEHOLDER',
  SUPPLIER_QUANTITY_LABEL = 'client.SUPPLIER_QUANTITY_LABEL',
  SUPPLIER_QUANTITY_PLACEHOLDER = 'client.SUPPLIER_QUANTITY_PLACEHOLDER',
  SUPPLIER_MSRP_LABEL = 'client.SUPPLIER_MSRP_LABEL',
  SUPPLIER_MSRP_PLACEHOLDER = 'client.SUPPLIER_MSRP_PLACEHOLDER',
  SUPPLIER_SALES_PRICE_LABEL = 'client.SUPPLIER_SALES_PRICE_LABEL',
  SUPPLIER_SALES_PRICE_PLACEHOLDER = 'client.SUPPLIER_SALES_PRICE_PLACEHOLDER',
  SUPPLIER_MEDIA_URL_LABEL = 'client.SUPPLIER_MEDIA_URL_LABEL',
  SUPPLIER_MEDIA_URL_PLACEHOLDER = 'client.SUPPLIER_MEDIA_URL_PLACEHOLDER',
  SUPPLIER_UPLOAD_MEDIA_LABEL = 'client.SUPPLIER_UPLOAD_MEDIA_LABEL',
  SUPPLIER_ADDITIONAL_INFO = 'client.SUPPLIER_ADDITIONAL_INFO',
  SUPPLIER_DESCRIPTION_LABEL = 'client.SUPPLIER_DESCRIPTION_LABEL',
  SUPPLIER_DESCRIPTION_PLACEHOLDER = 'client.SUPPLIER_DESCRIPTION_PLACEHOLDER',
  SUPPLIER_WEBPAGE_LINK_LABEL = 'client.SUPPLIER_WEBPAGE_LINK_LABEL',
  SUPPLIER_WEBPAGE_LINK_PLACEHOLDER = 'client.SUPPLIER_WEBPAGE_LINK_PLACEHOLDER',
  SUPPLIER_TYPES_LABEL = 'client.SUPPLIER_TYPES_LABEL',
  SUPPLIER_TYPES_PLACEHOLDER = 'client.SUPPLIER_TYPES_PLACEHOLDER',
  SUPPLIER_TOTAL_LENGTH_LABEL = 'client.SUPPLIER_TOTAL_LENGTH_LABEL',
  SUPPLIER_TOTAL_LENGTH_PLACEHOLDER = 'client.SUPPLIER_TOTAL_LENGTH_PLACEHOLDER',
  SUPPLIER_TOTAL_WIDTH_LABEL = 'client.SUPPLIER_TOTAL_WIDTH_LABEL',
  SUPPLIER_TOTAL_WIDTH_PLACEHOLDER = 'client.SUPPLIER_TOTAL_WIDTH_PLACEHOLDER',
  SUPPLIER_TOTAL_HEIGHT_LABEL = 'client.SUPPLIER_TOTAL_HEIGHT_LABEL',
  SUPPLIER_TOTAL_HEIGHT_PLACEHOLDER = 'client.SUPPLIER_TOTAL_HEIGHT_PLACEHOLDER',
  SUPPLIER_MEASUREMENT_UNITS_LABEL = 'client.SUPPLIER_MEASUREMENT_UNITS_LABEL',
  SUPPLIER_MEASUREMENT_UNITS_PLACEHOLDER = 'client.SUPPLIER_MEASUREMENT_UNITS_PLACEHOLDER',
  SUPPLIER_MARKETING_INFO = 'client.SUPPLIER_MARKETING_INFO',
  SUPPLIER_UPLOAD_SPECIFICATION_FILE_LABEL = 'client.SUPPLIER_UPLOAD_SPECIFICATION_FILE_LABEL',
  SUPPLIER_SERIES_LABEL = 'client.SUPPLIER_SERIES_LABEL',
  SUPPLIER_SERIES_PLACEHOLDER = 'client.SUPPLIER_SERIES_PLACEHOLDER',
  SUPPLIER_COLOR_TAG_LABEL = 'client.SUPPLIER_COLOR_TAG_LABEL',
  SUPPLIER_COLOR_Tag_PLACEHOLDER = 'client.SUPPLIER_COLOR_Tag_PLACEHOLDER',
  SUPPLIER_TAGS_LABEL = 'client.SUPPLIER_TAGS_LABEL',
  SUPPLIER_TAGS_PLACEHOLDER = 'client.SUPPLIER_TAGS_PLACEHOLDER',
  SUPPLIER_REPEAT_PASSWORD = 'client.SUPPLIER_REPEAT_PASSWORD',
  SUPPLIER_NOTE_TEXT = 'client.SUPPLIER_NOTE_TEXT',
  SUPPLIER_SKIP_CONFIRMATION_SUBTITLE1 = 'client.SUPPLIER_SKIP_CONFIRMATION_SUBTITLE1',
  SUPPLIER_SKIP_CONFIRMATION_SUBTITLE2 = 'client.SUPPLIER_SKIP_CONFIRMATION_SUBTITLE2',
  UPLOAD_BANNER_TITLE = 'client.UPLOAD_BANNER_TITLE',
  DESIGN_BANNER_TITLE = 'client.DESIGN_BANNER_TITLE',
  SHOP_BANNER_TITLE = 'client.SHOP_BANNER_TITLE',
  SUPPLIER_SCHEDULE_MEETING = 'client.SUPPLIER_SCHEDULE_MEETING',
  MODERN = 'client.MODERN',
  CLASSIC = 'client.CLASSIC',
  SCANDINAVIAN = 'client.SCANDINAVIAN',
  VIEW_SELECTION = 'client.VIEW_SELECTION',
  WHAT_ARE_LOOKING_FOR = 'client.WHAT_ARE_LOOKING_FOR',
  PRIVATE = 'client.PRIVATE',
  PROJECT = 'client.PROJECT',
  MYPROJECT = 'client.MYPROJECT',
  ADD_A_NEW_PROJECT = 'client.ADD_A_NEW_PROJECT',
  CREATE_MANUALLY = 'client.CREATE_MANUALLY',
  PEROJECT_DETAILS = 'client.PEROJECT_DETAILS',
  PROJECT_NAME = 'client.PROJECT_NAME',
  PROJECT_NUMBER = 'client.PROJECT_NUMBER',
  COVER_PHOTO = 'client.COVER_PHOTO',
  CSV_FILE = 'client.CSV_FILE',
  REAL_ESTATE_DEVELOPER = 'client.REAL_ESTATE_DEVELOPER',
  UPLOAD_COVER_PHOTO = 'client.UPLOAD_COVER_PHOTO',
  UPLOAD_MATRIX_FILE = 'client.UPLOAD_MATRIX_FILE',
  REPLACE_FILE = 'client.REPLACE_FILE',
  NO_RESULTS = 'client.NO_RESULTS',
  ADD_NEW_DEVELOPER = 'client.ADD_NEW_DEVELOPER',
  PROJECT_SEARCH_PLACEHOLDER = 'client.PROJECT_SEARCH_PLACEHOLDER',
  DELETE_PROJECT_TEXT = 'client.DELETE_PROJECT_TEXT',
  EDIT_PROJECT = 'client.EDIT_PROJECT',
  PROJECT_ADDRESS = 'client.PROJECT_ADDRESS',
  PROJECT_ADDRESS_FORM = 'client.PROJECT_ADDRESS_FORM',
  PROJECT_ADDRESS_FORM_MY_PROJECT_TITLE = 'client.PROJECT_ADDRESS_FORM_MY_PROJECT_TITLE',
  DEVELOPER_NAME_MY_PROJECT_TITLE = 'client.DEVELOPER_NAME_MY_PROJECT_TITLE',
  NUMBER_OF_PROPERTIES = 'client.NUMBER_OF_PROPERTIES',
  PROPERTIES_SECTION_TITLE = 'client.PROPERTIES_SECTION_TITLE',
  TYPES_SECTION_TITLE = 'client.TYPES_SECTION_TITLE',
  ENTER_APARTMENT_NUMBER_PLACEHOLDER = 'client.ENTER_APARTMENT_NUMBER_PLACEHOLDER',
  TYPE_NAME = 'client.TYPE_NAME',
  BUILDING_NAME_TITLE = 'client.BUILDING_NAME_TITLE',
  TYPE_TITLE = 'client.TYPE_TITLE',
  BUILDING = 'client.BUILDING',
  TYPE = 'client.TYPE',
  ASSOCIATED_PROPERTIES = 'client.ASSOCIATED_PROPERTIES',
  ADD_NEW_UNIT = 'client.ADD_NEW_UNIT',
  ADD_CUSTOMER_LIST = 'client.ADD_CUSTOMER_LIST',
  BUILDING_NAME = 'client.BUILDING_NAME',
  FLOOR_PLAN = 'client.FLOOR_PLAN',
  UPLOAD_FLOOR_PLAN = 'client.UPLOAD_FLOOR_PLAN',
  BROWSE = 'client.BROWSE',
  NEW_UNIT_UPLOAD_NOTICE = 'client.NEW_UNIT_UPLOAD_NOTICE',
  CREATE_NEW_UNIT = 'client.CREATE_NEW_UNIT',
  APPLY_CHANGES = 'client.APPLY_CHANGES',
  DEVELOPER_NAME_FORM = 'client.DEVELOPER_NAME_FORM',
  PROJECT_SEARCH = 'client.PROJECT_SEARCH',
  GREEN_HOUSE = 'client.GREEN_HOUSE',
  A = 'client.A',
  PROJECT_LOADER_TEXT = 'client.PROJECT_LOADER_TEXT',
  FILE_UPLOADING_SUMMARY = 'client.FILE_UPLOADING_SUMMARY',
  SUCCESS = 'client.SUCCESS',
  FAILED = 'client.FAILED',
  DOWNLOAD_CSV = 'client.DOWNLOAD_CSV',
  SHARE_PROPERTY_WITH_CUSTOMER = 'client.SHARE_PROPERTY_WITH_CUSTOMER',
  FINISH_AND_SEND = 'client.FINISH_AND_SEND',
  UPLOAD_FILE_FAIL = 'client.UPLOAD_FILE_FAIL',
  JOHN = 'client.JOHN',
  DOE = 'client.DOE',
  SUPPLIER_LOGIN_EMAIL_PLACEHOLDERS = 'client.SUPPLIER_LOGIN_EMAIL_PLACEHOLDERS',
  MODY_BUTTON_TEXT = 'client.MODY_BUTTON_TEXT',
  MODY_HEADER_TEXT = 'client.MODY_HEADER_TEXT',
  ALL_DETAILS_MANDATORY = 'client.ALL_DETAILS_MANDATORY',
  SCAN_YOUR_HOME = 'client.SCAN_YOUR_HOME',
  PROJECT_NAME_FORM = 'client.PROJECT_NAME_FORM',
  PROJECT_NUMBER_FORM = 'client.PROJECT_NUMBER_FORM',
  PROJECT_FORM_ADDRESS_FORM = 'client.PROJECT_FORM_ADDRESS_FORM',
  ADDRESS_FORM_CITY_FORM = 'client.ADDRESS_FORM_CITY_FORM',
  ADD_NEW_DEVELOPER_FORM = 'client.ADD_NEW_DEVELOPER_FOR',
  ADD_NEW_UNIT_PROJECT = 'client.ADD_NEW_UNIT_PROJECT',
  EDIT_UNIT = 'client.EDIT_UNIT',
  MATRIX_FILTER_BY_SUB_CATEGORY = 'client.matrix.MATRIX_FILTER_BY_SUB_CATEGORY',
  MATRIX_PROJECT_SPECIFICATION = 'client.matrix.MATRIX_PROJECT_SPECIFICATION',
  MATRIX_FILTER_BY_CATEGORY = 'client.matrix.MATRIX_FILTER_BY_CATEGORY',
  PROJECT_UNIT_CREATE_USER_FILE = 'client.projects.units.PROJECT_UNIT_CREATE_USER_FILE',
  PROJECT_UNIT_CREATE_USER_FILE_SUBTITLE = 'client.projects.units.PROJECT_UNIT_CREATE_USER_FILE_SUBTITLE',
  PROJECT_UNIT_CREATE_USER_FILE_SUCCESS_SUBTITLE = 'client.projects.units.PROJECT_UNIT_CREATE_USER_FILE_SUCCESS_SUBTITLE',
  PROPERTY_ADD_CO_OWNER = 'client.properties.PROPERTY_ADD_CO_OWNER',
  UPLOAD_PLAN_ERROR_SIZE = 'client.upload_plan.UPLOAD_PLAN_ERROR_SIZE',
  UPLOAD_PLAN_ERROR_RESOLUTION = 'client.upload_plan.UPLOAD_PLAN_ERROR_RESOLUTION',
  UPLOAD_PLAN_ERROR_RATIO = 'client.upload_plan.UPLOAD_PLAN_ERROR_RATIO',
  UPLOAD_PLAN_ERROR_TYPE = 'client.upload_plan.UPLOAD_PLAN_ERROR_TYPE',
  PROPERTY_STATUS_ERROR = 'client.properties.PROPERTY_STATUS_ERROR',
  PROPERTY_REUPLOAD_BUTTON = 'client.properties.PROPERTY_REUPLOAD_BUTTON',
  HOME_MANAGEMENT_TITLE = 'client.home.Message.HOME_MANAGEMENT_TITLE',
  PROPERTY_UPLOAD_PRODUCT_CSV = 'client.property.PROPERTY_UPLOAD_PRODUCT_CSV',
  STATUS_TEXT = 'client.common.STATUS_TEXT',
  UNIT_STATUS_WAITING = 'client.project.unit.status_waiting',
  UNIT_STATUS_TODO = 'client.project.unit.status_to_do',
  UNIT_STATUS_IN_PROGRESS = 'client.project.unit.status_in_progress',
  UNIT_STATUS_ERR_IN_PLAN = 'client.project.unit.status_error_in_plan',
  UNIT_STATUS_PUBLISHED = 'client.project.unit.status_published',
}

type LanguageMessages = Record<Message, string>;

export type { LanguageMessages };
export default Message;
