import OrdersDataType from 'store/constants/user-management/orders.constants';
import * as Payload from 'store/types/user-management/orders.types';
import { OrdersRequestParams } from 'types/orders';

export const getOrdersDataRequest = (payload: OrdersRequestParams) => ({
  type: OrdersDataType.GET_ORDERS_DATA_REQUEST,
  payload,
});

export const getOrdersDataFulfilled = (
  payload: Payload.GetOrdersDataFulfilledPayload
) => ({
  type: OrdersDataType.GET_ORDERS_DATA_FULFILLED,
  payload,
});

export const getOrdersDataRejected = (
  payload: Payload.GetOrdersDataRejectedPayload
) => ({
  type: OrdersDataType.GET_ORDERS_DATA_REJECTED,
  payload,
});

export type OrdersAction = ReturnType<
  | typeof getOrdersDataRequest
  | typeof getOrdersDataFulfilled
  | typeof getOrdersDataRejected
>;
