import * as React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';

interface Props {
  body?: string[];
  children?: React.ReactNode;
  className?: string;
}
const Content: React.FC<Props> = ({ children, body, className }) => {
  return (
    <Container className={className}>
      {body &&
        body.map((row, i) => (
          <Typography
            className="line"
            key={i.toString()}
            gutterBottom={i === body.length - 1}
          >
            {row}
          </Typography>
        ))}
      {children}
    </Container>
  );
};

export default React.memo(Content);

const Container = styled(DialogContent)`
  && {
    height: 100%;
    padding: 0 1.6rem 2.5rem 2rem;
    overflow-y: overlay;
    ${({ theme }) => theme.utils.scrollbar}
    & .line {
      min-height: 20px;
      font-family: ${({ theme }) => theme.dir === 'rtl' && 'Assistant'};
    }
    @media (max-width: 768px) {
      padding: 0;
      margin-bottom: 12px;
      & p {
        font-size: 14px;
      }
    }
  }
`;
