import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import Avatar from 'components/@client/Avatar';
import { useAuthentication } from 'hooks';
import { getUserName } from 'utils/string.utils';
import { Message } from 'i18n';

interface Props {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const UserDetails: React.FC<Props> = ({ onClick }) => {
  const { firstName, lastName, avatar, loading } = useAuthentication();
  const displayName = getUserName({ firstName, lastName });
  return (
    <Figure>
      <Avatar
        name={displayName}
        alt={displayName}
        src={avatar}
        onClick={onClick}
      />
      <Caption>
        <FormattedMessage
          id={loading ? Message.LOADING : Message.USER_DETAILS}
          values={{ displayName }}
        />
      </Caption>
    </Figure>
  );
};

export default UserDetails;

const Figure = styled.figure.attrs({ className: 'user-details-figure' })`
  margin: 0;
  padding: 0 0 30px 0;
  display: flex;
  align-items: center;
  align-self: flex-start;
  font-weight: 700;
  font-size: 1.25rem;
  color: ${({ theme }) => theme.colors.primary.clientTextColor};
`;

const Caption = styled.span.attrs({ className: 'user-details-caption' })`
  text-transform: capitalize;

  ${rtl`
    margin-left: 9px;
  `}
`;
