import * as React from 'react';
import { List, ListItem } from '@material-ui/core';
import styled from 'styled-components';
import { isSameRange } from 'utils/dates.utils';
import { isEmpty } from 'utils/lodash.utils';
import { DefinedRange, DateRange, SetDateRangeReason } from './types';

type DefinedRangesProps = {
  setRange: (range: DateRange, reason?: SetDateRangeReason) => void;
  selectedRange: DateRange;
  ranges: DefinedRange[];
};

const DefinedRanges: React.FC<DefinedRangesProps> = ({
  ranges,
  setRange,
  selectedRange,
}: DefinedRangesProps) => (
  <Ranges>
    {ranges.map(({ label, ...range }, idx) => {
      const isSelectedRange = isSameRange(range, selectedRange);
      return (
        <ListItem
          button
          key={idx.toString()}
          onClick={() => setRange(range, 'select-range')}
        >
          <Range isSelectedRange={isSelectedRange}>{label}</Range>
        </ListItem>
      );
    })}
    <ListItem button onClick={() => setRange({}, 'select-range')}>
      <Range isSelectedRange={isEmpty(selectedRange)}>Any time</Range>
    </ListItem>
  </Ranges>
);

export default DefinedRanges;

const Ranges = styled(List)`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 183px;
    margin: 10px 0;
  }
`;

const Range = styled.span<{ isSelectedRange: boolean }>`
  && {
    font-size: 1rem;
    width: 100%;
    border-radius: 15px;
    font-weight: ${({ isSelectedRange }) =>
      isSelectedRange ? 800 : 400} !important;
    color: #989eae;
    padding: 10px 25px;
  }
`;
