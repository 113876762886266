import * as React from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import styled from 'styled-components';
import CloseButton from 'components/Modal/CloseButton';
import WhiteCloseButton from 'components/Modal/WhiteCloseButton';
import Container from 'components/Modal/Container';
import BackLink, { BackLinkProps } from 'components/BackLink';
import { CSSProperties } from '@material-ui/styles';

interface Props {
  backTo?: BackLinkProps;
  className?: string;
  disableBackdropClick?: boolean;
  dialogStyles?: CSSProperties;
  disablePadding?: true;
  fullScreen?: boolean;
  height?: string | number;
  minHeight?: string | number;
  open: boolean;
  sideNavigation?: React.ReactNode;
  verticalSpaceBetween?: true;
  width?: string | number;
  close?: () => void;
  whiteClose?: boolean;
  overrideZIndex?: boolean;
  actionStyles?: React.CSSProperties;
}

const AppModal: React.FC<Props> = ({
  backTo,
  children,
  className = 'app-modal',
  disablePadding,
  disableBackdropClick = false,
  dialogStyles,
  fullScreen,
  height,
  minHeight,
  open,
  sideNavigation,
  verticalSpaceBetween,
  width,
  close,
  whiteClose,
  overrideZIndex,
  actionStyles,
}) => {
  return (
    <Container
      $width={width}
      $height={height}
      $minHeight={minHeight}
      verticalSpaceBetween={verticalSpaceBetween}
      disablePadding={disablePadding}
      open={open}
      onBackdropClick={disableBackdropClick ? undefined : close}
      $hasBack={!!backTo}
      className={className}
      sideNavigation={sideNavigation}
      dialogStyles={dialogStyles}
      fullScreen={fullScreen}
      overrideZIndex={overrideZIndex}
    >
      <Actions $hasBack={!!backTo} style={actionStyles}>
        {backTo && <BackLink {...backTo} />}
        {close && !whiteClose && <CloseButton onClick={close} />}
        {close && whiteClose && (
          <WhiteCloseButton onClick={close} className="white-icon" />
        )}
      </Actions>
      {children}
    </Container>
  );
};

export default AppModal;

const Actions = styled(DialogActions)<{ $hasBack: boolean }>`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: ${({ $hasBack }) =>
    $hasBack ? 'space-between' : 'flex-end'};

  & > a {
    margin-left: 25px;
  }
`;
