import * as React from 'react';
import { useIntl } from 'react-intl';
import { Skeleton } from '@material-ui/core';
import styled from 'styled-components';
import { Message } from 'i18n';
import { MOBILE_RESOLUTION } from 'styles/sharedStyle';
import { OrderColors, OrderStatus } from 'types/orders';

interface Props {
  status?: OrderStatus;
  deliveryDate?: string;
  shippingEst?: string;
  isLoading?: boolean;
  showStepper?: boolean;
}

const OrderHeading: React.FC<Props> = ({
  status,
  deliveryDate,
  shippingEst,
  isLoading,
  showStepper = false,
}) => {
  const intl = useIntl();

  const statusName = React.useMemo(
    () => ({
      [OrderStatus.approved]: intl.formatMessage({
        id: Message.ORDER_STATUS_WAITING_SHIPPING,
      }),
      [OrderStatus.shipped]: intl.formatMessage({
        id: Message.ORDER_STATUS_SHIPPING,
      }),
      [OrderStatus.done]: intl.formatMessage({
        id: Message.ORDER_STATUS_ARRIVED,
      }),
      [OrderStatus.received]: intl.formatMessage({
        id: Message.ORDER_STATUS_PENDING,
      }),
      [OrderStatus.not_approved]: intl.formatMessage({
        id: Message.ORDER_STATUS_PENDING,
      }),
      [OrderStatus.dispute]: intl.formatMessage({
        id: Message.ORDER_STATUS_PENDING,
      }),
      [OrderStatus.in_progress]: intl.formatMessage({
        id: Message.ORDER_STATUS_PENDING,
      }),
    }),
    [intl]
  );

  return (
    <Container status={status}>
      {isLoading ? (
        <Skeleton variant="text" width={120} />
      ) : (
        !showStepper && (
          <h4 className="order-title">
            {status
              ? statusName[status] ??
                intl.formatMessage({
                  id: Message.ORDER_STATUS_IN_PROGRESS,
                })
              : intl.formatMessage({
                  id: Message.ORDER_STATUS_PENDING,
                })}
          </h4>
        )
      )}

      {isLoading ? (
        <Skeleton variant="text" width={250} />
      ) : (
        <span className="order-date">
          {status === OrderStatus.done && deliveryDate && !showStepper
            ? intl.formatMessage({ id: Message.ORDER_DELIVERED_ON }) +
              intl.formatDate(deliveryDate, { dateStyle: 'long' })
            : shippingEst &&
              intl.formatMessage({ id: Message.ORDER_DELIVERY_ON }) +
                intl.formatDate(shippingEst, { dateStyle: 'long' })}
        </span>
      )}

      {!showStepper && !isLoading && (
        <div className={`status-indicator${isLoading ? ' loading' : ''}`} />
      )}
      {isLoading ? (
        <Skeleton variant="text" width={250} />
      ) : (
        <span className="order-date">
          {status === OrderStatus.done && deliveryDate && showStepper
            ? intl.formatMessage({ id: Message.ORDER_DELIVERED_ON }) +
              intl.formatDate(deliveryDate, { dateStyle: 'long' })
            : shippingEst &&
              intl.formatMessage({ id: Message.ORDER_DELIVERY_ON }) +
                intl.formatDate(shippingEst, { dateStyle: 'long' })}
        </span>
      )}
    </Container>
  );
};

export default OrderHeading;

const Container = styled.section.attrs({ className: 'order-heading' })<{
  status?: OrderStatus;
}>`
  & .order-title {
    font-weight: 500;
    font-size: 20px;
    margin: 0;
    color: ${({ theme }) => theme.colors.primary.clientTextColor};
  }
  & .order-date {
    font-weight: 500;
    font-size: 16px;
    color: #797676;
    margin-bottom: 8px;
  }
  & .status-indicator {
    border-radius: 10px;
    width: 100%;
    height: 8px;
    margin: 20px 0;
    background-color: ${({ status = OrderStatus.received }) =>
      OrderColors[status]};

    &.loading {
      background: rgba(121, 118, 118, 0.2);
    }

    @media ${MOBILE_RESOLUTION} {
      margin: 10px 0 20px;
    }
  }
`;
