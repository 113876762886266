import * as React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import StoriesComponent from 'react-insta-stories';
import Dialog from '@material-ui/core/Dialog';
import styled, { css } from 'styled-components';
import { MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';
import { useBlobFromUrl } from 'hooks';
import { LogsAPI } from 'api/methods';
import { userLogData } from 'mockData/userLogData';
import { ReactComponent as Arrow } from 'assets/icons/Separator.svg';
import { ModalProps } from '../Modal';

interface Props {
  label: string;
  stories: Array<{ url: string; duration?: number; type: string }>;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const InstaStoryModal: React.FC<Props> = ({
  stories,
  isOpen,
  setIsOpen,
  label,
}) => {
  const isMobile = useMediaQuery(MOBILE_TABLET_RESOLUTION);
  const onClose = React.useCallback(() => setIsOpen(false), [setIsOpen]);
  const [currIndex, setCurrIndex] = React.useState(0);
  const [storiesData, setStoriesData] = React.useState<
    { url: string; duration?: number; type: string }[]
  >(stories);
  const { handleDownload } = useBlobFromUrl({
    url:
      'https://patricia-ai-data-production.s3.eu-central-1.amazonaws.com/media/stories/meet-patricia/1st.mp4',
  });
  const goToNext = React.useCallback(() => {
    if (currIndex < stories.length - 1) {
      setCurrIndex(prev => prev + 1);
    }
    LogsAPI.postUserLogs({
      name: userLogData.eventName.nextStory,
      area: userLogData.eventArea.homePage,
      section: userLogData.eventSection.instaStories,
      path: userLogData.eventPath.appPath,
      metadata: { story_name: label },
    });
  }, [currIndex, stories, label]);
  const goToPrev = React.useCallback(() => {
    if (currIndex > 0) {
      setCurrIndex(prev => prev - 1);
    }
  }, [currIndex]);
  const loadNextStory = React.useCallback(
    (s: number) => {
      if (storiesData[s + 1]?.url)
        handleDownload({ baseURL: '' }, storiesData[s + 1].url).then(r => {
          setStoriesData(prev => {
            return prev.map((e, i) => {
              if (i === s + 1) {
                return {
                  ...e,
                  url: r || e.url,
                };
              }
              return e;
            });
          });
        });
    },
    [handleDownload, storiesData]
  );

  return (
    <Container open={isOpen} onClose={onClose}>
      <StoryDiv>
        {!isMobile && (
          <ArrowButton
            onClick={() => {
              goToPrev();
            }}
          >
            <LeftArrow />
          </ArrowButton>
        )}
        <StoryContainer>
          <StyledStoryComponent
            stories={storiesData.map(e => ({
              ...e,

              header: {
                heading: label,
                subheading: '',
                profileImage: '',
              },
            }))}
            currentIndex={currIndex}
            width={isMobile ? '100%' : undefined}
            height={isMobile ? '100%' : undefined}
            onAllStoriesEnd={onClose}
            onStoryStart={(s: number) => {
              loadNextStory(s);
              setCurrIndex(s);
            }}
            onStoryEnd={(s: number) => {
              goToNext();
            }}
          />
        </StoryContainer>
        {!isMobile && (
          <ArrowButton
            onClick={() => {
              goToNext();
            }}
          >
            <Arrow />
          </ArrowButton>
        )}
      </StoryDiv>
    </Container>
  );
};

export default InstaStoryModal;

const Container = styled(Dialog).attrs<ModalProps>({
  className: 'client-dialog',
  BackdropProps: { className: 'client-dialog-backdrop' },
})`
  direction: ltr;
  position: relative;
  &.client-dialog .MuiDialog-paper {
    padding: 0px;
    border-radius: 5px;

    & .MuiDialogTitle-root {
      margin: 24px;
    }

    & .MuiDialogActions-root {
      padding: 0 3rem 24px 3rem;
      justify-content: center;

      & > button {
        flex: 1;
        max-width: 150px;
      }
    }
  }
  .client-dialog-backdrop {
    background: rgba(92, 87, 87, 0.8);
  }
  .MuiPaper-root {
    background-color: transparent;
  }
  .MuiPaper-elevation24 {
    box-shadow: none;
  }
`;

const ArrowButton = styled.button<{ disabled?: boolean }>`
  border-radius: 50%;
  background: rgba(40, 40, 40, 0.5);
  margin: 0 10px;
  height: 40px;
  color: #fff;

  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;

  &:hover {
    cursor: pointer;
  }

  & > svg {
    width: 28px;
    height: 20px;
    path {
      stroke: white;
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      & > svg {
        path {
          stroke: transparent;
        }
      }
      &:hover {
        cursor: default;
      }
    `}
`;

const LeftArrow = styled(Arrow)`
  transform: rotate(${({ theme }) => 180}deg);
`;

const StoryDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
`;

const StyledStoryComponent = styled(StoriesComponent)`
  background-color: white;
`;

const StoryContainer = styled.div``;
