import * as React from 'react';
import styled from 'styled-components';
import SafeImg from 'components/SafeImg/SafeImg';
import {
  MOBILE_RESOLUTION,
  MOBILE_TABLET_RESOLUTION,
} from 'styles/sharedStyle';
import { useMediaQuery } from '@material-ui/core';
import { Carousel } from 'react-responsive-carousel';
import rtl from 'styled-components-rtl';
import * as CoverImage from 'assets/@client/products/coverSection/index';

interface Props {
  imageUrl: string | undefined;
  title?: string;
}

const CoverSection: React.FC<Props> = ({ imageUrl, title }) => {
  const isMobile = useMediaQuery(MOBILE_TABLET_RESOLUTION);

  const pages = [
    {
      image: imageUrl,
      product: title,
    },
  ];

  return (
    <>
      {isMobile ? (
        <Carousel
          showArrows={false}
          showStatus={false}
          showIndicators={false}
          showThumbs={false}
        >
          {pages.map((item, i) => (
            <MobileContainer key={item.product}>
              <Background />
              {title && (
                <Header>
                  <h1>{title}</h1>
                </Header>
              )}
              <Img>
                <SafeImg
                  src={item.image || CoverImage.Mobile}
                  alt={item.product}
                />
              </Img>
            </MobileContainer>
          ))}
        </Carousel>
      ) : (
        <DesktopContainer>
          <SafeImg src={CoverImage.Desktop} />
        </DesktopContainer>
      )}
    </>
  );
};

export default CoverSection;

const DesktopContainer = styled.div`
  width: 100vw;
  margin: 0 calc(-50vw + 50%);
  display: flex;
  ${({ theme }) => theme.shadow.l}
  img {
    max-width: 100%;
    max-height: 400px;
    margin: auto;
  }
`;

const MobileContainer = styled.div`
  position: relative;
`;

const Background = styled.div`
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
`;

const Header = styled.div`
  position: absolute;
  bottom: 0;
  ${rtl`
    margin-left: 12px;
  `}
  h1 {
    font-size: 2.25rem;
    @media ${MOBILE_RESOLUTION} {
      font-size: 1.5rem;
    }
  }
`;

const Img = styled.div`
  & img {
    height: 100%;
    width: 100%;
    max-height: 300px;
    max-width: 800px;
    min-height: 152px;
    border-radius: 10px;
  }
`;
