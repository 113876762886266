import * as React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import styled from 'styled-components';

const WhiteCloseButton: React.FC<{
  onClick: () => void;
  className?: string;
}> = ({ onClick, className }) => {
  return (
    <IconButton aria-label="close" onClick={onClick} className={className}>
      <Close className="close-icon" />
    </IconButton>
  );
};

export default React.memo(WhiteCloseButton);

const Close = styled(CloseIcon)`
  font-size: 30px;
  fill: #fff;
`;
