import * as actions from 'store/actions/quiz-management/designStyles.actions';
import DesignStylesDataType from 'store/constants/quiz-management/designStyles.constants';
import * as Payload from 'store/types/designStyles.types';
import { DesignStyle } from 'types/quizzes';

export interface DesignStylesState {
  loading: Record<DesignStylesDataType, boolean>;
  error: Record<DesignStylesDataType, null | string>;
  designStyles: DesignStyle[];
  pageCount: number;
}

const REQUESTS = [DesignStylesDataType.GET_DESIGN_STYLES_DATA_REQUEST];

const initialState: DesignStylesState = {
  loading: REQUESTS.reduce(
    (state, next) => ({ ...state, [next]: false }),
    {} as Record<DesignStylesDataType, boolean>
  ),
  error: REQUESTS.reduce(
    (state, next) => ({ ...state, [next]: null }),
    {} as Record<DesignStylesDataType, null | string>
  ),
  designStyles: [],
  pageCount: 0,
};

const quizzesReducer = (
  state: DesignStylesState = initialState,
  action: actions.QuizzesAction
): DesignStylesState => {
  switch (action.type) {
    case DesignStylesDataType.GET_DESIGN_STYLES_DATA_REQUEST:
      return {
        ...updateLoading(
          state,
          DesignStylesDataType.GET_DESIGN_STYLES_DATA_REQUEST
        ),
      };
    case DesignStylesDataType.GET_DESIGN_STYLES_DATA_FULFILLED:
      return {
        ...updateSuccess(
          state,
          DesignStylesDataType.GET_DESIGN_STYLES_DATA_REQUEST,
          action.payload as Payload.GetDesignStylesFulfilledPayload
        ),
      };
    case DesignStylesDataType.GET_DESIGN_STYLES_DATA_REJECTED:
      return {
        ...updateError(
          state,
          DesignStylesDataType.GET_DESIGN_STYLES_DATA_REQUEST,
          action.payload as Payload.GetDesignStylesRejectedPayload
        ),
      };
    default:
      return state;
  }
};

export default quizzesReducer;

type requestType = DesignStylesDataType.GET_DESIGN_STYLES_DATA_REQUEST;

const updateLoading = (state: DesignStylesState, requestType: requestType) => ({
  ...state,
  loading: {
    ...state.loading,
    [requestType]: true,
  },
  error: {
    ...state.error,
    [requestType]: null,
  },
});

type ErrorPayload = Payload.GetDesignStylesRejectedPayload;
const updateError = (
  state: DesignStylesState,
  requestType: requestType,
  { error }: ErrorPayload
) => ({
  ...state,
  loading: {
    ...state.loading,
    [requestType]: false,
  },
  error: {
    ...state.error,
    [requestType]: error,
  },
});

type SuccessPayload = Payload.GetDesignStylesFulfilledPayload;
const updateSuccess = (
  state: DesignStylesState,
  requestType: requestType,
  payload: SuccessPayload
) => ({
  ...state,
  loading: {
    ...state.loading,
    [requestType]: false,
  },
  error: {
    ...state.error,
    [requestType]: null,
  },
  ...payload,
});
