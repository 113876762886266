import * as React from 'react';
import styled, { css } from 'styled-components';
import { FormProvider } from 'react-hook-form';
import { MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';
import { Checkbox } from '@mui/material';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Message } from 'i18n';
import { ReactComponent as ContentCopy } from 'assets/socialIcons/Copy.svg';
import type { ClientInformationType } from 'types/common.types';
import { Label, PhoneField } from 'components/forms';
import TextInput from 'components/@client/form-components/TextInput';
import Button from 'components/@client/Button';
import useSellerInformation from 'hooks/useSellerInformation';

interface Props {
  onClose: VoidFunction;
}

const ClientInformation: React.FC<Props> = ({ onClose }) => {
  const intl = useIntl();
  const [isCopy, setIsCopy] = React.useState<boolean>(false);
  const [isTncAgreed, setIsTncAgreed] = React.useState(false);
  const {
    methods,
    onUpdatePassword,
    handleSave,
    isLoading,
    isPassword,
    storePass,
  } = useSellerInformation();

  const { register, control, handleSubmit, formState } = methods;
  const { isValid, isDirty } = formState;

  React.useEffect(() => {
    if (isPassword) {
      onUpdatePassword();
    }
  }, [isPassword, onUpdatePassword]);

  const onCopyText = React.useCallback((text: string) => {
    navigator.clipboard.writeText(text);
    setIsCopy(true);
  }, []);

  const onSubmit = React.useCallback(
    (payload: ClientInformationType) => {
      handleSave(payload);
    },
    [handleSave]
  );

  return (
    <Container>
      <Text>
        <FormattedMessage id={Message.PROPERTY_MODAL_TITLE_SELLER} />
      </Text>
      <FormTitle>
        <FormattedMessage id={Message.PROPERTY_MODAL_CUSTOMER_DETAILS} />
      </FormTitle>
      <ValidationMessage>
        <FormattedMessage id={Message.PROPERTY_MODAL_VALIDATION_MESSAGE} />
      </ValidationMessage>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <TextWrapper>
            <TextInput
              name="firstName"
              control={control}
              placeholder={intl.formatMessage({ id: Message.JOHN })}
              label={intl.formatMessage({ id: Message.FIRST_NAME })}
              required
            />
          </TextWrapper>
          <TextWrapper>
            <TextInput
              name="lastName"
              control={control}
              placeholder={intl.formatMessage({ id: Message.DOE })}
              label={intl.formatMessage({ id: Message.LAST_NAME })}
              required
            />
          </TextWrapper>
          <TextWrapper>
            <TextInput
              name="email"
              dir="ltr"
              control={control}
              placeholder={intl.formatMessage({
                id: Message.SUPPLIER_LOGIN_EMAIL_PLACEHOLDERS,
              })}
              label={
                intl.locale === 'he'
                  ? intl.formatMessage({
                      id: Message.USER_DELETE_ACCOUNT_EMAIL_ADDRESS,
                    })
                  : intl.formatMessage({
                      id: Message.EMAIL_TITLE,
                    })
              }
              type="email"
              required
            />
          </TextWrapper>
          <TextWrapper>
            <PhoneNumber
              name="phone"
              label={{
                title: intl.formatMessage({
                  id: Message.PHONE_NUMBER,
                }),
              }}
              prefix="+"
            />
          </TextWrapper>
          {isPassword && (
            <PasswordContainer>
              <Label
                label={{
                  title: <FormattedMessage id={Message.CLIENT_PASSWORD} />,
                }}
                htmlFor="clientpassword"
              />
              <input
                type="hidden"
                {...register('password')}
                placeholder="password"
              />
              <CopyButtonWrapper>
                <GeneratePassword>{storePass}</GeneratePassword>
                <CopyButton
                  isCopy={isCopy}
                  onClick={() => onCopyText(storePass ?? '')}
                >
                  <ContentCopy />
                </CopyButton>
              </CopyButtonWrapper>
            </PasswordContainer>
          )}
          <ParentTermsDiv>
            <StyledCheckbox
              checked={isTncAgreed}
              onChange={() => setIsTncAgreed(prev => !prev)}
            />
            <FormattedMessage
              id={Message.LANDING_PAGE_ACCEPT_TERMS}
              values={{
                link: (chunks: string) => (
                  <>
                    &nbsp;
                    <StyledLink to="/app/privacy-policy">{chunks}</StyledLink>
                  </>
                ),
              }}
            />
          </ParentTermsDiv>
          <Row center={true}>
            <AddPropertButton
              className="modal-action-button"
              isLoading={isLoading}
              disabled={!isValid || !isDirty || !isTncAgreed}
              type="submit"
            >
              <FormattedMessage id={Message.FINISH} />
            </AddPropertButton>
          </Row>
        </Form>
      </FormProvider>
    </Container>
  );
};

export default ClientInformation;

const Container = styled.section.attrs({ className: 'upload-plan-container' })`
  display: flex;
  flex-direction: column;
  .text-input-root {
    padding-bottom: 0;
  }
  .phone-input-container {
    // width: 110%;
    // margin-left: -24px;
    &:hover {
      .flag-dropdown {
        border-color: #e6e6e6;
      }
    }
  }
  label {
    font-size: 18px !important;
    line-height: 20px;
    color: #696969 !important;
    margin-left: 0;
    font-weight: 400 !important;
  }
  .phone-input {
    background: #fafcfe;
    width: 110%;
    &:hover {
      border-color: #e6e6e6 !important;
    }
  }
  .flag-dropdown .custom-input-label {
    color: #696969 !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
    font-family: Roboto !important;
  }

  .phone-number-label {
    color: #696969 !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
    font-family: Roboto !important;
  }
  .react-tel-input .form-control {
    width: 250px;
  }

  .react-tel-input {
    background: #fafcfe;
    &:hover {
      border-color: #e6e6e6 !important;
    }
  }

  .flag-dropdown {
    border-color: none;
  }

  .country-list {
    width: 250px;
  }
`;

const Text = styled.span.attrs({ className: 'upload-plan-text' })`
  font-weight: 600;
  font-size: 28px;
  margin-bottom: 0px;
  text-align: center;
  line-height: 32px;
  margin-top: 24px;
  color: #fc5c42;
  @media (max-width: 800px) {
    font-weight: 500;
    font-size: 22px;
    margin-top: 32px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  gap: 14px;
  display: flex;
  align-items: center;
`;

const Row = styled.div<{
  center?: boolean;
}>`
  display: flex;
  gap: 14px;
  ${({ center }) =>
    center &&
    css`
      align-items: center;
      justify-content: center;
    `}
  @media ${MOBILE_TABLET_RESOLUTION} {
    display: block;
  }
`;

const TextWrapper = styled.div`
  width: 256px;
  .text-input-root {
    width: 100%;
    min-height: auto;
  }
  .custom-input-root .MuiInputBase-root {
    max-height: 50px;
    background: #fafcfe;
    border: 1px solid #e8eaeb;
    border-radius: 10px;
  }
  .custom-input-root:hover {
    border: none !important;
    border-radius: 10px !important;
  }
  .custom-input-label {
    margin-bottom: 8px;
    font-family: ${({ theme }) =>
      theme.dir === 'rtl' ? ' Assistant' : 'inherit'};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.545455px;
    color: #696969;
  }
`;

// const TextInputField = styled(TextInput)`
//   width: 100% !important;
//   max-height: 50px !important;
// `;

const CopyButton = styled.span<{
  isCopy: boolean;
}>`
  color: ${({ theme }) => theme.colors.primary.lightMobile};
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
  svg {
    color: ${({ theme }) => theme.colors.primary.contrastText};
    fill: #282929;
    width: 24px;
    height: 24px;
    ${({ isCopy }) =>
      isCopy &&
      css`
        background: #fc5c42;
        border-radius: 50%;
        fill: #ffffff;
      `}
  }
`;

const GeneratePassword = styled.span`
  font-size: 18px;
  line-height: 22px;
  color: #282929;
`;

const PasswordContainer = styled.div`
  display: flex;
  align-items: baseline;
  label {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #282929;
  }
`;

const CopyButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  gap: 8px;
`;

const PhoneNumber = styled(PhoneField)`
  &:hover {
    .flag-dropdown,
    .form-control {
      border-color: #e6e6e6;
    }
  }
`;

const AddPropertButton = styled(Button)`
  && {
    width: 134px;
    margin-top: -5px;
    height: 48px;
    background: #fc5c42;
    border: 1px solid #fc5c42;
    border-radius: 15px;
    .MuiButton-label {
      font-family: ${({ theme }) =>
        theme.dir === 'rtl' ? ' Assistant' : 'inherit'};
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: rgba(255, 255, 255, 0.9);
      text-align: center;
    }
    &:hover {
      background-color: #fc5c42;
      transition: background-color 0.3s;
      box-shadow: none;
    }

    &:disabled {
      background-color: #e8e8e8;
      border: 1px solid #e8e8e8;
      color: white;
    }
  }
`;

const FormTitle = styled.div`
  color: #282929;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  margin-top: 24px;
  text-align: center;
`;

const ValidationMessage = styled.div`
  text-align: center;
  color: #3e3e3e;
  font-size: 18px;
  line-height: 22px;
`;

const StyledCheckbox = styled(Checkbox)`
  background: #f5f5fa;
  border: 1px solid #2e294e;
  border-radius: 4px;
  &.Mui-checked {
    color: #2e294e !important;
  }
`;

const StyledLink = styled(Link)`
  color: #5c95ff;
  text-decoration: none;
  border-bottom: 1px solid #5c95ff;
`;

const ParentTermsDiv = styled.div`
  font-size: 18px;
  line-height: 22px;
`;
