import * as React from 'react';
import * as Transform from 'api/transform';
import styled from 'styled-components';
import { useFetch } from 'hooks';
import { METHODS } from 'api/client';
import { SubCategory } from 'types/common.types';
import CoverSection from 'components/@client/CoverSection/CoverSection';
import ProductsCard from './ProductsCard';

interface Props {
  title: string;
  subCategory: SubCategory | undefined;
}

const ProductPage: React.FC<Props> = ({ title, subCategory }) => {
  const {
    data: subCategories,
    callFetch: loadMoreProducts,
    isLoading,
  } = useFetch({
    initialUrl: `api/products/`,
    skipOnStart: true,
    config: {
      method: METHODS.GET,

      params: { sub_category: title, size: 12, page: 1 },
    },
    transform: Transform.PaginatedProducts,
  });
  return (
    <Root>
      <CoverSection imageUrl={subCategory?.category?.cover_photo_mobile} />
      <ProductsCard
        products={subCategories}
        loadMoreProducts={loadMoreProducts}
        isLoading={isLoading}
      />
    </Root>
  );
};

export default ProductPage;

const Root = styled.div``;
