import { Autocomplete, Button, TextField } from '@mui/material';
import * as React from 'react';
import styled, { css } from 'styled-components';
import { developer } from 'types/properties';
import { useFetch, useNotifications } from 'hooks';
import { METHODS } from 'api/client';
import { ReactComponent as UploadIcon } from 'assets/@client/supplier/cloud-upload-btn.svg';
import { ReactComponent as UploadDoneIcon } from 'assets/@client/supplier/cloud-upload-done.svg';
import TextInput from 'components/@client/form-components/TextInput';
import { Message } from 'i18n';
import rtl from 'styled-components-rtl';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormProvider } from 'react-hook-form';
import { ProjectInformationType } from 'types/common.types';
import Loader from 'components/Loader/Loader';
import useProjectInformation from 'hooks/useProjectInformation';
import Icon from 'components/Icon/Icon';
import { MOBILE_RESOLUTION } from 'styles/sharedStyle';

const UserForm: React.FC<{ supplier?: number; calendlyUrl?: string }> = ({
  supplier,
  calendlyUrl,
}) => {
  const intl = useIntl();
  // eslint-disable-next-line
  let _window: any = window;

  const {
    methods,
    handleSave,
    image,
    isLoading,
    searchFlag,
    setImage,
    csv,
    setCSV,
    developer: developerObj,
    onAddressesUpdate,
  } = useProjectInformation();
  const { control, handleSubmit, formState } = methods;
  const { isValid, isDirty } = formState;

  const { showNotification } = useNotifications();
  const onFailure = React.useCallback(() => {
    showNotification({
      key: 'properties/getPropertiesDataRejected',
      message:
        'We had a problem fetching your projects, please try again later',
      severity: 'error',
    });
  }, [showNotification]);

  const { data: developers, callFetch: serchList } = useFetch<developer[]>({
    initialUrl: `api/developers/`,
    onFailure,
  });

  const [selectAddress, setSelectedAddress] = React.useState<{
    label: string | number | undefined;
    value?: string | number | undefined;
  } | null>(null);

  const [searchValue, setSearchValue] = React.useState('');

  const developerOptions = React.useMemo(() => {
    return (
      developers
        ?.filter(e => e.name?.includes(searchValue))
        .map(e => ({
          label: e.name,
          value: e.id,
        })) ?? []
    );
  }, [developers, searchValue]);
  React.useEffect(() => {
    setSelectedAddress(developerObj);
  }, [developerObj]);

  const addressOptions = [...developerOptions];

  const onSearchSuccess = React.useCallback(
    (data: { id: string; name: string } | null) => {
      serchList();
      onAddressesUpdate('', { label: data?.name, value: data?.id });
      setSelectedAddress({ label: data?.name, value: data?.id });
    },
    [onAddressesUpdate, serchList]
  );

  const onFailures = React.useCallback(() => {
    showNotification({
      key: 'properties/getPropertiesDataRejected',
      message:
        'We had a problem fetching your developer, please try again later',
      severity: 'error',
    });
  }, [showNotification]);

  const { callFetch: addDevelopers } = useFetch({
    initialUrl: 'api/developers/',
    skipOnStart: true,
    onSuccess: onSearchSuccess,
    onFailure: onFailures,
  });

  const addDeveloper = () => {
    addDevelopers({
      url: 'api/developers/',
      method: METHODS.POST,
      data: {
        name: searchValue,
      },
    });
  };

  const [developerLogo, setDeveloperLogo] = React.useState<File | null>(null);
  const onSubmit = React.useCallback(
    (payload: ProjectInformationType) => {
      if (developerLogo) {
        const developerData = new FormData();
        developerData.append('logo', developerLogo as Blob);
        addDevelopers({
          url: `/api/developers/${developerObj.value}/`,
          method: METHODS.PATCH,
          data: developerData,
        });
      }
      handleSave({ ...payload });
    },
    [addDevelopers, developerLogo, developerObj?.value, handleSave]
  );
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const csvInputRef = React.useRef<HTMLInputElement>(null);
  const developerLogoInputRef = React.useRef<HTMLInputElement>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const submitForm = handleSubmit(onSubmit);
  return (
    <MainContainer>
      <Text>
        <FormattedMessage id={Message.ADD_A_NEW_PROJECT} />
      </Text>
      <FormOption>
        <LeftContainer>
          <UserFormContainer id="userForm">
            <h5>
              <FormattedMessage id={Message.PEROJECT_DETAILS} />
            </h5>
            <h6>
              <FormattedMessage
                id={Message.PROPERTY_MODAL_VALIDATION_MESSAGE}
              />
            </h6>
            <input
              type="file"
              style={{ display: 'none' }}
              ref={fileInputRef}
              accept="image/png, image/jpeg, image/jpg"
              data-testid="fileTestinput1"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const fileList = e.target.files;

                if (!fileList) return;
                setImage(fileList[0]);
              }}
            />
            <input
              type="file"
              style={{ display: 'none' }}
              ref={csvInputRef}
              accept=".csv, application/vnd.ms-excel"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const fileList = e.target.files;
                if (!fileList) return;
                setCSV(fileList[0]);
              }}
            />
            <input
              type="file"
              style={{ display: 'none' }}
              ref={developerLogoInputRef}
              accept="image/png, image/jpeg, image/jpg"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const fileList = e.target.files;
                if (!fileList) return;
                setDeveloperLogo(fileList[0]);
              }}
            />

            <FormProvider {...methods}>
              <form className="sign-up-form" onSubmit={submitForm}>
                <div className="row">
                  <div className="form-group first-name project-input-width">
                    <TextInput
                      name="name"
                      control={control}
                      label={intl.formatMessage({ id: Message.PROJECT_NAME })}
                      placeholder={intl.formatMessage({
                        id: Message.PROJECT_NAME_FORM,
                      })}
                      required
                      inputPropsData={{ 'data-testid': 'name' }}
                    />
                  </div>
                  <CoverPhoto>
                    <FormattedMessage id={Message.DEVELOPER_NAME_FORM} />
                  </CoverPhoto>
                  <AutocompleteComponent
                    disablePortal
                    classes={{ paper: 'paper-class' }}
                    data-testid="autoCompleteBox"
                    ListboxProps={{ style: { maxHeight: '10rem' } }}
                    onInputChange={(_, value) => {
                      setSearchValue(value);
                    }}
                    renderOption={(
                      props: React.HTMLAttributes<HTMLLIElement>,
                      option: unknown,
                      _state
                    ) => (
                      <>
                        <li
                          {...props}
                          key={
                            (option as {
                              label: string;
                              value: string | number;
                            }).value
                          }
                        >
                          {
                            (option as {
                              label: string;
                              value: string | number;
                            }).label
                          }
                        </li>
                      </>
                    )}
                    noOptionsText={
                      <div className="d-flex align-items-center justify-content-between mt-2 mainClass">
                        <div className="mainClass2">
                          <span className="fw-bold without-error">
                            {intl.formatMessage({ id: Message.NO_RESULTS })}{' '}
                          </span>
                          "<span className="error">{searchValue}</span>"
                        </div>
                        <span
                          className="add-developer justify-content-center"
                          onClick={() => addDeveloper()}
                        >
                          <Icon
                            name="plus"
                            className="icon-button"
                            color="#5c95ff"
                          />
                          {intl.formatMessage({
                            id: Message.ADD_NEW_DEVELOPER,
                          })}
                        </span>
                      </div>
                    }
                    value={selectAddress}
                    onChange={onAddressesUpdate}
                    options={addressOptions}
                    renderInput={params => (
                      <TextField
                        {...params}
                        placeholder={intl.formatMessage({
                          id: Message.ADD_NEW_DEVELOPER_FORM,
                        })}
                        // label="autoCompleteBox"
                      />
                    )}
                  />
                </div>
                <CSVFile>Developer Logo</CSVFile>
                <div className="upload-file">
                  <div className="upload-btn-container">
                    <div className="upload-floor-plan">Upload Logo</div>
                    <UploadButton
                      isImageSelected={Boolean(developerLogo)}
                      className="upload-floor-btn"
                      onClick={() => developerLogoInputRef.current?.click()}
                    >
                      {developerLogo ? <UploadDoneIcon /> : <UploadIcon />}
                      <FormattedMessage
                        id={
                          developerLogo
                            ? Message.REPLACE_FILE
                            : Message.LANDING_PAGE_CHOOSE_FILE_BUTTON
                        }
                      />
                    </UploadButton>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group email-wrap phone project-input-width">
                    <TextInput
                      name="number"
                      control={control}
                      label={intl.formatMessage({ id: Message.PROJECT_NUMBER })}
                      required
                      placeholder={intl.formatMessage({
                        id: Message.PROJECT_NUMBER_FORM,
                      })}
                      inputPropsData={{ 'data-testid': 'project_number' }}
                    />
                  </div>
                </div>

                <div className="row address">
                  <div className="form-group project-address form-email project-input-width">
                    <TextInput
                      name="address"
                      control={control}
                      label={intl.formatMessage({
                        id: Message.PROJECT_ADDRESS_FORM,
                      })}
                      type="text"
                      placeholder={intl.formatMessage({
                        id: Message.PROJECT_FORM_ADDRESS_FORM,
                      })}
                      inputPropsData={{ 'data-testid': 'address' }}
                    />
                  </div>
                  <div className="form-group project-city form-email project-input-width">
                    <TextInput
                      name="city"
                      control={control}
                      label={intl.formatMessage({
                        id: Message.ADDRESS_FORM_CITY,
                      })}
                      type="text"
                      required
                      placeholder={intl.formatMessage({
                        id: Message.ADDRESS_FORM_CITY_FORM,
                      })}
                      inputPropsData={{ 'data-testid': 'city' }}
                    />
                  </div>
                </div>
                <CoverPhoto>
                  <FormattedMessage id={Message.COVER_PHOTO} />
                </CoverPhoto>
                <div className="upload-file">
                  <div className="upload-btn-container">
                    <div className="upload-floor-plan">
                      <FormattedMessage id={Message.UPLOAD_COVER_PHOTO} />
                    </div>
                    <UploadButton
                      isImageSelected={Boolean(image)}
                      className="upload-floor-btn"
                      onClick={() => fileInputRef.current?.click()}
                    >
                      {image ? <UploadDoneIcon /> : <UploadIcon />}
                      <FormattedMessage
                        id={
                          image
                            ? Message.REPLACE_FILE
                            : Message.LANDING_PAGE_CHOOSE_FILE_BUTTON
                        }
                      />
                    </UploadButton>
                  </div>
                  <CSVFile>
                    <FormattedMessage id={Message.CSV_FILE} />
                  </CSVFile>
                  <div className="upload-file">
                    <div className="upload-btn-container">
                      <div className="upload-floor-plan">
                        <FormattedMessage id={Message.UPLOAD_MATRIX_FILE} />
                      </div>
                      <UploadButton
                        isImageSelected={Boolean(csv)}
                        className="upload-floor-btn"
                        onClick={() => csvInputRef.current?.click()}
                      >
                        {csv ? <UploadDoneIcon /> : <UploadIcon />}
                        <FormattedMessage
                          id={
                            csv
                              ? Message.REPLACE_FILE
                              : Message.LANDING_PAGE_CHOOSE_FILE_BUTTON
                          }
                        />
                      </UploadButton>
                    </div>
                  </div>
                  <FinishButton
                    type="submit"
                    disabled={
                      !isValid || !isDirty || !image || !searchFlag || isLoading
                    }
                  >
                    {isLoading ? (
                      <Loader />
                    ) : (
                      <FormattedMessage
                        id={Message.LANDING_PAGE_FINISH_BUTTON}
                      />
                    )}
                  </FinishButton>
                </div>
              </form>
            </FormProvider>
          </UserFormContainer>
        </LeftContainer>
      </FormOption>
    </MainContainer>
  );
};

const UserFormContainer = styled.div`
  width: 100v;
  background-color: white;
  padding: 0 0;
  text-align: center;
  .error {
    color: #fc5c42;
  }
  .loader {
    span {
      height: 30px !important;
      width: 30px !important;
    }
  }
  .without-error {
    color: black;
  }
  .add-developer {
    font-style: normal;
    font-weight: ${({ theme }) => (theme.dir === 'rtl' ? '600' : '400')};
    font-size: 16px;
    line-height: 20px;
    color: #5c95ff;
    padding-top: 50px;
    cursor: pointer;
    .icon-button {
      ${rtl` padding-right: 6px;`}
      padding-top: 5px;
      color: #5c95ff !important;
      width: 16px !important;
    }
  }
  input {
    &::placeholder {
      font-family: ${({ theme }) => theme.dir === 'rtl' && 'Assistant'};
    }
  }
  .MuiAutocomplete-inputRoot {
    border: 1px solid #ff928700;
    &::placeholder {
      font-family: ${({ theme }) => theme.dir === 'rtl' && 'Assistant'};
    }
  }
  .MuiAutocomplete-endAdornment {
    top: calc(50% - 19px);
  }
  // .icon-button {
  //   width: 20px;
  // }
  .mainClass {
    text-align: ${({ theme }) => (theme.dir === 'rtl' ? 'right' : 'left')};
  }
  .MuiOutlinedInput-root {
  }
  .mainClass2 {
    margin-bottom: 20px;
  }
  .MuiFormHelperText-root {
    margin: 2px auto;
    width: 100%;
  }
  .phone {
    // margin: -6px 0px 9px 0px !important;
  }
  .MuiInputBase-root {
    border: none;
    background-color: #fafcfe;
    border-radius: 10px;
    &:focus-within {
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1) !important;
      border-radius: 10px !important;
    }
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgb(230 230 230);
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: rgb(230 230 230);
  }
  .MuiOutlinedInput-input {
    padding: 0 14px;
    height: 48px;
    font-size: 16px;
  }
  .custom-input-label {
    text-align: ${({ theme }) => (theme.dir === 'rtl' ? 'right' : 'left')};
    margin-left: 0px;
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #696969 !important;
    margin: 0px 0px 8px 0px !important;
    transform: initial;
  }
  .text-input-root {
    min-height: 36px !important;
    padding-bottom: 6px;
  }
  .phone-number-label {
    text-align: ${({ theme }) => (theme.dir === 'rtl' ? 'right' : 'left')};
    margin-left: 0px;
    margin-top: 8px;
    font-size: 19px !important;
    color: #696969 !important;
    font-weight: 400 !important;
  }
  .react-tel-input .country-list .country {
    text-align: ${({ theme }) => (theme.dir === 'rtl' ? 'right' : 'left')};
  }
  .project-input-width {
    width: 357px;
    @media ${MOBILE_RESOLUTION} {
      width: 260px;
    }
    margin-top: 6px;
  }
  .address {
    display: flex;
    gap: 8px;
  }
  .MuiInputBase-input {
    // background: #fafcfe !important;
    // border-radius: 10px !important;
  }
  .custom-input-root:hover {
    border: none !important;
  }

  h4 {
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    color: #282929;
    margin: 0 auto;
    border-bottom: 1px solid #282929;
    width: ${({ theme }) => (theme.dir === 'rtl' ? '114px' : '161px')};
    font-family: ${({ theme }) => theme.dir === 'rtl' && 'Assistant'};
  }
  h5 {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #282929;
    margin: 16px auto 0;
    width: 117px;
    font-family: ${({ theme }) => theme.dir === 'rtl' && 'Assistant'};
  }
  h6 {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin: 4px auto 16px;
    color: #696969;
    width: 184px;
    font-family: ${({ theme }) => theme.dir === 'rtl' && 'Assistant'};
  }
  .project-address {
    margin-top: 0px;
    width: 228px !important;
  }
  .MuiAutocomplete-root {
    width: 357px;
    @media ${MOBILE_RESOLUTION} {
      width: 260px;
    }
  }
  .project-city {
    margin-top: 0px;
    width: 121px !important;
  }

  .upload-file .upload-btn-container .upload-floor-plan {
    font-size: 16px !important;
  }
  .upload-file {
    text-align: center;
    width: 357px;
    @media ${MOBILE_RESOLUTION} {
      width: 260px;
    }
    margin-top: 8px;
    .upload-btn-container {
      display: flex;
      justify-content: center;
      .upload-floor-plan {
        width: 52%;
        height: 48px;
        border-radius: 10px 0px 0px 10px;
        background: #fafcfe;
        border: 1px solid #e8eaeb;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #282929;
        padding: 13px 16px;
        @media ${MOBILE_RESOLUTION} {
          padding: 3px 0px !important;
        }
        ${({ theme }) =>
          theme.dir === 'rtl' &&
          css`
            border-radius: 0px 10px 10px 0px;
          `};
      }
      .upload-floor-btn {
        background: #fafcfe;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        border: 1px solid #fc5c42;
        border-radius: 0px 10px 10px 0px;
        color: #fc5c42;
        width: 169px;
        height: 48px;
        text-transform: none;
        svg {
          ${rtl` margin-right: 8px;`}
        }
        ${({ theme }) =>
          theme.dir === 'rtl' &&
          css`
            border-radius: 10px 0px 0px 10px;
          `};
      }
    }
    .text-container {
      margin-top: 8px;
      line-height: ${({ theme }) => (theme.dir === 'rtl' ? '18px' : '16px')};
      font-size: 14px;
      color: #3e3e3e;
    }
    .terms-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: ${({ theme }) => (theme.dir === 'rtl' ? '32px' : '35px')};
      line-height: 22px;
      font-size: 18px;
      color: #3e3e3e;
    }
  }
`;

const UploadButton = styled(Button)<{ isImageSelected: boolean }>`
  ${({ isImageSelected }) =>
    isImageSelected &&
    css`
      background: #2cdd73 !important;
      color: white !important;
      border: 1px solid #2cdd73 !important;
      width: 50%;
    `}
`;

const FinishButton = styled.button`
  margin-top: 24px;
  width: 134px;
  height: 48px;
  background: #fc5c42;
  border-radius: 15px;
  border: none;
  font-size: 18px;
  line-height: 21px;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
  :hover {
    background: #a50a0a;
  }
  :disabled {
    background: #d9d9d9;
    color: #ffffff;
    border: 1px solid #d9d9d9;
  }
`;

const Text = styled.p.attrs({ className: 'upload-plan-text' })`
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  margin: 6px auto 0;
  text-align: center;
  width: 226px;
  color: #fc5c42;
  @media (max-width: 800px) {
    font-weight: 500;
    font-size: 22px;
    margin-top: 32px;
  }
`;

const MainContainer = styled.div`
  width: 100%;
`;

const LeftContainer = styled.div`
  width: 357px;
  .MuiAutocomplete-listbox {
    ${({ theme }) => theme.utils.scrollbar};
  }
  @media ${MOBILE_RESOLUTION} {
    width: 260px;
    ${rtl`margin-right:32px;`}
  }
`;

const FormOption = styled.div`
  display: flex;
`;

const CoverPhoto = styled.div`
  text-align: ${({ theme }) => (theme.dir === 'rtl' ? 'right' : 'left')};
  margin-left: 0px;
  font-weight: 400 !important;
  font-size: 14px;
  font-family: ${({ theme }) => (theme.dir === 'rtl' ? 'Assistant' : 'Roboto')};
  color: #696969 !important;
  margin-top: 2px;
  margin-bottom: 8px;
`;

const CSVFile = styled.div`
  text-align: ${({ theme }) => (theme.dir === 'rtl' ? 'right' : 'left')};
  margin-left: 0px;
  font-weight: 400 !important;
  font-size: 14px;
  line-height: ${({ theme }) => (theme.dir === 'rtl' ? '20px' : '16px')};
  font-family: ${({ theme }) => (theme.dir === 'rtl' ? 'Assistant' : 'Roboto')};
  color: #696969 !important;
  margin-top: 8px;
`;

const AutocompleteComponent = styled(Autocomplete)`
  .MuiAutocomplete-inputRoot {
    .MuiAutocomplete-input {
      max-width: 265px !important;
    }
  }
  .MuiInputBase-root {
    height: 48px;
    padding-left: 60px;
    font-family: ${({ theme }) =>
      theme.dir === 'rtl' ? 'Assistant' : 'Roboto'};

    background: #ffffff;
    border: 1px solid #ff9287;
    box-sizing: border-box;
    border-radius: 10px;
    input {
      height: auto;
    }
    .MuiAutocomplete-input {
      padding: 3px !important;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
      opacity: 0.9;
    }
  }
  .MuiOutlinedInput-root {
    background: #fafcfe;
    border: none;
    padding-right: ${({ theme }) =>
      theme.dir === 'rtl' ? '9px !important' : '20px !important'};
  }
  .MuiAutocomplete-endAdornment {
    right: ${({ theme }) =>
      theme.dir === 'rtl' ? '282px !important' : '9px !important'};
  }
`;

export default UserForm;
