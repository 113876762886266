import NavigationList from 'components/@client/NavigationList/NavigationList';
import NoProduct from 'components/@client/NoProduct/NoProduct';
import { Message } from 'i18n';
import * as React from 'react';
import styled from 'styled-components';
import { Product, SearchResults } from 'types/products';
import { MenuItem } from 'components/Popper';
import { ClientRouter } from 'router/routes';
import SafeImg from 'components/SafeImg/SafeImg';
import { FormattedMessage, useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import ShowMoreButton from 'components/@client/Button/ShowMoreButton';
import Loader from 'components/Loader/Loader';
import { Link } from 'react-router-dom';
import { GetProductsDataResponsePayload } from 'store/types/user-management/suppliers.types';
import Divider from '@material-ui/core/Divider';
import CategoriesCard, {
  Card,
  Header,
  ImageContainer,
  Img,
} from '../products/components/CategoriesCard';
import { SubCategoryContainer } from '../products/components/SubCategoryPage';
import MobileProductCard from '../products/components/MobileProductCard';

interface Props {
  searchResults: SearchResults | null;
  searchName?: string;
  supplierId?: number;
  pageNo: number;
  setPageNo: () => void;
  showMoreBtn: boolean;
  productList: Product[];
  isLoading: boolean;
  productData?: GetProductsDataResponsePayload | null;
  handleSupplier: (data: string) => void;
}

const MobileSearch: React.FC<Props> = ({
  searchResults,
  supplierId,
  pageNo,
  setPageNo,
  showMoreBtn,
  productList,
  isLoading,
  searchName = '',
  productData,
  handleSupplier,
}) => {
  const intl = useIntl();
  const SubCategoryList: MenuItem[] = searchResults?.subCategories
    ? searchResults?.subCategories.results.map(data => {
        return {
          component: (
            <SubCategoryContainer>
              <span>
                <SafeImg src={data.thumbnail} alt={data.name} />
              </span>
              <p>{data.name}</p>
            </SubCategoryContainer>
          ),
          to: data?.category
            ? ClientRouter.PRODUCT_SUB_CATEGORY.replace(
                ':catName',
                data.category.name
              ).replace(':subCatName', data.name)
            : ClientRouter.PRODUCTS,
        };
      })
    : [];

  return (
    <Container>
      <Header>
        <FormattedMessage id={Message.PRODUCTS} />
        <CounterContainer>
          {`(${
            (supplierId
              ? productData?.count
              : searchResults?.products?.count) || 0
          })`}
        </CounterContainer>
      </Header>
      <Grid container>
        {productList.length
          ? productList.map(data => (
              <Grid item sm={4} xs={6} key={data.id}>
                <MobileProductCard product={data} />
              </Grid>
            ))
          : !isLoading && <NoProduct value={Message.NO_PRODUCT_AVAILABLE} />}
      </Grid>
      {!!showMoreBtn && !isLoading && (
        <div>
          <ShowMoreBtnDiv>
            <ShowMoreButton onClick={() => setPageNo()}>
              <FormattedMessage id={Message.SKINS_SHOW_MORE_BUTTON} />
            </ShowMoreButton>
          </ShowMoreBtnDiv>
        </div>
      )}
      {productList && isLoading && <Loader />}
      {!supplierId && !!searchResults?.subCategories?.count && (
        <>
          <Separator />
          <Header>
            <FormattedMessage id={Message.SUB_CATEGORIES} />
            <CounterContainer>
              {`(${searchResults?.subCategories?.count || 0})`}
            </CounterContainer>
          </Header>
          {searchResults?.subCategories.count ? (
            <NavigationList items={SubCategoryList} />
          ) : (
            <NoProduct value={Message.NO_SUB_CATEGORY} />
          )}
        </>
      )}
      {!supplierId && !!searchResults?.categories?.count && (
        <>
          <Separator />
          <CategoriesCard
            categories={searchResults?.categories.results ?? []}
            title={`${intl.formatMessage({
              id: Message.CATEGORIES,
            })}`}
            count={searchResults?.categories?.count}
          />
        </>
      )}
      {!supplierId && !!searchResults?.suppliers?.count && (
        <>
          <Separator />
          <Header>
            <FormattedMessage id={Message.BRANDS} />
            <CounterContainer>
              {`(${searchResults?.suppliers?.count || 0})`}
            </CounterContainer>
          </Header>
          {searchResults?.suppliers.count ? (
            searchResults?.suppliers.results.map(data => {
              return (
                <Grid item xs={6} sm={4} key={data.id}>
                  <Card onClick={() => handleSupplier(data.companyName)}>
                    <Link
                      to={`${ClientRouter.SEARCH_PAGE.replace(
                        ':searchName',
                        searchName
                      )}?supplierId=${data.id}`}
                    >
                      <ImageContainer>
                        <Img src={data.logo} alt={data.companyName} />
                      </ImageContainer>
                      <h4>{data.companyName}</h4>
                    </Link>
                  </Card>
                </Grid>
              );
            })
          ) : (
            <NoProduct value={Message.NO_RESULT} />
          )}
        </>
      )}
    </Container>
  );
};

export default MobileSearch;

const Container = styled.section`
  margin-top: 30px;
`;

const ShowMoreBtnDiv = styled.div`
  margin: auto;
  text-align: center;
  & > button {
    margin: 20px 0;
  }
`;

const CounterContainer = styled.span`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.black0};
`;

const Separator = styled(Divider)`
  margin: 10px 0;
`;
