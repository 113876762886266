import * as React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import styled, { css } from 'styled-components';
import rtl from 'styled-components-rtl';
import PhotoGrid from 'assets/@client/skins';
import {
  TABLET_RESOLUTION,
  MOBILE_TABLET_RESOLUTION,
  MOBILE_RESOLUTION,
  MIN_TABLET_RESOLUTION,
} from 'styles/sharedStyle';
import { FormattedMessage, useIntl } from 'react-intl';
import { Message } from 'i18n';

const Header = () => {
  const intl = useIntl();
  const isMobile = useMediaQuery(MOBILE_RESOLUTION);
  const isTablet = useMediaQuery(
    `(min-width: 500px) and ${MOBILE_TABLET_RESOLUTION}`
  );

  const src = React.useMemo(() => {
    if (isTablet) return PhotoGrid.Mobile;
    if (isMobile) return PhotoGrid.Mobile;
    return intl.locale === 'he' ? PhotoGrid.DesktopHebrew : PhotoGrid.Desktop;
  }, [isMobile, isTablet, intl]);
  return (
    <Container>
      {isMobile ? (
        <MobileImageContainer>
          <Title>
            <FormattedMessage
              id={Message.SKINS_HEADER_TITLE_MOBILE}
              values={{
                span: (chunks: string) => (
                  <span className="title-text">{chunks}</span>
                ),
                linebreak: <br />,
              }}
            />
          </Title>
          <SubTitle>
            <SubTitleMobile>
              <FormattedMessage
                id={Message.SKINS_HEADER_TEXT_FOR_MOBILE}
                values={{
                  span: (chunks: string) => (
                    <span className="title-text">{chunks}</span>
                  ),
                  linebreak: <br />,
                }}
              />
            </SubTitleMobile>
          </SubTitle>
        </MobileImageContainer>
      ) : (
        <MobileImageContainer>
          {/* <DrawLineWrapper> */}
          <Title>
            <FormattedMessage
              id={Message.SKINS_HEADER_TITLE}
              values={{
                span: (chunks: string) => (
                  <span className="title-text">{chunks}</span>
                ),
              }}
            />
            {intl.locale === 'he' && (
              <>
                &nbsp;
                <PatriciaText>
                  <FormattedMessage id={Message.APP_NAME} />
                </PatriciaText>
              </>
            )}
          </Title>
          {/* </DrawLineWrapper> */}

          {/* <DrawLineWrapper>
            <DrawLine />
          </DrawLineWrapper> */}

          <SubTitle>
            <FormattedMessage
              id={Message.SKINS_HEADER_SUB_TEXT_FOR_WEB}
              values={{
                a: (chunks: string) => (
                  <a href="/app/skins" className="title-text">
                    {chunks}
                  </a>
                ),
                linebreak: <br />,
              }}
            />
          </SubTitle>
          <TextContent>
            <FormattedMessage
              id={Message.SKINS_HEADER_TEXT_FOR_WEB}
              values={{
                span: (chunks: string) => (
                  <span className="title-text"> {chunks} </span>
                ),
                linebreak: <br />,
              }}
            />
          </TextContent>
          <FirstBgImage src={PhotoGrid.HeaderBg1} />
          <SecondBgImage src={PhotoGrid.HeaderBg2} />
          <ThirdBgImage src={PhotoGrid.HeaderBg3} />
          {/* <SecondBgImage src={PhotoGrid.HeaderBg2} />
          <ThirdBgImage src={PhotoGrid.HeaderBg3} /> */}
        </MobileImageContainer>
      )}

      <Image>
        <img src={src} alt="a Mosaic of various living room photos" />
      </Image>
      {isMobile && <BorderedDiv />}
      {!isMobile && (
        <>
          <FirstBgImage src={PhotoGrid.HeaderBg1} />
          <SecondBgImage src={PhotoGrid.HeaderBg2} />
          <ThirdBgImage src={PhotoGrid.HeaderBg3} />
        </>
      )}
    </Container>
  );
};

export default Header;

const Container = styled.section`
  @media ${MIN_TABLET_RESOLUTION} {
    margin-top: 64px;
  }
  width: 100%;
  display: flex;
  gap: 108px;
  margin-top: 64px;
  align-items: center;
  @media ${TABLET_RESOLUTION} {
    height: auto;
    display: block;
    background: #ffffff;
  }
  @media ${MOBILE_RESOLUTION} {
    margin-top: 0;
  }
  height: 523px;
  overflow: hidden;
  color: #424242;
  position: relative;
  text-align: center;
  background: linear-gradient(
    94.14deg,
    #f4f4f4 0.16%,
    #fafafa 51.85%,
    #eef1f3 100%
  );
`;
const MobileImageContainer = styled.div`
  z-index: 1;
  width: 40%;
  ${rtl`
  margin-left: 80px;
    `}

  @media ${TABLET_RESOLUTION} {
    width: 100%;
    padding: 0;
    margin-left: 0;
    padding-left: 0;
  }
  @media ${MOBILE_RESOLUTION} {
    margin: 64px auto auto;
  }
`;
const Title = styled.span`
  display: flex;
  font-family: ${({ theme }) => theme.dir !== 'rtl' && 'Roboto Condensed'};
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 52px;
  color: #282929;
  position: relative;
  z-index: 9;
  border-bottom: 2px solid #fc5c42;
  width: fit-content;
  padding-bottom: 16px;
  @media (min-width: 1200px) and (max-width: 1600px) {
    ${({ theme }) =>
      theme.dir === 'rtl' &&
      css`
        font-size: 30px;
      `};
  }

  @media ${TABLET_RESOLUTION} {
    display: block;
    font-size: 34px;
    line-height: 40px;
    text-align: center;
    color: #282929;
    margin: 20px auto;
    /* margin: 15px 10px 0 10px; */
    /* &:after {
      content: '';
      border: 2px solid #fc5c42;
      display: flex;
      margin: 0 60px;
      margin-top: 16px;
    } */
  }
  .title-text {
    ${rtl`
  padding: 0 10px 0 10px;
    `} @media ${TABLET_RESOLUTION} {
      padding: 0;
    }
    color: #fc5c42;
  }
  @media ${MOBILE_RESOLUTION} {
    ${rtl`
      margin: 24px auto 0;
  `}
    border-bottom: 2px solid #72d3fe;
    font-family: ${({ theme }) => theme.dir !== 'rtl' && 'Roboto'};
    line-height: 36px;
  }
`;

const PatriciaText = styled.div`
  font-weight: 500;
  font-family: 'roboto';
`;

const SubTitle = styled.span`
  display: block;
  margin-top: 16px;
  font-family: ${({ theme }) => theme.dir !== 'rtl' && 'Roboto Condensed'};
  font-style: normal;
  font-weight: ${({ theme }) => (theme.dir === 'rtl' ? '600' : '400')};
  font-size: 20px;
  line-height: 24px;
  z-index: 9;
  position: relative;
  ${rtl`
  text-align: left;
    `}

  color: #282929;
  .title-text {
    pointer-events: none;
    ${rtl`
    padding-left: 5px;
    `}
    color: #fc5c42;
    font-weight: ${({ theme }) => (theme.dir === 'rtl' ? '600' : '500')};
    text-decoration: auto;
    @media ${MOBILE_RESOLUTION} {
      font-weight: 600;
      margin-top: 3px;
    }
  }
  @media ${TABLET_RESOLUTION} {
    display: block;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #282929;
    margin: 15px 24px;
    .title-text {
      display: block;
    }
  }
  @media ${MOBILE_RESOLUTION} {
    margin: 16px 24px 0;
    font-family: ${({ theme }) => theme.dir !== 'rtl' && 'Roboto'};
  }
`;

const SubTitleMobile = styled.div`
  font-weight: 400;
  .title-text {
    font-weight: 600;
  }
`;

const TextContent = styled.span`
  display: grid;
  margin-top: 16px;
  font-family: ${({ theme }) => theme.dir !== 'rtl' && 'Roboto Condensed'};
  font-weight: ${({ theme }) => (theme.dir === 'rtl' ? '600' : '500')};
  font-style: normal;
  font-size: 20px;
  line-height: 23px;
  color: #282929;
  ${rtl`
  text-align: left;
  `}
  z-index: 9;
  position: relative;
  @media ${TABLET_RESOLUTION} {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #282929;
    margin: 15px 24px;
  }
  .title-text {
    color: #fc5c42;
  }
`;

const Image = styled.div`
  position: relative;
  z-index: 1;
  width: 60%;
  height: 100%;
  @media ${TABLET_RESOLUTION} {
    width: 100%;
    /* &:after {
      content: '';
      border: 2px solid #fc5c42;
      display: flex;
      margin: 0 60px;
      margin-top: 16px;
    } */
    /* border-width: 256px;
    border-bottom: 2px solid #72d3fe; */
  }
  img {
    width: 100%;
    height: 100%;
  }
  @media ${MOBILE_RESOLUTION} {
    margin-top: 24px;
  }
`;

const FirstBgImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
`;
const SecondBgImage = styled.img`
  position: absolute;
  top: 0;
  right: 0;
`;
const ThirdBgImage = styled.img`
  position: absolute;
  bottom: 0;
  right: 25%;
  left: 30%;
`;

const BorderedDiv = styled.div`
  height: 2px;
  background: #72d3fe;
  width: 256px;
  margin: 24px auto 0;
`;
// const FirstBgImage = styled.img`
//   position: absolute;
//   left: 0;
//   top: 0;
// `;
// const SecondBgImage = styled.img`
//   position: absolute;
//   top: 150px;
// `;
// const ThirdBgImage = styled.img`
//   position: absolute;
//   top: 0;
//   right: 0;
// `;
