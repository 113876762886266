import * as React from 'react';
import styled from 'styled-components';
import no_image_placeholder from 'assets/images/client-products/no_image.png';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';
import { useRouter } from 'hooks';
import { formatEnumToStr } from 'utils/string.utils';
import { isString } from 'utils/lodash.utils';
import { ClientRouter } from 'router/routes';
import type { Skin } from 'types/properties';
import Chip from 'components/Chip';

interface Props {
  skin: Skin;
  color: string;
  withSkinCategory?: boolean;
}

const SkinCard: React.FC<Props> = ({
  skin,
  color,
  withSkinCategory = false,
}) => {
  const { history } = useRouter();
  const isMobile = useMediaQuery(MOBILE_TABLET_RESOLUTION);
  const redirectToView = React.useCallback(
    () => history.push(ClientRouter.SKIN.replace(':id', skin.id.toString())),
    [history, skin]
  );
  const skinChips = React.useMemo(
    () =>
      [
        /* COMMENTED OUT BECAUSE OF PM PRIORITIES */
        // withSkinCategory && skin.skinCategory,
        // skin.color?.name && `color_${skin.color.name}`,
        // skin.priceRange === 'low' && skin.price > 0 && 'low_price',
        skin.isPopular && 'popular',
      ].filter(isString),
    [
      // skin.color?.name,
      skin.isPopular,
      // skin.price,
      // skin.priceRange,
      // skin.skinCategory,
      // withSkinCategory,
    ]
  ) as string[];

  return (
    <Card
      thumbnail={
        isMobile
          ? skin.mobileThumbnail ?? skin.desktopThumbnail
          : skin.desktopThumbnail
      }
      className="skin"
      onClick={redirectToView}
    >
      <ChipsContainer
        className="chips-container"
        customColor={skin.color?.color_hex}
      >
        {skinChips.map(chip => (
          <Chip
            key={chip}
            $color={color}
            label={
              chip.startsWith('color')
                ? chip.split('_')[1]
                : formatEnumToStr(chip)
            }
            className={`chip ${chip.split('_')[0]}`}
          />
        ))}
      </ChipsContainer>
      {/* <Description>
        <div className="bold">{skin.name}</div>
        <div>{skin.user?.name ?? NO_SKIN_NAME}</div>
        <div className="bold">
          <FormattedMessage id={Message.SKIN_CARD_PRICE} />:{' '}
          {skin.price ? (
            price
          ) : (
            <FormattedMessage id={Message.SKIN_CARD_PRICE_FREE} />
          )}
        </div>
      </Description> */}
    </Card>
  );
};

export default React.memo(SkinCard);

const Card = styled.div<{ thumbnail?: string }>`
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${({ thumbnail = no_image_placeholder }) => thumbnail});
  cursor: pointer;
  transition: all ${({ theme: { utils } }) => utils.normalTransition};
  &:hover {
    opacity: 0.9;
  }
`;
const ChipsContainer = styled.div<{ customColor?: string }>`
  margin: 10px;
  display: flex;
  flex-wrap: wrap;
  & .chip {
    font-size: 10px;
    color: #ffffff;
    font-weight: bold;
    &.color {
      background-color: ${({ customColor }) => customColor};
    }
    &.low {
      background-color: ${({ theme }) => theme.colors.primary.lightMobile};
    }
    &.popular {
      background-color: #41bbe2;
    }
  }
`;
