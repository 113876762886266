import styled from 'styled-components';

const Magazine3 = () => {
  return (
    <Root>
      <div>
        <p>
          <b>
            יצירות אמנות, תמונות וציורים המקשטים את הבית, הם אלמנטים אשר מוסיפים
            לו חמימות וייחודיות חסרי תחליף. בשנים האחרונות רכישת תמונות אונליין
            הופכת לנפוצה יותר ויותר. שלא במפתיע, רבים בוחרים לקנות תמונות
            וציורים אונליין מכיוון וזו הדרך הטובה והזמינה ביותר להתרשם מהמגוון
            ולהוסיף צבע למרחב הביתי, בקלות ובנוחות. על מה כדאי לשים דגש בקניית
            תמונות באינטרנט וכיצד תוכלו לראות את התמונה בבית שלכם ?
          </b>
        </p>
      </div>
      <div>
        <p>
          ממוצרי חשמל, דרך תאורה ודקורציה ועד לקניית רהיטים ותמונות אונליין,
          עידן הדיגיטל משנה תדיר את הרגלי הצריכה שלנו והופך את אורח החיים שלנו
          למסוגנן ובו בעת פשוט יותר, הכל בלחיצת כפתור אחת. המסחר האלקטרוני
          ואמצעי הדיגיטל לא רק מספקים לנו , הלקוחות, חוויית רכישה חדשנית, אלא גם
          מנגישים עוד ועוד מוצרים, שירותים ואפילו ציורים ופריטי אומנות למעגלים
          נוספים של רוכשים. היכן קונים ציורים אונליין ומה חשוב לדעת?
          <br />
          <b>
            צוות המומחים של פטרישיה, סייעת וירטואלית חדשנית המספקת חוויה
            דיגיטלית ייחודית לעיצוב הבית , עונים על כל השאלות החשובות
          </b>
        </p>
      </div>
      <div>
        <p>
          <b>אומנות במרחק קליק - כך רוכשים תמונות וציורים אונליין</b>
          <br />
          מעצבים מחדש את הדירה? בוחרים פריטי ריהוט ותמונות לביתכם החדש? ודאי
          תשמחו לגלות כי הימים בהם נאלצנו לסייר בין גלריות, לכתת רגליים בין
          אולמות תצוגה או לחפש ציורים בתערוכות, מזמן מאחורינו. בעשורים האחרונים
          תמונות, ציורים ואינספור פריטי אומנות הפכו לזמינים ונגישים וכיום ניתן
          לרכוש אותם בנוחות ביתכם. בקליק אחד, תוכלו להתרשם, לבחור ולרכוש מבחר
          צבעוני של תמונות וציורים אונליין, המוצעים בסגנונות וקווים עיצובים
          שונים.
        </p>
      </div>
      <div>
        <p>
          <b>
            מבחר, ממשק ידידותי ורכישה נוחה - כל היתרונות ברכישה של תמונות
            אונליין
          </b>
          <br />
          קנייה מקוונת מהווה חוויית רכישה נוחה וייחודית המספקת יתרונות רבים :
          <br />
          <b>וואן סטופ שופ - </b> מרחב עיצובי המאגד בתוכו מבחר מגוון ועשיר של
          מעצבים ומותגים המציעים פריטי ריהוט, עיצוב, אמנות ויצירה, הכל תחת קורת
          גג דיגיטלית נפלאה אחת.
          <br />
          <b>איכות ואמינות בקניית תמונות אונליין - </b>רכישה מגורם מקצועי ונישתי
          בתחום העיצוב, תבטיח קנייה אותנטית, מקוריות ואיכות.
          <br />
          <b>חסכון בעלויות ופערי תיווך - </b> פלטפורמת האונליין מאפשרת לחסוך
          בעלויות פיזיות ופערי תיווך ולהציע ללקוחות תמחור הוגן ומנעד רחב של
          מחירים, לצד איכות וייחודיות .
          <br />
          <b>ממשק דיגיטלי וידידותי למשתמש - </b> הממשק הדיגיטלי מאפשר ללקוחות
          לצפות בתמונות וציורים אונליין, להתרשם מהמגוון תחת מותג מסוים או לבחור
          בין תמונות שונות של מספר אומנים. כאמור, שלא כמו בעבר, התהליך הוא
          וירטואלי ויכול להתבצע מהמחשב, הסלולר והטאבלט, מכל מקום ובכל עת, בנוחות
          שלכם. <br />
          <b>תהליך רכישה מאובטח ואמין - </b>
          אתרים ייעודיים ומקצועיים מספקים חווית רכישה נוחה ומהירה המאפשרת
          ללקוחות לבחור ולבצע תשלום בקלות וללא חשש, תחת רמות האבטחה הגבוהות
          ביותר.
        </p>
      </div>
      <div>
        <p>
          <b>הדמיה אחת שווה אלף מילים - כך נוכל לוודא כי התמונה מתאימה לבית</b>
          <br />
          לעיתים, בעת רכישת תמונות או ציורים , אנחנו מתרשמים מתמונה מיוחדת, או
          ציור יוצא דופן הנראה לנו מתאים כמו כפפה ליד עבור הסלון הביתי. אך אנחנו
          מגיעים הביתה ומופתעים לגלות כי התמונה גדולה מדי ביחס לסלון, או לחלופין
          אינה מתאימה, כמו שחשבנו, לעיצוב ולשאר פריטי הריהוט. על כן, אחת השאלות
          המרכזיות העולות היא כיצד נוכל לראות ולהתרשם מהתמונה בבית ולהבטיח כי
          אכן היא מתאימה לעיצוב ולסגנון הייחודי לנו? הפתרון פשוט. באמצעות שימוש
          בתוכנת הדמיה דיגיטלית, נוכל לקבל ויז'ואל תלת ממדי של הסלון, חדר השינה
          או חדרי הילדים ולהתרשם בדיוק כיצד יראה הציור בבית.
        </p>
      </div>
      <div>
        <p>
          <b>פטרישיה - חוויה וירטואלית מושלמת בהתאמה אישית</b>
          <br />
          נעים להכיר, פטרישיה, העוזרת הווירטואלית האישית שלכם לעיצוב הבית ובחירת
          פריטי עיצוב ואמנות. אני שמחה להזמין אתכם לחוויה וירטואלית חדשנית
          המציעה לכם מרחב עיצובי מקוון המותאם אישית לחלל הביתי שלכם ומאפשר לכם
          עיצוב, רכישה והלבשת הבית, הכל בקלות ובנוחות, בקליק אחד.
          <br />
          רוכשים ציורים אונליין? על מנת שתוכלו לבחור בקלות ובנוחות, ריכזתי
          עבורכם, תחת מטריה אחת, מגוון של ציורים, תמונות ופריטי אומנות מתוך מבחר
          מעצבים ומותגי בוטיק. על מנת לסייע לכם בבחירה, אלווה אתכם לאורך כל
          התהליך ואנחה אתכם בבחירות מדויקות ותואמות צרכים. תוכנת ההדמיה והסיור
          הווירטואלי שלי, תאפשר לכם לראות כיצד נראית התמונה בבית שלכם.
          <b>אהבתם? קליק ואתם בדרך.</b>
        </p>
      </div>
    </Root>
  );
};

export default Magazine3;

const Root = styled.div``;
