import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import styled from 'styled-components';
import NavButton from 'components/@client/NavButton';
import { useAuthentication, useRecentProperty, useRouter } from 'hooks';
import {
  ClientRouter,
  ManagementRouter,
  SupplierDashBoardRoutes,
} from 'router/routes';
import useProperties from 'pages/appClient/properties/useProperties';
import {
  MOBILE_TABLET_MIN_RESOLUTION,
  MOBILE_TABLET_RESOLUTION,
} from 'styles/sharedStyle';
import { useMediaQuery } from '@mui/material';
import { useIntl } from 'react-intl';
import { Type } from 'constants/config';
import tabs from './client.config';

const MobileNavBar: React.FC = () => {
  const classes = useStyles();
  const { pathname } = useRouter();
  const { token, type, supplierId: ids, seller_supplier } = useAuthentication();
  const { properties, callFetch: refreshProperty } = useProperties(true);

  React.useEffect(() => {
    if (token) {
      refreshProperty();
    }
  }, [token, refreshProperty]);
  const intl = useIntl();
  const { recentProperty } = useRecentProperty();
  const items = React.useMemo(
    () =>
      tabs(pathname, !!token, recentProperty, ids, seller_supplier, type, intl),
    [token, pathname, recentProperty, type, ids, seller_supplier, intl]
  );
  const isTablet = useMediaQuery(
    `${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION}`
  );
  const finalItemsRight = React.useMemo(() => {
    let itemsRight = items.right;
    if (type !== 'SUPPLIER' && type !== Type.TEAM_PATRICIA) {
      itemsRight = itemsRight.filter(el => el.icon !== 'supplier-shop');
    } else if (type === 'SUPPLIER') {
      itemsRight = itemsRight.filter(
        el => !el.to.includes(ManagementRouter.APP)
      );
    } else {
      itemsRight = itemsRight.filter(
        el => !el.to.includes(SupplierDashBoardRoutes.INFO)
      );
    }

    if (!isTablet) {
      itemsRight = itemsRight.filter(el => el.icon !== 'supplier-shop');
    }
    return itemsRight;
  }, [isTablet, items.right, type]);
  if (
    pathname.includes('/onboarding') ||
    pathname.includes('/auth') ||
    pathname.includes('/tour') ||
    pathname.includes('/terms-of-use') ||
    pathname.includes('/supplier')
  )
    return null;
  return (
    <>
      <MobileAppBar position="fixed" component="nav" className={classes.appBar}>
        <Toolbar component="ul">
          {items.left.map(item => (
            <NavButton
              key={item.to}
              item={{
                ...item,
                to: token ? item.to : ClientRouter.AUTH,
              }}
              isMobile
              showBadge={item.showBadge}
              propertyBadgeCount={token && properties?.count}
            />
          ))}

          <NavButton item={items.main} isMobile />
          <CircleClip />
          <li className={classes.grow} />

          {finalItemsRight.map(item => (
            <NavButton
              key={item.to}
              showBadge={item.showBadge}
              item={{
                ...item,
                to: token || item.public === true ? item.to : ClientRouter.AUTH,
              }}
              isMobile
            />
          ))}
        </Toolbar>
      </MobileAppBar>
    </>
  );
};

export default MobileNavBar;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      top: 'auto',
      bottom: 0,
      height: 72,
      boxShadow: '0px 1px 47px rgba(0, 0, 0, 0.09)',
      borderRadius: '40px 40px 0 0',
      '& .MuiToolbar-root': {
        margin: 0,
        height: '100%',
        padding: '0 12px',
        listStyle: 'none',
      },
      background: 'black',
    },
    grow: {
      flexGrow: 1,
    },
  })
);

const circleClipWidth = 39.5;

const CircleClip = styled.li`
  position: absolute;
  z-index: 3;
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
  width: ${circleClipWidth * 2}px;
  height: ${circleClipWidth}px;
  border-bottom-left-radius: ${circleClipWidth}px;
  border-bottom-right-radius: ${circleClipWidth}px;
  border-bottom: 0;
  background: black;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.09);
  color: black;
`;
const MobileAppBar = styled(AppBar)`
  z-index: 999999;
`;
