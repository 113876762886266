import { Button, Modal, Paper, useMediaQuery } from '@mui/material';
import React from 'react';
import { Field } from 'components/forms';
import { useFetch, useAuthentication } from 'hooks';
import rtl from 'styled-components-rtl';
import styled from 'styled-components';
import {
  MOBILE_RESOLUTION,
  MOBILE_TABLET_MIN_RESOLUTION,
} from 'styles/sharedStyle';

import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import { METHODS } from 'api/client';
import Loader from 'components/Loader/Loader';
import { AUTH_CONFIG_ROOT_KEY } from 'constants/localstorage.constants';

interface Props {
  open: boolean;
  onClose: () => void;
  onRefresh?: VoidFunction;
}

const DeleteAccount: React.FC<Props> = ({ open, onClose, onRefresh }) => {
  const { firstName, email, id } = useAuthentication();
  const [entertedEmail, setEnteredEmail] = React.useState('');
  const [isFinalStep, setIsFinalStep] = React.useState(false);
  const isMobile = useMediaQuery(MOBILE_RESOLUTION);

  const { callFetch, isLoading } = useFetch<{}>({
    initialUrl: `/api/users/${id}/`,
    skipOnStart: true,
    config: {
      method: METHODS.DELETE,
    },
    onSuccess: () => {
      localStorage.removeItem(AUTH_CONFIG_ROOT_KEY);
      window.location.reload();
    },
  });

  return (
    <ModalContainer open={open} onClose={onClose}>
      <PaperContainer className={isFinalStep ? 'small-container' : ''}>
        {!isFinalStep ? (
          <div className="modal-body">
            <h2 className="delete-account-heading" style={{ marginTop: '0' }}>
              <FormattedMessage id={Message.USER_DELETE_ACCOUNT_BUTTON} />
            </h2>
            <h4
              className="user-name-heading"
              style={{ marginTop: isMobile ? '0px' : '16px' }}
            >
              <FormattedMessage
                id={Message.USER_DETAILS}
                values={{ displayName: firstName }}
              />
            </h4>
            <p className="user-name-subtitle" style={{ marginTop: '8px' }}>
              <FormattedMessage
                id={Message.USER_DELETE_ACCOUNT_ENTER_EMAIL_SUBTITLE}
                values={{ email }}
              />
            </p>
            <Field
              className="email-field"
              width={isMobile ? '90%' : '100%'}
              label={{
                title: (
                  <FormattedMessage
                    id={Message.USER_DELETE_ACCOUNT_EMAIL_ADDRESS}
                  />
                ),
              }}
              name="email"
              type="email"
              onChange={val => setEnteredEmail(val.target.value)}
              style={{
                marginTop: isMobile ? '16px' : '24px',
              }}
            />
            <div
              className="button-container"
              style={{ marginTop: isMobile ? '16px' : '19px' }}
            >
              <span onClick={onClose} style={{ textDecoration: 'underline' }}>
                <FormattedMessage
                  id={Message.USER_DELETE_ACCOUNT_CANCEL_BUTTON}
                />
              </span>
              <CustomNextButton
                className="next-btn"
                disabled={email !== entertedEmail}
                onClick={() => setIsFinalStep(true)}
              >
                <FormattedMessage
                  id={Message.USER_DELETE_ACCOUNT_NEXT_BUTTON}
                />
              </CustomNextButton>
            </div>
          </div>
        ) : (
          <>
            <h2 className="delete-account-heading" style={{ marginTop: '0' }}>
              <FormattedMessage id={Message.USER_DELETE_ACCOUNT_BUTTON} />{' '}
            </h2>
            <p className="user-name-subtitle" style={{ marginTop: '24px' }}>
              <FormattedMessage
                id={Message.USER_DELETE_ACCOUNT_SECOND_MODAL_TITLE}
              />
            </p>
            <p className="confirm-subtitle" style={{ marginTop: '8px' }}>
              <FormattedMessage
                id={Message.USER_DELETE_ACCOUNT_SECOND_MODAL_SUBTITLE}
              />
            </p>
            <div className="button-container" style={{ marginTop: '24px' }}>
              <Button
                className="next-button"
                style={{
                  width: isMobile ? '82px' : '104px',
                  height: isMobile ? '40px' : '48px',
                  background: email !== entertedEmail ? '#D9D9D9' : '#FC5C42',
                  borderRadius: '10px',
                  border:
                    email !== entertedEmail
                      ? '1px solid #D9D9D9'
                      : '1px solid #FC5C42',

                  color: 'white',
                }}
                onClick={() => {
                  setIsFinalStep(false);
                  setEnteredEmail('');
                  onClose();
                }}
              >
                <FormattedMessage
                  id={Message.USER_DELETE_ACCOUNT_CANCEL_BUTTON}
                />
              </Button>
              <DeleteButton
                className="next-button"
                style={{}}
                onClick={() => {
                  callFetch({ url: `/api/users/${id}/` });
                }}
              >
                {isLoading ? (
                  <Loader />
                ) : (
                  <FormattedMessage
                    id={Message.USER_DELETE_ACCOUNT_DELETE_BUTTON}
                  />
                )}
              </DeleteButton>
            </div>
          </>
        )}
      </PaperContainer>
    </ModalContainer>
  );
};

export default DeleteAccount;

const DeleteButton = styled(Button)`
  width: 104px;
  height: 48px;
  background: white !important;
  border-radius: 10px !important;
  border: 1px solid #d9d9d9 !important;
  ${rtl`margin-left:24px !important;`}
  color: #696969 !important;
  @media ${MOBILE_RESOLUTION} {
    width: 94px;
    height: 40px;
  }
  /* width: isMobile ? '94px' : '104px',
                  height: isMobile ? '40px' : '48px',
                  background: 'white',
                  borderRadius: '10px',
                  border: '1px solid #D9D9D9',
                  marginLeft: '24px',
                  color: '#696969', */
`;

const ModalContainer = styled(Modal)`
  .small-container {
    height: unset;
    padding: 24px;
  }
  margin: auto;
  @media ${MOBILE_RESOLUTION} {
    background: #ffffff;
    border-radius: 15px;
    height: 267px;
  }
`;

const PaperContainer = styled(Paper)`
  border-radius: 15px !important;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} {
    width: 496px;
    height: auto;
    margin: auto;
    margin-top: 20%;
    text-align: center;
    padding: 24px 48px;
    border-radius: 15px;
  }
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and (max-width: 1200px) {
    width: 496px;
    height: auto;
    margin: auto;
    margin-top: 30%;
    text-align: center;
    padding: 24px 48px;
    border-radius: 15px;
  }
  .modal-body {
  }
  .delete-account-heading {
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    align-items: center;
    text-align: center;
  }
  .user-name-heading {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    /* margin-top: -8px; */
    margin: 0;
    ${rtl`
      margin-top: 16px;
    `}
  }
  .user-name-subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;

    color: #3e3e3e;
    margin-bottom: 0px;
  }
  .confirm-subtitle {
    @media ${MOBILE_RESOLUTION} {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      /* margin: 8px 16px; */
      ${rtl`
      margin: 8px 16px;
    `}
      /* margin-left: 16px; */
      ${rtl`
      margin-left: 16px;
    `}
      /* margin-bottom: 0px; */
      ${rtl`
      margin-bottom: 0px;
    `}
      color: #696969;
    }
  }
  .email-field {
    width: 279px;
    margin-top: 24px;
    label {
      font-size: 20px;
      ${rtl`text-align:left;`}
    }
    input {
      height: 30px;
    }
    @media ${MOBILE_RESOLUTION} {
      /* margin: 16px 16px; */
      label {
        font-size: 14px;
      }
      ${rtl`
      margin: 16px 16px;
    `}
    }
  }
  @media ${MOBILE_RESOLUTION} {
    font-size: 16px;
    width: unset;
    max-width: 95vw;
    height: 288px;
    margin: auto;
  }
  .button-container {
    @media ${MOBILE_RESOLUTION} {
      ${rtl`
      margin-left: 8px;
    `}
      display: flex;
      justify-content: center;
      align-items: center;
    }

    /* .next-btn{
        width: isMobile ? '82px' : '86px',
                  height: isMobile ? '40px' : '48px',
                  background: email !== entertedEmail ? '#D9D9D9' : '#FC5C42',
                  borderRadius: '10px',
                  border:
                    email !== entertedEmail
                      ? '1px solid #D9D9D9'
                      : '1px solid #FC5C42',
                  marginLeft: '24px',
                  color: 'white',
    } */
  }
  .cancel-button {
    @media ${MOBILE_RESOLUTION} {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      align-items: center;
      text-decoration-line: underline;
      color: #696969;
    }
  }
  .next-button {
    @media ${MOBILE_RESOLUTION} {
      width: 82px;
      height: 40px;
      color: #d9d9d9;
    }
  }
`;

const CustomNextButton = styled(Button)`
  border-radius: 10px !important;
  ${rtl`margin-left:24px !important;`}
  color:white !important;
  height: 48px;
  width: 86px;
  background: #fc5c42 !important;
  border: 1px solid #fc5c42 !important;
  @media ${MOBILE_RESOLUTION} {
    width: 82px;
    height: 40px;
  }
  &:disabled {
    background: #d9d9d9 !important;
    border: 1px solid #d9d9d9 !important;
  }
`;
