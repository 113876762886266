import * as React from 'react';
import { Dialog } from '@material-ui/core';
import styled from 'styled-components';
import Button from 'components/@client/Button/Button';
import { Link } from 'react-router-dom';
import { Message } from 'i18n';
import { FormattedMessage } from 'react-intl';
import {
  MOBILE_RESOLUTION,
  MOBILE_TABLET_MIN_RESOLUTION,
  MOBILE_TABLET_RESOLUTION,
} from 'styles/sharedStyle';

const SkipConfirmationDialog: React.FC<{
  isOpen: boolean;
  toggle: VoidFunction;
}> = ({ isOpen, toggle }) => {
  return (
    <CustomDiv open={isOpen}>
      <div className="title">
        <FormattedMessage id={Message.ONBOARDING_LEAVE_CINFIRM_TITLE} />
      </div>
      <div className="subtitle1">
        <FormattedMessage id={Message.SUPPLIER_SKIP_CONFIRMATION_SUBTITLE1} />
      </div>
      <div className="subtitle2">
        <FormattedMessage id={Message.SUPPLIER_SKIP_CONFIRMATION_SUBTITLE2} />
      </div>
      <Button className="primary-btn" onClick={toggle}>
        <FormattedMessage
          id={Message.OONBOARDING_LEAVE_CONFIRMATION_STAY_BUTTON}
        />
      </Button>
      <Link to="/app/supplier-congratulations/skip" className="redirect-link">
        <FormattedMessage
          id={Message.OONBOARDING_LEAVE_CONFIRMATION_LEAVE_BUTTON}
        />
      </Link>
    </CustomDiv>
  );
};

const CustomDiv = styled(Dialog)`
  font-family: 'Roboto Condensed';
  .MuiPaper-root {
    width: 537px;
    height: 232px;
    background: #ffffff;
    /* Popup */

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    @media ${MOBILE_RESOLUTION} {
      width: 327px;
      margin: 0;
      height: auto;
    }
    @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
      width: 425px;
      height: 228px;
      padding: 12px;
    }
  }
  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    margin: auto;
    margin-top: 24px;
    margin-bottom: 0;
    color: #fc5c42;
    @media ${MOBILE_RESOLUTION} {
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
    }
    @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
      margin-top: 0;
    }
  }
  .subtitle1 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    margin: auto;
    margin-top: 16px;
    margin-bottom: 0;
    color: #282929;
    @media ${MOBILE_RESOLUTION} {
      margin-top: 8px;
      display: flex;
      align-items: center;
      text-align: center;
    }
    @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
      text-align: center;
    }
  }
  .subtitle2 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 0;
    color: #696969;
    @media ${MOBILE_RESOLUTION} {
      display: flex;
      align-items: center;
      text-align: center;
    }
  }
  .primary-btn {
    background: #fc5c42;
    margin-top: 16px;
    border: 1px solid #fc5c42;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 24px;
    gap: 10px;
    @media ${MOBILE_RESOLUTION} {
      width: 156px;
      height: 40px;
    }
    @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
      width: 156px;
      height: 48px;
      padding: 13.5px 20px;
      span {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
      }
    }
  }
  .redirect-link {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-decoration-line: underline;

    /* Third text level */
    margin: 16px auto 0;
    color: #696969;
    @media ${MOBILE_RESOLUTION} {
      margin: 8px auto 24px;
    }
  }
`;

export default SkipConfirmationDialog;
