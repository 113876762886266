import * as React from 'react';
import { Message } from 'i18n';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { FormProvider } from 'react-hook-form';
import styled, { css } from 'styled-components';
import rtl from 'styled-components-rtl';
import { Button, Checkbox } from '@mui/material';
import TextInput from 'components/@client/form-components/TextInput';
import { FormattedMessage, useIntl } from 'react-intl';
import { ClientInformationType, Setter } from 'types/common.types';
import { ReactComponent as ContentCopy } from 'assets/socialIcons/Copy.svg';
import { MOBILE_RESOLUTION } from 'styles/sharedStyle';
import { Label, PhoneField } from 'components/forms';
import { Link } from 'react-router-dom';
import useAddCoOwner from 'hooks/useAddCoOwner';

const AddCoOwner: React.FC<{
  setIsOpenCoOwnerForm: Setter<boolean>;
  propertyId: string;
  co_owners: number[] | undefined;
}> = ({ setIsOpenCoOwnerForm, propertyId, co_owners }) => {
  const intl = useIntl();
  const [isCopy, setIsCopy] = React.useState<boolean>(false);
  const [isTncAgreed, setIsTncAgreed] = React.useState<boolean>(false);
  const {
    methods,
    onUpdatePassword,
    handleSave,
    isPassword,
    storePass,
  } = useAddCoOwner(() => setIsOpenCoOwnerForm(false), propertyId, co_owners);

  const { register, control, handleSubmit, formState } = methods;
  const { isValid, isDirty } = formState;

  React.useEffect(() => {
    if (isPassword) {
      onUpdatePassword();
    }
  }, [isPassword, onUpdatePassword]);

  const onCopyText = React.useCallback((text: string) => {
    navigator.clipboard.writeText(text);
    setIsCopy(true);
  }, []);

  const onSubmit = React.useCallback(
    (payload: ClientInformationType) => {
      handleSave(payload);
    },
    [handleSave]
  );

  return (
    <MainContainer>
      <Icon onClick={() => setIsOpenCoOwnerForm(false)}>
        <CloseIcon />
      </Icon>
      <DetailsContainer>
        <FormTitle>
          <FormattedMessage id={Message.SHARE_PROPERTY_WITH_CUSTOMER} />
        </FormTitle>
        <h5>
          <FormattedMessage id={Message.PROPERTY_MODAL_CUSTOMER_DETAILS} />
        </h5>
        <h6>
          <FormattedMessage id={Message.ALL_DETAILS_MANDATORY} />
        </h6>
        <FormProvider {...methods}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <TextInput
                name="firstName"
                control={control}
                placeholder={intl.formatMessage({ id: Message.JOHN })}
                label={intl.formatMessage({ id: Message.FIRST_NAME })}
                required
              />
            </div>
            <div className="form-group">
              <TextInput
                name="lastName"
                control={control}
                placeholder={intl.formatMessage({ id: Message.DOE })}
                label={intl.formatMessage({ id: Message.LAST_NAME })}
                required
              />
            </div>
            <div className="form-group email-wrap form-email">
              <TextInput
                name="email"
                dir="ltr"
                control={control}
                placeholder={intl.formatMessage({
                  id: Message.SUPPLIER_LOGIN_EMAIL_PLACEHOLDERS,
                })}
                label={
                  intl.locale === 'he'
                    ? intl.formatMessage({
                        id: Message.USER_DELETE_ACCOUNT_EMAIL_ADDRESS,
                      })
                    : intl.formatMessage({
                        id: Message.EMAIL_TITLE,
                      })
                }
                type="email"
                required
              />
            </div>
            <div className="form-group email-wrap phone">
              <PhoneNumber
                name="phone"
                label={{
                  title: intl.formatMessage({
                    id: Message.PHONE_NUMBER,
                  }),
                }}
                prefix="+"
              />
            </div>
            {isPassword && (
              <PasswordContainer>
                <Label
                  label={{
                    title: <FormattedMessage id={Message.CLIENT_PASSWORD} />,
                  }}
                  htmlFor="clientpassword"
                />
                <input
                  type="hidden"
                  {...register('password')}
                  placeholder="password"
                />
                <CopyButtonWrapper>
                  <GeneratePassword>{storePass}</GeneratePassword>
                  <CopyButton
                    isCopy={isCopy}
                    onClick={() => onCopyText(storePass ?? '')}
                  >
                    <ContentCopy />
                  </CopyButton>
                </CopyButtonWrapper>
              </PasswordContainer>
            )}
            <ParentTermsDiv>
              <StyledCheckbox
                checked={isTncAgreed}
                onChange={() => setIsTncAgreed(prev => !prev)}
              />
              <FormattedMessage
                id={Message.LANDING_PAGE_ACCEPT_TERMS}
                values={{
                  link: (chunks: string) => (
                    <>
                      &nbsp;
                      <StyledLink to="/app/privacy-policy">{chunks}</StyledLink>
                    </>
                  ),
                }}
              />
            </ParentTermsDiv>
            <AddPropertButton
              className="modal-action-button"
              // isLoading={isLoading}
              disabled={!isValid || !isDirty || !isTncAgreed}
              type="submit"
            >
              <FormattedMessage id={Message.FINISH_AND_SEND} />
            </AddPropertButton>
          </Form>
        </FormProvider>
      </DetailsContainer>
    </MainContainer>
  );
};

export default AddCoOwner;

const MainContainer = styled.div`
  padding: 24px 32px;
  width: 402px;

  @media ${MOBILE_RESOLUTION} {
    width: 100%;
  }
  ${({ theme }) => theme.utils.scrollbar}
  .loader {
    span {
      height: 30px !important;
      width: 30px !important;
    }
  }  
  h5 {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #282929;
    margin: 0 auto;
    margin-top:16px;
    width: ${({ theme }) => (theme.dir === 'rtl' ? '81px' : '154px')};
    font-family: ${({ theme }) => theme.dir === 'rtl' && 'Assistant'};
  }
  h6 {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin: 8px auto 16px;
    color: #3E3E3E;
    width: 184px;
    text-align: center;
    font-family: ${({ theme }) => theme.dir === 'rtl' && 'Assistant'};
  }
  .react-tel-input .form-control {
    font-family: ${({ theme }) =>
      theme.dir === 'rtl' ? 'Assistant' : 'Roboto'};
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
  label {
    font-size: 18px !important;
    line-height: 20px;
    color: #696969 !important;
    margin-left: 0;
    font-weight: 400 !important;
  }
  .text-input-root {
    width:256px;
    margin:0 auto;
    min-height: 0;
    padding-bottom: 0px;
  }
  .MuiInputBase-input {
    background: #fafcfe;
  }
  .custom-input-root:hover {
    border: none !important;
  }
  .MuiOutlinedInput-input {
    padding: 0 14px;
    height: 48px;
  }
  .phone {
    width:256px;
    margin:0 auto;
  }
  .country-list {
    width: 254px;
}
  .phone-input {
    background: #fafcfe;
    &:hover{
      border-color: #e6e6e6 !important;
    }
  }
  
  .react-tel-input {
    background: #fafcfe;
    &:hover{
      border-color: #e6e6e6 !important;
    }
  }

  .eqXFbA:hover .form-control, .eqXFbA:hover .flag-dropdown {
    border-color: #e6e6e6;
  }

  .iYMShv:hover .form-control, .iYMShv:hover .flag-dropdown {
    border-color: #e6e6e6;
}

  .MuiInputBase-root {
    &:focus-within {
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1); !important;
      border-radius: 10px !important;
    }
  }
  .upload-btn-container {
    display: flex;
    justify-content: center;
    margin-top: 8px;
    .upload-floor-plan {
      border-radius: 10px 0px 0px 10px;
      background: #fafcfe;
      border: 1px solid #e8eaeb;
      font-size: 16px;
      line-height: 20px;
      color: #696969;
      padding: 13px 16px;
      height: 48px;
      width: 188px;
      position: relative;
      z-index: 1;
      text-align: center;
      ${({ theme }) =>
        theme.dir === 'rtl' &&
        css`
          border-radius: 0px 10px 10px 0px;
        `};
      @media ${MOBILE_RESOLUTION} {
        font-size: 14px;
      }
    }
    .upload-floor-btn {
      background: #fafcfe;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      border: 1px solid #fc5c42;
      border-radius: 0px 10px 10px 0px;
      color: #fc5c42;
      width: 169px;
      height: 48px;
      text-transform: none;
      svg {
        ${rtl` margin-right: 8px;`}
      }
      ${({ theme }) =>
        theme.dir === 'rtl' &&
        css`
          border-radius: 10px 0px 0px 10px;
        `};
      @media ${MOBILE_RESOLUTION} {
        font-size: 16px;
      }
    }
  }
`;

const PhoneNumber = styled(PhoneField)`
  &:hover {
    .flag-dropdown,
    .form-control {
      border-color: #e6e6e6;
    }
  }
`;

const PasswordContainer = styled.div`
  display: flex;
  align-items: baseline;
  label {
    font-weight: 500 !important;
    font-size: 18px;
    line-height: 22px;
    color: #282929 !important;
  }
`;

const CopyButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  gap: 8px;
`;

const CopyButton = styled.span<{
  isCopy: boolean;
}>`
  color: ${({ theme }) => theme.colors.primary.lightMobile};
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
  svg {
    color: ${({ theme }) => theme.colors.primary.contrastText};
    fill: #282929;
    width: 24px;
    height: 24px;
    ${({ isCopy }) =>
      isCopy &&
      css`
        background: #fc5c42;
        border-radius: 50%;
        fill: #ffffff;
      `}
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  gap: 14px;
  display: flex;
  align-items: center;
`;

const AddPropertButton = styled(Button)`
  && {
    width: ${({ theme }) => (theme.dir === 'rtl' ? ' 127px' : '175px')};
    height: 48px;
    background: #fc5c42;
    border: 1px solid #fc5c42;
    border-radius: 15px;
    color: white;
    text-transform: none;
    font-size: 18px;
    .MuiButton-label {
      font-family: ${({ theme }) =>
        theme.dir === 'rtl' ? ' Assistant' : 'inherit'};
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: rgba(255, 255, 255, 0.9);
      text-align: center;
    }
    &:hover {
      background-color: #fc5c42;
      transition: background-color 0.3s;
      box-shadow: none;
    }

    &:disabled {
      background-color: #d9d9d9;
      border: 1px solid #d9d9d9;
      color: white;
    }
  }
`;

const GeneratePassword = styled.span`
  font-size: 18px;
  line-height: 22px;
  color: #282929;
`;

const Icon = styled(IconButton)`
  position: absolute !important;
  ${rtl` left: 6px;`}
  top: 6px;
  svg {
    font-size: 30.86px;
  }
`;

const DetailsContainer = styled.div`
  .sign-up-form {
    input {
      background-color: transparent !important;
    }
    .MuiInputBase-root {
      border: 0 !important;
    }
  }
`;

const ParentTermsDiv = styled.div`
  line-height: 22px;
`;

const StyledCheckbox = styled(Checkbox)`
  background: #f5f5fa;
  border: 1px solid #2e294e;
  border-radius: 4px;
  padding: 8px !important;
  &.Mui-checked {
    color: #2e294e !important;
  }
`;

const StyledLink = styled(Link)`
  color: #5c95ff;
  text-decoration: none;
  border-bottom: 1px solid #5c95ff;
`;

const FormTitle = styled.div`
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: #fc5c42;
  text-align: center;
`;
