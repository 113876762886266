import * as React from 'react';
import { styled as Styled } from '@material-ui/core';
import MuCheckbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { TableToggleRowsSelectedProps } from 'react-table';
import styledTheme from 'styles/theme';

export const areEqual = (
  prevProps: { [key: string]: unknown },
  nextProps: { [key: string]: unknown }
) =>
  prevProps.checked === nextProps.checked &&
  prevProps.indeterminate === nextProps.indeterminate;

const Checkbox: React.FC<
  TableToggleRowsSelectedProps & CheckboxProps
> = React.forwardRef((props, ref) => <StyledCheckbox {...props} />);

export default React.memo(Checkbox, areEqual);

const StyledCheckbox = Styled(
  (props: TableToggleRowsSelectedProps & CheckboxProps) => (
    <MuCheckbox
      checkedIcon={<span className="icon checked" />}
      icon={<span className="icon" />}
      {...props}
    />
  )
)({
  fontSize: '14px',
  margin: '-9px 0 -8px -15px',
  padding: '8px 9px 9px 9px',
  '& .icon': {
    width: 18,
    height: 18,
    backgroundColor: '#E8E8E8',
    borderRadius: 5,
    '&.checked': {
      backgroundColor: styledTheme.colors.primary.dark,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundImage:
        "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAJCAYAAADpeqZqAAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAADaADAAQAAAABAAAACQAAAAB/gpxUAAAAb0lEQVQYGWNgIAL8//9fAIjzgdiACOUMDFAN54E0CBwgqAmoCGQDTMMHIBthE0gS3QRCGhqACkAgAaYRyMZtA0gRUEEASAcUJABp/BqQTAYphoH7UAaqH2CKkWmgQmSNhDXANEM1XgDSiFCCSSLRAFD4s4f7Y3DwAAAAAElFTkSuQmCC')",
    },
  },
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '& svg': {
    fill: '#cdcaca',
    width: 24,
    height: 24,
  },
});
