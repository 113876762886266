import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { useIntl } from 'react-intl';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import BackLink from 'components/BackLink';
import * as Form from 'components/forms';
import Icon from 'components/Icon';
import { useSession, useValidateUser } from 'hooks';
import { Message } from 'i18n';
import { MOBILE_RESOLUTION } from 'styles/sharedStyle';
import { AuthRoute, ClientRouter } from 'router/routes';

const ResetSuccess = () => {
  const isMobile = useMediaQuery(MOBILE_RESOLUTION);
  const intl = useIntl();
  const { isAuthenticated } = useValidateUser();
  const { getLocation, country } = useSession();

  React.useEffect(() => {
    if (!country) getLocation();
  }, [country, getLocation]);

  if (isAuthenticated) return <Redirect to={ClientRouter.APP} />;

  return (
    <Container
      title={intl.formatMessage(
        { id: Message.AUTH_RESET_PASSWORD_SUCCESS_TITLE },
        { successIcon: isMobile ? '' : <SuccessIcon /> }
      )}
    >
      <BackLink
        to={AuthRoute.LOGIN}
        label={intl.formatMessage({
          id: Message.AUTH_FORGOT_PASSWORD_BACK_LINK,
        })}
      />
    </Container>
  );
};

export default ResetSuccess;

const Container = styled(Form.Container).attrs({
  className: 'register-form-container',
})`
  && .auth-form-content {
    ${rtl`
      left: 0;
    `}
    height: 100vh;
    max-height: unset;

    @media (max-width: 1500px) {
      max-width: 535px;
      padding: 23px 40px;
    }

    @media (min-width: 1500px) {
      width: 623px;
      padding: 43px 58px;
    }
    @media (max-width: 535px) {
      min-width: unset;
      width: 100%;
      padding: 40px 50px;
    }
  }

  && .auth-form-header {
    margin-bottom: 29px;
    flex-direction: row;
    align-items: flex-end;
    gap: 100px;

    @media ${MOBILE_RESOLUTION} {
      margin-bottom: 29px;
      gap: 25px;
    }

    @media (min-width: 1500px) {
      margin-bottom: 29px;
      gap: 95px;
    }

    & .logo {
      @media (max-width: 500px) {
        align-self: center;
        height: 40px;
      }
      align-self: flex-start;
      @media (max-width: 1500px) {
        height: 50px;
      }
      height: 77px;
    }
  }
  && .auth-header-title {
    font-weight: bold;
    @media (max-width: 500px) {
      font-size: 1.25rem;
    }
    font-size: 1.25rem;
    @media (min-width: 1500px) {
      font-size: 1.75rem;
    }
  }

  && .main-button {
    width: 100%;
    min-height: 48px;
    text-transform: none;
    margin-top: 75px;

    @media (max-width: 500px) {
      width: 88%;
    }
  }

  && .MuiFormControl-root {
    @media (min-width: 500px) {
      width: 218px;
    }

    @media (min-width: 1500px) {
      width: 270px;
    }
  }
  & .register-fields {
    margin-top: 30px;
    row-gap: 14px;
    column-gap: 22px;

    @media (max-width: 1500px) {
      gap: 19px;
    }
    @media (max-width: 500px) {
      gap: 28px;
    }
  }
`;

const SuccessIcon = styled(Icon).attrs(({ theme }) => ({
  name: 'checkmark-circle',
  color: '#1DBF87',
  size: 24,
}))`
  position: relative;
  ${rtl`
    left: 3px;
  `}
  top: 2px;
`;
