import { useStripe, PaymentElement } from '@stripe/react-stripe-js';
import Button from 'components/Button/PrimaryButton';
import styled from 'styled-components';

const CheckoutForm = () => {
  const stripe = useStripe();

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    // // We don't want to let default form submission happen here,
    // // which would refresh the page.
    // event.preventDefault();
    // if (!stripe || !elements) {
    //   // Stripe.js has not yet loaded.
    //   // Make sure to disable form submission until Stripe.js has loaded.
    //   return;
    // }
    // const result = await stripe.confirmPayment({
    //   // `Elements` instance that was used to create the Payment Element
    //   elements,
    //   confirmParams: {
    //     return_url: 'https://example.com/order/123/complete',
    //   },
    // });
    // if (result.error) {
    //   // Show error to your customer (for example, payment details incomplete)
    //   console.log(result.error.message);
    // } else {
    //   // Your customer will be redirected to your `return_url`. For some payment
    //   // methods like iDEAL, your customer will be redirected to an intermediate
    //   // site first to authorize the payment, then redirected to the `return_url`.
    // }
  };

  return (
    <StriptFormContainer onSubmit={handleSubmit} className="stripe-form">
      <PaymentElement />
      <br />
      <Button type="submit" disabled={!stripe} style={{ width: '100%' }}>
        pay
      </Button>
      <br />
    </StriptFormContainer>
  );
};

export default CheckoutForm;

const StriptFormContainer = styled.form``;
