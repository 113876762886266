import * as React from 'react';
import { ClientUser } from 'api/responses';
import { Value } from 'components/Autocomplete/Autocomplete.config';
import { reduce } from 'utils/lodash.utils';
import useFetch from 'hooks/useFetch';
import { PermissionsParams } from 'types/common.types';

const useAssigneeOptions = ({ permissions, types }: PermissionsParams) => {
  const { data: users, isLoading: loadingUsersList } = useFetch<ClientUser[]>({
    initialUrl: '/api/users/',
    config: {
      params: {
        permissions,
        types,
      },
    },
  });

  const usersOptionsMap = React.useMemo(
    () =>
      reduce(
        users,
        (usersMap, user) => ({
          ...usersMap,
          [`${user.first_name ?? ''} ${user.last_name ?? ''}`]: {
            ...user,
            name: `${user.first_name ?? ''} ${user.last_name ?? ''}`,
          },
        }),
        {}
      ),
    [users]
  );

  const assigneeOptions = React.useMemo(
    () =>
      (Object.values(usersOptionsMap) as (ClientUser & {
        name: string;
      })[]).reduce(
        (options: unknown[], nextUser) => [
          ...options,
          { name: nextUser.name, value: nextUser.id },
        ],
        []
      ),
    [usersOptionsMap]
  ) as Value[];
  return { assigneeOptions, loadingUsersList };
};

export default useAssigneeOptions;
