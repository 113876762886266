import * as React from 'react';
import styled, { css } from 'styled-components';
import rtl from 'styled-components-rtl';
import { Story } from 'pages/appClient/home/mock/stories.config';
import { useImageGuard } from 'hooks';
import { userLogData } from 'mockData/userLogData';
import { LogsAPI } from 'api/methods';
import InstaStoryModal from 'components/@client/InstaStoryViewModal/InstaStoryViewModal';

interface Props extends Story {}

const StoryItem: React.FC<Props> = ({ label, image, mediaList }) => {
  const { src, onError } = useImageGuard(image);
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <ListItem>
      {isOpen && (
        <InstaStoryModal
          label={label}
          isOpen={isOpen}
          stories={mediaList}
          setIsOpen={setIsOpen}
        />
      )}

      <StoryContainer
        onClick={e => {
          setIsOpen(true);
          LogsAPI.postUserLogs({
            name: userLogData.eventName.storyOpen,
            area: userLogData.eventArea.homePage,
            section: userLogData.eventSection.instaStories,
            path: userLogData.eventPath.appPath,
            metadata: { story_name: label },
          });
        }}
      >
        <img src={src} alt={label} onError={onError} />
      </StoryContainer>
      <Content>
        <h4>{label}</h4>
      </Content>
    </ListItem>
  );
};

export default StoryItem;

const ListItem = styled.li`
  position: relative;
  ${rtl`
    margin-right: 24px;
  `}
  ${({ theme }) => theme.utils.centerFlex};
  justify-content: flex-start;
  flex-direction: column;
  cursor: pointer;
  padding: 0 10px !important;
  @media (min-width: 850px) {
    width: 20%;
  }
`;

const ImageStyle = css`
  position: absolute;
  object-fit: cover;
  ${rtl`
    left: 50%;
    transform: translate(-50%, -50%);
  `}
  top: 50%;
  border-radius: 50%;
`;

const StoryContainer = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 50%;
  & > img {
    ${ImageStyle}
    width: 100%;
    height: 100%;
  }
`;

const Content = styled.div`
  margin: 0 10px;
  text-align: center;
  & h4 {
    font-weight: 700;
    font-size: 1.125rem;
    margin: 8px 0 0 0;
    line-height: 18px;
    @media (max-width: 1150px) {
      max-width: 11ch;
    }
  }
  & p {
    margin: 0;
    font-weight: 300;
    font-size: 1rem;
  }
`;
