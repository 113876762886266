import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import not_supported from 'assets/@client/home/not_supported_region.png';
import { Message } from 'i18n';

const NotSupportedRegionPlaceholder = () => {
  return (
    <Container>
      <FormattedMessage id={Message.NOT_SUPPORTED_REGION} tagName="h3" />
      <img src={not_supported} alt="Not supported region" />
    </Container>
  );
};

export default NotSupportedRegionPlaceholder;

const Container = styled.section.attrs({
  className: 'not-supported-region-container',
})`
  margin-top: 40px;
  text-align: center;
  h3 {
    font-size: 2.25rem;
    font-weight: 700;
    color: #8f8e8e;
    max-width: 30ch;
    margin: 0 auto;
    margin-bottom: 40px;
  }
  img {
    width: 100%;
  }
`;
