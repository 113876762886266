import * as Response from 'api/responses';
import * as Transform from 'api/transform';
import { useFetch } from 'hooks';
import type { RoleInfo } from 'types/teamPatricia';

const useRoles = () => {
  const { data: roleOptions } = useFetch<RoleInfo[], Response.Role[]>({
    initialUrl: '/api/roles/',
    transform: Transform.Roles,
  });

  return roleOptions;
};

export default useRoles;
