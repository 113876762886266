import * as React from 'react';
import Popper, { PopperProps } from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import styled, { css } from 'styled-components';
import { popperModifiers } from './Autocomplete.config';

interface Props extends Partial<PopperProps> {
  onClickAway: () => void;
  borderRadius: { top: number; bottom: number };
  width: number | string;
}

const Container: React.FC<Props> = ({
  anchorEl,
  borderRadius,
  children,
  width = 300,
  onClickAway,
  disablePortal = true,
}) => {
  return (
    <Popper
      anchorEl={anchorEl}
      className="popper-container"
      style={{ zIndex: 10, width: '100%' }}
      disablePortal={disablePortal}
      modifiers={popperModifiers}
      open={!!anchorEl}
      placement="bottom-start"
    >
      <ClickAwayListener onClickAway={onClickAway}>
        <Wrapper
          $borderTop={borderRadius.top}
          $borderBottom={borderRadius.bottom}
          $width={width}
        >
          {children}
        </Wrapper>
      </ClickAwayListener>
    </Popper>
  );
};

export default React.memo(Container);

const Wrapper = styled.div<{
  $borderTop: number;
  $borderBottom: number;
  $width: number | string;
}>`
  background: #ffffff;
  width: ${({ $width = 'inherit' }) =>
    typeof $width === 'string' ? $width : `${$width}px`};
  margin: 0;
  box-shadow: rgb(13 13 13 / 4%) 1px -1px 12px 6px;
  & .MuiAutocomplete-listbox {
    background: white;
    ${({ theme }) => theme.utils.scrollbar}
    overflow-y: hidden;
  }

  ${({ $borderTop, $borderBottom }) => css`
    border-radius: ${$borderTop}px ${$borderTop}px ${$borderBottom}px
      ${$borderBottom}px;
  `}
`;
