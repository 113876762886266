import * as React from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Message } from 'i18n';
import { Field, TextArea } from 'components/forms';
import PatriciaLogo from 'assets/@client/supplier-login-flow/patricia-red-text.png';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { ClientRouter } from 'router/routes';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  SupplierOnboardingAction,
  useSupplierOnboardingDispatch,
  useSupplierOnboardingState,
} from 'context/supplierOnboardingContext';
import { useRouter } from 'hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { supplierProductStep2Schema } from 'utils/validation.utils';
import { useMediaQuery } from '@mui/material';
import {
  MOBILE_TABLET_RESOLUTION,
  MOBILE_RESOLUTION,
  MOBILE_TABLET_MIN_RESOLUTION,
} from 'styles/sharedStyle';
import {
  ParentMainDetails,
  PatriciaLogoContainer,
  MainDetails,
  UploadProductText,
  MainDetailsText,
  FormContainer,
  FormWrapperContainer,
  FooterButtons,
  BackButtonParent,
  BackButton,
  BackButtonText,
  NextButtonParent,
  NextButton,
  NextButtonText,
  MainDetailsParent,
} from '../supplier-main-details/SupplierMainDetails';
import SkipConfirmationDialog from '../components/SkipConfirmationDialog';
import SupplierAdditionalInfoMobile from './SupplierAdditionalInfoMobile';

const SupplierAdditionalInfo: React.FC = () => {
  const intl = useIntl();
  const [isConfirmationModalVisible, setIsGoBackModalVisible] = React.useState(
    false
  );
  const [descriptionText, setDescriptionText] = React.useState('');
  const state = useSupplierOnboardingState();
  const MeasurementUnits = ['mm', 'cm', 'm', 'in', 'ft'];
  const Types = [
    { id: 'material', name: 'Material' },
    { id: 'model', name: 'Model' },
    { id: 'internal_no_model', name: 'Internal' },
  ];
  const methods = useForm({
    defaultValues: {
      type: state.addProductDetails.type ?? '',
      description: state.addProductDetails.description ?? '',
      webpageLink: state.addProductDetails.webpageLink ?? '',
      length: state.addProductDetails.length ?? '',
      width: state.addProductDetails.width ?? '',
      height: state.addProductDetails.height ?? '',
      measurementUnits: state.addProductDetails.measurementUnits ?? '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(supplierProductStep2Schema),
  });
  const { handleSubmit } = methods;
  const isMobile = useMediaQuery(MOBILE_RESOLUTION);
  const isTablet = useMediaQuery(
    `${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION}`
  );
  const dispatch = useSupplierOnboardingDispatch();
  const { history } = useRouter();
  const onFormSubmit = React.useCallback(
    data => {
      dispatch({
        type: SupplierOnboardingAction.SET_ADD_PRODUCT_DETAILS,
        payload: data,
      });
      history.push(ClientRouter.SUPPLIER_MARKETING_INFO);
    },
    [dispatch, history]
  );
  return (
    <>
      {isMobile ? (
        <SupplierAdditionalInfoMobile
          onSubmit={handleSubmit(onFormSubmit)}
          methods={methods}
        />
      ) : (
        <ParentMainDetails>
          <a href={ClientRouter.APP}>
            <PatriciaLogoContainer src={PatriciaLogo} alt="patricia-logo" />
          </a>
          <MainDetailsParent>
            <MainDetails>
              <SkipText onClick={() => setIsGoBackModalVisible(true)}>
                <FormattedMessage
                  id={Message.PROPERTY_ADD_PROPERTY_BUTTON_SKIP}
                />
              </SkipText>
              <UploadProductText>
                <FormattedMessage
                  id={Message.SUPPLIER_UPLOAD_YOUR_FIRST_PRODUCT}
                  values={{
                    span: (chunks: string) => (
                      <span style={{ color: '#FC5C42' }}>{chunks}</span>
                    ),
                  }}
                />
              </UploadProductText>
              <MainDetailsText>
                <FormattedMessage id={Message.SUPPLIER_ADDITIONAL_INFO} />
              </MainDetailsText>
              <FormContainer style={{ marginTop: isTablet ? 0 : 36 }}>
                <FormProvider {...methods}>
                  <FormWrapperContainer onSubmit={handleSubmit(onFormSubmit)}>
                    <FirstEl>
                      <FormTextareaDiv
                        maxLength={100}
                        handleChange={e => setDescriptionText(e.target.value)}
                        label={{
                          title: (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                fontSize: 14,
                              }}
                            >
                              <div style={{ display: 'flex' }}>
                                <div>
                                  {intl.formatMessage({
                                    id: Message.SUPPLIER_DESCRIPTION_LABEL,
                                  })}
                                </div>
                                &nbsp;
                                <div style={{ fontWeight: 'normal' }}>
                                  (
                                  {intl.formatMessage({
                                    id: Message.ADDRESS_FORM_OPTIONAL,
                                  })}
                                  )
                                </div>
                              </div>
                              <div style={{ fontWeight: 'normal' }}>
                                {descriptionText?.length}/100
                              </div>
                            </div>
                          ),
                        }}
                        name="description"
                        placeholder={intl.formatMessage({
                          id: Message.SUPPLIER_DESCRIPTION_PLACEHOLDER,
                        })}
                      />
                    </FirstEl>
                    <InlineViewFirst>
                      <FormFieldDiv
                        label={{
                          title: (
                            <div style={{ display: 'flex' }}>
                              <div>
                                {intl.formatMessage({
                                  id: Message.SUPPLIER_WEBPAGE_LINK_LABEL,
                                })}
                              </div>
                              &nbsp;
                              <div style={{ fontWeight: 'normal' }}>
                                (
                                {intl.formatMessage({
                                  id: Message.ADDRESS_FORM_OPTIONAL,
                                })}
                                )
                              </div>
                            </div>
                          ),
                          isBold: true,
                        }}
                        name="webpageLink"
                        type="text"
                        placeholder={intl.formatMessage({
                          id: Message.SUPPLIER_WEBPAGE_LINK_PLACEHOLDER,
                        })}
                      />
                      <div>
                        <SelectLabel>
                          {intl.formatMessage({
                            id: Message.SUPPLIER_TYPES_LABEL,
                          })}
                        </SelectLabel>
                        <CustomFormControl>
                          <Controller
                            control={methods.control}
                            name="type"
                            render={({ onChange, value, name, ref }) => (
                              <Select
                                inputRef={ref}
                                // value={}
                                defaultValue={
                                  state.addProductDetails.type ?? ''
                                }
                                onChange={val => onChange(val.target.value)}
                                displayEmpty
                                name="type"
                                inputProps={{ 'aria-label': 'Without label' }}
                              >
                                <MenuItem disabled value="">
                                  {' '}
                                  {intl.formatMessage({
                                    id: Message.SUPPLIER_TYPES_PLACEHOLDER,
                                  })}
                                </MenuItem>
                                {(Types ?? []).map(type => (
                                  <MenuItem key={type.id} value={type.id}>
                                    {' '}
                                    {type.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          />
                        </CustomFormControl>
                      </div>
                    </InlineViewFirst>

                    <InlineViewSecond>
                      <FormFieldDiv
                        label={{
                          title: intl.formatMessage({
                            id: Message.SUPPLIER_TOTAL_LENGTH_LABEL,
                          }),
                          isBold: true,
                        }}
                        name="length"
                        type="text"
                        placeholder={intl.formatMessage({
                          id: Message.SUPPLIER_TOTAL_LENGTH_PLACEHOLDER,
                        })}
                      />
                      <FormFieldDiv
                        label={{
                          title: intl.formatMessage({
                            id: Message.SUPPLIER_TOTAL_WIDTH_LABEL,
                          }),
                          isBold: true,
                        }}
                        name="width"
                        type="text"
                        placeholder={intl.formatMessage({
                          id: Message.SUPPLIER_TOTAL_WIDTH_PLACEHOLDER,
                        })}
                      />
                    </InlineViewSecond>
                    <InlineViewThird>
                      <FormFieldDiv
                        label={{
                          title: intl.formatMessage({
                            id: Message.SUPPLIER_TOTAL_HEIGHT_LABEL,
                          }),
                          isBold: true,
                        }}
                        name="height"
                        type="text"
                        placeholder={intl.formatMessage({
                          id: Message.SUPPLIER_TOTAL_HEIGHT_PLACEHOLDER,
                        })}
                      />
                      <div>
                        <SelectLabel>
                          {intl.formatMessage({
                            id: Message.SUPPLIER_MEASUREMENT_UNITS_LABEL,
                          })}
                        </SelectLabel>
                        <CustomFormControl>
                          <Controller
                            control={methods.control}
                            name="measurementUnits"
                            render={({ onChange, value, name, ref }) => (
                              <Select
                                // value={}
                                defaultValue={
                                  state.addProductDetails.measurementUnits ?? ''
                                }
                                inputRef={ref}
                                onChange={val => onChange(val.target.value)}
                                displayEmpty
                                name={name}
                                inputProps={{ 'aria-label': 'Without label' }}
                              >
                                <MenuItem disabled value="">
                                  {' '}
                                  {intl.formatMessage({
                                    id:
                                      Message.SUPPLIER_MEASUREMENT_UNITS_PLACEHOLDER,
                                  })}
                                </MenuItem>
                                {(MeasurementUnits ?? []).map(unitName => (
                                  <MenuItem key={unitName} value={unitName}>
                                    {unitName}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          />
                        </CustomFormControl>
                      </div>
                    </InlineViewThird>
                    <MandatoryFields>
                      <FormattedMessage
                        id={Message.SUPPLIER_MANDATORY_FIELDS}
                      />
                    </MandatoryFields>
                  </FormWrapperContainer>
                </FormProvider>
              </FormContainer>
              <FooterButtons>
                <FooterParent>
                  <BackButtonParent>
                    <BackButton onClick={() => history.goBack()}>
                      <BackButtonText>
                        <FormattedMessage
                          id={Message.PROPERTY_ADD_PROPERTY_BUTTON_PREVIOUS}
                        />
                      </BackButtonText>
                    </BackButton>
                  </BackButtonParent>
                  <NextButtonParent>
                    <NextButton
                      disabled={!methods.formState.isValid}
                      onClick={handleSubmit(onFormSubmit)}
                    >
                      <NextButtonText>
                        <FormattedMessage
                          id={Message.PROPERTY_ADD_PROPERTY_BUTTON_NEXT}
                        />
                      </NextButtonText>
                    </NextButton>
                  </NextButtonParent>
                </FooterParent>
              </FooterButtons>
            </MainDetails>
          </MainDetailsParent>

          <SkipConfirmationDialog
            isOpen={isConfirmationModalVisible}
            toggle={() => setIsGoBackModalVisible(prev => !prev)}
          />
        </ParentMainDetails>
      )}
    </>
  );
};

export default SupplierAdditionalInfo;

const FirstEl = styled.div``;
export const FormTextareaDiv = styled(TextArea)`
  label {
    font-size: 14px;
    line-height: 22px;
    color: #3e3e3e;
    margin-left: 0;
    display: unset;
  }
  textarea {
    width: 520px;
    height: 76px;
    background: #fafcfe;
    border: 1px solid #e8eaeb;
    border-radius: 10px;
    margin-top: 0px;
  }
`;
export const FormFieldDiv = styled(Field)`
  label {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #3e3e3e;
    margin-left: 0;
    font-family: 'Roboto Condensed';
  }
  .MuiInputBase-root {
    margin-top: 20px;
    width: 250px;
    height: 48px;
    input {
      background: #fafcfe;
    }
  }
`;

export const CustomFormControl = styled(FormControl)`
  .MuiInputBase-root {
    width: 250px;
    height: 48px;
    border-radius: 10px;
    .MuiSelect-select {
      background: #fafcfe;
    }
    input {
      background: #fafcfe;
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: #e8eaeb !important;
    }
  }
`;
export const SelectLabel = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: #3e3e3e;
  margin-bottom: 0px;
  font-weight: 600;
`;

const InlineViewFirst = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 35px;
`;
const InlineViewSecond = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 40px;
`;

const InlineViewThird = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 42px;
`;

const SkipText = styled.div`
  position: absolute;
  right: 224px;
  top: 72px;
  text-decoration-line: underline;
  color: #696969;
  cursor: pointer;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    right: 65px;
    top: 164px;
  }
`;

const FooterParent = styled.div`
  display: flex;
  gap: 200px;
  margin-top: 112px;
  margin-bottom: 20px;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    margin-top: 48px;
    gap: 100px;
  }
`;

export const MandatoryFields = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: #696969;
  margin-top: 24px;
  text-align: center;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    text-align: start;
  }
`;
