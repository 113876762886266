import * as React from 'react';
import { CustomIcon, BaseButton } from 'components/Button';
import { alpha, ButtonProps } from '@material-ui/core';
import styled, { css } from 'styled-components';
import { ButtonCss } from 'styles/sharedStyle';

interface ICustomProps extends Partial<ButtonProps> {
  isLoading?: boolean;
  $buttonColor?: 'dark' | 'light' | 'lightMobile';
  $secondary?: boolean;
}

const ActionButton: React.FC<ICustomProps> = ({
  isLoading = false,
  children,
  ...props
}): React.ReactElement => {
  return (
    // FIXME: open issue with MU https://github.com/mui-org/material-ui/pull/21552
    // @ts-ignore
    <ActionButtonStyled {...props}>
      {isLoading ? (
        <CustomIcon $secondary={props.$secondary} name="spinner" size={20} />
      ) : (
        children
      )}
    </ActionButtonStyled>
  );
};

export default ActionButton;

const ActionButtonStyled = styled(BaseButton).attrs(
  ({ variant = 'contained' }) => ({
    className: 'modal-action-button',
    variant,
    size: 'medium',
    autoFocus: true,
  })
)<ICustomProps>`
  && {
    ${ButtonCss};
    background-color: ${({ theme, $buttonColor = 'light' }) =>
      theme.colors.primary[$buttonColor]};
    color: ${({ theme }) => theme.colors.primary.contrastText};
    text-transform: none;
    height: 48px;
    padding: 0 15px;

    &:hover {
      background-color: #a50a0a;
      transition: background-color 0.3s;
      box-shadow: none;
    }

    ${({ theme, $secondary = false, $buttonColor = 'light' }) =>
      $secondary &&
      css`
        background-color: ${theme.colors.light};
        color: ${theme.colors.primary[$buttonColor]};
        border: 1px solid ${theme.colors.primary[$buttonColor]};

        &:hover {
          background-color: ${theme.colors.light};
        }

        &:disabled {
          background-color: ${theme.colors.light};
          border-color: ${alpha(theme.colors.primary[$buttonColor], 0.5)};
          color: ${alpha(theme.colors.primary[$buttonColor], 0.5)} !important;
        }
      `}
  }
`;
