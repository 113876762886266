import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { PhoneField } from 'components/forms';
import { Message } from 'i18n';
import { useAuthentication, useRouter } from 'hooks';
import { ClientRouter } from 'router/routes';
import Icon from 'components/Icon';
import eyeIcon from 'assets/@client/onboarding/eye-down.png';
import eyeUpIcon from 'assets/@client/onboarding/eye-up.png';
import { FormattedMessage, useIntl } from 'react-intl';
import TextInput from 'components/@client/form-components/TextInput';
import {
  MOBILE_TABLET_MIN_RESOLUTION,
  MOBILE_TABLET_RESOLUTION,
} from 'styles/sharedStyle';

const SignUpSupplierMobile: React.FC = () => {
  const { control, formState } = useFormContext();
  const { loading: isLoading } = useAuthentication();
  const [isPasswordVisible, setIsPasswordVisible] = React.useState({
    password: false,
    confirmPassword: false,
  });
  const intl = useIntl();
  const { history } = useRouter();
  const { isValid, isDirty } = formState;
  return (
    <>
      <ParentDiv>
        <NameParent>
          <TextInput
            name="firstName"
            control={control}
            label={intl.formatMessage({ id: Message.FIRST_NAME })}
            required
            placeholder="John"
          />
          <TextInput
            name="lastName"
            control={control}
            label={intl.formatMessage({ id: Message.LAST_NAME })}
            required
            placeholder="Doe"
          />
        </NameParent>
        <EmailDiv>
          <TextInput
            name="email"
            control={control}
            label={intl.formatMessage({
              id: Message.AUTH_LOGIN_EMAIL_FIELD,
            })}
            placeholder={intl.formatMessage({
              id: Message.SUPPLIER_LOGIN_EMAIL_PLACEHOLDER,
            })}
            type="email"
            required
          />
        </EmailDiv>
        <PhoneNumberDiv>
          <PhoneField
            name="phoneNumber"
            label={{
              title: intl.formatMessage(
                {
                  id: Message.SUPPLIER_REGISTRATION_PHONE_NUMBER_LABEL,
                },
                {
                  span: (chunks: string) => (
                    <span
                      style={{
                        fontSize: '12px',
                        fontWeight: 400,
                        color: '#696969',
                      }}
                    >
                      {chunks}
                    </span>
                  ),
                }
              ),
            }}
          />
        </PhoneNumberDiv>
        <PasswordParent>
          <TextInput
            name="password"
            control={control}
            label={intl.formatMessage({
              id: Message.AUTH_LOGIN_PASSWORD_FIELD,
            })}
            type={isPasswordVisible.password ? 'text' : 'password'}
            required
          />
          <IconParent
            onClick={() =>
              setIsPasswordVisible(prev => ({
                ...prev,
                password: !prev.password,
              }))
            }
          >
            <img
              alt="temp"
              src={isPasswordVisible.password ? eyeUpIcon : eyeIcon}
            />
          </IconParent>
        </PasswordParent>
        <ConfirmPasswordParent>
          <TextInput
            name="verifyPassword"
            control={control}
            label={intl.formatMessage({
              id: Message.SUPPLIER_REPEAT_PASSWORD,
            })}
            type={isPasswordVisible.confirmPassword ? 'text' : 'password'}
            required
          />
          <IconParent
            onClick={() =>
              setIsPasswordVisible(prev => ({
                ...prev,
                confirmPassword: !prev.confirmPassword,
              }))
            }
          >
            <img
              alt="temp"
              src={isPasswordVisible.confirmPassword ? eyeUpIcon : eyeIcon}
            />
          </IconParent>
        </ConfirmPasswordParent>
        <ButtonsContainer>
          <BackButton onClick={() => history.push(ClientRouter.SUPPLIER_LOGIN)}>
            <BackButtonText>
              <FormattedMessage
                id={Message.PROPERTY_ADD_PROPERTY_BUTTON_PREVIOUS}
              />
            </BackButtonText>
          </BackButton>
          <NextButton
            disabled={!isValid || !isDirty || isLoading}
            type="submit"
          >
            {isLoading ? (
              <CustomIcon name="spinner" size={20} />
            ) : (
              <NextButtonText>
                <FormattedMessage
                  id={Message.PROPERTY_ADD_PROPERTY_BUTTON_NEXT}
                />
              </NextButtonText>
            )}
          </NextButton>
        </ButtonsContainer>
      </ParentDiv>
    </>
  );
};

export default SignUpSupplierMobile;

const ParentDiv = styled.div`
  direction: ltr;
  .phoneNumber {
    margin: -6px 0 0;
  }
  label {
    font-size: 14px !important;
    line-height: 16px;
    color: #696969 !important;
    letter-spacing: 0.545455px !important;
    font-weight: 400 !important;
    margin-left: 0 !important;
    @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
      font-size: 18px !important;
      line-height: 20px;
    }
  }
  .text-input-root {
    padding-bottom: 0;
    min-height: 0;
  }
  .custom-input-root:hover {
    border: none !important;
  }
  .MuiOutlinedInput-input {
    background: #fafcfe;
    border-radius: 10px;
    height: 15px;
  }
`;

const NameParent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: center;
  .text-input-root {
    width: 156px;
    min-height: 0;
    @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
      width: 185px;
    }
  }
`;

const EmailDiv = styled.div`
  margin-top: 8px;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    margin-top: 16px;
  }
`;

const PhoneNumberDiv = styled.div`
  margin-top: 12px;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    margin-top: 22px;
  }
`;

const PasswordParent = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  .text-input-root {
    width: 327px;
  }
  .custom-input-root:hover {
    border: none !important;
  }
  margin-top: 8px;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    margin-top: 16px;
    .text-input-root {
      width: 384px;
    }
  }
`;

const ConfirmPasswordParent = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  .text-input-root {
    width: 327px;
  }
  margin-top: 8px;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    margin-top: 16px;
    .text-input-root {
      width: 384px;
    }
  }
  .custom-input-root:hover {
    border: none !important;
  }
`;

const IconParent = styled.div`
  position: absolute;
  right: 16px;
  top: 36px;
  cursor: pointer;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    top: 40px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    margin-top: 32px;
  }
`;

const BackButton = styled.button`
  cursor: pointer;
  border: 1px solid #d9d9d9;
  border-radius: 15px;
  padding: 6px 24px;
  height: 40px;
  width: 134px;
  background: rgba(250, 250, 250, 0.8);
`;

const BackButtonText = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: #696969;
`;

const NextButton = styled.button`
  :disabled {
    background: #d9d9d9;
    cursor: default;
  }
  background: #fc5c42;
  border: 1px solid #d9d9d9;
  border-radius: 15px;
  padding: 6px 24px;
  height: 40px;
  width: 134px;
  cursor: pointer;
`;

const NextButtonText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.9);
`;

const CustomIcon = styled(Icon)`
  ${({ theme }) => theme.utils.spinAnimation};
  position: absolute;
  fill: #f9fafa;
`;
