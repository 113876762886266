import * as React from 'react';
import Card, { CardField } from 'components/@client/CardList/Card';
import { Skin } from 'types/properties';
import { useFormattedCurrency } from 'hooks';

interface Props {
  skin: Skin | null;
}

const SkinCheckoutItem: React.FC<Props> = ({ skin }) => {
  const price = useFormattedCurrency(skin?.price);
  return (
    <Card img={skin?.thumbnail ?? ''} alt={skin?.name ?? ''}>
      <CardField label="" value={skin?.name ?? ''} />
      <CardField label={skin?.user?.name} value={price} />
    </Card>
  );
};

export default SkinCheckoutItem;
