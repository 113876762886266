import * as React from 'react';
import styled from 'styled-components';
import IconButton, { IconName } from 'components/Button/IconButton';

interface Props {
  isDeleteDisabled?: boolean;
  onEdit?: VoidFunction;
  onDelete: VoidFunction;
}

const AddressActions: React.FC<Props> = ({
  isDeleteDisabled = false,
  onEdit,
  onDelete,
}) => {
  return (
    <Actions>
      <IconButton
        iconName={IconName.custom_icon}
        customIcon="edit"
        onClick={onEdit}
        className="edit"
      />
      <IconButton
        iconName={IconName.custom_icon}
        customIcon="trash-filled"
        disabled={isDeleteDisabled}
        onClick={onDelete}
        className="delete"
      />
    </Actions>
  );
};

export default AddressActions;

const Actions = styled.div`
  display: flex;

  .icon {
    fill: ${({ theme }) => theme.colors.silver};
  }
`;
