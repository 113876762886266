import { useRouter } from 'hooks';
import * as Section from 'pages/appClient/onboarding/shared';
import * as React from 'react';
import styled from 'styled-components';
import { Property } from 'types/properties';
import PremadePlanView from './PremadePlanView';
import UploadPlanView from './UploadPlanView';

const WebView: React.FC<{
  onClick: VoidFunction;
  userImage?: string;
  property: Property | null;
  goToTour: VoidFunction;
}> = ({ property }) => {
  const { location } = useRouter();
  return (
    <Container>
      {(location.state as { isUploadPlan?: boolean })?.isUploadPlan ? (
        <UploadPlanView property={property} />
      ) : (
        <PremadePlanView property={property} />
      )}
    </Container>
  );
};

export default WebView;

const Container = styled(Section.Container)`
  text-align: center;

  margin: 20px auto 0;
  box-shadow: none;

  & .action-button {
    font-family: ${({ theme }) => theme.dir === 'rtl' && 'Assistant'};
    margin: 45px 0;
    padding: 0 80px;
  }
`;

export const Warning = styled.div`
  padding-top: 10px;
  font-size: 0.9rem;
`;
