import { useFetch } from 'hooks';
import * as React from 'react';
// import { FormattedMessage } from 'react-intl';
import { IRouteConfig } from 'router/routes';
// import styled, { css } from 'styled-components';
// import { Button } from '@mui/material';
// import useMediaQuery from '@material-ui/core/useMediaQuery';
// import { Message } from 'i18n';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { METHODS } from 'api/client';
import { Route, Switch } from 'react-router';
import { BaseUserResponse } from 'types/common.types';

const SellerLandingRouter: React.FC<IRouteConfig> = ({ routes }) => {
  const [headerTopPosition, setHeaderTopPosition] = React.useState(false);
  // const { location } = useRouter();
  // const isMobile = useMediaQuery('(max-width: 1024px)');

  const { data, callFetch } = useFetch<
    Array<{
      color: string;
      hero_gif: string;
      id: number;
      supplier_id: number;
      supplier: {
        additional_phone_number: string;
        logo: string;
        user: BaseUserResponse;
        id: number;
      };
    }>
  >({
    initialUrl: `/api/landing_pages/`,
    config: {
      //   params: {
      //     supplier_name: query.supplierName,
      //   },
      method: METHODS.GET,
    },
  });

  return (
    <>
      {/* <StyledHeader topPosition={headerTopPosition} isMobile={isMobile}> */}
      {/* {!isLoading && (
        <img
          src={
            data?.[0]?.supplier.logo ??
            'https://patricia-ai-data-staging.s3.amazonaws.com/media/suppliers/download_EZ0EHhg.png'
          }
          alt="Seller logo"
        />
      )}
      {!location.pathname.includes('thankyou') && (
        <Button
          className="design-home-free"
          onClick={() => window.scrollTo({ top: 1240 })}
        >
          <FormattedMessage id={Message.LANDING_PAGE_HEADING} />
          <ChevronRightIcon className="right-arrow" />
        </Button>
      )} */}
      {/* </StyledHeader> */}
      <Switch>
        {routes.map(path => {
          const Component = path.component;
          return (
            <Route
              key={path.path}
              {...path}
              component={undefined}
              render={props => (
                <Component
                  {...props}
                  supplierData={data?.[0]}
                  headerTopPosition={headerTopPosition}
                  setHeaderTopPosition={setHeaderTopPosition}
                  getSupplierData={callFetch}
                />
              )}
            />
          );
        })}
      </Switch>
    </>
  );
};

// const StyledHeader = styled.header<{ topPosition: boolean; isMobile: boolean }>`
//   position: fixed;
//   width: 100vw;
//   ${({ isMobile }) =>
//     css`
//       height: ${isMobile ? '0px' : '80px'};
//     `}
//   left: 0px;
//   top: 0;
//   padding: 16px 160px;
//   background: #fafcfe;
//   box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
//   display: flex;
//   justify-content: space-between;
//   transition: top 0.3s;
//   z-index: 9;
//   img {
//     height: 48px;
//     object-fit: cover;
//   }
//   .design-home-free {
//     display: ${({ topPosition }) => (topPosition ? 'block' : 'none')};
//     width: ${({ theme }) => (theme.dir === 'rtl' ? '274px' : '406px')};
//     height: 48px;
//     border: 1px solid #a50a0a;
//     border-radius: 100px;
//     -webkit-box-align: center;
//     align-items: center;
//     text-align: center;
//     transition: display 0.3s;
//     color: #a50a0a;
//     padding: 10px 0;
//     font-weight: 500;
//     font-size: 20px;
//     line-height: 24px;
//     .right-arrow {
//       font-size: 48px;
//       margin: -15px -10px;
//       rotate: ${({ theme }) => (theme.dir === 'rtl' ? '180deg' : '0deg')};
//     }
//   }
// `;

export default SellerLandingRouter;
