import { useCallback, useState } from 'react';
import client, { METHODS } from 'api/client';
import type { AxiosRequestConfig } from 'axios';

type Props = {
  url: string;
};

const useBlobFromUrl = ({ url }: Props) => {
  const [currentPending, setPending] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);

  const handleDownload = useCallback(
    async (config: AxiosRequestConfig = {}, mediaUrl?: string) => {
      setPending(true);
      setError(null);
      try {
        return await client({
          url: mediaUrl ?? url,
          isPublic: true,
          method: METHODS.GET,
          responseType: 'blob',
          ...config,
        }).then(r => {
          setError(null);
          return URL.createObjectURL(r as Blob);
        });
      } catch (e) {
        const errorMessage = e.message || 'ERROR: Cannot refresh Data source';
        setError(errorMessage);
        return false;
      }
    },
    [url]
  );

  return {
    isDownloading: currentPending,
    handleDownload,
    error,
  };
};

export default useBlobFromUrl;
