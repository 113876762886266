import * as React from 'react';
import styled from 'styled-components';
import { useFetch } from 'hooks';
import Grid from '@material-ui/core/Grid';
import SectionTitle from 'components/@client/SectionTitle';
import { METHODS } from 'api/client';
import * as Transform from 'api/transform';
import Loader from 'components/Loader';
import ShowMoreButton from 'components/@client/Button/ShowMoreButton';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import { Product } from 'types/products';
import NoProduct from 'components/@client/NoProduct/NoProduct';
import CoverSection from 'components/@client/CoverSection/CoverSection';
import Breadcrumbs from 'components/@client/Breadcrumbs/Breadcrumbs';
import { userLogData } from 'mockData/userLogData';
import { LogsAPI } from 'api/methods';
import { PRODUCT_LISTING } from 'constants/common.constants';
import { ClientRouter } from 'router/routes';
import BestSellerCard from '../products/components/BestSellerCard';

const SubCategoryPageComponent: React.FC = () => {
  const [page, setPage] = React.useState(1);
  const pageSize = 12;
  const [productList, setProductList] = React.useState<Product[] | undefined>();
  const { data: products, isLoading, callFetch } = useFetch({
    initialUrl: `api/products/`,
    config: {
      useCache: false,
      method: METHODS.GET,

      params: {
        on_sale: true,
        size: pageSize,
        page,
      },
    },
    skipOnStart: true,
    transform: Transform.PaginatedProducts,
  });
  React.useEffect(() => {
    LogsAPI.pushDataLayer([
      { ecommerce: null },
      {
        ecommerce: {
          impressions: products?.results.map((r, i) => ({
            name: r.name,
            id: r.id,
            price: r.price,
            brand: r.manufacture.name,
            category: r.category,
            list: PRODUCT_LISTING,
            position: i + 1,
          })),
        },
      },
    ]);
    setProductList(prevProductList =>
      Array.isArray(prevProductList) &&
      Array.isArray(products?.results) &&
      products
        ? [...prevProductList, ...products?.results]
        : products?.results
    );
  }, [products]);
  React.useEffect(() => {
    callFetch({
      params: {
        on_sale: true,
        size: pageSize,
        page,
      },
    });
  }, [page, pageSize, callFetch]);

  const showMoreBtn = React.useMemo(
    () => products && productList && products?.count > productList?.length,
    [productList, products]
  );

  const onSubCategoryClick = (product: Product) => {
    LogsAPI.postUserLogs({
      name: userLogData.eventName.product,
      area: userLogData.eventArea.catalog,
      section: userLogData.eventSection.subCategories,
      path: userLogData.eventPath.appProductsSubCategoryNamePath,
      metadata: {
        product_id: product.id,
        product_name: product.name,
        product_sku: product.sku,
      },
    });
  };
  return (
    <Root>
      <CoverSection imageUrl={undefined} />
      <BreadcrumbsContainer>
        <Breadcrumbs
          links={[
            {
              to: ClientRouter.SALES_PAGE,
              children: 'פסח SALE',
            },
          ]}
        />
      </BreadcrumbsContainer>
      <Container>
        <SectionTitle label="פסח SALE" borderColor="#c08173" />
        {isLoading && !productList ? (
          <Loader />
        ) : (
          <Grid container>
            {productList?.length ? (
              productList.map(data => (
                <Grid item md={3} sm={3} key={data.id}>
                  <BestSellerCard product={data} onClick={onSubCategoryClick} />
                </Grid>
              ))
            ) : (
              <NoProduct value={Message.NO_PRODUCT_AVAILABLE} />
            )}
          </Grid>
        )}
        {!!showMoreBtn && !isLoading && (
          <div>
            <ShowMoreBtnDiv>
              <ShowMoreButton onClick={() => setPage(prevPage => prevPage + 1)}>
                <FormattedMessage id={Message.SKINS_SHOW_MORE_BUTTON} />
              </ShowMoreButton>
            </ShowMoreBtnDiv>
          </div>
        )}
        {productList && isLoading && <Loader />}
      </Container>
    </Root>
  );
};

export default SubCategoryPageComponent;

const Root = styled.div`
  width: 100%;
`;

const Container = styled.div`
  margin: 20px 16px 0px;
`;

const ShowMoreBtnDiv = styled.div`
  text-align: center;
  padding: 15px;
`;

const BreadcrumbsContainer = styled.div`
  margin: 20px 16px 0px;
`;
