import * as React from 'react';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import SkeletonList from 'components/@client/SkeletonList';
import { isEmpty } from 'utils/lodash.utils';
import { ProductSkeleton } from './CarouselItems';

interface Props<T extends object> {
  isLoading?: boolean;
  items: T[];
  emptyStateMessage?: string | JSX.Element;
  loadingItemCount?: number;
  SkeletonItem?: () => JSX.Element;
  renderItem: (item: T) => React.ReactNode;
  className?: string;
}

const HorizontalCarousel = <T extends object>({
  emptyStateMessage = 'No items available',
  isLoading,
  items,
  loadingItemCount = 5,
  SkeletonItem = ProductSkeleton,
  renderItem,
  className,
}: Props<T>) => {
  return (
    <Container className={className}>
      <List>
        {isLoading ? (
          <SkeletonList count={loadingItemCount} Item={SkeletonItem} />
        ) : (
          <>
            {!isEmpty(items) ? (
              items.map(renderItem)
            ) : (
              <EmptyStateItem>{emptyStateMessage}</EmptyStateItem>
            )}
          </>
        )}
      </List>
    </Container>
  );
};

export * from './CarouselItems';
export default HorizontalCarousel;

const Container = styled.div`
  width: 100vw;
  max-width: 500px;
  overflow: overlay;
  ${({ theme }) => theme.utils.scrollbar};
  ${rtl`
    margin: 0 -25px;
  `};

  &::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }

  &.homepage-behavior-style {
    & .horizontal-carousel {
      & li:not(:first-child) {
      }
      @media (min-width: 1151px) and (max-width: 1439px) {
        width: 100%;
      }
    }
  }

  @media (min-width: 500px) {
    margin: 0;
    max-width: 100%;
  }
`;

const List = styled.ul.attrs({ className: 'horizontal-carousel' })`
  color: ${({ theme }) => theme.colors.primary.clientTextColor};
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
  width: fit-content;
  overflow-x: overlay;
  overflow-y: hidden;
  ${({ theme }) => theme.utils.scrollbar};

  & > li {
    list-style: none;
    padding: 0;
    margin: 0;
  }
`;

const EmptyStateItem = styled.li.attrs({
  className: 'horizontal-carousel-empty-state-item',
})`
  width: 100%;
  text-align: center;
  min-height: 150px;
  font-size: 1.25rem;
  opacity: 0.6;

  &.horizontal-carousel-empty-state-item {
    ${rtl`
      margin-left: 25px;
    `}
  }
`;
