import * as React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import styled from 'styled-components';
import illustration from 'assets/@client/checkout/failure-illustration.svg';
import mobileIllustration from 'assets/@client/checkout/failure-mobile-illustration.svg';
import Button from 'components/@client/Button';
import { Link } from 'react-router-dom';
import { ClientRouter } from 'router/routes';
import { MOBILE_RESOLUTION } from 'styles/sharedStyle';
import { useRouter } from 'hooks';

const Failure = () => {
  const isMobile = useMediaQuery(MOBILE_RESOLUTION);
  const { pathname } = useRouter();
  React.useEffect(() => {
    if (window.top.location.pathname !== pathname)
      window.top.location.pathname = ClientRouter.CHECKOUT_FAILURE;
  }, [pathname]);
  return (
    <Container>
      <p>
        Sorry to bother you like that in the end, but there's a problem. It
        could be a small problem,
        <br />
        You can return to the order page and start the process again.
      </p>
      <Content>
        <img
          src={isMobile ? mobileIllustration : illustration}
          alt="A dark wood credenza with two books inside and a record player, vase, and tea set on top. A stack of books are on the floor next to the credenza waiting to be put away."
        />
      </Content>
      <Link className="orders-link" to={ClientRouter.CART}>
        <Button className="orders-button" fullWidth>
          Back to cart
        </Button>
      </Link>
    </Container>
  );
};

export default Failure;

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  & p {
    margin: 20px auto;
    max-width: 25ch;
    text-align: center;
    color: rgba(66, 66, 66, 1);
    font-size: 1.25rem;
    font-weight: 500;
    z-index: 1;

    @media (min-width: 800px) {
      max-width: 35ch;
    }
  }
  .orders-link {
    text-decoration: none;
    width: 100%;
    text-align: center;

    & > .orders-button {
      border-radius: 12px;
      max-width: 350px;
      margin: 0 auto;
    }

    @media (max-width: 500px) {
      position: fixed;
      bottom: 120px;
    }
  }
`;

const Content = styled.figure`
  margin: 0 auto;
  text-align: center;

  img {
    text-align: center;
    margin: 20px auto;
    max-width: 100%;
    z-index: 0;

    @media (max-width: 500px) {
      width: 100vw;
      max-height: 72vh;
      margin: 0;
      position: fixed;
      top: 0;
      left: 0;
    }
  }
`;
