import * as React from 'react';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';

const Illustrations: React.FC = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default Illustrations;

const Wrapper = styled.div.attrs({ className: 'illustrations-wrapper' })`
  position: relative;
  @media (min-width: 1150px) {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  & .illustration.lamp {
    position: absolute;
    ${rtl`
      right: -20px;
    `}
    top: -150px;

    @media (min-width: 1150px) {
      right: 40px;
      top: -40px;
    }
  }
  & .illustration.books {
    position: absolute;
    bottom: -30px;
    left: 30px;
    z-index: 2;
    width: 58.41px;

    @media (min-width: 1150px) {
      bottom: -20px;
    }

    @media (max-width: 768px) {
      width: 50px;
      left: 50px;
    }
  }

  & .illustration.plant {
    position: absolute;
    width: 66.68px;
    left: -20px;
    bottom: -30px;

    @media (min-width: 1150px) {
      bottom: -20px;
    }

    @media (max-width: 768px) {
      width: 50px;
      left: 0px;
    }
  }

  & .section-title-label {
    margin: 0 20px;

    @media (min-width: 400px) {
      margin: 0 auto;
    }
  }
`;
