import * as React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import Carousel, { SkinSkeleton } from 'components/@client/HorizontalCarousel';
import type { PropertyMedia } from 'types/properties';

interface Props {
  isLoading?: boolean;
  items: PropertyMedia[];
  onClick: (type: string | null) => void;
}

const MediaCarousel: React.FC<Props> = ({ items, isLoading, onClick }) => {
  return (
    <Root>
      <MediaTitle>
        <FormattedMessage
          id={Message.CAROUSEL_MEDIA_TITLE}
          values={{
            mediaCount: items.length,
            span: (chunks: string) => <span>{chunks}</span>,
          }}
        />
      </MediaTitle>
      <div style={{ margin: '0 24px;', overflow: 'hidden' }}>
        <Carousel
          className="media-carousel"
          emptyStateMessage={
            <FormattedMessage id={Message.PROPERTY_PRODUCTS_NO_ITEMS} />
          }
          isLoading={isLoading}
          items={items ?? []}
          SkeletonItem={SkinSkeleton}
          renderItem={item => (
            <MediaCard
              key={item.type}
              className="media-card"
              type={item.type}
              onClick={() => onClick(item.type)}
            >
              <img src={item.file} alt={item.name} />
            </MediaCard>
          )}
        />
      </div>
    </Root>
  );
};

export default MediaCarousel;

const Root = styled.div`
  margin: 0;
  & .media-carousel {
    margin: 0;
    ul {
      margin: 0;
    }
  }
`;

const MediaTitle = styled.div`
  margin: 24px;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  span {
    color: ${({ theme }) => theme.colors.primary.lightMobile};
  }
`;

const MediaCard = styled.li<{ src?: string; type: string }>`
  position: relative;
  cursor: pointer;
  width: 256px;
  height: 299px;
  border-radius: 10px;
  margin: 0 8px !important;
  background-image: url(${({ src }) => src});
  background-size: contain;
  transition: opacity 0.125s ease-in;
  &:hover {
    opacity: 0.8;
  }
  &:after {
    position: absolute;
    content: '';
    bottom: -20px;
    left: 10px;
    font-size: 14px;
    font-weight: 500;
  }
  img {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }
`;
