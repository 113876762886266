import * as React from 'react';
import styled from 'styled-components';
import { clientOrderList } from 'api/transform';
import { useFetch } from 'hooks';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import SectionTitle from 'components/@client/SectionTitle';
import ProductSkeleton from 'components/@client/ProductsSection/ProductItem/ProductSkeleton';
import { isEmpty } from 'utils/lodash.utils';
import { LogsAPI } from 'api/methods';
import { userLogData } from 'mockData/userLogData';
import OrdersListItem, { OrderHeading } from './OrdersListItem';
import OrdersSkeletonItem from './OrderSkeletonItem';
import SkeletonList from '../SkeletonList/SkeletonList';

const NoOrdersPlaceHolder = React.lazy(
  () =>
    import('pages/appClient/properties/property/Components/NoOrdersPlaceHolder')
);

interface Props {
  propertyId?: string;
  showTitle?: boolean;
}

const Orders: React.FC<Props> = ({ propertyId, showTitle = true }) => {
  const { data: orders, isLoading } = useFetch({
    initialUrl: `api/orders/`,
    config: { params: propertyId ? { property: propertyId } : undefined },
    transform: clientOrderList,
  });

  return (
    <Root className="property-orders">
      {showTitle && (
        <SectionTitle
          label={<FormattedMessage id={Message.PROPERTY_ORDERS_TITLE} />}
          borderColor="#4E6C54"
        />
      )}
      <List>
        <React.Suspense fallback={<ProductSkeleton />}>
          {!isLoading && isEmpty(orders) ? (
            <NoOrdersPlaceHolder />
          ) : (
            <>
              {isLoading ? (
                <SkeletonList Item={OrdersSkeletonItem} count={3} />
              ) : (
                orders?.map((order, index) => (
                  <CustomDiv
                    key={order.id}
                    onClick={() => {
                      if (propertyId)
                        LogsAPI.postUserLogs({
                          area: userLogData.eventArea.myProperties,
                          section: userLogData.eventSection.propertyPage,
                          name: userLogData.eventName.order,
                          path: userLogData.eventPath.appPropertiesIdPath,
                          metadata: {
                            property_id: propertyId,
                            order_id: order.id.toString(),
                          },
                        });
                      else {
                        LogsAPI.postUserLogs({
                          area: userLogData.eventArea.myOrders,
                          section: userLogData.eventSection.orders,
                          name: userLogData.eventName.openOrder,
                          path: userLogData.eventPath.appOrderPath,
                          metadata: { order_id: order.id.toString() },
                        });
                      }
                    }}
                  >
                    <OrdersListItem
                      key={order.id}
                      order={order}
                      index={index}
                    />
                  </CustomDiv>
                ))
              )}
            </>
          )}
        </React.Suspense>
      </List>
    </Root>
  );
};

export { OrderHeading };
export default React.memo(Orders);

const Root = styled.div`
  margin: 0 15px;

  @media (min-width: 1150px) {
    margin: 0 160px;
  }
`;
const List = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
`;

const CustomDiv = styled.div`
  flex-grow: 100;
  padding-top: 20px;
`;
