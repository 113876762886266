import * as React from 'react';
import Breadcrumbs from 'components/@client/Breadcrumbs';
import { ClientRouter } from 'router/routes';
import { ClientRoot } from 'styles/sharedStyle';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import { userLogData } from 'mockData/userLogData';
import { LogsAPI } from 'api/methods';
import {
  IAddress,
  SupportedCountry,
  SupportedLanguage,
} from 'types/common.types';
import { CheckOutPersonalDetailPayload } from 'types/orders';
import {
  useAuthentication,
  useFetch,
  useNotifications,
  useRouter,
  useSession,
} from 'hooks';
import { METHODS } from 'api/client';
import { AddressRequest } from 'api/transform';
import { removeEmptyValues, saveLocalStorage } from 'utils/common.utils';
import { GUEST_TOKEN } from 'constants/localstorage.constants';
import { CheckoutNotForSaleModal } from 'components/@client/CheckoutNotForSaleModal/CheckoutNotForSaleModal';
import { formatDate, FILTER_DATE_FORMAT } from 'utils/dates.utils';
import Loader from 'components/Loader/Loader';
import { SelectedProductCoin } from 'types/products';
import { Skin } from 'types/properties';
import { stringifyAddress } from 'utils/addresses.utils';
import WebView from './Checkout-new.web';
import { CheckoutProps, useCheckout } from './Checkout.config';
import PaymentModal from './PaymentModal';

interface Props extends CheckoutProps {
  skin?: Skin | null;
  coins?: SelectedProductCoin | null;
  hs?: string;
  onProccess: VoidFunction;
}

const Checkout: React.FC<Props> = ({ skin, coins, hs, onProccess }) => {
  const [open, setOpen] = React.useState<boolean>(false);

  const { loading: isUsertLoading } = useAuthentication();
  const [stripeCredentials, setStripeCredentials] = React.useState<{
    publicKey: string;
    clientKey: string;
  }>({ publicKey: '', clientKey: '' });
  const props = useCheckout();
  const {
    cart,
    propertyCart,
    personalDetail,
    address,
    deliveryDate,
    isNotForSaleModalOpen,
    setIsNotForSaleModalOpen,
    updateUrl,
    userId,
  } = props;

  const toggle = React.useCallback(() => {
    if (!open && address) {
      LogsAPI.postUserLogs({
        name: userLogData.eventName.proceed,
        area: userLogData.eventArea.myCart,
        section: userLogData.eventSection.cart,
        path: userLogData.eventPath.appCartCartCheckOutId,
      });
      setOpen(prev => !prev);
    }
  }, [open, address]);
  const { locale, country } = useSession();
  const { showNotification } = useNotifications();

  const [guestAddressState, setGuestAddressState] = React.useState<{
    isValid: boolean;
    guestAddress: IAddress | null;
  }>({ isValid: false, guestAddress: null });

  const shippingDate = formatDate(deliveryDate, FILTER_DATE_FORMAT);
  const shippingAddress = React.useMemo(() => stringifyAddress(address) ?? '', [
    address,
  ]);
  const updateGuestAddress = React.useCallback(
    (isValid: boolean, guestAddress: IAddress) => {
      if (guestAddressState.isValid !== isValid)
        setGuestAddressState({ isValid, guestAddress });
    },
    [guestAddressState.isValid]
  );
  const [
    guestPersonalDetails,
    setGuestPersonalDetails,
  ] = React.useState<CheckOutPersonalDetailPayload>();
  const { callFetch: callGuestCheckout, isLoading } = useFetch({
    initialUrl: '/api/checkout_cart/guest_user_checkout/',
    skipOnStart: true,
    onSuccess: (data: { token: string } | null) => {
      if (data) saveLocalStorage(GUEST_TOKEN, data.token);
      /* eslint-disable-next-line */
      if (true) {
        setOpen(true);
      } else {
        handleStripeSubmit();
      }
    },
    config: {
      method: METHODS.POST,
      isGuestAllowed: true,
    },
  });

  const Sum = React.useMemo(
    () => skin?.finalPrice.toFixed(2) ?? cart?.finalPrice.toFixed(2),
    [cart?.finalPrice, skin?.finalPrice]
  );
  const {
    query: { skinId },
  } = useRouter<{ cartId: string }>();

  const onSuccess = React.useCallback(
    res => {
      showNotification({
        key: 'clients/stripePaymentSuccess',
        message: 'Payment initiated successfully',
        severity: 'success',
      });
      setStripeCredentials({
        publicKey: res.publicKey,
        clientKey: res.clientSecret,
      });
      setOpen(true);
    },
    [showNotification, setStripeCredentials]
  );

  const onFailure = React.useCallback(() => {
    showNotification({
      key: 'clients/stripePaymentFailure',
      message: 'error in initiating payment',
      severity: 'error',
    });
  }, [showNotification]);

  const { callFetch: stripePayment, isLoading: stripeIsLoading } = useFetch({
    initialUrl: '/api/payment/create-stripe-checkout-session/',
    skipOnStart: true,
    config: {
      method: METHODS.POST,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    },
    onSuccess,
    onFailure,
  });

  const handleStripeSubmit = React.useCallback(() => {
    return stripePayment({
      data: removeEmptyValues({
        amount: 1100,
        currency: 'usd',
        description: 'Data about purchase',
        user_id: userId,
        checkout_cart_id: cart?.id,
        sum: Sum,
        shipping_date: shippingDate,
        index: 11,
        // json_purchase_data: encodeURIComponent(JSON.stringify(purchaseData)),
        trBgColor: 'fcfcfc',
        cred_type: 1,
        nologo: 1,
        lang: locale === SupportedLanguage.HEBREW ? 'il' : 'us',
        trButtonColor: 'FF3100',
        mobile:
          personalDetail?.phoneNumber ?? guestPersonalDetails?.phoneNumber,
        contact: personalDetail?.name ?? guestPersonalDetails?.name,
        address: shippingAddress,
        email: personalDetail?.email ?? guestPersonalDetails?.email,
        city: address?.city,
        u71: 1,
        tranmode: 'A',
        tour_section: 1,
        skin_id: skinId,
        coins: coins?.id,
        thtk: cart?.hs ?? hs,
        new_process: 1,
        Ilang: country === 'IL' ? 'heb' : 'eng',
        coin_product: coins,
        product_price: 11,
        product_quantity: 1,
      }),
    });
  }, [
    stripePayment,
    userId,
    Sum,
    cart?.id,
    shippingDate,
    address?.city,
    cart?.hs,
    coins,
    country,
    hs,
    locale,
    personalDetail?.email,
    personalDetail?.name,
    personalDetail?.phoneNumber,
    guestPersonalDetails?.email,
    guestPersonalDetails?.name,
    guestPersonalDetails?.phoneNumber,
    // purchaseData,
    shippingAddress,
    skinId,
  ]);

  const updateGuestPersonalDetails = React.useCallback(
    (data: CheckOutPersonalDetailPayload) => {
      if (data !== guestPersonalDetails) {
        setGuestPersonalDetails(data);
        callGuestCheckout({
          method: METHODS.POST,
          isGuestAllowed: true,
          data: {
            user: {
              first_name: data.name.trim().split(' ')[0],
              last_name: data.name.trim().split(' ')[1] ?? '',
              email: data.email,
              telephone_number: data.phoneNumber,
            },
            user_address: guestAddressState.guestAddress
              ? AddressRequest(guestAddressState.guestAddress)
              : {},
          },
        });
      }
    },
    [callGuestCheckout, guestAddressState.guestAddress, guestPersonalDetails]
  );

  return (
    <>
      <ClientRoot>
        <Breadcrumbs
          links={[
            {
              to: propertyCart,
              children: <FormattedMessage id={Message.CART_TITLE} />,
            },
            {
              to: ClientRouter.CHECKOUT,
              children: <FormattedMessage id={Message.CHECKOUT_TITLE} />,
            },
          ]}
        />

        {isUsertLoading ? (
          <Loader />
        ) : (
          <WebView
            openModal={toggle}
            onProccess={() => setOpen(true)}
            updateGuestAddress={updateGuestAddress}
            guestAddressState={guestAddressState}
            updateGuestPersonalDetails={updateGuestPersonalDetails}
            isLoading={
              country === SupportedCountry.ISRAEL ? isLoading : stripeIsLoading
            }
            setStripeCredentials={setStripeCredentials}
            handleStripeSubmit={handleStripeSubmit}
            {...props}
          />
        )}
      </ClientRoot>
      <PaymentModal
        deliveryDate={deliveryDate}
        personalDetail={personalDetail ?? guestPersonalDetails}
        address={address ?? guestAddressState.guestAddress}
        cart={cart}
        open={open}
        publicKey={stripeCredentials.publicKey}
        clientSecretKey={stripeCredentials.clientKey}
        onClose={() => {
          setOpen(false);
        }}
      />
      <CheckoutNotForSaleModal
        isOpen={isNotForSaleModalOpen}
        toggle={() => setIsNotForSaleModalOpen(prev => !prev)}
        cartApi={() => {
          props.cartAPI({
            url: `api/checkout_cart/${cart?.id}/exclude_not_for_sale/`,
            method: METHODS.POST,
          });
          updateUrl(`api/checkout_cart/${cart?.id}/`);
        }}
      />
    </>
  );
};

export default Checkout;
