import * as React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useFetch, useRouter } from 'hooks';
import styled from 'styled-components';
import { MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';
import Loader from 'components/Loader/Loader';
import { METHODS } from 'api/client';
import { PaginatedProducts, SearchProducts } from 'api/transform';
import { Product } from 'types/products';
import Breadcrumbs from 'components/@client/Breadcrumbs/Breadcrumbs';
import { useLocation } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import { Message } from 'i18n';
import Divider from '@material-ui/core/Divider';
import { LogsAPI } from 'api/methods';
import { SEARCH_PRODUCT } from 'constants/common.constants';
import Icon from 'components/Icon';
import MobileSearch from './MobileSearch';
import DesktopSearch from './DesktopSearch';

const SearchPage = () => {
  const intl = useIntl();
  const isMobile = useMediaQuery(MOBILE_TABLET_RESOLUTION);
  const location = useLocation();
  const { query, history } = useRouter<{
    searchName: string;
    supplierId: number;
  }>();
  const { data, callFetch: callSearch, isLoading: isSearchLoading } = useFetch({
    initialUrl: 'api/search/',
    skipOnStart: true,
    config: {
      method: METHODS.GET,
      params: {
        query: query.searchName,
      },
    },

    transform: SearchProducts,
  });

  const {
    data: productData,
    callFetch: loadMoreProducts,
    isLoading: productsLoading,
  } = useFetch({
    initialUrl: 'api/products/',
    skipOnStart: true,
    config: {
      method: METHODS.GET,
    },
    transform: PaginatedProducts,
  });

  const [page, setPage] = React.useState(1);
  const [productList, setProductList] = React.useState<Product[] | undefined>();
  const [supplierName, setSupplierName] = React.useState('');
  const handleSupplier = React.useCallback(
    (name: string) => setSupplierName(name),
    []
  );
  const increasePage = React.useCallback(() => {
    setPage(prev => prev + 1);
  }, []);
  const showMoreBtn = React.useMemo(
    () =>
      (data?.products &&
        productList?.length &&
        !query.supplierId &&
        data.products?.count > productList.length) ||
      (productData &&
        productList?.length &&
        query.supplierId &&
        productData.count > productList.length),
    [productList, data, productData, query]
  );

  const links = [
    {
      to: location.pathname,
      children: `${intl.formatMessage({
        id: Message.SEARCH_RESULT_FOR,
      })} ${query.searchName}`,
    },
  ];

  React.useEffect(() => {
    LogsAPI.pushDataLayer([
      { ecommerce: null },
      {
        ecommerce: {
          impressions: data?.products.results.map((r, i) => ({
            name: r.name,
            id: r.id,
            price: r.price,
            brand: r.manufacture.name,
            category: r.category,
            list: SEARCH_PRODUCT,
            position: i + 1,
          })),
        },
      },
    ]);
    setProductList(prevProductList =>
      Array.isArray(prevProductList) &&
      Array.isArray(data?.products?.results) &&
      data?.products
        ? [...prevProductList, ...data.products?.results]
        : data?.products?.results
    );
  }, [data]);

  React.useEffect(() => {
    setProductList(prevProductList =>
      Array.isArray(prevProductList) &&
      Array.isArray(productData?.results) &&
      productData
        ? [...prevProductList, ...productData?.results]
        : productData?.results
    );
  }, [productData]);

  React.useEffect(() => {
    if (!query.supplierId) {
      callSearch({
        params: { query: query.searchName, size: 12, page },
      });
    } else {
      loadMoreProducts({
        params: { supplier: query.supplierId, size: 12, page },
      });
    }
  }, [query, callSearch, page, loadMoreProducts]);

  React.useEffect(() => {
    setPage(1);
    setProductList([]);
  }, [query]);

  if (!query.searchName || (isSearchLoading && !productList?.length)) {
    return (
      <LoaderDiv>
        <Loader />
      </LoaderDiv>
    );
  }

  return (
    <Root>
      <BreadcrumbsSection>
        <Breadcrumbs links={links} />
      </BreadcrumbsSection>
      <Separator />
      <HeadLine>
        <Icon name="left-arrow" size={20} onClick={() => history.goBack()} />
        {/* <LeftArrow  /> */}
        <FormattedMessage id={Message.YOUR_SEARCH_RESULT_FOR} />
        <h2>
          "{(query.supplierId && supplierName) || query.searchName}"
          <CounterContainer>
            ({(query.supplierId ? productData?.count : data?.query.count) || 0})
          </CounterContainer>
        </h2>
      </HeadLine>
      <Separator />
      {isMobile ? (
        <MobileSearch
          searchResults={data}
          searchName={query.searchName}
          supplierId={query.supplierId}
          pageNo={page}
          setPageNo={increasePage}
          showMoreBtn={!!showMoreBtn}
          productList={productList || []}
          isLoading={isSearchLoading || productsLoading}
          productData={productData}
          handleSupplier={handleSupplier}
        />
      ) : (
        <DesktopSearch
          searchResults={data}
          searchName={query.searchName}
          supplierId={query.supplierId}
          pageNo={page}
          setPageNo={increasePage}
          showMoreBtn={!!showMoreBtn}
          productList={productList || []}
          isLoading={isSearchLoading || productsLoading}
          productData={productData}
          handleSupplier={handleSupplier}
        />
      )}
    </Root>
  );
};

export default SearchPage;

const Root = styled.div`
  margin: 25px;
`;

const BreadcrumbsSection = styled.div`
  span.link {
    font-size: 1.125rem;
  }
`;

const HeadLine = styled.div`
  text-align: center;
  font-size: 1rem;
  h2 {
    margin: 10px 0;
  }
  svg {
    position: absolute;
    left: 24px;
    height: 54px;
    width: 20px;
    stroke: transparent;
    color: rgba(0, 0, 0, 0.12);
    path {
      stroke: rgba(0, 0, 0, 0.55);
    }
  }
`;

const CounterContainer = styled.span`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.black0};
`;

const LoaderDiv = styled.div`
  margin-top: 25%;
`;

const Separator = styled(Divider)`
  margin: 10px 0;
`;
