import * as React from 'react';
import { Product } from 'types/products';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import Button from 'components/@client/Button/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import { Message } from 'i18n';
import { ReactComponent as InfoIcon } from 'assets/icons/ideaInfo.svg';
import FormattedCurrency from 'components/@client/FormattedCurrency/FormattedCurrency';
import { Link } from 'react-router-dom';
import { useRouter } from 'hooks';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const ProductToolTip: React.FC<{
  product?: Product;
  addToCart: (product: Product, quantity?: number) => void;
  onClose: VoidFunction;
  isLoading: boolean;
}> = ({ product, addToCart, onClose, isLoading }) => {
  const { history } = useRouter();
  const intl = useIntl();

  return (
    <RootContainer>
      <div className="x-button">
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <StyledLink to={`${history.location.pathname}?productId=${product?.id}`}>
        <FlexDiv>
          <div>
            <div className="category-name">
              {(intl.locale === 'he' && product?.subCategoryAltName) ||
                product?.subCategory}
            </div>
            <div className="product-name">
              {(intl.locale === 'he' && product?.altName) || product?.name}
            </div>
            <div className="product-msrp">
              {product && (
                <>
                  <FormattedCurrency value={product?.price} />
                  {product?.msrp > product?.price && (
                    <>
                      <ProductMSRPPrice>
                        <FormattedCurrency value={product?.msrp} />
                      </ProductMSRPPrice>
                    </>
                  )}
                </>
              )}
            </div>
            <div className="add-to-cart-btn">
              <Button
                isLoading={isLoading}
                className="buyNow-button"
                onClick={e => {
                  e.preventDefault();
                  if (product) addToCart(product);
                }}
              >
                <FormattedMessage id={Message.ADD_TO_CART} />
              </Button>
              <InfoIcon />
            </div>
          </div>
          <ImageContainer>
            <img alt={product?.name} src={product?.thumbnail.file} />
          </ImageContainer>
        </FlexDiv>
      </StyledLink>
    </RootContainer>
  );
};

const RootContainer = styled.div`
  min-width: 369px;
  color: black;
  .x-button {
    ${rtl`
      position:absolute;
      right:12px;
      top:8px;
      svg{
        width:14px;
        height:14px;
      }
    `}
  }
`;

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`;

const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
  div:first-child {
    // max-width: 50%;
  }
  .category-name {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #696969;
  }
  .product-name {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    margin-top: 4px;
  }
  .product-msrp {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin-top: 4px;
    color: ${({ theme }) => theme.colors.primary.lightMobile};
  }
  .add-to-cart-btn {
    display: flex;
    margin-top: 23px;
    gap: 8px;
    .buyNow-button {
      width: 128px;
      height: 30px;
      padding: 7px 23px;
      border-radius: 8px;
      background: #fc5c42;
      border: 1px solid #fc5c42;
      .MuiButton-label {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
      }
    }
    svg {
      border: 1px solid #3e3e3e;
      border-radius: 8px;
    }
  }
`;

const ImageContainer = styled.div`
  width: 131px;
  height: 116px;
  border-radius: 10px;
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
`;

const ProductMSRPPrice = styled.span`
  color: black;
  text-decoration: line-through;
  margin: 0 15px;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  align-items: center;
  text-decoration-line: line-through;
  color: #696969;
  opacity: 0.5;
`;

export default ProductToolTip;
