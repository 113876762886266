import styled, { css } from 'styled-components';
import rtl from 'styled-components-rtl';
import type { Option } from 'components/Autocomplete/Autocomplete.config';
import Checkbox from 'components/Checkbox';

const ClientCheckboxItem = (values: Option[] = []) => (
  option: Option,
  onClick?: React.MouseEventHandler<HTMLLIElement>
) => {
  return (
    <MyListItem
      id={option?.name.toString()}
      className="colorOption"
      onClick={onClick}
    >
      <CardContainer>
        <Checkbox
          className="checkbox-input"
          tabIndex={-1}
          checked={values.some(item => item?.name === option?.name)}
        />
        <ItemName isSelected={values.some(item => item?.name === option?.name)}>
          {option?.name as string}
        </ItemName>
      </CardContainer>
    </MyListItem>
  );
};

export default ClientCheckboxItem;

const CardContainer = styled.div`
  background-color: inherit;

  width: 200px;

  display: flex;
  margin: 5px 0;
`;

const ItemName = styled.span<{ isSelected: boolean }>`
  white-space: nowrap;
  margin: 0 5px;
  height: 20px;
  font-size: 16px;

  width: 110px;
  ${({ theme, isSelected }) =>
    isSelected &&
    css`
      color: ${theme.colors.primary.lightMobile};
    `}
`;

const MyListItem = styled.li`
  &:hover {
    background-color: #f2f2f2;
  }
  position: relative !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  ${rtl`
  padding-left: 14px;
  margin-right:14px;
  `}
  .icon.checked {
    background-color: ${({ theme }) => theme.colors.primary.lightMobile};
  }
  height: 40px;
  width: 177px;
`;
