import * as React from 'react';
import styled, { css, keyframes } from 'styled-components';
import * as Response from 'api/responses';
import { FormattedMessage, useIntl } from 'react-intl';
import IconButton, { IconName } from 'components/Button/IconButton';
import rtl from 'styled-components-rtl';
import IconSVG from 'components/Icon/Svg';
import Icon from 'components/Icon';
import Title from 'components/@client/TopTitle/Title';
import { Message } from 'i18n';
import Button from 'components/@client/Button';
import ProjectAddressImage from 'assets/images/projects/project_address.png';
import DeveloperNameImage from 'assets/images/projects/developer_name.png';
import PropertiesImage from 'assets/images/projects/properties.png';
import no_image_placeholder from 'assets/@client/project/no-image-mody.png';
import Slider from 'react-slick';
import { ArrowBase } from 'components/@client/SlideshowModal/Arrows';
import CustomTooltip from 'components/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { addNewUnitSchema } from 'utils/validation.utils';
import { useFetch, useNotifications, useRouter } from 'hooks';
import { Properties, singleProjectDetails } from 'api/transform';
import { METHODS } from 'api/client';
import MobileHeader from 'components/@client/MobileHeader';
import {
  LAPTOP_MAX_RESOLUTION,
  TABLET_RESOLUTION,
  MOBILE_RESOLUTION,
  MOBILE_TABLET_RESOLUTION,
} from 'styles/sharedStyle';
import { ProjectDetail, Units } from 'types/projects';
import Loader from 'components/Loader/Loader';
import { Property } from 'types/properties';
import Breadcrumbs from 'components/@client/Breadcrumbs/Breadcrumbs';
import { ClientRouter } from 'router/routes';
import { GetPropertiesDataResponsePayload } from 'store/types/user-management/properties.types';
import { getRoute } from 'utils/sidebar.utils';
import { useMediaQuery } from '@material-ui/core';
import { ReactComponent as ContentCopy } from 'assets/@client/project/share-icon.svg';
import { FileStatus, FileTask } from 'types/suppliers';
import * as Transform from 'api/transform';
import { ReactComponent as LoaderProjects } from 'assets/icons/loader-projects.svg';
import { ReactComponent as SuccessTick } from 'assets/icons/success.svg';
import HeaderBox from './components/HeaderBox';
import PropertyCard from './components/PropertyCard';
import TypesCard from './components/TypesCard';
import UnitForm from './components/UnitForm';
import EditProjectForm from './components/EditProjectForm';
import AddCustomerListForm from './components/AddCustomerListForm';

const MyProjects = () => {
  const [propertyParams, setPropertyParams] = React.useState({
    search: '',
    page: 1,
    size: 6,
  });
  const isMobile = useMediaQuery(MOBILE_TABLET_RESOLUTION);
  const isTablet = useMediaQuery('(min-width: 767px) and (max-width: 1024px)');
  const { showNotification } = useNotifications();
  const [isOpenUnitForm, setIsOpenUnitForm] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [unit, setUnit] = React.useState<Units>();
  const [isOpenEditProjectForm, setIsOpenEditProjectForm] = React.useState(
    false
  );
  const [
    isOpenAddCustomerListForm,
    setIsOpenAddCustomerListForm,
  ] = React.useState(false);
  const [isBulkCoownerOpen, setIsBulkCoownerOpen] = React.useState(false);
  const [prevIndex, setPrevIndex] = React.useState<number>(0);
  const [prevIndexProperty, setPrevIndexProperty] = React.useState<number>(0);
  const [propertyList, setPropertyList] = React.useState<Property[]>([]);
  const intl = useIntl();
  const { query, history } = useRouter<{
    id: string;
  }>();
  const newUnitInitialValues = {
    buildingName: '',
    typeName: '',
    floorPlan: null,
  };

  const methods = useForm({
    resolver: yupResolver(addNewUnitSchema),
    defaultValues: newUnitInitialValues,
    mode: 'all',
  });

  const onFailure = React.useCallback(
    (message: string) => {
      showNotification({
        key: 'clients/myProjectsRejected',
        message,
        severity: 'error',
      });
    },
    [showNotification]
  );

  const {
    callFetch: getProjectDetails,
    data: projectData,
    isLoading,
  } = useFetch<ProjectDetail, Response.ResponseProjectDetail>({
    initialUrl: `/api/projects/${query?.projectId}/`,
    skipOnStart: true,
    config: {
      method: METHODS.GET,
    },
    onFailure,
    transform: singleProjectDetails,
  });

  const { callFetch: getproperties, data: propertiesData } = useFetch<
    GetPropertiesDataResponsePayload,
    Response.Properties
  >({
    initialUrl: `/api/projects/${query?.projectId}/properties/`,
    skipOnStart: true,
    config: {
      method: METHODS.GET,
    },
    onFailure,
    onSuccess: data => {
      if (propertyParams.page === 1) setPropertyList(data?.results ?? []);
      else setPropertyList(prev => [...prev, ...(data?.results ?? [])]);
    },
    transform: Properties,
  });

  const currentValue = React.useMemo(() => {
    return isMobile ? 1.2 : 6;
  }, [isMobile]);

  React.useEffect(() => {
    if (!isOpenUnitForm) {
      setUnit(undefined);
    }
  }, [isOpenUnitForm]);

  React.useEffect(() => {
    if (query?.projectId) {
      getproperties();
      getProjectDetails();
    }
    // eslint-disable-next-line
  }, [query?.projectId]);

  React.useEffect(() => {
    getproperties({
      params: propertyParams,
    });
    // eslint-disable-next-line
  }, [propertyParams]);

  const settings = {
    dots: false,
    infinite: false,

    afterChange: (index: number) => {
      setPrevIndex(index);
    },
  };

  const settingsProperty = {
    dots: false,
    infinite: false,
    slidesToShow: 2.5,
    slidesToScroll: 1,
    afterChange: (index: number) => {
      if (
        index >= propertyList.length / 2.5 - 1 &&
        propertyList.length !== propertiesData?.count
      ) {
        setPropertyParams(prev => ({ ...prev, page: prev.page + 1 }));
      }
      setPrevIndexProperty(index);
    },
  };

  const NextArrow = (props: {
    onClick?: React.MouseEventHandler<HTMLDivElement>;
  }) => {
    const { onClick } = props;
    return (
      <ArrowContainer
        role="button"
        isOpen={true}
        onClick={onClick}
        prev={false}
        prevIndex={Math.floor(prevIndex)}
        mediaLength={projectData?.types?.length}
        currentValue={currentValue}
      >
        <ArrowIcon className="next" />
      </ArrowContainer>
    );
  };

  const NextArrowProperty = (props: {
    onClick?: React.MouseEventHandler<HTMLDivElement>;
  }) => {
    const { onClick } = props;
    return (
      <ArrowContainerProperty
        role="button"
        isOpen={true}
        onClick={onClick}
        prev={false}
        prevIndex={Math.floor(prevIndexProperty)}
        mediaLength={propertiesData?.count}
        currentValue={6}
      >
        <ArrowIcon className="next" />
      </ArrowContainerProperty>
    );
  };

  const PrevArrow = (props: {
    onClick?: React.MouseEventHandler<HTMLDivElement>;
  }) => {
    const { onClick } = props;

    return (
      <ArrowContainer
        role="button"
        isOpen={true}
        onClick={onClick}
        prev={true}
        prevIndex={Math.floor(prevIndex)}
        mediaLength={projectData?.types?.length}
        currentValue={currentValue}
      >
        <ArrowIcon className="prev" />
      </ArrowContainer>
    );
  };

  const PrevArrowProperty = (props: {
    onClick?: React.MouseEventHandler<HTMLDivElement>;
  }) => {
    const { onClick } = props;

    return (
      <ArrowContainerProperty
        role="button"
        isOpen={true}
        onClick={onClick}
        prev={true}
        prevIndex={Math.floor(prevIndexProperty)}
        mediaLength={propertiesData?.count}
        currentValue={6}
      >
        <ArrowIcon className="prev" />
      </ArrowContainerProperty>
    );
  };

  const links = [
    {
      to: ClientRouter.PROPERTIES_SELLER,
      children: <FormattedMessage id={Message.PROPERTIES_INFO_BOX_TITLE} />,
    },
    {
      to: ClientRouter.PROPERTIES_SELLER_LISTING,
      children: <FormattedMessage id={Message.MYPROJECT} />,
    },
    { to: '', children: projectData?.projectName },
  ];

  const timeOutRef = React.useRef<ReturnType<typeof setInterval> | null>(null);
  const [task, setTask] = React.useState<FileTask | null>();
  const [isLoadingFlag, setIsLoadingFlag] = React.useState(false);

  const getAsyncTaskSuccess = (response: Response.ResponseFile | null) => {
    if (response?.status === FileStatus.Done) {
      if (timeOutRef.current) {
        clearInterval(timeOutRef.current);
        timeOutRef.current = null;
      }
      if (response) {
        setTask(Transform.FileTaskResponse(response));
        setIsLoadingFlag(false);
      }
    } else if (response?.status === FileStatus.Fail) {
      if (timeOutRef.current) {
        clearInterval(timeOutRef.current);
        timeOutRef.current = null;
      }
      if (response) {
        showNotification({
          key: 'clients/csvUploadRejected',
          message: intl.formatMessage({ id: Message.UPLOAD_FILE_FAIL }),
          severity: 'error',
        });
        setIsLoadingFlag(false);
      }
    }
  };

  const { callFetch: getAsyncTask } = useFetch({
    initialUrl: `/api/async_tasks/`,
    skipOnStart: true,
    config: {
      method: METHODS.GET,
    },
    onSuccess: getAsyncTaskSuccess,
  });

  const onSuccess = React.useCallback(
    response => {
      getAsyncTask({ url: `/api/async_tasks/${response?.id}/` });
      const timeOut = setInterval(
        () => getAsyncTask({ url: `/api/async_tasks/${response?.id}/` }),
        5000
      );
      timeOutRef.current = timeOut;
      setTimeout(() => clearInterval(timeOut), 120000);
      setTimeout(() => {
        setIsLoadingFlag(false);
      }, 120000);
    },
    [getAsyncTask]
  );

  const onFailureAsyncTask = React.useCallback(
    (message: string) => {
      showNotification({
        key: 'clients/csvUploadRejected',
        message,
        severity: 'error',
      });
      setIsLoadingFlag(false);
    },
    [showNotification]
  );

  const { callFetch: uploadCustomerList } = useFetch<{}>({
    initialUrl: `/api/projects/${query?.projectId}/bulk-add-co-owners-csv/`,
    skipOnStart: true,
    config: {
      method: METHODS.POST,
    },
    onFailure: onFailureAsyncTask,
    onSuccess,
  });

  const downloadCSV = () => {
    const href = task?.missingDataFile;
    const link = document.createElement('a');
    link.href = href || '';
    link.setAttribute('download', `${task?.fileName}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      {isMobile && !isTablet && (
        <MobileHeader title={<FormattedMessage id={Message.PROJECT} />} />
      )}
      {isLoading ? (
        <Loader fullHeight />
      ) : (
        <MainContainer>
          <Breadcrumbs links={links} force={isTablet} />
          <MainHeaderDiv>
            <HeaderParent>
              <HeaderTitle>{projectData?.projectName}</HeaderTitle>
              <CustomTooltip
                title={<FormattedMessage id={Message.EDIT_PROJECT} />}
                $variant="info"
                data-testid="edit-new-project-button"
              >
                <span className="icon-clients-action">
                  <StyledIconButton
                    customIcon="edit"
                    $size="small"
                    iconSize={16}
                    iconName={IconName.custom_icon}
                    onClick={() => setIsOpenEditProjectForm(true)}
                  />
                </span>
              </CustomTooltip>
            </HeaderParent>
            <BottomBorder />
          </MainHeaderDiv>
          <SubHeaderDiv>
            <HeaderBox
              img={ProjectAddressImage}
              alt="project-address"
              title={
                <FormattedMessage
                  id={Message.PROJECT_ADDRESS_FORM_MY_PROJECT_TITLE}
                />
              }
              subTitle={`${projectData?.street} , ${projectData?.city}`}
            />
            <HeaderBox
              img={DeveloperNameImage}
              alt="developer-name"
              title={
                <FormattedMessage
                  id={Message.DEVELOPER_NAME_MY_PROJECT_TITLE}
                />
              }
              subTitle={projectData?.developerDetails?.name}
            />
            <HeaderBox
              img={PropertiesImage}
              alt="properties-number"
              title={<FormattedMessage id={Message.NUMBER_OF_PROPERTIES} />}
              subTitle={projectData?.propertyCount}
            />
          </SubHeaderDiv>
          <ImagesContainer>
            <LeftImage>
              <Images
                src={
                  projectData?.developerDetails?.logo ?? no_image_placeholder
                }
                alt={projectData?.developerDetails?.logo ?? ''}
              />
            </LeftImage>
            <RightImage>
              <Image src={projectData?.coverPhoto ?? ''} alt="left" />
            </RightImage>
          </ImagesContainer>
          <PropertiesSection>
            <Title
              showBack={false}
              title={
                <FormattedMessage
                  id={Message.PROPERTIES_SECTION_TITLE}
                  values={{
                    properties: propertiesData?.count,
                    span: (chunks: string) => <StyledSpan>{chunks}</StyledSpan>,
                  }}
                />
              }
            />
            <PropertySearch>
              <CustomTooltip
                placement="top"
                $variant="info"
                title={
                  <FormattedMessage
                    id={Message.PROJECT_UNIT_CREATE_USER_FILE}
                  />
                }
              >
                <CustomIconButton
                  onClick={() => {
                    uploadCustomerList();
                    setIsBulkCoownerOpen(true);
                    setIsLoadingFlag(true);
                  }}
                >
                  <ContentCopy />
                </CustomIconButton>
              </CustomTooltip>
              <div className="search-input-div">
                <input
                  placeholder={intl.formatMessage({
                    id: Message.ENTER_APARTMENT_NUMBER_PLACEHOLDER,
                  })}
                  value={propertyParams.search}
                  onChange={e =>
                    setPropertyParams(prev => ({
                      ...prev,
                      page: 1,
                      search: e.target.value,
                    }))
                  }
                />
              </div>
              <Button
                className="search-button"
                disabled={!propertyParams.search.length}
              >
                <FormattedMessage id={Message.PROJECT_SEARCH} />
              </Button>
            </PropertySearch>

            <PropertiesContainer>
              <Slider
                {...settingsProperty}
                swipeToSlide={true}
                nextArrow={<NextArrowProperty />}
                prevArrow={<PrevArrowProperty />}
              >
                {propertyList.length &&
                  propertyList.map(property => {
                    return (
                      <PropertyCard
                        key={property?.id}
                        id={property?.id}
                        co_owner={property?.co_owner}
                        img={property?.media?.[0]?.file}
                        alt={`property-image-${property.id}`}
                        propertyName={property.name}
                        buildingName={property?.unit?.building}
                        typeName={property?.unit?.type}
                        lastModified={property.updatedAt}
                        onClick={() =>
                          history.push(
                            getRoute([
                              ClientRouter.PROPERTIES,
                              `/${property.id}?projectid=${query?.projectId}&name=${projectData?.projectName}`,
                            ])
                          )
                        }
                      />
                    );
                  })}
              </Slider>
            </PropertiesContainer>
          </PropertiesSection>
          <TypesSection>
            <Title
              showBack={false}
              title={
                <FormattedMessage
                  id={Message.TYPES_SECTION_TITLE}
                  values={{
                    types: projectData?.types?.length,
                    span: (chunks: string) => <StyledSpan>{chunks}</StyledSpan>,
                  }}
                />
              }
            />
            <TypesContainer>
              <Slider
                {...settings}
                slidesToShow={currentValue}
                swipeToSlide={true}
                nextArrow={<NextArrow />}
                prevArrow={<PrevArrow />}
              >
                {projectData &&
                  projectData?.types?.length > 0 &&
                  projectData?.types.map(type => {
                    return (
                      <TypesCard
                        key={type.id}
                        img={type.floorPlan[0]?.file}
                        alt={`type-image-${type.id}`}
                        buildingName={type.building}
                        type={type.type}
                        associatedProperties={type.propertyCount}
                        status={type.status}
                        onClick={() => {
                          setUnit(type);
                          setIsEdit(true);
                          setIsOpenUnitForm(true);
                        }}
                      />
                    );
                  })}
              </Slider>
            </TypesContainer>
          </TypesSection>
          <ButtonsContainer>
            <Button
              className="add-new-unit-button"
              startIcon={
                <IconSVG name="plus" className="icon-button" color="#fff" />
              }
              data-testid="add-new-unit-button"
              onClick={() => {
                setIsEdit(false);
                setIsOpenUnitForm(true);
              }}
            >
              <FormattedMessage id={Message.ADD_NEW_UNIT_PROJECT} />
            </Button>
            <Button
              className="add-customer-list-button"
              startIcon={
                <IconSVG name="plus" className="icon-button" color="#fff" />
              }
              onClick={() => setIsOpenAddCustomerListForm(true)}
            >
              <FormattedMessage id={Message.ADD_CUSTOMER_LIST} />
            </Button>
          </ButtonsContainer>
          <Modal open={isOpenUnitForm}>
            <FormProvider {...methods}>
              <UnitForm
                unit={unit}
                isEdit={isEdit}
                setIsOpenUnitForm={setIsOpenUnitForm}
                isValid={methods.formState?.isValid}
                isDirty={methods.formState?.isDirty}
                getProjectDetails={getProjectDetails}
              />
            </FormProvider>
          </Modal>
          <Modal open={isOpenEditProjectForm}>
            <EditProjectForm
              setIsOpenEditProjectForm={setIsOpenEditProjectForm}
              datas={projectData}
              getProjectDetails={getProjectDetails}
            />
          </Modal>
          <Modal open={isOpenAddCustomerListForm}>
            <AddCustomerListForm
              setIsOpenAddCustomerListForm={setIsOpenAddCustomerListForm}
            />
          </Modal>
          <Modal open={isBulkCoownerOpen}>
            <CustomAsyncTaskDiv>
              <div className="title">
                <FormattedMessage id={Message.PROJECT_UNIT_CREATE_USER_FILE} />
              </div>
              {isLoadingFlag && (
                <>
                  <StyledLoader />
                  <div className="subtitle">
                    <FormattedMessage
                      id={Message.PROJECT_UNIT_CREATE_USER_FILE_SUBTITLE}
                    />
                  </div>
                </>
              )}
              {!isLoadingFlag && (
                <>
                  <SuccessTick />
                  <div className="subtitle">
                    <FormattedMessage
                      id={
                        Message.PROJECT_UNIT_CREATE_USER_FILE_SUCCESS_SUBTITLE
                      }
                    />
                  </div>
                  <FlexContainer>
                    {/* <Title>
                  <FormattedMessage id={Message.FILE_UPLOADING_SUMMARY} />
                </Title> */}
                    <SuccessFlex>
                      <SuccessText>
                        <FormattedMessage id={Message.SUCCESS} />
                      </SuccessText>
                      <SuccessCount>{task?.detailsCount ?? 0}</SuccessCount>
                    </SuccessFlex>
                  </FlexContainer>
                  <DownloadCSV onClick={downloadCSV}>
                    <FormattedMessage id={Message.DOWNLOAD_CSV} />
                  </DownloadCSV>
                  <FinishButton onClick={() => setIsBulkCoownerOpen(false)}>
                    <FormattedMessage id={Message.FINISH} />
                  </FinishButton>
                </>
              )}
            </CustomAsyncTaskDiv>
          </Modal>
        </MainContainer>
      )}
    </>
  );
};

export default MyProjects;

const CustomAsyncTaskDiv = styled.div`
  min-width: 322px;

  background: #ffffff;
  /* Popup */

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  div {
    width: fit-content;
  }
  .subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #3e3e3e;
    width: fit-content;
    margin: 16px auto;
  }
  .title {
    color: #fc5c42;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    margin: 24px auto 16px;
    width: fit-content;
  }
  svg {
    display: block;
    margin: 0 auto;
  }
`;

const StyledKeyFrames = () => keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`;

const StyledLoader = styled(LoaderProjects)`
  animation: ${() => StyledKeyFrames()} 1.5s linear infinite;
  display: flex;
  margin: 16px auto 0;
`;

const CustomIconButton = styled.div`
  width: 48px;
  height: 48px;

  /* Primary Color */

  background: #fc5c42;
  /* Primary Color */

  border: 1px solid #fc5c42;
  border-radius: 10px;
  ${rtl`margin-right: 16px;`}
  cursor: pointer;
  :hover {
    background: #a50a0a;
    /* Hover Primary Button */

    border: 1px solid #a50a0a;
    circle {
      fill: #a50a0a;
    }
  }
  svg {
    margin: 5px 3px;
    circle {
      fill: #fc5c42;
    }
    path {
      stroke: white;
    }
  }
`;

const MainContainer = styled.div`
  margin-top: 28px;
  .MuiButton-startIcon {
    ${rtl`
    margin-right: 4px;
    margin-left:0px;
  `}
  }
  .breadcrumbs {
    position: fixed;
    top: 64px;
    width: 100%;
    height: 60px;
    background: white;
    ${rtl`padding: 24px 0 0 80px; `}
    @media (min-width: 768px) and ${MOBILE_TABLET_RESOLUTION} {
      ${rtl`padding:24px 0px 24px 28px;`}
    }
    z-index: 99;
  }
  .add-new-unit-button {
    border-radius: 15px;
    width: ${({ theme }) => (theme.dir === 'rtl' ? '169px' : '152px')};
    @media ${MOBILE_RESOLUTION} {
      height: 40px;
    }
    .icon-plus.icon-button {
      width: 12px;
    }
    span {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      @media ${MOBILE_RESOLUTION} {
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
  .add-customer-list-button {
    background-color: white;
    color: #fc5c42;
    border-color: #fc5c42;
    border: 1px solid;
    border-radius: 15px;
    .icon-plus.icon-button {
      fill: #fc5c42;
      width: 12px;
    }
    :hover {
      color: #a50a0a;
      border-color: #a50a0a;
      border: 1px solid;
      background: white;
      .icon.icon-plus.icon-button {
        fill: #a50a0a;
      }
    }
    width: 189px;
    @media ${MOBILE_RESOLUTION} {
      height: 40px;
    }
    span {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      @media ${MOBILE_RESOLUTION} {
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
  @media ${LAPTOP_MAX_RESOLUTION} {
    margin: 60px 20px 0 20px;
  }

  @media ${MOBILE_RESOLUTION} {
    margin: 16px 20px 0 20px;
  }
`;

const HeaderTitle = styled.div`
  font-weight: ${({ theme }) => (theme.dir === 'rtl' ? '600' : '500')};
  font-size: 36px;
  line-height: 40px;
  color: #282929;
  @media ${MOBILE_RESOLUTION} {
    font-size: 24px;
    line-height: 40px;
  }
`;

const StyledIconButton = styled(IconButton)``;

const BottomBorder = styled.div`
  border-bottom: 4px solid #fc5c42;
  width: calc(100% + 50px);

  ${rtl`
  margin-left: -30px;
  `}
`;
const HeaderParent = styled.div`
  display: flex;
  width: fit-content;

  padding-bottom: 24px;
  @media ${MOBILE_RESOLUTION} {
    padding-bottom: 12px;
  }
  .icon-edit {
    :hover {
      fill: #fc5c42;
    }
  }
  .icon-button {
    background: white;
    margin-left: 5px;
  }
`;

const MainHeaderDiv = styled.div`
  margin: 0 auto;
  margin-top: 97px;
  width: fit-content;
  @media ${MOBILE_RESOLUTION} {
    margin-top: 24px;
  }
`;

const SubHeaderDiv = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;
  gap: 88px;
  @media ${MOBILE_RESOLUTION} {
    gap: 20px;
  }
`;

const ImagesContainer = styled.div`
  margin: 48px auto 0 auto;
  justify-content: center;
  gap: 20px;
  display: flex;
  @media ${TABLET_RESOLUTION} {
    width: auto;
  }
  @media ${MOBILE_RESOLUTION} {
    margin: 24px auto 0 auto;
    gap: 12px;
  }
`;

const LeftImage = styled.div`
  height: 446px;
  width: 520px;
  box-shadow: 0px 0px 33px rgb(0 0 0 / 10%);
  border-radius: 10px;
  @media ${TABLET_RESOLUTION} {
    height: 254px;
    width: 296px;
  }
  @media ${MOBILE_RESOLUTION} {
    width: 50%;
    height: 200px;
  }
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

const Images = styled.img`
  height: 100%;
  width: 100%;
  object-fit: fill;
`;

const RightImage = styled.div`
  height: 446px;
  width: 1060px;
  box-shadow: 0px 0px 33px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  @media ${TABLET_RESOLUTION} {
    height: 254px;
    width: 612px;
  }
  @media ${MOBILE_RESOLUTION} {
    width: 50%;
    height: 200px;
  }
`;

const PropertiesSection = styled.div`
  margin: 58px auto 0 auto;
  max-width: 1600px;
  @media ${TABLET_RESOLUTION} {
    width: auto;
  }
  @media ${MOBILE_RESOLUTION} {
    margin: 32px auto 0 auto;
  }
  .client-top-title {
    @media ${MOBILE_RESOLUTION} {
      margin: 32px auto 0 auto;
    }
  }
  .title {
    font-size: 24px;
    line-height: 28px;
    color: #282929;
    font-weight: ${({ theme }) => (theme.dir === 'rtl' ? '600' : '500')};
  }
`;

const StyledSpan = styled.span`
  color: #fc5c42;
  font-size: 20px;
  line-height: 23px;
`;

const PropertySearch = styled.div`
  width: 655px;
  height: 48px;
  @media ${TABLET_RESOLUTION} {
    width: 454px;
  }
  @media ${MOBILE_RESOLUTION} {
    width: 100%;
  }
  ${rtl`
  margin 32px auto;
  display: flex;
  .search-input-div {
    background: #fafcfe;
    border-width: 2px 0px 2px 2px;
    border-style: solid;
    border-color: #e8eaeb;
    border-radius: 10px 0px 0px 10px;
    width: 567px;
    height: 100%;
    input {
      &:focus {
        outline: none;
      }
      border-radius: 10px 0px 0px 10px;
      padding: 10px 16px;
      height: 100%;
      width: 100%;
      border: none;
      font-size: 18px;
      line-height: 22px;
      color: #282929;
      ::placeholder {
        color: rgba(41, 41, 41, 0.2);
      }
    }
  }

  .search-button {
    &:disabled{
      border:none;
    }
    text-align: center;
    padding: 3% 0;
    background: #fc5c42;
    min-width: 88px;
    width: 88px;
    height: inherit;
    border: 1px solid #fc5c42;
    border-radius: 0px 10px 10px 0px;
    span {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
    }
  }
  `}
`;

const TypesSection = styled.div`
  margin: 46px auto 0 auto;
  max-width: 1600px;
  @media ${TABLET_RESOLUTION} {
    width: auto;
  }
  .title {
    font-size: 24px;
    line-height: 28px;
    color: #282929;
    font-weight: ${({ theme }) => (theme.dir === 'rtl' ? '600' : '500')};
  }
`;

const ButtonsContainer = styled.div`
  margin: 32px auto;
  display: flex;
  justify-content: center;
  gap: 16px;
  .icon-button {
    @media ${MOBILE_RESOLUTION} {
      height: 12px !important;
      width: 12px !important;
    }
  }
`;

const Modal = styled(Dialog)`
  .MuiDialog-paper {
    border-radius: 15px;
    overflow-y: hidden !important;
    max-width: none;
  }
  .client-dialog .MuiDialog-paper {
    overflow-y: hidden;
  }
`;

const ArrowContainer = styled(ArrowBase)<{
  prev: boolean;
  prevIndex: number;
  mediaLength?: number;
  currentValue: number;
}>`
  position: absolute;
  top: -40px;

  ${({ theme, prev }) =>
    theme.dir === 'rtl'
      ? css`
          left: ${prev ? '0' : '62'}px;
          transform: rotate(${prev ? 180 : 0}deg);
        `
      : css`
          right: ${prev ? '62' : '0'}px;
          transform: rotate(${prev ? 180 : 0}deg);
        `}

  & .prev {
    fill: ${({ prevIndex }) => (prevIndex === 0 ? '#D9D9D9' : '#292929E5')};
    margin-right: 0px;
  }
  & .next {
    fill: ${({ prevIndex, mediaLength, currentValue }) =>
      prevIndex + currentValue === mediaLength ? '#D9D9D9' : '#292929E5'};
    margin-left: 0px;
  }
`;

const ArrowContainerProperty = styled(ArrowBase)<{
  prev: boolean;
  prevIndex: number;
  mediaLength?: number;
  currentValue: number;
}>`
  position: absolute;
  top: -150px;
  ${({ theme, prev }) =>
    theme.dir === 'rtl'
      ? css`
          left: ${prev ? '0' : '62'}px;
          transform: rotate(${prev ? 180 : 0}deg);
        `
      : css`
          right: ${prev ? '62' : '0'}px;
          transform: rotate(${prev ? 180 : 0}deg);
        `}

  & .prev {
    fill: ${({ prevIndex }) => (prevIndex === 0 ? '#D9D9D9' : '#292929E5')};
    margin-right: 0px;
  }
  & .next {
    fill: ${({ prevIndex, mediaLength, currentValue }) =>
      prevIndex + currentValue === mediaLength ? '#D9D9D9' : '#292929E5'};
    margin-left: 0px;
  }
`;

const ArrowIcon = styled(Icon).attrs({
  name: 'nav-arrow-right',
})<{ isOpen: boolean }>`
  position: relative;
  left: ${({ isOpen }) => (isOpen ? -4 : 4)}px;
  ${({ theme, isOpen }) => theme.utils.rotateIcon({ isOpen })}
`;

const TypesContainer = styled.div`
  position: relative;
  && .horizontal-carousel {
    gap: 24px !important;
  }
  && .slick-slide {
    width: 270px !important;
    @media ${TABLET_RESOLUTION} {
      width: 237px !important;
    }
  }
  && .slick-track {
    margin: 0;
    width: max-content !important;
    /* height: 310px; */
    @media ${TABLET_RESOLUTION} {
      height: auto;
    }
  }
  && .slick-list {
    padding: 2px;
  }
`;

const PropertiesContainer = styled.div`
  position: relative;
  && .horizontal-carousel {
    gap: 24px !important;
  }
  && .slick-slide {
    width: 570px !important;
    @media ${TABLET_RESOLUTION} {
      width: 474px !important;
    }
    @media ${MOBILE_RESOLUTION} {
      width: 412px !important;
    }
  }
  && .slick-track {
    margin: 0;
    width: max-content !important;
    height: 240px;
  }
  && .slick-list {
    padding: 2px;
  }
`;

const SuccessText = styled.div``;
const FlexContainer = styled.div`
  width: auto !important;
`;

const SuccessFlex = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 64px;
  margin-top: 16px;
  width: auto !important;
`;

const SuccessCount = styled.div`
  color: #2cdd73;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
`;

const DownloadCSV = styled.div`
  color: #5c95ff;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  margin: 0 auto;
  margin-top: 16px;
  cursor: pointer;
`;

const FinishButton = styled.button`
  width: 145px;
  height: 48px;
  background: #fc5c42;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: rgba(255, 255, 255, 0.9);
  margin-top: 24px;
  margin: 24px 88px;
  border: 1px solid #fc5c42;
  border-radius: 15px;
  cursor: pointer;
`;
