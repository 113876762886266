import * as React from 'react';
import Paper, { PaperProps } from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import images from 'assets/images/login/loginSlides';
import Logo from 'components/Logo';
import {
  MOBILE_RESOLUTION,
  slideAnimation,
  SMALL_RESOLUTION,
} from 'styles/sharedStyle';
import { useMediaQuery } from '@material-ui/core';
import { ClientRouter } from 'router/routes';

interface Props {
  className?: string;
  title: string | React.ReactNode;
  info?: string | React.ReactNode;
}

const FormContainer: React.FC<Props> = ({
  className,
  children,
  title,
  info,
}) => {
  const isMobile = useMediaQuery(MOBILE_RESOLUTION);
  return (
    <Container className={className}>
      {!isMobile &&
        Array.from({ length: 5 }).map((_, idx) => (
          <span key={`image_${idx.toString()}`} className="image" />
        ))}
      <Content>
        <ContentBody>
          <Header>
            <Link to={ClientRouter.APP}>
              <Logo title="Patricia" className="logo" />
            </Link>
            <Title>{title}</Title>
          </Header>
          {info && (
            <Information>
              <Row variant="subtitle1">{info}</Row>
            </Information>
          )}
          {children}
        </ContentBody>
      </Content>
    </Container>
  );
};

export default FormContainer;

const Container = styled.section.attrs<{ className?: string }>(
  ({ className }) => ({
    className: `auth-form-container ${className}`,
  })
)`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  display: flex;
  align-items: center;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;

  @media ${MOBILE_RESOLUTION} {
    align-items: flex-end;
    border-radius: 0;
    position: relative;
    min-height: 100vh;
  }

  & .image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    opacity: 0;
    z-index: 0;
    animation: ${slideAnimation} 25s linear infinite;
  }

  & .image:nth-child(1) {
    background-image: url('${images.livingRoom1}');
  }
  & .image:nth-child(2) {
    background-image: url('${images.livingRoom2}');
    animation-delay: 5s;
  }
  & .image:nth-child(3) {
    background-image: url('${images.livingRoom3}');
    animation-delay: 10s;
  }
  & .image:nth-child(4) {
    background-image: url('${images.livingRoom4}');
    animation-delay: 15s;
  }
  & .image:nth-child(5) {
    background-image: url('${images.livingRoom5}');
    animation-delay: 20s;
  }
`;

const Content = styled((props: PaperProps) => (
  <Paper elevation={24} square {...props} />
)).attrs({ className: 'auth-form-content' })`
  position: absolute;
  box-shadow: none;
  height: 100vh;
  max-width: 688px;
  padding: 33px 43px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: auto;
  @media ${SMALL_RESOLUTION} {
    padding: 25px 10px;
    max-width: 502px;
  }

  @media ${MOBILE_RESOLUTION} {
    padding: 30px;
    width: 100vw;
    left: 0;
    border-radius: 0;
    ${({ theme }) => theme.utils.scrollbar}
  }
`;

const Title = styled(Typography).attrs({
  variant: 'h4',
  className: 'auth-header-title',
})`
  && {
    font-size: 1.875rem;
    font-family: ${({ theme }) => theme.dir === 'rtl' && 'Assistant'};

    @media (min-width: 1500px) {
      font-size: 3.125rem;
    }
  }
`;

const ContentBody = styled.div.attrs({ className: 'auth-content-body' })`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${MOBILE_RESOLUTION} {
    height: initial;
  }
`;

export const Header = styled.div.attrs({ className: 'auth-form-header' })`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 100%;

  .logo {
    width: unset;
    height: 104px;
  }
  @media (min-width: 1500px) {
    gap: 48px;
  }
`;

const Row = styled(Typography).attrs({ className: 'information-row' })`
  && {
    font-size: 0.875rem;

    @media (min-width: 1500px) {
      font-size: 1rem;
    }
  }
`;

const Information = styled.section.attrs({
  className: 'auth-form-information',
})`
  text-align: center;
  margin: 20px 0;
  max-width: 95%;

  @media (min-width: 1500px) {
    margin: 25px 0;
    max-width: 100%;
  }
`;
