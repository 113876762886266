import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Button from 'components/@client/Button';
import CardList, {
  CartItem,
  CartEmptyState,
} from 'components/@client/CardList';
import { MOBILE_RESOLUTION } from 'styles/sharedStyle';
import CreditCardProviders from 'components/@client/CreditCardProviders';
import {
  useAuthentication,
  useFormattedCurrency,
  useRecentProperty,
  useSession,
} from 'hooks';
import { Message } from 'i18n';
import { ClientRouter } from 'router/routes';
import { SupportedCountry } from 'types/common.types';
import { isEmpty, size, reduce } from 'utils/lodash.utils';
import { userLogData } from 'mockData/userLogData';
import { LogsAPI } from 'api/methods';
import IconButton, { IconName } from 'components/Button/IconButton';
import SecondaryButton from 'components/Button/SecondaryButton';
import { StyledHr } from 'components/@client/CardList/Card';
import rtl from 'styled-components-rtl';
import usePropertyCart from './hooks/usePropertyCart';
import {
  DeleteItemModal,
  DeleteItemModalAll,
  RestrictionModal,
} from './modals';

const PropertyCartWeb: React.FC<{ isLoading: boolean }> = ({
  isLoading: isCartLoading,
}) => {
  const [isRestricted, setIsRestricted] = React.useState<boolean>(false);

  const { country } = useSession();
  const { recentProperty } = useRecentProperty();
  const { type } = useAuthentication();

  const {
    cart,
    deleteCandidate,
    isUpdating,
    isProceedingToCheckout,
    restrictedItems,
    deleteItem,
    emptyItem,
    deleteCandidateAll,
    proceedToCheckout,
    toggleModal,
    toggleModalAllItem,
    updateQuantity,
    setPagination,
    pagination,
  } = usePropertyCart(type !== 'CLIENT');

  const isDeleteOpen = Boolean(deleteCandidate);
  const isDeleteAllOpen = Boolean(
    deleteCandidateAll?.ids && deleteCandidateAll?.ids?.length > 0
  );
  const price = useFormattedCurrency(cart?.subTotal ?? 0, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const shippingAmount = useFormattedCurrency(0, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const vatAmount = useFormattedCurrency(cart?.vat ?? 0, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const totalAmount = useFormattedCurrency(cart?.finalPrice ?? 0, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
  const changeModalOpen = React.useCallback(() => {
    toggleModalAllItem('null');
  }, [toggleModalAllItem]);

  const hasItems = size(cart?.items) > 0;
  const outOfStocked = React.useMemo(
    () => cart?.items.every(item => item.product.quantity === 0),
    [cart?.items]
  );

  const onClick = React.useCallback(() => {
    LogsAPI.postUserLogs({
      name: userLogData.eventName.checkOutButton,
      area: userLogData.eventArea.myCart,
      section: userLogData.eventSection.cart,
      path: userLogData.eventPath.appCartCartIdPath,
      metadata: { cart_id: cart?.id },
    });
    LogsAPI.pushDataLayer([
      { ecommerce: null },
      {
        event: 'checkout',
        ecommerce: {
          checkout: {
            products: cart?.items.map(item => ({
              name: item?.product.englishName,
              id: item?.product.id,
              price: item?.product.finalPrice,
              brand: item?.product.supplier.companyName,
              category: item?.product.subCategory,
              quantity: item.quantity,
            })),
          },
        },
      },
    ]);

    if (!isEmpty(restrictedItems)) return setIsRestricted(true);
    return proceedToCheckout();
  }, [proceedToCheckout, restrictedItems, cart]);
  const totalItems = React.useMemo(() => {
    return cart ? reduce(cart?.items, (sum, n) => sum + n.quantity, 0) : 0;
  }, [cart]);

  return (
    <>
      <Container>
        <TopContainer>
          <TopRow display="flex">
            <Col className="title-col">
              {hasItems && (
                <span className="cart-counter">
                  <FormattedMessage
                    id={Message.CART_TOTAL_ITEMS_LABEL}
                    values={{
                      value: (
                        <>
                          <span className="cart-count">{totalItems}</span>
                        </>
                      ),
                    }}
                  />
                </span>
              )}
            </Col>
          </TopRow>
        </TopContainer>
        <Stack count={cart?.items?.length}>
          <LeftSection>
            <Row display="flex">
              <CartItemsDiv>
                <CardList
                  isLoading={isCartLoading}
                  EmptyState={
                    <CartEmptyState
                      to={
                        recentProperty?.id
                          ? ClientRouter.PROPERTY.replace(
                              ':propertyId',
                              recentProperty?.id
                            )
                          : ClientRouter.PRODUCTS
                      }
                    />
                  }
                  items={cart?.items}
                  renderItem={item => (
                    <CartItem
                      key={item.id}
                      isLoading={isUpdating}
                      onQuantityChange={updateQuantity}
                      onDelete={toggleModal}
                      nameLength={40}
                      {...item}
                    />
                  )}
                />
                <Col className="delete-button-container">
                  {cart?.items && cart?.items.length > 0 && (
                    <SecondaryButton
                      $xlarge
                      className="secondary-button"
                      onClick={changeModalOpen}
                    >
                      <IconButton
                        iconName={IconName.custom_icon}
                        customIcon="trash-outlined"
                      />
                      <FormattedMessage id={Message.CLEAR_CART} />
                    </SecondaryButton>
                  )}
                </Col>
                {type !== 'CLIENT' && cart && cart?.items?.length >= 10 && (
                  <LoadMoreButton
                    className="checkout-button"
                    disabled={outOfStocked}
                    isLoading={isProceedingToCheckout}
                    onClick={() => {
                      setPagination({
                        page: pagination.page + 1,
                        size: 10,
                      });
                    }}
                  >
                    Load More...
                  </LoadMoreButton>
                )}
              </CartItemsDiv>
            </Row>
          </LeftSection>

          {(cart === null || cart?.items.length > 0) && (
            <RightSection>
              <OrderSummaryContainer>
                <SubTitle>
                  <FormattedMessage id={Message.ORDER_SUMMARY} tagName="span" />
                </SubTitle>
                <Row>
                  <Col text="uppercase">
                    <FormattedMessage
                      id={
                        country === SupportedCountry.USA
                          ? Message.CHECKOUT_SUB_TOTAL_LABEL
                          : Message.CHECKOUT_TOTAL_LABEL
                      }
                      tagName="span"
                    />
                  </Col>
                  <Col weight="500" text="uppercase">
                    {price}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormattedMessage
                      id={Message.CHECKOUT_SHIPPING_LABEL}
                      tagName="span"
                    />
                  </Col>
                  <Col>{shippingAmount}</Col>
                </Row>
                <Row>
                  <Col>
                    <FormattedMessage
                      id={
                        country === SupportedCountry.ISRAEL
                          ? Message.CHECKOUT_VAT_LABEL
                          : Message.CHECKOUT_TAX_LABEL
                      }
                      tagName="span"
                      values={{
                        value: `${cart?.taxRate ?? 0}%`,
                      }}
                    />
                  </Col>
                  <Col>{vatAmount}</Col>
                </Row>
                <StyledHr />
                <Row>
                  <Col weight="bold" fontSize="22px">
                    <FormattedMessage
                      id={Message.CHECKOUT_TOTAL_LABEL}
                      tagName="span"
                    />
                  </Col>
                  <Col weight="bold" fontSize="22px">
                    {totalAmount}
                  </Col>
                </Row>
                <StyledHr />
                <Row justify="end" align="end">
                  <CheckoutButton
                    className="checkout-button"
                    disabled={outOfStocked}
                    isLoading={isProceedingToCheckout}
                    onClick={onClick}
                  >
                    <FormattedMessage id={Message.CART_PLACE_ORDER_BUTTON} />
                  </CheckoutButton>
                </Row>
                <Row>
                  {outOfStocked && (
                    <p className="out-of-stock-caption">
                      <FormattedMessage
                        id={Message.CART_OUT_OF_STOCK_CAPTION}
                      />
                    </p>
                  )}
                </Row>
              </OrderSummaryContainer>
              <CreditCardProviders />
            </RightSection>
          )}
        </Stack>
      </Container>
      <DeleteItemModal
        open={isDeleteOpen}
        onClose={() => toggleModal()}
        onDelete={deleteItem}
        deleteCandidate={deleteCandidate}
      />
      <DeleteItemModalAll
        open={isDeleteAllOpen}
        onClose={() => toggleModalAllItem()}
        onDelete={emptyItem}
        deleteCandidateAll={deleteCandidateAll?.ids && deleteCandidateAll?.ids}
      />
      <RestrictionModal
        open={isRestricted}
        items={restrictedItems}
        isProceeding={isProceedingToCheckout}
        onProceed={proceedToCheckout}
        onClose={() => setIsRestricted(false)}
      />
    </>
  );
};

export default PropertyCartWeb;

const Container = styled.section`
  .card {
    position: relative;
  }

  & .cart-counter {
    font-size: 20px;
    color: #696969;
    line-height: 23px !important;

    & .cart-count {
      color: #fc5c42;
    }
  }

  & .credit-card-providers {
    order: 3;
  }

  & .checkout-button {
    border-radius: 12px;
    font-size: 1.125rem;
    width: auto;
    margin-top: 16px;
    align-self: flex-end;
    order: 2;
  }

  & .actions {
    ${rtl`
    right: 10%;
    top: 20%;
    
    `}
    height: auto;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    background: white !important;
    box-shadow: 0px -3px 25px 5px rgba(0, 88, 171, 0.07);
    border-radius: 10px;
    .order-summary {
      margin: 0 0 30px 0;
      font-weight: 700;
    }
    .total-price {
      font-weight: 700;
    }
    p {
      max-width: 500px;
      margin: 0 0 10px 0;

      display: flex;
      justify-content: space-between;
    }

    .out-of-stock-caption {
      color: ${({ theme }) => theme.colors.status.error};
      margin-top: 1rem;
      font-size: 0.75rem;
      order: 2;
      text-align: center;

      @media (min-width: 1150px) {
        order: 1;
      }
    }

    a {
      text-decoration: none;
    }
    @media (min-width: 1150px) {
      ${rtl`
      position: fixed;
      right: 10%;
      top: 20%;
      
      `}
      width: auto;
      height: auto;
      margin-top: 0;
      flex-basis: 400px;
      padding: 22px;
      background: rgba(248, 247, 247, 1);
      border-radius: 4px;
      display: flex;
      flex-direction: column;
    }
  }
`;

const Stack = styled.div<{ count: number | undefined }>`
  display: flex;
  flex-direction: row;
  margin-top: 32px;
  @media (min-width: 1150px) {
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
  }

  @media (max-width: 820px) {
    flex-direction: column;
    width: 100%;
  }
`;

const TopContainer = styled.div`
  @media (max-width: 820px) {
    flex-direction: column;
    width: 100%;
  }
`;

const CartItemsDiv = styled.div.attrs({ dir: 'ltr' })`
  width: 100%;
  display: grid;
  .card-list {
    max-width: initial;
    width: 100%;
    margin: 15px 0 0 0;
    padding: 10px;
    direction: ${({ theme }) => theme.dir};
    .cart-item {
      background: #ffffff;
      box-shadow: 6px 12px 150px rgb(111 118 123 / 13%);
      border-radius: 10px;
      margin-bottom: 20px;
      div {
        padding: 0px;
      }
      .additional-text {
        padding: 15px;
      }
      img {
        width: 123px;
        height: 123px;
        margin: 20px;
      }
      .card-content {
        h4 {
          font-family: ${({ theme }) =>
            theme.dir === 'rtl' ? 'Assistant' : 'Roboto'};
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          color: #000000;
        }
        .subtitle-data {
          font-family: ${({ theme }) =>
            theme.dir === 'rtl' ? 'Assistant' : 'Roboto'};
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 14x;
          color: #000000;
          mix-blend-mode: normal;
          opacity: 0.9;
        }
        div:last-child {
          margin-right: ${({ theme }) =>
            theme.dir === 'ltr' ? '40px' : '0px'};
          margin-left: ${({ theme }) => (theme.dir === 'rtl' ? '40px' : '0px')};
        }
        div {
          margin-top: 10px;
        }
        .cart-price-container {
          .cart-item-price {
            font-family: ${({ theme }) =>
              theme.dir === 'rtl' ? 'Assistant' : 'Roboto'};
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 20px;
            color: #000000;
          }
          .view-details-btn {
            font-family: ${({ theme }) =>
              theme.dir === 'rtl' ? 'Assistant' : 'Roboto'};
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            text-decoration-line: underline;
            color: #ff5241;
            mix-blend-mode: normal;
            opacity: 0.9;
          }
          .x-button {
            border: 1.2px solid #000000;
            box-sizing: border-box;
            transform: rotate(45deg);
            width: 23px;
            height: 23px;
            svg {
              width: 10.89px;
              height: 10.89px;
              border: 1.54286px solid #000000;
              transform: rotate(45deg);
            }
          }
        }
      }
      .card-divider {
        display: none;
      }
    }
  }
  .delete-button-container {
    padding: 0 10px;
  }
  .secondary-button {
    ${rtl`
    float: left;
    
    `}
  }
`;

const LeftSection = styled.div`
  @media (max-width: 820px) {
    width: 100%;
  }
  width: 59%;
  margin-right: ${({ theme }) => (theme.dir === 'ltr' ? '20px' : '0px')};
  margin-left: ${({ theme }) => (theme.dir === 'rtl' ? '20px' : '0px')};
`;

const RightSection = styled.div`
  width: 41%;
  height: 631px;
  @media (max-width: 820px) {
    width: 100%;
  }
`;

const Row = styled.div<{
  justify?: string;
  align?: string;
  display?: string;
}>`
  display: ${({ display }) => (!display ? 'inline-flex' : display)};
  gap: 10px;
  justify-content: ${({ justify }) => (!justify ? 'space-between' : justify)};
  align-items: ${({ align }) => (!align ? 'center' : align)};
`;

const TopRow = styled.div<{
  justify?: string;
  align?: string;
  display?: string;
}>`
  display: ${({ display }) => (!display ? 'inline-flex' : display)};
  gap: 10px;
  justify-content: ${({ justify }) => (!justify ? 'space-between' : justify)};
  align-items: ${({ align }) => (!align ? 'center' : align)};
  width: fit-content;
  margin: auto;
  & .title-col {
    line-height: 23px !important;
  }
`;

const Col = styled.div<{
  text?: string;
  weight?: string;
  fontSize?: string;
  color?: string;
}>`
  font-family: ${({ theme }) => (theme.dir === 'rtl' ? 'Assistant' : 'Roboto')};
  font-style: normal;
  font-weight: ${({ weight }) => (!weight ? 'normal' : weight)};
  font-size: ${({ fontSize }) => (!fontSize ? '18px' : fontSize)};
  line-height: 43px;
  text-transform: ${({ text }) => text};
  color: ${({ color }) => (!color ? '#000000' : color)};
`;

const OrderSummaryContainer = styled.section`
  margin-bottom: 40px;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 10px;
  .order-summary {
    margin: 0 0 30px 0;
    font-weight: 700;
  }
  .total-price {
    font-weight: 700;
  }
  p {
    max-width: 500px;
    margin: 0 0 10px 0;

    display: flex;
    justify-content: space-between;
  }

  .out-of-stock-caption {
    color: ${({ theme }) => theme.colors.status.error};
    margin-top: 1rem;
    font-size: 0.75rem;
    order: 2;
    text-align: center;

    @media (min-width: 1150px) {
      order: 1;
    }
  }

  background: #ffffff;
  box-shadow: 6px 12px 150px rgba(111, 118, 123, 0.126885);
  border-radius: 10px;
  padding: 20px;
`;

const SubTitle = styled.span`
  font-family: ${({ theme }) => (theme.dir === 'rtl' ? 'Assistant' : 'Roboto')};
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 43px;
  color: #000000;
  @media ${MOBILE_RESOLUTION} {
    font-size: 16px;
  }
`;

const CheckoutButton = styled(Button)`
  background: #ff5241;
  border-radius: 11px;
  .MuiButton-label {
    padding: 1px 76px 1px 76px;
  }
`;

const LoadMoreButton = styled(Button)`
  align-self: center;
  margin: auto !important;
  background: #ff5241;
  border-radius: 11px;
  .MuiButton-label {
    padding: 1px 76px 1px 76px;
  }
`;
