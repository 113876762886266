import { Message } from 'i18n';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Anchor from 'components/Link';

export interface LinkItemProps {
  id: Message;
  to?: string | null;
  href?: string;
  onClick?: VoidFunction;
}
const LinkItem: React.FC<LinkItemProps> = ({ id, to, href, onClick }) => {
  const body = React.useMemo(() => {
    if (to)
      return (
        <Link to={to} onClick={onClick}>
          <FormattedMessage id={id} />
        </Link>
      );
    if (href)
      return (
        <Anchor className="no-decoration" href={href}>
          <FormattedMessage id={id} />
        </Anchor>
      );
    return <FormattedMessage id={id} />;
  }, [id, to, href, onClick]);

  return <li>{body}</li>;
};

export default LinkItem;
