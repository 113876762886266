import * as React from 'react';
import styled from 'styled-components';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';

interface Props extends TooltipProps {
  $variant?: 'info' | 'error' | 'social' | 'supplierInfo';
  marginWidth?: number;
  arrowMargin?: boolean;
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
const getBackground = (theme: any, variant: string) => {
  if (variant === 'social') {
    return theme.colors.black0;
  }
  if (variant === 'supplierInfo') {
    return '#11516D';
  }
  return theme.colors.light;
};

const getColor = (theme: any, variant: string) => {
  if (variant === 'info') {
    return theme.colors.primary.primaryTextColor;
  }
  if (variant === 'supplierInfo') {
    return 'rgba(255, 255, 255, 0.9)';
  }
  return theme.colors.status.error;
};

const getArrowColor = (theme: any, variant: string) => {
  if (variant === 'social') {
    return theme.colors.black0;
  }
  if (variant === 'supplierInfo') {
    return '#11516D';
  }
  return theme.colors.lightGray;
};

const getFontsize = (variant: string) => {
  if (variant === 'supplierInfo') {
    return '16px';
  }
  return '14px';
};

const getFontweight = (variant: string) => {
  if (variant === 'supplierInfo') {
    return 400;
  }
  return 500;
};

const getBorderRadius = (variant: string) => {
  if (variant === 'supplierInfo') {
    return '5px';
  }
  return '8px';
};

const getMarginLeft = (variant: string, marginWidth: number) => {
  if (variant === 'supplierInfo' && marginWidth) {
    return `${(window.innerWidth - marginWidth) / 2}px`;
  }
  return 0;
};

const getArrowMargin = (
  variant: string,
  marginWidth: number,
  arrowMargin: boolean
) => {
  if (variant === 'supplierInfo' && marginWidth && arrowMargin) {
    return `-${(window.innerWidth - marginWidth) / 4 - 2}px !important`;
  }
  return 0;
};

const CustomTooltip = styled(({ children, ...props }: Props) => (
  <Tooltip
    classes={{
      popper: props?.className ?? 'popper',
      tooltip: 'tooltip',
      arrow: 'tooltip-arrow',
    }}
    TransitionComponent={Zoom}
    arrow
    placement={props.placement || 'top-end'}
    {...props}
  >
    {children}
  </Tooltip>
))<{
  $variant: 'info' | 'error' | 'social' | 'supplierInfo';
  marginWidth: number;
  arrowMargin: boolean;
}>`
  & .tooltip {
    background-color: ${({ theme, $variant }) =>
      getBackground(theme, $variant)};
    color: ${({ theme, $variant = 'error' }) => getColor(theme, $variant)};
    padding: 10px;
    box-shadow: 0 1px 6px #00000029;
    border-radius: ${({ $variant = 'error' }) => getBorderRadius($variant)}; 
    font-size: ${({ $variant = 'error' }) => getFontsize($variant)};
    line-height: 20px;
    font-weight: ${({ $variant = 'error' }) => getFontweight($variant)};
    max-width: 400px;
    margin-left: ${({ $variant = 'error', marginWidth }) =>
      getMarginLeft($variant, marginWidth)};
    margin-bottom: 11px; 
  & .tooltip-arrow {
    color: ${({ theme, $variant }) => getArrowColor(theme, $variant)};
    left: ${({ $variant = 'error', marginWidth, arrowMargin }) =>
      getArrowMargin($variant, marginWidth, arrowMargin)};
      
  }
`;

export default React.memo(CustomTooltip);
