import { keyBy } from 'utils/lodash.utils';
import types from 'store/constants/user-management/clients.constants';
import { RootState } from 'store/types';

export const clientsSelector = (state: RootState) => state.clients;
export const allClientsMap = (state: RootState) =>
  keyBy(clientsSelector(state).users, 'userID');
export const allClients = (state: RootState) => clientsSelector(state).users;
export const allClientsPageCount = (state: RootState) =>
  clientsSelector(state).pageCount;
export const allClientsCount = (state: RootState) =>
  clientsSelector(state).userCount;
export const allCartItems = (state: RootState) => clientsSelector(state).cart;
export const allRoomsItems = (state: RootState) => clientsSelector(state).rooms;

// loading
export const allClientsIsLoading = (state: RootState) =>
  clientsSelector(state).loading[types.GET_USERS_DATA_REQUEST];
export const clientIsLoading = (state: RootState) =>
  clientsSelector(state).loading[types.GET_USER_DATA_REQUEST];
export const allProductsIsLoading = (state: RootState) =>
  clientsSelector(state).loading[types.GET_PRODUCTS_DATA_REQUEST];
export const allPropertiesIsLoading = (state: RootState) =>
  clientsSelector(state).loading[types.GET_PROPERTIES_DATA_REQUEST];
export const allQuizResultsIsLoading = (state: RootState) =>
  clientsSelector(state).loading[types.GET_QUIZ_RESULTS_DATA_REQUEST];
export const allCartResultsIsLoading = (state: RootState) =>
  clientsSelector(state).loading[types.GET_CART_ITEM_DATA_REQUEST];
export const userActivityIsLoading = (state: RootState) =>
  clientsSelector(state).loading[types.GET_USER_ACTIVITY_DATA_REQUEST];
// error
export const allClientsError = (state: RootState) =>
  clientsSelector(state).error[types.GET_USERS_DATA_REQUEST];
export const allProductsError = (state: RootState) =>
  clientsSelector(state).error[types.GET_PRODUCTS_DATA_REQUEST];
export const allPropertiesError = (state: RootState) =>
  clientsSelector(state).error[types.GET_PROPERTIES_DATA_REQUEST];
export const allQuizResultsError = (state: RootState) =>
  clientsSelector(state).error[types.GET_QUIZ_RESULTS_DATA_REQUEST];
export const allCartResultsError = (state: RootState) =>
  clientsSelector(state).error[types.GET_CART_ITEM_DATA_REQUEST];
export const userActivityResultError = (state: RootState) =>
  clientsSelector(state).error[types.GET_USERS_DATA_REQUEST];
