import { Property } from 'types/properties';
import PropertyType from 'store/constants/user-management/properties.constants';
import * as actions from 'store/actions/user-management/properties.actions';
import * as Payload from 'store/types/user-management/properties.types';

export interface PropertiesState {
  loading: Record<PropertyType, boolean>;
  error: Record<PropertyType, null | string>;
  properties: Property[];
  pageCount: number;
}

const REQUESTS = [PropertyType.GET_PROPERTIES_DATA_REQUEST];

const initialState: PropertiesState = {
  loading: REQUESTS.reduce(
    (state, next) => ({ ...state, [next]: false }),
    {} as Record<PropertyType, boolean>
  ),
  error: REQUESTS.reduce(
    (state, next) => ({ ...state, [next]: null }),
    {} as Record<PropertyType, null | string>
  ),
  properties: [],
  pageCount: 0,
};

const propertiesReducer = (
  state: PropertiesState = initialState,
  action: actions.PropertiesAction
): PropertiesState => {
  switch (action.type) {
    case PropertyType.GET_PROPERTIES_DATA_REQUEST:
      return {
        ...updateLoading(state, PropertyType.GET_PROPERTIES_DATA_REQUEST),
      };
    case PropertyType.GET_PROPERTIES_DATA_FULFILLED:
      return {
        ...updateSuccess(
          state,
          PropertyType.GET_PROPERTIES_DATA_REQUEST,
          action.payload as Payload.GetPropertiesDataFulfilledPayload
        ),
      };
    case PropertyType.GET_PROPERTIES_DATA_REJECTED:
      return updateError(
        state,
        PropertyType.GET_PROPERTIES_DATA_REQUEST,
        action.payload as Payload.GetPropertiesDataRejectedPayload
      );
    default:
      return state;
  }
};

export default propertiesReducer;

type requestType = PropertyType.GET_PROPERTIES_DATA_REQUEST;
const updateLoading = (state: PropertiesState, requestType: requestType) => ({
  ...state,
  loading: {
    ...state.loading,
    [requestType]: true,
  },
  error: {
    ...state.error,
    [requestType]: null,
  },
});

type ErrorPayload = Payload.GetPropertiesDataRejectedPayload;
const updateError = (
  state: PropertiesState,
  requestType: requestType,
  { error }: ErrorPayload
) => ({
  ...state,
  loading: {
    ...state.loading,
    [requestType]: false,
  },
  error: {
    ...state.error,
    [requestType]: error,
  },
});
type SuccessPayload = Payload.GetPropertiesDataFulfilledPayload;
const updateSuccess = (
  state: PropertiesState,
  requestType: requestType,
  payload: SuccessPayload
) => ({
  ...state,
  loading: {
    ...state.loading,
    [requestType]: false,
  },
  error: {
    ...state.error,
    [requestType]: null,
  },
  ...payload,
});
