import * as React from 'react';
import { NavLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import styled, { css } from 'styled-components';
import { alpha } from '@material-ui/core';
import Icon from 'components/Icon';

export interface Item {
  label?: string;
  to: string;
  icon: string | null;
  exact: boolean;
  arrow?: true;
  narrow?: true;
}

const ListItem: React.FC<Item> = ({ label, to, icon, arrow, narrow }) => {
  return (
    <Container narrow={narrow} shortLabel={label === 'Dashboard'}>
      <CustomNavLink to={to} $arrow={arrow} $narrow={narrow}>
        <BaseIcon name={icon} narrow={narrow} />
        <Typography variant="inherit">{label}</Typography>
      </CustomNavLink>
    </Container>
  );
};

export default ListItem;

const Container = styled.li<{ narrow?: true; shortLabel: boolean }>`
  padding: ${({ shortLabel }) => (shortLabel ? '2rem' : '0.8rem')} 0.2rem 0 0;
  width: 100%;
  height: 82px;
  display: flex;
  justify-content: center;

  @media (min-width: 1500px) {
    height: ${({ narrow }) => (narrow ? '82px' : '100px')};
  }
`;

const CustomNavLink = styled(NavLink)<{ $arrow?: true; $narrow?: true }>`
  ${({ theme }) => theme.utils.centerFlex}
  flex-direction: column;
  text-decoration: none;
  color: ${alpha('#ffff', 0.9)};
  font-size: 0.75rem;
  font-weight: 400;
  height: 100%;
  width: 100%;
  transition: all ${({ theme }) => theme.utils.quickTransition};
  gap: 2px;
  border-left: ${({ $narrow }) => ($narrow ? 'none' : '3px solid transparent')};
  position: relative;
  opacity: 0.8;

  &.active {
    border-left: 3px solid
      ${({ $narrow }) => ($narrow ? 'none' : alpha('#ffff', 0.9))};
  }

  &.active .icon {
    fill: ${({ theme, $narrow }) =>
      $narrow ? theme.colors.primary.light : 'white'};
  }

  ${({ $narrow }) =>
    $narrow &&
    css`
      color: black;
      font-size: 0.75rem;
      width: 70%;
      margin-top: 49px;

      @media (min-width: 1500px) {
        margin-top: 57px;
      }
    `}

  &:hover,
  &.active {
    opacity: 1;
    color: white;

    ${({ $narrow, theme }) =>
      $narrow &&
      css`
        color: ${alpha(theme.colors.primary.light, 0.9)};
        font-size: 0.75rem;
        border-left: none;
      `}
  }

  &.active {
    ${({ $narrow, theme }) =>
      $narrow &&
      css`
        border-bottom: 2px solid ${theme.colors.primary.light};
      `}
  }
`;

const BaseIcon = styled(Icon).attrs<{ name: string; narrow?: true }>(
  ({ theme, name, narrow }) => ({
    name,
    size: narrow ? 24 : 32,
    color: narrow ? theme.colors.primary.dark : 'white',
  })
)`
  margin-bottom: 4px;
  &.icon-client-filled,
  &.icon-client-outlined {
    position: relative;
    left: 3px;
  }
`;
