import Skeleton from '@material-ui/core/Skeleton';
import styled from 'styled-components';
import Card from 'components/@client/CardList/Card';

const PropertySkeleton = () => {
  return (
    <li>
      <Card isLoading>
        <CardField>
          <Skeleton variant="text" width={50} />
          <Skeleton variant="text" width={100} />
        </CardField>
        <CardField>
          <Skeleton variant="text" width={50} />
          <Skeleton variant="text" width={100} />
        </CardField>
      </Card>
    </li>
  );
};

export default PropertySkeleton;

const CardField = styled.div`
  color: ${({ theme }) => theme.colors.primary.clientTextColor};
  margin: 4px 0;
  width: 100%;
`;
