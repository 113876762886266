import * as React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Button from 'components/@client/Button';
import { Message } from 'i18n';
import { ClientRouter } from 'router/routes';
import { useSession } from 'hooks';

const GuestButton = () => {
  const { isFromSupportedCountry } = useSession();
  return (
    <CustomLink
      to={isFromSupportedCountry ? ClientRouter.ONBOARDING : ClientRouter.APP}
    >
      <HeaderButton disabled={!isFromSupportedCountry}>
        <FormattedMessage id={Message.CREATE_HOME_BUTTON} />
      </HeaderButton>
    </CustomLink>
  );
};

export default GuestButton;

const HeaderButton = styled(Button)`
  && {
    font-weight: 400;
    font-size: 20px;
    padding: 0 15px;
    height: 50px;
  }
`;

const CustomLink = styled(Link).attrs(({ to }) => ({
  as: to === ClientRouter.APP ? 'span' : undefined,
}))`
  text-decoration: none;
  cursor: ${({ to }) => (to === ClientRouter.APP ? 'default' : 'pointer')};
`;
