import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { animated } from 'react-spring';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import styled, { css } from 'styled-components';
import { PopupModal, useCalendlyEventListener } from 'react-calendly';
import UserImage from 'assets/@client/home/home_background_image.png';
import { useFetch, useRecentProperty, useRouter, useSession } from 'hooks';
// import Icon from '@mui/material/Icon';
import { Message } from 'i18n';
import {
  MOBILE_RESOLUTION,
  MOBILE_TABLET_RESOLUTION,
  SMALL_MOBILE_RESOLUTION,
  SMALL_RESOLUTION,
  SMALL_WIDTH_RESOLUTION,
  // SMALL_WIDTH_RESOLUTION,
  TABLET_RESOLUTION,
  TextContent,
} from 'styles/sharedStyle';
import * as deviceInfo from 'react-device-detect';
import { ClientRouter } from 'router/routes';
import { Supplier } from 'types/suppliers';
import BrandLogoCarousel from 'components/@client/BrandLogoCarousel/BrandLogoCarousel';
import {
  SupplierOnboardingAction,
  useSupplierOnboardingDispatch,
} from 'context/supplierOnboardingContext';
import { Logo } from './Components/shared';
import useAnimations from './useAnimations';
// import { CoverVideoModal } from './Components/Modal';
import { CTAButton } from './Components/Button';
import Modal from './Components/Modal/Modal';

interface Props {
  isAuthenticated: boolean;
  fullName: string;
}

const Header: React.FC<Props> = ({ isAuthenticated, fullName }) => {
  const { recentProperty } = useRecentProperty();
  const dispatch = useSupplierOnboardingDispatch();
  const isMobile =
    useMediaQuery(MOBILE_TABLET_RESOLUTION) || !deviceInfo.isDesktop;
  const { isAnimate } = useAnimations(recentProperty);
  const { country } = useSession();
  const [isOpen, setIsOpen] = React.useState(false);
  const { data: supplierData, callFetch } = useFetch<Supplier[]>({
    initialUrl: `api/suppliers/logos?country=${country}`,
    skipOnStart: true,
    config: {
      useCache: false,
    },
  });
  React.useEffect(() => {
    if (country) callFetch({ url: `api/suppliers/logos/?country=${country}` });
  }, [callFetch, country]);

  useCalendlyEventListener({
    onEventScheduled: e => {
      dispatch({
        type: SupplierOnboardingAction.SET_IS_SHEDULED_MEETING,
        payload: true,
      });
    },
  });

  const actionButton = React.useMemo(
    () => (
      <ActionButton isMobile={isMobile}>
        <CTAButton
          text={Message.SUPPLIER_HEADER_BUTTON}
          width={330}
          onClick={() => setIsModalOpen(true)}
        />
      </ActionButton>
    ),
    [isMobile]
  );
  const { pathname } = useRouter();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  return (
    <Root dir="ltr">
      <Container isMobile={isMobile}>
        <CustomTransparentDiv />
        <Banner isMobile={isMobile}>
          <BannerSection>
            <LogoContainer>
              <Logo />
            </LogoContainer>
            <Title $isAnimate={isAnimate} isMobile={isMobile}>
              <TopTitle isMobile={isMobile}>
                <FormattedMessage id={Message.SUPPLIER_HEADER_TITLE} />
                <br />
                <span>
                  <FormattedMessage
                    id={Message.SUPPLIER_HEADER_TITLE_CAPTION_BOLD}
                  />
                </span>
                <br />
                <FormattedMessage id={Message.SUPPLIER_HEADER_TITLE_CAPTION} />
              </TopTitle>
            </Title>
            {actionButton}
          </BannerSection>
        </Banner>
        <ImageContainer isMobile={isMobile}>
          <img
            src="https://patricia-ai-data-production.s3.eu-central-1.amazonaws.com/media/static_images/sellerBackground.png"
            alt="not availble"
          />
        </ImageContainer>
      </Container>
      <MainTitle>
        <p>
          <FormattedMessage id={Message.SUPPLIER_HEADER_MAIN_TITLE} />
        </p>
      </MainTitle>
      {pathname === ClientRouter.BECOME_A_SELLER && (
        <BrandLogoCarousel supplierList={supplierData} />
      )}
      <ModalContainer open={isOpen} onClose={() => setIsOpen(false)} />
      <PopupModal
        open={isModalOpen}
        url="https://calendly.com/dan1707"
        rootElement={document.getElementById('root')!}
        onModalClose={() => {
          setIsModalOpen(false);
        }}
      />
    </Root>
  );
};

export default Header;

const Root = styled.section`
  width: 100%;
`;

const MainTitle = styled.div`
  background: black;
  & > p {
    opacity: 0.5;
    font-weight: bold;
    font-size: 2rem;
    text-transform: uppercase;
    text-align: center;
    padding: 20px 50px;
    letter-spacing: 0.5rem;

    @media ${SMALL_WIDTH_RESOLUTION} {
      font-size: 1.5rem;
      padding: 20px;
    }

    @media ${MOBILE_RESOLUTION} {
      font-size: 1.2rem;
    }
    @media ${MOBILE_TABLET_RESOLUTION} {
      letter-spacing: 0.1rem;
    }
  }
`;
const LogoContainer = styled.div`
  padding: 0px 20px;
  @media ${MOBILE_TABLET_RESOLUTION} {
    text-align: center;
  }
`;
const CustomTransparentDiv = styled.div`
  width: 100vw;
  height: calc(100vh + 10px);
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.8),
    rgba(25, 25, 25, 0.3)
  );
  @media ${TABLET_RESOLUTION} {
    background-color: rgba(0, 0, 0, 0.2);
  }
  position: absolute;
  top: 0;
`;
const Container = styled.header.attrs({ className: 'home-hero-header' })<{
  isMobile: boolean;
}>`
  width: 100%;
  padding: 0;
  background-image: url(${UserImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  z-index: 999;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  height: 100vh;

  @media ${MOBILE_TABLET_RESOLUTION} {
    top: 0;
    display: flex;
    align-items: center;
    padding: 0;
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      flex-direction: column;
    `}
`;

const Title = styled(animated.div)<{ $isAnimate: boolean; isMobile: boolean }>`
  z-index: 99999999;
  padding: 0 20px;
  color: white;
  @media ${TABLET_RESOLUTION} {
  }
  display: grid;
  height: calc(100% - 134px);
  & > svg {
    width: 62px;
    height: 92px;
  }
  & button {
    padding: 0 40px !important;
  }
  ${({ isMobile }) =>
    isMobile &&
    css`
      width: 100%;
      margin: auto;
    `}
`;

const TopTitle = styled(TextContent)<{ isMobile: boolean }>`
  font-size: 4.5rem;
  font-weight: 400;
  line-height: ${({ theme }) => (theme.dir === 'rtl' ? 8 : 8)}rem;

  & > span {
    font-weight: bold;
    font-size: 5.5rem;
  }

  p {
    margin: 40px 0 0 0 !important;
    width: fit-content;
  }

  @media (max-height: 800px) {
    line-height: 7rem;
  }
  @media (max-width: 1785px) {
    font-size: 4.5rem;
    & > span {
      font-size: 5.5rem;
    }
  }
  @media (max-width: 1568px) {
    font-size: 4.2rem;
    & > span {
      font-size: 5.2rem;
    }
  }

  @media ${SMALL_RESOLUTION} {
    font-size: 4.1rem;
    & > span {
      font-size: 5.1rem;
    }
  }

  @media ${TABLET_RESOLUTION} {
    font-size: 3.2rem;
    line-height: 6rem;
    p {
      margin: auto !important;
    }
    & > span {
      font-size: 3.5rem;
    }
  }
  @media ${MOBILE_TABLET_RESOLUTION} {
    line-height: 5rem;
    text-align: center;
    p {
      margin: auto !important;
    }
    & > span {
      font-size: 3.5rem;
    }
  }

  @media (max-width: 684px) {
    font-size: 2.3rem;
    & > span {
      font-size: 2.5rem;
    }
  }

  @media ${MOBILE_RESOLUTION} {
    text-align: center;
    line-height: 4rem;
    font-size: 1.8rem;
    & > span {
      font-size: 2rem;
    }
  }

  @media (max-height: 667px) {
    line-height: 3rem;
  }
  @media ${SMALL_MOBILE_RESOLUTION} {
    font-size: 1.5rem;
    & > span {
      font-size: 1.6rem;
    }
  }
`;

const Banner = styled.div.attrs({
  className: 'home-hero-text-container',
})<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? 'inherit' : '85%')};
  margin: 0 7.5%;
  height: auto;
  flex: 1;
  position: absolute;
  top: 150px;
  color: white;

  @media ${TABLET_RESOLUTION} {
    top: 150px;
    width: 100%;
    margin: 0;
  }

  @media (max-height: 800px) {
    top: 90px !important;
  }
  @media ${SMALL_MOBILE_RESOLUTION} {
    top: 90px;
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      width: 100%
      text-align: center;
      margin: 0;
    `}
`;

const BannerSection = styled.div`
  height: 100%;

  @media ${TABLET_RESOLUTION} {
    width: 100%;
  }
`;

const ActionButton = styled.div<{ isMobile: boolean }>`
  margin-top: 50px;
  width: 100%;
  z-index: 999999;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${MOBILE_TABLET_RESOLUTION} {
    width: 100%;
    margin: auto;
  }

  @media ${MOBILE_TABLET_RESOLUTION} {
    display: flex;
    flex-direction: column;
    margin: 60px 0px;
  }
  ${({ theme, isMobile }) =>
    theme.dir === 'rtl' &&
    isMobile &&
    css`
  width:90%;
  margin auto;
  
  `};
  a {
    height: 100%;

    button {
      font-size: 2rem !important;
      @media (max-width: 1400px) {
        font-size: 1.1rem !important;
      }
      @media ${MOBILE_TABLET_RESOLUTION} {
        font-size: 2rem !important;
      }
      min-width: 350px !important;
      line-height: 1rem;
      height: ${({ isMobile }) => (isMobile ? '70%' : '90%')} !important;
    }
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      margin-top: 25px;
      .enter-home-btn,
      .hero-action-button {
        width: 100%;
      }
    `};
`;

const ImageContainer = styled.div<{ isMobile: boolean }>`
  width: 100%;
  display: inline-block;
  height: 100vh;
  flex: 0.6;
  min-height: -webkit-fill-available;
  overflow: hidden;

  & > img {
    width: 100%;
    height: 100%;
  }
  ${({ isMobile }) =>
    isMobile &&
    css`
      width: 100%;
      padding: 0;
    `}
`;

const ModalContainer = styled(Modal)`
  &&.client-dialog .MuiDialog-paper .MuiDialogTitle-root {
    margin: 10px auto;
    padding: 10px 24px;
    font-size: 1.25rem;
    h2 {
      font-weight: 700;
    }
  }
  & .MuiDialog-paperWidthSm {
    max-width: 100%;
    height: 100%;
    width: 100%;

    @media (max-width: 1400px) {
      height: 90%;
    }
    @media (max-width: 1200px) {
      height: 80%;
    }
    @media (max-width: 1000px) {
      height: 70%;
    }
    @media (max-width: 800px) {
      height: 60%;
    }
    @media (max-width: 600px) {
      height: 50%;
    }
    @media (max-width: 500px) {
      height: 40%;
    }
  }

  & .MuiDialogContent-root {
    padding: 8px 50px;
    margin-bottom: 35px;
    color: rgba(21, 25, 32, 0.5);
  }
`;
