import type { IAddress, KPI } from 'types/common.types';
import type { Product } from 'types/products';
import type { Order } from 'types/orders';
import { SupplierStatisticsResponse } from 'store/types/user-management/suppliers.types';

export type SupplierCategory = {
  id?: number;
  name: string;
};

export enum FileStatus {
  Incomplete = 'pending',
  Done = 'success',
  Fail = 'failed',
  'Processed with errors' = 'processed_with_errors',
  'Processed with warnings' = 'processed_with_warnings',
}

export const FileStatusColors = {
  [FileStatus.Incomplete]: '#FDEBC9',
  [FileStatus.Done]: '#B0E3B3',
  [FileStatus.Fail]: '#FF0000',
  [FileStatus['Processed with errors']]: '#FF9900',
  [FileStatus['Processed with warnings']]: '#FFFF00',
};

export type FileTask = {
  id: string;
  file?: string;
  file_name?: string;
  fileName: string;
  type: string;
  taskId: string;
  status: FileStatus;
  failed: number;
  succeed: number;
  missingData: number;
  missingDataFile?: string;
  created: number;
  updated: number;
  uploaded: number;
  total: number;
  detailsCount?: number;
  created_at?: string;
  updated_at?: string;
};

export enum AttachmentType {
  INVOICE = 'Invoice',
  PRICING_OFFER = 'Pricing offer',
  CONTRACT = 'Contract',
}

export type SupplierAttachment = {
  id: string;
  fileName: string;
  type: AttachmentType;
  href: string;
  uploadedBy?: string;
  createdAt?: Date | number | string;
};

export type SupplierOption = {
  id: string;
  name: string;
};

export type Supplier = {
  id: string;
  userID: string;
  avatar?: string | null;
  additionalPhoneNumber: string;
  registeredNumber?: string | null;
  companyName: string;
  categories?: SupplierCategory[];
  country: string;
  addresses: IAddress[] | null;
  firstName: string;
  lastName: string;
  email: string;
  KPIs: KPI[];
  phoneNumber: string;
  productsQuantity: number;
  isArchived?: boolean;
  products?: { results: Product[]; pageCount?: number };
  orders?: { results: Order[]; pageCount?: number };
  files?: { results: SupplierAttachment[]; pageCount?: number };
  statistics?: SupplierStatisticsResponse;
  website: string | null;
  totalSales?: number;
  logo?: string;
  white_logo?: string;
  productCount?: number;
  skuPrefix?: string;
};
