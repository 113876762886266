import * as React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import classnames from 'classnames';

interface DayProps {
  filled?: boolean;
  outlined?: boolean;
  highlighted?: boolean;
  disabled?: boolean;
  visible?: boolean;
  startOfRange?: boolean;
  isStartOfWeek?: boolean;
  isEndOfWeek?: boolean;
  endOfRange?: boolean;
  onClick?: () => void;
  onHover?: () => void;
  value: number | string;
}

const Day: React.FC<DayProps> = ({
  startOfRange,
  endOfRange,
  isStartOfWeek,
  isEndOfWeek,
  disabled,
  highlighted,
  outlined,
  filled,
  visible,
  onClick,
  onHover,
  value,
}: DayProps) => {
  return (
    <Container
      className={classnames({
        invisible: !visible,
        start: startOfRange || isStartOfWeek,
        end: endOfRange || isEndOfWeek,
        highlighted: !disabled && highlighted,
      })}
    >
      <Button
        className={classnames({
          outlined: !disabled && outlined,
          filled: !disabled && filled,
        })}
        disabled={disabled}
        onClick={onClick}
        onMouseOver={onHover}
      >
        <Typography variant="body2">{value}</Typography>
      </Button>
    </Container>
  );
};

export default Day;

const Container = styled.div`
  display: flex;
  margin: 1px 0px;

  &.invisible {
    visibility: hidden;
  }

  &&.highlighted {
    background-color: #ff3100;
    color: white;
  }

  &.start {
    border-radius: 50% 0 0 50%;
  }

  &.end {
    border-radius: 0 50% 50% 0;
  }

  &.start.end {
    border-radius: 50%;
  }
`;

const Button = styled(IconButton)`
  height: 35px;
  width: 35px;
  padding: 0;
  color: #242e4c;

  &&.MuiIconButton-root.Mui-disabled {
    color: rgba(36, 46, 76, 0.4);
  }

  && .MuiTypography-root {
    font-size: 0.875rem;
  }

  &.outlined {
    border: 1px solid #242e4c;
  }

  &.filled {
    &:hover {
      background-color: #952002;
    }
    background-color: ${({ theme }) => theme.colors.primary.light};
    border-radius: inherit;
    color: white;
    opacity: 85%;
  }
`;
