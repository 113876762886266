import { managementRoutes } from './management.routes';
import { authRoutes } from './auth.routes';
import { supplierRoutes } from './supplier.routes';
import { clientRoutes } from './client.routes';

const routeConfig = {
  managementRoutes,
  clientRoutes,
  supplierRoutes,
  authRoutes,
};

export default routeConfig;
