import { Supplier } from 'types/suppliers';
import * as actions from 'store/actions/user-management/suppliers.actions';
import SuppliersDataType from 'store/constants/user-management/suppliers.constants';
import * as Payload from 'store/types/user-management/suppliers.types';

export interface SuppliersState {
  loading: Record<SuppliersDataType, boolean>;
  error: Record<SuppliersDataType, null | string>;
  users: Supplier[];
  pageCount: number;
}

const REQUESTS = [
  SuppliersDataType.GET_USERS_DATA_REQUEST,
  SuppliersDataType.GET_USER_DATA_REQUEST,
  SuppliersDataType.GET_ORDERS_DATA_REQUEST,
  SuppliersDataType.GET_FILES_DATA_REQUEST,
  SuppliersDataType.GET_PRODUCTS_DATA_REQUEST,
];

const initialState: SuppliersState = {
  loading: REQUESTS.reduce(
    (state, next) => ({ ...state, [next]: false }),
    {} as Record<SuppliersDataType, boolean>
  ),
  error: REQUESTS.reduce(
    (state, next) => ({ ...state, [next]: null }),
    {} as Record<SuppliersDataType, null | string>
  ),
  users: [],
  pageCount: 0,
};

const suppliersReducer = (
  state: SuppliersState = initialState,
  action: actions.SuppliersAction
): SuppliersState => {
  switch (action.type) {
    case SuppliersDataType.GET_USERS_DATA_REQUEST:
      return {
        ...updateLoading(state, SuppliersDataType.GET_USERS_DATA_REQUEST),
      };
    case SuppliersDataType.GET_USERS_DATA_FULFILLED:
      return updateSuccess(
        state,
        SuppliersDataType.GET_USERS_DATA_REQUEST,
        action.payload as Payload.GetUsersDataFulfilledPayload
      );

    case SuppliersDataType.GET_USERS_DATA_REJECTED:
      return {
        ...updateError(
          state,
          SuppliersDataType.GET_USERS_DATA_REQUEST,
          action.payload as Payload.GetUsersDataRejectedPayload
        ),
      };
    case SuppliersDataType.GET_USER_DATA_REQUEST:
      return updateLoading(state, SuppliersDataType.GET_USER_DATA_REQUEST);
    case SuppliersDataType.GET_USER_DATA_FULFILLED:
      return updateSuccess(
        state,
        SuppliersDataType.GET_USER_DATA_REQUEST,
        action.payload as Payload.GetUserDataFulfilledPayload
      );
    case SuppliersDataType.GET_USER_DATA_REJECTED:
      return updateError(
        state,
        SuppliersDataType.GET_USER_DATA_REQUEST,
        action.payload as Payload.GetUserDataRejectedPayload
      );
    case SuppliersDataType.GET_ORDERS_DATA_REQUEST:
      return {
        ...updateLoading(state, SuppliersDataType.GET_ORDERS_DATA_REQUEST),
      };
    case SuppliersDataType.GET_ORDERS_DATA_FULFILLED:
      return updateSuccess(
        state,
        SuppliersDataType.GET_ORDERS_DATA_REQUEST,
        action.payload as Payload.GetOrdersDataFulfilledPayload
      );

    case SuppliersDataType.GET_ORDERS_DATA_REJECTED:
      return {
        ...updateError(
          state,
          SuppliersDataType.GET_ORDERS_DATA_REQUEST,
          action.payload as Payload.GetOrdersDataRejectedPayload
        ),
      };
    case SuppliersDataType.GET_FILES_DATA_REQUEST:
      return {
        ...updateLoading(state, SuppliersDataType.GET_FILES_DATA_REQUEST),
      };
    case SuppliersDataType.GET_FILES_DATA_FULFILLED:
      return updateSuccess(
        state,
        SuppliersDataType.GET_FILES_DATA_REQUEST,
        action.payload as Payload.GetFilesDataFulfilledPayload
      );

    case SuppliersDataType.GET_FILES_DATA_REJECTED:
      return {
        ...updateError(
          state,
          SuppliersDataType.GET_FILES_DATA_REQUEST,
          action.payload as Payload.GetFilesDataRejectedPayload
        ),
      };

    // SUPPLIER PRODUCTS
    case SuppliersDataType.GET_PRODUCTS_DATA_REQUEST:
      return {
        ...updateLoading(state, SuppliersDataType.GET_PRODUCTS_DATA_REQUEST),
      };
    case SuppliersDataType.GET_PRODUCTS_DATA_FULFILLED:
      return updateSuccess(
        state,
        SuppliersDataType.GET_PRODUCTS_DATA_REQUEST,
        action.payload as Payload.GetProductsDataFulfilledPayload
      );

    case SuppliersDataType.GET_PRODUCTS_DATA_REJECTED:
      return {
        ...updateError(
          state,
          SuppliersDataType.GET_PRODUCTS_DATA_REQUEST,
          action.payload as Payload.GetProductsDataRejectedPayload
        ),
      };
    default:
      return state;
  }
};

export default suppliersReducer;

type requestType =
  | SuppliersDataType.GET_USERS_DATA_REQUEST
  | SuppliersDataType.GET_USER_DATA_REQUEST
  | SuppliersDataType.GET_ORDERS_DATA_REQUEST
  | SuppliersDataType.GET_FILES_DATA_REQUEST
  | SuppliersDataType.GET_PRODUCTS_DATA_REQUEST;
const updateLoading = (state: SuppliersState, requestType: requestType) => ({
  ...state,
  loading: {
    ...state.loading,
    [requestType]: true,
  },
  error: {
    ...state.error,
    [requestType]: null,
  },
});

type ErrorPayload =
  | Payload.GetUsersDataRejectedPayload
  | Payload.GetUserDataRejectedPayload
  | Payload.GetOrdersDataRejectedPayload
  | Payload.GetFilesDataRejectedPayload
  | Payload.GetProductsDataRejectedPayload;
const updateError = (
  state: SuppliersState,
  requestType: requestType,
  { error }: ErrorPayload
) => ({
  ...state,
  loading: {
    ...state.loading,
    [requestType]: false,
  },
  error: {
    ...state.error,
    [requestType]: error,
  },
});
type SuccessPayload =
  | Payload.GetUsersDataFulfilledPayload
  | Payload.GetUserDataFulfilledPayload
  | Payload.GetOrdersDataFulfilledPayload
  | Payload.GetFilesDataFulfilledPayload
  | Payload.GetProductsDataFulfilledPayload;
const updateSuccess = (
  state: SuppliersState,
  requestType: requestType,
  payload: SuccessPayload
) => ({
  ...state,
  loading: {
    ...state.loading,
    [requestType]: false,
  },
  error: {
    ...state.error,
    [requestType]: null,
  },
  ...payload,
});
