import { useMediaQuery } from '@material-ui/core';
import { MOBILE_RESOLUTION } from 'styles/sharedStyle';
import MobileCart from './MobileCart';
import PropertyCartWeb from './PropertyCartWeb';

const PropertyCart: React.FC<{ isLoading: boolean }> = ({ isLoading }) => {
  const isMobile = useMediaQuery(MOBILE_RESOLUTION);
  return isMobile ? (
    <MobileCart isLoading={isLoading} />
  ) : (
    <PropertyCartWeb isLoading={isLoading} />
  );
};

export default PropertyCart;
