import * as React from 'react';
import { Cart } from 'types/properties';
import styled from 'styled-components';
import { useMediaQuery } from '@material-ui/core';
import { MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';
import MobileView from 'pages/appClient/checkout/Checkout.mobile';
import WebView from 'pages/appClient/checkout/Checkout.web';
import { useCheckout } from 'pages/appClient/checkout/Checkout.config';
import PaymentForm from 'components/@client/PaymentForm/PaymentForm';
import { useRouter } from 'hooks';

interface Props {
  cart: Cart | null;
}

const CheckoutAddressModal: React.FC<Props> = ({ cart }) => {
  const isMobile = useMediaQuery(MOBILE_TABLET_RESOLUTION);
  const { history, location, query } = useRouter<{ payment: string }>();
  const toggle = React.useCallback(() => {
    history.push(`${location.search}&isPaymentPage=true`);
  }, [history, location]);
  const checkoutProps = useCheckout();
  const { address } = checkoutProps;

  if (query.isPaymentPage) {
    return (
      <PaymentFormContainer>
        <PaymentForm address={address} cart={cart} tourSection />;
      </PaymentFormContainer>
    );
  }

  return (
    <Container>
      <div>
        {isMobile ? (
          <MobileView openModal={toggle} {...checkoutProps} isModal />
        ) : (
          <CheckoutWeb>
            <WebView openModal={toggle} {...checkoutProps} isModal />
          </CheckoutWeb>
        )}
      </div>
    </Container>
  );
};

export default CheckoutAddressModal;

const Container = styled.div`
  width: 100%;
  ${({ theme }) => theme.utils.scrollbar};
  .MuiDialogContent-root {
    overflow: auto;
    width: 100%;
  }
  .MuiDialog-paperWidthSm {
    width: 1200px;
    max-width: none;
  }
`;

const CheckoutWeb = styled.div`
  display: flex;
  justify-content: space-around;
`;

const PaymentFormContainer = styled.div`
  .payment-form-container {
    display: flex;
    min-height: 60vh;
    #payment-iframe {
      margin: auto;
      border: 1px solid #dcdcdc !important;
    }
  }
`;
