import * as React from 'react';
import { View, StyleSheet, Text, Font } from '@react-pdf/renderer';
import { VarelaRound } from 'documents/documents.config';
import { Order } from 'types/orders';
import { getTaxRate } from 'utils/number.utils';
import { toCurrencyString, toPercentageString } from 'utils/string.utils';
import theme from 'styles/theme';
import { SupportedCountry } from 'types/common.types';

Font.register(VarelaRound);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',

    justifyContent: 'flex-end',
    fontFamily: 'Varela Round',
    backgroundColor: '#F7F7F7',
    paddingBottom: 17,
  },
  productCounter: {
    fontSize: 10,
    color: '#A0A0A0',
  },
  calculations: {
    width: 230,

    backgroundColor: theme.colors.primary.lightMobile,
  },
  calculation: {
    fontWeight: 400,
    fontSize: '14px',
  },
  label: {
    fontSize: 10,
  },
  value: {
    marginTop: 10,
    fontSize: 12,
  },
  bold: {
    fontWeight: 500,
  },
  totalCalculation: {
    fontWeight: 500,
    fontSize: '20px',
  },
});

const Totals: React.FC<{ order: Order }> = ({
  order: { products, subTotal, supplier, vat, finalPrice, discount },
}) => {
  return (
    <View style={styles.container}>
      <View style={styles.calculations}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: '8px',
            marginHorizontal: '12px',
            color: 'white',
          }}
        >
          <View>
            <Text style={styles.calculation}>Sub Total</Text>
          </View>
          <View>
            <Text style={styles.calculation}>
              {toCurrencyString({
                value: subTotal,
                country: supplier?.country,
              })}
            </Text>
          </View>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: '8px',
            marginHorizontal: '12px',
            color: 'white',
          }}
        >
          <View>
            <Text style={styles.calculation}>Discount</Text>
          </View>
          <View>
            <Text style={styles.calculation}>
              {toCurrencyString({
                value: discount,
                country: supplier?.country,
              })}
            </Text>
          </View>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: '8px',
            marginHorizontal: '12px',
            color: 'white',
            marginBottom: '12px',
          }}
        >
          <View>
            <Text style={styles.calculation}>
              VAT(
              {toPercentageString({
                value: getTaxRate(supplier?.country as SupportedCountry),
              })}
              )
            </Text>
          </View>
          <View>
            <Text style={styles.calculation}>
              {toCurrencyString({
                value: vat,
                country: supplier?.country,
              })}
            </Text>
          </View>
        </View>
        <View
          style={{ backgroundColor: 'white', height: '1px', width: '100%' }}
        />
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginVertical: '8px',
            marginHorizontal: '12px',
            color: 'white',
          }}
        >
          <View>
            <Text style={styles.totalCalculation}>TOTAL</Text>
          </View>
          <View>
            <Text style={styles.totalCalculation}>
              {toCurrencyString({
                value: finalPrice,
                country: supplier?.country,
              })}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default Totals;
