import * as React from 'react';
import styled from 'styled-components';
import { useCategories, useRouter } from 'hooks';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import { Route, Switch } from 'react-router-dom';
import Icon from 'components/Icon';
import LoginWelcomeImage from 'assets/@client/supplier-login-flow/supplier-login-welcome.png';
import {
  SupplierOnboardingAction,
  useSupplierOnboardingDispatch,
} from 'context/supplierOnboardingContext';
import { ClientRouter, IRouteConfig } from 'router/routes';
import { MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';
import { useMediaQuery } from '@mui/material';
import SelectCategoryMobile from './SelectCategoryMobile';

const SelectCategory: React.FC<{ routes: IRouteConfig[] }> = ({ routes }) => {
  const { Categories: categoryOptions = [] } = useCategories();
  const [selectedCategories, setSelectedCategories] = React.useState<
    Array<number>
  >([]);
  const dispatch = useSupplierOnboardingDispatch();
  const { history } = useRouter();
  const isMobile = useMediaQuery(MOBILE_TABLET_RESOLUTION);
  return (
    <div dir="ltr">
      <Switch>
        {routes.map(props => (
          <Route key={props.path} {...props} />
        ))}
        {isMobile ? (
          <SelectCategoryMobile />
        ) : (
          <ParentSelectCategory>
            <LeftContainer>
              <Title>
                <FormattedMessage id={Message.SUPPLIER_WHAT_DO_YOU_SELL} />
              </Title>
              <SubTitle>
                <FormattedMessage id={Message.SUPPLIER_SELECT_CATEGORY} />
              </SubTitle>
              <CategoryContainer>
                <MainContainer>
                  <Categories>
                    {!categoryOptions && (
                      <CustomIcon name="spinner" size={30} />
                    )}
                    {categoryOptions?.length &&
                      categoryOptions.map(category => {
                        return (
                          <CategoryBox
                            key={category.id}
                            onClick={() => {
                              if (selectedCategories.includes(category.id))
                                setSelectedCategories(prev =>
                                  prev.filter(data => data !== category.id)
                                );
                              else
                                setSelectedCategories(prev => [
                                  ...prev,
                                  category.id,
                                ]);
                            }}
                          >
                            <StyledCheckbox
                              type="checkbox"
                              checked={selectedCategories.includes(category.id)}
                              onChange={e => {
                                if (e.target.checked)
                                  setSelectedCategories(prev => [
                                    ...prev,
                                    category.id,
                                  ]);
                                else
                                  setSelectedCategories(prev =>
                                    prev.filter(data => data !== category.id)
                                  );
                              }}
                            />
                            <ImageParent>
                              <CategoryImage
                                checked={selectedCategories.includes(
                                  category.id
                                )}
                                src={category.icon}
                                alt="category"
                              />
                            </ImageParent>
                            <CategoryName
                              checked={selectedCategories.includes(category.id)}
                            >
                              {category.name}
                            </CategoryName>
                          </CategoryBox>
                        );
                      })}
                  </Categories>
                  <NextButton
                    onClick={() => {
                      dispatch({
                        type: SupplierOnboardingAction.SET_SELECTED_CATEGORIES,
                        payload: selectedCategories,
                      });
                      history.push(ClientRouter.SUPPLIER_LOGIN_THANKS);
                    }}
                    disabled={!selectedCategories.length}
                  >
                    <NextText>
                      <FormattedMessage
                        id={Message.USER_DELETE_ACCOUNT_NEXT_BUTTON}
                      />
                    </NextText>
                  </NextButton>
                </MainContainer>
              </CategoryContainer>
            </LeftContainer>
            <RightContainer>
              <img
                style={{ height: '100%' }}
                src={LoginWelcomeImage}
                alt="login-seller"
              />
            </RightContainer>
          </ParentSelectCategory>
        )}
      </Switch>
    </div>
  );
};

export default SelectCategory;

const MainContainer = styled.div``;

const Categories = styled.div`
  width: 608px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  height: 650px;
  overflow-y: overlay;
  ${({ theme }) => theme.utils.scrollbar};
  &::-webkit-scrollbar-thumb {
    background-color: #11516d;
  }
`;
const CategoryBox = styled.div`
  height: 174px;
  width: 184px;
  border: 1px solid #e8eaeb;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
`;

const StyledCheckbox = styled.input`
  height: 20px;
  width: 20px;
  margin: 8px 0 0 8px;
  position: absolute;
  cursor: pointer;
  :checked {
    accent-color: #11516d;
  }
`;

const NextButton = styled.button`
  :disabled {
    cursor: default;
    background: #d9d9d9;
  }
  cursor: pointer;
  background: #fc5c42;
  border: 0.5px solid #d9d9d9;
  border-radius: 15px;
  padding: 13px 48px;
  width: 134px;
  height: 48px;
  display: flex;
  margin: 32px auto 0 auto;
`;

const NextText = styled.div`
  color: rgba(255, 255, 255, 0.9);
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
`;

const ImageParent = styled.div`
  height: 72px;
  width: 72px;
  margin: 32px 56px 0 56px;
`;
const CategoryImage = styled.img<{ checked: boolean }>`
  height: 100%;
  width: 100%;
  opacity: ${({ checked }) => (checked ? 0.5 : 1)};
`;

const CategoryName = styled.div<{ checked: boolean }>`
  font-size: 18px;
  line-height: 22px;
  color: #3e3e3e;
  margin-top: 16px;
  text-align: center;
  opacity: ${({ checked }) => (checked ? 0.5 : 1)};
`;

const CustomIcon = styled(Icon)`
  ${({ theme }) => theme.utils.spinAnimation};
  position: absolute;
  fill: #3e3e3e;
  top: 50%;
  left: 25%;
`;

const ParentSelectCategory = styled.div`
  display: flex;
  margin-top: -65px;
  font-family: 'Roboto Condensed';
`;

const LeftContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 0;
`;

const Title = styled.div`
  color: #282929;
  font-weight: 600;
  font-size: 40px;
  line-height: 44px;
`;

const SubTitle = styled.div`
  font-size: 24px;
  line-height: 28px;
  color: #3e3e3e;
  margin-top: 8px;
`;

const CategoryContainer = styled.div`
  margin-top: 32px;
`;
const RightContainer = styled.div`
  width: 50%;
`;
