import * as React from 'react';
import type { Option } from 'components/Autocomplete/Autocomplete.config';
import type { Setter } from 'types/common.types';

type ComboBoxConfig = {
  setValue?: Setter<Option[]> | Setter<Option | null>;
  value?: Option[] | Option | null;
  onApply?: (selection: Option[] | Option) => void;
};

const useCombobox = (config?: ComboBoxConfig) => {
  const { value, setValue, onApply } = config ?? {};
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const selectionRef = React.useRef<Option[] | Option | null>([]);

  const handleClick = React.useCallback(
    (event: React.SyntheticEvent<HTMLDivElement>) => {
      setAnchorEl(event.currentTarget as HTMLElement);
    },
    []
  );

  const handleClose = React.useCallback(() => {
    if (anchorEl) anchorEl.focus();
    setAnchorEl(null);
  }, [anchorEl]);

  const handleClear = React.useCallback(() => {
    const setItems = setValue as Setter<Option[]>;
    setItems([]);
  }, [setValue]);

  const handleApply = React.useCallback(() => {
    onApply?.(selectionRef.current);
    setAnchorEl(null);
  }, [onApply]);

  const open = React.useMemo(() => Boolean(anchorEl), [anchorEl]);

  React.useEffect(() => {
    selectionRef.current = value as Option[];
  }, [value]);

  return React.useMemo(
    () => ({
      anchorEl,
      handleClick,
      handleClose,
      handleClear,
      handleApply,
      open,
    }),
    [anchorEl, handleApply, handleClear, handleClick, handleClose, open]
  );
};

export default useCombobox;
