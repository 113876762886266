import * as React from 'react';
import styled from 'styled-components';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Breadcrumbs from 'components/@client/Breadcrumbs';
import NotFound from 'components/@client/NotFound';
import ProductsSection from 'components/@client/ProductsSection';
import SlideshowModal from 'components/@client/SlideshowModal';
import Orders from 'components/@client/Orders/Orders';
import Title from 'components/@client/TopTitle';
import Loader from 'components/Loader';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import { NO_PROPERTY_NAME } from 'constants/common.constants';
import { useAuthentication, useRouter } from 'hooks';
import { ClientRouter } from 'router/routes';
import { MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';
import { sortBy, groupBy } from 'utils/lodash.utils';
import { getRoute } from 'utils/sidebar.utils';
import { LogsAPI } from 'api/methods';
import { userLogData } from 'mockData/userLogData';
import { PROPERTIES_STATUS } from 'types/properties';
import { Type } from 'constants/config';
import useProperty from './useProperty';
import PropertyCard from './Components/PropertyCard';
import MediaCarousel from './Components/MediaCarousel';
import MediaSection from './Components/MediaSection';
import MatrixSection from './Components/MatrixSection';

const Property = () => {
  const isMobile = useMediaQuery(MOBILE_TABLET_RESOLUTION);
  const { property, isLoading, hasError, callFetch } = useProperty();
  const { query } = useRouter<{
    backTo?: string;
    id: string;
    projectid: string;
    name: string;
  }>();
  const [mediaIndex, setMediaIndex] = React.useState<number>(0);

  const links = [
    {
      to: query.backTo ?? ClientRouter.PROPERTIES,
      children: query.backTo ? (
        'My inspiration quizzes'
      ) : (
        <FormattedMessage id={Message.PROPERTIES_TITLE} />
      ),
    },
    hasError
      ? {
          to: '/',
          children: <FormattedMessage id={Message.PROPERTY_NOT_FOUND} />,
        }
      : {
          to: getRoute([ClientRouter.PROPERTIES, `/${property?.id}`]),
          children: property?.name.length ? property?.name : NO_PROPERTY_NAME,
        },
  ];

  const link = [
    {
      to: ClientRouter.PROPERTIES_SELLER,
      children: <FormattedMessage id={Message.PROPERTIES_INFO_BOX_TITLE} />,
    },
    {
      to: ClientRouter.PROPERTIES_SELLER_LISTING,
      children: <FormattedMessage id={Message.MYPROJECT} />,
    },
    {
      to: `${ClientRouter.PROPERTIES_SELLER_LISTING}/${query.projectid}`,
      children: query.name,
    },
    { to: '', children: property?.name },
  ];
  const mediaInfo = React.useMemo(() => sortBy(property?.media ?? [], 'type'), [
    property?.media,
  ]);

  const groupedMedia = React.useMemo(
    () => groupBy(property?.media ?? [], 'type'),
    [property?.media]
  );
  const [selectedMedia, setSelectedMedia] = React.useState<
    keyof typeof groupedMedia | null
  >(null);

  const toggleSlideShow = React.useCallback(
    (mediaType: typeof selectedMedia) => {
      if (mediaType)
        LogsAPI.postUserLogs({
          area: userLogData.eventArea.myProperties,
          section: userLogData.eventSection.propertyPage,
          name: userLogData.eventName.showAllMedia,
          path: userLogData.eventPath.appPropertiesIdPath,
          metadata: { property_id: query.id },
        });
      setSelectedMedia(mediaType);
    },
    [query.id]
  );
  const { type } = useAuthentication();
  const tourLink =
    property?.status === PROPERTIES_STATUS.Published
      ? ClientRouter.TOUR.replace(':propertyId', query.id)
      : ClientRouter.PROPERTY_IN_PROGRESS;
  return (
    <Container>
      {isLoading ? (
        <Loader fullHeight />
      ) : (
        <>
          {type !== Type.SELLER_CLIENT && (
            <Breadcrumbs
              links={
                query.projectid !== undefined && query.name !== undefined
                  ? link
                  : links
              }
            />
          )}
          {!isMobile ||
            (hasError && (
              <Title
                title={<FormattedMessage id={Message.PROPERTY_NOT_FOUND} />}
                backTo={hasError ? ClientRouter.PROPERTIES : undefined}
              />
            ))}
          {!property || hasError ? (
            <NotFound />
          ) : (
            <>
              <PropertyCard
                showShare={type === Type.SUPPLIER || type === Type.SELLER}
                isLoading={isLoading}
                property={property}
                onMediaClick={() =>
                  toggleSlideShow(property?.media?.[0]?.type ?? null)
                }
                updateProperty={callFetch}
              />
              {isMobile ? (
                <MediaCarousel
                  items={property?.media ?? []}
                  isLoading={isLoading}
                  onClick={typemedia => toggleSlideShow(typemedia)}
                />
              ) : (
                <MediaSection
                  property={property}
                  onMediaClick={val => {
                    toggleSlideShow(property?.media?.[val]?.type ?? null);
                    setMediaIndex(val);
                  }}
                  callFetch={callFetch}
                />
              )}
              <ProductsSection propertyId={query.id} />

              {property.unit ? (
                <MatrixSection
                  propertyId={(property.unit as unknown) as string}
                />
              ) : (
                <Orders propertyId={query.id} />
              )}

              {selectedMedia && (
                <SlideshowModal
                  onClick={() => toggleSlideShow(null)}
                  open={Boolean(selectedMedia)}
                  items={mediaInfo}
                  tourLink={tourLink}
                  selectedIndex={mediaIndex}
                  setSelectedIndex={setMediaIndex}
                  callFetch={callFetch}
                  isFromProperty={true}
                />
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default Property;

const Container = styled.section`
  height: 100%;
  max-width: 1920px;
  margin: 0 auto;
  .breadcrumbs {
    padding: 24px 80px;
  }

  .border.bottom {
    width: 70%;
  }
`;
