import * as React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Button from 'components/@client/Button';
import { ClientRouter } from 'router/routes';
import { LogsAPI } from 'api/methods';
import { Message } from 'i18n';
import { shallowEqual, useSelector } from 'store/selectors';
import { recentPropertySelector } from 'store/selectors/@client/userInfo.selectors';
import { PROPERTIES_STATUS } from 'types/properties';
import { userLogData } from 'mockData/userLogData';
import { useSession } from 'hooks';

interface Props {
  isLoading: boolean;
  desktop?: boolean;
}

const UserButton: React.FC<Props> = ({ isLoading, desktop }) => {
  const recentProperty = useSelector(recentPropertySelector, shallowEqual);
  const { isFromSupportedCountry } = useSession();

  const to = React.useMemo(() => {
    if (!isFromSupportedCountry) return ClientRouter.APP;
    if (recentProperty?.status !== PROPERTIES_STATUS.Published)
      return ClientRouter.PROPERTY_IN_PROGRESS;
    if (recentProperty?.id)
      return ClientRouter.TOUR.replace(':propertyId', recentProperty?.id ?? '');
    return ClientRouter.PROPERTIES;
  }, [isFromSupportedCountry, recentProperty?.id, recentProperty?.status]);

  return (
    <StyledLink to={to}>
      <HeaderButton
        className="hero-action-button"
        disableRipple={desktop}
        isLoading={isLoading}
        disabled={isLoading || !isFromSupportedCountry}
        onClick={() =>
          LogsAPI.postUserLogs({
            name: userLogData.eventName.enterYourHome,
            area: userLogData.eventArea.homePage,
            section: userLogData.eventSection.heroSection,
            path: userLogData.eventPath.appPath,
            metadata: { property_id: recentProperty?.id },
          })
        }
      >
        <FormattedMessage
          id={
            recentProperty
              ? Message.ENTER_HOME_BUTTON
              : Message.CREATE_HOME_BUTTON
          }
          defaultMessage="Create your home"
        />
      </HeaderButton>
    </StyledLink>
  );
};

export default UserButton;

const HeaderButton = styled(Button)`
  && {
    border-radius: 7px;
    font-weight: 400;
    font-size: 20px;
    padding: 0 15px;
    font-family: ${({ theme }) =>
      theme.dir === 'rtl' ? ' Assistant' : 'inherit'};
  }
`;

const StyledLink = styled(Link).attrs(({ to }) => ({
  className: 'enter-home-btn',
  as: to === ClientRouter.APP ? 'span' : undefined,
}))`
  text-decoration: none;
  z-index: 5;
`;
