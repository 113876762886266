import { LogsAPI } from 'api/methods';
import { useRecentProperty, useRouter } from 'hooks';
import { userLogData } from 'mockData/userLogData';
import useConfirmPlan from 'pages/appClient/onboarding/confirm-plan/useConfirmPlan';
import * as React from 'react';
import { ClientRouter } from 'router/routes';
import WebView from './Done.web';

const DoneViews = () => {
  const { history } = useRouter();
  // const isMobile = useMediaQuery(MOBILE_RESOLUTION);
  const { selectedFloor } = useConfirmPlan();
  const { recentProperty } = useRecentProperty();

  const goToProducts = React.useCallback(() => {
    LogsAPI.postUserLogs({
      area: userLogData.eventArea.registration,
      section: userLogData.eventSection.registrationDone,
      name: userLogData.eventName.browseCatalog,
      path: userLogData.eventPath.appOnBoardingDonePath,
    });
    history.push(ClientRouter.PRODUCTS);
  }, [history]);

  const goToTour = React.useCallback(() => {
    LogsAPI.postUserLogs({
      area: userLogData.eventArea.registration,
      section: userLogData.eventSection.registrationDone,
      name: userLogData.eventName.takeATour,
      path: userLogData.eventPath.appOnBoardingDonePath,
    });
    if (recentProperty) {
      history.push(
        ClientRouter.TOUR.replace(':propertyId', recentProperty?.id)
      );
    }
  }, [history, recentProperty]);

  return (
    <WebView
      onClick={goToProducts}
      goToTour={goToTour}
      userImage={selectedFloor?.file}
      property={recentProperty}
    />
  );
};

export default DoneViews;
