import * as React from 'react';
import { ClientRouter } from 'router/routes';
import styled from 'styled-components';
import { SearchResults, Product } from 'types/products';
import Grid from '@material-ui/core/Grid';
import SafeImg from 'components/SafeImg';
import { oliveGreen } from 'styles/sharedStyle';
import { Message } from 'i18n';
import { FormattedMessage } from 'react-intl';
import Loader from 'components/Loader';
import NoProduct from 'components/@client/NoProduct/NoProduct';
import ShowMoreButton from 'components/@client/Button/ShowMoreButton';
import { GetProductsDataResponsePayload } from 'store/types/user-management/suppliers.types';
import Divider from '@material-ui/core/Divider';
import { StyledLink } from '../products/DesktopProducts';
import BestSellerCard from '../products/components/BestSellerCard';
import {
  Card,
  CategoryText,
  Img,
} from '../products/components/ProductCategory';

interface Props {
  searchResults: SearchResults | null;
  searchName?: string;
  supplierId?: number;
  pageNo: number;
  setPageNo: () => void;
  showMoreBtn: boolean;
  productList: Product[];
  isLoading: boolean;
  productData?: GetProductsDataResponsePayload | null;
  handleSupplier: (data: string) => void;
}

const DesktopSearch: React.FC<Props> = ({
  searchResults,
  supplierId,
  pageNo,
  setPageNo,
  showMoreBtn,
  productList,
  isLoading,
  searchName = '',
  productData,
  handleSupplier,
}) => {
  return (
    <Container>
      <CategoryText>
        <FormattedMessage id={Message.PRODUCTS} />
        <CounterContainer>
          {`(${
            (supplierId
              ? productData?.count
              : searchResults?.products?.count) || 0
          })`}
        </CounterContainer>
      </CategoryText>
      <Grid container>
        {productList.length
          ? productList.map(data => (
              <Grid item md={3} sm={3} key={data.id}>
                <BestSellerCard product={data} />
              </Grid>
            ))
          : !isLoading && <NoProduct value={Message.NO_PRODUCT_AVAILABLE} />}
      </Grid>
      {!!showMoreBtn && !isLoading && (
        <div>
          <ShowMoreBtnDiv>
            <ShowMoreButton
              onClick={() => setPageNo()}
              className="show-more-button"
            >
              <FormattedMessage id={Message.SKINS_SHOW_MORE_BUTTON} />
            </ShowMoreButton>
          </ShowMoreBtnDiv>
        </div>
      )}
      {productList && isLoading && <Loader />}
      {!supplierId && !!searchResults?.subCategories?.count && (
        <>
          <Separator />
          <CategoryText>
            <FormattedMessage id={Message.SUB_CATEGORIES} />
            <CounterContainer>
              {`(${searchResults?.subCategories?.count || 0})`}
            </CounterContainer>
          </CategoryText>
          <Grid container>
            {searchResults?.subCategories?.count ? (
              searchResults.subCategories.results.map(data => (
                <Grid item md={4} sm={4} key={data.name}>
                  <StyledLink
                    to={
                      data?.category
                        ? ClientRouter.PRODUCT_SUB_CATEGORY.replace(
                            ':catName',
                            data.category.name
                          ).replace(':subCatName', data.name)
                        : ClientRouter.PRODUCTS
                    }
                  >
                    <Card>
                      <Img>
                        <SafeImg src={data.thumbnail} alt={data.name} />
                      </Img>
                      <div>
                        <h4>{data.name}</h4>
                      </div>
                    </Card>
                  </StyledLink>
                </Grid>
              ))
            ) : (
              <NoProduct value={Message.NO_SUB_CATEGORY} />
            )}
          </Grid>
        </>
      )}
      {!supplierId && !!searchResults?.categories?.count && (
        <>
          <Separator />
          <CategoryText>
            <FormattedMessage id={Message.CATEGORIES} />
            <CounterContainer>
              {`(${searchResults?.categories?.count || 0})`}
            </CounterContainer>
          </CategoryText>
          <Grid container>
            {searchResults?.categories?.count ? (
              searchResults.categories.results.map(data => (
                <Grid item md={4} sm={4} key={data.name}>
                  <StyledLink
                    to={ClientRouter.PRODUCT_CATEGORY.replace(
                      ':catName',
                      data.name
                    )}
                  >
                    <Card>
                      <Img>
                        <SafeImg src={data.thumbnail} alt={data.name} />
                      </Img>
                      <div>
                        <h4>{data.name}</h4>
                      </div>
                    </Card>
                  </StyledLink>
                </Grid>
              ))
            ) : (
              <NoProduct value={Message.NO_CATEGORY} />
            )}
          </Grid>
        </>
      )}

      {!supplierId && !!searchResults?.suppliers?.count && (
        <>
          <Separator />
          <CategoryText>
            <FormattedMessage id={Message.BRANDS} />
            <CounterContainer>
              {`(${searchResults?.suppliers?.count || 0})`}
            </CounterContainer>
          </CategoryText>
          <Grid container>
            {searchResults?.suppliers?.count ? (
              searchResults.suppliers.results.map(data => (
                <Grid item md={4} sm={4} key={data.id}>
                  <StyledLink
                    to={`${ClientRouter.SEARCH_PAGE.replace(
                      ':searchName',
                      searchName
                    )}?supplierId=${data.id}`}
                    onClick={() => handleSupplier(data.companyName)}
                  >
                    <Card>
                      <Img>
                        <SafeImg src={data.logo} alt={data.companyName} />
                      </Img>
                      <div>
                        <h4>{data.companyName}</h4>
                      </div>
                    </Card>
                  </StyledLink>
                </Grid>
              ))
            ) : (
              <NoProduct value={Message.NO_RESULT} />
            )}
          </Grid>
        </>
      )}
    </Container>
  );
};

export default DesktopSearch;

const Container = styled.section``;

const ShowMoreBtnDiv = styled.div`
  text-align: center;
  padding: 15px;

  && .show-more-button {
    background-color: ${oliveGreen};
    border-radius: 12px;
    font-weight: 400;

    @media (min-width: 1150px) {
      max-width: 252px;
      height: 48px;
      font-size: 22px;
      line-height: 26px;
      padding: 11px 64px;
    }

    &:hover {
      background-color: #2e3f31;
    }
  }
`;

const CounterContainer = styled.span`
  font-size: 1.25rem;
  color: ${({ theme }) => theme.colors.black0};
`;

const Separator = styled(Divider)`
  margin: 10px 0;
`;
