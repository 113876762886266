import * as React from 'react';
import styled from 'styled-components';
import { Color } from 'types/quizzes';

const ColorDisplay: React.FC<Color> = ({ name, color }) => (
  <Container>
    <ColorBlock $value={color} />
    {name}
  </Container>
);

const Container = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ColorBlock = styled.div<{ $value: string }>`
  height: 16px !important;
  width: 25px !important;
  background-color: ${({ $value }) => $value} !important;
  border-radius: 4px;
`;

export default ColorDisplay;
