import styled from 'styled-components';
import Breadcrumbs from 'components/@client/Breadcrumbs';
import { ClientRouter } from 'router/routes';
import { ClientRoot } from 'styles/sharedStyle';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import Title from 'components/@client/TopTitle/Title';
import BuyMorePatriciaCoinsDetail from './BuyMorePatriciaCoinDetail';

const BuyMorePatriciaCoins = () => {
  return (
    <ClientRoot>
      <Breadcrumbs
        links={[
          {
            to: ClientRouter.PROFILE,
            children: <FormattedMessage id={Message.MENU_ITEM_PROFILE} />,
          },
          {
            to: ClientRouter.BUY_MORE_PATRICIA_COINS,
            children: (
              <FormattedMessage
                id={Message.MENU_ITEM_BUY_MORE_PATRICIA_COINS}
              />
            ),
          },
        ]}
      />
      <MainTitle>
        <Title
          title={
            <FormattedMessage id={Message.MENU_ITEM_BUY_MORE_PATRICIA_COINS} />
          }
          backTo={ClientRouter.APP}
        />
      </MainTitle>
      <BuyMorePatriciaCoinsDetail />
    </ClientRoot>
  );
};
const MainTitle = styled.div`
  margin-top: 24px;
  .client-top-title {
    justify-content: start;
    .title {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 48px;
      line-height: 56px;
      color: #282929;
    }
  }
`;
export default BuyMorePatriciaCoins;
