import * as React from 'react';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import styled from 'styled-components';

interface Props {
  icon?: React.ReactNode;
  open: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const ArrowButton: React.FC<Props> = ({ open, onClick }) => (
  <Button color="inherit" onClick={onClick}>
    <Arrow open={open} />
  </Button>
);

export default ArrowButton;

const Button = styled(IconButton)`
  && {
    border-radius: 50%;
  }
`;

export const Arrow = styled(props =>
  props?.icon ? (
    props?.icon
  ) : (
    <KeyboardArrowDown fontSize="inherit" {...props} />
  )
)<{ open: boolean }>`
  font-size: 1.5rem;
  ${({ theme, open }) => theme.utils.rotateIcon({ isOpen: open })};
`;

export const CustomArrow = styled(props => (
  <IconButton style={{ borderRadius: '50%' }} onClick={props.onClick}>
    {props?.icon ? (
      props?.icon
    ) : (
      <ArrowDropDownIcon fontSize="inherit" {...props} />
    )}
  </IconButton>
))<{ open: boolean; onClick: () => void }>`
  font-size: 2rem;
  color: #5c669d;

  ${({ theme, open }) => theme.utils.rotateIcon({ isOpen: open })};
`;
