import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';

export const SkinsBlock = styled.div.attrs({ className: 'skins-block' })`
  width: 100%;
  height: 400px;
  @media ${MOBILE_TABLET_RESOLUTION} {
    height: ${({ theme }) => (theme.dir === 'rtl' ? '270' : '260')}px;
  }
  & .flex {
    display: flex;
    @media ${MOBILE_TABLET_RESOLUTION} {
      justify-content: space-between;
    }
    & .skin {
      height: 310px;
      min-width: 25%;
      flex: 0.25;
      border-radius: 25px;
      @media ${MOBILE_TABLET_RESOLUTION} {
        flex: 0.5;
        height: 200px;
        max-width: calc(50% - 20px);
        min-width: calc(50% - 20px);
      }
    }
    & .skin:not(:last-of-type) {
      ${rtl`
        margin-right: 20px;
      `}
    }
  }
`;
