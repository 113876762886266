import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Message } from 'i18n';
import styled from 'styled-components';
import { MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';
import { map } from 'utils/lodash.utils';
import { AuthRoute, ClientRouter } from 'router/routes';
import Tooltip from 'components/Tooltip';
import Button from 'components/Button/PrimaryButton';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import rtl from 'styled-components-rtl';
import { useRouter, useValidateUser } from 'hooks';
import { useMediaQuery } from '@material-ui/core';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { userLogData } from 'mockData/userLogData';
import { LogsAPI } from 'api/methods';
import { Type } from 'constants/config';
import LinkItem, { LinkItemProps } from './LinkItem';

const LinksStack = () => {
  const intl = useIntl();
  const { history } = useRouter();
  const { isAuthenticated, type } = useValidateUser();
  const [open, setOpen] = React.useState(false);
  const isMobile = useMediaQuery(MOBILE_TABLET_RESOLUTION);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const links: Record<string, LinkItemProps[]> = {
    [Message.FOOTER_ABOUT_MENU_TITLE]: [
      {
        id: Message.FOOTER_ABOUT_MENU_OUR_STORY,
        to: ClientRouter.COMING_SOON,
        onClick: () => {
          LogsAPI.postUserLogs({
            name: userLogData.eventName.ourStory,
            area: userLogData.eventArea.homePage,
            section: userLogData.eventSection.footer,
            path: userLogData.eventPath.appPath,
          });
        },
      },
      {
        id: Message.FOOTER_ABOUT_MENU_CAREERS,
        to: ClientRouter.COMING_SOON,
        onClick: () => {
          LogsAPI.postUserLogs({
            name: userLogData.eventName.careers,
            area: userLogData.eventArea.homePage,
            section: userLogData.eventSection.footer,
            path: userLogData.eventPath.appPath,
          });
        },
      },
      {
        id: Message.FOOTER_ABOUT_MENU_MAGAZINE,
        to: ClientRouter.MAGAZINE,
        onClick: () => {
          LogsAPI.postUserLogs({
            name: userLogData.eventName.magazine,
            area: userLogData.eventArea.homePage,
            section: userLogData.eventSection.footer,
            path: userLogData.eventPath.appPath,
          });
        },
      },
    ],
    [Message.FOOTER_SUPPORT_MENU_TITLE]: [
      {
        id: Message.FOOTER_SUPPORT_MENU_QA,
        to: ClientRouter.COMING_SOON,
        onClick: () => {
          LogsAPI.postUserLogs({
            name: userLogData.eventName.supportFaqs,
            area: userLogData.eventArea.homePage,
            section: userLogData.eventSection.footer,
            path: userLogData.eventPath.appPath,
          });
        },
      },
      {
        id: Message.FOOTER_SUPPORT_MENU_TERMS,
        to: ClientRouter.TERMS_OF_USE,
        onClick: () => {
          LogsAPI.postUserLogs({
            name: userLogData.eventName.termsOfUse,
            area: userLogData.eventArea.homePage,
            section: userLogData.eventSection.footer,
            path: userLogData.eventPath.appPath,
          });
        },
      },
      {
        id: Message.FOOTER_SUPPORT_MENU_PRIVACY,
        to: ClientRouter.PRIVACY_POLICY,
        onClick: () => {
          LogsAPI.postUserLogs({
            name: userLogData.eventName.privacyPolicy,
            area: userLogData.eventArea.homePage,
            section: userLogData.eventSection.footer,
            path: userLogData.eventPath.appPath,
          });
        },
      },
      {
        id: Message.FOOTER_SUPPORT_MENU_CONTACT,
        to: ClientRouter.CONTACT,
        onClick: () => {
          LogsAPI.postUserLogs({
            name: userLogData.eventName.contactUs,
            area: userLogData.eventArea.homePage,
            section: userLogData.eventSection.footer,
            path: userLogData.eventPath.appPath,
          });
        },
      },
      {
        id: Message.FOOTER_SUPPORT_MENU_ACCESSIBILITY,
        to: ClientRouter.ACCESSIBILITY_PAGE,
        onClick: () => {
          LogsAPI.postUserLogs({
            name: userLogData.eventName.accessibility,
            area: userLogData.eventArea.homePage,
            section: userLogData.eventSection.footer,
            path: userLogData.eventPath.appPath,
          });
        },
      },
    ],
    [Message.FOOTER_COMMUNITY_MENU_TITLE]:
      type !== Type.SELLER &&
      type !== Type.SUPPLIER &&
      type !== Type.SELLER_CLIENT
        ? [
            {
              id: Message.HOME_BECOME_A_SUPPLIER_TITLE,
              to: isAuthenticated
                ? ClientRouter.SUPPLIER_LOGIN_WELCOME
                : ClientRouter.SUPPLIER_LOGIN,
              onClick: () => {
                LogsAPI.postUserLogs({
                  name: userLogData.eventName.becomeAnExpert,
                  area: userLogData.eventArea.homePage,
                  section: userLogData.eventSection.footer,
                  path: userLogData.eventPath.appPath,
                });
              },
            },
            {
              id: Message.FOOTER_COMMUNITY_MENU_SUPPLIER,
              to: ClientRouter.BECOME_A_SELLER,
            },
            {
              id: Message.FOOTER_COMMUNITY_MENU_SUPPLIER_INTERFACE,
              to: AuthRoute.LOGIN,
              onClick: () => {
                LogsAPI.postUserLogs({
                  name: userLogData.eventName.suppliersInterface,
                  area: userLogData.eventArea.homePage,
                  section: userLogData.eventSection.footer,
                  path: userLogData.eventPath.appPath,
                });
              },
            },
          ]
        : [
            {
              id: Message.FOOTER_COMMUNITY_MENU_SUPPLIER,
              to: ClientRouter.BECOME_A_SELLER,
            },
            {
              id: Message.FOOTER_COMMUNITY_MENU_SUPPLIER_INTERFACE,
              to: AuthRoute.LOGIN,
              onClick: () => {
                LogsAPI.postUserLogs({
                  name: userLogData.eventName.suppliersInterface,
                  area: userLogData.eventArea.homePage,
                  section: userLogData.eventSection.footer,
                  path: userLogData.eventPath.appPath,
                });
              },
            },
          ],
  };

  const contactUSBox = () => {
    return (
      <List>
        <FormattedMessage
          id={Message.FOOTER_CONTACT_MENU_CUSTOMER_SERVICE}
          tagName="li"
        />
        <li>
          <FormattedMessage
            id={Message.FOOTER_CONTACT_MENU_PRIMARY_OFFICE_TITLE}
            tagName="strong"
          />
        </li>
        <li>
          <FormattedMessage id={Message.FOOTER_CONTACT_MENU_WORK_DAYS} />{' '}
          <FormattedMessage
            id={Message.FOOTER_CONTACT_MENU_PRIMARY_OFFICE_WORK_DAYS}
          />
        </li>
        <li>
          <FormattedMessage id={Message.FOOTER_CONTACT_MENU_WORK_HOURS} />{' '}
          <FormattedMessage
            id={Message.FOOTER_CONTACT_MENU_PRIMARY_OFFICE_WORK_HOURS}
          />
        </li>
        <li className="phone">
          <a
            className="phone"
            href={`tel: ${intl.formatMessage({
              id: Message.FOOTER_CONTACT_MENU_PRIMARY_OFFICE_PHONE,
            })}`}
          >
            <FormattedMessage
              id={Message.FOOTER_CONTACT_MENU_PRIMARY_OFFICE_PHONE}
            />
          </a>
        </li>
        <li>
          <a
            className="mail"
            href={`mailto: ${intl.formatMessage({
              id: Message.FOOTER_CONTACT_MENU_PRIMARY_OFFICE_EMAIL,
            })}`}
          >
            <FormattedMessage
              id={Message.FOOTER_CONTACT_MENU_PRIMARY_OFFICE_EMAIL}
            />
          </a>
        </li>
        <li>
          <FormattedMessage
            id={Message.FOOTER_CONTACT_MENU_SECONDARY_OFFICE_TITLE}
            tagName="strong"
          />
        </li>
        <li>
          <FormattedMessage id={Message.FOOTER_CONTACT_MENU_WORK_DAYS} />{' '}
          <FormattedMessage
            id={Message.FOOTER_CONTACT_MENU_SECONDARY_OFFICE_WORK_DAYS}
          />
        </li>
        <li>
          <FormattedMessage id={Message.FOOTER_CONTACT_MENU_WORK_HOURS} />{' '}
          <FormattedMessage
            id={Message.FOOTER_CONTACT_MENU_SECONDARY_OFFICE_WORK_HOURS}
          />
        </li>
        <li className="phone">
          <a
            className="phone"
            href={`tel: ${intl.formatMessage({
              id: Message.FOOTER_CONTACT_MENU_SECONDARY_OFFICE_PHONE,
            })}`}
          >
            <FormattedMessage
              id={Message.FOOTER_CONTACT_MENU_SECONDARY_OFFICE_PHONE}
            />
          </a>
        </li>
        <li>
          <a
            className="mail"
            href={`mailto: ${intl.formatMessage({
              id: Message.FOOTER_CONTACT_MENU_SECONDARY_OFFICE_EMAIL,
            })}`}
          >
            <FormattedMessage
              id={Message.FOOTER_CONTACT_MENU_SECONDARY_OFFICE_EMAIL}
            />
          </a>
        </li>
      </List>
    );
  };

  return (
    <Container>
      {map(links, (messages, title) => (
        <React.Fragment key={title}>
          <ListWrapper>
            <Title>
              <FormattedMessage id={title} />
            </Title>
            <List>
              {messages.map(message => (
                <LinkItem key={message.id} {...message} />
              ))}
            </List>
          </ListWrapper>
          <Divider />
        </React.Fragment>
      ))}
      <ListWrapper>
        <Title className="row">
          <FormattedMessage id={Message.FOOTER_CONTACT_MENU_TITLE} />
        </Title>
        <ButtonContainer>
          <Button
            onClick={() => {
              LogsAPI.postUserLogs({
                name: userLogData.eventName.quickService,
                area: userLogData.eventArea.homePage,
                section: userLogData.eventSection.footer,
                path: userLogData.eventPath.appPath,
              });
              history.push(ClientRouter.CONTACT);
            }}
          >
            <AccessTimeIcon fontSize="small" />
            <FormattedMessage id={Message.FOOTER_CONTACT_QUICK_SERVICE} />
          </Button>
        </ButtonContainer>
        {isMobile ? (
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
              <Tooltip
                placement="auto"
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={contactUSBox()}
                $variant="info"
              >
                <TooltipText onClick={handleTooltipOpen}>
                  <FormattedMessage id={Message.FOOTER_CONTACT_WEEKLY_HOURS} />
                </TooltipText>
              </Tooltip>
            </div>
          </ClickAwayListener>
        ) : (
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
              <Tooltip
                // PopperProps={{
                //   disablePortal: true,
                // }}
                // onClose={handleTooltipClose}
                open={open}
                placement="left"
                disableFocusListener
                disableHoverListener
                disableTouchListener
                $variant="info"
                title={contactUSBox()}
              >
                <TooltipText onClick={handleTooltipOpen}>
                  <FormattedMessage id={Message.FOOTER_CONTACT_WEEKLY_HOURS} />
                </TooltipText>
              </Tooltip>
            </div>
          </ClickAwayListener>
        )}
      </ListWrapper>
    </Container>
  );
};

export default LinksStack;

const Title = styled.h4`
  color: ${({ theme }) => theme.colors.primary.lightMobile};
  font-weight: 400;
  font-size: 1.063rem;

  &.row {
    @media ${MOBILE_TABLET_RESOLUTION} {
      margin-top: 20px;
    }
  }
`;

const ListWrapper = styled.div`
  padding: 0 10px;
  flex: 0.5;
  justify-content: flex-start;
`;

const List = styled.ul<{ row?: boolean }>`
  display: ${({ row = false }) => (row ? 'flex' : 'block')};
  margin-top: ${({ row = false }) => (row ? 0 : 20)}px;

  li {
    list-style: none;
    padding: 0;
    margin: 14px ${({ row = false }) => (row ? 15 : 0)}px;
    font-size: 15px;
    color: #1e2833;

    &.phone {
      direction: ltr;
    }
  }

  li:first-of-type {
    margin-left: 0;
  }

  .mail {
    color: #2466a8;
  }

  a {
    color: inherit;
    text-decoration: none;
    :hover {
      text-decoration: underline;
    }
  }
`;

const Container = styled.div`
  ${({ theme }) => theme.utils.centerFlex};
  flex: 2;
  padding: 0 34px;
  align-items: flex-start;

  @media ${MOBILE_TABLET_RESOLUTION} {
    display: block;
    padding: 0;
    text-align: center;
  }
`;

export const Divider = styled.hr`
  border-top: none;
  border-left: none;
  border-color: rgba(30, 40, 51, 0.0671438);
`;

const TooltipText = styled.span`
  color: ${({ theme }) => theme.colors.primary.lightMobile};
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
`;

const ButtonContainer = styled.div`
  margin: 14px 0;
  button {
    width: auto !important;
    svg {
      ${rtl`
        margin-right: 4px;
      `}
    }
  }
`;
