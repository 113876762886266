/* eslint-disable no-param-reassign */
import * as React from 'react';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { useSelect } from 'hooks';
import Select, {
  PopperPlacementType,
  PopperModifiersType,
  OptionsGenericType,
} from 'components/@client/Select';
import { Arrow, CustomArrow } from 'components/Button/ArrowButton';
import { Option } from 'components/Autocomplete/Autocomplete.config';
import { ClickAwayListener } from '@material-ui/core';
import { ControlProps } from './types';

interface Props extends ControlProps<Option[]> {
  disableSelectAll?: boolean;
  selectedPlaceholder?: string;
  disabled?: boolean;
  width?: number;
  customIcon?: boolean;
  placement?: PopperPlacementType;
  renderOption: (
    selectedItems: Option[],
    multiple?: boolean
  ) => (newValue: Option) => JSX.Element;
  buttonClassName?: string;
  modifiers?: PopperModifiersType;
  popperOptions?: OptionsGenericType;
  deselectOnReselect?: boolean;
  parentRef?: React.MutableRefObject<HTMLDivElement | null>;
}

const SelectFilter: React.FC<Props> = ({
  disableSelectAll,
  onApply,
  options,
  placeholder,
  selectedPlaceholder,
  multiple = true,
  initialState = [],
  disabled = false,
  placement = 'auto',
  width,
  customIcon,
  renderOption,
  buttonClassName,
  modifiers,
  popperOptions,
  deselectOnReselect = true,
  parentRef,
}) => {
  const [selectedItems, setSelected] = React.useState<Option[]>([
    ...initialState,
  ]);
  const selectFilterProps = useSelect<Option>({
    options,
    selectedItems,
    setSelected,
    multiple: multiple || undefined,
    onApply: onApply as (selection: Option[] | Option) => void,
    applyOnChange: true,
    deselectOnReselect,
  });
  const { isOpen } = selectFilterProps;

  React.useEffect(() => {
    if (parentRef && parentRef?.current) {
      if (isOpen) {
        parentRef.current.style.overflowY = 'hidden';
        parentRef.current.style.maxWidth = '498px';
      } else {
        parentRef.current.style.overflowY = 'scroll';
        parentRef.current.style.maxWidth = 'unset';
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const option = React.useMemo(() => renderOption(selectedItems, multiple), [
    renderOption,
    selectedItems,
    multiple,
  ]);

  return (
    <ClickAwayListener onClickAway={() => selectFilterProps.toggle()}>
      <div>
        <Select
          options={options}
          selectedItems={selectedItems}
          placeholder={placeholder}
          selectedPlaceholder={selectedPlaceholder}
          multiple={multiple || undefined}
          disabled={disabled}
          placement={placement}
          modifiers={modifiers}
          popperOptions={popperOptions}
          width={width}
          disableSelectAll={disableSelectAll}
          endIcon={
            customIcon ? (
              <CustomArrowIcon
                open={selectFilterProps.isOpen}
                fontSize="inherit"
              />
            ) : (
              <DefaultArrow
                open={selectFilterProps.isOpen}
                fontSize="inherit"
              />
            )
          }
          renderOption={option}
          {...selectFilterProps}
          buttonClassName={buttonClassName}
        />
      </div>
    </ClickAwayListener>
  );
};

export default SelectFilter;

const CustomArrowIcon = styled(Arrow)`
  font-size: 1.214rem;
  position: relative;
  ${rtl`
    right: 0;
  `}
`;

const DefaultArrow = styled(CustomArrow)`
  position: relative;

  ${rtl`
  right: 0;
`}
`;
