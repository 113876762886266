import { createStore, applyMiddleware, Store, PreloadedState } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import history from 'router/history';
import rootSaga from 'store/sagas';
import type { RootState, RootAction } from 'store/types';
import rootReducer from './reducers';

const configureStore = (
  initialState?: PreloadedState<RootState>
): Store<RootState, RootAction> => {
  const composeEnhancers = composeWithDevTools({});
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware))
  );

  sagaMiddleware.run(rootSaga);
  return store;
};

export default configureStore();
