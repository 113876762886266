import * as React from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as PatriciaLogo } from 'assets/@client/supplier/patricia.svg';
import { ReactComponent as MacbookImage } from 'assets/@client/supplier/laptop-macbook.svg';
import ImageInsideMacbook from 'assets/@client/supplier/image-inside-macbook.png';
import MobileImage from 'assets/@client/supplier/iphone-mobile.png';
import CheckVector from 'assets/@client/onboarding/CheckVector.png';
import rtl from 'styled-components-rtl';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import { LandingProps } from './SellerLandingPageContainer';

const ThankYou: React.FC<LandingProps> = ({ supplierData }) => {
  return (
    <HeaderSection>
      <LeftContainer>
        <ImageContainer>
          <img src={CheckVector} alt="check" />
        </ImageContainer>
        <MessageParent>
          <SuccessMessage>
            <FormattedMessage id={Message.LANDING_PAGE_THANKYOU_SUCCESS} />
          </SuccessMessage>
          <SuccessSubMessage>
            <FormattedMessage
              id={Message.LANDING_PAGE_THANKYOU_SUCCESS_SUBTITLE}
              values={{ linebreak: <br />, mobileLineBreak: '' }}
            />
          </SuccessSubMessage>
          {/* <AppointmentText>
            <FormattedMessage
              id={Message.LANDING_PAGE_THANKYOU_SUCCESS_APPOINTMENT_TEXT}
              values={{
                mobileLineBreak: '',
                contactName: supplierData?.supplier.user?.first_name,
                contactNumber:
                  supplierData?.supplier.additional_phone_number ??
                  supplierData?.supplier.user?.phone_number,
              }}
            />
          </AppointmentText> */}
        </MessageParent>
      </LeftContainer>
      <RightContainer>
        <div className="macbook-parent-div">
          <PatriciaLogoContainer logoColor={supplierData?.color} />
          <MacbookImageContainer />
          <img
            src={supplierData?.hero_gif ?? ImageInsideMacbook}
            alt="hero gif"
            className="hero-gif"
          />
          <MobileImageContainer src={MobileImage} alt="iphone-image" />
        </div>
      </RightContainer>
    </HeaderSection>
  );
};

const RightContainer = styled.div`
  .macbook-parent-div {
    position: relative;
  }
  ${rtl`margin-left: 8px;`}
  .hero-gif {
    width: 596.6px;
    height: 335.58px;
    top: 120px;
    ${rtl`right: 170px;`}
    border-radius: 4px;
    position: absolute;
  }
`;

const PatriciaLogoContainer = styled(PatriciaLogo)<{ logoColor?: string }>`
  position: relative;

  margin: ${({ theme }) =>
    theme.dir === 'rtl' ? '0 108px 0 272px;' : '0 155px 0 232px'};
  path {
    fill: ${({ logoColor }) => logoColor};
  }
`;

const MacbookImageContainer = styled(MacbookImage)`
  position: absolute;
  top: 98px;
  ${rtl`left: 42px;`}
`;

const MobileImageContainer = styled.img`
  position: absolute;
  bottom: 27px;
  right: ${({ theme }) => (theme.dir === 'rtl' ? '-98px' : '-46px')};
`;

const HeaderSection = styled.div`
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.01),
      rgba(255, 255, 255, 0.01)
    ),
    #fafafa;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  height: 100vh;
  width: 100vw;
  /* margin-top: 80px; */
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LeftContainer = styled.div`
  width: 755px;
  height: 384px;
  .left-text {
  }

  margin: 0;
  ${({ theme }) =>
    theme.dir === 'rtl' &&
    css`
      margin-left: 140px;
    `}
  color: #282929;
  h3 {
    font-weight: 600;
    font-size: 76px;
    line-height: 80px;
    margin: 0;
  }
  ul {
    margin-left: 43px;
    margin-top: 24px;
    ${({ theme }) =>
      theme.dir === 'rtl' &&
      css`
        padding-right: 38px;
      `};
  }
  li {
    font-weight: 400;
    font-size: 30px;
    line-height: 46px;
  }
  .tired-btn {
    width: 350px;
    height: 64px;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    background: #fc5c42;
    border-radius: 35px;
    color: #ffffff;
    text-transform: none;
    margin-top: 32px;
    border-radius: 35px;
    &:hover {
      background: #fc5c42;
      background: #a50a0a;
      color: #ffffff;
    }
    .right-arrow {
      font-size: 30px;
      ${({ theme }) =>
        theme.dir === 'rtl' &&
        css`
          rotate: 180deg;
        `}
    }
  }
`;

const ImageContainer = styled.div`
  width: min-content;
  margin: auto;
  margin-top: 32px;
`;

const SuccessMessage = styled.div`
  margin-top: 16px;
  font-weight: 600;
  font-size: 50px;
  line-height: 58px;
`;

const SuccessSubMessage = styled.div`
  margin-top: 16px;
  font-weight: 400;
  font-size: 30px;
  line-height: ${({ theme }) => (theme.dir === 'rtl' ? '36px' : '38px')};
`;

// const AppointmentText = styled.div`
//   margin-top: 24px;
//   font-weight: 400;
//   font-size: 24px;
//   line-height: 32px;
//   color: #696969;
// `;

const MessageParent = styled.div`
  text-align: center;
`;

export default ThankYou;
