import * as React from 'react';
import styled, { css } from 'styled-components';
import rtl from 'styled-components-rtl';
import { Chip, useMediaQuery } from '@material-ui/core';
import { GenericCarouselImage, MOBILE_RESOLUTION } from 'styles/sharedStyle';
import { FormattedMessage, useIntl } from 'react-intl';
import { Message } from 'i18n';
import { useSession } from 'hooks';
import { Room } from 'types/clients';
import { Visibility } from '@material-ui/icons';

interface Props {
  image: string;
  price?: string | React.ReactNode;
  userName?: string;
  name?: string;
  date?: string;
  views?: string;
  chipsData?: string | object;
  type?: string;
  onClick?: (type: string | null) => void;
  roomsData?: Room[];
  isSkinCard?: boolean;
}

const PinterestIdeasCard: React.FC<Props> = ({
  userName,
  image,
  price,
  name,
  date,
  chipsData,
  roomsData,
  onClick,
  views,
  type,
  isSkinCard,
}) => {
  const getRandomHeight = React.useCallback(
    () => (isSkinCard ? 388 : Math.floor(Math.random() * (420 - 230)) + 230),
    [isSkinCard]
  );
  const isMobile = useMediaQuery(MOBILE_RESOLUTION);
  const { currency } = useSession();
  const intl = useIntl();

  const chipsDataList = React.useMemo(() => {
    if (chipsData) {
      return typeof chipsData === 'string' ? JSON.parse(chipsData) : chipsData;
    }
    return null;
  }, [chipsData]);

  const finalPrice = React.useMemo(() => {
    let customPrice: number = 0;
    if (chipsDataList?.total_price && chipsDataList?.total_price >= 100000) {
      customPrice = 100;
      // eslint-disable-next-line no-else-return
    } else if (
      chipsDataList?.total_price &&
      chipsDataList?.total_price < 100000 &&
      chipsDataList?.total_price >= 50000
    ) {
      customPrice = 50;
    } else if (
      chipsDataList?.total_price &&
      chipsDataList?.total_price < 50000 &&
      chipsDataList?.total_price >= 30000
    ) {
      customPrice = 30;
    } else if (
      chipsDataList?.total_price &&
      chipsDataList?.total_price < 30000 &&
      chipsDataList?.total_price >= 20000
    ) {
      customPrice = 20;
    } else if (
      chipsDataList?.total_price &&
      chipsDataList?.total_price < 20000 &&
      chipsDataList?.total_price >= 10000
    ) {
      customPrice = 10;
    } else {
      return `0 - 2k`;
    }
    if (intl.locale === 'he') {
      const newVal = `+ K${intl.formatNumber(customPrice, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        style: 'currency',
        currency,
      })}`;
      return newVal
        .replace('\u200F', `\u200F${newVal[newVal.length - 1]}`)
        .substring(0, newVal.length - 1);
    }
    return `${intl.formatNumber(customPrice, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      style: 'currency',
      currency,
    })}K +`;
  }, [chipsDataList?.total_price, currency, intl]);

  const room = roomsData?.find(item => {
    return Number(item.id) === Number(chipsDataList?.room_id);
  });
  return (
    <CustomDivContainer
      style={{
        height: isSkinCard ? getRandomHeight() : getRandomHeight() + 80,
      }}
    >
      <CustomImage
        imgUrl={image}
        onClick={() => onClick?.(null)}
        isSkinCard={isSkinCard}
        className="idea-image"
      >
        <ImageContainer>
          {!isMobile && (
            <div className="shop-now">
              <HoverButton
                className="center-button"
                onMouseDown={event => {
                  if (event.button === 1) onClick?.('_blank');
                  if (event.button === 0) onClick?.(null);
                }}
                buttonColor="#a50a0a"
              >
                <FormattedMessage id={Message.TAKE_A_LOOK} />
              </HoverButton>
            </div>
          )}
          {type !== 'idea' && (
            <ChipsRow mobile={isMobile}>
              {chipsDataList?.total_price && (
                <HoverButton
                  className="chip final-price"
                  buttonColor="#49BF68"
                  width="108px"
                >
                  {finalPrice}
                </HoverButton>
              )}
              {room && room?.name && (
                <HoverButton
                  className="roomchip"
                  buttonColor="#4178E2"
                  width="auto"
                >
                  {room && room?.name}
                </HoverButton>
              )}
            </ChipsRow>
          )}
          {isMobile && (
            <ChipsRow mobile={true} style={{ left: '0' }}>
              <ViewCount>
                {intl.locale === 'he' ? (
                  <>
                    {`${views} `}
                    <Visibility />
                  </>
                ) : (
                  <>
                    <Visibility /> {` ${views}`}
                  </>
                )}
              </ViewCount>
            </ChipsRow>
          )}
        </ImageContainer>
      </CustomImage>
      {!isSkinCard && (
        <CardContainer>
          {name && <Title>{name}</Title>}
          {price && <Price>{price}</Price>}
          <SubTitleContainer>
            <CustomName>
              <Username>
                {userName} {!isMobile && '|'}
              </Username>
              <Date>
                {date && <> {date}</>}
                {!isMobile && ' |'}
              </Date>
            </CustomName>
            {views && !isMobile && (
              <ViewCount>
                {intl.locale === 'he' ? (
                  <>
                    {` ${views}`}
                    <Visibility />
                  </>
                ) : (
                  <>
                    <Visibility /> {`${views} `}
                  </>
                )}
              </ViewCount>
            )}
          </SubTitleContainer>
        </CardContainer>
      )}
      {type === 'idea' && (
        <IdeaChipsContainer>
          <Chip label={finalPrice} variant="outlined" />
          {room && <Chip label={room?.name} variant="outlined" />}
        </IdeaChipsContainer>
      )}
    </CustomDivContainer>
  );
};

const CustomDivContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media ${MOBILE_RESOLUTION} {
    margin: 5px 0;
  }
`;
export const IdeaChipsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-top: 8px;
  .MuiChip-root {
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    height: 26px;
    ${rtl`margin-right: 4px;`}
    font-weight: 400;
    font-size: 14px;
    color: #3e3e3e;
    @media (max-width: 767px) {
      font-size: 12px;
      height: 24px;
      ${rtl`margin-right: 0;`}
      .MuiChip-label {
        padding: 0 4px;
      }
    }
  }
`;
const SubTitleContainer = styled.div`
  font-size: 14px;
  color: #696969;
  display: flex;
  justify-content: space-between;
  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

const CardContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  @media (max-width: 767px) {
    margin-top: 8px;
  }
`;

const Title = styled.h4`
  font-family: ${({ theme }) => theme.dir === 'rtl' && 'Assistant'};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  margin: 0px;
`;

const Price = styled.span`
  font-family: ${({ theme }) => theme.dir === 'rtl' && 'Assistant'};
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #fc5c42;
  display: block;
`;

const CustomName = styled.div`
  display: flex;
  gap: 5px;
  direction: ${({ theme }) => theme.dir};
  flex-wrap: wrap;
  @media ${MOBILE_RESOLUTION} {
    display: contents;
  }
`;

const Username = styled.div`
  font-family: ${({ theme }) => theme.dir === 'rtl' && 'Assistant'};
  @media ${MOBILE_RESOLUTION} {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100px;
  }
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #696969;
`;

const Date = styled.div`
  font-family: ${({ theme }) => theme.dir === 'rtl' && 'Assistant'};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #696969;
`;

const ViewCount = styled.div`
  color: #696969;
  @media (max-width: 767px) {
    color: white;
  }
  svg {
    height: 14px;
    width: auto;
    vertical-align: middle;
    margin-top: -3px;
    color: #3e3e3e;
    @media (max-width: 767px) {
      color: white;
    }
  }
`;

const CustomImage = styled(GenericCarouselImage)<{ isSkinCard?: boolean }>`
  &:hover {
    .shop-now {
      opacity: 1;
    }
  }
  margin: 0;
  border-radius: 15px !important;
  &:hover {
    border-radius: 15px !important;
  }
  flex: 1;
  object-fit: cover;
  ${({ isSkinCard }) =>
    isSkinCard &&
    css`
      background-size: contain;
    `}
  width: 304px;
  height: 388px;
  @media ${MOBILE_RESOLUTION} {
    width: 100%;
    border-radius: 15px;
    background: ${({ imgUrl }) =>
      `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(${imgUrl}) center center no-repeat`};
    ${({ isSkinCard }) =>
      !isSkinCard &&
      css`
        background-size: cover;
      `}
  }
`;

const ChipsRow = styled.div<{ mobile?: boolean }>`
  bottom: 0;
  position: absolute;
  right: ${({ mobile }) => (mobile ? '0' : '')};
  display: flex;
  ${({ mobile }) =>
    mobile
      ? rtl` 
     margin: auto 8px 8px ;
  `
      : rtl` 
     margin: auto 16px 16px ;
  `}
`;

const HoverButton = styled.button<{
  buttonColor?: string;
  width?: string;
  height?: string;
}>`
  min-width: ${({ width }) => (!width ? '128px' : width)} !important;
  height: 36px;
  font-style: normal;
  font-family: inherit;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  text-transform: capitalize;
  ${rtl`
  margin-right: 8px;
  padding-right: 16px;
  padding-left: 16px;
  `}
  box-sizing: border-box;
  border-radius: 10px;

  @media ${MOBILE_RESOLUTION} {
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: #ffffff;
    padding: 6px 16px;
    margin-right: 0;
    :first-child {
      margin-right: 8px;
    }
  }

  ${({ buttonColor }) =>
    buttonColor &&
    css`
      background-color: ${buttonColor} !important;
      background: ${buttonColor} !important;
      border: 1px solid ${buttonColor};
    `}
  direction: ltr;
`;

const ImageContainer = styled.div`
  cursor: pointer;
  .center-button {
    margin: auto;
    height: 48px !important;
    width: 134px !important;
    border: 0.5px solid #a50a0a;
    box-sizing: border-box;
    border-radius: 15px;
    font-family: ${({ theme }) =>
      theme.dir === 'rtl' ? 'Assistant' : 'Roboto'};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: rgba(255, 255, 255, 0.9);

    cursor: pointer;
  }
  .shop-now {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));

    top: 0;
    border-radius: 15px;
    z-index: 99;
    display: flex;
  }
`;

export default PinterestIdeasCard;
