import * as React from 'react';
import { DateRange } from 'components/DateRange';
import { isEmpty, isEqual } from 'utils/lodash.utils';

const useDateRange = (
  onApply: (dateRange: DateRange) => void,
  initialState?: DateRange
) => {
  const [dateRange, setDateRange] = React.useState<DateRange>(
    initialState ?? {}
  );
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const toggle = React.useCallback(
    (event?: React.SyntheticEvent<HTMLDivElement, Event>) => {
      setOpen(prev => !prev);
      setAnchorEl(anchorEl ? null : event?.currentTarget!);
    },
    [anchorEl]
  );
  React.useEffect(() => {
    setDateRange(initialState ?? {});
  }, [initialState]);
  const handleDateRange = React.useCallback(
    (range: DateRange) => {
      setDateRange(range);
      if (
        isEmpty(range) ||
        (range.startDate && range.endDate && !isEqual(range, dateRange))
      )
        onApply(range);
    },
    [dateRange, onApply]
  );

  return {
    dateRange,
    handleDateRange,
    toggle,
    open,
    anchorEl,
  };
};

export default useDateRange;
