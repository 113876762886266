import * as React from 'react';
import styled from 'styled-components';

const Divider: React.FC = ({ children }) => {
  return (
    <DividersWrapper>
      <CustomDivider />
      <DividerContent>{children}</DividerContent>
      <CustomDivider />
    </DividersWrapper>
  );
};

export default Divider;

const DividersWrapper = styled.div.attrs({ className: 'divider-wrapper' })`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 40px 0 10px 0;
  width: 65%;
  color: #bababa;

  @media (max-width: 1500px) {
    margin: 14px 0;
  }
`;

const CustomDivider = styled.hr`
  border: 1px solid #f5f5f5;
  flex: 1;
`;

const DividerContent = styled.span``;
