import PropertiesType from 'store/constants/properties-management/PropertiesManagement.constants';
import * as Payload from 'store/types/properties-management/PropertiesManagement.types';

export const getPropertiesTasksDataRequest = (
  payload: Payload.GetPropertiesTasksDataRequestPayload
) => ({
  type: PropertiesType.GET_PROPERTIES_DATA_REQUEST,
  payload,
});

export const getPropertiesTasksDataFulfilled = (
  payload: Payload.GetPropertiesTasksFulfilledPayload
) => ({
  type: PropertiesType.GET_PROPERTIES_DATA_FULFILLED,
  payload,
});

export const getPropertiesTasksDataRejected = (
  payload: Payload.GetPropertiesTasksRejectedPayload
) => ({
  type: PropertiesType.GET_PROPERTIES_DATA_REJECTED,
  payload,
});

export type PropertiesTasksAction = ReturnType<
  | typeof getPropertiesTasksDataRequest
  | typeof getPropertiesTasksDataFulfilled
  | typeof getPropertiesTasksDataRejected
>;
