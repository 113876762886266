import { TeamMember } from 'types/teamPatricia';
import * as actions from 'store/actions/user-management/teamPatricia.actions';
import TeamPatriciaDataType from 'store/constants/user-management/teamPatricia.constants';
import * as Payload from 'store/types/user-management/teamPatricia.types';
import { Roles } from 'store/types/user-management/teamPatricia.types';

export interface TeamUsersState {
  loading: Record<TeamPatriciaDataType, boolean>;
  error: Record<TeamPatriciaDataType, null | string>;
  users: TeamMember[];
  pageCount: number;
  roles: Roles;
}

const REQUESTS = [
  TeamPatriciaDataType.GET_USERS_DATA_REQUEST,
  TeamPatriciaDataType.GET_ROLES_DATA_REQUEST,
  TeamPatriciaDataType.ADD_USER_DATA_REQUEST,
];

const initialState: TeamUsersState = {
  loading: REQUESTS.reduce(
    (state, next) => ({ ...state, [next]: false }),
    {} as Record<TeamPatriciaDataType, boolean>
  ),
  error: REQUESTS.reduce(
    (state, next) => ({ ...state, [next]: null }),
    {} as Record<TeamPatriciaDataType, null | string>
  ),
  users: [],
  pageCount: 0,
  roles: {
    pageCount: 0,
    results: [],
  },
};

const teamPatriciaUsersReducer = (
  state: TeamUsersState = initialState,
  action: actions.TeamPatriciaAction
): TeamUsersState => {
  switch (action.type) {
    case TeamPatriciaDataType.GET_USERS_DATA_REQUEST:
      return updateLoading(state, TeamPatriciaDataType.GET_USERS_DATA_REQUEST);
    case TeamPatriciaDataType.GET_USERS_DATA_FULFILLED:
      return updateSuccess(
        state,
        TeamPatriciaDataType.GET_USERS_DATA_REQUEST,
        action.payload as Payload.GetUsersDataFulfilledPayload
      );
    case TeamPatriciaDataType.GET_USERS_DATA_REJECTED:
      return updateError(
        state,
        TeamPatriciaDataType.GET_USERS_DATA_REQUEST,
        action.payload as Payload.GetUsersDataRejectedPayload
      );
    case TeamPatriciaDataType.GET_ROLES_DATA_REQUEST:
      return updateLoading(state, TeamPatriciaDataType.GET_ROLES_DATA_REQUEST);
    case TeamPatriciaDataType.GET_ROLES_DATA_FULFILLED:
      return updateSuccess(
        state,
        TeamPatriciaDataType.GET_ROLES_DATA_REQUEST,
        action.payload as SuccessPayload
      );
    case TeamPatriciaDataType.GET_ROLES_DATA_REJECTED:
      return updateError(
        state,
        TeamPatriciaDataType.GET_ROLES_DATA_REQUEST,
        action.payload as Payload.GetRolesDataRejectedPayload
      );
    case TeamPatriciaDataType.ADD_USER_DATA_REQUEST:
      return updateLoading(state, TeamPatriciaDataType.ADD_USER_DATA_REQUEST);
    case TeamPatriciaDataType.ADD_USER_DATA_FULFILLED:
      return updateSuccess(
        state,
        TeamPatriciaDataType.ADD_USER_DATA_REQUEST,
        action.payload as Payload.AddUserDataFulfilledPayload
      );
    case TeamPatriciaDataType.ADD_USER_DATA_REJECTED:
      return updateError(
        state,
        TeamPatriciaDataType.ADD_USER_DATA_REQUEST,
        action.payload as Payload.AddUserDataRejectedPayload
      );
    default:
      return state;
  }
};

export default teamPatriciaUsersReducer;

type requestType =
  | TeamPatriciaDataType.GET_USERS_DATA_REQUEST
  | TeamPatriciaDataType.GET_ROLES_DATA_REQUEST
  | TeamPatriciaDataType.ADD_USER_DATA_REQUEST;
const updateLoading = (state: TeamUsersState, requestType: requestType) => ({
  ...state,
  loading: {
    ...state.loading,
    [requestType]: true,
  },
  error: {
    ...state.error,
    [requestType]: null,
  },
});

type ErrorPayload =
  | Payload.GetUsersDataRejectedPayload
  | Payload.GetRolesDataRejectedPayload
  | Payload.AddUserDataRejectedPayload;
const updateError = (
  state: TeamUsersState,
  requestType: requestType,
  { error }: ErrorPayload
) => ({
  ...state,
  loading: {
    ...state.loading,
    [requestType]: false,
  },
  error: {
    ...state.error,
    [requestType]: error,
  },
});

type SuccessPayload =
  | Payload.GetUsersDataFulfilledPayload
  | Payload.GetRolesDataFulfilledPayload
  | Payload.AddUserDataFulfilledPayload;
const updateSuccess = (
  state: TeamUsersState,
  requestType: requestType,
  payload: SuccessPayload
) => ({
  ...state,
  loading: {
    ...state.loading,
    [requestType]: false,
  },
  error: {
    ...state.error,
    [requestType]: null,
  },
  ...payload,
});
