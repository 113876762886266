import * as React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { METHODS } from 'api/client';
import * as Transform from 'api/transform';
import NavigationList from 'components/@client/NavigationList';
import { MenuItem } from 'components/Popper';
import SafeImg from 'components/SafeImg';
import { useFetch } from 'hooks';
import { Message } from 'i18n';
import { ClientRouter } from 'router/routes';
import { DatabaseStatus } from 'types/products';
import { SubCategory } from 'types/common.types';
import NoProduct from 'components/@client/NoProduct/NoProduct';
import CoverSection from 'components/@client/CoverSection/CoverSection';
import { LogsAPI } from 'api/methods';
import { BEST_SELLER_LIST } from 'constants/common.constants';
import ProductsCard from './ProductsCard';

interface Props {
  title: string;
  subCategory: Array<SubCategory> | undefined;
}

const SubCategoryPage: React.FC<Props> = ({ title, subCategory }) => {
  const intl = useIntl();

  const {
    data: categoryProduct,
    callFetch: loadMoreProducts,
    isLoading,
  } = useFetch({
    initialUrl: `/api/products/`,
    skipOnStart: true,
    config: {
      method: METHODS.GET,
      params: {
        category: title,
        size: 12,
        page: 1,
        status: DatabaseStatus['In database'],
      },
    },
    transform: Transform.PaginatedProducts,
    onSuccess: res => {
      LogsAPI.pushDataLayer([
        { ecommerce: null },
        {
          ecommerce: {
            impressions: res?.results.map((r, i) => ({
              name: r.name,
              id: r.id,
              price: r.price,
              brand: r.manufacture.name,
              category: r.category,
              list: BEST_SELLER_LIST,
              position: i + 1,
            })),
          },
        },
      ]);
    },
  });

  const SubCategoryList: MenuItem[] = subCategory
    ? subCategory.map(data => {
        return {
          component: (
            <SubCategoryContainer>
              <span>
                <SafeImg src={data.thumbnail} alt={data.name} />
              </span>
              <p>{(intl.locale === 'he' && data.alt_name) || data.name}</p>
            </SubCategoryContainer>
          ),
          to: ClientRouter.PRODUCT_SUB_CATEGORY.replace(
            ':catName',
            title
          ).replace(
            ':subCatName',
            (intl.locale === 'he' && data.alt_name) || data.name
          ),
        };
      })
    : [];

  return (
    <Root>
      <CoverSection
        imageUrl={subCategory && subCategory[0]?.category?.cover_photo_mobile}
      />
      {SubCategoryList ? (
        <NavigationList items={SubCategoryList} />
      ) : (
        <NoDataContainer>
          <NoProduct value={Message.NO_SUB_CATEGORY} />
        </NoDataContainer>
      )}
      <ProductsCard
        title={`${intl.formatMessage({
          id: Message.SHOP_BEST_SELLERS,
        })} ${title}`}
        products={categoryProduct}
        loadMoreProducts={loadMoreProducts}
        isLoading={isLoading}
      />
    </Root>
  );
};

export default SubCategoryPage;

const Root = styled.div`
  color: ${({ theme }) => theme.colors.black0};
  padding: 0;
`;

export const SubCategoryContainer = styled.span`
  display: flex;
  span {
    width: 40px;
    height: 40px;
    display: flex;
    ${rtl`
    margin-right: 12px;
    `};
  }
  img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    text-indent: -9999px;
  }
  p {
    font-size: 18px;
    font-weight: 500;
    margin: auto;
  }
`;

const NoDataContainer = styled.div`
  margin-top: 2rem;
`;
