import * as React from 'react';
import { ListItem } from 'components/Autocomplete';
import styled from 'styled-components';
import classNames from 'classnames';
import type { Value } from 'components/Autocomplete/Autocomplete.config';

const AssigneeCellOptionRender = () => (
  props: React.HTMLAttributes<HTMLLIElement>,
  newValue: Value
) => {
  return (
    <StyledListItem
      key={newValue?.value}
      tabIndex={0}
      {...props}
      className={classNames(props.className, {
        unassigned: !newValue.value,
      })}
    >
      <>{newValue?.name}</>
    </StyledListItem>
  );
};

export default AssigneeCellOptionRender;

export const StyledListItem = styled(ListItem)`
  &.unassigned {
    opacity: 0.6;
  }
`;
