import * as Image from 'assets/@client/magazine/magazines/index';
import * as Component from '../../magazine/magazines/index';

const magazine = [
  {
    id: 1,
    image: Image.Magazine1,
    name: 'עולם שלם של עיצוב בקליק - רכישת רהיטים אונליין',
    description: 'צוות פטרישיה',
    component: Component.Magazine1,
    date: '13/11/2021',
  },
  {
    id: 2,
    image: Image.Magazine2,
    name: 'אלגנטיות וזרימה - טיפים לעיצוב סלון מושלם',
    description: 'צוות פטרישיה',
    component: Component.Magazine2,
    date: '01/09/2021',
  },
  {
    id: 3,
    image: Image.Magazine3,
    name: 'קונים תמונות אונליין - כך תוכלו לראות את התמונה בבית שלכם',
    description: 'צוות פטרישיה',
    component: Component.Magazine3,
    date: '22/10/2021',
  },
];

export default magazine;
