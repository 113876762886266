import * as React from 'react';
import styled, { css, keyframes } from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import { Setter } from 'types/common.types';
import rtl from 'styled-components-rtl';
import IconButton from '@material-ui/core/IconButton';
import { Message } from 'i18n';
import * as Response from 'api/responses';
import * as Transform from 'api/transform';
import { ReactComponent as UploadIcon } from 'assets/@client/supplier/cloud-upload-btn.svg';
import { ReactComponent as UploadDoneIcon } from 'assets/@client/supplier/cloud-upload-done.svg';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@mui/material';
import { ReactComponent as LoaderProjects } from 'assets/icons/loader-projects.svg';
import { ReactComponent as SuccessTick } from 'assets/icons/success.svg';
import { METHODS } from 'api/client';
import { useFetch, useNotifications } from 'hooks';
import { FileTask, FileStatus } from 'types/suppliers';

interface Props {
  setIsOpenAddCustomerListForm: Setter<boolean>;
}

const AddCustomerListForm: React.FC<Props> = ({
  setIsOpenAddCustomerListForm,
}) => {
  const [file, setFile] = React.useState<File | null>(null);
  const { showNotification } = useNotifications();
  const intl = useIntl();
  const [task, setTask] = React.useState<FileTask | null>();
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const timeOutRef = React.useRef<NodeJS.Timeout | number | null>(null);
  const [isLoadingFlag, setIsLoadingFlag] = React.useState(false);

  const getAsyncTaskSuccess = (response: Response.ResponseFile | null) => {
    if (response?.status === FileStatus.Done) {
      if (timeOutRef.current) {
        clearInterval(timeOutRef.current as NodeJS.Timeout);
        timeOutRef.current = null;
      }
      if (response) {
        setTask(Transform.FileTaskResponse(response));
        setIsLoadingFlag(false);
      }
    } else if (response?.status === FileStatus.Fail) {
      if (timeOutRef.current) {
        clearInterval(timeOutRef.current as number);
        timeOutRef.current = null;
      }
      if (response) {
        showNotification({
          key: 'clients/csvUploadRejected',
          message: intl.formatMessage({ id: Message.UPLOAD_FILE_FAIL }),
          severity: 'error',
        });
        setIsLoadingFlag(false);
      }
    }
  };

  const { callFetch: getAsyncTask } = useFetch({
    initialUrl: `/api/async_tasks/`,
    skipOnStart: true,
    config: {
      method: METHODS.GET,
    },
    onSuccess: getAsyncTaskSuccess,
  });

  const onSuccess = React.useCallback(
    response => {
      getAsyncTask({ url: `/api/async_tasks/${response?.id}/` });
      const timeOut = setInterval(
        () => getAsyncTask({ url: `/api/async_tasks/${response?.id}/` }),
        5000
      );
      timeOutRef.current = timeOut;
      setTimeout(() => clearInterval(timeOut), 120000);
      setTimeout(() => {
        setIsLoadingFlag(false);
      }, 120000);
    },
    [getAsyncTask]
  );

  const onFailure = React.useCallback(
    (message: string) => {
      showNotification({
        key: 'clients/csvUploadRejected',
        message,
        severity: 'error',
      });
      setIsLoadingFlag(false);
    },
    [showNotification]
  );

  const { callFetch: uploadCustomerList } = useFetch<{}>({
    initialUrl: `/api/properties/csv_upload/`,
    skipOnStart: true,
    config: {
      method: METHODS.POST,
    },
    onFailure,
    onSuccess,
  });

  const uploadFile = () => {
    setIsLoadingFlag(true);
    const formData = new FormData();
    formData.append('file', file as Blob);
    uploadCustomerList({
      data: formData,
    });
  };

  const downloadCSV = () => {
    const href = task?.missingDataFile;
    const link = document.createElement('a');
    link.href = href || '';
    link.setAttribute('download', `${task?.fileName}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <MainContainer>
      <CrossIcon onClick={() => setIsOpenAddCustomerListForm(false)}>
        <CloseIcon />
      </CrossIcon>
      <input
        type="file"
        accept=".csv"
        style={{ display: 'none' }}
        data-testid="csvInput"
        ref={fileInputRef}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const fileList = e.target.files;
          if (!fileList) return;
          setFile(fileList[0]);
        }}
      />
      <DetailsContainer>
        <FormTitle>
          <FormattedMessage id={Message.ADD_CUSTOMER_LIST} />
        </FormTitle>
      </DetailsContainer>
      {!isLoadingFlag && !task && (
        <FileParent>
          <Label>CSV file</Label>
          <div className="upload-btn-container">
            <div className="upload-floor-plan">Upload CSV file*</div>
            <UploadButton
              isImageSelected={Boolean(file)}
              className="upload-floor-btn"
              onClick={() => fileInputRef.current?.click()}
            >
              {file ? <UploadDoneIcon /> : <UploadIcon />}
              <FormattedMessage
                id={
                  file
                    ? Message.LANDING_PAGE_CHANGE_FILE_BUTTON
                    : Message.LANDING_PAGE_CHOOSE_FILE_BUTTON
                }
              />
            </UploadButton>
          </div>
        </FileParent>
      )}
      {isLoadingFlag && (
        <>
          <StyledLoader />
          <LoaderText>
            <FormattedMessage id={Message.PROJECT_LOADER_TEXT} />
          </LoaderText>
        </>
      )}
      {!isLoadingFlag && !task && (
        <ButtonContainer disabled={!file} onClick={uploadFile}>
          <FormattedMessage id={Message.FILE_DROP_ZONE_TITLE} />
        </ButtonContainer>
      )}
      {task && (
        <SuccessContainer>
          <SuccessTick />
          <FlexContainer>
            <Title>
              <FormattedMessage id={Message.FILE_UPLOADING_SUMMARY} />
            </Title>
            <SuccessFlex>
              <SuccessText>
                <FormattedMessage id={Message.SUCCESS} />
              </SuccessText>
              <SuccessCount>{task?.succeed ?? 0}</SuccessCount>
            </SuccessFlex>
            <FailedFlex>
              <SuccessText>
                <FormattedMessage id={Message.FAILED} />
              </SuccessText>
              <FailedCount>{task?.failed ?? 0}</FailedCount>
            </FailedFlex>
          </FlexContainer>
          <DownloadCSV onClick={downloadCSV}>
            <FormattedMessage id={Message.DOWNLOAD_CSV} />
          </DownloadCSV>
          <FinishButton onClick={() => setIsOpenAddCustomerListForm(false)}>
            <FormattedMessage id={Message.FINISH} />
          </FinishButton>
        </SuccessContainer>
      )}
    </MainContainer>
  );
};

export default AddCustomerListForm;

const StyledKeyFrames = () => keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`;

const StyledLoader = styled(LoaderProjects)`
  animation: ${() => StyledKeyFrames()} 1.5s linear infinite;
  display: flex;
  margin: 16px auto 0;
`;

const MainContainer = styled.div`
  padding: 24px 32px;
  width: 421px;
  .upload-btn-container {
    display: flex;
    justify-content: center;
    .upload-floor-plan {
      height: 48px;
      width: 188px;
      text-align: center;
      border-radius: 10px 0px 0px 10px;
      background: #fafcfe;
      border: 1px solid #e8eaeb;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #696969;
      padding: 13px 16px;
      ${({ theme }) =>
        theme.dir === 'rtl' &&
        css`
          border-radius: 0px 10px 10px 0px;
        `};
    }
    .upload-floor-btn {
      background: #fafcfe;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      border: 1px solid #fc5c42;
      border-radius: 0px 10px 10px 0px;
      color: #fc5c42;
      width: 169px;
      height: 48px;
      text-transform: none;
      svg {
        ${rtl` margin-right: 8px;`}
      }
      ${({ theme }) =>
        theme.dir === 'rtl' &&
        css`
          border-radius: 10px 0px 0px 10px;
        `};
    }
  }
`;

const Label = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #696969;
  margin-bottom: 8px;
`;

const CrossIcon = styled(IconButton)`
  position: absolute !important;
  ${rtl`
  left: 6px;
  `}
  top: 6px;
  svg {
    font-size: 30.86px;
  }
`;

const DetailsContainer = styled.div``;

const FormTitle = styled.div`
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: #fc5c42;
  text-align: center;
`;

const LoaderText = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #3e3e3e;
  margin-top: 24px;
  text-align: center;
`;

const UploadButton = styled(Button)<{ isImageSelected: boolean }>`
  ${({ isImageSelected }) =>
    isImageSelected &&
    css`
      background: #2cdd73 !important;
      color: white !important;
      border: 1px solid #2cdd73 !important;
    `}
`;

const FileParent = styled.div`
  margin-top: 16px;
`;

const ButtonContainer = styled.button`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  border: 1px solid #d9d9d9;
  border-radius: 15px;
  color: rgba(255, 255, 255, 0.9);
  height: 48px;
  width: 134px;
  background: #fc5c42;
  margin: 24px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :disabled {
    background: #d9d9d9;
    cursor: default;
  }
`;

const SuccessContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #3e3e3e;
  margin-top: 16px;
`;

const SuccessText = styled.div``;
const FlexContainer = styled.div``;

const SuccessFlex = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

const FailedFlex = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

const SuccessCount = styled.div`
  color: #2cdd73;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
`;

const FailedCount = styled.div`
  color: #dd2c2c;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
`;

const DownloadCSV = styled.div`
  color: #5c95ff;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  margin-top: 16px;
  cursor: pointer;
`;

const FinishButton = styled.button`
  width: 145px;
  height: 48px;
  background: #fc5c42;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: rgba(255, 255, 255, 0.9);
  margin-top: 24px;
  border: 1px solid #fc5c42;
  border-radius: 15px;
  cursor: pointer;
`;
