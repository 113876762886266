import styled from 'styled-components';
import { isEmpty } from 'utils/lodash.utils';
import SkeletonList from 'components/@client/SkeletonList';
import { MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';
import { isFunction } from 'utils/common.utils';
import SkeletonItem from './Items/SkeletonItem';

interface Props<T extends object> {
  EmptyState?: React.FC | JSX.Element;
  items?: T[];
  isLoading?: boolean;
  loadingItemCount?: number;
  renderItem: (item: T) => JSX.Element;
}

const CardList = <T extends object>({
  EmptyState = () => <>No items available</>,
  items,
  isLoading = false,
  loadingItemCount = 3,
  renderItem,
}: Props<T>) => {
  return (
    <>
      {items !== undefined && items !== null && isEmpty(items) && !isLoading ? (
        <>{isFunction(EmptyState) ? <EmptyState /> : EmptyState}</>
      ) : (
        <List>
          {isLoading ? (
            <SkeletonList count={loadingItemCount} Item={SkeletonItem} />
          ) : (
            items?.map(renderItem)
          )}
        </List>
      )}
    </>
  );
};

export * from './Items';
export * from './EmptyStates';
export default CardList;

const List = styled.ul.attrs({ className: 'card-list' })`
  margin: auto;
  list-style: none;

  @media ${MOBILE_TABLET_RESOLUTION} {
    font-size: 0.875rem;
    width: 100%;
  }

  & li:last-of-type hr {
    display: none;
  }
`;
