import CancelSharpIcon from '@material-ui/icons/CancelSharp';
import styled from 'styled-components';
import { MOBILE_RESOLUTION } from 'styles/sharedStyle';

const DeleteIcon = styled(CancelSharpIcon).attrs({
  color: 'inherit',
})`
  color: ${({ theme }) => theme.colors.primary.dark};
  font-size: 1.2rem;
  cursor: pointer;
  @media ${MOBILE_RESOLUTION} {
    margin: 0 5px 0 0px;
    height: 18px;
  }
`;

export default DeleteIcon;
