import * as React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import Combobox from 'components/ComboBox';
import FormLabel, { ILabel } from 'components/forms/FormLabel';
import Field from 'components/forms/FormFieldContainer';
import { useCombobox } from 'hooks';
import { Setter } from 'types/common.types';
import type { Option } from 'components/Autocomplete/Autocomplete.config';

interface Props {
  disabled?: boolean;
  disablePortal?: boolean;
  name: string;
  label: ILabel;
  searchPlaceholder?: string;
  placeholder: string;
  options: Option[];
  multiple?: boolean;
  required?: boolean;
  width?: number | string;
  height?: number;
  className?: string;
  setterKey?: string;
  initialValue?: Option;
  selectedValue: Option | Option[];
  selectedPlaceholder?: string;
  optionTextLimit?: number;
  setSelectedValue: Setter<Option> | Setter<Option[]>;
  renderOption: (
    selectedItems?: Option[],
    optionTextLimit?: number,
    multiple?: boolean
  ) => (
    props: React.HTMLAttributes<HTMLLIElement>,
    newValue: Option
  ) => JSX.Element;
  renderValue?: (value: Option) => React.ReactNode | string;
}

const FormComboBox = ({
  disabled,
  disablePortal,
  initialValue,
  label,
  multiple = false,
  name,
  options,
  placeholder,
  required,
  searchPlaceholder,
  setterKey = 'name',
  selectedValue,
  setSelectedValue,
  width,
  height = 48,
  optionTextLimit,
  renderOption,
  renderValue,
  selectedPlaceholder,
  className,
}: Props) => {
  const { control, setValue } = useFormContext();
  const { field } = useController({ name, control, defaultValue: '' });
  const { onBlur, onChange } = field;

  const {
    anchorEl,
    open,
    handleClick,
    handleClose,
    handleApply,
    handleClear,
  } = useCombobox({ setValue: setSelectedValue, value: selectedValue });

  const option = React.useMemo(
    () => renderOption(selectedValue as Option[], optionTextLimit, multiple),
    [renderOption, multiple, optionTextLimit, selectedValue]
  );

  React.useEffect(() => {
    setValue(
      name,
      multiple
        ? selectedValue
        : ((selectedValue as Option)?.[setterKey] as string) ?? '',
      { shouldDirty: true, shouldValidate: true }
    );
  }, [selectedValue, name, setValue, setterKey, multiple]);

  return (
    <Container className={className}>
      <FormLabel label={label} htmlFor={name} required={required} />
      <Field>
        <Combobox
          disablePortal={disablePortal}
          multiple={multiple}
          disabled={disabled}
          height={height}
          width={width}
          anchorEl={anchorEl}
          open={open}
          responsive={false}
          disableSelectAll
          options={options as Option[]}
          value={selectedValue}
          onBlur={onBlur}
          onChange={onChange}
          setValue={setSelectedValue}
          onClick={handleClick}
          handleClose={handleClose}
          placeholder={placeholder}
          searchPlaceholder={searchPlaceholder}
          renderOption={option}
          renderValue={renderValue}
          onApply={handleApply}
          onClear={handleClear}
          selectedPlaceholder={selectedPlaceholder}
        />
      </Field>
    </Container>
  );
};

export default FormComboBox;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;

  label {
    margin-bottom: 3px;
    ${rtl`
      transform-origin: top left;
    `}
  }

  svg {
    color: ${({ theme }) => theme.colors.primary.dark};
  }
`;
