import * as React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import styled, { css } from 'styled-components';
import { TypographyVariant } from '@material-ui/core';

const Title: React.FC<{
  variant?: TypographyVariant;
  fontSize?: string;
  className?: string;
}> = ({ children, variant = 'h5', fontSize, className = 'title' }) => {
  return (
    <Container disableTypography className={`${className} modal-title`}>
      <Bold fontSize={fontSize} variant={variant}>
        {children}
      </Bold>
    </Container>
  );
};

export default Title;

const Container = styled(DialogTitle)`
  margin: 0;
  padding: 0 2rem;
  margin-bottom: 34px;
  & h5 {
    font-family: ${({ theme }) => theme.dir === 'rtl' && 'Assistant'};
  }

  @media (max-width: 768px) {
    margin-bottom: 16px;
    padding: 0;
  }
`;

const Bold = styled(Typography)<{ fontSize?: string }>`
  && {
    color: ${({ theme }) => theme.colors.primary.primaryTextColor};
    font-weight: 800;
    ${({ fontSize }) =>
      fontSize &&
      css`
        font-size: ${fontSize} !important;
      `};
    @media (max-width: 768px) {
      font-size: 20px !important;
    }
  }
`;
