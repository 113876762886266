import * as React from 'react';
import QuantityCounter from 'components/@client/QuantityCounter';
import { DatabaseStatus, LocalCartProduct, Product } from 'types/products';
import styled from 'styled-components';
import Divider from '@material-ui/core/Divider';
import Button from 'components/@client/Button';
import FormattedCurrency from 'components/@client/FormattedCurrency/FormattedCurrency';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { Message } from 'i18n';
import {
  useAuthentication,
  useFetch,
  useLocalStorage,
  useRouter,
  useSession,
} from 'hooks';
import { METHODS } from 'api/client';
import usePropertyCarts from 'pages/appClient/cart/usePropertyCarts';
import { ClientRouter } from 'router/routes';
import {
  MOBILE_RESOLUTION,
  MOBILE_TABLET_RESOLUTION,
  TABLET_RESOLUTION,
} from 'styles/sharedStyle';
import Modal, { ModalAction } from 'components/@client/Modal';
import { ReactComponent as Checkmark } from 'components/@client/form-components/pick.svg';
import SafeImg from 'components/SafeImg';
import rtl from 'styled-components-rtl';
import {
  ShoppingCartOutlined,
  ThreeDRotationOutlined,
} from '@mui/icons-material';
import { addDays } from 'date-fns';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import { CART_KEY, GUEST_TOKEN } from 'constants/localstorage.constants';
import { userLogData } from 'mockData/userLogData';
import { LogsAPI } from 'api/methods';
import { isMobile } from 'react-device-detect';
import { getSizeObject } from 'utils/number.utils';
import { getLocalStorage, saveLocalStorage } from 'utils/common.utils';
import { Type, baseURL } from 'constants/config';

interface Props {
  product: Product;
  onClickAddToCart?: (quantity: number) => void;
  isMatrix?: boolean;
}

const ProductDetails: React.FC<Props> = ({
  product,
  onClickAddToCart,
  isMatrix,
}) => {
  const { history } = useRouter();
  const { token: isAuthenticated, type } = useAuthentication();
  const [quantity, setQuantity] = React.useState(1);
  const [isOpen, setIsOpen] = React.useState(false);
  const standardDeliveryDate = addDays(new Date(), 21);
  const intl = useIntl();
  const { country, locale } = useSession();
  const guestToken = getLocalStorage(GUEST_TOKEN, null);
  const { isLoading: isCartLoading, callFetch: preregisterUser } = useFetch({
    initialUrl: '/api/checkout_cart/guest_user_checkout/',
    skipOnStart: true,
    onSuccess: (data: { token: string } | null) => {
      if (data) saveLocalStorage(GUEST_TOKEN, data.token);
      history.push(ClientRouter.CART);
    },
  });
  const getSize = (data: number) => {
    const centimeter = locale === 'he' ? 'ס"מ' : 'cm';
    const inch = locale === 'he' ? `אינץ'` : 'in';
    return `${Math.ceil(data * 100)} ${centimeter} | ${(
      (data * 100) /
      2.54
    ).toFixed(2)} ${inch}`;
  };

  const size = product != null && getSizeObject(product.size);

  const onQuantityChange = (id: string, quantityNumber: number) => {
    setQuantity(quantityNumber);
  };

  const { fetchCart, setCartCounts } = usePropertyCarts({ skipOnStart: true });

  const onSuccess = React.useCallback(() => {
    fetchCart();
    setIsOpen(prev => !prev);
  }, [fetchCart]);

  const { isLoading, callFetch: processProductCart } = useFetch({
    initialUrl: '/api/shopping_cart_item/',
    skipOnStart: true,
    onSuccess,
  });
  const [cartState, setCartState] = useLocalStorage<LocalCartProduct[]>(
    CART_KEY,
    []
  );

  const onClick = React.useCallback(() => {
    onClickAddToCart?.(quantity);
    if (isAuthenticated || guestToken) {
      processProductCart({
        method: METHODS.POST,
        isGuestAllowed: true,
        data: {
          product: product.id,
          quantity,
        },
      });
    } else {
      const dupArry = [...cartState];
      const elIndex = dupArry.indexOf(
        dupArry.filter(e => e.id === product.id)[0]
      );
      if (elIndex !== -1) {
        dupArry[elIndex].quantity += quantity;
        setCartState([...dupArry]);
        setCartCounts(dupArry.length);
      } else {
        setCartState([...cartState, { id: product.id, quantity }]);
        setCartCounts(dupArry.length + 1);
      }
      onSuccess();
    }
  }, [
    processProductCart,
    product,
    quantity,
    isAuthenticated,
    setCartState,
    cartState,
    onSuccess,
    onClickAddToCart,
    setCartCounts,
    guestToken,
  ]);

  const actions: ModalAction[] = [
    {
      label: <FormattedMessage id={Message.CART_KEEP_SHOPPING_BUTTON} />,
      onClick: () => {
        setIsOpen(false);
        LogsAPI.postUserLogs({
          name: userLogData.eventName.keepShopping,
          area: userLogData.eventArea.catalog,
          section: userLogData.eventSection.addToCartConfirmation,
          path: userLogData.eventPath.appProductSubCategoryNameProductIdPath,
          metadata: { product_id: product.id },
        });
      },
      variant: 'secondary',
    },
    {
      label: <FormattedMessage id={Message.CART_CHECKOUT_BUTTON} />,
      onClick: () => {
        LogsAPI.postUserLogs({
          name: userLogData.eventName.checkout,
          area: userLogData.eventArea.catalog,
          section: userLogData.eventSection.addToCartConfirmation,
          path: userLogData.eventPath.appProductSubCategoryNameProductIdPath,
          metadata: { product_id: product.id },
        });
        if (isAuthenticated || guestToken) {
          history.push(ClientRouter.CART);
        } else {
          preregisterUser({
            method: METHODS.POST,
            data: {
              pre_register: true,
              checkout_data: cartState.map(e => ({
                product: e.id,
                quantity: e.quantity,
                checkout_quantity: e.quantity,
              })),
            },
          });
          // history.push(`${ClientRouter.ADDITIONAL_INFO}?redirect=CART`);
        }
      },
      isLoading: isCartLoading,
      variant: 'primary',
    },
  ];

  const onCloseModal = () => {
    LogsAPI.postUserLogs({
      name: userLogData.eventName.xCloseButton,
      area: userLogData.eventArea.catalog,
      section: userLogData.eventSection.addToCartConfirmation,
      path: userLogData.eventPath.appProductSubCategoryNameProductIdPath,
      metadata: { product_id: product.id },
    });
    setIsOpen(false);
  };
  // eslint-disable-next-line
  let _window: any = window;

  return (
    <Root>
      <Container>
        <ProductName>{product.altName || product.name}</ProductName>
        <SubCategory>
          {(intl.locale === 'he' && product.subCategoryAltName) ||
            product.subCategory}
        </SubCategory>
        <PriceContainer>
          {!isMatrix && type !== Type.SELLER_CLIENT && (
            <ProductPrice>
              <FormattedCurrency value={product.price} />
              {product.msrp > product.price && (
                <>
                  <ProductMSRPPrice>
                    <FormattedCurrency value={product.msrp} />
                  </ProductMSRPPrice>
                  <ProductDiscount>
                    <FormattedMessage
                      id={Message.DISCOUNT_PERCENT}
                      values={{
                        percentage: Math.round(
                          ((product.msrp - product.price) * 100) / product.msrp
                        ),
                      }}
                    />
                  </ProductDiscount>
                </>
              )}
            </ProductPrice>
          )}

          {typeof product.supplier !== 'string' && (
            <SafeImg
              src={product.supplier.logo}
              alt="logo"
              className="supplier-images"
            />
          )}
          <ShippingDetails>
            <p className="free-shipping">
              <LocalShippingOutlinedIcon />
              <span>
                <FormattedMessage id={Message.FREE_SHIPPING} />
              </span>
            </p>
            <p>
              <FormattedMessage id={Message.GET_IT_BY} />
              {': '}
              <FormattedDate value={standardDeliveryDate} dateStyle="long" />
            </p>
          </ShippingDetails>
          <Div />
          <QuantityContainer>
            <span>
              <FormattedMessage id={Message.CHECKOUT_ITEM_QUANTITY_LABEL} />
            </span>
            <QuantityCounter
              itemId={product.id}
              maxQuantity={product.quantity}
              value={quantity}
              onClick={onQuantityChange}
              logData={{
                area: userLogData.eventArea.catalog,
                section: userLogData.eventSection.productPage,
                name: userLogData.eventName.quantityAdd,
                path:
                  userLogData.eventPath.appProductSubCategoryNameProductIdPath,
                metadata: {
                  product_id: product.id,
                  product_name: product.name,
                  product_sku: product.sku,
                },
              }}
            />
          </QuantityContainer>
          <ButtonContainer>
            <Button
              isLoading={isLoading}
              className="buyNow-button"
              onClick={onClick}
            >
              <IconContainer>
                <ShoppingCartOutlined />
              </IconContainer>

              <FormattedMessage id={Message.ADD_TO_CART} />
            </Button>
          </ButtonContainer>

          {product.id &&
            product.status === DatabaseStatus['In database'] &&
            product.type === 'model' && (
              <ButtonContainer>
                <Button
                  className="threeD_button"
                  onClick={() => {
                    if (isMobile || _window.vuplex) {
                      if (_window.vuplex) {
                        _window.vuplex.postMessage({
                          product_Id: product.id,

                          pause: false,

                          baseEp: `${baseURL}/api`,
                        });
                      } else {
                        _window.open(
                          `https://patriciaai.page.link/?link=https://patriciaai.page.link/product-preview?product_Id%3D${product.id}%26pause%3Dfalse%26baseEp%3D${baseURL}/api&apn=com.patriciaai.com.PatriciaTourPlayer&isi=1597629731&ibi=com.patricia-ai.com.PatriciaTourPlayer`,
                          '_blank'
                        );
                      }
                    } else {
                      history.push(`?product3dView=${product.id}`);
                    }
                  }}
                >
                  <IconContainer>
                    <ThreeDRotationOutlined />
                  </IconContainer>
                  <FormattedMessage id={Message.THREED_BUTTON_TEXT} />
                </Button>
              </ButtonContainer>
            )}
        </PriceContainer>
      </Container>

      {/* Started */}

      <ProductInfoSection>
        {product?.description && (
          <ProductDescription>
            <b>
              <FormattedMessage id={Message.DESCRIPTION} />
            </b>
            <p>{product.description}</p>
          </ProductDescription>
        )}
        {size && (
          <ProductDimention>
            <b>
              <FormattedMessage id={Message.DIMENSIONS} />
            </b>
            <DescriptionContainer>
              <Table>
                <TableRow>
                  <TableData>
                    <FormattedMessage
                      id={
                        country === 'IL'
                          ? Message.HEIGHT_CM
                          : Message.HEIGHT_INCH
                      }
                    />
                  </TableData>
                  <TableData>
                    <TableData>{size ? getSize(size.y ?? 0) : 'NA'}</TableData>
                  </TableData>
                </TableRow>
                <TableRow>
                  <TableData>
                    <FormattedMessage
                      id={
                        country === 'IL'
                          ? Message.LENGTH_CM
                          : Message.LENGTH_INCH
                      }
                    />
                  </TableData>
                  <TableData>
                    <TableData>{size ? getSize(size.x ?? 0) : 'NA'}</TableData>
                  </TableData>
                </TableRow>
                <TableRow>
                  <TableData>
                    <FormattedMessage
                      id={
                        country === 'IL' ? Message.DEPTH_CM : Message.DEPTH_INCH
                      }
                    />
                  </TableData>
                  <TableData>
                    <TableData>{size ? getSize(size.z ?? 0) : 'NA'}</TableData>
                  </TableData>
                </TableRow>
              </Table>
            </DescriptionContainer>
          </ProductDimention>
        )}
      </ProductInfoSection>

      <ConfirmModal
        className="confirm-modal"
        onClose={onCloseModal}
        actions={actions}
        open={isOpen}
      >
        <Content>
          <div>
            <SafeImg src={product.thumbnail?.file} alt={product.name} />
          </div>
          <Checkmark className="checkmark" />
          <FormattedMessage id={Message.ADDED_TO_CART} />
        </Content>
      </ConfirmModal>
    </Root>
  );
};

export default ProductDetails;

const Root = styled.div`
  ${rtl`
   padding-left:30px;
  `}
  @media ${MOBILE_TABLET_RESOLUTION} {
    padding: 20px;
  }
`;

const Container = styled.div`
  .supplier-images {
    max-width: 100px;
    max-height: 60px;
    margin: 10px 0;
  }
`;

const ShippingDetails = styled.div`
  p {
    margin: 8px 0;
  }
  .free-shipping {
    display: flex;
    color: green;
    svg {
      color: ${({ theme }) => theme.colors.black0};
    }
    > span {
      ${rtl`
        margin: auto auto auto 4px;
      `}
    }
  }
`;

const SubCategory = styled.div`
  color: ${({ theme }) => theme.colors.black0};
  margin: 6px 0;
`;

const ProductName = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
  color: #333333;
`;

const PriceContainer = styled.div`
  /* width: 50%; */
  max-width: 450px;
`;

const ProductPrice = styled.div`
  font-size: 2.1rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary.lightMobile};
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const ProductMSRPPrice = styled.span`
  font-size: 1.6rem;
  font-weight: 400;
  color: black;
  text-decoration: line-through;
  margin: 0 15px;
`;

const ProductDiscount = styled.span`
  font-size: 1rem;
  font-weight: 400;
  color: #fff;
  padding: 7px 15px;
  background: green;
  border-radius: 20px;
`;

const Div = styled(Divider)`
  margin: 27px 0;
`;

const ButtonContainer = styled.div`
  & .buyNow-button {
    margin: 30px 0 0 0;
    font-size: 1.25rem;
    font-weight: 700;
    width: 100%;
    border-radius: 25px;
    height: 50px;
    @media (max-width: 300px) {
      font-size: 1rem;
    }
  }
  & .threeD_button {
    margin: 30px 0 0 0;
    font-size: 1.25rem;
    font-weight: 700;
    width: 100%;
    border-radius: 25px;
    height: 50px;
    background-color: #000;
    @media (max-width: 300px) {
      font-size: 1rem;
    }
  }
`;

const IconContainer = styled.div`
  margin-right: 5px;
  width: 24px;
  height: 24px;
`;

const QuantityContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > span {
    font-weight: 500;
  }
`;

const Content = styled.p`
  text-align: center;
  img {
    max-width: 80px;
    max-height: 80px;
    margin-bottom: 10px;
  }
  margin-bottom: 20px;
  font-size: 1.25rem;
  color: ${({ theme }) => theme.colors.black0};
  svg {
    path {
      fill: ${({ theme }) => theme.colors.primary.lightMobile};
    }
    ${rtl`
      margin-right: 10px;
    `}
  }
`;
const ProductInfoSection = styled.div`
  margin-top: 30px;

  /* padding-left: 80px; */
  max-width: 600px;
  word-break: break-all;
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ProductDescription = styled.div`
  margin-top: 0;
  & > span {
    font-weight: 500;
  }
  font-weight: 400;
`;

const ProductDimention = styled.div`
  margin-top: 30px;
`;

const ConfirmModal = styled(Modal)`
  & .MuiDialogActions-root {
    @media ${MOBILE_RESOLUTION} {
      display: block;
      text-align: center;
      & > button {
        margin: 10px !important;
      }
    }
    & > button {
      min-width: 150px;
    }
  }
`;

const Table = styled.table`
  width: 100%;
  margin-top: 15px;

  /* @media ${TABLET_RESOLUTION} {
    width: 40%;
  } */
`;

const TableRow = styled.tr`
  td:nth-child(1) {
    width: 15%;
    font-weight: 400;
    @media (max-width: 900px) {
      width: 20%;
    }

    @media (max-width: 800px) {
      width: 15%;
    }

    @media (max-width: 500px) {
      width: 24%;
    }
    @media (max-width: 300px) {
      width: 28%;
    }
  }

  // Fixing width of second
  // column of each row
  td:nth-child(2) {
    width: 85%;
    /* color: ${({ theme }) => theme.colors.gray}; */
    color: #949494;
    font-weight: 400;
  }
`;

const TableData = styled.td`
  padding: 0px;
`;
