import * as React from 'react';
import LoginImage from 'assets/@client/supplier-login-flow/login-main-image.png';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import EastIcon from '@mui/icons-material/East';
import { ClientRouter } from 'router/routes';
import { useAuthentication, useRouter } from 'hooks';
import {
  MOBILE_TABLET_MIN_RESOLUTION,
  MOBILE_TABLET_RESOLUTION,
} from 'styles/sharedStyle';

const SupplierLoginWelcomeMobile: React.FC = () => {
  const { fullName } = useAuthentication();
  const { history } = useRouter();
  return (
    <ParentMobile LoginImage={LoginImage}>
      <TransparentDiv>
        <ParentDivText>
          <WelcomeTitle>
            <FormattedMessage
              id={Message.SUPPLIER_LOGIN_WELCOME_TITLE}
              values={{ name: fullName }}
            />
          </WelcomeTitle>
          <SubTitle>
            <FormattedMessage
              id={Message.SUPPLIER_LOGIN_WELCOME_SUBTITLE_MOBILE}
              values={{ linebreak: <br /> }}
            />
          </SubTitle>
          <LetsDoThisButton
            onClick={() => history.push(ClientRouter.SUPPLIER_INFO)}
          >
            <LetsDoThisText>
              <FormattedMessage
                id={Message.SUPPLIER_LOGIN_WELCOME_LETSDOTHIS_BUTTON}
              />
              <IconDiv>
                <EastIcon />
              </IconDiv>
            </LetsDoThisText>
          </LetsDoThisButton>
        </ParentDivText>
      </TransparentDiv>
    </ParentMobile>
  );
};
export default SupplierLoginWelcomeMobile;

const ParentMobile = styled.div<{ LoginImage: string }>`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto Condensed';
  direction: ltr;
  ::before {
    background-image: url(${LoginImage});
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.6;
    background-position: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    content: '';
  }
`;

const TransparentDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(250, 250, 250, 0.8);
  border-radius: 20px;
  position: relative;
  flex-direction: column;
  padding: 16px;
  width: 90vw;
  .form-container {
    margin-top: 16px;
  }
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    height: 364px;
    width: 639px;
    padding: 0;
  }
`;

const ParentDivText = styled.div`
  margin: auto;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    margin: 64px;
  }
`;

const WelcomeTitle = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #282929;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    font-size: 40px;
    line-height: 44px;
  }
`;

const SubTitle = styled.div`
  font-size: 18px;
  line-height: 22px;
  color: #3e3e3e;
  margin-top: 8px;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    font-size: 24px;
    line-height: 32px;
    margin-top: 24px;
  }
`;

const LetsDoThisText = styled.div`
  display: flex;
  color: #ffffff;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  width: 135px;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    font-size: 20px;
    line-height: 24px;
    width: 145px;
  }
`;

const LetsDoThisButton = styled.button`
  cursor: pointer;
  background: #fc5c42;
  border: 0.5px solid #d9d9d9;
  border-radius: 15px;
  padding: 9px 16px;
  width: 157px;
  height: 40px;
  margin-top: 24px;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    width: 184px;
    height: 48px;
    padding: 11px 24px;
  }
`;

const IconDiv = styled.div`
  margin-left: 4px;
`;
