import * as React from 'react';
import { Link } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import styled, { css } from 'styled-components';
import rtl from 'styled-components-rtl';
import { Order, OrderColors, OrderStatus } from 'types/orders';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { Message } from 'i18n';
import SafeImg from 'components/SafeImg';
import Icon from 'components/Icon';
import { MOBILE_RESOLUTION } from 'styles/sharedStyle';
import { ClientRouter } from 'router/routes';
import OrderHeading from './OrderHeading';

interface Props {
  order: Order;
  index: number;
}

const OrdersListItem: React.FC<Props> = ({ order, index }) => {
  const { status, id, deliveryDate, shippingEst, products, createdAt } = order;
  const getCounter = React.useCallback(
    (items = [], idx: number) =>
      items.length > 3 && idx === 2 ? items.length - 3 : 0,
    []
  );

  const isMobile = useMediaQuery(MOBILE_RESOLUTION);

  return (
    <CardContainer className="order">
      <FlexCardContainer>
        <OrderCard
          to={ClientRouter.ORDER_DETAILS.replace(':orderId', id.toString())}
          className="order-card"
          status={status as OrderStatus}
        >
          <ArrowWrapper>
            <Icon name="Arrow-right-circle" size={isMobile ? 20 : 40} />
          </ArrowWrapper>
          <OrderHeading
            status={status as OrderStatus}
            deliveryDate={deliveryDate}
            shippingEst={shippingEst}
          />
          <Products>
            {products.slice(0, 3).map((product, idx) => (
              <ProductContainer
                key={product.id}
                counter={getCounter(products, idx)}
              >
                <SafeImg
                  src={product?.media?.[0]?.file ?? product.thumbnail?.file}
                  alt={product?.name}
                  className="list-images"
                />
              </ProductContainer>
            ))}
          </Products>
          <div className="description-box">
            <span className="description order-date">
              <FormattedMessage id={Message.ORDERS_CARD_ORDER_NUMBER} />
            </span>
            <span className="description order-date">{id}</span>
          </div>
          <div className="description-box">
            <span className="description order-date">
              <FormattedMessage id={Message.PROPERTY_ORDERS_CARD_ORDER_DATE} />
            </span>
            <span className="description order-date">
              <FormattedDate value={createdAt} dateStyle="long" />
            </span>
          </div>
        </OrderCard>
        {index % 2 === 0 && <Border className="border right" />}
      </FlexCardContainer>
      <Border className="border bottom" />
    </CardContainer>
  );
};

export { OrderHeading };
export default OrdersListItem;

const CardContainer = styled.li`
  width: 50%;
  min-width: 50%;
  &:last-of-type .border {
    display: none;
  }
  @media ${MOBILE_RESOLUTION} {
    width: 100%;
    & .border.right {
      display: none;
    }
  }
`;

const FlexCardContainer = styled.div`
  display: flex;
`;

const OrderCard = styled(Link)<{ status: OrderStatus }>`
  text-decoration: none;
  width: 100%;
  position: relative;
  height: 396px;
  background: #ffffff;
  box-shadow: 0 -3px 25px 5px rgba(0, 88, 171, 0.07);
  border-radius: 16px;
  padding: 28px 24px;
  min-width: 550px;
  @media ${MOBILE_RESOLUTION} {
    height: 287px;
    margin-bottom: 20px;
    padding: 18px 14px;
    min-width: inherit;
  }

  & .order-title {
    font-weight: 500;
    font-size: 20px;
    margin: 0;
    color: ${({ theme }) => theme.colors.primary.clientTextColor};
  }
  & .order-date {
    font-weight: 500;
    font-size: 16px;
    color: #797676;
    margin-bottom: 8px;
  }
  & .status-indicator {
    border-radius: 10px;
    width: 100%;
    height: 8px;
    margin: 20px 0;
    background-color: ${({ status = OrderStatus.received }) =>
      OrderColors[status]};

    @media ${MOBILE_RESOLUTION} {
      margin: 10px 0 20px;
    }
  }
  & .description-box {
    display: flex;
    & .description {
      width: 40%;
      color: ${({ theme }) => theme.colors.primary.clientTextColor};
    }
  }
`;
const Products = styled.div`
  display: flex;
  max-height: 38%;
  width: 100%;
  margin-bottom: 50px;

  @media ${MOBILE_RESOLUTION} {
    margin-bottom: 20px;
  }
`;
const ProductContainer = styled.div<{ counter?: number }>`
  position: relative;
  width: 33%;
  background: #efefef;
  border-radius: 25px;
  object-fit: cover;
  &:not(:last-of-type) {
    ${rtl`
        margin-right: 35px;
    `}
  }
  @media ${MOBILE_RESOLUTION} {
    width: 110px;
    height: 100px;
    &:not(:last-of-type) {
      ${rtl`
          margin-right: 16px;
      `}
    }
  }
  overflow: hidden;

  & img {
    object-fit: cover;

    height: 100%;
    width: 100%;
  }
  ${({ counter = 0 }) =>
    counter > 0 &&
    css`
      &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(63, 63, 63, 0.51);
        color: #ffffff;
        font-weight: 500;
        font-size: 20px;
        border-radius: 25px;
        content: '+${counter}';
        display: flex;
        justify-content: center;
        align-items: center;
      }
    `};
`;
const ArrowWrapper = styled.span`
  position: absolute;
  ${rtl`
      top: 20px;
      right: 20px;
  `}
  transform: rotate(  ${({ theme }) => (theme.dir === 'rtl' ? '180deg' : 0)} );
  transition: opacity 0.125s ease-in;
  &:hover {
    opacity: 0.8;
  }
`;
const Border = styled.div`
  &.right {
    border-right: 1px solid #dfd9d8;
    height: 230px;
    margin: auto 40px;
  }
  &.bottom {
    width: 90%;
    margin: 40px auto;
    border-bottom: 1px solid #dfd9d8;
    @media ${MOBILE_RESOLUTION} {
      margin: 0 auto 20px;
    }
  }
`;
