import { all, fork } from 'redux-saga/effects';
import authenticationSaga from './authentication.saga';
import clientsSaga from './clients.saga';
import teamUsersSaga from './teamPatricia.saga';
import supplierUsersSaga from './suppliers.saga';
import ordersSaga from './orders.saga';
import quizzesSaga from './quizManagement.saga';
import PropertyTasksSaga from './PropertiesManagement.saga';
import propertiesSaga from './properties.saga';
import databaseSaga from './database.saga';
import threeDSaga from './threeD.saga';
import userInfoSaga from './@client/userInfo.saga';
import designStylesSaga from './designStyles.saga';
import sessionSaga from './session.saga';
import rendersSaga from './render.saga';

function* rootSaga() {
  yield all([
    fork(authenticationSaga),
    fork(sessionSaga),
    fork(clientsSaga),
    fork(supplierUsersSaga),
    fork(teamUsersSaga),
    fork(ordersSaga),
    fork(quizzesSaga),
    fork(designStylesSaga),
    fork(propertiesSaga),
    fork(PropertyTasksSaga),
    fork(threeDSaga),
    fork(databaseSaga),
    fork(userInfoSaga),
    fork(rendersSaga),
  ]);
}

export default rootSaga;
