import * as React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import DeleteIcon from 'components/DeleteIcon';
import Icon from 'components/Icon';
import { isEmpty } from 'utils/lodash.utils';
import styledTheme from 'styles/theme';
import type { AutocompleteRenderInputParams } from '@material-ui/core/Autocomplete';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputBase: {
      padding: 0,
      width: '100%',
      background: 'white',
      borderRadius: 10,
      '& input': {
        outline: 'none',
        backgroundColor: 'white',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        fontSize: 16,
      },
      '&:focus': {
        outline: 'none',
        border: `1px solid ${styledTheme.colors.primary.dark}`,
      },
    },
  })
);

const SearchInput: React.FC<
  AutocompleteRenderInputParams & {
    placeholder: string;
    inputValue: string;
    onInputClear: () => void;
    width: string | number;
  }
> = ({
  InputProps,
  InputLabelProps,
  inputProps,
  placeholder,
  inputValue,
  onInputClear,
  width,
}) => {
  const { inputBase: className } = useStyles();
  const textFieldParams: TextFieldProps = React.useMemo(
    () => ({
      variant: 'outlined' as 'outlined',
      inputProps,
      InputLabelProps,
      InputProps: {
        ...InputProps,
        notched: false,
        className: 'combobox-search-input',
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon active={!isEmpty(inputValue)} />
          </InputAdornment>
        ),
        endAdornment: !isEmpty(inputValue) && (
          <InputAdornment position="end">
            <DeleteIcon onClick={onInputClear} />
          </InputAdornment>
        ),
        style: {
          padding: 0,
          margin: 9,
          backgroundColor: '#FFFFFF',
          border: `1px solid ${styledTheme.colors.darkGray}`,
          outline: 'none !important',
          borderRadius: 9,
        },
        labelWidth: 0,
        autoFocus: true,
        autoComplete: 'new-password',
        classes: {
          notchedOutline: 'input',
          focused: 'focus',
          input: 'search-input',
        },
      },
      className,
    }),
    [
      className,
      InputProps,
      inputProps,
      InputLabelProps,
      inputValue,
      onInputClear,
    ]
  );

  return (
    <CustomField
      placeholder={placeholder}
      ref={InputProps.ref}
      width={width}
      {...textFieldParams}
    />
  );
};

export default SearchInput;

const CustomField = styled(TextField)<{ width: string | number }>`
  && .input {
    border-width: 2px;
    border-color: ${({ theme }) => theme.colors.darkGray};
    font-size: 0.875rem;
  }

  && .search-input {
    width: ${({ width }) => width};
    padding: 9.5px 4px;
  }

  && ::placeholder {
    color: #3b3b3b;
  }
  && .Mui-focused {
    border-color: transparent !important;
  }

  && .MuiInputAdornment-positionEnd {
    position: relative;
    right: 7px;
  }
`;

const SearchIcon = styled(Icon).attrs<{ active?: boolean }>(
  ({ theme, active }) => ({
    name: 'search',
    color: active ? theme.colors.primary.dark : theme.colors.gray,
  })
)`
  display: flex;
  justify-self: center;
  position: relative;
  left: 12px;
  margin-right: 10px;
`;
