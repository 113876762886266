import * as React from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';
import { useSession } from 'hooks';
import { SupportedLanguage } from 'types/common.types';
import messages from './messages';

const IntlProvider: React.FC = ({ children }) => {
  const { locale = SupportedLanguage.ENGLISH } = useSession();
  return (
    <ReactIntlProvider
      defaultLocale={SupportedLanguage.ENGLISH}
      locale={locale}
      messages={messages[locale]}
    >
      {children}
    </ReactIntlProvider>
  );
};

export default IntlProvider;
