import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import styled from 'styled-components';
import Button from 'components/Button/PrimaryButton';
import { Link } from 'react-router-dom';
import { ClientRouter } from 'router/routes';
import { useAuthentication } from 'hooks';

interface Props {
  isOpen: boolean;
  toggle: VoidFunction;
  cartApi: VoidFunction;
}

export const CheckoutNotForSaleModal: React.FC<Props> = ({
  isOpen,
  cartApi,
}) => {
  const { token } = useAuthentication();
  return (
    <CustomDialog open={isOpen}>
      <RootDiv>
        <Title>Item Not Available</Title>
        <SubTitle>
          It looks like some items in your cart do not ship to your region.
        </SubTitle>
        <SubTitle>Continue without unavailable products?</SubTitle>
        <StyledButton>
          <Button onClick={cartApi}>Continue</Button>
        </StyledButton>
        <StyledButton>
          <StyledLink to={token ? ClientRouter.PROPERTIES : ClientRouter.APP}>
            Return Home
          </StyledLink>
        </StyledButton>
      </RootDiv>
    </CustomDialog>
  );
};

const CustomDialog = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 0;
    box-shadow: none;
    background: transparent;
  }
  .MuiButtonBase-root {
    margin: 8px auto 16px;
    width: 201px;
    height: 48px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 0.9);
  }
`;

const RootDiv = styled.div`
  width: 537px;
  height: 230px;
  border-radius: 15px;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 24px 48px;
`;

const Title = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  margin: 0 auto 16px;
  align-items: center;
  text-align: center;
  color: #fc5c42;
`;

const SubTitle = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin: 0 auto 8px;
  align-items: center;
  text-align: center;
  color: #282929;
`;

const StyledButton = styled.div`
  margin: 0 auto;
  width: fit-content;
`;

const StyledLink = styled(Link)`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: #696969;
  margin: 0 auto;
`;
