import * as React from 'react';
import styled from 'styled-components';
import Success from 'pages/appClient/checkout/Success';
import Failure from 'pages/appClient/checkout/Failure';

interface Props {
  payment?: string;
}

const ResponseModal: React.FC<Props> = ({ payment }) => {
  return (
    <Container>{payment === 'success' ? <Success /> : <Failure />}</Container>
  );
};

export default ResponseModal;

const Container = styled.div``;
