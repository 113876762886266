import { METHODS } from 'api/client';
import useFetch from 'hooks/useFetch';

export type Category = {
  id: number;
  name: string;
  thumbnail: string;
  list_number: number;
  homepage_thumbnail?: string;
  cover_photo_mobile?: string;
  cover_photo_web?: string;
  hebrew_name: string;
  alt_name?: string;
  icon?: string;
};

const useCategories = () => {
  const { data: Categories, isLoading, hasError } = useFetch<Category[]>({
    initialUrl: '/api/categories/',
    config: {
      useCache: true,
      method: METHODS.GET,
      params: {
        with_products: true,
      },
    },
  });

  return { Categories, isLoading, hasError };
};

export default useCategories;
