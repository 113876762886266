import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import { ClientAPI, LogsAPI } from 'api/methods';
import { LOCALE_KEY } from 'constants/localstorage.constants';
import * as Action from 'store/actions/session.actions';
import { Session } from 'store/constants/session.constants';
import { GetLocationResponsePayload } from 'store/types/session.types';
import {
  SupportedCountry,
  SupportedCurrency,
  SupportedLanguage,
} from 'types/common.types';
import {
  getLocalStorage,
  isSupportedLanguage,
  saveLocalStorage,
} from 'utils/common.utils';

function* selectLanguage({ payload }: Action.SessionAction) {
  yield call(saveLocalStorage, LOCALE_KEY, payload);
}

function* getLocationRequest() {
  try {
    const { language, country }: GetLocationResponsePayload = yield call(
      ClientAPI.getLocation
    );
    const locale = isSupportedLanguage(language)
      ? language
      : SupportedLanguage.ENGLISH;
    const storedLocale: SupportedLanguage = yield call(
      getLocalStorage,
      LOCALE_KEY,
      locale
    );

    yield call(LogsAPI.heapAddUserProperties, { country });

    if (isSupportedLanguage(storedLocale))
      yield call(saveLocalStorage, LOCALE_KEY, storedLocale);
    yield put(
      Action.getLocationFulfilled({
        locale: isSupportedLanguage(storedLocale) ? storedLocale : locale,
        country,
        currency:
          country === SupportedCountry.ISRAEL
            ? SupportedCurrency.ILS
            : SupportedCurrency.USD,
      })
    );
  } catch (error) {
    yield put(Action.getLocationRejected(error.message));
  }
}

function* sessionSaga() {
  yield all([
    takeLatest(Session.SELECT_LANGUAGE, selectLanguage),
    takeLatest(Session.GET_LOCATION_REQUEST, getLocationRequest),
  ]);
}

export default sessionSaga;
