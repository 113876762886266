import * as React from 'react';
import { Field, PhoneField } from 'components/forms';
import { useBreakpoint } from 'hooks';
import { useFormContext } from 'react-hook-form';
import ImageField from 'components/forms/ImageField/ImageField';
import styled from 'styled-components';
import { Row } from './FormFields.config';

const min = { w: '227px', h: 40 };
const max = { w: '278px', h: 40 };
const UserDetails: React.FC<{ editMode?: boolean }> = ({
  children,
  editMode = false,
}) => {
  const { isSmallScreen, width } = useBreakpoint(min, max);
  const preventSpaceTyping = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === ' ') {
        e.preventDefault();
      }
    },
    []
  );

  const preventSpaceOnPaste = React.useCallback((e: React.ClipboardEvent) => {
    if (e.clipboardData.getData('text') === ' ') {
      e.preventDefault();
    }
  }, []);

  const { control } = useFormContext();
  return (
    <>
      <StyledRow>
        <ImageField
          name="profilePicture"
          label="Profile Picture"
          defaultValue={control.defaultValuesRef?.current?.profilePicture}
        />
      </StyledRow>
      <Row className="form-first-row">
        <Field
          width={width}
          className="field-first-name"
          label={{ title: 'First name' }}
          name="firstName"
          type="text"
          required
          onKeyPress={preventSpaceTyping}
          onPaste={preventSpaceOnPaste}
        />
        <Field
          width={isSmallScreen ? width : '316px'}
          className="field-last-name"
          label={{ title: 'Last name' }}
          name="lastName"
          type="text"
          required
          onKeyPress={preventSpaceTyping}
          onPaste={preventSpaceOnPaste}
        />
      </Row>
      <Row className="form-second-row">
        <Field
          width={width}
          className="field-email"
          label={{ title: 'Email address' }}
          name="email"
          type="email"
          required
          disabled={editMode}
          onKeyPress={preventSpaceTyping}
          onPaste={preventSpaceOnPaste}
        />
        <PhoneField
          name="phoneNumber"
          required
          width={isSmallScreen ? (width as string) : '316px'}
        />
        {children}
      </Row>
    </>
  );
};

export default UserDetails;

const StyledRow = styled(Row)`
  & > div > div {
    height: inherit !important;
  }
`;
