import * as React from 'react';
import {
  useFetch,
  useAuthentication,
  useNotifications,
  useRouter,
} from 'hooks';
import type { Property } from 'api/responses';
import type { History } from 'history';
import { Type } from 'constants/config';
import { PROPERTIES_STATUS } from 'types/properties';
import { ClientRouter } from 'router/routes';

interface Props {
  history: History<unknown>;
  propertyId: string;
}

const useGetTourPlayerInfo = ({ history, propertyId }: Props) => {
  const { showNotification } = useNotifications();
  const { type } = useAuthentication();
  const {
    query: { units },
  } = useRouter<{ units?: boolean }>();

  const onFailure = React.useCallback(
    (redirectOnError = true) => {
      showNotification({
        key: 'unity/upload-property',
        message: 'We had an error opening your tour, please try again later.',
        severity: 'error',
      });
      if (redirectOnError) {
        history.goBack();
      }
    },
    [showNotification, history]
  );
  const onSuccess = React.useCallback(
    (property: Property | null) => {
      if (
        type === Type.CLIENT &&
        property?.status !== PROPERTIES_STATUS.Published
      )
        history.replace(ClientRouter.PROPERTY_IN_PROGRESS);
    },
    [history, type]
  );
  const { data: response, isLoading } = useFetch<Property>({
    initialUrl: `api/properties/${propertyId}/`,
    onFailure,
    onSuccess,
    skipOnStart: units,
  });

  return { response, isLoading, onFailure };
};

export default useGetTourPlayerInfo;
