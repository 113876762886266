import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import rtl from 'styled-components-rtl';
import styled from 'styled-components';
import useUploadPlan from 'pages/appClient/onboarding/upload-plan/useUploadPlan';
import Button from 'components/@client/Button/Button';
import { useNotifications } from 'hooks';
import { readFile } from 'utils/common.utils';
import * as Image from 'assets/@client/newpre-made-properties/index';
import Jimp from 'jimp';
import { Modal, useMediaQuery } from '@material-ui/core';
import {
  MOBILE_TABLET_RESOLUTION,
  MOBILE_RESOLUTION,
} from 'styles/sharedStyle';
import { Cancel, Check, CheckCircle } from '@material-ui/icons';

interface Props {
  next: VoidFunction;
}

const SIZE_LIMIT = 1048576 * 10;
const MAX_PAGES_SIZE = 4;
const UploadPlanSeller: React.FC<Props> = ({ next }) => {
  const {
    setImage,
    onClick,
    isLoading,
    setNewPropertyType,
    newPropertyType,
  } = useUploadPlan(next);

  const [isResolutionPopupOpen, setIsResolutionPopupOpen] = React.useState(
    false
  );
  const isMobile = useMediaQuery(MOBILE_TABLET_RESOLUTION);

  const fileInputRef = React.useRef<HTMLInputElement | null>(null);
  const { showNotification } = useNotifications();
  const showError = React.useCallback(
    (
      message = 'The file type is not supported. Please, upload a JPG, JPEG, PNG, or PDF'
    ) => {
      showNotification({
        key: 'fileDropZone/notSupported',
        message,
        severity: 'error',
      });
      return setImage(null);
    },
    [setImage, showNotification]
  );
  const [fileError, setFileError] = React.useState<{
    type?: boolean;
    resolution?: boolean;
    size?: boolean;
    ratio?: boolean;
  }>({});
  const onChangeHandler = React.useCallback(
    async (files: File[] | FileList | null) => {
      setFileError({});
      const file = files?.[0];
      if (!file) return null;
      const conditions = [
        !['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'].includes(
          file?.type ?? ''
        ),
        (file?.size ?? 0) > SIZE_LIMIT,
      ];
      if (conditions.some(v => v)) {
        setFileError({
          size: (file?.size ?? 0) > SIZE_LIMIT,
          type: ![
            'application/pdf',
            'image/png',
            'image/jpeg',
            'image/jpg',
          ].includes(file?.type ?? ''),
        });
      }
      if (file.type.includes('pdf')) {
        const { data: results, error } = await readFile(file);
        const count = results?.match(/\/Type[\s]*\/Page[^s]/g)?.length ?? 10;
        if (Boolean(error) || count > MAX_PAGES_SIZE) return showError();
        setNewPropertyType('upload');
        return setImage(file);
      }
      // const tmppath = URL.createObjectURL(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        Jimp.read(
          Buffer.from(
            (reader.result?.toString() ?? '')
              .replace(/^data:image\/png;base64,/, '')
              .replace(/^data:image\/jpeg;base64,/, ''),
            'base64'
          ),
          (err, imageFile) => {
            if (err?.toString().includes('maxResolutionInM')) {
              const img = document.createElement('img');

              img.onload = () => {
                if (
                  img?.width > 10000 ||
                  img?.height > 10000 ||
                  img.width / img.height > 3 ||
                  img.height / img.width > 3
                ) {
                  setFileError(e => ({
                    ...e,
                    resolution: img?.width > 10000 || img?.height > 10000,
                    ratio: img.height / img.width > 3,
                  }));
                }
              };

              img.src = reader.result?.toString() ?? '';
              setIsResolutionPopupOpen(true);
            } else if (imageFile === undefined) {
              showError();
            } else if (
              imageFile?.bitmap.width > 10000 ||
              imageFile?.bitmap.height > 10000 ||
              imageFile.bitmap.width / imageFile.bitmap.height > 3 ||
              conditions.some(v => v)
            ) {
              setFileError(e => ({
                ...e,
                resolution:
                  imageFile?.bitmap.width > 10000 ||
                  imageFile?.bitmap.height > 10000,
                ratio: imageFile.bitmap.width / imageFile.bitmap.height > 3,
              }));
              setIsResolutionPopupOpen(true);
            } else {
              setNewPropertyType('upload');
              setImage(file);
            }
          }
        );
      };

      return null;
    },
    [setImage, setNewPropertyType, showError]
  );

  return (
    <>
      <Container>
        <Text>
          <FormattedMessage id={Message.PROPERTY_MODAL_TITLE_SELLER} />
        </Text>
        <MainContainer>
          <div>
            <PlansContainer
              isActive={newPropertyType}
              onClick={() => {
                fileInputRef?.current?.click();
              }}
            >
              <div className="checkContainer">
                <Check />
              </div>
              <ImageContainer>
                <img src={Image.upload} alt="not available" />
              </ImageContainer>
              <FileInput
                ref={fileInputRef}
                onChange={e => onChangeHandler(e.target.files)}
              />
              <UploadText>
                <FormattedMessage
                  id={Message.PROPERTY_MODAL_UPLOAD_FLOOR_PLAN}
                />
              </UploadText>
              <UploadTextSubtitle>
                <FormattedMessage
                  id={Message.PROPERTY_MODAL_UPLOAD_FLOOR_PLAN_SUBTITLE}
                  values={{
                    linebreak: <br />,
                  }}
                />
              </UploadTextSubtitle>
            </PlansContainer>
            <UploadNotice isMobile={isMobile}>
              <FormattedMessage
                id={Message.ONBOARDING_UPLOAD_NOTICE}
                values={{ linebreak: <br /> }}
              />
            </UploadNotice>
          </div>
          <div>
            {' '}
            <DoYourselfContainer>
              <DrawImage>
                <ComingSoonDiv>
                  <FormattedMessage id={Message.PROPERTY_MODAL_COMING_SOON} />
                </ComingSoonDiv>
                <img
                  style={{ paddingTop: 32 }}
                  src={Image.Draw}
                  alt="not available"
                />
              </DrawImage>
              <TextContainer>
                <FormattedMessage
                  id={Message.PROPERTY_MODAL_DO_IT_YOURSELF_TITLE}
                />
              </TextContainer>
              <SubtitleContainer>
                <FormattedMessage
                  id={Message.PROPERTY_MODAL_DO_IT_YOURSELF_SUBTITLE}
                  values={{ linebreak: <br /> }}
                />
              </SubtitleContainer>
            </DoYourselfContainer>
          </div>
          <div>
            <DemoFloorPlansContainer
              isActive={newPropertyType}
              onClick={() => {
                setImage(null);
                setNewPropertyType('demoFloorPlans');
              }}
            >
              <div className="checkContainer">
                <Check />
              </div>
              <DemoImage>
                <img
                  style={{ paddingTop: 32 }}
                  src={Image.DemoFloorPlan}
                  alt="not available"
                />
              </DemoImage>
              <TextContainer>
                <FormattedMessage
                  id={Message.PROPERTY_MODAL_DEMO_FLOOR_PLANS_TITLE}
                />
              </TextContainer>
              <SubtitleContainer>
                <FormattedMessage
                  id={Message.PROPERTY_MODAL_DEMO_FLOOR_PLANS_SUBTITLE}
                  values={{ linebreak: <br /> }}
                />
              </SubtitleContainer>
            </DemoFloorPlansContainer>
          </div>
        </MainContainer>
        <Actions>
          <Button
            isLoading={isLoading}
            disabled={!newPropertyType}
            onClick={onClick}
          >
            <FormattedMessage id={Message.PROPERTY_ADD_PROPERTY_BUTTON_NEXT} />
          </Button>
        </Actions>
      </Container>

      <Modal
        open={isResolutionPopupOpen}
        BackdropProps={{
          onClick: () => {
            setImage(null);
            setIsResolutionPopupOpen(false);
          },
        }}
      >
        <ModalContent>
          <div className="title">
            <FormattedMessage
              id={Message.ONBOARDING_PLAN_UPLOAD_RESOLUTION_TITLE}
              values={{
                linebreak: isMobile ? <br /> : ' ',
              }}
            />
          </div>
          <div className="subtitle">
            <FormattedMessage
              id={Message.ONBOARDING_PLAN_UPLOAD_RESOLUTION_SUBTITLE}
            />
          </div>
          <div className="notice">
            <ul>
              <li>
                {fileError.type ? <Cancel /> : <CheckCircle />}
                <span>
                  <FormattedMessage id={Message.UPLOAD_PLAN_ERROR_TYPE} />
                </span>
              </li>
              <li>
                {fileError.size ? <Cancel /> : <CheckCircle />}
                <span>
                  <FormattedMessage id={Message.UPLOAD_PLAN_ERROR_SIZE} />
                </span>
              </li>
              <li>
                {fileError.resolution ? <Cancel /> : <CheckCircle />}
                <span>
                  <FormattedMessage id={Message.UPLOAD_PLAN_ERROR_RESOLUTION} />
                </span>
              </li>
              <li>
                {fileError.ratio ? <Cancel /> : <CheckCircle />}
                <span>
                  <FormattedMessage id={Message.UPLOAD_PLAN_ERROR_RATIO} />
                </span>
              </li>
            </ul>
          </div>
          <Button
            className="ok-btn"
            onClick={() => {
              setImage(null);
              setIsResolutionPopupOpen(false);
            }}
          >
            <FormattedMessage
              id={Message.ONBOARDING_PLAN_UPLOAD_RESOLUTION_OK_BUTTON}
            />
          </Button>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UploadPlanSeller;

const Container = styled.section.attrs({ className: 'upload-plan-container' })`
  display: flex;
  flex-direction: column;
  align-items: center;

  & .file-drop-zone {
    padding: 15px;
  }
  /* height: 700px; */
  /* @media ${MOBILE_RESOLUTION} {
    height: 700px;
  } */
`;

const Text = styled.p.attrs({ className: 'upload-plan-text' })`
  font-weight: 600;
  font-size: 28px;
  margin-bottom: 0px;
  text-align: center;
  line-height: 32px;
  margin-top: 24px;
  color: #fc5c42;
  @media (max-width: 800px) {
    font-weight: 500;
    font-size: 22px;
    margin-top: 32px;
  }
`;

const Actions = styled.section.attrs({ className: 'upload-plan-actions' })`
  margin: auto;
  margin-top: 16px;
  @media (max-width: 800px) {
    margin-bottom: 14px;
  }
  align-self: flex-end;
  .MuiButton-label {
    font-weight: 500;
    font-size: 18px;
  }
  .MuiButtonBase-root {
    width: 134px;
  }
`;

const PlansContainer = styled.div<{ isActive: string }>`
  &:hover {
    border: 2px solid #fc5c42;
  }
  cursor: pointer;
  box-shadow: 0px 0px 10px rgb(40 41 41 / 15%);
  border: ${({ isActive }) =>
    isActive === 'upload' ? '3px solid #fc5c42' : '3px solid transparent'};
  width: 264px;
  border-radius: 15px;
  text-align: center;
  position: relative;
  margin-top: 24px;
  padding-top: 13px;
  @media (max-width: 800px) {
    width: 264px;
    padding-top: 6px;
    margin-top: 68px;
  }
  @media ${MOBILE_RESOLUTION} {
    width: 242px;
    margin-top: 30px;
  }
  .text-container {
    width: 48px;
    margin: 0 32px;
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    > span {
      :first-letter {
        color: #fc5c42 !important;
      }
    }
  }

  & > span {
    :first-letter {
      color: #fc5c42 !important;
    }
  }
  .checkContainer {
    height: 24px;
    width: 24px;
    position: absolute;
    top: 16px;
    ${rtl`right: 16px;`}
    border-radius: 12px;
    background-color: #fc5c42;
    display: ${({ isActive }) => (isActive === 'upload' ? 'block' : 'none')};
    svg {
      width: 15px;
      height: 23px;
      path {
        stroke: white;
        fill: white;
      }
    }
  }
`;

const FileInput = styled.input.attrs({
  type: 'file',
  name: 'file',
  accept: 'application/pdf, image/png, image/jpeg, image/jpg',
})`
  position: absolute;
  visibility: hidden;
`;

const ImageContainer = styled.div`
  margin-top: 16px;
  width: 200px;
  height: 200px;
  margin: auto;
  @media (max-width: 800px) {
    /* width: 311px;
    height: 285px; */
  }
  > img {
    width: 100%;
    height: 95%;
  }
`;

const ModalContent = styled.div`
  width: 537px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding-top: 24px;
  text-align: center;
  border: none;
  outline: none;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  padding-bottom: 24px;
  @media (max-width: 767px) {
    width: 327px;
    height: 224px;
  }
  .title {
    font-weight: 500;
    font-size: 22px;
    text-align: center;

    /* Primary Color */

    color: #fc5c42;
    @media (max-width: 767px) {
      font-weight: 500;
      font-size: 20px;
    }
  }
  .ok-btn {
    background: #fc5c42;
    /* Primary Color */
    margin-top: 16px;
    border: 1px solid #fc5c42;
    border-radius: 15px;
    width: 127px;
    height: 48px;
    font-weight: 500;
    font-size: 18px;
    @media (max-width: 767px) {
      height: 40px;
      font-weight: 500;
      font-size: 18px;
    }
  }
  .subtitle {
    font-weight: 400;
    font-size: 16px;
    text-align: center;

    /* First level text */

    color: #282929;
    margin-top: 16px;
    @media (max-width: 767px) {
      font-weight: 500;
      font-size: 16px;
    }
  }
  .notice {
    font-weight: 400;
    font-size: 14px;
    color: #696969;
    margin-top: 8px;
    text-align: center;
    ul {
      width: fit-content;
      margin: auto;
      li {
        position: relative;
        width: fit-content;
        list-style: none;
        svg {
          margin-bottom: -6px;
          fill: #fc5c42;
        }
        span {
          line-height: 24px;
          vertical-align: middle;
        }
      }
    }
    @media (max-width: 767px) {
      font-weight: 400;
      font-size: 14px;
    }
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
  @media (max-width: 900px) {
    overflow: auto;
    width: 100%;
    padding: 0px 10px;
  }
`;

const UploadText = styled.div`
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  margin-top: 10px;
  color: #fc5c42;
`;

const UploadTextSubtitle = styled.div`
  font-size: 18px;
  line-height: 22px;
  color: #3e3e3e;
  margin-top: 8px;
`;

const DoYourselfContainer = styled.div`
  box-shadow: 0px 0px 10px rgb(40 41 41 / 15%);
  width: 264px;
  border-radius: 15px;
  text-align: center;
  position: relative;
  margin-top: 24px;
  overflow: hidden;
  @media (max-width: 800px) {
    width: 264px;
    padding-top: 6px;
    margin-top: 68px;
  }
  @media ${MOBILE_RESOLUTION} {
    width: 242px;
    margin-top: 30px;
  }
  .text-container {
    width: 48px;
    margin: 0 32px;
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    > span {
      :first-letter {
        color: #fc5c42 !important;
      }
    }
  }
`;

const DrawImage = styled.div`
  margin-top: 16px;
  width: 200px;
  height: 200px;
  margin: auto;
  @media (max-width: 800px) {
    /* width: 311px;
    height: 285px; */
  }
  > img {
    width: 100%;
    height: 100%;
  }
`;

const DemoFloorPlansContainer = styled.div<{ isActive: string }>`
  cursor: pointer;
  box-shadow: 0px 0px 10px rgb(40 41 41 / 15%);
  width: 264px;
  border-radius: 15px;
  text-align: center;
  position: relative;
  margin-top: 24px;
  overflow: hidden;
  border: ${({ isActive }) =>
    isActive === 'demoFloorPlans'
      ? '3px solid #fc5c42'
      : '3px solid transparent'};
  &:hover {
    border: 2px solid #fc5c42;
  }
  .checkContainer {
    height: 24px;
    width: 24px;
    position: absolute;
    top: 16px;
    ${rtl`right: 16px;`}
    border-radius: 12px;
    background-color: #fc5c42;
    display: ${({ isActive }) =>
      isActive === 'demoFloorPlans' ? 'block' : 'none'};
    svg {
      width: 15px;
      height: 23px;
      path {
        stroke: white;
        fill: white;
      }
    }
  }
  @media (max-width: 800px) {
    width: 264px;
    padding-top: 6px;
    margin-top: 68px;
  }
  @media ${MOBILE_RESOLUTION} {
    width: 242px;
    margin-top: 30px;
  }
  .text-container {
    width: 48px;
    margin: 0 32px;
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    > span {
      :first-letter {
        color: #fc5c42 !important;
      }
    }
  }
`;

const TextContainer = styled.div`
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  margin-top: 24px;
  color: #282929;
`;

const SubtitleContainer = styled.div`
  font-size: 18px;
  line-height: 22px;
  margin-top: 8px;
  color: #282929;
`;

const DemoImage = styled.div`
  margin-top: 16px;
  width: 200px;
  height: 200px;
  margin: auto;
  @media (max-width: 800px) {
    /* width: 311px;
    height: 285px; */
  }
  > img {
    width: 100%;
    height: 100%;
  }
`;

const ComingSoonDiv = styled.div`
  height: 34px;
  width: 200px;
  background: #11516d;
  transform: rotate(-45deg);
  color: rgba(255, 255, 255, 0.9);
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 45%;
  top: 8%;
`;

const UploadNotice = styled.div<{ isMobile: boolean }>`
  margin-top: ${({ isMobile }) => (isMobile ? 16 : 8)}px;
  width: 264px;
  font-size: 14px;
  text-align: center;
  font-weight: 400;
  @media ${MOBILE_TABLET_RESOLUTION} {
    margin-bottom: 20px;
  }
`;
