import * as React from 'react';
import { Link } from 'react-router-dom';
import Button from 'components/@client/Button';
import CardList, { CartItem } from 'components/@client/CardList';
import Loader from 'components/Loader';
import CreditCardProviders from 'components/@client/CreditCardProviders';
import Checkbox from 'components/@client/form-components/Checkbox';
import AddressSection from 'pages/appClient/profile/AddressSection';
import { size } from 'utils/lodash.utils';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import { ClientRouter } from 'router/routes';
import { getRoute } from 'utils/sidebar.utils';
import { stringify } from 'utils/string.utils';
import { userLogData } from 'mockData/userLogData';
import { LogsAPI } from 'api/methods';
import { CheckoutProps } from './Checkout.config';
import PriceField from './PriceField';

interface Props extends CheckoutProps {
  isModal?: boolean;
}

const WebView: React.FC<Props> = ({
  address,
  backTo,
  cart,
  canProceed,
  propertyCart,
  priceFields,
  isLoadingCart,
  shippingOptions,
  userId,
  openModal,
  onAddressesChange,
  isModal,
}) => {
  return (
    <>
      <section className="shipping-section">
        {/* {cart?.property?.name && <h4>{cart.property.name}</h4>} */}
        <AddressSection
          userId={userId}
          backTo={backTo}
          onAddressesChange={onAddressesChange}
          isModal={isModal}
          logData={{
            name: userLogData.eventName.addNewAddress,
            area: userLogData.eventArea.myCart,
            section: userLogData.eventSection.cart,
            path: userLogData.eventPath.appCartCartCheckOutId,
            metadata: { cart_id: cart?.id },
          }}
        />
        <FormattedMessage
          id={Message.CHECKOUT_SHIPPING_OPTIONS_TITLE}
          tagName="h4"
        />
        {shippingOptions.map(props => (
          <Checkbox key={props.value as string} {...props} />
        ))}
      </section>
      <section className="cart-section">
        {!isModal && (
          <div className="cart-heading">
            <span className="cart-counter">
              <FormattedMessage
                id={Message.CART_TOTAL_ITEMS_LABEL}
                values={{ value: size(cart?.items) }}
              />
            </span>
            <Link
              className="cart-edit"
              to={propertyCart}
              onClick={() => {
                LogsAPI.postUserLogs({
                  name: userLogData.eventName.editButton,
                  area: userLogData.eventArea.myCart,
                  section: userLogData.eventSection.cart,
                  path: userLogData.eventPath.appCartCartCheckOutId,
                  metadata: { cart_id: cart?.id },
                });
              }}
            >
              <FormattedMessage id={Message.EDIT_BUTTON} />
            </Link>
          </div>
        )}

        <section className="actions">
          {!isModal && (
            <>
              <CardList
                isLoading={isLoadingCart}
                EmptyState={<Loader />}
                items={cart?.items ?? []}
                renderItem={item => (
                  <CartItem
                    key={item.id}
                    {...item}
                    quantity={item.checkoutQuantity}
                  />
                )}
              />
              <hr />
            </>
          )}

          {priceFields.map(props => (
            <PriceField
              key={props.label?.toString()}
              fractionDigits={2}
              {...props}
            />
          ))}
          <hr />
          <CreditCardProviders />
          <Button
            disabled={!canProceed}
            className="checkout-button"
            onClick={openModal}
          >
            <FormattedMessage id={Message.CHECKOUT_PROCEED_BUTTON} />
          </Button>
          {!address && (
            <p className="missing-address-caption">
              <FormattedMessage
                id={Message.CHECKOUT_MISSING_ADDRESS_CAPTION}
                values={{
                  action: (
                    <Link
                      to={getRoute([
                        ClientRouter.PROFILE_ADDRESSES,
                        stringify({ backTo }),
                      ])}
                    >
                      <FormattedMessage
                        id={Message.CHECKOUT_MISSING_ADDRESS_ACTION}
                      />
                    </Link>
                  ),
                }}
              />
            </p>
          )}
        </section>
      </section>
    </>
  );
};

export default WebView;
