import * as React from 'react';
import styled from 'styled-components';
import { MOBILE_RESOLUTION } from 'styles/sharedStyle';

interface Props {
  img: string;
  alt: string;
  title: string | React.ReactNode;
  subTitle: string | number | null | undefined;
}

const HeaderBox: React.FC<Props> = ({ img, alt, title, subTitle }) => {
  return (
    <>
      <SubHeaderParent>
        <img src={img} alt={alt} />
        <Title>{title}</Title>
        <SubTitle>{subTitle ?? ''}</SubTitle>
      </SubHeaderParent>
    </>
  );
};

export default HeaderBox;

const SubHeaderParent = styled.div`
  text-align: center;
`;

const Title = styled.div`
  font-size: 18px;
  line-height: 22px;
  color: #3e3e3e;
  margin-top: 8px;
  @media ${MOBILE_RESOLUTION} {
    font-size: 14px;
    line-height: 18px;
  }
`;

const SubTitle = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #282929;
  margin-top: 8px;
  @media ${MOBILE_RESOLUTION} {
    font-size: 16px;
    line-height: 20px;
    margin-top: 4px;
  }
`;
