import * as React from 'react';
import styled from 'styled-components';
import { Fade, List, ClickAwayListener, Paper } from '@material-ui/core';
import SearchInput from 'components/@client/SearchInput/SearchInput';
import Popper from '@material-ui/core/Popper';
import { SearchResults } from 'types/products';
import { MenuItem } from 'components/Popper/Popper';
import SearchListItem from 'components/SearchListItem/SearchListItem';
import { ClientRouter } from 'router/routes';
import { useIntl, FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import { useFetch, useRouter } from 'hooks';
import { METHODS } from 'api/client';
import { LogsAPI } from 'api/methods';
import { SearchProducts } from 'api/transform';
import { removeEmptyValues } from 'utils/common.utils';
import Loader from 'components/Loader/Loader';
import rtl from 'styled-components-rtl';
import { Link } from 'react-router-dom';
import { MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';
import { userLogData } from 'mockData/userLogData';

interface Props {
  setSearchBarHeight?: (h: string) => void;
}

const SearchBarComponent: React.FC<Props> = ({ setSearchBarHeight }) => {
  const intl = useIntl();
  const { history } = useRouter();
  const [searchQuery, setSearchQuery] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [searchItems, setSearchItems] = React.useState<MenuItem[]>([]);
  const open = Boolean(anchorEl);
  const closeMenu = React.useCallback(() => {
    if (setSearchBarHeight) {
      setSearchBarHeight('40px');
    }
    setAnchorEl(null);
  }, [setSearchBarHeight]);
  const openMenu = React.useCallback(
    event => {
      if (setSearchBarHeight) {
        setSearchBarHeight('100%');
      }
      setAnchorEl(event.currentTarget);
    },
    [setSearchBarHeight]
  );

  const onSearchSuccess = React.useCallback(
    (response: SearchResults | null) => {
      if (response?.query)
        setSearchItems(prev => [
          ...prev,
          {
            component: (
              <SearchListItem
                title={response.query.text}
                searchTerm={searchQuery}
                count={response.query.count}
              />
            ),
            to: ClientRouter.SEARCH_PAGE.replace(':searchName', searchQuery),
          },
        ]);
      if (response?.suppliers?.count)
        setSearchItems(prev => [
          ...prev,
          {
            component: (
              <ListingType>
                <FormattedMessage id={Message.BRANDS} />
              </ListingType>
            ),
            divider: true,
          },
        ]);
      response?.suppliers?.results.splice(0, 3).forEach(e => {
        setSearchItems(prev => [
          ...prev,
          {
            component: (
              <SearchListItem
                title={e.companyName}
                searchTerm={searchQuery}
                count={e.productsQuantity}
              />
            ),
            to: `${ClientRouter.SEARCH_PAGE.replace(
              ':searchName',
              searchQuery
            )}?supplierId=${e.id}`,
          },
        ]);
      });
      if (response?.categories?.count)
        setSearchItems(prev => [
          ...prev,
          {
            component: (
              <ListingType>
                <FormattedMessage id={Message.CATEGORIES} />
              </ListingType>
            ),
            divider: true,
          },
        ]);
      response?.categories?.results.splice(0, 3).forEach(e => {
        setSearchItems(prev => [
          ...prev,
          {
            component: (
              <SearchListItem
                title={e.name}
                searchTerm={searchQuery}
                count={e.product_count}
              />
            ),
            to: ClientRouter.PRODUCT_CATEGORY.replace(':catName', e.name),
          },
        ]);
      });
      if (response?.subCategories?.count)
        setSearchItems(prev => [
          ...prev,
          {
            component: (
              <ListingType>
                <FormattedMessage id={Message.SUB_CATEGORIES} />
              </ListingType>
            ),
            divider: true,
          },
        ]);
      response?.subCategories?.results.forEach(e => {
        setSearchItems(prev => [
          ...prev,
          {
            component: (
              <SearchListItem
                title={e.name}
                searchTerm={searchQuery}
                count={e.product_count}
              />
            ),
            to:
              e.category &&
              ClientRouter.PRODUCT_SUB_CATEGORY.replace(
                ':catName',
                e.category.name
              ).replace(':subCatName', e.name),
          },
        ]);
      });
      if (response?.products?.count)
        setSearchItems(prev => [
          ...prev,
          {
            component: (
              <ListingType>
                <FormattedMessage id={Message.PRODUCTS} />
              </ListingType>
            ),
            divider: true,
          },
        ]);
      response?.products?.results.splice(0, 3).forEach(e => {
        setSearchItems(prev => [
          ...prev,
          {
            component: (
              <>
                <SearchListItem
                  title={intl.locale === 'en' ? e.name : e.altName ?? e.name}
                  searchTerm={searchQuery}
                />
              </>
            ),
            to: ClientRouter.PRODUCT_PAGE.replace(':catName', e.category)
              .replace(':subCatName', e.subCategory)
              .replace(':productId', e.id),
          },
        ]);
      });
    },
    [searchQuery, intl.locale]
  );
  const { callFetch: callSearch, isLoading: isSearchLoading } = useFetch({
    initialUrl: 'api/search/',
    skipOnStart: true,
    config: {
      method: METHODS.GET,
    },
    onSuccess: onSearchSuccess,
    transform: SearchProducts,
  });

  const handleSearchTextChange = React.useCallback(
    e => {
      openMenu(e);
      setSearchQuery(e.target.value);
    },
    [setSearchQuery, openMenu]
  );

  React.useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (searchQuery.length > 2) {
        callSearch({
          params: { query: searchQuery, page: 1, size: 5 },
        });
        setSearchItems([]);
      } else {
        closeMenu();
      }
    }, 700);
    return () => clearTimeout(timeOutId);
  }, [searchQuery, callSearch, closeMenu]);

  const onSearchValue = React.useCallback(() => {
    LogsAPI.postUserLogs({
      name: userLogData.eventName.searchResultPage,
      area: userLogData.eventArea.homePage,
      section: userLogData.eventSection.catalog,
      path: userLogData.eventPath.appSearchPath,
      metadata: { search_key: searchQuery },
    });
    history.push(ClientRouter.SEARCH_PAGE.replace(':searchName', searchQuery));
    if (setSearchBarHeight) {
      setSearchBarHeight('40px');
    }
    setAnchorEl(null);
  }, [searchQuery, history, setSearchBarHeight]);

  return (
    <>
      <SearchOverlay isOpen={!!anchorEl} />
      <Search>
        <ClickAwayListener onClickAway={closeMenu}>
          <SearchContainer
            onClick={() =>
              LogsAPI.postUserLogs({
                name: userLogData.eventName.search,
                area: userLogData.eventArea.homePage,
                section: userLogData.eventSection.header,
                path: userLogData.eventPath.appPath,
                metadata: { search_key: searchQuery },
              })
            }
          >
            <CustomSearchInput
              isOpen={(!!searchItems?.length || isSearchLoading) && open}
              onFocus={e => openMenu(e)}
              onChange={handleSearchTextChange}
              placeholder={intl.formatMessage({
                id: Message.SEARCH_PLACEHOLDER,
              })}
              onIconClick={() => {
                if (searchQuery !== '') {
                  onSearchValue();
                }
              }}
              onKeyPress={e => {
                if (e.key === 'Enter' && searchQuery !== '') {
                  onSearchValue();
                }
              }}
            />
          </SearchContainer>
        </ClickAwayListener>
        <DropdownContainer>
          <DropDown
            open={(!!searchItems?.length || isSearchLoading) && open}
            className="menu-popper"
            anchorEl={anchorEl}
            placement="bottom-end"
            top={-10}
            disablePortal
            transition
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={150}>
                <List
                  className="list-paper"
                  component={ListContainer}
                  onClick={closeMenu}
                >
                  {searchItems && !isSearchLoading
                    ? searchItems.map(
                        (
                          { onClick, component, disabled, divider, to },
                          idx
                        ) => {
                          const Element = to ? CustomLink : React.Fragment;
                          const props = removeEmptyValues({
                            to,
                          }) as { to: string };
                          return (
                            <Element key={idx.toString()} {...props}>
                              {component}
                              {divider && <Divider />}
                            </Element>
                          );
                        }
                      )
                    : isSearchLoading && <Loader />}
                </List>
              </Fade>
            )}
          </DropDown>
        </DropdownContainer>
      </Search>
    </>
  );
};

export default SearchBarComponent;

const SearchOverlay = styled.div<{ isOpen: boolean }>`
  background-color: rgba(50, 50, 50, 0.65);
  position: fixed;
  top: -40px;
  left: 0;
  right: 0;
  height: calc(100vh + 40px);
  display: block;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  pointer-events: none;
  transition: opacity 0.3s ease-in;
  z-index: 99;
`;

const Search = styled.div`
  width: 338px;
  @media ${MOBILE_TABLET_RESOLUTION} {
    width: 250px;
    height: 40px;
  }
  margin: auto;
  -webkit-transition: width 0.4s ease-in-out;
  transition: width 0.4s ease-in-out;

  &:focus-within {
    width: 70%;
    @media ${MOBILE_TABLET_RESOLUTION} {
      width: 85%;
    }
  }
  z-index: 9999;
  position: relative;
`;

const SearchContainer = styled.div`
  width: 100%;
  & > div {
    width: 100%;
  }
`;

const CustomSearchInput = styled(SearchInput)<{ isOpen: boolean }>`
  .MuiInputBase-root {
    background-color: rgba(50, 50, 50, 0.65) !important;

    transition: border-radius 0.5s ease-in-out;
    @media ${MOBILE_TABLET_RESOLUTION} {
      ${({ theme }) => theme.shadow.l}
      position:relative;
    }
    border-radius: ${({ isOpen }) =>
      isOpen ? '20px 20px 0 0 !important' : '40px'};

    & > input {
      color: white !important;
      width: 100%;
      ::placeholder {
        color: white !important;
        opacity: 1;
      }

      :-ms-input-placeholder {
        color: white !important;
        opacity: 1;
      }

      ::-ms-input-placeholder {
        color: white !important;
        opacity: 1;
      }
    }
  }
`;

const DropdownContainer = styled.div`
  position: relative;
  > div {
    transform: translate(0px, 10px) !important;
    inset: none !important;
    width: 100%;
    min-height: 50px;
  }
`;

const DropDown = styled(Popper)<{ top?: number }>`
  && {
    z-index: 2000;
    top: ${({ top = 16 }) => top}px !important;
    @media ${MOBILE_TABLET_RESOLUTION} {
      position: relative !important;
    }
    .MuiPaper-root {
      max-height: 70vh;
      overflow: auto;
      background-color: black;
      color: white;
    }
    .MuiPaper-rounded {
      border-radius: 0 0 20px 20px;
    }
  }
`;

const ListingType = styled.div`
  ${rtl`
    margin-left: 10px;
  `}
  color:white;
  font-family: ${({ theme }) => (theme.dir === 'rtl' ? 'Assistant' : 'Roboto')};
  padding: 10px 0;
`;

const ListContainer = styled(Paper)``;
const CustomLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const Divider = styled.hr`
  background: #dee0e3;
  border: none;
  height: 1px;
  margin: 0 10px 10px;
`;
