import * as React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import { ReactComponent as SendIcon } from 'assets/@client/orders/Send.svg';
import Breadcrumbs from 'components/@client/Breadcrumbs';
import InfoBox from 'components/@client/InfoBox/InfoBox';
import Title from 'components/@client/TopTitle';
import OrderList from 'components/@client/Orders';
import { ClientRouter } from 'router/routes';
import { ClientRoot, MIN_TABLET_RESOLUTION } from 'styles/sharedStyle';

const links = [
  { to: '', children: <FormattedMessage id={Message.ORDERS_TITLE} /> },
];

const Orders = () => {
  return (
    <Root>
      <Breadcrumbs links={links} />
      <Title
        title={<FormattedMessage id={Message.ORDERS_TITLE} />}
        backTo={ClientRouter.MY_ACCOUNT}
      />
      <Content>
        <MyOrders className="my-orders">
          <OrderList showTitle={false} />
        </MyOrders>
        <InfoBox
          icon={<SendIcon />}
          title={<FormattedMessage id={Message.ORDERS_INFO_BOX_TITLE} />}
          text={
            <FormattedMessage
              id={Message.ORDERS_INFO_BOX_TEXT}
              values={{ linebreak: <br /> }}
            />
          }
        />
      </Content>
    </Root>
  );
};

export default Orders;

const Root = styled(ClientRoot)`
  padding: 20px 25px;

  .info-box {
    right: 0;
    display: none;
  }

  @media ${MIN_TABLET_RESOLUTION} {
    .info-box {
      display: block;
      width: 30%;
    }
  }
`;
const Content = styled.div`
  display: flex;
  justify-content: space-between;
`;
const MyOrders = styled.div`
  max-width: 600px;
  width: 100%;
  flex: 1;
  & .property-orders {
    margin: 0;
  }
  & .border.right {
    display: none;
  }
  & .order-card {
    min-width: unset;
    max-width: 100%;
  }
  & .order {
    width: 100%;
  }
`;
