import styled from 'styled-components';
import rtl from 'styled-components-rtl';

export const CheckoutContainer = styled.section`
  & .shipping-section h4 {
    margin-bottom: 5px;

    @media (min-width: 1150px) {
      font-size: 1.5rem;
    }
  }
  &:not(.skin-cart-item) {
    & .card {
      @media (min-width: 1150px) {
        transform: rotateY(
          ${({ theme }) => (theme.dir === 'ltr' ? 0 : -180)}deg
        );
      }
    }
  }

  & .card-list {
    @media (min-width: 1150px) {
      box-shadow: 0px -3px 25px 5px rgb(0 88 171 / 7%);
      transform: rotateY(${({ theme }) => (theme.dir === 'ltr' ? 0 : 180)}deg);
      max-height: 50vh;
      overflow-y: overlay;
      ${({ theme }) => theme.utils.scrollbar};
    }
  }
  .checkbox {
    div {
      align-self: flex-end;
      strong {
        ${rtl`
          margin-right: 1ch;
        `}
        font-size: 1rem;
        @media (min-width: 1150px) {
          font-size: 1.375rem;
        }
      }
      span {
        color: rgba(102, 102, 102, 1);
        font-weight: 700;
        font-size: 1rem;
        @media (min-width: 1150px) {
          font-size: 1.375rem;
        }
      }
      p {
        margin: 0;
        padding: 0;
        color: rgba(102, 102, 102, 1);
        font-weight: 700;
        font-size: 0.875rem;
        max-width: 25ch;
        @media (min-width: 1150px) {
          font-size: 1.125rem;
        }
      }
    }
  }
  @media (min-width: 1150px) {
    display: flex;
    justify-content: space-between;

    hr {
      border-top: none;
      border-bottom: 1px solid rgba(169, 153, 151, 1);
      max-width: 500px;
      width: 100%;

      &:first-of-type {
        margin-top: 20px;
      }
    }

    & > * {
      max-width: 480px;
      flex: 1;
    }

    & ul {
      max-width: 440px;

      h4 {
        font-size: 1.375rem;
      }
      img {
        height: 162px;
        width: 178px;
      }
    }
  }
  .shipping-addresses-list {
    width: 100%;
    max-width: 500px;
  }
  & .cart-heading {
    max-width: 500px;
    display: flex;
    justify-content: space-between;
    padding: 18px;

    @media (max-width: 1150px) {
      padding: 0;
      padding-bottom: 0;
    }

    a {
      color: rgba(34, 119, 204, 1);
    }
    & .cart-counter {
      font-size: 0.875rem;
      color: rgba(187, 187, 187, 1);
    }
  }

  & .checkout-button {
    border-radius: 4px;
    font-size: 1.125rem;
    width: 100%;
    max-width: 500px;
    margin-top: 16px;
    align-self: flex-start;
    height: 47px;

    @media (min-width: 1150px) {
      border-radius: 12px;
      height: 83px;
      font-size: 1.5rem;
    }
  }

  .missing-address-caption {
    color: ${({ theme }) => theme.colors.status.error};
    margin-top: 1rem;
    font-size: 0.75rem;
    text-align: center;

    a {
      color: inherit;
      font-weight: 700;
      text-decoration: underline !important;
      transition: 0.2s all;
    }

    a:hover {
      opacity: 0.6;
    }
  }

  .price-field {
    max-width: 500px;
    padding-top: 24px;
    margin: 0;
    font-weight: 500;
    display: flex;
    justify-content: space-between;

    &:last-of-type {
      font-weight: 700;
      font-size: 1.125rem;
    }
  }
  .price-field.empty {
    text-decoration: line-through;
  }
  & .actions {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    flex-basis: 400px;

    a {
      text-decoration: none;
    }
    @media (min-width: 1150px) {
      margin-top: 0;
      padding: 22px;
      background: rgba(248, 247, 247, 1);
      border-radius: 4px;
      display: flex;
      flex-direction: column;
    }
  }

  .cart-section {
    @media (max-width: 1150px) {
      position: relative;
      background: rgba(248, 247, 247, 1);
      max-width: 500px;
      width: 100%;

      & .mobile-background {
        padding: 0 20px 20px;
        @media (max-width: 500px) {
          position: relative;
          margin: 0 -20px;
          background: rgba(248, 247, 247, 1);
          width: calc(100% + 40px);
        }
      }
    }
  }

  @media (max-width: 500px) {
    .card-list {
      transform: unset;
      box-shadow: 0px -3px 25px 5px rgb(0 88 171 / 7%);
    }
    .card {
      transform: unset;
      border-radius: 0;
      box-shadow: none;
      // TODO: REMOVE ENTIRELY OR PRESERVE - THIS CHANGE BREAKS MOBILE DESIGNS
      /* margin-top: 61px;
      margin-bottom: 58px;
      box-shadow: 0px -3px 25px 5px rgb(0 88 171 / 7%);
      border-radius: 25px;
      height: 134px; */
    }
    /* .shipping-section {
      padding: 12px;
      height: 305px;
      hr {
        display: none;
      }
    } */
    .card-image-container {
      /* display: flex;
      flex-direction: column;
      justify-content: center; */
    }
    .cart-item:first-of-type .card {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }
    .cart-item:last-of-type .card {
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    }
    .checkout-button {
      position: relative;
      margin-top: 30px;
      width: 100%;
      ${rtl`
          left: 50%;
          transform: translateX(-50%);
      `}
    }
    .cart-item-price {
      position: absolute;
      bottom: 25px;
      ${rtl`
        right: 25px;
      `}
    }

    .card-divider {
      display: none;
    }
  }

  & .checkbox {
    margin-bottom: 14px;

    @media (min-width: 1150px) {
      margin-bottom: 32px;
    }
  }

  & .add-btn {
    border-radius: 4px;
  }
`;
