import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from 'assets/@client/cart/delete_icon.svg';
import styled from 'styled-components';
import QuantityCounter from 'components/@client/QuantityCounter';
import Tooltip from 'components/Tooltip';
import no_image_placeholder from 'assets/images/client-products/no_image.png';
import { useFormattedCurrency } from 'hooks';
import { Message } from 'i18n';
import type { CartItem as ICartItem } from 'types/properties';
import { summarizeText } from 'utils/string.utils';
import ProductPageModal from 'pages/appClient/productPage/component/ProductPageModal';
import { additionalChargeSubCategory } from 'mockData/additionalChargeSubCategory';
import { LogsAPI } from 'api/methods';
import { userLogData } from 'mockData/userLogData';
import { isMobile } from 'react-device-detect';

interface Props extends ICartItem {
  dense?: boolean;
  isLoading?: boolean;
  nameLength?: number;
  onQuantityChange?: (id: string, quantity: number) => void;
  onDelete?: (id: string) => void;
}

const CartItem: React.FC<Props> = ({
  dense,
  id,
  isLoading,
  nameLength = 40,
  quantity,
  product,
  onDelete,
  onQuantityChange,
}) => {
  const intl = useIntl();
  const handleDelete = React.useCallback(() => onDelete?.(id), [id, onDelete]);
  const price = useFormattedCurrency(product?.finalPrice * quantity);
  const orignal_price = useFormattedCurrency(product?.originalPrice * quantity);

  const additionalCharge = additionalChargeSubCategory.includes(
    product.subCategory.name
  );

  return (
    <Container dense={dense}>
      <ImageContainer>
        <Image
          src={product.thumbnail?.file ?? no_image_placeholder}
          alt={product.altName ?? product.englishName}
        />
        {product.quantity === 0 && (
          <div className="overlay">
            <FormattedMessage id={Message.CART_ITEM_OUT_OF_STOCK_LABEL} />
          </div>
        )}
        {additionalCharge && (
          <AdditionalText>
            <FormattedMessage id={Message.CART_ADDITIONAL_TEXT} />
          </AdditionalText>
        )}
      </ImageContainer>
      <Content>
        <Tooltip
          title={product?.altName ?? product?.englishName}
          $variant="info"
        >
          <Title>
            {summarizeText(
              product?.altName ?? product?.englishName,
              isMobile ? 13 : nameLength
            )}
          </Title>
        </Tooltip>
        <CategoryName>
          {intl.locale === 'he'
            ? product.subCategory.hebrewName
            : product.subCategory.name}
        </CategoryName>
        <Price>
          {price} {orignal_price !== price && <sub>{orignal_price}</sub>}
        </Price>
        {onQuantityChange ? (
          <>
            <QuantityCounterContainer>
              <QuantityCounter
                itemId={id}
                isLoading={isLoading}
                maxQuantity={product?.quantity}
                value={quantity}
                onClick={onQuantityChange}
                logData={{
                  area: userLogData.eventArea.myCart,
                  section: userLogData.eventSection.cart,
                  name: userLogData.eventName.quantityAdd,
                  path: userLogData.eventPath.appCartCartIdPath,
                  metadata: {
                    cart_id: id,
                    product_sku: product.sku,
                  },
                }}
              />
            </QuantityCounterContainer>

            <StockContainer>
              <StockInformer isSufficientQuantity={product.quantity < quantity}>
                <FormattedMessage
                  id={
                    product.quantity < 10
                      ? Message.CART_ITEM_STOCK_LABEL_FEW_IN_STOCK
                      : Message.CART_ITEM_STOCK_LABEL_FULL
                  }
                  values={{
                    quantity: `${product.quantity} `,
                  }}
                />
              </StockInformer>
            </StockContainer>
          </>
        ) : (
          <span>
            <FormattedMessage id={Message.CHECKOUT_ITEM_QUANTITY_LABEL} />:{' '}
            {quantity}
          </span>
        )}

        <ButtonContainer>
          <ProductPageModal
            btnOnClick={() => {
              LogsAPI.postUserLogs({
                name: userLogData.eventName.viewDetails,
                area: userLogData.eventArea.myCart,
                section: userLogData.eventSection.cart,
                path: userLogData.eventPath.appCartCartIdPath,
                metadata: { cart_id: id, product_sku: product.id },
              });
            }}
            product={product.id}
          />
          {onDelete && (
            <IconButton
              className="x-button-mobile"
              onClick={() => {
                LogsAPI.postUserLogs({
                  name: userLogData.eventName.deleteProduct,
                  area: userLogData.eventArea.myCart,
                  section: userLogData.eventSection.cart,
                  path: userLogData.eventPath.appCartCartIdPath,
                  metadata: { cart_id: id, product_sku: product.id },
                });
                LogsAPI.pushDataLayer([
                  { ecommerce: null },
                  {
                    event: 'removeFromCart',
                    ecommerce: {
                      remove: {
                        products: [
                          {
                            name: product?.englishName,
                            id: product?.id,
                            price: product?.finalPrice,
                            brand: product?.supplier.companyName,
                            category: product?.subCategory,
                            quantity,
                          },
                        ],
                      },
                    },
                  },
                ]);
                handleDelete();
              }}
            >
              <img src={DeleteIcon} alt="Delete" />
            </IconButton>
          )}
        </ButtonContainer>
      </Content>
    </Container>
  );
};

export default CartItem;

const Container = styled.li.attrs({ className: 'cart-item' })<{
  dense?: boolean;
}>`
  display: flex;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 33px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  gap: 16px;
  padding: 16px;
`;

const StockInformer = styled.span.attrs({ className: 'stock-informer' })<{
  isSufficientQuantity?: boolean;
}>`
  font-family: ${({ theme }) => (theme.dir === 'rtl' ? 'Assistant' : 'Roboto')};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${({ isSufficientQuantity = false }) =>
    isSufficientQuantity ? 'rgba(252, 92, 66, 1)' : 'rgba(187, 187, 187, 1)'};
`;

const ImageContainer = styled.div`
  position: relative;
  max-width: 100px;
  max-height: 100px;
  & .overlay {
    position: absolute;
    ${({ theme }) => theme.utils.centerFlex};
    border-radius: inherit;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: rgba(255, 255, 255, 1);
    font-size: 0.875rem;
    font-weight: 700;
    background: rgba(134, 133, 133, 0.56);

    @media (min-width: 1150px) {
      font-size: 1rem;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 70%;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const Title = styled.span`
  font-family: ${({ theme }) => (theme.dir === 'rtl' ? 'Assistant' : 'Roboto')};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #282929;
`;

const CategoryName = styled.span`
  font-family: ${({ theme }) => (theme.dir === 'rtl' ? 'Assistant' : 'Roboto')};
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #3e3e3e;
`;

const Price = styled.span`
  font-family: ${({ theme }) => (theme.dir === 'rtl' ? 'Assistant' : 'Roboto')};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #fc5c42;
  gap: 8px;
  sub {
    text-decoration-line: line-through;
    color: #d9d9d9;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  align-items: end;
  justify-content: end;
`;

const StockContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const QuantityCounterContainer = styled.div`
  && {
    .quantity-counter {
      padding: 0;
      gap: 9px;
      button {
        min-width: initial;
        padding: 0;
      }
    }
  }
`;

const AdditionalText = styled.div.attrs({ className: 'card additional-text' })`
  color: ${({ theme }) => theme.colors.primary.lightMobile};
  font-size: 0.74rem;
  font-weight: 500;
  margin-top: 2px;
`;
