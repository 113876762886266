import styled from 'styled-components';

const NotFound = () => {
  return (
    <Container>
      <h1>This page has been removed or not exist.</h1>
    </Container>
  );
};

export default NotFound;

const Container = styled.div`
  width: 85vw;
  height: 80%;
  ${({ theme }) => theme.utils.centerFlex};

  & h1 {
    font-size: 1.75rem;
    opacity: 0.6;
  }
`;
