import { Message } from 'i18n';
import * as React from 'react';
import { FormProvider, UseFormMethods } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Form from 'components/forms';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { PopupModal, useCalendlyEventListener } from 'react-calendly';
import DragHomeButton from 'pages/appClient/onboarding/shared/DragHomeButton';
import ScheduleMeetingPicker from 'pages/appClient/supplier-info/components/ScheduleMeetingPicker';
import { BasicOption } from 'components/Filters/Options';
import { useManufacturers, useRouter, useSubCategories } from 'hooks';
import type { Option } from 'components/Autocomplete/Autocomplete.config';
import {
  SupplierOnboardingAction,
  useSupplierOnboardingDispatch,
  useSupplierOnboardingState,
} from 'context/supplierOnboardingContext';
import ImagePicker from '../components/ImagePicker';
import SkipConfirmationDialog from '../components/SkipConfirmationDialog';
import MobileTooltip from '../components/MobileTooltip';

const SupplierMainDetailsMobile: React.FC<{
  onSubmit: VoidFunction;
  methods: UseFormMethods<{
    englishName: string;
    media: string | File[];
    manufacturer: string | number;
    subCategory: string | number;
    mediaUrls: string[];
    msrp: string | number;
    price: string | number;
    quantity: string | number;
    sku: string;
  }>;
}> = ({ onSubmit, methods }) => {
  const [isConfirmationModalVisible, setIsGoBackModalVisible] = React.useState(
    false
  );
  const [isManufacturer, setIsManufacturer] = React.useState<Option | null>(
    null
  );
  const [isCategory, setIsCategory] = React.useState<Option | null>(null);
  const [schedulePickerOpen, setSchedulePickerOpen] = React.useState(false);
  const intl = useIntl();
  const { manufacturers } = useManufacturers();
  const { subCategoryOptions } = useSubCategories();
  const state = useSupplierOnboardingState();
  const { history } = useRouter();
  const dispatch = useSupplierOnboardingDispatch();
  const { setValue, control, formState, getValues } = methods;
  const [open, setOpen] = React.useState<boolean>(false);
  const [
    openManufacturerName,
    setOpenManufacturerName,
  ] = React.useState<boolean>(false);
  const [openMSRP, setOpenMSRP] = React.useState<boolean>(false);
  const [openMediaURL, setOpenMediaURL] = React.useState<boolean>(false);

  useCalendlyEventListener({
    onEventScheduled: e => {
      dispatch({
        type: SupplierOnboardingAction.SET_IS_SHEDULED_MEETING,
        payload: true,
      });
    },
  });

  React.useEffect(() => {
    if (isManufacturer?.id)
      setValue('manufacturer', isManufacturer?.id.toString(), {
        shouldValidate: true,
        shouldDirty: true,
      });

    if (isCategory?.value)
      setValue('subCategory', isCategory?.value.toString(), {
        shouldDirty: true,
        shouldValidate: true,
      });
  }, [isManufacturer, isCategory, setValue]);

  return (
    <>
      <ParentDiv>
        <Header>
          <SkipText onClick={() => setIsGoBackModalVisible(true)}>
            <FormattedMessage id={Message.PROPERTY_ADD_PROPERTY_BUTTON_SKIP} />
          </SkipText>
          <UploadProductText>
            <FormattedMessage
              id={Message.SUPPLIER_UPLOAD_YOUR_FIRST_PRODUCT}
              values={{
                span: (chunks: string) => (
                  <span style={{ color: '#FC5C42' }}>{chunks}</span>
                ),
              }}
            />
          </UploadProductText>

          <MainDetailsText>
            <FormattedMessage id={Message.SUPPLIER_MAIN_DETAILS} />
          </MainDetailsText>
        </Header>
        <MainDetails>
          <FormProvider {...methods}>
            <FormWrapperContainer onSubmit={onSubmit}>
              <ScrollableDiv>
                <FormFieldDiv
                  label={{
                    title: intl.formatMessage({
                      id: Message.SUPPLIER_PRODUCT_NAME_LABEL,
                    }),
                    isBold: true,
                  }}
                  name="englishName"
                  type="text"
                  placeholder={intl.formatMessage({
                    id: Message.SUPPLIER_PRODUCT_NAME_PLACEHOLDER,
                  })}
                />
                <ComboBoxDiv
                  className="combobox state"
                  width={327}
                  label={{
                    title: MobileTooltip({
                      label: 'Manufacturer name',
                      title: (
                        <FormattedMessage
                          id={Message.SUPPLIER_MANUFACTURER_NAME_TOOLTIP_TEXT}
                          values={{
                            linebreak: <br />,
                          }}
                        />
                      ),
                      open,
                      setOpen,
                      marginWidth: 327,
                      arrowMargin: true,
                    }),
                  }}
                  name="manufacturer"
                  options={manufacturers ?? []}
                  searchPlaceholder="Search Manufacturer..."
                  renderOption={BasicOption}
                  placeholder="Select Manufacturer"
                  selectedValue={isManufacturer}
                  setSelectedValue={setIsManufacturer}
                  setterKey="iso"
                />
                <FormFieldDiv
                  label={{
                    title: MobileTooltip({
                      label: intl.formatMessage({
                        id: Message.SUPPLIER_RETAILER_SKU_LABEL,
                      }),
                      title: (
                        <FormattedMessage
                          id={Message.SUPPLIER_RETAILER_SKU_TOOLTIP_TEXT}
                          values={{
                            linebreak: <br />,
                          }}
                        />
                      ),
                      open: openManufacturerName,
                      setOpen: setOpenManufacturerName,
                      marginWidth: 327,
                      arrowMargin: true,
                    }),
                  }}
                  name="sku"
                  type="text"
                  placeholder={intl.formatMessage({
                    id: Message.SUPPLIER_RETAILER_SKU_PLACEHOLDER,
                  })}
                />
                <ComboBoxDiv
                  className="combobox state"
                  width={327}
                  label={{ title: 'Category' }}
                  name="subCategory"
                  options={(subCategoryOptions ?? []).map(
                    ({ name, id, group }) => ({
                      name: `${name} - ${group}`,
                      value: id,
                    })
                  )}
                  searchPlaceholder="Search Category..."
                  renderOption={BasicOption}
                  placeholder="Select category"
                  selectedValue={isCategory}
                  setSelectedValue={setIsCategory}
                  setterKey="iso"
                />
                <FormFieldDiv
                  label={{
                    title: intl.formatMessage({
                      id: Message.SUPPLIER_QUANTITY_LABEL,
                    }),
                    isBold: true,
                  }}
                  name="quantity"
                  type="text"
                  placeholder={intl.formatMessage({
                    id: Message.SUPPLIER_QUANTITY_PLACEHOLDER,
                  })}
                />
                <FormFieldDiv
                  label={{
                    title: MobileTooltip({
                      label: intl.formatMessage({
                        id: Message.SUPPLIER_MSRP_LABEL,
                      }),
                      title: (
                        <FormattedMessage
                          id={Message.SUPPLIER_MSRP_TOOLTIP_TEXT}
                          values={{
                            linebreak: <br />,
                          }}
                        />
                      ),
                      open: openMSRP,
                      setOpen: setOpenMSRP,
                      marginWidth: 327,
                      arrowMargin: true,
                    }),
                  }}
                  name="msrp"
                  type="text"
                  placeholder={intl.formatMessage({
                    id: Message.SUPPLIER_MSRP_PLACEHOLDER,
                  })}
                />
                <FormFieldDiv
                  label={{
                    title: intl.formatMessage({
                      id: Message.SUPPLIER_SALES_PRICE_LABEL,
                    }),
                    isBold: true,
                  }}
                  name="price"
                  type="text"
                  placeholder={intl.formatMessage({
                    id: Message.SUPPLIER_SALES_PRICE_PLACEHOLDER,
                  })}
                />
                <Form.TagsField
                  name="mediaUrls"
                  required={false}
                  defaultValue={state.addProductDetails?.mediaUrls}
                  label={MobileTooltip({
                    label: (
                      <div
                        style={{
                          fontWeight: 600,
                          fontSize: 16,
                          lineHeight: '20px',
                          marginLeft: '-10px',
                          fontFamily: 'Roboto Condensed',
                        }}
                      >
                        Media URLs
                      </div>
                    ),
                    title: (
                      <FormattedMessage
                        id={Message.SUPPLIER_MEDIA_URLS_TOOLTIP_TEXT}
                        values={{
                          linebreak: <br />,
                        }}
                      />
                    ),
                    open: openMediaURL,
                    setOpen: setOpenMediaURL,
                    marginWidth: 327,
                    arrowMargin: true,
                  })}
                />
                <ImagePickerDiv>
                  <LogoParent>
                    <UploadLogoText>
                      <FormattedMessage
                        id={Message.SUPPLIER_UPLOAD_MEDIA_LABEL}
                      />
                    </UploadLogoText>
                    <ImagePicker
                      name="media"
                      label="Upload media"
                      control={control}
                      onFileChange={e => {
                        if (e?.target?.files?.[0])
                          setValue('media', e?.target.files[0]);
                      }}
                    />
                  </LogoParent>
                </ImagePickerDiv>
              </ScrollableDiv>
              <FooterParent>
                <BackButtonParent>
                  <BackButton onClick={() => history.goBack()}>
                    <BackButtonText>
                      <FormattedMessage
                        id={Message.PROPERTY_ADD_PROPERTY_BUTTON_PREVIOUS}
                      />
                    </BackButtonText>
                  </BackButton>
                </BackButtonParent>
                <NextButtonParent>
                  <NextButton
                    disabled={
                      !formState.isValid || !getValues().mediaUrls?.length
                    }
                  >
                    <NextButtonText>
                      <FormattedMessage
                        id={Message.PROPERTY_ADD_PROPERTY_BUTTON_NEXT}
                      />
                    </NextButtonText>
                  </NextButton>
                </NextButtonParent>
              </FooterParent>
            </FormWrapperContainer>
          </FormProvider>
        </MainDetails>
      </ParentDiv>
      <ScheduleMeetingPicker onClick={() => setSchedulePickerOpen(true)} />
      <DragHomeButton />
      <PopupModal
        open={schedulePickerOpen}
        url="https://calendly.com/dan1707"
        rootElement={document.getElementById('root')!}
        onModalClose={() => {
          setSchedulePickerOpen(false);
        }}
      />
      <SkipConfirmationDialog
        isOpen={isConfirmationModalVisible}
        toggle={() => setIsGoBackModalVisible(prev => !prev)}
      />
    </>
  );
};

export default SupplierMainDetailsMobile;

const ParentDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: 'Roboto Condensed';
  .MuiFormControl-root {
    width: 327px;
  }
  .dropdown-placeholder {
    color: #3e3e3e;
  }
  .combobox {
    margin-top: 8px;
    label {
      margin-bottom: 0;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #3e3e3e;
      margin-left: 0;
      font-family: 'Roboto Condensed';
    }
  }
  .image-picker {
    width: 327px;
    height: 129px;
    margin-bottom: 0;
    border: 1px dashed #e8eaeb;
    label {
      span {
        font-size: 16px;
        line-height: 20px;
      }
      svg {
        fill: #3e3e3e;
        opacity: 1;
      }
    }
  }
  .maindetail-tag {
    min-height: auto;
    margin-top: 8px;
  }
  .maindetail-span {
    padding-right: 0;
  }
  .dashed-icon {
    width: 48px;
    height: 48px;
    border: 1px dashed #3e3e3e;
  }
`;

const SkipText = styled.div`
  text-decoration-line: underline;
  color: #696969;
  cursor: pointer;
  font-size: 18px;
  line-height: 22px;
  margin-left: auto;
  margin-top: 8px;
`;

export const MainDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const UploadProductText = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  margin-top: 16px;
`;

export const MainDetailsText = styled.div`
  color: #3e3e3e;
  font-size: 18px;
  line-height: 22px;
  margin-top: 4px;
`;

export const FormWrapperContainer = styled.form.attrs({
  className: 'form-container',
})`
  margin-top: 8px !important;
  display: flex;
  flex-direction: column;
  @media (min-width: 1150px) {
    margin-top: 20px;
  }

  .phoneNumber {
    margin: -6px 0 24px;
    & .flag-dropdown,
    & .flag-dropdown.open {
      height: 48px;
    }

    & .form-control {
      height: 48px;
    }
  }
  .country-list {
    & .search {
      & .search-box {
        height: 48px;
      }
    }
  }
  && .submit-btn {
    @media (min-width: 1150px) {
      margin-top: 0;
      width: unset;
      ${rtl`
          margin-left: auto;
        `}
    }
  }
  & .divider-wrapper {
    margin: 14px auto;
  }

  & .anchor-link {
    color: #2277cc;
    font-weight: 500;
  }

  & .actions-container {
    @media (min-height: 800px) {
      margin-top: 0;
    }
    @media (min-width: 1150px) {
      margin-top: 15px;
    }
  }
`;

const FormFieldDiv = styled(Form.Field)`
  margin-top: 8px;
  label {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #3e3e3e;
    margin-left: 0;
    font-family: 'Roboto Condensed';
  }
  .MuiInputBase-root {
    width: 327px !important;
    margin-top: 20px;
  }
`;

const ComboBoxDiv = styled(Form.ComboBox)`
  label {
    margin-bottom: -2px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #3e3e3e;
    margin-left: 0;
  }
`;

export const InfoView = styled.div`
  height: 21.33px;
  width: 21.33px;
  margin-left: 6px;
  cursor: pointer;
`;

export const InfoImage = styled.img`
  height: 100%;
  width: 100%;
`;

export const ImagePickerDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 8px;
  label {
    width: 100%;
  }
`;

export const LogoParent = styled.div``;

export const UploadLogoText = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #3e3e3e;
`;

export const FooterParent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 24px;
`;

export const BackButtonParent = styled.div``;

export const BackButton = styled.button`
  border: 1px solid #d9d9d9;
  border-radius: 15px;
  padding: 9px 47px;
  height: 40px;
  width: 134px;
  text-align: center;
  background-color: white;
  cursor: pointer;
`;

export const BackButtonText = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: #696969;
`;

export const NextButtonParent = styled.div`
  text-align: center;
`;

export const NextButton = styled.button`
  :disabled {
    background: #d9d9d9;
    cursor: default;
  }
  background: #fc5c42;
  border: 1px solid #d9d9d9;
  border-radius: 15px;
  padding: 9px 48px;
  height: 40px;
  width: 134px;
  text-align: center;
  cursor: pointer;
`;

export const NextButtonText = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: rgba(255, 255, 255, 0.9);
`;

const ScrollableDiv = styled.div`
  height: calc(100vh - 200px);
  margin-bottom: 16px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  padding: 0 24px;
`;

const Header = styled.div`
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
