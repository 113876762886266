import { useMediaQuery } from '@material-ui/core';
import { ReactComponent as HrLine } from 'assets/icons/hrLine.svg';
import { Message } from 'i18n';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';

const PhotoGrid: React.FC<{ items: string[]; colors?: string[] }> = ({
  items,
  colors,
}) => {
  // const trail = useTrail(items.length, {
  //   config: { mass: 5, tension: 2000, friction: 200 },
  //   opacity: 1,
  //   x: 0,
  //   height: 110,
  //   from: { opacity: 0, x: 20, height: 0 },
  // });

  const isMobile = useMediaQuery(MOBILE_TABLET_RESOLUTION);
  const legoImage =
    'https://patricia-ai-data-staging.s3.amazonaws.com/media/lego-2.png';

  return isMobile ? (
    <MobileGrid>
      <TitleSection>
        <div className="moodtitle">
          <FormattedMessage id={Message.ONBOARDING_YOUR_MOODBOARD} />
        </div>
        <div className="moodsubtitle">
          <FormattedMessage id={Message.MOODBOARD_DESCRIPTION} />
        </div>
      </TitleSection>
      <div className="main">
        <div className="row">
          <div className="mood-boardres-part1-wrapper img-comman-wrap">
            <img alt="quiz img" src={items[0] || legoImage} />
          </div>
          <div className="mood-boardres-part1-wrapper img-comman-wrap">
            <img alt="quiz img" src={items[1] || legoImage} />
          </div>
          <div className="mood-boardres-part1-wrapper img-comman-wrap">
            <img alt="quiz img" src={items[2] || legoImage} />
          </div>
        </div>
        <div className="row">
          <div className="mood-boardres-part1-wrapper img-comman-wrap">
            <img src={items[3] || legoImage} alt="quiz img" />
          </div>
          <div className="mood-boardres-part1-wrapper res-round-wrapper img-comman-wrap">
            {colors?.map(e => (
              <MyDiv className="round" backgroundClr={e} key={e} />
            ))}
          </div>
          <div className="mood-boardres-part1-wrapper img-comman-wrap">
            <img src={items[4] || legoImage} alt="quiz img" />
          </div>
        </div>
        <div className="row">
          <div className="mood-boardres-part1-wrapper img-comman-wrap">
            <img alt="quiz img" src={items[5] || legoImage} />
          </div>
          <div className="mood-boardres-part1-wrapper img-comman-wrap">
            <img alt="quiz img" src={items[6] || legoImage} />
          </div>
          <div className="mood-boardres-part1-wrapper img-comman-wrap">
            <img alt="quiz img" src={items[7] || legoImage} />
          </div>
        </div>
      </div>
    </MobileGrid>
  ) : (
    <Grid>
      <div className="row">
        {items.slice(0, 5).map(e => (
          <div className="mood-board-part1-wrapper" key={e}>
            <img src={e || legoImage} alt="boardImg" />
          </div>
        ))}
      </div>
      <div className="row mood-board-main-wrapper">
        <div className="mood-board-part2-wrapper">
          {items.slice(5, 7).map(e => (
            <div className="sub-wrapper" key={e}>
              <img src={e || legoImage} alt="boardImg" />
            </div>
          ))}
        </div>
        <div className="mood-board-part2-wrapper">
          <div className="part2-wrapper-sub">
            <img src={items[7] || legoImage} alt="boardImg" />
          </div>
        </div>
        <div className="mood-board-part2-wrapper">
          <div className="sub-wrapper">
            <div className="txt-mmodboard">
              <p>
                <FormattedMessage id={Message.YOUR_MOODBOARD} />
              </p>
              <HrLine />
            </div>
          </div>
          <div className="sub-wrapper">
            <img src={items[8] || legoImage} alt="boardImg" />
          </div>
        </div>
        <div className="mood-board-part2last-wrapper">
          <div className="last-sub-wrapper design-long-wrap">
            <img src={items[9] || legoImage} alt="boardImg" />
          </div>
          <div className="last-sub-wrapper">
            <div className="sub-sub-wrap">
              <img src={items[10] || legoImage} alt="boardImg" />
            </div>
            <div className="sub-sub-wrap">
              <img src={items[11] || legoImage} alt="boardImg" />
            </div>
          </div>
        </div>
        <div className="round-wrapper">
          {colors?.map(e => (
            <MyDiv className="round" backgroundClr={e} key={e} />
          ))}
        </div>
      </div>
    </Grid>
  );
};

export default PhotoGrid;

const MyDiv = styled.div<{ backgroundClr: string }>`
  background: ${({ backgroundClr }) => backgroundClr};
`;

const MobileGrid = styled.section.attrs({ className: 'res-mood-board' })`
  margin: auto;
  margin-top: 48px;
  .main {
    padding-left: 30px;
  }
`;

const TitleSection = styled.div`
  text-align: center;
  margin-bottom: 48px;
  .moodtitle {
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    color: #11516d;
    margin-bottom: 16px;
  }
  .moodsubtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #696969;
  }
`;

const Grid = styled.section.attrs({
  className: 'desk-mood-board',
})`
  width: 1330px;
  margin: auto;
  margin-top: 48px;
  img {
    border-radius: 15px;
    object-fit: cover;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .mood-board-main-wrapper {
    position: relative;
  }
  .mood-board-part1-wrapper {
    width: 20%;
  }
  .mood-board-part1-wrapper img,
  .sub-wrapper img {
    width: 258.78px;
    height: 203.68px;
    border-radius: 15px;
    margin: 1px 0px 1px 0px;
  }

  .mood-board-part2-wrapper {
    width: 20%;
  }

  .mood-board-part2-wrapper .sub-wrapper {
    width: 100%;
  }

  .mood-board-part2-wrapper:last-child {
    width: 40%;
  }

  .mood-board-part2-wrapper .last-sub-wrapper {
    width: 100%;
    display: flex;
  }

  .mood-board-part2-wrapper .last-sub-wrapper .sub-sub-wrap {
    width: 100%;
    margin-top: 5px;
    margin-right: 5px;
  }

  .mood-board-part2-wrapper .last-sub-wrapper .sub-sub-wrap img {
    width: 258.78px;
    height: 203.68px;
  }
  .mood-board-part2-wrapper .part2-wrapper-sub img {
    width: 258.78px;
    height: 415.84px;
    border-radius: 15px;
  }
  .txt-mmodboard {
    border: 3px solid #fc5c42;
    border-radius: 15px;
    position: relative;
    width: 252.78px;
    height: 198.68px;

    align-items: center;
    justify-content: center;
    margin-bottom: 7px;
    svg {
      path {
        fill: #fc5c42;
        stroke: #fc5c42;
      }
    }
  }

  .txt-mmodboard p {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    color: #fc5c42;
  }

  .txt-mmodboard svg {
    width: 100% !important;
    height: initial !important;
    position: absolute;
    top: 120px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .mood-board-part2last-wrapper {
    width: 40%;
  }

  .mood-board-part2last-wrapper .last-sub-wrapper {
    width: 100%;
    display: flex;
  }

  .last-sub-wrapper .sub-sub-wrap {
    width: 50%;
  }

  .last-sub-wrapper .sub-sub-wrap img {
    width: 258.78px;
    height: 203.68px;
    margin-top: 7px;
  }
  .design-long-wrap img {
    width: 525.58px;
    height: 198.68px;
  }
`;
