import RendersActionTypes from 'store/constants/user-management/renders.constants';
import {
  GetRenderDataFulfilledPayload,
  GetRenderDataRejectedPayload,
  GetRendersDataRequestPayload,
} from 'store/types/user-management/renders.types';

export const getRendersList = (payload: GetRendersDataRequestPayload) => {
  return {
    type: RendersActionTypes.LOAD_RENDERS_LIST,
    payload,
  };
};

export const getRendersListFulfilled = (
  payload: GetRenderDataFulfilledPayload
) => ({
  type: RendersActionTypes.LOAD_RENDERS_LIST_FULFILLED,
  payload,
});

export const getRendersListRejected = (
  payload: GetRenderDataRejectedPayload
) => ({
  type: RendersActionTypes.LOAD_RENDERS_LIST_REJECTED,
  payload,
});

export type RendersAction = ReturnType<
  | typeof getRendersList
  | typeof getRendersListFulfilled
  | typeof getRendersListRejected
>;
