import { createStyles, withStyles } from '@material-ui/core/styles';
import MuiSelect from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import styled from 'styled-components';
import styledTheme from 'styles/theme';

const Select = styled(MuiSelect).attrs(() => ({
  variant: 'outlined',
  input: <SelectInput notched={false} />,
}))<{ open?: boolean; $isInvalid?: boolean }>`
  && {
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.darkGray};
    min-width: 80px;
    max-width: 284px;
    height: 33px;
    color: ${({ theme }) => theme.colors.gray};
    font-size: 0.875rem;

    &&.date-picker-month-select {
      min-width: 70px;
      width: 70px;
    }
  }

  && .MuiMenuItem-root {
    width: 100%;
  }

  & .MuiSelect-icon {
    font-size: 2rem;
    color: ${({ theme }) => theme.colors.primary.dark};
    transform: rotate(${({ open }) => (open ? 180 : 0)}deg);
    transition: transform 0.2s;
    transform-origin: center;
    position: absolute;
    top: -2px;
    right: 2px;
  }

  && .MuiSelect-select {
    padding: 5px;
    border: 1px solid transparent;
    border-radius: 8px;
  }

  && .MuiSelect-select:focus {
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.colors.primary.dark};
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${({ $isInvalid, theme }) =>
      $isInvalid ? theme.colors.primary.light : 'transparent'} !important;
  }
`;

const SelectInput = withStyles(() =>
  createStyles({
    root: {
      backgroundColor: 'white',
      display: 'flex !important',
      alignItems: 'center !important',
    },
    input: {
      width: '100%',
    },
    notchedOutline: {
      '&:focus': {
        borderColor: `${styledTheme.colors.primary.dark} !important`,
      },
    },
  })
)(OutlinedInput);

export default Select;
