import { Client, Room } from 'types/clients';
import * as actions from 'store/actions/user-management/clients.actions';
import ClientsType from 'store/constants/user-management/clients.constants';
import * as Payload from 'store/types/user-management/clients.types';
import { Cart } from 'types/properties';

export interface ClientsState {
  loading: Record<ClientsType, boolean>;
  error: Record<ClientsType, null | string>;
  users: Client[];
  pageCount: number;
  userCount: number;
  cart?: Cart;
  rooms?: Room[];
}

const REQUESTS = [
  ClientsType.GET_USERS_DATA_REQUEST,
  ClientsType.GET_USER_DATA_REQUEST,
  ClientsType.GET_PRODUCTS_DATA_REQUEST,
  ClientsType.GET_PROPERTIES_DATA_REQUEST,
  ClientsType.GET_QUIZ_RESULTS_DATA_REQUEST,
  ClientsType.GET_CART_ITEM_DATA_REQUEST,
];

const initialState: ClientsState = {
  loading: REQUESTS.reduce(
    (state, next) => ({ ...state, [next]: false }),
    {} as Record<ClientsType, boolean>
  ),
  error: REQUESTS.reduce(
    (state, next) => ({ ...state, [next]: null }),
    {} as Record<ClientsType, null | string>
  ),
  users: [],
  pageCount: 0,
  userCount: 0,
  rooms: [],
};

const clientsReducer = (
  state: ClientsState = initialState,
  action: actions.ClientsAction
): ClientsState => {
  switch (action.type) {
    case ClientsType.GET_USERS_DATA_REQUEST:
      return updateLoading(state, ClientsType.GET_USERS_DATA_REQUEST);
    case ClientsType.GET_USERS_DATA_FULFILLED:
      return updateSuccess(
        state,
        ClientsType.GET_USERS_DATA_REQUEST,
        action.payload as Payload.GetUsersDataFulfilledPayload
      );
    case ClientsType.GET_USERS_DATA_REJECTED:
      return updateError(
        state,
        ClientsType.GET_USERS_DATA_REQUEST,
        action.payload as Payload.GetUserDataRejectedPayload
      );
    case ClientsType.GET_USER_DATA_REQUEST:
      return updateLoading(state, ClientsType.GET_USER_DATA_REQUEST);

    case ClientsType.GET_USER_DATA_FULFILLED:
      return updateSuccess(
        state,
        ClientsType.GET_USER_DATA_REQUEST,
        action.payload as Payload.GetUserDataFulfilledPayload
      );
    case ClientsType.GET_ROOMS_DATA_FULFILLED:
      return updateSuccess(
        state,
        ClientsType.GET_ROOMS_DATA_REQUEST,
        action.payload as Payload.GetRoomsDataFulfilledPayload
      );

    case ClientsType.GET_USER_DATA_REJECTED:
      return updateError(
        state,
        ClientsType.GET_USER_DATA_REQUEST,
        action.payload as Payload.GetUserDataRejectedPayload
      );

    case ClientsType.GET_PRODUCTS_DATA_REQUEST:
      return updateLoading(state, ClientsType.GET_PRODUCTS_DATA_REQUEST);

    case ClientsType.GET_CART_ITEM_DATA_REQUEST:
      return updateLoading(state, ClientsType.GET_CART_ITEM_DATA_REQUEST);

    case ClientsType.GET_PRODUCTS_DATA_FULFILLED:
      return updateSuccess(
        state,
        ClientsType.GET_PRODUCTS_DATA_REQUEST,
        action.payload as Payload.GetProductsDataFulfilledPayload
      );
    case ClientsType.GET_CART_ITEM_DATA_FULFILLED:
      return updateSuccess(
        state,
        ClientsType.GET_CART_ITEM_DATA_REQUEST,
        action.payload as Payload.GetCartsDataFulfilledPayload
      );
    case ClientsType.GET_PRODUCTS_DATA_REJECTED:
      return updateError(
        state,
        ClientsType.GET_PRODUCTS_DATA_REQUEST,
        action.payload as Payload.GetProductsDataRejectedPayload
      );
    case ClientsType.GET_PROPERTIES_DATA_REQUEST:
      return updateLoading(state, ClientsType.GET_PROPERTIES_DATA_REQUEST);
    case ClientsType.GET_PROPERTIES_DATA_FULFILLED:
      return updateSuccess(
        state,
        ClientsType.GET_PROPERTIES_DATA_REQUEST,
        action.payload as Payload.GetPropertiesDataFulfilledPayload
      );
    case ClientsType.GET_PROPERTIES_DATA_REJECTED:
      return updateError(
        state,
        ClientsType.GET_PROPERTIES_DATA_REQUEST,
        action.payload as Payload.GetPropertiesDataRejectedPayload
      );
    case ClientsType.GET_QUIZ_RESULTS_DATA_REQUEST:
      return updateLoading(state, ClientsType.GET_QUIZ_RESULTS_DATA_REQUEST);

    case ClientsType.GET_QUIZ_RESULTS_DATA_FULFILLED:
      return updateSuccess(
        state,
        ClientsType.GET_QUIZ_RESULTS_DATA_REQUEST,
        action.payload as Payload.GetPropertiesDataFulfilledPayload
      );
    case ClientsType.GET_QUIZ_RESULTS_DATA_REJECTED:
      return updateError(
        state,
        ClientsType.GET_QUIZ_RESULTS_DATA_REQUEST,
        action.payload as Payload.GetPropertiesDataRejectedPayload
      );

    case ClientsType.GET_USER_ACTIVITY_DATA_REQUEST:
      return updateLoading(state, ClientsType.GET_USER_ACTIVITY_DATA_REQUEST);

    case ClientsType.GET_USER_ACTIVITY_DATA_FULFILLED:
      return updateSuccess(
        state,
        ClientsType.GET_USER_ACTIVITY_DATA_REQUEST,
        action.payload as Payload.GetUserLogsDataFulfilledPayload
      );
    case ClientsType.GET_USER_ACTIVITY_DATA_REJECTED:
      return updateError(
        state,
        ClientsType.GET_USER_ACTIVITY_DATA_REQUEST,
        action.payload as Payload.GetPropertiesDataRejectedPayload
      );

    default:
      return state;
  }
};

export default clientsReducer;

type RequestType =
  | ClientsType.GET_USERS_DATA_REQUEST
  | ClientsType.GET_USER_DATA_REQUEST
  | ClientsType.GET_PRODUCTS_DATA_REQUEST
  | ClientsType.GET_PROPERTIES_DATA_REQUEST
  | ClientsType.GET_QUIZ_RESULTS_DATA_REQUEST
  | ClientsType.GET_CART_ITEM_DATA_REQUEST
  | ClientsType.GET_USER_ACTIVITY_DATA_REQUEST
  | ClientsType.GET_ROOMS_DATA_REQUEST;

const updateLoading = (state: ClientsState, requestType: RequestType) => ({
  ...state,
  loading: { ...state.loading, [requestType]: true },
  error: { ...state.error, [requestType]: null },
});

type ErrorPayload =
  | Payload.GetUsersDataRejectedPayload
  | Payload.GetProductsDataRejectedPayload
  | Payload.GetPropertiesDataRejectedPayload;
const updateError = (
  state: ClientsState,
  requestType: RequestType,
  { error }: ErrorPayload
) => ({
  ...state,
  loading: { ...state.loading, [requestType]: false },
  error: { ...state.error, [requestType]: error },
});

type SuccessPayload =
  | Payload.GetUsersDataFulfilledPayload
  | Payload.GetUserDataFulfilledPayload
  | Payload.GetProductsDataFulfilledPayload
  | Payload.GetPropertiesDataFulfilledPayload
  | Payload.GetCartsDataFulfilledPayload
  | Payload.GetUserLogsDataFulfilledPayload
  | Payload.GetRoomsDataFulfilledPayload;
const updateSuccess = (
  state: ClientsState,
  requestType: RequestType,
  payload: SuccessPayload
) => {
  return {
    ...state,
    loading: { ...state.loading, [requestType]: false },
    error: { ...state.error, [requestType]: null },
    ...payload,
  };
};
