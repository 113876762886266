import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useRouter } from 'hooks';

interface Props {
  title: string | React.ReactNode;
}

const MobileHeader: React.FC<Props> = ({ title }) => {
  const { history } = useRouter();
  return (
    <Section>
      <IconContainer onClick={() => history.go(-1)}>
        <ArrowBackIosNewIcon />
      </IconContainer>
      <Content>
        <Title>{title}</Title>
      </Content>
    </Section>
  );
};

export default MobileHeader;

const Section = styled.div.attrs({ className: 'mobile-header' })`
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 20px;
  margin-top: 24px;
`;

const IconContainer = styled.div.attrs({ className: 'mobile-header-icon' })`
  width: 40%;
  ${rtl`
  margin-left: 24px;
  `};
  svg {
    fill: #282929;
    transform: rotate(
      ${({ theme }) => (theme.dir === 'rtl' ? '180deg' : '0deg')}
    );
  }
`;

const Content = styled.div`
  width: 60%;
`;

const Title = styled.h3`
  font-family: ${({ theme }) => theme.dir !== 'rtl' && 'Roboto'};
  font-style: normal;
  font-weight: ${({ theme }) => (theme.dir === 'rtl' ? 600 : 500)};
  font-size: 22px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #282929;
`;
