import useMediaQuery from '@material-ui/core/useMediaQuery';
import { SMALL_RESOLUTION } from 'styles/sharedStyle';
import type { Dimension } from 'types/common.types';

const useBreakpoint = (
  min?: Dimension,
  max?: Dimension,
  breakpoint = SMALL_RESOLUTION
) => {
  const isSmallScreen = useMediaQuery(breakpoint);
  const height = isSmallScreen ? min?.h : max?.h;
  const width = isSmallScreen ? min?.w : max?.w;

  return { height, width, isSmallScreen };
};

export default useBreakpoint;
