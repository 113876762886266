import * as React from 'react';
import ListItem from '@material-ui/core/ListItem';
import styled from 'styled-components';
import Icon from 'components/Icon';
import { stringifyAddress } from 'utils/addresses.utils';
import { IAddress } from 'types/common.types';
import AddressActions from './AddressActions';

interface Props {
  address: IAddress;
  withHomeIcon: boolean;
  withActions?: boolean;
  onEdit?: (address: IAddress) => void;
  onDelete?: (address: IAddress) => void;
}

const AddressListItem: React.FC<Props> = ({
  address,
  withHomeIcon,
  withActions,
  onEdit,
  onDelete,
}) => {
  return (
    <Item key={stringifyAddress(address)} $isAddressesModal={!withHomeIcon}>
      <Address>
        <IconWrapper withHomeIcon={withHomeIcon}>
          {withHomeIcon && (
            <HomeIcon $isSecondaryAddress={!address.isPrimary} />
          )}
        </IconWrapper>
        {stringifyAddress(address)}
      </Address>
      {withActions && (
        <AddressActions
          onEdit={() => onEdit?.(address)}
          onDelete={() => onDelete?.(address)}
          isDeleteDisabled={address.isPrimary}
        />
      )}
    </Item>
  );
};

export default AddressListItem;

const Item = styled(ListItem).attrs(() => ({ button: false }))<{
  $isAddressesModal?: boolean;
}>`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    padding: ${({ $isAddressesModal }) =>
      $isAddressesModal ? '0.6rem 0.8rem' : '0 0 5px 0'};

    @media (min-width: 1500px) {
      &:nth-child(1) {
        margin-top: 5px;
      }
    }
  }
`;

const Address = styled.div`
  display: flex;
`;

const IconWrapper = styled.div<{ withHomeIcon?: boolean }>`
  width: ${({ withHomeIcon }) => (withHomeIcon ? 14 : 0)}px;
  margin-right: 10px;
`;

const HomeIcon = styled(Icon).attrs(({ theme, $isSecondaryAddress }) => ({
  name: 'home',
  color: $isSecondaryAddress ? theme.colors.silver : theme.colors.primary.dark,
  size: 14,
}))`
  margin: 0 8px 0 0;
`;
