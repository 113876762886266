import { PropertyRequestParams } from 'types/properties';
import * as Payload from 'store/types/user-management/properties.types';
import PropertyDataType from 'store/constants/user-management/properties.constants';

export const getPropertiesDataRequest = (payload: PropertyRequestParams) => ({
  type: PropertyDataType.GET_PROPERTIES_DATA_REQUEST,
  payload,
});

export const getPropertiesDataFulfilled = (
  payload: Payload.GetPropertiesDataFulfilledPayload
) => ({
  type: PropertyDataType.GET_PROPERTIES_DATA_FULFILLED,
  payload,
});

export const getPropertiesDataRejected = (
  payload: Payload.GetPropertiesDataRejectedPayload
) => ({
  type: PropertyDataType.GET_PROPERTIES_DATA_REJECTED,
  payload,
});

export type PropertiesAction = ReturnType<
  | typeof getPropertiesDataRequest
  | typeof getPropertiesDataFulfilled
  | typeof getPropertiesDataRejected
>;
