import font from 'assets/fonts/VarelaRound-Regular.woff';

export const Roboto = {
  family: 'Roboto',
  fonts: [
    {
      fontWeight: 400,
      src:
        'https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-regular-webfont.ttf',
    },
    {
      fontWeight: 500,
      src:
        'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
    },
    {
      fontWeight: 700,
      src:
        'https://cdnjs.cloudflare.com/ajax/libs/mdbootstrap/4.11.0/font/roboto/Roboto-Bold.ttf',
    },
  ],
};

export const VarelaRound = {
  family: 'Varela Round',
  src: font,
};
