import * as React from 'react';
import styled from 'styled-components';
import Breadcrumbs from 'components/@client/Breadcrumbs';
import Title from 'components/@client/TopTitle/Title';
import { ClientRouter } from 'router/routes';
import { ClientRoot, MOBILE_RESOLUTION } from 'styles/sharedStyle';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import { getLocalStorage, saveLocalStorage } from 'utils/common.utils';
import { useAuthentication, useFetch, useLocalStorage, useRouter } from 'hooks';
import { METHODS } from 'api/client';
import { useMediaQuery } from '@material-ui/core';
import {
  AUTH_CONFIG_ROOT_KEY,
  CART_KEY,
  GUEST_TOKEN,
} from 'constants/localstorage.constants';
import { LocalCartProduct } from 'types/products';
import PropertyCart from './property';

const Cart = () => {
  const guestToken = getLocalStorage(GUEST_TOKEN, null);
  const [cartState, _] = useLocalStorage<LocalCartProduct[]>(CART_KEY, []);
  const userConfig = getLocalStorage(AUTH_CONFIG_ROOT_KEY, null);
  const { token } = useAuthentication();
  const { history } = useRouter();
  const isMobile = useMediaQuery(MOBILE_RESOLUTION);
  const { isLoading } = useFetch({
    initialUrl: '/api/checkout_cart/guest_user_checkout/',
    skipOnStart: userConfig || Boolean(token) || !(!token && !guestToken),
    config: {
      method: METHODS.POST,
      data: {
        pre_register: true,
        checkout_data: cartState.map(e => ({
          product: e.id,
          quantity: e.quantity,
          checkout_quantity: e.quantity,
        })),
      },
    },
    onSuccess: (data: { token: string } | null) => {
      if (data) {
        saveLocalStorage(GUEST_TOKEN, data.token);
        saveLocalStorage(CART_KEY, []);
      }
      history.push(ClientRouter.CART);
    },
  });
  return (
    <ClientRoot>
      <Breadcrumbs
        links={[
          {
            to: ClientRouter.CART,
            children: <FormattedMessage id={Message.CART_TITLE} />,
          },
        ]}
      />
      {!isMobile && (
        <CartTitle>
          <Title
            title={<FormattedMessage id={Message.CART_TITLE} />}
            backTo={ClientRouter.APP}
          />
        </CartTitle>
      )}

      <PropertyCart isLoading={isLoading} />
    </ClientRoot>
  );
};

const CartTitle = styled.div`
  .client-top-title {
    justify-content: center;
    height: 28px;
    margin-bottom: 8px;
  }
`;

export default Cart;
