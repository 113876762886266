import { useCallback, useState } from 'react';
import client, { METHODS } from 'api/client';
import type { AxiosRequestConfig } from 'axios';

type Props = {
  url?: string;
};

const useDownloadFile = ({ url }: Props) => {
  const [currentPending, setPending] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);

  const handleDownload = useCallback(
    async (config: AxiosRequestConfig = {}) => {
      setPending(true);
      setError(null);
      try {
        window.location.href = await client({
          url,
          method: METHODS.GET,
          ...config,
        });
        setError(null);
      } catch (e) {
        const errorMessage = e.message || 'ERROR: Cannot refresh Data source';
        setError(errorMessage);
      } finally {
        setPending(false);
      }
    },
    [url]
  );

  return {
    isDownloading: currentPending,
    handleDownload,
    error,
  };
};

export default useDownloadFile;
