import * as React from 'react';
import type { CartItem } from 'api/responses';
import { useFetch } from 'hooks';
import { METHODS } from 'api/client';

const useUpdateItem = (onSuccess: (updatedItem: CartItem | null) => void) => {
  const { callFetch, isLoading: isUpdating } = useFetch<CartItem>({
    initialUrl: `/api/shopping_cart_item/`,
    skipOnStart: true,
    onSuccess,
  });

  const updateQuantity = React.useCallback(
    (id: string, quantity: number) => {
      callFetch({
        url: `/api/shopping_cart_item/${id}/`,
        method: METHODS.PATCH,
        data: { quantity },
        isGuestAllowed: true,
      });
    },
    [callFetch]
  );

  const deleteItem = React.useCallback(
    (id?: string) => {
      if (!id) return;
      callFetch({
        url: `/api/shopping_cart_item/${id}/`,
        method: METHODS.DELETE,
        isGuestAllowed: true,
      });
    },
    [callFetch]
  );

  const emptyItem = React.useCallback(
    (id?: string[]) => {
      if (id?.length === 0) return;
      callFetch({
        url: `/api/shopping_cart_item/empty_cart/`,
        method: METHODS.DELETE,
        isGuestAllowed: true,
      });
    },
    [callFetch]
  );

  return { updateQuantity, deleteItem, emptyItem, isUpdating };
};

export default useUpdateItem;
