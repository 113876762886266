import * as React from 'react';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';

interface Props {
  title: string;
  searchTerm: string;
  count?: number;
}

const SearchListItem: React.FC<Props> = ({ title, searchTerm, count = 1 }) => {
  const getCustomText = () => {
    if (title.toLowerCase().indexOf(searchTerm.toLowerCase()) === -1) {
      return <StyledP>{title}</StyledP>;
    }
    if (title.toLowerCase().indexOf(searchTerm.toLowerCase()) === 0) {
      return (
        <StyledP>
          <b>{title.substr(0, searchTerm.length)}</b>
          {title.substring(searchTerm.length, title.length)}
        </StyledP>
      );
    }

    return (
      <StyledP>
        {title.substring(
          0,
          title.toLowerCase().indexOf(searchTerm.toLowerCase())
        )}
        <b>
          {title.substr(
            title.toLowerCase().indexOf(searchTerm.toLowerCase()),
            searchTerm.length
          )}
        </b>
        {title.substring(
          title.toLowerCase().indexOf(searchTerm.toLowerCase()) +
            searchTerm.length,
          title.length
        )}
      </StyledP>
    );
  };

  return (
    <>
      <CustomDropListItem>
        <CustomTitle>{getCustomText()}</CustomTitle>

        <CustomCount>
          <p className="count">{count}</p>
        </CustomCount>
      </CustomDropListItem>
    </>
  );
};

export default SearchListItem;

const CustomDropListItem = styled.div`
  min-height: 50px;
  justify-content: space-between;

  padding: 0 10px;
  display: flex;
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.lightMobile};
    color: black;
  }
`;

const CustomTitle = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  ${rtl`
    margin-left: 5px;
  `}
`;

const StyledP = styled.p`
  font-family: ${({ theme }) => (theme.dir === 'rtl' ? 'Assistant' : 'Roboto')};
  font-size: 1.1rem;
  margin-top: 0px !important;
`;

const CustomCount = styled.div`
  ${rtl`
    margin-right: 5px;
  `}
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  .count {
    margin-top: 0px !important;
  }
`;
