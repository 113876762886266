import * as React from 'react';
import ProductSkeleton from 'components/@client/ProductsSection/ProductItem/ProductSkeleton';
import { ProductView } from 'components/@client/ProductsSection/ProductItem';

export const isThirdItem = (currentIndex: number) =>
  (currentIndex + 1) % 3 === 0;

interface Props {
  loadingItemCount?: number;
  view: ProductView;
}

const SkeletonList = ({ loadingItemCount = 3, view }: Props) => (
  <>
    {Array.from({ length: loadingItemCount }).map((_, i) => (
      <ProductSkeleton key={i.toString()} isWide={isThirdItem(i)} view={view} />
    ))}
  </>
);
export default SkeletonList;
