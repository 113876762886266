import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import styled from 'styled-components';
import Button, { ButtonProps } from 'components/@client/Button';
import SecondaryButton from 'components/@client/Button/SecondaryButton';
import { useOnboarding } from 'context/onboardingContext';

interface Props {
  withPrevious?: boolean;
  withSkip?: boolean;
  nextProps?: ButtonProps;
  prevProps?: ButtonProps;
  isLoading?: boolean;
  nextText?: string | React.ReactNode;
}

const Actions: React.FC<Props> = ({
  withPrevious = false,
  withSkip = false,
  nextProps,
  prevProps,
  isLoading,
  nextText,
}) => {
  const [{ choosePlan }] = useOnboarding();
  const buttonText = React.useCallback(() => {
    if (nextText) return nextText;
    if (withSkip && !choosePlan)
      return (
        <FormattedMessage id={Message.PROPERTY_ADD_PROPERTY_BUTTON_SKIP} />
      );
    return <FormattedMessage id={Message.PROPERTY_ADD_PROPERTY_BUTTON_NEXT} />;
  }, [nextText, withSkip, choosePlan]);

  return (
    <ActionsContainer>
      <div>
        {withPrevious && (
          <CustomSecondaryButton {...prevProps}>
            <FormattedMessage
              id={Message.PROPERTY_ADD_PROPERTY_BUTTON_PREVIOUS}
            />
          </CustomSecondaryButton>
        )}
      </div>
      {withSkip ? (
        <CustomSecondaryButton {...nextProps} isLoading={isLoading}>
          {buttonText()}
        </CustomSecondaryButton>
      ) : (
        <Button {...nextProps} isLoading={isLoading}>
          {buttonText()}
        </Button>
      )}
    </ActionsContainer>
  );
};

export default Actions;

export const CustomSecondaryButton = styled(SecondaryButton)`
  border: 1px solid #b3b3b3 !important;
  border-radius: 15px !important;
`;

export const ActionsContainer = styled.div.attrs({
  className: 'actions-container',
})`
  margin-top: 20px;
  display: flex;
  /* align-self: flex-end; */
  justify-content: center;
  align-items: center;
  font-size: 18px !important;
  font-weight: 500;

  @media (max-width: 767px) {
    position: fixed !important;
    bottom: 24px !important;
    width: 100% !important;
  }
  width: 100%;
  > button {
    width: 134px;
    margin: 0 100px;
    @media (max-width: 500px) {
      height: 40px !important;
      border-radius: 15px;
      margin: 0 7px;
    }
  }
  > div > button {
    width: 134px;
    margin: 0 100px;
    @media (max-width: 500px) {
      height: 40px !important;
      border-radius: 15px;
      margin: 0 7px;
    }
  }
  @media (min-width: 1150px) {
    height: 100%;
    padding: 20px 40px;
  }
`;
