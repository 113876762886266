import * as React from 'react';
import classnames from 'classnames';
import { useFormattedCurrency } from 'hooks';

const PriceField: React.FC<{
  label: string;
  value: number;
  fractionDigits?: number;
}> = ({ label, value = 0, fractionDigits = 0 }) => {
  const price = useFormattedCurrency(value, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });
  return (
    <p
      className={classnames('price-field', {
        empty:
          value === 0 && !label?.toString().toLowerCase().includes('total'),
      })}
    >
      <span>{label} </span>
      <span>{price}</span>
    </p>
  );
};

export default PriceField;
