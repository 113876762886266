// eslint-
import * as React from 'react';
import { ThemeProvider as StyledComponentsProvider } from 'styled-components';
import {
  StylesProvider,
  MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles';
import { useSession } from 'hooks';
import { GlobalStyle } from 'styles';
import theme from 'styles/theme';
import { SupportedLanguage } from 'types/common.types';
import { ManagementRouter } from 'router/routes';

const MITheme = createMuiTheme({
  custom: theme,
});

const ThemeProvider: React.FC = ({ children }) => {
  const { locale } = useSession();
  const direction =
    locale === SupportedLanguage.HEBREW &&
    !window.location.pathname.includes('/app/supplier-')
      ? 'rtl'
      : 'ltr';
  const MuiTheme = React.useMemo(
    () => ({ ...MITheme, direction, touchAction: 'none !important' }),
    [direction]
  );
  const StyledTheme = React.useMemo(
    () => ({ ...theme, dir: direction, touchAction: 'none !important' }),
    [direction]
  );
  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={MuiTheme}>
        <StyledComponentsProvider theme={StyledTheme}>
          <GlobalStyle
            enableZoom={window.location?.pathname?.includes(
              ManagementRouter.APP
            )}
          />
          {children}
        </StyledComponentsProvider>
      </MuiThemeProvider>
    </StylesProvider>
  );
};

export default ThemeProvider;
