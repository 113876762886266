import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { useIntl } from 'react-intl';
import SafeImg from 'components/SafeImg';
import {
  MOBILE_RESOLUTION,
  MOBILE_TABLET_RESOLUTION,
  TABLET_RESOLUTION,
} from 'styles/sharedStyle';
import { Supplier } from 'types/suppliers';
import { useAuthentication } from 'hooks';

interface Props {
  supplierList: Supplier[] | null;
}

const BrandLogoCarousel: React.FC<Props> = ({ supplierList }) => {
  const intl = useIntl();
  const { token } = useAuthentication();
  return (
    <MainContainer isLoggedIn={Boolean(token)}>
      {supplierList && (
        <SliderComponent itemLength={supplierList.length} locale={intl.locale}>
          <SliderItemsContainer>
            {supplierList.map(
              (data, i) =>
                data.white_logo && (
                  <React.Fragment key={data.id}>
                    <BorderDiv />
                    <SliderDiv key={data.id}>
                      <SafeImg src={data.white_logo} />
                    </SliderDiv>
                  </React.Fragment>
                )
            )}

            {supplierList.map(
              (data, i) =>
                data.white_logo && (
                  <React.Fragment key={i.toString()}>
                    <BorderDiv />
                    <SliderDiv key={data.id}>
                      <SafeImg src={data.white_logo} />
                    </SliderDiv>
                  </React.Fragment>
                )
            )}

            {supplierList.length < 10 && (
              <>
                {supplierList.map(
                  (data, i) =>
                    data.white_logo && (
                      <React.Fragment key={i.toString()}>
                        <BorderDiv />
                        <SliderDiv key={data.id}>
                          <SafeImg src={data.white_logo} />
                        </SliderDiv>
                      </React.Fragment>
                    )
                )}
                {supplierList.map(
                  (data, i) =>
                    data.white_logo && (
                      <React.Fragment key={i.toString()}>
                        <BorderDiv />
                        <SliderDiv key={data.id}>
                          <SafeImg src={data.white_logo} />
                        </SliderDiv>
                      </React.Fragment>
                    )
                )}
              </>
            )}
          </SliderItemsContainer>
        </SliderComponent>
      )}
    </MainContainer>
  );
};

export default BrandLogoCarousel;

const SliderDiv = styled.div`
  min-width: 18%;
  max-width: 18%;
  overflow: hidden;
  padding: 0 20px;
  @media ${TABLET_RESOLUTION} {
    max-width: 25%;
    min-width: 25%;
    overflow: hidden;
  }
  @media ${MOBILE_TABLET_RESOLUTION} {
    max-width: 50%;
    min-width: 139px;
    overflow: hidden;
  }
  text-align: center;
  & > img {
    height: 40px;
    width: auto;
    max-width: 100%;
    @media (max-width: 1025px) {
      height: 21px;
      margin-top: 10px;
    }
  }
`;

const BorderDiv = styled.div`
  border-right: 0.5px solid white;
  height: 40px;
  margin-top: 3px;
`;

const MainContainer = styled.div<{ isLoggedIn: boolean }>`
  overflow: hidden;
  width: auto;
  position: static;
  margin-top: -5px;

  background: black;
  padding: 15px 5px;
  @media (max-width: 1025px) {
    padding: 0px 5px;
  }
  @media ${MOBILE_RESOLUTION} {
    padding: ${({ isLoggedIn }) => (isLoggedIn ? '0' : '18px 0 8px 0')};
  }
`;

const StyledKeyFrames = (itemLength: number, locale: string) => keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
  
  100% {
    
    transform: translate3d(${
      locale === 'en' ? -18 * itemLength : 18 * itemLength
    }%, 0, 0);
  }
`;

const StyledMobileKeyFrames = (itemLength: number, locale: string) => keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
 
  100% {
    transform:  translate3d(${
      locale === 'en' ? -139 * itemLength : 139 * itemLength
    }px, 0, 0);
  }
`;

const StyledTabKeyFrames = (itemLength: number, locale: string) => keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
 
  100% {
    transform:  translate3d(${
      locale === 'en' ? -25 * itemLength : 25 * itemLength
    }%, 0, 0);
  }
`;

const SliderItemsContainer = styled.div`
  display: flex;
`;

export const SliderComponent = styled.div<{
  itemLength: number;
  locale: string;
}>`
  animation: ${({ itemLength, locale }) => StyledKeyFrames(itemLength, locale)}
    ${({ itemLength }) => itemLength * 7}s linear infinite;
  @media ${TABLET_RESOLUTION} {
    animation: ${({ itemLength, locale }) =>
        StyledTabKeyFrames(itemLength, locale)}
      ${({ itemLength }) => itemLength * 7}s linear infinite;
  }
  @media ${MOBILE_TABLET_RESOLUTION} {
    animation: ${({ itemLength, locale }) =>
        StyledMobileKeyFrames(itemLength, locale)}
      ${({ itemLength }) => itemLength * 7}s linear infinite;
  }
`;
