import * as React from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { getRecentPropertyRequest } from 'store/actions/@client/userInfo.actions';
import { useSelector } from 'store/selectors';
import {
  recentPropertySelector,
  recentPropertyLoadingSelector,
} from 'store/selectors/@client/userInfo.selectors';

const useRecentProperty = () => {
  const dispatch = useDispatch();
  const recentProperty = useSelector(recentPropertySelector, shallowEqual);
  const isLoading = useSelector(recentPropertyLoadingSelector);

  const getRecentProperty = React.useCallback(
    () => dispatch(getRecentPropertyRequest()),
    [dispatch]
  );

  return { recentProperty, isLoading, getRecentProperty };
};

export default useRecentProperty;
