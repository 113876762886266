import { SupportedLanguage } from 'types/common.types';
import { AuthenticationAction } from 'store/actions/authentication.actions';
import { Authentication } from 'store/constants/authentication.constants';
import { AccountPayload } from 'store/types/authentication.types';

export interface AuthState extends Partial<AccountPayload> {
  loading: boolean;
  error?: string;
  locale?: SupportedLanguage;
}

const initialState: AuthState = {
  loading: false,
  error: undefined,
  locale: SupportedLanguage.ENGLISH,
};

const authenticationReducer = (
  state: AuthState = initialState,
  action: AuthenticationAction
): AuthState => {
  switch (action.type) {
    case Authentication.LOGIN_REQUEST:
    case Authentication.LOGIN_WITH_PROVIDER_REQUEST:
      return { ...state, loading: true };
    case Authentication.LOGIN_FULFILLED:
      return {
        ...state,
        ...(action.payload as AccountPayload),
        loading: false,
        error: undefined,
      };
    case Authentication.UPDATE_USER_INFO:
      return {
        ...state,
        ...(action.payload as AccountPayload),
        loading: false,
        error: undefined,
      };
    case Authentication.LOGIN_REJECTED:
      return {
        ...state,
        error: (action.payload as Error).message,
        loading: false,
      };
    case Authentication.RESET_ERROR:
      return { ...state, error: undefined };
    case Authentication.LOGOUT_REQUEST:
      return initialState;
    case Authentication.VERIFY_TOKEN_REQUEST:
      return { ...state, loading: true };
    case Authentication.VERIFY_TOKEN_SUCCESS:
      return {
        ...state,
        ...(action.payload as AccountPayload),
        loading: false,
        error: undefined,
      };
    case Authentication.REGISTER_REQUEST:
    case Authentication.ONBOARDING_REQUEST:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case Authentication.REGISTER_FULFILLED:
      return {
        ...state,
        ...(action.payload as AccountPayload),
        loading: false,
        error: undefined,
      };
    case Authentication.REGISTER_REJECTED:
      return {
        ...state,
        loading: false,
        error: (action.payload as Error).message,
      };
    case Authentication.PASSWORD_RESET_LINK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Authentication.PASSWORD_RESET_LINK_FULFILLED:
      return {
        ...state,
        loading: false,
      };
    case Authentication.PASSWORD_RESET_LINK_REJECTED:
      return {
        ...state,
        loading: false,
        error: (action.payload as Error).message,
      };
    case Authentication.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Authentication.RESET_PASSWORD_FULFILLED:
      return {
        ...state,
        loading: false,
      };
    case Authentication.RESET_PASSWORD_REJECTED:
      return {
        ...state,
        loading: false,
        error: (action.payload as Error).message,
      };
    case Authentication.SET_SUPPLIER_ID:
      return {
        ...state,
        supplierId: (action.payload as {
          supplier: string;
        }).supplier.toString(),
      };
    default:
      return state;
  }
};

export default authenticationReducer;
