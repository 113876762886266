import * as React from 'react';
import MuiBreadcrumbs from '@material-ui/core/Breadcrumbs';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import styled from 'styled-components';
import { Link, LinkProps } from 'react-router-dom';
import { ClientRouter } from 'router/routes';
import { MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';
import { userLogData } from 'mockData/userLogData';
import { LogsAPI } from 'api/methods';
import { ReactComponent as Arrow } from './Separator.svg';

const homeProps: LinkProps = {
  to: ClientRouter.APP,
  children: <FormattedMessage id={Message.BREADCRUMBS_HOME_BUTTON} />,
  onClick: () =>
    LogsAPI.postUserLogs({
      name: userLogData.eventName.backToHomePage,
      area: userLogData.eventArea.catalog,
      section: userLogData.eventSection.breadcrumbs,
      path: userLogData.eventPath.appProductsSubCategoryNamePath,
    }),
};

interface Props {
  links: LinkProps[];
  force?: boolean | true;
}

const Breadcrumbs: React.FC<Props> = ({ links, force }) => {
  const isMobile = useMediaQuery(MOBILE_TABLET_RESOLUTION);
  if (isMobile && !force) return null;

  return (
    <Container>
      {[homeProps, ...links].map((props, i) => (
        <CustomLink
          as={i === links.length ? 'span' : undefined}
          className="link"
          key={props.to as string}
          {...props}
        />
      ))}
    </Container>
  );
};

export default Breadcrumbs;

const CustomArrow = styled(Arrow)`
  transform: rotate(${({ theme }) => (theme.dir === 'rtl' ? 180 : 0)}deg);
`;

const Container = styled(MuiBreadcrumbs).attrs(({ theme }) => ({
  className: 'breadcrumbs',
  separator: <CustomArrow />,
  'aria-label': 'breadcrumb',
}))`
  & .MuiBreadcrumbs-separator {
    margin: 0 20px;
  }

  a.link {
    /* color: ${({ theme }) => theme.colors.black0}; */
    color: #696969;
    font-family: ${({ theme }) =>
      theme.dir === 'rtl' ? ' Assistant' : 'inherit'};
    font-weight: 400;
    text-decoration: none;
    font-size: 1.125rem;
    transition: all 0.3s;

    &:hover {
      text-decoration: underline;
      opacity: 0.7;
    }
  }

  span.link {
    color: #282929;
    font-family: ${({ theme }) =>
      theme.dir === 'rtl' ? 'Assistant' : 'Roboto'};
    font-weight: 400;
    font-size: 1.125rem;
    cursor: default;
  }
`;

const CustomLink = styled(Link)``;
