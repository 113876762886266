import {
  all,
  call,
  put,
  debounce,
  takeLatest,
  select,
} from 'redux-saga/effects';
import { showNotification } from 'store/actions/notifications.actions';
import { OrdersAPI, SuppliersAPI } from 'api/methods';
import * as Response from 'api/responses';
import SupplierUserDataType from 'store/constants/user-management/suppliers.constants';
import * as SuppliersActions from 'store/actions/user-management/suppliers.actions';
import * as selectors from 'store/selectors/suppliers.selector';
import * as Payload from 'store/types/user-management/suppliers.types';
import { newSortByMap, sortByMap } from 'utils/transform.utils';
import { removeEmptyValues, sleep } from 'utils/common.utils';
import { Supplier } from 'types/suppliers';
import { createSupplierInfo } from 'mockData/supplier';
import { formatDate, FILTER_DATE_FORMAT } from 'utils/dates.utils';

function* errorHandler(
  e: Error,
  type: SupplierUserDataType,
  rejectAction: (payload: {
    error: string;
  }) => { type: SupplierUserDataType; payload: { error: string } }
) {
  const error = e.message || 'ERROR: Cannot refresh Data source';
  yield put(showNotification({ key: type, message: error, severity: 'error' }));
  yield put(rejectAction({ error }));
}

const sortUsersMap = {
  email: 'email',
  country: 'country',
  phoneNumber: 'phone_number',
};

function* getUsersDataRequest({
  payload,
  type,
}: ReturnType<typeof SuppliersActions.getUsersDataRequest>) {
  try {
    const {
      page = 0,
      size = 10,
      search,
      countries,
      categories: category,
      sortBy,
      created_at__date__gte,
      created_at__date__lte,
    } = payload;
    const params = {
      ...(removeEmptyValues({
        page: page + 1,
        size,
        search,
        category,
        created_at__date__gte,
        created_at__date__lte,
        countries: (countries as { iso: string; name: string }[])?.map(
          country => country.iso
        ),
        ...newSortByMap<typeof sortUsersMap>(sortUsersMap, sortBy),
      }) as Payload.GetUsersDataRequestPayload),
      is_archived: false,
    };

    const {
      results: users,
      count,
    }: Payload.GetUsersDataResponsePayload = yield call(
      SuppliersAPI.getSuppliersUsers,
      { params }
    );
    const pageCount = Math.ceil(count / size);
    yield put(SuppliersActions.getUsersDataFulfilled({ users, pageCount }));
  } catch (e) {
    yield call(errorHandler, e, type, SuppliersActions.getUsersDataRejected);
  }
}

function* getUserDataRequest({
  payload,
  type,
}: ReturnType<typeof SuppliersActions.getUserDataRequest>) {
  try {
    const { userId } = payload;
    const suppliers: Record<string, Supplier> = yield select(
      selectors.allSuppliersUsersMap
    );
    const selectedSupplier: Supplier = yield call(
      SuppliersAPI.getSupplierInfo,
      userId
    );

    const [products, orders, statistics]: (
      | Payload.GetProductsDataResponsePayload
      | Payload.GetOrdersDataResponsePayload
      | Payload.SupplierStatisticsResponse
    )[] = yield all([
      call(SuppliersAPI.getSupplierProducts, {
        params: {
          size: 5,
          page: 1,
          supplier: userId,
          ordering: '-sold_quantity',
        },
      }),
      call(OrdersAPI.getOrders, {
        params: { size: 50, page: 1, supplier: userId },
      }),
      call(SuppliersAPI.getStatistics, userId),
    ]);
    const response: Response.Supplier = yield call(
      createSupplierInfo,
      selectedSupplier
    );
    const supplierInfo: Response.Supplier = {
      ...response,
      products: products as Payload.GetProductsDataResponsePayload,
      orders: orders as Payload.GetOrdersDataResponsePayload,
      statistics: statistics as Payload.SupplierStatisticsResponse,
    };

    const updatedSupplier: Supplier = { ...selectedSupplier, ...supplierInfo };
    const users = Object.values({ ...suppliers, [userId]: updatedSupplier });
    yield put(SuppliersActions.getUserDataFulfilled({ users }));
  } catch (e) {
    yield call(errorHandler, e, type, SuppliersActions.getUserDataRejected);
  }
}

const sortOrdersMap = {
  userName: 'user__full_name',
  shippingAddress: 'address',
};

function* getOrdersDataRequest({
  payload,
  type,
}: ReturnType<typeof SuppliersActions.getOrdersDataRequest>) {
  try {
    const {
      page = 0,
      size = 10,
      search,
      countries,
      sortBy,
      dateRange,
      status,
      userId,
    } = payload;
    const params = removeEmptyValues({
      page: page + 1,
      size,
      search,
      from_date: formatDate(dateRange?.startDate, FILTER_DATE_FORMAT, ''),
      to_date: formatDate(dateRange?.endDate, FILTER_DATE_FORMAT, ''),
      status,
      countries: countries?.map(country => country.iso),
      supplier: userId,
      ...newSortByMap<typeof sortOrdersMap>(sortOrdersMap, sortBy),
    }) as Payload.GetOrdersDataRequestPayload;
    const {
      results,
      count,
    }: Response.SupplierOrders = yield call(OrdersAPI.getOrders, { params });

    const pageCount = Math.ceil(count / size);
    const suppliers: Record<string, Supplier> = yield select(
      selectors.allSuppliersUsersMap
    );
    const orders = { results, pageCount };
    const supplier: Supplier = yield suppliers[userId] ??
      call(SuppliersAPI.getSupplierInfo, userId);

    const updatedSupplier: Supplier = { ...supplier, orders };
    const users = Object.values({
      ...suppliers,
      [supplier.id]: updatedSupplier,
    });
    yield put(SuppliersActions.getOrdersDataFulfilled({ users }));
  } catch (e) {
    yield call(errorHandler, e, type, SuppliersActions.getOrdersDataRejected);
  }
}

function* getFilesDataRequest({
  payload,
  type: actionType,
}: ReturnType<typeof SuppliersActions.getFilesDataRequest>) {
  try {
    const {
      page = 0,
      size = 10,
      search,
      sortBy,
      dateRange,
      type,
      userId,
    } = payload;
    // TODO: hold params and pass to API call
    removeEmptyValues({
      page: page + 1,
      size,
      search,
      from_date: formatDate(dateRange?.startDate, FILTER_DATE_FORMAT, ''),
      to_date: formatDate(dateRange?.endDate, FILTER_DATE_FORMAT, ''),
      type,
      ...sortByMap<typeof sortOrdersMap>(sortOrdersMap, sortBy),
    }) as Payload.GetFilesDataRequestPayload;
    // TODO: call API for File attachments
    const response: Response.Attachments = { results: [], count: 0 };
    const { results, count }: Response.Attachments = yield call(
      sleep,
      response
    );
    const pageCount = Math.ceil(count / size);
    const files = { results, pageCount };
    const suppliers: Record<string, Supplier> = yield select(
      selectors.allSuppliersUsersMap
    );
    const supplier: Supplier = yield suppliers[userId] ??
      call(SuppliersAPI.getSupplierInfo, userId);

    const updatedSupplier: Supplier = { ...supplier, files };
    const users = Object.values({
      ...suppliers,
      [supplier.id]: updatedSupplier,
    });
    yield put(SuppliersActions.getFilesDataFulfilled({ users }));
  } catch (e) {
    yield call(
      errorHandler,
      e,
      actionType,
      SuppliersActions.getFilesDataRejected
    );
  }
}
function* getSupplierProducts({
  payload,
  type: actionType,
}: ReturnType<typeof SuppliersActions.getProductsDataRequest>) {
  const productsMap = { name: 'name' };
  const {
    page = 0,
    size = 10,
    search,
    sortBy,
    dateRange,
    categories: category,
    subCategories: sub_category,
    supplier: userId,
    ordering,
    direction,
  } = payload;
  try {
    const params = removeEmptyValues({
      page: page + 1,
      size,
      search,
      from_date: formatDate(dateRange?.startDate, FILTER_DATE_FORMAT, ''),
      to_date: formatDate(dateRange?.endDate, FILTER_DATE_FORMAT, ''),
      category,
      sub_category,
      supplier: userId,
      ordering,
      direction,
      ...newSortByMap<typeof productsMap>(productsMap, sortBy),
    }) as Payload.GetProductsDataRequestPayload;

    const {
      results,
      count,
    }: Payload.GetProductsDataResponsePayload = yield call(
      SuppliersAPI.getSupplierProducts,
      { params }
    );
    const pageCount = Math.ceil(count / size);
    results.forEach((e, i) => {
      e.rank = (params.page - 1) * params.size + i + 1;
    });
    const suppliers: Record<string, Supplier> = yield select(
      selectors.allSuppliersUsersMap
    );
    const supplier: Supplier = yield suppliers[userId!] ??
      call(SuppliersAPI.getSupplierInfo, userId!);

    const users: Supplier[] = Object.values({
      ...suppliers,
      [userId!]: {
        ...supplier,
        id: userId ?? '',
        products: {
          results,
          pageCount,
        } as Payload.GetProductsDataFulfilledResponse,
      },
    });

    yield put(SuppliersActions.getProductsDataFulfilled({ users }));
  } catch (e) {
    yield call(
      errorHandler,
      e,
      actionType,
      SuppliersActions.getProductsDataRejected
    );
  }
}

function* suppliersSaga() {
  yield all([
    debounce(
      500,
      SupplierUserDataType.GET_USERS_DATA_REQUEST,
      getUsersDataRequest
    ),
    // Supplier database
    debounce(
      500,
      SupplierUserDataType.GET_PRODUCTS_DATA_REQUEST,
      getSupplierProducts
    ),
    takeLatest(SupplierUserDataType.GET_USER_DATA_REQUEST, getUserDataRequest),
    takeLatest(
      SupplierUserDataType.GET_ORDERS_DATA_REQUEST,
      getOrdersDataRequest
    ),
    takeLatest(
      SupplierUserDataType.GET_FILES_DATA_REQUEST,
      getFilesDataRequest
    ),
  ]);
}

export default suppliersSaga;
