import * as React from 'react';
import { StyleSheet, View, Text } from '@react-pdf/renderer';
import { NO_VALUE } from 'constants/common.constants';
import { HeaderItem, Row } from 'documents/components';
import { BaseUser } from 'types/common.types';
import { getUserName } from 'utils/string.utils';
import { Order } from 'types/orders';

interface Props {
  user?: BaseUser;
  order?: Order;
}

const styles = StyleSheet.create({
  label: {
    fontSize: 16,
    fontFamily: 'Roboto',
    fontWeight: 500,
    marginBottom: '8px',
  },
});

const nameStyles = StyleSheet.create({
  header: {
    width: '100%',
  },
  label: {
    fontSize: 16,
    fontFamily: 'Roboto',
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: '9px',
  },
});

const ClientInfo: React.FC<Props> = ({ user, order }) => {
  return (
    <View
      style={{
        paddingHorizontal: 48,
        width: '100%',
        marginBottom: '50px',
        marginTop: '33px',
      }}
    >
      <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
        <View>
          <HeaderItem
            direction="row"
            label="Order summary for :"
            itemStyles={styles}
          />
          <Text
            style={{
              fontSize: '16px',
              fontWeight: 700,
              fontFamily: 'Roboto',
              marginBottom: '10px',
            }}
          >
            {getUserName(user, NO_VALUE)}
          </Text>
          <Text
            style={{
              fontSize: '14px',
              fontWeight: 400,
              fontFamily: 'Roboto',
              marginBottom: '11px',
            }}
          >
            {user?.phoneNumber ?? NO_VALUE}
          </Text>
          <Text
            style={{
              fontSize: '14px',
              fontWeight: 400,
              fontFamily: 'Roboto',
              marginBottom: '10px',
            }}
          >
            {order?.shippingAddress?.country === 'IL'
              ? `${order?.shippingAddress?.street ?? ''},${
                  order?.shippingAddress?.city ?? ''
                },${order?.shippingAddress?.state ?? ''}${
                  order?.shippingAddress?.zipCode ?? ''
                }`
              : `${order?.shippingAddress?.address_line_1 ?? ''} ${
                  order?.shippingAddress?.address_line_2 ?? ''
                },${order?.shippingAddress?.city ?? ''},${
                  order?.shippingAddress?.state ?? ''
                }${order?.shippingAddress?.zipCode ?? ''}`}
          </Text>
        </View>
        <View style={{ width: '170px' }}>
          <HeaderItem
            direction="row"
            label="ORDER DETAILS"
            itemStyles={nameStyles}
          />
          <View
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: 'row',
              width: '100%',
              marginBottom: '10px',
            }}
          >
            <Text
              style={{
                fontWeight: 500,
                fontFamily: 'Roboto',
                fontSize: '12px',
              }}
            >
              ORDER ID:
            </Text>
            <Text
              style={{
                textAlign: 'right',
                fontWeight: 400,
                fontFamily: 'Roboto',
                fontSize: '12px',
              }}
            >
              #{order?.id ?? NO_VALUE}
            </Text>
          </View>
          <View
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: 'row',
              width: '100%',
              marginBottom: '10px',
            }}
          >
            <Text
              style={{
                fontWeight: 500,
                fontFamily: 'Roboto',
                fontSize: '12px',
              }}
            >
              DATE:
            </Text>
            <Text
              style={{
                textAlign: 'right',
                fontWeight: 400,
                fontFamily: 'Roboto',
                fontSize: '12px',
              }}
            >
              {order?.createdAt ?? NO_VALUE}
            </Text>
          </View>
          <View
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: 'row',
              width: '100%',
              marginBottom: '10px',
            }}
          >
            <Text
              style={{
                fontWeight: 500,
                fontFamily: 'Roboto',
                fontSize: '12px',
              }}
            >
              SHIPPING DATE:
            </Text>
            <Text
              style={{
                textAlign: 'right',
                fontWeight: 400,
                fontFamily: 'Roboto',
                fontSize: '12px',
              }}
            >
              {order?.shippingDate ?? NO_VALUE}
            </Text>
          </View>
        </View>
      </Row>
    </View>
  );
};

export default ClientInfo;
