import * as React from 'react';
import styled from 'styled-components';
import ListItem, { Item } from './ListItem';

interface Props {
  items: Item[];
  narrow?: true;
}

const NavList: React.FC<Props> = ({ items, ...itemProps }) => {
  return (
    <List>
      {items.map(item => (
        <ListItem key={item.label} {...itemProps} {...item} />
      ))}
    </List>
  );
};

export default NavList;

const List = styled.ul`
  list-style: none;
  top: 20px;
  position: relative;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0;
  width: 100%;
`;
