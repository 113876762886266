import { Message } from 'i18n';
import { IntlShape } from 'react-intl';
import {
  ClientRouter,
  ManagementRouter,
  SupplierDashBoardRoutes,
} from 'router/routes';
import { Property } from 'types/properties';
import { Tab } from 'utils/sidebar.utils';

type MobileTabs = Record<'left' | 'right', Tab[]> & { main: Tab };

// const homeButtonLink = (
//   isAuthenticated: boolean,
//   recentProperty: Property | null
// ) => {
// if (isAuthenticated && recentProperty?.status === PROPERTIES_STATUS.Published)
//   return ClientRouter.TOUR.replace(':propertyId', recentProperty?.id ?? '');
// if (isAuthenticated) return ClientRouter.PROPERTY_IN_PROGRESS;
// return ClientRouter.AUTH;
// };

const tabs = (
  route: string,
  isAuthenticated: boolean,
  recentProperty: Property | null,
  ids?: string,
  seller_supplier?: number,
  type?: string,
  intl?: IntlShape
): MobileTabs => ({
  left: [
    {
      to: ClientRouter.MY_ACCOUNT,
      icon: 'profile-mobile',
      exact: true,
      isActive: route.includes(ClientRouter.MY_ACCOUNT),
    },
    // {
    //   to: ClientRouter.SKINS,
    //   icon: 'discover',
    //   exact: true,
    //   isActive: route.includes(ClientRouter.SKINS),
    // },
    {
      to:
        (type === 'SELLER' &&
          (ids === '7' ||
            ids === '16' ||
            seller_supplier === 7 ||
            seller_supplier === 16)) ||
        (type === 'SUPPLIER' &&
          (ids === '7' ||
            ids === '16' ||
            seller_supplier === 7 ||
            seller_supplier === 16))
          ? ClientRouter.PROPERTIES_SELLER
          : ClientRouter.PROPERTIES,
      icon: 'buildings-outlined',
      exact: true,
      isActive: route.includes(ClientRouter.PROPERTIES),
      showBadge: true,
    },
  ],
  main: {
    to: ClientRouter.APP,
    icon: 'home-nav',
    exact: true,
    isMain: true,
  },
  right: [
    {
      to: route.includes(SupplierDashBoardRoutes.INFO)
        ? route
        : SupplierDashBoardRoutes.INFO,
      icon: 'supplier-shop',
      exact: true,
      public: false,
      viewBox: '0 0 24 24',
      isActive: route.includes(SupplierDashBoardRoutes.INFO),
      label: intl?.formatMessage({ id: Message.HOME_SUPPLIER_CMS_TITLE }),
    },
    {
      to: ManagementRouter.APP,
      icon: 'supplier-shop',
      exact: true,
      public: false,
      viewBox: '0 0 24 24',
      isActive: route.includes(ManagementRouter.APP),
      label: intl?.formatMessage({ id: Message.HOME_MANAGEMENT_TITLE }),
    },
    {
      to: route.includes(ClientRouter.CART) ? route : ClientRouter.CART,
      icon: 'shopping-cart',
      exact: true,
      isActive: route.includes(ClientRouter.CART),
      showBadge: true,
      public: true,
    },
    {
      to: route.includes(ClientRouter.PRODUCTS) ? route : ClientRouter.PRODUCTS,
      icon: 'furnitures',
      exact: true,
      isActive: route.includes(ClientRouter.PRODUCTS),
      public: true,
    },
  ],
});

export default tabs;
