import * as React from 'react';
import styled from 'styled-components';
import { NO_VALUE } from 'constants/common.constants';
import { ReactComponent as Icon } from './calendar.svg';

const CalendarIcon: React.FC<{ day: number | string }> = ({ day }) => {
  return (
    <Container>
      <span>{day === NO_VALUE ? 'X' : day}</span>
      <Icon />
    </Container>
  );
};

export default CalendarIcon;

const Container = styled.div`
  position: relative;
  margin: 0;
  padding: 0;
  ${({ theme }) => theme.utils.centerFlex};
  height: 52px;
  width: 52px;

  @media (max-width: 800px) {
    height: 32px;
    width: 32px;
  }

  & span {
    font-size: 1.375rem;
    font-weight: 400;
    color: black;
    position: relative;
    z-index: 1;
    top: 8px;
    user-select: none;

    @media (max-width: 800px) {
      font-size: 0.875rem;
      top: 4px;
      stroke-width: 2px;
    }
  }

  & svg {
    position: absolute;
    height: 52px;
    width: 52px;

    @media (max-width: 800px) {
      height: 30px;
      width: 30px;

      & path {
        stroke-width: 2px;
      }
    }
  }
`;
