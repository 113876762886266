import * as React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import * as deviceInfo from 'react-device-detect';
import Button from 'components/@client/Button';
import { ClientRouter } from 'router/routes';
import { useSession } from 'hooks';
import { useMediaQuery } from '@material-ui/core';
import {
  MOBILE_RESOLUTION,
  MOBILE_TABLET_RESOLUTION,
} from 'styles/sharedStyle';

interface Props {
  text: string;
  width: number;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const CTAButton: React.FC<Props> = ({ text, onClick, width }) => {
  const { isFromSupportedCountry } = useSession();
  const isMobile =
    useMediaQuery(MOBILE_TABLET_RESOLUTION) || deviceInfo.isMobile;
  return (
    <CustomLink to={ClientRouter.APP}>
      <HeaderButton
        disabled={!isFromSupportedCountry}
        onClick={onClick}
        width={width}
        fontSize={isMobile ? 1.5 : 1.8}
      >
        <FormattedMessage id={text} />
      </HeaderButton>
    </CustomLink>
  );
};

export default CTAButton;

const HeaderButton = styled(Button).attrs({ className: 'btn-header' })<{
  width: number;
  fontSize: number;
}>`
  && {
    border-radius: 0px;
    font-weight: 700;
    padding: 0 20px;
    font-size: ${({ fontSize }) => `${fontSize}rem`};
    border-radius: 25px;
    height: 70px;

    width: ${({ width }) => width}px;

    @media ${MOBILE_RESOLUTION} {
      width: 330px;
    }
  }
  && .btn-header {
    background-color: green;
  }
`;

const CustomLink = styled(Link).attrs(({ to }) => ({
  as: to === ClientRouter.APP ? 'span' : undefined,
}))`
  text-decoration: none;
  cursor: ${({ to }) => (to === ClientRouter.APP ? 'default' : 'pointer')};
`;
