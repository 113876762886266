import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useSession, useValidateUser } from 'hooks';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import * as Form from 'components/forms';
import { Message } from 'i18n';
import { ClientRouter } from 'router/routes';
import Icon from 'components/Icon/Icon';

const LinkSent = () => {
  const intl = useIntl();
  const { isAuthenticated } = useValidateUser();
  const { getLocation, country } = useSession();

  React.useEffect(() => {
    if (!country) getLocation();
  }, [country, getLocation]);

  if (isAuthenticated) return <Redirect to={ClientRouter.APP} />;

  return (
    <Container
      title={intl.formatMessage(
        { id: Message.AUTH_RESET_LINK_SENT_TITLE },
        { successIcon: <SuccessIcon /> }
      )}
      info={intl.formatMessage(
        { id: Message.AUTH_RESET_LINK_SENT_INFO },
        { linebreak: <br /> }
      )}
    />
  );
};

export default LinkSent;

const Container = styled(Form.Container).attrs({
  className: 'register-form-container',
})`
  && .auth-form-content {
    ${rtl`
      left: 0;
    `}
    height: 100vh;
    max-height: unset;

    @media (max-width: 1500px) {
      width: 535px;
      padding: 23px 40px;
    }

    @media (min-width: 1500px) {
      width: 623px;
      padding: 23px 28px;
    }
    @media (max-width: 500px) {
      width: 395px;
    }
  }

  && .auth-form-header {
    margin-bottom: 29px;
    flex-direction: row;
    align-items: flex-end;
    gap: 100px;

    @media (max-width: 500px) {
      margin-bottom: 29px;
      gap: 25px;
    }

    @media (min-width: 1500px) {
      margin-bottom: 29px;
      gap: 95px;
    }

    & .logo {
      @media (max-width: 500px) {
        align-self: center;
        height: 40px;
      }
      align-self: flex-start;
      @media (max-width: 1500px) {
        height: 50px;
      }
      height: 77px;
    }
  }
  && .auth-header-title {
    @media (max-width: 500px) {
      font-size: 1.25rem;
      font-weight: bold;
    }
    font-size: 1.25rem;
    @media (min-width: 1500px) {
      font-size: 1.75rem;
    }
  }

  && .main-button {
    width: 100%;
    min-height: 48px;
    text-transform: none;
    margin-top: 75px;
  }

  && .MuiFormControl-root {
    @media (min-width: 500px) {
      width: 218px;
    }

    @media (min-width: 1500px) {
      width: 270px;
    }
  }
  & .register-fields {
    margin-top: 30px;
    row-gap: 14px;
    column-gap: 22px;

    @media (max-width: 1500px) {
      gap: 19px;
    }
    @media (max-width: 500px) {
      gap: 28px;
    }
  }
`;

const SuccessIcon = styled(Icon).attrs({
  name: 'checkmark-circle',
  color: '#1DBF87',
  size: 24,
})`
  position: relative;
  ${rtl`
    left: 3px;
  `}
  top: 2px;
`;
