import * as React from 'react';
import styled, { css } from 'styled-components';
import rtl from 'styled-components-rtl';
import { CustomArrow } from 'components/Button/ArrowButton';
import CountPlaceholder from './CountPlaceholder';

interface Props {
  disabled?: boolean;
  onClick: (event: React.SyntheticEvent<HTMLDivElement>) => void;
  placeholder?: string;
  displayPlaceholder: boolean;
  selectedItems?: (string | React.ReactNode)[];
  selectedPlaceholder?: string;
  isOpen?: boolean;
  disableCounter?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  isHideEndIcon?: boolean;
  responsive?: boolean;
  active?: boolean;
  width?: number | string;
  height?: number;
  className?: string;
}

const InputButton: React.FC<Props> = ({
  disabled,
  onClick,
  placeholder = 'Select',
  displayPlaceholder,
  selectedItems = [],
  isOpen,
  disableCounter,
  startIcon,
  endIcon = <Arrow open={isOpen} />,
  isHideEndIcon,
  responsive = false,
  active = false,
  width = 230,
  height = 48,
  selectedPlaceholder,
  className,
}) => {
  const handleOnClick = React.useCallback(
    (e: React.SyntheticEvent<HTMLDivElement>) => {
      if (!disabled) {
        onClick(e);
      }
    },
    [disabled, onClick]
  );

  return (
    <Container
      $disabled={disabled}
      $isOpen={isOpen}
      $responsive={responsive}
      $active={active}
      $width={width}
      $height={height}
      tabIndex={0}
      role="button"
      onKeyPress={onClick}
      onClick={handleOnClick}
      className={className}
    >
      <LeftSection className="dropdown-placeholder">
        {(selectedItems.length === 0 || isHideEndIcon) && startIcon}
        {displayPlaceholder && placeholder}
        {disableCounter ? (
          selectedItems?.[0]
        ) : (
          <CountPlaceholder
            count={selectedItems.length}
            label={selectedPlaceholder}
          />
        )}
      </LeftSection>
      {!isHideEndIcon && endIcon}
    </Container>
  );
};

export default React.memo(InputButton);

const responsiveActiveFill = css`
  outline: 1px solid transparent;
  border: 1px solid transparent;
  background-color: #ffeae5;

  & svg {
    fill: ${({ theme }) => theme.colors.primary.light};
  }
`;

const Container = styled.div<{
  $disabled?: boolean;
  $isOpen?: boolean;
  $responsive: boolean;
  $active: boolean;
  $width?: number | string;
  $height?: number;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 400;
  height: ${({ $height }) => $height}px !important;
  width: ${({ $width }) => {
    if (typeof $width === 'string') return $width;
    if ($width) return `${$width}px`;
    return 'inherit';
  }};
  border-radius: 8px;
  border: 1px solid;
  border-color: ${({ $isOpen, theme }) =>
    $isOpen ? theme.colors.primary.dark : theme.colors.darkGray};
  color: ${({ $active, theme }) =>
    $active ? theme.colors.primary.dark : theme.colors.gray};
  padding: 0 10px;
  user-select: none;
  background-color: white;

  &:focus,
  &:hover {
    outline: 1px solid rgba(255, 255, 255, 0.3);
    border: 1px solid ${({ theme }) => theme.colors.primary.dark};
  }

  background: ${({ $disabled, theme }) => $disabled && theme.colors.darkGray};
  border-color: ${({ $disabled }) => $disabled && '#e8e8e8'};

  ${({ $responsive, $active, theme, $isOpen }) =>
    $responsive &&
    css`
      @media (max-width: 1500px) {
        width: 48px;
        justify-content: center;
        border: 1px solid ${$active ? theme.colors.primary.dark : 'inherit'};
        font-size: 0;
        min-width: 48px;
        cursor: pointer;

        ${$isOpen && responsiveActiveFill}

        ${$active &&
        `&::before {
          content: '';
          position: absolute;
          border: 1px solid white;
          border-radius: 50%;
          background: ${theme.colors.primary.light};
          top: -2px;
          ${rtl`
            right: -4px;
          `}
          height: 12px;
          width: 12px;
        }`}
      }
    `}
`;

const Arrow = styled(CustomArrow)`
  position: absolute;
  ${rtl`
    right: 0;
  `}
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: 1500px) {
    gap: 5px;
  }
`;
