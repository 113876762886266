import * as actions from 'store/actions/quiz-management/quizManagement.actions';
import QuizzesDataType from 'store/constants/quiz-management/quizManagement.constants';
import * as Payload from 'store/types/quizManagement.types';
import { Quiz } from 'types/quizzes';

export interface QuizzesState {
  loading: Record<QuizzesDataType, boolean>;
  error: Record<QuizzesDataType, null | string>;
  quizzes: Quiz[];
  pageCount: number;
}

const REQUESTS = [QuizzesDataType.GET_QUIZZES_DATA_REQUEST];

const initialState: QuizzesState = {
  loading: REQUESTS.reduce(
    (state, next) => ({ ...state, [next]: false }),
    {} as Record<QuizzesDataType, boolean>
  ),
  error: REQUESTS.reduce(
    (state, next) => ({ ...state, [next]: null }),
    {} as Record<QuizzesDataType, null | string>
  ),
  quizzes: [],
  pageCount: 0,
};

const quizzesReducer = (
  state: QuizzesState = initialState,
  action: actions.QuizzesAction
): QuizzesState => {
  switch (action.type) {
    case QuizzesDataType.GET_QUIZZES_DATA_REQUEST:
      return {
        ...updateLoading(state, QuizzesDataType.GET_QUIZZES_DATA_REQUEST),
      };
    case QuizzesDataType.GET_QUIZZES_DATA_FULFILLED:
      return {
        ...updateSuccess(
          state,
          QuizzesDataType.GET_QUIZZES_DATA_REQUEST,
          action.payload as Payload.GetQuizzesFulfilledPayload
        ),
      };
    case QuizzesDataType.GET_QUIZZES_DATA_REJECTED:
      return {
        ...updateError(
          state,
          QuizzesDataType.GET_QUIZZES_DATA_REQUEST,
          action.payload as Payload.GetQuizzesRejectedPayload
        ),
      };
    default:
      return state;
  }
};

export default quizzesReducer;

type requestType = QuizzesDataType.GET_QUIZZES_DATA_REQUEST;

const updateLoading = (state: QuizzesState, requestType: requestType) => ({
  ...state,
  loading: {
    ...state.loading,
    [requestType]: true,
  },
  error: {
    ...state.error,
    [requestType]: null,
  },
});

type ErrorPayload = Payload.GetQuizzesRejectedPayload;
const updateError = (
  state: QuizzesState,
  requestType: requestType,
  { error }: ErrorPayload
) => ({
  ...state,
  loading: {
    ...state.loading,
    [requestType]: false,
  },
  error: {
    ...state.error,
    [requestType]: error,
  },
});

type SuccessPayload = Payload.GetQuizzesFulfilledPayload;
const updateSuccess = (
  state: QuizzesState,
  requestType: requestType,
  payload: SuccessPayload
) => ({
  ...state,
  loading: {
    ...state.loading,
    [requestType]: false,
  },
  error: {
    ...state.error,
    [requestType]: null,
  },
  ...payload,
});
