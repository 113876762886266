import * as React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { Link, LinkProps } from 'react-router-dom';
import { useFetch } from 'hooks';
import { ClientRouter } from 'router/routes';
import { METHODS } from 'api/client';
import * as Transform from 'api/transform';
import { Product } from 'types/products';
import SafeImg from 'components/SafeImg';
import { SubCategory } from 'types/common.types';
import Loader from 'components/Loader';
import { useIntl } from 'react-intl';
import { Message } from 'i18n';
import NoProduct from 'components/@client/NoProduct/NoProduct';
import rtl from 'styled-components-rtl';
import Breadcrumbs from 'components/@client/Breadcrumbs/Breadcrumbs';
import CoverSection from 'components/@client/CoverSection/CoverSection';
import { LogsAPI } from 'api/methods';
import { BEST_SELLER_LIST } from 'constants/common.constants';
import BestSellerCard from './BestSellerCard';

interface Props {
  title: string;
  subCategory: Array<SubCategory> | undefined;
  links: LinkProps[];
}

const ProductsCategoryPage: React.FC<Props> = ({
  title,
  subCategory,
  links,
}) => {
  const intl = useIntl();
  const { data: bestSellers, callFetch, isLoading } = useFetch({
    initialUrl: `api/products/`,
    config: {
      method: METHODS.GET,

      params: { category: title, page: 1, size: 12, ordering: '?' },
    },
    skipOnStart: true,
    transform: Transform.PaginatedProducts,
  });

  React.useEffect(() => {
    LogsAPI.pushDataLayer([
      { ecommerce: null },
      {
        ecommerce: {
          impressions: bestSellers?.results.map((r, i) => ({
            name: r.name,
            id: r.id,
            price: r.price,
            brand: r.manufacture.name,
            category: r.category,
            list: BEST_SELLER_LIST,
            position: i + 1,
          })),
        },
      },
    ]);
  }, [bestSellers]);

  React.useEffect(() => {
    callFetch({
      params: { category: title, page: 1, size: 12, ordering: '?' },
    });
  }, [title, callFetch]);
  return (
    <Container>
      <CoverSection
        imageUrl={subCategory && subCategory[0]?.category?.cover_photo_web}
      />
      <BreadcrumbsContainer>
        <Breadcrumbs links={links} />
      </BreadcrumbsContainer>
      <CategoryText>{title}</CategoryText>
      <Grid container>
        {(subCategory &&
          subCategory.map(data => (
            <Grid item md={4} sm={6} key={data.name}>
              <StyledLink
                to={ClientRouter.PRODUCT_SUB_CATEGORY.replace(
                  ':catName',
                  title
                ).replace(
                  ':subCatName',
                  (intl.locale === 'he' && data.alt_name) || data.name
                )}
              >
                <Card>
                  <Img>
                    <SafeImg src={data.thumbnail} alt={data.name} />
                  </Img>
                  <div>
                    <h4>
                      {(intl.locale === 'he' && data.alt_name) || data.name}
                    </h4>
                  </div>
                </Card>
              </StyledLink>
            </Grid>
          ))) || <NoProduct value={Message.NO_SUB_CATEGORY} />}
      </Grid>
      <CategoryText>{`${intl.formatMessage({
        id: Message.SHOP_BEST_SELLERS,
      })} ${title}`}</CategoryText>
      {isLoading ? (
        <Loader />
      ) : (
        <Grid container>
          {bestSellers?.results.length ? (
            bestSellers.results.map((data: Product) => (
              <Grid item md={3} sm={3} key={data.name}>
                <BestSellerCard product={data} />
              </Grid>
            ))
          ) : (
            <NoProduct value={Message.NO_PRODUCT_AVAILABLE} />
          )}
        </Grid>
      )}
    </Container>
  );
};

export default ProductsCategoryPage;

const Container = styled.div`
  width: 100%;
`;

const BreadcrumbsContainer = styled.div`
  margin: 20px 16px 0px;
`;

export const CategoryText = styled.div`
  padding: 15px;
  font-family: ${({ theme }) => (theme.dir === 'rtl' ? 'Assistant' : 'Roboto')};
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 174.3%;
`;

export const Card = styled.div`
  cursor: pointer;
  min-height: 155px;
  max-width: 355px;
  margin: 15px 18px;
  align-content: center;
  display: flex;
  background: #fafafa;
  border-radius: 25px;
  text-decoration: none;
  > div {
    height: 100%;
    ${rtl`
      margin: auto auto auto 10px;
    `};
  }
  h4 {
    font-family: ${({ theme }) =>
      theme.dir === 'rtl' ? 'Assistant' : 'Roboto'};
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 128.91%;
  }
  &: hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
`;

export const Img = styled.div`
  display: flex;
  & img {
    max-width: 118px;
    max-height: 100px;
    border-radius: 14px;
  }
`;

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`;
