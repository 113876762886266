import { ListItem } from 'components/Autocomplete';
import styled from 'styled-components';
import { textTruncate } from 'styles/sharedStyle';
import type { AutocompleteRenderOptionState } from '@material-ui/core/Autocomplete';
import type { Option } from 'components/Autocomplete/Autocomplete.config';

const OrderSearchOption = (
  props: React.HTMLAttributes<HTMLLIElement>,
  option: Option,
  { inputValue }: AutocompleteRenderOptionState
) => {
  const entries = Object.entries(option as object)?.filter(([_, val]) =>
    val?.toString?.()?.includes(inputValue)
  )?.[0];
  return (
    <ListItem key={option?.name} tabIndex={0} {...props}>
      <div style={{ maxWidth: 100 }}>
        {entries?.[0] ? (
          <Label>
            <strong>{entries[0]}: </strong> {entries[1]}
          </Label>
        ) : (
          <Label>{option?.id}</Label>
        )}
      </div>
    </ListItem>
  );
};

export default OrderSearchOption;

const Label = styled.strong`
  ${textTruncate}
`;
