import { MenuProps } from '@material-ui/core/Menu';
import sub from 'date-fns/sub';

import { DefinedRange } from './types';

const createDefinedRange = (
  label: string,
  duration: Duration
): DefinedRange => ({
  label,
  startDate: sub(new Date(), duration),
  endDate: new Date(),
});

export const getDefaultRanges = () => [
  createDefinedRange('Last day', { days: 1 }),
  createDefinedRange('Last 7 days', { days: 7 }),
  createDefinedRange('Last 30 days', { days: 30 }),
  createDefinedRange('Last year', { years: 1 }),
];

export const defaultMinDate = sub(new Date(), { years: 10 });

export const defaultYearsShown = 10;

export const defaultRanges = getDefaultRanges();

export const defaultMenuProps: Partial<MenuProps> = { disablePortal: true };
