import PropertiesType from 'store/constants/properties-management/PropertiesManagement.constants';
import * as Payload from 'store/types/properties-management/PublishedProperties.types';

export const getPublishedPropertiesTasksDataRequest = (
  payload: Payload.GetPublishedPropertiesTasksDataRequestPayload
) => ({
  type: PropertiesType.GET_PUBLISHED_PROPERTIES_DATA_REQUEST,
  payload,
});

export const getPublishedPropertiesTasksDataFulfilled = (
  payload: Payload.GetPublishedPropertiesTasksFulfilledPayload
) => ({
  type: PropertiesType.GET_PUBLISHED_PROPERTIES_DATA_FULFILLED,
  payload,
});

export const getPublishedPropertiesTasksDataRejected = (
  payload: Payload.GetPublishedPropertiesTasksRejectedPayload
) => ({
  type: PropertiesType.GET_PUBLISHED_PROPERTIES_DATA_REJECTED,
  payload,
});

export type PropertiesTasksAction = ReturnType<
  | typeof getPublishedPropertiesTasksDataRequest
  | typeof getPublishedPropertiesTasksDataFulfilled
  | typeof getPublishedPropertiesTasksDataRejected
>;
