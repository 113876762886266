import * as React from 'react';
import { ListItem } from 'components/Autocomplete';
import CheckboxItem from 'components/Checkbox/CheckboxItem';
import type { Option } from 'components/Autocomplete/Autocomplete.config';

const SubCategoryOptionRender = (
  values: Option[] = [],
  optionTextLimit = 13,
  isMultiple = true
) => (props: React.HTMLAttributes<HTMLLIElement>, newValue: Option) => {
  const isShowToolTip = (name?: string) => name?.length! > optionTextLimit;

  return (
    <ListItem key={newValue?.id} tabIndex={0} {...props}>
      {isMultiple ? (
        <CheckboxItem
          title={
            isShowToolTip(newValue?.name as string)
              ? newValue?.name.toString()
              : ''
          }
          value={newValue?.name}
          checked={values.some(item => item?.name === newValue?.name)}
        />
      ) : (
        <>{newValue?.name}</>
      )}
    </ListItem>
  );
};

export default SubCategoryOptionRender;
