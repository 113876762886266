import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import Card from 'components/@client/CardList/Card';
import Tooltip from 'components/Tooltip';
import { useFormattedCurrency } from 'hooks';
import { Message } from 'i18n';
import type { CartItem as ICartItem } from 'types/properties';
import { summarizeText } from 'utils/string.utils';
import { MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';
import { LogsAPI } from 'api/methods';
import { userLogData } from 'mockData/userLogData';

interface Props extends ICartItem {
  dense?: boolean;
  isLoading?: boolean;
  nameLength?: number;
  onQuantityChange?: (id: string, quantity: number) => void;
  onDelete?: (id: string) => void;
}

const CheckoutCartItem: React.FC<Props> = ({
  dense,
  id,
  isLoading,
  nameLength = 15,
  quantity,
  checkoutQuantity,
  product,
  onDelete,
  onQuantityChange,
}) => {
  const price = useFormattedCurrency(product?.finalPrice);
  const qty = checkoutQuantity < quantity ? checkoutQuantity : quantity;

  return (
    <Container dense={dense}>
      <CustomCard
        img={product.thumbnail?.file}
        alt={product.altName ?? product.englishName}
        overlay={
          product.quantity === 0 && (
            <FormattedMessage id={Message.CART_ITEM_OUT_OF_STOCK_LABEL} />
          )
        }
        tabIndex={-1}
        onClick={() => {
          LogsAPI.postUserLogs({
            name: userLogData.eventName.productClick,
            area: userLogData.eventArea.myCart,
            section: userLogData.eventSection.cart,
            path: userLogData.eventPath.appCartCartIdPath,
            metadata: { cart_id: id, product_sku: product.id },
          });
        }}
      >
        <div>
          <Tooltip
            title={product?.altName ?? product?.englishName}
            $variant="info"
          >
            <h4>
              {summarizeText(
                product?.altName ?? product?.englishName,
                nameLength
              )}
            </h4>
          </Tooltip>

          <p className="subtitle-data">{product.subCategory.name}</p>
          <p className="cart-item-price">{`${qty} x ${price}`}</p>
        </div>
      </CustomCard>
    </Container>
  );
};

export default CheckoutCartItem;

const Container = styled.li.attrs({ className: 'cart-item' })<{
  dense?: boolean;
}>`
  position: relative;

  & img {
    height: 155px;
    width: 155px;
    object-fit: contain;
    @media ${MOBILE_TABLET_RESOLUTION} {
      height: 123px;
      width: 119px;
    }
  }
  & .cart-item-actions {
    margin-top: 14px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  & h4 {
    color: ${({ theme }) => theme.colors.black0};
    font-size: 1.125rem;
    font-weight: 500;
    margin: 0;
    padding: 0;

    @media (min-width: 1150px) {
      font-size: 1.5rem;
    }
  }

  & p {
    font-weight: 500;
    font-size: 0.875rem;
    margin: 4px 0 0 0;
    padding: 0;

    @media (min-width: 1150px) {
      font-size: 1.375rem;
    }
  }

  & .card-divider {
    max-width: initial;
  }

  & .subtitle-data {
    font-weight: 400;
    font-size: 0.675rem;
    margin: 4px 0 0 0;
    padding: 0;

    @media (min-width: 1150px) {
      font-size: 1.175rem;
    }
  }

  & .design-style {
    color: rgba(187, 187, 187, 1);
    font-weight: 400;

    @media (min-width: 1150px) {
      font-size: 1.125rem;
    }
  }

  & .x-button {
    height: 30px;
    width: 30px;
    border: 1px solid black;
    border-radius: 20px;
    margin: auto;
  }

  & div {
    max-width: initial;
    box-shadow: none;
    padding: 0 15px;
    .card-content {
      width: 100%;
      padding: 0;
      margin: 0;
      align-self: self-start;

      div {
        padding: 0 10px;
        h4,
        p {
          line-height: 1.5;
        }
      }
    }
  }

  ${({ dense }) =>
    dense &&
    css`
      margin: 0 50px;
      max-width: 370px;
      h4,
      p {
        font-size: 1rem !important;
      }

      img {
        width: 120px;
        height: 120px;
      }
    `}
`;

const CustomCard = styled(Card)`
  width: 100%;
  max-width: initial !important;

  box-shadow: none !important;
`;
