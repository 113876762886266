import * as React from 'react';
import styled from 'styled-components';
import { useImageGuard } from 'hooks';

interface Props {
  photo: string;
  alt: string;
  selected: boolean;
  onClick: VoidFunction;
}

const Thumbnail: React.FC<Props> = ({ alt, photo, onClick, selected }) => {
  const { src, hasError, onError } = useImageGuard(photo);
  if (hasError) return null;
  return (
    <li>
      <Container
        role="button"
        tabIndex={0}
        onError={onError}
        selected={selected}
        onClick={onClick}
        onKeyPress={onClick}
      >
        <img src={src} alt={alt} className="thumbnail-image" />
      </Container>
    </li>
  );
};

export default Thumbnail;

export const Photo = styled.div`
  display: flex;
  align-items: center;
  height: auto;

  & > div > div {
    display: flex;
  }

  & img {
    /* margin: auto; */
    max-width: 100%;
    max-height: 400px;
    border-radius: 12px;
    height: 100%;
    object-fit: contain;

    @media (max-width: 1400px) {
      height: 600px;
    }
    @media (max-width: 1200px) {
      height: 500px;
    }
    @media (max-width: 1000px) {
      height: 450px;
    }
    @media (max-width: 900px) {
      height: 400px;
    }
  }
`;

const Container = styled(Photo)<{ selected: boolean }>`
  width: 42px;
  height: 43px;
  cursor: pointer;
  border-radius: 8px;
  padding: 2px;
  border: ${({ selected, theme }) =>
    `1px solid ${selected ? '#fc5c42' : 'transparent'}`};
  transition: border 0.1s;

  & img {
    width: 100%;
    height: 100%;
  }

  && .thumbnail-image {
    border-radius: 5px;
    padding: 0;
    object-fit: contain;
  }

  @media (min-width: 1500px) {
    width: 68px;
    height: 69px;
  }

  &:focus {
    outline: 0;
    border: ${({ theme }) => `1px solid #fc5c42`};
  }
`;
