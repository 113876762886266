import * as React from 'react';
import LoginWelcomeImage from 'assets/@client/supplier-login-flow/supplier-login-welcome.png';
import styled from 'styled-components';
import { Message } from 'i18n';
import { FormattedMessage } from 'react-intl';
import { useCategories, useRouter } from 'hooks';
import Icon from 'components/Icon';
import {
  SupplierOnboardingAction,
  useSupplierOnboardingDispatch,
} from 'context/supplierOnboardingContext';
import { ClientRouter } from 'router/routes';
import {
  MOBILE_TABLET_MIN_RESOLUTION,
  MOBILE_TABLET_RESOLUTION,
} from 'styles/sharedStyle';

const SelectCategoryMobile: React.FC = () => {
  const { Categories: categoryOptions = [] } = useCategories();
  const dispatch = useSupplierOnboardingDispatch();
  const { history } = useRouter();
  const [selectedCategories, setSelectedCategories] = React.useState<
    Array<number>
  >([]);
  return (
    <ParentDiv LoginWelcomeImage={LoginWelcomeImage}>
      <TransparentDiv>
        <Title>
          <FormattedMessage id={Message.SUPPLIER_WHAT_DO_YOU_SELL} />
        </Title>
        <SubTitle>
          <FormattedMessage id={Message.SUPPLIER_SELECT_CATEGORY} />
        </SubTitle>
        <CategoryContainer>
          <MainContainer>
            <Categories>
              {!categoryOptions && <CustomIcon name="spinner" size={30} />}
              {categoryOptions?.length &&
                categoryOptions.map(category => {
                  return (
                    <CategoryBox
                      key={category.id}
                      onClick={() => {
                        if (selectedCategories.includes(category.id))
                          setSelectedCategories(prev =>
                            prev.filter(data => data !== category.id)
                          );
                        else
                          setSelectedCategories(prev => [...prev, category.id]);
                      }}
                    >
                      <StyledCheckbox
                        type="checkbox"
                        checked={selectedCategories.includes(category.id)}
                        onChange={e => {
                          if (e.target.checked)
                            setSelectedCategories(prev => [
                              ...prev,
                              category.id,
                            ]);
                          else
                            setSelectedCategories(prev =>
                              prev.filter(data => data !== category.id)
                            );
                        }}
                      />
                      <ImageParent>
                        <CategoryImage
                          checked={selectedCategories.includes(category.id)}
                          src={category.icon}
                          alt="category"
                        />
                      </ImageParent>
                      <CategoryName
                        checked={selectedCategories.includes(category.id)}
                      >
                        {category.name}
                      </CategoryName>
                    </CategoryBox>
                  );
                })}
            </Categories>
            <NextButton
              onClick={() => {
                dispatch({
                  type: SupplierOnboardingAction.SET_SELECTED_CATEGORIES,
                  payload: selectedCategories,
                });
                history.push(ClientRouter.SUPPLIER_LOGIN_THANKS);
              }}
              disabled={!selectedCategories.length}
            >
              <NextText>
                <FormattedMessage
                  id={Message.USER_DELETE_ACCOUNT_NEXT_BUTTON}
                />
              </NextText>
            </NextButton>
          </MainContainer>
        </CategoryContainer>
      </TransparentDiv>
    </ParentDiv>
  );
};

export default SelectCategoryMobile;

const ParentDiv = styled.div<{ LoginWelcomeImage: string }>`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto Condensed';
  ::before {
    background-image: url(${LoginWelcomeImage});
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.6;
    background-position: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    content: '';
  }
`;

const TransparentDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(250, 250, 250, 0.8);
  border-radius: 20px;
  position: relative;
  flex-direction: column;
  padding: 16px 8px;
  width: 93vw;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    width: 736px;
    height: 970px;
  }
`;

const Title = styled.div`
  color: #282929;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    font-size: 40px;
    line-height: 44px;
  }
`;

const SubTitle = styled.div`
  font-size: 18px;
  line-height: 22px;
  color: #3e3e3e;
  margin-top: 4px;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    font-size: 24px;
    line-height: 28px;
    margin-top: 8px;
  }
`;

const CategoryContainer = styled.div`
  margin-top: 16px;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    margin-top: 32px;
  }
`;

const MainContainer = styled.div``;

const CategoryBox = styled.div`
  background: #fafcfe;
  height: 146px;
  width: 156px;
  border: 1px solid #e8eaeb;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    height: 174px;
    width: 184px;
  }
`;

const Categories = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
  height: 650px;
  overflow-y: overlay;
  ${({ theme }) => theme.utils.scrollbar};
  &::-webkit-scrollbar-thumb {
    background-color: #11516d;
  }
  &::-webkit-scrollbar {
    width: 4px;
  }
`;

const StyledCheckbox = styled.input`
  height: 16px;
  width: 16px;
  margin: 8px 0 0 8px;
  position: absolute;
  cursor: pointer;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    height: 20px;
    width: 20px;
  }
  :checked {
    accent-color: #11516d;
  }
`;

const NextButton = styled.button`
  :disabled {
    cursor: default;
    background: #d9d9d9;
  }
  cursor: pointer;
  background: #fc5c42;
  border: 0.5px solid #d9d9d9;
  border-radius: 15px;
  padding: 9px 48px;
  width: 134px;
  height: 40px;
  display: flex;
  margin: 16px auto 0 auto;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    height: 48px;
    padding: 13px 48px;
    margin: 32px auto 0 auto;
  }
`;

const NextText = styled.div`
  color: rgba(255, 255, 255, 0.9);
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    font-size: 18px;
    line-height: 21px;
  }
`;

const ImageParent = styled.div`
  height: 74.61px;
  width: 74.61px;
  margin: 30.35px 40.7px 15.04px 40.7px;
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    height: 88px;
    width: 88px;
    margin: 24px 48px 16px 48px;
  }
`;
const CategoryImage = styled.img<{ checked: boolean }>`
  height: 100%;
  width: 100%;
  opacity: ${({ checked }) => (checked ? 0.5 : 1)};
`;

const CategoryName = styled.div<{ checked: boolean }>`
  font-size: 16px;
  line-height: 20px;
  color: #3e3e3e;
  text-align: center;
  opacity: ${({ checked }) => (checked ? 0.5 : 1)};
  @media ${MOBILE_TABLET_MIN_RESOLUTION} and ${MOBILE_TABLET_RESOLUTION} {
    font-size: 18px;
    line-height: 22px;
  }
`;

const CustomIcon = styled(Icon)`
  ${({ theme }) => theme.utils.spinAnimation};
  position: absolute;
  fill: #3e3e3e;
  top: 50%;
  left: 50%;
`;
