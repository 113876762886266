import styled, { css } from 'styled-components';
import rtl from 'styled-components-rtl';
import type { Option } from 'components/Autocomplete/Autocomplete.config';

const ClientBasicOption = (
  values: Option[] | Option,
  multiple: boolean = true
) => (option: Option, onClick?: React.MouseEventHandler<HTMLLIElement>) => {
  return (
    <MyListItem
      id={option?.name.toString()}
      className="colorOption"
      onClick={onClick}
    >
      <CardContainer>
        <ItemName
          isSelected={
            multiple
              ? (values as Option[]).some(item => item?.name === option?.name)
              : values === option
          }
        >
          {option?.name as string}
        </ItemName>
      </CardContainer>
    </MyListItem>
  );
};

export default ClientBasicOption;

const CardContainer = styled.div`
  background-color: inherit;

  width: 200px;

  display: flex;
  margin: 5px 0;
`;

const ItemName = styled.span<{ isSelected: boolean }>`
  white-space: nowrap;
  margin: 0 5px;
  font-size: 16px;

  height: 20px;
  width: 110px;
  ${({ theme, isSelected }) =>
    isSelected &&
    css`
      color: ${theme.colors.primary.lightMobile};
    `}
`;

const MyListItem = styled.li`
  &:hover {
    background-color: #f2f2f2;
  }
  position: relative !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  ${rtl`
  padding-left: 14px;
  margin-right:14px;
  `}
  .icon.checked {
    background-color: ${({ theme }) => theme.colors.primary.lightMobile};
  }
  height: 40px;
  width: 177px;
`;
