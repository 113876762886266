import { Permission } from 'constants/config';
import * as React from 'react';
import { getRoute } from 'utils/sidebar.utils';
import {
  IRouteConfig,
  ManagementRouter,
  PropertiesManagementRoutes,
  ThreeDContentManagementRoutes,
  QuizManagementRoute,
  UserManagementRoute,
  ManagemenDasboardRoutes,
} from './routes';

export const managementRoutes = [
  {
    path: ManagementRouter.HOME,
    exact: true,
    component: React.lazy(
      () =>
        import(/* webpackChunkName: "page-Home" */ 'pages/appManagement/home')
    ),
    routes: [] as IRouteConfig[],
  },
  {
    path: ManagementRouter.DASHBOARD,
    exact: false,
    component: React.lazy(
      () =>
        import(
          /* webpackChunkName: "page-Dashboard" */ 'pages/appManagement/dashboard'
        )
    ),
    routes: [
      {
        path: getRoute([
          ManagementRouter.DASHBOARD,
          ManagemenDasboardRoutes.USERS,
        ]),
        exact: true,
        component: React.lazy(
          () =>
            import(
              /* webpackChunkName: "page-PropertyManagement-new_task" */ 'pages/appManagement/dashboard/tabs/users'
            )
        ),
      },
      {
        path: getRoute([
          ManagementRouter.DASHBOARD,
          ManagemenDasboardRoutes.PROPERTIES,
        ]),
        exact: true,
        component: React.lazy(
          () =>
            import(
              /* webpackChunkName: "page-PropertyManagement-new_task" */ 'pages/appManagement/dashboard/tabs/properties'
            )
        ),
      },
      {
        path: getRoute([
          ManagementRouter.DASHBOARD,
          ManagemenDasboardRoutes.SHOPPING,
        ]),
        exact: true,
        component: React.lazy(
          () =>
            import(
              /* webpackChunkName: "page-PropertyManagement-new_task" */ 'pages/appManagement/dashboard/tabs/shopping'
            )
        ),
      },
      {
        path: getRoute([
          ManagementRouter.DASHBOARD,
          ManagemenDasboardRoutes.PRODUCTS,
        ]),
        exact: true,
        component: React.lazy(
          () =>
            import(
              /* webpackChunkName: "page-PropertyManagement-new_task" */ 'pages/appManagement/dashboard/tabs/products'
            )
        ),
      },
      {
        path: getRoute([
          ManagementRouter.DASHBOARD,
          ManagemenDasboardRoutes.REVENUE,
        ]),
        exact: true,
        component: React.lazy(
          () =>
            import(
              /* webpackChunkName: "page-PropertyManagement-new_task" */ 'pages/appManagement/dashboard/tabs/revenue'
            )
        ),
      },
    ],
  },
  {
    path: ManagementRouter.USER_MANAGEMENT,
    permissions: [Permission.USER_MANAGEMENT],
    exact: false,
    component: React.lazy(
      () =>
        import(
          /* webpackChunkName: "page-UserManagement" */ 'pages/appManagement/user-management'
        )
    ),
    routes: [
      {
        path: getRoute([
          ManagementRouter.USER_MANAGEMENT,
          UserManagementRoute.CLIENTS,
        ]),
        exact: true,
        component: React.lazy(
          () =>
            import(
              /* webpackChunkName: "page-UserManagementClients" */ 'pages/appManagement/user-management/clients'
            )
        ),
        routes: [] as IRouteConfig[],
      },
      {
        path: getRoute([
          ManagementRouter.USER_MANAGEMENT,
          UserManagementRoute.CLIENTS,
          '/:id',
        ]),
        exact: false,
        component: React.lazy(
          () =>
            import(
              /* webpackChunkName: "page-UserManagementClientsClient" */ 'pages/appManagement/user-management/clients/client'
            )
        ),
        routes: [] as IRouteConfig[],
      },
      {
        path: getRoute([
          ManagementRouter.USER_MANAGEMENT,
          UserManagementRoute.TEAM_PATRICIA,
        ]),
        exact: false,
        component: React.lazy(
          () =>
            import(
              /* webpackChunkName: "page-UserManagementTeamPatricia" */ 'pages/appManagement/user-management/team-patricia'
            )
        ),
        routes: [] as IRouteConfig[],
      },
      {
        path: getRoute([
          ManagementRouter.USER_MANAGEMENT,
          UserManagementRoute.SUPPLIERS,
        ]),
        exact: true,
        component: React.lazy(
          () =>
            import(
              /* webpackChunkName: "page-UserManagementSuppliers" */ 'pages/appManagement/user-management/suppliers'
            )
        ),
        routes: [] as IRouteConfig[],
      },
      {
        path: getRoute([
          ManagementRouter.USER_MANAGEMENT,
          UserManagementRoute.SUPPLIERS,
          '/:id',
        ]),
        exact: false,
        component: React.lazy(
          () =>
            import(
              /* webpackChunkName: "page-UserManagementSuppliersSupplier" */ 'pages/appManagement/user-management/suppliers/supplier'
            )
        ),
        routes: [] as IRouteConfig[],
      },
      {
        path: getRoute([
          ManagementRouter.USER_MANAGEMENT,
          UserManagementRoute.ORDERS,
        ]),
        exact: true,
        component: React.lazy(
          () =>
            import(
              /* webpackChunkName: "page-UserManagementOrders" */ 'pages/appManagement/user-management/orders/Orders'
            )
        ),
        routes: [] as IRouteConfig[],
      },
      {
        path: getRoute([
          ManagementRouter.USER_MANAGEMENT,
          UserManagementRoute.PROPERTIES,
        ]),
        exact: true,
        component: React.lazy(
          () =>
            import(
              /* webpackChunkName: "page-UserManagementProperties" */ 'pages/appManagement/user-management/properties'
            )
        ),
        routes: [] as IRouteConfig[],
      },
      {
        path: getRoute([
          ManagementRouter.USER_MANAGEMENT,
          UserManagementRoute.ARCHIVED_USERS,
        ]),
        exact: true,
        component: React.lazy(
          () =>
            import(
              /* webpackChunkName: "page-UserManagementArchivedUsers" */ 'pages/appManagement/user-management/archived-users'
            )
        ),
        routes: [] as IRouteConfig[],
      },
      {
        path: getRoute([
          ManagementRouter.USER_MANAGEMENT,
          UserManagementRoute.RENDERS,
        ]),
        exact: true,
        component: React.lazy(
          () =>
            import(
              /* webpackChunkName: "page-UserManagementArchivedUsers" */ 'pages/appManagement/user-management/renders'
            )
        ),
        routes: [] as IRouteConfig[],
      },
    ] as IRouteConfig[],
  },
  {
    path: ManagementRouter.PROPERTY_MANAGEMENT,
    permissions: [Permission.PROPERTIES_MANAGEMENT],
    exact: false,
    component: React.lazy(
      () =>
        import(
          /* webpackChunkName: "page-PropertyManagement" */ 'pages/appManagement/property-management'
        )
    ),
    routes: [
      {
        path:
          PropertiesManagementRoutes.PROPERTY_MANAGEMENT_NEW_TASK_PROPERTIES,
        exact: true,
        component: React.lazy(
          () =>
            import(
              /* webpackChunkName: "page-PropertyManagement-new_task" */ 'pages/appManagement/property-management/new-tasks-properties'
            )
        ),
      },
      {
        path: PropertiesManagementRoutes.PROPERTY_MANAGEMENT_NEW_TASK_UNITS,
        exact: true,
        component: React.lazy(
          () =>
            import(
              /* webpackChunkName: "page-PropertyManagement-new_task" */ 'pages/appManagement/property-management/new-tasks-units'
            )
        ),
      },
      {
        path: PropertiesManagementRoutes.PROPERTY_MANAGEMENT_ARCHIVED,
        exact: true,
        component: React.lazy(
          () =>
            import(
              /* webpackChunkName: "page-PropertyManagement-archived" */ 'pages/appManagement/property-management/archived'
            )
        ),
      },
      {
        path: PropertiesManagementRoutes.PROPERTY_MANAGEMENT_PUBLISHED,
        exact: true,
        component: React.lazy(
          () =>
            import(
              /* webpackChunkName: "page-PropertyManagement-published" */ 'pages/appManagement/property-management/published'
            )
        ),
      },
    ],
  },
  {
    path: ManagementRouter.THREE_D_CONTENT_MANAGEMENT,
    permissions: [Permission.MANAGEMENT_3D_CONTENT],
    exact: false,
    component: React.lazy(
      () =>
        import(
          /* webpackChunkName: "page-ThreeDContentManagement" */ 'pages/appManagement/3d-content-management'
        )
    ),
    routes: [
      {
        path: ThreeDContentManagementRoutes.THREE_D_MANAGEMENT_ALL_TASKS,
        exact: true,
        component: React.lazy(
          () =>
            import(
              /* webpackChunkName: "page-threeDManagement-all_tasks" */ 'pages/appManagement/3d-content-management/all-tasks'
            )
        ),
      },
      {
        path: ThreeDContentManagementRoutes.THREE_D_MANAGEMENT_IN_DB,
        exact: true,
        component: React.lazy(
          () =>
            import(
              /* webpackChunkName: "page-threeDManagement-in_db" */ 'pages/appManagement/3d-content-management/in-db'
            )
        ),
      },
    ],
  },
  {
    path: ManagementRouter.QUIZ_MANAGEMENT,
    permissions: [Permission.QUIZ_MANAGEMENT],
    exact: false,
    component: React.lazy(
      () =>
        import(
          /* webpackChunkName: "page-QuizManagement" */ 'pages/appManagement/quiz-management'
        )
    ),
    routes: [
      {
        path: getRoute([
          ManagementRouter.QUIZ_MANAGEMENT,
          QuizManagementRoute.INSPIRATION_QUIZ,
        ]),
        exact: false,
        component: React.lazy(
          () =>
            import(
              /* webpackChunkName: "page-QuizManagementInspirationQuiz" */ 'pages/appManagement/quiz-management/inspiration-quiz'
            )
        ),
        routes: [],
      },
      {
        path: getRoute([
          ManagementRouter.QUIZ_MANAGEMENT,
          QuizManagementRoute.DESIGN_STYLE_DRILL_DOWN,
        ]),
        exact: true,
        component: React.lazy(
          () =>
            import(
              /* webpackChunkName: "page-QuizManagementDesignStyle" */ 'pages/appManagement/quiz-management/design-style'
            )
        ),
        routes: [],
      },
      {
        path: getRoute([
          ManagementRouter.QUIZ_MANAGEMENT,
          QuizManagementRoute.DESIGN_STYLE_ROOM_DRILL_DOWN,
        ]),
        exact: true,
        component: React.lazy(
          () =>
            import(
              /* webpackChunkName: "page-QuizManagementDesignStyleRoom" */ 'pages/appManagement/quiz-management/design-style/room'
            )
        ),
        routes: [],
      },
      {
        path: getRoute([
          ManagementRouter.QUIZ_MANAGEMENT,
          QuizManagementRoute.DESIGN_STYLE,
        ]),
        exact: true,
        component: React.lazy(
          () =>
            import(
              /* webpackChunkName: "page-QuizManagementDesignStyles" */ 'pages/appManagement/quiz-management/design-styles/DesignStyles'
            )
        ),
        routes: [],
      },
      {
        path: getRoute([
          ManagementRouter.QUIZ_MANAGEMENT,
          QuizManagementRoute.ARCHIVE,
        ]),
        exact: false,
        component: React.lazy(
          () =>
            import(
              /* webpackChunkName: "page-QuizManagementArchive" */ 'pages/appManagement/quiz-management/archive'
            )
        ),
        routes: [],
      },
    ] as IRouteConfig[],
  },
  {
    path: ManagementRouter.NOT_FOUND,
    exact: true,
    component: React.lazy(
      () => import(/* webpackChunkName: "page-404" */ 'pages/common/404')
    ),
    routes: [] as IRouteConfig[],
  },
] as IRouteConfig[];
