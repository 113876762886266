import ClientsDataType from 'store/constants/user-management/clients.constants';
import * as Payload from 'store/types/user-management/clients.types';

export const getUsersDataRequest = (
  payload: Payload.GetUsersDataRequestPayload
) => ({
  type: ClientsDataType.GET_USERS_DATA_REQUEST,
  payload,
});

export const getUsersDataFulfilled = (
  payload: Payload.GetUsersDataFulfilledPayload
) => ({
  type: ClientsDataType.GET_USERS_DATA_FULFILLED,
  payload,
});

export const getUsersDataRejected = (
  payload: Payload.GetUsersDataRejectedPayload
) => ({
  type: ClientsDataType.GET_USERS_DATA_REJECTED,
  payload,
});

export const getUserDataRequest = (
  payload: Payload.GetUserDataRequestPayload
) => ({
  type: ClientsDataType.GET_USER_DATA_REQUEST,
  payload,
});

export const getUserBasicInfoRequest = (
  payload: Payload.GetUserDataRequestPayload
) => ({
  type: ClientsDataType.GET_USER_BASIC_DATA_REQUEST,
  payload,
});

export const getRoomsDataRequest = () => ({
  type: ClientsDataType.GET_ROOMS_DATA_REQUEST,
});

export const getRoomsDataFulfilled = (
  payload: Payload.GetRoomsDataFulfilledPayload
) => ({
  type: ClientsDataType.GET_ROOMS_DATA_FULFILLED,
  payload,
});

export const getUserDataFulfilled = (
  payload: Payload.GetUserDataFulfilledPayload
) => ({
  type: ClientsDataType.GET_USER_DATA_FULFILLED,
  payload,
});

export const getUserDataRejected = (
  payload: Payload.GetUserDataRejectedPayload
) => ({
  type: ClientsDataType.GET_USER_DATA_REJECTED,
  payload,
});

export const getRoomsDataRejected = (
  payload: Payload.GetRoomsDataRejectedPayload
) => ({
  type: ClientsDataType.GET_ROOMS_DATA_REJECTED,
  payload,
});

export const getProductsDataRequest = (
  payload: Payload.GetProductsDataRequestPayload
) => ({
  type: ClientsDataType.GET_PRODUCTS_DATA_REQUEST,
  payload,
});

export const getCartItemsDataRequest = (payload: string) => {
  return {
    type: ClientsDataType.GET_CART_ITEM_DATA_REQUEST,
    payload,
  };
};

export const getCartItemsDataFulfilled = (
  payload: Payload.GetCartsDataFulfilledPayload
) => ({
  type: ClientsDataType.GET_CART_ITEM_DATA_FULFILLED,
  payload,
});

export const getCartItemsDataRejected = (
  payload: Payload.GetProductsDataRejectedPayload
) => ({
  type: ClientsDataType.GET_PRODUCTS_DATA_REJECTED,
  payload,
});
export const getProductsDataFulfilled = (
  payload: Payload.GetProductsDataFulfilledPayload
) => ({
  type: ClientsDataType.GET_PRODUCTS_DATA_FULFILLED,
  payload,
});

export const getProductsDataRejected = (
  payload: Payload.GetProductsDataRejectedPayload
) => ({
  type: ClientsDataType.GET_PRODUCTS_DATA_REJECTED,
  payload,
});

export const getPropertiesDataRequest = (
  payload: Payload.GetPropertiesDataRequestPayload
) => ({
  type: ClientsDataType.GET_PROPERTIES_DATA_REQUEST,
  payload,
});

export const getPropertiesDataFulfilled = (
  payload: Payload.GetPropertiesDataFulfilledPayload
) => ({
  type: ClientsDataType.GET_PROPERTIES_DATA_FULFILLED,
  payload,
});

export const getPropertiesDataRejected = (
  payload: Payload.GetPropertiesDataRejectedPayload
) => ({
  type: ClientsDataType.GET_PROPERTIES_DATA_REJECTED,
  payload,
});

export const getQuizResultsDataRequest = (
  payload: Payload.GetQuizResultsRequestPayload
) => ({
  type: ClientsDataType.GET_QUIZ_RESULTS_DATA_REQUEST,
  payload,
});

export const getQuizResultsDataFulfilled = (
  payload: Payload.GetQuizResultsDataFulfilledPayload
) => ({
  type: ClientsDataType.GET_QUIZ_RESULTS_DATA_FULFILLED,
  payload,
});

export const getQuizResultsDataRejected = (
  payload: Payload.GetQuizResultsDataRejectedPayload
) => ({
  type: ClientsDataType.GET_QUIZ_RESULTS_DATA_REJECTED,
  payload,
});

export const getUserActivitiesDataRequest = (
  payload: Payload.GetUserActivityRequestPayload
) => ({
  type: ClientsDataType.GET_USER_ACTIVITY_DATA_REQUEST,
  payload,
});

export const getUserActivitiesDataFulfilled = (
  payload: Payload.GetUserDataFulfilledPayload
) => ({
  type: ClientsDataType.GET_USER_ACTIVITY_DATA_FULFILLED,
  payload,
});

export const getUserActivitiesDataRejected = (
  payload: Payload.GetQuizResultsDataRejectedPayload
) => ({
  type: ClientsDataType.GET_USER_ACTIVITY_DATA_REJECTED,
  payload,
});

export type ClientsAction = ReturnType<
  | typeof getUsersDataRequest
  | typeof getUsersDataFulfilled
  | typeof getUsersDataRejected
  | typeof getUserDataRequest
  | typeof getUserDataFulfilled
  | typeof getUserDataRejected
  | typeof getProductsDataRequest
  | typeof getProductsDataFulfilled
  | typeof getProductsDataRejected
  | typeof getCartItemsDataRequest
  | typeof getCartItemsDataFulfilled
  | typeof getCartItemsDataRejected
  | typeof getPropertiesDataRequest
  | typeof getPropertiesDataFulfilled
  | typeof getPropertiesDataRejected
  | typeof getQuizResultsDataRequest
  | typeof getQuizResultsDataFulfilled
  | typeof getQuizResultsDataRejected
  | typeof getUserActivitiesDataRequest
  | typeof getUserActivitiesDataRejected
  | typeof getUserActivitiesDataFulfilled
>;
