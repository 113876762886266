import { Link } from 'react-router-dom';
import styled from 'styled-components';
import EmptyState from 'assets/@client/quizzes/quizzes-empty-state.svg';
import Button from 'components/@client/Button';
import { ClientRouter } from 'router/routes';
import { MOBILE_RESOLUTION } from 'styles/sharedStyle';

const QuizEmptyState = () => {
  return (
    <EmptyStateWrapper>
      <p className="description">
        You have not yet answered <br />
        our questionnaire?
      </p>
      <IllustrationWrapper>
        <img
          src={EmptyState}
          alt="An orange notebook on top of a chair inside the house."
        />
      </IllustrationWrapper>
      <Link to={ClientRouter.QUIZ_ADD_PROPERTY}>
        <CustomButton className="btn">Start building your home</CustomButton>
      </Link>
    </EmptyStateWrapper>
  );
};

export default QuizEmptyState;

const EmptyStateWrapper = styled.div`
  font-weight: 500;
  max-width: 710px;
  font-size: 1.25rem;
  text-align: center;
  margin: auto;
  .btn {
    border-radius: 12px;
    margin-top: 10px;
  }
  @media ${MOBILE_RESOLUTION} {
    margin: 5px -25px 0 -25px;
    max-width: 500px;
  }

  a {
    width: 100%;
    text-decoration: none;
  }

  @media (min-width: 500px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 330px;
    .description {
      font-size: 40px;
    }
    .btn {
      font-size: 1.5rem;
      margin: 20px 0;
    }
  }
`;

const IllustrationWrapper = styled.div.attrs({
  className: 'empty-state-illustration-wrapper',
})`
  width: 100%;
  max-width: 650px;

  & img {
    width: 100%;
  }
`;

const CustomButton = styled(Button)`
  width: 95%;
  margin: 20px;

  @media (min-width: 500px) {
    width: 60%;
  }
`;
