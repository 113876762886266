import * as React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Slide from '@material-ui/core/Slide';
import styled, { css } from 'styled-components';
import CustomModals, { ModalProps } from 'components/@client/CustomModel';
import { useRouter, useAuthentication } from 'hooks';
import { MOBILE_RESOLUTION } from 'styles/sharedStyle';
import { LogsAPI } from 'api/methods';
import { userLogData } from 'mockData/userLogData';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import rtl from 'styled-components-rtl';
import { useIntl } from 'react-intl';
import { KeyboardArrowRight } from '@material-ui/icons';
import useUploadPlan from 'pages/appClient/onboarding/upload-plan/useUploadPlan';
import UserForm from 'pages/appClient/seller-property/UserForm';

interface Props extends ModalProps {}

const AddProperty: React.FC<Props> = ({ open }) => {
  const { history, pathname } = useRouter();
  const { type } = useAuthentication();
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const isMobile = useMediaQuery(MOBILE_RESOLUTION);
  const intl = useIntl();
  const { setImage, setNewPropertyType, setSelectedFloor } = useUploadPlan();

  const close = React.useCallback(() => {
    LogsAPI.postUserLogs({
      area: userLogData.eventArea.myProperties,
      section: userLogData.eventSection.properties,
      name: userLogData.eventName.xCloseButton,
      path: `${history.location.pathname}${history.location.search}`,
    });
    setActiveStep(0);
    history.push(pathname);
  }, [history, pathname]);

  const steps = React.useMemo(
    () =>
      type === 'SELLER'
        ? [<UserForm key="upload-plan" />]
        : [<UserForm key="upload-plan" />],
    [type]
  );

  const modalProp =
    type === 'SELLER'
      ? {
          open,
          disableClose: isMobile,
          isFooterBtn: false,
          onClose: () => {
            setImage(null);
            setNewPropertyType('');
            setSelectedFloor(null);
            close();
          },
        }
      : {
          fullWidth: true,
          fullScreen: isMobile,
          actions: [],
          open,
          onClose: close,
          disableClose: isMobile,
        };

  return (
    <CustomModal type={type} TransitionComponent={Slide} {...modalProp}>
      {isMobile && (
        <CustomArrowContainer onClick={() => history.goBack()}>
          {intl.locale === 'he' ? (
            <KeyboardArrowRight fontSize="large" />
          ) : (
            <KeyboardArrowLeftIcon fontSize="large" />
          )}
        </CustomArrowContainer>
      )}
      {steps[activeStep]}
      {/* <OnboardingContextProvider>{steps[activeStep]}</OnboardingContextProvider> */}
    </CustomModal>
  );
};

export default AddProperty;

const CustomModal = styled(CustomModals)<{
  type: string | null | undefined;
}>`
  &&.client-dialog .MuiDialog-paper {
    .MuiSvgIcon-root {
      font-size: 30.86px;
    }
    overflow: hidden;
    padding: 10px 24px 10px 24px;
    max-width: fit-content;
    @media (min-width: 1000px) {
      ${({ type }) =>
        type !== 'SELLER' &&
        type !== 'SUPPLIER' &&
        css`
          width: 598px;
          max-width: 598px;
          height: 600px;
          padding: 0;
        `}
    }
    @media (max-width: 800px) {
      border-radius: 20px;
      max-width: 928px;
      height: 600px;
    }
    @media (max-width: 820px) {
      border-radius: 20px;
      max-width: 928px;
      width: 928px;
    }
  }
  &.client-dialog .MuiDialog-paper .MuiDialogTitle-root {
    margin: 0;
  }

  && .MuiDialogContent-root {
    margin: 0;
    overflow: overlay;
    @media (max-width: 380px) {
      padding: 8px 0px !important;
    }
    padding: 8px 8px !important;
    ${({ theme }) => theme.utils.scrollbar};
  }
  &&.client-dialog .MuiDialog-paper .MuiDialogActions-root {
    padding: 0;
  }
`;

const CustomArrowContainer = styled.div`
  position: absolute;
  top: 25px;
  ${rtl`left: 10px;`}
`;
