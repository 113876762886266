import Login from 'pages/auth/Login';
import Register from 'pages/auth/Register';
import Password from 'pages/auth/reset-password';
import { AuthRoute, IRouteConfig } from './routes';

export const authRoutes = [
  {
    path: AuthRoute.LOGIN,
    exact: true,
    component: Login,
    routes: [],
  },
  {
    path: AuthRoute.REGISTER,
    exact: true,
    component: Register,
    routes: [],
  },
  {
    path: AuthRoute.FORGOT_PASSWORD,
    exact: true,
    component: Password.Forgot,
    routes: [],
  },
  {
    path: AuthRoute.RESET_LINK_SENT,
    exact: true,
    component: Password.LinkSent,
    routes: [],
  },
  {
    path: AuthRoute.RESET_PASSWORD,
    exact: true,
    component: Password.Reset,
    routes: [],
  },
  {
    path: AuthRoute.RESET_SUCCESS,
    exact: true,
    component: Password.Success,
    routes: [],
  },
] as IRouteConfig[];
