import * as React from 'react';
import { Link } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import styled, { css } from 'styled-components';
import Logo from 'components/Logo';
import { AuthRoute, ClientRouter } from 'router/routes';
import { isString, size } from 'utils/lodash.utils';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';

interface Props {
  forceLogo?: boolean;
  label?: string | React.ReactNode;
  withLoginLink?: boolean;
  dense?: boolean;
  hideLogo?: boolean;
}

const TitleStack: React.FC<Props> = ({
  label,
  withLoginLink = false,
  forceLogo = false,
  dense,
  hideLogo = false,
}) => {
  const isMobile = useMediaQuery('(max-width: 1150px)');
  return (
    <Container withLoginLink={withLoginLink} forceLogo={forceLogo}>
      {(isMobile || forceLogo) && !hideLogo && (
        <Link to={ClientRouter.APP}>
          <Logo />
        </Link>
      )}
      <Label dense={dense || (isString(label) && size(label) > 40)}>
        {label}
      </Label>
      {withLoginLink && isMobile && (
        <Link to={AuthRoute.LOGIN}>
          <FormattedMessage id={Message.MENU_ITEM_LOGIN} />
        </Link>
      )}
    </Container>
  );
};

export default TitleStack;

const Container = styled.header.attrs({ className: 'title-stack' })<{
  withLoginLink: boolean;
  forceLogo: boolean;
}>`
  ${({ forceLogo }) =>
    !forceLogo &&
    css`
      justify-content: center;
    `}
  margin-top: 10px;
  display: flex;
  align-items: flex-end;

  & svg {
    height: 59px;
    width: 39px;
  }

  & a {
    font-size: 0.875rem;
    display: flex;
    align-self: flex-start;
    color: #266fdd;
    line-height: 17.12px;
  }

  ${({ withLoginLink }) =>
    withLoginLink &&
    css`
      justify-content: space-between;

      & h2 {
        margin: 0;
      }
    `}
`;

const Label = styled.h2.attrs({ className: 'section-title-label' })<{
  dense?: boolean;
}>`
  margin: auto;
  max-width: 23ch;
  font-size: 1.25rem;
  text-align: center;

  @media (min-width: 1150px) {
    font-size: 1.875rem;
  }

  ${({ dense }) =>
    dense &&
    css`
      max-width: 28ch;
      font-size: 1rem;
      font-weight: 400;
      margin-left: 0;
      margin-bottom: 0;
    `};
`;
