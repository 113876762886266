import * as React from 'react';
import { Link } from 'react-router-dom';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import styled from 'styled-components';

export interface BackLinkProps {
  to: string;
  label?: string;
}

const BackLink: React.FC<BackLinkProps> = ({ to, label = 'Back' }) => {
  return (
    <Back to={to}>
      <KeyboardArrowLeftIcon /> <span>{label}</span>
    </Back>
  );
};

export default BackLink;

const Back = styled(Link).attrs({ className: 'back-link' })`
  color: ${({ theme }) => theme.colors.primary.dark};
  display: flex;
  align-items: center;
  justify-self: flex-start;
  font-size: 0.875rem;
  text-decoration: none;
  opacity: 0.6;
  opacity: 1;
  transition: all 0.2s;

  & > span {
    border-bottom: 1px solid transparent;
  }

  &:hover {
    opacity: 0.6;
    & > span {
      border-bottom: 1px solid transparent;
      font-weight: 500;
      border-color: #3e3e3e;
    }
  }
`;
