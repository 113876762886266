import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Message } from 'i18n';
import eyeIcon from 'assets/@client/onboarding/eye-down.png';
import eyeUpIcon from 'assets/@client/onboarding/eye-up.png';
import { PhoneField } from 'components/forms';
import styled from 'styled-components';
import { useRouter } from 'hooks';
import TextInput from 'components/@client/form-components/TextInput';
import { ClientRouter } from 'router/routes';

const SignUpSupplier: React.FC = () => {
  const { control, formState } = useFormContext();
  const { history } = useRouter();
  const intl = useIntl();
  const [isPasswordVisible, setIsPasswordVisible] = React.useState({
    password: false,
    confirmPassword: false,
  });
  const { isValid, isDirty } = formState;

  return (
    <ParentDiv>
      <NameParent>
        <TextInput
          name="firstName"
          control={control}
          label={intl.formatMessage({ id: Message.FIRST_NAME })}
          required
          placeholder="John"
        />
        <TextInput
          name="lastName"
          control={control}
          label={intl.formatMessage({ id: Message.LAST_NAME })}
          required
          placeholder="Doe"
        />
      </NameParent>
      <TextInput
        name="email"
        control={control}
        label={intl.formatMessage({
          id: Message.AUTH_LOGIN_EMAIL_FIELD,
        })}
        placeholder={intl.formatMessage({
          id: Message.SUPPLIER_LOGIN_EMAIL_PLACEHOLDER,
        })}
        type="email"
        required
      />
      <PhoneField
        name="phoneNumber"
        label={{
          title: intl.formatMessage(
            {
              id: Message.SUPPLIER_REGISTRATION_PHONE_NUMBER_LABEL,
            },
            {
              span: (chunks: string) => (
                <span
                  style={{
                    fontSize: '12px',
                    fontWeight: 400,
                    color: '#696969',
                  }}
                >
                  {chunks}
                </span>
              ),
            }
          ),
        }}
      />

      <PasswordParent>
        <TextInput
          name="password"
          control={control}
          label={intl.formatMessage({
            id: Message.AUTH_LOGIN_PASSWORD_FIELD,
          })}
          type={isPasswordVisible.password ? 'text' : 'password'}
          required
        />
        <IconParent
          onClick={() =>
            setIsPasswordVisible(prev => ({
              ...prev,
              password: !prev.password,
            }))
          }
        >
          <img
            alt="temp"
            src={isPasswordVisible.password ? eyeUpIcon : eyeIcon}
          />
        </IconParent>
      </PasswordParent>
      <ConfirmPasswordParent>
        <TextInput
          name="verifyPassword"
          control={control}
          label={intl.formatMessage({
            id: Message.SUPPLIER_REPEAT_PASSWORD,
          })}
          type={isPasswordVisible.confirmPassword ? 'text' : 'password'}
          required
        />
        <IconParent
          onClick={() =>
            setIsPasswordVisible(prev => ({
              ...prev,
              confirmPassword: !prev.confirmPassword,
            }))
          }
        >
          <img
            alt="temp"
            src={isPasswordVisible.confirmPassword ? eyeUpIcon : eyeIcon}
          />
        </IconParent>
      </ConfirmPasswordParent>
      <ButtonsContainer>
        <BackButton onClick={() => history.push(ClientRouter.SUPPLIER_LOGIN)}>
          <BackButtonText>
            <FormattedMessage
              id={Message.PROPERTY_ADD_PROPERTY_BUTTON_PREVIOUS}
            />
          </BackButtonText>
        </BackButton>
        <NextButton disabled={!isValid || !isDirty} type="submit">
          <NextButtonText>
            <FormattedMessage id={Message.PROPERTY_ADD_PROPERTY_BUTTON_NEXT} />
          </NextButtonText>
        </NextButton>
      </ButtonsContainer>
    </ParentDiv>
  );
};

export default SignUpSupplier;

const ParentDiv = styled.div`
  direction: ltr !important;
  label {
    font-size: 14px !important;
    line-height: 16px;
    color: #696969 !important;
    letter-spacing: 0.545455px !important;
    font-weight: 400 !important;
    margin-left: 0 !important;
  }
  .text-input-root {
    padding-bottom: 0;
  }
  .custom-input-root:hover {
    border: none !important;
  }
  .MuiFormControl-root {
    direction: ltr !important;
  }
`;

const NameParent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 14px;
  .text-input-root {
    width: 185px;
  }
`;

const PasswordParent = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  .text-input-root {
    width: 384px;
  }
  .custom-input-root:hover {
    border: none !important;
  }
`;

const ConfirmPasswordParent = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  .text-input-root {
    width: 384px;
  }
  .custom-input-root:hover {
    border: none !important;
  }
`;

const IconParent = styled.div`
  position: absolute;
  right: 40px;
  top: 36px;
  cursor: pointer;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BackButton = styled.button`
  cursor: pointer;
  border: 1px solid #d9d9d9;
  border-radius: 15px;
  padding: 6px 24px;
  height: 48px;
  width: 134px;
  background: white;
`;

const NextButton = styled.button`
  :disabled {
    background: #d9d9d9;
    cursor: default;
  }
  background: #fc5c42;
  border: 1px solid #d9d9d9;
  border-radius: 15px;
  padding: 6px 24px;
  height: 48px;
  width: 134px;
  cursor: pointer;
`;

const BackButtonText = styled.div`
  font-size: 18px;
  line-height: 21px;
  color: #696969;
`;

const NextButtonText = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: rgba(255, 255, 255, 0.9);
`;
