import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import SafeImg from 'components/SafeImg/SafeImg';
import countries from 'data/countries';
import { useFormattedCurrency } from 'hooks';
import { Message } from 'i18n';
import type { OrderDetail } from 'types/orders';
import { Supplier } from 'types/suppliers';
import { summarizeText } from 'utils/string.utils';
import { isEmpty } from 'utils/lodash.utils';
import { SupportedCurrency } from 'types/common.types';

const OrderProductItem: React.FC<{ product: OrderDetail }> = ({ product }) => {
  const currency =
    (product?.supplier as Supplier)?.country === countries.IL
      ? SupportedCurrency.ILS
      : SupportedCurrency.USD;
  const formattedPrice = useFormattedCurrency(
    product.quantity * (product?.totalRowPrice ?? 0),
    { currency }
  );
  return (
    <li>
      <SafeImg
        src={product?.media?.[0]?.file ?? product?.thumbnail?.file}
        alt={product.name}
      />
      <div className="content-stack">
        <span>{summarizeText(product.name, 20)}</span>
        <div className="stock">
          <div>
            {!isEmpty(product.colors) && (
              <div>
                <FormattedMessage id={Message.ORDER_PRODUCT_COLOR_LABEL} />:{' '}
                {product.colors?.[0]?.name}
              </div>
            )}
            <div>
              <FormattedMessage id={Message.CHECKOUT_ITEM_QUANTITY_LABEL} />:{' '}
              {product.quantity ?? <FormattedMessage id={Message.NO_VALUE} />}
            </div>
          </div>
          <span className="stock-price">{formattedPrice}</span>
        </div>
      </div>
    </li>
  );
};

export default OrderProductItem;
