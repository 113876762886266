import * as React from 'react';
import styled from 'styled-components';
import illustration from './not-found-illustration.svg';

const NotFound = () => {
  return (
    <Container>
      <p>
        We did not find what you were looking for. <br />
        You can go back to the home page and search again
      </p>
      <img
        src={illustration}
        alt="Very pale person in denim shorts, a blue t-shirt, black fanny pack, and sun hat looking at a red screen in the shape of a map."
      />
    </Container>
  );
};

export default NotFound;

const Container = styled.figure`
  margin: 0 auto;
  text-align: center;
  & p {
    margin: 20px auto;
    max-width: 25ch;
    text-align: center;
    color: rgba(66, 66, 66, 1);
    font-size: 1.25rem;
    font-weight: 500;

    @media (min-width: 800px) {
      max-width: 35ch;
    }
  }

  img {
    text-align: center;
    margin: 20px auto;
    max-width: 100%;
  }
`;
