import { Message } from 'i18n';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import MobileHeader from 'components/@client/MobileHeader';
import styled, { css } from 'styled-components';
import SectionTitle from 'components/@client/SectionTitle/SectionTitle';
import PhotoGrid from 'assets/@client/skins';
import Icon from 'components/Icon';
import rtl from 'styled-components-rtl';
import { ArrowBase } from 'components/@client/SlideshowModal/Arrows';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { METHODS } from 'api/client';
import { LogsAPI } from 'api/methods';
import { userLogData } from 'mockData/userLogData';
import { ClientRouter } from 'router/routes';
import {
  useAuthentication,
  useFetch,
  useNotifications,
  useRecentProperty,
  useRouter,
} from 'hooks';
import { error, info, success, warning } from 'styles/sharedStyle';
import Slider from 'react-slick';
import MuiSlider from '@mui/material/Slider';
import ProductPageModal from 'pages/appClient/productPage/component/ProductPageModal';
import Loader from 'components/Loader/Loader';
import { Skin } from 'types/properties';
import SkinCard from '../components/SkinCard';
import useSkin from './useSkin';
import SkinApplyButton from './Components/SkinApplyButton';

const getPositionWiseImage = (pos: number, skin?: Skin | null) => {
  const value = skin?.user?.skinCreator?.skinImages?.filter(
    image => image?.position === pos
  );
  return value?.[0]?.image;
};

const NewSkinMobile: React.FC = () => {
  const { skin, products, userSkins, isLoading: isSkinLoading } = useSkin();
  const intl = useIntl();
  const { token } = useAuthentication();
  const { history } = useRouter();
  const getTitleColor = () =>
    [success, warning, error, info, '#87b9e7'][Math.floor(Math.random() * 5)];
  const [selectedItem, setSelectedItem] = React.useState(0);
  const [selectedItemSkin, setSelectedItemSkin] = React.useState(0);
  const mainColor = React.useRef(getTitleColor());
  const { recentProperty } = useRecentProperty();
  const [productId, setProductId] = React.useState<string>('');
  const [isReadMore, setIsReadMore] = React.useState(false);
  const { showNotification } = useNotifications();
  const scrollRef = React.useRef<Slider>(null);
  const scrollRefSkin = React.useRef<Slider>(null);

  const onApplyFreeSkinSuccess = React.useCallback(() => {
    if (recentProperty && skin) {
      history.push(
        `${ClientRouter.TOUR.replace(
          ':propertyId',
          recentProperty.id
        )}?skinId=${skin.id}`
      );
    }
  }, [history, recentProperty, skin]);

  const { callFetch, isLoading: isApplyingSkin } = useFetch({
    initialUrl: '/api/skins/',
    skipOnStart: true,
    config: { method: METHODS.PATCH },
    onSuccess: onApplyFreeSkinSuccess,
    onFailure: React.useCallback(
      (message: string) => {
        showNotification({
          severity: 'error',
          message,
          key: message,
        });
      },
      [showNotification]
    ),
  });

  const handlePurchaseSkinClick = React.useCallback(() => {
    LogsAPI.postUserLogs({
      name: userLogData.eventName.applySkin,
      area: userLogData.eventArea.skins,
      section: userLogData.eventSection.skins,
      path: userLogData.eventPath.appSkinsCheckoutId,
      metadata: { propertyId: recentProperty?.id },
    });
    if (!token) {
      history.push(ClientRouter.ONBOARDING);
    } else if (skin?.price === 0)
      callFetch({
        url: `api/skins/${skin?.id}/purchase_free_skin/`,
      });
    else
      history.push(
        ClientRouter.SKIN_CHECKOUT.replace(':id', skin?.id?.toString() ?? '')
      );
  }, [callFetch, history, recentProperty?.id, skin?.id, skin?.price, token]);

  const buttonProps = React.useMemo(
    () => ({
      free: skin?.price === 0,
      onClick: handlePurchaseSkinClick,
      isLocked: Boolean(token) && skin?.isLocked,
      isLoading: isApplyingSkin,
    }),
    [
      handlePurchaseSkinClick,
      isApplyingSkin,
      skin?.isLocked,
      skin?.price,
      token,
    ]
  );

  const settings = {
    dots: false,
    infinite: false,
  };

  const NextArrow = (props: {
    onClick?: React.MouseEventHandler<HTMLDivElement>;
  }) => {
    const { onClick } = props;
    return (
      <ArrowContainer
        role="button"
        isOpen={true}
        onClick={onClick}
        prev={false}
        mediaLength={products?.length}
        currentValue={1.2}
        currentIndex={Math.floor(selectedItem)}
      >
        <ArrowIcon className="next" />
      </ArrowContainer>
    );
  };

  const PrevArrow = (props: {
    onClick?: React.MouseEventHandler<HTMLDivElement>;
  }) => {
    const { onClick } = props;
    return (
      <ArrowContainer
        role="button"
        isOpen={true}
        onClick={onClick}
        prev={true}
        mediaLength={products?.length}
        currentValue={1.2}
        currentIndex={selectedItem}
      >
        <ArrowIcon className="prev" />
      </ArrowContainer>
    );
  };

  React.useEffect(() => {
    if (intl.locale === 'he') {
      scrollRef?.current?.slickGoTo(products.length - 1);
      setSelectedItem(products.length - 1);
      setSelectedItemSkin((userSkins?.length ?? 1) - 1);
      scrollRefSkin?.current?.slickGoTo((userSkins?.length ?? 1) - 1);
    }
    // eslint-disable-next-line
  }, [products, intl.locale, scrollRef?.current]);

  const NextArrowSkin = (props: {
    onClick?: React.MouseEventHandler<HTMLDivElement>;
  }) => {
    const { onClick } = props;
    return (
      <ArrowContainerSkin
        role="button"
        isOpen={true}
        onClick={onClick}
        prev={false}
        mediaLength={userSkins?.length}
        currentValue={1.2}
        currentIndex={selectedItemSkin}
      >
        <ArrowIcon className="next" />
      </ArrowContainerSkin>
    );
  };

  const PrevArrowSkin = (props: {
    onClick?: React.MouseEventHandler<HTMLDivElement>;
  }) => {
    const { onClick } = props;
    return (
      <ArrowContainerSkin
        role="button"
        isOpen={true}
        onClick={onClick}
        prev={true}
        mediaLength={userSkins?.length}
        currentValue={1.2}
        currentIndex={selectedItemSkin}
      >
        <ArrowIcon className="prev" />
      </ArrowContainerSkin>
    );
  };

  if (isSkinLoading) {
    return <Loader fullHeight />;
  }
  return (
    <MainContainer>
      <MobileHeader title={<FormattedMessage id={Message.SKINS_TITLE} />} />
      <Container>
        <SectionTitle
          label={
            <FormattedMessage id={Message.SINGLE_SKINS_ABOUT_THE_DESIGNER} />
          }
          borderImage="linear-gradient(#AE8265 0%, #BE9A6D 7%, #DCC77C 21%,#EFE486 32%,#F7EF8A 39%,#F5EB86 52%,
                #EFE17D 62%,
                #E6D16C 71%,
                #DAB955 80%,
                #D4AE4A 84%,
                #C59D54 91%,
                #AE8265 100%)"
          center
        />
        <HeaderParent>
          <LeftContainer>
            <RightContainer>
              <FirstImageDiv>
                <Image1
                  src={skin?.user?.skinCreator?.logo ?? PhotoGrid.Image_1}
                  alt="image_1"
                />
              </FirstImageDiv>
              <SecondImageDiv>
                <MainChildDiv>
                  <Image1
                    src={getPositionWiseImage(1, skin) ?? PhotoGrid.Image_1}
                    alt="image_1"
                  />
                </MainChildDiv>
                <MainChildDiv>
                  <Image1
                    src={getPositionWiseImage(2, skin) ?? PhotoGrid.Image_1}
                    alt="image_1"
                  />
                </MainChildDiv>
                <MainChildDiv>
                  <Image1
                    src={getPositionWiseImage(3, skin) ?? PhotoGrid.Image_1}
                    alt="image_1"
                  />
                </MainChildDiv>
              </SecondImageDiv>
            </RightContainer>
            <Title>{skin?.user?.name}</Title>
            <SubTitle>
              <FormattedMessage
                id={
                  isReadMore
                    ? Message.SINGLE_SKINS_HEADER_SUB_TEXT_FOR_WEB
                    : Message.SINGLE_SKINS_HEADER_SUB_TEXT_FOR_WEB_READ_LESS
                }
                values={{
                  linebreak: <br />,
                  name: (
                    <span
                      style={{
                        fontWeight: intl.locale === 'he' ? 'bold' : 'normal',
                      }}
                    >
                      {intl.locale === 'he'
                        ? `"${skin?.room?.name}"`
                        : skin?.room?.name}
                    </span>
                  ),
                }}
              />
            </SubTitle>
            <ReadMoreLink onClick={() => setIsReadMore(!isReadMore)}>
              <ReadText>
                {isReadMore ? (
                  <FormattedMessage id={Message.SINGLE_SKINS_READ_LESS} />
                ) : (
                  <FormattedMessage id={Message.SINGLE_SKINS_READ_MORE} />
                )}
              </ReadText>
              {isReadMore ? <ExpandLess /> : <ExpandMoreIcon />}
            </ReadMoreLink>
          </LeftContainer>
        </HeaderParent>
        <SectionTitle
          label={<FormattedMessage id={Message.SINGLE_SKINS_ABOUT_TITLE} />}
          borderImage="linear-gradient(#AE8265 0%, #BE9A6D 7%, #DCC77C 21%,#EFE486 32%,#F7EF8A 39%,#F5EB86 52%,
            #EFE17D 62%,
            #E6D16C 71%,
            #DAB955 80%,
            #D4AE4A 84%,
            #C59D54 91%,
            #AE8265 100%)"
          center
        />
        <AboutSubTitle>
          <FormattedMessage
            id={Message.SINGLE_SKINS_ABOUT_SUB_TITLE}
            values={{
              linebreak: <br />,
            }}
          />
        </AboutSubTitle>
        <SkinNameParent>
          <FormattedMessage
            id={Message.SINGLE_SKINS_SKIN_NAME}
            values={{
              name: <span className="skin-name">{skin?.name}</span>,
            }}
          />
        </SkinNameParent>
        <PriceParent>
          <FormattedMessage
            id={Message.SINGLE_SKINS_ABOUT_PRICE}
            values={{
              price: (
                <span className="skin-name">
                  {skin?.price === 0 ? (
                    <FormattedMessage id={Message.SINGLE_SKINS_FREE} />
                  ) : (
                    skin?.price
                  )}
                </span>
              ),
            }}
          />
        </PriceParent>
        <SkinApplyButton {...buttonProps} />
        <SectionTitle
          label={
            <FormattedMessage id={Message.SINGLE_SKINS_PRODUCTS_IN_THIS_SKIN} />
          }
          borderImage="linear-gradient(#AE8265 0%, #BE9A6D 7%, #DCC77C 21%,#EFE486 32%,#F7EF8A 39%,#F5EB86 52%,
            #EFE17D 62%,
            #E6D16C 71%,
            #DAB955 80%,
            #D4AE4A 84%,
            #C59D54 91%,
            #AE8265 100%)"
          center
        />
        {!skin?.isLocked || skin?.price === 0 ? (
          <Slider
            ref={scrollRef}
            {...settings}
            swipeToSlide={true}
            variableWidth={true}
            nextArrow={<NextArrow />}
            prevArrow={<PrevArrow />}
            afterChange={index => {
              setSelectedItem(index);
            }}
          >
            {products.map(product => {
              return (
                <ProductContainer
                  key={product.id}
                  onClick={() => {
                    setProductId(product.id.toString());
                  }}
                >
                  <ImageContainer alt="product-img" src={product.file} />
                </ProductContainer>
              );
            })}
          </Slider>
        ) : (
          <SkinLockParent>
            <SkinLockImageParent>
              <SkinLockImage src={PhotoGrid.SkinLock} alt="skin-lock" />
            </SkinLockImageParent>
            <SkinLockText>
              <FormattedMessage
                id={Message.SKIN_LOCK_TEXT}
                values={{ linebreak: <br /> }}
              />
            </SkinLockText>
          </SkinLockParent>
        )}
        <StyledSliderParent>
          <MuiSlider
            max={products.length - 1}
            min={0}
            value={selectedItem}
            onChange={(e, value) => {
              scrollRef?.current?.slickGoTo(value as number);
            }}
            componentsProps={{
              rail: {
                style: {
                  width: '167px',
                  height: '2px',
                  background: '#C4C4C4',
                  borderRadius: '30px',
                },
              },
              thumb: {
                style: {
                  width: `${167 / products.length}px`,
                  height: '2px',
                  borderRadius: '30px',
                  background: '#11516D',
                },
              },
              track: {
                style: {
                  width: '167px',
                  height: '2px',
                  background: '#C4C4C4',
                  borderRadius: '30px',
                  border: 'none',
                },
              },
            }}
          />
        </StyledSliderParent>
        <SectionTitle
          label={<FormattedMessage id={Message.IDEA_TAB_TITLE_TAB1} />}
          borderImage="linear-gradient(#AE8265 0%, #BE9A6D 7%, #DCC77C 21%,#EFE486 32%,#F7EF8A 39%,#F5EB86 52%,
            #EFE17D 62%,
            #E6D16C 71%,
            #DAB955 80%,
            #D4AE4A 84%,
            #C59D54 91%,
            #AE8265 100%)"
          center
        />
        <SliderParentSkin>
          <Slider
            ref={scrollRefSkin}
            {...settings}
            swipeToSlide={true}
            variableWidth={true}
            nextArrow={<NextArrowSkin />}
            prevArrow={<PrevArrowSkin />}
            afterChange={index => {
              setSelectedItemSkin(index);
            }}
          >
            {userSkins?.length &&
              userSkins.map(product => {
                return (
                  <SkinCardParent key={product.id}>
                    <SkinCard
                      key={product.name}
                      skin={product}
                      withSkinCategory
                      color={mainColor.current}
                    />
                  </SkinCardParent>
                );
              })}
          </Slider>
        </SliderParentSkin>
        {userSkins?.length && (
          <StyledSliderParentSkin>
            <MuiSlider
              max={userSkins?.length - 2}
              min={0}
              value={selectedItemSkin}
              onChange={(e, value) => {
                scrollRefSkin?.current?.slickGoTo(value as number);
              }}
              componentsProps={{
                rail: {
                  style: {
                    width: '167px',
                    height: '2px',
                    background: '#C4C4C4',
                    borderRadius: '30px',
                  },
                },
                thumb: {
                  style: {
                    width: `${167 / userSkins?.length}px`,
                    height: '2px',
                    borderRadius: '30px',
                    background: '#11516D',
                  },
                },
                track: {
                  style: {
                    width: '167px',
                    height: '2px',
                    background: '#C4C4C4',
                    borderRadius: '30px',
                    border: 'none',
                  },
                },
              }}
            />
          </StyledSliderParentSkin>
        )}

        <ProductPageModal
          product={productId}
          defaultOpen={Boolean(productId)}
          hideEveryButton={true}
        />
      </Container>
    </MainContainer>
  );
};

export default NewSkinMobile;
export { getPositionWiseImage };

const MainContainer = styled.div`
  .mobile-header {
    position: fixed;
    z-index: 10;
    background-color: #fff;
    width: 100%;
    height: 64px;
    margin: -24px 0;
  }
  .chips-container {
    margin: 0;
  }

  .slick-slide {
    max-width: 200px !important;
    width: 200px !important;
    margin-right: 16px;
    img {
      object-fit: contain;
    }
  }
  .slick-track {
    width: 100% !important;
    display: flex;
  }
`;

const Container = styled.div`
  margin: 24px;
  padding-top: 12px;
  padding-bottom: 64px;
  .section-title-wrapper {
    margin: 24px 0 16px !important;
  }
  .section-title-label {
    font-size: 20px;
    line-height: 24px;
    color: #282929;
    font-weight: ${({ theme }) => theme.dir === 'rtl' && 600};
  }
  .skin-name {
    color: #fc5c42;
    font-family: 'Roboto';
    font-weight: 500;
  }
  .buy-button {
    width: 100%;
    margin-top: 16px;
    height: 40px;
    border-radius: 10px;
    .MuiButton-label {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }
  }
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #fc5c42;
`;

const SubTitle = styled.div`
  margin-top: 8px;
  font-size: 16px;
  line-height: ${({ theme }) => (theme.dir === 'rtl' ? '21px' : '19px')};
  color: #282929;
  font-family: ${({ theme }) => theme.dir !== 'rtl' && 'Roboto condensed'};
`;

const HeaderParent = styled.div`
  display: flex;
`;

const LeftContainer = styled.div`
  width: 100%;
`;

const RightContainer = styled.div`
  height: 150px;
  ${rtl`
  float: right;
  margin-left: 8px;
    `}
`;

const FirstImageDiv = styled.div`
  height: 120px;
  width: 120px;
  position: relative;
  ::after {
    position: absolute;
    content: '';
    border: 4px solid white;
    height: 100%;
    width: 100%;
    left: 0;
    border-radius: 100%;
    opacity: 0.5;
  }
`;

const Image1 = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 50%;
`;

const SecondImageDiv = styled.div`
  display: flex;
  margin-top: -25px;
`;

const MainChildDiv = styled.div`
  height: 48px;
  width: 48px;
  ${rtl`margin-right: -12px;`}
  display: flex;
  position: relative;
  ::after {
    position: absolute;
    content: '';
    border: 4px solid white;
    height: 100%;
    width: 100%;
    left: 0;
    border-radius: 100%;
    opacity: 0.5;
  }
`;

const ReadMoreLink = styled.div`
  color: #11516d;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  font-family: ${({ theme }) => theme.dir !== 'rtl' && 'Roboto condensed'};
  margin-top: 4px;
  display: flex;
  align-items: center;
`;

const ReadText = styled.div`
  border-bottom: 1px solid #11516d;
`;

const AboutSubTitle = styled.div`
  font-size: 16px;
  line-height: ${({ theme }) => (theme.dir === 'rtl' ? '21px' : '19px')};
  color: #282929;
  font-family: ${({ theme }) => theme.dir !== 'rtl' && 'Roboto Condensed'};
`;

const SkinNameParent = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #3e3e3e;
  margin-top: 16px;
`;

const PriceParent = styled.div`
  margin-top: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #3e3e3e;
`;

const ArrowContainerSkin = styled(ArrowBase)<{
  prev: boolean;
  mediaLength?: number;
  currentValue: number;
  currentIndex: number;
}>`
  background: #ffffff;
  z-index: ${({ currentIndex, prev }) => {
    return currentIndex === 0 && prev ? -1 : 9999;
  }};
  display: ${({ mediaLength, prev, currentIndex }) => {
    return currentIndex === (mediaLength ?? 0) - 2 && !prev && 'none';
  }};
  position: absolute;
  top: 40%;
  ${({ theme, prev }) =>
    theme.dir === 'rtl'
      ? css`
          left: ${prev ? '75vw' : '0px'};
          transform: rotate(${prev ? 0 : 180}deg);
        `
      : css`
          right: ${prev ? '62' : '0'}px;
          left: ${prev && '0'}px;
          transform: rotate(${prev ? 180 : 0}deg);
        `}
`;

const ArrowContainer = styled(ArrowBase)<{
  prev: boolean;
  mediaLength?: number;
  currentValue: number;
  currentIndex: number;
}>`
  background: #ffffff;
  z-index: ${({ currentIndex, prev }) => {
    return currentIndex === 0 && prev ? -1 : 9999;
  }};
  display: ${({ mediaLength, prev, currentIndex }) => {
    return currentIndex === (mediaLength ?? 0) - 1 && !prev && 'none';
  }};
  position: absolute;
  top: 40%;
  ${({ theme, prev }) =>
    theme.dir === 'rtl'
      ? css`
          left: ${prev ? '75vw' : '0px'};
          transform: rotate(${prev ? 0 : 180}deg);
        `
      : css`
          right: ${prev ? '62' : '0'}px;
          left: ${prev && '0'}px;
          transform: rotate(${prev ? 180 : 0}deg);
        `}
`;

const ArrowIcon = styled(Icon).attrs({
  name: 'nav-arrow-right',
})<{ isOpen: boolean }>`
  position: relative;
  left: ${({ isOpen }) => (isOpen ? -4 : 4)}px;
  ${({ theme, isOpen }) => theme.utils.rotateIcon({ isOpen })}
`;

const ProductContainer = styled.div`
  height: 250px;
  width: 200px !important;
  cursor: pointer;
`;

const ImageContainer = styled.img`
  height: 100%;
  width: 100%;
`;

const SkinLockParent = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 250px;
  margin-top: 16px;
  text-align: center;
`;

const SkinLockImage = styled.img`
  height: 100%;
  width: 100%;
`;

const SkinLockText = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #282929;
  font-weight: ${({ theme }) => theme.dir !== 'rtl' && 'Roboto Condensed'};
  margin-top: 8px;
`;

const SkinLockImageParent = styled.div`
  height: 48px;
  width: 48px;
`;

const SliderParentSkin = styled.div`
  margin-top: -10px;
  .slick-slide {
    max-width: 304px !important;
    width: 304px !important;
    margin-right: 16px;
  }
  .slick-track {
    width: 100% !important;
    display: flex;
    /* gap: 16px; */
  }
  & .slick-list {
    @media (max-width: 768px) {
      margin: 0 0px;
    }
    @media (min-width: 769px) and (max-width: 1030px) {
      margin: 0 50px;
    }
    @media (min-width: 600px) and (max-width: 768px) {
      margin: 0 42px;
    }
  }
`;

const SkinCardParent = styled.div`
  width: 156px !important;
  height: 220px !important;
`;

const StyledSliderParent = styled.div`
  width: 167px;
  margin: auto;
  margin-top: 8px;
  .MuiSlider-root {
    padding: 0;
  }
  .MuiSlider-thumb.Mui-active {
    box-shadow: none;
  }
`;

const StyledSliderParentSkin = styled.div`
  width: 167px;
  margin: -4px auto 24px;
  .MuiSlider-root {
    padding: 0;
  }
  .MuiSlider-thumb.Mui-active {
    box-shadow: none;
  }
`;
