import * as actions from 'store/actions/@client/userInfo.actions';
import UserInfoType from 'store/constants/@client/userInfo.constants';
import * as Payload from 'store/types/@client/UserInfo.types';
import type { Property } from 'types/properties';

export interface UserInfoState {
  loading: Record<UserInfoType, boolean>;
  error: Record<UserInfoType, null | string>;
  recentProperty: Property | null;
}

const REQUESTS = [UserInfoType.GET_RECENT_PROPERTY_REQUEST];

const initialState: UserInfoState = {
  loading: REQUESTS.reduce(
    (state, next) => ({ ...state, [next]: false }),
    {} as Record<UserInfoType, boolean>
  ),
  error: REQUESTS.reduce(
    (state, next) => ({ ...state, [next]: null }),
    {} as Record<UserInfoType, null | string>
  ),
  recentProperty: null,
};

const userInfoReducer = (
  state: UserInfoState = initialState,
  action: actions.UserInfoActions
): UserInfoState => {
  switch (action.type) {
    case UserInfoType.GET_RECENT_PROPERTY_REQUEST:
      return updateLoading(state, UserInfoType.GET_RECENT_PROPERTY_REQUEST);
    case UserInfoType.GET_RECENT_PROPERTY_FULFILLED:
      return updateSuccess(
        state,
        UserInfoType.GET_RECENT_PROPERTY_REQUEST,
        (action as ReturnType<typeof actions.getRecentPropertyFulfilled>)
          .payload as Payload.getRecentPropertyFulfilledPayload
      );
    case UserInfoType.GET_RECENT_PROPERTY_REJECTED:
      return updateError(
        state,
        UserInfoType.GET_RECENT_PROPERTY_REQUEST,
        (action as ReturnType<typeof actions.getRecentPropertyRejected>)
          .payload as Payload.getRecentPropertyRejectedPayload
      );
    case UserInfoType.CLEAR_RECENT_PROPERTY:
      return { ...state, recentProperty: null };
    default:
      return state;
  }
};

export default userInfoReducer;

type requestType = UserInfoType.GET_RECENT_PROPERTY_REQUEST;

const updateLoading = (state: UserInfoState, requestType: requestType) => ({
  ...state,
  loading: {
    ...state.loading,
    [requestType]: true,
  },
  error: {
    ...state.error,
    [requestType]: null,
  },
});

type ErrorPayload = Payload.getRecentPropertyRejectedPayload;
const updateError = (
  state: UserInfoState,
  requestType: requestType,
  { error }: ErrorPayload
) => ({
  ...state,
  loading: {
    ...state.loading,
    [requestType]: false,
  },
  error: {
    ...state.error,
    [requestType]: error,
  },
});

type SuccessPayload = Payload.getRecentPropertyFulfilledPayload;
const updateSuccess = (
  state: UserInfoState,
  requestType: requestType,
  payload: SuccessPayload
) => ({
  ...state,
  loading: {
    ...state.loading,
    [requestType]: false,
  },
  error: {
    ...state.error,
    [requestType]: null,
  },
  recentProperty: payload,
});
