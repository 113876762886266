import * as React from 'react';
import styled, { css } from 'styled-components';
import rtl from 'styled-components-rtl';
import Icon from 'components/Icon';

export const ArrowBase = styled.div.attrs({ role: 'button' })<{
  isOpen: boolean;
  locale?: boolean;
}>`
  width: 48px;
  height: 48px;
  border-radius: 48px;
  border: 1.5px solid #d9d9d9;
  box-sizing: border-box;
  position: absolute;
  cursor: pointer;
  ${({ theme }) => theme.utils.centerFlex};
  transition: all 0.3s;
  top: -17%;
  ${({ isOpen }) => rtl`
  right:${isOpen ? '82px' : '20px'}`}

  ${({ locale }) =>
    locale &&
    css`
      right: inherit;
    `}
`;

interface Props {
  isOpen: boolean;
  onClick: () => void;
  title: string;
  className?: string;
  disabled?: boolean;
}

const ArrowIcon = styled(Icon).attrs({
  name: 'nav-arrow-right',
  color: 'rgba(41, 41, 41, 0.9)',
})<Props>`
  ${({ theme, isOpen }) =>
    theme.utils.rotateIcon({ isOpen: theme.dir === 'rtl' ? !isOpen : isOpen })};
  position: relative;
  ${({ disabled }) =>
    disabled &&
    css`
      fill: #d9d9d9;
    `}/* top: 10%; */
  /* right: 10%; */
  /* ${({ isOpen }) => rtl`
    left: ${isOpen ? -4 : 4}px;
  `}; */
  /* ${({ isOpen }) => rtl`
    ${isOpen ? 'margin-right:  8px' : 'margin-left: 8px'}
  `}; */
`;

const Arrow: React.FC<Props> = ({ isOpen, ...props }) => (
  <ArrowBase isOpen={isOpen} {...props}>
    <ArrowIcon isOpen={isOpen} disabled={props.disabled} />
  </ArrowBase>
);

type Params = (
  clickHandler: () => void,
  hasNext: boolean,
  label: string
) => React.ReactNode;

export const renderArrowNext: Params = (clickHandler, hasNext, label) => (
  <Arrow
    onClick={clickHandler}
    title={label}
    isOpen={false}
    className="next-arrow"
    disabled={!hasNext}
  />
);

export const renderArrowPrev: Params = (clickHandler, hasNext, label) => (
  <Arrow
    onClick={clickHandler}
    title={label}
    isOpen={true}
    className="prev-arrow"
    disabled={!hasNext}
  />
);
