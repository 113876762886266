import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import styled from 'styled-components';
import { TABLET_RESOLUTION } from 'styles/sharedStyle';
import { useRouter } from 'hooks';
import { ClientRouter } from 'router/routes';
import type { Skin } from 'types/properties';
import { toCurrencyString } from 'utils/string.utils';
import no_image_placeholder from 'assets/images/client-products/no_image.png';
import PinterestIdeasCardMobile from 'components/@client/PinterestIdeasCardMobile';
import Loader from './components/SkinsLoader';

interface Props {
  isLoading: boolean;
  groupedSkinListItems: Skin[];
}

const SkinsContainerMobile: React.FC<Props> = ({
  groupedSkinListItems,
  isLoading,
}) => {
  const { history } = useRouter();
  const convertPrice = React.useCallback((price, country) => {
    return price > 0 ? (
      `${toCurrencyString({
        value: price,
        country,
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
      })}`
    ) : (
      <FormattedMessage id={Message.SKIN_CARD_PRICE_FREE} />
    );
  }, []);

  return (
    <>
      <MyDivContainer>
        {isLoading ? (
          <Loader />
        ) : (
          <List>
            {groupedSkinListItems.map((e: Skin, i: number) => {
              return (
                <MyGridItem
                  key={`${e.id + i.toString()}`}
                  className="photo-item"
                >
                  <PinterestIdeasCardMobile
                    image={e?.mobileThumbnail ?? no_image_placeholder}
                    userName={e?.user?.name ?? ''}
                    name={e.name.toString()}
                    price={convertPrice(e.price, e.country)}
                    date={e?.createdAt}
                    chipsData={e.chipsData}
                    skinDesignedByText={
                      <FormattedMessage
                        id={Message.SKIN_DESIGNED_BY_TEXT}
                        values={{ value: e?.user?.name ?? '' }}
                      />
                    }
                    roomsData={
                      e?.rooms?.map(a => ({
                        id: a.id.toString(),
                        name: a.name,
                      })) ?? []
                    }
                    skinCategoryName={e?.skinCategory}
                    onClick={() => {
                      history.push(
                        ClientRouter.SKIN.replace(':id', e?.id.toString() ?? '')
                      );
                    }}
                  />
                </MyGridItem>
              );
            })}
          </List>
        )}
      </MyDivContainer>
    </>
  );
};

export default React.memo(SkinsContainerMobile);

const MyGridItem = styled.div`
  width: 156px;
  /* max-width: 327px; */
  height: fit-content;
  /* min-height: 285px; */
  /* margin-bottom: 16px; */
`;

const MyDivContainer = styled.div`
  margin-top: 48px;
  background: #fff;
  width: 100%;
  @media ${TABLET_RESOLUTION} {
    margin: 24px 0 120px;
    padding: 0 24px;
  }
`;

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  row-gap: 8px;
`;
