import { NotificationAction } from 'store/actions/notifications.actions';
import { Notification } from 'store/constants/notifications.constants';
import { ShowNotificationPayload } from 'store/types/notification.types';

export interface NotificationsState
  extends Record<string, ShowNotificationPayload | null> {}

const initialState: NotificationsState = {};

const notificationsReducer = (
  state: NotificationsState = initialState,
  action: NotificationAction
): NotificationsState => {
  switch (action.type) {
    case Notification.SHOW_NOTIFICATION:
      return {
        ...state,
        [(action.payload as ShowNotificationPayload).key]: {
          ...(action.payload as ShowNotificationPayload),
        },
      };
    case Notification.CLOSE_NOTIFICATION:
      return {
        ...state,
        [action.payload as string]: null,
      };
    case Notification.RESET_NOTIFICATIONS:
      return initialState;
    default:
      return state;
  }
};

export default notificationsReducer;
