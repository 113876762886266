import * as React from 'react';
import { CartItem as ICartItem } from 'types/properties';
import styled, { css } from 'styled-components';
import rtl from 'styled-components-rtl';
import { MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';
import { useFormattedCurrency } from 'hooks';

interface Props extends ICartItem {
  dense?: boolean;
  isLoading?: boolean;
  onQuantityChange?: (id: string, quantity: number) => void;
  onDelete?: (id: string) => void;
}

const IframeCartItem: React.FC<Props> = ({ quantity, product }) => {
  const price = useFormattedCurrency(product?.finalPrice * quantity);

  return (
    <Container>
      <ItemContainer>
        <ItemImg
          src={product.thumbnail?.file}
          alt={product.altName ?? product.englishName}
        />
        <VerticalBorder />
        <ItemInfo>
          <h4>{product?.altName ?? product?.englishName}</h4>
          <SerialNumber>Serial number: {product?.sku}</SerialNumber>
          <PriceAndQuantity>
            <Price>{price}</Price>
            <span>x{quantity}</span>
          </PriceAndQuantity>
        </ItemInfo>
      </ItemContainer>
      <StyledHr />
    </Container>
  );
};
export default IframeCartItem;

const Container = styled.li.attrs({ className: 'cart-item' })`
  position: relative;
  & img {
    height: 100%;
  }
  & .cart-item-actions {
    margin-top: 14px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  & h4 {
    color: ${({ theme }) => theme.colors.black0};
    font-size: 1.125rem;
    font-weight: 500;
    margin: 0;
    padding: 0;

    @media (min-width: 1150px) {
      font-size: 1.5rem;
    }
  }

  & p {
    font-weight: 500;
    font-size: 0.875rem;
    margin: 4px 0 0 0;
    padding: 0;

    @media (min-width: 1150px) {
      font-size: 1.375rem;
    }
  }

  & .design-style {
    color: rgba(187, 187, 187, 1);
    font-weight: 400;

    @media (min-width: 1150px) {
      font-size: 1.125rem;
    }
  }

  & .x-button {
    position: absolute;
    top: 12px;
    right: 12px;
  }

  ${css`
    margin: 0 50px;
    max-width: 370px;
    h4,
    p {
      font-size: 1rem !important;
    }

    img {
      width: 103px;
    }
  `}
`;

const ItemContainer = styled.div`
  display: flex;
  padding: 12px;
`;

const ItemImg = styled.img`
  height: 160px;
  width: 215px;
  object-fit: cover;

  @media ${MOBILE_TABLET_RESOLUTION} {
    width: 103px;
    height: 94px;
  }
`;

const ItemInfo = styled.div``;

const VerticalBorder = styled.div`
  border-right: 0.5px solid #b5b5b5;
  opacity: 1;
  margin: 0 20px;
`;

const SerialNumber = styled.div`
  font-size: 0.75rem;
  margin: 8px 0;
`;

const PriceAndQuantity = styled.div`
  font-size: 0.75rem;
`;

const Price = styled.span`
  color: #ff3200;
  font-weight: bold;
  ${rtl`
    margin-right: 8px;
  `}
  font-size: 1rem;
`;

const StyledHr = styled.hr.attrs({ className: 'card-divider' })`
  height: 0.3px;
  border-right: 0.5px solid #b5b5b5;
  opacity: 1;
  max-width: 550px;
`;
