import * as React from 'react';
import { Dialog } from '@material-ui/core';
import styled from 'styled-components';
import Button from 'components/@client/Button/Button';
import { Link } from 'react-router-dom';
import { ClientRouter } from 'router/routes';
import { Message } from 'i18n';
import { FormattedMessage } from 'react-intl';

const ConfirmationDialog: React.FC<{
  isOpen: boolean;
  toggle: VoidFunction;
}> = ({ isOpen, toggle }) => {
  return (
    <CustomDiv open={isOpen}>
      <div className="title">
        <FormattedMessage id={Message.ONBOARDING_LEAVE_CINFIRM_TITLE} />
      </div>
      <div className="subtitle1">
        <FormattedMessage id={Message.ONBOARDING_LEAVE_CONFIRM_SUBTITLE1} />
      </div>
      <div className="subtitle2">
        <FormattedMessage id={Message.ONBOARDING_LEAVE_CONFIRM_SUBTITLE2} />
      </div>
      <Button className="primary-btn" onClick={toggle}>
        <FormattedMessage
          id={Message.OONBOARDING_LEAVE_CONFIRMATION_STAY_BUTTON}
        />
      </Button>
      <Link to={ClientRouter.APP} className="redirect-link">
        <FormattedMessage
          id={Message.OONBOARDING_LEAVE_CONFIRMATION_LEAVE_BUTTON}
        />
      </Link>
    </CustomDiv>
  );
};

const CustomDiv = styled(Dialog)`
  .MuiPaper-root {
    width: 537px;
    height: 232px;
    background: #ffffff;
    /* Popup */

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    @media (max-width: 767px) {
      width: 327px;
      height: 213px;
      margin: 0;
    }
  }
  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    /* identical to box height */
    margin: auto;
    margin-top: 24px;
    margin-bottom: 0;

    /* Primary Color */

    color: #fc5c42;
    @media (max-width: 767px) {
      font-weight: 500;
      font-size: 20px;
    }
  }
  .subtitle1 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    margin: auto;
    margin-top: 16px;
    margin-bottom: 0;

    /* First level text */

    color: #282929;
    @media (max-width: 767px) {
      font-weight: 400;
      font-size: 14px;
    }
  }
  .subtitle2 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    margin: auto;
    margin-top: 8px;
    margin-bottom: 0;

    /* First level text */

    color: #282929;
    @media (max-width: 767px) {
      font-weight: 400;
      font-size: 14px;
    }
  }
  .primary-btn {
    /* Primary Color */

    background: #fc5c42;
    /* Primary Color */
    margin-top: 16px;
    border: 1px solid #fc5c42;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 24px;
    gap: 10px;
    @media (max-width: 767px) {
      width: 156px;
      height: 40px;
    }
  }
  .redirect-link {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-decoration-line: underline;

    /* Third text level */
    margin: 16px auto 0;
    color: #696969;
  }
`;

export default ConfirmationDialog;
