import * as React from 'react';
import {
  useGoogleLogin,
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import { googleClientId } from 'constants/config';
import { useDispatch } from 'store/selectors';
import * as Authentication from 'store/actions/authentication.actions';
import { OnboardingFields } from 'store/types/authentication.types';
import { useNotifications, useRouter } from 'hooks';
import { ClientRouter } from 'router/routes';

type GoogleError = {
  error?: string;
  details?: string;
};

const useGoogleProvider = (onboardingFields?: OnboardingFields) => {
  const dispatch = useDispatch();
  const { showNotification } = useNotifications();
  const { query } = useRouter<{ redirect: string }>();

  const onSuccess = React.useCallback(
    (res: GoogleLoginResponse | GoogleLoginResponseOffline) => {
      if (!query.redirect) {
        dispatch(
          Authentication.loginWithProviderRequest({
            backend: 'google-oauth2',
            token: (res as GoogleLoginResponse).tokenId,
            onboardingFields,
          })
        );
      } else {
        dispatch(
          Authentication.redirectLoginWithProviderRequest({
            redirect: ClientRouter.CART,
            registerPayload: {
              backend: 'google-oauth2',
              token: (res as GoogleLoginResponse).tokenId,
              onboardingFields,
            },
          })
        );
      }
    },
    [dispatch, onboardingFields, query.redirect]
  );

  const onFailure = React.useCallback(
    (e: Error | GoogleError) => {
      const { error = '', details } = (e as GoogleError) ?? {};

      const isIncognito =
        details === 'Cookies are not enabled in current environment.';

      const ignoredErrors = [
        'popup_closed_by_user',
        ...(isIncognito ? [] : ['idpiframe_initialization_failed']),
      ];

      const errorMessage = isIncognito
        ? 'Google login will not work using incognito or cookies disabled'
        : 'An error has been occurred with Google login, please try again later';

      if (!ignoredErrors.includes(error)) {
        showNotification({
          key: 'login/google',
          message: errorMessage,
          severity: isIncognito ? 'warning' : 'error',
        });
      }
    },
    [showNotification]
  );

  const hostName = `${window.location.protocol}//${window.location.host}`;

  const { signIn } = useGoogleLogin({
    clientId: googleClientId,
    redirectUri: hostName,
    onSuccess,
    onFailure,
  });

  return signIn;
};

export default useGoogleProvider;
