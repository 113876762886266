import { NO_VALUE } from 'constants/common.constants';
import countries, { ISOCode } from 'data/countries';
import { SortingRule } from 'react-table';

export const transformByCountryCode = (code: ISOCode) =>
  countries[code] ?? NO_VALUE;

export const sortByMap = <T>(
  map: Record<keyof T, string>,
  sortBy?: SortingRule<T>
) =>
  !sortBy
    ? {}
    : {
        direction: sortBy.desc ? 'descending' : 'ascending',
        ordering: map[sortBy.id as keyof T] || sortBy.id,
      };

export const newSortByMap = <T>(
  map: Record<keyof T, string>,
  sortBy?: SortingRule<T>
) =>
  !sortBy
    ? {}
    : {
        ordering: sortBy.desc
          ? `${
              map[sortBy.id as keyof T]
                ?.split(',')
                .map(e => `-${e}`)
                .join(',') ||
              sortBy.id
                .split(',')
                .map(e => `-${e}`)
                .join(',')
            }`
          : map[sortBy.id as keyof T] || sortBy.id,
      };
