import { createGlobalStyle, css } from 'styled-components';
import rtl from 'styled-components-rtl';
import { scrollbar } from './sharedStyle';

const GlobalStyle = createGlobalStyle<{ enableZoom?: boolean }>`
  html {
    height: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: overlay;
    scroll-behavior: smooth;
    ${({ theme }) => theme.utils.scrollbar}
  }

  *, *:before, *:after {
  box-sizing: inherit;
  // Remove weird background color when clicking the button in mobile
  -webkit-tap-highlight-color: transparent;
  }
  
  body {
    ${({ enableZoom }) =>
      !enableZoom &&
      css`
        touch-action: pan-x pan-y;
      `}
    direction: ${({ theme }) => theme.dir};
    margin: 0;
    background: #fcfcfc;
    font-family: ${({ theme }) =>
      theme.dir === 'rtl'
        ? 'Assistant'
        : 'Roboto'}, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
      div {
        ${({ enableZoom }) =>
          !enableZoom &&
          css`
            touch-action: pan-x pan-y;
          `}
      }
  }
  
  ul {
    ${rtl`
      padding-left: 0;
    `}
  }
  input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

  #backdrop {
    z-index: 1100;
    height: 100vh;
  }

  #overlay {
    ${({ theme }) => theme.utils.centerFlex};
    height: 100vh;
    width: 100vw;
    position: absolute;
    margin: auto;
  }

  .capitalize {
    text-transform: capitalize;
  }
  .MuiPaper-root {
    ${scrollbar}
    
  }

  .idea-tooltip-popper{
    z-index:5 !important;
  }

  .pac-container {
    border-radius: 6px;
    transform: translate(0, 10px);
    box-shadow: none;
    border: 1px solid ${({ theme }) => theme.colors.darkGray};
    &::after {
      display: none;
    }
    & .pac-item {
      cursor: pointer;
      height: 40px;
      display: flex;
      align-items: center;
      border-top: none;
      padding-left: 16px;
      & .pac-icon-marker {
        display: none;
      }
    }
  }

 
  .MuiPickersDay-root.Mui-selected{
    background-color:${({ theme }) =>
      theme.colors.primary.lightMobile} !important;
  }

`;

export default GlobalStyle;
