import * as React from 'react';
import useFetch from 'hooks/useFetch';
import { KPI } from 'types/common.types';
import { DatabaseStatus, ProductGridNames } from 'types/products';
import { getEnumKeyByValue } from 'utils/common.utils';

const useProductKPIs = (params: object = {}) => {
  const [KPIData, setKPIData] = React.useState<KPI[]>([]);

  const onSuccess = React.useCallback((kpis: Record<string, number> | null) => {
    const keys = getEnumKeyByValue(DatabaseStatus);
    setKPIData(
      Object.entries(kpis ?? {}).map(([key, value]) => ({
        name: ProductGridNames[key as keyof typeof ProductGridNames],
        label: keys.get(key) ?? '',
        value,
        unit: 'products',
      }))
    );
  }, []);
  const { isLoading, callFetch: refetchKPIs } = useFetch<
    Record<string, number>
  >({
    initialUrl: '/api/products_tasks/summary/',
    config: { params },
    onSuccess,
  });

  return { KPIData, isLoading, refetchKPIs };
};

export default useProductKPIs;
