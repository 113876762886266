import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Check } from '@material-ui/icons';
import Logo from 'components/Logo';
import { useOnboarding, useOnboardingState } from 'context/onboardingContext';
import { useRouter } from 'hooks';
import { Message } from 'i18n';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { ClientRouter } from 'router/routes';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import {
  LAPTOP_MAX_RESOLUTION,
  LAPTOP_MIN_RESOLUTION,
  MOBILE_RESOLUTION,
  MOBILE_TABLET_RESOLUTION,
} from 'styles/sharedStyle';
import ConfirmationDialog from '../components/ConfirmationDialog';
import useQuizQuestion from '../quiz-question/useQuizQuestion';

const Container: React.FC<{
  className?: string;
  withLogin?: boolean;
}> = ({ children, className, withLogin }) => {
  const isMobile = useMediaQuery('(max-width: 767px)');
  const { location } = useRouter();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = React.useState(false);
  const [designConfirmation] = React.useState(false);
  const { question, progress, progressStep } = useQuizQuestion();
  const [isDesignQuizStarted, setIsDesignQuizStarted] = React.useState(false);
  const { isPremadeProperty } = useOnboardingState();
  const designStyleQuiz = React.useMemo(
    () => question?.question.includes('color'),
    [question]
  );
  const [{ choosePlan }] = useOnboarding();

  React.useEffect(() => {
    if (
      (location.pathname === ClientRouter.QUIZ_QUESTION &&
        !question?.question.includes('room') &&
        question?.question !== 'What is your budget?') ||
      location.pathname === ClientRouter.ADDITIONAL_INFO
    ) {
      setIsDesignQuizStarted(true);
    }
  }, [location.pathname, question?.question, isDesignQuizStarted, choosePlan]);

  const currentProgress = React.useMemo(() => {
    if (location.pathname === ClientRouter.UPLOAD_PLAN) return 0;
    if (location.pathname === ClientRouter.PLAN_CONFIRMED) return 1;
    if (location.pathname === ClientRouter.ADDITIONAL_INFO) return 6;
    if (question?.question.includes('room')) return 2;
    if (question?.question === 'What is your budget?') return 3;
    if (question?.question === 'Select your view') return 5;
    if (question?.question === 'Choose your Design style' && isPremadeProperty)
      return 2;
    if (question?.question === 'Choose your Design style' && !isPremadeProperty)
      return 4;
    return 6;
  }, [location.pathname, question, isPremadeProperty]);

  const tooltipData = React.useMemo(() => {
    if (currentProgress === 0)
      return (
        <ToolTipContaier choosePlan={choosePlan}>
          {/* <div className="title">
            <FormattedMessage id={Message.ONBOARDING_TOOLTIP_STEP_1} />
          </div> */}
          <div className="subTitle">
            <FormattedMessage
              id={Message.ONBOARDING_TOOLTIP_FLOOR_PLAN_SELECT}
            />
          </div>
        </ToolTipContaier>
      );
    if (currentProgress === 1)
      return (
        <ToolTipContaier
          choosePlan={choosePlan}
          // style={{ marginTop: isMobile ? 6 : 12 }}
        >
          <div className="subTitle">
            <FormattedMessage
              id={Message.ONBOARDING_TOOLTIP_FLOOR_ROOM_CONFIRM}
            />
          </div>
        </ToolTipContaier>
      );

    if (currentProgress === 2) {
      if (isPremadeProperty)
        return (
          <ToolTipContaier
            choosePlan={choosePlan}
            // style={{ marginTop: isMobile && !choosePlan ? 10 : 0 }}
          >
            {/* {choosePlan && (
              <div className="title">
                <FormattedMessage
                  id={Message.ONBOARDING_TOOLTIP_DESIGN_STYLE}
                />
              </div>
            )} */}
            {!isMobile && choosePlan ? (
              <div className="subTitle" style={{ marginTop: 0 }}>
                <FormattedMessage id={Message.SKIN_FILTER_BY_DESIGN_STYLE} />
              </div>
            ) : (
              <div
                className="subTitle"
                // style={{ marginTop: isMobile ? 0 : 20 }}
              >
                <FormattedMessage id={Message.SKIN_FILTER_BY_DESIGN_STYLE} />
              </div>
            )}
          </ToolTipContaier>
        );
      return (
        <ToolTipContaier
          choosePlan={choosePlan}
          // style={{ marginTop: isMobile && !choosePlan ? 10 : 0 }}
        >
          {choosePlan ? (
            <div className="subTitle">
              <FormattedMessage
                id={Message.ONBOARDING_TOOLTIP_FLOOR_ROOM_SELECT}
              />
            </div>
          ) : (
            <div
              className="subTitle"
              // style={{ marginTop: !isMobile && !choosePlan ? 20 : 0 }}
            >
              <FormattedMessage
                id={Message.ONBOARDING_TOOLTIP_FLOOR_ROOM_SELECT}
              />
            </div>
          )}
        </ToolTipContaier>
      );
    }

    if (currentProgress === 3)
      return (
        <ToolTipContaier
          choosePlan={choosePlan}
          // style={{ marginTop: isMobile && !choosePlan ? 10 : 0 }}
        >
          {/* {choosePlan && (
            // <div className="title">
            //   <FormattedMessage id={Message.ONBOARDING_TOOLTIP_DESIGN_STYLE} />
            // </div>
          )} */}
          {choosePlan ? (
            <div className="subTitle">
              <FormattedMessage id={Message.ONBOARDING_TOOLTIP_BUDGET_SELECT} />
            </div>
          ) : (
            <div
              className="subTitle"
              // style={{ marginTop: !isMobile && !choosePlan ? 20 : 0 }}
            >
              <FormattedMessage id={Message.ONBOARDING_TOOLTIP_BUDGET_SELECT} />
            </div>
          )}
        </ToolTipContaier>
      );
    if (currentProgress === 4)
      return (
        <ToolTipContaier
          choosePlan={choosePlan}
          // style={{ marginTop: isMobile && !choosePlan ? 10 : 0 }}
        >
          {/* {choosePlan && (
              <div className="title">
                <FormattedMessage
                  id={Message.ONBOARDING_TOOLTIP_DESIGN_STYLE}
                />
              </div>
            )} */}
          {!isMobile && choosePlan ? (
            <div className="subTitle" style={{ marginTop: 0 }}>
              <FormattedMessage id={Message.SKIN_FILTER_BY_DESIGN_STYLE} />
            </div>
          ) : (
            <div
              className="subTitle"
              // style={{ marginTop: isMobile ? 0 : 20 }}
            >
              <FormattedMessage id={Message.SKIN_FILTER_BY_DESIGN_STYLE} />
            </div>
          )}
        </ToolTipContaier>
      );

    if (currentProgress === 5)
      return (
        <ToolTipContaier
          choosePlan={choosePlan}
          // style={{ marginTop: isMobile && !choosePlan ? 10 : 0 }}
        >
          {/* {choosePlan && (
              <div className="title">
                <FormattedMessage
                  id={Message.ONBOARDING_TOOLTIP_DESIGN_STYLE}
                />
              </div>
            )} */}
          {!isMobile && choosePlan ? (
            <div className="subTitle" style={{ marginTop: 0 }}>
              <FormattedMessage id={Message.VIEW_SELECTION} />
            </div>
          ) : (
            <div
              className="subTitle"
              // style={{ marginTop: isMobile ? 0 : 20 }}
            >
              <FormattedMessage id={Message.VIEW_SELECTION} />
            </div>
          )}
        </ToolTipContaier>
      );

    return (
      <ToolTipContaier
        choosePlan={choosePlan}
        // style={{ marginTop: isMobile ? 10 : 20 }}
      >
        <div className="title">
          <FormattedMessage id={Message.ONBOARDING_TOOLTIP_MAKE_KEY} />
        </div>
      </ToolTipContaier>
    );
  }, [currentProgress, isMobile, choosePlan, isPremadeProperty]);
  return (
    <OuterContainer>
      {!isMobile && (
        <CustomLink onClick={() => setIsConfirmModalOpen(true)}>
          <Logo className="brand-logo" />
        </CustomLink>
      )}
      {location.pathname !== ClientRouter.ONBOARDING &&
        location.pathname !== ClientRouter.DONE && (
          <ProgressBarContainer
            progress={currentProgress}
            progressStep={progressStep}
            progressPixel={progress}
            choosePlan={choosePlan}
            designStyleQuiz={designStyleQuiz}
            designConfirmation={designConfirmation}
            isMobile={isMobile}
          >
            <div className="tooltip">{tooltipData}</div>

            <ProgressStep
              style={{
                position: 'absolute',
                top: '0',
                zIndex: 11111,
                color: '#11516D',
              }}
              hasCheck={isDesignQuizStarted}
            >
              {isDesignQuizStarted ? (
                <CustomTick>
                  <Check />
                </CustomTick>
              ) : (
                <div />
              )}
            </ProgressStep>
            <LinkDiv
              progress={currentProgress}
              progressStep={progressStep}
              progressPixel={progress}
              choosePlan={choosePlan}
              designStyleQuiz={designStyleQuiz}
              designConfirmation={designConfirmation}
            >
              <div />
            </LinkDiv>
            <ProgressStep hasCheck={false}>
              {location.pathname === ClientRouter.ADDITIONAL_INFO && <div />}
            </ProgressStep>
            <ProgressStep
              style={{
                position: 'absolute',
                top: '0',
              }}
              hasCheck={location.pathname === ClientRouter.ADDITIONAL_INFO}
            >
              {location.pathname === ClientRouter.ADDITIONAL_INFO && (
                <CustomTick>
                  <Check />
                </CustomTick>
              )}
              {isDesignQuizStarted &&
                location.pathname !== ClientRouter.ADDITIONAL_INFO && <div />}
            </ProgressStep>
          </ProgressBarContainer>
        )}
      <InnerContainer className={className}>{children}</InnerContainer>
      <ConfirmationDialog
        isOpen={isConfirmModalOpen}
        toggle={() => setIsConfirmModalOpen(prev => !prev)}
      />
      {/* {!isMobile && withLogin && !isAuthenticated && (
        <Text className="login-link">
          <FormattedMessage id={Message.UPLOAD_PLAN_ALREADY_USER} />{' '}
          <Link to={AuthRoute.LOGIN}>
            {' '}
            <FormattedMessage id={Message.UPLOAD_PLAN_LOGIN_LINK} />
          </Link>
        </Text>
      )} */}
    </OuterContainer>
  );
};

export default Container;

const ToolTipContaier = styled.div<{ choosePlan?: boolean }>`
  margin: auto;
  .title {
    color: #282929;
    font-weight: 500;
    font-size: 14px;

    // margin-top: 8px;
    @media (max-width: 767px) {
      margin-top: 3px;
    }
  }
  .subTitle {
    font-weight: 400;
    font-size: 14px;

    text-align: center;

    /* Third text level */

    color: #696969;
    @media (max-width: 767px) {
      font-weight: ${({ theme }) => (theme.dir === 'rtl' ? 600 : 400)};
      font-size: 11px;
      width: 68px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      /* margin: 3px auto; */
    }
  }

  &::before {
    border-left: solid transparent 10px;
    border-right: solid transparent 10px;
    border-top: solid #fff 10px;
    bottom: -7px;
    content: ' ';
    height: 0;
    left: 50%;
    margin-left: -13px;
    position: absolute;
    width: 0;
    z-index: 9;
  }
`;
const CustomTick = styled.div`
  width: 100% !important;
  height: 100% !important;
  background: #11516d;
  border: 1px solid #11516d !important;
  border-radius: 12px !important;
  margin: 0 !important;
  /* Progress bar */

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25) !important;
  svg {
    width: 20px;
    ${rtl` margin-left: -1px;`}
    height: 19px;
    margin-top: -1px;
    path {
      fill: white;
    }
    @media (max-width: 767px) {
      width: 17px !important;
      ${rtl` margin-left: -3px;`}
      margin-bottom: 14px !important;
      margin-top: -2px;
      height: 14px !important;
    }
  }
`;
const InnerContainer = styled.section`
  & .progress-bar {
    width: 65%;
    margin: 30px auto 0 auto;

    @media (min-width: 1150px) {
      width: 15%;
      ${rtl`
          margin: auto 0 auto auto;
      `}
    }
  }

  & .file-drop-zone {
    padding: 0 28px;
    /* margin: 40px auto 0 auto; */
  }

  & .fade-effect {
    gap: 50px;
    position: relative;
    ::after {
      position: absolute;

      ${rtl`
          right: 0;
          background: linear-gradient(
        to right,
        rgba(254, 254, 254, 0.4),
        rgba(254, 254, 254, 0.9)
      );
      `}
      top: 0;
      content: '';
      width: 50px;
      height: 100%;
    }
  }

  /* & .actions-container {
    margin-top: 80px;
    @media (min-height: 700px) {
      margin-top: 80px;
    }

    @media (min-width: 1150px) {
      margin-top: 60px;
    }

    @media (min-width: 1150px) and (max-height: 800px) {
      margin-top: 20px;
    }
  } */

  /* @media (min-width: 1150px) {
    padding: 30px 0 0 0;
    margin: 40px 250px 0 250px;
    max-width: unset;
    border-radius: 25px;
    box-shadow: 0px 10px 17.869px rgba(0, 0, 0, 0.0417275),
      0px -3px 10.0172px rgba(0, 0, 0, 0.035),
      0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
      0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
  } */
`;

const OuterContainer = styled.div.attrs({ className: 'outer-container' })`
  position: relative;
  max-width: 1600px;
  margin: 0 auto;
  margin-bottom: 15px;
  & > a {
    position: absolute;
    left: 0px;
    top: 0px;
  }
  /*Mood board page*/

  .main {
    margin: 0 auto;
    width: 328px;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    @media (max-width: 767px) {
      width: 328px;
      justify-content: space-between;
      margin-right: 0;
      margin-left: 0;
    }
  }

  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  .container {
    max-width: 1290px;
  }

  .mood-board-part1-wrapper {
    width: 20%;
  }

  .desk-mood-board {
    margin: 50px auto;
    @media ${LAPTOP_MAX_RESOLUTION} and ${LAPTOP_MIN_RESOLUTION} {
      padding: 0 20px;
    }
  }

  .mood-board-part1-wrapper img,
  .sub-wrapper img {
    width: 258.78px;
    height: 203.68px;
    border-radius: 15px;
    margin: 1px 0px 1px 0px;
  }

  .mood-board-part2-wrapper {
    width: 20%;
  }

  .mood-board-part2-wrapper .sub-wrapper {
    width: 100%;
  }

  .mood-board-part2-wrapper:last-child {
    width: 40%;
  }

  .mood-board-part2-wrapper .last-sub-wrapper {
    width: 100%;
    display: flex;
  }

  .mood-board-part2-wrapper .last-sub-wrapper .sub-sub-wrap {
    width: 100%;
    margin-top: 5px;
    margin-right: 5px;
  }

  .mood-board-part2-wrapper .last-sub-wrapper .sub-sub-wrap img {
    width: 258.78px;
    height: 203.68px;
  }

  .txt-mmodboard {
    border: 3px solid #fc5c42;
    border-radius: 15px;
    position: relative;
    width: 252.78px;
    height: 198.68px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 7px;
  }

  .txt-mmodboard p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    color: #fc5c42;
  }

  .txt-mmodboard img {
    width: 100% !important;
    height: initial !important;
    position: absolute;
    top: 120px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  .mood-board-part2-wrapper .part2-wrapper-sub img {
    width: 258.78px;
    height: 415.84px;
  }

  .round-wrapper {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: -35px;
    left: 0;
    right: 0;
  }

  .round-wrapper .round {
    border: 4px solid #ffffff;
    width: 70px;
    height: 70px;
    border-radius: 68px;
    margin-right: 24px;
    cursor: pointer;
  }

  .round-wrapper .round-1 {
    background-color: #383931;
  }

  .round-wrapper .round-2 {
    background-color: #646756;
  }

  .round-wrapper .round-3 {
    background-color: #ccbcad;
  }

  .round-wrapper .round-4 {
    background-color: #907e68;
  }

  .round-wrapper .round-5 {
    background-color: #a0552b;
  }

  .back-next-btn .back-btn {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #696969;
    width: 134px;
    height: 48px;
    border-radius: 15px;
    background-color: transparent;
    border: 1px solid #696969;
    justify-content: center;
    cursor: pointer;
  }

  .back-next-btn .next-btn {
    width: 134px;
    height: 48px;
    background: #fc5c42;
    border: 1px solid #fc5c42;
    border-radius: 15px;
    margin-left: 250px;
    font-size: 18px;
    cursor: pointer;
  }

  .mood-board-main-wrapper {
    position: relative;
  }

  .back-next-btn {
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
  }

  .mood-board-part2last-wrapper {
    width: 40%;
  }

  .mood-board-part2last-wrapper .last-sub-wrapper {
    width: 100%;
    display: flex;
  }

  .last-sub-wrapper .sub-sub-wrap {
    width: 50%;
  }

  .last-sub-wrapper .sub-sub-wrap img {
    width: 258.78px;
    height: 203.68px;
    margin-top: 7px;
  }

  .res-mood-board {
    display: none;
  }

  /*sign-up page*/

  .sign-up {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
  }

  .key-creation {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .key-creation span {
    color: #fc5c42;
    font-size: 34px;
    margin-left: 10px;
    margin-right: 5px;
  }

  .key-creation p {
    font-size: 34px;
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon-wrapper img {
    margin-right: 25px;
  }

  .icon-wrapper img:first-child {
    width: 44px;
    height: 44px;
  }

  .seperator {
    position: relative;
    text-align: center;
    margin: 24px -15px;
    color: #696969;
  }

  .seperator:before {
    content: '';
    position: absolute;
    top: 11px;
    left: 0;
    z-index: 1;
    height: 1px;
    width: 100%;
    background-color: #000;
    opacity: 0.1;
  }

  .seperator span {
    background-color: #fff;
    padding: 0 10px;
    display: inline-block;
    position: relative;
    z-index: 2;
    margin-bottom: 0.5rem;
  }

  .sign-up-form label {
    display: block;

    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px !important;

    letter-spacing: 0.545455px;
    color: #696969 !important;
    margin-bottom: 8px;
    span {
      font-weight: 400 !important;
      font-size: 12px !important;
    }
  }

  .sign-up-form input {
    background: #fafcfe;
    border: 1px solid #e8eaeb;
    width: 185px;
    height: 50px;
    border-radius: 10px;
    padding: 16px;
    padding: 16px;
    box-sizing: border-box;
  }
  .custom-input-root:hover {
    border: none !important;
  }

  .sign-up-form input::placeholder {
    color: rgba(41, 41, 41, 0.2);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
  .sign-up-form .last-name {
    .text-input-root {
      max-width: 185px;
    }
  }
  .sign-up-form .first-name {
    .text-input-root {
      max-width: 185px;
    }
    ${rtl`
    margin-right: 14px;
    `}
  }

  .email-wrap input {
    width: 384px;
  }
  .phone {
    input {
      padding-left: 60px;
    }
  }

  .sign-up-form .pass-wrap input::placeholder {
    color: #282929;
  }

  .form-group.email-wrap.pass-wrap {
    position: relative;
  }

  .form-group.email-wrap.pass-wrap img {
    position: absolute;
    right: 16px;
    // top: 43px;
    cursor: pointer;
  }

  .cancle-finish-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 17px;
    width: 384px;
    margin-bottom: 30px;
  }

  .cancle-finish-btn .cancle-btn {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;

    width: 134px;
    height: 48px;
    border-radius: 15px;
    background-color: transparent;
    border: 1px solid #696969;
    justify-content: center;
    cursor: pointer;
    margin-left: -50px;
  }

  .cancle-finish-btn .finish-btn {
    width: 100%;
    height: 48px;

    border-radius: 15px;
    font-size: 18px;
    cursor: pointer;
    span {
      color: inherit;
    }
  }

  @media (max-width: 1440px) and (min-width: 1112px) {
    /*Mood board page*/

    .mood-board-part1-wrapper img,
    .sub-wrapper img,
    .last-sub-wrapper .sub-sub-wrap img,
    .txt-mmodboard {
      width: 207px;
      height: 172px;
    }

    .mood-board-part2-wrapper .part2-wrapper-sub img {
      width: 207px;
      height: 351px;
    }

    .container {
      max-width: 1030px;
    }

    .txt-mmodboard {
      margin-bottom: 5px;
      margin-right: 7px;
      box-sizing: border-box;
    }

    .design-long-wrap img {
      width: 420px;
      height: 172px;
    }
  }

  @media (max-width: 1112px) and (min-width: 992px) {
    /*Mood board page*/

    .mood-board-part1-wrapper img,
    .sub-wrapper img,
    .last-sub-wrapper .sub-sub-wrap img,
    .txt-mmodboard {
      width: 177px;
      height: 142px;
    }

    .mood-board-part2-wrapper .part2-wrapper-sub img {
      width: 177px;
      height: 289px;
    }

    .container {
      max-width: 880px;
    }

    .txt-mmodboard {
      margin-bottom: 5px;
      margin-right: 7px;
      box-sizing: border-box;
    }

    .design-long-wrap img {
      width: 359px;
      height: 142px;
    }
  }

  @media (max-width: 992px) and (min-width: 767px) {
    /*Mood board page*/

    .mood-board-part1-wrapper img,
    .sub-wrapper img,
    .last-sub-wrapper .sub-sub-wrap img,
    .txt-mmodboard {
      width: 82px;
      height: 69px;
    }

    .mood-board-part2-wrapper .part2-wrapper-sub img {
      width: 82px;
      height: 140px;
    }

    .container {
      max-width: 400px;
    }

    .txt-mmodboard {
      margin-bottom: 5px;
      margin-right: 7px;
      box-sizing: border-box;
    }

    .design-long-wrap img {
      width: 167px;
      height: 69px;
    }

    .txt-mmodboard p {
      font-size: 10px;
      line-height: 12px;
    }

    .round-wrapper .round {
      border: 1.2754px solid #ffffff;
      width: 22px;
      height: 22px;
      margin-right: 6px;
    }

    .round-wrapper {
      bottom: -12px;
    }

    .back-next-btn {
      justify-content: space-between;
      width: 100%;
    }

    .back-next-btn .next-btn {
      margin-left: 0px;
    }

    .mood-board-part1-wrapper img,
    .sub-wrapper img {
      margin: 0px;
    }

    .txt-mmodboard img {
      top: 40px;
    }
  }

  @media ${MOBILE_TABLET_RESOLUTION} {
    /*Mood board page*/

    .desk-mood-board {
      display: none;
    }

    .container {
      max-width: 300px;
    }

    .mood-boardres-part1-wrapper {
      width: 33%;
    }

    .mood-boardres-part1-wrapper {
      width: 33%;
    }

    .img-comman-wrap img {
      width: 106px;
      height: 110px;
      border-radius: 15px;
    }

    .res-round-wrapper {
      display: flex;
    }

    .res-round-wrapper .round {
      width: 18.6px;
      height: 110px;
      border-radius: 8px;
      margin-right: 4px;
      cursor: pointer;
    }

    .res-round-wrapper .round-1 {
      background: linear-gradient(0deg, #383931, #383931), url(2-2.jpg);
    }

    .res-round-wrapper .round-2 {
      background: linear-gradient(0deg, #646756, #646756), url(2-2.jpg);
    }

    .res-round-wrapper .round-3 {
      background: linear-gradient(0deg, #ccbcad, #ccbcad), url(2-2.jpg);
    }

    .res-round-wrapper .round-4 {
      background: linear-gradient(0deg, #907e68, #907e68), url(2-2.jpg);
    }

    .res-round-wrapper .round-5 {
      background: linear-gradient(0deg, #a0552b, #a0552b), url(2-2.jpg);
    }

    .back-next-btn {
      justify-content: space-between;
    }

    .back-next-btn .next-btn {
      height: 40px;
      margin-left: 0px;
      padding: 6px 24px;
      color: #fff;
    }

    .back-next-btn .back-btn {
      height: 40px;
    }

    .res-mood-board .text-main {
      text-align: center;
    }

    .res-mood-board h4 {
      margin-bottom: 0px;
      color: #11516d;
      font-size: 22px;
      font-weight: 600;
    }

    .res-mood-board p {
      margin-top: 5px;
      font-size: 16px;
      line-height: 20.93px;
    }

    .res-mood-board {
      display: block;
    }

    .cancle-finish-btn .cancle-btn {
      margin-left: 0px;
    }

    .cancle-finish-btn .finish-btn {
      margin-right: 0px;
    }

    .sign-up-form input {
      width: 156px;
    }

    .email-wrap input {
      width: 327px !important;
    }
  }

  @media (max-width: 359px) {
    /*Mood board page*/

    .img-comman-wrap img {
      width: 96px;
      height: 100px;
      border-radius: 15px;
    }

    .container {
      max-width: 270px;
    }

    .res-round-wrapper .round {
      height: 100px;
    }

    .sign-up-form input {
      width: 146px;
    }

    .email-wrap input {
      width: 307px !important;
    }
  }
`;

const ProgressBarContainer = styled.div<{
  progress: number;
  progressStep: number;
  progressPixel: number;
  choosePlan: boolean;
  designStyleQuiz: boolean;
  designConfirmation: boolean;
  isMobile: boolean;
}>`
  width: fit-content;
  margin: 0 auto;
  display: flex;
  position: relative;
  margin-top: -80px;
  @media (max-width: 767px) {
    margin-top: -37px;
  }
  @media (min-width: 767px) and (max-width: 1440px) {
    margin-top: 2.6rem;
  }
  .tooltip {
    background: transparent;
    color: #000;
    position: absolute;
    width: 124px;
    height: 60px;
    border: 1.5px solid #11516d;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
    text-align: center;
    border-radius: 10px;
    top: -74px;
    display: flex;
    ${rtl`transition: left linear 1s;`}
    @media
      ${MOBILE_RESOLUTION} {
      ${rtl`
      left: calc(${3 * 80 + 20}px - 16px);
    `}
    }
    @media (max-width: 767px) {
      height: 42px;
      width: 88px;
      top: -55px;
      ${({
        progress,
        progressStep,
        progressPixel,
        choosePlan,
        designConfirmation,
        isMobile,
        theme,
      }) => {
        if (progress === 6) return rtl`left: calc(228px - 25px);`;
        if (choosePlan && progress === 5)
          return rtl`left: calc(${
            progress * 120 + progressPixel / 2 - progressStep * 2
          }px - 34px);`;
        if (progress === 5)
          return rtl`left: calc(${
            progress * 30 + progressPixel / 2 - progressStep * 2
          }px - 34px);`;

        if (choosePlan && progress === 4)
          return rtl`left: calc(${
            progress * 70 + progressPixel / 2 - progressStep * 2
          }px - 34px);`;
        if (progress === 4)
          return rtl`left: calc(${
            progress * 30 + progressPixel / 2 - progressStep * 2
          }px - 34px);`;

        if (
          progress &&
          progress >= 3 &&
          progressPixel >= progressStep * 4 &&
          !choosePlan
        )
          return rtl`left: calc(${
            progress * 35 + progressPixel / 2 - progressStep
          }px - 30px);`;
        if (progress === 3 && choosePlan) {
          return rtl`left: calc(${
            progress * 35 + progressPixel / 2
          }px - 30px);`;
        }
        if (choosePlan && progress === 3)
          return rtl`left: calc(${progress * 80 + progressPixel}px - 16px);`;

        if (designConfirmation === true && choosePlan && progress === 3)
          return rtl`left: calc(${
            progress * 80 + progressPixel + 40
          }px - 16px);`;

        if (
          isMobile &&
          designConfirmation === true &&
          choosePlan &&
          progress === 3
        )
          return rtl`left: calc(${
            progress * 80 + progressPixel + 40
          }px - 16px);`;

        if (choosePlan && progress === 2)
          return rtl`left: calc(${progress * 30}px + 60px);`;
        if (progress && progress >= 2)
          return rtl`left: calc(${progress * 30}px - 30px);`;
        return rtl`
        left: calc(${progress * 30}px -  34px);
        `;
      }}
    }
    ${({
      progress,
      progressStep,
      progressPixel,
      choosePlan,
      designConfirmation,
    }) => {
      if (progress === 6) return rtl`left: calc(580px - 62px);`;
      if (choosePlan && progress === 5)
        return rtl`left: calc(${
          progress * 330 + progressPixel - progressStep * 5
        }px - 55px);`;
      if (progress === 5)
        return rtl`left: calc(${
          progress * 105 + progressPixel - progressStep * 5
        }px - 55px);`;
      if (choosePlan && progress === 4)
        return rtl`left: calc(${
          progress * 170 + progressPixel - progressStep * 5
        }px - 55px);`;
      if (progress === 4)
        return rtl`left: calc(${
          progress * 80 + progressPixel - progressStep * 5
        }px - 55px);`;
      if (designConfirmation === true && choosePlan && progress === 3)
        return rtl`left: calc(${progress * 80 + progressPixel + 40}px - 16px);`;
      if (choosePlan && progress === 3)
        return rtl`left: calc(${progress * 80 + progressPixel}px - 16px);`;
      if (progress && progress >= 3 && progressPixel >= progressStep * 4)
        return rtl`left: calc(${
          progress * 80 + progressPixel - progressStep
        }px - 55px);`;
      if (progress && progress >= 2 && choosePlan)
        return rtl`left: calc(${progress * 80 * 2}px - 0px);`;
      if (progress && progress >= 2)
        return rtl`left: calc(${progress * 80}px - 55px);`;
      if (progress === 1)
        return rtl`
      left: 8px;  
      `;
      return rtl`left: calc(0px - 47px);`;
    }}
    &:before {
      border-left: solid transparent 10px;
      border-right: solid transparent 10px;
      border-top: solid #fff 10px;
      bottom: -7px;
      content: ' ';
      height: 0;
      left: 50%;
      margin-left: -13px;
      position: absolute;
      width: 0;
      z-index: 9;
    }
    &:after {
      content: '';
      border-left: solid transparent 10px;
      border-right: solid transparent 10px;
      border-top: solid #11516d 10px;
      bottom: -10px;
      content: ' ';
      height: 0;
      left: 50%;
      margin-left: -13px;
      position: absolute;
      width: 0;
    }
  }
`;
const ProgressStep = styled.div<{ hasCheck: boolean }>`
  height: 24px;
  width: 24px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  border: ${({ hasCheck }) =>
    hasCheck ? '2px solid #11516d' : '2px solid #7a9aa8'};
  & > div {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background: #11516d;
    margin: 5px;
  }
  @media (max-width: 767px) {
    height: 16px;
    width: 16px;
    & > div {
      width: 8px;
      height: 8px;
      margin: 2px;
    }
  }
`;
const LinkDiv = styled.div<{
  progress: number;
  progressStep: number;
  progressPixel: number;
  choosePlan: boolean;
  designStyleQuiz: boolean;
  designConfirmation: boolean;
}>`
  width: 566px;
  height: 4px;
  border: 1px solid #7a9aa8;
  margin: auto 0;
  overflow: hidden;
  display: flex;
  background: #7a9aa8;
  & > div {
    height: 4px;
    transition: width linear 1s;
    background: #11516d;
    width: ${({
      progress,
      progressPixel,
      progressStep,
      choosePlan,
      designStyleQuiz,
    }) => {
      if (progress === 6)
        return progress * 200 + progressPixel - progressStep * 2;
      if (choosePlan && progress === 5)
        return progress * 210 + progressPixel - progressStep * 3;
      if (progress === 5)
        return progress * 100 + progressPixel - progressStep * 3;
      if (choosePlan && progress === 4)
        return progress * 280 + progressPixel - progressStep * 7;
      if (progress === 4)
        return progress * 95 + progressPixel - progressStep * 7;
      if (!choosePlan && progress === 3 && progressPixel >= progressStep * 5) {
        return progress * 95 + progressPixel - progressStep * 3;
      }
      if (choosePlan && progress === 2) {
        return progress * 180 + progressPixel;
      }
      if (designStyleQuiz === true) {
        if (choosePlan && progress === 3) {
          return progress * 105 + progressPixel;
        }
      } else if (choosePlan && progress === 3) {
        return progress * 85 + progressPixel;
      }
      return progress * 95;
    }}px;
  }
  @media (max-width: 767px) {
    width: 242px;
    & > div {
      width: ${({
        progress,
        progressPixel,
        progressStep,
        choosePlan,
        designStyleQuiz,
        designConfirmation,
      }) => {
        if (choosePlan && progress === 6)
          return progress * 160 + progressPixel - progressStep * 7;
        if (progress === 6)
          return progress * 100 + progressPixel / 2 - progressStep * 2;
        if (choosePlan && progress === 5)
          return progress * 150 + progressPixel - progressStep * 3;
        if (progress === 5)
          return progress * 35 + progressPixel / 2 - progressStep * 2;
        if (progress === 4)
          return progress * 50 + progressPixel / 2 - progressStep * 5;
        if (progress === 3 && progressPixel >= progressStep * 5) {
          return progress * 45 + progressPixel / 2 - progressStep * 2;
        }
        if (choosePlan && progress === 2) {
          return progress * 5 + progressPixel + 140;
        }
        if (designStyleQuiz) {
          if (choosePlan && progress === 3 && designConfirmation) {
            return progress + 300;
          }
          if (choosePlan && progress === 3) {
            return progress + 200;
          }
          if (designStyleQuiz && choosePlan && progress === 3) {
            return progress + 240;
          }
        }
        if (progress === 3) return 120;
        return progress * 30;
      }}px;
    }
  }
`;

const CustomLink = styled.div`
  cursor: pointer;
  width: fit-content;
  height: 150px;
`;
