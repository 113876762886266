import * as React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import rtl from 'styled-components-rtl';
import Icon from 'components/Icon';
import { MenuItem } from 'components/Popper';
import {
  LogoutOutlined,
  PhoneForwardedOutlined,
  LocalPhoneOutlined,
  FactCheckOutlined,
} from '@mui/icons-material';

const NavigationListItem: React.FC<MenuItem> = ({
  to = '',
  component,
  onClick,
  displayNormalText = false,
  icon,
}) => {
  const checkIcon =
    icon && ['order', 'contact', 'support', 'logout'].includes(icon);
  const innerItem = React.useMemo(
    () => (
      <>
        <ItemContainer>
          {checkIcon ? (
            <>
              {icon === 'order' && <FactCheckOutlined fontSize="small" />}
              {icon === 'contact' && <LocalPhoneOutlined fontSize="small" />}
              {icon === 'support' && (
                <PhoneForwardedOutlined fontSize="small" />
              )}
              {icon === 'logout' && <LogoutOutlined fontSize="small" />}
            </>
          ) : (
            icon && <Icon name={icon} size={20} />
          )}

          <span>{component}</span>
        </ItemContainer>
        <Circle>
          <Icon name="nav-arrow-right" size={10} />
        </Circle>
      </>
    ),
    [component, icon, checkIcon]
  );
  return (
    <Item displayNormalText={displayNormalText}>
      {onClick ? (
        <div
          className="button-item"
          tabIndex={0}
          role="button"
          onClick={onClick}
          onKeyPress={onClick}
        >
          {innerItem}
        </div>
      ) : (
        <CustomLink to={to}>{innerItem}</CustomLink>
      )}
    </Item>
  );
};

export default NavigationListItem;

const Item = styled.li.attrs({ className: 'navigation-list-item' })<{
  displayNormalText: boolean;
}>`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 18px 0;
  font-weight: ${({ displayNormalText }) =>
    displayNormalText ? '400' : '700'};
  font-size: 1.25rem;
  list-style: none;
  border-bottom: 0.3px solid rgba(169, 153, 151, 0.5);

  .button-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${({ theme }) => theme.colors.primary.clientTextColor};
  }
`;

const CustomLink = styled(Link).attrs({
  className: 'navigation-list-item-link',
})`
  font: inherit;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary.clientTextColor};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  &:hover {
    color: rgba(66, 66, 66, 0.7);
    transition: all 0.3s;

    & .arrow-container {
      background: ${({ theme }) => theme.colors.primary.clientTextColor};
      transition: all 0.3s;

      & > .icon {
        fill: white;
      }
    }
  }
`;

const ItemContainer = styled.div`
  display: flex;
  & > span {
    ${rtl`
    margin-left: 10px;
  `}
`;

const Circle = styled.div.attrs({ className: 'arrow-container' })`
  ${({ theme }) => theme.utils.centerFlex};
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colors.primary.clientTextColor};

  & > .icon {
    fill: ${({ theme }) => theme.colors.primary.clientTextColor};
    position: relative;

    ${rtl`
      left: 2px;
    `}
    ${({ theme }) =>
      theme.dir === 'rtl' &&
      css`
        transform: rotate(180deg);
      `}
  }
`;
