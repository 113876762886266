import * as React from 'react';
import styled from 'styled-components';
import { MenuItem } from 'components/Popper';
import NavigationListItem from './NavigationListItem';

interface Props {
  items: MenuItem[];
}

const NavigationList: React.FC<Props> = ({ items }) => {
  return (
    <List>
      {items.map(props => (
        <NavigationListItem key={props.to as string} {...props} />
      ))}
    </List>
  );
};

export default NavigationList;

const List = styled.ul.attrs({ className: 'navigation-list' })`
  padding: 0;
  margin: 0;
  max-width: 800px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
`;
