import { LogsAPI } from 'api/methods';
import rtl from 'styled-components-rtl';
import eyeIcon from 'assets/@client/onboarding/eye-down.png';
import eyeUpIcon from 'assets/@client/onboarding/eye-up.png';
import {
  MOBILE_RESOLUTION,
  MOBILE_TABLET_RESOLUTION,
} from 'styles/sharedStyle';
import Button from 'components/@client/Button';
import TextInput from 'components/@client/form-components/TextInput';
import { PhoneField } from 'components/forms';
import { useAuthentication } from 'hooks';
import { Message } from 'i18n';
import { userLogData } from 'mockData/userLogData';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import DigitalKey from 'assets/@client/onboarding/digital-key-high-res.png';
import DigitalKeyHe from 'assets/@client/onboarding/digital-key-high-res-he.png';
import { useMediaQuery } from '@material-ui/core';
import ConfirmationDialog from '../components/ConfirmationDialog';

const NewSignUpForm: React.FC = () => {
  const { control, formState } = useFormContext();
  const { loading } = useAuthentication();
  const intl = useIntl();
  const submitRef = React.useRef<HTMLButtonElement>(null);
  const isMobile = useMediaQuery('(max-width: 767px)');

  const [isPasswordVidsible, setIsPasswordVisible] = React.useState({
    password: false,
    confirmPassword: false,
  });
  const [isConfirmationModalVisible, setIsGoBackModalVisible] = React.useState(
    false
  );
  const { isValid, isDirty } = formState;

  const handleKey: React.KeyboardEventHandler<HTMLInputElement> = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter') submitRef.current?.click();
  };

  return (
    <Container>
      {!isMobile && (
        <div className="key-img">
          <img
            src={intl.locale === 'he' ? DigitalKeyHe : DigitalKey}
            alt="digital key"
            style={{ marginRight: 8, width: 211, height: 466 }}
          />
        </div>
      )}
      <section className="sign-up">
        <div>
          {/* <div className="icon-wrapper">
            <img alt="temp" src={facebookIcon} />
            <img alt="temp" src={googleIcon} />
            <img alt="temp" src={appleIcon} />
          </div>
          <div className="seperator">
            <span>Or connect with</span>
          </div> */}

          <form className="sign-up-form" id="sign-up-form">
            <div className="row">
              <div className="form-group first-name">
                <TextInput
                  name="firstName"
                  control={control}
                  label={intl.formatMessage({ id: Message.FIRST_NAME })}
                  required
                  inputPropsData={{ 'data-testid': 'firstName' }}
                />
              </div>
              <div className="form-group last-name">
                <TextInput
                  name="lastName"
                  control={control}
                  label={intl.formatMessage({ id: Message.LAST_NAME })}
                  required
                  inputPropsData={{ 'data-testid': 'lastName' }}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group email-wrap form-email">
                <TextInput
                  name="email"
                  control={control}
                  label={intl.formatMessage({
                    id: Message.AUTH_LOGIN_EMAIL_FIELD,
                  })}
                  type="email"
                  required
                  dir="ltr"
                  inputPropsData={{ 'data-testid': 'email' }}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group email-wrap phone">
                <PhoneField
                  name="phoneNumber"
                  label={{
                    title: intl.formatMessage(
                      {
                        id: Message.REGISTRATION_PHONE_NUMBER,
                      },
                      {
                        span: (chunks: string) => (
                          <span
                            style={{
                              fontSize: '12px',
                              fontWeight: 400,
                              color: '#696969',
                            }}
                          >
                            {chunks}
                          </span>
                        ),
                      }
                    ),
                  }}
                  inputProps={{ 'data-testid': 'phoneNumber' }}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group form-password email-wrap pass-wrap">
                <TextInput
                  name="password"
                  control={control}
                  label={intl.formatMessage({
                    id: Message.AUTH_LOGIN_PASSWORD_FIELD,
                  })}
                  type={isPasswordVidsible.password ? 'text' : 'password'}
                  required
                  inputPropsData={{ 'data-testid': 'password' }}
                  dir="ltr"
                />
                <div
                  onClick={() =>
                    setIsPasswordVisible(prev => ({
                      ...prev,
                      password: !prev.password,
                    }))
                  }
                >
                  <img
                    alt="temp"
                    style={{
                      top: 38,
                    }}
                    src={isPasswordVidsible.password ? eyeUpIcon : eyeIcon}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="form-group email-wrap pass-wrap">
                <TextInput
                  name="verifyPassword"
                  control={control}
                  label={intl.formatMessage({
                    id: Message.AUTH_REGISTRATION_VERIFY_PASSWORD_FIELD,
                  })}
                  type={
                    isPasswordVidsible.confirmPassword ? 'text' : 'password'
                  }
                  onkeyup={handleKey}
                  required
                  dir="ltr"
                  inputPropsData={{ 'data-testid': 'confirmPassword' }}
                />
                <div
                  onClick={() =>
                    setIsPasswordVisible(prev => ({
                      ...prev,
                      confirmPassword: !prev.confirmPassword,
                    }))
                  }
                >
                  <img
                    alt="temp"
                    style={{
                      top: 38,
                    }}
                    src={
                      isPasswordVidsible.confirmPassword ? eyeUpIcon : eyeIcon
                    }
                  />
                </div>
              </div>
            </div>
          </form>
          <div className="row">
            <div className="cancle-finish-btn">
              {/* <button
                className="cancle-btn"
                type="button"
                onClick={() => setIsGoBackModalVisible(true)}
              >
                <FormattedMessage id={Message.CANCEL} />
              </button> */}
              <Button
                className="finish-btn"
                id="register_form"
                buttonRef={submitRef}
                type="submit"
                disabled={!isValid || !isDirty}
                isLoading={loading}
                onClick={() => {
                  LogsAPI.postUserLogs({
                    area: userLogData.eventArea.registration,
                    section: userLogData.eventSection.userInfo,
                    name: userLogData.eventName.done,
                    path: userLogData.eventPath.appOnBoardingAdditionalInfoPath,
                  });
                }}
              >
                <FormattedMessage id={Message.FINISH} />
              </Button>
            </div>
          </div>
        </div>
      </section>

      <ConfirmationDialog
        isOpen={isConfirmationModalVisible}
        toggle={() => setIsGoBackModalVisible(prev => !prev)}
      />
    </Container>
  );
};

const Container = styled.div.attrs({
  className: 'sign-up',
})`
  margin: auto;
  display: flex;
  justify-content: space-around;
  .phone-number-label {
    font-weight: 400 !important;
    font-size: 14px !important;
    ${rtl`margin-left: 0;`}
  }
  .text-input-root {
    @media (max-width: 767px) {
      min-height: unset !important;
    }
    label {
      ${rtl`margin-left: 0;`}
    }
  }
  .key-img {
    @media (max-width: 1050px) {
      display: none;
    }
    ${rtl`position: absolute;
    top: 0;
    left: -292px;`}
    img {
      width: 192px;
      height: 436px;
    }
  }

  .form-email {
    margin-bottom: 1px;
  }
  .form-password {
    margin-top: 7px;
  }

  .sign-up {
    @media (min-width: 767px) and (max-width: 1050px) {
      margin-top: 30px;
    }
  }

  .finish-btn {
    @media ${MOBILE_TABLET_RESOLUTION} {
      width: 327px !important;
    }
  }

  @media ${MOBILE_RESOLUTION} {
    .cancle-finish-btn .finish-btn {
      margin-top: 24px;
      width: 327px;
    }
  }
`;

export default NewSignUpForm;
