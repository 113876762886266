import { useScrollPosition } from 'hooks';
import { useSpring, config } from 'react-spring';
import { Property } from 'types/properties';

const useAnimations = (recentProperty?: Property | null) => {
  const scrollPosition = useScrollPosition();
  const isAnimate = scrollPosition > 64;

  const zoomHomeIconStyles = useSpring({
    config: { ...config.stiff, duration: 300 },
    from: { transform: 'scale(0)' },
    to: {
      transform: recentProperty ? 'scale(1)' : 'scale(0)',
    },
  });

  const fadeStyles = useSpring({
    config: { ...config.stiff, duration: 300 },
    from: { opacity: 1 },
    to: {
      opacity: isAnimate ? 0 : 1,
    },
  });

  const fromStyles = {
    width: '30%',
    opacity: 1,
    paddingRight: '7px',
    paddingLeft: '7px',
  };
  const transitionTitleStyles = useSpring({
    config: { ...config.stiff, duration: 300 },
    from: fromStyles,
    to: isAnimate
      ? {
          width: '0',
          opacity: 0,
          paddingRight: '0',
          paddingLeft: '0',
        }
      : fromStyles,
  });

  const transitionBackgroundStyles = useSpring({
    config: { ...config.stiff, duration: 300 },
    from: { width: '70%' },
    to: { width: isAnimate ? '100%' : '70%' },
  });

  return {
    isAnimate,
    zoomHomeIconStyles,
    transitionTitleStyles,
    transitionBackgroundStyles,
    fadeStyles,
  };
};

export default useAnimations;
