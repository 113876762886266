import { useMediaQuery } from '@mui/material';
import { OnboardingContextProvider } from 'context/onboardingContext';
import * as React from 'react';
import { MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';
// eslint-disable-next-line import/no-cycle
import UserFormMobile from './UserFormMobile';
import UserForm from './UserForm';

const UserFormContainer: React.FC = () => {
  const isMobile = useMediaQuery(MOBILE_TABLET_RESOLUTION);
  return (
    <OnboardingContextProvider>
      {isMobile ? <UserFormMobile supplier={0} /> : <UserForm />}
    </OnboardingContextProvider>
  );
};

export default UserFormContainer;
