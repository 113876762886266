import * as React from 'react';
import { useFormContext, useController, Control } from 'react-hook-form';
import styled from 'styled-components';
import FormLabel, { ILabel } from 'components/forms/FormLabel';
import { FormHelperText } from '@material-ui/core';
import rtl from 'styled-components-rtl';
import Icon from 'components/Icon/Icon';
import { error as errorColor } from 'styles/sharedStyle';

interface Props {
  control: Control;
  name: string;
  label?: ILabel;
  placeholder?: string;
  required?: boolean;
  className?: string;
  limit?: number | undefined;
  subTitle?: string | undefined | null;
}

const FormTextArea: React.FC<Props> = ({
  name,
  label,
  placeholder,
  required,
  className,
  control,
  limit,
  subTitle,
}) => {
  const { formState } = useFormContext();
  const { field } = useController({ control, name });
  const errorMessage = formState.errors[name]?.message;
  const description = field.ref?.current?.value
    ? field.ref?.current?.value?.toString().length
    : 0;
  // console.log('Message', descriptionLength.length);

  return (
    <Container className={className}>
      <div className="label-container">
        {label && (
          <FormLabel label={label} htmlFor={name} required={required} />
        )}
        {limit && <h4>{` ${description}/ ${limit}`}</h4>}
      </div>
      {subTitle && <h5>{` ${subTitle}`}</h5>}
      <TextAreaField
        {...field}
        ref={field.ref}
        name={name}
        placeholder={placeholder}
      />
      {errorMessage && (
        <FormHelperText error variant="filled" className="error-label">
          <Icon name="information-circle" color={errorColor} />
          {errorMessage}
        </FormHelperText>
      )}
    </Container>
  );
};

export default FormTextArea;

const Container = styled.div`
  & .error-label {
    margin: 6px 0 0;
    display: flex;
    align-items: center;
    & .icon-information-circle {
      ${rtl`
        margin-right: 4px;
      `}
    }
  }
  & .label-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }

  h4 {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #747575;
  }
  h5 {
    margin: 0 0 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #c9c9c9;
  }
`;

const TextAreaField = styled.textarea`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.darkGray};
  color: ${({ theme }) => theme.colors.primary.primaryTextColor};
  margin-top: 4px;
  resize: none;
  padding: 15px;
  font-size: 0.875rem;
  font-family: ${({ theme }) => (theme.dir === 'rtl' ? 'Assistant' : 'Roboto')};
  min-height: 90px;
  width: 100%;

  &::placeholder {
    color: ${({ theme }) => theme.colors.silver};
  }
  &:focus,
  &:hover {
    outline: 1px solid rgba(255, 255, 255, 0.3);
    border: 1px solid ${({ theme }) => theme.colors.primary.dark};
  }
`;
