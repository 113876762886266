export enum ThreeDTasksDataType {
  GET_3D_TASKS_REQUEST = 'threeD/get3DTasksDataRequest',
  GET_3D_TASKS_FULFILLED = 'threeD/get3DTasksDataFulfilled',
  GET_3D_TASKS_REJECTED = 'threeD/get3DTasksDataRejected',
}

export enum ThreeDInDbDataType {
  GET_3D_IN_DB_REQUEST = 'threeD/get3DInDbDataRequest',
  GET_3D_IN_DB_FULFILLED = 'threeD/get3DInDbDataFulfilled',
  GET_3D_IN_DB_REJECTED = 'threeD/get3DInDbDataRejected',
}
