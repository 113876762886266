import * as React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import rtl from 'styled-components-rtl';
import { FormattedMessage, useIntl } from 'react-intl';
import Breadcrumbs from 'components/@client/Breadcrumbs';
import { Message } from 'i18n';
import CardList from 'components/@client/CardList';
import Button from 'components/@client/Button';
import Icon from 'components/Icon/Svg';
import Title from 'components/@client/TopTitle';
import { useAuthentication, useRouter } from 'hooks';
import { ClientRouter } from 'router/routes';
import { getRoute } from 'utils/sidebar.utils';
import {
  ClientRoot,
  MOBILE_RESOLUTION,
  SMALL_RESOLUTION,
} from 'styles/sharedStyle';
import { isEmpty } from 'utils/lodash.utils';
import { LogsAPI } from 'api/methods';
import { userLogData } from 'mockData/userLogData';
import Pagination from '@mui/material/Pagination';
import { Type } from 'constants/config';
import _ from 'lodash';
import { OnboardingContextProvider } from 'context/onboardingContext';
import ProjectItem from 'components/@client/CardList/Items/ProjectItem';
import AddProperty from './add-property/AddProperty';
import useProperties from './useProperties';

const links = [
  {
    to: ClientRouter.PROPERTIES_SELLER,
    children: <FormattedMessage id={Message.PROPERTIES_INFO_BOX_TITLE} />,
  },
  {
    to: ClientRouter.PROPERTIES_SELLER_LISTING,
    children: <FormattedMessage id={Message.MYPROJECT} />,
  },
];
const SellerPropertyListing = () => {
  const intl = useIntl();
  const {
    properties,
    isLoading,
    callFetch: refreshPropertyList,
    pagination,
    setPagination,
  } = useProperties();
  // const { type: types } = useAuthentication();

  const { query, history } = useRouter<{
    modal: 'add-project';
  }>();

  const goTo = React.useCallback(
    (id: number) =>
      history.push(
        getRoute([ClientRouter.PROPERTIES_SELLER_LISTING, `/${id}`])
      ),
    [history]
  );

  const onRefresh = React.useCallback(() => {
    refreshPropertyList();
  }, [refreshPropertyList]);

  const isAddingProject = query.modal === 'add-project';
  const { type } = useAuthentication();

  React.useEffect(() => {
    if (!isAddingProject && !isLoading) onRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddingProject]);

  const debounOnChange = _.debounce(
    (value: string) =>
      setPagination(prev => ({
        ...prev,
        page: 1,
        name: value,
      })),
    500
  );

  return (
    <>
      <OnboardingContextProvider>
        <Root>
          <Breadcrumbs links={links} />
          <div className="title-stack">
            <Title
              title={<FormattedMessage id={Message.MYPROJECT} />}
              backTo={ClientRouter.MY_ACCOUNT}
            />

            <Link
              to={type === Type.SELLER_CLIENT ? '#' : ClientRouter.ADD_PROJECT}
            >
              <Button
                className="add-property-button"
                startIcon={
                  <Icon name="plus" className="icon-button" color="#fff" />
                }
                onClick={() =>
                  LogsAPI.postUserLogs({
                    name: userLogData.eventName.addNewProperty,
                    area: userLogData.eventArea.myProperties,
                    section: userLogData.eventSection.properties,
                    path: userLogData.eventPath.appModalAddPropertyPath,
                  })
                }
                disabled={type === Type.SELLER_CLIENT}
              >
                <FormattedMessage id={Message.ADD_A_NEW_PROJECT} />
              </Button>
            </Link>
          </div>
          <SellerSearch>
            <div className="search-input-div">
              <input
                placeholder={intl.formatMessage({
                  id: Message.PROJECT_SEARCH_PLACEHOLDER,
                })}
                // value={searchText}
                onChange={e => debounOnChange(e.target.value)}
              />
            </div>
            <Button
              className="search-button"
              disabled={!pagination.name.length}
            >
              <FormattedMessage id={Message.PROJECT_SEARCH} />
            </Button>
          </SellerSearch>
          <PageContainer empty={isEmpty(properties)}>
            <ListContainer>
              <CardList
                isLoading={isLoading}
                items={properties?.results ?? []}
                renderItem={property => (
                  <ProjectItem
                    key={property.id}
                    property={property}
                    onRefresh={onRefresh}
                    onClick={() => {
                      goTo(property.id);
                    }}
                  />
                )}
              />
              <PropertyPagination
                count={Math.ceil((properties?.count ?? 1) / 5)}
                variant="outlined"
                shape="rounded"
                boundaryCount={1}
                siblingCount={2}
                onChange={(e, page) =>
                  setPagination(prev => ({ ...prev, page }))
                }
              />
            </ListContainer>
          </PageContainer>
        </Root>
        {isAddingProject && <AddProperty open={isAddingProject} />}
      </OnboardingContextProvider>
    </>
  );
};

export default SellerPropertyListing;

const Root = styled(ClientRoot)`
  & .title-stack {
    display: flex;
    justify-content: space-between;

    @media (max-width: 1150px) {
      & .title {
        font-size: 1.125rem;
      }
    }

    & a {
      text-decoration: none;
      ${rtl`
      align-self: center;
      margin-left: auto;
      
      `}

      .add-property-button {
        width: 207px;
        font-weight: 500 !important;
        font-size: 18px;
        line-height: 22px;
        font-weight: 400;
        ${rtl`
        padding-left: 16px;
        `}

        @media (max-width: 500px) {
          padding: 10px;
          font-size: 0.75rem;
        }
      }
      .icon-button {
        margin-left: ${({ theme }) => (theme.dir === 'rtl' ? '12px' : '-4px')};
        margin-right: ${({ theme }) => (theme.dir === 'rtl' ? '-8px' : '0')};
      }
    }

    & .client-top-title {
      width: unset;
      .title {
        color: #282929;
      }
    }
  }

  @media ${SMALL_RESOLUTION} {
    padding: 20px 25px;
  }
  @media ${MOBILE_RESOLUTION} {
    padding: 20px 25px;
  }
`;

const SellerSearch = styled.div`
  ${rtl`
  width: 331px;
  height: 40px;
  margin 32px 0;
  display: flex;
  .search-input-div {
    background: #fafcfe;
    border-width: 2px 0px 2px 2px;
    border-style: solid;
    border-color: #e8eaeb;
    border-radius: 10px 0px 0px 10px;
    width: 249px;
    height: 100%;
    input {
      &:focus {
        outline: none;
      }
      border-radius: 10px 0px 0px 10px;
      padding: 10px 16px;
      height: 100%;
      width: 100%;
      border: none;
    }
  }

  .search-button {
    &:disabled{
      border:none;
    }
    text-align: center;
    padding: 3% 0;
    background: #fc5c42;

    width: 82px;
    height: inherit;
    border: 1px solid #fc5c42;
    border-radius: 0px 10px 10px 0px;
  }
  `}
`;

const PageContainer = styled.div<{ empty: boolean }>`
  display: ${({ empty }) => (empty ? 'block' : 'flex')};
  justify-content: space-between;

  & .info-box {
    top: unset;
  }
`;

const ListContainer = styled.div`
  flex: 0.7;

  @media ${MOBILE_RESOLUTION} {
    width: 100%;
    margin: 0 auto;
  }
`;

const PropertyPagination = styled(Pagination)`
  margin-top: 48px;
  ${({ theme }) =>
    theme.dir === 'rtl' &&
    css`
       {
        float: right;
        direction: ltr;
      }
    `}
`;
