import * as React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import InputAdornment from '@material-ui/core/InputAdornment';
import styled, { DefaultTheme } from 'styled-components';
import Icon from 'components/Icon';

interface Props {
  name: string;
  showValidAdornment?: boolean;
}

const ValidationIndicator: React.FC<Props> = ({ name, showValidAdornment }) => {
  const { control } = useFormContext();
  const { meta } = useController({ name, control });
  const { invalid } = meta;
  if (!invalid && !showValidAdornment) return null;

  return (
    <InputAdornment variant="outlined" position="end">
      <IconContainer>
        <InfoIcon size={20} invalid={invalid} />
      </IconContainer>
    </InputAdornment>
  );
};

export default ValidationIndicator;

const IconContainer = styled.div`
  display: flex;
  align-self: center;
  justify-self: center;
  margin-left: 5px;
`;

const InfoIcon = styled(Icon).attrs(
  ({ invalid, theme }: { invalid: boolean; theme: DefaultTheme }) => ({
    name: invalid ? 'information-circle' : 'checkmark-circle',
    color: invalid ? theme.colors.status.error : theme.colors.status.success,
    size: 15,
  })
)<{ invalid: boolean }>``;
