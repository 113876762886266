import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Button from 'components/@client/Button';
import CardList, { CartItem } from 'components/@client/CardList';
import CreditCardProviders from 'components/@client/CreditCardProviders';
import Loader from 'components/Loader';
import Checkbox from 'components/@client/form-components/Checkbox';
import AddressSection from 'pages/appClient/profile/AddressSection';
import { size } from 'utils/lodash.utils';
import { Message } from 'i18n';
import { ClientRouter } from 'router/routes';
import { getRoute } from 'utils/sidebar.utils';
import { stringify } from 'utils/string.utils';
import { userLogData } from 'mockData/userLogData';
import { LogsAPI } from 'api/methods';
import { CheckoutProps } from './Checkout.config';
import PriceField from './PriceField';

interface Props extends CheckoutProps {
  isModal?: boolean;
}

const MobileView: React.FC<Props> = ({
  address,
  backTo,
  cart,
  canProceed,
  propertyCart,

  isLoadingCart,
  priceFields,
  shippingOptions,
  userId,
  openModal,
  onAddressesChange,
  isModal,
}) => {
  return (
    <>
      <section className="shipping-section">
        {/* <h4>{cart?.property?.name}</h4> */}
        <div className="cart-heading">
          <span className="cart-counter">
            <FormattedMessage
              id={Message.CART_TOTAL_ITEMS_LABEL}
              values={{ value: size(cart?.items) }}
            />
          </span>
          <Link
            className="cart-edit"
            to={propertyCart}
            onClick={() => {
              LogsAPI.postUserLogs({
                name: userLogData.eventName.editButton,
                area: userLogData.eventArea.myCart,
                section: userLogData.eventSection.cart,
                path: userLogData.eventPath.appCartCartCheckOutId,
                metadata: { cart_id: cart?.id },
              });
            }}
          >
            <FormattedMessage id={Message.EDIT_BUTTON} />
          </Link>
        </div>
        <section className="actions">
          <CardList
            isLoading={isLoadingCart}
            EmptyState={<Loader />}
            items={cart?.items ?? []}
            renderItem={item => (
              <CartItem
                nameLength={12}
                key={item.id}
                {...item}
                quantity={item.checkoutQuantity}
              />
            )}
          />
          <h4>
            <FormattedMessage id={Message.CHECKOUT_SHIPPING_OPTIONS_TITLE} />
          </h4>
          {shippingOptions.map(props => (
            <Checkbox
              className="shipping-option"
              key={props.value as string}
              {...props}
            />
          ))}
          <AddressSection
            userId={userId}
            backTo={backTo}
            onAddressesChange={onAddressesChange}
            isModal={isModal}
            logData={{
              name: userLogData.eventName.addNewAddress,
              area: userLogData.eventArea.myCart,
              section: userLogData.eventSection.cart,
              path: userLogData.eventPath.appCartCartCheckOutId,
              metadata: { cart_id: cart?.id },
            }}
          />
        </section>
        <section className="cart-section">
          <div className="mobile-background">
            {priceFields.map(props => (
              <PriceField
                key={props.label?.toString()}
                fractionDigits={2}
                {...props}
              />
            ))}
            <CreditCardProviders />
            <Button
              disabled={!canProceed}
              className="checkout-button"
              onClick={openModal}
            >
              <FormattedMessage id={Message.CHECKOUT_PROCEED_BUTTON} />
            </Button>
            {!address && (
              <p className="missing-address-caption">
                <FormattedMessage
                  id={Message.CHECKOUT_MISSING_ADDRESS_CAPTION}
                  values={{
                    action: (
                      <Link
                        to={getRoute([
                          ClientRouter.PROFILE_ADDRESSES,
                          stringify({ backTo }),
                        ])}
                      >
                        <FormattedMessage
                          id={Message.CHECKOUT_MISSING_ADDRESS_ACTION}
                        />
                      </Link>
                    ),
                  }}
                />
              </p>
            )}
          </div>
        </section>
      </section>
    </>
  );
};

export default MobileView;
