const ComingSoon = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '3rem',
        height: '100vh',
        opacity: 0.7,
      }}
    >
      Coming Soon
    </div>
  );
};

export default ComingSoon;
