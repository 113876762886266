import SafeImg from 'components/SafeImg/SafeImg';
import HeroImage from 'assets/@client/magazine/hero_image.png';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { useRouter } from 'hooks';
import { ClientRouter } from 'router/routes';
import { summarizeText } from 'utils/string.utils';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import { userLogData } from 'mockData/userLogData';
import { LogsAPI } from 'api/methods';
import magazine from '../home/mock/magazine';

const Magazine = () => {
  const { history } = useRouter();

  return (
    <Root>
      <Image>
        <SafeImg src={HeroImage} alt="hero_image" />
      </Image>
      <MagazineContainer>
        <h1>
          <FormattedMessage id={Message.HOME_MAGAZINE_TITLE} />
        </h1>
        <Grid container spacing={4}>
          {magazine.map(data => {
            return (
              <Grid item xs={12} sm={6} md={4} key={data.id}>
                <MagazineItem
                  onClick={() => {
                    LogsAPI.postUserLogs({
                      name: userLogData.eventName.openArticle,
                      area: userLogData.eventArea.magazine,
                      section: userLogData.eventSection.magzine,
                      path: userLogData.eventPath.appMagazinePath,
                      metadata: { article_name: data.name },
                    });
                    history.push(
                      ClientRouter.MAGAZINE_PAGE.replace(
                        ':magazineName',
                        data.name
                      )
                    );
                  }}
                >
                  <MagazineImage>
                    <SafeImg src={data.image} alt={data.name} />
                  </MagazineImage>
                  <h3>{summarizeText(data.name, 30)}</h3>
                  {/* TODO: fetch magazine data from backend */}
                  <p>{data.date}</p>
                </MagazineItem>
              </Grid>
            );
          })}
        </Grid>
      </MagazineContainer>
    </Root>
  );
};

export default Magazine;

const Root = styled.div``;

const MagazineContainer = styled.div`
  max-width: 1140px;
  margin: 40px auto;
  padding: 0 30px;
  color: ${({ theme }) => theme.colors.black0};
  h1 {
    font-size: 3rem;
    text-align: center;
  }
`;

const Image = styled.div`
  width: 100vw;
  margin: 0 calc(-50vw + 50%);
  img {
    width: 100%;
    max-height: 400px;
  }
`;

const MagazineItem = styled.div`
  cursor: pointer;
  h3,
  p {
    margin: 0;
  }
`;

const MagazineImage = styled.div`
  display: flex;
  min-height: 262px;
  ${({ theme }) => theme.shadow.l};
  margin-bottom: 10px;
  img {
    margin: auto;
    max-width: 100%;
    max-height: 100%;
  }
`;
