import * as React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import { SupplierDashBoardRoutes, ClientRouter } from 'router/routes';
import CongratulationsImage from 'assets/@client/supplier-login-flow/supplier-login-congratulations.png';
import RightImage from 'assets/@client/supplier-login-flow/supplier-login-right.png';

import { useRouter } from 'hooks';

enum TYPES {
  UPLOAD_PRODUCT = 'upload_product',
  SKIP = 'skip',
  SCHEDULED_MEETING = 'schedule_meeting',
}
const SupplierLoginCongratulationsMobile: React.FC = () => {
  const location = useRouter();
  const { type } = location.query;

  const CongratsText = React.useMemo(() => {
    let MSG;
    if (type === TYPES.UPLOAD_PRODUCT) {
      MSG = (
        <FormattedMessage
          id={Message.SUPPLIER_LOGIN_CONGRATULATIONS_STORE_OPEN_TITLE_MOBILE}
          values={{
            linebreak: <br />,
          }}
        />
      );
    } else if (type === TYPES.SKIP) {
      MSG = (
        <FormattedMessage
          id={Message.SUPPLIER_LOGIN_CONGRATULATIONS_JOIN_TITLE}
        />
      );
    } else if (type === TYPES.SCHEDULED_MEETING) {
      MSG = (
        <FormattedMessage
          id={Message.SUPPLIER_LOGIN_CONGRATULATIONS_PROVIDED_TITLE_MOBILE}
          values={{ linebreak: <br /> }}
        />
      );
    }
    return MSG;
  }, [type]);

  const CongratsFirstText = React.useMemo(() => {
    let MSG;
    if (type === TYPES.UPLOAD_PRODUCT) {
      MSG = (
        <FormattedMessage
          id={Message.SUPPLIER_LOGIN_FIRST_CONGRATULATIONS_SUBTITLE}
          values={{
            linebreak: <br />,
            span: (chunks: string) => (
              <span style={{ fontWeight: 500 }}>{chunks}</span>
            ),
          }}
        />
      );
    } else if (type === TYPES.SKIP) {
      MSG = (
        <FormattedMessage
          id={Message.SUPPLIER_LOGIN__CONGRATULATIONS_SUBTITLE_SKIP_MOBILE}
          values={{ linebreak: <br /> }}
        />
      );
    } else if (type === TYPES.SCHEDULED_MEETING) {
      MSG = (
        <FormattedMessage
          id={Message.SUPPLIER_LOGIN__CONGRATULATIONS_SUBTITLE_SCHEDULED_MOBILE}
          values={{ linebreak: <br /> }}
        />
      );
    }
    return MSG;
  }, [type]);

  const ParentMargin = React.useMemo(() => {
    if (type === TYPES.UPLOAD_PRODUCT) {
      return '92px';
    }
    if (type === TYPES.SKIP) {
      return '128px';
    }
    return '213px';
  }, [type]);

  const ParentMarginTitle = React.useMemo(() => {
    if (type === TYPES.SKIP) {
      return '8px';
    }
    if (type === TYPES.SCHEDULED_MEETING) {
      return '16px';
    }
    return '1px';
  }, [type]);

  return (
    <ParentLoginWelcome>
      <ParentDiv topSpace={ParentMargin}>
        {type === TYPES.UPLOAD_PRODUCT || type === TYPES.SKIP ? (
          <SupplierLoginCongratulationsImage
            src={CongratulationsImage}
            alt="congrats-logo"
          />
        ) : (
          <SupplierLoginRightImage src={RightImage} alt="right-image" />
        )}

        {(type === TYPES.UPLOAD_PRODUCT || type === TYPES.SKIP) && (
          <CongratsTitle>
            <FormattedMessage
              id={Message.SUPPLIER_LOGIN_CONGRATULATIONS_TITLE}
            />
          </CongratsTitle>
        )}

        <WelcomeTitle topSpace={ParentMarginTitle}>{CongratsText}</WelcomeTitle>
        <SubTitle>{CongratsFirstText}</SubTitle>
        {type === TYPES.UPLOAD_PRODUCT && (
          <SecondSubTitle>
            <FormattedMessage
              id={Message.SUPPLIER_LOGIN_SECOND_CONGRATULATIONS_SUBTITLE_MOBILE}
              values={{ linebreak: <br /> }}
            />
          </SecondSubTitle>
        )}

        {type === TYPES.UPLOAD_PRODUCT || type === TYPES.SKIP ? (
          <GoToYourShopButton
            onClick={() => location.history.push(SupplierDashBoardRoutes.INFO)}
          >
            <GoToYourShopText>
              <FormattedMessage
                id={Message.SUPPLIER_LOGIN_WELCOME_CONGRATULATIONS_BUTTON}
              />
            </GoToYourShopText>
          </GoToYourShopButton>
        ) : (
          <GoToHomeButton
            onClick={() => location.history.push(SupplierDashBoardRoutes.INFO)}
          >
            <GoToHomeText>
              <FormattedMessage
                id={Message.SUPPLIER_LOGIN_WELCOME_GO_HOME_BUTTON}
              />
            </GoToHomeText>
          </GoToHomeButton>
        )}

        {(type === TYPES.UPLOAD_PRODUCT || type === TYPES.SKIP) && (
          <ReturnToHome onClick={() => location.history.push(ClientRouter.APP)}>
            <FormattedMessage
              id={Message.SUPPLIER_LOGIN_FIRST_CONGRATULATIONS_RETURN_TO_HOME}
            />
          </ReturnToHome>
        )}
      </ParentDiv>
    </ParentLoginWelcome>
  );
};
export default SupplierLoginCongratulationsMobile;

const ParentLoginWelcome = styled.div`
  font-family: 'Roboto Condensed';
  direction: ltr;
`;
const ParentDiv = styled.div<{ topSpace: string }>`
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  margin-top: ${({ topSpace }) => topSpace};
`;

const SupplierLoginCongratulationsImage = styled.img`
  width: 230px;
  height: 170px;
`;
const SupplierLoginRightImage = styled.img`
  width: 88px;
  height: 66px;
`;

const GoToYourShopButton = styled.button`
  cursor: pointer;
  background: #fc5c42;
  border: 1px solid #fc5c42;
  border-radius: 15px;
  padding: 10px 24px;
  width: 163px;
  height: 40px;
  margin-top: 24px;
`;

const GoToHomeButton = styled.button`
  cursor: pointer;
  background: #fc5c42;
  border: 0.5px solid #d9d9d9;
  border-radius: 15px;
  padding: 6px 48px;
  width: 234px;
  height: 48px;
  margin-top: 24px;
`;

const ReturnToHome = styled.div`
  cursor: pointer;
  text-decoration-line: underline;
  color: #696969;
  font-size: 16px;
  line-height: 19px;
  margin-top: 16px;
`;

const GoToYourShopText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.9);
  width: 115px;
`;
const GoToHomeText = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.9);
  width: 140px;
`;
const CongratsTitle = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #2cdd73;
  margin-top: 16px;
`;

const WelcomeTitle = styled.div<{ topSpace: string }>`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #3e3e3e;
  margin-top: ${({ topSpace }) => topSpace};
`;

const SubTitle = styled.div`
  font-size: 18px;
  line-height: 22px;
  color: #3e3e3e;
  margin-top: 8px;
`;

const SecondSubTitle = styled.div`
  font-size: 18px;
  line-height: 22px;
  color: #3e3e3e;
  margin-top: 8px;
`;
