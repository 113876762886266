import * as React from 'react';
import { IntlShape, FormattedMessage } from 'react-intl';
import { ClientRouter, AuthRoute } from 'router/routes';
import { MenuItem } from 'components/Popper';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import LanguageToggle from 'components/@client/LanguageToggle';
import { stringify } from 'utils/string.utils';
import { Message } from 'i18n';
import { userLogData } from 'mockData/userLogData';
import Icon from 'components/Icon';
import {
  LogoutOutlined,
  ShoppingCartOutlined,
  LocalMallOutlined,
  MapsHomeWorkOutlined,
  PhoneInTalkOutlined,
  ContactSupportOutlined,
  PermIdentityOutlined,
} from '@mui/icons-material';
import { LogsAPI } from 'api/methods';
// import { Storefront } from '@material-ui/icons';
// import { Type } from 'constants/config';

export const userItems = (
  route: string,
  onLanguageChange: VoidFunction,
  intl: IntlShape,
  type: string | undefined,
  ids: string | undefined,
  seller_supplier: number | undefined
) => [
  {
    component: (
      <>
        <IconContainer>
          <PermIdentityOutlined />
        </IconContainer>
        <b>
          <FormattedMessage id={Message.MENU_ITEM_PROFILE} />
        </b>
      </>
    ),
    to: ClientRouter.PROFILE,
    onClick: () => {
      LogsAPI.postUserLogs({
        name: userLogData.eventName.myProfile,
        area: userLogData.eventArea.homePage,
        section: userLogData.eventSection.personalMenu,
        path: userLogData.eventPath.appPath,
      });
    },
  },
  {
    component: (
      <>
        <IconContainer>
          <MapsHomeWorkOutlined />
        </IconContainer>

        <FormattedMessage id={Message.MENU_ITEM_PROPERTIES} />
      </>
    ),
    to:
      (type === 'SELLER' &&
        (ids === '7' ||
          ids === '16' ||
          seller_supplier === 7 ||
          seller_supplier === 16)) ||
      (type === 'SUPPLIER' &&
        (ids === '7' ||
          ids === '16' ||
          seller_supplier === 7 ||
          seller_supplier === 16))
        ? ClientRouter.PROPERTIES_SELLER
        : ClientRouter.PROPERTIES,
    onClick: () => {
      LogsAPI.postUserLogs({
        name: userLogData.eventName.myProperties,
        area: userLogData.eventArea.homePage,
        section: userLogData.eventSection.personalMenu,
        path: userLogData.eventPath.appPath,
      });
    },
  },
  {
    component: (
      <>
        <IconContainer>
          <LocalMallOutlined />
        </IconContainer>
        <FormattedMessage id={Message.MENU_ITEM_ORDERS} />
      </>
    ),
    to: ClientRouter.ORDERS,
    onClick: () => {
      LogsAPI.postUserLogs({
        name: userLogData.eventName.myOrders,
        area: userLogData.eventArea.homePage,
        section: userLogData.eventSection.personalMenu,
        path: userLogData.eventPath.appPath,
      });
    },
    divider: true,
  },
  {
    component: (
      <>
        <IconContainer>
          <Icon size={18} color="#000" name="furnitures" />
        </IconContainer>

        <FormattedMessage id={Message.MENU_ITEM_PRODUCTS} />
      </>
    ),
    to: ClientRouter.PRODUCTS,
    onClick: () => {
      LogsAPI.postUserLogs({
        name: userLogData.eventName.productsCatalog,
        area: userLogData.eventArea.homePage,
        section: userLogData.eventSection.personalMenu,
        path: userLogData.eventPath.appPath,
      });
    },
  },
  {
    component: (
      <>
        <IconContainer>
          <ShoppingCartOutlined />
        </IconContainer>

        <FormattedMessage id={Message.MENU_ITEM_CART} />
      </>
    ),
    to: route.includes(ClientRouter.CART) ? route : ClientRouter.CART,
    onClick: () => {
      LogsAPI.postUserLogs({
        name: userLogData.eventName.shoppingCart,
        area: userLogData.eventArea.homePage,
        section: userLogData.eventSection.personalMenu,
        path: userLogData.eventPath.appPath,
      });
    },
  },
  {
    component: (
      <>
        <IconContainer>
          <ContactSupportOutlined />
        </IconContainer>
        <FormattedMessage id={Message.MENU_ITEM_SUPPORT} />
      </>
    ),

    to: ClientRouter.HELP,
    onClick: () => {
      LogsAPI.postUserLogs({
        name: userLogData.eventName.customerSupport,
        area: userLogData.eventArea.homePage,
        section: userLogData.eventSection.personalMenu,
        path: userLogData.eventPath.appPath,
      });
    },
    divider: true,
  },
  {
    component: (
      <>
        <IconContainer>
          <PhoneInTalkOutlined />
        </IconContainer>

        <FormattedMessage id={Message.MENU_ITEM_CONTACT} />
      </>
    ),
    to: ClientRouter.CONTACT,
    onClick: () => {
      LogsAPI.postUserLogs({
        name: userLogData.eventName.contactUs,
        area: userLogData.eventArea.homePage,
        section: userLogData.eventSection.personalMenu,
        path: userLogData.eventPath.appPath,
      });
    },
  },
  {
    component: <LanguageToggle />,
    onClick: onLanguageChange,
  },
  {
    component: (
      <>
        <IconContainer>
          <LogoutOutlined fontSize="small" />
        </IconContainer>

        <FormattedMessage id={Message.MENU_ITEM_LOGOUT} />
      </>
    ),
    to: stringify({ dialog: 'logout' }),
    onClick: () => {
      LogsAPI.postUserLogs({
        name: userLogData.eventName.logOut,
        area: userLogData.eventArea.homePage,
        section: userLogData.eventSection.personalMenu,
        path: userLogData.eventPath.appPath,
      });
    },
  },
  // ...(type === Type.SUPPLIER
  //   ? [
  //       {
  //         component: (
  //           <>
  //             <IconContainer>
  //               <Storefront />
  //             </IconContainer>

  //             <FormattedMessage id={Message.HOME_SUPPLIER_CMS_TITLE} />
  //           </>
  //         ),
  //         to: SupplierRouter.DASHBOARD,
  //         onClick: () => {
  //           LogsAPI.postUserLogs({
  //             name: userLogData.eventName.logOut,
  //             area: userLogData.eventArea.homePage,
  //             section: userLogData.eventSection.personalMenu,
  //             path: userLogData.eventPath.appPath,
  //           });
  //         },
  //       },
  //     ]
  //   : []),
  // ...(type === Type.TEAM_PATRICIA
  //   ? [
  //       {
  //         component: (
  //           <>
  //             <IconContainer>
  //               <Storefront />
  //             </IconContainer>

  //             <FormattedMessage id={Message.HOME_MANAGEMENT_TITLE} />
  //           </>
  //         ),
  //         to: SupplierRouter.DASHBOARD,
  //         onClick: () => {
  //           LogsAPI.postUserLogs({
  //             name: userLogData.eventName.logOut,
  //             area: userLogData.eventArea.homePage,
  //             section: userLogData.eventSection.personalMenu,
  //             path: userLogData.eventPath.appPath,
  //           });
  //         },
  //       },
  //     ]
  //   : []),
];

export const guestItems: (
  onLanguageChange: VoidFunction,
  isFromSupportedCountry?: boolean
) => MenuItem[] = (onLanguageChange, isFromSupportedCountry) =>
  !isFromSupportedCountry
    ? [
        {
          component: (
            <FormattedMessage id={Message.MENU_ITEM_TEAM_MEMBER_LOGIN} />
          ),
          to: AuthRoute.LOGIN,
        },
      ]
    : [
        {
          component: (
            <FormattedMessage id={Message.MENU_ITEM_LOGIN} tagName="strong" />
          ),
          to: AuthRoute.LOGIN,
        },
        {
          component: (
            <FormattedMessage
              id={Message.MENU_ITEM_MENU_ITEM_REGISTRATION}
              tagName="strong"
            />
          ),
          to: ClientRouter.ONBOARDING,
        },
        {
          component: <LanguageToggle />,
          onClick: onLanguageChange,
        },
        {
          component: (
            <FormattedMessage id={Message.MENU_ITEM_SUPPORT} tagName="strong" />
          ),
          to: ClientRouter.HELP,
        },
      ];

const IconContainer = styled.div`
  ${rtl`
    margin-right: 10px;
  `}

  margin-right: 10px;
  & .icon-furnitures {
    stroke: black;
    stroke-width: 10px;
    width: 1.4rem;
    height: 1.4rem;
  }
  width: 24px;
  height: 24px;
`;
