import * as React from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { Setter } from 'types/common.types';
import DesktopTooltip from './DesktopTooltip';
import MobileTooltip from './MobileTooltip';

const CustomTooltip: React.FC<{
  label: string;
  title: React.ReactNode;
  open?: boolean;
  setOpen?: Setter<boolean>;
}> = ({ label, title, open, setOpen }) => {
  return (
    <>
      {isTablet || isMobile ? (
        <MobileTooltip
          label={label}
          title={title}
          open={open}
          setOpen={setOpen}
        />
      ) : (
        <DesktopTooltip label={label} title={title} />
      )}
    </>
  );
};

export default CustomTooltip;
