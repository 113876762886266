import * as React from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import * as Action from 'store/actions/notifications.actions';
import { useSelector } from 'store/selectors';
import { ShowNotificationPayload } from 'store/types/notification.types';

const useNotifications = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(state => state.notifications, shallowEqual);

  const showNotification = React.useCallback(
    (payload: ShowNotificationPayload) =>
      dispatch(Action.showNotification(payload)),
    [dispatch]
  );

  const closeNotification = React.useCallback(
    (payload: string) => dispatch(Action.closeNotification(payload)),
    [dispatch]
  );

  const resetNotifications = React.useCallback(
    () => dispatch(Action.resetNotifications()),
    [dispatch]
  );

  return {
    notifications,
    showNotification,
    closeNotification,
    resetNotifications,
  };
};

export default useNotifications;
