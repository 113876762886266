import * as React from 'react';
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router';
import qs from 'qs';

function useRouter<P extends object>(path: string = '') {
  const params = useParams<P>();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch(path);

  return React.useMemo(
    () => ({
      push: history.push,
      replace: history.replace,
      pathname: location.pathname,
      query: {
        ...qs.parse(location.search, { ignoreQueryPrefix: true }), // Convert string to object
        ...params,
      },
      // Include match, location, history objects so we have
      match,
      location,
      history,
    }),
    [params, match, location, history]
  );
}

export default useRouter;
