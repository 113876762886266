import * as React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import Alert from '@material-ui/core/Alert';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Slide from '@material-ui/core/Slide';
import styled from 'styled-components';
import CardList, { CartItem } from 'components/@client/CardList';
import Modal, { ModalProps } from 'components/@client/Modal';
import { MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';
import { CartItem as ICartItem } from 'types/properties';
import { ErrorSharp } from '@material-ui/icons';
import { Message } from 'i18n';

interface Props extends ModalProps {
  items: ICartItem[];
  isProceeding: boolean;
  onProceed: VoidFunction;
}

const RestrictionModal: React.FC<Props> = ({
  items,
  onProceed,
  isProceeding,
  ...props
}) => {
  const isMobile = useMediaQuery(MOBILE_TABLET_RESOLUTION);
  const intl = useIntl();
  return (
    <Container
      fullWidth
      fullScreen={isMobile}
      TransitionComponent={Slide}
      transitionDuration={600}
      title={intl.formatMessage({ id: Message.CART_RESTRICTION_MODAL_TITLE })}
      actions={[
        {
          isLoading: isProceeding,
          label: (
            <FormattedMessage
              id={Message.CART_RESTRICTION_MODAL_CONTINUE_BUTTON}
            />
          ),
          variant: 'primary',
          onClick: onProceed,
        },
        {
          label: (
            <FormattedMessage
              id={Message.CART_RESTRICTION_MODAL_EDIT_CART_BUTTON}
            />
          ),
          variant: 'secondary',
        },
      ]}
      {...props}
    >
      <Alert
        variant="standard"
        iconMapping={{ error: <ErrorSharp /> }}
        severity="error"
      >
        <FormattedMessage
          id={Message.CART_RESTRICTION_MODAL_MISSING_ITEMS_ERROR}
        />
      </Alert>
      <h4 className="text">
        <FormattedMessage
          id={Message.CART_RESTRICTION_MODAL_MISSING_ITEMS_SUBTITLE}
        />
      </h4>
      <div className="list">
        <CardList
          items={items}
          renderItem={item => (
            <CartItem dense={!isMobile} key={item.id} {...item} />
          )}
        />
      </div>
    </Container>
  );
};

export default RestrictionModal;

const Container = styled(Modal)`
  .MuiAlert-standardError {
    display: flex;
    align-items: center;
    background: #fae7ec;
    padding: 0 40px;
  }
  .MuiAlert-icon {
    align-items: center;
  }
  z-index: 5 !important;
  .MuiAlert-message {
    color: rgba(21, 25, 32, 0.88);
    font-weight: 300;
    font-size: 1.25rem;
    @media (max-width: 1150px) {
      font-size: 0.75rem;
    }
  }
  .MuiDialogContent-root {
    @media (min-width: 1150px) {
      overflow-y: hidden;
    }

    @media (max-width: 500px) {
      padding: 0;
    }
  }
  &&.client-dialog .MuiDialog-paper {
    max-width: 620px;
    border-radius: 12px;
    padding: 0;
  }

  &&.client-dialog .MuiDialog-paper .MuiDialogTitle-root {
    margin: 10px auto;
    padding: 10px 24px;
    font-size: 1.25rem;

    h2 {
      font-weight: 700;
    }
  }

  .list {
    overflow: overlay;
    ${({ theme }) => theme.utils.scrollbar};
  }

  & .MuiDialogContent-root {
    padding: 8px 0;
    margin-bottom: 35px;
    color: rgba(21, 25, 32, 0.5);

    @media (max-width: 500px) {
      padding: 0;
    }
    .text {
      padding: 0 55px;
      color: rgba(21, 25, 32, 0.88);
      font-weight: 700;
      font-size: 1rem;
      text-align: center;

      @media (min-width: 500px) {
        text-align: start;
      }
    }
  }

  &&.client-dialog .MuiDialog-paper .MuiDialogActions-root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > button {
      margin: 15px 0;
      max-width: 350px;
      width: 100%;
      border-radius: 12px;
      min-height: 56px;
      text-transform: none;

      @media (min-width: 1150px) {
        font-size: 1.125rem;
        border-radius: 6px;
      }
    }
  }

  .card {
    box-shadow: none;
    padding: 0;
    transform: rotateY(${({ theme }) => (theme.dir === 'ltr' ? 0 : -180)}deg);
  }

  .card-list {
    padding: 0 30px;
    transform: rotateY(${({ theme }) => (theme.dir === 'ltr' ? 0 : 180)}deg);
    @media (min-width: 1150px) {
      height: 40vh;
      ${({ theme }) => theme.utils.scrollbar};
      overflow-y: overlay;
      width: 100%;
    }
    @media (min-width: 500px) {
      padding: 0px 52px;
    }
  }

  .cart-item {
    margin: unset;
    @media (min-width: 500px) {
      max-width: 100%;
    }
  }
`;
