import { all, call, put, debounce } from 'redux-saga/effects';
import DesignStylesDataType from 'store/constants/quiz-management/designStyles.constants';
import { DesignStylesAPI } from 'api/methods';
import * as DesignStylesActions from 'store/actions/quiz-management/designStyles.actions';
import * as Payload from 'store/types/designStyles.types';
import { showNotification } from 'store/actions/notifications.actions';
import { removeEmptyValues } from 'utils/common.utils';
import { newSortByMap } from 'utils/transform.utils';
import { FILTER_DATE_FORMAT, formatDate } from 'utils/dates.utils';

function* errorHandler(
  e: Error,
  type: DesignStylesDataType,
  rejectAction: (payload: {
    error: string;
  }) => { type: DesignStylesDataType; payload: { error: string } }
) {
  const error = e.message || 'ERROR: Cannot refresh Data source';
  yield put(showNotification({ key: type, message: error, severity: 'error' }));
  yield put(rejectAction({ error }));
}

const sortDesignStylesMap = {
  id: 'id',
  name: 'name',
  createdAt: 'created_at',
  productsUploaded: 'products_count',
  createdBy: 'created_by',
};

function* getDesignStylesDataRequest({
  payload,
  type,
}: ReturnType<typeof DesignStylesActions.getDesignStylesDataRequest>) {
  try {
    const { page = 0, size = 10, search, dateRange, sortBy } = payload;
    const params = removeEmptyValues({
      page: page + 1,
      size,
      search,
      from_date: formatDate(dateRange?.startDate, FILTER_DATE_FORMAT, ''),
      to_date: formatDate(dateRange?.endDate, FILTER_DATE_FORMAT, ''),
      ...newSortByMap<typeof sortDesignStylesMap>(sortDesignStylesMap, sortBy),
    }) as Payload.GetDesignStylesDataRequestPayload;
    const {
      results: designStyles,
      count,
    }: Payload.GetDesignStylesDataResponsePayload = yield call(
      DesignStylesAPI.getDesignStyles,
      { params: { ...params, is_archived: false } }
    );

    const pageCount = Math.ceil(count / size);
    yield put(
      DesignStylesActions.getDesignStylesDataFulfilled({
        designStyles,
        pageCount,
      })
    );
  } catch (e) {
    yield call(
      errorHandler,
      e,
      type,
      DesignStylesActions.getDesignStylesDataRejected
    );
  }
}

function* designStylesSaga() {
  yield all([
    debounce(
      500,
      DesignStylesDataType.GET_DESIGN_STYLES_DATA_REQUEST,
      getDesignStylesDataRequest
    ),
  ]);
}

export default designStylesSaga;
