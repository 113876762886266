import * as React from 'react';
import { QuizQuestion } from 'api/responses';
import { PropertyMedia } from 'types/properties';
import { Room } from 'types/common.types';

type QuestionID = number;
type AnswerID = number | null;

interface State {
  image: File | null;
  floorPlanFiles: PropertyMedia[];
  selectedFloor: PropertyMedia | null;
  propertyId: number | null;
  questions: QuizQuestion[];
  currentQuestion: number;
  answers: Record<QuestionID, AnswerID>;
  progress: number;
  selectedQuizResult: number | null;
  choosePlan: boolean;
  maxPrice: number;
  rooms: Room[];
  panorama: null | { id: number; thumbnail: string; name: string };
  newPropertyType: string;
  isPremadeProperty: boolean;
  isScan: boolean;
}

const initialState: State = {
  image: null,
  floorPlanFiles: [],
  selectedFloor: null,
  propertyId: null,
  currentQuestion: 0,
  questions: [],
  answers: {},
  progress: 25,
  selectedQuizResult: null,
  choosePlan: false,
  maxPrice: 0,
  rooms: [],
  panorama: null,
  newPropertyType: '',
  isPremadeProperty: false,
  isScan: false,
};

export enum OnboardingAction {
  SET_IMAGE = 'setImage',
  SET_PLAN_FILES = 'setPlanFiles',
  SET_SELECTED_FLOOR = 'setSelectedFloor',
  SET_QUIZ_QUESTIONS = 'setQuizQuestions',
  SET_CURRENT_QUESTION = 'setCurrentQuestion',
  SET_QUIZ_ANSWER = 'setQuizAnswer',
  SET_PROGRESS = 'setProgress',
  SET_SELECTED_QUIZ_RESULT = 'setSelectedQuizResult',
  SET_CHOOSE_PLAN = 'setChoosePlan',
  SET_MAX_PRICE = 'setMaxPrice',
  SET_ROOM_LIST = 'setRoomList',
  SET_PANORAMA = 'setPanorama',
  SET_NEW_PROPERTY_TYPE = 'setNewPropertyType',
  SET_IS_PREMADE_PROPERTY = 'setIsPremadeProperty',
  SET_IS_SCAN = 'setIsScan',
}

export type PropertyPlanPayload = { files: PropertyMedia[]; id: number };
export type SetQuizAnswerPayload = {
  question: number;
  answer: number | null;
  currentQuestion: number;
  progress: number;
};
export type SetCurrentQuestionPayload = {
  currentQuestion: number;
  progress: number;
};

type Payload =
  | File
  | PropertyPlanPayload
  | PropertyMedia
  | SetCurrentQuestionPayload
  | QuizQuestion[]
  | SetQuizAnswerPayload
  | Record<string, string | null>
  | number
  | boolean
  | null
  | Room[]
  | { id: number; thumbnail: string; name: string }
  | string;

export interface Action {
  type: OnboardingAction;
  payload: Payload;
}

type OnboardingReducer = (state: State, action: Action) => State;

const onboardingReducer: OnboardingReducer = (state, { type, payload }) => {
  switch (type) {
    case OnboardingAction.SET_IMAGE:
      return {
        ...state,
        image: payload as File,
      };
    case OnboardingAction.SET_PLAN_FILES:
      return {
        ...state,
        floorPlanFiles: (payload as PropertyPlanPayload).files,
        propertyId: (payload as PropertyPlanPayload).id,
        selectedFloor:
          ((payload as PropertyPlanPayload)?.files ?? []).length === 1
            ? (((payload as PropertyPlanPayload)?.files)[0] as PropertyMedia)
            : null,
      };
    case OnboardingAction.SET_SELECTED_FLOOR:
      return {
        ...state,
        selectedFloor: payload as PropertyMedia,
      };
    case OnboardingAction.SET_QUIZ_ANSWER:
      return {
        ...state,
        currentQuestion: (payload as SetQuizAnswerPayload).currentQuestion,
        answers: {
          ...state.answers,
          [(payload as SetQuizAnswerPayload)
            .question]: (payload as SetQuizAnswerPayload).answer,
        },
        progress: (payload as SetQuizAnswerPayload).progress,
      };
    case OnboardingAction.SET_QUIZ_QUESTIONS:
      return {
        ...state,
        questions: payload as QuizQuestion[],
      };
    case OnboardingAction.SET_CURRENT_QUESTION:
      return {
        ...state,
        currentQuestion: (payload as SetCurrentQuestionPayload).currentQuestion,
        progress: (payload as SetCurrentQuestionPayload).progress,
      };
    case OnboardingAction.SET_PROGRESS:
      return {
        ...state,
        progress: payload as number,
      };
    case OnboardingAction.SET_SELECTED_QUIZ_RESULT:
      return {
        ...state,
        selectedQuizResult: payload as number,
      };
    case OnboardingAction.SET_CHOOSE_PLAN:
      return {
        ...state,
        choosePlan: payload as boolean,
      };
    case OnboardingAction.SET_MAX_PRICE:
      return {
        ...state,
        maxPrice: payload as number,
      };
    case OnboardingAction.SET_ROOM_LIST:
      return {
        ...state,
        rooms: payload as Room[],
      };
    case OnboardingAction.SET_PANORAMA:
      return {
        ...state,
        panorama: payload as { id: number; thumbnail: string; name: string },
      };
    case OnboardingAction.SET_NEW_PROPERTY_TYPE:
      return {
        ...state,
        newPropertyType: payload as string,
      };
    case OnboardingAction.SET_IS_PREMADE_PROPERTY:
      return {
        ...state,
        isPremadeProperty: payload as boolean,
      };
    case OnboardingAction.SET_IS_SCAN:
      return {
        ...state,
        isScan: payload as boolean,
      };
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

const defaultDispatch: React.Dispatch<Action> = () => initialState;
const OnboardingStateContext = React.createContext<State>(initialState);
const OnboardingDispatchContext = React.createContext(defaultDispatch);

const OnboardingContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer<React.Reducer<State, Action>>(
    onboardingReducer,
    initialState
  );

  return (
    <OnboardingStateContext.Provider value={state}>
      <OnboardingDispatchContext.Provider value={dispatch}>
        {children}
      </OnboardingDispatchContext.Provider>
    </OnboardingStateContext.Provider>
  );
};

const useOnboardingState = () => {
  return React.useContext(OnboardingStateContext);
};

const useOnboardingDispatch = () => {
  return React.useContext(OnboardingDispatchContext);
};

const useOnboarding: () => [State, React.Dispatch<Action>] = () => {
  return [useOnboardingState(), useOnboardingDispatch()];
};

export {
  OnboardingContextProvider,
  useOnboardingState,
  useOnboardingDispatch,
  useOnboarding,
};
