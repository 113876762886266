import * as React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Container from 'components/Modal/Container';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import InformationIcon from 'assets/@client/cart/information_icon.svg';
import { Product } from 'types/products';
import PhotosSection from 'pages/common/modals/ProductDetailsModal/PhotosSection';
import Grid from '@material-ui/core/Grid';
import CloseButton from 'components/Modal/CloseButton';
import styledTheme from 'styles/theme';
import {
  MOBILE_TABLET_RESOLUTION,
  MOBILE_RESOLUTION,
} from 'styles/sharedStyle';
import * as Transform from 'api/transform';
import { METHODS } from 'api/client';
import { useFetch, useAuthentication, useRouter } from 'hooks';
import Loader from 'components/Loader/Loader';
import { FormattedMessage } from 'react-intl';
import { ClientRouter } from 'router/routes';
import { Message } from 'i18n';
import { useMediaQuery } from '@material-ui/core';
import Modal, { ModalAction } from 'components/@client/Modal';
import NoProduct from 'components/@client/NoProduct/NoProduct';
import rtl from 'styled-components-rtl';
import SafeImg from 'components/SafeImg';
import { ReactComponent as Checkmark } from 'components/@client/form-components/pick.svg';
import { userLogData } from 'mockData/userLogData';
import { LogsAPI } from 'api/methods';

import ProductDetails from './ProductDetails';

interface Props {
  product: Product | string;
  component?: JSX.Element | string;
  showAddToCartBtn?: boolean;
  btnOnClick?: VoidFunction;
  defaultOpen?: boolean;
  hideEveryButton?: boolean;
  isMatrix?: boolean;
  isPropertyPage?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionTitle: {
      '& > div > div': {
        fontSize: '2rem',
      },
    },
    imageContainer: {
      borderRight:
        theme.direction === 'ltr'
          ? `1px solid ${styledTheme.colors.primary.lightMobile}`
          : '',
      borderLeft:
        theme.direction !== 'ltr'
          ? `1px solid ${styledTheme.colors.primary.lightMobile}`
          : '',
      [`@media ${MOBILE_TABLET_RESOLUTION}`]: {
        borderRight: `none`,
        borderLeft: `none`,
      },
      '& > section': {
        maxWidth: '597px',
        margin: 'auto',

        '& > div:first-child': {
          height: '400px',
          width: '100%',
          [`@media ${MOBILE_TABLET_RESOLUTION}`]: {
            height: '350px',
          },

          minWidth: 'inherit',
          '& > img': {
            height: 'auto',
            width: 'auto',
            margin: 'auto',
            maxHeight: '100%',
            maxWidth: '100%',
          },
        },

        '& > div > ul': {
          maxWidth: 'none',
          margin: '0 20px',
          '& > li > div': {
            width: '76px',
            height: '76px',
          },
        },
      },
    },
  })
);

const ProductPageModal: React.FC<Props> = ({
  product,
  component,
  showAddToCartBtn,
  btnOnClick,
  defaultOpen = false,
  hideEveryButton = false,
  isMatrix = false,
  isPropertyPage = false,
}) => {
  const classes = useStyles();
  const isMobile = useMediaQuery(MOBILE_RESOLUTION);
  const { history, query } = useRouter<{ productId: string }>();
  const { token } = useAuthentication();
  const [isModalOpen, setIsModalOpen] = React.useState(defaultOpen);
  const [isOpen, setIsOpen] = React.useState(false);
  const changeModalOpen = React.useCallback(() => {
    if (query.productId) {
      history.goBack();
    }
    setIsModalOpen(prev => !prev);
  }, [query.productId, history]);
  const [productData, setProductData] = React.useState<Product>();
  const {
    isLoading: isLoaderAddToCartBtn,
    callFetch: processProductCart,
  } = useFetch({
    initialUrl: '/api/shopping_cart_item/',
    skipOnStart: true,
  });
  const { callFetch, isLoading } = useFetch({
    initialUrl: `api/products/${product}/`,
    skipOnStart: true,
    config: {
      method: METHODS.GET,
    },
    transform: Transform.transformProduct,
    onSuccess: response => {
      if (response) setProductData(response);
    },
  });

  React.useEffect(() => {
    if (defaultOpen && typeof product === 'string') {
      setIsModalOpen(true);
    }
  }, [defaultOpen, product]);

  React.useEffect(() => {
    if (typeof product === 'string' && isModalOpen) {
      callFetch({ url: `api/products/${product}/` });
    } else if (typeof product !== 'string') {
      setProductData(product);
    }
  }, [callFetch, setProductData, product, isModalOpen]);

  const onClick = React.useCallback(() => {
    if (token) {
      processProductCart({
        method: METHODS.POST,
        data: {
          product: productData?.id,
          quantity: 1,
          property: 13,
        },
      });
      setIsOpen(true);
    }
  }, [processProductCart, token, productData]);

  const actions: ModalAction[] = [
    {
      label: <FormattedMessage id={Message.CART_KEEP_SHOPPING_BUTTON} />,
      onClick: () => setIsOpen(false),
      variant: 'secondary',
    },
    {
      label: <FormattedMessage id={Message.CART_CHECKOUT_BUTTON} />,
      onClick: () =>
        token
          ? history.push(ClientRouter.CART)
          : history.push(`${ClientRouter.ADDITIONAL_INFO}?redirect=CART`),
      variant: 'primary',
    },
  ];

  const onClickModal = (productsData: Product) => {
    LogsAPI.postUserLogs({
      name: userLogData.eventName.addToCart,
      area: userLogData.eventArea.catalog,
      section: userLogData.eventSection.productModal,
      path: userLogData.eventPath.appProductSubCategoryNameProductIdPath,
      metadata: {
        product_id: productsData.id,
        product_name: productsData.name,
        product_sku: productsData.sku,
      },
    });
  };

  React.useEffect(() => {
    const footer = document.getElementById('footer');
    if (isModalOpen && footer != null) footer.style.zIndex = '0';
    return () => {
      if (footer != null) footer.style.zIndex = '999';
    };
  }, [isModalOpen]);

  return (
    <>
      <>
        {(component && <div onClick={changeModalOpen}>{component}</div>) || (
          <>
            {!hideEveryButton && showAddToCartBtn ? (
              <>
                {isMobile ? (
                  <>
                    <DesktopIconButton
                      className="x-button-mobile"
                      disabled={productData && productData.notForSale}
                      onClick={changeModalOpen}
                    >
                      <img src={InformationIcon} alt="Info" />
                    </DesktopIconButton>
                    <ViewDetailsBtn
                      disabled={productData && productData.notForSale}
                      onClick={() => {
                        btnOnClick?.();
                        onClick();
                      }}
                    >
                      {isLoaderAddToCartBtn ? (
                        <Loader size={18} />
                      ) : (
                        <FormattedMessage id={Message.ADD_TO_CART} />
                      )}
                    </ViewDetailsBtn>
                  </>
                ) : (
                  <>
                    <ViewDetailsBtn
                      disabled={productData && productData.notForSale}
                      // showDisable={productData && productData.notForSale}
                      onClick={() => {
                        btnOnClick?.();
                        onClick();
                      }}
                    >
                      {isLoaderAddToCartBtn ? (
                        <Loader size={18} />
                      ) : (
                        <FormattedMessage id={Message.ADD_TO_CART} />
                      )}
                    </ViewDetailsBtn>

                    {isPropertyPage ? (
                      <DesktopIconButton onClick={changeModalOpen}>
                        <img src={InformationIcon} alt="Delete" />
                      </DesktopIconButton>
                    ) : (
                      <ViewDetailsGrayBtn onClick={changeModalOpen}>
                        <FormattedMessage id={Message.VIEW_DETAILS} />
                      </ViewDetailsGrayBtn>
                    )}
                  </>
                )}
              </>
            ) : (
              !hideEveryButton && (
                <>
                  {isMobile ? (
                    <IconButton
                      className="x-button-mobile"
                      onClick={changeModalOpen}
                    >
                      <img src={InformationIcon} alt="Delete" />
                    </IconButton>
                  ) : (
                    <ViewDetailsBtn onClick={changeModalOpen} disabled={false}>
                      <FormattedMessage id={Message.VIEW_DETAILS} />
                    </ViewDetailsBtn>
                  )}
                </>
              )
            )}
          </>
        )}

        <Container
          $borderRadius="10px"
          open={isModalOpen}
          onClose={changeModalOpen}
          $width="70%"
        >
          {productData && !isLoading ? (
            <>
              <CloseButtonContainer>
                <CloseButton
                  onClick={changeModalOpen}
                  className="close-button"
                />
              </CloseButtonContainer>
              <Grid container>
                <Grid xs={12} sm={12} md={6}>
                  <div className={classes.imageContainer}>
                    <PhotosSection
                      images={productData?.media}
                      alt={productData?.name}
                      showArrows
                    />
                  </div>
                </Grid>

                <Grid xs={12} sm={12} md={6}>
                  <ProductDetailsContainer>
                    <ProductDetails
                      product={productData}
                      onClickAddToCart={() => onClickModal(productData)}
                      isMatrix={isMatrix}
                    />
                  </ProductDetailsContainer>
                </Grid>
              </Grid>
            </>
          ) : (
            (isLoading && <Loader />) ||
            (((!productData && productData !== undefined) || !isLoading) && (
              <NoProduct value={Message.NO_PRODUCT_AVAILABLE} />
            ))
          )}
        </Container>

        <ConfirmModal
          className="confirm-modal"
          onClose={() => setIsOpen(false)}
          actions={actions}
          open={isOpen}
        >
          <Content>
            <div>
              <SafeImg
                src={productData?.thumbnail?.file}
                alt={productData?.name}
              />
            </div>
            <Checkmark className="checkmark" />
            <FormattedMessage id={Message.ADDED_TO_CART} />
          </Content>
        </ConfirmModal>
      </>
    </>
  );
};

export default ProductPageModal;

const ProductDetailsContainer = styled.div`
  margin-top: 0px;
  @media ${MOBILE_TABLET_RESOLUTION} {
    margin-top: 0;
  }
`;
const DesktopIconButton = styled(IconButton)`
  position: absolute !important;
  
  ${rtl`
    right: 1%;
  `}
  top: 66%;
  z-index: 99;
  @media ${MOBILE_RESOLUTION} {
    ${rtl`
    right: 1%;
  `}
  top: 57%;
  z-index: 99;
`;
const ViewDetailsBtn = styled.button.attrs({ className: 'view-details-btn' })<{
  disabled: boolean | undefined;
}>`
  background: ${({ theme, disabled }) =>
    disabled ? '#e8e8e8' : theme.colors.primary.lightMobile};
  width: fit-content;
  color: white;
  box-shadow: none;
  border: none;
  cursor: pointer;
  font-family: ${({ theme }) => (theme.dir === 'rtl' ? 'Assistant' : 'Roboto')};
  margin: 4px 0;
  @media ${MOBILE_RESOLUTION} {
    ${rtl`
    margin:0;
  `}
    margin-top: 8px;
  }
  border-radius: 10px;
  padding: 7px 20px;
  margin-top: 10px;

  & > div {
    opacity: 1;
  }
`;

const ViewDetailsGrayBtn = styled.button`
  width: fit-content;
  color: ${({ theme }) => theme.colors.primary.dark};
  box-shadow: none;
  border: 1px solid ${({ theme }) => theme.colors.primary.dark};
  cursor: pointer;
  font-family: ${({ theme }) => (theme.dir === 'rtl' ? 'Assistant' : 'Roboto')};
  margin: 4px 0;
  border-radius: 10px;
  padding: 7px 20px;
  background-color: white;
  margin-top: 10px;
  ${rtl`
    margin-left: 15px;
  `}
`;
const ConfirmModal = styled(Modal)`
  & .MuiDialogActions-root {
    @media ${MOBILE_RESOLUTION} {
      display: block;
      text-align: center;
      & > button {
        margin: 10px !important;
      }
    }
    & > button {
      min-width: 150px;
    }
  }
`;

const Content = styled.p`
  text-align: center;
  img {
    max-width: 80px;
    max-height: 80px;
    margin-bottom: 10px;
  }
  margin-bottom: 20px;
  font-size: 1.25rem;
  color: ${({ theme }) => theme.colors.black0};
  svg {
    path {
      fill: ${({ theme }) => theme.colors.primary.lightMobile};
    }
    ${rtl`
      margin-right: 10px;
    `}
  }
`;

const CloseButtonContainer = styled.div`
  && {
    .close-button {
      z-index: 9;
    }
  }
`;
