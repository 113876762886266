import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link } from 'react-router-dom';
import noop from 'lodash/noop';
import styled from 'styled-components';
import { RegisterKey } from 'api/responses';
import * as Transform from 'api/transform';
import Button from 'components/Button';
import { FormattedMessage } from 'react-intl';
import { Message } from 'i18n';
import { ClientRouter } from 'router/routes';
import Divider from 'components/Divider';
import * as Form from 'components/forms';
import Anchor from 'components/Link';
import { Type } from 'constants/config';
import { useAuthentication, useBreakpoint, useFetch, useRouter } from 'hooks';
import { RegisterPayload } from 'store/types/authentication.types';
import { registerSchema } from 'utils/validation.utils';
import { prefixPhoneNumber } from 'utils/string.utils';
import FormDivider from 'components/Divider/FormDivider';
import { AuthProvidersWrapper, AuthLinkRow } from 'styles/sharedStyle';
import { isEmpty } from 'utils/lodash.utils';
import {
  AuthForm,
  Fields,
  registrationFields,
  registerInitialValues,
} from './Auth.config';
import Providers from './providers';

const Register = () => {
  const { isSmallScreen } = useBreakpoint();
  const { query } = useRouter<{ key: string }>();
  const { register, loading } = useAuthentication();

  const methods = useForm<RegisterPayload>({
    resolver: yupResolver(registerSchema),
    defaultValues: registerInitialValues,
    mode: 'all',
  });

  const { formState, handleSubmit, reset } = methods;
  const { isValid } = formState;
  const onSubmit = ({ phoneNumber, ...payload }: RegisterPayload) => {
    if (phoneNumber && !isEmpty(phoneNumber)) {
      const phoneNumber1 = prefixPhoneNumber(phoneNumber);
      return register({
        ...payload,
        phoneNumber: phoneNumber1,
        key: query.key,
      });
    }
    return register({
      ...payload,
      key: query.key,
    });
  };

  const { data, callFetch } = useFetch<RegisterKey>({
    initialUrl: `/api/invites/${query.key}/`,
    skipOnStart: true,
  });

  React.useEffect(() => {
    if (query.key) callFetch();
  }, [callFetch, query.key]);

  React.useEffect(() => {
    if (data) reset(Transform.registerKeyResponse(data));
  }, [data, reset]);

  return (
    <Container title="Sign up to Patricia">
      <FormProvider {...methods}>
        <CustomForm onSubmit={handleSubmit(onSubmit)}>
          <Fields className="register-fields" direction="row">
            {registrationFields.map(field => (
              <React.Fragment key={field.name}>
                {field.name === 'phoneNumber' ? (
                  <>
                    <Form.PhoneField {...field} />
                    {!isSmallScreen && <FormDivider />}
                  </>
                ) : (
                  <Form.Field
                    {...field}
                    disabled={
                      !!query.key &&
                      ['email', 'verifyEmail'].includes(field.name)
                    }
                    required
                    onKeyDown={noop}
                  />
                )}
              </React.Fragment>
            ))}
          </Fields>
          <Form.Checkbox
            name="termsAndConditions"
            label={
              <CheckboxLabel>
                <FormattedMessage
                  id={Message.AUTH_REGISTRATION_READ_TERMS_AND_POLICY}
                  values={{
                    termsOfUse: (
                      <Anchor
                        className="anchor-link"
                        href={ClientRouter.TERMS_OF_USE}
                      >
                        <FormattedMessage
                          id={Message.FOOTER_SUPPORT_MENU_TERMS}
                        />
                      </Anchor>
                    ),
                    privacyPolicy: (
                      <Anchor
                        className="anchor-link"
                        href={ClientRouter.PRIVACY_POLICY}
                      >
                        <FormattedMessage
                          id={Message.FOOTER_SUPPORT_MENU_PRIVACY}
                        />
                      </Anchor>
                    ),
                  }}
                />
              </CheckboxLabel>
            }
          />
          <Button
            className="main-button"
            variant="contained"
            color="inherit"
            type="submit"
            disabled={!isValid}
            isLoading={loading}
          >
            Sign up
          </Button>
          {(data?.type === Type.CLIENT || !data) && (
            <>
              <Divider>Or</Divider>
              <AuthProvidersWrapper>
                <Providers authContext="Register" />
              </AuthProvidersWrapper>
              <AuthLinkRow>
                Already have an account? <Link to="/login">Sign in</Link>
              </AuthLinkRow>
            </>
          )}
        </CustomForm>
      </FormProvider>
    </Container>
  );
};

export default Register;

const Container = styled(Form.Container).attrs({
  className: 'register-form-container',
})`
  && .auth-form-content {
    left: 0;
    height: 100vh;
    max-height: unset;

    @media (max-width: 1500px) {
      max-width: 535px;
      padding: 23px 40px;
    }

    @media (min-width: 1500px) {
      max-width: 623px;
      padding: 23px 28px;
    }
  }

  && .auth-form-header {
    margin-bottom: 13px;
    flex-direction: row;
    align-items: flex-end;
    gap: 100px;

    @media (max-width: 500px) {
      margin-bottom: 36px;
      gap: 25px;
    }

    @media (min-width: 1500px) {
      margin-bottom: 83px;
      gap: 95px;
    }

    & .logo {
      @media (max-width: 500px) {
        align-self: center;
        height: 40px;
      }
      align-self: flex-start;
      @media (max-width: 1500px) {
        height: 50px;
      }
      height: 77px;
    }
  }
  && .auth-header-title {
    @media (max-width: 500px) {
      font-size: 1.25rem;
      font-weight: bold;
    }
    font-size: 1.25rem;
    @media (min-width: 1500px) {
      font-size: 1.75rem;
    }
  }

  && .main-button {
    width: 100%;
    min-height: 48px;
    text-transform: none;
  }

  && .MuiFormControl-root {
    @media (min-width: 500px) {
      width: 218px;
    }

    @media (min-width: 1500px) {
      width: 270px;
    }
  }
  & .register-fields {
    row-gap: 14px;
    column-gap: 22px;

    @media (max-width: 1500px) {
      gap: 19px;
    }
    @media (max-width: 500px) {
      gap: 28px;
    }
  }
`;

const CustomForm = styled(AuthForm)`
  .input-checkbox {
    align-self: start;
    margin: 47px 0 35px 0;

    a {
      color: #1565d8;
    }
    label {
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors.primary.primaryTextColor};
    }

    @media (max-width: 1500px) {
      margin: 18px 0 18px 0;
    }

    @media (max-width: 500px) {
      align-self: flex-start;
      margin: 28px 0;
    }
  }

  & .divider-wrapper {
    @media (min-width: 1500px) {
      margin-right: 15px;
    }
  }
`;

const CheckboxLabel = styled.span`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.primary.primaryTextColor};
`;
