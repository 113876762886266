import * as React from 'react';
import { TABLET_RESOLUTION } from 'styles/sharedStyle';
import { useMediaQuery } from '@material-ui/core';
import { SkinFilter } from 'types/properties';
import useFetch from 'hooks/useFetch';
import { Option } from 'components/Autocomplete/Autocomplete.config';
import { useIntl } from 'react-intl';
import { Message } from 'i18n';
import MobileFilter from './MobileFilter';
import DeskTopFilter from './DeskTopFilter';
import { SkinSortType } from '../Skins.config';

const Controller: React.FC<{
  updateView: (data: SkinFilter) => void;
}> = ({ updateView }) => {
  const intl = useIntl();

  const isMobile = useMediaQuery(TABLET_RESOLUTION);

  const sortByOptions = React.useMemo(
    () => [
      {
        name: intl.formatMessage({
          id: Message.SKINS_SORT_POPULAR,
        }),
        value: SkinSortType.POPULAR,
      },
      {
        name: intl.formatMessage({
          id: Message.SKINS_SORT_NEW,
        }),
        value: SkinSortType.NEW,
      },
      {
        name: intl.formatMessage({
          id: Message.SKINS_SORT_PRICE_LOW,
        }),
        value: SkinSortType.LOWTOHIGH,
      },
      {
        name: intl.formatMessage({
          id: Message.SKINS_SORT_PRICE_HIGH,
        }),
        value: SkinSortType.HIGHTOLOW,
      },
    ],
    [intl]
  );

  const designStyles = [
    {
      name: 'ASIAN',
      value: 'asian',
    },
    {
      name: 'BEACH_STYLE',
      value: 'beach_style',
    },
    {
      name: 'CONTEMPORARY',
      value: 'contemporary',
    },
    {
      name: 'CRAFTSMAN ',
      value: 'craftsman',
    },
    {
      name: 'ECLECTIC ',
      value: 'eclectic',
    },
    {
      name: 'FARMHOUSE ',
      value: 'farmhouse',
    },
    {
      name: 'INDUSTRIAL ',
      value: 'industrial',
    },
    {
      name: 'MEDITERRANEAN ',
      value: 'mediterranean',
    },
    {
      name: 'MIDCENTURY ',
      value: 'midcentury',
    },
    {
      name: 'MODERN ',
      value: 'modern',
    },
    {
      name: 'RUSTIC ',
      value: 'rustic',
    },
    {
      name: 'SCANDINAVIAN ',
      value: 'scandinavian',
    },
    {
      name: 'SOUTHWESTERN ',
      value: 'southwestern',
    },
    {
      name: 'TRADITIONAL ',
      value: 'traditional',
    },
    {
      name: 'TRANSITIONAL ',
      value: 'transitional',
    },
    {
      name: 'TROPICAL ',
      value: 'tropical',
    },
    {
      name: 'VICTORIAN ',
      value: 'victorian',
    },
    {
      name: 'GENERAL ',
      value: 'general',
    },
  ];

  const { data: creators } = useFetch({
    initialUrl: '/api/skins/skin_creators/',
  });

  const { data: roomsList } = useFetch<
    | {
        id: string;
        name: string;
      }[]
    | undefined
  >({
    initialUrl: '/api/rooms/',
  });

  const colorOptions = [
    {
      name: intl.formatMessage({
        id: Message.SKINS_FILTER_COLOR_RED,
      }),
      value: 'red',
    },
    {
      name: intl.formatMessage({
        id: Message.SKINS_FILTER_COLOR_BLACK,
      }),
      value: 'black',
    },
    {
      name: intl.formatMessage({
        id: Message.SKINS_FILTER_COLOR_BLUE,
      }),
      value: 'blue',
    },
    {
      name: intl.formatMessage({
        id: Message.SKINS_FILTER_COLOR_GREY,
      }),
      value: 'grey',
    },
    {
      name: intl.formatMessage({
        id: Message.SKINS_FILTER_COLOR_YELLOW,
      }),
      value: 'yellow',
    },
    {
      name: intl.formatMessage({
        id: Message.SKINS_FILTER_COLOR_ORANGE,
      }),
      value: 'orange',
    },
    {
      name: intl.formatMessage({
        id: Message.SKINS_FILTER_COLOR_PURPLE,
      }),
      value: 'purple',
    },
    {
      name: intl.formatMessage({
        id: Message.SKINS_FILTER_COLOR_PINK,
      }),
      value: 'pink',
    },
    {
      name: intl.formatMessage({
        id: Message.SKINS_FILTER_COLOR_BROWN,
      }),
      value: 'brown',
    },
    {
      name: intl.formatMessage({
        id: Message.SKINS_FILTER_COLOR_GREEN,
      }),
      value: 'green',
    },
  ];

  const { data: colors } = useFetch<
    { color_hex: string; id: string; name: string }[]
  >({ initialUrl: '/api/colors/' });

  const budgetOptions = [
    { name: '0 - 10K', value: 1 },
    { name: '10K - 25K', value: 2 },
    { name: '25K - 100K', value: 3 },
    { name: '100K +', value: 4 },
  ];

  const rooms =
    roomsList
      ?.filter(e => e.name.toLowerCase() !== 'unassigned')
      .map(a => ({
        name: a.name,
        value: a.id,
      })) ?? [];

  return isMobile ? (
    <MobileFilter
      updateView={updateView}
      sortByOptions={sortByOptions}
      designStyles={designStyles.map(a => {
        const str = a.name.replace('_', ' ');
        // eslint-disable-next-line no-param-reassign
        a.name = str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
        return a;
      })}
      applyDesignStyle={(styles: Option | Option[]) => {}}
      creators={
        creators as Array<{
          id: string;
          first_name: string;
          last_name: string;
        }>
      }
      roomOptions={rooms}
      colorOptions={colorOptions}
      budgetOptions={budgetOptions}
    />
  ) : (
    <DeskTopFilter
      updateView={updateView}
      sortByOptions={sortByOptions}
      designStyles={designStyles.map(a => {
        const str = a.name.replace('_', ' ');
        // eslint-disable-next-line no-param-reassign
        a.name = str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
        return a;
      })}
      applyDesignStyle={(styles: Option | Option[]) => {}}
      creators={
        creators as Array<{
          id: string;
          first_name: string;
          last_name: string;
        }>
      }
      roomOptions={rooms}
      colorOptions={
        colors?.map(e => ({ ...e, value: e.color_hex } as Option)) ?? []
      }
      budgetOptions={budgetOptions}
    />
  );
};

export default Controller;
