import type {
  BaseUser,
  IAddress,
  KPI,
  UserForm,
  UserLogType,
} from 'types/common.types';
import { Address } from 'api/responses';
import type { Order, OrderDetail } from './orders';
import type { Property } from './properties';
import type { QuizResult } from './quizzes';

export type Client = BaseUser & {
  avatar?: string;
  fullName?: string;
  country?: string;
  addresses?: IAddress[] | null;
  lastSeen?: Date | string;
  createdAt?: Date | string;
  status: ClientStatus[];
  KPIs?: KPI[];
  products?: { results: OrderDetail[]; pageCount?: number };
  properties?: { results: Property[]; pageCount?: number };
  quizzes?: { results: QuizResult[]; pageCount?: number };
  orders?: { results: Order[]; pageCount?: number };
  userActivities?: { results: UserLogType[]; pageCount: number };
  archivedDate?: Date | string;
  isArchived?: boolean;
  userType: UserTypeKeys;
  supplier?: string;
  teamMember?: string;
  branch?: Address;
};

export type Room = {
  id?: string;
  name?: string;
};

export type AddClientPayload = UserForm;

export enum ClientStatus {
  SIGN_UP = 'Sign up',
  PURCHASE = 'Purchased',
  PROPERTIES_ADDED = 'Properties added',
  GUEST = 'GUEST',
}

export enum RenderStatus {
  PENDING = 'pending',
  PROCESSING = 'processing',
  ERROR = 'error',
  DONE = 'done',
  ABORTED = 'aborted',
}

export type UserTypeKeys = keyof typeof UserType;
export enum UserType {
  TEAM_PATRICIA = 'Team member',
  SUPPLIER = 'Supplier',
  CLIENT = 'Client',
  SELLER_CLIENT = 'SELLER_CLIENT',
}

export type Activity = {
  id: string;
  description: string;
  createdAt: string;
};

export type UserActivities = {
  id: string;
  user: string;
  activities: Activity[];
};

export type ContactTechnicalDetails = {
  id: string;
  department: string;
  email: string;
  phoneNumber?: string;
  description: string;
  deviceversion: string;
  problemAreas: string;
  deviceType: string;
};
