enum TeamUserDataType {
  GET_USERS_DATA_REQUEST = 'teamPatricia/getUsersDataRequest',
  GET_USERS_DATA_REJECTED = 'teamPatricia/getUsersDataRejected',
  GET_USERS_DATA_FULFILLED = 'teamPatricia/getUsersDataFulfilled',

  GET_ROLES_DATA_REQUEST = 'teamPatricia/getRolesDataRequest',
  GET_ROLES_DATA_REJECTED = 'teamPatricia/getRolesDataRejected',
  GET_ROLES_DATA_FULFILLED = 'teamPatricia/getRolesDataFulfilled',

  ADD_USER_DATA_REQUEST = 'teamPatricia/addUserDataRequest',
  ADD_USER_DATA_REJECTED = 'teamPatricia/addUserDataRejected',
  ADD_USER_DATA_FULFILLED = 'teamPatricia/addUserDataFulfilled',
}

export default TeamUserDataType;
