import * as React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { MOBILE_TABLET_RESOLUTION } from 'styles/sharedStyle';
import DeskTopSectionTitle from './DeskTopSectionTitle';
import MobileSectionTitle from './MobileSectionTitle';

interface Props {
  label: string | React.ReactNode;
  borderColor?: string;
  isLoading?: boolean;
  action?: {
    label: string | React.ReactNode;
    onClick?: VoidFunction;
    to?: string;
  };
  center?: boolean;
  titleColor?: string;
  borderImage?: string;
}

const SectionTitle: React.FC<Props> = ({
  label,
  borderColor = '#87b9e7',
  action,
  isLoading,
  center,
  titleColor,
  borderImage,
}) => {
  const isMobile = useMediaQuery(MOBILE_TABLET_RESOLUTION);

  return (
    <>
      {isMobile ? (
        <MobileSectionTitle
          label={label}
          borderColor={borderColor}
          action={action}
          isLoading={isLoading}
          center={center}
          titleColor={titleColor}
          borderImage={borderImage}
        />
      ) : (
        <DeskTopSectionTitle
          label={label}
          borderColor={borderColor}
          action={action}
          isLoading={isLoading}
          center={center}
          titleColor={titleColor}
          borderImage={borderImage}
        />
      )}
    </>
  );
};

export default React.memo(SectionTitle);
