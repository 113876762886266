import * as React from 'react';
import useRouter from 'hooks/useRouter';
import { Type } from 'constants/config';
import Sidebar from 'components/Sidebar';
import { isEmpty } from 'utils/lodash.utils';
import { Tab } from 'utils/sidebar.utils';
import managementTabs from './management.config';
import supplierTabs from './supplier.config';

const tabsConfig = {
  TEAM_PATRICIA: managementTabs,
  // TODO: ADD DIFFERENT CONFIG FOR EACH TYPE
  SUPPLIER: supplierTabs,
  CLIENT: () => [] as Tab[],
  SELLER: () => [] as Tab[],
  SELLER_CLIENT: () => [] as Tab[],
  PROFESSIONAL: () => [] as Tab[],
};

interface Props {
  userType: Type;
}

const ExternalSidebar: React.FC<Props> = ({ userType }) => {
  const { pathname } = useRouter();
  const items = React.useMemo(() => {
    const tabs = tabsConfig[userType];
    return tabs(pathname);
  }, [pathname, userType]);
  if (isEmpty(items)) return null;
  return <Sidebar items={items} displayLogo fixed />;
};

export default ExternalSidebar;
