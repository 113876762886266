import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Check } from '@material-ui/icons';
import { LogsAPI } from 'api/methods';
import MultipleChips from 'components/MultipleChips/MultipleChips';
import SliderRange from 'components/SilderRange/SliderRange';
import {
  OnboardingAction,
  useOnboarding,
  useOnboardingState,
} from 'context/onboardingContext';
import { useRouter, useSession, useFormattedCurrency } from 'hooks';
import { Message } from 'i18n';
import { rooms as roomListData } from 'mockData/rooms';
import { userLogData } from 'mockData/userLogData';
import * as Section from 'pages/appClient/onboarding/shared';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';
import { animated, config, useTransition } from 'react-spring';
import { ClientRouter } from 'router/routes';
import styled, { css } from 'styled-components';
import rtl from 'styled-components-rtl';
import {
  MOBILE_RESOLUTION,
  SMALL_WIDTH_RESOLUTION,
  MOBILE_TABLET_RESOLUTION,
} from 'styles/sharedStyle';
import { MediaFile, Room, SupportedCountry } from 'types/common.types';
import Loader from 'components/Loader/Loader';
import useOnboardingQuiz from '../plan-confirmed/useOnboardingQuiz';
import { PriceTag } from '../shared/QuizAnswerSlides';
import useQuizQuestion from './useQuizQuestion';

const QuizQuestion = () => {
  const [{ choosePlan, maxPrice, rooms, isScan }, dispatch] = useOnboarding();
  const { isLoading: isLoadingScan } = useOnboardingQuiz();
  const { isPremadeProperty } = useOnboardingState();
  const isMobile = useMediaQuery(MOBILE_RESOLUTION);
  const { question, prev, next, hasPrev } = useQuizQuestion();
  const { country } = useSession();
  const { history } = useRouter();
  const intl = useIntl();
  const transitions = useTransition(question, {
    keys: item => item.id,
    from: { opacity: 0, transform: 'translateY(-25%)' },
    enter: { opacity: 1, transform: 'translateY(0)' },
    leave: { position: 'absolute', opacity: 0, transform: 'translateY(25%)' },
    config: config.gentle,
  });
  const [budgetValue, setBudgetValue] = React.useState<number>(maxPrice || 0);
  const [roomList, setRoomList] = React.useState<Room[]>(rooms || []);

  const getValue = React.useCallback(
    (data: number) => {
      setBudgetValue(data || maxPrice);
    },
    [maxPrice]
  );

  const getRooms = React.useCallback(
    (data: Room[]) => {
      setRoomList(data || rooms);
    },
    [rooms]
  );

  const setMaxPrice = React.useCallback(
    (data: number) => {
      dispatch({
        type: OnboardingAction.SET_MAX_PRICE,
        payload: data,
      });
      next(data);
    },
    [dispatch, next]
  );

  const setRooms = React.useCallback(
    (data: Room[]) => {
      dispatch({
        type: OnboardingAction.SET_ROOM_LIST,
        payload: data,
      });
      next(null);
    },
    [dispatch, next]
  );

  const setPanorama = React.useCallback(
    (data: { id: number; thumbnail: string; name: string } | null) => {
      dispatch({
        type: OnboardingAction.SET_PANORAMA,
        payload: data,
      });
      // next(null);
    },
    [dispatch]
  );

  const isBudgetSection =
    question?.question === 'What is your budget?' || question?.id === 20;

  const isRoomsSection =
    question?.question === 'Choose the rooms you would like to design.' ||
    question?.id === 22;

  const isColorPallleteSection =
    question?.question === 'Choose you color palette';

  const isDesignStyleSection =
    question?.question === 'Choose your Design style';

  const isPanoramaSelection = question?.question.includes('view');

  const budgetPriceValue = useFormattedCurrency(budgetValue ?? 0, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const currency =
    intl.locale === 'he' || country === SupportedCountry.ISRAEL ? '₪' : '$';
  const value =
    intl.locale === 'he'
      ? budgetPriceValue.slice(0, -1)
      : budgetPriceValue.substring(1);
  const budgetSlider = () => {
    return (
      <SliderContainer>
        <div className="text-container">
          <FormattedMessage
            id={Message.ONBOARDING_AMOUNT_OF_MONEY_TO_SPEND}
            values={{
              linebreak: isMobile ? <br /> : ' ',
            }}
          />
        </div>
        <SliderRange
          min={0}
          max={150000}
          getValue={getValue}
          defaultValue={budgetValue || maxPrice}
          icon={country === SupportedCountry.USA ? '$' : '₪'}
          label={currency}
        />
        <BudgetValueContainer>
          <CurrencyText>{currency}</CurrencyText>
          <ValueText>{value}</ValueText>
        </BudgetValueContainer>
      </SliderContainer>
    );
  };
  const roomsSection = () => {
    return (
      <MultipleChips
        singleSelect={isScan}
        options={roomListData}
        getValue={getRooms}
        defaultValue={roomList || rooms}
        allChips
        allChipName={intl.formatMessage({ id: Message.SELECT_ALL })}
      />
    );
  };
  const [selectedAnswer, setSelectedAnswer] = React.useState<number | null>(
    null
  );
  const onClick = React.useCallback(() => {
    LogsAPI.postUserLogs({
      area: userLogData.eventArea.registration,
      section: userLogData.eventSection.planUpload,
      name: userLogData.eventName.skipQuestion,
      path: userLogData.eventPath.appOnBoardingQuizQuestionPath,
    });
    if (isRoomsSection) {
      setRooms(roomList);
    }
    if (isBudgetSection) {
      setMaxPrice(budgetValue);
    }
    if (selectedAnswer !== null) {
      next(selectedAnswer);
      setSelectedAnswer(null);
    } else next(Math.floor(Math.random() * 2));
  }, [
    isRoomsSection,
    isBudgetSection,
    selectedAnswer,
    next,
    setRooms,
    roomList,
    setMaxPrice,
    budgetValue,
  ]);

  const getDesignStyleName = (name: string) => {
    if (name === 'Modern') {
      return Message.MODERN;
    }
    if (name === 'Classic') {
      return Message.CLASSIC;
    }
    if (name === 'Scandinavian') {
      return Message.SCANDINAVIAN;
    }
    return name;
  };

  if (!isScan) if (!question) return <Redirect to={ClientRouter.UPLOAD_PLAN} />;

  return (
    <ParentDiv>
      {isScan && isLoadingScan ? (
        <Loader fullHeight />
      ) : (
        <>
          <Container className="quiz-container budget">
            <div style={{ position: 'relative', minHeight: 255 }}>
              {transitions((style, item) => (
                <animated.div key={item.id} style={style}>
                  <Section.QuestionLabel question={question} />
                  {isColorPallleteSection && (
                    <div className="color-subtitle">
                      <FormattedMessage id={Message.SELECT_ONE_COLOR} />
                    </div>
                  )}
                  {!isBudgetSection &&
                    !isColorPallleteSection &&
                    !isRoomsSection && (
                      <div className="color-subtitle">
                        <FormattedMessage id={Message.CHOOSE_IMAGE_SUBTEXT} />
                      </div>
                    )}
                  {isMobile ? (
                    <>
                      {isBudgetSection && budgetSlider()}
                      {isRoomsSection && roomsSection()}
                      {!(isBudgetSection || isRoomsSection) && (
                        <>
                          <Section.QuizAnswerSlides
                            items={question.answers}
                            onClickItem={i => {
                              if (choosePlan && isDesignStyleSection) {
                                setSelectedAnswer(i);
                              }
                              if (isColorPallleteSection) setSelectedAnswer(i);
                              else if (isPanoramaSelection)
                                setPanorama(question.answers[i].panorama);
                              setSelectedAnswer(i);
                              // else if (!choosePlan) next(i);
                            }}
                            isColorPallleteSection={isColorPallleteSection}
                            selectedAnswer={selectedAnswer}
                            isPanoramaSelection={isPanoramaSelection}
                          />
                        </>
                      )}
                    </>
                  ) : (
                    <AnswerGrid>
                      {isBudgetSection && budgetSlider()}
                      {isRoomsSection && roomsSection()}
                      {!(isBudgetSection || isRoomsSection) && (
                        <>
                          {question.answers.map((answer, i) => (
                            <div key={answer.id}>
                              <GridContainer
                                key={answer.id}
                                isSelected={i === selectedAnswer}
                              >
                                <div className="checkContainer">
                                  <Check />
                                </div>
                                <GridItem
                                  tabIndex={0}
                                  onClick={() => {
                                    if (choosePlan && isDesignStyleSection) {
                                      setSelectedAnswer(i);
                                    }
                                    if (
                                      !isPremadeProperty &&
                                      isDesignStyleSection
                                    ) {
                                      setSelectedAnswer(i);
                                    }
                                    if (isColorPallleteSection)
                                      setSelectedAnswer(i);
                                    else if (isPanoramaSelection)
                                      setPanorama(answer.panorama);
                                    setSelectedAnswer(i);
                                    // else if (!choosePlan) next(i);
                                  }}
                                  onKeyPress={() => next(i)}
                                  withLabel={Boolean(answer.price_tag)}
                                  style={{
                                    height: isColorPallleteSection ? 235 : 312,
                                    width: '100%',
                                  }}
                                  isColor={isColorPallleteSection}
                                  src={
                                    isPanoramaSelection
                                      ? answer.panorama?.thumbnail
                                      : (answer.answer as MediaFile).file
                                  }
                                >
                                  {Boolean(answer.price_tag) && (
                                    <>
                                      <img
                                        src={
                                          isPanoramaSelection
                                            ? answer.panorama?.thumbnail
                                            : (answer.answer as MediaFile).file
                                        }
                                        alt="budget"
                                      />
                                      <p>{PriceTag[answer.price_tag]}</p>
                                    </>
                                  )}
                                </GridItem>
                                {isColorPallleteSection && (
                                  <div
                                    style={{
                                      width: '100%',
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    {answer.colors?.map(e => (
                                      <div
                                        style={{
                                          width: `${95 / 6}%`,
                                          height: 42,
                                          background: e,
                                          margin: '4px',
                                          borderRadius: '10px',
                                        }}
                                        key={e}
                                      />
                                    ))}
                                  </div>
                                )}
                              </GridContainer>
                              <div className="design-subtitle">
                                {isDesignStyleSection && (
                                  <FormattedMessage
                                    id={getDesignStyleName(
                                      answer?.text as string
                                    )}
                                    tagName="span"
                                  />
                                )}
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </AnswerGrid>
                  )}
                </animated.div>
              ))}
            </div>
            {!isMobile && (
              <CustomActions
                withSkip={
                  !(
                    isBudgetSection ||
                    isRoomsSection ||
                    isColorPallleteSection ||
                    isDesignStyleSection ||
                    isPanoramaSelection
                  )
                }
                withPrevious={true}
                prevProps={{
                  onClick: () => {
                    LogsAPI.postUserLogs({
                      area: userLogData.eventArea.registration,
                      section: userLogData.eventSection.planUpload,
                      name: userLogData.eventName.backQuestion,
                      path: userLogData.eventPath.appOnBoardingQuizQuestionPath,
                    });

                    if (hasPrev) prev();
                    else history.goBack();
                  },
                }}
                nextProps={{
                  onClick: () => onClick(),
                  disabled:
                    (isBudgetSection && !budgetValue) ||
                    (isRoomsSection && !roomList.length) ||
                    ((isColorPallleteSection ||
                      isDesignStyleSection ||
                      isPanoramaSelection) &&
                      selectedAnswer === null),
                }}
                nextText={
                  ((isBudgetSection && budgetValue) ||
                    (isRoomsSection && roomList.length > 0) ||
                    selectedAnswer !== null) && (
                    <FormattedMessage
                      id={Message.PROPERTY_ADD_PROPERTY_BUTTON_NEXT}
                    />
                  )
                }
              />
            )}
          </Container>
          {isMobile && (
            <CustomActions
              withSkip={
                !(
                  isBudgetSection ||
                  isRoomsSection ||
                  isColorPallleteSection ||
                  isDesignStyleSection ||
                  isPanoramaSelection
                )
              }
              withPrevious={true}
              prevProps={{
                style: { background: 'white' },
                onClick: () => {
                  LogsAPI.postUserLogs({
                    area: userLogData.eventArea.registration,
                    section: userLogData.eventSection.planUpload,
                    name: userLogData.eventName.backQuestion,
                    path: userLogData.eventPath.appOnBoardingQuizQuestionPath,
                  });

                  if (hasPrev) prev();
                  else history.goBack();
                },
              }}
              nextProps={{
                onClick: () => onClick(),
                disabled:
                  (isBudgetSection && !budgetValue) ||
                  (isRoomsSection && !roomList) ||
                  ((isColorPallleteSection ||
                    isDesignStyleSection ||
                    isPanoramaSelection) &&
                    selectedAnswer === null),
              }}
              nextText={
                ((isBudgetSection && budgetValue) ||
                  (isRoomsSection && roomList.length > 0) ||
                  selectedAnswer !== null) && (
                  <FormattedMessage
                    id={Message.PROPERTY_ADD_PROPERTY_BUTTON_NEXT}
                  />
                )
              }
            />
          )}
        </>
      )}
      ;
    </ParentDiv>
  );
};

export default QuizQuestion;

const ParentDiv = styled.div`
  .quiz-container {
    min-height: 800px;
    @media (max-width: 767px) {
      height: 70vh;
      overflow: scroll;
      min-height: 0;
    }
  }
  .MuiChip-label .MuiChip-labelSmall {
    @media (max-width: 767px) {
      padding: 6px 4px !important;
    }
  }
`;

const Container = styled(Section.Container)`
  margin-top: 104px;
  @media (max-width: 767px) {
    margin-top: 48px;
  }
  & .color-subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;

    margin-top: 24px;
    text-align: center;
    /* Third text level */
    @media (min-width: 767px) and (max-height: 1050px) {
      margin-bottom: 24px !important;
    }
    margin-bottom: 84px;
    color: #696969;
    @media (max-width: 767px) {
      font-weight: 400;
      font-size: 16px;
      margin-top: 16px;
      margin-bottom: 70px;
    }
  }
  .design-subtitle {
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #282929;
    margin-top: 16px;
    p {
      margin: 0;
    }
  }
  & .section-title-label {
    text-align: start;
    font-weight: 700;
    font-size: 1.25rem;
    ${rtl`
      margin-left: 10px;
    `}
    @media (min-width: 1150px) {
      font-size: 1.75rem;
      max-width: unset;
    }
  }

  & .actions-container {
    position: absolute !important;
    bottom: 64px !important;
    width: 100% !important;
    height: fit-content;
    ${rtl`left: 0;`}
    @media (min-height: 900px) {
      margin-top: 30px;
    }
    @media (max-width: 767px) {
      position: fixed !important;
      // bottom: 24px !important;
      bottom: 107px !important;
      width: 100% !important;
    }
  }
`;

const CustomActions = styled(Section.Actions)``;

const AnswerGrid = styled.div.attrs({ className: 'quiz-answers-grid' })`
  display: flex;
  align-items: center;
  width: fit-content;
  flex-wrap: wrap;
  @media ${MOBILE_TABLET_RESOLUTION} {
    @media (min-width: 767px) and (max-height: 1050px) {
      width: auto;
    }
    width: 100%;
    overflow-x: scroll;
    flex-wrap: inherit;
  }

  margin: auto;
  gap: 22px;
  padding: 10px 50px;
`;

const GridItem = styled.div.attrs({ className: 'image-container' })<{
  withLabel?: boolean;
  src?: string;
  isColor: boolean;
}>`
  position: relative;
  flex-basis: 100%;

  border-radius: 15px;
  border: 1px solid #f7f7f7;
  background: #f7f7f7;
  ${({ theme }) => theme.utils.centerFlex};
  flex-direction: column;
  cursor: pointer;
  ${({ withLabel }) =>
    !withLabel &&
    css<{ src?: string }>`
      background-image: url(${({ src }) => src});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    `}

  & img {
    position: relative;
    top: -8px;
    height: ${({ withLabel = false }) => (withLabel ? 65 : 100)}%;
    width: 100%;
    border-radius: inherit;
    object-fit: scale-down;
  }
  ${({ isColor }) =>
    isColor &&
    css`
      &: hover {
        opacity: 0.4;
      }
    `}

  & > p {
    color: #5b7971;
    font-size: 1.25rem;
    font-weight: 900;
    position: absolute;
    bottom: 0;
    left: auto;
    @media (max-height: 900px) {
      font-size: 1rem;
    }
  }
`;

const GridContainer = styled.div<{ isSelected: boolean }>`
  &:hover {
    border: 2px solid transparent #fc5c42;
  }
  position: relative;
  min-height: 281px;
  width: 254px;
  @media ${SMALL_WIDTH_RESOLUTION} {
    width: 100%;
    min-width: 300px;
  }

  border-radius: 15px;
  border: ${({ isSelected }) => (isSelected ? ' 2px solid #fc5c42' : 'none')};

  cursor: pointer;
  margin: 10px 0;
  & .image-container {
    ${({ isSelected }) =>
      isSelected &&
      css`
        opacity: 0.4;
      `}
  }
  .checkContainer {
    z-index: 9;
    height: 24px;
    width: 24px;
    position: absolute;
    top: 8px;
    ${rtl`right: 8px;`}
    border-radius: 12px;
    background-color: #fc5c42;
    display: ${({ isSelected }) => (isSelected ? 'block' : 'none')};
    svg {
      width: 15px;
      height: 23px;
      ${rtl`margin-left: 4px;`}
      path {
        stroke: white;
        fill: white;
      }
    }
  }
`;

const SliderContainer = styled.div`
  width: 100%;
  .text-container {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;

    height: 28px;
    margin: 14px auto;
    text-align: center;
    padding-bottom: 78px;
    /* Third text level */

    color: #696969;
    @media (max-width: 767px) {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
    }
  }
`;

const BudgetValueContainer = styled.div`
  font-style: normal;
  font-weight: 900;
  font-size: 72px;
  line-height: 117.5%;
  /* identical to box height, or 85px */
  display: flex;
  margin: 0 auto;
  margin-top: 45px;
  direction: ltr;
  /* First level text */
  width: fit-content;
  color: #282929;
  @media (max-width: 767px) {
    font-weight: 900;
    font-size: 50px;
    margin-top: 16px;
  }
  /* & > div {
    font-weight: 900;
    font-size: 40px;
    line-height: 117.5%;
    padding-top: 32px;
    margin-right: 8px;
    @media (max-width: 767px) {
      font-weight: 900;
      font-size: 50px;
      padding-top: 18px;
    }
  } */
`;

const CurrencyText = styled.div`
  font-size: 40px;
  margin-right: 8px;
  padding-top: 6px;
  @media ${MOBILE_RESOLUTION} {
    font-size: 30px;
  }
`;

const ValueText = styled.div``;
