import * as React from 'react';
import GoogleIcon from 'assets/icons/google.svg';
import GoogleIconOnboarding from 'assets/icons/google-onboarding.png';
import ProviderButton from 'components/Button/ProviderButton';
import { OnboardingFields } from 'store/types/authentication.types';
import { LogsAPI } from 'api/methods';
import { userLogData } from 'mockData/userLogData';
import styled from 'styled-components';
import useGoogleProvider from './useGoogleProvider';
import type { AuthContext } from '../types';

interface Props {
  authContext: AuthContext;
  forceLabel?: boolean;
  onboardingFields?: OnboardingFields;
  isOnBoarding?: boolean;
}

const GoogleProvider: React.FC<Props> = ({
  authContext,
  forceLabel,
  onboardingFields,
  isOnBoarding,
}) => {
  const SignInWithGoogle = useGoogleProvider(onboardingFields);

  const googleSingIn = () => {
    LogsAPI.postUserLogs({
      area: userLogData.eventArea.registration,
      section: userLogData.eventSection.userInfo,
      name: userLogData.eventName.google,
      path: userLogData.eventPath.appOnBoardingAdditionalInfoPath,
    });
    SignInWithGoogle();
  };

  return (
    <>
      {isOnBoarding ? (
        <GoogleOnboarding
          src={GoogleIconOnboarding}
          alt="google-icon"
          onClick={googleSingIn}
        />
      ) : (
        <div className="provider-button-wrapper">
          <ProviderButton
            provider="Google"
            icon={GoogleIcon}
            // authContext={authContext}
            // forceLabel={forceLabel}
            onClick={googleSingIn}
          />
        </div>
      )}
    </>
  );
};

export default GoogleProvider;

const GoogleOnboarding = styled.img`
  cursor: pointer;
`;
