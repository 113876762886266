import * as React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import styled, { css } from 'styled-components';
import { ButtonCss } from 'styles/sharedStyle';

interface Props extends ButtonProps {}

const ExpandButton: React.FC<Props> = props => (
  <CustomButton {...props}>+</CustomButton>
);

export default ExpandButton;

const ButtonStyle = css`
  ${ButtonCss}

  width: 32px;
  height: 32px;
  min-width: unset;
  border-radius: 10px;
`;

const CustomButton = styled(Button)`
  && {
    ${ButtonStyle}
    background: ${({ theme }) => theme.colors.primary.dark};
    color: white;

    &:hover {
      background: ${({ theme }) => theme.colors.primary.dark};
    }
  }
`;
