import * as React from 'react';
import TextField, { StandardTextFieldProps } from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import styled from 'styled-components';
import Icon from 'components/Icon';

interface Props extends StandardTextFieldProps {
  onIconClick?: () => void;
}

const SearchInput: React.FC<Props> = ({ onIconClick, ...props }) => {
  return (
    <CustomTextField
      {...props}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconContainer onClick={onIconClick}>
              <Icon name="search-mobile" size={23} color="#130F26" />
            </IconContainer>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchInput;

const CustomTextField = styled(TextField)`
  && {
    .MuiInputBase-root {
      padding: 0 20px;
      height: 38px;
      background: rgba(255, 255, 255, 0.8);
      box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
      backdrop-filter: blur(13px);
      border-radius: 40px;

      .MuiOutlinedInput-notchedOutline {
        border: 1px solid white;
      }

      input {
        ::placeholder {
          color: #222222;
          font-size: 16px;
          opacity: 1;
          letter-spacing: 0.5px;
        }
      }
    }
  }
`;

const IconContainer = styled.span`
  display: flex;
  cursor: pointer;
  svg {
    color: white;
    fill: white;
  }
`;
