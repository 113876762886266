import { all, call, put, debounce, takeLatest } from 'redux-saga/effects';
import { RolesAPI, TeamPatriciaAPI } from 'api/methods';
import TeamPatriciaDataType from 'store/constants/user-management/teamPatricia.constants';
import * as TeamPatriciaActions from 'store/actions/user-management/teamPatricia.actions';
import * as Payload from 'store/types/user-management/teamPatricia.types';
import { newSortByMap } from 'utils/transform.utils';
import { removeEmptyValues } from 'utils/common.utils';
import { API_ERROR_MESSAGE } from 'constants/common.constants';
import { showNotification } from 'store/actions/notifications.actions';

function* errorHandler(
  e: Error & { status: number },
  type: TeamPatriciaDataType,
  rejectAction: (payload: {
    error: string;
  }) => { type: TeamPatriciaDataType; payload: { error: string } }
) {
  const error = e.message || API_ERROR_MESSAGE;
  yield put(showNotification({ key: type, message: error, severity: 'error' }));
  yield put(rejectAction({ error }));
}

const sortUsersMap = {
  email: 'email',
  country: 'country',
  fullName: 'full_name',
  phoneNumber: 'phone_number',
  department: 'department',
  role: 'role',
};

function* getUsersDataRequest({
  payload,
  type,
}: ReturnType<typeof TeamPatriciaActions.getUsersDataRequest>) {
  try {
    const {
      page = 0,
      size = 10,
      search,
      countries,
      departments,
      roles,
      sortBy,
      created_at__date__gte,
      created_at__date__lte,
    } = payload;
    const params = {
      ...removeEmptyValues({
        page: page + 1,
        size,
        search,
        departments,
        roles,
        created_at__date__gte,
        created_at__date__lte,
        countries: (countries as { iso: string; name: string }[])?.map(
          country => country.iso
        ),
        ...newSortByMap<typeof sortUsersMap>(sortUsersMap, sortBy),
      }),
      is_archived: false,
    } as Payload.GetUsersDataRequestPayload;

    const {
      results: users,
      count,
    }: Payload.GetUsersDataResponsePayload = yield call(
      TeamPatriciaAPI.getTeamPatriciaUsers,
      { params }
    );
    const pageCount = Math.ceil(count / size);
    yield put(TeamPatriciaActions.getUsersDataFulfilled({ users, pageCount }));
  } catch (e) {
    yield call(errorHandler, e, type, TeamPatriciaActions.getUsersDataRejected);
  }
}

function* getRolesDataRequest({
  type,
  payload,
}: ReturnType<typeof TeamPatriciaActions.getRolesDataRequest>) {
  try {
    const { page = 0, size = 10, search, sortBy } = payload;
    const params = removeEmptyValues({
      page: page + 1,
      size,
      search,
      ...newSortByMap<typeof sortUsersMap>(sortUsersMap, sortBy),
    }) as Payload.GetRolesDataRequestPayload;

    const { results, count }: Payload.GetRolesDataResponsePayload = yield call(
      RolesAPI.getRoles,
      {
        params,
      }
    );
    const pageCount = Math.ceil(count / size);
    const roles = {
      pageCount,
      results,
    };
    yield put(TeamPatriciaActions.getRolesDataFulfilled({ roles }));
  } catch (e) {
    yield call(errorHandler, e, type, TeamPatriciaActions.getRolesDataRejected);
  }
}

function* addUserDataRequest({
  payload: { onSuccess, ...payload },
  type,
}: ReturnType<typeof TeamPatriciaActions.addUserDataRequest>) {
  try {
    const data = removeEmptyValues({
      ...payload,
      type: 'TEAM_PATRICIA',
    }) as Payload.AddUserDataRequestPayload;
    yield call(TeamPatriciaAPI.addTeamPatriciaUser, data);
    yield put(TeamPatriciaActions.addUserDataFulfilled());
    yield call(onSuccess);
    yield put(
      showNotification({
        key: type,
        message: 'An invite has been sent to the provided email address.',
        severity: 'success',
      })
    );
  } catch (e) {
    yield call(errorHandler, e, type, TeamPatriciaActions.addUserDataRejected);
  }
}

function* teamUsersSaga() {
  yield all([
    debounce(
      500,
      TeamPatriciaDataType.GET_USERS_DATA_REQUEST,
      getUsersDataRequest
    ),
    debounce(
      500,
      TeamPatriciaDataType.GET_ROLES_DATA_REQUEST,
      getRolesDataRequest
    ),
    takeLatest(TeamPatriciaDataType.ADD_USER_DATA_REQUEST, addUserDataRequest),
  ]);
}

export default teamUsersSaga;
