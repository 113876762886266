import PropertiesType from 'store/constants/properties-management/PropertiesManagement.constants';
import * as Payload from 'store/types/properties-management/PropertiesManagement.types';

export const getUnitsTasksDataRequest = (
  payload: Payload.GetUnitsTasksDataRequestPayload
) => ({
  type: PropertiesType.GET_UNITS_DATA_REQUEST,
  payload,
});

export const getUnitsTasksDataFulfilled = (
  payload: Payload.GetUnitsTasksFulfilledPayload
) => ({
  type: PropertiesType.GET_UNITS_DATA_FULFILLED,
  payload,
});

export const getUnitsTasksDataRejected = (
  payload: Payload.GetUnitsTasksRejectedPayload
) => ({
  type: PropertiesType.GET_UNITS_DATA_REJECTED,
  payload,
});

export type UnitsTasksAction = ReturnType<
  | typeof getUnitsTasksDataRequest
  | typeof getUnitsTasksDataFulfilled
  | typeof getUnitsTasksDataRejected
>;
