import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import Logo from 'components/Logo';
import socialIcons from 'assets/@client/social';
import Link from 'components/Link/Link';
import { Message } from 'i18n';
import { Link as LinkRoute } from 'react-router-dom';
import { userLogData } from 'mockData/userLogData';
import { LogsAPI } from 'api/methods';

interface Props {
  hebrew?: boolean;
}
const LogoStack: React.FC<Props> = ({ hebrew }) => {
  return (
    <Stack>
      <LogoContainer>
        <LinkRoute to="/">
          <Logo className="brand-logo" />
        </LinkRoute>
        <div>
          <p>
            <FormattedMessage id={Message.APP_NAME} />
            <br />{' '}
            {hebrew ? (
              'חוויה וירטואלית מותאמת אישית לעיצוב קנייה והלבשת הבית שלך.'
            ) : (
              <FormattedMessage id={Message.FOOTER_APP_DESCRIPTION} />
            )}
          </p>
          <SocialIcons>
            {socialIcons.map(({ alt, src, href }) => (
              <Link
                href={href}
                key={alt}
                onClick={() => {
                  LogsAPI.postUserLogs({
                    name: userLogData.eventName.socialIcon,
                    area: userLogData.eventArea.homePage,
                    section: userLogData.eventSection.footer,
                    path: userLogData.eventPath.appPath,
                    metadata: { socia_media_name: alt },
                  });
                }}
              >
                <img src={src} alt={alt} />
              </Link>
            ))}
          </SocialIcons>
        </div>
      </LogoContainer>
      <Copyright>
        {hebrew ? (
          '2021 כל הזכויות שמורות © פטרישיה איי.איי'
        ) : (
          <FormattedMessage
            id={Message.FOOTER_COPYRIGHT_STATEMENT}
            values={{ currentYear: new Date().getFullYear() }}
          />
        )}
      </Copyright>
    </Stack>
  );
};

export default LogoStack;

const Stack = styled.div.attrs({ className: 'logo-stack-container' })`
  padding: 0 20px;
  flex: 1;
  margin: 1.33rem 0;

  & .brand-logo {
    height: 106px;
    width: 62px;
  }
`;

const LogoContainer = styled.div.attrs({
  className: 'logo-stack-container-logo-container',
})`
  display: flex;
  justify-content: center;
  & > div {
    ${rtl`
        margin-left:20px
       `}
  }

  & > p {
    margin: 0 0 30px 0;
    color: #1e2833;
    max-width: 28ch;
    font-size: 0.938rem;
  }
`;

const SocialIcons = styled.div`
  & :first-child {
    ${rtl`
      margin-left: 0;
    `}
  }
  & > a {
    margin: 0 10px;
    & > img {
      width: 29px;
      height: 29px;
    }
  }
`;

const Copyright = styled.p`
  margin: 30px auto;
  color: #1e2833;
  font-weight: 400;
  font-size: 0.938rem;
  text-align: center;
  opacity: 0.5;
`;
