import * as React from 'react';
import { OnboardingFields } from 'store/types/authentication.types';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import Provider from './provider';
import type { AuthContext } from './types';

interface Props {
  authContext: AuthContext;
  forceLabel?: boolean;
  onboardingFields?: OnboardingFields;
  isOnBoarding?: boolean;
}

const Providers: React.FC<Props> = ({
  authContext,
  forceLabel,
  onboardingFields,
  isOnBoarding,
}) => {
  const providersProps = {
    authContext,
    forceLabel,
    onboardingFields,
    isOnBoarding,
  };
  return (
    <Grid container justifyContent="center">
      {isOnBoarding ? (
        <OnboardingMainContainer>
          <Provider.Facebook {...providersProps} />
          <Provider.Google {...providersProps} />
          <Provider.Apple {...providersProps} />
        </OnboardingMainContainer>
      ) : (
        <>
          <Provider.Google {...providersProps} />
          <Provider.Facebook {...providersProps} />
          <Provider.Apple {...providersProps} />
        </>
      )}
    </Grid>
  );
};

export default Providers;

const OnboardingMainContainer = styled.div`
  display: flex;
  gap: 24px;
`;
